import React, { useState, useEffect } from "react";
import { StyleSheet, Text, View, FlatList } from "react-native";
import Style from "src/globalStyles";
import Analytics from "src/backend/analytics";
import NavBar from "src/components/navBar";
import PortalRow from "src/components/PortalRow";
import Rex from "src/globalState";
import Util from "src/utility";

export default function AddPortals({ route, navigation }) {
  const { params: { checkRefresh, portalsToHide = [] } = {} } = route || {};
  const allPortals = Util.filterPortals(
    Rex.getAllPortals()
      .filter((p) => !portalsToHide.includes(p.navName))
      .sort((p1, p2) => {
        // sort alphabetically
        if (p1.txtName < p2.txtName) return -1;
        if (p1.txtName > p2.txtName) return 1;
        return 0;
      })
  );
  const keyToPortal = {};
  allPortals.forEach((p) => {
    keyToPortal[p.navName] = p;
  });
  const [topMessage, setTopMessage] = useState(
    "Add more to your home screen! ✨"
  );
  const [activePortals, setActivePortals] = useState(Rex.getHomePortals());
  const activePortalKeys = activePortals.map((p) => p.navName);
  const inactivePortals = allPortals.filter(
    (p) => !activePortalKeys.includes(p.navName)
  );
  const bottomMessage =
    inactivePortals < 1
      ? "There are no more screens left to add."
      : "Touch any screen to add it to your personal home screen.";

  useEffect(() => {
    Analytics.logEvent("view_addPortals");
  }, []);

  return (
    <View style={styles.pageContent}>
      <NavBar navigation={navigation} text="Add More" />
      <View style={{ marginHorizontal: 15 }}>
        <Text style={{ ...Style.get("headerText"), marginTop: 15 }}>
          {topMessage}
        </Text>
        <Text style={styles.formDescription}>{bottomMessage}</Text>
      </View>
      <FlatList
        style={styles.rowList}
        contentContainerStyle={{ paddingBottom: 40 }}
        data={inactivePortals}
        renderItem={({ item }) => (
          <PortalRow
            portal={item}
            navigation={navigation}
            type="pressable"
            onPressProp={() => {
              Analytics.logEvent("touch_addPortals_addPortal", {
                key: item.navName,
                title: item.txtName
              });
              const newPortals = [...activePortals, item];
              setActivePortals(newPortals);
              const portalKeys = newPortals.map((portal) => portal.navName);
              if (portalKeys.length > 0) Rex.orderChanged(newPortals);
              checkRefresh();
              setTopMessage(
                `Added ${item.txtName}! ${Util.randomCelebratoryEmoji()}`
              );
            }}
          />
        )}
        keyExtractor={(item) => item.navName || "addPortalButton"}
        scrollIndicatorInsets={{ right: 1 }}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  pageContent: {
    flex: 1,
    backgroundColor: "white"
  },
  formDescription: {
    marginVertical: 10
  }
});
