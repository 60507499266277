import React, { useState, useEffect } from "react";
import { StyleSheet, View, FlatList } from "react-native";
import Database from "src/backend/database";
import Analytics from "src/backend/analytics";
import NavBar from "src/components/navBar";
import PortalRow from "src/components/PortalRow";
import HelpButton from "src/components/HelpButton";
import HelpText from "src/components/HelpText";
import Rex from "src/globalState";
import Glob from "src/globalConstants";

const { width } = Glob.get("dimensions");

export default function EditAllPortals({ route, navigation }) {
  const { onUpdatePortal } = route?.params || {};
  const [allPortals, setAllPortals] = useState(Rex.getAllPortals());

  useEffect(() => {
    Analytics.logEvent("view_editAllPortals");
    onUpdatePortal(setAllPortals, false);
  }, []);

  const allRows = [{ isAddPortalButton: true }, ...allPortals];

  return (
    <View style={styles.pageContent}>
      <NavBar
        navigation={navigation}
        text="All Screens"
        RightButton={
          <View style={{ alignItems: "flex-end" }}>
            <HelpButton
              title="All Screens"
              message="On this screen, you can see all your app's screens ('portals'). Think of portals like mini apps themselves. Tap any portal to edit it or delete it. Or create a new one with 'Make New Screen'."
              navigation={navigation}
              videoUrl="https://youtu.be/nFOaVznIiJ4"
            />
          </View>
        }
      />
      <View style={{ marginTop: 20, paddingHorizontal: 15, width }}>
        <HelpText
          text={`This is where you can view and manage all your app's screens.${
            allPortals && allPortals.length < 1 ? " Try making a screen!" : ""
          }`}
        />
      </View>
      <FlatList
        style={styles.rowList}
        contentContainerStyle={{ paddingBottom: 40 }}
        data={allRows}
        renderItem={({ item }) => (
          <PortalRow
            portal={item}
            navigation={navigation}
            type={item.isAddPortalButton ? "add" : "edit"}
            onUpdatePortal={onUpdatePortal}
            showPortalType
          />
        )}
        keyExtractor={(item) => item.navName || "addPortalButton"}
        scrollIndicatorInsets={{ right: 1 }}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  pageContent: {
    flex: 1,
    alignItems: "center",
    backgroundColor: "white"
  },
  formDescription: {
    marginVertical: 20
  },
  rowList: {
    width,
    paddingTop: 20
  }
});
