import React, { useState } from "react";
import { StyleSheet, View } from "react-native";
import DraggableList from "src/components/DraggableList";
import PortalRow from "src/components/PortalRow";
import Glob from "src/globalConstants";

const CUTOFF_KEY = "INACTIVE_PORTALS_CUTOFF";

const { width } = Glob.get("dimensions");

export default function EditPortalsOrder({
  allPortals,
  defaultPortalKeys,
  onUpdatePortalKeys,
  activeHeader = "Active",
  inactiveHeader = "Inactive",
  showPortalType
}) {
  const [activePortalKeys, setActivePortalKeys] = useState(
    defaultPortalKeys || []
  );
  const keyToPortal = { [CUTOFF_KEY]: { header: inactiveHeader } };
  allPortals.forEach((p) => {
    keyToPortal[p.navName] = p;
  });
  const activePortals = activePortalKeys
    .map((pk) => keyToPortal[pk])
    .filter((p) => p); // in case there's a fake portal key
  const inactivePortals = allPortals.filter(
    (p) => !activePortalKeys.includes(p.navName)
  );
  inactivePortals.sort((p1, p2) => {
    // sort alphabetically
    if (p1.txtName < p2.txtName) return -1;
    if (p1.txtName > p2.txtName) return 1;
    return 0;
  });

  const portalsData = [
    { header: activeHeader },
    ...activePortals,
    { header: inactiveHeader },
    ...inactivePortals
  ];
  const isPortalActive = (index) =>
    index < portalsData.findIndex((p) => p.header === inactiveHeader);

  return (
    <View style={styles.pageContent}>
      <DraggableList
        data={portalsData}
        style={{ width }}
        onDragEnd={({ data }) => {
          const cutoffKeyIndex = data.findIndex(
            (p) => p.header === inactiveHeader
          );
          const activeKeys = data
            .slice(1, cutoffKeyIndex)
            .map((p) => p.navName);
          setActivePortalKeys(activeKeys);
          onUpdatePortalKeys(activeKeys);
        }}
        keyExtractor={(item) => `draggable-item-${item.navName || item.header}`}
        autoscrollSpeed={120}
        renderItem={({ item, index, drag, isActive }) => (
          <PortalRow
            isMoving={isActive}
            type={item.header ? "inactiveHeader" : "move"}
            inactiveHeader={item.header}
            portal={item}
            index={index}
            isActive={isPortalActive(index)}
            showPortalType={showPortalType}
            drag={drag}
          />
        )}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  contentContainer: {
    // TODO
  },

  pageContent: {
    flex: 1,
    alignItems: "center",
    backgroundColor: "white"
  }
});
