import React, { useEffect, useState } from "react";
import {
  View,
  ScrollView,
  ActivityIndicator,
  Text,
  TouchableOpacity
} from "react-native";
import Rex from "src/globalState";
import Util from "src/utility";
import Glob from "src/globalConstants";
import Firebase from "src/backend/firebase";
import Database from "src/backend/database";
import Analytics from "src/backend/analytics";
import Button from "src/components/Button";
import NavBar from "src/components/navBar";
import InputBox from "src/components/InputBox";
import AuthScreenHeader from "src/components/auth/AuthScreenHeader";
import AlertModal from "src/components/AlertModal";

const { width } = Glob.get("dimensions");
const APP_IS_UNE = Glob.get("appIsUphillNewEngland");

export default function JoinWithInvitationCode({ route, navigation }) {
  const { params: { fullName, accountType } = {} } = route || {};
  const loggedInEmail = Firebase.getUser()?.email;
  const showBackButton = Glob.get("appIsOnespotlike") && navigation.canGoBack();
  const [passcode, setPasscode] = useState("");
  const [checkingPasscode, setCheckingPasscode] = useState(false);
  const [alert, setAlert] = useState(null);

  useEffect(() => {
    Analytics.logEvent("view_joinWithInvitationCode");
  }, []);

  const continueToSignup = async (userInvitation = null) => {
    const { type, firstName, lastName } = userInvitation || {};
    let invitationAccountType = null;
    if (type) {
      invitationAccountType = await Database.fetchAccountTypeDetails(type);
      if (invitationAccountType) Rex.setUserType(type);
    }
    let userFullName = fullName;
    if (firstName)
      userFullName = `${firstName}${lastName ? ` ${lastName}` : ""}`;
    // If we successfully fetched the account type from the invitation
    if (invitationAccountType)
      navigation.push("signup", {
        fullName: userFullName,
        accountType: { ...invitationAccountType, key: type },
        userInvitation
      });
    // Otherwise, if there's only 1 account type, passed from the first screen
    else if (accountType)
      navigation.push("signup", {
        fullName: userFullName,
        accountType,
        userInvitation
      });
    // Otherwise, make user pick
    else
      navigation.push("pickAccountType", {
        fullName: userFullName,
        userInvitation
      });
  };

  const joinApp = () => {
    setCheckingPasscode(true);
    Analytics.logEvent("action_joinWithInvitationCode_checkingPasscode", {
      passcode
    });
    Database.getUserFromInvitationCode(passcode)
      .then(async ({ user }) => {
        if (user) {
          setCheckingPasscode(false);
          if (loggedInEmail && loggedInEmail !== user.email) {
            Analytics.logEvent(
              "action_joinWithInvitationCode_loggedInOtherEmail"
            );
            setAlert({
              title: "Invitation found for a different email",
              message: `You're currently signed in with ${loggedInEmail}, but the invitation code you entered is for ${user.email}. To redeem your invitation, sign out of this app and then join it again with that same code ("${passcode}").`
            });
          } else {
            Analytics.logEvent("action_joinWithInvitationCode_foundInvitation");
            setAlert({
              title: `Welcome${
                user.firstName ? `, ${user.firstName}` : ""
              }! 👋`,
              message: `We found an invitation for ${
                user.email
              }.${"\n\n"}Let's finish setting up your account.`,
              confirm: {
                text: "Continue",
                onPress: () => continueToSignup(user)
              },
              cancel: { text: "Cancel" }
            });
          }
        } else {
          setCheckingPasscode(false);
          Analytics.logEvent("action_joinWithInvitationCode_noInvitationFound");
          setAlert({
            title: "No invitation found",
            message:
              "Double-check the invitation code you entered, or reach out to the administrators of this app for more help."
          });
        }
      })
      .catch((error) => {
        console.log("error");
        console.log(error);
        setCheckingPasscode(false);
        Analytics.logEvent("error_joinWithInvitationCode_errorCheckingCode", {
          error
        });
        Util.alert(
          "Something went wrong, sorry!",
          "Double-check your invitation code and try again."
        );
      });
  };

  return (
    <View style={{ flex: 1, alignItems: "center", backgroundColor: "white" }}>
      <NavBar
        cannotNavigateBack={!showBackButton}
        navigation={navigation}
        text=""
        backgroundColor="white"
        buttonColor={Rex.getConfig()?.colors?.button}
      />
      <ScrollView
        contentContainerStyle={{ width, padding: 20 }}
        scrollIndicatorInsets={{ right: 1 }}
      >
        <View style={{ marginBottom: 20 }}>
          <AuthScreenHeader
            title={
              APP_IS_UNE
                ? "Enter member invitation code"
                : "Enter invitation code (optional)"
            }
            subtitle={
              APP_IS_UNE
                ? "You should have received this code by email from Uphill New England within 48 hours of purchasing. If you haven't received the code, reach out to admin@uphillnewengland.org."
                : `If you received an invitation code from ${
                    Rex.getConfig()?.names?.full
                  }, please enter it here.`
            }
          />
        </View>
        <InputBox
          key="passcode"
          openStyle
          placeholder="Invitation code"
          icon="8e4e505d-bcc9-445d-a842-c2a792aca31e" // key-outline
          value={passcode}
          onChangeText={setPasscode}
          autoCapitalize="characters"
          onSubmitEditing={joinApp}
        />
        <TouchableOpacity
          activeOpacity={0.7}
          style={{ marginTop: 20, alignItems: "flex-end" }}
          onPress={() => {
            Analytics.logEvent(
              "touch_joinWithInvitationCode_explainInvitationCode"
            );
            setAlert({
              title: "Finding Your Invitation Code",
              message: `If you received an invitation, you can find it by searching your email for a message sent to you from notifications@1spot.app. Or you can search for the phrase "invited you to join ${
                Rex.getConfig()?.names?.nickname
              }". Make sure to check your spam folder too.${"\n\n"}If you haven't received an invitation, feel free to select the "Join without code" option below. Or, if you've logged into any other Onespot app before, you can select the "I've logged in before" option.`
            });
          }}
        >
          <Text style={{ color: "#868686" }}>
            How do I find my invitation code?
          </Text>
        </TouchableOpacity>
        <View style={{ marginTop: 60 }}>
          {checkingPasscode ? (
            <ActivityIndicator size="large" />
          ) : (
            <Button
              text="Next"
              onPress={joinApp}
              disabled={!passcode}
              style={{ opacity: passcode ? 1 : 0.3 }}
              textStyle={{ fontWeight: "bold" }}
            />
          )}
          <View style={{ alignItems: "center" }}>
            <View
              style={{
                marginVertical: 50,
                width: "30%",
                height: 1,
                backgroundColor: "#ccc"
              }}
            />
            <Button
              text={`Join ${APP_IS_UNE ? "as a visitor" : "without code"}`}
              onPress={() => {
                Analytics.logEvent(
                  "touch_joinWithInvitationCode_createAccount"
                );
                continueToSignup();
              }}
              outline={APP_IS_UNE}
              textStyle={{ fontWeight: "bold" }}
            />
            <Button
              text="I've logged in before"
              onPress={() => {
                Analytics.logEvent(
                  "touch_joinWithInvitationCode_loggedInBefore"
                );
                navigation.push("login");
              }}
              outline
              textStyle={{ fontWeight: "bold" }}
            />
            {APP_IS_UNE && (
              <Button
                text="Buy a pass"
                onPress={() => {
                  Analytics.logEvent(
                    "touch_joinWithInvitationCode_buyAUNEPass"
                  );
                  navigation.push("webNav", {
                    title: "Buy a Pass",
                    url: "https://www.uphillnewengland.org"
                  });
                }}
                outline
                textStyle={{ fontWeight: "bold" }}
              />
            )}
          </View>
        </View>
      </ScrollView>
      <AlertModal alert={alert} setAlert={setAlert} />
    </View>
  );
}
