import React, { useEffect, useState } from "react";
import {
  StyleSheet,
  Text,
  View,
  ScrollView,
  Image,
  ActivityIndicator,
  Platform
} from "react-native";
import Glob from "src/globalConstants";
import Database from "src/backend/database";
import Analytics from "src/backend/analytics";
import Util from "src/utility";
import NavBar from "src/components/navBar";
import Button from "src/components/Button";
import BottomSheetModal from "src/components/BottomSheetModal";

const { height, width } = Glob.get("dimensions");
const STRIPE_DASHBOARD_URL = "https://dashboard.stripe.com/dashboard";
const STRIPE_FEES_URL = "https://stripe.com/pricing";

export default function CommerceSettings({ navigation }) {
  const [accountLinkURL, setAccountLinkURL] = useState(null);
  const [loadedCommerceConfig, setLoadedCommerceConfig] = useState(false);
  const [isLoadingButton, setIsLoadingButton] = useState(false);
  const [commerceConfig, setCommerceConfig] = useState({});
  const [stripeAccount, setStripeAccount] = useState(null);
  const stripeAccountIsConfigured =
    stripeAccount?.charges_enabled && stripeAccount?.payouts_enabled;

  useEffect(() => {
    Analytics.logEvent("view_commerceSettings");
    Database.subscribeToCommerce(async (data) => {
      const { config = {} } = data || {};
      if (config?.stripeConnect?.accountID) {
        const account = await Database.fetchStripeConnectedAccount();
        setStripeAccount(account);
      }
      setCommerceConfig(config);
      setLoadedCommerceConfig(true);
    });
    // cleanup called when component is unmounting
    return () => {
      Database.unsubscribeFromCommerce();
    };
  }, []);

  const { stripeConnect = {}, onespotFees = {} } = commerceConfig || {};
  const { accountID = null } = stripeConnect || {};

  const configureStripeConnect = async () => {
    Analytics.logEvent("touch_commerceSettings_stripeConfiguration");
    // If there's already an account ID, we don't need to configure
    let finalAccountID = accountID;
    if (!finalAccountID) {
      setIsLoadingButton(true);
      const { account, error } = await Database.createStripeConnectedAccount();
      Analytics.logEvent("action_commerceSettings_stripeAccountCreated", {
        account,
        error
      });
      finalAccountID = account;
      setIsLoadingButton(false);
    }

    if (finalAccountID) {
      Analytics.logEvent("action_commerceSettings_stripeAccountFetched", {
        accountID: finalAccountID
      });
      setIsLoadingButton(true);
      const { url, error } = await Database.createStripeConnectedAccountLink();
      Analytics.logEvent("action_commerceSettings_stripeAccountLinkFetched", {
        url,
        error
      });
      setIsLoadingButton(false);
      if (Platform.OS === "web") Util.openURL(url);
      else setAccountLinkURL(url);
    }
  };

  const openAppInBrowser = () => {
    Database.fetchPrimaryMetaApp().then((metaApp) => {
      if (metaApp?.webLink) {
        Util.alert(
          "Open on your computer",
          "Open the web version of your app in your phone's browser. Then, copy the page link and send it to your computer so you can navigate back to this commerce page from there.",
          [
            {
              text: "Cancel",
              onPress: () => {},
              style: "cancel"
            },
            {
              text: "Open in Browser",
              onPress: () => Util.openURL(metaApp.webLink)
            }
          ]
        );
      } else Util.alert("No web version", "Sorry, no web version available.");
    });
  };

  if (!loadedCommerceConfig)
    return (
      <View style={styles.pageContent}>
        <NavBar navigation={navigation} text="Onespot Payments" />
        <ScrollView style={{ paddingHorizontal: 20, width }}>
          <ActivityIndicator size="large" style={{ marginTop: 80 }} />
        </ScrollView>
      </View>
    );

  if (!stripeAccountIsConfigured)
    return (
      <View style={styles.pageContent}>
        <NavBar navigation={navigation} text="Onespot Payments" />
        <ScrollView
          style={{ paddingHorizontal: 20, width }}
          scrollIndicatorInsets={{ right: 1 }}
        >
          <Image
            source={Glob.get("commercePhone")}
            style={{
              height: height * 0.3,
              width: height * 0.3,
              resizeMode: "contain",
              alignSelf: "center"
            }}
          />
          <Text
            style={{
              fontWeight: "bold",
              marginVertical: 15,
              fontSize: 24,
              textAlign: "center"
            }}
          >
            Ready to start selling?
          </Text>
          <Text style={styles.bodyText}>
            Sign up as a merchant to sell products & services.
          </Text>
          <Text style={styles.bodyText}>
            {!accountID ? "Configure" : "Finish configuring"} your Stripe
            account to start accepting payments:
          </Text>
          {isLoadingButton ? (
            <ActivityIndicator size="large" style={{ marginTop: 15 }} />
          ) : (
            <>
              {!accountID ? (
                <Button
                  text="Get started"
                  style={{ marginTop: 15 }}
                  color="#7B62A0"
                  onPress={configureStripeConnect}
                />
              ) : (
                <Button
                  text="Finish setting up account"
                  style={{ marginTop: 15 }}
                  color="#7B62A0"
                  onPress={configureStripeConnect}
                />
              )}
              {Platform.OS !== "web" && (
                <>
                  <Text style={styles.bodyText}>
                    Note: You can configure your account in the{" "}
                    {Platform.OS === "ios" ? "iOS" : "Android"} app if you want,
                    but it's a bit easier to do on a computer.
                  </Text>
                  <Button
                    text="Open app on computer"
                    style={{ marginTop: 15 }}
                    outline
                    color="#7B62A0"
                    onPress={openAppInBrowser}
                  />
                </>
              )}
            </>
          )}
        </ScrollView>
        {!!accountLinkURL && (
          <BottomSheetModal
            onClose={() => setAccountLinkURL(null)}
            url={accountLinkURL}
          />
        )}
      </View>
    );

  return (
    <View style={styles.pageContent}>
      <NavBar navigation={navigation} text="Onespot Payments" />
      <ScrollView style={{ paddingHorizontal: 20, width }}>
        <Image
          source={Glob.get("commerceComputer")}
          style={{
            height: height * 0.3,
            width: height * 0.3,
            resizeMode: "contain",
            alignSelf: "center"
          }}
        />
        <Text
          style={{
            fontWeight: "bold",
            marginVertical: 15,
            fontSize: 24,
            textAlign: "center"
          }}
          scrollIndicatorInsets={{ right: 1 }}
        >
          Your account is set up 👍
        </Text>
        <Text style={styles.bodyText}>
          View your customers, orders, payouts, and more in your Stripe
          dashboard.
        </Text>
        <Button
          text="Stripe Dashboard"
          style={{ marginTop: 15 }}
          color="#3f5bb4"
          onPress={() => {
            navigation.push("webNav", {
              title: "Stripe Dashboard",
              url: STRIPE_DASHBOARD_URL
            });
          }}
        />
        <View
          style={{
            alignItems: "center",
            backgroundColor: "#e8ecfa",
            marginTop: 40,
            padding: 20,
            borderRadius: 20
          }}
        >
          <View>
            <Text
              style={{ fontWeight: "bold", fontSize: 24 }}
              scrollIndicatorInsets={{ right: 1 }}
            >
              Account Info
            </Text>
            <Text>
              <Text style={{ fontWeight: "bold" }}>Name: </Text>
              {stripeAccount?.business_profile?.name}
            </Text>
            <Text>
              <Text style={{ fontWeight: "bold" }}>Email: </Text>
              {stripeAccount?.email}
            </Text>
            <Text>
              <Text style={{ fontWeight: "bold" }}>Location: </Text>
              {stripeAccount?.business_profile?.support_address?.city},{" "}
              {stripeAccount?.business_profile?.support_address?.state}
            </Text>
          </View>
          <Button
            text="Edit Configuration"
            outline
            flat
            small
            color="#3f5bb4"
            onPress={configureStripeConnect}
          />
        </View>
        <View
          style={{
            alignItems: "center",
            backgroundColor: "#e8ecfa",
            marginTop: 10,
            marginBottom: 60,
            padding: 20,
            borderRadius: 20
          }}
        >
          <View>
            <Text
              style={{ fontWeight: "bold", fontSize: 24 }}
              scrollIndicatorInsets={{ right: 1 }}
            >
              Transaction Fees
            </Text>
            <Text style={{ fontWeight: "bold", marginTop: 5 }}>
              Onespot Fee:{" "}
            </Text>
            <Text>
              • All Payments:{" "}
              {`${parseFloat(
                ((onespotFees?.overallPercent || 0) * 100).toFixed(2)
              )}%`}
            </Text>
            <Text style={{ fontWeight: "bold", marginTop: 5 }}>
              Stripe Fees:{" "}
            </Text>
            <Text>• Cards: 2.9% + 30¢</Text>
            <Text>• ACH: 0.8% (max $5)</Text>
          </View>
          <Button
            text="Detailed Stripe Fees"
            outline
            flat
            small
            color="#3f5bb4"
            onPress={() => {
              navigation.push("webNav", {
                title: "Stripe Fees",
                url: STRIPE_FEES_URL
              });
            }}
          />
        </View>
      </ScrollView>
      {!!accountLinkURL && (
        <BottomSheetModal
          onClose={() => setAccountLinkURL(null)}
          url={accountLinkURL}
        />
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  pageContent: {
    flex: 1,
    alignItems: "center",
    backgroundColor: "white"
  },
  bodyText: {
    fontSize: 16,
    marginTop: 15
  }
});
