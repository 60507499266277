import React, { useState, useEffect } from "react";
import {
  View,
  TouchableOpacity,
  StyleSheet,
  Platform,
  FlatList
} from "react-native";
import Icon from "src/components/Icon";
import SearchBar from "src/components/searchBar";
import Glob from "src/globalConstants";
import Rex from "src/globalState";
import Util from "src/utility";
import IconCollection from "resources/icons/Icons";

const { height, width } = Glob.get("dimensions");

export default function IconPicker({
  icon,
  onSelectIcon = () => {},
  onTogglePicker = () => {}
}) {
  const [showPicker, setShowPicker] = useState(false);
  const [filteredIcons, setFilteredIcons] = useState(IconCollection);
  const [textToSearch, setTextToSearch] = useState(null);

  const onSearch = (text) => {
    setTextToSearch(text);
  };

  useEffect(() => {
    if (textToSearch !== null) {
      const searchResults = Util.searchItems(
        IconCollection,
        textToSearch,
        "iconName"
      );
      setFilteredIcons(searchResults);
      setTextToSearch(null);
    }
  }, [textToSearch]);

  return (
    <View
      style={[
        styles.container,
        {
          borderWidth: showPicker ? 1 : 0,
          alignItems: showPicker ? "center" : "flex-start"
        }
      ]}
    >
      <TouchableOpacity
        style={[
          styles.icon,
          Platform.OS === "web"
            ? { boxShadow: "0px 0px 7px rgba(0,0,0,0.2)" }
            : {}
        ]}
        onPress={() => {
          const shouldShowPicker = !showPicker;
          onTogglePicker(shouldShowPicker);
          setShowPicker(shouldShowPicker);
        }}
      >
        <Icon
          icon={icon}
          color={Rex.getConfig()?.colors?.background}
          size={38}
        />
      </TouchableOpacity>
      {showPicker && (
        <View style={styles.searchSectionContainer}>
          <SearchBar
            placeholder="Search"
            onChangeText={onSearch}
            isSearching={!!textToSearch}
          />
          <FlatList
            data={filteredIcons}
            nestedScrollEnabled
            renderItem={({ item }) => (
              <TouchableOpacity
                style={styles.iconContainer}
                onPress={() => onSelectIcon(item.uuid)}
              >
                <Icon
                  icon={item.uuid}
                  color={Rex.getConfig()?.colors?.background}
                  size={20}
                />
              </TouchableOpacity>
            )}
            keyExtractor={(item) => item.uuid}
            contentContainerStyle={{ alignItems: "center" }}
            numColumns={Math.floor(width / 70)}
            scrollIndicatorInsets={{ right: 1 }}
          />
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    borderColor: "lightgray",
    borderWidth: 1,
    borderRadius: 10
  },
  icon: {
    backgroundColor: "white",
    padding: 10,
    borderRadius: 80,
    width: 80,
    height: 80,
    justifyContent: "center",
    alignItems: "center",
    marginTop: 5,
    marginBottom: 5,
    marginHorizontal: 20,
    opacity: 1,
    shadowOpacity: 0.2,
    shadowRadius: 7,
    shadowOffset: { width: 0, height: 0 },
    elevation: 5
  },
  searchSectionContainer: {
    width: width * 0.9,
    height: 0.33 * height
  },
  iconContainer: {
    width: 50,
    height: 50,
    borderRadius: 50,
    margin: 5,
    justifyContent: "center",
    marginTop: 10,
    alignItems: "center"
  }
});
