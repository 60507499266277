import React, { useState, useEffect } from "react";
import { StyleSheet, Text, View, ScrollView } from "react-native";
import Style from "src/globalStyles";
import Glob from "src/globalConstants";
import Database from "src/backend/database";
import Analytics from "src/backend/analytics";
import NavBar from "src/components/navBar";
import Multiselect from "src/components/Multiselect";
import Button from "src/components/Button";

const { width } = Glob.get("dimensions");

function EditUserGroups({ route, navigation }) {
  const { params: { isEntryScreen = false } = {} } = route || {};

  const [allGroups, setAllGroups] = useState(null);
  const [activeGroups, setActiveGroups] = useState(null);
  const [allGroupIDsInOrder, setAllGroupIDsInOrder] = useState(null);

  useEffect(() => {
    Analytics.logEvent("view_editUserGroups");

    // Block backwards navigation (important for Android hardware back button)
    if (isEntryScreen)
      navigation.addListener("beforeRemove", (e) => e.preventDefault());

    Database.getAllUserGroupsDisplayOrder().then((displayOrder) => {
      setAllGroupIDsInOrder(displayOrder || []);
    });
    Database.getAllUserGroups().then((groups) => {
      // if the user has just signed up but there are no groups configured, skip this screen
      if (isEntryScreen && !groups) {
        navigation.push("root");
      } else setAllGroups(groups || []);
    });
    Database.getActiveUserGroups().then((groups) =>
      setActiveGroups(groups || [])
    );
  }, []);

  const onToggleGroup = (groupId, active) => {
    const newActiveGroups = [...activeGroups];
    if (active) newActiveGroups.push(groupId);
    else newActiveGroups.splice(activeGroups.indexOf(groupId), 1);
    setActiveGroups(newActiveGroups);
    Database.setUserGroups(newActiveGroups);
  };

  if (!allGroups || !activeGroups || !allGroupIDsInOrder) return null;

  const allCheckboxes = allGroupIDsInOrder.map((groupId) => ({
    key: groupId,
    value: activeGroups.indexOf(groupId) > -1,
    text: allGroups[groupId].name
  }));

  return (
    <View style={styles.pageContent}>
      <NavBar
        navigation={navigation}
        text="Your Groups"
        cannotNavigateBack={isEntryScreen}
      />
      <View style={{ paddingHorizontal: 15, flex: 5, width }}>
        <Text style={{ ...Style.get("headerText"), marginTop: 15 }}>
          Select which groups you're in{isEntryScreen && "!"}
        </Text>
        <Text style={{ ...Style.get("subheaderText"), marginBottom: 15 }}>
          Select all groups you identify with. Among other things, this will
          affect which notifications you receive.{" "}
          {isEntryScreen && "You can always change this later in Settings."}
        </Text>
        <ScrollView scrollIndicatorInsets={{ right: 1 }}>
          <Multiselect
            items={allCheckboxes}
            onToggle={(key, value) => onToggleGroup(key, value)}
          />
        </ScrollView>
      </View>
      {isEntryScreen && (
        <View style={{ flex: 1 }}>
          <Button text="Begin!" onPress={() => navigation.push("root")} />
        </View>
      )}
    </View>
  );
}

export default EditUserGroups;

const styles = StyleSheet.create({
  list: {
    flex: 1
  },
  pageContent: {
    flex: 1,
    alignItems: "center",
    backgroundColor: "white"
  }
});
