import React, { useState, useEffect } from "react";
import { Text, View, ScrollView, TouchableOpacity } from "react-native";
import {
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword
} from "firebase/auth/react-native";
import * as WebBrowser from "expo-web-browser";
import indefinite from "indefinite";
import Constants from "expo-constants";
import School from "school/school";
import Glob from "src/globalConstants";
import Rex from "src/globalState";
import Util from "src/utility";
import Firebase from "src/backend/firebase";
import Database from "src/backend/database";
import Analytics from "src/backend/analytics";
import NavBar from "src/components/navBar";
import AlertModal from "src/components/AlertModal";
import AuthWidget from "src/components/auth/AuthWidget";
import PrivacyAndTerms from "src/components/PrivacyAndTerms";

// SITUATIONS & HOW THEY ARE HANDLED (Feb 2023):
// When signing up with an email account:
// 1. User has an email account + a node in this db: Log them into that node
// 2. User has an email account + no node in this db: Log them in and then create a new node
// 3. User has no account: Create a new account & new node in the db
// 4. User is already signed in and is just joining a new spot: Create a new node in the db

const { width } = Glob.get("dimensions");
const DUMMY_PASSWORD = "DUMMYPASSWORD";

WebBrowser.maybeCompleteAuthSession();

export default function Signup({ route, navigation }) {
  const { params: { fullName, accountType, userInvitation } = {} } =
    route || {};
  const {
    privileges: invitationPrivileges,
    groups: invitationGroups,
    notifications: invitationNotifications,
    internalAdminNote: invitationInternalAdminNote,
    invitedAtTimestamp,
    invitedByUser
  } = userInvitation || {};
  const loggedInEmail = Firebase.getUser()?.email;
  const userIsAlreadyLoggedIn = Rex.getLoginStatus() && !!loggedInEmail;
  const [customInputFields, setCustomInputFields] = useState({});
  const [customInputFieldsArray, setCustomInputFieldsArray] = useState([]);
  const [customInputFieldsValues, setCustomInputFieldsValues] = useState({});
  const [name, setName] = useState(fullName || "");
  const [year, setYear] = useState(userInvitation?.year || "");
  const [email, setEmail] = useState(
    userInvitation?.email || loggedInEmail || ""
  );
  const [password, setPassword] = useState(
    userIsAlreadyLoggedIn ? DUMMY_PASSWORD : ""
  );
  const [isLoading, setIsLoading] = useState(false);
  const [alert, setAlert] = useState(null);

  const accountTypeIcon = accountType?.icon || "person";
  let accountTypeTitle = accountType?.title || accountType?.key || "user";
  accountTypeTitle = indefinite(accountTypeTitle).toLowerCase();

  useEffect(() => {
    Analytics.logEvent("view_signup");
    Database.fetchUserAccountFields().then(({ fields, fieldsArray }) => {
      setCustomInputFields(fields || {});
      setCustomInputFieldsArray(fieldsArray || []);
      const newValues = {};
      fieldsArray.forEach((field) => {
        if (field.key) {
          const invitationValue = userInvitation && userInvitation[field.key];
          newValues[field.key] = invitationValue || null;
        }
      });
      setCustomInputFieldsValues(newValues);
    });
  }, []);

  const isClassYearRequired = () =>
    "year" in customInputFields &&
    (customInputFields.year?.required ||
      Rex.getUserType() === "student" ||
      Rex.getUserType() === "alum");

  const errorAlert = (title, message) => {
    setAlert({
      title,
      message,
      confirm: {
        title: "OK"
      }
    });
    setIsLoading(false);
  };

  // Set self as creator & schedule welcome notifications
  const setSelfAsAppCreator = async (
    firstName,
    lastName,
    userEmail,
    appName
  ) => {
    return new Promise(async (resolve) => {
      Analytics.logEvent("action_signup_setSelfAsAppCreator");
      Database.setSelfAsAppCreator();
      if (Glob.get("appIsOnespot")) {
        await Database.schedulePushNotification(
          {
            title: "Want any help?",
            body: `Hey ${firstName ||
              "there"}, congrats on starting to make an app for ${appName ||
              "your organization"}! 🎉
  
I'm Sean, one of the creators of Onespot. If you have any questions or want help with anything, reply to this message and I'd be happy to help.`,
            chatEnabled: true,
            audience: { users: [Firebase.getUserID()] }
          },
          new Date(Date.now() + 60 * 60 * 1000).toISOString(), // 3 minutes from now
          Glob.get("seanCannUserID")
        );
        await Database.schedulePushNotification(
          {
            title: "Here are some tutorials",
            body: `Hey ${firstName ||
              "there"}, check out these tutorials to help you get started with your app.`,
            url:
              "https://1spot.app/?app=-N9c3gfF3YFVaM2FIstS&screen=-NvgFZVHcO0oDDynYqWL",
            audience: { users: [Firebase.getUserID()] }
          },
          new Date(Date.now() + 24 * 60 * 60 * 1000).toISOString(), // 1 day from now
          Glob.get("seanCannUserID")
        );
        await Database.schedulePushNotification(
          {
            title: "Free onboarding session",
            body: `Good news! If you'd like some extra help getting started, you can schedule a complimentary onboarding session with one of our founders. Just click the link below to schedule a time that works for you.`,
            url: `https://calendly.com/seancann/onespot-app-onboarding-session?hide_event_type_details=1&hide_gdpr_banner=1&primary_color=5b59d6&name=${firstName} ${lastName}&email=${userEmail ||
              ""}&a1=${appName || ""}`,
            audience: { users: [Firebase.getUserID()] }
          },
          new Date(Date.now() + 3 * 24 * 60 * 60 * 1000).toISOString(), // 3 days from now
          Glob.get("seanCannUserID")
        );
        await Database.schedulePushNotification(
          {
            title: "Checking in",
            body: `Hey ${firstName ||
              "there"}, this is just an automated notification to check in. It's been 7 days since you started creating your app for ${appName ||
              "your organization"}! 🎉  Reply to this message if you'd like help with anything.`,
            chatEnabled: true,
            audience: { users: [Firebase.getUserID()] }
          },
          new Date(Date.now() + 7 * 24 * 60 * 60 * 1000).toISOString(), // 7 days from now
          Glob.get("seanCannUserID")
        );
        await Database.schedulePushNotification(
          {
            title: "14-day check-in",
            body: `You started making an app for ${appName ||
              "your organization"} 14 days ago! Time flies, huh? Let us know if you have any questions we can help with 🙂`,
            chatEnabled: true,
            audience: { users: [Firebase.getUserID()] }
          },
          new Date(Date.now() + 14 * 24 * 60 * 60 * 1000).toISOString(), // 14 days from now
          Glob.get("seanCannUserID")
        );
        await Database.schedulePushNotification(
          {
            title: "Happy 1-month anniversary",
            body: `Hey ${firstName ||
              "there"}! This is your last automated notification. You started making your Onespot app 30 days ago, so we just wanted to say congrats 🎉  We won't bother you anymore with automated notifications, but reach out any time if you have questions!`,
            chatEnabled: true,
            audience: { users: [Firebase.getUserID()] }
          },
          new Date(Date.now() + 30 * 24 * 60 * 60 * 1000).toISOString(), // 30 days from now
          Glob.get("seanCannUserID")
        );
      }
      return resolve();
    });
  };

  const signUp = async () => {
    Analytics.logEvent("touch_signup_signupButton", { email });
    if (!email || !password) return;
    setIsLoading(true);
    // If no owner has joined/claimed this app yet, mark it as claimed whenever the first person signs up
    if (Rex.getConfig()?.appIsUnclaimed) Database.markAppAsClaimed();
    try {
      if (!userIsAlreadyLoggedIn) {
        await createUserWithEmailAndPassword(
          Firebase.getAuth(),
          email,
          password
        ).catch((error) => {
          const errorCode = error.code;
          let errorMessage = error.message;
          if (errorCode === "auth/email-already-in-use") {
            Analytics.logEvent("action_signup_accountExistsAttemptingSignin", {
              email,
              errorCode
            });
            errorMessage =
              "Looks like you've created an account previously! 🙌  Trying to sign you in now...";
          }
          console.log(`${errorCode}: ${errorMessage}`);
          errorAlert(errorMessage);
        });
        let wrongPasswordError = false;
        await signInWithEmailAndPassword(
          Firebase.getAuth(),
          email,
          password
        ).catch((error) => {
          const errorCode = error.code;
          let errorMessage = error.message;
          if (errorCode === "auth/wrong-password") {
            Analytics.logEvent("error_signup_accountExistsPasswordFailed", {
              email,
              errorCode
            });
            errorMessage = `Sorry! You've created an account with "${email}" previously, but that was the wrong password for it 😕  Try a different password, or try going back to "Log in" and reseting your password.`;
            wrongPasswordError = true;
          }
          console.log(`${errorCode}: ${errorMessage}`);
          errorAlert(errorMessage);
        });
        if (wrongPasswordError) return;

        // Check if the user node already exists, because we don't want to overwrite it if so
        const user = await Database.fetchAllUserData();
        if (Firebase.getUser()?.email === user?.email) {
          // User already exists, so we can just log in
          Rex.setLoginStatus(true);
          Analytics.setUserID();
          const appMetadata = await Database.fetchAppMetadata();
          if (!appMetadata?.creator) {
            const wasBuiltOnThisDevice = await School.appWasBuiltOnThisDeviceAsync();
            if (wasBuiltOnThisDevice || Rex.getConfig()?.appIsUnclaimed) {
              try {
                await setSelfAsAppCreator(
                  user?.firstName,
                  user?.lastName,
                  user?.email,
                  appMetadata?.name
                );
              } catch {
                // Continue onward
                console.log("Error setting self as app creator");
              }
            }
          }
          if (invitationPrivileges) {
            Analytics.logEvent(
              "action_signup_invitationIncludesAdminPrivileges",
              {
                invitationPrivileges
              }
            );
            await Database.writeUserData({
              privileges: {
                ...(user.privileges || {}),
                ...invitationPrivileges
              }
            });
          }
          if (userInvitation?.invitationKey) {
            Analytics.logEvent("action_signup_redeemingUserInvitation", {
              invitationKey: userInvitation.invitationKey
            });
            await Database.redeemUserInvitation(userInvitation.invitationKey);
          } else {
            Analytics.logEvent("action_signup_noInvitationKey");
            Database.markUserInvitationAsUnused(user.email);
          }
          navigation.push("root");
          return;
        }
        // User doesn't yet exist, so we need to create them
      }

      await Database.setUserEmail(email);

      const names = Util.splitUserName(name);
      const userType = Rex.getUserType();

      Analytics.setUserID();
      Database.getDefaultPortalMetadata(userType, (portals) => {
        Rex.setLoginStatus(true);
        Database.setUserPortals(portals);
        School.appWasBuiltOnThisDeviceAsync().then((wasBuiltOnThisDevice) => {
          const isOwner =
            wasBuiltOnThisDevice || !!Rex.getConfig()?.appIsUnclaimed;
          let privileges = {};
          if (isOwner)
            Object.keys(Glob.get("allAdminPrivileges")).forEach((priv) => {
              if (priv !== "TextAndCall") privileges[priv] = true;
            });
          else if (invitationPrivileges) privileges = invitationPrivileges;
          const groups = invitationGroups || {};
          const notifications = invitationNotifications || {};
          const internalAdminNote = invitationInternalAdminNote || {};
          Analytics.setUserProperties({
            standaloneAppSlug: Constants.expoConfig.slug,
            appVersion: Glob.get("appVersionFull"),
            first_name: names[0],
            last_name: names[1],
            email,
            ...(year ? { year } : {}),
            type: userType,
            isOwner,
            ...customInputFieldsValues
          });
          Database.writeUserData({
            firstName: names[0],
            lastName: names[1],
            email,
            ...(year ? { year } : {}),
            type: userType,
            privileges,
            groups,
            notifications,
            internalAdminNote,
            createdAtTimestamp: new Date(),
            ...(invitedAtTimestamp ? { invitedAtTimestamp } : {}),
            ...(invitedByUser ? { invitedByUser } : {}),
            ...customInputFieldsValues
          }).then(async () => {
            Analytics.logEvent("action_signup_triggerWelcomeEmail", {
              email,
              isOwner
            });
            Database.triggerWelcomeEmail({
              organizationName: Rex.getConfig()?.names?.full,
              firstName: names[0],
              lastName: names[1],
              email,
              ...(year ? { year } : {}),
              type: userType,
              isOwner,
              createdAtTimestamp: new Date(),
              appDescription: Rex.getConfig()?.descriptionLong,
              ...customInputFieldsValues
            });
            if (isOwner) {
              try {
                await setSelfAsAppCreator(
                  names[0] || "",
                  names[1] || "",
                  email,
                  Rex.getConfig()?.names?.full
                );
              } catch {
                // Continue onward
                console.log("Error setting self as app creator");
              }
            }
            if (userInvitation?.invitationKey) {
              Analytics.logEvent("action_signup_redeemingUserInvitation", {
                invitationKey: userInvitation.invitationKey
              });
              Database.redeemUserInvitation(userInvitation.invitationKey);
            } else {
              Analytics.logEvent("action_signup_noInvitationKey");
              Database.markUserInvitationAsUnused(email);
            }
            navigation.push("editUserGroups", {
              isEntryScreen: true
            });
          });
        });
      });
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  };

  return (
    <View
      style={{
        flex: 1,
        height: "100%",
        alignItems: "center",
        backgroundColor: "white"
      }}
    >
      <NavBar
        navigation={navigation}
        text=""
        backgroundColor="white"
        buttonColor={Rex.getConfig()?.colors?.button}
      />
      <ScrollView
        contentContainerStyle={{ width, padding: 20 }}
        scrollIndicatorInsets={{ right: 1 }}
      >
        <AuthWidget
          navigation={navigation}
          title={userIsAlreadyLoggedIn ? "Get started" : "Create an account"}
          subtitle={`Join ${Rex.getConfig()?.names?.full ||
            ""} as ${accountTypeTitle}.`}
          buttonText={userIsAlreadyLoggedIn ? "Join this app" : "Sign up"}
          isLoading={isLoading}
          inputFieldOptions={{
            name,
            year,
            customInputFieldsValues,
            setName,
            setYear,
            setCustomInputFieldsValues,
            nameFieldIcon: accountTypeIcon,
            customFields: customInputFields,
            customFieldsArray: customInputFieldsArray,
            classYearRequired: isClassYearRequired()
          }}
          email={email}
          password={password}
          setEmail={setEmail}
          setPassword={setPassword}
          showLoggedInMessage={userIsAlreadyLoggedIn && !fullName}
          userIsAlreadyLoggedIn={userIsAlreadyLoggedIn}
          onEmailAuth={signUp}
          userInvitation={userInvitation}
        />

        <View
          style={{
            marginTop: 40,
            backgroundColor: "#eee",
            padding: 20,
            borderRadius: 10
          }}
        >
          <PrivacyAndTerms
            message="By joining this app, you agree to our"
            navigation={navigation}
            style={{
              fontSize: 12,
              textAlign: "center",
              color: "#868686"
            }}
          />
        </View>

        {!userIsAlreadyLoggedIn && (
          <TouchableOpacity
            activeOpacity={0.7}
            style={{ marginTop: 20, alignItems: "center" }}
            onPress={() => {
              Analytics.logEvent("touch_signup_alreadyHaveAccountLogIn");
              navigation.push("login");
            }}
          >
            <Text style={{ color: "#868686" }}>
              Already have an account?{" "}
              <Text
                style={{
                  color: Rex.getConfig()?.colors?.button,
                  fontWeight: "bold"
                }}
              >
                Log in
              </Text>
            </Text>
          </TouchableOpacity>
        )}
      </ScrollView>
      <AlertModal alert={alert} setAlert={setAlert} />
    </View>
  );
}
