import React, { useEffect } from "react";
import {
  StyleSheet,
  Text,
  View,
  ScrollView,
  TouchableHighlight,
  Share,
  Platform
} from "react-native";
import * as Calendar from "expo-calendar";
import { isMobile } from "react-device-detect";
import Glob from "src/globalConstants";
import Rex from "src/globalState";
import Util from "src/utility";
import NavBar from "src/components/navBar";
import Moment from "moment";
import Analytics from "src/backend/analytics";
import RenderHTML from "src/components/RenderHTML";
import WebPageMetaTitle from "src/components/WebPageMetaTitle";

const { height, width } = Glob.get("dimensions");

export default function Events({ route, navigation }) {
  const { params: { event } = {} } = route || {};

  useEffect(() => {
    Analytics.logEvent("view_eventDetail", {
      event_name: event.event,
      event_day: event.day,
      event_location: event.location,
      event_startTime: event.startTime,
      event_endTime: event.endTime,
      event_details: event.details
    });
  }, []);

  const onShare = async () => {
    await Share.share({
      message: `Event: ${event.event}
Time: ${`${Moment(event.startTime).format("h:mm a")} – ${Moment(
        event.endTime
      ).format("h:mm a")}`}
Day: ${Moment(event.day).format("dddd, MMM D")}
Place: ${event.location || "None specified"}`
    });
  };

  const getCalendarPermisisons = async (callbackFunc) => {
    const { status } = await Calendar.requestCalendarPermissionsAsync();
    if (status === "granted") {
      callbackFunc();
    } else {
      Util.alert(
        "To use this feature, please go to your device's settings and grant this app permission to use your Calendar."
      );
    }
  };

  // this handles when a user presses the button to save this event to their phone's calendar
  const pressSaveToCalendar = () => getCalendarPermisisons(buildEventToSave);

  // build the correctly structured event object to save to the phone's default calendar
  // todo: fix this (new calendar): https://docs.expo.io/versions/latest/sdk/calendar/#calendar
  const buildEventToSave = async () => {
    const eventToSave = {};
    const startDate = new Date(event.startTime);
    const endDate = new Date(event.endTime);

    eventToSave.title = event.event;
    eventToSave.startDate = startDate;
    eventToSave.endDate = endDate;
    eventToSave.location = event.location;
    eventToSave.notes = event.details;

    const defaultIOSCalendar = await Calendar.getDefaultCalendarAsync();
    if (defaultIOSCalendar && defaultIOSCalendar.id) {
      await Calendar.createEventAsync(defaultIOSCalendar.id, eventToSave);
    }
    Util.alert("Event saved to your phone's calendar!");
  };

  // for now, we just render null
  // TODO: fix the add-to-calendar functionality for Android (the only issue is Calendar.getDefaultCalendarAsync isn't supported)
  const renderAddToCalendarButton = () => {
    if (Platform.OS !== "ios") return null;
    return (
      <TouchableHighlight
        style={styles.touchableButton}
        underlayColor="#CCCCCC"
        onPress={pressSaveToCalendar}
      >
        <Text style={styles.addToCalendarText}>Add to Calendar</Text>
      </TouchableHighlight>
    );
  };

  const eventsDetailsComponent = (details) => {
    // If this is probably html
    if (details.includes("<") && details.includes(">")) {
      const cleanDetails = details
        .replace("<html-blob>", "")
        .replace("</html-blob>", "");
      return <RenderHTML code={cleanDetails} />;
    }
    return <Text style={styles.eventDetails}>{details}</Text>;
  };

  return (
    <View style={styles.pageContent}>
      <WebPageMetaTitle title={event.event} />
      <NavBar
        navigation={navigation}
        text="Event"
        screenType={Platform.OS === "web" && !isMobile ? null : "share"}
        onShare={onShare}
      />
      <View style={styles.mainContent}>
        <View style={styles.contentSection}>
          <View style={styles.firstSection}>
            <Text style={styles.eventTitle}>{event.event}</Text>
            <Text style={styles.eventLocation}>{event.location}</Text>
          </View>
          <View style={styles.secondSection}>
            <View style={styles.subLeftSection}>
              <View
                style={{
                  height: 0.16 * width,
                  width: 0.16 * width,
                  backgroundColor: Rex.getConfig()?.colors?.button,
                  opacity: 0.5,
                  borderRadius: 0.16 * 0.5 * width,
                  flexDirection: "column",
                  justifyContent: "center",
                  marginLeft: 0.013 * width,
                  marginRight: 0.04 * width
                }}
              >
                <Text
                  style={{
                    fontSize: 0.065 * width,
                    fontWeight: "bold",
                    textAlign: "center",
                    color: "white",
                    alignSelf: "center"
                  }}
                  adjustsFontSizeToFit
                >
                  {Moment(event.day).format("DD")}
                </Text>
              </View>
            </View>
            <View style={styles.subRightSection}>
              <Text style={styles.eventDate}>
                {Moment(event.day).format("MMM D")}
              </Text>
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  width: 0.667 * width
                }}
              >
                <View style={{ flex: 1 }}>
                  <Text style={styles.eventDay}>
                    {Moment(event.day).format("dddd")}
                  </Text>
                </View>
                <View style={{ flex: 1 }}>
                  <Text style={styles.eventHour}>
                    {event.durationInDays > 0
                      ? event.timeText
                      : `${Moment(event.startTime).format("h:mm a")} – ${Moment(
                          event.endTime
                        ).format("h:mm a")}`}
                  </Text>
                </View>
              </View>
            </View>
          </View>
          <View style={styles.thirdSection}>{renderAddToCalendarButton()}</View>
          {!!event.details && (
            <ScrollView
              style={styles.fourthSection}
              scrollIndicatorInsets={{ right: 1 }}
            >
              <Text style={styles.eventLocation}>Details:</Text>
              <View style={styles.eventDetailsContainer}>
                {eventsDetailsComponent(event.details)}
              </View>
            </ScrollView>
          )}
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  pageContent: {
    flex: 1,
    backgroundColor: "white",
    alignItems: "center",
    width: "100%"
  },

  /* Style for the main section that will hold all the of the content */
  mainContent: {
    backgroundColor: Glob.get("extremely light gray"),
    justifyContent: "flex-start",
    alignItems: "center",
    flexDirection: "column",
    width: "100%",
    flex: 1
  },

  /* Style for the section that will hold the content */
  contentSection: {
    backgroundColor: "white",
    width: 0.971 * width,
    height: 0.833 * height,
    paddingTop: 0.015 * height,
    paddingLeft: 0.027 * width,
    paddingRight: 0.027 * width,
    paddingBottom: 0.015 * width,
    borderRadius: 0.004 * height,
    marginTop: 0.007 * height
  },

  /* Style for the first section in the main section */
  firstSection: {
    borderBottomWidth: 1,
    borderBottomColor: "#ddd",
    width: 0.93 * width,
    alignSelf: "center",
    paddingBottom: 0.02 * width
  },

  /* Style for the second section in the main section */
  secondSection: {
    flexDirection: "row",
    width: 0.93 * width
  },

  /* Style for the sub section to the left */
  subLeftSection: {
    marginTop: 0.022 * height,
    marginBottom: 0.022 * height,
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center"
  },

  /* Style for the sub section to the right */
  subRightSection: {
    marginTop: 0.022 * height,
    marginBottom: 0.022 * height,
    flexDirection: "column",
    justifyContent: "flex-start"
  },

  /* Style for the second section in the main section */
  thirdSection: {
    flexDirection: "column",
    alignItems: "center",
    width: 0.93 * width
  },

  /* Style for the third section in the main section */
  fourthSection: {
    marginTop: 0.03 * height,
    flexDirection: "column",
    height: 0.375 * height,
    width: 0.909 * width
  },

  /* Style for the school title text */
  schoolTitle: {
    fontSize: 0.035 * height,
    fontFamily: "System",
    fontWeight: "300",
    color: "black",
    letterSpacing: -0.002 * width
  },

  /* Style for the event's date */
  eventDate: {
    fontSize: 14,
    fontWeight: "400",
    color: Glob.get("gray")
  },

  /* Style for the event's day */
  eventDay: {
    fontSize: 18,
    fontWeight: "400",
    color: Glob.get("gray")
  },

  /* Style for the event's time */
  eventHour: {
    fontSize: 14,
    fontWeight: "bold",
    color: Glob.get("gray"),
    textAlign: "right"
  },

  /* Style for the event's title */
  eventTitle: {
    fontSize: 0.027 * height,
    fontWeight: "400",
    color: Glob.get("gray"),
    marginLeft: 0.027 * width,
    marginRight: 0.02 * width,
    marginBottom: 0.02 * width,
    width: 0.833 * width
  },

  /* Style for the event's location */
  eventLocation: {
    fontSize: 0.021 * height,
    fontStyle: "italic",
    fontWeight: "400",
    color: "rgba(93, 93, 92, 0.6)",
    marginLeft: 0.027 * width,
    width: 0.588 * width
  },

  eventDetailsContainer: {
    marginTop: 0.03 * height,
    marginLeft: 0.027 * width,
    marginRight: 0.02 * width
  },

  /* Style for the event's details */
  eventDetails: {
    fontSize: 0.024 * height,
    fontWeight: "400",
    color: Glob.get("gray")
  },

  /* Style for the TouchableHighlight serving as the entire button */
  touchableButton: {
    alignItems: "center",
    justifyContent: "center",
    paddingRight: 20,
    paddingLeft: 20,
    paddingTop: 5,
    paddingBottom: 5,
    backgroundColor: Glob.get("light gray"),
    height: 0.06 * height,
    borderRadius: 0.025 * height
  },

  /* Style for 'Add to Calendar' */
  addToCalendarText: {
    fontSize: 0.025 * height,
    fontWeight: "400",
    color: Glob.get("gray")
  }
});
