import React, { useState, useEffect } from "react";
import {
  Text,
  TouchableOpacity,
  View,
  ActivityIndicator,
  Platform
} from "react-native";
import Style from "src/globalStyles";
import Button from "src/components/Button";
import HelpText from "src/components/HelpText";
import Product from "src/components/Product";
import Dropdown from "src/components/Dropdown";
import Database from "src/backend/database";
import Rex from "src/globalState";

export default function ProductItem({
  item,
  editable,
  isEditingAnItem,
  setIsEditingAnItem,
  onChangeItem,
  // dataSourceRow, // todo: handle this
  onStartCheckoutSession,
  productDropdownItems,
  allProducts
}) {
  const [isEditing, setIsEditing] = useState(false);
  const [product, setProduct] = useState(null);
  const [isLoadingPurchaseButton, setIsLoadingPurchaseButton] = useState(false);

  const isEditingADifferentItem = isEditingAnItem && !isEditing;
  const userHasCommercePrivilege = Rex.getSessionMemory(
    "adminPrivileges"
  )?.includes("Commerce");

  useEffect(() => {
    if (item.justCreated) {
      toggleEditing();
      const newItem = { ...item };
      delete newItem.justCreated;
      onChangeItem(newItem);
    } else if (item.productID) fetchProduct(item.productID);
  }, []);

  useEffect(() => {
    fetchProduct(item.productID);
  }, [allProducts]);

  const fetchProduct = async (productID) => {
    const stripeProduct = allProducts.find((p) => p.id === productID);
    setProduct({
      name: stripeProduct.name,
      description: stripeProduct.description,
      shippable: stripeProduct.shippable,
      image: stripeProduct.images?.[0] || null,
      price: stripeProduct.default_price?.unit_amount || 0,
      customQuestions: stripeProduct.metadata?.customQuestions
        ? JSON.parse(stripeProduct.metadata.customQuestions)
        : []
    });
  };

  const toggleEditing = () => {
    setIsEditingAnItem(!isEditing);
    setIsEditing(!isEditing);
  };

  const onSelectProduct = (productID) => {
    onChangeItem({ ...item, productID });
    fetchProduct(productID);
  };

  const allProductIDs = productDropdownItems.map((p) => p.value);

  const editingStyle = {
    width: "97%",
    alignSelf: "center",
    shadowOpacity: 1,
    shadowOffset: { width: 0, height: 0 },
    shadowRadius: 200,
    elevation: 20,
    backgroundColor: "white",
    borderRadius: 5,
    padding: 20,
    paddingBottom: 10,
    marginBottom: 10
  };
  if (Platform.OS === "web")
    editingStyle.boxShadow = "0px 0px 200px rgba(0,0,0,1)";

  if (isEditing)
    return (
      <View style={editingStyle}>
        <Text style={{ ...Style.get("headerText"), marginTop: 15 }}>
          Product
        </Text>
        {productDropdownItems?.length > 0 ? (
          <Dropdown
            enableAutocomplete
            placeholder="Select a product..."
            value={
              allProductIDs.includes(item.productID) ? item.productID : null
            }
            items={productDropdownItems}
            onSelect={onSelectProduct}
          />
        ) : (
          <ActivityIndicator
            size="large"
            style={{ alignSelf: "flex-start", marginTop: 10, marginLeft: 10 }}
          />
        )}
        {userHasCommercePrivilege && (
          <HelpText
            text="You can add and edit products within the Onespot Payments section of the app."
            noteStyle
            style={{ marginTop: 20 }}
          />
        )}
        <Button
          text="✓ Done"
          onPress={toggleEditing}
          small
          style={{
            backgroundColor: "#555",
            marginTop: 30
          }}
        />
      </View>
    );
  if (!editable || isEditingADifferentItem)
    return (
      <Product
        key={item.productID}
        productID={item.productID}
        name={product?.name}
        price={product?.price}
        description={product?.description}
        shippable={product?.shippable}
        image={product?.image}
        customQuestions={product?.customQuestions}
        // If editing a different item, don't display the "Purchase" button
        onPressPurchase={
          isEditingADifferentItem
            ? null
            : async (questionResponses) => {
                setIsLoadingPurchaseButton(true);
                const response = await Database.stripeConnectedAccountCreateCheckoutSession(
                  item.productID,
                  questionResponses
                );
                if (response?.success) {
                  onStartCheckoutSession(response.checkoutSessionURL);
                }
                setIsLoadingPurchaseButton(false);
              }
        }
        isLoadingProduct={!product}
        isLoadingPurchaseButton={isLoadingPurchaseButton}
      />
    );
  return (
    <TouchableOpacity
      key={item.key}
      activeOpacity={0.7}
      onPress={toggleEditing}
    >
      <Product
        key={item.productID}
        productID={item.productID}
        name={product?.name}
        price={product?.price}
        description={product?.description}
        shippable={product?.shippable}
        image={product?.image}
        isLoadingProduct={!product}
        shrinkText
      />
    </TouchableOpacity>
  );
}
