import React, { useRef, useState, useEffect } from "react";
import {
  StyleSheet,
  Text,
  View,
  ScrollView,
  ActivityIndicator,
  TouchableOpacity,
  Platform,
  TextInput,
  KeyboardAvoidingView
} from "react-native";
import { Chat, defaultTheme } from "@flyerhq/react-native-chat-ui";
import tinycolor from "tinycolor2";
import { Ionicons, FontAwesome, MaterialIcons } from "@expo/vector-icons";
import * as Haptics from "expo-haptics";
import Moment from "moment";
import Glob from "src/globalConstants";
import Rex from "src/globalState";
import Style from "src/globalStyles";
import Database from "src/backend/database";
import Firebase from "src/backend/firebase";
import Analytics from "src/backend/analytics";
import NavBar from "src/components/navBar";
import Button from "src/components/Button";
import Util from "src/utility";

const ICON_SIZE = Glob.deviceHasTabletDimensions() ? 42 : 26;

const { height, width } = Glob.get("dimensions");

// For the testing purposes, you should probably use https://github.com/uuidjs/uuid
const uuidv4 = () => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
    const r = Math.floor(Math.random() * 16);
    const v = c === "x" ? r : (r % 4) + 8;
    return v.toString(16);
  });
};

export default function NotificationDetails({ route, navigation }) {
  const webChatInputRef = useRef(null);
  const { notification, chatRecipient } = route?.params || {};
  const [details, setDetails] = useState(null);
  const [chatID, setChatID] = useState(notification?.chat);
  const [showingThread, setShowingThread] = useState(false);
  const [chatMembers, setChatMembers] = useState(null);
  const [otherChatMember, setOtherChatMember] = useState(null);
  const [chatUserNotificationKey, setChatUserNotificationKey] = useState(null);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState(null);
  const [
    notificationContainerIsExpanded,
    setNotificationContainerIsExpanded
  ] = useState(false);
  const [portal, setPortal] = useState(null);

  const userID = Firebase.getUserID();
  const chatUser = { id: userID };
  const adminPrivileges = Rex.getSessionMemory("adminPrivileges");
  const allowedToManageUsers =
    !!adminPrivileges && adminPrivileges?.includes("ManageUsers");

  let messageColor = Rex.getConfig()?.colors?.primary || "#565666";
  if (tinycolor(messageColor).isLight())
    messageColor = Rex.getConfig()?.colors?.button || "#565666";
  if (tinycolor(messageColor).isLight()) messageColor = "#565666";

  const navigateToPortal = () => {
    if (portal) {
      if (portal.portalType === "native") {
        // e.g. "food"
        navigation.push(portal, {
          navName: portal.portalID,
          txtName: portal.txtName,
          icon: portal.icon,
          portalType: portal.portalType
        });
      } else {
        // e.g. "webNav"
        navigation.push(portal.portalType, {
          navName: portal.portalID,
          txtName: portal.txtName,
          icon: portal.icon,
          portalType: portal.portalType
        });
      }
    }
  };

  useEffect(() => {
    if (notification?.key) {
      Database.markUserNotificationAsOpened(notification.key);
    }
    // If this has a chat, then fetch the original notification this chat is based on
    if (chatID) {
      Database.subscribeToChat(chatID, onChatChange);
      Analytics.logEvent("view_chat", { chatID });
    } else if (notification) {
      Database.fetchNotificationDetails(
        notification.notificationDetails || notification.key
      ).then((notificationDetails) => {
        if (notificationDetails) {
          const formattedNotificationForAnalytics = {
            ...notificationDetails,
            audience: JSON.stringify(notificationDetails.audience)
          };
          Analytics.logEvent(
            "view_notificationDetails",
            formattedNotificationForAnalytics
          );
          setDetails(notificationDetails);
        }
        // If there are no notification details, then this is a personalized notification
        else {
          setDetails(notification);
        }
      });
    }
    // If we're trying to create a message to someone
    else if (chatRecipient) {
      // First check if we already have a chat with them
      const self = userID;
      const other = chatRecipient.uid;
      const chatKey = Database.membersToDirectChatID(self, other);
      Database.checkIfChatExistsByID(chatKey).then((exists) => {
        if (exists) {
          Analytics.logEvent("view_chat", { chatID: chatKey });
          Database.subscribeToChat(chatKey, onChatChange);
          setChatID(chatKey);
        }
        Analytics.logEvent("view_chat", { newChat: true });
        setShowingThread(true);
      });
    } else {
      Analytics.logEvent("view_chat", { newChat: true });
      setShowingThread(true);
    }

    // cleanup called when component is unmounting
    return () => {
      if (chatID) Database.unsubscribeFromChat(chatID);
      if (notification?.key)
        Database.markUserNotificationAsOpened(notification.key);
    };
  }, []);

  useEffect(() => {
    if (allowedToManageUsers && chatMembers) {
      const otherMembers = chatMembers.filter((m) => m !== userID);
      if (otherMembers?.length === 1) {
        const otherUserID = otherMembers[0];
        Database.fetchUserByID(otherUserID).then((otherUser) => {
          setOtherChatMember({ ...otherUser, uid: otherUserID });
        });
      }
    }
  }, [chatMembers]);

  useEffect(() => {
    if (details?.portalID) {
      Database.getPortalMetadata(details.portalID, (metadata) => {
        if (metadata) setPortal({ ...metadata, portalID: details.portalID });
      });
    }
  }, [details]);

  const onChatChange = (fetchedChat) => {
    setChatMembers(Object.keys(fetchedChat?.members));
    setChatUserNotificationKey(fetchedChat?.userNotificationKey);
    if (Platform.OS !== "web")
      Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Light);
    if (fetchedChat.thread) {
      const { thread } = fetchedChat;
      setShowingThread(true);
      const currentThread = Object.keys(thread)
        .sort((a1, a2) => {
          if (a1 < a2) return 1;
          if (a1 > a2) return -1;
          return 0;
        })
        .map((k) => ({
          author: { id: thread[k].sender },
          createdAt: new Date(parseInt(k, 10)),
          id: uuidv4(),
          text: thread[k].message,
          type: "text"
        }));
      setMessages(currentThread);
    }
    if (fetchedChat.notification) {
      Database.fetchNotificationDetails(fetchedChat.notification).then(
        (notificationDetails) => {
          const formattedNotificationForAnalytics = {
            ...notificationDetails,
            audience: JSON.stringify(notificationDetails.audience)
          };
          Analytics.logEvent(
            "view_notificationDetails",
            formattedNotificationForAnalytics
          );
          setDetails(notificationDetails);
        }
      );
    }
  };

  const handleChatPreviewDataFetched = ({ message, previewData }) => {
    setMessages(
      messages.map((m) => (m.id === message.id ? { ...m, previewData } : m))
    );
  };

  const addMessage = (message) => {
    setMessages([message, ...messages]);
  };

  const onSendChatMessage = (message) => {
    const textMessage = {
      author: chatUser,
      createdAt: Date.now(),
      id: uuidv4(),
      text: message.text,
      type: "text"
    };
    let chatTitle = notification?.title || null;
    if (chatRecipient)
      chatTitle = `${chatRecipient.firstName} ${chatRecipient.lastName}`;
    if (chatID) {
      Analytics.logEvent("touch_chat_sendMessage", { chatID });
      if (notification?.key) {
        Database.replyToChatThread(
          chatID,
          message.text,
          notification.key,
          chatTitle
        ).then(() => {});
      } else if (chatUserNotificationKey) {
        Database.replyToChatThread(
          chatID,
          message.text,
          chatUserNotificationKey,
          chatTitle
        ).then(() => {});
      } else {
        Database.fetchUserNotificationByChat(chatID).then((notificationKey) => {
          if (notificationKey) {
            Database.replyToChatThread(
              chatID,
              message.text,
              notificationKey,
              chatTitle
            ).then(() => {});
          } else {
            console.log("error");
          }
        });
      }
    } else {
      Analytics.logEvent("touch_chat_sendMessage", { newChat: true });
      Database.createNewChat(
        [userID, details?.sender || chatRecipient?.uid],
        message.text,
        notification,
        chatTitle,
        true
      ).then((createdChatID) => {
        Analytics.logEvent("touch_chat_createdNewChat", {
          chatID: createdChatID
        });
        setChatID(createdChatID);
        Database.subscribeToChat(createdChatID, onChatChange);
      });
    }
    addMessage(textMessage);
  };

  const navigateToLink = (title, url) => {
    Analytics.logEvent("touch_notificationDetails_openedLink", { title, url });
    navigation.push("webNav", { title, url });
  };

  const renderChatMessageBubble = ({ child, message, nextMessageInGroup }) => {
    const [showTime, setShowTime] = useState(false);
    const userIsAuthor = userID === message.author.id;
    return (
      <>
        {showTime && (
          <Text
            style={{
              marginTop: 10,
              color: "gray",
              textAlign: userIsAuthor ? "right" : "left"
            }}
          >
            Sent at {Moment(message.createdAt).format("h:mm:ss A")}
          </Text>
        )}
        <TouchableOpacity
          onPress={() => setShowTime(!showTime)}
          style={{
            backgroundColor: userIsAuthor ? messageColor : "#f5f5f7",
            borderRadius: 20,
            borderBottomLeftRadius:
              !nextMessageInGroup && !userIsAuthor ? 0 : 20,
            borderBottomRightRadius:
              !nextMessageInGroup && userIsAuthor ? 0 : 20,
            overflow: "hidden"
          }}
        >
          {child}
        </TouchableOpacity>
      </>
    );
  };

  let navBarTitle = notification?.title || "Message";
  if (chatRecipient)
    navBarTitle = `${chatRecipient.firstName} ${chatRecipient.lastName}`;
  else if (!notification?.title && otherChatMember) {
    // If there's no title, but user is admin, we can set the title to be the other user's name
    // An example where there might be no title is if user had deleted the thread, then the person chatted again.
    navBarTitle = `${otherChatMember.firstName} ${otherChatMember.lastName}`;
  }

  const chatIsWithSelf =
    (chatMembers?.length === 1 && chatMembers[0] === userID) ||
    chatRecipient?.uid === userID ||
    (!chatMembers && details?.sender === userID);

  let RightButton = null;
  if (allowedToManageUsers && otherChatMember) {
    RightButton = (
      <TouchableOpacity
        style={{
          width: "100%",
          height: "100%",
          alignItems: "flex-end",
          justifyContent: "center",
          paddingRight: 10
        }}
        activeOpacity={0.6}
        onPress={() => {
          navigation.push("manageUser", {
            user: otherChatMember,
            isFromChat: true
          });
        }}
      >
        <Ionicons name="person" size={ICON_SIZE} color="white" />
      </TouchableOpacity>
    );
  }

  return (
    <KeyboardAvoidingView
      style={styles.pageContent}
      behavior={Platform.OS === "ios" ? "padding" : "height"}
    >
      <NavBar
        navigation={navigation}
        text={navBarTitle}
        RightButton={RightButton}
      />
      {!Rex.getLoginStatus() ? (
        <View style={{ width, alignSelf: "center", padding: 20 }}>
          <Text style={Style.get("headerText")}>You're not signed in.</Text>
          <Text style={Style.get("subheaderText")}>
            To use this feature, please go back and log into this app (the
            button in the top left corner).
          </Text>
        </View>
      ) : (
        <>
          {!details && !showingThread && (
            <ActivityIndicator size="large" style={{ marginTop: 50 }} />
          )}
        </>
      )}
      {!!details && (
        <View
          style={[
            styles.notificationContainer,
            notificationContainerIsExpanded ? { flex: 6 } : {}
          ]}
        >
          <ScrollView
            persistentScrollbar={notificationContainerIsExpanded}
            style={{ width, padding: 20, paddingBottom: 3 }}
            scrollIndicatorInsets={{ right: 1 }}
          >
            <Text style={styles.notificationDate}>
              Sent {Util.friendlyDate(details.timestamp, { isLong: false })} at{" "}
              {Moment(details.timestamp).format("h:mma")}
            </Text>
            <Text style={styles.notificationTitle}>{details.title}</Text>
            <Text style={styles.notificationBody}>{details.body}</Text>
            {!!portal && (
              <Button text={`${portal.txtName}`} onPress={navigateToPortal} />
            )}
            {!!details.url && (
              <Button
                text="Open Link"
                onPress={() => navigateToLink(details.title, details.url)}
              />
            )}
            {!showingThread &&
              !!Rex.getConfig()?.chatEnabled &&
              !!details?.chatEnabled && (
                <Button text="Reply" onPress={() => setShowingThread(true)} />
              )}
          </ScrollView>
          {showingThread && (
            <TouchableOpacity
              activeOpacity={0.6}
              style={styles.notificationContainerExpandButton}
              onPress={() => {
                setNotificationContainerIsExpanded(
                  !notificationContainerIsExpanded
                );
              }}
            >
              <MaterialIcons
                name={`expand-${
                  notificationContainerIsExpanded ? "less" : "more"
                }`}
                size={24}
                color="black"
              />
            </TouchableOpacity>
          )}
        </View>
      )}
      {showingThread && (
        <View style={{ flex: 4.5, marginTop: 10, alignItems: "center" }}>
          <View
            style={{
              width,
              height: "100%",
              borderColor: "#eee",
              borderLeftWidth: 1,
              borderRightWidth: 1
            }}
          >
            <Chat
              sendButtonVisibilityMode="always"
              onPreviewDataFetched={handleChatPreviewDataFetched}
              timeFormat="h:mm A"
              theme={{
                ...defaultTheme,
                colors: {
                  ...defaultTheme.colors,
                  inputBackground: "#404040",
                  primary: messageColor
                }
              }}
              renderBubble={renderChatMessageBubble}
              textInputProps={{
                placeholder: chatIsWithSelf ? "Note to self" : "Message"
              }}
              messages={messages}
              onSendPress={onSendChatMessage}
              user={chatUser}
              customBottomComponent={() => {
                return (
                  <View style={styles.chatInputContainer}>
                    <TextInput
                      ref={webChatInputRef}
                      placeholder={chatIsWithSelf ? "Note to self" : "Message"}
                      style={[
                        styles.chatInput,
                        { marginRight: newMessage ? 0 : 20 }
                      ]}
                      value={newMessage}
                      onChangeText={setNewMessage}
                      selectionColor={messageColor}
                      multiline
                      numberOfLines={2}
                    />
                    {newMessage ? (
                      <TouchableOpacity
                        style={styles.chatSendButton}
                        activeOpacity={0.6}
                        onPress={() => {
                          onSendChatMessage({ text: newMessage });
                          setNewMessage(null);
                          webChatInputRef.current.clear();
                        }}
                      >
                        <FontAwesome
                          name="send"
                          size={ICON_SIZE}
                          color={Rex.getConfig()?.colors?.button}
                        />
                      </TouchableOpacity>
                    ) : null}
                  </View>
                );
              }}
            />
          </View>
        </View>
      )}
    </KeyboardAvoidingView>
  );
}

const notificationContainer = {
  flex: 1,
  alignItems: "center",
  backgroundColor: "white",
  shadowOpacity: 0.3,
  shadowRadius: 5,
  shadowOffset: { width: 0, height: 0 },
  elevation: 7
};
if (Platform.OS === "web")
  notificationContainer.boxShadow = "0px 0px 5px rgba(0,0,0,0.3)";
const styles = StyleSheet.create({
  pageContent: {
    backgroundColor: "white",
    height
  },
  notificationContainer,
  notificationTitle: {
    fontSize: 20,
    marginVertical: 10
  },
  notificationContainerExpandButton: {
    justifyContent: "center",
    alignItems: "center",
    opacity: 0.8,
    position: "absolute",
    bottom: 0,
    width: "100%",
    backgroundColor: "white",
    alignSelf: "center"
  },
  notificationDate: {
    fontSize: 12,
    color: "gray"
  },
  notificationBody: {
    fontSize: 15,
    marginBottom: 20
  },
  chatInputContainer: {
    width: "100%",
    flexDirection: "row"
  },
  chatInput: {
    flex: 1,
    borderWidth: 1,
    borderColor: "gray",
    borderRadius: 10,
    margin: 20,
    padding: 10,
    minHeight: 40,
    fontSize: 16
  },
  chatSendButton: {
    alignItems: "center",
    justifyContent: "center",
    padding: 20
  }
});
