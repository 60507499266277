import React from "react";
import { Pressable, StyleSheet, Text, View } from "react-native";
import { MaterialIcons, MaterialCommunityIcons } from "@expo/vector-icons";
import Rex from "src/globalState";
import Glob from "src/globalConstants";

export default function MyCheckbox({
  checked,
  text,
  onChange = () => {},
  disabled,
  radio,
  iconColor,
  style,
  textStyle = {}
}) {
  const onCheckmarkPress = () => onChange(!checked);

  const color =
    iconColor || Rex.getConfig()?.colors?.button || Glob.get("onespotColor");

  const CheckboxIcon = () => {
    if (radio)
      return (
        <MaterialIcons
          name={`radio-button-${checked ? "" : "un"}checked`}
          size={28}
          color={color}
        />
      );
    return (
      <MaterialCommunityIcons
        name={`checkbox-${checked ? "marked" : "blank-outline"}`}
        size={28}
        color={color}
      />
    );
  };

  return (
    <Pressable
      style={[styles.buttonContainer, { opacity: disabled ? 0.3 : 1 }, style]}
      onPress={onCheckmarkPress}
      disabled={disabled}
    >
      <View style={styles.checkboxContainer}>
        <CheckboxIcon />
      </View>
      <Text style={[styles.text, textStyle]}>{text}</Text>
    </Pressable>
  );
}

const styles = StyleSheet.create({
  buttonContainer: {
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
    marginVertical: 2
  },
  checkboxContainer: {
    justifyContent: "center",
    alignItems: "center",
    marginRight: 10
  },
  text: {
    flex: 1,
    fontSize: 14,
    color: "black"
  }
});
