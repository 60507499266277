/*  global window */
import "react-native-gesture-handler";
import React, { useState, useEffect, useRef } from "react";
import {
  View,
  Platform,
  Alert,
  LogBox,
  Text,
  ActivityIndicator,
  ImageBackground,
  Image
} from "react-native";
import {
  NavigationContainer,
  useNavigationContainerRef
} from "@react-navigation/native";
import {
  createStackNavigator,
  TransitionPresets
} from "@react-navigation/stack";
import { NativeBaseProvider } from "native-base";
import { isIOS, isAndroid } from "react-device-detect";
import * as Network from "expo-network";
import Constants from "expo-constants";
import * as Updates from "expo-updates";
import * as Linking from "expo-linking";
import School from "school/school";
import Rex from "src/globalState";
import Util from "src/utility";
import Glob from "src/globalConstants";
import Firebase from "src/backend/firebase";
import Analytics from "src/backend/analytics";
import Database from "src/backend/database";
import Root from "src/screens/root";
import Welcome from "src/screens/auth/welcome";
import Signup from "src/screens/auth/signup";
import Login from "src/screens/auth/login";
import PickAccountType from "src/screens/auth/pickAccountType";
import ForgotPassword from "src/screens/auth/forgotPassword";
import JoinWithInvitationCode from "src/screens/auth/joinWithInvitationCode";
import JoinLockedApp from "src/screens/auth/joinLockedApp";
import JoinLockedAccountType from "src/screens/auth/joinLockedAccountType";
import Settings from "src/screens/settings";
import ShareApp from "src/screens/shareApp";
import InviteUsers from "src/screens/inviteUsers";
import ImportUsers from "src/screens/importUsers";
import Notifications from "src/screens/notifications/notifications";
import NotificationDetails from "src/screens/notifications/notificationDetails";
import SelectUsersForChat from "src/screens/notifications/selectUsersForChat";
import Admin from "src/screens/admin/admin";
import SetBannerMessage from "src/screens/admin/setBannerMessage";
import EditAllPortals from "src/screens/admin/editAllPortals";
import AppAnalytics from "src/screens/admin/analytics";
import AppAnalyticsViewUsers from "src/screens/admin/analyticsViewUsers";
import ManageUsers from "src/screens/admin/manageUsers";
import ManageUser from "src/screens/admin/manageUser";
import EditPortal from "src/screens/admin/editPortal";
import EditDefaultPortals from "src/screens/admin/editDefaultPortals";
import EditAccountTypes from "src/screens/admin/editAccountTypes";
import EditAllUserGroups from "src/screens/admin/editAllUserGroups";
import EditGlobalConfig from "src/screens/admin/editGlobalConfig";
import Publish from "src/screens/admin/publish";
import SubmitToAppStores from "src/screens/admin/submitToAppStores";
import CommerceSettings from "src/screens/admin/commerceSettings";
import CommerceProducts from "src/screens/admin/commerceProducts";
import CommerceOrderHistory from "src/screens/admin/commerceOrderHistory";
import SubscribePay from "src/screens/admin/subscribePay";
import SendPushNotification from "src/screens/admin/sendPushNotification";
import NotificationHistory from "src/screens/admin/notificationHistory";
import TextsAndCalls from "src/screens/admin/textsAndCalls";
import SendTextsAndCalls from "src/screens/admin/sendTextsAndCalls";
import Help from "src/screens/admin/help";
import Tutorial from "src/screens/admin/tutorial";
import ViewAsMember from "src/screens/admin/viewAsMember";
import SuperAdminDashboard from "src/screens/admin/superAdminDashboard";
import EditProfile from "src/screens/editProfile";
import EditUserGroups from "src/screens/editUserGroups";
import DisplayQRCode from "src/screens/displayQRCode";
import NotificationPreferences from "src/screens/notificationPreferences";
import ChangePassword from "src/screens/changePassword";
import AddPortals from "src/screens/addPortals";
import Covid19Form from "src/screens/covid19form";
import Covid19VaccinePassport from "src/screens/covid19VaccinePassport";
import QRScanner from "src/screens/qrscanner";
import RoomCheckin from "src/screens/roomcheckin";
import PlaceCheckIn from "src/screens/placecheckin";
import MyStatsUNE from "src/screens/myStatsUNE";
import Passport from "src/screens/passport";
import Dynamic from "src/screens/dynamic";
import DynamicForm from "src/screens/dynamicForm";
import ActivityFeed from "src/screens/activityFeed";
import ActivityFeedComments from "src/screens/activityFeedComments";
import ActivityFeedManageUsers from "src/screens/activityFeedManageUsers";
import Events from "src/screens/events";
import EventDetail from "src/screens/eventdetail";
import Schedule from "src/screens/schedule";
import Food from "src/screens/food";
import FoodHours from "src/screens/foodHours";
import Buildings from "src/screens/buildings";
import WebStatic from "src/screens/webStatic";
import WebNav from "src/screens/webNav";
import PDFDocument from "src/screens/pdfDocument";
import WebNavWithButton from "src/screens/webNavWithButton";
import ListWebNavs from "src/screens/listWebNavs";
import ListWebNavsSplit from "src/screens/listWebNavsSplit";
import OnespotWelcome from "src/screens/onespot/welcome";
import OnespotLogin from "src/screens/onespot/login";
import OnespotJoin from "src/screens/onespot/join";
import OnespotCreateFromWebsite from "src/screens/onespot/createFromWebsite";
import OnespotCreateType from "src/screens/onespot/createType";
import OnespotCreateTypeGame from "src/screens/onespot/createTypeGame";
import OnespotCreateDetails from "src/screens/onespot/createDetails";
import OnespotCreatorOnboarding from "src/screens/onespot/creatorOnboarding";
import OnespotCheckoutPage from "src/screens/onespot/checkoutPage";
import TouchableLink from "src/components/dynamicContent/TouchableLink";
import Icon from "src/components/Icon";
import Button from "src/components/Button";
import { appImages } from "./standalone/appImages";

LogBox.ignoreAllLogs();

const ONESPOT_APP_ID = "ONESPOT";
const APP_URL_HANDLES = {
  acquired: "-N9gSB4L-2z_TJ4OJB7i",
  myr: "-N0wh8jgNwvUUCpBtio9"
};
const IS_ONESPOT_CAMPUS = Constants?.expoConfig?.slug === "onespotcampus";
const IS_UPHILL_NE = Constants?.expoConfig?.slug === "uphillnewengland";

function InitialScreen({ navigation }) {
  const [screenLoaded, setScreenLoaded] = useState(null);
  const [loadingIsSlow, setLoadingIsSlow] = useState(false);
  const [internetInfo, setInternetInfo] = useState(null);

  let urlAppID = Glob.get("urlParams")?.app;
  if (urlAppID in APP_URL_HANDLES) urlAppID = APP_URL_HANDLES[urlAppID];
  const urlPageID = Glob.get("urlParams")?.screen;
  const openAppStore = Glob.get("urlParams")?.openAppStore;
  const openCheckoutPage = !!Glob.get("urlParams")?.checkout;
  const openAIBuilder = !!Glob.get("urlParams")?.ai;
  const productPurchased =
    Glob.get("urlParams")?.subscriptionPurchased ||
    Glob.get("urlParams")?.productPurchased;
  const tryToOpenAppStore =
    Platform.OS === "web" && (isIOS || isAndroid) && !!openAppStore;
  let metaAppLinks = Glob.get("onespotAppStoreLinks");
  let metaAppName = "the Onespot app";
  if (Platform.OS === "web") {
    const currentBaseURL = window?.location?.origin || "";
    if (currentBaseURL.includes("montessori")) {
      metaAppLinks = Glob.get("montessoriAppStoreLinks");
      metaAppName = "The Montessori App";
    } else if (currentBaseURL.includes("onespotk12")) {
      metaAppLinks = Glob.get("onespotk12AppStoreLinks");
      metaAppName = "the Onespot K12 app";
    } else if (currentBaseURL.includes("onespotcampus")) {
      metaAppLinks = Glob.get("onespotCampusAppStoreLinks");
      metaAppName = "the Onespot Campus app";
    }
  }

  useEffect(() => {
    Analytics.initialize();

    // Wait a few seconds, which seems to solve this issue: https://github.com/expo/expo/issues/15946
    // TODO: Revisit this if a better solution is posted on that github issue
    setTimeout(() => {
      fetchLatestAppVersion();
    }, 1000);

    // If the app takes 5 seconds to load, show a message
    setTimeout(() => {
      setLoadingIsSlow(true);
    }, 5000);

    Network.getNetworkStateAsync().then((state) => {
      if (!state.isInternetReachable) setLoadingIsSlow(true);
      setInternetInfo(state);
    });

    try {
      if (tryToOpenAppStore) {
        if (!urlAppID) {
          const { android, ios } = metaAppLinks;
          const link = isIOS ? ios : android;
          Analytics.logEvent("action_openOnespotAppStore", { link });
          Linking.openURL(link);
        } else {
          Database.fetchAppStoreInfo(urlAppID).then((info) => {
            const { android, ios } = info?.appLinks || metaAppLinks;
            const link = isIOS ? ios : android;
            Analytics.logEvent("action_openAppStore", { urlAppID, link });
            if (link) Linking.openURL(link);
          });
        }
      }
    } catch (error) {
      Analytics.logEvent("error_openAppStoreLinkFailed", { error });
    }
    try {
      if (Platform.OS === "web" && openCheckoutPage) {
        setScreenLoaded("onespotCheckoutPage");
        return;
      }
    } catch (error) {
      Analytics.logEvent("error_openCheckoutPageFailed", { error });
    }
    try {
      if (Platform.OS === "web" && openAIBuilder) {
        setScreenLoaded("onespotCreateFromWebsite");
        return;
      }
    } catch (error) {
      Analytics.logEvent("error_openAIBuilderFailed", { error });
    }
    // Listen for whenever the user's signin status changes
    Firebase.onAuthStateChanged().then((isSignedIn) => {
      Rex.setLoginStatus(isSignedIn);
      let metaAppID = null;
      if (
        IS_ONESPOT_CAMPUS &&
        urlAppID &&
        urlAppID in Glob.get("onespotCampusApps")
      ) {
        metaAppID = Glob.get("onespotCampusApps")[urlAppID];
      }
      Database.fetchMetaApp(metaAppID).then((metaApp) => {
        Rex.setMetaApp(metaApp);
        if (IS_ONESPOT_CAMPUS && !metaApp) {
          // If this is onespot campus but we don't know what the meta app is, exit to the welcome screen
          setScreenLoaded("onespotWelcome");
        } else if (!Glob.get("appIsOnespotlike")) {
          // If this is not a meta app
          joinAppWithinOnespot();
        } else {
          // Otherwise, this is a meta app, so check if they've joined a spot already
          Util.localStorageGetItemAsync("databaseAppID").then(
            (storedDatabaseAppID) => {
              let databaseAppID = storedDatabaseAppID;
              // If there's an app id in the url query string
              if (urlAppID) {
                databaseAppID = urlAppID;
                // If we're going to a specific public webpage
                if (urlPageID && Platform.OS === "web") {
                  School.setDatabaseAppID(databaseAppID);
                  joinAppWithinOnespot(urlPageID);
                  return;
                }
              }
              // If they are signed in and they HAVE joined a spot already
              if (
                (isSignedIn || urlAppID) &&
                databaseAppID &&
                databaseAppID !== ONESPOT_APP_ID
              ) {
                // Update the global databaseAppID and continue them to their spot
                School.setDatabaseAppID(databaseAppID);
                joinAppWithinOnespot();
              } else if (metaApp?.primaryApp) {
                // If this is a meta app that specifies a primary app to start in,
                // update the global databaseAppID and continue them to that initial spot
                School.setDatabaseAppID(metaApp?.primaryApp);
                joinAppWithinOnespot();
              } else {
                // Continue to the Onespot welcome screen
                setScreenLoaded("onespotWelcome");
              }
            }
          );
        }
      });
    });
  }, []);

  // Ensure we're always running the latest app update
  const fetchLatestAppVersion = async () => {
    try {
      const update = await Updates.checkForUpdateAsync();
      if (update.isAvailable) {
        Analytics.logEvent("action_installingAppUpdate");
        await Updates.fetchUpdateAsync();
        Alert.alert(
          `New app updates incoming! ${Util.randomCelebratoryEmoji()}`
        );
        setTimeout(Util.reloadApp, 1500);
      }
    } catch (e) {
      // Ignore any errors so we don't break things
      // For example, a known error is that we cannot check for updates in dev mode or on web
    }
  };

  const joinAppWithinOnespot = (webpageID) => {
    Database.fetchAppMetadata().then((appMetadata) => {
      if (appMetadata) {
        Util.determineScreenWhenJoiningAppAsync(
          School.getDatabaseAppID(),
          appMetadata
        ).then((screenName) => {
          setScreenLoaded(screenName); // TODO: HANDLE FULLNAME
          // If we're navigating to a specific public web page, jump to that
          if (webpageID && Rex.getConfig()?.publicPagesEnabled)
            goToPublicWebpage(webpageID);
        });
      } else {
        // If the app failed to load, direct them back to the Onespot welcome screen
        School.setDatabaseAppID(ONESPOT_APP_ID);
        setScreenLoaded("onespotWelcome");
      }
    });
  };

  const goToPublicWebpage = (webpageID) => {
    Database.getAllPortalMetadata().then((metadata) => {
      const portal = metadata ? metadata[webpageID] : null;
      const navigateToScreen =
        portal?.portalType === "native" ? webpageID : portal?.portalType;
      if (navigateToScreen) {
        navigation.push(navigateToScreen, {
          ...portal,
          navName: webpageID
        });
      }
    });
  };

  const internetInfoText = () => {
    if (!internetInfo) return null;
    if (
      !internetInfo.isInternetReachable ||
      internetInfo.type === Network.NetworkStateType.NONE
    )
      return "It looks like you're not connected to the internet.";
    let internetType = "";
    if (internetInfo.type === Network.NetworkStateType.CELLULAR)
      internetType = "cellular ";
    if (internetInfo.type === Network.NetworkStateType.WIFI)
      internetType = "WiFi ";
    return `It looks like your ${internetType}internet connection is slow.`;
  };

  if (!screenLoaded) {
    return (
      <ImageBackground
        source={appImages?.splash}
        style={{
          height: "100%",
          width: "100%",
          flexDirection: "column",
          justifyContent: "space-evenly",
          alignItems: "center",
          backgroundColor: Rex.getConfig()?.colors?.background
        }}
      >
        {tryToOpenAppStore || loadingIsSlow ? (
          <View
            style={[
              {
                margin: 20,
                padding: 20,
                borderRadius: 10,
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "rgba(255,255,255,0.94)"
              }
            ]}
          >
            {loadingIsSlow && Platform.OS !== "web" && (
              <>
                <View>
                  <Image
                    source={appImages?.icon}
                    style={{
                      width: 100,
                      height: 100,
                      borderRadius: 23
                    }}
                  />
                  <View
                    style={{
                      position: "absolute",
                      top: -10,
                      right: -10,
                      backgroundColor: Glob.get("onespotRed"),
                      borderRadius: 100,
                      padding: 10
                    }}
                  >
                    <Icon
                      icon="929a8c45-4c92-4f47-8dfc-ae17324c7e69"
                      size={30}
                      color="white"
                    />
                  </View>
                </View>
                {!!internetInfo && (
                  <Text
                    style={{
                      textAlign: "center",
                      marginTop: 20,
                      fontWeight: "bold"
                    }}
                  >
                    {internetInfoText()}
                  </Text>
                )}
                {!IS_UPHILL_NE ? (
                  <Text
                    style={{
                      textAlign: "center",
                      marginTop: 20
                    }}
                  >
                    {Constants?.expoConfig?.name} needs the internet to work.
                    Double-check your network connection. Once you're connected,
                    close and reopen this app.
                  </Text>
                ) : (
                  <>
                    <Text
                      style={{
                        textAlign: "center",
                        marginTop: 20
                      }}
                    >
                      Don't worry, you can still check in at a resort without
                      internet.
                    </Text>
                    <Button
                      text="Check In"
                      onPress={() => navigation.push("placecheckin")}
                      color="#122C3E"
                      style={{ marginTop: 20 }}
                    />
                  </>
                )}
              </>
            )}
            {tryToOpenAppStore && (
              <>
                <Text
                  style={{
                    textAlign: "center",
                    fontWeight: "bold",
                    marginTop: 20
                  }}
                >
                  Opening in {isIOS ? "the iOS App Store" : "Google Play"}...
                </Text>
                <Text style={{ textAlign: "center" }}>
                  If this takes more than 5 seconds, try refreshing this page.
                  Or touch
                  <TouchableLink
                    type="web"
                    link={isIOS ? metaAppLinks?.ios : metaAppLinks?.android}
                    text=" this link "
                    navigation={navigation}
                  />
                  to install {metaAppName} for {isIOS ? "iOS" : "Android"}.
                </Text>
              </>
            )}
          </View>
        ) : (
          <View />
        )}
        <ActivityIndicator
          size="large"
          color={Platform.OS === "web" ? "black" : "white"}
        />
      </ImageBackground>
    );
  }

  if (screenLoaded === "onespotCheckoutPage")
    return <OnespotCheckoutPage navigation={navigation} />;

  if (screenLoaded === "onespotCreateFromWebsite")
    return <OnespotCreateFromWebsite navigation={navigation} />;

  if (
    screenLoaded === "onespotWelcome" ||
    School.getDatabaseAppID() === ONESPOT_APP_ID
  )
    return <OnespotWelcome navigation={navigation} />;

  if (screenLoaded === "root")
    return <Root navigation={navigation} productPurchased={productPurchased} />;

  if (screenLoaded === "joinLockedApp")
    return <JoinLockedApp navigation={navigation} />; // todo: backbutton

  if (screenLoaded === "welcome") return <Welcome navigation={navigation} />; // todo: backbutton

  // Backup
  return <OnespotWelcome navigation={navigation} />;
}

const Stack = createStackNavigator();
const defaultTransition =
  Platform.OS === "android" ? TransitionPresets.FadeFromBottomAndroid : {};

// TODO: Enabled this to allow for web page navigation
// const linking = {
//   prefixes: [
//     /* your linking prefixes */
//   ],
//   config: {
//     /* configuration for matching screens with paths */
//   }
// };

function App() {
  const navigationRef = useNavigationContainerRef();
  const routeNameRef = useRef();

  return (
    <NativeBaseProvider>
      <NavigationContainer
        // linking={linking}
        // fallback={<Text>Loading...!</Text>}
        ref={navigationRef}
        onReady={() => {
          routeNameRef.current = navigationRef.getCurrentRoute().name;
        }}
        onStateChange={async () => {
          const previousRouteName = routeNameRef.current;
          const currentRouteName = navigationRef.getCurrentRoute().name;
          if (previousRouteName !== currentRouteName) {
            // TODO: Track currentRouteName as the current screen name in analytics
          }
          // Save the current route name for later comparison
          routeNameRef.current = currentRouteName;
        }}
      >
        <Stack.Navigator
          initialRouteName="onespot"
          screenOptions={{
            headerShown: false,
            ...defaultTransition
          }}
        >
          <Stack.Screen
            name="onespot"
            component={InitialScreen}
            options={{ title: "Onespot" }}
          />
          <Stack.Screen
            name="root"
            component={Root}
            options={{
              gestureEnabled: false,
              title: "Onespot"
            }}
          />
          <Stack.Screen
            name="onespotWelcome"
            component={OnespotWelcome}
            options={{ title: "Onespot" }}
          />
          <Stack.Screen
            name="onespotLogin"
            component={OnespotLogin}
            options={{ title: "Log in" }}
          />
          <Stack.Screen
            name="onespotJoin"
            component={OnespotJoin}
            options={{ title: "Join an app" }}
          />
          <Stack.Screen
            name="onespotCreateFromWebsite"
            component={OnespotCreateFromWebsite}
            options={{ title: "Onespot AI" }}
          />
          <Stack.Screen
            name="onespotCreateType"
            component={OnespotCreateType}
            options={{ title: "Create an app" }}
          />
          <Stack.Screen
            name="onespotCreateTypeGame"
            component={OnespotCreateTypeGame}
            options={{ title: "Create a game" }}
          />
          <Stack.Screen
            name="onespotCreateDetails"
            component={OnespotCreateDetails}
            options={{ title: "Create an app" }}
          />
          <Stack.Screen
            name="onespotCreatorOnboarding"
            component={OnespotCreatorOnboarding}
            options={{ title: "Create an app" }}
          />
          <Stack.Screen
            name="onespotCheckoutPage"
            component={OnespotCheckoutPage}
            options={{ title: "Get an App" }}
          />
          <Stack.Screen
            name="login"
            component={Login}
            options={{ title: "Log in" }}
          />
          <Stack.Screen
            name="forgotPassword"
            component={ForgotPassword}
            options={{ title: "Forgot Password" }}
          />
          <Stack.Screen
            name="joinWithInvitationCode"
            component={JoinWithInvitationCode}
            options={{ title: "Invitation Code" }}
          />
          <Stack.Screen
            name="joinLockedApp"
            component={JoinLockedApp}
            options={{ title: "Private" }}
          />
          <Stack.Screen
            name="joinLockedAccountType"
            component={JoinLockedAccountType}
            options={{ title: "Secure" }}
          />
          <Stack.Screen
            name="welcome"
            component={Welcome}
            options={{ title: "Sign up" }}
          />
          <Stack.Screen
            name="signup"
            component={Signup}
            options={{ title: "Sign up" }}
          />
          <Stack.Screen
            name="pickAccountType"
            component={PickAccountType}
            options={{ title: "Sign up" }}
          />
          <Stack.Screen
            name="settings"
            component={Settings}
            options={{ title: "Profile" }}
          />
          <Stack.Screen
            name="shareApp"
            component={ShareApp}
            options={{ title: "Share This App" }}
          />
          <Stack.Screen
            name="inviteUsers"
            component={InviteUsers}
            options={{ title: "Invite Members" }}
          />
          <Stack.Screen
            name="importUsers"
            component={ImportUsers}
            options={{ title: "Import Members" }}
          />
          <Stack.Screen
            name="notifications"
            component={Notifications}
            options={{ title: "Messages" }}
          />
          <Stack.Screen
            name="notificationDetails"
            component={NotificationDetails}
            options={{ title: "Message" }}
          />
          <Stack.Screen
            name="selectUsersForChat"
            component={SelectUsersForChat}
            options={{ title: "New Message" }}
          />
          <Stack.Screen
            name="admin"
            component={Admin}
            options={{ title: "Admin Superpowers" }}
          />
          <Stack.Screen
            name="setBannerMessage"
            component={SetBannerMessage}
            options={{ title: "Set Banner Message" }}
          />
          <Stack.Screen
            name="editAllPortals"
            component={EditAllPortals}
            options={{ title: "Edit Screens" }}
          />
          <Stack.Screen
            name="appAnalytics"
            component={AppAnalytics}
            options={{ title: "Analytics" }}
          />
          <Stack.Screen
            name="analyticsViewUsers"
            component={AppAnalyticsViewUsers}
            options={{ title: "View Members" }}
          />
          <Stack.Screen
            name="manageUsers"
            component={ManageUsers}
            options={{ title: "Manage Members" }}
          />
          <Stack.Screen
            name="manageUser"
            component={ManageUser}
            options={{ title: "View Member" }}
          />
          <Stack.Screen
            name="editPortal"
            component={EditPortal}
            options={{ title: "Edit Screen" }}
          />
          <Stack.Screen
            name="editDefaultPortals"
            component={EditDefaultPortals}
            options={{ title: "Default Screens" }}
          />
          <Stack.Screen
            name="editAccountTypes"
            component={EditAccountTypes}
            options={{ title: "Account Types" }}
          />
          <Stack.Screen
            name="editAllUserGroups"
            component={EditAllUserGroups}
            options={{ title: "Notification Groups" }}
          />
          <Stack.Screen
            name="editGlobalConfig"
            component={EditGlobalConfig}
            options={{ title: "App Settings & Design" }}
          />
          <Stack.Screen
            name="publish"
            component={Publish}
            options={{ title: "Publishing & Privacy" }}
          />
          <Stack.Screen
            name="submitToAppStores"
            component={SubmitToAppStores}
            options={{ title: "Submit to App Stores" }}
          />
          <Stack.Screen
            name="commerceSettings"
            component={CommerceSettings}
            options={{ title: "Onespot Payments" }}
          />
          <Stack.Screen
            name="commerceProducts"
            component={CommerceProducts}
            options={{ title: "Products & Services" }}
          />
          <Stack.Screen
            name="commerceOrderHistory"
            component={CommerceOrderHistory}
            options={{ title: "Order History" }}
          />
          <Stack.Screen
            name="subscribePay"
            component={SubscribePay}
            options={{ title: "Subscribe" }}
          />
          <Stack.Screen
            name="sendPushNotification"
            component={SendPushNotification}
            options={{ title: "Send Notification" }}
          />
          <Stack.Screen
            name="notificationHistory"
            component={NotificationHistory}
            options={{ title: "Notification History" }}
          />
          <Stack.Screen
            name="textsAndCalls"
            component={TextsAndCalls}
            options={{ title: "Texting & Calling" }}
          />
          <Stack.Screen
            name="sendTextsAndCalls"
            component={SendTextsAndCalls}
            options={{ title: "Send Text Message" }}
          />
          <Stack.Screen
            name="help"
            component={Help}
            options={{ title: "Help" }}
          />
          <Stack.Screen
            name="tutorial"
            component={Tutorial}
            options={{ title: "Tutorial" }}
          />
          <Stack.Screen
            name="viewAsMember"
            component={ViewAsMember}
            options={{ title: "View as Member" }}
          />
          <Stack.Screen
            name="superAdminDashboard"
            component={SuperAdminDashboard}
            options={{ title: "Super Admin Dashboard" }}
          />
          <Stack.Screen
            name="editProfile"
            component={EditProfile}
            options={{ title: "Your Profile" }}
          />
          <Stack.Screen
            name="editUserGroups"
            component={EditUserGroups}
            options={({ route }) => ({
              gestureEnabled: !route?.params?.isEntryScreen,
              title: "Your Groups"
            })}
          />
          <Stack.Screen
            name="displayQRCode"
            component={DisplayQRCode}
            options={{ title: "Your QR Code" }}
          />
          <Stack.Screen
            name="notificationPreferences"
            component={NotificationPreferences}
            options={{ title: "Notification Preferences" }}
          />
          <Stack.Screen
            name="changePassword"
            component={ChangePassword}
            options={{ title: "Account Settings" }}
          />
          <Stack.Screen
            name="addPortals"
            component={AddPortals}
            options={{ title: "Add Screens" }}
          />
          <Stack.Screen
            name="covid19form"
            component={Covid19Form}
            options={{ title: "COVID-19 Form" }}
          />
          <Stack.Screen
            name="covid19VaccinePassport"
            component={Covid19VaccinePassport}
            options={{ title: "COVID-19 Vaccine Passport" }}
          />
          <Stack.Screen
            name="qrscanner"
            component={QRScanner}
            options={{ title: "QR Scanner" }}
          />
          <Stack.Screen
            name="roomcheckin"
            component={RoomCheckin}
            options={{ title: "Room Check-in" }}
          />
          <Stack.Screen
            name="placecheckin"
            component={PlaceCheckIn}
            options={{ title: "Ski Resort Check-in" }}
          />
          <Stack.Screen
            name="myStatsUNE"
            component={MyStatsUNE}
            options={{ title: "My Stats" }}
          />
          <Stack.Screen
            name="passport"
            component={Passport}
            options={{ title: "My Uphill Pass" }}
          />
          <Stack.Screen
            name="dynamic"
            component={Dynamic}
            options={{ title: "Onespot" }}
          />
          <Stack.Screen
            name="activityFeed"
            component={ActivityFeed}
            options={{ title: "Activity Feed" }}
          />
          <Stack.Screen
            name="activityFeedComments"
            component={ActivityFeedComments}
            options={{ title: "Comments" }}
          />
          <Stack.Screen
            name="activityFeedManageUsers"
            component={ActivityFeedManageUsers}
            options={{ title: "Members" }}
          />
          <Stack.Screen
            name="dynamicForm"
            component={DynamicForm}
            options={{ title: "Form" }}
          />
          <Stack.Screen
            name="events"
            component={Events}
            options={{ title: "Events" }}
          />
          <Stack.Screen
            name="eventDetail"
            component={EventDetail}
            options={{ title: "Event Details" }}
          />
          <Stack.Screen
            name="schedule"
            component={Schedule}
            options={{ title: "Schedule" }}
          />
          <Stack.Screen
            name="food"
            component={Food}
            options={{ title: "Dining" }}
          />
          <Stack.Screen
            name="foodHours"
            component={FoodHours}
            options={{ title: "Dining Hours" }}
          />
          <Stack.Screen
            name="buildings"
            component={Buildings}
            options={{ title: "Buildings" }}
          />
          <Stack.Screen
            name="webStatic"
            component={WebStatic}
            options={{ title: "Onespot" }}
          />
          <Stack.Screen
            name="webNav"
            component={WebNav}
            options={{ title: "Onespot" }}
          />
          <Stack.Screen
            name="pdfDocument"
            component={PDFDocument}
            options={{ title: "PDF" }}
          />
          <Stack.Screen
            name="webNavWithButton"
            component={WebNavWithButton}
            options={{ title: "Onespot" }}
          />
          <Stack.Screen
            name="listWebNavs"
            component={ListWebNavs}
            options={{ title: "Onespot" }}
          />
          <Stack.Screen
            name="listWebNavsSplit"
            component={ListWebNavsSplit}
            options={{ title: "Onespot" }}
          />
        </Stack.Navigator>
      </NavigationContainer>
    </NativeBaseProvider>
  );
}

export default App;
