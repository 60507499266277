import React from "react";
import { Image } from "react-native";
import {
  AntDesign,
  Entypo,
  EvilIcons,
  Feather,
  FontAwesome,
  FontAwesome5,
  Fontisto,
  Foundation,
  Ionicons,
  MaterialCommunityIcons,
  MaterialIcons,
  Octicons,
  SimpleLineIcons,
  Zocial
} from "@expo/vector-icons";
import IconCollection from "resources/icons/Icons";

const IMAGE_ICONS = [
  {
    uuid: "social_media",
    image: require("resources/images/Portal_Tiles/social_media.png")
  },
  {
    uuid: "dartmouth_sapa",
    image: require("resources/images/Portal_Tiles/dartmouth_sapa.png")
  },
  {
    uuid: "dartmouth_student_assembly",
    image: require("resources/images/Portal_Tiles/dartmouth_student_assembly.png")
  },
  {
    uuid: "flocknote",
    image: require("resources/images/Portal_Tiles/flocknote.png")
  },
  {
    uuid: "foot",
    image: require("resources/images/Portal_Tiles/foot.png")
  },
  {
    uuid: "laserHairRemoval",
    image: require("resources/images/Portal_Tiles/laser_hair_removal.png")
  },
  {
    uuid: "pbisRewardsLogo",
    image: require("resources/images/Portal_Tiles/pbis_logo.png")
  },
  {
    uuid: "morano_gelato",
    image: require("resources/images/Portal_Tiles/morano_gelato.png")
  },
  {
    uuid: "seabird",
    image: require("resources/images/Portal_Tiles/seabird.png")
  }
];

const allImageIcons = {};
const allVectorIcons = {};
IconCollection.forEach((icon) => {
  allVectorIcons[icon.uuid] = icon;
});
IMAGE_ICONS.forEach((icon) => {
  allImageIcons[icon.uuid] = icon;
});

const getIconFromCollection = (iconSet, name, props) => {
  switch (iconSet) {
    case "AntDesign":
      return <AntDesign name={name} {...props} />;
    case "Entypo":
      return <Entypo name={name} {...props} />;
    case "EvilIcons":
      return <EvilIcons name={name} {...props} />;
    case "Feather":
      return <Feather name={name} {...props} />;
    case "FontAwesome":
      return <FontAwesome name={name} {...props} />;
    case "FontAwesome5":
      return <FontAwesome5 name={name} {...props} />;
    case "Fontisto":
      return <Fontisto name={name} {...props} />;
    case "Foundation":
      return <Foundation name={name} {...props} />;
    case "Ionicons":
      return <Ionicons name={name} {...props} />;
    case "MaterialCommunityIcons":
      return <MaterialCommunityIcons name={name} {...props} />;
    case "MaterialIcons":
      return <MaterialIcons name={name} {...props} />;
    case "Octicons":
      return <Octicons name={name} {...props} />;
    case "SimpleLineIcons":
      return <SimpleLineIcons name={name} {...props} />;
    case "Zocial":
      return <Zocial name={name} {...props} />;
    default:
      return null;
  }
};

export default function Icon({
  icon,
  iconURL,
  set,
  name,
  color = "white",
  size = 54,
  style = {}
}) {
  if (iconURL) {
    return (
      <Image
        source={{ uri: iconURL }}
        style={[{ width: 1.4 * size, height: 1.4 * size }, style]}
        resizeMode="contain"
      />
    );
  }
  if (icon) {
    if (allVectorIcons[icon]) {
      const iconObject = allVectorIcons[icon];
      return getIconFromCollection(iconObject.iconSet, iconObject.iconName, {
        color,
        size,
        style
      });
    }
    if (allImageIcons[icon]) {
      const iconObject = allImageIcons[icon];
      return (
        <Image
          source={iconObject.image}
          style={[
            { width: 1.4 * size, height: 1.4 * size, tintColor: color },
            style
          ]}
        />
      );
    }
  } else if (set && name) {
    return getIconFromCollection(set, name, { color, size });
  }
  return null;
}
