import React, { useEffect, useState } from "react";
import {
  StyleSheet,
  Text,
  View,
  ScrollView,
  KeyboardAvoidingView,
  Platform,
  ActivityIndicator
} from "react-native";
import * as Haptics from "expo-haptics";
import LottieView from "src/components/Lottie";
import indefinite from "indefinite";
import Rex from "src/globalState";
import Style from "src/globalStyles";
import Glob from "src/globalConstants";
import Analytics from "src/backend/analytics";
import Database from "src/backend/database";
import NavBar from "src/components/navBar";
import Button from "src/components/Button";
import InputBox from "src/components/InputBox";
import Multiselect from "src/components/Multiselect";
import Checkbox from "src/components/Checkbox";
import PreviewPushNotification from "src/components/PreviewPushNotification";
import PreviewTextMessage from "src/components/PreviewTextMessage";
import HelpButton from "src/components/HelpButton";
import HelpText from "src/components/HelpText";
import TouchableLink from "src/components/dynamicContent/TouchableLink";

const PAPER_AIRPLANE_ANIMATION = require("resources/animations/paperAirplane.json");

const { height, width } = Glob.get("dimensions");

const LoadingRecipientsIndicator = () => (
  <>
    <ActivityIndicator size="small" />
    <Text style={{ textAlign: "center", color: "#888" }}>
      Loading recipients...
    </Text>
  </>
);

export default function SendPushNotification({ navigation, route }) {
  const { params: { sendAsTextMessage } = {} } = route || {};
  const [allGroups, setAllGroups] = useState(null);
  const [showURL, setShowURL] = useState(false);
  const [
    notificationPermissionsLimited,
    setNotificationPermissionsLimited
  ] = useState(false);
  const [showLimitRecipients, setShowLimitRecipients] = useState(false);
  const [showLimitAccountTypes, setShowLimitAccountTypes] = useState(false);
  const [showDeliveryMethods, setShowDeliveryMethods] = useState(false);
  const [showReplies, setShowReplies] = useState(false);
  const [showLimitGroups, setShowLimitGroups] = useState(false);
  const [showLimitInvitationStatus, setShowLimitInvitationStatus] = useState(
    false
  );
  const [pendingInvitationsExist, setPendingInvitationsExist] = useState(false);
  const [sendingNotification, setSendingNotification] = useState(false);
  const [allGroupCheckboxes, setAllGroupCheckboxes] = useState(null);
  const [allAccountTypeCheckboxes, setAllAccountTypeCheckboxes] = useState(
    null
  );
  const [allAccountTypeDetails, setAllAccountTypeDetails] = useState(null);
  const [anonymizedUsers, setAnonymizedUsers] = useState(null);
  const [loadedAnonymizedUsers, setLoadedAnonymizedUsers] = useState(false);
  const [phoneCredits, setPhoneCredits] = useState(0);
  const [isGeneratingTitle, setIsGeneratingTitle] = useState(false);
  const [isGeneratingBody, setIsGeneratingBody] = useState(false);
  const [notification, setNotification] = useState({
    title: null,
    body: null,
    url: null,
    chatEnabled: false,
    audience: {
      accountTypes: [],
      groups: [],
      users: [],
      invitationStatus: "all" // "all", "current", "invited"
    },
    deliveryMethod: {
      pushNotification: true,
      email: true,
      textMessage: !!sendAsTextMessage,
      phoneCall: false
    }
  });
  const {
    audience: { accountTypes, groups, invitationStatus },
    deliveryMethod
  } = notification;
  const limitAccountTypes = showLimitAccountTypes; // && accountTypes.length > 0;
  const limitGroups = showLimitGroups; // && groups.length > 0;
  const { textMessagingEnabled, phoneCallsEnabled, granularSecurityEnabled } =
    Rex.getConfig() || {};
  const textOrCallEnabled = textMessagingEnabled || phoneCallsEnabled;
  const adminPrivileges = Rex.getSessionMemory("adminPrivileges");
  const hasTextAndCallPrivilege = adminPrivileges?.includes("TextAndCall");
  const canTextOrCall = hasTextAndCallPrivilege && textOrCallEnabled;
  const canText = hasTextAndCallPrivilege && textMessagingEnabled;
  const canCall = hasTextAndCallPrivilege && phoneCallsEnabled;

  const userShouldReceivePhoneMessage = (user) =>
    (deliveryMethod.textMessage && user.receiveTextMessages) ||
    (deliveryMethod.phoneCall && user.receivePhoneCalls);

  let totalRecipients = 0;
  let totalPhoneNumberRecipients = 0;
  let totalInvitationRecipients = 0;
  if (anonymizedUsers) {
    let usersArray = [];
    if (limitAccountTypes)
      usersArray = accountTypes.map((type) => anonymizedUsers[type] || []);
    else usersArray = Object.values(anonymizedUsers);
    // Flatten the double array
    usersArray = [].concat(...usersArray);
    // Filter by invitation status
    if (invitationStatus && invitationStatus !== "all") {
      usersArray = usersArray.filter((user) => {
        if (invitationStatus === "current") return !user.isInvitation;
        if (invitationStatus === "invited") return user.isInvitation;
        return true;
      });
    }
    usersArray.forEach((user) => {
      if (
        !limitGroups ||
        allGroupCheckboxes.length < 1 ||
        groups.some((group) => user.groups[group])
      ) {
        totalRecipients += 1;
        if (userShouldReceivePhoneMessage(user))
          totalPhoneNumberRecipients += 1;
        if (user?.isInvitation) totalInvitationRecipients += 1;
      }
    });
  }
  const allRecipientsAreInvitations =
    totalRecipients !== 0 && totalRecipients === totalInvitationRecipients;

  const invitationModifier = invitationStatus === "invited" ? "invited " : "";

  useEffect(() => {
    Analytics.logEvent("view_sendPushNotification");
    Database.fetchUserPrivileges(false).then((privileges) => {
      const notificationsPrivilege = privileges?.PushNotifications;
      const limitPermissions =
        granularSecurityEnabled && notificationsPrivilege !== true;
      if (limitPermissions) {
        setShowLimitRecipients(true);
        setShowLimitGroups(true);
        setShowLimitAccountTypes(true);
        if (pendingInvitationsExist) setShowLimitInvitationStatus(true);
        setNotificationPermissionsLimited(true);
      }
      Database.getAllUserGroupsDisplayOrder().then((displayOrder) => {
        const allGroupIDsInOrder = displayOrder || [];
        Database.getAllUserGroups().then((data) => {
          let newAllGroups = {};
          if (limitPermissions && notificationsPrivilege?.groups) {
            // Filter to only allowed groups
            Object.entries(data || {}).forEach(([key, value]) => {
              if (notificationsPrivilege.groups[key]) newAllGroups[key] = value;
            });
          } else {
            newAllGroups = data || {};
          }
          setAllGroups(newAllGroups);
          const checkboxes = allGroupIDsInOrder
            .filter((groupId) => newAllGroups[groupId])
            .map((groupId) => ({
              key: groupId,
              value: false,
              text: newAllGroups[groupId].name
            }));
          setAllGroupCheckboxes(checkboxes);
        });
      });
      Database.fetchAllAccountTypeDetails().then((data) => {
        let allTypes = {};
        if (limitPermissions && notificationsPrivilege?.accountTypes) {
          // Filter to only allowed account types
          Object.entries(data || {}).forEach(([key, value]) => {
            if (notificationsPrivilege.accountTypes[key]) allTypes[key] = value;
          });
        } else {
          allTypes = data || {};
        }
        setAllAccountTypeDetails(allTypes);
        if (allTypes) {
          const checkboxes = Object.entries(allTypes).map(
            ([type, details]) => ({
              key: type,
              value: false,
              text: details?.title || type
            })
          );
          setAllAccountTypeCheckboxes(checkboxes);
        } else {
          // Handle legacy database structure
          Database.getAllDefaultPortals((portals) => {
            // Note: If legacy database, we don't handle limitPermissions
            const allTypeIDs = Object.keys(portals);
            const checkboxes = allTypeIDs.map((type) => ({
              key: type,
              value: false,
              text: Glob.getAccountType(type).buttonText
            }));
            setAllAccountTypeCheckboxes(checkboxes);
          });
        }
      });
    });
    Database.checkIfPendingUserInvitationsExist().then(
      setPendingInvitationsExist
    );
    Database.fetchAllUsersAnonymized().then((users) => {
      setAnonymizedUsers(users);
      setLoadedAnonymizedUsers(true);
    });
    if (canTextOrCall)
      Database.subscribeToTextsAndCalls((data) => {
        const { currentCredits } = data || {};
        setPhoneCredits(currentCredits || 0);
      });
    // cleanup called when component is unmounting
    return () => {
      if (canTextOrCall) Database.unsubscribeFromTextsAndCalls();
    };
  }, []);

  const userTypeTitle = (type) => {
    return allAccountTypeDetails &&
      allAccountTypeDetails[type] &&
      allAccountTypeDetails[type].title
      ? allAccountTypeDetails[type].title.toLowerCase()
      : type;
  };

  const writeMessageTitleWithAI = async () => {
    if (
      !Rex.getConfig()?.aiDisabled &&
      notification.body &&
      notification.body.length > 20 &&
      !notification.title
    ) {
      setIsGeneratingTitle(true);
      const response = await Database.aiGenerateText(
        `Generate a short title (3-5 words) for the following body: ${notification.body}`,
        "You are a helpful assistant that generates message titles for push notifications. You respond with the message title only."
      );
      const newTitle = (response || "").replace(/"/g, "");
      Analytics.logEvent("touch_sendPushNotification_writeMessageTitleWithAI", {
        body: notification.body,
        title: newTitle
      });
      updateNotificationField("title", newTitle);
      setIsGeneratingTitle(false);
    }
  };

  const writeMessageBodyWithAI = async () => {
    setIsGeneratingBody(true);
    const response = await Database.aiGenerateText(
      `Generate a message body for the following title: ${notification.title}`,
      "You are a helpful assistant that generates message bodies for push notifications. You respond with the message body only."
    );
    const newBody = (response || "").replace(/"/g, "");
    Analytics.logEvent("touch_sendPushNotification_writeMessageBodyWithAI", {
      title: notification.title,
      body: newBody
    });
    updateNotificationField("body", newBody);
    setIsGeneratingBody(false);
  };

  const sendPushNotification = () => {
    let formattedNotification = notification;
    if (!showLimitAccountTypes)
      formattedNotification = {
        ...formattedNotification,
        audience: { ...formattedNotification.audience, accountTypes: [] }
      };
    if (!showLimitGroups)
      formattedNotification = {
        ...formattedNotification,
        audience: { ...formattedNotification.audience, groups: [] }
      };
    const formattedNotificationForAnalytics = {
      ...notification,
      audience: JSON.stringify(notification.audience)
    };
    Analytics.logEvent(
      "touch_sendPushNotification_send",
      formattedNotificationForAnalytics
    );
    Database.sendPushNotification(formattedNotification);
    Database.addTask("sendPushNotification", "done");
    if (Platform.OS !== "web")
      Haptics.notificationAsync(Haptics.NotificationFeedbackType.Success);
    setSendingNotification(true);
  };

  const updateNotificationField = (key, value) => {
    setNotification((prevState) => ({ ...prevState, [key]: value }));
  };

  const updateNotificationAudience = (key, value) => {
    setNotification((prevState) => ({
      ...prevState,
      audience: { ...prevState.audience, [key]: value }
    }));
  };

  const updateNotificationDeliveryMethod = (key, value) => {
    setNotification((prevState) => ({
      ...prevState,
      deliveryMethod: { ...prevState.deliveryMethod, [key]: value }
    }));
  };

  const onToggleAudience = (groupId, active, category = "groups") => {
    const activeAudienceList = notification.audience[category];
    const newActiveAudienceList = [...activeAudienceList];
    if (active) newActiveAudienceList.push(groupId);
    else newActiveAudienceList.splice(activeAudienceList.indexOf(groupId), 1);
    updateNotificationAudience(category, newActiveAudienceList);
    const setCheckboxesState = (prevState) =>
      prevState.map((c) => ({
        ...c,
        value: newActiveAudienceList.indexOf(c.key) > -1
      }));
    if (category === "groups") setAllGroupCheckboxes(setCheckboxesState);
    else if (category === "accountTypes")
      setAllAccountTypeCheckboxes(setCheckboxesState);
  };

  const onChangeAudienceInvitationStatus = (status) => {
    updateNotificationAudience("invitationStatus", status);
  };

  const audienceMessage = () => {
    if (limitAccountTypes && accountTypes.length < 1)
      return `no ${invitationModifier}users`;
    let message = `all ${invitationModifier}users`;
    const groupNames = groups.map((key) => allGroups[key].name);
    if (limitAccountTypes) {
      let accountTypesPhrase = "";
      const accountTypesWithIndefiniteArticle = accountTypes.map((type) =>
        indefinite(userTypeTitle(type))
      );
      // "a student"
      if (accountTypes.length === 1)
        accountTypesPhrase = accountTypesWithIndefiniteArticle[0];
      // "a student or an alum"
      if (accountTypes.length === 2)
        accountTypesPhrase = accountTypesWithIndefiniteArticle.join(" or ");
      // "a student, an alum, or a parent"
      if (accountTypes.length > 2) {
        accountTypesPhrase = accountTypesWithIndefiniteArticle
          .slice(0, accountTypes.length - 1)
          .join(", ");
        accountTypesPhrase = `${accountTypesPhrase}, or ${
          accountTypesWithIndefiniteArticle[accountTypes.length - 1]
        }`;
      }

      message = `any ${invitationModifier}user who is ${accountTypesPhrase}`;
    }
    if (limitGroups) {
      const groupsListed = groups.length < 1 ? "none" : groupNames.join(", ");
      if (limitAccountTypes)
        message = `${message} and who is also in one of these groups: ${groupsListed}`;
      else
        message = `any ${invitationModifier}user who is in one of these groups: ${groupsListed}`;
    }

    return message;
  };

  if (sendingNotification)
    return (
      <View style={styles.pageContent}>
        <NavBar navigation={navigation} text="Sending Notification" />
        <View style={{ flex: 2, maxHeight: "50%", width }}>
          <LottieView
            style={{ height: "100%", width: "100%" }}
            autoPlay
            loop
            source={PAPER_AIRPLANE_ANIMATION}
          />
        </View>
        <View style={{ flex: 1, paddingHorizontal: 20, marginBottom: 20 }}>
          <Text style={styles.headerText}>Sending now!</Text>
          <Text style={styles.bodyText}>
            Over the next few minutes, your message will be sent to{" "}
            {audienceMessage()}
          </Text>
          <Button
            text="View Delivery Status"
            onPress={() => navigation.replace("notificationHistory")}
          />
        </View>
      </View>
    );

  const bottomContainerStyle = {
    paddingTop: 10,
    paddingBottom: 32,
    paddingHorizontal: 15,
    backgroundColor: "white",
    shadowOpacity: 0.15,
    shadowOffset: { width: 0, height: -5 },
    shadowRadius: 3,
    elevation: 20,
    width: "100%"
  };
  if (Platform.OS === "web")
    bottomContainerStyle.boxShadow = "0px -5px 3px rgba(0,0,0,0.15)";

  const shouldSendToPhoneNumbers =
    deliveryMethod.textMessage || deliveryMethod.phoneCall;

  const textMessageBody = `${notification.title || ""}${
    notification.body ? `\n${notification.body}` : ""
  }`; // Note: Texting URLs seems to sometimes cause the message to be filtered (Twilio Error: 30032)
  const textMessage = `From ${
    Rex.getConfig()?.names?.nickname
  }:\n${textMessageBody.truncate(
    Glob.get("maxTextMessageCharacters") + 1,
    false
  )}\n\n(Automated, don’t reply)`;
  const textMessageIsTruncated =
    textMessageBody.length > Glob.get("maxTextMessageCharacters");

  const notEnoughPhoneCredits =
    shouldSendToPhoneNumbers && totalPhoneNumberRecipients > phoneCredits;

  const canSend =
    !!notification.title &&
    Object.values(deliveryMethod).some((m) => m) &&
    !notEnoughPhoneCredits &&
    totalRecipients > 0;

  let everyonePhrase = "everyone";
  if (invitationStatus === "current") everyonePhrase = "all current members";
  else if (invitationStatus === "invited")
    everyonePhrase = "all invited members";

  return (
    <KeyboardAvoidingView
      style={styles.pageContent}
      behavior={Platform.OS === "ios" ? "padding" : "height"}
    >
      <NavBar
        navigation={navigation}
        text="Send Notification"
        RightButton={
          <View style={{ alignItems: "flex-end" }}>
            <HelpButton
              title="Sending Notifications"
              message="Use this to instantly send a message to your users. You can also target notifications to specific groups or account types."
              navigation={navigation}
              videoUrl="https://youtu.be/mnyY2RBG_uM"
            />
          </View>
        }
      />
      <ScrollView
        style={{ paddingHorizontal: 15, width }}
        scrollIndicatorInsets={{ right: 1 }}
        keyboardDismissMode="on-drag"
      >
        <Text
          style={{
            textAlign: "center",
            fontSize: 48,
            fontWeight: "900",
            marginTop: 20,
            color: "#eaeaea"
          }}
        >
          Message
        </Text>
        {isGeneratingTitle ? (
          <View>
            <Text style={[Style.get("headerText"), { marginTop: 20 }]}>
              Message title
            </Text>
            <ActivityIndicator
              size="large"
              style={{ alignSelf: "flex-start", marginTop: 10, marginLeft: 20 }}
            />
          </View>
        ) : (
          <InputBox
            key="title"
            header="Message title"
            placeholder="Required"
            onChangeText={(text) => updateNotificationField("title", text)}
            value={notification.title}
          />
        )}
        {isGeneratingBody ? (
          <View>
            <Text style={[Style.get("headerText"), { marginTop: 20 }]}>
              Message content
            </Text>
            <ActivityIndicator
              size="large"
              style={{ alignSelf: "flex-start", marginTop: 10, marginLeft: 20 }}
            />
          </View>
        ) : (
          <>
            <InputBox
              key="body"
              header="Message content"
              placeholder="Optional"
              onChangeText={(text) => updateNotificationField("body", text)}
              value={notification.body}
              multiline
              onBlur={writeMessageTitleWithAI}
            />
            {!Rex.getConfig()?.aiDisabled &&
              !!notification.title &&
              notification.title.length > 4 &&
              !notification.body && (
                <View
                  style={{
                    alignItems: "flex-end",
                    marginRight: 0.18 * width - 15
                  }}
                >
                  <TouchableLink
                    text="Draft it for me with Onespot AI"
                    type="button"
                    textStyle={{ textDecorationLine: "underline" }}
                    onPress={writeMessageBodyWithAI}
                  />
                </View>
              )}
          </>
        )}

        {!showURL ? (
          <View style={{ marginTop: 20 }}>
            <Button
              small
              text="Add a Link"
              flat
              outline
              align="left"
              onPress={() => setShowURL(true)}
            />
          </View>
        ) : (
          <InputBox
            key="url"
            header="Link"
            placeholder="Optional"
            onChangeText={(text) => updateNotificationField("url", text)}
            value={notification.url}
            navigation={navigation}
            browseForLinkOptions={{
              show: true,
              originalURL: "https://www.google.com", // notification.url
              onPickURL: (newURL) => updateNotificationField("url", newURL),
              disabled: false
            }}
          />
        )}
        <Text
          style={{
            textAlign: "center",
            fontSize: 48,
            fontWeight: "900",
            marginTop: 50,
            color: "#eaeaea"
          }}
        >
          Options
        </Text>
        <Text style={{ ...Style.get("headerText"), marginTop: 10 }}>
          Recipients
        </Text>
        {!showLimitRecipients ? (
          <>
            <Text style={Style.get("subheaderText")}>
              Your message will send to {everyonePhrase}
            </Text>
            <Button
              small
              text="Limit Recipients"
              flat
              outline
              align="left"
              onPress={() => setShowLimitRecipients(true)}
            />
          </>
        ) : (
          <>
            <Text style={Style.get("subheaderText")}>
              Your message will{" "}
              {!showLimitAccountTypes && !showLimitGroups
                ? `send to ${everyonePhrase}. Select an option below to limit the audience`
                : `only send to ${invitationModifier}users in the selected `}
              {showLimitAccountTypes ? "account types" : ""}
              {showLimitAccountTypes && showLimitGroups ? " and " : ""}
              {showLimitGroups ? "groups" : ""}
              {totalRecipients < 1 ? " (no one)" : ""}.
            </Text>
            {notificationPermissionsLimited ? (
              <Text style={{ fontWeight: "bold", marginTop: 6 }}>
                Account types:
              </Text>
            ) : (
              <Checkbox
                iconColor="black"
                checked={showLimitAccountTypes}
                text={
                  <Text style={{ fontSize: 16 }}>
                    Limit to {showLimitAccountTypes ? "these" : "specific"}{" "}
                    <Text style={{ fontWeight: "bold" }}>account types</Text>
                    {showLimitAccountTypes ? ":" : ""}
                  </Text>
                }
                onChange={(checked) => setShowLimitAccountTypes(checked)}
              />
            )}

            {showLimitAccountTypes && !!allAccountTypeCheckboxes && (
              <ScrollView
                style={{
                  maxHeight: height / 2,
                  marginLeft: 35,
                  marginBottom:
                    showLimitAccountTypes && !notificationPermissionsLimited
                      ? 10
                      : 0
                }}
                scrollIndicatorInsets={{ right: 1 }}
              >
                <Multiselect
                  items={allAccountTypeCheckboxes}
                  onToggle={(key, value) =>
                    onToggleAudience(key, value, "accountTypes")
                  }
                />
              </ScrollView>
            )}

            {notificationPermissionsLimited ? (
              <Text style={{ fontWeight: "bold", marginTop: 6 }}>Groups:</Text>
            ) : (
              <Checkbox
                iconColor="black"
                checked={showLimitGroups}
                text={
                  <Text style={{ fontSize: 16 }}>
                    Limit to {showLimitGroups ? "these" : "specific"}{" "}
                    <Text style={{ fontWeight: "bold" }}>groups</Text>
                    {showLimitGroups ? ":" : ""}
                  </Text>
                }
                onChange={(checked) => setShowLimitGroups(checked)}
              />
            )}

            {showLimitGroups && !!allGroupCheckboxes && (
              <ScrollView
                style={{
                  maxHeight: height / 2,
                  marginLeft: 35
                }}
                scrollIndicatorInsets={{ right: 1 }}
              >
                {allGroupCheckboxes.length > 0 ? (
                  <Multiselect
                    items={allGroupCheckboxes}
                    onToggle={(key, value) =>
                      onToggleAudience(key, value, "groups")
                    }
                  />
                ) : (
                  <Text style={{ fontStyle: "italic" }}>
                    (There are no groups)
                  </Text>
                )}
              </ScrollView>
            )}

            {!!pendingInvitationsExist && (
              <>
                <Checkbox
                  iconColor="black"
                  checked={showLimitInvitationStatus}
                  text={
                    <Text style={{ fontSize: 16 }}>
                      Limit by{" "}
                      <Text style={{ fontWeight: "bold" }}>
                        invitation status
                      </Text>
                      {showLimitInvitationStatus ? ":" : ""}
                    </Text>
                  }
                  onChange={(checked) => {
                    onChangeAudienceInvitationStatus("all");
                    setShowLimitInvitationStatus(checked);
                  }}
                />

                {showLimitInvitationStatus && (
                  <ScrollView
                    style={{
                      maxHeight: height / 2,
                      marginLeft: 35
                    }}
                    scrollIndicatorInsets={{ right: 1 }}
                  >
                    <Multiselect
                      radio
                      items={[
                        {
                          key: "all",
                          value:
                            !invitationStatus || invitationStatus === "all",
                          text: "All current & invited members"
                        },
                        {
                          key: "current",
                          value: invitationStatus === "current",
                          text: "Only current members (who joined this app)"
                        },
                        {
                          key: "invited",
                          value: invitationStatus === "invited",
                          text: "Only invited members (who haven't yet joined)"
                        }
                      ]}
                      onToggle={(key) => onChangeAudienceInvitationStatus(key)}
                    />
                  </ScrollView>
                )}
              </>
            )}
          </>
        )}

        {!!Rex.getConfig()?.chatEnabled && (
          <View>
            <Text
              style={{
                ...Style.get("headerText"),
                marginTop: 25
              }}
            >
              Replies
            </Text>
            {!showReplies ? (
              <>
                <Text style={Style.get("subheaderText")}>
                  No one will be allowed to reply to you
                </Text>
                <Button
                  small
                  text="Allow Replies"
                  flat
                  outline
                  align="left"
                  onPress={() => {
                    updateNotificationField("chatEnabled", true);
                    setShowReplies(true);
                  }}
                />
              </>
            ) : (
              <>
                <Checkbox
                  checked={notification.chatEnabled}
                  text="Allow recipients to reply in the app"
                  onChange={(enabled) =>
                    updateNotificationField("chatEnabled", enabled)
                  }
                />
                <HelpText
                  noteStyle
                  text="Replies create a one-on-one chat with you directly. This can be helpful if you're asking for feedback, or if someone might have a question about your notification."
                />
              </>
            )}
          </View>
        )}

        <Text style={{ ...Style.get("headerText"), marginTop: 25 }}>
          Delivery method
        </Text>
        {!showDeliveryMethods && !canTextOrCall ? (
          <>
            <Text style={Style.get("subheaderText")}>
              It will send as a push notification & email
            </Text>
            <Button
              small
              text="Change Method"
              flat
              outline
              align="left"
              onPress={() => setShowDeliveryMethods(true)}
            />
          </>
        ) : (
          <>
            <Checkbox disabled checked text="Message inbox (in the app)" />
            <Checkbox
              checked={deliveryMethod.pushNotification}
              text="Push notification"
              onChange={(checked) =>
                updateNotificationDeliveryMethod("pushNotification", checked)
              }
            />
            <Checkbox
              checked={deliveryMethod.email}
              text="Email"
              onChange={(checked) =>
                updateNotificationDeliveryMethod("email", checked)
              }
            />
          </>
        )}
        {canText && (
          <Checkbox
            checked={deliveryMethod.textMessage}
            text="Text message"
            onChange={(checked) =>
              updateNotificationDeliveryMethod("textMessage", checked)
            }
          />
        )}
        {canCall && (
          <Checkbox
            checked={deliveryMethod.phoneCall}
            text="Phone call"
            onChange={(checked) =>
              updateNotificationDeliveryMethod("phoneCall", checked)
            }
          />
        )}
        {shouldSendToPhoneNumbers && (
          <>
            {loadedAnonymizedUsers ? (
              <HelpText
                text={
                  <Text>
                    This message will send to{" "}
                    <Text style={{ fontWeight: "bold" }}>
                      {totalPhoneNumberRecipients} phone number
                      {totalPhoneNumberRecipients === 1 ? "" : "s"}
                    </Text>
                    {totalPhoneNumberRecipients < 1 ? (
                      "."
                    ) : (
                      <Text>
                        , which will use {totalPhoneNumberRecipients} phone
                        credit
                        {totalPhoneNumberRecipients === 1 ? "" : "s"}. You
                        currently have {phoneCredits} phone credit
                        {phoneCredits === 1 ? "" : "s"}.
                        {textMessageIsTruncated && (
                          <Text style={{ color: Glob.get("dangerRed") }}>
                            {"\n\n"}
                            <Text style={{ fontWeight: "bold" }}>
                              Heads up: Your text message is over{" "}
                              {Glob.get("maxTextMessageCharacters")} characters
                              and will get cut off.
                            </Text>{" "}
                            Check the text message preview below to see how it
                            will look.
                          </Text>
                        )}
                      </Text>
                    )}
                  </Text>
                }
                noteStyle
              />
            ) : (
              <LoadingRecipientsIndicator />
            )}
          </>
        )}

        <Text
          style={{
            textAlign: "center",
            fontSize: 48,
            fontWeight: "900",
            marginTop: 50,
            color: "#eaeaea"
          }}
        >
          Preview
        </Text>

        {loadedAnonymizedUsers ? (
          <HelpText
            noteStyle
            text={
              <Text>
                This message will send to{" "}
                <Text style={{ fontWeight: "bold" }}>
                  {totalRecipients}{" "}
                  {allRecipientsAreInvitations ? "invited " : ""}member
                  {totalRecipients === 1 ? "" : "s"}
                </Text>
                {totalInvitationRecipients > 0 && !allRecipientsAreInvitations
                  ? ` (including ${totalInvitationRecipients} invited member${
                      totalInvitationRecipients === 1 ? "" : "s"
                    })`
                  : ""}
                , i.e. to {audienceMessage()}.{"\n\n"}Note that users'
                notification preferences may vary.
              </Text>
            }
          />
        ) : (
          <View style={{ marginBottom: 30 }}>
            <LoadingRecipientsIndicator />
          </View>
        )}

        {!!deliveryMethod.pushNotification && (
          <>
            <Text
              style={[
                Style.get("headerText"),
                { textAlign: "center", marginTop: 10 }
              ]}
            >
              Push Notification
            </Text>
            <PreviewPushNotification
              title={notification.title}
              body={notification.body}
            />
          </>
        )}

        {deliveryMethod.textMessage && (
          <>
            <Text
              style={[
                Style.get("headerText"),
                { textAlign: "center", marginTop: 25 }
              ]}
            >
              Text Message
            </Text>
            <PreviewTextMessage
              message={textMessage}
              isTruncated={textMessageIsTruncated}
            />
          </>
        )}

        <View style={{ height: 50 }} />
      </ScrollView>
      <View style={bottomContainerStyle}>
        <Button
          text="Send"
          icon="send"
          onPress={sendPushNotification}
          disabled={!canSend}
          style={{ opacity: canSend ? 1 : 0.4 }}
        />
        {!loadedAnonymizedUsers && <LoadingRecipientsIndicator />}
        {loadedAnonymizedUsers && totalRecipients < 1 && (
          <Text
            style={{
              fontSize: 16,
              textAlign: "center",
              color: Glob.get("dangerRed")
            }}
          >
            Based on the recipient limitations you set, zero members would
            receive this message.
          </Text>
        )}
        {loadedAnonymizedUsers && shouldSendToPhoneNumbers && (
          <Text
            style={{
              fontSize: 16,
              textAlign: "center",
              color: notEnoughPhoneCredits ? Glob.get("dangerRed") : "gray",
              fontWeight: notEnoughPhoneCredits ? "bold" : "normal"
            }}
          >
            This {notEnoughPhoneCredits ? "would" : "will"} use{" "}
            <Text
              style={{
                fontWeight: "bold",
                color: notEnoughPhoneCredits ? Glob.get("dangerRed") : "black"
              }}
            >
              {totalPhoneNumberRecipients} phone credit
              {totalPhoneNumberRecipients === 1 ? "" : "s"}
            </Text>{" "}
            (out of {`${phoneCredits}`.toLocaleString()})
          </Text>
        )}
      </View>
    </KeyboardAvoidingView>
  );
}

const styles = StyleSheet.create({
  list: {
    flex: 1
  },
  pageContent: {
    flex: 1,
    alignItems: "center",
    backgroundColor: "white"
  },
  questionDescription: {
    fontSize: 14,
    color: "gray"
  },
  bodyText: {
    fontSize: 16,
    marginVertical: 15
  },
  headerText: {
    fontSize: 20,
    marginTop: 15,
    fontWeight: "bold"
  },
  noteText: {
    fontSize: 12,
    marginBottom: 5,
    textAlign: "center"
  },
  textInput: {
    fontSize: 0.03 * height,
    width: 0.82 * width,
    height: 0.056 * height,
    borderWidth: 1,
    borderColor: Glob.get("light gray"),
    borderRadius: 6,
    paddingLeft: 8,
    marginTop: 8,
    color: "black"
  }
});
