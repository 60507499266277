const legacyIcons = [
  // These were previously png images
  {
    uuid: "academics",
    iconSet: "SimpleLineIcons",
    iconName: "notebook"
  },
  {
    uuid: "book",
    iconSet: "Entypo",
    iconName: "open-book"
  },
  {
    uuid: "bookshelf",
    iconSet: "MaterialCommunityIcons",
    iconName: "bookshelf"
  },
  {
    uuid: "building",
    iconSet: "FontAwesome5",
    iconName: "school"
  },
  {
    uuid: "checklist",
    iconSet: "Foundation",
    iconName: "clipboard-notes"
  },
  {
    uuid: "church",
    iconSet: "FontAwesome5",
    iconName: "church"
  },
  {
    uuid: "library",
    iconSet: "FontAwesome",
    iconName: "bank"
  },
  {
    uuid: "news",
    iconSet: "Ionicons",
    iconName: "newspaper-outline"
  },
  {
    uuid: "research",
    iconSet: "FontAwesome5",
    iconName: "microphone"
  },
  {
    uuid: "sports",
    iconSet: "FontAwesome",
    iconName: "soccer-ball-o"
  },
  {
    uuid: "stats",
    iconSet: "AntDesign",
    iconName: "linechart"
  },

  // These were previously Expo vector icons
  {
    uuid: "clock",
    iconSet: "Ionicons",
    iconName: "alarm-outline"
  },
  {
    uuid: "alum",
    iconSet: "FontAwesome5",
    iconName: "user-graduate"
  },
  {
    uuid: "announcements",
    iconSet: "Ionicons",
    iconName: "megaphone-outline"
  },
  {
    uuid: "verticalArrows",
    iconSet: "FontAwesome",
    iconName: "arrows-v"
  },
  {
    uuid: "bus",
    iconSet: "FontAwesome5",
    iconName: "bus"
  },
  {
    uuid: "calendar",
    iconSet: "AntDesign",
    iconName: "calendar"
  },
  {
    uuid: "camera",
    iconSet: "Feather",
    iconName: "camera"
  },
  {
    uuid: "check",
    iconSet: "Entypo",
    iconName: "check"
  },
  {
    uuid: "code",
    iconSet: "FontAwesome",
    iconName: "code"
  },
  {
    uuid: "coffee",
    iconSet: "Feather",
    iconName: "coffee"
  },
  {
    uuid: "customize",
    iconSet: "MaterialIcons",
    iconName: "dashboard-customize"
  },
  {
    uuid: "combokeeper",
    iconSet: "SimpleLineIcons",
    iconName: "lock"
  },
  {
    uuid: "dining",
    iconSet: "MaterialIcons",
    iconName: "local-dining"
  },
  {
    uuid: "door",
    iconSet: "FontAwesome5",
    iconName: "door-open"
  },
  {
    uuid: "drag",
    iconSet: "MaterialCommunityIcons",
    iconName: "drag"
  },
  {
    uuid: "email",
    iconSet: "MaterialCommunityIcons",
    iconName: "email-outline"
  },
  {
    uuid: "emergency",
    iconSet: "AntDesign",
    iconName: "medicinebox"
  },
  {
    uuid: "eye",
    iconSet: "Ionicons",
    iconName: "eye"
  },
  {
    uuid: "gift",
    iconSet: "Feather",
    iconName: "gift"
  },
  {
    uuid: "home",
    iconSet: "FontAwesome5",
    iconName: "home"
  },
  {
    uuid: "horizontalLine",
    iconSet: "MaterialIcons",
    iconName: "horizontal-rule"
  },
  {
    uuid: "ice_cream",
    iconSet: "Ionicons",
    iconName: "ice-cream-outline"
  },
  {
    uuid: "lightbulb",
    iconSet: "FontAwesome",
    iconName: "lightbulb-o"
  },
  {
    uuid: "bolt",
    iconSet: "MaterialCommunityIcons",
    iconName: "lightning-bolt"
  },
  {
    uuid: "link",
    iconSet: "Feather",
    iconName: "link"
  },
  {
    uuid: "map",
    iconSet: "Entypo",
    iconName: "location"
  },
  {
    uuid: "suggestions",
    iconSet: "AntDesign",
    iconName: "message1"
  },
  {
    uuid: "money",
    iconSet: "MaterialCommunityIcons",
    iconName: "cash-multiple"
  },
  {
    uuid: "magic",
    iconSet: "FontAwesome5",
    iconName: "magic"
  },
  {
    uuid: "send",
    iconSet: "FontAwesome",
    iconName: "send-o"
  },
  {
    uuid: "people",
    iconSet: "FontAwesome5",
    iconName: "users"
  },
  {
    uuid: "person",
    iconSet: "FontAwesome5",
    iconName: "user-alt"
  },
  {
    uuid: "call",
    iconSet: "MaterialIcons",
    iconName: "call"
  },
  {
    uuid: "photos",
    iconSet: "Fontisto",
    iconName: "photograph"
  },
  {
    uuid: "pizza",
    iconSet: "Ionicons",
    iconName: "pizza-outline"
  },
  {
    uuid: "plus",
    iconSet: "Entypo",
    iconName: "plus"
  },
  {
    uuid: "printer",
    iconSet: "AntDesign",
    iconName: "printer"
  },
  {
    uuid: "professional",
    iconSet: "FontAwesome5",
    iconName: "user-tie"
  },
  {
    uuid: "schedule",
    iconSet: "Feather",
    iconName: "clock"
  },
  {
    uuid: "laundry",
    iconSet: "Ionicons",
    iconName: "shirt-outline"
  },
  {
    uuid: "smilingStar",
    iconSet: "MaterialCommunityIcons",
    iconName: "star-face"
  },
  {
    uuid: "sms",
    iconSet: "MaterialCommunityIcons",
    iconName: "cellphone-message"
  },
  {
    uuid: "switchScreens",
    iconSet: "MaterialIcons",
    iconName: "send-to-mobile"
  },
  {
    uuid: "table",
    iconSet: "MaterialCommunityIcons",
    iconName: "table"
  },
  {
    uuid: "tapButton",
    iconSet: "MaterialCommunityIcons",
    iconName: "gesture-tap-button"
  },
  {
    uuid: "teacher",
    iconSet: "FontAwesome5",
    iconName: "chalkboard-teacher"
  },
  {
    uuid: "delete",
    iconSet: "MaterialIcons",
    iconName: "delete"
  },
  {
    uuid: "trophy",
    iconSet: "SimpleLineIcons",
    iconName: "trophy"
  },
  {
    uuid: "upload",
    iconSet: "Feather",
    iconName: "upload"
  },
  {
    uuid: "web",
    iconSet: "MaterialCommunityIcons",
    iconName: "web"
  },
  {
    uuid: "wristwatch",
    iconSet: "Feather",
    iconName: "watch"
  },
  {
    uuid: "edit",
    iconSet: "AntDesign",
    iconName: "form"
  },
  {
    uuid: "reorder",
    iconSet: "Ionicons",
    iconName: "reorder-three-outline"
  },

  // these weren't included in the export for some reason
  {
    uuid: "robot-outline",
    iconSet: "MaterialCommunityIcons",
    iconName: "robot-outline"
  },
  {
    uuid: "robot-happy-outline",
    iconSet: "MaterialCommunityIcons",
    iconName: "robot-happy-outline"
  },
  {
    uuid: "robot-off-outline",
    iconSet: "MaterialCommunityIcons",
    iconName: "robot-off-outline"
  }
];

// From https://github.com/Grassper/react-native-icon-picker/tree/main/src/Icons
const IconCollection = [
  ...legacyIcons,
  {
    uuid: "0296334b-3276-4846-842d-708f289a1da8",
    iconSet: "AntDesign",
    iconName: "stepforward"
  },
  {
    uuid: "4a4fdb90-8313-41cc-8e51-f6b2b0728602",
    iconSet: "AntDesign",
    iconName: "stepbackward"
  },
  {
    uuid: "51127138-12fb-4eaf-b033-93d76e78af95",
    iconSet: "AntDesign",
    iconName: "forward"
  },
  {
    uuid: "07b14ad5-dde9-43a8-bf25-114283ce2d7a",
    iconSet: "AntDesign",
    iconName: "banckward"
  },
  {
    uuid: "86305123-e546-4204-a7bc-58c37477058c",
    iconSet: "AntDesign",
    iconName: "caretright"
  },
  {
    uuid: "72f83eb1-9821-4345-9e39-e9b1c28efb02",
    iconSet: "AntDesign",
    iconName: "caretleft"
  },
  {
    uuid: "b09bd849-213a-40b8-b655-f80976701def",
    iconSet: "AntDesign",
    iconName: "caretdown"
  },
  {
    uuid: "2ffe312e-04ec-44b3-b664-30681a3abf92",
    iconSet: "AntDesign",
    iconName: "caretup"
  },
  {
    uuid: "94adb089-866c-4288-a035-584cacffcdb9",
    iconSet: "AntDesign",
    iconName: "rightcircle"
  },
  {
    uuid: "de3e7644-215e-4663-a970-f72a06cecda4",
    iconSet: "AntDesign",
    iconName: "leftcircle"
  },
  {
    uuid: "002a4b0f-9897-4ff6-b918-07079c324fdc",
    iconSet: "AntDesign",
    iconName: "upcircle"
  },
  {
    uuid: "da06332d-4634-41e3-b542-c1873c060446",
    iconSet: "AntDesign",
    iconName: "downcircle"
  },
  {
    uuid: "5fd35449-81bf-4666-8347-5ea72c9f7d4f",
    iconSet: "AntDesign",
    iconName: "rightcircleo"
  },
  {
    uuid: "4690aedc-100c-4fc0-904d-2d281c20927c",
    iconSet: "AntDesign",
    iconName: "leftcircleo"
  },
  {
    uuid: "a1cdccb4-6d2a-41e5-b2fd-991688fe4f21",
    iconSet: "AntDesign",
    iconName: "upcircleo"
  },
  {
    uuid: "204da7f9-028e-4d12-813b-db1186d532e8",
    iconSet: "AntDesign",
    iconName: "downcircleo"
  },
  {
    uuid: "f2c2dfee-9e15-4bbf-8ffb-88872812d47d",
    iconSet: "AntDesign",
    iconName: "verticleleft"
  },
  {
    uuid: "c6c069e0-1232-4c03-9eaa-274b3dc61f58",
    iconSet: "AntDesign",
    iconName: "verticleright"
  },
  {
    uuid: "18013223-3814-45e0-9450-a1e883253e91",
    iconSet: "AntDesign",
    iconName: "back"
  },
  {
    uuid: "868eda74-f065-4667-bead-4ff9ca4cdc25",
    iconSet: "AntDesign",
    iconName: "retweet"
  },
  {
    uuid: "b110decb-8849-4baa-88bb-0af8e6740222",
    iconSet: "AntDesign",
    iconName: "shrink"
  },
  {
    uuid: "a301fb22-b033-41c1-854e-d8e06c535b64",
    iconSet: "AntDesign",
    iconName: "arrowsalt"
  },
  {
    uuid: "1ed9cfe2-bd4b-42a2-b5c7-77e8ff9481bf",
    iconSet: "AntDesign",
    iconName: "doubleright"
  },
  {
    uuid: "1ac44508-d1b6-4cfa-9936-181727d1506a",
    iconSet: "AntDesign",
    iconName: "doubleleft"
  },
  {
    uuid: "0b5647ca-cb13-4e60-9f68-4589b7033aa6",
    iconSet: "AntDesign",
    iconName: "arrowdown"
  },
  {
    uuid: "968365ed-2f4d-4456-a848-f1d52895b202",
    iconSet: "AntDesign",
    iconName: "arrowup"
  },
  {
    uuid: "e5f05f9d-1cca-4f3e-b17f-7feff0170358",
    iconSet: "AntDesign",
    iconName: "arrowright"
  },
  {
    uuid: "5335d40a-c7f1-4b19-8602-ab579a43c3af",
    iconSet: "AntDesign",
    iconName: "arrowleft"
  },
  {
    uuid: "0aa3518f-57ad-4145-808a-6e7638a89310",
    iconSet: "AntDesign",
    iconName: "down"
  },
  {
    uuid: "45eec277-5c97-47b4-a25e-faf12fac3b54",
    iconSet: "AntDesign",
    iconName: "up"
  },
  {
    uuid: "eb88e7ba-3f89-4649-ad79-dce33bb8e5a8",
    iconSet: "AntDesign",
    iconName: "right"
  },
  {
    uuid: "17c7cb3a-1964-4d19-bb13-995fef8562cd",
    iconSet: "AntDesign",
    iconName: "left"
  },
  {
    uuid: "a48f5b41-4e57-4ce4-a1f8-c1d1dd3ba642",
    iconSet: "AntDesign",
    iconName: "minussquareo"
  },
  {
    uuid: "336a74aa-0ae2-41a8-b859-99d815120a1c",
    iconSet: "AntDesign",
    iconName: "minuscircle"
  },
  {
    uuid: "faabc799-9c1c-4054-99cd-ee7eaa672057",
    iconSet: "AntDesign",
    iconName: "minuscircleo"
  },
  {
    uuid: "21fdf117-e872-464d-a564-386e89e49196",
    iconSet: "AntDesign",
    iconName: "minus"
  },
  {
    uuid: "ed5441f4-990b-47c8-b097-e3f4dc11cb9c",
    iconSet: "AntDesign",
    iconName: "pluscircleo"
  },
  {
    uuid: "480d5756-49fa-40d8-b704-6f7a2c811f4d",
    iconSet: "AntDesign",
    iconName: "pluscircle"
  },
  {
    uuid: "526ab9a9-d9b1-41fc-86f9-cded9f091370",
    iconSet: "AntDesign",
    iconName: "plus"
  },
  {
    uuid: "f36ba3e3-124e-447f-aff1-6b72769470f3",
    iconSet: "AntDesign",
    iconName: "infocirlce"
  },
  {
    uuid: "a69ed234-6b1a-48b8-852e-006241fa5832",
    iconSet: "AntDesign",
    iconName: "infocirlceo"
  },
  {
    uuid: "8df00b3b-c9d6-4b14-9284-13cee209b479",
    iconSet: "AntDesign",
    iconName: "info"
  },
  {
    uuid: "d0fce429-a5e1-4575-97d6-93dd26aa0676",
    iconSet: "AntDesign",
    iconName: "exclamation"
  },
  {
    uuid: "27faa254-aa77-45a1-b80c-1d86d569ec39",
    iconSet: "AntDesign",
    iconName: "exclamationcircle"
  },
  {
    uuid: "c8538506-1c53-457d-a8aa-39870ce6928f",
    iconSet: "AntDesign",
    iconName: "exclamationcircleo"
  },
  {
    uuid: "5c625d6c-7f6c-4604-b08f-eb12ab36d639",
    iconSet: "AntDesign",
    iconName: "closecircle"
  },
  {
    uuid: "50df1c19-1bcc-4771-af6f-b8ce21e3bd98",
    iconSet: "AntDesign",
    iconName: "closecircleo"
  },
  {
    uuid: "3f9332bb-b0b0-45de-a725-862039159b6d",
    iconSet: "AntDesign",
    iconName: "checkcircle"
  },
  {
    uuid: "ae5a55cf-8939-47e0-b0b4-6101d9c83c89",
    iconSet: "AntDesign",
    iconName: "checkcircleo"
  },
  {
    uuid: "c75a9869-82b1-4570-b105-4c0e0f735d94",
    iconSet: "AntDesign",
    iconName: "check"
  },
  {
    uuid: "8f20f056-c494-4f3b-925e-1b950a8434d5",
    iconSet: "AntDesign",
    iconName: "close"
  },
  {
    uuid: "8607f1d1-6680-4d9f-9d55-f432b475f218",
    iconSet: "AntDesign",
    iconName: "customerservice"
  },
  {
    uuid: "d7a6b02c-a4f6-49ef-ba16-eb1adf4b5652",
    iconSet: "AntDesign",
    iconName: "creditcard"
  },
  {
    uuid: "446db603-5e71-4ba4-b713-769fd4f3c959",
    iconSet: "AntDesign",
    iconName: "codesquareo"
  },
  {
    uuid: "b14a9fea-03cb-4593-83fd-464e0571046f",
    iconSet: "AntDesign",
    iconName: "book"
  },
  {
    uuid: "1afff9a3-9e1d-492e-8bc8-eb9867f78ef5",
    iconSet: "AntDesign",
    iconName: "barschart"
  },
  {
    uuid: "1430871f-6b2b-4672-b432-f459cbb97e82",
    iconSet: "AntDesign",
    iconName: "bars"
  },
  {
    uuid: "2e594561-54e0-4894-97d5-5851fb473494",
    iconSet: "AntDesign",
    iconName: "question"
  },
  {
    uuid: "9b96f4fa-835b-4f6b-8769-b67e4dc6ce74",
    iconSet: "AntDesign",
    iconName: "questioncircle"
  },
  {
    uuid: "d6968995-88ba-4c07-a526-ce63417160dd",
    iconSet: "AntDesign",
    iconName: "questioncircleo"
  },
  {
    uuid: "714b5ba6-088c-484a-aaa3-40566dc0dc63",
    iconSet: "AntDesign",
    iconName: "pause"
  },
  {
    uuid: "aa4003a8-a675-48b0-9e75-58d1f296ff2e",
    iconSet: "AntDesign",
    iconName: "pausecircle"
  },
  {
    uuid: "8bfbe015-6d54-4117-84b9-53dc86bb1867",
    iconSet: "AntDesign",
    iconName: "pausecircleo"
  },
  {
    uuid: "0cfefa66-8c77-4a1a-a8f9-0a49aec8239d",
    iconSet: "AntDesign",
    iconName: "clockcircle"
  },
  {
    uuid: "2e22d16d-4b9d-4b4c-97c7-35c6d77461dc",
    iconSet: "AntDesign",
    iconName: "clockcircleo"
  },
  {
    uuid: "a126882b-4d03-49f5-b1ba-e94305d481e5",
    iconSet: "AntDesign",
    iconName: "swap"
  },
  {
    uuid: "2e1eb6cd-3689-4bd4-8dc8-cfa8d9a4e921",
    iconSet: "AntDesign",
    iconName: "swapleft"
  },
  {
    uuid: "8e366733-5c8e-4054-8db6-7857537b9d12",
    iconSet: "AntDesign",
    iconName: "swapright"
  },
  {
    uuid: "19d63288-061b-4a26-b051-a77b580c2ea7",
    iconSet: "AntDesign",
    iconName: "plussquareo"
  },
  {
    uuid: "9eb74271-3ae1-4865-a28d-8db830446bfc",
    iconSet: "AntDesign",
    iconName: "frown"
  },
  {
    uuid: "508600f6-ebc2-4187-bd6f-ff88a4963145",
    iconSet: "AntDesign",
    iconName: "menufold"
  },
  {
    uuid: "00023b3e-20ff-45e4-9846-7acdf6b2bb31",
    iconSet: "AntDesign",
    iconName: "mail"
  },
  {
    uuid: "cb46a183-0424-48bd-a36c-d87cbd6a669d",
    iconSet: "AntDesign",
    iconName: "link"
  },
  {
    uuid: "06429fa4-7621-4522-9be3-570989dd5ec4",
    iconSet: "AntDesign",
    iconName: "areachart"
  },
  {
    uuid: "c4610d00-415b-46a1-9994-8972a9986737",
    iconSet: "AntDesign",
    iconName: "linechart"
  },
  {
    uuid: "102fff0c-8a90-4b0b-9bee-0fe4f41e0029",
    iconSet: "AntDesign",
    iconName: "home"
  },
  {
    uuid: "78a88f87-01cf-424c-b0cc-fa3bda7d88a0",
    iconSet: "AntDesign",
    iconName: "laptop"
  },
  {
    uuid: "82380c58-df2a-410a-a8a0-0c494cfe5886",
    iconSet: "AntDesign",
    iconName: "star"
  },
  {
    uuid: "a55f4b2c-8007-44e9-b7a7-46402a22ac29",
    iconSet: "AntDesign",
    iconName: "staro"
  },
  {
    uuid: "d9caebf0-6313-437d-abf3-9963cc9e8354",
    iconSet: "AntDesign",
    iconName: "filter"
  },
  {
    uuid: "e89435fd-9145-4750-9476-f89cc5ecf961",
    iconSet: "AntDesign",
    iconName: "meho"
  },
  {
    uuid: "99b0e6da-c0ad-4839-a67d-98ca8f4b3276",
    iconSet: "AntDesign",
    iconName: "meh"
  },
  {
    uuid: "e50dae4d-f0eb-41a0-ba7c-bf41ec58ef75",
    iconSet: "AntDesign",
    iconName: "shoppingcart"
  },
  {
    uuid: "3cec6977-6e26-4294-bbed-df38e71b4c0a",
    iconSet: "AntDesign",
    iconName: "save"
  },
  {
    uuid: "656e0fb0-91fb-47f5-ab3a-d89e758152fa",
    iconSet: "AntDesign",
    iconName: "user"
  },
  {
    uuid: "b648e2a8-728a-4eb9-8ee1-aa8aecbc8109",
    iconSet: "AntDesign",
    iconName: "videocamera"
  },
  {
    uuid: "6aae0ab3-6a61-4265-acef-e7eb9ad286fd",
    iconSet: "AntDesign",
    iconName: "totop"
  },
  {
    uuid: "5b46a38f-768a-467b-9592-4de51d15a15c",
    iconSet: "AntDesign",
    iconName: "team"
  },
  {
    uuid: "23b5d88e-9a50-45db-9ab7-c8bb38b78f1a",
    iconSet: "AntDesign",
    iconName: "sharealt"
  },
  {
    uuid: "1e0d3620-ae84-41eb-b940-f664013d5b0d",
    iconSet: "AntDesign",
    iconName: "setting"
  },
  {
    uuid: "ce8118ac-0d37-49bd-a7ad-a1866dfaecb3",
    iconSet: "AntDesign",
    iconName: "picture"
  },
  {
    uuid: "6c363f5a-fdc7-42a8-815b-cde44d4009af",
    iconSet: "AntDesign",
    iconName: "phone"
  },
  {
    uuid: "8660a52a-6596-46b6-a049-5407c2f30610",
    iconSet: "AntDesign",
    iconName: "paperclip"
  },
  {
    uuid: "60cca1e9-3a7e-4cb8-9010-ddbf95c9352f",
    iconSet: "AntDesign",
    iconName: "notification"
  },
  {
    uuid: "ddbf869b-cc2e-492c-a5cc-46e749cbdbec",
    iconSet: "AntDesign",
    iconName: "menuunfold"
  },
  {
    uuid: "97f15215-976b-4366-9f9d-15027f433faf",
    iconSet: "AntDesign",
    iconName: "inbox"
  },
  {
    uuid: "2c26d24f-b8a6-4c18-a09a-3bd1265c5d99",
    iconSet: "AntDesign",
    iconName: "lock"
  },
  {
    uuid: "201cc45b-1e5a-4d89-9a1c-1c63be62962c",
    iconSet: "AntDesign",
    iconName: "qrcode"
  },
  {
    uuid: "f1d2296c-1930-4c6e-af2f-5b889ad4f5ab",
    iconSet: "AntDesign",
    iconName: "tags"
  },
  {
    uuid: "466892f8-75be-4fc5-8368-804bb17e9bcd",
    iconSet: "AntDesign",
    iconName: "tagso"
  },
  {
    uuid: "742dfa47-63bd-4896-95b6-cbc28a12378e",
    iconSet: "AntDesign",
    iconName: "cloudo"
  },
  {
    uuid: "bfb2da30-e254-4a92-b96f-e679bf482c9b",
    iconSet: "AntDesign",
    iconName: "cloud"
  },
  {
    uuid: "291ea9fa-8eaa-46f9-bdcc-15675d874ffa",
    iconSet: "AntDesign",
    iconName: "cloudupload"
  },
  {
    uuid: "18b08f12-801c-4639-801d-d743feec9578",
    iconSet: "AntDesign",
    iconName: "clouddownload"
  },
  {
    uuid: "4fde608c-42fa-4061-8fd9-20c53ff5a078",
    iconSet: "AntDesign",
    iconName: "clouddownloado"
  },
  {
    uuid: "a8bd13aa-40e5-4b91-a8bc-d23658a5e776",
    iconSet: "AntDesign",
    iconName: "clouduploado"
  },
  {
    uuid: "39e3dc67-34cc-4ec4-93a4-23abee94a16a",
    iconSet: "AntDesign",
    iconName: "enviroment"
  },
  {
    uuid: "7b9d9bd5-4647-4409-b6a0-e5ad6afa7883",
    iconSet: "AntDesign",
    iconName: "enviromento"
  },
  {
    uuid: "9c9dfd73-5e3f-47f2-88a6-17f5e12b99f9",
    iconSet: "AntDesign",
    iconName: "eye"
  },
  {
    uuid: "2d246039-a089-4ef0-bf8c-49c7e01e142f",
    iconSet: "AntDesign",
    iconName: "eyeo"
  },
  {
    uuid: "15304a57-255b-46ac-95cc-9780c7a8df7b",
    iconSet: "AntDesign",
    iconName: "camera"
  },
  {
    uuid: "2a936eb2-02b6-4aae-908c-0f8c613c30e7",
    iconSet: "AntDesign",
    iconName: "camerao"
  },
  {
    uuid: "dda38842-180d-4833-a083-38b814e503cb",
    iconSet: "AntDesign",
    iconName: "windows"
  },
  {
    uuid: "3542e529-d908-46d5-8719-3ad5dd061ced",
    iconSet: "AntDesign",
    iconName: "export2"
  },
  {
    uuid: "7e45ae12-5b37-4cc6-a1ba-aa5e6264eee1",
    iconSet: "AntDesign",
    iconName: "export"
  },
  {
    uuid: "8b8e1537-dd0b-47fa-bba4-95559e05efea",
    iconSet: "AntDesign",
    iconName: "circledowno"
  },
  {
    uuid: "88a22ed3-f4ea-428b-8f53-76f659dbd028",
    iconSet: "AntDesign",
    iconName: "circledown"
  },
  {
    uuid: "6c14f6ae-d92c-4254-ba21-2f272e0dcf8b",
    iconSet: "AntDesign",
    iconName: "hdd"
  },
  {
    uuid: "a2007324-f1e1-4e44-904b-cd26ab0e24be",
    iconSet: "AntDesign",
    iconName: "ie"
  },
  {
    uuid: "f2e53379-85ca-48a9-b924-6431eb6d6fcf",
    iconSet: "AntDesign",
    iconName: "delete"
  },
  {
    uuid: "c410bcc5-5c71-4b22-82f0-d2de65256117",
    iconSet: "AntDesign",
    iconName: "enter"
  },
  {
    uuid: "95ac2b03-139b-461d-a5fb-51b18d979d97",
    iconSet: "AntDesign",
    iconName: "pushpino"
  },
  {
    uuid: "2d2e6c54-12ef-4d3c-869e-3acf213dd8e0",
    iconSet: "AntDesign",
    iconName: "pushpin"
  },
  {
    uuid: "d6bc729c-bd64-4072-bee7-bb21bee60f08",
    iconSet: "AntDesign",
    iconName: "heart"
  },
  {
    uuid: "87b8dda0-9300-484f-8ae6-d5cc4547ad60",
    iconSet: "AntDesign",
    iconName: "hearto"
  },
  {
    uuid: "ebd69889-6714-484c-ba5e-91f0cc1c2d4c",
    iconSet: "AntDesign",
    iconName: "smile-circle"
  },
  {
    uuid: "5fcbad4a-8a68-4f0f-992d-bca8432c0c13",
    iconSet: "AntDesign",
    iconName: "smileo"
  },
  {
    uuid: "541b1bb9-ad9f-423b-8730-8d3a16417a25",
    iconSet: "AntDesign",
    iconName: "frowno"
  },
  {
    uuid: "6d3a6e1f-a512-4cf0-8da3-c147b2e64e8d",
    iconSet: "AntDesign",
    iconName: "calculator"
  },
  {
    uuid: "f6b0b664-d3be-4d83-8724-aa67fa9b5621",
    iconSet: "AntDesign",
    iconName: "chrome"
  },
  {
    uuid: "a75aef71-86a8-4293-a5d1-aaab89b36497",
    iconSet: "AntDesign",
    iconName: "github"
  },
  {
    uuid: "479caa69-020b-4032-a714-a51040a687ce",
    iconSet: "AntDesign",
    iconName: "iconfontdesktop"
  },
  {
    uuid: "38bf0937-817e-4abc-902c-4f9b944c6235",
    iconSet: "AntDesign",
    iconName: "caretcircleoup"
  },
  {
    uuid: "6c57789b-db63-4b87-8ce1-ced22aa89cb6",
    iconSet: "AntDesign",
    iconName: "upload"
  },
  {
    uuid: "216034f9-201e-48d8-b232-a998fc9a7d35",
    iconSet: "AntDesign",
    iconName: "download"
  },
  {
    uuid: "7605a177-2bc3-490e-9ee0-0827a920530e",
    iconSet: "AntDesign",
    iconName: "piechart"
  },
  {
    uuid: "57fec5d6-6249-42cf-bd1a-2c024dd53728",
    iconSet: "AntDesign",
    iconName: "lock1"
  },
  {
    uuid: "702b499d-c7b8-4509-acc8-95d54ac3ff7f",
    iconSet: "AntDesign",
    iconName: "unlock"
  },
  {
    uuid: "429d7e40-c268-4210-a838-28f1449665df",
    iconSet: "AntDesign",
    iconName: "windowso"
  },
  {
    uuid: "1fbfa2e4-1cb2-49b3-88b0-eeba1bb0425e",
    iconSet: "AntDesign",
    iconName: "dotchart"
  },
  {
    uuid: "d50574cb-9fce-4659-8428-8bc97331ea89",
    iconSet: "AntDesign",
    iconName: "barchart"
  },
  {
    uuid: "bd839b90-1ab7-46e8-ac3c-10e66febdb94",
    iconSet: "AntDesign",
    iconName: "codesquare"
  },
  {
    uuid: "991f639f-f3aa-4945-bda9-a52936fc8bbf",
    iconSet: "AntDesign",
    iconName: "plussquare"
  },
  {
    uuid: "678ef526-0100-4435-b619-c34e4afce94d",
    iconSet: "AntDesign",
    iconName: "minussquare"
  },
  {
    uuid: "6f46bb60-a9f1-4c62-b331-b9821d5c9a76",
    iconSet: "AntDesign",
    iconName: "closesquare"
  },
  {
    uuid: "418e2dfd-7a64-4a9c-9e7f-dbabf4b81e64",
    iconSet: "AntDesign",
    iconName: "closesquareo"
  },
  {
    uuid: "b8a80304-8597-43d6-a778-a14a9bd4483b",
    iconSet: "AntDesign",
    iconName: "checksquare"
  },
  {
    uuid: "1951024a-888e-44ac-9424-956ef449c618",
    iconSet: "AntDesign",
    iconName: "checksquareo"
  },
  {
    uuid: "0bf53717-8414-495d-8f01-1e571635e99f",
    iconSet: "AntDesign",
    iconName: "fastbackward"
  },
  {
    uuid: "f6b8126e-e621-40b8-bd79-5f53d6f00e4f",
    iconSet: "AntDesign",
    iconName: "fastforward"
  },
  {
    uuid: "c4cd5b69-4f9a-46ef-a5e6-f1da0c3ce8f5",
    iconSet: "AntDesign",
    iconName: "upsquare"
  },
  {
    uuid: "5a3f58c9-cbf2-4bd1-bc4d-3b5959ef18a0",
    iconSet: "AntDesign",
    iconName: "downsquare"
  },
  {
    uuid: "39fdf016-f8af-49e1-ae67-f514f02c509e",
    iconSet: "AntDesign",
    iconName: "leftsquare"
  },
  {
    uuid: "6f047b61-3140-4fb3-9eaa-82b40facbfdf",
    iconSet: "AntDesign",
    iconName: "rightsquare"
  },
  {
    uuid: "ba314cfb-e18a-4d5e-9e5b-c279cc09015b",
    iconSet: "AntDesign",
    iconName: "rightsquareo"
  },
  {
    uuid: "20945e4e-dc22-4896-8314-ca45957b8931",
    iconSet: "AntDesign",
    iconName: "leftsquareo"
  },
  {
    uuid: "c1c114e1-cee1-48a4-ad78-2a3ad4ea0851",
    iconSet: "AntDesign",
    iconName: "down-square-o"
  },
  {
    uuid: "75bde4ae-46ec-45f2-821b-38330e924256",
    iconSet: "AntDesign",
    iconName: "up-square-o"
  },
  {
    uuid: "4998895e-f74a-4e23-be2e-c91c756d42a9",
    iconSet: "AntDesign",
    iconName: "play"
  },
  {
    uuid: "681a176d-0269-4a6b-86ff-02b92e8aa9af",
    iconSet: "AntDesign",
    iconName: "playcircleo"
  },
  {
    uuid: "3a97337d-5799-43ba-821a-5bf52529c37e",
    iconSet: "AntDesign",
    iconName: "tag"
  },
  {
    uuid: "9445366f-b874-47d2-9e69-7a042bfa4f93",
    iconSet: "AntDesign",
    iconName: "tago"
  },
  {
    uuid: "4318ce2b-14fe-4920-ad1c-964072e1be2c",
    iconSet: "AntDesign",
    iconName: "addfile"
  },
  {
    uuid: "2aff35ec-dcda-426d-ae8a-9e7be02c51ef",
    iconSet: "AntDesign",
    iconName: "folder1"
  },
  {
    uuid: "8e1d38f1-bb4e-477a-9f19-04cc52ce2264",
    iconSet: "AntDesign",
    iconName: "file1"
  },
  {
    uuid: "b1c3446b-27dc-4fb6-8dee-66280d82e1dd",
    iconSet: "AntDesign",
    iconName: "switcher"
  },
  {
    uuid: "1e641209-c58a-4637-b04c-bcfd9ddd7b75",
    iconSet: "AntDesign",
    iconName: "addfolder"
  },
  {
    uuid: "06a140cf-13fe-4dc7-b2b2-1696ac041f1a",
    iconSet: "AntDesign",
    iconName: "folderopen"
  },
  {
    uuid: "82586220-eae2-4e53-9476-e1c935b037b8",
    iconSet: "AntDesign",
    iconName: "search1"
  },
  {
    uuid: "c0f40f3c-3d29-4afd-8fc5-185e07a5a7ed",
    iconSet: "AntDesign",
    iconName: "ellipsis1"
  },
  {
    uuid: "b9dcfef6-2cdd-4671-8832-fd3bfb037e5b",
    iconSet: "AntDesign",
    iconName: "calendar"
  },
  {
    uuid: "676f9a8d-712c-48af-8dc3-4552cf42584e",
    iconSet: "AntDesign",
    iconName: "filetext1"
  },
  {
    uuid: "7079f1ba-a0df-4fb6-a124-309524a675e2",
    iconSet: "AntDesign",
    iconName: "copy1"
  },
  {
    uuid: "13e3186b-f261-442c-b983-0b0adde41954",
    iconSet: "AntDesign",
    iconName: "jpgfile1"
  },
  {
    uuid: "52ad79ad-58b4-4469-a676-3e00c9e02c3b",
    iconSet: "AntDesign",
    iconName: "pdffile1"
  },
  {
    uuid: "d2c252c9-6b2b-4e74-b482-71ec1d853b40",
    iconSet: "AntDesign",
    iconName: "exclefile1"
  },
  {
    uuid: "d9174d33-fc5b-4499-911f-4cc36ace27b8",
    iconSet: "AntDesign",
    iconName: "pptfile1"
  },
  {
    uuid: "8dfaba17-ce56-44a3-8b2f-bfb28cad7fb3",
    iconSet: "AntDesign",
    iconName: "unknowfile1"
  },
  {
    uuid: "00aaf844-5ed8-4eaf-99af-e87698e81da5",
    iconSet: "AntDesign",
    iconName: "wordfile1"
  },
  {
    uuid: "f775449c-d72a-46f1-bba9-60cadda62136",
    iconSet: "AntDesign",
    iconName: "dingding"
  },
  {
    uuid: "1a5a1958-acb2-4bb0-8690-06de2983f43a",
    iconSet: "AntDesign",
    iconName: "dingding-o"
  },
  {
    uuid: "33ae0513-6e73-4709-800f-9645c3797df3",
    iconSet: "AntDesign",
    iconName: "mobile1"
  },
  {
    uuid: "acd3ed02-12bc-4415-b978-d2facf0e1e0a",
    iconSet: "AntDesign",
    iconName: "tablet1"
  },
  {
    uuid: "ef583342-0be5-4081-aecb-2cb6ba6b498c",
    iconSet: "AntDesign",
    iconName: "bells"
  },
  {
    uuid: "09c579c0-bcd6-4c41-9ee5-d66f2f570c1e",
    iconSet: "AntDesign",
    iconName: "disconnect"
  },
  {
    uuid: "01687255-1338-4109-b7fa-f71ad0249792",
    iconSet: "AntDesign",
    iconName: "database"
  },
  {
    uuid: "f26bc1ed-9d40-4998-aa70-6954a6e63a22",
    iconSet: "AntDesign",
    iconName: "barcode"
  },
  {
    uuid: "6bbc59f0-8de4-4caa-94e8-4e84c9e8251c",
    iconSet: "AntDesign",
    iconName: "hourglass"
  },
  {
    uuid: "8f40bbd8-364c-4b65-92cf-5a4aa000bcd9",
    iconSet: "AntDesign",
    iconName: "key"
  },
  {
    uuid: "73e26c2c-ba92-4066-ab31-be2bbef50e45",
    iconSet: "AntDesign",
    iconName: "flag"
  },
  {
    uuid: "ad8ba792-b41c-4541-9a28-cad5d72d134a",
    iconSet: "AntDesign",
    iconName: "layout"
  },
  {
    uuid: "b35f745a-738e-4ba4-9bd2-8fbe6a09a844",
    iconSet: "AntDesign",
    iconName: "printer"
  },
  {
    uuid: "04468639-b7fe-4bc4-ad54-c01c818b7063",
    iconSet: "AntDesign",
    iconName: "USB"
  },
  {
    uuid: "eff78dfa-69c5-4683-a688-0e230e895bf9",
    iconSet: "AntDesign",
    iconName: "skin"
  },
  {
    uuid: "c1cb7099-3db7-42fa-bdeb-de0cf78f23f6",
    iconSet: "AntDesign",
    iconName: "tool"
  },
  {
    uuid: "0420d1f5-6623-42bc-a19c-9b0ad7f15d6a",
    iconSet: "AntDesign",
    iconName: "car"
  },
  {
    uuid: "76874264-4548-4dac-b620-e9014d0ac94d",
    iconSet: "AntDesign",
    iconName: "addusergroup"
  },
  {
    uuid: "ad063a92-2642-4a87-9622-7db743d1a809",
    iconSet: "AntDesign",
    iconName: "carryout"
  },
  {
    uuid: "61d31efc-ec2f-49d5-8f08-4eb00b0b571a",
    iconSet: "AntDesign",
    iconName: "deleteuser"
  },
  {
    uuid: "40de5cd2-7ca6-45be-8d80-5a9922c414fc",
    iconSet: "AntDesign",
    iconName: "deleteusergroup"
  },
  {
    uuid: "191219d1-7795-4ba4-99be-82df034d97a8",
    iconSet: "AntDesign",
    iconName: "man"
  },
  {
    uuid: "0d7459ac-1f6e-480c-a841-30ac834f8406",
    iconSet: "AntDesign",
    iconName: "isv"
  },
  {
    uuid: "88aef074-7912-43d5-a718-b41b4ffff98b",
    iconSet: "AntDesign",
    iconName: "gift"
  },
  {
    uuid: "b8412f54-acd9-40ea-99cb-4d58bc0e2ff2",
    iconSet: "AntDesign",
    iconName: "idcard"
  },
  {
    uuid: "7711f236-be2c-414d-8531-d95df599d4b9",
    iconSet: "AntDesign",
    iconName: "medicinebox"
  },
  {
    uuid: "d83ca4cd-9695-4d0c-a20f-4a07845baa12",
    iconSet: "AntDesign",
    iconName: "redenvelopes"
  },
  {
    uuid: "85ec97f4-ff7f-4ecb-8178-03e1c3522dfd",
    iconSet: "AntDesign",
    iconName: "rest"
  },
  {
    uuid: "b4177384-9a31-4c3c-a596-b44fe66939c2",
    iconSet: "AntDesign",
    iconName: "Safety"
  },
  {
    uuid: "28609d3a-2025-4745-bd03-b77025c043a9",
    iconSet: "AntDesign",
    iconName: "wallet"
  },
  {
    uuid: "bf1dba0e-0216-456e-aad9-23afb040c246",
    iconSet: "AntDesign",
    iconName: "woman"
  },
  {
    uuid: "9f38af00-5925-46cb-9556-21da2fc685c2",
    iconSet: "AntDesign",
    iconName: "adduser"
  },
  {
    uuid: "8418177d-1e2c-4e68-809a-f2556cf0b405",
    iconSet: "AntDesign",
    iconName: "bank"
  },
  {
    uuid: "0b3edf02-a1be-4d6c-9c04-bd19c1e7b86a",
    iconSet: "AntDesign",
    iconName: "Trophy"
  },
  {
    uuid: "f2c96b19-5ebb-49bf-a6a8-6c781170c7f9",
    iconSet: "AntDesign",
    iconName: "loading1"
  },
  {
    uuid: "3db1fc07-76b5-436d-b368-1abc4694a95e",
    iconSet: "AntDesign",
    iconName: "loading2"
  },
  {
    uuid: "b132df00-95a1-44e5-8507-1d431af571ac",
    iconSet: "AntDesign",
    iconName: "like2"
  },
  {
    uuid: "5e66d2ff-1d49-437c-a285-d32a9e614f03",
    iconSet: "AntDesign",
    iconName: "dislike2"
  },
  {
    uuid: "ac8e2a4b-4952-42ea-98c6-6e9c398354ee",
    iconSet: "AntDesign",
    iconName: "like1"
  },
  {
    uuid: "cfc0813c-530a-4a13-89ec-bb355b2f683b",
    iconSet: "AntDesign",
    iconName: "dislike1"
  },
  {
    uuid: "dedb5f7f-5021-4787-8401-f29a1b52d31e",
    iconSet: "AntDesign",
    iconName: "bulb1"
  },
  {
    uuid: "1c9df938-7f0a-417c-bd07-fb46150f336e",
    iconSet: "AntDesign",
    iconName: "rocket1"
  },
  {
    uuid: "1f9f0cd2-41d3-48c8-b64b-eaefe2470163",
    iconSet: "AntDesign",
    iconName: "select1"
  },
  {
    uuid: "be7bfd50-e1c8-4b2e-9b18-3eb3733109b4",
    iconSet: "AntDesign",
    iconName: "apple1"
  },
  {
    uuid: "87cb3a02-0fc6-4a26-bdb8-647d0acf169c",
    iconSet: "AntDesign",
    iconName: "apple-o"
  },
  {
    uuid: "ffed29d2-213f-4651-bbf8-0c7d8eb32b07",
    iconSet: "AntDesign",
    iconName: "android1"
  },
  {
    uuid: "f56cef99-20c5-4d87-bdc5-5f2d94ebcb8f",
    iconSet: "AntDesign",
    iconName: "android"
  },
  {
    uuid: "316b2f36-9fff-4035-a461-df7fe155932a",
    iconSet: "AntDesign",
    iconName: "aliwangwang-o1"
  },
  {
    uuid: "27bf0149-a507-40dd-b24d-5169139aed6a",
    iconSet: "AntDesign",
    iconName: "aliwangwang"
  },
  {
    uuid: "a48e5d7c-c3c8-4161-9270-b2f7cee2e258",
    iconSet: "AntDesign",
    iconName: "pay-circle1"
  },
  {
    uuid: "c7d533fa-89cb-4cc0-8490-2665a91281a2",
    iconSet: "AntDesign",
    iconName: "pay-circle-o1"
  },
  {
    uuid: "94995867-7a33-4177-a9c9-80e1930d8e4a",
    iconSet: "AntDesign",
    iconName: "poweroff"
  },
  {
    uuid: "9b938bae-8445-4b83-8b03-1c4bc8448de8",
    iconSet: "AntDesign",
    iconName: "trademark"
  },
  {
    uuid: "6f3f3cf6-9084-4e9a-97bb-fefe6a170587",
    iconSet: "AntDesign",
    iconName: "find"
  },
  {
    uuid: "a2a2658c-fc64-412e-b479-083c68b61f50",
    iconSet: "AntDesign",
    iconName: "copyright"
  },
  {
    uuid: "3501f568-6d99-4b80-8724-4bce86c7511b",
    iconSet: "AntDesign",
    iconName: "sound"
  },
  {
    uuid: "27af56c4-62a3-4edb-bdec-aaf15ad08f6b",
    iconSet: "AntDesign",
    iconName: "earth"
  },
  {
    uuid: "09d137bd-46cf-48cf-882c-7745dc40a302",
    iconSet: "AntDesign",
    iconName: "wifi"
  },
  {
    uuid: "02c72a67-5fab-4365-9b4b-f54fb58c7db0",
    iconSet: "AntDesign",
    iconName: "sync"
  },
  {
    uuid: "e9c11270-4b40-4255-895d-12479709e089",
    iconSet: "AntDesign",
    iconName: "login"
  },
  {
    uuid: "09448c1c-b240-4eea-bb4b-2d515a78f584",
    iconSet: "AntDesign",
    iconName: "logout"
  },
  {
    uuid: "003544a1-646a-4677-91ec-02d74bbb7258",
    iconSet: "AntDesign",
    iconName: "reload1"
  },
  {
    uuid: "45f0f827-0438-4cb1-86c0-c511e24732f0",
    iconSet: "AntDesign",
    iconName: "message1"
  },
  {
    uuid: "106529b0-d698-4514-af6a-20cc0ecc28dd",
    iconSet: "AntDesign",
    iconName: "shake"
  },
  {
    uuid: "b7371847-ef7f-48ca-a6b4-8926280f1267",
    iconSet: "AntDesign",
    iconName: "API"
  },
  {
    uuid: "6d738fbd-2a88-4e49-b20f-a2eb58002e38",
    iconSet: "AntDesign",
    iconName: "appstore-o"
  },
  {
    uuid: "f8ec7f84-154e-47a3-b96a-8f3abf0caabf",
    iconSet: "AntDesign",
    iconName: "appstore1"
  },
  {
    uuid: "1255bc6f-1f4f-45ef-af2b-ffecc269986f",
    iconSet: "AntDesign",
    iconName: "scan1"
  },
  {
    uuid: "d99af589-6b5e-489d-8886-fff82c39617d",
    iconSet: "AntDesign",
    iconName: "exception1"
  },
  {
    uuid: "05475c18-1a66-4e08-9183-8b10b5b6de55",
    iconSet: "AntDesign",
    iconName: "contacts"
  },
  {
    uuid: "c3cbf6fa-7848-40c8-a04c-6a12656df0c0",
    iconSet: "AntDesign",
    iconName: "solution1"
  },
  {
    uuid: "2b9a887a-2675-4b64-88b8-6fe8275a748d",
    iconSet: "AntDesign",
    iconName: "fork"
  },
  {
    uuid: "04f05481-e83a-498d-80d7-ec41a42796db",
    iconSet: "AntDesign",
    iconName: "edit"
  },
  {
    uuid: "d811e0a3-d862-4a37-b6a7-c5d00a6ec7c1",
    iconSet: "AntDesign",
    iconName: "form"
  },
  {
    uuid: "c8950169-8345-485c-b21d-d872d8a043c7",
    iconSet: "AntDesign",
    iconName: "warning"
  },
  {
    uuid: "5cec47e8-4e6c-4bc6-9bb3-8d80e649cc34",
    iconSet: "AntDesign",
    iconName: "table"
  },
  {
    uuid: "4f9ef43c-4104-4da1-bab3-410fb14644c2",
    iconSet: "AntDesign",
    iconName: "profile"
  },
  {
    uuid: "20cbc528-0097-43c7-a5fd-61f4668e50e7",
    iconSet: "AntDesign",
    iconName: "dashboard"
  },
  {
    uuid: "28e4e0a4-c9da-40d9-9dc7-9c78c1f8acfb",
    iconSet: "AntDesign",
    iconName: "indent-left"
  },
  {
    uuid: "edb50b26-cdea-48cc-8c83-fce15a97ba56",
    iconSet: "AntDesign",
    iconName: "indent-right"
  },
  {
    uuid: "8a7826e8-aefc-4c61-be43-2430669d20ff",
    iconSet: "AntDesign",
    iconName: "menu-unfold"
  },
  {
    uuid: "a3cdf734-d60c-4323-80ed-e1a49ba4bc65",
    iconSet: "AntDesign",
    iconName: "menu-fold"
  },
  {
    uuid: "2716f8df-53cc-471a-8742-0695b5747945",
    iconSet: "AntDesign",
    iconName: "antdesign"
  },
  {
    uuid: "91b6d9fc-2e59-462d-a79f-3d4f283b1f09",
    iconSet: "AntDesign",
    iconName: "alipay-square"
  },
  {
    uuid: "f137e13b-6a96-40c5-a7bd-e647c0389f8c",
    iconSet: "AntDesign",
    iconName: "codepen-circle"
  },
  {
    uuid: "864386b6-3422-4981-ba27-a97d9a9a6228",
    iconSet: "AntDesign",
    iconName: "google"
  },
  {
    uuid: "a017a4eb-7b94-4fc9-9cbb-ef40226e3a94",
    iconSet: "AntDesign",
    iconName: "amazon"
  },
  {
    uuid: "93f20784-84b0-43d1-8e63-39f8d7a69971",
    iconSet: "AntDesign",
    iconName: "codepen"
  },
  {
    uuid: "57afedc8-1ec1-4968-8b09-df6f8c3c7199",
    iconSet: "AntDesign",
    iconName: "facebook-square"
  },
  {
    uuid: "21ee8193-9617-4b75-b9c3-676b81bb2aa7",
    iconSet: "AntDesign",
    iconName: "dropbox"
  },
  {
    uuid: "ee033100-d942-479e-89c6-bb032097ec02",
    iconSet: "AntDesign",
    iconName: "googleplus"
  },
  {
    uuid: "f7cf8d4d-abe7-49b2-abc4-2f1503540818",
    iconSet: "AntDesign",
    iconName: "linkedin-square"
  },
  {
    uuid: "18edceb9-98e1-4be6-8f16-1c51930eac39",
    iconSet: "AntDesign",
    iconName: "medium-monogram"
  },
  {
    uuid: "576d89a7-fbf1-4887-98b6-4a2ec97a5f49",
    iconSet: "AntDesign",
    iconName: "gitlab"
  },
  {
    uuid: "222b8f34-ed97-4259-9951-421b24a6206d",
    iconSet: "AntDesign",
    iconName: "medium-wordmark"
  },
  {
    uuid: "96d6358d-a835-477a-bff0-d210c52bb88e",
    iconSet: "AntDesign",
    iconName: "QQ"
  },
  {
    uuid: "babdb65f-89c6-498f-b310-792ffc525369",
    iconSet: "AntDesign",
    iconName: "skype"
  },
  {
    uuid: "64e01a04-05f7-49b4-9703-9c59681902c8",
    iconSet: "AntDesign",
    iconName: "taobao-square"
  },
  {
    uuid: "92c962e2-0292-44a4-a1d5-fb3856946c3e",
    iconSet: "AntDesign",
    iconName: "alipay-circle"
  },
  {
    uuid: "89e6ea42-2f02-4dfc-9bdc-ac06aeafff4a",
    iconSet: "AntDesign",
    iconName: "youtube"
  },
  {
    uuid: "7f54575c-2fda-4044-b78e-5e580614a466",
    iconSet: "AntDesign",
    iconName: "wechat"
  },
  {
    uuid: "30176c8e-a841-42a3-bf88-118b6b9ff580",
    iconSet: "AntDesign",
    iconName: "twitter"
  },
  {
    uuid: "b32c7beb-2e32-4153-8353-873638f01080",
    iconSet: "AntDesign",
    iconName: "weibo"
  },
  {
    uuid: "8c8163ae-0f7b-44ae-a127-af4c06db438e",
    iconSet: "AntDesign",
    iconName: "HTML"
  },
  {
    uuid: "422b4957-15e5-4819-8d83-ab9660097b45",
    iconSet: "AntDesign",
    iconName: "taobao-circle"
  },
  {
    uuid: "6be0e721-0879-4566-8083-9006f57fd8b5",
    iconSet: "AntDesign",
    iconName: "weibo-circle"
  },
  {
    uuid: "09f27903-0204-4e1c-9b31-224570aa353f",
    iconSet: "AntDesign",
    iconName: "weibo-square"
  },
  {
    uuid: "5cb6756c-8b35-4997-be00-37fc33d04905",
    iconSet: "AntDesign",
    iconName: "CodeSandbox"
  },
  {
    uuid: "080c3853-02ae-45ac-95c8-3f423b4d414f",
    iconSet: "AntDesign",
    iconName: "aliyun"
  },
  {
    uuid: "b90e58fd-8aa8-42c6-a8bc-4994bb89a6b8",
    iconSet: "AntDesign",
    iconName: "zhihu"
  },
  {
    uuid: "34f47125-aa05-4bf3-886a-97daec4df511",
    iconSet: "AntDesign",
    iconName: "behance"
  },
  {
    uuid: "f9b2c504-7051-4ca0-bfd0-d15963195c2f",
    iconSet: "AntDesign",
    iconName: "dribbble"
  },
  {
    uuid: "0bdf7944-060c-4abf-b2b3-38ceedf2d281",
    iconSet: "AntDesign",
    iconName: "dribbble-square"
  },
  {
    uuid: "b3df3a33-105d-4757-b1d3-4ab7e8ccae5e",
    iconSet: "AntDesign",
    iconName: "behance-square"
  },
  {
    uuid: "18b47737-acab-4d14-8384-83a22138156b",
    iconSet: "AntDesign",
    iconName: "file-markdown"
  },
  {
    uuid: "19aa059a-6f61-4bc3-a791-f4cce655e9ed",
    iconSet: "AntDesign",
    iconName: "instagram"
  },
  {
    uuid: "6beabc24-516f-41e0-b6fb-cdaf03ba304c",
    iconSet: "AntDesign",
    iconName: "yuque"
  },
  {
    uuid: "4a8b26b8-d852-4ce7-8cd0-00479da03fd6",
    iconSet: "AntDesign",
    iconName: "slack"
  },
  {
    uuid: "962f4369-1f46-4362-ab19-11f70e1d2628",
    iconSet: "AntDesign",
    iconName: "slack-square"
  },
  {
    uuid: "b960a6ba-0fb5-4a60-985b-ab42d352f85f",
    iconSet: "Entypo",
    iconName: "500px"
  },
  {
    uuid: "fbcce5e6-1c04-4029-a5d9-7aa18a1bf346",
    iconSet: "Entypo",
    iconName: "500px-with-circle"
  },
  {
    uuid: "ea55942c-8d40-494a-96b8-23114c7f1c75",
    iconSet: "Entypo",
    iconName: "add-to-list"
  },
  {
    uuid: "053e3ae7-01ba-498c-a0d7-33d170b2a4f0",
    iconSet: "Entypo",
    iconName: "add-user"
  },
  {
    uuid: "6af61fbf-de5f-45e9-8e60-75bcbd03d107",
    iconSet: "Entypo",
    iconName: "address"
  },
  {
    uuid: "225b1f27-0089-4980-89ee-e268f90c2a39",
    iconSet: "Entypo",
    iconName: "adjust"
  },
  {
    uuid: "18ece20a-36ba-4d1e-bf1a-cd97f9e68ab2",
    iconSet: "Entypo",
    iconName: "air"
  },
  {
    uuid: "7e854392-7f75-4e5c-81dc-b5c3e118ff4f",
    iconSet: "Entypo",
    iconName: "aircraft"
  },
  {
    uuid: "191d6398-5394-4e3e-ad17-f07675f87692",
    iconSet: "Entypo",
    iconName: "aircraft-landing"
  },
  {
    uuid: "9663a507-500c-4e76-9b8b-8571fdae0661",
    iconSet: "Entypo",
    iconName: "aircraft-take-off"
  },
  {
    uuid: "7c6a249d-1868-46f2-bf5b-1fa8272c4ff9",
    iconSet: "Entypo",
    iconName: "align-bottom"
  },
  {
    uuid: "56a0b866-3b3d-4104-bd64-58fc6100f985",
    iconSet: "Entypo",
    iconName: "align-horizontal-middle"
  },
  {
    uuid: "0a08ed46-4e79-4dfe-aefe-4aa7908607bb",
    iconSet: "Entypo",
    iconName: "align-left"
  },
  {
    uuid: "f622c385-2f29-4e1e-b8cb-94410fbff195",
    iconSet: "Entypo",
    iconName: "align-right"
  },
  {
    uuid: "3954e0bb-b662-4f88-9e5b-8b4a1a471798",
    iconSet: "Entypo",
    iconName: "align-top"
  },
  {
    uuid: "c7320791-2634-428a-8b5a-46dfc2172c5c",
    iconSet: "Entypo",
    iconName: "align-vertical-middle"
  },
  {
    uuid: "7d9cc5f6-7bcb-4e75-81c5-53cf3a98c8e1",
    iconSet: "Entypo",
    iconName: "app-store"
  },
  {
    uuid: "2c97b7ca-7e95-4c93-9c6d-7936c08cb7a5",
    iconSet: "Entypo",
    iconName: "archive"
  },
  {
    uuid: "8659875b-1b5b-4dd3-9cc7-7c9773e921fe",
    iconSet: "Entypo",
    iconName: "area-graph"
  },
  {
    uuid: "4576fc05-e52b-4981-9982-397dc8a16922",
    iconSet: "Entypo",
    iconName: "arrow-bold-down"
  },
  {
    uuid: "828dbc5c-93d3-47a0-bf9d-fbf333d5812e",
    iconSet: "Entypo",
    iconName: "arrow-bold-left"
  },
  {
    uuid: "ae8349c6-3f66-49a7-8660-6a904c14ccc1",
    iconSet: "Entypo",
    iconName: "arrow-bold-right"
  },
  {
    uuid: "012077a5-7a46-439c-8dd0-af384292b458",
    iconSet: "Entypo",
    iconName: "arrow-bold-up"
  },
  {
    uuid: "af4747f1-0236-45b8-95d6-187a2c641592",
    iconSet: "Entypo",
    iconName: "arrow-down"
  },
  {
    uuid: "9e6f5baa-5230-48fe-b5f1-acf836884734",
    iconSet: "Entypo",
    iconName: "arrow-left"
  },
  {
    uuid: "fca09768-c42a-4407-a226-90e9e4ca2d72",
    iconSet: "Entypo",
    iconName: "arrow-long-down"
  },
  {
    uuid: "8671a866-af78-4de1-bdb2-ee37189195a2",
    iconSet: "Entypo",
    iconName: "arrow-long-left"
  },
  {
    uuid: "206c415d-77ad-4d04-8d95-42fe8f435472",
    iconSet: "Entypo",
    iconName: "arrow-long-right"
  },
  {
    uuid: "c58f6f77-a534-47ad-9d15-dd6f8be314cb",
    iconSet: "Entypo",
    iconName: "arrow-long-up"
  },
  {
    uuid: "660e93f1-bad5-4844-a44f-e3e4a72f66b0",
    iconSet: "Entypo",
    iconName: "arrow-right"
  },
  {
    uuid: "151b002b-06c5-42fd-bfd0-c390e2ee4491",
    iconSet: "Entypo",
    iconName: "arrow-up"
  },
  {
    uuid: "4df62c33-afde-4c96-9c02-e1e378043078",
    iconSet: "Entypo",
    iconName: "arrow-with-circle-down"
  },
  {
    uuid: "21447f4f-04ad-491d-b50b-48e4a1139b71",
    iconSet: "Entypo",
    iconName: "arrow-with-circle-left"
  },
  {
    uuid: "4992f2c5-f9a3-4569-bc4f-145e9a70619d",
    iconSet: "Entypo",
    iconName: "arrow-with-circle-right"
  },
  {
    uuid: "70826e61-eda7-4159-adb2-918e2e3d7a77",
    iconSet: "Entypo",
    iconName: "arrow-with-circle-up"
  },
  {
    uuid: "24dbd636-5cc3-4595-8d03-2f7045fa0e72",
    iconSet: "Entypo",
    iconName: "attachment"
  },
  {
    uuid: "6c770818-ad16-497f-9729-eedbb027ea6d",
    iconSet: "Entypo",
    iconName: "awareness-ribbon"
  },
  {
    uuid: "630b4100-ffcb-4b37-ab8c-2dabd97948e8",
    iconSet: "Entypo",
    iconName: "back"
  },
  {
    uuid: "85b83ba2-a1a5-4ca1-976c-6ff366cee72b",
    iconSet: "Entypo",
    iconName: "back-in-time"
  },
  {
    uuid: "81573c32-faaf-4b05-bc5a-1454a0bbce1e",
    iconSet: "Entypo",
    iconName: "baidu"
  },
  {
    uuid: "bb826e8d-beb2-41d4-af6b-213bb7618d34",
    iconSet: "Entypo",
    iconName: "bar-graph"
  },
  {
    uuid: "98943e3f-c9e3-428a-b53a-1b39bcafbeff",
    iconSet: "Entypo",
    iconName: "basecamp"
  },
  {
    uuid: "e852bf45-f8bc-402c-b3d0-492b0912e40a",
    iconSet: "Entypo",
    iconName: "battery"
  },
  {
    uuid: "bdb9b763-04ba-4e9e-a672-a01720cb9d39",
    iconSet: "Entypo",
    iconName: "beamed-note"
  },
  {
    uuid: "4f4b0533-5a65-4063-afa6-15c560e063ad",
    iconSet: "Entypo",
    iconName: "behance"
  },
  {
    uuid: "ac8c0f1a-92a4-4d7a-b030-d785dfbf84c6",
    iconSet: "Entypo",
    iconName: "bell"
  },
  {
    uuid: "63c78fb2-d6c4-4026-96bf-c429e24ac095",
    iconSet: "Entypo",
    iconName: "blackboard"
  },
  {
    uuid: "99b9949f-8f03-484e-bdd2-f0c0787dcc88",
    iconSet: "Entypo",
    iconName: "block"
  },
  {
    uuid: "0f7e2683-8875-4b31-b4fe-d8a16d1c9f8d",
    iconSet: "Entypo",
    iconName: "book"
  },
  {
    uuid: "375e64f3-cd7e-41a9-a8d2-cb6800614f6a",
    iconSet: "Entypo",
    iconName: "bookmark"
  },
  {
    uuid: "be03d9f1-4e6c-4739-874b-837f364808b9",
    iconSet: "Entypo",
    iconName: "bookmarks"
  },
  {
    uuid: "4ff87fa9-ad82-4c12-b52b-daead13270c4",
    iconSet: "Entypo",
    iconName: "bowl"
  },
  {
    uuid: "3b1464bb-aca4-4423-8251-2abd8b5fba3d",
    iconSet: "Entypo",
    iconName: "box"
  },
  {
    uuid: "219ad146-c5be-4ba3-b1e4-3d32a07b2acd",
    iconSet: "Entypo",
    iconName: "briefcase"
  },
  {
    uuid: "bb32311b-6b44-41a6-9fce-8c6e8b07b80f",
    iconSet: "Entypo",
    iconName: "browser"
  },
  {
    uuid: "dbbe1d63-b4e2-4183-9cb0-19056b6f3b31",
    iconSet: "Entypo",
    iconName: "brush"
  },
  {
    uuid: "3a50e5a4-a8b3-4190-a768-18b869b078b6",
    iconSet: "Entypo",
    iconName: "bucket"
  },
  {
    uuid: "d63007c8-bf47-4db7-9c12-907d17f6b573",
    iconSet: "Entypo",
    iconName: "bug"
  },
  {
    uuid: "43bca516-cf47-4d82-b1c9-14a6dece110c",
    iconSet: "Entypo",
    iconName: "cake"
  },
  {
    uuid: "be5dc9cc-c93c-42d3-ad1d-d1b90bafb175",
    iconSet: "Entypo",
    iconName: "calculator"
  },
  {
    uuid: "9b73c93e-605c-45f4-be72-386958a48fbb",
    iconSet: "Entypo",
    iconName: "calendar"
  },
  {
    uuid: "1df444e8-1dbd-425c-9141-558eed394a4a",
    iconSet: "Entypo",
    iconName: "camera"
  },
  {
    uuid: "9c88c045-39cf-4f19-aec1-6560d52d0a62",
    iconSet: "Entypo",
    iconName: "ccw"
  },
  {
    uuid: "410765d9-0bd4-49a7-b1c0-7425d3132a81",
    iconSet: "Entypo",
    iconName: "chat"
  },
  {
    uuid: "6854695d-da3a-4275-8702-728c44de314f",
    iconSet: "Entypo",
    iconName: "check"
  },
  {
    uuid: "aba344c6-435e-4da8-965f-54ed12a04792",
    iconSet: "Entypo",
    iconName: "chevron-down"
  },
  {
    uuid: "c78be427-0fac-441e-a5a6-282fac3eff56",
    iconSet: "Entypo",
    iconName: "chevron-left"
  },
  {
    uuid: "5429cf21-f257-4ada-964f-a34e34e1b541",
    iconSet: "Entypo",
    iconName: "chevron-right"
  },
  {
    uuid: "50ad4f25-04a0-4cce-b1a2-823fe6f70c2d",
    iconSet: "Entypo",
    iconName: "chevron-small-down"
  },
  {
    uuid: "9be85b68-576b-40ed-8123-369050afe447",
    iconSet: "Entypo",
    iconName: "chevron-small-left"
  },
  {
    uuid: "2ef6b226-9080-4128-b9f5-9eb6784bfa49",
    iconSet: "Entypo",
    iconName: "chevron-small-right"
  },
  {
    uuid: "033a078c-091b-4dd8-b7a0-c05dd3fc18a9",
    iconSet: "Entypo",
    iconName: "chevron-small-up"
  },
  {
    uuid: "6ee27e2f-1f22-471d-a119-14b1178d796b",
    iconSet: "Entypo",
    iconName: "chevron-thin-down"
  },
  {
    uuid: "6b382dc7-c94e-413f-a2d4-8fdc09e17642",
    iconSet: "Entypo",
    iconName: "chevron-thin-left"
  },
  {
    uuid: "b97419fb-1b4d-43d6-a5a5-17cba3d028d5",
    iconSet: "Entypo",
    iconName: "chevron-thin-right"
  },
  {
    uuid: "8fa5bf03-c992-4baf-8d4d-53b33f3faf4a",
    iconSet: "Entypo",
    iconName: "chevron-thin-up"
  },
  {
    uuid: "35eeba6c-2f77-4616-9dd5-e11ff6a22732",
    iconSet: "Entypo",
    iconName: "chevron-up"
  },
  {
    uuid: "5cc25149-4922-4c71-b3c1-85e902186baf",
    iconSet: "Entypo",
    iconName: "chevron-with-circle-down"
  },
  {
    uuid: "af31ffaa-7cde-497c-8ec0-00ee882cfa7a",
    iconSet: "Entypo",
    iconName: "chevron-with-circle-left"
  },
  {
    uuid: "09526ccb-0fb8-409e-bf9e-8f76966819c1",
    iconSet: "Entypo",
    iconName: "chevron-with-circle-right"
  },
  {
    uuid: "b71f21d3-760d-478e-8576-91732320f6a1",
    iconSet: "Entypo",
    iconName: "chevron-with-circle-up"
  },
  {
    uuid: "56e63cd7-d018-40dc-9909-30c5046923cc",
    iconSet: "Entypo",
    iconName: "circle"
  },
  {
    uuid: "707938d8-e04b-4742-a091-3ace9f3e8f51",
    iconSet: "Entypo",
    iconName: "circle-with-cross"
  },
  {
    uuid: "6347677b-4f35-476f-864e-951c82c6c3ac",
    iconSet: "Entypo",
    iconName: "circle-with-minus"
  },
  {
    uuid: "16a64f06-e1f3-40b9-b835-8c9c38eeb0ec",
    iconSet: "Entypo",
    iconName: "circle-with-plus"
  },
  {
    uuid: "7ef25a0a-39ba-4548-9ab7-59ab3e38bf45",
    iconSet: "Entypo",
    iconName: "circular-graph"
  },
  {
    uuid: "16b6c94e-84dd-4963-89d7-6b44d9c6f631",
    iconSet: "Entypo",
    iconName: "clapperboard"
  },
  {
    uuid: "ed3faa17-bc3e-4ff0-be11-4e6c9c4b1181",
    iconSet: "Entypo",
    iconName: "classic-computer"
  },
  {
    uuid: "6cda5a5f-2204-4086-90d3-ea6710559254",
    iconSet: "Entypo",
    iconName: "clipboard"
  },
  {
    uuid: "5465999d-8334-470b-96a9-3e43499afa76",
    iconSet: "Entypo",
    iconName: "clock"
  },
  {
    uuid: "c5bfb5cb-5880-40c5-9125-fb8614fc383d",
    iconSet: "Entypo",
    iconName: "cloud"
  },
  {
    uuid: "ab78bc56-597d-4a34-a20b-d1484dd4418a",
    iconSet: "Entypo",
    iconName: "code"
  },
  {
    uuid: "329dd835-cec1-4c0f-b699-7e79cb2304f3",
    iconSet: "Entypo",
    iconName: "cog"
  },
  {
    uuid: "974336a4-d6b3-4625-9447-0fddfd25789e",
    iconSet: "Entypo",
    iconName: "colours"
  },
  {
    uuid: "e212b269-0ec2-4c7d-85ca-c2d97de582d6",
    iconSet: "Entypo",
    iconName: "compass"
  },
  {
    uuid: "26252f26-42a9-4db4-93b8-a3d57801abd5",
    iconSet: "Entypo",
    iconName: "controller-fast-backward"
  },
  {
    uuid: "62383b9f-1392-4296-b5b8-6988d7400f54",
    iconSet: "Entypo",
    iconName: "controller-fast-forward"
  },
  {
    uuid: "239fd33d-6256-40e5-a813-0ccc6ee36009",
    iconSet: "Entypo",
    iconName: "controller-jump-to-start"
  },
  {
    uuid: "c986bc85-7001-4f02-b0c6-229e2c2608ee",
    iconSet: "Entypo",
    iconName: "controller-next"
  },
  {
    uuid: "247a69f1-cebd-493f-ac15-eecea39123ee",
    iconSet: "Entypo",
    iconName: "controller-paus"
  },
  {
    uuid: "38d71cbe-21d0-4ed5-bb8d-bd0db5ea981d",
    iconSet: "Entypo",
    iconName: "controller-play"
  },
  {
    uuid: "13d775ec-dbe0-451c-a9f1-413258e774a9",
    iconSet: "Entypo",
    iconName: "controller-record"
  },
  {
    uuid: "4ff21aa1-0347-49e9-b997-7f8e6a039790",
    iconSet: "Entypo",
    iconName: "controller-stop"
  },
  {
    uuid: "30655380-623b-4611-8b13-0d02875f0ad7",
    iconSet: "Entypo",
    iconName: "controller-volume"
  },
  {
    uuid: "00e8ff63-06ac-40be-9777-99c6ea907eeb",
    iconSet: "Entypo",
    iconName: "copy"
  },
  {
    uuid: "12458edf-2afa-427d-a109-8e9b777ffb02",
    iconSet: "Entypo",
    iconName: "creative-cloud"
  },
  {
    uuid: "e0e45245-3293-4095-9059-8af3ffe274d4",
    iconSet: "Entypo",
    iconName: "creative-commons"
  },
  {
    uuid: "24c62ba8-2165-4efd-86da-52feb69055b1",
    iconSet: "Entypo",
    iconName: "creative-commons-attribution"
  },
  {
    uuid: "409e0977-b68d-464b-9d6d-da8b7a40a98c",
    iconSet: "Entypo",
    iconName: "creative-commons-noderivs"
  },
  {
    uuid: "9bdb13de-baf3-46ee-82fb-c01b5a763db4",
    iconSet: "Entypo",
    iconName: "creative-commons-noncommercial-eu"
  },
  {
    uuid: "db3aca1b-8808-47bf-bdaa-86817951dc5e",
    iconSet: "Entypo",
    iconName: "creative-commons-noncommercial-us"
  },
  {
    uuid: "f402e2fe-2591-4149-997d-0cd8a1459804",
    iconSet: "Entypo",
    iconName: "creative-commons-public-domain"
  },
  {
    uuid: "7c81c4aa-b209-42be-bf2b-c6739d7cbc3b",
    iconSet: "Entypo",
    iconName: "creative-commons-remix"
  },
  {
    uuid: "bf976185-d120-43ea-ba33-a17c11a27a86",
    iconSet: "Entypo",
    iconName: "creative-commons-share"
  },
  {
    uuid: "f78466dc-471f-47e6-8831-0705b2d1e652",
    iconSet: "Entypo",
    iconName: "creative-commons-sharealike"
  },
  {
    uuid: "d378f228-7c82-4c18-bd10-2094f360b3d5",
    iconSet: "Entypo",
    iconName: "credit"
  },
  {
    uuid: "40918acf-3298-405b-8628-a158ad5e0b10",
    iconSet: "Entypo",
    iconName: "credit-card"
  },
  {
    uuid: "bc5f01e9-d607-46d5-ac01-a498cf9679ee",
    iconSet: "Entypo",
    iconName: "crop"
  },
  {
    uuid: "7c0dabcd-fb9f-43e0-8f36-588032194806",
    iconSet: "Entypo",
    iconName: "cross"
  },
  {
    uuid: "ce3917e2-5768-40ce-b1fd-8deec595fb4a",
    iconSet: "Entypo",
    iconName: "cup"
  },
  {
    uuid: "7d9e8aa6-a2a2-4593-9f3c-f65ce9c3ca0a",
    iconSet: "Entypo",
    iconName: "cw"
  },
  {
    uuid: "1b622b1e-3f9a-487c-808d-1904696e4407",
    iconSet: "Entypo",
    iconName: "cycle"
  },
  {
    uuid: "6a74c72b-5aea-4956-862b-8825075f5066",
    iconSet: "Entypo",
    iconName: "database"
  },
  {
    uuid: "5c9dbadb-ba0a-4644-bba4-4cde1ce8695a",
    iconSet: "Entypo",
    iconName: "dial-pad"
  },
  {
    uuid: "21d27f41-d77b-4161-b290-65bb95a44c39",
    iconSet: "Entypo",
    iconName: "direction"
  },
  {
    uuid: "b8377946-9c80-4bd4-b43e-383321fb7098",
    iconSet: "Entypo",
    iconName: "document"
  },
  {
    uuid: "38dedfc0-e2e8-42b0-b219-a29212afb38f",
    iconSet: "Entypo",
    iconName: "document-landscape"
  },
  {
    uuid: "e260e9e0-b789-4758-b904-6c3c7372383c",
    iconSet: "Entypo",
    iconName: "documents"
  },
  {
    uuid: "4ebb74c4-2a4e-4df9-a6a8-31b2031e44cf",
    iconSet: "Entypo",
    iconName: "dot-single"
  },
  {
    uuid: "3fcbd6e1-27b8-47ee-b0c5-ecc2699ea4df",
    iconSet: "Entypo",
    iconName: "dots-three-horizontal"
  },
  {
    uuid: "73812374-086f-4a7c-8f56-50d58455f1f7",
    iconSet: "Entypo",
    iconName: "dots-three-vertical"
  },
  {
    uuid: "128697cc-5dba-49eb-9174-10b86d80da63",
    iconSet: "Entypo",
    iconName: "dots-two-horizontal"
  },
  {
    uuid: "71fd3a7c-d431-46ba-9db7-5ffbc7643be6",
    iconSet: "Entypo",
    iconName: "dots-two-vertical"
  },
  {
    uuid: "ad7ab509-7803-43f9-872f-6da526dda119",
    iconSet: "Entypo",
    iconName: "download"
  },
  {
    uuid: "3bd492cd-ef47-4b43-830e-e1e9c18c32f5",
    iconSet: "Entypo",
    iconName: "dribbble"
  },
  {
    uuid: "a332d41e-7f97-471e-b25e-fd85a1966d8d",
    iconSet: "Entypo",
    iconName: "dribbble-with-circle"
  },
  {
    uuid: "b84b3049-3f90-4498-9b84-5cc6a9b3e78e",
    iconSet: "Entypo",
    iconName: "drink"
  },
  {
    uuid: "3ffbe5d1-837f-457c-a7d7-8534444b38c7",
    iconSet: "Entypo",
    iconName: "drive"
  },
  {
    uuid: "a9e92dde-21ba-4051-8a90-1c8053387d8e",
    iconSet: "Entypo",
    iconName: "drop"
  },
  {
    uuid: "d613c709-732c-42f3-a4bd-035c8bd8fb90",
    iconSet: "Entypo",
    iconName: "dropbox"
  },
  {
    uuid: "c07ff33a-9abe-4950-bbe0-925f80d07208",
    iconSet: "Entypo",
    iconName: "edit"
  },
  {
    uuid: "4cd870a0-dde3-42b2-8e4e-3236d07abb19",
    iconSet: "Entypo",
    iconName: "email"
  },
  {
    uuid: "b5a48cdd-6c0d-4d92-a905-cd5f166e1e5c",
    iconSet: "Entypo",
    iconName: "emoji-flirt"
  },
  {
    uuid: "4ffc91d0-63a6-47f6-9d9a-8bdf08f30b89",
    iconSet: "Entypo",
    iconName: "emoji-happy"
  },
  {
    uuid: "214b3843-2d09-4ebb-972d-bd2561247236",
    iconSet: "Entypo",
    iconName: "emoji-neutral"
  },
  {
    uuid: "fe76a817-10c8-45d5-8e05-63e8da4a4a29",
    iconSet: "Entypo",
    iconName: "emoji-sad"
  },
  {
    uuid: "fff76aaa-d816-4238-a5ab-58a1ea4f1272",
    iconSet: "Entypo",
    iconName: "erase"
  },
  {
    uuid: "194910a0-4b41-4bec-a477-069333ac0167",
    iconSet: "Entypo",
    iconName: "eraser"
  },
  {
    uuid: "474138c2-de6b-4325-b7ed-7d3fb52a40e4",
    iconSet: "Entypo",
    iconName: "evernote"
  },
  {
    uuid: "a53d8207-84f4-4865-9f44-eb5e4671e1ff",
    iconSet: "Entypo",
    iconName: "export"
  },
  {
    uuid: "aaf247b4-c504-4ae8-8ded-49fbbc3cc1dc",
    iconSet: "Entypo",
    iconName: "eye"
  },
  {
    uuid: "b973c516-4770-420d-9921-327b341583f3",
    iconSet: "Entypo",
    iconName: "eye-with-line"
  },
  {
    uuid: "6574f0b0-5dd8-49ae-beab-5d6d0a35f5e7",
    iconSet: "Entypo",
    iconName: "facebook"
  },
  {
    uuid: "a9ea47ce-e69c-4989-bd52-74f0451a40b7",
    iconSet: "Entypo",
    iconName: "facebook-with-circle"
  },
  {
    uuid: "8fce65f8-3274-4adb-9343-f16097ceddf0",
    iconSet: "Entypo",
    iconName: "feather"
  },
  {
    uuid: "21659bac-af9f-4d0c-b09b-f2e0ad02eafb",
    iconSet: "Entypo",
    iconName: "fingerprint"
  },
  {
    uuid: "70e52bda-9563-4555-81a7-8f50767cb80f",
    iconSet: "Entypo",
    iconName: "flag"
  },
  {
    uuid: "01fc1189-f945-4f34-8f0a-aaafd86d5334",
    iconSet: "Entypo",
    iconName: "flash"
  },
  {
    uuid: "4517728f-690d-4bf2-862e-8dd99ba26a5b",
    iconSet: "Entypo",
    iconName: "flashlight"
  },
  {
    uuid: "f168fa7e-96c3-4439-af0e-8c23721a177c",
    iconSet: "Entypo",
    iconName: "flat-brush"
  },
  {
    uuid: "8708e6f3-8c30-4d97-b0f0-138b2ecf7b8a",
    iconSet: "Entypo",
    iconName: "flattr"
  },
  {
    uuid: "1abb8635-68a9-4b41-8d60-e26f3777e5ec",
    iconSet: "Entypo",
    iconName: "flickr"
  },
  {
    uuid: "29ec6ed8-f035-4f84-857b-31d0710411fd",
    iconSet: "Entypo",
    iconName: "flickr-with-circle"
  },
  {
    uuid: "17693e15-5176-4530-bcf4-4400d5729796",
    iconSet: "Entypo",
    iconName: "flow-branch"
  },
  {
    uuid: "6c5029c2-ca35-44cf-b37c-d19c2ebc85ff",
    iconSet: "Entypo",
    iconName: "flow-cascade"
  },
  {
    uuid: "872d16bb-2679-4097-859f-83ba5d81ea52",
    iconSet: "Entypo",
    iconName: "flow-line"
  },
  {
    uuid: "c2d6a880-dd84-4c55-9934-322be1ab0350",
    iconSet: "Entypo",
    iconName: "flow-parallel"
  },
  {
    uuid: "2e588121-bdd0-4605-a0c3-f42a940b9384",
    iconSet: "Entypo",
    iconName: "flow-tree"
  },
  {
    uuid: "720de553-ad12-4700-a652-5d71daff9fbb",
    iconSet: "Entypo",
    iconName: "flower"
  },
  {
    uuid: "455dc8c3-8b39-49c5-92f7-c7d6f898db76",
    iconSet: "Entypo",
    iconName: "folder"
  },
  {
    uuid: "d18cbb97-723f-4749-8740-d5d276ac9ead",
    iconSet: "Entypo",
    iconName: "folder-images"
  },
  {
    uuid: "99a83fe6-8fc0-43be-8636-b12b57efe2ef",
    iconSet: "Entypo",
    iconName: "folder-music"
  },
  {
    uuid: "f79038cb-48c6-47e7-92cc-13387e5f7911",
    iconSet: "Entypo",
    iconName: "folder-video"
  },
  {
    uuid: "750dcf77-72c0-4c0a-a719-41ace75d73fe",
    iconSet: "Entypo",
    iconName: "forward"
  },
  {
    uuid: "7e1b50ba-77e2-41a2-af3c-605b32160da3",
    iconSet: "Entypo",
    iconName: "foursquare"
  },
  {
    uuid: "5370d693-fc7d-4bd6-a6d0-34085ac19b00",
    iconSet: "Entypo",
    iconName: "funnel"
  },
  {
    uuid: "7c0c2579-d6b9-4f47-8f2b-b23c2b90dde8",
    iconSet: "Entypo",
    iconName: "game-controller"
  },
  {
    uuid: "3c133f0b-064b-4e35-9146-e4fc63bd421b",
    iconSet: "Entypo",
    iconName: "gauge"
  },
  {
    uuid: "d1ff86cf-30e1-4902-a350-4aaafb31aab6",
    iconSet: "Entypo",
    iconName: "github"
  },
  {
    uuid: "f2d35e6c-f1c2-479f-b40b-cf7cc739fb6c",
    iconSet: "Entypo",
    iconName: "github-with-circle"
  },
  {
    uuid: "f1b521ab-1c5a-4ebf-846c-eb1453c06215",
    iconSet: "Entypo",
    iconName: "globe"
  },
  {
    uuid: "1e76f9cf-268a-42c2-b46b-5aa2a026eb2b",
    iconSet: "Entypo",
    iconName: "google-"
  },
  {
    uuid: "5383a2c5-6621-46a5-bb3c-205e2c564757",
    iconSet: "Entypo",
    iconName: "google--with-circle"
  },
  {
    uuid: "5aba6ca0-8cd4-45da-98e6-bca8e3df9c43",
    iconSet: "Entypo",
    iconName: "google-drive"
  },
  {
    uuid: "268410a8-7421-49db-b434-1e030dbfd9ac",
    iconSet: "Entypo",
    iconName: "google-hangouts"
  },
  {
    uuid: "2f1c4a6b-44f9-47c7-b0c1-f3abe03cb1a9",
    iconSet: "Entypo",
    iconName: "google-play"
  },
  {
    uuid: "abc65dbf-5141-4bcf-adb3-9d9d9ead1ac5",
    iconSet: "Entypo",
    iconName: "graduation-cap"
  },
  {
    uuid: "88448738-fe0f-4908-9d99-f8d250338e99",
    iconSet: "Entypo",
    iconName: "grid"
  },
  {
    uuid: "6691b75b-37bf-468e-8541-138afde56639",
    iconSet: "Entypo",
    iconName: "grooveshark"
  },
  {
    uuid: "7f097c78-c34b-47e1-923f-1cb0fea21769",
    iconSet: "Entypo",
    iconName: "hair-cross"
  },
  {
    uuid: "c9e37e19-512f-40c4-94e0-14db3d2d98d9",
    iconSet: "Entypo",
    iconName: "hand"
  },
  {
    uuid: "a9cdf6aa-5fd4-4a8c-9c09-88c0b5140557",
    iconSet: "Entypo",
    iconName: "heart"
  },
  {
    uuid: "83873386-3a92-4d87-9739-2c667123e588",
    iconSet: "Entypo",
    iconName: "heart-outlined"
  },
  {
    uuid: "16f29fc1-6b9b-435b-951f-cdfa2ce51066",
    iconSet: "Entypo",
    iconName: "help"
  },
  {
    uuid: "c8b5b985-60a3-4971-aed4-94b4206d7d9d",
    iconSet: "Entypo",
    iconName: "help-with-circle"
  },
  {
    uuid: "77b20856-47f9-4eee-a228-5402a440f625",
    iconSet: "Entypo",
    iconName: "home"
  },
  {
    uuid: "8942f11c-6c11-4dcb-9d39-38b874197656",
    iconSet: "Entypo",
    iconName: "hour-glass"
  },
  {
    uuid: "249636df-8d1b-4de1-9177-3c84782b11df",
    iconSet: "Entypo",
    iconName: "houzz"
  },
  {
    uuid: "e26c2ef0-4499-4e2c-a8fc-eea2d91340b1",
    iconSet: "Entypo",
    iconName: "icloud"
  },
  {
    uuid: "92151ebd-165e-4f8f-b4c6-ef17850aaaf3",
    iconSet: "Entypo",
    iconName: "image"
  },
  {
    uuid: "025e1550-a5cc-4525-91b5-02de7f360a6f",
    iconSet: "Entypo",
    iconName: "image-inverted"
  },
  {
    uuid: "1076c1af-e5c6-4f15-a934-e5f03f94378f",
    iconSet: "Entypo",
    iconName: "images"
  },
  {
    uuid: "0383d715-68f3-4458-a396-bbceaef02093",
    iconSet: "Entypo",
    iconName: "inbox"
  },
  {
    uuid: "0f0159ac-e3b6-4c35-8372-7aceae135e9b",
    iconSet: "Entypo",
    iconName: "infinity"
  },
  {
    uuid: "02dbd737-2455-4a59-9bf0-00448feded86",
    iconSet: "Entypo",
    iconName: "info"
  },
  {
    uuid: "0ccf8198-bc05-4fe3-a7c2-229d303371b7",
    iconSet: "Entypo",
    iconName: "info-with-circle"
  },
  {
    uuid: "d2ef635e-5fa6-4c02-bbc8-60524af5817d",
    iconSet: "Entypo",
    iconName: "instagram"
  },
  {
    uuid: "1a4563b8-b757-4d02-9e37-5ddd6f204c40",
    iconSet: "Entypo",
    iconName: "instagram-with-circle"
  },
  {
    uuid: "ebca3d78-5943-4daf-add5-65768751abe2",
    iconSet: "Entypo",
    iconName: "install"
  },
  {
    uuid: "e8c3b9b7-ffbc-4d39-94ea-c4e5424c25ad",
    iconSet: "Entypo",
    iconName: "key"
  },
  {
    uuid: "951219d0-56f1-4f06-858f-8708b966fb68",
    iconSet: "Entypo",
    iconName: "keyboard"
  },
  {
    uuid: "99d6d4c1-24b2-42bc-b1d8-68c91158da33",
    iconSet: "Entypo",
    iconName: "lab-flask"
  },
  {
    uuid: "9fe31dde-8a64-4e4d-8868-136a596fbeee",
    iconSet: "Entypo",
    iconName: "landline"
  },
  {
    uuid: "5acbb7ce-88f9-4673-aaff-0ba24fa54bf5",
    iconSet: "Entypo",
    iconName: "language"
  },
  {
    uuid: "9c0ff3e7-82c9-4a7f-8dd7-62addb531f79",
    iconSet: "Entypo",
    iconName: "laptop"
  },
  {
    uuid: "26e80f88-b054-4bcb-9846-e8528ee8aba7",
    iconSet: "Entypo",
    iconName: "lastfm"
  },
  {
    uuid: "de3872e5-8674-488e-a60a-be2825051dbf",
    iconSet: "Entypo",
    iconName: "lastfm-with-circle"
  },
  {
    uuid: "26250be9-e132-4493-ba2b-9525e7ca96e5",
    iconSet: "Entypo",
    iconName: "layers"
  },
  {
    uuid: "cab46dac-b8fc-40ac-88ca-e5bc5e0b167f",
    iconSet: "Entypo",
    iconName: "leaf"
  },
  {
    uuid: "623d739e-ee44-475b-b9c6-aa64c1b6a307",
    iconSet: "Entypo",
    iconName: "level-down"
  },
  {
    uuid: "bf63910c-903f-4cd9-920e-8e039ada7747",
    iconSet: "Entypo",
    iconName: "level-up"
  },
  {
    uuid: "46b4f2c5-a6fa-4cc9-a9e8-aa8f248624af",
    iconSet: "Entypo",
    iconName: "lifebuoy"
  },
  {
    uuid: "48d35175-80d0-4ef4-9918-48c1523c8e20",
    iconSet: "Entypo",
    iconName: "light-bulb"
  },
  {
    uuid: "0b696d0c-a980-434e-bbcb-9022dc89897f",
    iconSet: "Entypo",
    iconName: "light-down"
  },
  {
    uuid: "59b284a1-48a5-4692-8cfc-7b69cf113947",
    iconSet: "Entypo",
    iconName: "light-up"
  },
  {
    uuid: "36780f1a-6508-4806-ae9f-cbb423ef334d",
    iconSet: "Entypo",
    iconName: "line-graph"
  },
  {
    uuid: "907767be-d3c4-45ea-aba2-677562da6896",
    iconSet: "Entypo",
    iconName: "link"
  },
  {
    uuid: "81819d68-73f2-43aa-8566-b1253c53ef6a",
    iconSet: "Entypo",
    iconName: "linkedin"
  },
  {
    uuid: "a41a5433-2eed-4f43-8125-6c592d486ede",
    iconSet: "Entypo",
    iconName: "linkedin-with-circle"
  },
  {
    uuid: "e05de9fc-b0ae-4c30-9e81-322f50b256fc",
    iconSet: "Entypo",
    iconName: "list"
  },
  {
    uuid: "bfaf4f43-9e37-4739-89b5-1c894bb45d39",
    iconSet: "Entypo",
    iconName: "location"
  },
  {
    uuid: "ff326ce6-cae8-42b6-a033-b0238792f580",
    iconSet: "Entypo",
    iconName: "location-pin"
  },
  {
    uuid: "041a099a-88be-472e-b3ac-60b11bd9be4c",
    iconSet: "Entypo",
    iconName: "lock"
  },
  {
    uuid: "f64bfd1d-ab41-4518-a49e-4cbad8e1cfe9",
    iconSet: "Entypo",
    iconName: "lock-open"
  },
  {
    uuid: "7723bbd2-8812-4e46-a7cd-08e37605df1e",
    iconSet: "Entypo",
    iconName: "log-out"
  },
  {
    uuid: "ca3c41f7-548a-49c9-bc45-6089aa9e88c7",
    iconSet: "Entypo",
    iconName: "login"
  },
  {
    uuid: "7d39ad1b-a7d0-41d8-bb1f-dd7c976a35a4",
    iconSet: "Entypo",
    iconName: "loop"
  },
  {
    uuid: "e9ec6332-5eaf-4084-b631-fdfe3cbe0ed7",
    iconSet: "Entypo",
    iconName: "magnet"
  },
  {
    uuid: "1e7b230f-83ce-4dfa-a966-9689a7a09319",
    iconSet: "Entypo",
    iconName: "magnifying-glass"
  },
  {
    uuid: "f802a841-cecc-4400-9d78-5765b84528c3",
    iconSet: "Entypo",
    iconName: "mail"
  },
  {
    uuid: "ee9c0e01-aea1-4b35-b202-62adc78bcf08",
    iconSet: "Entypo",
    iconName: "mail-with-circle"
  },
  {
    uuid: "b68339c6-8705-4935-8cb4-e7c58505cce8",
    iconSet: "Entypo",
    iconName: "man"
  },
  {
    uuid: "86a3a065-0c5f-4add-83c0-aa70419dcbc6",
    iconSet: "Entypo",
    iconName: "map"
  },
  {
    uuid: "a75f4f12-2071-4df5-b819-6065c86d2bc2",
    iconSet: "Entypo",
    iconName: "mask"
  },
  {
    uuid: "04e05cfa-ce2a-42ae-b652-65c9a7e1098e",
    iconSet: "Entypo",
    iconName: "medal"
  },
  {
    uuid: "9bcf7664-6ab8-4e81-b172-cb04559cdd06",
    iconSet: "Entypo",
    iconName: "medium"
  },
  {
    uuid: "7c463fe9-cd5c-4f93-b161-0f9e1dbbd931",
    iconSet: "Entypo",
    iconName: "medium-with-circle"
  },
  {
    uuid: "5e2b373f-f19b-40a8-9cdf-33aea41ad5b0",
    iconSet: "Entypo",
    iconName: "megaphone"
  },
  {
    uuid: "0ebd69da-aa14-44c9-9f14-7324acf88dc5",
    iconSet: "Entypo",
    iconName: "menu"
  },
  {
    uuid: "0e6238b0-6459-4bab-a834-be844b992325",
    iconSet: "Entypo",
    iconName: "merge"
  },
  {
    uuid: "6fbe757b-3b86-4164-b400-0cfc6ff29ebf",
    iconSet: "Entypo",
    iconName: "message"
  },
  {
    uuid: "1edb3fef-c229-4034-809e-2fa3488362c4",
    iconSet: "Entypo",
    iconName: "mic"
  },
  {
    uuid: "c0a1eba9-52d5-46a5-b0e3-44835cc5d348",
    iconSet: "Entypo",
    iconName: "minus"
  },
  {
    uuid: "bc1f7da5-cd46-43ed-b778-4846868e33b5",
    iconSet: "Entypo",
    iconName: "mixi"
  },
  {
    uuid: "976bbe01-1e34-40f7-b28b-0bb3903abe5f",
    iconSet: "Entypo",
    iconName: "mobile"
  },
  {
    uuid: "299c566d-d08c-428b-bea0-422f33677a41",
    iconSet: "Entypo",
    iconName: "modern-mic"
  },
  {
    uuid: "9b10b4f2-3f3d-41f6-97f4-90cf4341758b",
    iconSet: "Entypo",
    iconName: "moon"
  },
  {
    uuid: "9e54dc9b-4de7-4e1e-a6af-ad8bb6f66d43",
    iconSet: "Entypo",
    iconName: "mouse"
  },
  {
    uuid: "e6490511-496e-4c70-8413-b8db2a9350b3",
    iconSet: "Entypo",
    iconName: "mouse-pointer"
  },
  {
    uuid: "6d8d4b72-22bd-498b-908b-08ffc817b105",
    iconSet: "Entypo",
    iconName: "music"
  },
  {
    uuid: "460cb406-5b00-4c42-858b-d7c8184b9dfd",
    iconSet: "Entypo",
    iconName: "network"
  },
  {
    uuid: "cdf46dd4-2182-4944-85fb-5b3dcd693a96",
    iconSet: "Entypo",
    iconName: "new"
  },
  {
    uuid: "f84c1e29-6b2e-4531-8391-9a04a5e8de17",
    iconSet: "Entypo",
    iconName: "new-message"
  },
  {
    uuid: "21ee500c-1b74-40d5-a413-60c63594498c",
    iconSet: "Entypo",
    iconName: "news"
  },
  {
    uuid: "b88497a8-1fa0-493b-95c0-4d3d1b5aae89",
    iconSet: "Entypo",
    iconName: "newsletter"
  },
  {
    uuid: "bd560a1c-f743-43fb-88af-6221e91a23a2",
    iconSet: "Entypo",
    iconName: "note"
  },
  {
    uuid: "b182fe35-00db-46b2-b028-cf046d4300c7",
    iconSet: "Entypo",
    iconName: "notification"
  },
  {
    uuid: "00ec7ef8-eaba-4fc5-aef4-589dead00720",
    iconSet: "Entypo",
    iconName: "notifications-off"
  },
  {
    uuid: "5b3e6bed-73d1-4b5e-bf4e-3e8e3e82ae62",
    iconSet: "Entypo",
    iconName: "old-mobile"
  },
  {
    uuid: "a43db184-07cd-44f8-bef8-46c6d788c169",
    iconSet: "Entypo",
    iconName: "old-phone"
  },
  {
    uuid: "a2c65dab-bdf0-4a90-b950-eb450702246c",
    iconSet: "Entypo",
    iconName: "onedrive"
  },
  {
    uuid: "450c4264-0d89-458b-a008-01093221cdfc",
    iconSet: "Entypo",
    iconName: "open-book"
  },
  {
    uuid: "cdf13e58-ee53-4ce4-b645-7a369796670e",
    iconSet: "Entypo",
    iconName: "palette"
  },
  {
    uuid: "9c684501-a8aa-45c8-85e5-5d8d51cbb958",
    iconSet: "Entypo",
    iconName: "paper-plane"
  },
  {
    uuid: "d108c4d8-05c5-4dc5-b4da-a301b03a2346",
    iconSet: "Entypo",
    iconName: "paypal"
  },
  {
    uuid: "cfe736ae-21b9-4acd-8d69-ffd936684707",
    iconSet: "Entypo",
    iconName: "pencil"
  },
  {
    uuid: "55d25ed0-3ae7-4a21-9e55-487f90a7c153",
    iconSet: "Entypo",
    iconName: "phone"
  },
  {
    uuid: "7252378f-5851-4f4e-b588-9b193da17f5a",
    iconSet: "Entypo",
    iconName: "picasa"
  },
  {
    uuid: "2a01d807-454d-42de-ae9e-9b3600912e8e",
    iconSet: "Entypo",
    iconName: "pie-chart"
  },
  {
    uuid: "176758db-991e-496e-b5ca-3ac99269284c",
    iconSet: "Entypo",
    iconName: "pin"
  },
  {
    uuid: "6a912171-7d08-460f-809d-a220c72356ea",
    iconSet: "Entypo",
    iconName: "pinterest"
  },
  {
    uuid: "ea9c725d-05c7-4c76-b6da-695826757a41",
    iconSet: "Entypo",
    iconName: "pinterest-with-circle"
  },
  {
    uuid: "475c95c4-6da1-45a6-a9bc-16733bd12c2e",
    iconSet: "Entypo",
    iconName: "plus"
  },
  {
    uuid: "c6c44df4-c849-4bd7-98cf-8ec02af94155",
    iconSet: "Entypo",
    iconName: "popup"
  },
  {
    uuid: "784316e9-57ee-4779-8517-adf014987b3a",
    iconSet: "Entypo",
    iconName: "power-plug"
  },
  {
    uuid: "b3c6709f-8fe8-4a15-91a9-de087a30bc7d",
    iconSet: "Entypo",
    iconName: "price-ribbon"
  },
  {
    uuid: "53434fdd-f1a8-445b-aa57-79add01dc10c",
    iconSet: "Entypo",
    iconName: "price-tag"
  },
  {
    uuid: "12279726-3b55-4620-b35f-f40f2e0c9080",
    iconSet: "Entypo",
    iconName: "print"
  },
  {
    uuid: "f1a43bea-eb0a-411c-9803-e1d028c9143b",
    iconSet: "Entypo",
    iconName: "progress-empty"
  },
  {
    uuid: "12ceabe6-2a0f-4402-acb4-b0766b0b1e73",
    iconSet: "Entypo",
    iconName: "progress-full"
  },
  {
    uuid: "046ef190-386d-47ed-b059-1a3876394ef9",
    iconSet: "Entypo",
    iconName: "progress-one"
  },
  {
    uuid: "2c33d7cb-ab85-492f-8440-c0f60d6e6f2e",
    iconSet: "Entypo",
    iconName: "progress-two"
  },
  {
    uuid: "140e87f6-4ca3-4f8d-83c6-f6ae43669326",
    iconSet: "Entypo",
    iconName: "publish"
  },
  {
    uuid: "418aeffd-31e1-4daf-83c8-60a560858c9b",
    iconSet: "Entypo",
    iconName: "qq"
  },
  {
    uuid: "22c07ae1-bf79-43b5-9454-df88924717d2",
    iconSet: "Entypo",
    iconName: "qq-with-circle"
  },
  {
    uuid: "c5202306-1f74-4905-83a9-b80436d9c98f",
    iconSet: "Entypo",
    iconName: "quote"
  },
  {
    uuid: "43512ec5-39ee-4d01-bf83-55127ca618eb",
    iconSet: "Entypo",
    iconName: "radio"
  },
  {
    uuid: "c3992b17-1562-407d-9008-3d0a31782943",
    iconSet: "Entypo",
    iconName: "raft"
  },
  {
    uuid: "3ad54a71-3a4f-46ba-a965-3fa3ae1298a9",
    iconSet: "Entypo",
    iconName: "raft-with-circle"
  },
  {
    uuid: "6f3f0bdc-f4fe-4fc2-9da5-cb7ff3f2226a",
    iconSet: "Entypo",
    iconName: "rainbow"
  },
  {
    uuid: "b01e54be-af34-4f4a-b449-d0ebbe2c6196",
    iconSet: "Entypo",
    iconName: "rdio"
  },
  {
    uuid: "3f9da3c3-3d3c-4b46-928f-bd4972652e41",
    iconSet: "Entypo",
    iconName: "rdio-with-circle"
  },
  {
    uuid: "a7dd13cf-5528-475a-8941-9ac0456b94ff",
    iconSet: "Entypo",
    iconName: "remove-user"
  },
  {
    uuid: "fd794660-4e80-41fa-93a9-a0f52064d65a",
    iconSet: "Entypo",
    iconName: "renren"
  },
  {
    uuid: "e0f87b9b-3e37-4323-a3ea-d21c8ae6e752",
    iconSet: "Entypo",
    iconName: "reply"
  },
  {
    uuid: "1295671d-03d4-4578-858f-917dc56da1f4",
    iconSet: "Entypo",
    iconName: "reply-all"
  },
  {
    uuid: "5d42be95-e922-4e76-a4ff-8f7b76576273",
    iconSet: "Entypo",
    iconName: "resize-100-"
  },
  {
    uuid: "a09e7461-3e01-48c7-966c-3d7b8f81a305",
    iconSet: "Entypo",
    iconName: "resize-full-screen"
  },
  {
    uuid: "51fd4a15-7443-4712-9675-64315aed497f",
    iconSet: "Entypo",
    iconName: "retweet"
  },
  {
    uuid: "25781d0e-edff-4e29-83a3-62ef913e9571",
    iconSet: "Entypo",
    iconName: "rocket"
  },
  {
    uuid: "87cd5258-3634-4d21-a313-40da3dd2c744",
    iconSet: "Entypo",
    iconName: "round-brush"
  },
  {
    uuid: "984c35a5-c492-40d8-ac39-45b77832a3bd",
    iconSet: "Entypo",
    iconName: "rss"
  },
  {
    uuid: "c59ac3ab-9530-4a1c-9fe9-067fd21ff9ce",
    iconSet: "Entypo",
    iconName: "ruler"
  },
  {
    uuid: "90dece66-50a6-4134-b324-bcb6c67e5e13",
    iconSet: "Entypo",
    iconName: "save"
  },
  {
    uuid: "fb4e2c98-f6d0-4522-9a35-a464466ee746",
    iconSet: "Entypo",
    iconName: "scissors"
  },
  {
    uuid: "d67e2fe3-0a7b-4540-97ab-61762e22c3d6",
    iconSet: "Entypo",
    iconName: "scribd"
  },
  {
    uuid: "abaa3708-1eee-472d-b588-bb856eca59e5",
    iconSet: "Entypo",
    iconName: "select-arrows"
  },
  {
    uuid: "8a4a8b06-5206-4c53-9d17-5925616a0ec0",
    iconSet: "Entypo",
    iconName: "share"
  },
  {
    uuid: "0bb71a3f-21ae-4272-8d9f-a9c051478532",
    iconSet: "Entypo",
    iconName: "share-alternative"
  },
  {
    uuid: "e9f2d903-1c70-47a9-9231-c61c696c77ce",
    iconSet: "Entypo",
    iconName: "shareable"
  },
  {
    uuid: "aa16b50d-500c-4ca2-aa76-8f5b8c78612d",
    iconSet: "Entypo",
    iconName: "shield"
  },
  {
    uuid: "985b7625-e324-40cc-8a5a-b74a7ccb16d7",
    iconSet: "Entypo",
    iconName: "shop"
  },
  {
    uuid: "a163e24e-09bd-44ca-87d5-24c5b5214da1",
    iconSet: "Entypo",
    iconName: "shopping-bag"
  },
  {
    uuid: "6bd66447-286e-419a-a45b-4a7f1f513c20",
    iconSet: "Entypo",
    iconName: "shopping-basket"
  },
  {
    uuid: "b01a2e8e-1231-4b08-9a72-b68a6dc12501",
    iconSet: "Entypo",
    iconName: "shopping-cart"
  },
  {
    uuid: "f09b80e8-cca5-44b2-9896-1672efcf9b7b",
    iconSet: "Entypo",
    iconName: "shuffle"
  },
  {
    uuid: "73e1d3f6-e88c-4fe9-87d9-a517bc4fbe14",
    iconSet: "Entypo",
    iconName: "signal"
  },
  {
    uuid: "682f0242-ad0a-48db-b6d7-6d01755cc4ad",
    iconSet: "Entypo",
    iconName: "sina-weibo"
  },
  {
    uuid: "d175de71-f8b7-42b6-bcc8-f523acf9792b",
    iconSet: "Entypo",
    iconName: "skype"
  },
  {
    uuid: "4a9c7b0f-0038-447b-bb03-7d6ec7fd3ea4",
    iconSet: "Entypo",
    iconName: "skype-with-circle"
  },
  {
    uuid: "54213f4d-bece-4530-bdc6-e774518ea447",
    iconSet: "Entypo",
    iconName: "slideshare"
  },
  {
    uuid: "2756210e-301d-42ac-bdec-06ac1f44d030",
    iconSet: "Entypo",
    iconName: "smashing"
  },
  {
    uuid: "61793728-e6c0-4d29-a8c0-ad6de316afd9",
    iconSet: "Entypo",
    iconName: "sound"
  },
  {
    uuid: "7463e9d7-b418-4fce-bec5-a487738375c3",
    iconSet: "Entypo",
    iconName: "sound-mix"
  },
  {
    uuid: "ad9977f9-f92c-4af0-9cd3-433b8c50ff37",
    iconSet: "Entypo",
    iconName: "sound-mute"
  },
  {
    uuid: "886f79bd-6afd-47b5-b885-f03625422d94",
    iconSet: "Entypo",
    iconName: "soundcloud"
  },
  {
    uuid: "ca7008b0-5792-49c7-8977-4cbcee93bac2",
    iconSet: "Entypo",
    iconName: "sports-club"
  },
  {
    uuid: "a790873d-b14c-4a7f-b958-458bdbb58140",
    iconSet: "Entypo",
    iconName: "spotify"
  },
  {
    uuid: "5250a415-4eae-49f9-97c9-5eeb9ea0460f",
    iconSet: "Entypo",
    iconName: "spotify-with-circle"
  },
  {
    uuid: "54c3ea5c-cad0-44d0-879c-a4c252082714",
    iconSet: "Entypo",
    iconName: "spreadsheet"
  },
  {
    uuid: "c6dd5b4c-d00b-44f2-b1a2-be0d793d7b80",
    iconSet: "Entypo",
    iconName: "squared-cross"
  },
  {
    uuid: "3ae1512f-5af7-4a5e-9946-f41f430faec7",
    iconSet: "Entypo",
    iconName: "squared-minus"
  },
  {
    uuid: "a83eb5d2-2544-49ee-977d-1650681f4ba3",
    iconSet: "Entypo",
    iconName: "squared-plus"
  },
  {
    uuid: "0ab10787-f579-443a-8042-acfb35686644",
    iconSet: "Entypo",
    iconName: "star"
  },
  {
    uuid: "a6fa44ed-dd99-476c-bd9c-d422dd5a6767",
    iconSet: "Entypo",
    iconName: "star-outlined"
  },
  {
    uuid: "47de1b10-161f-41fa-91c9-7a18415862cd",
    iconSet: "Entypo",
    iconName: "stopwatch"
  },
  {
    uuid: "651ac5b4-9c21-455e-a154-fc5243394ec6",
    iconSet: "Entypo",
    iconName: "stumbleupon"
  },
  {
    uuid: "948f9fc8-dee1-4ac5-a9e5-30c3f0486a0d",
    iconSet: "Entypo",
    iconName: "stumbleupon-with-circle"
  },
  {
    uuid: "92499b32-f17e-46d9-98f7-bba824089223",
    iconSet: "Entypo",
    iconName: "suitcase"
  },
  {
    uuid: "23d2f012-e4f2-4496-88ca-018b1b338c32",
    iconSet: "Entypo",
    iconName: "swap"
  },
  {
    uuid: "f7bbbb60-a61a-4ab0-a3b4-a0aceb5c9319",
    iconSet: "Entypo",
    iconName: "swarm"
  },
  {
    uuid: "85d0d0fc-2a9b-4a09-bc44-79bc8ee2fe8f",
    iconSet: "Entypo",
    iconName: "sweden"
  },
  {
    uuid: "d6a63272-fbe3-4288-8af3-cbe578997f6e",
    iconSet: "Entypo",
    iconName: "switch"
  },
  {
    uuid: "5dee096d-0cc2-4bea-ab2a-a94b1a08e1c3",
    iconSet: "Entypo",
    iconName: "tablet"
  },
  {
    uuid: "d5e7ade9-1df7-485b-b30c-4e485a84078e",
    iconSet: "Entypo",
    iconName: "tablet-mobile-combo"
  },
  {
    uuid: "abceafb6-3389-4e2f-9769-9d83c0f1619f",
    iconSet: "Entypo",
    iconName: "tag"
  },
  {
    uuid: "e32c1b08-3663-4421-9133-3a58c7be66f3",
    iconSet: "Entypo",
    iconName: "text"
  },
  {
    uuid: "2923f2f1-24e1-404b-988a-43927ee246fb",
    iconSet: "Entypo",
    iconName: "text-document"
  },
  {
    uuid: "345f11c5-dd6b-4446-ba2f-ee4031f20179",
    iconSet: "Entypo",
    iconName: "text-document-inverted"
  },
  {
    uuid: "3f8a5e66-b6f2-4731-9438-5d1d374b67f7",
    iconSet: "Entypo",
    iconName: "thermometer"
  },
  {
    uuid: "e5870354-f5e1-4574-a50e-32e67e4627fe",
    iconSet: "Entypo",
    iconName: "thumbs-down"
  },
  {
    uuid: "8ca1e9f3-7440-42bf-816a-db862213e4a0",
    iconSet: "Entypo",
    iconName: "thumbs-up"
  },
  {
    uuid: "5c9eb413-8b28-4247-a7c7-e04e06c00bdb",
    iconSet: "Entypo",
    iconName: "thunder-cloud"
  },
  {
    uuid: "a97e39da-7cd0-4e42-b8c7-d73bcd5c68c4",
    iconSet: "Entypo",
    iconName: "ticket"
  },
  {
    uuid: "3b82c55e-ef3c-4bdf-93b6-348c107e042c",
    iconSet: "Entypo",
    iconName: "time-slot"
  },
  {
    uuid: "029f9420-f50e-4d26-b480-bd7a65cd183c",
    iconSet: "Entypo",
    iconName: "tools"
  },
  {
    uuid: "bb7cd8e6-5cd4-4216-99b7-ba5f791cad9c",
    iconSet: "Entypo",
    iconName: "traffic-cone"
  },
  {
    uuid: "8bb06ff6-4d09-4b6d-af15-ebbd3bb973b6",
    iconSet: "Entypo",
    iconName: "trash"
  },
  {
    uuid: "a5ac2ef3-cec8-49c4-8cb4-aed906062685",
    iconSet: "Entypo",
    iconName: "tree"
  },
  {
    uuid: "e3c18ab4-be92-4420-8fa4-902b1e628877",
    iconSet: "Entypo",
    iconName: "triangle-down"
  },
  {
    uuid: "ea2f698c-60ed-4230-b6f9-1c43d2f6c4eb",
    iconSet: "Entypo",
    iconName: "triangle-left"
  },
  {
    uuid: "bdc06845-da45-4354-b0bb-4e28d9e383e8",
    iconSet: "Entypo",
    iconName: "triangle-right"
  },
  {
    uuid: "071baaf2-4960-442c-9566-702807f62057",
    iconSet: "Entypo",
    iconName: "triangle-up"
  },
  {
    uuid: "2680196b-c758-486e-bcea-5d0127a1b470",
    iconSet: "Entypo",
    iconName: "tripadvisor"
  },
  {
    uuid: "e419d4dc-1488-4136-9003-8f66ed022e56",
    iconSet: "Entypo",
    iconName: "trophy"
  },
  {
    uuid: "8e3c041d-b491-465c-a959-240baf68753c",
    iconSet: "Entypo",
    iconName: "tumblr"
  },
  {
    uuid: "004e76fe-0bde-4dcb-878b-0228e989da1b",
    iconSet: "Entypo",
    iconName: "tumblr-with-circle"
  },
  {
    uuid: "6ea61567-b2d0-4084-891e-26fc5f0af1e6",
    iconSet: "Entypo",
    iconName: "tv"
  },
  {
    uuid: "f4ae1846-8217-4957-a5e5-a37266e587e5",
    iconSet: "Entypo",
    iconName: "twitter"
  },
  {
    uuid: "0df50aa4-ae0a-484e-975c-1173e7ad17ae",
    iconSet: "Entypo",
    iconName: "twitter-with-circle"
  },
  {
    uuid: "19423a04-c3e9-40b3-994d-661ede5af779",
    iconSet: "Entypo",
    iconName: "typing"
  },
  {
    uuid: "76f0b6f4-b3d7-43bc-a73c-c162a07b8fc9",
    iconSet: "Entypo",
    iconName: "uninstall"
  },
  {
    uuid: "fde1d485-dc30-4c12-8203-b3ac13ae8855",
    iconSet: "Entypo",
    iconName: "unread"
  },
  {
    uuid: "a6508915-6772-405c-af43-a2b5b535eda4",
    iconSet: "Entypo",
    iconName: "untag"
  },
  {
    uuid: "093025a7-2e42-49d5-b8fc-7c74b6641ca0",
    iconSet: "Entypo",
    iconName: "upload"
  },
  {
    uuid: "c956a39d-d2d8-4ef4-97b7-fc27fb992200",
    iconSet: "Entypo",
    iconName: "upload-to-cloud"
  },
  {
    uuid: "9df219de-2638-4a52-a4cc-abe9cf1fc4f5",
    iconSet: "Entypo",
    iconName: "user"
  },
  {
    uuid: "21cf60e5-d5c0-49b9-a2f5-11c4c11e4fee",
    iconSet: "Entypo",
    iconName: "users"
  },
  {
    uuid: "57301492-b19d-4217-8996-c6da2861ffd4",
    iconSet: "Entypo",
    iconName: "v-card"
  },
  {
    uuid: "786cc159-b66e-4520-9914-3ca9330945ac",
    iconSet: "Entypo",
    iconName: "video"
  },
  {
    uuid: "b7edf458-de4a-4658-80bc-54d2d131465a",
    iconSet: "Entypo",
    iconName: "video-camera"
  },
  {
    uuid: "48d71590-509d-49d7-828e-ab8e70e9f795",
    iconSet: "Entypo",
    iconName: "vimeo"
  },
  {
    uuid: "f3f025b1-7747-48eb-bd4c-882da81bd360",
    iconSet: "Entypo",
    iconName: "vimeo-with-circle"
  },
  {
    uuid: "e6b3a933-a494-498b-bb4f-32fcd54407a1",
    iconSet: "Entypo",
    iconName: "vine"
  },
  {
    uuid: "27f010bb-1bdc-43c4-8b0c-c5618fd353aa",
    iconSet: "Entypo",
    iconName: "vine-with-circle"
  },
  {
    uuid: "3407b38b-0d4d-41a4-bd70-078985b64dd3",
    iconSet: "Entypo",
    iconName: "vinyl"
  },
  {
    uuid: "ad039ca1-af01-4207-811a-202563cd640e",
    iconSet: "Entypo",
    iconName: "vk"
  },
  {
    uuid: "89ff2491-de19-4ec9-8588-f03dd7f8ec4a",
    iconSet: "Entypo",
    iconName: "vk-alternitive"
  },
  {
    uuid: "3dbcceda-c11b-4381-953a-09f9cbea8b8c",
    iconSet: "Entypo",
    iconName: "vk-with-circle"
  },
  {
    uuid: "c282b57e-8ba0-4f30-a54e-aa1a8c9bf25f",
    iconSet: "Entypo",
    iconName: "voicemail"
  },
  {
    uuid: "d12abeae-4d46-41ed-bf3e-53b657b8a570",
    iconSet: "Entypo",
    iconName: "wallet"
  },
  {
    uuid: "65e243ef-719b-4703-bde6-4aee4a60d9d8",
    iconSet: "Entypo",
    iconName: "warning"
  },
  {
    uuid: "951652ec-3726-4e70-a184-d958f38d7163",
    iconSet: "Entypo",
    iconName: "water"
  },
  {
    uuid: "4bfef8d7-864a-461a-9a58-ffde9787b862",
    iconSet: "Entypo",
    iconName: "windows-store"
  },
  {
    uuid: "c570e57a-f93c-4140-bc91-cd61f4113ea7",
    iconSet: "Entypo",
    iconName: "xing"
  },
  {
    uuid: "f25b3fb5-9cc5-4795-8a4d-e7f8889561cf",
    iconSet: "Entypo",
    iconName: "xing-with-circle"
  },
  {
    uuid: "b6437a19-c1d0-4883-bfd2-d56322786da3",
    iconSet: "Entypo",
    iconName: "yelp"
  },
  {
    uuid: "fc1447d3-d478-4acf-ba6e-890eeba44716",
    iconSet: "Entypo",
    iconName: "youko"
  },
  {
    uuid: "bf015b1a-3644-47ae-bb65-a93675772724",
    iconSet: "Entypo",
    iconName: "youko-with-circle"
  },
  {
    uuid: "6a6a8d81-be67-44b2-94b8-3b03cd1f738e",
    iconSet: "Entypo",
    iconName: "youtube"
  },
  {
    uuid: "f5fa6ab1-381f-45ab-825a-a263315a76ff",
    iconSet: "Entypo",
    iconName: "youtube-with-circle"
  },
  {
    uuid: "2fbb94f0-90b4-4311-9491-3d968dbe5046",
    iconSet: "EvilIcons",
    iconName: "archive"
  },
  {
    uuid: "be823abb-bc06-49ae-a2c3-1030c2dc38f6",
    iconSet: "EvilIcons",
    iconName: "arrow-down"
  },
  {
    uuid: "9aa98606-85be-46a1-966d-fd3ddd4ffa18",
    iconSet: "EvilIcons",
    iconName: "arrow-left"
  },
  {
    uuid: "c649205b-b270-4d97-a607-7ac9acb43519",
    iconSet: "EvilIcons",
    iconName: "arrow-right"
  },
  {
    uuid: "16ae65ce-3a30-46f4-9692-4a27ffe4f1b2",
    iconSet: "EvilIcons",
    iconName: "arrow-up"
  },
  {
    uuid: "1d426106-023b-4b0a-954c-ca0dba8826cc",
    iconSet: "EvilIcons",
    iconName: "bell"
  },
  {
    uuid: "ccc21748-9aee-4e46-8aa3-9220cafde642",
    iconSet: "EvilIcons",
    iconName: "calendar"
  },
  {
    uuid: "e58ac09b-c613-4aba-87dd-80fc49519300",
    iconSet: "EvilIcons",
    iconName: "camera"
  },
  {
    uuid: "4615a27b-8a44-48b3-b0c2-1ca0c712f0f9",
    iconSet: "EvilIcons",
    iconName: "cart"
  },
  {
    uuid: "cc7ff2fe-9be6-48b8-ba47-a0b04f185c4a",
    iconSet: "EvilIcons",
    iconName: "chart"
  },
  {
    uuid: "d8263e65-1dcc-49ea-a6e1-2ed815233961",
    iconSet: "EvilIcons",
    iconName: "check"
  },
  {
    uuid: "9ebe3a10-29f2-4bb8-a593-04aadfc6c1a2",
    iconSet: "EvilIcons",
    iconName: "chevron-down"
  },
  {
    uuid: "7630991d-9532-4731-a3eb-500ad49cfa6b",
    iconSet: "EvilIcons",
    iconName: "chevron-left"
  },
  {
    uuid: "9aa1a860-70d8-4e91-b969-531755776c78",
    iconSet: "EvilIcons",
    iconName: "chevron-right"
  },
  {
    uuid: "c81b1a4a-43f4-4b77-b5bd-72fb2694bc77",
    iconSet: "EvilIcons",
    iconName: "chevron-up"
  },
  {
    uuid: "0f9423a0-777c-43c0-a33f-d3720d3ac011",
    iconSet: "EvilIcons",
    iconName: "clock"
  },
  {
    uuid: "628ac0be-8c69-423c-a49e-74f0704d6eea",
    iconSet: "EvilIcons",
    iconName: "close"
  },
  {
    uuid: "4fc3236b-b40a-4835-8390-611f0f007c78",
    iconSet: "EvilIcons",
    iconName: "close-o"
  },
  {
    uuid: "d8f4a029-b02e-4f19-aea4-808bb8727fc6",
    iconSet: "EvilIcons",
    iconName: "comment"
  },
  {
    uuid: "bdd28d12-335c-4200-a640-df6167248e7b",
    iconSet: "EvilIcons",
    iconName: "credit-card"
  },
  {
    uuid: "4e95d677-eb71-4f8f-bb7b-81ff8fb28bd6",
    iconSet: "EvilIcons",
    iconName: "envelope"
  },
  {
    uuid: "f7b876b5-485a-471c-b416-52027e6a6587",
    iconSet: "EvilIcons",
    iconName: "exclamation"
  },
  {
    uuid: "d9de91c9-c264-4f32-ab69-ebcbca52c48e",
    iconSet: "EvilIcons",
    iconName: "external-link"
  },
  {
    uuid: "d4012ade-f455-42cd-ad3d-8f506beabeb5",
    iconSet: "EvilIcons",
    iconName: "eye"
  },
  {
    uuid: "4607fcb4-340a-4634-b726-1b073264974c",
    iconSet: "EvilIcons",
    iconName: "gear"
  },
  {
    uuid: "66a66191-1268-4358-916e-ef33c86f4ef8",
    iconSet: "EvilIcons",
    iconName: "heart"
  },
  {
    uuid: "3a4e5129-077b-437d-b1bb-b835978a1fb0",
    iconSet: "EvilIcons",
    iconName: "image"
  },
  {
    uuid: "c8654686-669b-483f-9316-569f94d5fd1f",
    iconSet: "EvilIcons",
    iconName: "like"
  },
  {
    uuid: "186bc52b-5f1c-4656-a495-02e0c5fea09c",
    iconSet: "EvilIcons",
    iconName: "link"
  },
  {
    uuid: "e2de7b11-9d7b-44f7-8de3-a69d3424805b",
    iconSet: "EvilIcons",
    iconName: "location"
  },
  {
    uuid: "a2cdc304-5a9a-4f7d-8006-33259f3be7b9",
    iconSet: "EvilIcons",
    iconName: "lock"
  },
  {
    uuid: "a85c3a36-2773-443e-82d9-5337a469834d",
    iconSet: "EvilIcons",
    iconName: "minus"
  },
  {
    uuid: "19104f2e-5ed3-4ac2-bd79-a9ed6bd54042",
    iconSet: "EvilIcons",
    iconName: "navicon"
  },
  {
    uuid: "2fff9a0e-8239-438d-a608-2a5ff2da46d6",
    iconSet: "EvilIcons",
    iconName: "paperclip"
  },
  {
    uuid: "1d535771-1da3-44a7-bf13-90bba2f23e11",
    iconSet: "EvilIcons",
    iconName: "pencil"
  },
  {
    uuid: "cf832a7d-02ce-4c43-939d-6601074553d3",
    iconSet: "EvilIcons",
    iconName: "play"
  },
  {
    uuid: "4d73a3d5-0d99-4bb9-ae74-ab504bb244b6",
    iconSet: "EvilIcons",
    iconName: "plus"
  },
  {
    uuid: "a9a5def3-2f6e-4bce-90c6-393c05ef336f",
    iconSet: "EvilIcons",
    iconName: "pointer"
  },
  {
    uuid: "ae729cec-d60e-4829-b4b9-5d4071a7ba7d",
    iconSet: "EvilIcons",
    iconName: "question"
  },
  {
    uuid: "a6139c7e-bded-4aee-b66b-7b2a0da6a181",
    iconSet: "EvilIcons",
    iconName: "redo"
  },
  {
    uuid: "c293b5f6-7c22-46f3-8543-3fdeaae75691",
    iconSet: "EvilIcons",
    iconName: "refresh"
  },
  {
    uuid: "bc8deadf-54c3-48bc-8190-285e12727824",
    iconSet: "EvilIcons",
    iconName: "retweet"
  },
  {
    uuid: "2237e3d7-37ca-4255-b31d-19b1b99463af",
    iconSet: "EvilIcons",
    iconName: "sc-facebook"
  },
  {
    uuid: "bebbaabd-3f92-4216-8f7e-54e6f3636383",
    iconSet: "EvilIcons",
    iconName: "sc-github"
  },
  {
    uuid: "0ca7a4a8-56d7-404e-9f8b-eed304d5ff95",
    iconSet: "EvilIcons",
    iconName: "sc-google-plus"
  },
  {
    uuid: "17c0c02a-b5d5-45e3-b0b7-1bab69b92aa7",
    iconSet: "EvilIcons",
    iconName: "sc-instagram"
  },
  {
    uuid: "3b667256-c341-45d3-a831-745834753106",
    iconSet: "EvilIcons",
    iconName: "sc-linkedin"
  },
  {
    uuid: "c2d44662-e056-4fb9-bbd9-ee5cebd7e261",
    iconSet: "EvilIcons",
    iconName: "sc-odnoklassniki"
  },
  {
    uuid: "15c204cd-f1b3-4c23-b26b-e4500f7ca862",
    iconSet: "EvilIcons",
    iconName: "sc-pinterest"
  },
  {
    uuid: "3708ad12-6d58-496a-a901-e193731a84de",
    iconSet: "EvilIcons",
    iconName: "sc-skype"
  },
  {
    uuid: "17cf4b0d-2178-43db-b834-f4231f54977a",
    iconSet: "EvilIcons",
    iconName: "sc-soundcloud"
  },
  {
    uuid: "f301227a-824c-49e0-99ca-73781bc47b7c",
    iconSet: "EvilIcons",
    iconName: "sc-telegram"
  },
  {
    uuid: "24696993-c832-4384-8e10-83ccdf1b4f7e",
    iconSet: "EvilIcons",
    iconName: "sc-tumblr"
  },
  {
    uuid: "2d1e935f-c0b9-45b9-8dc1-105bbacc8f2f",
    iconSet: "EvilIcons",
    iconName: "sc-twitter"
  },
  {
    uuid: "fd67be3b-057e-41e3-8f39-bbffd78329db",
    iconSet: "EvilIcons",
    iconName: "sc-vimeo"
  },
  {
    uuid: "e94eaacd-4d92-4250-8b41-56c0a373641c",
    iconSet: "EvilIcons",
    iconName: "sc-vk"
  },
  {
    uuid: "95c9154c-1ed3-4312-a931-e235cf94d7f1",
    iconSet: "EvilIcons",
    iconName: "sc-youtube"
  },
  {
    uuid: "3e74bb09-b75c-4237-bcd9-a1b7d666df0d",
    iconSet: "EvilIcons",
    iconName: "search"
  },
  {
    uuid: "b7d05c53-61c2-47c6-8b78-3fbd2572f6db",
    iconSet: "EvilIcons",
    iconName: "share-apple"
  },
  {
    uuid: "c6ea39b7-1ca4-4ad0-9a74-65b2251a3305",
    iconSet: "EvilIcons",
    iconName: "share-google"
  },
  {
    uuid: "3d4f3d43-2368-4455-a705-8755dfb54dfc",
    iconSet: "EvilIcons",
    iconName: "spinner"
  },
  {
    uuid: "ebc51c27-50c1-4870-908c-f3520739cb35",
    iconSet: "EvilIcons",
    iconName: "spinner-2"
  },
  {
    uuid: "f994391e-d5bb-409a-82b5-6379d0815a02",
    iconSet: "EvilIcons",
    iconName: "spinner-3"
  },
  {
    uuid: "cc10f112-4641-4cce-840e-d78c9f51a76e",
    iconSet: "EvilIcons",
    iconName: "star"
  },
  {
    uuid: "8b72329a-89c8-449d-84a9-42b36f239377",
    iconSet: "EvilIcons",
    iconName: "tag"
  },
  {
    uuid: "6141d691-a7ed-4ab1-b12f-f785db147830",
    iconSet: "EvilIcons",
    iconName: "trash"
  },
  {
    uuid: "a4922148-ee47-48e4-a5ba-ad74ea72a0ae",
    iconSet: "EvilIcons",
    iconName: "trophy"
  },
  {
    uuid: "c1772943-cf55-4728-b66e-629d58cbfb9f",
    iconSet: "EvilIcons",
    iconName: "undo"
  },
  {
    uuid: "f0e4c9f4-f405-4eb8-b182-e53cca1f240f",
    iconSet: "EvilIcons",
    iconName: "unlock"
  },
  {
    uuid: "4a99fdf3-e1a3-4bbe-80f0-6ef5c14bc034",
    iconSet: "EvilIcons",
    iconName: "user"
  },
  {
    uuid: "50987870-c170-40b2-bee8-c85f4eac65c6",
    iconSet: "Feather",
    iconName: "activity"
  },
  {
    uuid: "98eee703-204a-4054-9ae1-660d3791f735",
    iconSet: "Feather",
    iconName: "airplay"
  },
  {
    uuid: "8e35935c-5b0c-4958-9eae-4a9dd2bfacb5",
    iconSet: "Feather",
    iconName: "alert-circle"
  },
  {
    uuid: "a547400e-194d-40e4-acb2-154b6b5ac715",
    iconSet: "Feather",
    iconName: "alert-octagon"
  },
  {
    uuid: "bbf46e6f-0d07-44ff-862f-6f066eb5930f",
    iconSet: "Feather",
    iconName: "alert-triangle"
  },
  {
    uuid: "ae2db5fb-081d-4faa-aed6-50fcbc9ad011",
    iconSet: "Feather",
    iconName: "align-center"
  },
  {
    uuid: "9d46ad1a-805a-433b-aa18-3613867eecec",
    iconSet: "Feather",
    iconName: "align-justify"
  },
  {
    uuid: "fcf67e6f-7472-4587-803f-86559977966b",
    iconSet: "Feather",
    iconName: "align-left"
  },
  {
    uuid: "790076f0-2a77-47a0-b20c-a097f6a8cfc4",
    iconSet: "Feather",
    iconName: "align-right"
  },
  {
    uuid: "5c7b2ab7-200c-4793-9854-f7710f232bbb",
    iconSet: "Feather",
    iconName: "anchor"
  },
  {
    uuid: "64515baa-b5db-429a-8273-0e5751d40a07",
    iconSet: "Feather",
    iconName: "aperture"
  },
  {
    uuid: "6dd3dfe0-85c1-437a-8861-c2ebfbfa13de",
    iconSet: "Feather",
    iconName: "archive"
  },
  {
    uuid: "51bd5fa0-656c-4dca-8495-a1ae9f9d8644",
    iconSet: "Feather",
    iconName: "arrow-down"
  },
  {
    uuid: "d2034917-5491-4fb6-a2d1-7de6ceceb263",
    iconSet: "Feather",
    iconName: "arrow-down-circle"
  },
  {
    uuid: "21a17199-8a62-47bb-b924-3d5bad2b3b11",
    iconSet: "Feather",
    iconName: "arrow-down-left"
  },
  {
    uuid: "40dd797b-06ff-4c20-b676-4cf3398273fb",
    iconSet: "Feather",
    iconName: "arrow-down-right"
  },
  {
    uuid: "afe85830-40fc-4c6f-b8ba-9633259cc0c7",
    iconSet: "Feather",
    iconName: "arrow-left"
  },
  {
    uuid: "624bddca-55b1-4b0d-8f9c-4248d60a088c",
    iconSet: "Feather",
    iconName: "arrow-left-circle"
  },
  {
    uuid: "f549aee5-89fb-4d2e-a069-85a0e3518903",
    iconSet: "Feather",
    iconName: "arrow-right"
  },
  {
    uuid: "09f73aa3-64c9-4ff9-b504-53e04997ed6e",
    iconSet: "Feather",
    iconName: "arrow-right-circle"
  },
  {
    uuid: "83a554b2-c927-40bc-8851-a4d06e68fdb2",
    iconSet: "Feather",
    iconName: "arrow-up"
  },
  {
    uuid: "50113642-1279-4fc9-b532-40e9d86fbc63",
    iconSet: "Feather",
    iconName: "arrow-up-circle"
  },
  {
    uuid: "68fea4da-9e72-4f8a-a705-0afec35a6a24",
    iconSet: "Feather",
    iconName: "arrow-up-left"
  },
  {
    uuid: "1dfac82c-1940-4d99-b2d0-d7b41bc5f511",
    iconSet: "Feather",
    iconName: "arrow-up-right"
  },
  {
    uuid: "e2aa275d-188b-4665-b673-525e6e925aad",
    iconSet: "Feather",
    iconName: "at-sign"
  },
  {
    uuid: "da08d3ed-7a10-40f6-bd1d-db11be5f368a",
    iconSet: "Feather",
    iconName: "award"
  },
  {
    uuid: "ef54029a-8764-4932-99d4-029904bfa69c",
    iconSet: "Feather",
    iconName: "bar-chart"
  },
  {
    uuid: "9977f093-82d0-4d42-aba7-9bc27facf6a0",
    iconSet: "Feather",
    iconName: "bar-chart-2"
  },
  {
    uuid: "4b72e310-fca7-4627-aaf6-1d2688fa811f",
    iconSet: "Feather",
    iconName: "battery"
  },
  {
    uuid: "bd8d4e53-ee08-4da0-a5c6-2272cf3137e1",
    iconSet: "Feather",
    iconName: "battery-charging"
  },
  {
    uuid: "6549aae9-6bca-483a-8275-46d01d47110f",
    iconSet: "Feather",
    iconName: "bell"
  },
  {
    uuid: "7d913760-e2f0-457d-a810-4667cde23018",
    iconSet: "Feather",
    iconName: "bell-off"
  },
  {
    uuid: "9a5b1903-936b-4480-9da0-d255ea461c51",
    iconSet: "Feather",
    iconName: "bluetooth"
  },
  {
    uuid: "31284e05-18e8-4089-b6a9-c98978c7d44d",
    iconSet: "Feather",
    iconName: "bold"
  },
  {
    uuid: "a6fb792c-bf68-4bcb-b43b-d5a99ad27565",
    iconSet: "Feather",
    iconName: "book"
  },
  {
    uuid: "3669f8af-2b61-4c45-bf9e-7982c5af37c4",
    iconSet: "Feather",
    iconName: "book-open"
  },
  {
    uuid: "02dba9d5-f9fa-4f88-9776-97dde36bc038",
    iconSet: "Feather",
    iconName: "bookmark"
  },
  {
    uuid: "fc7fa4aa-76dd-4b28-98df-c0181278107c",
    iconSet: "Feather",
    iconName: "box"
  },
  {
    uuid: "e95dcb39-0417-46cc-8a6c-1ecbe913885d",
    iconSet: "Feather",
    iconName: "briefcase"
  },
  {
    uuid: "0b25c8a6-833a-41af-83b2-e9dd3cde37b7",
    iconSet: "Feather",
    iconName: "calendar"
  },
  {
    uuid: "03e365f8-7654-4650-9ab7-c8d4470e0358",
    iconSet: "Feather",
    iconName: "camera"
  },
  {
    uuid: "59397db4-14ed-4f41-a0bd-45981a340a19",
    iconSet: "Feather",
    iconName: "camera-off"
  },
  {
    uuid: "24996138-4215-4bf0-a1b7-7d67d25023c0",
    iconSet: "Feather",
    iconName: "cast"
  },
  {
    uuid: "1ea59c43-1fab-437c-b06b-076116244b04",
    iconSet: "Feather",
    iconName: "check"
  },
  {
    uuid: "39a1b136-b6ca-4b3c-b554-8f16114254cf",
    iconSet: "Feather",
    iconName: "check-circle"
  },
  {
    uuid: "c3321473-96ba-45a2-81eb-19ded769b293",
    iconSet: "Feather",
    iconName: "check-square"
  },
  {
    uuid: "0d928e8e-3d72-4e24-8c90-aa9a7de118b5",
    iconSet: "Feather",
    iconName: "chevron-down"
  },
  {
    uuid: "05bbb2e2-0387-41a2-9fad-2f021e5ffd10",
    iconSet: "Feather",
    iconName: "chevron-left"
  },
  {
    uuid: "f4c7b668-16d5-4820-9574-4e4d2dfeb28f",
    iconSet: "Feather",
    iconName: "chevron-right"
  },
  {
    uuid: "a84c34de-0fea-45fc-a658-53c056bad68c",
    iconSet: "Feather",
    iconName: "chevron-up"
  },
  {
    uuid: "8f96434e-6741-42d1-8227-948047e4d511",
    iconSet: "Feather",
    iconName: "chevrons-down"
  },
  {
    uuid: "e8a8d931-e898-4648-9d20-104b08a7111c",
    iconSet: "Feather",
    iconName: "chevrons-left"
  },
  {
    uuid: "427203fa-06aa-42e1-a271-2fe01f39ea18",
    iconSet: "Feather",
    iconName: "chevrons-right"
  },
  {
    uuid: "ccbc73a0-a547-4dd9-a513-483ad8f4699a",
    iconSet: "Feather",
    iconName: "chevrons-up"
  },
  {
    uuid: "00dc0a2f-ea66-4262-8372-b3ef9faea99d",
    iconSet: "Feather",
    iconName: "chrome"
  },
  {
    uuid: "f7b451f3-4c6c-44b1-ae5e-e29e0237fbc4",
    iconSet: "Feather",
    iconName: "circle"
  },
  {
    uuid: "882914e2-3f4c-4954-b37a-5f04f70cf858",
    iconSet: "Feather",
    iconName: "clipboard"
  },
  {
    uuid: "bb212ca0-e1aa-4168-84d3-3b546f8026ba",
    iconSet: "Feather",
    iconName: "clock"
  },
  {
    uuid: "a376dd5e-d97b-418b-bb36-3298f9587652",
    iconSet: "Feather",
    iconName: "cloud"
  },
  {
    uuid: "488c8cba-1f95-4d35-ba67-4ac26af0e75c",
    iconSet: "Feather",
    iconName: "cloud-drizzle"
  },
  {
    uuid: "742be72a-9c23-4275-89e0-d07dadd96257",
    iconSet: "Feather",
    iconName: "cloud-lightning"
  },
  {
    uuid: "ae0bb95f-9c4d-42ca-996b-a24a9f570703",
    iconSet: "Feather",
    iconName: "cloud-off"
  },
  {
    uuid: "25c32f54-455e-4930-89dd-368a226562c3",
    iconSet: "Feather",
    iconName: "cloud-rain"
  },
  {
    uuid: "c147232e-6e88-44e7-8421-8486b204c1eb",
    iconSet: "Feather",
    iconName: "cloud-snow"
  },
  {
    uuid: "ea9080ac-77d3-4c6e-b490-c6ec56bf5acb",
    iconSet: "Feather",
    iconName: "code"
  },
  {
    uuid: "eaed51f2-7a1e-4ee7-9bf8-69ccef383af8",
    iconSet: "Feather",
    iconName: "codepen"
  },
  {
    uuid: "4fe0c522-19ef-47e6-8837-43f786edfd5f",
    iconSet: "Feather",
    iconName: "codesandbox"
  },
  {
    uuid: "d483b82d-fd72-42dc-ba79-95d4787b7506",
    iconSet: "Feather",
    iconName: "coffee"
  },
  {
    uuid: "73734129-8a34-4c73-8a02-6f26012797a0",
    iconSet: "Feather",
    iconName: "columns"
  },
  {
    uuid: "d7ee3b7b-3760-4962-a040-422b0ce2cac2",
    iconSet: "Feather",
    iconName: "command"
  },
  {
    uuid: "70419dab-ebae-438e-bbb0-0d4ed7eaad7c",
    iconSet: "Feather",
    iconName: "compass"
  },
  {
    uuid: "031b63be-24cb-4c80-a183-817b753d2ff4",
    iconSet: "Feather",
    iconName: "copy"
  },
  {
    uuid: "435234da-79a8-4e4e-8ccb-d48882e29d8d",
    iconSet: "Feather",
    iconName: "corner-down-left"
  },
  {
    uuid: "522c9d5d-b8d6-48bf-afa3-d765aea6f410",
    iconSet: "Feather",
    iconName: "corner-down-right"
  },
  {
    uuid: "77b81eef-c351-4d6d-bd1c-5495c9bc4af8",
    iconSet: "Feather",
    iconName: "corner-left-down"
  },
  {
    uuid: "81dad74e-8d69-47cd-ae94-1ada2dde5227",
    iconSet: "Feather",
    iconName: "corner-left-up"
  },
  {
    uuid: "8a4ed172-0200-4b79-9ff7-f3e7ff7cee96",
    iconSet: "Feather",
    iconName: "corner-right-down"
  },
  {
    uuid: "2f931b35-52de-4b51-ad5f-7faf34b76647",
    iconSet: "Feather",
    iconName: "corner-right-up"
  },
  {
    uuid: "c382b82f-8d09-4e75-a276-b3c03e308806",
    iconSet: "Feather",
    iconName: "corner-up-left"
  },
  {
    uuid: "a43e6d67-147f-4090-b2ef-4b760695d06d",
    iconSet: "Feather",
    iconName: "corner-up-right"
  },
  {
    uuid: "a71a44bb-28b9-4320-902f-49b1f48a6ae3",
    iconSet: "Feather",
    iconName: "cpu"
  },
  {
    uuid: "106423e6-e592-4beb-9c05-d8b958de6dcd",
    iconSet: "Feather",
    iconName: "credit-card"
  },
  {
    uuid: "015ed4ba-2b50-4f26-bdfe-b697fa0d8916",
    iconSet: "Feather",
    iconName: "crop"
  },
  {
    uuid: "ffcb2e22-f012-4bf0-b3f8-d35c3c10b162",
    iconSet: "Feather",
    iconName: "crosshair"
  },
  {
    uuid: "6e034b50-787a-4a3e-b219-41af896ba132",
    iconSet: "Feather",
    iconName: "database"
  },
  {
    uuid: "84a17f34-5691-42dd-856e-3441dd24c355",
    iconSet: "Feather",
    iconName: "delete"
  },
  {
    uuid: "7cf620e5-c11e-4216-a2a3-85c349c15d62",
    iconSet: "Feather",
    iconName: "disc"
  },
  {
    uuid: "71fbf680-ffda-4e95-a38a-126b3bde4d06",
    iconSet: "Feather",
    iconName: "divide"
  },
  {
    uuid: "dc5a3515-6c7d-4b8f-a503-5df24c027964",
    iconSet: "Feather",
    iconName: "divide-circle"
  },
  {
    uuid: "11dcfcb0-f8ed-4b1d-8f58-aca5fc3832ca",
    iconSet: "Feather",
    iconName: "divide-square"
  },
  {
    uuid: "0a88b129-7a09-49a7-8bc9-c1ab5c023a22",
    iconSet: "Feather",
    iconName: "dollar-sign"
  },
  {
    uuid: "6eed410c-343a-473c-8005-b04eab32e581",
    iconSet: "Feather",
    iconName: "download"
  },
  {
    uuid: "09c2dbcd-2a8a-4fd9-ad9e-90a64b3b23a9",
    iconSet: "Feather",
    iconName: "download-cloud"
  },
  {
    uuid: "ab43d45a-84fe-478a-9dc9-79995309ab18",
    iconSet: "Feather",
    iconName: "dribbble"
  },
  {
    uuid: "a76acf2c-0312-4487-b22b-11c7386501d0",
    iconSet: "Feather",
    iconName: "droplet"
  },
  {
    uuid: "4e0578e1-e33f-48a5-b680-20a6e6163dd6",
    iconSet: "Feather",
    iconName: "edit"
  },
  {
    uuid: "bfd0d9d2-9b30-4f43-a658-46f7409ddf91",
    iconSet: "Feather",
    iconName: "edit-2"
  },
  {
    uuid: "b509f08d-96c2-496d-b33e-1d9bad4a1458",
    iconSet: "Feather",
    iconName: "edit-3"
  },
  {
    uuid: "d24166f4-d993-4d3a-8692-ae839b70b6af",
    iconSet: "Feather",
    iconName: "external-link"
  },
  {
    uuid: "b3a8ee97-41d7-4dde-a36c-9d569b670ab9",
    iconSet: "Feather",
    iconName: "eye"
  },
  {
    uuid: "0cf25565-cca6-49bc-9794-a4f087a4457a",
    iconSet: "Feather",
    iconName: "eye-off"
  },
  {
    uuid: "c26a2769-ca6c-4319-9505-161471ba4aab",
    iconSet: "Feather",
    iconName: "facebook"
  },
  {
    uuid: "26042dcf-62bb-4b28-9be8-1006cd701af8",
    iconSet: "Feather",
    iconName: "fast-forward"
  },
  {
    uuid: "62b7cc72-3a91-4fe3-8edd-2fbb8af31948",
    iconSet: "Feather",
    iconName: "feather"
  },
  {
    uuid: "e6177dad-d4e2-4904-b3cd-bdd3bbfacb24",
    iconSet: "Feather",
    iconName: "figma"
  },
  {
    uuid: "eb35090b-e247-4387-a7f1-5a97ada8068c",
    iconSet: "Feather",
    iconName: "file"
  },
  {
    uuid: "ce9c1c95-1530-49a6-a66b-fe91824bbe2d",
    iconSet: "Feather",
    iconName: "file-minus"
  },
  {
    uuid: "fe07f0ad-607a-4e62-acd5-59f30c36621d",
    iconSet: "Feather",
    iconName: "file-plus"
  },
  {
    uuid: "92021ae7-f737-42a5-9e5a-5f2b3011a7df",
    iconSet: "Feather",
    iconName: "file-text"
  },
  {
    uuid: "e2b2ea8f-a57d-4804-ae13-576ebc17d9aa",
    iconSet: "Feather",
    iconName: "film"
  },
  {
    uuid: "428f8db0-6d65-42d5-a348-74a2d144eb01",
    iconSet: "Feather",
    iconName: "filter"
  },
  {
    uuid: "38e8e0c1-ac63-4e6b-9fef-51f3cf743941",
    iconSet: "Feather",
    iconName: "flag"
  },
  {
    uuid: "52f6920f-25c9-404f-8066-83c919c91dd9",
    iconSet: "Feather",
    iconName: "folder"
  },
  {
    uuid: "03d3a9b7-d4f3-4d0c-9353-a5928f475bdd",
    iconSet: "Feather",
    iconName: "folder-minus"
  },
  {
    uuid: "577953dc-0136-43ec-bd77-a75f4c14f11d",
    iconSet: "Feather",
    iconName: "folder-plus"
  },
  {
    uuid: "7fce5a35-7f4d-4dab-a4f6-b8f4c1f53993",
    iconSet: "Feather",
    iconName: "framer"
  },
  {
    uuid: "019434e4-52c2-455f-96dd-1d1fa60af8bf",
    iconSet: "Feather",
    iconName: "frown"
  },
  {
    uuid: "388976c0-085c-496a-a15d-8e1d543ff46a",
    iconSet: "Feather",
    iconName: "gift"
  },
  {
    uuid: "1947b9fa-8ed6-48b5-89d9-26a0e235865c",
    iconSet: "Feather",
    iconName: "git-branch"
  },
  {
    uuid: "efb53892-e6c6-4514-aa93-404b2bca8cd5",
    iconSet: "Feather",
    iconName: "git-commit"
  },
  {
    uuid: "ec45f8d7-8ec1-413d-9302-d140036bd0d8",
    iconSet: "Feather",
    iconName: "git-merge"
  },
  {
    uuid: "9d1daa24-fd22-4862-9078-6709ef6b1d6d",
    iconSet: "Feather",
    iconName: "git-pull-request"
  },
  {
    uuid: "8ad2d5ea-c889-4995-bcd2-b59452d27246",
    iconSet: "Feather",
    iconName: "github"
  },
  {
    uuid: "beba3f10-f995-4b80-872c-8bc9380def0a",
    iconSet: "Feather",
    iconName: "gitlab"
  },
  {
    uuid: "c36e06d8-bc2f-432c-bdfd-d10fdab4204a",
    iconSet: "Feather",
    iconName: "globe"
  },
  {
    uuid: "645d7000-a8d3-4d59-a0c7-9338772df184",
    iconSet: "Feather",
    iconName: "grid"
  },
  {
    uuid: "0a6c9170-1445-4bbe-8992-a36f54b36eb4",
    iconSet: "Feather",
    iconName: "hard-drive"
  },
  {
    uuid: "adff251a-41f5-4bf5-a901-100849d5c47f",
    iconSet: "Feather",
    iconName: "hash"
  },
  {
    uuid: "a6b92a28-d101-4264-92dd-fb600fe7ae54",
    iconSet: "Feather",
    iconName: "headphones"
  },
  {
    uuid: "e07097c7-8804-4b27-9080-a408a2606e61",
    iconSet: "Feather",
    iconName: "heart"
  },
  {
    uuid: "82cacf81-3c22-40d1-ac58-b5c085e79619",
    iconSet: "Feather",
    iconName: "help-circle"
  },
  {
    uuid: "dbe0abca-0ea9-4204-9ecd-43edfcf82029",
    iconSet: "Feather",
    iconName: "hexagon"
  },
  {
    uuid: "a6a5b830-d257-4e43-b23b-e632d8d283ec",
    iconSet: "Feather",
    iconName: "home"
  },
  {
    uuid: "e287243f-aa5e-4ad1-9477-14974c71c761",
    iconSet: "Feather",
    iconName: "image"
  },
  {
    uuid: "2f80b002-85fe-4a04-8fc0-ecf6bf51fa97",
    iconSet: "Feather",
    iconName: "inbox"
  },
  {
    uuid: "b75e3ba9-bf50-4c79-90b0-1fce34270713",
    iconSet: "Feather",
    iconName: "info"
  },
  {
    uuid: "daeafaff-e38b-4b27-a959-37fc737eecf5",
    iconSet: "Feather",
    iconName: "instagram"
  },
  {
    uuid: "2f400e5d-cce3-4eca-9512-8f26e3e169b6",
    iconSet: "Feather",
    iconName: "italic"
  },
  {
    uuid: "69d04c10-2758-446e-a862-12551303a594",
    iconSet: "Feather",
    iconName: "key"
  },
  {
    uuid: "ec155740-c2a0-487a-9493-5d726d20b5ea",
    iconSet: "Feather",
    iconName: "layers"
  },
  {
    uuid: "30279329-3150-4bb5-81c3-9568d1623727",
    iconSet: "Feather",
    iconName: "layout"
  },
  {
    uuid: "4d5c6d4f-b955-46d0-ab86-5ce033158318",
    iconSet: "Feather",
    iconName: "life-buoy"
  },
  {
    uuid: "1427fe88-b5c1-481b-92ef-082c09e2687d",
    iconSet: "Feather",
    iconName: "link"
  },
  {
    uuid: "98b9a9d6-3292-439f-af79-7c9c1e5d29f4",
    iconSet: "Feather",
    iconName: "link-2"
  },
  {
    uuid: "ad3767a4-fcb5-4198-996a-80f5b23bf5c4",
    iconSet: "Feather",
    iconName: "linkedin"
  },
  {
    uuid: "8e2b24d0-f975-4763-a25b-a0cc328d7192",
    iconSet: "Feather",
    iconName: "list"
  },
  {
    uuid: "1878d6ee-258c-45c7-8816-ac8d021959a0",
    iconSet: "Feather",
    iconName: "loader"
  },
  {
    uuid: "fb86534f-f5ff-4104-a519-01186ad0fd31",
    iconSet: "Feather",
    iconName: "lock"
  },
  {
    uuid: "17491d08-39f9-4fc3-8297-f6201a7118d7",
    iconSet: "Feather",
    iconName: "log-in"
  },
  {
    uuid: "12081333-1e80-45ee-b994-412305730ab8",
    iconSet: "Feather",
    iconName: "log-out"
  },
  {
    uuid: "63e683eb-7370-45ce-90c0-53b791918cfe",
    iconSet: "Feather",
    iconName: "mail"
  },
  {
    uuid: "c7770510-6cd2-4721-af37-47a2349a5330",
    iconSet: "Feather",
    iconName: "map"
  },
  {
    uuid: "327e3283-4421-4483-ab0b-bd0dec2554fd",
    iconSet: "Feather",
    iconName: "map-pin"
  },
  {
    uuid: "cbdc8639-6863-4f27-b817-9e4170a40d0a",
    iconSet: "Feather",
    iconName: "maximize"
  },
  {
    uuid: "aed18a18-e437-433c-9b0c-68fca8fad002",
    iconSet: "Feather",
    iconName: "maximize-2"
  },
  {
    uuid: "b7362132-fb31-4a9c-b0c1-5488701a7d8a",
    iconSet: "Feather",
    iconName: "meh"
  },
  {
    uuid: "ea7f14f2-3e2a-4fce-af5d-6567ec234c08",
    iconSet: "Feather",
    iconName: "menu"
  },
  {
    uuid: "7a1d3401-40aa-4912-b44f-249bba54fba8",
    iconSet: "Feather",
    iconName: "message-circle"
  },
  {
    uuid: "0faef4c9-626c-42f8-8141-07de4e8db05c",
    iconSet: "Feather",
    iconName: "message-square"
  },
  {
    uuid: "8b3a68d7-c599-470e-a16c-fda9ae2ebd02",
    iconSet: "Feather",
    iconName: "mic"
  },
  {
    uuid: "45a0033a-9a2a-41a8-a108-b00add68694b",
    iconSet: "Feather",
    iconName: "mic-off"
  },
  {
    uuid: "30bc5640-e704-46df-91ef-4ba4945da14c",
    iconSet: "Feather",
    iconName: "minimize"
  },
  {
    uuid: "4d5618fe-0d5a-49ec-aed9-591b94baa010",
    iconSet: "Feather",
    iconName: "minimize-2"
  },
  {
    uuid: "936197e7-3395-4679-a5c0-f3e6d7e7cfc1",
    iconSet: "Feather",
    iconName: "minus"
  },
  {
    uuid: "548ddc88-0605-4864-8939-83f00783a8e4",
    iconSet: "Feather",
    iconName: "minus-circle"
  },
  {
    uuid: "ca0911cc-8d16-475b-89ee-7dbfefd81d75",
    iconSet: "Feather",
    iconName: "minus-square"
  },
  {
    uuid: "1a9f1e93-57a3-4d6e-96bc-b82ff82fd14b",
    iconSet: "Feather",
    iconName: "monitor"
  },
  {
    uuid: "8f46a77d-4ffa-47d4-9d9a-7105cc8bf289",
    iconSet: "Feather",
    iconName: "moon"
  },
  {
    uuid: "82a0c53a-9445-4f7f-8e20-2addb0bf6f76",
    iconSet: "Feather",
    iconName: "more-horizontal"
  },
  {
    uuid: "b8b7f7dc-fa57-4174-b745-9792805bb31b",
    iconSet: "Feather",
    iconName: "more-vertical"
  },
  {
    uuid: "ac13db46-1f44-4fd7-b494-4f89b077e277",
    iconSet: "Feather",
    iconName: "mouse-pointer"
  },
  {
    uuid: "31f864b8-a241-4075-8591-197429f3e21d",
    iconSet: "Feather",
    iconName: "move"
  },
  {
    uuid: "b2f622c2-530d-46cb-977d-4d85e704b7c4",
    iconSet: "Feather",
    iconName: "music"
  },
  {
    uuid: "5af87847-2043-49fa-a7a4-964784e1e2f3",
    iconSet: "Feather",
    iconName: "navigation"
  },
  {
    uuid: "19a3a5aa-93d5-4f24-b5d8-69b6b820417a",
    iconSet: "Feather",
    iconName: "navigation-2"
  },
  {
    uuid: "0eca6ffa-d55d-4bd0-b7d8-ea8ff92a886f",
    iconSet: "Feather",
    iconName: "octagon"
  },
  {
    uuid: "79ea29d4-7100-4742-bf80-7eef7038d79e",
    iconSet: "Feather",
    iconName: "package"
  },
  {
    uuid: "546ed914-7f4d-4ee4-bef7-20113e00c1c1",
    iconSet: "Feather",
    iconName: "paperclip"
  },
  {
    uuid: "beb211a5-88a1-43cb-8768-4853d18aa0bc",
    iconSet: "Feather",
    iconName: "pause"
  },
  {
    uuid: "1f9af00b-1846-4be7-b6f3-053f3863c0ea",
    iconSet: "Feather",
    iconName: "pause-circle"
  },
  {
    uuid: "e42544ff-254f-4800-9933-0e319f805566",
    iconSet: "Feather",
    iconName: "pen-tool"
  },
  {
    uuid: "083f9e6a-57de-46a3-9e0d-34fd23eee204",
    iconSet: "Feather",
    iconName: "percent"
  },
  {
    uuid: "eacd053e-5dca-4e7d-beff-a65bcab765c6",
    iconSet: "Feather",
    iconName: "phone"
  },
  {
    uuid: "6099177d-3a8a-4a91-b071-a98e938e4cf5",
    iconSet: "Feather",
    iconName: "phone-call"
  },
  {
    uuid: "b59f8f09-fcc6-411a-9984-1cc83c9eba19",
    iconSet: "Feather",
    iconName: "phone-forwarded"
  },
  {
    uuid: "6a0b850b-1676-4a45-b565-81d1a3294e13",
    iconSet: "Feather",
    iconName: "phone-incoming"
  },
  {
    uuid: "93c569f2-8622-403d-9439-5242e5b04991",
    iconSet: "Feather",
    iconName: "phone-missed"
  },
  {
    uuid: "bb5266d6-1b02-486f-9e4b-3a359d41fbfc",
    iconSet: "Feather",
    iconName: "phone-off"
  },
  {
    uuid: "e9be53ee-e6be-4272-baaf-33d0d4c8258d",
    iconSet: "Feather",
    iconName: "phone-outgoing"
  },
  {
    uuid: "016f5256-2a58-4bff-9187-1b1acad83d75",
    iconSet: "Feather",
    iconName: "pie-chart"
  },
  {
    uuid: "bc3d3526-7159-4191-9a6a-3bb7a6c13f19",
    iconSet: "Feather",
    iconName: "play"
  },
  {
    uuid: "86311cda-a017-49f4-87ce-7118b7543a5c",
    iconSet: "Feather",
    iconName: "play-circle"
  },
  {
    uuid: "6c446d69-5c6b-427b-824c-77f5577be690",
    iconSet: "Feather",
    iconName: "plus"
  },
  {
    uuid: "c7cd36c8-8a7b-47fb-b6cc-3c8dcdf349a2",
    iconSet: "Feather",
    iconName: "plus-circle"
  },
  {
    uuid: "e0b7781d-c44c-4400-a4e2-d1562b11ea62",
    iconSet: "Feather",
    iconName: "plus-square"
  },
  {
    uuid: "e2254b65-7267-4677-b88d-5f260973eab5",
    iconSet: "Feather",
    iconName: "pocket"
  },
  {
    uuid: "042b594a-d93e-4c1a-a5c9-8b61a0e1c275",
    iconSet: "Feather",
    iconName: "power"
  },
  {
    uuid: "d5962bfc-faf7-4ca6-abe3-e38a92cee9a5",
    iconSet: "Feather",
    iconName: "printer"
  },
  {
    uuid: "1e09d9c2-aa66-435d-b1f6-ee81d39c5819",
    iconSet: "Feather",
    iconName: "radio"
  },
  {
    uuid: "9a45ef08-e514-4e70-a2e8-2119c08d7eb5",
    iconSet: "Feather",
    iconName: "refresh-ccw"
  },
  {
    uuid: "ac9e2f90-4842-44e6-8085-77954d8f02e8",
    iconSet: "Feather",
    iconName: "refresh-cw"
  },
  {
    uuid: "47b848ee-e819-41b8-bc53-a9e02d2a37f4",
    iconSet: "Feather",
    iconName: "repeat"
  },
  {
    uuid: "b36c69b3-668a-4532-af79-14324c430122",
    iconSet: "Feather",
    iconName: "rewind"
  },
  {
    uuid: "5d856d37-6a43-4cc1-ba19-f374bd13e500",
    iconSet: "Feather",
    iconName: "rotate-ccw"
  },
  {
    uuid: "2b0bd19e-1166-42ac-a5c9-e67a93893163",
    iconSet: "Feather",
    iconName: "rotate-cw"
  },
  {
    uuid: "78be49b9-5f0a-4b24-8e99-4a5e426cd13f",
    iconSet: "Feather",
    iconName: "rss"
  },
  {
    uuid: "779ac082-9efc-494e-af6c-46316a600e14",
    iconSet: "Feather",
    iconName: "save"
  },
  {
    uuid: "61cec90f-3700-420b-ad0b-f2271d405e38",
    iconSet: "Feather",
    iconName: "scissors"
  },
  {
    uuid: "c236fcc3-3578-41f5-b911-e8c2f03bc711",
    iconSet: "Feather",
    iconName: "search"
  },
  {
    uuid: "eb1cbc6e-ab54-48de-8b33-c96b7fd19d16",
    iconSet: "Feather",
    iconName: "send"
  },
  {
    uuid: "6e63ee84-e054-4d8b-9e06-9bf50e45ec02",
    iconSet: "Feather",
    iconName: "server"
  },
  {
    uuid: "ccbdc0e8-83d6-4ad4-89d6-d3249ba2ccbb",
    iconSet: "Feather",
    iconName: "settings"
  },
  {
    uuid: "6376f1f1-564f-40f4-9b9c-6de5c5c97367",
    iconSet: "Feather",
    iconName: "share"
  },
  {
    uuid: "fe906866-0c84-45cf-8396-b1c2b17608b3",
    iconSet: "Feather",
    iconName: "share-2"
  },
  {
    uuid: "adf45b01-879a-4b82-ab80-413fc62e4de1",
    iconSet: "Feather",
    iconName: "shield"
  },
  {
    uuid: "0b4c1379-4614-406e-9161-c4f4b637c2c1",
    iconSet: "Feather",
    iconName: "shield-off"
  },
  {
    uuid: "cb3332b0-5aa4-4523-8db5-fe6eb98d2603",
    iconSet: "Feather",
    iconName: "shopping-bag"
  },
  {
    uuid: "784cd8b8-0c3b-41a5-9094-165a68e8e06b",
    iconSet: "Feather",
    iconName: "shopping-cart"
  },
  {
    uuid: "42d10524-3250-4e5e-befe-16cae7720f2e",
    iconSet: "Feather",
    iconName: "shuffle"
  },
  {
    uuid: "21e8e34a-9f1f-405e-aef0-ebead371be41",
    iconSet: "Feather",
    iconName: "sidebar"
  },
  {
    uuid: "47f01b39-87dd-42b3-a5a8-5ab09aaf532e",
    iconSet: "Feather",
    iconName: "skip-back"
  },
  {
    uuid: "36113f44-a551-4e05-81d6-f452bbe97afb",
    iconSet: "Feather",
    iconName: "skip-forward"
  },
  {
    uuid: "3c6e4df7-03a1-4a8b-bcbd-bffa509cfc43",
    iconSet: "Feather",
    iconName: "slack"
  },
  {
    uuid: "e9b7cdae-a461-43c3-9562-1e70593b9821",
    iconSet: "Feather",
    iconName: "slash"
  },
  {
    uuid: "63032121-1a01-4120-944a-1114b7027dbf",
    iconSet: "Feather",
    iconName: "sliders"
  },
  {
    uuid: "0187d605-260a-45fa-bdfa-4f6b7e1cef3a",
    iconSet: "Feather",
    iconName: "smartphone"
  },
  {
    uuid: "f44bd9bd-0f30-48ef-becd-f356da15fd26",
    iconSet: "Feather",
    iconName: "smile"
  },
  {
    uuid: "78716b64-f454-47e5-bd2f-7601e2803341",
    iconSet: "Feather",
    iconName: "speaker"
  },
  {
    uuid: "21ce7216-286e-4f75-b709-cdc124ee2d4f",
    iconSet: "Feather",
    iconName: "square"
  },
  {
    uuid: "601b60db-4974-43e2-bc24-6bcd0d1a9851",
    iconSet: "Feather",
    iconName: "star"
  },
  {
    uuid: "07a40bdb-ac5f-42b1-bec8-540c13a3c4d9",
    iconSet: "Feather",
    iconName: "stop-circle"
  },
  {
    uuid: "41fbe237-88da-4dce-98fe-53a06b519ae3",
    iconSet: "Feather",
    iconName: "sun"
  },
  {
    uuid: "fd1fdd94-7b61-48d3-9e6f-c93b8fcc5596",
    iconSet: "Feather",
    iconName: "sunrise"
  },
  {
    uuid: "0befc9d1-2b47-4364-ae8e-d3fb5e0ef81e",
    iconSet: "Feather",
    iconName: "sunset"
  },
  {
    uuid: "f3432fd6-63b5-4e41-b845-e69370442b7a",
    iconSet: "Feather",
    iconName: "tablet"
  },
  {
    uuid: "81ef6f1a-533f-4a82-a4e6-4b87fa239366",
    iconSet: "Feather",
    iconName: "tag"
  },
  {
    uuid: "fb0b752b-0705-4012-b5c1-6cffa6a5ba03",
    iconSet: "Feather",
    iconName: "target"
  },
  {
    uuid: "a4709916-aaef-405e-b357-076746c0be80",
    iconSet: "Feather",
    iconName: "terminal"
  },
  {
    uuid: "5ea3497d-7bfd-4ccd-8373-682a0733da41",
    iconSet: "Feather",
    iconName: "thermometer"
  },
  {
    uuid: "5f9d5f5e-7d3b-4359-a782-12b0094eb3bf",
    iconSet: "Feather",
    iconName: "thumbs-down"
  },
  {
    uuid: "68dd456f-3810-4c42-b75e-3ae89fcd7c95",
    iconSet: "Feather",
    iconName: "thumbs-up"
  },
  {
    uuid: "f5565497-abfd-46d0-932c-062d4d935a96",
    iconSet: "Feather",
    iconName: "toggle-left"
  },
  {
    uuid: "894d6452-86b2-45be-9b42-ef277ec9f55b",
    iconSet: "Feather",
    iconName: "toggle-right"
  },
  {
    uuid: "ad82f120-ba0d-4108-bf46-3394a09c7052",
    iconSet: "Feather",
    iconName: "tool"
  },
  {
    uuid: "a7ec2f43-e25e-48ba-9c70-3e849406c99b",
    iconSet: "Feather",
    iconName: "trash"
  },
  {
    uuid: "59ae711c-7f26-471f-bb31-288f7e914ac8",
    iconSet: "Feather",
    iconName: "trash-2"
  },
  {
    uuid: "f6b45c37-3632-4c4e-9218-a8a3640789a5",
    iconSet: "Feather",
    iconName: "trello"
  },
  {
    uuid: "5f6248ac-1a39-4351-9f93-eaad07e0de3f",
    iconSet: "Feather",
    iconName: "trending-down"
  },
  {
    uuid: "40e79764-64b8-4301-a880-072d6689fded",
    iconSet: "Feather",
    iconName: "trending-up"
  },
  {
    uuid: "e83182d7-de6c-4912-ac3e-8d50aee176f5",
    iconSet: "Feather",
    iconName: "triangle"
  },
  {
    uuid: "95989ca8-3623-4688-af28-e94965bcd907",
    iconSet: "Feather",
    iconName: "truck"
  },
  {
    uuid: "b0c1cd8c-8410-437d-8ba6-937030606ad2",
    iconSet: "Feather",
    iconName: "tv"
  },
  {
    uuid: "2ac21fa8-75f4-4e3a-9c08-74326bc08ece",
    iconSet: "Feather",
    iconName: "twitch"
  },
  {
    uuid: "5777607a-cee6-4cd5-885f-c755278825d4",
    iconSet: "Feather",
    iconName: "twitter"
  },
  {
    uuid: "6076e8b3-97c7-4779-a630-49b7e916398d",
    iconSet: "Feather",
    iconName: "type"
  },
  {
    uuid: "5a064f76-001a-44f7-ba64-5496567c1647",
    iconSet: "Feather",
    iconName: "umbrella"
  },
  {
    uuid: "c154952b-07e2-4e0f-9bd6-836e96764abd",
    iconSet: "Feather",
    iconName: "underline"
  },
  {
    uuid: "043537c1-1c85-4c92-80ef-f309820a8d4e",
    iconSet: "Feather",
    iconName: "unlock"
  },
  {
    uuid: "fc98c688-2f46-46a7-8446-a9b7f1b0f759",
    iconSet: "Feather",
    iconName: "upload"
  },
  {
    uuid: "63326b82-611a-4193-993a-24db9492d93f",
    iconSet: "Feather",
    iconName: "upload-cloud"
  },
  {
    uuid: "83074fce-adb2-4cd4-bbab-68a02da01ed9",
    iconSet: "Feather",
    iconName: "user"
  },
  {
    uuid: "0798f8c3-49a3-45ba-a240-d8f14b06887b",
    iconSet: "Feather",
    iconName: "user-check"
  },
  {
    uuid: "37a6c868-e472-434d-9800-e51f144386a5",
    iconSet: "Feather",
    iconName: "user-minus"
  },
  {
    uuid: "1900a003-7a62-449b-8db0-979fbeef4876",
    iconSet: "Feather",
    iconName: "user-plus"
  },
  {
    uuid: "a0f46f61-f4bf-40bf-af46-cc38f2b96a51",
    iconSet: "Feather",
    iconName: "user-x"
  },
  {
    uuid: "4884172e-8830-4581-9ee8-9f08924c35ea",
    iconSet: "Feather",
    iconName: "users"
  },
  {
    uuid: "9647334d-9590-45cd-9906-78a0b9c52c01",
    iconSet: "Feather",
    iconName: "video"
  },
  {
    uuid: "231dd48c-ee88-4c82-832d-ae352aa86435",
    iconSet: "Feather",
    iconName: "video-off"
  },
  {
    uuid: "fc0e8a21-7a7e-4dd7-a972-bace2c4d7021",
    iconSet: "Feather",
    iconName: "voicemail"
  },
  {
    uuid: "23cd52c2-122d-4546-806b-a38cc18af06c",
    iconSet: "Feather",
    iconName: "volume"
  },
  {
    uuid: "6c1f00b1-3063-4d0f-8bce-901130e4c908",
    iconSet: "Feather",
    iconName: "volume-1"
  },
  {
    uuid: "44cfc405-64cc-40b2-8ee7-611e0ab182d8",
    iconSet: "Feather",
    iconName: "volume-2"
  },
  {
    uuid: "a38731a9-5d8c-4ac4-b21d-88c40b5694e3",
    iconSet: "Feather",
    iconName: "volume-x"
  },
  {
    uuid: "52b8783c-55c0-46b2-ad05-b853520ef0ad",
    iconSet: "Feather",
    iconName: "watch"
  },
  {
    uuid: "8b83daec-4f49-41ee-ab83-57d84472ccac",
    iconSet: "Feather",
    iconName: "wifi"
  },
  {
    uuid: "7351fd1e-1b18-4c75-8565-7bb2977cfe7d",
    iconSet: "Feather",
    iconName: "wifi-off"
  },
  {
    uuid: "5352c481-a934-408b-8d99-27c467925e74",
    iconSet: "Feather",
    iconName: "wind"
  },
  {
    uuid: "8004262d-c0fe-431d-a477-bad410569d7f",
    iconSet: "Feather",
    iconName: "x"
  },
  {
    uuid: "54c9290d-546c-4411-b740-4effb630e4e7",
    iconSet: "Feather",
    iconName: "x-circle"
  },
  {
    uuid: "78e82018-225d-4875-88c4-e79ac1cb3b45",
    iconSet: "Feather",
    iconName: "x-octagon"
  },
  {
    uuid: "240c1792-f337-46e8-91e3-6be0b8f438ba",
    iconSet: "Feather",
    iconName: "x-square"
  },
  {
    uuid: "6e261355-6ba8-44d0-9284-1fb8a304abd2",
    iconSet: "Feather",
    iconName: "youtube"
  },
  {
    uuid: "674efad1-17d8-4f3c-8862-919a06353ad6",
    iconSet: "Feather",
    iconName: "zap"
  },
  {
    uuid: "f1b36047-238a-475b-9121-7c6c4bc25329",
    iconSet: "Feather",
    iconName: "zap-off"
  },
  {
    uuid: "cde324d4-43d2-44c9-a528-99a2ebe0952d",
    iconSet: "Feather",
    iconName: "zoom-in"
  },
  {
    uuid: "40fe00a3-751b-4b5e-ad16-4aa50983cde7",
    iconSet: "Feather",
    iconName: "zoom-out"
  },
  {
    uuid: "807ca00b-e2a4-42aa-bac8-382d0aff8fa9",
    iconSet: "FontAwesome",
    iconName: "glass"
  },
  {
    uuid: "2ba9e43c-61f9-4a5f-911e-e7472b922a6e",
    iconSet: "FontAwesome",
    iconName: "music"
  },
  {
    uuid: "b4745969-522d-42b4-96ac-07b81ed8fa9b",
    iconSet: "FontAwesome",
    iconName: "search"
  },
  {
    uuid: "68dac1ff-eca0-45b3-87fd-ae084f6bf777",
    iconSet: "FontAwesome",
    iconName: "envelope-o"
  },
  {
    uuid: "293e7faf-5437-426f-bb57-7aea1db9e983",
    iconSet: "FontAwesome",
    iconName: "heart"
  },
  {
    uuid: "5202b5e8-3887-48e5-ac5c-5781d5911916",
    iconSet: "FontAwesome",
    iconName: "star"
  },
  {
    uuid: "f6783628-4b87-4f5b-807c-8a8c8bf0a676",
    iconSet: "FontAwesome",
    iconName: "star-o"
  },
  {
    uuid: "4207a5b9-b5ee-4ca0-a671-75bf1a23651b",
    iconSet: "FontAwesome",
    iconName: "user"
  },
  {
    uuid: "800e7e06-0736-45f0-adf5-e9673908aa82",
    iconSet: "FontAwesome",
    iconName: "film"
  },
  {
    uuid: "3fb434e0-fbe5-4bc3-9db1-cbf2140e44c0",
    iconSet: "FontAwesome",
    iconName: "th-large"
  },
  {
    uuid: "c44e865b-3af7-48b4-afaf-0094c03b71be",
    iconSet: "FontAwesome",
    iconName: "th"
  },
  {
    uuid: "e492c485-c721-461d-8b27-ed3e37fb643b",
    iconSet: "FontAwesome",
    iconName: "th-list"
  },
  {
    uuid: "9d10eeda-a086-4499-ac0d-3ec0cc5c720b",
    iconSet: "FontAwesome",
    iconName: "check"
  },
  {
    uuid: "c5a6801e-4da1-4791-a0bf-285491a5c19a",
    iconSet: "FontAwesome",
    iconName: "remove"
  },
  {
    uuid: "cbef7099-fdc8-4cc2-bdf5-4800d951d2a8",
    iconSet: "FontAwesome",
    iconName: "close"
  },
  {
    uuid: "4ee48f60-02b4-4d38-8d17-e2e899a47eb0",
    iconSet: "FontAwesome",
    iconName: "times"
  },
  {
    uuid: "1f9de62f-15f8-456d-9162-a48a8c8d2a06",
    iconSet: "FontAwesome",
    iconName: "search-plus"
  },
  {
    uuid: "032bf08a-f54e-4227-9a60-d381be80f170",
    iconSet: "FontAwesome",
    iconName: "search-minus"
  },
  {
    uuid: "d72544f8-012a-4fbc-8399-b6a9dd8521b1",
    iconSet: "FontAwesome",
    iconName: "power-off"
  },
  {
    uuid: "bdcb75ed-41cb-4da2-85b9-c2871fb07a3e",
    iconSet: "FontAwesome",
    iconName: "signal"
  },
  {
    uuid: "d779ceb0-bbae-42c3-b8a7-a204a59722b9",
    iconSet: "FontAwesome",
    iconName: "gear"
  },
  {
    uuid: "4c70c20a-5fb9-4fd1-bd5e-4c7d965ff4a3",
    iconSet: "FontAwesome",
    iconName: "cog"
  },
  {
    uuid: "52106f89-03a3-4939-9cb7-b7b58d6c2503",
    iconSet: "FontAwesome",
    iconName: "trash-o"
  },
  {
    uuid: "be306143-970c-4b95-82ad-f1e7759a1803",
    iconSet: "FontAwesome",
    iconName: "home"
  },
  {
    uuid: "ca9e3832-2c20-4d31-b96e-5830e0e612ac",
    iconSet: "FontAwesome",
    iconName: "file-o"
  },
  {
    uuid: "033d31aa-2f43-4630-840e-2f4c2d3fa97b",
    iconSet: "FontAwesome",
    iconName: "clock-o"
  },
  {
    uuid: "de9f42ba-cfb6-4049-bcae-c5618ce60bc4",
    iconSet: "FontAwesome",
    iconName: "road"
  },
  {
    uuid: "9d78bbb0-8e94-44fd-887e-52be36d019b8",
    iconSet: "FontAwesome",
    iconName: "download"
  },
  {
    uuid: "3b818600-0dc0-45f6-ac41-99ea5cf645ec",
    iconSet: "FontAwesome",
    iconName: "arrow-circle-o-down"
  },
  {
    uuid: "89b3b211-6a4b-4ca8-b9d6-26022b493905",
    iconSet: "FontAwesome",
    iconName: "arrow-circle-o-up"
  },
  {
    uuid: "941db481-b8de-4692-a3aa-13c0149a694c",
    iconSet: "FontAwesome",
    iconName: "inbox"
  },
  {
    uuid: "54977dec-0698-4348-a7d0-ff0230a20676",
    iconSet: "FontAwesome",
    iconName: "play-circle-o"
  },
  {
    uuid: "cff76e15-d9a0-4b7e-b96d-f741493090b7",
    iconSet: "FontAwesome",
    iconName: "rotate-right"
  },
  {
    uuid: "0210a3ed-c39c-4af9-888d-56319d7bd7d3",
    iconSet: "FontAwesome",
    iconName: "repeat"
  },
  {
    uuid: "fe478f5c-c956-4438-a3af-5eac63d1307f",
    iconSet: "FontAwesome",
    iconName: "refresh"
  },
  {
    uuid: "61f785f8-f4c6-4374-a040-8b83903f87f5",
    iconSet: "FontAwesome",
    iconName: "list-alt"
  },
  {
    uuid: "3054679b-9dcb-46da-96b7-9f89e221983a",
    iconSet: "FontAwesome",
    iconName: "lock"
  },
  {
    uuid: "3a0ceb05-5e18-47b6-b9ef-bab2c9dddb80",
    iconSet: "FontAwesome",
    iconName: "flag"
  },
  {
    uuid: "575fb855-c2cd-43de-9d71-5f7180cfc37f",
    iconSet: "FontAwesome",
    iconName: "headphones"
  },
  {
    uuid: "101e0b49-dae8-4651-8955-a1de421f4f62",
    iconSet: "FontAwesome",
    iconName: "volume-off"
  },
  {
    uuid: "f883223e-bc89-472b-9b2f-925345c83240",
    iconSet: "FontAwesome",
    iconName: "volume-down"
  },
  {
    uuid: "c22cde08-e4aa-42cd-98f3-d72983001757",
    iconSet: "FontAwesome",
    iconName: "volume-up"
  },
  {
    uuid: "e166e1e8-0d1d-48b5-b085-c207069a11c8",
    iconSet: "FontAwesome",
    iconName: "qrcode"
  },
  {
    uuid: "a10afee2-f236-4683-b7c7-49b60cae144c",
    iconSet: "FontAwesome",
    iconName: "barcode"
  },
  {
    uuid: "8dc852af-a43c-4602-8881-87655f68f783",
    iconSet: "FontAwesome",
    iconName: "tag"
  },
  {
    uuid: "e73a527d-7459-4cb0-b08f-93b372e48b70",
    iconSet: "FontAwesome",
    iconName: "tags"
  },
  {
    uuid: "6bf241d9-f4df-4764-a797-098b106b3ff4",
    iconSet: "FontAwesome",
    iconName: "book"
  },
  {
    uuid: "623a2072-9637-4430-9e60-fa7e6066dbdb",
    iconSet: "FontAwesome",
    iconName: "bookmark"
  },
  {
    uuid: "db7b72d0-2fb1-4c29-b2f2-07d66c4bce88",
    iconSet: "FontAwesome",
    iconName: "print"
  },
  {
    uuid: "d98372bb-8547-4908-aaac-79f0424e6005",
    iconSet: "FontAwesome",
    iconName: "camera"
  },
  {
    uuid: "cae2c041-b724-4153-9ec2-79418b2c9135",
    iconSet: "FontAwesome",
    iconName: "font"
  },
  {
    uuid: "462db64a-71cd-4912-b1e7-aa547c408aa4",
    iconSet: "FontAwesome",
    iconName: "bold"
  },
  {
    uuid: "6cfc654a-d471-4a94-82dc-2cd08d63a48b",
    iconSet: "FontAwesome",
    iconName: "italic"
  },
  {
    uuid: "eb7d07a1-1e1d-43a3-8360-ca9938b16039",
    iconSet: "FontAwesome",
    iconName: "text-height"
  },
  {
    uuid: "2890c330-0fa2-4899-9e98-c1ed3021a28d",
    iconSet: "FontAwesome",
    iconName: "text-width"
  },
  {
    uuid: "e1c43941-c93b-4f09-84d2-d46f77406031",
    iconSet: "FontAwesome",
    iconName: "align-left"
  },
  {
    uuid: "54cf1c4d-e729-4368-a060-8c24136827a8",
    iconSet: "FontAwesome",
    iconName: "align-center"
  },
  {
    uuid: "0d4da1e8-0451-403c-ab93-f1b43bcec23a",
    iconSet: "FontAwesome",
    iconName: "align-right"
  },
  {
    uuid: "3dfea64c-f2ca-4d51-8acd-5f86ba25deea",
    iconSet: "FontAwesome",
    iconName: "align-justify"
  },
  {
    uuid: "8ab1c3e0-1c14-48db-990a-9b224b981ca3",
    iconSet: "FontAwesome",
    iconName: "list"
  },
  {
    uuid: "a0f51541-da34-453b-9636-2c3cf9680383",
    iconSet: "FontAwesome",
    iconName: "dedent"
  },
  {
    uuid: "2fd828f0-6010-421d-a028-7b8b27d4a966",
    iconSet: "FontAwesome",
    iconName: "outdent"
  },
  {
    uuid: "40754d3a-e5b6-4233-8e41-0de0445bbc3f",
    iconSet: "FontAwesome",
    iconName: "indent"
  },
  {
    uuid: "66252673-e2ec-4479-96cc-7eb74c916bae",
    iconSet: "FontAwesome",
    iconName: "video-camera"
  },
  {
    uuid: "12fb2270-6b00-4bfb-b328-816caf51845d",
    iconSet: "FontAwesome",
    iconName: "photo"
  },
  {
    uuid: "78e430f5-2ca3-4b17-ad92-987099c1420d",
    iconSet: "FontAwesome",
    iconName: "image"
  },
  {
    uuid: "70cce460-f8e2-488f-8fa9-b0a6e1f04742",
    iconSet: "FontAwesome",
    iconName: "picture-o"
  },
  {
    uuid: "a0943769-6391-4d2b-bffe-cbdd2e6d6422",
    iconSet: "FontAwesome",
    iconName: "pencil"
  },
  {
    uuid: "6539b732-facc-4e65-82b8-ca1982cfe2a4",
    iconSet: "FontAwesome",
    iconName: "map-marker"
  },
  {
    uuid: "39f60bbd-3f6a-426d-9d7d-153aab5bf1fc",
    iconSet: "FontAwesome",
    iconName: "adjust"
  },
  {
    uuid: "5562640e-737a-4740-b501-14bf5ad338b5",
    iconSet: "FontAwesome",
    iconName: "tint"
  },
  {
    uuid: "bf7cdbaf-f8fa-4833-a341-c39ffc0ff1f9",
    iconSet: "FontAwesome",
    iconName: "edit"
  },
  {
    uuid: "73c61363-235a-4f28-aba1-99688032e3b9",
    iconSet: "FontAwesome",
    iconName: "pencil-square-o"
  },
  {
    uuid: "1294b8a9-9aba-41e1-9ef6-41a44a671ed4",
    iconSet: "FontAwesome",
    iconName: "share-square-o"
  },
  {
    uuid: "553ac180-d44b-4df7-bc50-edaac50b5f47",
    iconSet: "FontAwesome",
    iconName: "check-square-o"
  },
  {
    uuid: "e266f721-e26c-4b1f-aef0-78b77be1fa22",
    iconSet: "FontAwesome",
    iconName: "arrows"
  },
  {
    uuid: "32b766e4-49a4-44af-862b-5324db731502",
    iconSet: "FontAwesome",
    iconName: "step-backward"
  },
  {
    uuid: "412b8d7b-d56b-430b-8f89-0e8e7d59e5f1",
    iconSet: "FontAwesome",
    iconName: "fast-backward"
  },
  {
    uuid: "8fec3b45-e9ea-4404-b94f-00c427af5280",
    iconSet: "FontAwesome",
    iconName: "backward"
  },
  {
    uuid: "b19ed932-d54e-4cb8-bea2-6510092ab06c",
    iconSet: "FontAwesome",
    iconName: "play"
  },
  {
    uuid: "867c16b3-e41c-430d-b7c7-f02b5054d2b0",
    iconSet: "FontAwesome",
    iconName: "pause"
  },
  {
    uuid: "9bba5fff-c6da-4339-97ad-91799ab6802f",
    iconSet: "FontAwesome",
    iconName: "stop"
  },
  {
    uuid: "ad0cb4e6-6649-496b-9a7e-f690b18bfab2",
    iconSet: "FontAwesome",
    iconName: "forward"
  },
  {
    uuid: "3537396e-aae6-4c26-aece-448d02c7d7b1",
    iconSet: "FontAwesome",
    iconName: "fast-forward"
  },
  {
    uuid: "140a42fd-9186-4709-871b-458ce6ad0b04",
    iconSet: "FontAwesome",
    iconName: "step-forward"
  },
  {
    uuid: "ef97f16b-321b-493b-9394-779e06e702dc",
    iconSet: "FontAwesome",
    iconName: "eject"
  },
  {
    uuid: "e3e23f03-36b8-4a28-a830-7c03e39424ee",
    iconSet: "FontAwesome",
    iconName: "chevron-left"
  },
  {
    uuid: "f6a4f02f-af54-4c88-a2f7-1918fcd0251b",
    iconSet: "FontAwesome",
    iconName: "chevron-right"
  },
  {
    uuid: "c3ab70f6-1b90-492f-be47-81a11ed8ad4a",
    iconSet: "FontAwesome",
    iconName: "plus-circle"
  },
  {
    uuid: "a2dff105-a3b5-4e50-8fd0-01ab8ad5d37f",
    iconSet: "FontAwesome",
    iconName: "minus-circle"
  },
  {
    uuid: "77826771-f7ea-4298-8cdb-60df1a724d9d",
    iconSet: "FontAwesome",
    iconName: "times-circle"
  },
  {
    uuid: "599f52dc-15e6-409f-84a3-d7c321222218",
    iconSet: "FontAwesome",
    iconName: "check-circle"
  },
  {
    uuid: "86707828-904a-43ca-9e65-d262ecea15da",
    iconSet: "FontAwesome",
    iconName: "question-circle"
  },
  {
    uuid: "25a94e75-d3ce-4ef5-b7a8-5c98cd5860b8",
    iconSet: "FontAwesome",
    iconName: "info-circle"
  },
  {
    uuid: "5f996ea2-5816-42a6-9806-a2b781d5790d",
    iconSet: "FontAwesome",
    iconName: "crosshairs"
  },
  {
    uuid: "3a80c6c4-2b4c-4f58-be87-8dde516f843e",
    iconSet: "FontAwesome",
    iconName: "times-circle-o"
  },
  {
    uuid: "41efc877-561a-4709-ae4c-d975a1c1dffa",
    iconSet: "FontAwesome",
    iconName: "check-circle-o"
  },
  {
    uuid: "1a36c8f7-52bc-4035-a010-259137084425",
    iconSet: "FontAwesome",
    iconName: "ban"
  },
  {
    uuid: "89f45922-65a4-4ccd-8596-eea9c813cb27",
    iconSet: "FontAwesome",
    iconName: "arrow-left"
  },
  {
    uuid: "9bfac1ce-bad5-49d6-90a2-d7ff289f3301",
    iconSet: "FontAwesome",
    iconName: "arrow-right"
  },
  {
    uuid: "1eb11e52-5d1b-4cf6-b0b3-223f14a235e1",
    iconSet: "FontAwesome",
    iconName: "arrow-up"
  },
  {
    uuid: "22e8d655-e073-4dc7-851c-5d0f29266035",
    iconSet: "FontAwesome",
    iconName: "arrow-down"
  },
  {
    uuid: "a4d56d7d-9c06-4552-88ca-996eda32c449",
    iconSet: "FontAwesome",
    iconName: "mail-forward"
  },
  {
    uuid: "b1c09bae-8097-4e0a-8afe-6df0cab61e2c",
    iconSet: "FontAwesome",
    iconName: "share"
  },
  {
    uuid: "bdf53149-1c45-47bc-a7a6-0ddc2e6626af",
    iconSet: "FontAwesome",
    iconName: "expand"
  },
  {
    uuid: "ea5a1ff7-b5ad-402b-8140-8e3faca6d715",
    iconSet: "FontAwesome",
    iconName: "compress"
  },
  {
    uuid: "1b2dd1fe-f4d8-4c19-85da-aaf91a63fabf",
    iconSet: "FontAwesome",
    iconName: "plus"
  },
  {
    uuid: "4c0930b0-4ea8-4ab9-b223-14872e1658a9",
    iconSet: "FontAwesome",
    iconName: "minus"
  },
  {
    uuid: "b89b534c-cd77-454a-9cdc-def3a6e9d3ce",
    iconSet: "FontAwesome",
    iconName: "asterisk"
  },
  {
    uuid: "818fb4d3-da96-4151-a11d-485c487a6437",
    iconSet: "FontAwesome",
    iconName: "exclamation-circle"
  },
  {
    uuid: "03ee8cf6-8645-4be8-8120-9b9ceeb35213",
    iconSet: "FontAwesome",
    iconName: "gift"
  },
  {
    uuid: "485f9967-4954-442d-bbf4-b0192bf671f9",
    iconSet: "FontAwesome",
    iconName: "leaf"
  },
  {
    uuid: "40992cf1-6162-4df0-8365-40c6799271ea",
    iconSet: "FontAwesome",
    iconName: "fire"
  },
  {
    uuid: "db2b2bce-e550-4462-8211-6df3265016f0",
    iconSet: "FontAwesome",
    iconName: "eye"
  },
  {
    uuid: "1ab90394-9ac7-49a5-b7f9-1b3c11f2a43e",
    iconSet: "FontAwesome",
    iconName: "eye-slash"
  },
  {
    uuid: "0a36eb17-0ecd-452a-8bf1-9a07474d6595",
    iconSet: "FontAwesome",
    iconName: "warning"
  },
  {
    uuid: "49bf7924-d3a6-486e-b171-e1723e7a0f40",
    iconSet: "FontAwesome",
    iconName: "exclamation-triangle"
  },
  {
    uuid: "316c24ec-7841-41d9-b4d6-77568d313f7a",
    iconSet: "FontAwesome",
    iconName: "plane"
  },
  {
    uuid: "0ffd7737-7a2c-4c30-935a-6f5a65b7bdcc",
    iconSet: "FontAwesome",
    iconName: "calendar"
  },
  {
    uuid: "06f98fa9-07ff-4065-8fee-ab88309135e5",
    iconSet: "FontAwesome",
    iconName: "random"
  },
  {
    uuid: "5bc3e8eb-ddec-48aa-b5f1-db208766fd2e",
    iconSet: "FontAwesome",
    iconName: "comment"
  },
  {
    uuid: "0f33b230-3ec1-44b4-b347-18e72881e626",
    iconSet: "FontAwesome",
    iconName: "magnet"
  },
  {
    uuid: "d2b95d87-05f9-4a43-9245-7a783d6c63e5",
    iconSet: "FontAwesome",
    iconName: "chevron-up"
  },
  {
    uuid: "977e0cde-987c-4c30-8ecf-1decd0ffe340",
    iconSet: "FontAwesome",
    iconName: "chevron-down"
  },
  {
    uuid: "6caff01d-0bd2-45cd-94a6-eb428af7c157",
    iconSet: "FontAwesome",
    iconName: "retweet"
  },
  {
    uuid: "f7322557-6bb9-434a-a747-1ba28ddfaf85",
    iconSet: "FontAwesome",
    iconName: "shopping-cart"
  },
  {
    uuid: "45f5d1be-cd0c-4439-ad3d-ca9432401087",
    iconSet: "FontAwesome",
    iconName: "folder"
  },
  {
    uuid: "3099a075-0894-4a86-bc29-cf479c116dba",
    iconSet: "FontAwesome",
    iconName: "folder-open"
  },
  {
    uuid: "5a1f7e8c-5b41-4f38-b941-50f3b54e3566",
    iconSet: "FontAwesome",
    iconName: "arrows-v"
  },
  {
    uuid: "d3e03d72-72f8-441e-9f4a-7d6c8e07a1b6",
    iconSet: "FontAwesome",
    iconName: "arrows-h"
  },
  {
    uuid: "859b2032-d59b-43d5-9d3d-8222ff9e0631",
    iconSet: "FontAwesome",
    iconName: "bar-chart-o"
  },
  {
    uuid: "d5ddcb85-dd3f-41a5-8ffb-68f463c8688c",
    iconSet: "FontAwesome",
    iconName: "bar-chart"
  },
  {
    uuid: "4dbb7cbb-0847-4d81-aca7-ac5b0e9cf8cb",
    iconSet: "FontAwesome",
    iconName: "twitter-square"
  },
  {
    uuid: "a6f77512-a865-440c-aa83-670a68e98b33",
    iconSet: "FontAwesome",
    iconName: "facebook-square"
  },
  {
    uuid: "146e22fc-1747-41cd-a1e7-fb5f2c754c71",
    iconSet: "FontAwesome",
    iconName: "camera-retro"
  },
  {
    uuid: "1ad9fc36-7d93-4cf5-9690-69309dfa1530",
    iconSet: "FontAwesome",
    iconName: "key"
  },
  {
    uuid: "3df212a5-e913-4c79-8bbf-d8e67bda55c4",
    iconSet: "FontAwesome",
    iconName: "gears"
  },
  {
    uuid: "17fca4c1-1a59-423d-94bf-073cd2407e3e",
    iconSet: "FontAwesome",
    iconName: "cogs"
  },
  {
    uuid: "8f21ae62-5970-4c68-ad54-304053fcfaa4",
    iconSet: "FontAwesome",
    iconName: "comments"
  },
  {
    uuid: "32a5bdcf-7251-44de-b8b1-e452eb5b0baf",
    iconSet: "FontAwesome",
    iconName: "thumbs-o-up"
  },
  {
    uuid: "816c6aa4-9068-4e5e-8a48-9cd645bc1cc2",
    iconSet: "FontAwesome",
    iconName: "thumbs-o-down"
  },
  {
    uuid: "60a38b34-017a-4d73-a787-417d06b77e11",
    iconSet: "FontAwesome",
    iconName: "star-half"
  },
  {
    uuid: "f297c747-40cd-4265-ac92-f849ff51126f",
    iconSet: "FontAwesome",
    iconName: "heart-o"
  },
  {
    uuid: "3b955ef1-2f23-48fa-9285-45ac9514eee6",
    iconSet: "FontAwesome",
    iconName: "sign-out"
  },
  {
    uuid: "1e3195cd-2973-4d33-b913-053a6a5c7dfa",
    iconSet: "FontAwesome",
    iconName: "linkedin-square"
  },
  {
    uuid: "84e3c436-18e7-4966-93f2-ec0dea8d95d2",
    iconSet: "FontAwesome",
    iconName: "thumb-tack"
  },
  {
    uuid: "43bd728a-4382-42d9-8845-242413e6a873",
    iconSet: "FontAwesome",
    iconName: "external-link"
  },
  {
    uuid: "fc939e07-baed-410a-9e56-40f3b4275f7c",
    iconSet: "FontAwesome",
    iconName: "sign-in"
  },
  {
    uuid: "06d77c53-3379-4629-8d28-c1df4050d6b5",
    iconSet: "FontAwesome",
    iconName: "trophy"
  },
  {
    uuid: "c08f3d41-b8c7-4a75-8512-e4b7db817648",
    iconSet: "FontAwesome",
    iconName: "github-square"
  },
  {
    uuid: "b4f857e2-d416-49c5-910c-34d2ac20b8fa",
    iconSet: "FontAwesome",
    iconName: "upload"
  },
  {
    uuid: "838ad01f-774e-40b9-9505-fd21665e7d2f",
    iconSet: "FontAwesome",
    iconName: "lemon-o"
  },
  {
    uuid: "f8a33af2-526d-4b43-b675-a244660b7e3f",
    iconSet: "FontAwesome",
    iconName: "phone"
  },
  {
    uuid: "78e005bf-772a-4231-a189-9fefac9f9f01",
    iconSet: "FontAwesome",
    iconName: "square-o"
  },
  {
    uuid: "f21af1f2-0f2d-480d-882b-15d7d40c94ec",
    iconSet: "FontAwesome",
    iconName: "bookmark-o"
  },
  {
    uuid: "4413be78-9553-43c2-9124-ab5d8afd0c0b",
    iconSet: "FontAwesome",
    iconName: "phone-square"
  },
  {
    uuid: "bc9abbde-5310-4762-a6c6-190a7e94b906",
    iconSet: "FontAwesome",
    iconName: "twitter"
  },
  {
    uuid: "692c2285-c1a2-4515-97dc-f492bbe19359",
    iconSet: "FontAwesome",
    iconName: "facebook-f"
  },
  {
    uuid: "bfb7cbb0-0133-4104-92c5-82f1317dd5e9",
    iconSet: "FontAwesome",
    iconName: "facebook"
  },
  {
    uuid: "668ef5ec-faad-4a40-88a5-67bf88c5b178",
    iconSet: "FontAwesome",
    iconName: "github"
  },
  {
    uuid: "25c375b9-df75-42f3-b964-bf80b1fdbc58",
    iconSet: "FontAwesome",
    iconName: "unlock"
  },
  {
    uuid: "3550c565-778d-471f-bf15-553fb5e79cff",
    iconSet: "FontAwesome",
    iconName: "credit-card"
  },
  {
    uuid: "db429184-0633-406c-91bf-fc58f230b190",
    iconSet: "FontAwesome",
    iconName: "feed"
  },
  {
    uuid: "cefad0cc-4aed-4305-893b-6eed63fc5ec8",
    iconSet: "FontAwesome",
    iconName: "rss"
  },
  {
    uuid: "925b9f56-0fd8-4056-974e-2d94efa537e7",
    iconSet: "FontAwesome",
    iconName: "hdd-o"
  },
  {
    uuid: "f34dfe8c-c4d7-476a-84d5-7ce2e68c92e9",
    iconSet: "FontAwesome",
    iconName: "bullhorn"
  },
  {
    uuid: "00110a88-384e-4766-a5f7-60a7f6be5ab6",
    iconSet: "FontAwesome",
    iconName: "bell"
  },
  {
    uuid: "a5a4cf43-e247-4b25-b46d-1d4aee993907",
    iconSet: "FontAwesome",
    iconName: "certificate"
  },
  {
    uuid: "5f09a4a5-479f-452d-ba5a-5d33b820d26b",
    iconSet: "FontAwesome",
    iconName: "hand-o-right"
  },
  {
    uuid: "2cd8233f-58c6-4de5-beac-62a513157c75",
    iconSet: "FontAwesome",
    iconName: "hand-o-left"
  },
  {
    uuid: "a3ef81ad-18a3-4da1-817f-47ebcb2591e7",
    iconSet: "FontAwesome",
    iconName: "hand-o-up"
  },
  {
    uuid: "37061eb6-473a-487f-a82e-1012028c8269",
    iconSet: "FontAwesome",
    iconName: "hand-o-down"
  },
  {
    uuid: "424ed712-54aa-4f8e-b950-ac36f19b8cf2",
    iconSet: "FontAwesome",
    iconName: "arrow-circle-left"
  },
  {
    uuid: "e3e578d0-ba17-4f97-8f09-12d180c7d545",
    iconSet: "FontAwesome",
    iconName: "arrow-circle-right"
  },
  {
    uuid: "dc1b2562-83c9-41bc-b0d4-cc9c52c3dea8",
    iconSet: "FontAwesome",
    iconName: "arrow-circle-up"
  },
  {
    uuid: "4a4f6450-4756-4e6f-a5ad-415598c3f701",
    iconSet: "FontAwesome",
    iconName: "arrow-circle-down"
  },
  {
    uuid: "44f309d5-af9c-4166-8376-b9d047a0cda1",
    iconSet: "FontAwesome",
    iconName: "globe"
  },
  {
    uuid: "95830b69-06a7-4f56-8b1d-cb734c8f0f64",
    iconSet: "FontAwesome",
    iconName: "wrench"
  },
  {
    uuid: "26d72e1a-ed04-4339-b863-e6c09f884ba4",
    iconSet: "FontAwesome",
    iconName: "tasks"
  },
  {
    uuid: "dfd5d8b9-0354-4e40-bbe7-32b773707879",
    iconSet: "FontAwesome",
    iconName: "filter"
  },
  {
    uuid: "dbae0d42-d907-4e7d-b39b-51a387653732",
    iconSet: "FontAwesome",
    iconName: "briefcase"
  },
  {
    uuid: "67e19b48-1721-4c6f-8666-3e5e8258a5d5",
    iconSet: "FontAwesome",
    iconName: "arrows-alt"
  },
  {
    uuid: "806527f1-99c5-49e1-8a86-f39a5031a7b3",
    iconSet: "FontAwesome",
    iconName: "group"
  },
  {
    uuid: "3cc04af1-ed20-4371-b85a-bb3aa444b8f7",
    iconSet: "FontAwesome",
    iconName: "users"
  },
  {
    uuid: "188eb1d4-5d7a-4758-8d44-a35626c40820",
    iconSet: "FontAwesome",
    iconName: "chain"
  },
  {
    uuid: "403914f1-0d56-4507-adde-6582ce78740d",
    iconSet: "FontAwesome",
    iconName: "link"
  },
  {
    uuid: "2b1e0770-6891-4953-ab52-8454973b0c81",
    iconSet: "FontAwesome",
    iconName: "cloud"
  },
  {
    uuid: "d36d32fe-089a-4122-af9d-b18e7f149ca1",
    iconSet: "FontAwesome",
    iconName: "flask"
  },
  {
    uuid: "b095cb98-37a3-41b2-aecf-b0bf38dc2d6e",
    iconSet: "FontAwesome",
    iconName: "cut"
  },
  {
    uuid: "b20744c6-b70a-4fa4-9654-99ce1660578b",
    iconSet: "FontAwesome",
    iconName: "scissors"
  },
  {
    uuid: "a62704b2-46bb-4b35-85fd-bddc66685e17",
    iconSet: "FontAwesome",
    iconName: "copy"
  },
  {
    uuid: "bb7dc128-bac6-42b1-97f1-bad15de32a84",
    iconSet: "FontAwesome",
    iconName: "files-o"
  },
  {
    uuid: "baed970b-9449-413a-9e26-eba607e5aff5",
    iconSet: "FontAwesome",
    iconName: "paperclip"
  },
  {
    uuid: "62f77a14-6395-4f35-873e-8fadba8f7a96",
    iconSet: "FontAwesome",
    iconName: "save"
  },
  {
    uuid: "0fb1aaf2-e825-496a-b65e-ac768c03e188",
    iconSet: "FontAwesome",
    iconName: "floppy-o"
  },
  {
    uuid: "aa353ffc-5dd2-4b3c-b996-031ee99920f5",
    iconSet: "FontAwesome",
    iconName: "square"
  },
  {
    uuid: "9f2cfa37-d72b-496b-a583-f455b98433a7",
    iconSet: "FontAwesome",
    iconName: "navicon"
  },
  {
    uuid: "30fdaf72-0363-486f-b14a-5898a42b11f1",
    iconSet: "FontAwesome",
    iconName: "reorder"
  },
  {
    uuid: "3b6fb6e1-4704-4cab-ac40-05c68c38888a",
    iconSet: "FontAwesome",
    iconName: "bars"
  },
  {
    uuid: "ec3c006e-0eef-4eea-aa36-d38a3f21eec8",
    iconSet: "FontAwesome",
    iconName: "list-ul"
  },
  {
    uuid: "511ebf75-6bed-43d7-99a6-6127c0b20ec7",
    iconSet: "FontAwesome",
    iconName: "list-ol"
  },
  {
    uuid: "dfbc69bf-61a0-473a-b016-5516b76b4075",
    iconSet: "FontAwesome",
    iconName: "strikethrough"
  },
  {
    uuid: "6802528a-8b9d-4c75-a497-e7fe74d1c429",
    iconSet: "FontAwesome",
    iconName: "underline"
  },
  {
    uuid: "2853bb65-85d9-4f35-b90e-731ecd0d2c17",
    iconSet: "FontAwesome",
    iconName: "table"
  },
  {
    uuid: "8a31d61d-db0b-4ae4-bc75-6c2040f04e67",
    iconSet: "FontAwesome",
    iconName: "magic"
  },
  {
    uuid: "e6281b54-606b-4fc9-9459-f8d79d71ca75",
    iconSet: "FontAwesome",
    iconName: "truck"
  },
  {
    uuid: "07e43aaf-fe73-44fc-b1fe-ee4abf58c1ac",
    iconSet: "FontAwesome",
    iconName: "pinterest"
  },
  {
    uuid: "b89098c5-1bcb-44e9-985b-606638a9f1e8",
    iconSet: "FontAwesome",
    iconName: "pinterest-square"
  },
  {
    uuid: "9d2cfea5-8b17-46bf-a2ef-2d891e31a719",
    iconSet: "FontAwesome",
    iconName: "google-plus-square"
  },
  {
    uuid: "9b8d2d4b-f443-46db-b5d1-c9d420608d2b",
    iconSet: "FontAwesome",
    iconName: "google-plus"
  },
  {
    uuid: "1052fdfd-ee88-472f-8027-0638b2ff8e7a",
    iconSet: "FontAwesome",
    iconName: "money"
  },
  {
    uuid: "3ecf8720-1c0a-4422-b565-5253510700e2",
    iconSet: "FontAwesome",
    iconName: "caret-down"
  },
  {
    uuid: "4ba7ff69-8d0e-4c45-abe9-1210ae4ee23d",
    iconSet: "FontAwesome",
    iconName: "caret-up"
  },
  {
    uuid: "3f93f664-4ed7-4afb-9da5-65ef95abea2a",
    iconSet: "FontAwesome",
    iconName: "caret-left"
  },
  {
    uuid: "8abb73e0-88b5-44a7-b0b1-4d6481e9ba69",
    iconSet: "FontAwesome",
    iconName: "caret-right"
  },
  {
    uuid: "a84d7973-6576-409a-9f20-d23058c23dbd",
    iconSet: "FontAwesome",
    iconName: "columns"
  },
  {
    uuid: "80e908f8-5957-4bfb-850f-5ecfe6f03266",
    iconSet: "FontAwesome",
    iconName: "unsorted"
  },
  {
    uuid: "13294dea-d959-4432-9d0e-87990821718f",
    iconSet: "FontAwesome",
    iconName: "sort"
  },
  {
    uuid: "c45dfa9f-570e-41c0-aa4b-9b63f4537d5a",
    iconSet: "FontAwesome",
    iconName: "sort-down"
  },
  {
    uuid: "402c7ac6-5847-4707-81cd-245a58277141",
    iconSet: "FontAwesome",
    iconName: "sort-desc"
  },
  {
    uuid: "60f3a77e-e95d-44dc-a87c-72836e77b3f7",
    iconSet: "FontAwesome",
    iconName: "sort-up"
  },
  {
    uuid: "64a3fd9a-a205-4457-bf55-f11dcc59e9fa",
    iconSet: "FontAwesome",
    iconName: "sort-asc"
  },
  {
    uuid: "c6792c06-85ff-4789-be71-5164bec6ac82",
    iconSet: "FontAwesome",
    iconName: "envelope"
  },
  {
    uuid: "d9b472ab-b72b-45e7-8f6f-2b735cfbb7c1",
    iconSet: "FontAwesome",
    iconName: "linkedin"
  },
  {
    uuid: "32420c97-6e8b-4f6d-baee-d595ca1cb4a3",
    iconSet: "FontAwesome",
    iconName: "rotate-left"
  },
  {
    uuid: "41a527a9-db4d-4bfa-bd4d-b918f8fdb800",
    iconSet: "FontAwesome",
    iconName: "undo"
  },
  {
    uuid: "58dfdd7d-ccc7-4f05-b726-2c69e2a7e1b3",
    iconSet: "FontAwesome",
    iconName: "legal"
  },
  {
    uuid: "dac12358-fe78-469f-ad8a-7fdb47acfffb",
    iconSet: "FontAwesome",
    iconName: "gavel"
  },
  {
    uuid: "2726f57d-3e38-4214-80f8-b513deff6791",
    iconSet: "FontAwesome",
    iconName: "dashboard"
  },
  {
    uuid: "d33af76d-d3e7-4f6c-94f1-485a230a3fd5",
    iconSet: "FontAwesome",
    iconName: "tachometer"
  },
  {
    uuid: "938bc9aa-da9c-43d4-9a35-d85db54619bb",
    iconSet: "FontAwesome",
    iconName: "comment-o"
  },
  {
    uuid: "4f4e39d8-b4a7-4b41-a8b3-6066b33cebfb",
    iconSet: "FontAwesome",
    iconName: "comments-o"
  },
  {
    uuid: "08d783f1-b84d-4854-a8d6-6ca1c25532f8",
    iconSet: "FontAwesome",
    iconName: "flash"
  },
  {
    uuid: "0c78ad39-76ba-477b-9839-88e91da8f90c",
    iconSet: "FontAwesome",
    iconName: "bolt"
  },
  {
    uuid: "8467f056-3051-4917-b9f5-fbce08a46aa1",
    iconSet: "FontAwesome",
    iconName: "sitemap"
  },
  {
    uuid: "4eb62812-2bc9-4ec2-b2f0-8feafa9a6638",
    iconSet: "FontAwesome",
    iconName: "umbrella"
  },
  {
    uuid: "032a664c-3a38-4a28-95ef-5a71615bb7d3",
    iconSet: "FontAwesome",
    iconName: "paste"
  },
  {
    uuid: "fb0fdce9-1132-4df9-b10a-0fb67aba9765",
    iconSet: "FontAwesome",
    iconName: "clipboard"
  },
  {
    uuid: "5890b277-6c0e-463c-b507-749bc4ecaceb",
    iconSet: "FontAwesome",
    iconName: "lightbulb-o"
  },
  {
    uuid: "d96c3560-630f-4866-8662-1f1e901dfe9a",
    iconSet: "FontAwesome",
    iconName: "exchange"
  },
  {
    uuid: "e6953335-9daa-4e8e-9e19-8932d7fed36f",
    iconSet: "FontAwesome",
    iconName: "cloud-download"
  },
  {
    uuid: "10b00d29-826b-48f6-86df-4c6535b929f7",
    iconSet: "FontAwesome",
    iconName: "cloud-upload"
  },
  {
    uuid: "db97bdba-238f-4972-bed0-6908664012c0",
    iconSet: "FontAwesome",
    iconName: "user-md"
  },
  {
    uuid: "58457efa-dbab-41db-8ead-1cd7157f91f7",
    iconSet: "FontAwesome",
    iconName: "stethoscope"
  },
  {
    uuid: "f3a8aaba-6e5b-4258-9613-8500aee1a67e",
    iconSet: "FontAwesome",
    iconName: "suitcase"
  },
  {
    uuid: "5b6ae43b-4113-47ea-a2c6-f9f80ee55843",
    iconSet: "FontAwesome",
    iconName: "bell-o"
  },
  {
    uuid: "219a3cb0-ea1f-4a85-87ca-d716eed7cd39",
    iconSet: "FontAwesome",
    iconName: "coffee"
  },
  {
    uuid: "d1026a60-272f-4c84-9394-5a6a5df3864c",
    iconSet: "FontAwesome",
    iconName: "cutlery"
  },
  {
    uuid: "b4eeb1f6-060d-4d96-bf04-fcc3677d6029",
    iconSet: "FontAwesome",
    iconName: "file-text-o"
  },
  {
    uuid: "bb78bdac-6a78-4e6c-bf55-b9bea0977924",
    iconSet: "FontAwesome",
    iconName: "building-o"
  },
  {
    uuid: "67518b68-7410-44ac-8783-d84881078018",
    iconSet: "FontAwesome",
    iconName: "hospital-o"
  },
  {
    uuid: "8b930ce5-6908-4e6b-be66-34dd60239797",
    iconSet: "FontAwesome",
    iconName: "ambulance"
  },
  {
    uuid: "f63d990f-d845-4ed4-9dbb-832a6f0d46df",
    iconSet: "FontAwesome",
    iconName: "medkit"
  },
  {
    uuid: "2ad9d7d8-b256-449e-ba3d-476d455ceefd",
    iconSet: "FontAwesome",
    iconName: "fighter-jet"
  },
  {
    uuid: "647918c6-0292-4c6a-8330-1739ed752ddb",
    iconSet: "FontAwesome",
    iconName: "beer"
  },
  {
    uuid: "1725298f-cb97-4326-aa45-0b3550ba97ad",
    iconSet: "FontAwesome",
    iconName: "h-square"
  },
  {
    uuid: "e8f01905-31d5-4709-b9e1-c4f6cc8da119",
    iconSet: "FontAwesome",
    iconName: "plus-square"
  },
  {
    uuid: "f6a2525c-d66c-4cdc-9f9b-c87f2296d1d4",
    iconSet: "FontAwesome",
    iconName: "angle-double-left"
  },
  {
    uuid: "97e1bbbc-1312-458d-b14d-773d45a786b1",
    iconSet: "FontAwesome",
    iconName: "angle-double-right"
  },
  {
    uuid: "431be213-dc18-490c-8227-e9302ae0affd",
    iconSet: "FontAwesome",
    iconName: "angle-double-up"
  },
  {
    uuid: "cc15c775-efb3-4e4b-9bd6-32a2b32c56ad",
    iconSet: "FontAwesome",
    iconName: "angle-double-down"
  },
  {
    uuid: "bf137d5f-5ede-4645-9ebd-f8328b84fe4e",
    iconSet: "FontAwesome",
    iconName: "angle-left"
  },
  {
    uuid: "1e0ad923-86fb-40c8-90ab-461a1c03824d",
    iconSet: "FontAwesome",
    iconName: "angle-right"
  },
  {
    uuid: "6efecd3a-a0ae-4838-b25e-bb95a09d2b6b",
    iconSet: "FontAwesome",
    iconName: "angle-up"
  },
  {
    uuid: "e4f906d1-4fdb-4a97-8783-53ab02e2293b",
    iconSet: "FontAwesome",
    iconName: "angle-down"
  },
  {
    uuid: "2526ee90-610d-427f-a8d8-bc989d719ed7",
    iconSet: "FontAwesome",
    iconName: "desktop"
  },
  {
    uuid: "00dfa585-292e-4005-92a8-100396648a54",
    iconSet: "FontAwesome",
    iconName: "laptop"
  },
  {
    uuid: "7b976976-cdda-4c31-bf35-23741d185066",
    iconSet: "FontAwesome",
    iconName: "tablet"
  },
  {
    uuid: "ae94b5f9-6c39-4b0a-aa28-09152f4d8e64",
    iconSet: "FontAwesome",
    iconName: "mobile-phone"
  },
  {
    uuid: "9b898201-ba95-4dee-9a22-fb6073926de9",
    iconSet: "FontAwesome",
    iconName: "mobile"
  },
  {
    uuid: "dcc95efe-29be-424f-80bb-73f29d59884c",
    iconSet: "FontAwesome",
    iconName: "circle-o"
  },
  {
    uuid: "23de8b74-54af-49a2-b491-68b533020aaa",
    iconSet: "FontAwesome",
    iconName: "quote-left"
  },
  {
    uuid: "741e7cde-831c-468e-8335-37ec04e9bcda",
    iconSet: "FontAwesome",
    iconName: "quote-right"
  },
  {
    uuid: "fe070da0-7067-4f0e-a1c0-6ca3f168df8d",
    iconSet: "FontAwesome",
    iconName: "spinner"
  },
  {
    uuid: "f709be17-06bb-4660-a6dc-b6e92e67da3c",
    iconSet: "FontAwesome",
    iconName: "circle"
  },
  {
    uuid: "4fd76519-06a2-4f04-9182-802698cbaac4",
    iconSet: "FontAwesome",
    iconName: "mail-reply"
  },
  {
    uuid: "9ea013b1-f893-463c-92bc-655cd6395604",
    iconSet: "FontAwesome",
    iconName: "reply"
  },
  {
    uuid: "20d68ebf-0466-4031-a4ab-457fcd9103e6",
    iconSet: "FontAwesome",
    iconName: "github-alt"
  },
  {
    uuid: "aed0b3c4-4ce0-434b-a4bd-fcc5edcfb6f5",
    iconSet: "FontAwesome",
    iconName: "folder-o"
  },
  {
    uuid: "d437f97f-cea8-43c1-be67-b1576f019498",
    iconSet: "FontAwesome",
    iconName: "folder-open-o"
  },
  {
    uuid: "48f9bf2d-c8c3-4755-acb3-7d82b9dbd81b",
    iconSet: "FontAwesome",
    iconName: "smile-o"
  },
  {
    uuid: "74cca2fe-9dab-47de-a14a-de2772df6f09",
    iconSet: "FontAwesome",
    iconName: "frown-o"
  },
  {
    uuid: "3d7a9579-f164-404a-8224-c68d35641b06",
    iconSet: "FontAwesome",
    iconName: "meh-o"
  },
  {
    uuid: "9a4009bd-d27e-4720-a890-65defc2b9dd8",
    iconSet: "FontAwesome",
    iconName: "gamepad"
  },
  {
    uuid: "819f4f65-8600-4b73-b201-20f0fee3e0e8",
    iconSet: "FontAwesome",
    iconName: "keyboard-o"
  },
  {
    uuid: "be521b10-f7ce-4a87-bbb7-6514c8e8d831",
    iconSet: "FontAwesome",
    iconName: "flag-o"
  },
  {
    uuid: "a507409a-d706-444e-8bdd-406924040951",
    iconSet: "FontAwesome",
    iconName: "flag-checkered"
  },
  {
    uuid: "2829dc76-91bf-4665-9fd4-e00bbe8786c6",
    iconSet: "FontAwesome",
    iconName: "terminal"
  },
  {
    uuid: "f67e6cae-ea53-4e61-a8df-511669755956",
    iconSet: "FontAwesome",
    iconName: "code"
  },
  {
    uuid: "93d7bd5f-8024-4462-bcf5-b41cdfa67d7e",
    iconSet: "FontAwesome",
    iconName: "mail-reply-all"
  },
  {
    uuid: "96ae7f41-29fd-48e8-9353-e6241f6c284e",
    iconSet: "FontAwesome",
    iconName: "reply-all"
  },
  {
    uuid: "c64942d1-2cec-45eb-bef5-b8bc40a8c118",
    iconSet: "FontAwesome",
    iconName: "star-half-empty"
  },
  {
    uuid: "64accf9b-6e0a-4bdc-9b76-00ccb12f94c9",
    iconSet: "FontAwesome",
    iconName: "star-half-full"
  },
  {
    uuid: "220b624f-bc85-4c8d-8b87-9c538652eab7",
    iconSet: "FontAwesome",
    iconName: "star-half-o"
  },
  {
    uuid: "d2bd3e51-48d9-491a-848c-c687e30b0712",
    iconSet: "FontAwesome",
    iconName: "location-arrow"
  },
  {
    uuid: "de807677-7ebc-47cc-8980-dbe63bf58cc6",
    iconSet: "FontAwesome",
    iconName: "crop"
  },
  {
    uuid: "5f9d5ab8-6bbe-472f-ad8b-4677d6a37959",
    iconSet: "FontAwesome",
    iconName: "code-fork"
  },
  {
    uuid: "8f256f7d-8063-4ec9-ba9e-fc23b39c91ec",
    iconSet: "FontAwesome",
    iconName: "unlink"
  },
  {
    uuid: "6f7f2658-1284-4506-8f9e-369f8376595a",
    iconSet: "FontAwesome",
    iconName: "chain-broken"
  },
  {
    uuid: "eee18a6a-114b-44a3-b6d6-62277c2eb091",
    iconSet: "FontAwesome",
    iconName: "question"
  },
  {
    uuid: "6245db22-57d0-495e-9d29-125156bab7e1",
    iconSet: "FontAwesome",
    iconName: "info"
  },
  {
    uuid: "f5bfc327-9242-4d9a-8695-ca5fd49b8e5e",
    iconSet: "FontAwesome",
    iconName: "exclamation"
  },
  {
    uuid: "6974b656-8785-459b-ae61-ee4f3beeb4a0",
    iconSet: "FontAwesome",
    iconName: "superscript"
  },
  {
    uuid: "267e29c7-4e50-4eae-9075-4e14ecfa930e",
    iconSet: "FontAwesome",
    iconName: "subscript"
  },
  {
    uuid: "21121114-c343-4f04-beb1-3637cbac4c51",
    iconSet: "FontAwesome",
    iconName: "eraser"
  },
  {
    uuid: "0114d5b3-a730-46a7-986b-66e8f3976cba",
    iconSet: "FontAwesome",
    iconName: "puzzle-piece"
  },
  {
    uuid: "25204c94-cbe5-489f-96cf-10ebdfb6de28",
    iconSet: "FontAwesome",
    iconName: "microphone"
  },
  {
    uuid: "fb3ecb09-7608-4206-b450-4e67f6589a66",
    iconSet: "FontAwesome",
    iconName: "microphone-slash"
  },
  {
    uuid: "1a876464-e23e-4c5b-9c4c-a737e3b89802",
    iconSet: "FontAwesome",
    iconName: "shield"
  },
  {
    uuid: "ffbb6fb6-82a6-49a5-97b1-7907613437b1",
    iconSet: "FontAwesome",
    iconName: "calendar-o"
  },
  {
    uuid: "fdfb7932-ae9c-48a9-8276-017c6236ffe7",
    iconSet: "FontAwesome",
    iconName: "fire-extinguisher"
  },
  {
    uuid: "f079e549-d319-44eb-835f-6a2efb1e49f4",
    iconSet: "FontAwesome",
    iconName: "rocket"
  },
  {
    uuid: "a66a7d72-ae83-4344-a5f5-8a62ba284ac6",
    iconSet: "FontAwesome",
    iconName: "maxcdn"
  },
  {
    uuid: "c1d080eb-d1f7-46b9-b957-3aff64643710",
    iconSet: "FontAwesome",
    iconName: "chevron-circle-left"
  },
  {
    uuid: "2136e485-2337-424b-8e0f-62e89b3cb755",
    iconSet: "FontAwesome",
    iconName: "chevron-circle-right"
  },
  {
    uuid: "d971c149-f038-47dd-b71f-3e032c049273",
    iconSet: "FontAwesome",
    iconName: "chevron-circle-up"
  },
  {
    uuid: "a447c05e-1cbb-491b-81fa-e3c6e171fd2e",
    iconSet: "FontAwesome",
    iconName: "chevron-circle-down"
  },
  {
    uuid: "b947410c-144b-4d17-98ec-823eddfbbdbf",
    iconSet: "FontAwesome",
    iconName: "html5"
  },
  {
    uuid: "8569ac82-1593-468d-a853-cabb8afcff19",
    iconSet: "FontAwesome",
    iconName: "css3"
  },
  {
    uuid: "3b58fd51-e874-4352-b4db-fd88efc959a5",
    iconSet: "FontAwesome",
    iconName: "anchor"
  },
  {
    uuid: "b58bfde4-d087-47ab-94d6-369bd42e624e",
    iconSet: "FontAwesome",
    iconName: "unlock-alt"
  },
  {
    uuid: "5c672e1e-060a-44fd-a5e8-6c0777f30e7b",
    iconSet: "FontAwesome",
    iconName: "bullseye"
  },
  {
    uuid: "bb537dfa-85d5-459c-a93d-5419dd9e18ef",
    iconSet: "FontAwesome",
    iconName: "ellipsis-h"
  },
  {
    uuid: "09ef9584-5317-40dc-9b62-58ff30b44fc3",
    iconSet: "FontAwesome",
    iconName: "ellipsis-v"
  },
  {
    uuid: "89b0702b-0ae8-4cf6-9128-c439a5d4066e",
    iconSet: "FontAwesome",
    iconName: "rss-square"
  },
  {
    uuid: "528d285c-03f8-4d17-8353-bf46afdaa5f3",
    iconSet: "FontAwesome",
    iconName: "play-circle"
  },
  {
    uuid: "b91b2994-ebfd-4d72-a3d5-6c9022ff785d",
    iconSet: "FontAwesome",
    iconName: "ticket"
  },
  {
    uuid: "31cfa99e-311f-40ce-8c51-49fa9685c854",
    iconSet: "FontAwesome",
    iconName: "minus-square"
  },
  {
    uuid: "e20d472b-c6eb-4254-93f2-aac23f76353b",
    iconSet: "FontAwesome",
    iconName: "minus-square-o"
  },
  {
    uuid: "b3e95f41-fa5e-4832-8de0-9f97cc459e0c",
    iconSet: "FontAwesome",
    iconName: "level-up"
  },
  {
    uuid: "2417c23c-f758-4116-893b-d9e5d35c37e8",
    iconSet: "FontAwesome",
    iconName: "level-down"
  },
  {
    uuid: "5950b9ba-2bd0-4648-a269-3b9959bffdfd",
    iconSet: "FontAwesome",
    iconName: "check-square"
  },
  {
    uuid: "18101884-76fd-4c51-95f3-f4631a3ceb25",
    iconSet: "FontAwesome",
    iconName: "pencil-square"
  },
  {
    uuid: "e2db9c26-e1a0-4280-a1f5-49c2de1a5394",
    iconSet: "FontAwesome",
    iconName: "external-link-square"
  },
  {
    uuid: "89d9c09d-41a3-4a6e-99ba-d54fed56b11e",
    iconSet: "FontAwesome",
    iconName: "share-square"
  },
  {
    uuid: "be7f7af8-5571-4b05-8c74-04b2ca1db5bb",
    iconSet: "FontAwesome",
    iconName: "compass"
  },
  {
    uuid: "73e5b37e-6d18-4f5e-8d77-19f2616e627b",
    iconSet: "FontAwesome",
    iconName: "toggle-down"
  },
  {
    uuid: "e091e4df-d92a-431d-9b11-a4d8c4db202f",
    iconSet: "FontAwesome",
    iconName: "caret-square-o-down"
  },
  {
    uuid: "d7a405a0-4406-4a39-9ab6-7fd3991b991e",
    iconSet: "FontAwesome",
    iconName: "toggle-up"
  },
  {
    uuid: "bff4c606-0660-4a06-ace1-f603f48f5110",
    iconSet: "FontAwesome",
    iconName: "caret-square-o-up"
  },
  {
    uuid: "5cfa9d8a-5f5e-4e02-a281-e2573d3b22ff",
    iconSet: "FontAwesome",
    iconName: "toggle-right"
  },
  {
    uuid: "49a38616-2ca5-4caf-9fbc-4a764eccc49a",
    iconSet: "FontAwesome",
    iconName: "caret-square-o-right"
  },
  {
    uuid: "902f5e42-a3a3-48ce-94f4-e9ff4e245fb7",
    iconSet: "FontAwesome",
    iconName: "euro"
  },
  {
    uuid: "091f06e1-25ab-40e6-becf-2c4ddb068782",
    iconSet: "FontAwesome",
    iconName: "eur"
  },
  {
    uuid: "e4307b45-01bb-4a86-bd2d-5d3c24d2f011",
    iconSet: "FontAwesome",
    iconName: "gbp"
  },
  {
    uuid: "6d3dc8bc-8044-4ae1-967d-91d7210e7222",
    iconSet: "FontAwesome",
    iconName: "dollar"
  },
  {
    uuid: "c17d27c1-0e9f-48ad-ae5b-e45261a1f8ee",
    iconSet: "FontAwesome",
    iconName: "usd"
  },
  {
    uuid: "8b55aa46-3312-49bd-a407-2be830ea793f",
    iconSet: "FontAwesome",
    iconName: "rupee"
  },
  {
    uuid: "a484b7e0-2451-4571-b9cf-6a5f157a1d2b",
    iconSet: "FontAwesome",
    iconName: "inr"
  },
  {
    uuid: "9acbe74f-8eea-4318-8ee5-2be0a8ebdb2a",
    iconSet: "FontAwesome",
    iconName: "cny"
  },
  {
    uuid: "e17eeb65-dcdf-449d-9693-c7c0bfbe8f58",
    iconSet: "FontAwesome",
    iconName: "rmb"
  },
  {
    uuid: "bf9c9d88-bf67-4edd-90dc-b074dc2a05a4",
    iconSet: "FontAwesome",
    iconName: "yen"
  },
  {
    uuid: "3d4d4405-4870-426a-8adb-6c7afaddeff1",
    iconSet: "FontAwesome",
    iconName: "jpy"
  },
  {
    uuid: "08af5624-5d1f-48f1-83c0-375c1ef85002",
    iconSet: "FontAwesome",
    iconName: "ruble"
  },
  {
    uuid: "2d95b730-3940-4842-a574-d0b1d438e70a",
    iconSet: "FontAwesome",
    iconName: "rouble"
  },
  {
    uuid: "37ba9194-f655-41bc-9c05-78d3e3b216d2",
    iconSet: "FontAwesome",
    iconName: "rub"
  },
  {
    uuid: "d530671a-e7c5-436c-a818-94f1140d52ac",
    iconSet: "FontAwesome",
    iconName: "won"
  },
  {
    uuid: "16cc0b63-7834-41e8-9f71-2aeda4e10ba7",
    iconSet: "FontAwesome",
    iconName: "krw"
  },
  {
    uuid: "bb2dfeca-4954-46ae-8093-28f7453c74f6",
    iconSet: "FontAwesome",
    iconName: "bitcoin"
  },
  {
    uuid: "fa674e11-9b4a-4b5d-8b7c-c605f8aef253",
    iconSet: "FontAwesome",
    iconName: "btc"
  },
  {
    uuid: "63d680c2-d2c9-4ed3-b1ca-886eacebcf56",
    iconSet: "FontAwesome",
    iconName: "file"
  },
  {
    uuid: "eb0be32a-a2fb-4170-bc2d-db8852861c4e",
    iconSet: "FontAwesome",
    iconName: "file-text"
  },
  {
    uuid: "ab1a1b71-f661-48ed-9692-334d814e3314",
    iconSet: "FontAwesome",
    iconName: "sort-alpha-asc"
  },
  {
    uuid: "0eb0d1e2-1665-4745-adb1-26b4df1bd21a",
    iconSet: "FontAwesome",
    iconName: "sort-alpha-desc"
  },
  {
    uuid: "0031a07b-73e3-4bf3-bade-b559e4efbf6e",
    iconSet: "FontAwesome",
    iconName: "sort-amount-asc"
  },
  {
    uuid: "07d8a851-53fe-4836-8e19-adac0864d3fc",
    iconSet: "FontAwesome",
    iconName: "sort-amount-desc"
  },
  {
    uuid: "2b3f107f-721e-42d7-9abe-8898f8f97a70",
    iconSet: "FontAwesome",
    iconName: "sort-numeric-asc"
  },
  {
    uuid: "4cbcc468-398c-49fe-9147-45f09c4470f7",
    iconSet: "FontAwesome",
    iconName: "sort-numeric-desc"
  },
  {
    uuid: "bdb43af4-d208-4c9d-a9bc-62d3f7d7cc65",
    iconSet: "FontAwesome",
    iconName: "thumbs-up"
  },
  {
    uuid: "22adbd2b-c942-4012-bd31-1d4f6f88ef46",
    iconSet: "FontAwesome",
    iconName: "thumbs-down"
  },
  {
    uuid: "a7a97244-2751-44b7-a994-28df9a5dc780",
    iconSet: "FontAwesome",
    iconName: "youtube-square"
  },
  {
    uuid: "37a4720e-ef9c-4b5e-a5dd-ad2d6cf8e841",
    iconSet: "FontAwesome",
    iconName: "youtube"
  },
  {
    uuid: "d5744b01-50f5-4ffb-9c28-e122b3c25691",
    iconSet: "FontAwesome",
    iconName: "xing"
  },
  {
    uuid: "f99e7e36-09ec-45ee-8edd-af33d167d4e5",
    iconSet: "FontAwesome",
    iconName: "xing-square"
  },
  {
    uuid: "278a2452-af2e-4ce2-8f05-677c91b8e7d9",
    iconSet: "FontAwesome",
    iconName: "youtube-play"
  },
  {
    uuid: "5cd70403-a13a-4597-bd78-4d7e7b0405ce",
    iconSet: "FontAwesome",
    iconName: "dropbox"
  },
  {
    uuid: "cf358502-29fd-43c6-9bee-8093a02838ed",
    iconSet: "FontAwesome",
    iconName: "stack-overflow"
  },
  {
    uuid: "9973c0ba-8706-4397-abe2-71969e07a39d",
    iconSet: "FontAwesome",
    iconName: "instagram"
  },
  {
    uuid: "75444a17-0ead-418b-87eb-1b72f5240e20",
    iconSet: "FontAwesome",
    iconName: "flickr"
  },
  {
    uuid: "45c15391-5d49-4e49-8587-da1392f376fa",
    iconSet: "FontAwesome",
    iconName: "adn"
  },
  {
    uuid: "ad6a9b80-73c6-4a08-a015-822da016d710",
    iconSet: "FontAwesome",
    iconName: "bitbucket"
  },
  {
    uuid: "21e15774-fa0b-4786-901a-4ff7ffa3302e",
    iconSet: "FontAwesome",
    iconName: "bitbucket-square"
  },
  {
    uuid: "9f4c24a0-9320-49ce-9c60-83f0bd42c365",
    iconSet: "FontAwesome",
    iconName: "tumblr"
  },
  {
    uuid: "5785a0f5-66ac-4ac1-9ad5-d988489e23d0",
    iconSet: "FontAwesome",
    iconName: "tumblr-square"
  },
  {
    uuid: "b3f416c1-247e-4f29-b6a6-990ac3a5a79a",
    iconSet: "FontAwesome",
    iconName: "long-arrow-down"
  },
  {
    uuid: "c0da932a-aadc-4615-b32e-4b4d3dc02b3d",
    iconSet: "FontAwesome",
    iconName: "long-arrow-up"
  },
  {
    uuid: "a1a4ed6d-f42b-436f-988e-513f088163ad",
    iconSet: "FontAwesome",
    iconName: "long-arrow-left"
  },
  {
    uuid: "e70aeb4f-788c-477a-a443-b9fcf3a78f50",
    iconSet: "FontAwesome",
    iconName: "long-arrow-right"
  },
  {
    uuid: "70f7511a-d4f1-4756-99ba-402c8d4a7ffb",
    iconSet: "FontAwesome",
    iconName: "apple"
  },
  {
    uuid: "ea394958-3ebf-4c77-8ff3-398d1920fc5f",
    iconSet: "FontAwesome",
    iconName: "windows"
  },
  {
    uuid: "e416c72e-fb5d-44ac-becc-9b5756686e1d",
    iconSet: "FontAwesome",
    iconName: "android"
  },
  {
    uuid: "ca5d129f-70ee-4519-9402-44a8a0596dbd",
    iconSet: "FontAwesome",
    iconName: "linux"
  },
  {
    uuid: "52d70ac8-ee6e-4375-b184-58947f0133c8",
    iconSet: "FontAwesome",
    iconName: "dribbble"
  },
  {
    uuid: "3db890a6-0e9a-4f55-a38a-aed0e5a95d7c",
    iconSet: "FontAwesome",
    iconName: "skype"
  },
  {
    uuid: "139e5348-683a-4045-9b0a-a598c8f4e186",
    iconSet: "FontAwesome",
    iconName: "foursquare"
  },
  {
    uuid: "2956dde5-e473-4bc6-9ab7-21a0e4217063",
    iconSet: "FontAwesome",
    iconName: "trello"
  },
  {
    uuid: "78566096-584b-4d59-968f-4cf5d11304d0",
    iconSet: "FontAwesome",
    iconName: "female"
  },
  {
    uuid: "cac7a9df-1a2f-4847-91b2-6e08d2121757",
    iconSet: "FontAwesome",
    iconName: "male"
  },
  {
    uuid: "43b2ee42-507b-42ea-8897-d7c0b3fb8e48",
    iconSet: "FontAwesome",
    iconName: "gittip"
  },
  {
    uuid: "04c7eab9-47b7-4cf9-ba87-e6c04486320f",
    iconSet: "FontAwesome",
    iconName: "gratipay"
  },
  {
    uuid: "213f5973-8f9a-420a-8679-9189afe00d31",
    iconSet: "FontAwesome",
    iconName: "sun-o"
  },
  {
    uuid: "9076cc10-42a9-4aff-b4a3-b31e0ea6aff9",
    iconSet: "FontAwesome",
    iconName: "moon-o"
  },
  {
    uuid: "1483e894-11d7-4a6a-80fb-a854a21748d7",
    iconSet: "FontAwesome",
    iconName: "archive"
  },
  {
    uuid: "f4344631-1f95-41b3-aff5-ae9f305e2d13",
    iconSet: "FontAwesome",
    iconName: "bug"
  },
  {
    uuid: "127285cd-7ae8-4712-960a-dc1fb7266141",
    iconSet: "FontAwesome",
    iconName: "vk"
  },
  {
    uuid: "094516cf-ac57-428d-ae7e-fff4e74d5041",
    iconSet: "FontAwesome",
    iconName: "weibo"
  },
  {
    uuid: "3996b88c-a107-47cb-9f98-988661737189",
    iconSet: "FontAwesome",
    iconName: "renren"
  },
  {
    uuid: "b9c7058d-b242-41ad-b012-0e68cfd640b2",
    iconSet: "FontAwesome",
    iconName: "pagelines"
  },
  {
    uuid: "6b5cbf1f-1e7f-4c22-b298-263200fda495",
    iconSet: "FontAwesome",
    iconName: "stack-exchange"
  },
  {
    uuid: "0f5100b5-6efc-4c5e-b9bb-99b117b57c32",
    iconSet: "FontAwesome",
    iconName: "arrow-circle-o-right"
  },
  {
    uuid: "b7c91095-a7e7-4a9a-82db-e21737c96717",
    iconSet: "FontAwesome",
    iconName: "arrow-circle-o-left"
  },
  {
    uuid: "4a20a8d0-4347-4eac-ab38-0db39d351120",
    iconSet: "FontAwesome",
    iconName: "toggle-left"
  },
  {
    uuid: "23b7287c-46c8-44ad-99e0-30af9b06c3a9",
    iconSet: "FontAwesome",
    iconName: "caret-square-o-left"
  },
  {
    uuid: "220cfa09-5ee2-49e5-a688-2f4844280b86",
    iconSet: "FontAwesome",
    iconName: "dot-circle-o"
  },
  {
    uuid: "e27062e2-e37d-4971-a9bd-e414619376a3",
    iconSet: "FontAwesome",
    iconName: "wheelchair"
  },
  {
    uuid: "5c9dbc83-9a4b-419b-bc41-19dc87dc6779",
    iconSet: "FontAwesome",
    iconName: "vimeo-square"
  },
  {
    uuid: "21c20c14-e3a8-4ba7-bc41-afd96cdc735f",
    iconSet: "FontAwesome",
    iconName: "turkish-lira"
  },
  {
    uuid: "d9b8e5eb-55a7-4c41-857c-188a3ec7b6d3",
    iconSet: "FontAwesome",
    iconName: "try"
  },
  {
    uuid: "e2d14c57-4ede-4207-bc41-988e908cdbc1",
    iconSet: "FontAwesome",
    iconName: "plus-square-o"
  },
  {
    uuid: "0f7aab88-6e44-48f5-8d47-d4909d42c970",
    iconSet: "FontAwesome",
    iconName: "space-shuttle"
  },
  {
    uuid: "e855a844-d4e7-4c11-b06a-d506bd893cf7",
    iconSet: "FontAwesome",
    iconName: "slack"
  },
  {
    uuid: "55255a37-9f25-4a24-a3c1-433946c6bea3",
    iconSet: "FontAwesome",
    iconName: "envelope-square"
  },
  {
    uuid: "806cb1f7-e16f-4dd6-9785-31df76e550a3",
    iconSet: "FontAwesome",
    iconName: "wordpress"
  },
  {
    uuid: "8c6e5484-2c38-4837-9cbc-3eb72a328e39",
    iconSet: "FontAwesome",
    iconName: "openid"
  },
  {
    uuid: "e36b6bdd-c3eb-4bc9-8e04-37b476e8c0dc",
    iconSet: "FontAwesome",
    iconName: "institution"
  },
  {
    uuid: "2130e0fe-c1c2-409a-af17-b3d4aaa66f1e",
    iconSet: "FontAwesome",
    iconName: "bank"
  },
  {
    uuid: "6c6906db-5de3-432f-8d24-fd1460c7c786",
    iconSet: "FontAwesome",
    iconName: "university"
  },
  {
    uuid: "2572bd13-9e15-480f-abc5-bf342f237392",
    iconSet: "FontAwesome",
    iconName: "mortar-board"
  },
  {
    uuid: "3f43fc7c-e929-4ead-bfdc-1c300db45bc0",
    iconSet: "FontAwesome",
    iconName: "graduation-cap"
  },
  {
    uuid: "54d72ecf-9b26-4058-84ab-cbb14b0cb8a5",
    iconSet: "FontAwesome",
    iconName: "yahoo"
  },
  {
    uuid: "a2f023cf-cea8-4fee-9321-8de7257e840e",
    iconSet: "FontAwesome",
    iconName: "google"
  },
  {
    uuid: "824543c2-a153-4621-b71b-566338f67e65",
    iconSet: "FontAwesome",
    iconName: "reddit"
  },
  {
    uuid: "d89437f6-33cf-417d-9c7d-5cdc61e82317",
    iconSet: "FontAwesome",
    iconName: "reddit-square"
  },
  {
    uuid: "c087f58d-f1ea-49e1-a10c-62b985afd25f",
    iconSet: "FontAwesome",
    iconName: "stumbleupon-circle"
  },
  {
    uuid: "5fdcf8ed-70ea-4de8-bf23-00e061631cb6",
    iconSet: "FontAwesome",
    iconName: "stumbleupon"
  },
  {
    uuid: "c2031a24-8781-4543-a6c9-441ea85a6c69",
    iconSet: "FontAwesome",
    iconName: "delicious"
  },
  {
    uuid: "5038df7e-0c88-4fff-8333-dfacf3d14d98",
    iconSet: "FontAwesome",
    iconName: "digg"
  },
  {
    uuid: "11b47e74-4b2c-4a0b-b789-a6ebebd475c9",
    iconSet: "FontAwesome",
    iconName: "pied-piper-pp"
  },
  {
    uuid: "bceaddaf-d9ec-4baa-b3cc-dd32144ee5d7",
    iconSet: "FontAwesome",
    iconName: "pied-piper-alt"
  },
  {
    uuid: "af9532df-9d3b-44bf-aea1-cb3e0d74e319",
    iconSet: "FontAwesome",
    iconName: "drupal"
  },
  {
    uuid: "90ff41d2-f926-4739-bce6-474f2a17c55e",
    iconSet: "FontAwesome",
    iconName: "joomla"
  },
  {
    uuid: "d38066a2-cd52-47d7-84f8-a0a8dbab0486",
    iconSet: "FontAwesome",
    iconName: "language"
  },
  {
    uuid: "707135cd-3cb1-4757-af71-4775a2cc3527",
    iconSet: "FontAwesome",
    iconName: "fax"
  },
  {
    uuid: "1b2a9ba6-dc0f-46ae-ae16-96335ea9cdef",
    iconSet: "FontAwesome",
    iconName: "building"
  },
  {
    uuid: "d8232728-8e8d-4e39-bded-9e28a076e9d2",
    iconSet: "FontAwesome",
    iconName: "child"
  },
  {
    uuid: "e9111c35-a541-4d2a-8db6-864aad431b60",
    iconSet: "FontAwesome",
    iconName: "paw"
  },
  {
    uuid: "ee18656c-1733-4b69-ab52-f3abc367c5d9",
    iconSet: "FontAwesome",
    iconName: "spoon"
  },
  {
    uuid: "e34e4a06-7f11-4d02-ae80-17235cfb929c",
    iconSet: "FontAwesome",
    iconName: "cube"
  },
  {
    uuid: "81e9c35b-e325-4d80-9352-99c9809603ca",
    iconSet: "FontAwesome",
    iconName: "cubes"
  },
  {
    uuid: "98e3567a-42a8-4241-8c57-769e34bd5b4d",
    iconSet: "FontAwesome",
    iconName: "behance"
  },
  {
    uuid: "3b94bc8d-1418-407b-808e-d3312c00ade5",
    iconSet: "FontAwesome",
    iconName: "behance-square"
  },
  {
    uuid: "cd4dbb8e-4674-4632-a4f2-db5af338abc2",
    iconSet: "FontAwesome",
    iconName: "steam"
  },
  {
    uuid: "f0c88ae2-2391-4b3c-8efd-6205312cb8a5",
    iconSet: "FontAwesome",
    iconName: "steam-square"
  },
  {
    uuid: "6fec5acb-4486-4d48-81b6-8609cf3aa7b6",
    iconSet: "FontAwesome",
    iconName: "recycle"
  },
  {
    uuid: "ba7f275c-5658-4849-9e67-f6c98675df43",
    iconSet: "FontAwesome",
    iconName: "automobile"
  },
  {
    uuid: "8588fee8-0148-4f49-8f39-172a2f1333d6",
    iconSet: "FontAwesome",
    iconName: "car"
  },
  {
    uuid: "42aab814-70d4-4711-93ae-894f6451bad1",
    iconSet: "FontAwesome",
    iconName: "cab"
  },
  {
    uuid: "d185c9a1-621c-4c18-8475-d3d7485e46fa",
    iconSet: "FontAwesome",
    iconName: "taxi"
  },
  {
    uuid: "8ac53625-7bf4-477d-8389-3588c23be15d",
    iconSet: "FontAwesome",
    iconName: "tree"
  },
  {
    uuid: "b8d028b1-88b8-4102-94d2-595ed4d6620d",
    iconSet: "FontAwesome",
    iconName: "spotify"
  },
  {
    uuid: "16e0072b-9fb0-437b-936e-0656e3082f00",
    iconSet: "FontAwesome",
    iconName: "deviantart"
  },
  {
    uuid: "38b53cc0-86c8-469a-98ed-ea92dc07f820",
    iconSet: "FontAwesome",
    iconName: "soundcloud"
  },
  {
    uuid: "0be782dc-5041-4551-b17c-90bb133e851c",
    iconSet: "FontAwesome",
    iconName: "database"
  },
  {
    uuid: "5d6e37ca-0c15-4191-972c-ba01dd33ae96",
    iconSet: "FontAwesome",
    iconName: "file-pdf-o"
  },
  {
    uuid: "4b743779-3288-4c4d-99fb-6a312ba6ba77",
    iconSet: "FontAwesome",
    iconName: "file-word-o"
  },
  {
    uuid: "afdea598-f093-473f-a8fd-214394ca1b6c",
    iconSet: "FontAwesome",
    iconName: "file-excel-o"
  },
  {
    uuid: "2ee51bdf-5dbd-4c38-b98e-8aa61d3f8692",
    iconSet: "FontAwesome",
    iconName: "file-powerpoint-o"
  },
  {
    uuid: "8e4316d4-df6e-4d2c-a5ee-630031df2b2a",
    iconSet: "FontAwesome",
    iconName: "file-photo-o"
  },
  {
    uuid: "cdcb5955-8bfb-444b-84e6-c9e8a0d2444b",
    iconSet: "FontAwesome",
    iconName: "file-picture-o"
  },
  {
    uuid: "47d43371-7f32-4237-92e4-4418ebb24f70",
    iconSet: "FontAwesome",
    iconName: "file-image-o"
  },
  {
    uuid: "84b60170-ee64-43c8-84fe-80d6b882f895",
    iconSet: "FontAwesome",
    iconName: "file-zip-o"
  },
  {
    uuid: "d22646a2-6c52-4160-beaa-b2d371e2a5b3",
    iconSet: "FontAwesome",
    iconName: "file-archive-o"
  },
  {
    uuid: "e28e89f4-9822-45e3-8eb2-67ba4a864b64",
    iconSet: "FontAwesome",
    iconName: "file-sound-o"
  },
  {
    uuid: "b73796cf-b4a2-4cfe-a654-4fe9ffac149b",
    iconSet: "FontAwesome",
    iconName: "file-audio-o"
  },
  {
    uuid: "d79e190e-ed58-41b4-b751-78dc009a3cd3",
    iconSet: "FontAwesome",
    iconName: "file-movie-o"
  },
  {
    uuid: "447f053c-481b-493b-85b1-a19c106dcfdc",
    iconSet: "FontAwesome",
    iconName: "file-video-o"
  },
  {
    uuid: "39dc2804-37f5-48cd-8662-97c22bcb21c6",
    iconSet: "FontAwesome",
    iconName: "file-code-o"
  },
  {
    uuid: "d9900d4c-e891-4532-a677-8043bba8040e",
    iconSet: "FontAwesome",
    iconName: "vine"
  },
  {
    uuid: "acb6d3bb-7c2c-452f-ad27-283463bfbf8f",
    iconSet: "FontAwesome",
    iconName: "codepen"
  },
  {
    uuid: "b343414b-1221-4e4a-8cf8-c63c8a70d7c5",
    iconSet: "FontAwesome",
    iconName: "jsfiddle"
  },
  {
    uuid: "8f5f2612-f005-4459-87d4-813941a6fa00",
    iconSet: "FontAwesome",
    iconName: "life-bouy"
  },
  {
    uuid: "4ddf72c7-8f09-4272-ac57-288b1fcbdc6a",
    iconSet: "FontAwesome",
    iconName: "life-buoy"
  },
  {
    uuid: "51b88647-f2c0-48fa-b1f6-0e7c15250f7d",
    iconSet: "FontAwesome",
    iconName: "life-saver"
  },
  {
    uuid: "3a8d2e8a-0f18-4bca-9506-fa8e533e9cd9",
    iconSet: "FontAwesome",
    iconName: "support"
  },
  {
    uuid: "e8a3d258-8e5b-4ddf-8bad-64cc525411bd",
    iconSet: "FontAwesome",
    iconName: "life-ring"
  },
  {
    uuid: "a9402643-a905-45b5-bad4-b23bb740efce",
    iconSet: "FontAwesome",
    iconName: "circle-o-notch"
  },
  {
    uuid: "ddd5054a-6c11-4690-acf9-b5658c325860",
    iconSet: "FontAwesome",
    iconName: "ra"
  },
  {
    uuid: "897ce2a9-fc85-43ba-b3f5-715fabacb0b8",
    iconSet: "FontAwesome",
    iconName: "resistance"
  },
  {
    uuid: "e366b890-87b5-4bdc-9b67-4a6a8a0cb4e9",
    iconSet: "FontAwesome",
    iconName: "rebel"
  },
  {
    uuid: "0d96fe28-f2a8-43c1-9f45-12779d40008b",
    iconSet: "FontAwesome",
    iconName: "ge"
  },
  {
    uuid: "102605ef-0ddd-4b5c-933b-4fb8c12b3430",
    iconSet: "FontAwesome",
    iconName: "empire"
  },
  {
    uuid: "f30accc3-3f25-42d0-be54-333ac3174d74",
    iconSet: "FontAwesome",
    iconName: "git-square"
  },
  {
    uuid: "8e18576e-0bac-4427-8966-e6f6d6f87441",
    iconSet: "FontAwesome",
    iconName: "git"
  },
  {
    uuid: "342478eb-db53-4e7b-ae04-0e7f47f17de6",
    iconSet: "FontAwesome",
    iconName: "y-combinator-square"
  },
  {
    uuid: "39fc1f43-09c3-47ee-a692-a1af6e844cea",
    iconSet: "FontAwesome",
    iconName: "yc-square"
  },
  {
    uuid: "e42a04c9-551e-4a26-8777-5db64fec5cef",
    iconSet: "FontAwesome",
    iconName: "hacker-news"
  },
  {
    uuid: "670f38b0-6bb9-4c7c-8d09-1c231a4cf2a5",
    iconSet: "FontAwesome",
    iconName: "tencent-weibo"
  },
  {
    uuid: "a34c60e4-1617-4b27-9711-31e06d1351c0",
    iconSet: "FontAwesome",
    iconName: "qq"
  },
  {
    uuid: "565366c1-dc3f-475a-a5d5-15d1039d3840",
    iconSet: "FontAwesome",
    iconName: "wechat"
  },
  {
    uuid: "75c18529-6383-4d83-9d1a-343cbfb39e6e",
    iconSet: "FontAwesome",
    iconName: "weixin"
  },
  {
    uuid: "8a0516bf-bcae-4402-9694-7b89b3dcee12",
    iconSet: "FontAwesome",
    iconName: "send"
  },
  {
    uuid: "93dd39c7-6e0f-4d26-b233-4d29a0744428",
    iconSet: "FontAwesome",
    iconName: "paper-plane"
  },
  {
    uuid: "5cb7e4ba-74fc-48df-a24c-0424386a9ced",
    iconSet: "FontAwesome",
    iconName: "send-o"
  },
  {
    uuid: "c9d2bb4c-603a-47ad-a248-b16f69a93f3c",
    iconSet: "FontAwesome",
    iconName: "paper-plane-o"
  },
  {
    uuid: "8e3c3619-1d44-476a-9e37-f912f1754cfe",
    iconSet: "FontAwesome",
    iconName: "history"
  },
  {
    uuid: "d0c25bf4-5ba2-4757-abb0-f6774ad1d13b",
    iconSet: "FontAwesome",
    iconName: "circle-thin"
  },
  {
    uuid: "6638edae-8443-4d21-a980-e7a2381ddc56",
    iconSet: "FontAwesome",
    iconName: "header"
  },
  {
    uuid: "ad7ccf86-c61f-4179-b760-f4161d2f6c44",
    iconSet: "FontAwesome",
    iconName: "paragraph"
  },
  {
    uuid: "1b52f801-3181-4241-81e1-ec177d96c782",
    iconSet: "FontAwesome",
    iconName: "sliders"
  },
  {
    uuid: "04e8c76d-ae82-4ff8-84dd-97057b8bc033",
    iconSet: "FontAwesome",
    iconName: "share-alt"
  },
  {
    uuid: "4165176d-ec5f-4365-bfc1-4eb0b5fb3a2b",
    iconSet: "FontAwesome",
    iconName: "share-alt-square"
  },
  {
    uuid: "037f441a-9c34-44c1-b985-c64123aaeab4",
    iconSet: "FontAwesome",
    iconName: "bomb"
  },
  {
    uuid: "f7d3b620-95ad-4dfb-82ec-b6c30d7354bb",
    iconSet: "FontAwesome",
    iconName: "soccer-ball-o"
  },
  {
    uuid: "900df4c4-ab48-443b-b62b-3148edfffa5e",
    iconSet: "FontAwesome",
    iconName: "futbol-o"
  },
  {
    uuid: "73cef4f1-78ac-4354-b479-d824c732475c",
    iconSet: "FontAwesome",
    iconName: "tty"
  },
  {
    uuid: "ded8f82a-143e-49d4-bcbb-6fdb9eaf37c7",
    iconSet: "FontAwesome",
    iconName: "binoculars"
  },
  {
    uuid: "1ffe6412-7ecc-48ea-a8a7-d62c05536ce7",
    iconSet: "FontAwesome",
    iconName: "plug"
  },
  {
    uuid: "f5d85772-96bf-4bbd-9d29-cebb5605d1b9",
    iconSet: "FontAwesome",
    iconName: "slideshare"
  },
  {
    uuid: "20559573-4e1f-4046-92b5-b2373c96c712",
    iconSet: "FontAwesome",
    iconName: "twitch"
  },
  {
    uuid: "73bbe710-a8bb-4ac3-ad7b-10658544b393",
    iconSet: "FontAwesome",
    iconName: "yelp"
  },
  {
    uuid: "227bc335-b517-41f3-900b-4ef77b3717ac",
    iconSet: "FontAwesome",
    iconName: "newspaper-o"
  },
  {
    uuid: "69cad5b7-adeb-4349-84b8-ada00b5401ff",
    iconSet: "FontAwesome",
    iconName: "wifi"
  },
  {
    uuid: "1ce86d68-5692-4dd8-b31e-e7e6a7e9e4dc",
    iconSet: "FontAwesome",
    iconName: "calculator"
  },
  {
    uuid: "f73d8bf1-ba8e-4355-9863-545c1bb3bed3",
    iconSet: "FontAwesome",
    iconName: "paypal"
  },
  {
    uuid: "024c1178-ec86-45a2-81e6-a90815d8c18f",
    iconSet: "FontAwesome",
    iconName: "google-wallet"
  },
  {
    uuid: "8e15c027-4ec8-4c87-b82d-82081f989b39",
    iconSet: "FontAwesome",
    iconName: "cc-visa"
  },
  {
    uuid: "1b2ac297-44b3-42ea-86ad-b6a86ec4aa97",
    iconSet: "FontAwesome",
    iconName: "cc-mastercard"
  },
  {
    uuid: "0c183fb6-3a11-41e2-9ed3-72174e93ef93",
    iconSet: "FontAwesome",
    iconName: "cc-discover"
  },
  {
    uuid: "e39bd571-8880-4019-9ec3-3fc1ce9b41d7",
    iconSet: "FontAwesome",
    iconName: "cc-amex"
  },
  {
    uuid: "9417fdce-c314-405a-a808-b03dee15a4ac",
    iconSet: "FontAwesome",
    iconName: "cc-paypal"
  },
  {
    uuid: "fe58ccd4-c244-43ca-aa04-64adba3cc760",
    iconSet: "FontAwesome",
    iconName: "cc-stripe"
  },
  {
    uuid: "a9cebfdd-d310-4eba-af94-399c49c6a5af",
    iconSet: "FontAwesome",
    iconName: "bell-slash"
  },
  {
    uuid: "da66c34f-0119-4dd0-a945-d255fd9eb04e",
    iconSet: "FontAwesome",
    iconName: "bell-slash-o"
  },
  {
    uuid: "42d7f523-65df-45fb-90ad-a5289eaf7fa4",
    iconSet: "FontAwesome",
    iconName: "trash"
  },
  {
    uuid: "851aa1bb-3756-4b51-8a58-4856986da937",
    iconSet: "FontAwesome",
    iconName: "copyright"
  },
  {
    uuid: "62a3cc1c-e886-406d-a60e-0956b6aa3f36",
    iconSet: "FontAwesome",
    iconName: "at"
  },
  {
    uuid: "f4d2861f-adff-4e8d-bd8d-11a429ad7db7",
    iconSet: "FontAwesome",
    iconName: "eyedropper"
  },
  {
    uuid: "453068a6-6d1d-4b5f-9d70-c7cfab2d1a56",
    iconSet: "FontAwesome",
    iconName: "paint-brush"
  },
  {
    uuid: "221c11c0-426b-4bfd-9b38-5bffab4ff134",
    iconSet: "FontAwesome",
    iconName: "birthday-cake"
  },
  {
    uuid: "0a58aa94-0bd1-4d80-9f07-b47bb89dd0db",
    iconSet: "FontAwesome",
    iconName: "area-chart"
  },
  {
    uuid: "6042786d-cab9-43d2-bbe5-e8d3b73c4484",
    iconSet: "FontAwesome",
    iconName: "pie-chart"
  },
  {
    uuid: "e63d061f-2195-4a47-a81b-8e92cb407339",
    iconSet: "FontAwesome",
    iconName: "line-chart"
  },
  {
    uuid: "a90f46d6-599a-494b-ae4d-c2412456246d",
    iconSet: "FontAwesome",
    iconName: "lastfm"
  },
  {
    uuid: "94eae9e9-256c-4955-8957-3235f87c00d8",
    iconSet: "FontAwesome",
    iconName: "lastfm-square"
  },
  {
    uuid: "2be5ea81-1db6-49f6-876f-54869dc39206",
    iconSet: "FontAwesome",
    iconName: "toggle-off"
  },
  {
    uuid: "bb33e259-6604-4cf7-8d42-cf25d7f1967e",
    iconSet: "FontAwesome",
    iconName: "toggle-on"
  },
  {
    uuid: "46c3ccde-2a15-4cd1-9d32-dc2930a034f4",
    iconSet: "FontAwesome",
    iconName: "bicycle"
  },
  {
    uuid: "71c0cd86-4357-4837-bcd4-829bdf2ebff8",
    iconSet: "FontAwesome",
    iconName: "bus"
  },
  {
    uuid: "d51aec8d-fcc6-4df4-baf7-a3474fece772",
    iconSet: "FontAwesome",
    iconName: "ioxhost"
  },
  {
    uuid: "06ae7e5c-6b9a-44e3-b294-385ecd9d2ee5",
    iconSet: "FontAwesome",
    iconName: "angellist"
  },
  {
    uuid: "6d4bd532-5e9e-4793-8aa8-b304e9b16b76",
    iconSet: "FontAwesome",
    iconName: "cc"
  },
  {
    uuid: "b1004365-69af-4415-beb7-f40f83b4c19d",
    iconSet: "FontAwesome",
    iconName: "shekel"
  },
  {
    uuid: "8adc4464-1e04-4b12-9036-8540058199d3",
    iconSet: "FontAwesome",
    iconName: "sheqel"
  },
  {
    uuid: "92c75e68-702a-4fbe-aa71-7773553d9ad2",
    iconSet: "FontAwesome",
    iconName: "ils"
  },
  {
    uuid: "ed09bc36-237e-43c5-ad63-a98730e07941",
    iconSet: "FontAwesome",
    iconName: "meanpath"
  },
  {
    uuid: "635befda-2b92-498b-8ac9-83151bab3085",
    iconSet: "FontAwesome",
    iconName: "buysellads"
  },
  {
    uuid: "b72afc60-0d0f-4c3e-847a-6839dd8c2efc",
    iconSet: "FontAwesome",
    iconName: "connectdevelop"
  },
  {
    uuid: "a17ede84-7d6c-4f4c-b03c-d58bc148ffb6",
    iconSet: "FontAwesome",
    iconName: "dashcube"
  },
  {
    uuid: "6d3f714c-9b11-4594-9f3b-d9a4f4de6003",
    iconSet: "FontAwesome",
    iconName: "forumbee"
  },
  {
    uuid: "845e79c7-aea4-47e1-868c-893e37744a10",
    iconSet: "FontAwesome",
    iconName: "leanpub"
  },
  {
    uuid: "46babc7d-949c-4e34-87e3-46720b8ac18e",
    iconSet: "FontAwesome",
    iconName: "sellsy"
  },
  {
    uuid: "88571012-4c27-4611-adcc-ecd52348e237",
    iconSet: "FontAwesome",
    iconName: "shirtsinbulk"
  },
  {
    uuid: "8a276ed7-0cce-4c14-9e84-1bf721058508",
    iconSet: "FontAwesome",
    iconName: "simplybuilt"
  },
  {
    uuid: "b633d99d-c1f4-4188-b4e3-ab99f97b6a67",
    iconSet: "FontAwesome",
    iconName: "skyatlas"
  },
  {
    uuid: "53f47fb5-3b06-45a3-8063-5cb14f3da0df",
    iconSet: "FontAwesome",
    iconName: "cart-plus"
  },
  {
    uuid: "596a4fe8-3f2e-404f-95cb-7af9567111c9",
    iconSet: "FontAwesome",
    iconName: "cart-arrow-down"
  },
  {
    uuid: "920e7998-0108-4438-bddd-cf19d9feb312",
    iconSet: "FontAwesome",
    iconName: "diamond"
  },
  {
    uuid: "7278f197-751c-4286-8ba4-4f4677eed7f1",
    iconSet: "FontAwesome",
    iconName: "ship"
  },
  {
    uuid: "c90004e3-c2a9-4f42-87d6-495ea258480f",
    iconSet: "FontAwesome",
    iconName: "user-secret"
  },
  {
    uuid: "06ca0a96-af1c-4cff-bd51-0aacee80ea70",
    iconSet: "FontAwesome",
    iconName: "motorcycle"
  },
  {
    uuid: "78da220c-d3ed-425e-bbc3-6ef8253ee9c7",
    iconSet: "FontAwesome",
    iconName: "street-view"
  },
  {
    uuid: "8ef02090-2979-414d-a716-73c23cfadfd0",
    iconSet: "FontAwesome",
    iconName: "heartbeat"
  },
  {
    uuid: "56d41489-fa02-4f91-a00b-de5754ec3859",
    iconSet: "FontAwesome",
    iconName: "venus"
  },
  {
    uuid: "b083b6cf-3883-464e-8705-ff00d2d5b8e1",
    iconSet: "FontAwesome",
    iconName: "mars"
  },
  {
    uuid: "9cc25e2a-ac53-4ae3-ab6b-29e9e2444efb",
    iconSet: "FontAwesome",
    iconName: "mercury"
  },
  {
    uuid: "01c9a5f4-c6e4-4b65-a46b-a0958201689b",
    iconSet: "FontAwesome",
    iconName: "intersex"
  },
  {
    uuid: "db017df5-6c61-4841-8a7d-5759a7f668e2",
    iconSet: "FontAwesome",
    iconName: "transgender"
  },
  {
    uuid: "96ac8fa6-73e5-4b29-9798-94e5b84da952",
    iconSet: "FontAwesome",
    iconName: "transgender-alt"
  },
  {
    uuid: "48e02800-0780-4f02-9215-42c955cca6e1",
    iconSet: "FontAwesome",
    iconName: "venus-double"
  },
  {
    uuid: "3ce82241-c5f4-4722-aea8-16051a72cb04",
    iconSet: "FontAwesome",
    iconName: "mars-double"
  },
  {
    uuid: "1a497dff-2274-425d-bda5-f7c23602b970",
    iconSet: "FontAwesome",
    iconName: "venus-mars"
  },
  {
    uuid: "edcefba6-7f05-43c5-81a0-2f90904db234",
    iconSet: "FontAwesome",
    iconName: "mars-stroke"
  },
  {
    uuid: "b4341eb0-b205-421c-8110-0ed5a923a763",
    iconSet: "FontAwesome",
    iconName: "mars-stroke-v"
  },
  {
    uuid: "8ebf85d2-842b-4ba3-bc3a-e0b30b27e1d9",
    iconSet: "FontAwesome",
    iconName: "mars-stroke-h"
  },
  {
    uuid: "609c99ee-47d8-4da4-8d57-f521cb2e3b75",
    iconSet: "FontAwesome",
    iconName: "neuter"
  },
  {
    uuid: "0c3e9c95-9597-476d-9c9f-7f0ce4dcac9d",
    iconSet: "FontAwesome",
    iconName: "genderless"
  },
  {
    uuid: "93a0acc1-36dd-4c86-9fb6-027eb855b96e",
    iconSet: "FontAwesome",
    iconName: "facebook-official"
  },
  {
    uuid: "91492cc9-5c25-4627-9041-cc30b8aac1d1",
    iconSet: "FontAwesome",
    iconName: "pinterest-p"
  },
  {
    uuid: "1a20e48a-124c-4526-b90d-f2e7ac977f65",
    iconSet: "FontAwesome",
    iconName: "whatsapp"
  },
  {
    uuid: "b521deb9-e56d-4be3-bcc1-68d45ed5d3e1",
    iconSet: "FontAwesome",
    iconName: "server"
  },
  {
    uuid: "da0d76c9-5aa8-4130-ad98-68c352820c8c",
    iconSet: "FontAwesome",
    iconName: "user-plus"
  },
  {
    uuid: "a076a4df-b3e6-4e36-8403-8ee8ce573354",
    iconSet: "FontAwesome",
    iconName: "user-times"
  },
  {
    uuid: "3d0a1ff4-01a6-4386-bd02-c93824dfaeec",
    iconSet: "FontAwesome",
    iconName: "hotel"
  },
  {
    uuid: "61824b7f-2856-4827-9f99-fa5ec1ad0bc7",
    iconSet: "FontAwesome",
    iconName: "bed"
  },
  {
    uuid: "c7e119b2-ae68-43ed-86c8-af6494041889",
    iconSet: "FontAwesome",
    iconName: "viacoin"
  },
  {
    uuid: "679baef9-cc19-4d25-9efa-d9baa36bb1f4",
    iconSet: "FontAwesome",
    iconName: "train"
  },
  {
    uuid: "e420046d-3505-41c2-b7ba-7dff30c00a8a",
    iconSet: "FontAwesome",
    iconName: "subway"
  },
  {
    uuid: "7fb5b2d3-e176-4182-b483-6d27d8ae0aa4",
    iconSet: "FontAwesome",
    iconName: "medium"
  },
  {
    uuid: "d06899eb-fb3f-40d9-a05b-466995d07f01",
    iconSet: "FontAwesome",
    iconName: "yc"
  },
  {
    uuid: "2199f501-45df-46bc-b55a-fe2abded19f2",
    iconSet: "FontAwesome",
    iconName: "y-combinator"
  },
  {
    uuid: "7c933d6f-be0a-489a-9c59-a6216f4b6665",
    iconSet: "FontAwesome",
    iconName: "optin-monster"
  },
  {
    uuid: "059206f2-a0f5-43c1-b110-7407319062fb",
    iconSet: "FontAwesome",
    iconName: "opencart"
  },
  {
    uuid: "b707c90f-b98a-499e-a597-1f6fe61b7146",
    iconSet: "FontAwesome",
    iconName: "expeditedssl"
  },
  {
    uuid: "23825da4-f4da-4303-af22-789aac8fe6f8",
    iconSet: "FontAwesome",
    iconName: "battery-4"
  },
  {
    uuid: "324ec109-13b2-46f4-9cf5-9c0327bf9760",
    iconSet: "FontAwesome",
    iconName: "battery"
  },
  {
    uuid: "edf9c137-3106-4e16-8ae5-4998bbb52fd9",
    iconSet: "FontAwesome",
    iconName: "battery-full"
  },
  {
    uuid: "7f8ba2bd-aa40-4f95-952b-fad99f7ec227",
    iconSet: "FontAwesome",
    iconName: "battery-3"
  },
  {
    uuid: "284c5898-36bb-4435-aa13-40f475e6986f",
    iconSet: "FontAwesome",
    iconName: "battery-three-quarters"
  },
  {
    uuid: "ca8700a2-1fae-477c-bcea-6c6db4c73549",
    iconSet: "FontAwesome",
    iconName: "battery-2"
  },
  {
    uuid: "a71985ea-e3e5-4da0-add9-acc7b019c052",
    iconSet: "FontAwesome",
    iconName: "battery-half"
  },
  {
    uuid: "3256d3f4-bf3f-457f-b9ff-ff16da6e6143",
    iconSet: "FontAwesome",
    iconName: "battery-1"
  },
  {
    uuid: "21817d51-abad-438d-b5e9-44b832a7d9be",
    iconSet: "FontAwesome",
    iconName: "battery-quarter"
  },
  {
    uuid: "a9c8ec14-de6d-45f6-80a6-e01e5f9a843e",
    iconSet: "FontAwesome",
    iconName: "battery-0"
  },
  {
    uuid: "7120a1a3-ae6c-4ba8-8c34-45d06531b31e",
    iconSet: "FontAwesome",
    iconName: "battery-empty"
  },
  {
    uuid: "85de1b58-77fc-485e-977d-7e52e04ec6d8",
    iconSet: "FontAwesome",
    iconName: "mouse-pointer"
  },
  {
    uuid: "b4421db9-8009-492f-a51c-30c8f607e51a",
    iconSet: "FontAwesome",
    iconName: "i-cursor"
  },
  {
    uuid: "2ab267ea-35b8-48b9-b47d-c6cc4bb9bb06",
    iconSet: "FontAwesome",
    iconName: "object-group"
  },
  {
    uuid: "b3ce71c1-2b7d-421a-946a-d43123e3922d",
    iconSet: "FontAwesome",
    iconName: "object-ungroup"
  },
  {
    uuid: "6fac82f8-42ce-4cd8-9c8f-f5771ba91717",
    iconSet: "FontAwesome",
    iconName: "sticky-note"
  },
  {
    uuid: "561c56c2-412c-40b5-81b8-a8f3278fd5f7",
    iconSet: "FontAwesome",
    iconName: "sticky-note-o"
  },
  {
    uuid: "1c2af7e9-a591-4aec-b7e7-3ad5ee28de68",
    iconSet: "FontAwesome",
    iconName: "cc-jcb"
  },
  {
    uuid: "cc81e7ce-5e53-459d-b9e3-2c2009acb903",
    iconSet: "FontAwesome",
    iconName: "cc-diners-club"
  },
  {
    uuid: "b150b7b2-a879-4dc3-a51f-6221b73bfe6c",
    iconSet: "FontAwesome",
    iconName: "clone"
  },
  {
    uuid: "099076c1-9d68-4911-be87-f00a6325c533",
    iconSet: "FontAwesome",
    iconName: "balance-scale"
  },
  {
    uuid: "a5fa1d0b-b12c-43ec-b9f7-c5363718ba7c",
    iconSet: "FontAwesome",
    iconName: "hourglass-o"
  },
  {
    uuid: "c369b005-4c07-4270-af03-e63e5e649cc8",
    iconSet: "FontAwesome",
    iconName: "hourglass-1"
  },
  {
    uuid: "2e8484cd-67c3-40d4-828e-3815c47ffb96",
    iconSet: "FontAwesome",
    iconName: "hourglass-start"
  },
  {
    uuid: "c5e5ad3a-d1f5-4bb0-a7e6-d16cf260bd59",
    iconSet: "FontAwesome",
    iconName: "hourglass-2"
  },
  {
    uuid: "d1af1294-d9b0-457f-925b-6d99fcf27f8e",
    iconSet: "FontAwesome",
    iconName: "hourglass-half"
  },
  {
    uuid: "54ce359b-8594-46d4-8d14-fe45c25cff52",
    iconSet: "FontAwesome",
    iconName: "hourglass-3"
  },
  {
    uuid: "c8711cea-bdfa-407e-88c3-6da9b8eebf50",
    iconSet: "FontAwesome",
    iconName: "hourglass-end"
  },
  {
    uuid: "76d376a7-d3d4-44c1-80f7-d4a64fc7355d",
    iconSet: "FontAwesome",
    iconName: "hourglass"
  },
  {
    uuid: "676a995f-96de-4404-b9b6-60a8305f102f",
    iconSet: "FontAwesome",
    iconName: "hand-grab-o"
  },
  {
    uuid: "b314b3bd-d9e7-486d-b3a8-b427ad387a03",
    iconSet: "FontAwesome",
    iconName: "hand-rock-o"
  },
  {
    uuid: "cf6781c8-284e-4662-97d5-63579f9ec44b",
    iconSet: "FontAwesome",
    iconName: "hand-stop-o"
  },
  {
    uuid: "9d229d4b-c85d-4e41-9fb3-3f0a10d203e1",
    iconSet: "FontAwesome",
    iconName: "hand-paper-o"
  },
  {
    uuid: "d16a24af-b3ab-4465-92e7-5917ac093cb5",
    iconSet: "FontAwesome",
    iconName: "hand-scissors-o"
  },
  {
    uuid: "cfbd20da-19b1-4087-ab83-dd303c1fa8e3",
    iconSet: "FontAwesome",
    iconName: "hand-lizard-o"
  },
  {
    uuid: "7511d1a1-7fd5-4c21-a5b0-71142759bceb",
    iconSet: "FontAwesome",
    iconName: "hand-spock-o"
  },
  {
    uuid: "1425498c-112c-42d5-b3c1-e1a80463e6fe",
    iconSet: "FontAwesome",
    iconName: "hand-pointer-o"
  },
  {
    uuid: "51e05bcc-0d5d-4534-b901-b7d802846e89",
    iconSet: "FontAwesome",
    iconName: "hand-peace-o"
  },
  {
    uuid: "f0b238c6-1c50-4d91-8d99-a96615e73881",
    iconSet: "FontAwesome",
    iconName: "trademark"
  },
  {
    uuid: "70ee6687-f800-4d86-b571-028c5f82d1fc",
    iconSet: "FontAwesome",
    iconName: "registered"
  },
  {
    uuid: "43cf8b39-a510-46b9-b376-6b61e536a3c8",
    iconSet: "FontAwesome",
    iconName: "creative-commons"
  },
  {
    uuid: "01a9f750-eaf7-471f-80b9-3a40c32c1446",
    iconSet: "FontAwesome",
    iconName: "gg"
  },
  {
    uuid: "55955503-4db3-4ecc-894e-262c7a5a4aee",
    iconSet: "FontAwesome",
    iconName: "gg-circle"
  },
  {
    uuid: "24783197-0217-46dd-809c-328a338c7448",
    iconSet: "FontAwesome",
    iconName: "tripadvisor"
  },
  {
    uuid: "0189411c-ed8b-42a2-a2d1-08310b2b6ec5",
    iconSet: "FontAwesome",
    iconName: "odnoklassniki"
  },
  {
    uuid: "2f6b3d5c-2a91-4336-a7a2-8b0a360e813d",
    iconSet: "FontAwesome",
    iconName: "odnoklassniki-square"
  },
  {
    uuid: "e635cafd-dd37-4a4e-8e71-ff542d20c0a7",
    iconSet: "FontAwesome",
    iconName: "get-pocket"
  },
  {
    uuid: "904815f1-ee86-447c-9a5e-e60b09077104",
    iconSet: "FontAwesome",
    iconName: "wikipedia-w"
  },
  {
    uuid: "baadc30a-f83f-4dc3-8f30-2aa03cfcf441",
    iconSet: "FontAwesome",
    iconName: "safari"
  },
  {
    uuid: "5411fdbe-9ed4-4770-ac30-5e865ba43db7",
    iconSet: "FontAwesome",
    iconName: "chrome"
  },
  {
    uuid: "30a4d725-3027-4a8a-8844-5dd3a68eef70",
    iconSet: "FontAwesome",
    iconName: "firefox"
  },
  {
    uuid: "39a47b10-0a40-4931-a177-437d457bc9da",
    iconSet: "FontAwesome",
    iconName: "opera"
  },
  {
    uuid: "712b9490-59fe-4323-b725-01e66e56a4f6",
    iconSet: "FontAwesome",
    iconName: "internet-explorer"
  },
  {
    uuid: "139b7c97-7153-468d-8499-ccb20884df8f",
    iconSet: "FontAwesome",
    iconName: "tv"
  },
  {
    uuid: "f1152f08-0409-4d99-b6c6-e8b3b48d5744",
    iconSet: "FontAwesome",
    iconName: "television"
  },
  {
    uuid: "f116049a-e8c0-407a-b52a-62f919935ac5",
    iconSet: "FontAwesome",
    iconName: "contao"
  },
  {
    uuid: "8d0d33f3-35e8-43de-acbd-44bb4fe18a45",
    iconSet: "FontAwesome",
    iconName: "500px"
  },
  {
    uuid: "6e8a1c97-35cd-4731-8d46-5d345841f244",
    iconSet: "FontAwesome",
    iconName: "amazon"
  },
  {
    uuid: "9dc8c807-4cb0-4189-9f0a-d1baf25c73da",
    iconSet: "FontAwesome",
    iconName: "calendar-plus-o"
  },
  {
    uuid: "da2c85a6-568b-4939-b740-288e339dc16b",
    iconSet: "FontAwesome",
    iconName: "calendar-minus-o"
  },
  {
    uuid: "0a260371-0315-417b-95ce-9934fb852a85",
    iconSet: "FontAwesome",
    iconName: "calendar-times-o"
  },
  {
    uuid: "bd5fe903-2500-4e19-bf40-7a8150802d8c",
    iconSet: "FontAwesome",
    iconName: "calendar-check-o"
  },
  {
    uuid: "0cd27f00-aeb3-45d4-a0e6-f1dab28a3ee1",
    iconSet: "FontAwesome",
    iconName: "industry"
  },
  {
    uuid: "01a66086-337d-4397-b4ef-27feff72d738",
    iconSet: "FontAwesome",
    iconName: "map-pin"
  },
  {
    uuid: "9bd71465-f396-4a60-915e-961159b07ed5",
    iconSet: "FontAwesome",
    iconName: "map-signs"
  },
  {
    uuid: "b3e793c3-b4ec-4ec6-b76f-a710b2986b4e",
    iconSet: "FontAwesome",
    iconName: "map-o"
  },
  {
    uuid: "ef7296b5-7ba8-460f-97b6-8f4706fc1b93",
    iconSet: "FontAwesome",
    iconName: "map"
  },
  {
    uuid: "2bad0a7d-a95f-40a5-9f53-c6b1f4d5b130",
    iconSet: "FontAwesome",
    iconName: "commenting"
  },
  {
    uuid: "4b298890-c5f0-489e-835a-df0945cc07b2",
    iconSet: "FontAwesome",
    iconName: "commenting-o"
  },
  {
    uuid: "c5fd63e2-d19d-4350-b6f1-c6be605e72a6",
    iconSet: "FontAwesome",
    iconName: "houzz"
  },
  {
    uuid: "83263f5a-98b7-444a-bb5a-172ece4ccf36",
    iconSet: "FontAwesome",
    iconName: "vimeo"
  },
  {
    uuid: "2ac27b8e-dcf9-4e3b-badc-11bba5ebf37e",
    iconSet: "FontAwesome",
    iconName: "black-tie"
  },
  {
    uuid: "c1c4b1c5-ef0b-491d-9b5d-9f5062667e46",
    iconSet: "FontAwesome",
    iconName: "fonticons"
  },
  {
    uuid: "e1e17acd-776f-44e4-a3df-50e2a5ae47b3",
    iconSet: "FontAwesome",
    iconName: "reddit-alien"
  },
  {
    uuid: "164abb3e-efec-430b-a4f5-1eff7a5ee940",
    iconSet: "FontAwesome",
    iconName: "edge"
  },
  {
    uuid: "cc26b8e8-7885-4867-85d0-7196074e766a",
    iconSet: "FontAwesome",
    iconName: "credit-card-alt"
  },
  {
    uuid: "98e0db94-9780-411b-a0c7-5f61f6b03371",
    iconSet: "FontAwesome",
    iconName: "codiepie"
  },
  {
    uuid: "51de3d50-1180-47dc-a865-6e40c6a29064",
    iconSet: "FontAwesome",
    iconName: "modx"
  },
  {
    uuid: "1c91601d-1724-4add-949b-8f8d64dbbb2a",
    iconSet: "FontAwesome",
    iconName: "fort-awesome"
  },
  {
    uuid: "c06448cf-a70d-4050-b6d2-f0f47a0d6baf",
    iconSet: "FontAwesome",
    iconName: "usb"
  },
  {
    uuid: "a0b8f644-9740-4fb0-aa39-5b3cad221d9b",
    iconSet: "FontAwesome",
    iconName: "product-hunt"
  },
  {
    uuid: "bef8a3d6-3f79-422a-a1b6-2532ad65513f",
    iconSet: "FontAwesome",
    iconName: "mixcloud"
  },
  {
    uuid: "7cc4f662-e9a0-4415-a8a3-82ccd56b15cc",
    iconSet: "FontAwesome",
    iconName: "scribd"
  },
  {
    uuid: "b20be561-df0a-4bd1-9546-51b640de1d8f",
    iconSet: "FontAwesome",
    iconName: "pause-circle"
  },
  {
    uuid: "dba2ee44-a74e-45c1-978d-8c19c589d493",
    iconSet: "FontAwesome",
    iconName: "pause-circle-o"
  },
  {
    uuid: "fd2d893a-184d-4a0d-92c7-afa0a9f93e61",
    iconSet: "FontAwesome",
    iconName: "stop-circle"
  },
  {
    uuid: "232a7f6f-f2da-4cdb-9138-9d42504f99aa",
    iconSet: "FontAwesome",
    iconName: "stop-circle-o"
  },
  {
    uuid: "6532f272-f8a8-4ea2-a3b2-346aea3e1093",
    iconSet: "FontAwesome",
    iconName: "shopping-bag"
  },
  {
    uuid: "8eb4934f-1d7f-4a44-b27f-ed182d07f079",
    iconSet: "FontAwesome",
    iconName: "shopping-basket"
  },
  {
    uuid: "cfc60cd6-40c3-49f7-bdae-2c04289c75b8",
    iconSet: "FontAwesome",
    iconName: "hashtag"
  },
  {
    uuid: "984b2e3f-8591-4a21-b28d-e2e6a72d8d17",
    iconSet: "FontAwesome",
    iconName: "bluetooth"
  },
  {
    uuid: "f032717d-6264-485c-8438-806b9ba879bd",
    iconSet: "FontAwesome",
    iconName: "bluetooth-b"
  },
  {
    uuid: "810a8993-c65b-4a2f-9477-45a2b96a6e69",
    iconSet: "FontAwesome",
    iconName: "percent"
  },
  {
    uuid: "badfce43-5e9b-453c-9ba9-69ab2b04c406",
    iconSet: "FontAwesome",
    iconName: "gitlab"
  },
  {
    uuid: "a443c0a2-4423-4c3c-bbe1-ddc086931a29",
    iconSet: "FontAwesome",
    iconName: "wpbeginner"
  },
  {
    uuid: "82358382-1a2f-4b50-a5fc-d7aad8b30a5b",
    iconSet: "FontAwesome",
    iconName: "wpforms"
  },
  {
    uuid: "02bee1d9-787f-4082-96e5-94e1e3712457",
    iconSet: "FontAwesome",
    iconName: "envira"
  },
  {
    uuid: "1ca91866-a00a-404e-bbd9-731510cb8bf2",
    iconSet: "FontAwesome",
    iconName: "universal-access"
  },
  {
    uuid: "bfb7dad2-f98c-4bab-982e-ebba06010269",
    iconSet: "FontAwesome",
    iconName: "wheelchair-alt"
  },
  {
    uuid: "15bc6a21-9ea2-49df-937f-bb2bd018e693",
    iconSet: "FontAwesome",
    iconName: "question-circle-o"
  },
  {
    uuid: "9d4ba1c0-3e52-41c4-b85e-849bffa95a2f",
    iconSet: "FontAwesome",
    iconName: "blind"
  },
  {
    uuid: "1ac02209-b051-4f89-bed5-2e751357fcdc",
    iconSet: "FontAwesome",
    iconName: "audio-description"
  },
  {
    uuid: "7cc70b27-bbbc-483b-b5eb-24b7217d024c",
    iconSet: "FontAwesome",
    iconName: "volume-control-phone"
  },
  {
    uuid: "b4305c2a-4e03-4e6d-99dd-05723e6d1801",
    iconSet: "FontAwesome",
    iconName: "braille"
  },
  {
    uuid: "1acd25b3-f3a8-4d9d-a8d9-248686a030fa",
    iconSet: "FontAwesome",
    iconName: "assistive-listening-systems"
  },
  {
    uuid: "2b437ded-93ae-4806-b26c-3320629def4b",
    iconSet: "FontAwesome",
    iconName: "asl-interpreting"
  },
  {
    uuid: "9562ad20-1d60-49f4-a471-7c8c0084e80a",
    iconSet: "FontAwesome",
    iconName: "american-sign-language-interpreting"
  },
  {
    uuid: "8132998a-4fbf-4592-aa8d-87d74a72f38a",
    iconSet: "FontAwesome",
    iconName: "deafness"
  },
  {
    uuid: "7579df90-1398-463e-9d9c-ad942439dca8",
    iconSet: "FontAwesome",
    iconName: "hard-of-hearing"
  },
  {
    uuid: "9bfddbf0-42c8-4e51-bba9-1ba5c1553685",
    iconSet: "FontAwesome",
    iconName: "deaf"
  },
  {
    uuid: "f68221fa-d588-424c-afec-630cc30cea99",
    iconSet: "FontAwesome",
    iconName: "glide"
  },
  {
    uuid: "62f1f252-e8d5-4a9c-ba19-1c38fce3ae97",
    iconSet: "FontAwesome",
    iconName: "glide-g"
  },
  {
    uuid: "9a75e27e-a541-4547-84f2-70e173d74f9b",
    iconSet: "FontAwesome",
    iconName: "signing"
  },
  {
    uuid: "1fa68def-2ebf-43a0-8215-2f34ad4f56bf",
    iconSet: "FontAwesome",
    iconName: "sign-language"
  },
  {
    uuid: "17aa77b4-6d0c-48af-9f82-9941f66378d1",
    iconSet: "FontAwesome",
    iconName: "low-vision"
  },
  {
    uuid: "0b9f144e-546d-440c-b20e-0fda00a951cf",
    iconSet: "FontAwesome",
    iconName: "viadeo"
  },
  {
    uuid: "ee6fd41f-24f6-480f-beb7-75debdeb11bf",
    iconSet: "FontAwesome",
    iconName: "viadeo-square"
  },
  {
    uuid: "3e6ac8a6-c2c3-4947-b507-58b58174dc26",
    iconSet: "FontAwesome",
    iconName: "snapchat"
  },
  {
    uuid: "6b6cfc76-ac2e-426d-a655-b934d69b989e",
    iconSet: "FontAwesome",
    iconName: "snapchat-ghost"
  },
  {
    uuid: "1b1220bc-c8b6-4ef2-b798-508c9c4d8f62",
    iconSet: "FontAwesome",
    iconName: "snapchat-square"
  },
  {
    uuid: "5a56c225-04a6-4b10-b119-1eed47d875a5",
    iconSet: "FontAwesome",
    iconName: "pied-piper"
  },
  {
    uuid: "ed0aa904-f094-471d-917b-0a0c843e164b",
    iconSet: "FontAwesome",
    iconName: "first-order"
  },
  {
    uuid: "441f6e86-b3de-4f6d-afb2-6ae922582dfd",
    iconSet: "FontAwesome",
    iconName: "yoast"
  },
  {
    uuid: "dd897917-fbce-4171-9f6e-d726e8ae3890",
    iconSet: "FontAwesome",
    iconName: "themeisle"
  },
  {
    uuid: "29e4b38c-051c-4644-89b2-c9b0c0e7d7e5",
    iconSet: "FontAwesome",
    iconName: "google-plus-circle"
  },
  {
    uuid: "f30ac869-7e77-4649-a896-60a0b1367d94",
    iconSet: "FontAwesome",
    iconName: "google-plus-official"
  },
  {
    uuid: "f2c07ae1-3044-45c8-8dea-d42844c869e1",
    iconSet: "FontAwesome",
    iconName: "fa"
  },
  {
    uuid: "89a24025-4654-4cbd-8324-27547e092c75",
    iconSet: "FontAwesome",
    iconName: "font-awesome"
  },
  {
    uuid: "b42183d3-6e5e-405a-9b56-c3856f1a3ce3",
    iconSet: "FontAwesome",
    iconName: "handshake-o"
  },
  {
    uuid: "4c8d53a6-17d7-4d3d-9d22-a6d6315bb234",
    iconSet: "FontAwesome",
    iconName: "envelope-open"
  },
  {
    uuid: "39dc6294-35e3-4dac-aa60-a8e2bd364dd2",
    iconSet: "FontAwesome",
    iconName: "envelope-open-o"
  },
  {
    uuid: "b60292ca-a10e-43fe-881d-4e21a4c5eab1",
    iconSet: "FontAwesome",
    iconName: "linode"
  },
  {
    uuid: "6e079418-c429-40de-aece-9a4bc97653ef",
    iconSet: "FontAwesome",
    iconName: "address-book"
  },
  {
    uuid: "4d053e1d-b068-4306-a41e-73ccc341da66",
    iconSet: "FontAwesome",
    iconName: "address-book-o"
  },
  {
    uuid: "becadf11-bef4-4c1f-b721-a87ca7a8a5cd",
    iconSet: "FontAwesome",
    iconName: "vcard"
  },
  {
    uuid: "3f179df8-e998-4d89-87d4-f04b1e3e3b5e",
    iconSet: "FontAwesome",
    iconName: "address-card"
  },
  {
    uuid: "49cfd475-bc4f-48ec-af00-ce8e334b286c",
    iconSet: "FontAwesome",
    iconName: "vcard-o"
  },
  {
    uuid: "fafe5857-c4c3-4c20-a21e-0c3cf1079e13",
    iconSet: "FontAwesome",
    iconName: "address-card-o"
  },
  {
    uuid: "4bbaa9df-8421-4b87-b9f0-cbd81ac19072",
    iconSet: "FontAwesome",
    iconName: "user-circle"
  },
  {
    uuid: "6334b8eb-f22c-4a38-9cea-1e0d62ac9280",
    iconSet: "FontAwesome",
    iconName: "user-circle-o"
  },
  {
    uuid: "8b5f54e8-b5bc-423a-b1b5-b1b50fb43bb1",
    iconSet: "FontAwesome",
    iconName: "user-o"
  },
  {
    uuid: "7af7f93a-7d58-40bd-9982-8171eb2b65b4",
    iconSet: "FontAwesome",
    iconName: "id-badge"
  },
  {
    uuid: "181c553f-1082-4100-9084-4ed309f2b383",
    iconSet: "FontAwesome",
    iconName: "drivers-license"
  },
  {
    uuid: "4c571293-2c0f-4c47-a673-cd22316f2955",
    iconSet: "FontAwesome",
    iconName: "id-card"
  },
  {
    uuid: "15f056b8-550d-41c8-990e-285faf92f395",
    iconSet: "FontAwesome",
    iconName: "drivers-license-o"
  },
  {
    uuid: "281503d7-24e2-454c-925e-94ca140b0473",
    iconSet: "FontAwesome",
    iconName: "id-card-o"
  },
  {
    uuid: "34a23174-73f2-4048-8c4b-aded914c394c",
    iconSet: "FontAwesome",
    iconName: "quora"
  },
  {
    uuid: "921a3ed1-1f18-4e50-8eca-78f49f272bb7",
    iconSet: "FontAwesome",
    iconName: "free-code-camp"
  },
  {
    uuid: "7df0e50c-15bc-4a65-b1c5-0d813a9b4ceb",
    iconSet: "FontAwesome",
    iconName: "telegram"
  },
  {
    uuid: "1e572913-0f21-4738-bfc1-fcbb3eb239da",
    iconSet: "FontAwesome",
    iconName: "thermometer-4"
  },
  {
    uuid: "cdfdd56e-6b8c-43e2-961d-09c37283aeb2",
    iconSet: "FontAwesome",
    iconName: "thermometer"
  },
  {
    uuid: "848d0ad2-fc3c-4dde-a2f4-280f3e9b837d",
    iconSet: "FontAwesome",
    iconName: "thermometer-full"
  },
  {
    uuid: "56ec7ca1-196c-4f37-a945-a86961c83316",
    iconSet: "FontAwesome",
    iconName: "thermometer-3"
  },
  {
    uuid: "0522b6bc-5674-43d1-b88b-2b8caa416ff4",
    iconSet: "FontAwesome",
    iconName: "thermometer-three-quarters"
  },
  {
    uuid: "07a6fdce-b92f-4695-abcd-8ff1023b1f7a",
    iconSet: "FontAwesome",
    iconName: "thermometer-2"
  },
  {
    uuid: "78bf8662-fa63-4131-9be1-0f88a953adca",
    iconSet: "FontAwesome",
    iconName: "thermometer-half"
  },
  {
    uuid: "b26f49f6-f45f-4c4c-ab55-a72400bf2ee9",
    iconSet: "FontAwesome",
    iconName: "thermometer-1"
  },
  {
    uuid: "fff9e37b-e3d2-4c16-bffd-0e32cdb2a054",
    iconSet: "FontAwesome",
    iconName: "thermometer-quarter"
  },
  {
    uuid: "1e4d4215-c4ae-4ea5-b52a-f37efc1830c5",
    iconSet: "FontAwesome",
    iconName: "thermometer-0"
  },
  {
    uuid: "ab21db95-d84f-47e2-b8d8-e83fd1f2b194",
    iconSet: "FontAwesome",
    iconName: "thermometer-empty"
  },
  {
    uuid: "c7af46e3-5fcb-448b-b4d9-d0b1f2082a65",
    iconSet: "FontAwesome",
    iconName: "shower"
  },
  {
    uuid: "d67db2bd-e518-4c48-ac28-c92c67ab4117",
    iconSet: "FontAwesome",
    iconName: "bathtub"
  },
  {
    uuid: "00f2c27e-a607-41f8-90d6-6efca836d7e6",
    iconSet: "FontAwesome",
    iconName: "s15"
  },
  {
    uuid: "7d1e022f-bdbd-489a-ae42-90f324282cae",
    iconSet: "FontAwesome",
    iconName: "bath"
  },
  {
    uuid: "19446343-0eb9-4a7c-bc19-999616286b3a",
    iconSet: "FontAwesome",
    iconName: "podcast"
  },
  {
    uuid: "77c05f63-cffb-4b1d-8fc8-b4a56b3b02cd",
    iconSet: "FontAwesome",
    iconName: "window-maximize"
  },
  {
    uuid: "af3f50b9-da67-4752-b0e2-5f7ec0a0e3d5",
    iconSet: "FontAwesome",
    iconName: "window-minimize"
  },
  {
    uuid: "6d7e463c-0e54-49f2-9420-b93133baf1e7",
    iconSet: "FontAwesome",
    iconName: "window-restore"
  },
  {
    uuid: "7942dbc4-8e1d-4aa7-be00-c75d06df13a6",
    iconSet: "FontAwesome",
    iconName: "times-rectangle"
  },
  {
    uuid: "b90f1b56-3939-480b-b13c-0ba7995407b0",
    iconSet: "FontAwesome",
    iconName: "window-close"
  },
  {
    uuid: "e172c1b0-fe2d-436e-9653-9a545ade3f84",
    iconSet: "FontAwesome",
    iconName: "times-rectangle-o"
  },
  {
    uuid: "5fc6788b-b719-4704-b42d-ee69c4d0c54f",
    iconSet: "FontAwesome",
    iconName: "window-close-o"
  },
  {
    uuid: "a69bfb68-a9d6-4769-b52b-5a6767ccebc3",
    iconSet: "FontAwesome",
    iconName: "bandcamp"
  },
  {
    uuid: "cf533a9b-f932-4e16-9ff5-ccf02b3f846f",
    iconSet: "FontAwesome",
    iconName: "grav"
  },
  {
    uuid: "88fb9706-04ba-40e4-bdd7-53115c8b38e4",
    iconSet: "FontAwesome",
    iconName: "etsy"
  },
  {
    uuid: "df06623b-ebb8-4e0c-9612-e9e1bed99cee",
    iconSet: "FontAwesome",
    iconName: "imdb"
  },
  {
    uuid: "4126eeb5-a6d3-438d-b923-ec2426ffeafd",
    iconSet: "FontAwesome",
    iconName: "ravelry"
  },
  {
    uuid: "52082978-41a5-460b-bd66-9fcf3e7116ac",
    iconSet: "FontAwesome",
    iconName: "eercast"
  },
  {
    uuid: "5b27eca0-7910-43b9-ad44-420cd5027cf6",
    iconSet: "FontAwesome",
    iconName: "microchip"
  },
  {
    uuid: "36d30c4f-892e-4773-ad6d-e3c1dfecc749",
    iconSet: "FontAwesome",
    iconName: "snowflake-o"
  },
  {
    uuid: "f04f3965-a324-4129-8ac5-834a786bb7a1",
    iconSet: "FontAwesome",
    iconName: "superpowers"
  },
  {
    uuid: "87f48c3d-394e-44cb-bf77-379ff17c04d1",
    iconSet: "FontAwesome",
    iconName: "wpexplorer"
  },
  {
    uuid: "10953642-b9fe-49d2-b483-1a088df1972c",
    iconSet: "FontAwesome",
    iconName: "meetup"
  },
  {
    uuid: "750aff68-4b47-44f0-93f6-cf223b0e01b4",
    iconSet: "FontAwesome5",
    iconName: "500px"
  },
  {
    uuid: "4b74af62-cdf7-4183-a313-6565bcce7952",
    iconSet: "FontAwesome5",
    iconName: "accessible-icon"
  },
  {
    uuid: "3607e49c-6c55-4a93-bd05-e435a71f4c40",
    iconSet: "FontAwesome5",
    iconName: "accusoft"
  },
  {
    uuid: "48a3522b-edbe-4269-ab4a-f1226639adcc",
    iconSet: "FontAwesome5",
    iconName: "acquisitions-incorporated"
  },
  {
    uuid: "f6acdfbc-b2aa-4ca7-adf2-e300a8113fa3",
    iconSet: "FontAwesome5",
    iconName: "ad"
  },
  {
    uuid: "7318cd65-d213-41af-b067-0d6ed1b907b2",
    iconSet: "FontAwesome5",
    iconName: "address-book"
  },
  {
    uuid: "01518b34-42c0-4656-8c54-fe482c047e83",
    iconSet: "FontAwesome5",
    iconName: "address-card"
  },
  {
    uuid: "3f09f187-f529-48fc-97b6-f1d02f44daa6",
    iconSet: "FontAwesome5",
    iconName: "adjust"
  },
  {
    uuid: "c7ade20c-e089-4586-9e5d-da7b5d358825",
    iconSet: "FontAwesome5",
    iconName: "adn"
  },
  {
    uuid: "f31c7ef7-069d-4a6b-8026-ff376c517c5a",
    iconSet: "FontAwesome5",
    iconName: "adobe"
  },
  {
    uuid: "5d5f583a-37e8-4d3f-b023-4fef2beece9c",
    iconSet: "FontAwesome5",
    iconName: "adversal"
  },
  {
    uuid: "0d001a4e-568e-4589-8579-df0512539cbf",
    iconSet: "FontAwesome5",
    iconName: "affiliatetheme"
  },
  {
    uuid: "5634326c-c219-4417-af76-8fc34d64643a",
    iconSet: "FontAwesome5",
    iconName: "air-freshener"
  },
  {
    uuid: "915ecd1a-8fa4-4e30-9ace-57c3ff4e3f45",
    iconSet: "FontAwesome5",
    iconName: "airbnb"
  },
  {
    uuid: "73c4c5c6-03bb-488e-9477-46b127581eb6",
    iconSet: "FontAwesome5",
    iconName: "algolia"
  },
  {
    uuid: "3efd62b9-d448-4540-a7df-3b8bdd2c0a25",
    iconSet: "FontAwesome5",
    iconName: "align-center"
  },
  {
    uuid: "ee7f0f3c-7e7e-49a8-bf2f-73f2794d1909",
    iconSet: "FontAwesome5",
    iconName: "align-justify"
  },
  {
    uuid: "3ed15ddd-12c1-4289-9aa4-8d466191ff5b",
    iconSet: "FontAwesome5",
    iconName: "align-left"
  },
  {
    uuid: "bcd78eb5-1338-43bf-aa82-5e31a24b3d22",
    iconSet: "FontAwesome5",
    iconName: "align-right"
  },
  {
    uuid: "c68ca9a8-9fc8-4142-8b07-edb732c4a7ee",
    iconSet: "FontAwesome5",
    iconName: "alipay"
  },
  {
    uuid: "6ccc9260-d356-4817-96a9-36d97103647a",
    iconSet: "FontAwesome5",
    iconName: "allergies"
  },
  {
    uuid: "0f03f4aa-76a1-4fe7-b9f9-34b9a4d78025",
    iconSet: "FontAwesome5",
    iconName: "amazon"
  },
  {
    uuid: "7e87e40a-c7a5-4415-9af4-ea16a7837f5c",
    iconSet: "FontAwesome5",
    iconName: "amazon-pay"
  },
  {
    uuid: "3437aa13-72a0-4eb6-ab5a-3f26f2b7a26b",
    iconSet: "FontAwesome5",
    iconName: "ambulance"
  },
  {
    uuid: "46f98e55-1000-42da-ad5d-2ddf540af5ac",
    iconSet: "FontAwesome5",
    iconName: "american-sign-language-interpreting"
  },
  {
    uuid: "9b5c4a7e-aef0-4882-8394-305ccba1647b",
    iconSet: "FontAwesome5",
    iconName: "amilia"
  },
  {
    uuid: "ce996232-34a4-478e-9ede-094e0697899a",
    iconSet: "FontAwesome5",
    iconName: "anchor"
  },
  {
    uuid: "f11db4fd-15e4-4871-9d3a-9ef5edb00e0d",
    iconSet: "FontAwesome5",
    iconName: "android"
  },
  {
    uuid: "d9d80660-bf37-4385-9060-d64619d6b864",
    iconSet: "FontAwesome5",
    iconName: "angellist"
  },
  {
    uuid: "ab29ea6c-2b47-4e8f-a515-269addd1515b",
    iconSet: "FontAwesome5",
    iconName: "angle-double-down"
  },
  {
    uuid: "b26370eb-6543-4e30-9912-1042bb9a627d",
    iconSet: "FontAwesome5",
    iconName: "angle-double-left"
  },
  {
    uuid: "6e84f5e0-26d1-41f7-9e73-9d97d2d17667",
    iconSet: "FontAwesome5",
    iconName: "angle-double-right"
  },
  {
    uuid: "495ffb6c-072b-441e-8a34-ec4a7e53fb7c",
    iconSet: "FontAwesome5",
    iconName: "angle-double-up"
  },
  {
    uuid: "cb713f83-22ec-4f99-80ff-5529e08ce492",
    iconSet: "FontAwesome5",
    iconName: "angle-down"
  },
  {
    uuid: "4574d5cf-c8d5-4563-8823-82740fe176dc",
    iconSet: "FontAwesome5",
    iconName: "angle-left"
  },
  {
    uuid: "9786a683-f298-4471-9f4d-dd7a8165117e",
    iconSet: "FontAwesome5",
    iconName: "angle-right"
  },
  {
    uuid: "a3a85fdf-8d22-4717-b784-fd2c3712c399",
    iconSet: "FontAwesome5",
    iconName: "angle-up"
  },
  {
    uuid: "d61abea7-0666-47db-83ec-913f830612f8",
    iconSet: "FontAwesome5",
    iconName: "angry"
  },
  {
    uuid: "70dc1ded-a59c-4f6f-b63a-4bf0a6450ed9",
    iconSet: "FontAwesome5",
    iconName: "angrycreative"
  },
  {
    uuid: "d1fa97a3-0fa0-4d51-a88d-d5bd04951a79",
    iconSet: "FontAwesome5",
    iconName: "angular"
  },
  {
    uuid: "8aa4f11f-20de-4680-b9b6-04dff1c115d7",
    iconSet: "FontAwesome5",
    iconName: "ankh"
  },
  {
    uuid: "ee1ade20-453f-4b04-b9b8-d11ecabdbf4e",
    iconSet: "FontAwesome5",
    iconName: "app-store"
  },
  {
    uuid: "4ad31769-ad90-4e12-9d76-2451753fe3b7",
    iconSet: "FontAwesome5",
    iconName: "app-store-ios"
  },
  {
    uuid: "8e5f3c0b-48be-4dab-bc78-6b89b676071e",
    iconSet: "FontAwesome5",
    iconName: "apper"
  },
  {
    uuid: "07a5d825-96a6-4586-b099-5bf01245470b",
    iconSet: "FontAwesome5",
    iconName: "apple"
  },
  {
    uuid: "e924690d-971d-451b-b64d-f46bed4c1a1c",
    iconSet: "FontAwesome5",
    iconName: "apple-alt"
  },
  {
    uuid: "b7e70df1-6efc-478f-891f-ffa229dc2ac1",
    iconSet: "FontAwesome5",
    iconName: "apple-pay"
  },
  {
    uuid: "accf4d79-7ec6-4ab3-b283-7962f13ca0da",
    iconSet: "FontAwesome5",
    iconName: "archive"
  },
  {
    uuid: "593f4f78-cc84-47a9-8d97-4501d84cb6b1",
    iconSet: "FontAwesome5",
    iconName: "archway"
  },
  {
    uuid: "9b535b3d-b3ac-4d13-a879-c3d59cd15ea8",
    iconSet: "FontAwesome5",
    iconName: "arrow-alt-circle-down"
  },
  {
    uuid: "f9ea50b3-81f4-409c-93b2-1c264c7ce1a6",
    iconSet: "FontAwesome5",
    iconName: "arrow-alt-circle-left"
  },
  {
    uuid: "8873b989-1770-42e1-8be4-2d10e93e8b8e",
    iconSet: "FontAwesome5",
    iconName: "arrow-alt-circle-right"
  },
  {
    uuid: "71f56672-1f29-430c-aeec-5d710085ac84",
    iconSet: "FontAwesome5",
    iconName: "arrow-alt-circle-up"
  },
  {
    uuid: "a2993c4e-7f73-453a-9164-cbcfa6cb1f05",
    iconSet: "FontAwesome5",
    iconName: "arrow-circle-down"
  },
  {
    uuid: "a7f74c17-34b2-496e-8351-2713fd050ff2",
    iconSet: "FontAwesome5",
    iconName: "arrow-circle-left"
  },
  {
    uuid: "6c04538e-2366-43b0-a4b3-a14a1d7fabd7",
    iconSet: "FontAwesome5",
    iconName: "arrow-circle-right"
  },
  {
    uuid: "765e109c-741c-4213-8627-ff6579b24e10",
    iconSet: "FontAwesome5",
    iconName: "arrow-circle-up"
  },
  {
    uuid: "c258a4cf-53a5-4c88-8097-95e771251e9d",
    iconSet: "FontAwesome5",
    iconName: "arrow-down"
  },
  {
    uuid: "58dd38bb-1edf-4b94-8754-bc1e7673af64",
    iconSet: "FontAwesome5",
    iconName: "arrow-left"
  },
  {
    uuid: "7b782a86-e850-4c2b-94c0-9bf12cd2e572",
    iconSet: "FontAwesome5",
    iconName: "arrow-right"
  },
  {
    uuid: "08f3f743-c5f5-401a-988d-51f0d5a1bbbd",
    iconSet: "FontAwesome5",
    iconName: "arrow-up"
  },
  {
    uuid: "986a13ef-4fa7-4cbb-bc63-692466a9c50c",
    iconSet: "FontAwesome5",
    iconName: "arrows-alt"
  },
  {
    uuid: "bf8827e4-5c13-4986-a24b-eaed6d9b1e04",
    iconSet: "FontAwesome5",
    iconName: "arrows-alt-h"
  },
  {
    uuid: "b4cb391a-bbb1-4e3b-b775-f1752a606654",
    iconSet: "FontAwesome5",
    iconName: "arrows-alt-v"
  },
  {
    uuid: "3df512e6-81b0-4702-aa3f-2420a59b5171",
    iconSet: "FontAwesome5",
    iconName: "artstation"
  },
  {
    uuid: "585c7cf1-375d-40e8-ae54-2bf95d34200f",
    iconSet: "FontAwesome5",
    iconName: "assistive-listening-systems"
  },
  {
    uuid: "fe1c6469-5ca1-4aa4-a722-5c265a1f692a",
    iconSet: "FontAwesome5",
    iconName: "asterisk"
  },
  {
    uuid: "9e2be4b1-508e-4dc2-bd42-0990b4abe5f2",
    iconSet: "FontAwesome5",
    iconName: "asymmetrik"
  },
  {
    uuid: "680fd23c-c78f-4f28-9a3e-0ebce93c5bdb",
    iconSet: "FontAwesome5",
    iconName: "at"
  },
  {
    uuid: "d561cae1-e84a-4263-94d0-1d3784d4fa82",
    iconSet: "FontAwesome5",
    iconName: "atlas"
  },
  {
    uuid: "c378b250-1e90-4b17-bd18-fa3556ef6db9",
    iconSet: "FontAwesome5",
    iconName: "atlassian"
  },
  {
    uuid: "b2397154-0660-48b3-ac30-1f1c30fe3967",
    iconSet: "FontAwesome5",
    iconName: "atom"
  },
  {
    uuid: "5c2db845-c0fe-4a58-996f-5481168f7ae5",
    iconSet: "FontAwesome5",
    iconName: "audible"
  },
  {
    uuid: "589593ad-0492-4e90-826a-d87d21ebc0c4",
    iconSet: "FontAwesome5",
    iconName: "audio-description"
  },
  {
    uuid: "ada43700-8c59-478b-bc91-bca2a920b1c8",
    iconSet: "FontAwesome5",
    iconName: "autoprefixer"
  },
  {
    uuid: "37915046-256c-4490-99fd-ff99c773a763",
    iconSet: "FontAwesome5",
    iconName: "avianex"
  },
  {
    uuid: "f1924570-fe04-4204-a512-1215e3764788",
    iconSet: "FontAwesome5",
    iconName: "aviato"
  },
  {
    uuid: "2f823aa7-3f7e-435b-a443-2b7642227aa7",
    iconSet: "FontAwesome5",
    iconName: "award"
  },
  {
    uuid: "be48e377-b4ba-4fc2-8237-c03dc14ca8ac",
    iconSet: "FontAwesome5",
    iconName: "aws"
  },
  {
    uuid: "b5592f96-4c27-4a36-becb-c093303429c6",
    iconSet: "FontAwesome5",
    iconName: "baby"
  },
  {
    uuid: "98934e8e-d30e-4674-bcaa-5173b11d9193",
    iconSet: "FontAwesome5",
    iconName: "baby-carriage"
  },
  {
    uuid: "b556c0bd-a9dc-45b1-98b4-e7f8fcc935a8",
    iconSet: "FontAwesome5",
    iconName: "backspace"
  },
  {
    uuid: "a5dfab85-6e2d-4673-9dad-74aeb7a6d17c",
    iconSet: "FontAwesome5",
    iconName: "backward"
  },
  {
    uuid: "46794528-6bdc-492b-bcc5-28793e7e2e9a",
    iconSet: "FontAwesome5",
    iconName: "bacon"
  },
  {
    uuid: "9ac93edd-ba1b-444f-93b7-6b5aa69566b6",
    iconSet: "FontAwesome5",
    iconName: "bahai"
  },
  {
    uuid: "ba621132-a23f-4397-a88b-29777365c302",
    iconSet: "FontAwesome5",
    iconName: "balance-scale"
  },
  {
    uuid: "4698fc4e-ff16-445a-b0e6-70283f273580",
    iconSet: "FontAwesome5",
    iconName: "balance-scale-left"
  },
  {
    uuid: "ac890f9b-7bb6-4407-b006-a212d20d867b",
    iconSet: "FontAwesome5",
    iconName: "balance-scale-right"
  },
  {
    uuid: "3e33908e-2894-44f1-8111-a964e366dfbd",
    iconSet: "FontAwesome5",
    iconName: "ban"
  },
  {
    uuid: "1920a3a0-8386-4aae-b732-75d448b50fa7",
    iconSet: "FontAwesome5",
    iconName: "band-aid"
  },
  {
    uuid: "f99d8c44-f2c3-4dd5-95a8-18f85b6a028d",
    iconSet: "FontAwesome5",
    iconName: "bandcamp"
  },
  {
    uuid: "dd5b9127-3754-410e-af3f-28d46c84c57b",
    iconSet: "FontAwesome5",
    iconName: "barcode"
  },
  {
    uuid: "5f6d86a3-f7eb-47bf-99cd-407450a8dbe3",
    iconSet: "FontAwesome5",
    iconName: "bars"
  },
  {
    uuid: "94c5cf51-8bfe-48c9-81f2-1463e8714fb4",
    iconSet: "FontAwesome5",
    iconName: "baseball-ball"
  },
  {
    uuid: "c64580e7-7c64-4cea-894a-ba06a93c4cde",
    iconSet: "FontAwesome5",
    iconName: "basketball-ball"
  },
  {
    uuid: "613f07a1-7b12-47c9-87ba-557c7d3c516e",
    iconSet: "FontAwesome5",
    iconName: "bath"
  },
  {
    uuid: "96132819-b987-476b-ba0a-120f3a2e7d15",
    iconSet: "FontAwesome5",
    iconName: "battery-empty"
  },
  {
    uuid: "573f8ce6-2b32-404b-bcca-696ec4cbfd17",
    iconSet: "FontAwesome5",
    iconName: "battery-full"
  },
  {
    uuid: "78e4f7e6-faf7-4108-9f7f-d3a99fc34476",
    iconSet: "FontAwesome5",
    iconName: "battery-half"
  },
  {
    uuid: "21c7f6dc-f548-45d8-8667-7fa5eba61a49",
    iconSet: "FontAwesome5",
    iconName: "battery-quarter"
  },
  {
    uuid: "cd503571-0407-454c-8676-454ece049569",
    iconSet: "FontAwesome5",
    iconName: "battery-three-quarters"
  },
  {
    uuid: "18abb30d-75a6-4afc-8b77-d955d8ec2220",
    iconSet: "FontAwesome5",
    iconName: "battle-net"
  },
  {
    uuid: "6bc69ff4-7a18-4149-bb5f-a0bce273c855",
    iconSet: "FontAwesome5",
    iconName: "bed"
  },
  {
    uuid: "0d96dd57-050a-4a98-a647-f0999373746d",
    iconSet: "FontAwesome5",
    iconName: "beer"
  },
  {
    uuid: "ae0f8703-31b1-41ae-baca-bda1627c64e4",
    iconSet: "FontAwesome5",
    iconName: "behance"
  },
  {
    uuid: "076943e6-b34d-4770-9c95-1d1ceddd8e3c",
    iconSet: "FontAwesome5",
    iconName: "behance-square"
  },
  {
    uuid: "980f6c27-f289-460c-9a92-ce334c588c15",
    iconSet: "FontAwesome5",
    iconName: "bell"
  },
  {
    uuid: "11f76835-03d5-41fb-af75-c3e1954780b9",
    iconSet: "FontAwesome5",
    iconName: "bell-slash"
  },
  {
    uuid: "7e03ba0d-da71-462f-a47b-56c66eb56841",
    iconSet: "FontAwesome5",
    iconName: "bezier-curve"
  },
  {
    uuid: "08a323b5-0b2c-43d0-b984-8b41d29bde08",
    iconSet: "FontAwesome5",
    iconName: "bible"
  },
  {
    uuid: "5738975c-9c2f-4bd7-9e63-b5c193849144",
    iconSet: "FontAwesome5",
    iconName: "bicycle"
  },
  {
    uuid: "45f33ae5-1dd4-46be-9628-1322afa3aa02",
    iconSet: "FontAwesome5",
    iconName: "biking"
  },
  {
    uuid: "e443dbd0-9d7b-4cd8-a29b-c5e878af34aa",
    iconSet: "FontAwesome5",
    iconName: "bimobject"
  },
  {
    uuid: "dee7f27a-1bf8-48f4-baae-a8f6870db82f",
    iconSet: "FontAwesome5",
    iconName: "binoculars"
  },
  {
    uuid: "8db3bc62-c38a-472d-94d4-f9800a6a7aa3",
    iconSet: "FontAwesome5",
    iconName: "biohazard"
  },
  {
    uuid: "31f5a415-d668-4e2a-8914-9491a66340f2",
    iconSet: "FontAwesome5",
    iconName: "birthday-cake"
  },
  {
    uuid: "daa1c035-c08d-4b45-aa63-f2cd41f44fed",
    iconSet: "FontAwesome5",
    iconName: "bitbucket"
  },
  {
    uuid: "78bce631-73fe-4861-801e-e40044f61cbc",
    iconSet: "FontAwesome5",
    iconName: "bitcoin"
  },
  {
    uuid: "6eb86a88-d84f-4f40-a6df-dac37800bd5b",
    iconSet: "FontAwesome5",
    iconName: "bity"
  },
  {
    uuid: "612e3cab-872e-4da0-a160-6284e57227aa",
    iconSet: "FontAwesome5",
    iconName: "black-tie"
  },
  {
    uuid: "df72edeb-c036-4639-aca2-10e87a0daaa1",
    iconSet: "FontAwesome5",
    iconName: "blackberry"
  },
  {
    uuid: "fce448ab-ba26-492d-9575-d37093ed05c4",
    iconSet: "FontAwesome5",
    iconName: "blender"
  },
  {
    uuid: "6fd9e28a-41d3-4cc1-8bc7-eaf6e871cb0d",
    iconSet: "FontAwesome5",
    iconName: "blender-phone"
  },
  {
    uuid: "3b70d3c6-d6d0-4c8e-a9c3-3a560376b315",
    iconSet: "FontAwesome5",
    iconName: "blind"
  },
  {
    uuid: "7cf7a5ca-2ee9-4d23-afe5-0903a5ad23b0",
    iconSet: "FontAwesome5",
    iconName: "blog"
  },
  {
    uuid: "8d4c1dd4-6a7b-492e-bcc7-4fcf2396f8d9",
    iconSet: "FontAwesome5",
    iconName: "blogger"
  },
  {
    uuid: "c1144eaa-6f28-4073-a185-2e9886dd9f64",
    iconSet: "FontAwesome5",
    iconName: "blogger-b"
  },
  {
    uuid: "091aa652-fbd1-495b-b5bd-1e91d7d3da1a",
    iconSet: "FontAwesome5",
    iconName: "bluetooth"
  },
  {
    uuid: "4288b2f9-84d1-4145-beff-3c686b9c0598",
    iconSet: "FontAwesome5",
    iconName: "bluetooth-b"
  },
  {
    uuid: "b15d0b58-3197-45de-b8b7-a0868d025f48",
    iconSet: "FontAwesome5",
    iconName: "bold"
  },
  {
    uuid: "a7626ac8-43d6-4d29-8214-3f42adb89fd7",
    iconSet: "FontAwesome5",
    iconName: "bolt"
  },
  {
    uuid: "cc694d18-dd00-4062-9868-21ffdcca0420",
    iconSet: "FontAwesome5",
    iconName: "bomb"
  },
  {
    uuid: "a1f737b2-5b98-4965-949a-3a5ddc31ccd0",
    iconSet: "FontAwesome5",
    iconName: "bone"
  },
  {
    uuid: "940f27c7-547d-4966-be46-e78628adcea2",
    iconSet: "FontAwesome5",
    iconName: "bong"
  },
  {
    uuid: "2434b640-8e97-4b9d-8706-bbac9ee1b6ca",
    iconSet: "FontAwesome5",
    iconName: "book"
  },
  {
    uuid: "81583bb7-098e-4ec5-92b4-e8760ac01f8a",
    iconSet: "FontAwesome5",
    iconName: "book-dead"
  },
  {
    uuid: "b8f74a71-9f6b-4b84-8fd1-b33b7b2c0185",
    iconSet: "FontAwesome5",
    iconName: "book-medical"
  },
  {
    uuid: "eaff01b8-2f51-467c-a250-65d59914df91",
    iconSet: "FontAwesome5",
    iconName: "book-open"
  },
  {
    uuid: "cd5ad263-2a67-4ba7-a1a8-b4fbd9733353",
    iconSet: "FontAwesome5",
    iconName: "book-reader"
  },
  {
    uuid: "469a8fc6-ab7d-47d0-a233-b761157d8be6",
    iconSet: "FontAwesome5",
    iconName: "bookmark"
  },
  {
    uuid: "5dca3c93-14a3-42f6-b7b6-7f006c7245a7",
    iconSet: "FontAwesome5",
    iconName: "bootstrap"
  },
  {
    uuid: "8c755e2e-e289-4ffb-b3e9-4c0800690c5a",
    iconSet: "FontAwesome5",
    iconName: "border-all"
  },
  {
    uuid: "c2cb0c9d-d8d0-4e2a-97ad-64eec9df0bb3",
    iconSet: "FontAwesome5",
    iconName: "border-none"
  },
  {
    uuid: "dde8c0ee-393c-423b-8e06-61f40693b7f1",
    iconSet: "FontAwesome5",
    iconName: "border-style"
  },
  {
    uuid: "3b0af52d-c253-4d51-9749-b00f53e40b03",
    iconSet: "FontAwesome5",
    iconName: "bowling-ball"
  },
  {
    uuid: "632d0207-231d-4d12-967d-3b99f3454877",
    iconSet: "FontAwesome5",
    iconName: "box"
  },
  {
    uuid: "49625009-57d1-4f1c-8ba3-0898a612c652",
    iconSet: "FontAwesome5",
    iconName: "box-open"
  },
  {
    uuid: "65745395-9805-4743-a6ab-63ff4f1a57cb",
    iconSet: "FontAwesome5",
    iconName: "box-tissue"
  },
  {
    uuid: "de6e4785-7a3d-4f28-b5f3-f64f5a24d480",
    iconSet: "FontAwesome5",
    iconName: "boxes"
  },
  {
    uuid: "cd81b1c7-f926-4cd1-a757-d7b7b4f5c256",
    iconSet: "FontAwesome5",
    iconName: "braille"
  },
  {
    uuid: "4caf1cf4-2c7c-4876-a28c-c2431cf579e8",
    iconSet: "FontAwesome5",
    iconName: "brain"
  },
  {
    uuid: "502ab5c5-b276-4114-bf2b-82c9dd8c89de",
    iconSet: "FontAwesome5",
    iconName: "bread-slice"
  },
  {
    uuid: "a2a327c5-cfa0-4e66-bc78-0546e7a1a0ea",
    iconSet: "FontAwesome5",
    iconName: "briefcase"
  },
  {
    uuid: "95ae7acf-3f7c-423e-b9e2-31a47595f34a",
    iconSet: "FontAwesome5",
    iconName: "briefcase-medical"
  },
  {
    uuid: "69e69050-0326-41f0-9d48-141c720541aa",
    iconSet: "FontAwesome5",
    iconName: "broadcast-tower"
  },
  {
    uuid: "be6ad2ed-4d5d-4d0c-a7dc-1cf3a4e54a3a",
    iconSet: "FontAwesome5",
    iconName: "broom"
  },
  {
    uuid: "288e9f0d-c10c-48b3-b7d5-a93dd02ce19e",
    iconSet: "FontAwesome5",
    iconName: "brush"
  },
  {
    uuid: "ffde04c4-2bfb-43ba-9a4b-3cd76ef8e487",
    iconSet: "FontAwesome5",
    iconName: "btc"
  },
  {
    uuid: "49ea1ecb-9c11-4730-9109-e7598328266e",
    iconSet: "FontAwesome5",
    iconName: "buffer"
  },
  {
    uuid: "0f629bf3-8438-4fea-9443-dfd825a9cec1",
    iconSet: "FontAwesome5",
    iconName: "bug"
  },
  {
    uuid: "90b303a0-89e0-41db-b720-71ee61dbc303",
    iconSet: "FontAwesome5",
    iconName: "building"
  },
  {
    uuid: "14c5406c-700c-4024-8b89-67a452ec027a",
    iconSet: "FontAwesome5",
    iconName: "bullhorn"
  },
  {
    uuid: "bedc0d08-e078-4fe5-8d2d-bcdbe2507fe8",
    iconSet: "FontAwesome5",
    iconName: "bullseye"
  },
  {
    uuid: "87622ec1-f886-4fa2-83d2-dcf82abee2f3",
    iconSet: "FontAwesome5",
    iconName: "burn"
  },
  {
    uuid: "26ee3fbc-54a1-4bdd-9f5b-d7cb00a86a7e",
    iconSet: "FontAwesome5",
    iconName: "buromobelexperte"
  },
  {
    uuid: "f344d4d3-7dda-49ab-b68a-87602249ab59",
    iconSet: "FontAwesome5",
    iconName: "bus"
  },
  {
    uuid: "288b4f89-ca47-476b-8bf2-68cd704b8f56",
    iconSet: "FontAwesome5",
    iconName: "bus-alt"
  },
  {
    uuid: "9185c6ab-ac10-4246-ad61-9869923176a4",
    iconSet: "FontAwesome5",
    iconName: "business-time"
  },
  {
    uuid: "33d1ec28-06a7-42d1-a8b5-784984915f77",
    iconSet: "FontAwesome5",
    iconName: "buy-n-large"
  },
  {
    uuid: "56f21a1a-7876-47cf-8f1d-a9f7c064da59",
    iconSet: "FontAwesome5",
    iconName: "buysellads"
  },
  {
    uuid: "70545b1b-aed5-454d-b5bf-54cb2b1df885",
    iconSet: "FontAwesome5",
    iconName: "calculator"
  },
  {
    uuid: "393065f7-9f22-4831-b31f-7bd157eb0b5e",
    iconSet: "FontAwesome5",
    iconName: "calendar"
  },
  {
    uuid: "0e52f4e2-68e5-40f5-bd8e-2aa22e80d868",
    iconSet: "FontAwesome5",
    iconName: "calendar-alt"
  },
  {
    uuid: "02f6806a-6b2e-4020-8576-aab179247302",
    iconSet: "FontAwesome5",
    iconName: "calendar-check"
  },
  {
    uuid: "d380ff08-a482-403c-8e50-197d8d786299",
    iconSet: "FontAwesome5",
    iconName: "calendar-day"
  },
  {
    uuid: "b59e059d-815b-42b1-91c9-aa2dfde63b37",
    iconSet: "FontAwesome5",
    iconName: "calendar-minus"
  },
  {
    uuid: "520e1baf-c69d-4311-8d4e-29ffddc7e35d",
    iconSet: "FontAwesome5",
    iconName: "calendar-plus"
  },
  {
    uuid: "d6ecc986-ac28-49e7-9dc8-50b4c54dae96",
    iconSet: "FontAwesome5",
    iconName: "calendar-times"
  },
  {
    uuid: "a19bcf0c-95e5-45c5-bf68-33ef9a417da4",
    iconSet: "FontAwesome5",
    iconName: "calendar-week"
  },
  {
    uuid: "7f5efab2-2ac2-4bf5-98c6-289a66d9053c",
    iconSet: "FontAwesome5",
    iconName: "camera"
  },
  {
    uuid: "583250d9-86dc-4509-808a-2e5833a9dbe3",
    iconSet: "FontAwesome5",
    iconName: "camera-retro"
  },
  {
    uuid: "4c5c4cd2-9b99-4ff8-af85-88c627a7e8cf",
    iconSet: "FontAwesome5",
    iconName: "campground"
  },
  {
    uuid: "cc4b8c47-2b16-44d4-ad08-89a96b0de404",
    iconSet: "FontAwesome5",
    iconName: "canadian-maple-leaf"
  },
  {
    uuid: "2a6382da-376e-4ca1-9843-87843e0311f5",
    iconSet: "FontAwesome5",
    iconName: "candy-cane"
  },
  {
    uuid: "baf51398-bf7c-437d-8b8d-2d82e2d8d3fe",
    iconSet: "FontAwesome5",
    iconName: "cannabis"
  },
  {
    uuid: "e88d6529-dc36-4bdb-bb9b-dc6a513880e8",
    iconSet: "FontAwesome5",
    iconName: "capsules"
  },
  {
    uuid: "fb3a993c-5e8f-474d-98b2-592a408c6493",
    iconSet: "FontAwesome5",
    iconName: "car"
  },
  {
    uuid: "1bc550ee-7981-4626-b9dd-db2d7e2909ad",
    iconSet: "FontAwesome5",
    iconName: "car-alt"
  },
  {
    uuid: "999ad106-4698-4504-83ba-6cace1b91a8a",
    iconSet: "FontAwesome5",
    iconName: "car-battery"
  },
  {
    uuid: "674d36bc-8777-404e-ae3a-ed760bb4c1eb",
    iconSet: "FontAwesome5",
    iconName: "car-crash"
  },
  {
    uuid: "45bcce85-a707-46d6-8b3e-d4039c71afb4",
    iconSet: "FontAwesome5",
    iconName: "car-side"
  },
  {
    uuid: "ca87a12a-18fa-473d-9c2c-9fe74f6b5ecc",
    iconSet: "FontAwesome5",
    iconName: "caravan"
  },
  {
    uuid: "9b8b406a-f9c0-4bf2-b2ad-8cdb8cf1a3df",
    iconSet: "FontAwesome5",
    iconName: "caret-down"
  },
  {
    uuid: "43871423-4b23-4c4d-975e-f5ec639dc400",
    iconSet: "FontAwesome5",
    iconName: "caret-left"
  },
  {
    uuid: "8d0407e6-04c2-4534-9317-007627e977fc",
    iconSet: "FontAwesome5",
    iconName: "caret-right"
  },
  {
    uuid: "3e46a4b5-b10f-4a8a-aea3-af14253182a0",
    iconSet: "FontAwesome5",
    iconName: "caret-square-down"
  },
  {
    uuid: "a02005f1-24ae-46b3-863f-3a1588681ba2",
    iconSet: "FontAwesome5",
    iconName: "caret-square-left"
  },
  {
    uuid: "ec384a6d-96b3-43b8-865c-ca368dcfb8ef",
    iconSet: "FontAwesome5",
    iconName: "caret-square-right"
  },
  {
    uuid: "96ae0c2d-3664-4c7d-91d7-7b7b9dd46e02",
    iconSet: "FontAwesome5",
    iconName: "caret-square-up"
  },
  {
    uuid: "adc561d0-eb74-4467-8ae3-bb209442ba15",
    iconSet: "FontAwesome5",
    iconName: "caret-up"
  },
  {
    uuid: "6fc6ed03-d10a-47f3-ba92-000f3f2eaedd",
    iconSet: "FontAwesome5",
    iconName: "carrot"
  },
  {
    uuid: "b637a801-ea25-49dd-bb2a-fa8c5a64eaec",
    iconSet: "FontAwesome5",
    iconName: "cart-arrow-down"
  },
  {
    uuid: "b3f6d6a1-c442-4b36-b0d4-9fb7b15ef1a2",
    iconSet: "FontAwesome5",
    iconName: "cart-plus"
  },
  {
    uuid: "12276982-2559-45ae-95a9-ca9279f0f92d",
    iconSet: "FontAwesome5",
    iconName: "cash-register"
  },
  {
    uuid: "8bc7420c-f868-490c-b75d-58860d7d3968",
    iconSet: "FontAwesome5",
    iconName: "cat"
  },
  {
    uuid: "e17f8651-3d20-49ef-a87a-aac1aadc4550",
    iconSet: "FontAwesome5",
    iconName: "cc-amazon-pay"
  },
  {
    uuid: "35215ece-9e10-4f4e-afaf-38a34ac18697",
    iconSet: "FontAwesome5",
    iconName: "cc-amex"
  },
  {
    uuid: "6ab53656-9e4e-48fa-a1d7-a037a0d4cefb",
    iconSet: "FontAwesome5",
    iconName: "cc-apple-pay"
  },
  {
    uuid: "7aa6c641-4747-4b65-82d1-f237fd755c68",
    iconSet: "FontAwesome5",
    iconName: "cc-diners-club"
  },
  {
    uuid: "d20e96a0-5804-40aa-b99f-5eac1242fdb6",
    iconSet: "FontAwesome5",
    iconName: "cc-discover"
  },
  {
    uuid: "77437a63-99b3-46d4-8c86-a0bf390a576b",
    iconSet: "FontAwesome5",
    iconName: "cc-jcb"
  },
  {
    uuid: "432fe941-c673-4382-9806-1e411795176e",
    iconSet: "FontAwesome5",
    iconName: "cc-mastercard"
  },
  {
    uuid: "c381ac2c-f736-4078-8be9-91e5ef0ae362",
    iconSet: "FontAwesome5",
    iconName: "cc-paypal"
  },
  {
    uuid: "131312cf-c001-4e28-93d4-fd3ee72973b3",
    iconSet: "FontAwesome5",
    iconName: "cc-stripe"
  },
  {
    uuid: "8b8b976b-a534-4be5-b6b9-993d44c166f8",
    iconSet: "FontAwesome5",
    iconName: "cc-visa"
  },
  {
    uuid: "7b0aac02-da4d-4e89-bbd6-57117fc8cce2",
    iconSet: "FontAwesome5",
    iconName: "centercode"
  },
  {
    uuid: "bcc1c8e5-31f4-49e6-8a2e-8782c7487aee",
    iconSet: "FontAwesome5",
    iconName: "centos"
  },
  {
    uuid: "d06655b4-1522-40fa-9ea7-ca5a2a8b2e8e",
    iconSet: "FontAwesome5",
    iconName: "certificate"
  },
  {
    uuid: "af8171a1-b018-41e3-a01d-77fb308249f5",
    iconSet: "FontAwesome5",
    iconName: "chair"
  },
  {
    uuid: "48f690f1-0149-448c-97dd-5e6dbd4d20e4",
    iconSet: "FontAwesome5",
    iconName: "chalkboard"
  },
  {
    uuid: "21be02b3-40f3-4482-84d8-9b29a0090564",
    iconSet: "FontAwesome5",
    iconName: "chalkboard-teacher"
  },
  {
    uuid: "dd658a07-ec1d-4e87-b4f0-2f04262202eb",
    iconSet: "FontAwesome5",
    iconName: "charging-station"
  },
  {
    uuid: "92763313-396b-4e4e-991d-57f68ebefdcc",
    iconSet: "FontAwesome5",
    iconName: "chart-area"
  },
  {
    uuid: "454839ff-4c7a-4304-ae15-0069635e195b",
    iconSet: "FontAwesome5",
    iconName: "chart-bar"
  },
  {
    uuid: "74ad7a43-6222-4300-81f2-920c58488bba",
    iconSet: "FontAwesome5",
    iconName: "chart-line"
  },
  {
    uuid: "373ebe09-bbfe-49d0-9ee6-6c43fd27b408",
    iconSet: "FontAwesome5",
    iconName: "chart-pie"
  },
  {
    uuid: "cd969e37-c1c9-425a-9588-311612ba6a5f",
    iconSet: "FontAwesome5",
    iconName: "check"
  },
  {
    uuid: "030dc037-121c-416c-bd5c-ee5325e71a70",
    iconSet: "FontAwesome5",
    iconName: "check-circle"
  },
  {
    uuid: "08fc9b1b-7eea-417a-889a-0bb93be2f11b",
    iconSet: "FontAwesome5",
    iconName: "check-double"
  },
  {
    uuid: "baa98ab8-f7d9-4893-b317-be4ba792ea0b",
    iconSet: "FontAwesome5",
    iconName: "check-square"
  },
  {
    uuid: "a50e1704-6621-4e0a-b889-87d98eb754af",
    iconSet: "FontAwesome5",
    iconName: "cheese"
  },
  {
    uuid: "d7478e38-b556-4866-8405-1a9c2d2ea6a5",
    iconSet: "FontAwesome5",
    iconName: "chess"
  },
  {
    uuid: "b9488b84-7e36-4263-921c-253b2f94994b",
    iconSet: "FontAwesome5",
    iconName: "chess-bishop"
  },
  {
    uuid: "095a6f4e-a112-429d-a8a5-62c4284e93e1",
    iconSet: "FontAwesome5",
    iconName: "chess-board"
  },
  {
    uuid: "8a1b245b-09e8-4286-8f36-4cf1a422b174",
    iconSet: "FontAwesome5",
    iconName: "chess-king"
  },
  {
    uuid: "538e3f49-8055-4912-ba48-0ffa3a3598f7",
    iconSet: "FontAwesome5",
    iconName: "chess-knight"
  },
  {
    uuid: "0c783394-1448-4fc5-9cc1-3e5463d99fb6",
    iconSet: "FontAwesome5",
    iconName: "chess-pawn"
  },
  {
    uuid: "3d2cec9c-e1f0-4312-98de-339198ceab8c",
    iconSet: "FontAwesome5",
    iconName: "chess-queen"
  },
  {
    uuid: "7e29fc8d-785b-4559-8f3c-fa67c93cbe5e",
    iconSet: "FontAwesome5",
    iconName: "chess-rook"
  },
  {
    uuid: "86bae1d7-c13d-44b3-93ef-a24d78c4162b",
    iconSet: "FontAwesome5",
    iconName: "chevron-circle-down"
  },
  {
    uuid: "c36b8802-ad0e-4670-9052-dc96d4ba75f2",
    iconSet: "FontAwesome5",
    iconName: "chevron-circle-left"
  },
  {
    uuid: "fe8cd665-b0c5-4795-bf63-1ab68b29b691",
    iconSet: "FontAwesome5",
    iconName: "chevron-circle-right"
  },
  {
    uuid: "b664d53f-095d-45b0-9d3c-2974ba1320fc",
    iconSet: "FontAwesome5",
    iconName: "chevron-circle-up"
  },
  {
    uuid: "ae2f5d97-8f4e-47ec-bbbe-92e94684e18c",
    iconSet: "FontAwesome5",
    iconName: "chevron-down"
  },
  {
    uuid: "45f7588e-0ed1-446e-a078-15d62168161a",
    iconSet: "FontAwesome5",
    iconName: "chevron-left"
  },
  {
    uuid: "fe9d73cd-4ed2-4403-912e-7c908fb1f938",
    iconSet: "FontAwesome5",
    iconName: "chevron-right"
  },
  {
    uuid: "a71506dc-6e86-4c78-9119-c66fecd978a9",
    iconSet: "FontAwesome5",
    iconName: "chevron-up"
  },
  {
    uuid: "aaca6da7-7d7b-4eaa-b21f-f98df1d413f5",
    iconSet: "FontAwesome5",
    iconName: "child"
  },
  {
    uuid: "ffec3dec-e177-47ea-a33a-8f4c2e1fbe58",
    iconSet: "FontAwesome5",
    iconName: "chrome"
  },
  {
    uuid: "af1ee93f-496b-490b-bc78-d5745fc78111",
    iconSet: "FontAwesome5",
    iconName: "chromecast"
  },
  {
    uuid: "e1a11691-f9b4-4ff7-a185-b2b0165cb590",
    iconSet: "FontAwesome5",
    iconName: "church"
  },
  {
    uuid: "9abbf947-9126-4af3-b8e4-a0258e1ac816",
    iconSet: "FontAwesome5",
    iconName: "circle"
  },
  {
    uuid: "dc780d52-41ea-4ec2-aef9-6ab59e44f651",
    iconSet: "FontAwesome5",
    iconName: "circle-notch"
  },
  {
    uuid: "40220e1a-0dff-4485-a395-ec6203d1e454",
    iconSet: "FontAwesome5",
    iconName: "city"
  },
  {
    uuid: "590b0d32-f309-4a30-bffe-831874a3dfa0",
    iconSet: "FontAwesome5",
    iconName: "clinic-medical"
  },
  {
    uuid: "6808507f-853d-41bc-9629-2161684e3cfd",
    iconSet: "FontAwesome5",
    iconName: "clipboard"
  },
  {
    uuid: "cd8a7ad5-a87a-46bb-b4f5-59d2679f7657",
    iconSet: "FontAwesome5",
    iconName: "clipboard-check"
  },
  {
    uuid: "642d6133-13e4-427d-a96e-bd60b13bd3a7",
    iconSet: "FontAwesome5",
    iconName: "clipboard-list"
  },
  {
    uuid: "46e962ab-43a3-48d6-acdd-8eb7f60d9405",
    iconSet: "FontAwesome5",
    iconName: "clock"
  },
  {
    uuid: "a41f7f13-078b-4a7e-b751-364ccf4eb5fe",
    iconSet: "FontAwesome5",
    iconName: "clone"
  },
  {
    uuid: "b436a74d-8df8-433c-bd96-399c23a7d0d5",
    iconSet: "FontAwesome5",
    iconName: "closed-captioning"
  },
  {
    uuid: "81d28d41-b2e3-41a9-ae12-d3fd0ed70520",
    iconSet: "FontAwesome5",
    iconName: "cloud"
  },
  {
    uuid: "8ffe7ffd-5bd2-4cfc-a221-1ca8d8f3d557",
    iconSet: "FontAwesome5",
    iconName: "cloud-download-alt"
  },
  {
    uuid: "e8706377-a5de-47f9-80fe-fc58b4b662ad",
    iconSet: "FontAwesome5",
    iconName: "cloud-meatball"
  },
  {
    uuid: "be7f835e-ffea-4390-9af6-b818bb256bc5",
    iconSet: "FontAwesome5",
    iconName: "cloud-moon"
  },
  {
    uuid: "c25f9371-9e9f-4688-a4d4-b88980d6ffd1",
    iconSet: "FontAwesome5",
    iconName: "cloud-moon-rain"
  },
  {
    uuid: "5aaf16f4-1608-4b0e-ae47-439ddcf1100f",
    iconSet: "FontAwesome5",
    iconName: "cloud-rain"
  },
  {
    uuid: "71b550d7-eb56-43cb-ac5a-2ef299d4f215",
    iconSet: "FontAwesome5",
    iconName: "cloud-showers-heavy"
  },
  {
    uuid: "32a7edd4-7917-41fd-8237-21b7c21ace0f",
    iconSet: "FontAwesome5",
    iconName: "cloud-sun"
  },
  {
    uuid: "c775dec2-5dcc-4c4d-8fc6-803290b21612",
    iconSet: "FontAwesome5",
    iconName: "cloud-sun-rain"
  },
  {
    uuid: "efd2aaac-1b79-4dce-b4cc-1db570b5d0ac",
    iconSet: "FontAwesome5",
    iconName: "cloud-upload-alt"
  },
  {
    uuid: "a5d96c19-33a3-46c1-87b6-d5b658feda5b",
    iconSet: "FontAwesome5",
    iconName: "cloudscale"
  },
  {
    uuid: "3dd02783-54e1-44c0-9aa2-9478a57f30e3",
    iconSet: "FontAwesome5",
    iconName: "cloudsmith"
  },
  {
    uuid: "ad1c3922-fcd5-41b1-9958-db4da39fc4e9",
    iconSet: "FontAwesome5",
    iconName: "cloudversify"
  },
  {
    uuid: "61c449ea-3607-478e-b49d-96d20243994b",
    iconSet: "FontAwesome5",
    iconName: "cocktail"
  },
  {
    uuid: "52a32f5c-ccad-4d3b-b069-870fdcba3337",
    iconSet: "FontAwesome5",
    iconName: "code"
  },
  {
    uuid: "f9c56fe4-41df-4a54-b2cf-07cb113e4e24",
    iconSet: "FontAwesome5",
    iconName: "code-branch"
  },
  {
    uuid: "1824260b-8f26-45b4-b31c-ce550d3ce023",
    iconSet: "FontAwesome5",
    iconName: "codepen"
  },
  {
    uuid: "d279396b-7324-4717-b315-bf7445eefcae",
    iconSet: "FontAwesome5",
    iconName: "codiepie"
  },
  {
    uuid: "167aa31b-20e3-47d5-a8bf-e3452e176019",
    iconSet: "FontAwesome5",
    iconName: "coffee"
  },
  {
    uuid: "dd6006cf-7248-46a7-b1b3-897f19e55e11",
    iconSet: "FontAwesome5",
    iconName: "cog"
  },
  {
    uuid: "cb81b27c-be81-47e6-a836-3414c1a5f8aa",
    iconSet: "FontAwesome5",
    iconName: "cogs"
  },
  {
    uuid: "ef5fb94f-b7fc-4d56-9036-678c3cf893db",
    iconSet: "FontAwesome5",
    iconName: "coins"
  },
  {
    uuid: "670ae996-f20b-46c2-936a-9889a75d7818",
    iconSet: "FontAwesome5",
    iconName: "columns"
  },
  {
    uuid: "3a16abfd-9a50-4f22-a3f4-8cf7a033417d",
    iconSet: "FontAwesome5",
    iconName: "comment"
  },
  {
    uuid: "9a9c4388-c906-44ef-b5a8-04ae5330f9d4",
    iconSet: "FontAwesome5",
    iconName: "comment-alt"
  },
  {
    uuid: "aa6e97b3-107a-4060-915d-e68b4e27b4b3",
    iconSet: "FontAwesome5",
    iconName: "comment-dollar"
  },
  {
    uuid: "a3e7ff10-9b9a-4ac9-9073-9c9415ab0a88",
    iconSet: "FontAwesome5",
    iconName: "comment-dots"
  },
  {
    uuid: "eeb7512f-5516-4a6c-b858-14aff2ff02fe",
    iconSet: "FontAwesome5",
    iconName: "comment-medical"
  },
  {
    uuid: "74de21fd-7b59-43f1-9fae-bf8e9836d6d5",
    iconSet: "FontAwesome5",
    iconName: "comment-slash"
  },
  {
    uuid: "daed99e0-8b36-43a4-a7a9-761e8d276aa9",
    iconSet: "FontAwesome5",
    iconName: "comments"
  },
  {
    uuid: "813c6c29-b302-42f8-99d9-e62a8a64c7d5",
    iconSet: "FontAwesome5",
    iconName: "comments-dollar"
  },
  {
    uuid: "154a9e40-0f0c-4e01-8cc1-d71fedb5ba5a",
    iconSet: "FontAwesome5",
    iconName: "compact-disc"
  },
  {
    uuid: "8c9000f2-104f-4731-8877-a333f2d2343b",
    iconSet: "FontAwesome5",
    iconName: "compass"
  },
  {
    uuid: "5681e277-d6fb-4520-8ce7-a6d5ef54dc56",
    iconSet: "FontAwesome5",
    iconName: "compress"
  },
  {
    uuid: "94f70df7-bad2-45b2-96be-8c620abed706",
    iconSet: "FontAwesome5",
    iconName: "compress-alt"
  },
  {
    uuid: "46e4c213-4abe-45e2-8198-196798ea449a",
    iconSet: "FontAwesome5",
    iconName: "compress-arrows-alt"
  },
  {
    uuid: "4f592446-97d1-4055-a8d3-102104eabc87",
    iconSet: "FontAwesome5",
    iconName: "concierge-bell"
  },
  {
    uuid: "a1817961-517f-4855-b7fa-77a1e7781131",
    iconSet: "FontAwesome5",
    iconName: "confluence"
  },
  {
    uuid: "9290bc87-f344-4725-97be-7c64e4341217",
    iconSet: "FontAwesome5",
    iconName: "connectdevelop"
  },
  {
    uuid: "0cc55e45-44eb-415c-9604-885efe31116b",
    iconSet: "FontAwesome5",
    iconName: "contao"
  },
  {
    uuid: "b7396e99-adc8-453e-a500-995416897b39",
    iconSet: "FontAwesome5",
    iconName: "cookie"
  },
  {
    uuid: "5ef13cb7-621e-4e71-9dba-c8837b53234a",
    iconSet: "FontAwesome5",
    iconName: "cookie-bite"
  },
  {
    uuid: "fb2df661-c131-4915-a3c9-c9cfe09933bc",
    iconSet: "FontAwesome5",
    iconName: "copy"
  },
  {
    uuid: "a662d283-092c-490b-b86b-b52135519540",
    iconSet: "FontAwesome5",
    iconName: "copyright"
  },
  {
    uuid: "e1b216fa-da33-4761-93af-941590119c51",
    iconSet: "FontAwesome5",
    iconName: "cotton-bureau"
  },
  {
    uuid: "13ba3f1e-425e-4b14-be95-7f37ade71957",
    iconSet: "FontAwesome5",
    iconName: "couch"
  },
  {
    uuid: "2eeb6238-a13a-4005-b347-b1c6c3b340ee",
    iconSet: "FontAwesome5",
    iconName: "cpanel"
  },
  {
    uuid: "04c36888-eba1-4249-8d3d-5b8a12ece3eb",
    iconSet: "FontAwesome5",
    iconName: "creative-commons"
  },
  {
    uuid: "659111ba-fb0c-4703-a6e2-5ed22d35fd0a",
    iconSet: "FontAwesome5",
    iconName: "creative-commons-by"
  },
  {
    uuid: "cc3111bc-23fc-4ada-aa08-bdb7dc2690c8",
    iconSet: "FontAwesome5",
    iconName: "creative-commons-nc"
  },
  {
    uuid: "a71c6692-5d95-48d9-8b38-3fe08687c8d9",
    iconSet: "FontAwesome5",
    iconName: "creative-commons-nc-eu"
  },
  {
    uuid: "2a03be2e-f776-4841-9e8e-1b2e33c044c4",
    iconSet: "FontAwesome5",
    iconName: "creative-commons-nc-jp"
  },
  {
    uuid: "36249b1e-de00-462d-84eb-6811c334e1dd",
    iconSet: "FontAwesome5",
    iconName: "creative-commons-nd"
  },
  {
    uuid: "37587706-cc39-4e95-8167-6a4ba30ec5dc",
    iconSet: "FontAwesome5",
    iconName: "creative-commons-pd"
  },
  {
    uuid: "a19419bc-9578-41de-8931-88e97e617049",
    iconSet: "FontAwesome5",
    iconName: "creative-commons-pd-alt"
  },
  {
    uuid: "3c11a671-5157-478d-9396-317357f18212",
    iconSet: "FontAwesome5",
    iconName: "creative-commons-remix"
  },
  {
    uuid: "fb0a125a-5815-4209-9919-dd5d3afcc57b",
    iconSet: "FontAwesome5",
    iconName: "creative-commons-sa"
  },
  {
    uuid: "83fe7d79-c2c0-4599-b7ef-6b1561e30696",
    iconSet: "FontAwesome5",
    iconName: "creative-commons-sampling"
  },
  {
    uuid: "627ea111-5957-4001-aba1-0b3631ba5dda",
    iconSet: "FontAwesome5",
    iconName: "creative-commons-sampling-plus"
  },
  {
    uuid: "b3dbe3c7-d3dc-4bb4-aa80-01bbb63c5831",
    iconSet: "FontAwesome5",
    iconName: "creative-commons-share"
  },
  {
    uuid: "20b01ad6-fa0c-4188-9ca6-7c5f66f8be66",
    iconSet: "FontAwesome5",
    iconName: "creative-commons-zero"
  },
  {
    uuid: "a0b89b26-ef38-4a96-a6a9-52a78e1e128a",
    iconSet: "FontAwesome5",
    iconName: "credit-card"
  },
  {
    uuid: "e3a1ce1f-6988-40bd-915b-a3dc9a77065e",
    iconSet: "FontAwesome5",
    iconName: "critical-role"
  },
  {
    uuid: "a041a1dc-74a3-4a1c-a624-d41d73702b4f",
    iconSet: "FontAwesome5",
    iconName: "crop"
  },
  {
    uuid: "ef7962ef-d1ee-44be-aecc-a01b953dd2d3",
    iconSet: "FontAwesome5",
    iconName: "crop-alt"
  },
  {
    uuid: "e03af4b9-8bca-407e-aba0-dd8bdfd6979b",
    iconSet: "FontAwesome5",
    iconName: "cross"
  },
  {
    uuid: "73a1f64f-8908-42f3-8f48-9f5108f8b674",
    iconSet: "FontAwesome5",
    iconName: "crosshairs"
  },
  {
    uuid: "fa83b0ec-7f1b-49d3-a2fa-d5977a475c1e",
    iconSet: "FontAwesome5",
    iconName: "crow"
  },
  {
    uuid: "a1e57950-862b-4208-b4b8-3dda4e595cc7",
    iconSet: "FontAwesome5",
    iconName: "crown"
  },
  {
    uuid: "979850a7-0393-4a6a-8cec-85a7d0bd8d44",
    iconSet: "FontAwesome5",
    iconName: "crutch"
  },
  {
    uuid: "07181d88-50f9-40c0-9f14-34cfbf7c9950",
    iconSet: "FontAwesome5",
    iconName: "css3"
  },
  {
    uuid: "a3c0b2b8-3d0f-4a3d-832e-7d95d25a27a9",
    iconSet: "FontAwesome5",
    iconName: "css3-alt"
  },
  {
    uuid: "8aaa5681-3afc-4e24-8480-394965c243f0",
    iconSet: "FontAwesome5",
    iconName: "cube"
  },
  {
    uuid: "37a3fc96-dec8-42e2-b842-6e1cb954cc9d",
    iconSet: "FontAwesome5",
    iconName: "cubes"
  },
  {
    uuid: "47a4c1c0-2580-483c-8c13-9463963dc9e4",
    iconSet: "FontAwesome5",
    iconName: "cut"
  },
  {
    uuid: "0648b80b-1ee5-4856-895e-fd90f6c0c6b7",
    iconSet: "FontAwesome5",
    iconName: "cuttlefish"
  },
  {
    uuid: "668327a5-a29c-4458-9639-000ae30c5295",
    iconSet: "FontAwesome5",
    iconName: "d-and-d"
  },
  {
    uuid: "185d0fa9-ae39-42ed-98a0-545543616683",
    iconSet: "FontAwesome5",
    iconName: "d-and-d-beyond"
  },
  {
    uuid: "7c03799b-4bd8-4e6d-b852-f728c16734f6",
    iconSet: "FontAwesome5",
    iconName: "dailymotion"
  },
  {
    uuid: "cd97970b-fe74-45eb-b7c3-f4e6f12183af",
    iconSet: "FontAwesome5",
    iconName: "dashcube"
  },
  {
    uuid: "05f21157-a438-4e76-bd53-dba942ce2d6a",
    iconSet: "FontAwesome5",
    iconName: "database"
  },
  {
    uuid: "a33f97ae-b36c-485e-8569-4e670a695a16",
    iconSet: "FontAwesome5",
    iconName: "deaf"
  },
  {
    uuid: "940f5c3a-f784-4ae5-8452-a62dcd2e0e1e",
    iconSet: "FontAwesome5",
    iconName: "delicious"
  },
  {
    uuid: "ab264f4b-b4fc-409d-ac56-00ea645ad1f5",
    iconSet: "FontAwesome5",
    iconName: "democrat"
  },
  {
    uuid: "b0a9b7c3-e403-46e8-8a94-15d59e2def36",
    iconSet: "FontAwesome5",
    iconName: "deploydog"
  },
  {
    uuid: "be6b4f45-be46-4d96-bb27-aa4558629b56",
    iconSet: "FontAwesome5",
    iconName: "deskpro"
  },
  {
    uuid: "2ee79412-54c1-4fc0-9abd-c4a3f340b0ab",
    iconSet: "FontAwesome5",
    iconName: "desktop"
  },
  {
    uuid: "38aa227a-48ec-44c3-8da3-cb01f16a9bd9",
    iconSet: "FontAwesome5",
    iconName: "dev"
  },
  {
    uuid: "1c19260a-366e-495b-87a5-2c94c00a8729",
    iconSet: "FontAwesome5",
    iconName: "deviantart"
  },
  {
    uuid: "f97ab586-0762-4a0b-9424-c51c19009a78",
    iconSet: "FontAwesome5",
    iconName: "dharmachakra"
  },
  {
    uuid: "a8b43105-c2da-4ebd-87cb-25eb07f6258f",
    iconSet: "FontAwesome5",
    iconName: "dhl"
  },
  {
    uuid: "3694b26f-f77e-4de8-a591-ba9ff824fd6e",
    iconSet: "FontAwesome5",
    iconName: "diagnoses"
  },
  {
    uuid: "8e47d9b8-9f07-43ab-9329-837db49453f8",
    iconSet: "FontAwesome5",
    iconName: "diaspora"
  },
  {
    uuid: "7188f5cc-dad9-447b-b990-8a2875748cd7",
    iconSet: "FontAwesome5",
    iconName: "dice"
  },
  {
    uuid: "d735c8ea-9c6d-46b5-aad9-52dcefb91cbf",
    iconSet: "FontAwesome5",
    iconName: "dice-d20"
  },
  {
    uuid: "cc66f14c-ba45-4f70-b35b-0e5334c33716",
    iconSet: "FontAwesome5",
    iconName: "dice-d6"
  },
  {
    uuid: "1ece6ac2-6cf1-4009-a51b-3b48a4d31e5f",
    iconSet: "FontAwesome5",
    iconName: "dice-five"
  },
  {
    uuid: "c64fa5ca-c7a1-4fcb-b967-41c5a2e63f48",
    iconSet: "FontAwesome5",
    iconName: "dice-four"
  },
  {
    uuid: "57ccc2eb-fc58-421f-bd5a-26eeba5758ae",
    iconSet: "FontAwesome5",
    iconName: "dice-one"
  },
  {
    uuid: "31c4003b-4cdf-4a5a-aac1-8a91ac8c49f5",
    iconSet: "FontAwesome5",
    iconName: "dice-six"
  },
  {
    uuid: "25414671-05e7-445b-a689-bb53db3d84a0",
    iconSet: "FontAwesome5",
    iconName: "dice-three"
  },
  {
    uuid: "7f4a3a50-4b3a-426a-8b1a-eaeb25e4852a",
    iconSet: "FontAwesome5",
    iconName: "dice-two"
  },
  {
    uuid: "fa4e386b-a487-4b8c-a1f5-3d81c958e5fe",
    iconSet: "FontAwesome5",
    iconName: "digg"
  },
  {
    uuid: "abb6d59d-ba0c-4f62-a1ef-f0b679fdb6da",
    iconSet: "FontAwesome5",
    iconName: "digital-ocean"
  },
  {
    uuid: "97d9b309-f8ba-46d9-b7c3-01a19f8dac90",
    iconSet: "FontAwesome5",
    iconName: "digital-tachograph"
  },
  {
    uuid: "e1620101-d83b-4ebc-bef1-8c37d3a769cb",
    iconSet: "FontAwesome5",
    iconName: "directions"
  },
  {
    uuid: "d1184385-457f-45a0-9cc1-ddda5644287c",
    iconSet: "FontAwesome5",
    iconName: "discord"
  },
  {
    uuid: "099f9c62-3a99-4ca8-8ed4-9b63c99b58db",
    iconSet: "FontAwesome5",
    iconName: "discourse"
  },
  {
    uuid: "716ea0cf-0842-4e3b-ad84-6460fbbb97a5",
    iconSet: "FontAwesome5",
    iconName: "disease"
  },
  {
    uuid: "dff9f51d-486d-469e-acf0-8e7d9fa27201",
    iconSet: "FontAwesome5",
    iconName: "divide"
  },
  {
    uuid: "fe028adf-907a-403e-8cd9-6081f657f36d",
    iconSet: "FontAwesome5",
    iconName: "dizzy"
  },
  {
    uuid: "863458ce-5f2e-47bc-9819-4d53653d646f",
    iconSet: "FontAwesome5",
    iconName: "dna"
  },
  {
    uuid: "f3cabb32-2956-4295-9729-661a0df27586",
    iconSet: "FontAwesome5",
    iconName: "dochub"
  },
  {
    uuid: "2a586170-d97c-4589-a568-bbccee78a210",
    iconSet: "FontAwesome5",
    iconName: "docker"
  },
  {
    uuid: "c384b397-ddb8-4186-8286-4522dae3acc5",
    iconSet: "FontAwesome5",
    iconName: "dog"
  },
  {
    uuid: "ef7c6c4d-ace1-4249-a201-d2354b5fb2d7",
    iconSet: "FontAwesome5",
    iconName: "dollar-sign"
  },
  {
    uuid: "f3f870cc-18f5-424f-a428-624fcc4f1389",
    iconSet: "FontAwesome5",
    iconName: "dolly"
  },
  {
    uuid: "07b9f9b6-7382-4bf0-bfa6-5918f10d26da",
    iconSet: "FontAwesome5",
    iconName: "dolly-flatbed"
  },
  {
    uuid: "e1190796-2f72-4b68-89e5-58e338095e43",
    iconSet: "FontAwesome5",
    iconName: "donate"
  },
  {
    uuid: "ef725c1f-e957-425e-bde2-662bbc4eef96",
    iconSet: "FontAwesome5",
    iconName: "door-closed"
  },
  {
    uuid: "b1dfc59b-9df3-4449-8f25-f00fb59202e5",
    iconSet: "FontAwesome5",
    iconName: "door-open"
  },
  {
    uuid: "38a65058-8fea-4e66-b4f6-6659681c72cc",
    iconSet: "FontAwesome5",
    iconName: "dot-circle"
  },
  {
    uuid: "e243e81a-29ec-4395-b4da-6d9be04a987c",
    iconSet: "FontAwesome5",
    iconName: "dove"
  },
  {
    uuid: "8422a481-4a6e-447e-bcdd-72cb84509325",
    iconSet: "FontAwesome5",
    iconName: "download"
  },
  {
    uuid: "4cdd21de-3a49-42a8-8be9-25abd780b4ac",
    iconSet: "FontAwesome5",
    iconName: "draft2digital"
  },
  {
    uuid: "bf59bece-b214-454c-9c77-58631f59817b",
    iconSet: "FontAwesome5",
    iconName: "drafting-compass"
  },
  {
    uuid: "65700c1e-799c-4a38-881b-882a22851cee",
    iconSet: "FontAwesome5",
    iconName: "dragon"
  },
  {
    uuid: "8d479bd0-e638-4505-a616-64f2cb5a7840",
    iconSet: "FontAwesome5",
    iconName: "draw-polygon"
  },
  {
    uuid: "97e1cdc2-1c33-423b-a929-362e3d6175dc",
    iconSet: "FontAwesome5",
    iconName: "dribbble"
  },
  {
    uuid: "ae47f9b2-b5e9-4a66-97f8-3c79bef5c231",
    iconSet: "FontAwesome5",
    iconName: "dribbble-square"
  },
  {
    uuid: "ae70b3fe-b0c5-4954-a23c-49ccab310cbd",
    iconSet: "FontAwesome5",
    iconName: "dropbox"
  },
  {
    uuid: "8b000d22-c7f9-4bc3-878d-d4c1787ede25",
    iconSet: "FontAwesome5",
    iconName: "drum"
  },
  {
    uuid: "5865833a-af71-4219-ac5e-a04195d61d99",
    iconSet: "FontAwesome5",
    iconName: "drum-steelpan"
  },
  {
    uuid: "37c5ae5d-47dc-4078-9157-968543876b2a",
    iconSet: "FontAwesome5",
    iconName: "drumstick-bite"
  },
  {
    uuid: "cd008d6f-7795-4d77-b704-1c82699c2b13",
    iconSet: "FontAwesome5",
    iconName: "drupal"
  },
  {
    uuid: "4374d6de-155f-4597-99f0-4c48e7c9bec1",
    iconSet: "FontAwesome5",
    iconName: "dumbbell"
  },
  {
    uuid: "9ec535f1-7870-477f-bafb-02b95667a815",
    iconSet: "FontAwesome5",
    iconName: "dumpster"
  },
  {
    uuid: "97cd6967-88db-4564-92f8-93525afcb007",
    iconSet: "FontAwesome5",
    iconName: "dumpster-fire"
  },
  {
    uuid: "69ca37eb-e59e-48c7-872a-3da1ae5eefa0",
    iconSet: "FontAwesome5",
    iconName: "dungeon"
  },
  {
    uuid: "77da2b8f-3c31-49e1-b917-757b3f4a8927",
    iconSet: "FontAwesome5",
    iconName: "dyalog"
  },
  {
    uuid: "fd407492-b0e8-4673-a651-0fb6f2860099",
    iconSet: "FontAwesome5",
    iconName: "earlybirds"
  },
  {
    uuid: "886b3ccc-02f6-4fd2-9db7-5daeafa71100",
    iconSet: "FontAwesome5",
    iconName: "ebay"
  },
  {
    uuid: "0ef8cc02-07f3-4e07-856f-d441a74b3243",
    iconSet: "FontAwesome5",
    iconName: "edge"
  },
  {
    uuid: "b082ea27-e1a8-472d-ba32-513434a3bc0a",
    iconSet: "FontAwesome5",
    iconName: "edit"
  },
  {
    uuid: "5f47ce21-d90e-4d0f-9bbf-7dbbd30df952",
    iconSet: "FontAwesome5",
    iconName: "egg"
  },
  {
    uuid: "65ba40f9-48a4-4c56-9405-ee8945d275c2",
    iconSet: "FontAwesome5",
    iconName: "eject"
  },
  {
    uuid: "f937def3-c900-4de4-9d5f-0814db9b1b0a",
    iconSet: "FontAwesome5",
    iconName: "elementor"
  },
  {
    uuid: "cf4c2478-9614-4b25-834c-1d1c054ae4d7",
    iconSet: "FontAwesome5",
    iconName: "ellipsis-h"
  },
  {
    uuid: "08d76d66-def4-409b-a2b0-615e139051d7",
    iconSet: "FontAwesome5",
    iconName: "ellipsis-v"
  },
  {
    uuid: "4964bb82-d357-42d5-ad6c-0a39f917505d",
    iconSet: "FontAwesome5",
    iconName: "ello"
  },
  {
    uuid: "fa8a7d86-9908-4591-b683-3ccf77ec3c5e",
    iconSet: "FontAwesome5",
    iconName: "ember"
  },
  {
    uuid: "106b7144-ddc0-4d5e-8cbd-a775603999cd",
    iconSet: "FontAwesome5",
    iconName: "empire"
  },
  {
    uuid: "ae69b5a2-e5e3-4c26-a48f-225ceb37626a",
    iconSet: "FontAwesome5",
    iconName: "envelope"
  },
  {
    uuid: "04ddafc7-616f-4ae2-b9b5-69d51266d8f7",
    iconSet: "FontAwesome5",
    iconName: "envelope-open"
  },
  {
    uuid: "8fcae17f-b625-4cf2-a85e-c5d240a3b47f",
    iconSet: "FontAwesome5",
    iconName: "envelope-open-text"
  },
  {
    uuid: "ee891731-6e70-403b-a50e-de7b6f466587",
    iconSet: "FontAwesome5",
    iconName: "envelope-square"
  },
  {
    uuid: "ecb61a37-aa10-403d-815b-bbdbb6ebc282",
    iconSet: "FontAwesome5",
    iconName: "envira"
  },
  {
    uuid: "b42d272a-c9f2-4e69-9f98-4be74614f6b6",
    iconSet: "FontAwesome5",
    iconName: "equals"
  },
  {
    uuid: "b598b989-9b75-4273-8e80-b7d1946e0b2d",
    iconSet: "FontAwesome5",
    iconName: "eraser"
  },
  {
    uuid: "42d3996c-8ceb-48f0-9b52-a65d2b78753b",
    iconSet: "FontAwesome5",
    iconName: "erlang"
  },
  {
    uuid: "ee2e9535-2549-4840-a63d-ebee31c55d79",
    iconSet: "FontAwesome5",
    iconName: "ethereum"
  },
  {
    uuid: "2b8f1f81-9b43-47ed-a7a8-34ab4658e817",
    iconSet: "FontAwesome5",
    iconName: "ethernet"
  },
  {
    uuid: "956fbc7f-a5c6-46f7-a393-9601fa73c421",
    iconSet: "FontAwesome5",
    iconName: "etsy"
  },
  {
    uuid: "ce3a862b-e1ac-4122-ab3d-bc3f383593dc",
    iconSet: "FontAwesome5",
    iconName: "euro-sign"
  },
  {
    uuid: "0421de2b-41a9-4d2d-9773-888f6e33e745",
    iconSet: "FontAwesome5",
    iconName: "evernote"
  },
  {
    uuid: "55aaef47-ec4e-4d0f-8d0a-1b316142e766",
    iconSet: "FontAwesome5",
    iconName: "exchange-alt"
  },
  {
    uuid: "f5f0e8d7-07ae-48f0-bcc6-b7b4ada79286",
    iconSet: "FontAwesome5",
    iconName: "exclamation"
  },
  {
    uuid: "30ddcc26-c64b-4331-a8a4-135abbfe78b5",
    iconSet: "FontAwesome5",
    iconName: "exclamation-circle"
  },
  {
    uuid: "4e691848-09a1-4b0f-9a53-8bcc74d8765e",
    iconSet: "FontAwesome5",
    iconName: "exclamation-triangle"
  },
  {
    uuid: "636d3c44-8f68-4f74-8ddf-34eec3b1e6cf",
    iconSet: "FontAwesome5",
    iconName: "expand"
  },
  {
    uuid: "0be52374-ae43-4061-a659-2b6a229f6339",
    iconSet: "FontAwesome5",
    iconName: "expand-alt"
  },
  {
    uuid: "238a48dc-3816-4e23-9f12-57249ba23bcd",
    iconSet: "FontAwesome5",
    iconName: "expand-arrows-alt"
  },
  {
    uuid: "38d1209a-081f-4661-92f7-c476ff3e2796",
    iconSet: "FontAwesome5",
    iconName: "expeditedssl"
  },
  {
    uuid: "d474f482-85d0-426c-8245-d84b16ab4e13",
    iconSet: "FontAwesome5",
    iconName: "external-link-alt"
  },
  {
    uuid: "0c343ac0-13ae-4ad3-a2d4-8a2f4d76345d",
    iconSet: "FontAwesome5",
    iconName: "external-link-square-alt"
  },
  {
    uuid: "ae26cf27-16ef-4385-81cf-a5d335885c13",
    iconSet: "FontAwesome5",
    iconName: "eye"
  },
  {
    uuid: "f6bb2b7f-f63d-45dc-9f8f-e2204a70bc5b",
    iconSet: "FontAwesome5",
    iconName: "eye-dropper"
  },
  {
    uuid: "49ebc20a-9a52-468a-884d-84d2b291b8f5",
    iconSet: "FontAwesome5",
    iconName: "eye-slash"
  },
  {
    uuid: "b91943fe-dee1-4e6e-9474-028affa74e75",
    iconSet: "FontAwesome5",
    iconName: "facebook"
  },
  {
    uuid: "66c92978-918f-4c7f-8317-0f236c673acc",
    iconSet: "FontAwesome5",
    iconName: "facebook-f"
  },
  {
    uuid: "4ecb22c6-b48b-49db-b9a5-9450e557943f",
    iconSet: "FontAwesome5",
    iconName: "facebook-messenger"
  },
  {
    uuid: "c04ec210-e4fd-4eb4-a51a-59e14fe4fdaa",
    iconSet: "FontAwesome5",
    iconName: "facebook-square"
  },
  {
    uuid: "11d24d0e-8220-44e9-a6ac-56511ad83b87",
    iconSet: "FontAwesome5",
    iconName: "fan"
  },
  {
    uuid: "df55ba62-a233-40d5-acbd-2e941b6d7afa",
    iconSet: "FontAwesome5",
    iconName: "fantasy-flight-games"
  },
  {
    uuid: "a0cc44eb-7579-4eee-a3d7-68f713367787",
    iconSet: "FontAwesome5",
    iconName: "fast-backward"
  },
  {
    uuid: "d06a514c-0d46-43ff-b1bf-06dbd1b19f06",
    iconSet: "FontAwesome5",
    iconName: "fast-forward"
  },
  {
    uuid: "58f9c79e-d06f-42d6-8be4-c15d0ee78c6d",
    iconSet: "FontAwesome5",
    iconName: "faucet"
  },
  {
    uuid: "42cd2c96-584b-4127-af9e-8856ea8e427b",
    iconSet: "FontAwesome5",
    iconName: "fax"
  },
  {
    uuid: "fd390b83-969a-4cc0-a98e-23297d77e903",
    iconSet: "FontAwesome5",
    iconName: "feather"
  },
  {
    uuid: "a305056b-fa3a-404e-9cf1-a597215da953",
    iconSet: "FontAwesome5",
    iconName: "feather-alt"
  },
  {
    uuid: "799972a4-5eac-4c0c-967f-e137f61c9f62",
    iconSet: "FontAwesome5",
    iconName: "fedex"
  },
  {
    uuid: "4fcfcef6-71ed-4fd0-b8c0-d6ee43e71e41",
    iconSet: "FontAwesome5",
    iconName: "fedora"
  },
  {
    uuid: "fd7e3f22-ac41-405f-8814-995f35724742",
    iconSet: "FontAwesome5",
    iconName: "female"
  },
  {
    uuid: "19fef542-2357-4b12-9aca-0883e580882a",
    iconSet: "FontAwesome5",
    iconName: "fighter-jet"
  },
  {
    uuid: "52ad0453-df3c-4c7c-8051-4a6bee8977ef",
    iconSet: "FontAwesome5",
    iconName: "figma"
  },
  {
    uuid: "df47a4d4-f1a2-4e08-9414-ae6b54ba3466",
    iconSet: "FontAwesome5",
    iconName: "file"
  },
  {
    uuid: "9b5c281c-aaf0-42bb-9c0b-3d753ef78844",
    iconSet: "FontAwesome5",
    iconName: "file-alt"
  },
  {
    uuid: "f535e012-be07-47db-a21f-923011c1ce08",
    iconSet: "FontAwesome5",
    iconName: "file-archive"
  },
  {
    uuid: "c7eef786-13b9-4f34-a18a-90d1cfe598d5",
    iconSet: "FontAwesome5",
    iconName: "file-audio"
  },
  {
    uuid: "ba89f0ec-c306-4f4e-9ff1-7333cad53a99",
    iconSet: "FontAwesome5",
    iconName: "file-code"
  },
  {
    uuid: "37415364-4173-46ca-a799-2682b93ee43d",
    iconSet: "FontAwesome5",
    iconName: "file-contract"
  },
  {
    uuid: "7c5cacd3-fc1e-4d3b-bc55-f45429fec84d",
    iconSet: "FontAwesome5",
    iconName: "file-csv"
  },
  {
    uuid: "ce6faaca-5d03-4877-86ec-a955f213f531",
    iconSet: "FontAwesome5",
    iconName: "file-download"
  },
  {
    uuid: "19f0a181-6bc7-4a9d-9ff9-fdde001fca0c",
    iconSet: "FontAwesome5",
    iconName: "file-excel"
  },
  {
    uuid: "f71986f7-08fb-47b5-9cf9-5f720b43bd84",
    iconSet: "FontAwesome5",
    iconName: "file-export"
  },
  {
    uuid: "1aa28ae2-11d5-4401-a861-d067a94a983c",
    iconSet: "FontAwesome5",
    iconName: "file-image"
  },
  {
    uuid: "7d1d0661-c677-4414-a038-f39a2cd61c1e",
    iconSet: "FontAwesome5",
    iconName: "file-import"
  },
  {
    uuid: "0d6f7457-ae54-4959-bf0e-ecfdb903afc1",
    iconSet: "FontAwesome5",
    iconName: "file-invoice"
  },
  {
    uuid: "ee0ebf28-e346-48a0-b290-c092269ae2c5",
    iconSet: "FontAwesome5",
    iconName: "file-invoice-dollar"
  },
  {
    uuid: "3b2e062c-776e-4928-bfb6-877fbb1057e1",
    iconSet: "FontAwesome5",
    iconName: "file-medical"
  },
  {
    uuid: "13af96c7-0603-4b89-b0ab-ca9e52d28c4f",
    iconSet: "FontAwesome5",
    iconName: "file-medical-alt"
  },
  {
    uuid: "1e807ecb-1909-421b-a1fb-cc227aac3ac1",
    iconSet: "FontAwesome5",
    iconName: "file-pdf"
  },
  {
    uuid: "e89b3d92-7be5-405d-bf38-3e9afd9fd167",
    iconSet: "FontAwesome5",
    iconName: "file-powerpoint"
  },
  {
    uuid: "e5999d47-922f-4e3c-84ac-82943145a814",
    iconSet: "FontAwesome5",
    iconName: "file-prescription"
  },
  {
    uuid: "9fc3f2c3-2617-42f5-8130-311ae0f514f0",
    iconSet: "FontAwesome5",
    iconName: "file-signature"
  },
  {
    uuid: "75f409b2-5e86-4d9e-b7a4-7c1737d6e4d2",
    iconSet: "FontAwesome5",
    iconName: "file-upload"
  },
  {
    uuid: "4164a04d-55ec-4922-85ec-a1fc7bd39b6e",
    iconSet: "FontAwesome5",
    iconName: "file-video"
  },
  {
    uuid: "8861b4e4-c932-4053-87ff-bcd78cddd3ba",
    iconSet: "FontAwesome5",
    iconName: "file-word"
  },
  {
    uuid: "7e2cf93b-2019-458f-a93e-18f7fe0798c3",
    iconSet: "FontAwesome5",
    iconName: "fill"
  },
  {
    uuid: "ff54e517-5b12-4f5d-848d-34b5cee36d71",
    iconSet: "FontAwesome5",
    iconName: "fill-drip"
  },
  {
    uuid: "ec2b0898-062c-4a46-b454-442baff6582b",
    iconSet: "FontAwesome5",
    iconName: "film"
  },
  {
    uuid: "176cfd93-3d04-402d-b391-ee27d88dca52",
    iconSet: "FontAwesome5",
    iconName: "filter"
  },
  {
    uuid: "46862d0d-2697-4c70-8f96-91537aca218f",
    iconSet: "FontAwesome5",
    iconName: "fingerprint"
  },
  {
    uuid: "844f41cb-597f-4b8c-a8f6-965fde35aa39",
    iconSet: "FontAwesome5",
    iconName: "fire"
  },
  {
    uuid: "edf11cfa-6c01-4fe9-bda0-385195d03de9",
    iconSet: "FontAwesome5",
    iconName: "fire-alt"
  },
  {
    uuid: "a2e387a0-ea30-4b28-888c-d3b261266387",
    iconSet: "FontAwesome5",
    iconName: "fire-extinguisher"
  },
  {
    uuid: "01ad6fd2-df8b-43c8-ac52-227c6762dae4",
    iconSet: "FontAwesome5",
    iconName: "firefox"
  },
  {
    uuid: "7ca4c4b3-ed02-46a3-b8f8-0f6a7b6d5c9a",
    iconSet: "FontAwesome5",
    iconName: "firefox-browser"
  },
  {
    uuid: "838793ca-4986-4d30-93ba-b34a7d1e84fb",
    iconSet: "FontAwesome5",
    iconName: "first-aid"
  },
  {
    uuid: "3a090f11-47a0-4a1a-a065-9fc16da23cf3",
    iconSet: "FontAwesome5",
    iconName: "first-order"
  },
  {
    uuid: "f6c228b8-4334-4d65-b048-52df53e7da16",
    iconSet: "FontAwesome5",
    iconName: "first-order-alt"
  },
  {
    uuid: "c6e34468-6e6e-4dde-80a4-04620425a1ee",
    iconSet: "FontAwesome5",
    iconName: "firstdraft"
  },
  {
    uuid: "a4f60a6b-06e2-4413-89b9-51d448e3ad01",
    iconSet: "FontAwesome5",
    iconName: "fish"
  },
  {
    uuid: "6c0d365c-35d0-4241-80a7-d949e5b884a0",
    iconSet: "FontAwesome5",
    iconName: "fist-raised"
  },
  {
    uuid: "b60ccc1f-cd5e-48a8-8ebf-cbec7c90489a",
    iconSet: "FontAwesome5",
    iconName: "flag"
  },
  {
    uuid: "eca192ad-23b3-416a-876d-1109adf64242",
    iconSet: "FontAwesome5",
    iconName: "flag-checkered"
  },
  {
    uuid: "9918e9cf-73ce-4abb-a9eb-47821e1cc5f2",
    iconSet: "FontAwesome5",
    iconName: "flag-usa"
  },
  {
    uuid: "733bdfde-ae3e-4fd0-bc54-27cea4cad505",
    iconSet: "FontAwesome5",
    iconName: "flask"
  },
  {
    uuid: "20636f75-7e24-4282-8f75-65d79aa1b89e",
    iconSet: "FontAwesome5",
    iconName: "flickr"
  },
  {
    uuid: "59c8a521-2dbe-439a-a532-9a16a5dbe96f",
    iconSet: "FontAwesome5",
    iconName: "flipboard"
  },
  {
    uuid: "a1a5c0ec-b5ac-47e5-8e5d-e851a325695b",
    iconSet: "FontAwesome5",
    iconName: "flushed"
  },
  {
    uuid: "2c40481f-4bd4-48f2-8127-e5a204fee9a3",
    iconSet: "FontAwesome5",
    iconName: "fly"
  },
  {
    uuid: "1ca50ae1-5374-4c7e-9d16-86e4f7ff1260",
    iconSet: "FontAwesome5",
    iconName: "folder"
  },
  {
    uuid: "c73d419e-941d-4117-885c-353803c1269e",
    iconSet: "FontAwesome5",
    iconName: "folder-minus"
  },
  {
    uuid: "2b44ee61-91b1-4ef3-bf70-5c468bf4c1ad",
    iconSet: "FontAwesome5",
    iconName: "folder-open"
  },
  {
    uuid: "a08ba503-309a-4fc0-8873-f643cb54a546",
    iconSet: "FontAwesome5",
    iconName: "folder-plus"
  },
  {
    uuid: "38f039b8-f6e7-41ff-9a74-2956e2527b52",
    iconSet: "FontAwesome5",
    iconName: "font"
  },
  {
    uuid: "957bc484-cc5e-4678-af75-2fd64f17a1b2",
    iconSet: "FontAwesome5",
    iconName: "font-awesome"
  },
  {
    uuid: "cdd89e23-67e0-47ca-b045-0c46f3206e44",
    iconSet: "FontAwesome5",
    iconName: "font-awesome-alt"
  },
  {
    uuid: "c9d8c09c-8adb-4ada-8b02-d54800b925dc",
    iconSet: "FontAwesome5",
    iconName: "font-awesome-flag"
  },
  {
    uuid: "9a2f467a-2472-42cd-87ab-f4048eb15f21",
    iconSet: "FontAwesome5",
    iconName: "font-awesome-logo-full"
  },
  {
    uuid: "3463e50f-f0cd-41e2-b1d1-d379c2542f6c",
    iconSet: "FontAwesome5",
    iconName: "fonticons"
  },
  {
    uuid: "8f4e5451-4b8a-4fd2-9a6e-47c9240cba46",
    iconSet: "FontAwesome5",
    iconName: "fonticons-fi"
  },
  {
    uuid: "a86ea1fc-a54c-46fe-8c81-8772715920ef",
    iconSet: "FontAwesome5",
    iconName: "football-ball"
  },
  {
    uuid: "1ca2f90a-f092-4c26-957f-f0defa6b8cc0",
    iconSet: "FontAwesome5",
    iconName: "fort-awesome"
  },
  {
    uuid: "bb2a7714-624d-4823-94bf-17c34e0a4951",
    iconSet: "FontAwesome5",
    iconName: "fort-awesome-alt"
  },
  {
    uuid: "e56a3e9d-7d91-495d-b733-6e74697f7eba",
    iconSet: "FontAwesome5",
    iconName: "forumbee"
  },
  {
    uuid: "e1b3046e-0820-443c-af4f-3136619b3802",
    iconSet: "FontAwesome5",
    iconName: "forward"
  },
  {
    uuid: "bbad2f2b-f006-4190-98fe-15a8baa57efe",
    iconSet: "FontAwesome5",
    iconName: "foursquare"
  },
  {
    uuid: "4580708c-7e02-482f-b000-2fc66abba481",
    iconSet: "FontAwesome5",
    iconName: "free-code-camp"
  },
  {
    uuid: "b83b518b-33e1-46b5-bfb2-b81eefa3e18f",
    iconSet: "FontAwesome5",
    iconName: "freebsd"
  },
  {
    uuid: "64c9f827-07dd-4dc1-a8e8-d2fdc73239a7",
    iconSet: "FontAwesome5",
    iconName: "frog"
  },
  {
    uuid: "0ec8815e-a59c-45af-98df-d73df56ac304",
    iconSet: "FontAwesome5",
    iconName: "frown"
  },
  {
    uuid: "34dfca81-079e-4cc7-9e8c-b0771134472f",
    iconSet: "FontAwesome5",
    iconName: "frown-open"
  },
  {
    uuid: "c8777071-81b3-4c9e-a6de-3e398f71a9f3",
    iconSet: "FontAwesome5",
    iconName: "fulcrum"
  },
  {
    uuid: "9ab63d99-f4ee-4b39-adbc-59a77c186f63",
    iconSet: "FontAwesome5",
    iconName: "funnel-dollar"
  },
  {
    uuid: "2eedb7fb-13c8-432d-8828-31fe9dd3ed82",
    iconSet: "FontAwesome5",
    iconName: "futbol"
  },
  {
    uuid: "1d321568-f962-4ca1-bdf8-54f0e03ab071",
    iconSet: "FontAwesome5",
    iconName: "galactic-republic"
  },
  {
    uuid: "c049c0a4-a33f-46df-998f-7aefa8b4c38e",
    iconSet: "FontAwesome5",
    iconName: "galactic-senate"
  },
  {
    uuid: "88bbb9f2-af8c-4ca8-b8a4-d58b319baa68",
    iconSet: "FontAwesome5",
    iconName: "gamepad"
  },
  {
    uuid: "dce0fb0c-3c2c-4c63-b5a4-7d1857180d5c",
    iconSet: "FontAwesome5",
    iconName: "gas-pump"
  },
  {
    uuid: "267cc95f-5a99-4993-9ec0-29cb3427c232",
    iconSet: "FontAwesome5",
    iconName: "gavel"
  },
  {
    uuid: "e555d221-3ebc-44d5-ac10-4a4854744c5f",
    iconSet: "FontAwesome5",
    iconName: "gem"
  },
  {
    uuid: "66794a80-b53a-4bb1-81c0-04e8c37b7b09",
    iconSet: "FontAwesome5",
    iconName: "genderless"
  },
  {
    uuid: "834738bb-5772-44e3-be5e-a94acfed0d7c",
    iconSet: "FontAwesome5",
    iconName: "get-pocket"
  },
  {
    uuid: "c35d851f-47dc-44c2-97f2-b2d9057c241d",
    iconSet: "FontAwesome5",
    iconName: "gg"
  },
  {
    uuid: "41212315-6c45-43b9-aabc-5c64ad5bc028",
    iconSet: "FontAwesome5",
    iconName: "gg-circle"
  },
  {
    uuid: "c005b8d4-56ee-405b-b411-8b09fe33d12b",
    iconSet: "FontAwesome5",
    iconName: "ghost"
  },
  {
    uuid: "318dc277-4e3c-45bd-8351-3b96ca9ed2f1",
    iconSet: "FontAwesome5",
    iconName: "gift"
  },
  {
    uuid: "1e3e5a29-57d0-4582-982e-bd7279783f28",
    iconSet: "FontAwesome5",
    iconName: "gifts"
  },
  {
    uuid: "6d87dc85-d0c5-4b20-9794-689fb3897dd4",
    iconSet: "FontAwesome5",
    iconName: "git"
  },
  {
    uuid: "ae690621-951d-4393-b359-37b97d9f0a25",
    iconSet: "FontAwesome5",
    iconName: "git-alt"
  },
  {
    uuid: "0f5b7171-4243-4f78-a5fc-24cf52c0daae",
    iconSet: "FontAwesome5",
    iconName: "git-square"
  },
  {
    uuid: "98f0c281-8af6-4dbc-a3a2-6e91586ec80b",
    iconSet: "FontAwesome5",
    iconName: "github"
  },
  {
    uuid: "9050791e-b3bb-4a3d-b7c0-9cd33f5c5468",
    iconSet: "FontAwesome5",
    iconName: "github-alt"
  },
  {
    uuid: "7a51bedf-2362-408c-b87a-553c582c14b6",
    iconSet: "FontAwesome5",
    iconName: "github-square"
  },
  {
    uuid: "be25e30b-55be-43cc-a4d3-bdc41db41b43",
    iconSet: "FontAwesome5",
    iconName: "gitkraken"
  },
  {
    uuid: "e543fd62-f347-426f-b538-588256a0aa1c",
    iconSet: "FontAwesome5",
    iconName: "gitlab"
  },
  {
    uuid: "bd563b62-d690-4ce5-9588-a0ddd0b56dfc",
    iconSet: "FontAwesome5",
    iconName: "gitter"
  },
  {
    uuid: "c12560b4-f8ac-483e-992c-e03bf437217b",
    iconSet: "FontAwesome5",
    iconName: "glass-cheers"
  },
  {
    uuid: "67d2d06f-9d9a-4e34-8947-6920cd5245c3",
    iconSet: "FontAwesome5",
    iconName: "glass-martini"
  },
  {
    uuid: "cc29f71c-1978-4426-8292-98397f4f447a",
    iconSet: "FontAwesome5",
    iconName: "glass-martini-alt"
  },
  {
    uuid: "fb052153-a51d-4846-85f2-1382d6c0cc81",
    iconSet: "FontAwesome5",
    iconName: "glass-whiskey"
  },
  {
    uuid: "5060c01b-0ee4-4506-ac73-d2a85eab831e",
    iconSet: "FontAwesome5",
    iconName: "glasses"
  },
  {
    uuid: "374f9c31-0794-474b-82d9-921f3a02779f",
    iconSet: "FontAwesome5",
    iconName: "glide"
  },
  {
    uuid: "28c7415d-1e13-4e32-983a-9ad199d0d45b",
    iconSet: "FontAwesome5",
    iconName: "glide-g"
  },
  {
    uuid: "d1595f9b-864b-45af-bc90-7af9393315ce",
    iconSet: "FontAwesome5",
    iconName: "globe"
  },
  {
    uuid: "f8d72d44-0dad-425b-8b52-b7054072adc1",
    iconSet: "FontAwesome5",
    iconName: "globe-africa"
  },
  {
    uuid: "9d0503b6-7452-4db4-aa86-ca14e3135238",
    iconSet: "FontAwesome5",
    iconName: "globe-americas"
  },
  {
    uuid: "fa85c4f2-1d98-4e34-ab59-3843dcc0f954",
    iconSet: "FontAwesome5",
    iconName: "globe-asia"
  },
  {
    uuid: "198515d8-51c1-4b82-bc79-ea225923934c",
    iconSet: "FontAwesome5",
    iconName: "globe-europe"
  },
  {
    uuid: "49963864-9f4a-4418-b19c-b7bc9c021f58",
    iconSet: "FontAwesome5",
    iconName: "gofore"
  },
  {
    uuid: "dbc22a68-c622-4a36-942d-66b97db4bf54",
    iconSet: "FontAwesome5",
    iconName: "golf-ball"
  },
  {
    uuid: "098cb63c-9a8a-4aab-ad3e-0dc096ac8b3a",
    iconSet: "FontAwesome5",
    iconName: "goodreads"
  },
  {
    uuid: "3e60f84a-366f-4de0-9be0-c864b05ef393",
    iconSet: "FontAwesome5",
    iconName: "goodreads-g"
  },
  {
    uuid: "7789446f-2ad1-465d-a2c9-fdfa155a09d5",
    iconSet: "FontAwesome5",
    iconName: "google"
  },
  {
    uuid: "6b750285-8eb1-422a-8c0d-154fd9a57a94",
    iconSet: "FontAwesome5",
    iconName: "google-drive"
  },
  {
    uuid: "3c419507-13b6-4eed-8ee6-17a0e47c47c4",
    iconSet: "FontAwesome5",
    iconName: "google-play"
  },
  {
    uuid: "28b305ac-5522-477b-99f4-1ec7ec889f3d",
    iconSet: "FontAwesome5",
    iconName: "google-plus"
  },
  {
    uuid: "ed8d589c-cf48-44a0-9a06-3ba972b84864",
    iconSet: "FontAwesome5",
    iconName: "google-plus-g"
  },
  {
    uuid: "c9e6ac7d-e9e9-40d5-bdbd-54922b763231",
    iconSet: "FontAwesome5",
    iconName: "google-plus-square"
  },
  {
    uuid: "64130e87-9d3a-4a1b-8ec0-e5b7f9dfd51c",
    iconSet: "FontAwesome5",
    iconName: "google-wallet"
  },
  {
    uuid: "13fad2e0-2468-4244-aead-d0228670591f",
    iconSet: "FontAwesome5",
    iconName: "gopuram"
  },
  {
    uuid: "d60f5da6-a459-4916-b5d2-f9fb2e4190a2",
    iconSet: "FontAwesome5",
    iconName: "graduation-cap"
  },
  {
    uuid: "5e56e3d6-a2d7-4c2c-a06a-d51e6fc5c69d",
    iconSet: "FontAwesome5",
    iconName: "gratipay"
  },
  {
    uuid: "8908a347-8487-40cb-8a46-78579439a675",
    iconSet: "FontAwesome5",
    iconName: "grav"
  },
  {
    uuid: "da5892b4-8e9b-4a79-87d7-67adeb04792a",
    iconSet: "FontAwesome5",
    iconName: "greater-than"
  },
  {
    uuid: "47df49a6-6ca2-452a-bf36-46f5e35497b5",
    iconSet: "FontAwesome5",
    iconName: "greater-than-equal"
  },
  {
    uuid: "d526ea72-1b1e-45b1-a43b-13ac8a3386b5",
    iconSet: "FontAwesome5",
    iconName: "grimace"
  },
  {
    uuid: "60230ebd-d008-488e-b501-1df17db85718",
    iconSet: "FontAwesome5",
    iconName: "grin"
  },
  {
    uuid: "58d61dac-cb75-478d-bc74-83f92787d724",
    iconSet: "FontAwesome5",
    iconName: "grin-alt"
  },
  {
    uuid: "8626d3cf-c7ba-4827-a954-73c9b17f3579",
    iconSet: "FontAwesome5",
    iconName: "grin-beam"
  },
  {
    uuid: "bee190c9-5d6d-4a83-97b7-f136f2d429e4",
    iconSet: "FontAwesome5",
    iconName: "grin-beam-sweat"
  },
  {
    uuid: "fc747fbb-9f21-446d-af3d-fffad8608429",
    iconSet: "FontAwesome5",
    iconName: "grin-hearts"
  },
  {
    uuid: "736edcbb-c704-4491-86df-82fee42b813a",
    iconSet: "FontAwesome5",
    iconName: "grin-squint"
  },
  {
    uuid: "1ff1805f-0a7d-4d00-b5c1-60d862c73154",
    iconSet: "FontAwesome5",
    iconName: "grin-squint-tears"
  },
  {
    uuid: "fd6ef722-ec8b-4283-81dd-f5b42e52ba84",
    iconSet: "FontAwesome5",
    iconName: "grin-stars"
  },
  {
    uuid: "79f40051-6912-4bd3-af25-26b8f6ff9edd",
    iconSet: "FontAwesome5",
    iconName: "grin-tears"
  },
  {
    uuid: "e171a3c7-50c0-4dd8-88f8-d5fa2efe9af6",
    iconSet: "FontAwesome5",
    iconName: "grin-tongue"
  },
  {
    uuid: "078c8fdb-f35d-436c-b3a1-d15ee8f54cae",
    iconSet: "FontAwesome5",
    iconName: "grin-tongue-squint"
  },
  {
    uuid: "c35345ce-cb4c-4739-af4a-aa393aca33ab",
    iconSet: "FontAwesome5",
    iconName: "grin-tongue-wink"
  },
  {
    uuid: "f6c51c10-b13e-4b2a-8e76-8f6531854875",
    iconSet: "FontAwesome5",
    iconName: "grin-wink"
  },
  {
    uuid: "3ab53b43-178b-49db-b52c-087ffc1f2780",
    iconSet: "FontAwesome5",
    iconName: "grip-horizontal"
  },
  {
    uuid: "75900fc0-c326-40df-9966-5b17e3186e05",
    iconSet: "FontAwesome5",
    iconName: "grip-lines"
  },
  {
    uuid: "bd51a680-e620-4115-aa09-5f577a500f85",
    iconSet: "FontAwesome5",
    iconName: "grip-lines-vertical"
  },
  {
    uuid: "b2a135d3-aec7-4767-bb9a-3c37911e5871",
    iconSet: "FontAwesome5",
    iconName: "grip-vertical"
  },
  {
    uuid: "ab40751e-8047-40c8-b518-6b40cf4e8d8e",
    iconSet: "FontAwesome5",
    iconName: "gripfire"
  },
  {
    uuid: "a1e19610-9700-49b6-ac17-cc4d12f0e5cd",
    iconSet: "FontAwesome5",
    iconName: "grunt"
  },
  {
    uuid: "e5640734-5dc9-4346-913c-829d953ee64c",
    iconSet: "FontAwesome5",
    iconName: "guitar"
  },
  {
    uuid: "1513d74d-ba8c-494e-ae97-b6069f5f20c6",
    iconSet: "FontAwesome5",
    iconName: "gulp"
  },
  {
    uuid: "65a78a0b-4908-4a0c-898e-49422b583c5c",
    iconSet: "FontAwesome5",
    iconName: "h-square"
  },
  {
    uuid: "936dbdb1-b336-4824-8770-3b6cac765fe7",
    iconSet: "FontAwesome5",
    iconName: "hacker-news"
  },
  {
    uuid: "f4133fd6-cab2-4fd5-8df4-39fc0751abd2",
    iconSet: "FontAwesome5",
    iconName: "hacker-news-square"
  },
  {
    uuid: "ca6dd169-ff58-474f-9779-325fdc9b6275",
    iconSet: "FontAwesome5",
    iconName: "hackerrank"
  },
  {
    uuid: "83744ad9-a543-4a2b-8f21-c381528ccf50",
    iconSet: "FontAwesome5",
    iconName: "hamburger"
  },
  {
    uuid: "61948f29-f120-4938-8bdc-815230c5f2b6",
    iconSet: "FontAwesome5",
    iconName: "hammer"
  },
  {
    uuid: "602fb509-3462-437e-82ed-9fc9fdc4aaeb",
    iconSet: "FontAwesome5",
    iconName: "hamsa"
  },
  {
    uuid: "bb6c0ffb-4dd7-4ede-a331-2c7866a214b4",
    iconSet: "FontAwesome5",
    iconName: "hand-holding"
  },
  {
    uuid: "8cb14b76-a70c-4775-9126-d9275896f23d",
    iconSet: "FontAwesome5",
    iconName: "hand-holding-heart"
  },
  {
    uuid: "58d74b8e-9194-45cb-bdc4-a0e3c1d07b4f",
    iconSet: "FontAwesome5",
    iconName: "hand-holding-medical"
  },
  {
    uuid: "4162d1fc-c611-43b8-84e5-423b4c91b6dc",
    iconSet: "FontAwesome5",
    iconName: "hand-holding-usd"
  },
  {
    uuid: "62562627-442d-4e7d-a04e-02ff1c5c354a",
    iconSet: "FontAwesome5",
    iconName: "hand-holding-water"
  },
  {
    uuid: "636f3bc0-6bcc-4516-ab59-9f8a45b9e357",
    iconSet: "FontAwesome5",
    iconName: "hand-lizard"
  },
  {
    uuid: "3d3ba5ae-4cde-4e12-a070-9516607129ac",
    iconSet: "FontAwesome5",
    iconName: "hand-middle-finger"
  },
  {
    uuid: "bf20423b-8ee5-4367-a595-78db80c9af28",
    iconSet: "FontAwesome5",
    iconName: "hand-paper"
  },
  {
    uuid: "cebf2566-695d-45d0-8217-05d6330f516e",
    iconSet: "FontAwesome5",
    iconName: "hand-peace"
  },
  {
    uuid: "3084b3a5-4cbf-4cf5-836c-3e25d01d9d11",
    iconSet: "FontAwesome5",
    iconName: "hand-point-down"
  },
  {
    uuid: "5993abc3-0174-4a3a-986a-d597dc02d45e",
    iconSet: "FontAwesome5",
    iconName: "hand-point-left"
  },
  {
    uuid: "b552d62b-49b5-4294-b7ee-5f00d39c99a9",
    iconSet: "FontAwesome5",
    iconName: "hand-point-right"
  },
  {
    uuid: "a4158d2c-e926-49ee-8795-5a3cdf53dd52",
    iconSet: "FontAwesome5",
    iconName: "hand-point-up"
  },
  {
    uuid: "adad38c4-b5e3-4be1-a8b8-9f89be6eaa13",
    iconSet: "FontAwesome5",
    iconName: "hand-pointer"
  },
  {
    uuid: "78a911cc-522d-4a17-982d-5b4727747a0f",
    iconSet: "FontAwesome5",
    iconName: "hand-rock"
  },
  {
    uuid: "bad630fd-76d6-4630-9a15-f6ccadaa4cf7",
    iconSet: "FontAwesome5",
    iconName: "hand-scissors"
  },
  {
    uuid: "dac34a83-6324-458f-ad34-9b7aae08354c",
    iconSet: "FontAwesome5",
    iconName: "hand-sparkles"
  },
  {
    uuid: "2ec8b564-37f8-497e-83c5-6b8dfb9cdceb",
    iconSet: "FontAwesome5",
    iconName: "hand-spock"
  },
  {
    uuid: "ed4e9c9f-3829-42be-b3b8-519f16848e02",
    iconSet: "FontAwesome5",
    iconName: "hands"
  },
  {
    uuid: "ae1bb91a-9e32-48d8-982e-b184ab37478a",
    iconSet: "FontAwesome5",
    iconName: "hands-helping"
  },
  {
    uuid: "53986fb5-012f-4c0d-9e65-f3ebeeca8b4b",
    iconSet: "FontAwesome5",
    iconName: "hands-wash"
  },
  {
    uuid: "13f04a78-7e48-4620-9a8a-60c28bdda35a",
    iconSet: "FontAwesome5",
    iconName: "handshake"
  },
  {
    uuid: "d8d27c6f-4e25-471b-a9d6-ddbe527fd35b",
    iconSet: "FontAwesome5",
    iconName: "handshake-alt-slash"
  },
  {
    uuid: "896ad0c4-06d4-4352-a6b4-2c5be3e096eb",
    iconSet: "FontAwesome5",
    iconName: "handshake-slash"
  },
  {
    uuid: "4c7fe120-eb5a-4d1d-8b30-4795418136dc",
    iconSet: "FontAwesome5",
    iconName: "hanukiah"
  },
  {
    uuid: "6ee33567-eb16-4c85-9b48-a85695352871",
    iconSet: "FontAwesome5",
    iconName: "hard-hat"
  },
  {
    uuid: "80596118-62f3-4ce3-8224-8f8d0572970e",
    iconSet: "FontAwesome5",
    iconName: "hashtag"
  },
  {
    uuid: "bb88924d-f37a-4dbe-87d8-2f54d965cbcc",
    iconSet: "FontAwesome5",
    iconName: "hat-cowboy"
  },
  {
    uuid: "b117f23e-ce48-49f9-b5fc-7fa0c53fc4e1",
    iconSet: "FontAwesome5",
    iconName: "hat-cowboy-side"
  },
  {
    uuid: "f548dd37-3233-4a3d-8e7d-47b9ac64e9c3",
    iconSet: "FontAwesome5",
    iconName: "hat-wizard"
  },
  {
    uuid: "2a51b3a1-33d1-4ea5-9949-d151515165d6",
    iconSet: "FontAwesome5",
    iconName: "hdd"
  },
  {
    uuid: "f0a8e6b8-76d0-4505-9586-eed083a8328f",
    iconSet: "FontAwesome5",
    iconName: "head-side-cough"
  },
  {
    uuid: "61853adb-870a-47c1-a1c2-41701aa50375",
    iconSet: "FontAwesome5",
    iconName: "head-side-cough-slash"
  },
  {
    uuid: "0a86873a-5d93-485d-b62f-1da51cfac0db",
    iconSet: "FontAwesome5",
    iconName: "head-side-mask"
  },
  {
    uuid: "59fa0ccf-5b33-4c1d-a117-12c91d7a926e",
    iconSet: "FontAwesome5",
    iconName: "head-side-virus"
  },
  {
    uuid: "5fef2098-337d-4681-9565-b865b3c23eee",
    iconSet: "FontAwesome5",
    iconName: "heading"
  },
  {
    uuid: "424841b3-cdd9-4f8e-80aa-6340bdc2e893",
    iconSet: "FontAwesome5",
    iconName: "headphones"
  },
  {
    uuid: "8eb9d390-5320-43a6-af95-79aac37d4b7c",
    iconSet: "FontAwesome5",
    iconName: "headphones-alt"
  },
  {
    uuid: "3e3e8d52-28ae-436b-830f-a552ea08d3d5",
    iconSet: "FontAwesome5",
    iconName: "headset"
  },
  {
    uuid: "efffa70e-27eb-4980-b88f-74298cd35fb8",
    iconSet: "FontAwesome5",
    iconName: "heart"
  },
  {
    uuid: "a873de95-8d25-4053-bcfc-d50f0c04bcf9",
    iconSet: "FontAwesome5",
    iconName: "heart-broken"
  },
  {
    uuid: "93168c91-cf0f-4695-8f94-110bdd5fd9f9",
    iconSet: "FontAwesome5",
    iconName: "heartbeat"
  },
  {
    uuid: "bf0ef953-4694-48dd-8b37-2d1ba3714a09",
    iconSet: "FontAwesome5",
    iconName: "helicopter"
  },
  {
    uuid: "4539732f-bc29-44de-bbc1-38e48ced72d5",
    iconSet: "FontAwesome5",
    iconName: "highlighter"
  },
  {
    uuid: "d7de9b6b-25f3-4591-82a9-8af2b31475b1",
    iconSet: "FontAwesome5",
    iconName: "hiking"
  },
  {
    uuid: "eb0a75e9-51a4-4dc9-8407-fd600deb279b",
    iconSet: "FontAwesome5",
    iconName: "hippo"
  },
  {
    uuid: "e2142145-0177-4326-bee7-ff13ab6f508a",
    iconSet: "FontAwesome5",
    iconName: "hips"
  },
  {
    uuid: "2e4b1b78-8b66-4d7f-8ae7-d2ae6af1e165",
    iconSet: "FontAwesome5",
    iconName: "hire-a-helper"
  },
  {
    uuid: "e900ad31-f886-498e-9c68-3dc6012e1d97",
    iconSet: "FontAwesome5",
    iconName: "history"
  },
  {
    uuid: "010b1fdd-9aee-4221-9370-92b0916f47bd",
    iconSet: "FontAwesome5",
    iconName: "hockey-puck"
  },
  {
    uuid: "1ba82ffe-8675-4c17-8979-f9cccd4213fb",
    iconSet: "FontAwesome5",
    iconName: "holly-berry"
  },
  {
    uuid: "09fdf207-0054-4dea-8315-63307db1c696",
    iconSet: "FontAwesome5",
    iconName: "home"
  },
  {
    uuid: "719b2768-0fea-4c44-aace-eafe524ec776",
    iconSet: "FontAwesome5",
    iconName: "hooli"
  },
  {
    uuid: "fe489f62-bd48-4339-9d11-51883ed3e1ef",
    iconSet: "FontAwesome5",
    iconName: "hornbill"
  },
  {
    uuid: "53ea0697-156c-42a2-b9b6-5f18b0a893b3",
    iconSet: "FontAwesome5",
    iconName: "horse"
  },
  {
    uuid: "25d8b240-0425-4108-8ead-e63ba6680979",
    iconSet: "FontAwesome5",
    iconName: "horse-head"
  },
  {
    uuid: "025d0db9-e8c6-4e60-a5cf-920850b5c1d6",
    iconSet: "FontAwesome5",
    iconName: "hospital"
  },
  {
    uuid: "76685a4e-3558-46a1-bd65-420cd810329a",
    iconSet: "FontAwesome5",
    iconName: "hospital-alt"
  },
  {
    uuid: "7a351b52-7cdb-4e08-8a25-bd8c077b8950",
    iconSet: "FontAwesome5",
    iconName: "hospital-symbol"
  },
  {
    uuid: "dd3c8c0f-5c0e-408a-a9d2-d169a4215e2a",
    iconSet: "FontAwesome5",
    iconName: "hospital-user"
  },
  {
    uuid: "050eb0cb-85cb-4ed0-a545-668626bc2fff",
    iconSet: "FontAwesome5",
    iconName: "hot-tub"
  },
  {
    uuid: "fb8c54d0-3cdc-4914-a8cc-a60656cb60b2",
    iconSet: "FontAwesome5",
    iconName: "hotdog"
  },
  {
    uuid: "281ec91d-5ed4-4731-ac21-c5e15c1d774d",
    iconSet: "FontAwesome5",
    iconName: "hotel"
  },
  {
    uuid: "cdbadee2-60b0-4528-ba91-ffd25fe096a6",
    iconSet: "FontAwesome5",
    iconName: "hotjar"
  },
  {
    uuid: "c122929c-e03f-46f8-9546-3c83c03fd562",
    iconSet: "FontAwesome5",
    iconName: "hourglass"
  },
  {
    uuid: "56afd533-e744-4d01-a8d5-fc9f57ce6fee",
    iconSet: "FontAwesome5",
    iconName: "hourglass-end"
  },
  {
    uuid: "d751e67e-a7f6-4de1-a307-430ab34b6873",
    iconSet: "FontAwesome5",
    iconName: "hourglass-half"
  },
  {
    uuid: "4b03bd2d-7f13-438c-bc98-086741207aa3",
    iconSet: "FontAwesome5",
    iconName: "hourglass-start"
  },
  {
    uuid: "bf052537-a574-4cc2-a9c6-4c439b6607c2",
    iconSet: "FontAwesome5",
    iconName: "house-damage"
  },
  {
    uuid: "01f1d136-1b73-4f8f-98d6-e6c65f8433e0",
    iconSet: "FontAwesome5",
    iconName: "house-user"
  },
  {
    uuid: "afcd63bc-03e3-4416-9606-677ebc823e6b",
    iconSet: "FontAwesome5",
    iconName: "houzz"
  },
  {
    uuid: "18001f6c-c973-4858-bbbb-095d9822360f",
    iconSet: "FontAwesome5",
    iconName: "hryvnia"
  },
  {
    uuid: "cdc0a594-e9b3-4054-92fb-ed1a6ba46097",
    iconSet: "FontAwesome5",
    iconName: "html5"
  },
  {
    uuid: "98bcec5c-331c-43db-b627-646484fe2d58",
    iconSet: "FontAwesome5",
    iconName: "hubspot"
  },
  {
    uuid: "1634ec61-f361-4ff7-9135-6e6a11140421",
    iconSet: "FontAwesome5",
    iconName: "i-cursor"
  },
  {
    uuid: "d97f15f0-c7b4-494f-a6ce-764872167eef",
    iconSet: "FontAwesome5",
    iconName: "ice-cream"
  },
  {
    uuid: "e0523a22-efcb-4dbf-adf7-66fe3202e0a0",
    iconSet: "FontAwesome5",
    iconName: "icicles"
  },
  {
    uuid: "ffcfdc16-03bb-4c05-8a60-fa87ca3e095f",
    iconSet: "FontAwesome5",
    iconName: "icons"
  },
  {
    uuid: "64641251-08cd-4ca7-a9a3-e50065d3fd19",
    iconSet: "FontAwesome5",
    iconName: "id-badge"
  },
  {
    uuid: "a90ffb19-aa6d-477d-a5b3-4c91a3b2cfcb",
    iconSet: "FontAwesome5",
    iconName: "id-card"
  },
  {
    uuid: "618cc9f8-175b-4616-9e9d-4163f795d6bf",
    iconSet: "FontAwesome5",
    iconName: "id-card-alt"
  },
  {
    uuid: "ebacbef8-db19-4075-ae9d-ec041d9ab31c",
    iconSet: "FontAwesome5",
    iconName: "ideal"
  },
  {
    uuid: "a97d3c5c-26f8-40fc-b632-5c3d90468ae3",
    iconSet: "FontAwesome5",
    iconName: "igloo"
  },
  {
    uuid: "0b876182-9f62-422a-b212-472877af8698",
    iconSet: "FontAwesome5",
    iconName: "image"
  },
  {
    uuid: "c6e05dcb-3798-4d25-a8bb-676df4df372e",
    iconSet: "FontAwesome5",
    iconName: "images"
  },
  {
    uuid: "b5162aab-1e80-477d-8312-8d94d4b5a790",
    iconSet: "FontAwesome5",
    iconName: "imdb"
  },
  {
    uuid: "a7aa854a-1cf7-49b7-85a3-b0cc80319e1c",
    iconSet: "FontAwesome5",
    iconName: "inbox"
  },
  {
    uuid: "796353a2-45fd-4f09-a035-f4e5fd4606ba",
    iconSet: "FontAwesome5",
    iconName: "indent"
  },
  {
    uuid: "226f0675-c192-4b12-99b4-10d70d0ab4d8",
    iconSet: "FontAwesome5",
    iconName: "industry"
  },
  {
    uuid: "5eb242e8-4fb1-4114-819a-b349f3f02199",
    iconSet: "FontAwesome5",
    iconName: "infinity"
  },
  {
    uuid: "f6d2b944-d405-47cf-9082-bf6930721709",
    iconSet: "FontAwesome5",
    iconName: "info"
  },
  {
    uuid: "197affe4-71d9-4329-b8c1-2eeca1ca4233",
    iconSet: "FontAwesome5",
    iconName: "info-circle"
  },
  {
    uuid: "cabb6aea-a861-4372-b788-83c3705cb8fe",
    iconSet: "FontAwesome5",
    iconName: "instagram"
  },
  {
    uuid: "ab4a18e0-ac66-49c1-98c4-7758e5da94f9",
    iconSet: "FontAwesome5",
    iconName: "instagram-square"
  },
  {
    uuid: "aa740efe-38c6-44d4-837b-d13f52129c61",
    iconSet: "FontAwesome5",
    iconName: "intercom"
  },
  {
    uuid: "42257b4b-1bf0-4a11-98d4-c8b49f32978f",
    iconSet: "FontAwesome5",
    iconName: "internet-explorer"
  },
  {
    uuid: "8cd26087-465a-43ef-a013-a3e4916e7d45",
    iconSet: "FontAwesome5",
    iconName: "invision"
  },
  {
    uuid: "906076e9-b2cf-48a6-8660-5ef65143a5f1",
    iconSet: "FontAwesome5",
    iconName: "ioxhost"
  },
  {
    uuid: "93a765d2-6f3f-49d0-8a96-b94983f89fb5",
    iconSet: "FontAwesome5",
    iconName: "italic"
  },
  {
    uuid: "bdd73da2-0a6d-4165-8eb3-e4a28f04b7ed",
    iconSet: "FontAwesome5",
    iconName: "itch-io"
  },
  {
    uuid: "33cda63a-5e3a-4d2e-b64f-f4253540c13f",
    iconSet: "FontAwesome5",
    iconName: "itunes"
  },
  {
    uuid: "f0e08315-84fc-4960-9ce2-d015c5c5d3b0",
    iconSet: "FontAwesome5",
    iconName: "itunes-note"
  },
  {
    uuid: "3a793861-10b0-44e8-a429-6af604d8f277",
    iconSet: "FontAwesome5",
    iconName: "java"
  },
  {
    uuid: "316a32fd-c354-4593-8a05-c54e30aa0a26",
    iconSet: "FontAwesome5",
    iconName: "jedi"
  },
  {
    uuid: "f60512a7-3641-44b8-881a-f21c08fc1508",
    iconSet: "FontAwesome5",
    iconName: "jedi-order"
  },
  {
    uuid: "94319101-e1d4-4015-8d9e-5773fa8bfc93",
    iconSet: "FontAwesome5",
    iconName: "jenkins"
  },
  {
    uuid: "43e3465a-ea41-4d11-b313-79aa1c7e7d94",
    iconSet: "FontAwesome5",
    iconName: "jira"
  },
  {
    uuid: "d84d1144-6ae1-4759-9519-bf4f92f339f7",
    iconSet: "FontAwesome5",
    iconName: "joget"
  },
  {
    uuid: "af3844ca-d7d8-4485-838a-ba0e5bc2bb5f",
    iconSet: "FontAwesome5",
    iconName: "joint"
  },
  {
    uuid: "87cd5c57-b901-4d5a-8dc3-e8948648a9fd",
    iconSet: "FontAwesome5",
    iconName: "joomla"
  },
  {
    uuid: "82259693-dd09-49eb-8d1c-32ccc8d9b9ee",
    iconSet: "FontAwesome5",
    iconName: "journal-whills"
  },
  {
    uuid: "3ff0362b-33b4-4672-b5d4-4bcc98fccdf9",
    iconSet: "FontAwesome5",
    iconName: "js"
  },
  {
    uuid: "c1c20ed1-379c-479d-8b00-320852080d9b",
    iconSet: "FontAwesome5",
    iconName: "js-square"
  },
  {
    uuid: "58e2083a-ed8c-4d2d-885f-f792f9912c6a",
    iconSet: "FontAwesome5",
    iconName: "jsfiddle"
  },
  {
    uuid: "bada1f35-a930-4671-9e7e-88fb9e4f1bc3",
    iconSet: "FontAwesome5",
    iconName: "kaaba"
  },
  {
    uuid: "b886871a-344a-4ca6-bb8e-d1bb359af823",
    iconSet: "FontAwesome5",
    iconName: "kaggle"
  },
  {
    uuid: "d9bd1bba-1707-43d6-ac12-ed6437482850",
    iconSet: "FontAwesome5",
    iconName: "key"
  },
  {
    uuid: "4a13af90-0fb8-4c61-a628-4405a5c5ed11",
    iconSet: "FontAwesome5",
    iconName: "keybase"
  },
  {
    uuid: "412d8156-2c0e-4b70-bd09-6ed27f920180",
    iconSet: "FontAwesome5",
    iconName: "keyboard"
  },
  {
    uuid: "2219081f-d1e1-4e66-9e79-ff4582706360",
    iconSet: "FontAwesome5",
    iconName: "keycdn"
  },
  {
    uuid: "21304032-388f-4e44-b2f5-9254d9165b9b",
    iconSet: "FontAwesome5",
    iconName: "khanda"
  },
  {
    uuid: "741b4770-ffbc-425d-977d-509d2e85f4d2",
    iconSet: "FontAwesome5",
    iconName: "kickstarter"
  },
  {
    uuid: "f64895d0-8c63-4530-b9c5-333641fc46c6",
    iconSet: "FontAwesome5",
    iconName: "kickstarter-k"
  },
  {
    uuid: "dbf1c3ff-e12a-4d75-8e2c-efabfe3f9501",
    iconSet: "FontAwesome5",
    iconName: "kiss"
  },
  {
    uuid: "166e6ec8-20ff-4e99-86e2-b8cdbb9e15aa",
    iconSet: "FontAwesome5",
    iconName: "kiss-beam"
  },
  {
    uuid: "28ba2223-7d40-4d86-9eb4-2563063fa07b",
    iconSet: "FontAwesome5",
    iconName: "kiss-wink-heart"
  },
  {
    uuid: "02ccd5b6-f02a-49e4-9de5-b8a528545c66",
    iconSet: "FontAwesome5",
    iconName: "kiwi-bird"
  },
  {
    uuid: "95c9d54c-6319-4c8a-ab07-e4e1b922ab89",
    iconSet: "FontAwesome5",
    iconName: "korvue"
  },
  {
    uuid: "afa2ed65-b52c-4fc6-ad25-29851c3b456b",
    iconSet: "FontAwesome5",
    iconName: "landmark"
  },
  {
    uuid: "8892af02-6ab3-495f-b5b6-187395f8bf9e",
    iconSet: "FontAwesome5",
    iconName: "language"
  },
  {
    uuid: "e2a7bb65-3d46-4b21-bdd2-f313bdf7d842",
    iconSet: "FontAwesome5",
    iconName: "laptop"
  },
  {
    uuid: "2354ffb2-3e0d-418f-bd6f-af3c43f1c06a",
    iconSet: "FontAwesome5",
    iconName: "laptop-code"
  },
  {
    uuid: "e1b1952c-4c0b-418e-9523-ecd8b5953bd9",
    iconSet: "FontAwesome5",
    iconName: "laptop-house"
  },
  {
    uuid: "526b080c-3e71-47ef-8e9a-f5dd6ffdfe74",
    iconSet: "FontAwesome5",
    iconName: "laptop-medical"
  },
  {
    uuid: "cfec89a9-6726-4f3e-9ce8-482b4af294a4",
    iconSet: "FontAwesome5",
    iconName: "laravel"
  },
  {
    uuid: "12effd7c-0e15-404b-a978-1758863d449f",
    iconSet: "FontAwesome5",
    iconName: "lastfm"
  },
  {
    uuid: "ba16df1d-08d4-4086-8ac4-67bffa5cc458",
    iconSet: "FontAwesome5",
    iconName: "lastfm-square"
  },
  {
    uuid: "93219389-eba5-4639-8671-8b847104f23a",
    iconSet: "FontAwesome5",
    iconName: "laugh"
  },
  {
    uuid: "6552d836-0be0-4148-a1b4-3ac616acf31d",
    iconSet: "FontAwesome5",
    iconName: "laugh-beam"
  },
  {
    uuid: "66b79fe6-6eb4-4102-973c-f17097a4ca46",
    iconSet: "FontAwesome5",
    iconName: "laugh-squint"
  },
  {
    uuid: "7a1c784c-6a66-4cd1-bdb1-813a51a02670",
    iconSet: "FontAwesome5",
    iconName: "laugh-wink"
  },
  {
    uuid: "13cba31e-aeac-499d-8d30-b77754dae239",
    iconSet: "FontAwesome5",
    iconName: "layer-group"
  },
  {
    uuid: "15c6ba58-ca3f-4b03-98f6-717caee191e2",
    iconSet: "FontAwesome5",
    iconName: "leaf"
  },
  {
    uuid: "c0ec4d45-7c96-47c9-8398-ea607f450ea5",
    iconSet: "FontAwesome5",
    iconName: "leanpub"
  },
  {
    uuid: "6d8a1511-c264-4be3-a51c-3e16a39d7b2c",
    iconSet: "FontAwesome5",
    iconName: "lemon"
  },
  {
    uuid: "e329a4d0-2e01-4d5e-85f5-c36c5e2bffc1",
    iconSet: "FontAwesome5",
    iconName: "less"
  },
  {
    uuid: "9a5ec414-bf17-4026-87c3-b8cf88210563",
    iconSet: "FontAwesome5",
    iconName: "less-than"
  },
  {
    uuid: "b2a0c836-1234-45d2-a2d7-7d7aae97253b",
    iconSet: "FontAwesome5",
    iconName: "less-than-equal"
  },
  {
    uuid: "cb5dfc4d-9ed6-4597-8d89-8b11c72f4e84",
    iconSet: "FontAwesome5",
    iconName: "level-down-alt"
  },
  {
    uuid: "e3dfba14-7930-497f-a60e-0c16f2b21839",
    iconSet: "FontAwesome5",
    iconName: "level-up-alt"
  },
  {
    uuid: "5a24fb6b-aa40-45ed-a571-47439732276c",
    iconSet: "FontAwesome5",
    iconName: "life-ring"
  },
  {
    uuid: "9194b386-b46a-4066-b6c0-873932ab6389",
    iconSet: "FontAwesome5",
    iconName: "lightbulb"
  },
  {
    uuid: "999a23d7-e93d-49e7-8346-3f40c830327e",
    iconSet: "FontAwesome5",
    iconName: "line"
  },
  {
    uuid: "b63c334d-4934-4aec-a032-a5606147bc59",
    iconSet: "FontAwesome5",
    iconName: "link"
  },
  {
    uuid: "c4a2a4e4-9691-4ab7-a357-e563eecc2867",
    iconSet: "FontAwesome5",
    iconName: "linkedin"
  },
  {
    uuid: "44cd8332-ebb7-4af8-93bb-37d1822ecf99",
    iconSet: "FontAwesome5",
    iconName: "linkedin-in"
  },
  {
    uuid: "84f3f0ec-824c-4683-a3b2-484d2928e873",
    iconSet: "FontAwesome5",
    iconName: "linode"
  },
  {
    uuid: "a20e6c47-b850-4af8-b10b-9d0958c6222f",
    iconSet: "FontAwesome5",
    iconName: "linux"
  },
  {
    uuid: "c6bd111f-e050-49b1-918a-345157b4257e",
    iconSet: "FontAwesome5",
    iconName: "lira-sign"
  },
  {
    uuid: "36c4e5b9-b6c7-4d14-a297-b13c578af106",
    iconSet: "FontAwesome5",
    iconName: "list"
  },
  {
    uuid: "d4b55b83-dc9d-4629-b0f6-f135ccf2070f",
    iconSet: "FontAwesome5",
    iconName: "list-alt"
  },
  {
    uuid: "d0918c01-315d-49c1-9582-6661f8bc3fb1",
    iconSet: "FontAwesome5",
    iconName: "list-ol"
  },
  {
    uuid: "3e860c82-db2d-4287-9c92-dbe6921bbd6f",
    iconSet: "FontAwesome5",
    iconName: "list-ul"
  },
  {
    uuid: "54005d44-7b20-480d-b8bd-61596e4d22cc",
    iconSet: "FontAwesome5",
    iconName: "location-arrow"
  },
  {
    uuid: "14a46764-3d96-44b2-bf58-ebed23550310",
    iconSet: "FontAwesome5",
    iconName: "lock"
  },
  {
    uuid: "39f519d9-4e9c-4075-a129-04c8adc5469f",
    iconSet: "FontAwesome5",
    iconName: "lock-open"
  },
  {
    uuid: "ab0361f7-efde-42b9-b16e-151ca30711b3",
    iconSet: "FontAwesome5",
    iconName: "long-arrow-alt-down"
  },
  {
    uuid: "29e7f11c-f3e9-418d-bd44-9478d9b7e00a",
    iconSet: "FontAwesome5",
    iconName: "long-arrow-alt-left"
  },
  {
    uuid: "4de404a3-c0ec-48a7-9a90-4d3fe150dd22",
    iconSet: "FontAwesome5",
    iconName: "long-arrow-alt-right"
  },
  {
    uuid: "eb4425cf-7361-4ecb-8b4b-09035bc48938",
    iconSet: "FontAwesome5",
    iconName: "long-arrow-alt-up"
  },
  {
    uuid: "baeebbb5-6054-4857-8026-88cb58e9b645",
    iconSet: "FontAwesome5",
    iconName: "low-vision"
  },
  {
    uuid: "69ed5e65-713a-4b5b-a527-423eb6cb2eea",
    iconSet: "FontAwesome5",
    iconName: "luggage-cart"
  },
  {
    uuid: "824b1d26-3c55-4fbb-a4d5-1d902d8a49d4",
    iconSet: "FontAwesome5",
    iconName: "lungs"
  },
  {
    uuid: "4ad955d7-f8db-41b5-9a74-8361e2c44d8a",
    iconSet: "FontAwesome5",
    iconName: "lungs-virus"
  },
  {
    uuid: "93983487-d804-4ec6-9f86-f0cd53d7eb13",
    iconSet: "FontAwesome5",
    iconName: "lyft"
  },
  {
    uuid: "59b35414-1567-406d-8688-9005f5688938",
    iconSet: "FontAwesome5",
    iconName: "magento"
  },
  {
    uuid: "b790b289-c4a9-401b-8a0b-fc64d900002c",
    iconSet: "FontAwesome5",
    iconName: "magic"
  },
  {
    uuid: "827c7db4-d589-46d8-b3bd-8ffbe0228f2b",
    iconSet: "FontAwesome5",
    iconName: "magnet"
  },
  {
    uuid: "1c43b354-2289-4eaf-8efe-15e97d36b139",
    iconSet: "FontAwesome5",
    iconName: "mail-bulk"
  },
  {
    uuid: "96f6a790-5ff9-492a-95ff-48865909af7d",
    iconSet: "FontAwesome5",
    iconName: "mailchimp"
  },
  {
    uuid: "d2c07f9c-73c2-44db-ae6c-ad705dc62688",
    iconSet: "FontAwesome5",
    iconName: "male"
  },
  {
    uuid: "5d12028f-a67e-4a37-90a6-b7f07432eba8",
    iconSet: "FontAwesome5",
    iconName: "mandalorian"
  },
  {
    uuid: "afebbcf2-bb3f-4f2b-ace0-4fcfafe4820b",
    iconSet: "FontAwesome5",
    iconName: "map"
  },
  {
    uuid: "24269c1e-3b7d-4070-a557-531cdc14fe08",
    iconSet: "FontAwesome5",
    iconName: "map-marked"
  },
  {
    uuid: "537a5c68-6ca7-4c26-8708-fe8670029383",
    iconSet: "FontAwesome5",
    iconName: "map-marked-alt"
  },
  {
    uuid: "81e7f0bd-e2aa-493c-80f0-7cb1d5894e15",
    iconSet: "FontAwesome5",
    iconName: "map-marker"
  },
  {
    uuid: "9f2599da-3147-4676-8581-bee1283d0900",
    iconSet: "FontAwesome5",
    iconName: "map-marker-alt"
  },
  {
    uuid: "84eb94dc-bb78-4a99-8de4-83ab0fe30d9d",
    iconSet: "FontAwesome5",
    iconName: "map-pin"
  },
  {
    uuid: "d64b9f54-ef76-4fd5-b58f-5b1a4eebe00f",
    iconSet: "FontAwesome5",
    iconName: "map-signs"
  },
  {
    uuid: "8ba07fe0-06c1-4cfd-8a4c-ce52a1780ae7",
    iconSet: "FontAwesome5",
    iconName: "markdown"
  },
  {
    uuid: "8d54e434-2233-4770-a521-9fa4c581ce0d",
    iconSet: "FontAwesome5",
    iconName: "marker"
  },
  {
    uuid: "1b8fc3a2-04c9-47a8-9802-55a2c6601cdf",
    iconSet: "FontAwesome5",
    iconName: "mars"
  },
  {
    uuid: "79f5419c-98b2-426a-b916-265ff3e80380",
    iconSet: "FontAwesome5",
    iconName: "mars-double"
  },
  {
    uuid: "1d794457-5d63-4499-89be-bae5bf5384d4",
    iconSet: "FontAwesome5",
    iconName: "mars-stroke"
  },
  {
    uuid: "112cb5df-ee6b-4d9e-b4ff-ff2e3d7ea8c8",
    iconSet: "FontAwesome5",
    iconName: "mars-stroke-h"
  },
  {
    uuid: "46a05364-5764-49bd-9b2e-e7a02c06710c",
    iconSet: "FontAwesome5",
    iconName: "mars-stroke-v"
  },
  {
    uuid: "862bb987-814f-4e9d-ae57-759215320c88",
    iconSet: "FontAwesome5",
    iconName: "mask"
  },
  {
    uuid: "42932165-8ed2-4407-b898-9cd843de345d",
    iconSet: "FontAwesome5",
    iconName: "mastodon"
  },
  {
    uuid: "eddf0eb0-1f5b-49b2-9e67-f59b0220bcc7",
    iconSet: "FontAwesome5",
    iconName: "maxcdn"
  },
  {
    uuid: "8572a6de-9d6d-421c-a23b-38858ba097e7",
    iconSet: "FontAwesome5",
    iconName: "mdb"
  },
  {
    uuid: "6dcbd033-d664-45fc-8c79-0585a98fe5c8",
    iconSet: "FontAwesome5",
    iconName: "medal"
  },
  {
    uuid: "6367e64f-a4cd-4fb5-8f50-02713db2170c",
    iconSet: "FontAwesome5",
    iconName: "medapps"
  },
  {
    uuid: "fb48cd48-1e28-480d-b3f9-45fdd3e31b9d",
    iconSet: "FontAwesome5",
    iconName: "medium"
  },
  {
    uuid: "7a3a1db0-4812-458f-a69e-fdfbe75ff467",
    iconSet: "FontAwesome5",
    iconName: "medium-m"
  },
  {
    uuid: "10b21e49-6474-4d18-9f7a-c2662d8de0ce",
    iconSet: "FontAwesome5",
    iconName: "medkit"
  },
  {
    uuid: "ad6e94b2-e4db-4cad-917b-81f3247e8989",
    iconSet: "FontAwesome5",
    iconName: "medrt"
  },
  {
    uuid: "89785d6f-e646-4b86-b090-1bd0b1350764",
    iconSet: "FontAwesome5",
    iconName: "meetup"
  },
  {
    uuid: "3d8278b5-2025-4566-82c1-d074239a53f0",
    iconSet: "FontAwesome5",
    iconName: "megaport"
  },
  {
    uuid: "c8da3523-7532-4cee-829f-2c3f49e5ea54",
    iconSet: "FontAwesome5",
    iconName: "meh"
  },
  {
    uuid: "793ab185-fcd5-49bd-b0d7-a95a49dcd57b",
    iconSet: "FontAwesome5",
    iconName: "meh-blank"
  },
  {
    uuid: "01af64b9-5b9d-4334-9fe3-6c94700e4385",
    iconSet: "FontAwesome5",
    iconName: "meh-rolling-eyes"
  },
  {
    uuid: "9f656dd7-f9a4-46e7-b2c2-109166c80034",
    iconSet: "FontAwesome5",
    iconName: "memory"
  },
  {
    uuid: "c401c6ae-14bd-4d40-bb4b-ba97700b0a61",
    iconSet: "FontAwesome5",
    iconName: "mendeley"
  },
  {
    uuid: "53899fa5-8a07-427c-8aad-d1d1c21e9b0b",
    iconSet: "FontAwesome5",
    iconName: "menorah"
  },
  {
    uuid: "4ddf8c55-b933-48b2-9278-e3f1cddd3640",
    iconSet: "FontAwesome5",
    iconName: "mercury"
  },
  {
    uuid: "8087dc5a-1939-4007-a154-01ceb15eea98",
    iconSet: "FontAwesome5",
    iconName: "meteor"
  },
  {
    uuid: "a6b45086-9547-4cf2-924c-3c254bac2dd7",
    iconSet: "FontAwesome5",
    iconName: "microblog"
  },
  {
    uuid: "287bcca2-28a1-46d5-a82d-d80f76e3ea68",
    iconSet: "FontAwesome5",
    iconName: "microchip"
  },
  {
    uuid: "0fb46867-939f-4ffe-a917-a427cb141c50",
    iconSet: "FontAwesome5",
    iconName: "microphone"
  },
  {
    uuid: "7ff64068-c1ff-41dc-a6d2-e773c6e8041e",
    iconSet: "FontAwesome5",
    iconName: "microphone-alt"
  },
  {
    uuid: "de8a8323-ed10-425a-b586-ff2fb762c6d3",
    iconSet: "FontAwesome5",
    iconName: "microphone-alt-slash"
  },
  {
    uuid: "083c8b96-98c1-49fd-9fee-2e41ec5ba304",
    iconSet: "FontAwesome5",
    iconName: "microphone-slash"
  },
  {
    uuid: "9bb76292-fd72-45ac-b546-33c95c855b65",
    iconSet: "FontAwesome5",
    iconName: "microscope"
  },
  {
    uuid: "af9be6e6-4c93-4fe7-936f-9a9c0792b6f4",
    iconSet: "FontAwesome5",
    iconName: "microsoft"
  },
  {
    uuid: "1bb49ef2-f34e-4102-bc29-11a14b3a18b7",
    iconSet: "FontAwesome5",
    iconName: "minus"
  },
  {
    uuid: "d4f09aae-3945-4190-923b-3114ec32ffde",
    iconSet: "FontAwesome5",
    iconName: "minus-circle"
  },
  {
    uuid: "65404770-54a1-4d75-97df-911f468aed17",
    iconSet: "FontAwesome5",
    iconName: "minus-square"
  },
  {
    uuid: "9395e4b3-be15-4051-9a51-29338b6381b5",
    iconSet: "FontAwesome5",
    iconName: "mitten"
  },
  {
    uuid: "f813ebec-5e2e-49fb-93e7-473eecfffd1c",
    iconSet: "FontAwesome5",
    iconName: "mix"
  },
  {
    uuid: "b7b1ca3f-b274-47cd-b7e1-7b42b68259a3",
    iconSet: "FontAwesome5",
    iconName: "mixcloud"
  },
  {
    uuid: "201a78a4-34f6-4103-b8e4-637fc168c74c",
    iconSet: "FontAwesome5",
    iconName: "mixer"
  },
  {
    uuid: "6c16fa60-cab9-473e-bf45-94e2117e04c6",
    iconSet: "FontAwesome5",
    iconName: "mizuni"
  },
  {
    uuid: "a7fa57b2-38b2-4139-8000-a8c7f78c0dc1",
    iconSet: "FontAwesome5",
    iconName: "mobile"
  },
  {
    uuid: "c774bc13-5458-4f60-9b98-df54e46e1984",
    iconSet: "FontAwesome5",
    iconName: "mobile-alt"
  },
  {
    uuid: "3d6b075b-235f-448e-98b2-b2766075118e",
    iconSet: "FontAwesome5",
    iconName: "modx"
  },
  {
    uuid: "c66d8b97-89d2-4f24-9c42-ecad9690caf1",
    iconSet: "FontAwesome5",
    iconName: "monero"
  },
  {
    uuid: "c3ed97b5-0b81-4719-85a9-0188471d437f",
    iconSet: "FontAwesome5",
    iconName: "money-bill"
  },
  {
    uuid: "2b96a4c2-1f86-49cb-a5d0-13ed68bc2f36",
    iconSet: "FontAwesome5",
    iconName: "money-bill-alt"
  },
  {
    uuid: "d094b999-6384-4d75-b3dd-3c72389d6ad3",
    iconSet: "FontAwesome5",
    iconName: "money-bill-wave"
  },
  {
    uuid: "721e7859-0212-4826-b4ec-afd5ce1bfa27",
    iconSet: "FontAwesome5",
    iconName: "money-bill-wave-alt"
  },
  {
    uuid: "0caa62bf-8575-44da-adac-f9a7478c70b2",
    iconSet: "FontAwesome5",
    iconName: "money-check"
  },
  {
    uuid: "8204dfc4-61ed-4bf7-9ebd-f22a0f4917fd",
    iconSet: "FontAwesome5",
    iconName: "money-check-alt"
  },
  {
    uuid: "e15cceea-66d1-427f-bc5f-e8bae5d8a9ef",
    iconSet: "FontAwesome5",
    iconName: "monument"
  },
  {
    uuid: "c00869f7-bf61-4d24-a27a-ba546ee3876f",
    iconSet: "FontAwesome5",
    iconName: "moon"
  },
  {
    uuid: "80ae16c9-2e1f-44ba-97d4-80127490bf3b",
    iconSet: "FontAwesome5",
    iconName: "mortar-pestle"
  },
  {
    uuid: "4699b227-56b3-4de9-b9d5-bb57e98c233f",
    iconSet: "FontAwesome5",
    iconName: "mosque"
  },
  {
    uuid: "636fe52e-2ca0-4923-b005-d7c64048c23b",
    iconSet: "FontAwesome5",
    iconName: "motorcycle"
  },
  {
    uuid: "9aa1940b-fc2d-4386-a46f-8464dac40588",
    iconSet: "FontAwesome5",
    iconName: "mountain"
  },
  {
    uuid: "ae54124e-8222-44f1-ac34-383c57840e02",
    iconSet: "FontAwesome5",
    iconName: "mouse"
  },
  {
    uuid: "95a32328-f4dd-412a-aeb6-dfa6590a581f",
    iconSet: "FontAwesome5",
    iconName: "mouse-pointer"
  },
  {
    uuid: "b5c21c2f-23bb-4107-bbb0-526624611bdb",
    iconSet: "FontAwesome5",
    iconName: "mug-hot"
  },
  {
    uuid: "af17dc43-1db9-4a02-8544-e1abb8675bdb",
    iconSet: "FontAwesome5",
    iconName: "music"
  },
  {
    uuid: "326573df-0e0c-4681-9ed5-03a6288f7471",
    iconSet: "FontAwesome5",
    iconName: "napster"
  },
  {
    uuid: "fa9ae563-d1a5-4add-9f33-04b74b00a1e0",
    iconSet: "FontAwesome5",
    iconName: "neos"
  },
  {
    uuid: "13c62ef7-b2d0-4475-9ca4-fa1e30fc9bdd",
    iconSet: "FontAwesome5",
    iconName: "network-wired"
  },
  {
    uuid: "eb7c3232-c406-4cba-bad7-a6e37d6388ae",
    iconSet: "FontAwesome5",
    iconName: "neuter"
  },
  {
    uuid: "a8b43eac-9c05-4de9-a3ed-1a0ef1ade89b",
    iconSet: "FontAwesome5",
    iconName: "newspaper"
  },
  {
    uuid: "11f6b59f-c05c-4dcf-9334-9ec83c1e074f",
    iconSet: "FontAwesome5",
    iconName: "nimblr"
  },
  {
    uuid: "4ece13b5-214f-42cf-9cea-4768e972b9ad",
    iconSet: "FontAwesome5",
    iconName: "node"
  },
  {
    uuid: "59561894-4db0-4c03-88a6-db6398444045",
    iconSet: "FontAwesome5",
    iconName: "node-js"
  },
  {
    uuid: "dd08984a-44fd-4389-8c4c-0373180a5904",
    iconSet: "FontAwesome5",
    iconName: "not-equal"
  },
  {
    uuid: "3da39436-14b9-4568-aed3-f8201d5f3dc6",
    iconSet: "FontAwesome5",
    iconName: "notes-medical"
  },
  {
    uuid: "d911961f-129c-4991-86d6-25e05f7efca7",
    iconSet: "FontAwesome5",
    iconName: "npm"
  },
  {
    uuid: "586e265d-030d-495c-ba05-718e17ecfb54",
    iconSet: "FontAwesome5",
    iconName: "ns8"
  },
  {
    uuid: "e7b92aad-e331-4447-bdf5-12f5ab255e15",
    iconSet: "FontAwesome5",
    iconName: "nutritionix"
  },
  {
    uuid: "4ecf0961-ed4f-4e51-9414-bfa228c395f3",
    iconSet: "FontAwesome5",
    iconName: "object-group"
  },
  {
    uuid: "924bfdd1-2831-4294-9902-188379560df9",
    iconSet: "FontAwesome5",
    iconName: "object-ungroup"
  },
  {
    uuid: "50a947b8-a9be-4d00-8a9c-59eaf9ef34a7",
    iconSet: "FontAwesome5",
    iconName: "odnoklassniki"
  },
  {
    uuid: "804f5d84-bc63-45cd-96b7-4cf1009602cb",
    iconSet: "FontAwesome5",
    iconName: "odnoklassniki-square"
  },
  {
    uuid: "6fa49632-6802-4ea9-9930-b57d67085967",
    iconSet: "FontAwesome5",
    iconName: "oil-can"
  },
  {
    uuid: "2f5a6ad9-f96b-4c84-b62c-6bbe091893a9",
    iconSet: "FontAwesome5",
    iconName: "old-republic"
  },
  {
    uuid: "a0847ebe-247c-4652-981e-1a574fe22492",
    iconSet: "FontAwesome5",
    iconName: "om"
  },
  {
    uuid: "e8220e81-1c4d-42f6-8a14-709b3dad0129",
    iconSet: "FontAwesome5",
    iconName: "opencart"
  },
  {
    uuid: "9f910eba-0949-4a06-a920-321eed737565",
    iconSet: "FontAwesome5",
    iconName: "openid"
  },
  {
    uuid: "4145a32b-cf74-4c17-aa94-d267e9fd73c3",
    iconSet: "FontAwesome5",
    iconName: "opera"
  },
  {
    uuid: "ae53c876-f12a-4e92-8c7d-8b2126d722b8",
    iconSet: "FontAwesome5",
    iconName: "optin-monster"
  },
  {
    uuid: "b3cc10be-a5d0-4a0f-a031-a5019442af2a",
    iconSet: "FontAwesome5",
    iconName: "orcid"
  },
  {
    uuid: "71ae8f7c-59a4-4a38-9575-4243b6dcd2ab",
    iconSet: "FontAwesome5",
    iconName: "osi"
  },
  {
    uuid: "8795b714-f8b0-42fe-97d4-264184177cae",
    iconSet: "FontAwesome5",
    iconName: "otter"
  },
  {
    uuid: "fc9c85d3-1b52-4a2b-a84d-38b1ba4c2039",
    iconSet: "FontAwesome5",
    iconName: "outdent"
  },
  {
    uuid: "edc2c8f4-aaeb-4342-84a7-90b027f7f5e9",
    iconSet: "FontAwesome5",
    iconName: "page4"
  },
  {
    uuid: "76b66c22-abf7-4a47-9227-81f130a7859c",
    iconSet: "FontAwesome5",
    iconName: "pagelines"
  },
  {
    uuid: "b9419711-7e14-49b9-8647-ea07d55741f5",
    iconSet: "FontAwesome5",
    iconName: "pager"
  },
  {
    uuid: "bd0376fc-15cf-4193-b879-133516ab934a",
    iconSet: "FontAwesome5",
    iconName: "paint-brush"
  },
  {
    uuid: "ed35c519-37c2-42d9-8b6f-97119b26e6b3",
    iconSet: "FontAwesome5",
    iconName: "paint-roller"
  },
  {
    uuid: "9db1e134-a864-4f6c-8ed8-299d5f65cea4",
    iconSet: "FontAwesome5",
    iconName: "palette"
  },
  {
    uuid: "20c9949d-81e1-4d67-b948-76ac1ee2f67b",
    iconSet: "FontAwesome5",
    iconName: "palfed"
  },
  {
    uuid: "1cbcdb11-f8dd-43b0-a069-e372889c862d",
    iconSet: "FontAwesome5",
    iconName: "pallet"
  },
  {
    uuid: "4d2fb1ff-633f-4580-807b-338fa9869fab",
    iconSet: "FontAwesome5",
    iconName: "paper-plane"
  },
  {
    uuid: "b5d34e63-978f-4dae-b1c1-dececdce64e2",
    iconSet: "FontAwesome5",
    iconName: "paperclip"
  },
  {
    uuid: "25719654-8bd6-49f5-a508-0609d5c96c9c",
    iconSet: "FontAwesome5",
    iconName: "parachute-box"
  },
  {
    uuid: "67f05692-40dc-4bb1-9023-d024c258fd8c",
    iconSet: "FontAwesome5",
    iconName: "paragraph"
  },
  {
    uuid: "9a6bfa17-3c60-4577-939f-e817f8a8b961",
    iconSet: "FontAwesome5",
    iconName: "parking"
  },
  {
    uuid: "b2773ddd-8203-4475-8030-1b85edb5a4f2",
    iconSet: "FontAwesome5",
    iconName: "passport"
  },
  {
    uuid: "c16b5e4c-5152-474c-b686-969f05c8246e",
    iconSet: "FontAwesome5",
    iconName: "pastafarianism"
  },
  {
    uuid: "cab78724-f0c2-4d42-9fed-cd2aa711ed62",
    iconSet: "FontAwesome5",
    iconName: "paste"
  },
  {
    uuid: "8dc00a87-dcd9-4495-9043-244ed04956c3",
    iconSet: "FontAwesome5",
    iconName: "patreon"
  },
  {
    uuid: "06f48d11-dd08-4df0-b34d-bacdf4022616",
    iconSet: "FontAwesome5",
    iconName: "pause"
  },
  {
    uuid: "35340c1b-6222-45c3-9050-6ece60dc3800",
    iconSet: "FontAwesome5",
    iconName: "pause-circle"
  },
  {
    uuid: "f1243d98-62ef-4c53-af6a-6fde108e7813",
    iconSet: "FontAwesome5",
    iconName: "paw"
  },
  {
    uuid: "cf406569-8e96-44d4-a528-c5d482bcbfb5",
    iconSet: "FontAwesome5",
    iconName: "paypal"
  },
  {
    uuid: "039abab2-e47c-4a85-852e-2c1df387c2a6",
    iconSet: "FontAwesome5",
    iconName: "peace"
  },
  {
    uuid: "6eac3502-f58d-4adc-8837-e6f584654a21",
    iconSet: "FontAwesome5",
    iconName: "pen"
  },
  {
    uuid: "6367b430-5473-45af-8889-9dc943a55bc5",
    iconSet: "FontAwesome5",
    iconName: "pen-alt"
  },
  {
    uuid: "93ffb773-00ef-4615-ad96-663382818805",
    iconSet: "FontAwesome5",
    iconName: "pen-fancy"
  },
  {
    uuid: "2edee7ff-acd8-4c6f-9b33-97d254b460ce",
    iconSet: "FontAwesome5",
    iconName: "pen-nib"
  },
  {
    uuid: "5ac812d0-c394-45b1-95a2-3961524123b8",
    iconSet: "FontAwesome5",
    iconName: "pen-square"
  },
  {
    uuid: "fcb29cdf-0ed1-4647-b15d-90f10a0f18b3",
    iconSet: "FontAwesome5",
    iconName: "pencil-alt"
  },
  {
    uuid: "1a0be396-1f75-4d54-9f2a-deb53cd3c657",
    iconSet: "FontAwesome5",
    iconName: "pencil-ruler"
  },
  {
    uuid: "e513d495-1b18-49af-8374-a18262db8f9a",
    iconSet: "FontAwesome5",
    iconName: "penny-arcade"
  },
  {
    uuid: "a079a13a-5030-4020-975d-c273a7a97633",
    iconSet: "FontAwesome5",
    iconName: "people-arrows"
  },
  {
    uuid: "80fd947f-a19e-49a6-a6dc-51c0a905ff00",
    iconSet: "FontAwesome5",
    iconName: "people-carry"
  },
  {
    uuid: "f980b2c7-3e47-413a-a998-c1286e521261",
    iconSet: "FontAwesome5",
    iconName: "pepper-hot"
  },
  {
    uuid: "6fce7b12-39cf-4aad-8a28-329135b26bff",
    iconSet: "FontAwesome5",
    iconName: "percent"
  },
  {
    uuid: "fab54e67-e4cc-4793-900c-d2622855c34c",
    iconSet: "FontAwesome5",
    iconName: "percentage"
  },
  {
    uuid: "a795834c-bb62-4dbd-8bfa-d217bb3a5b6c",
    iconSet: "FontAwesome5",
    iconName: "periscope"
  },
  {
    uuid: "aa552f9b-2d30-4457-944c-407a8ce03942",
    iconSet: "FontAwesome5",
    iconName: "person-booth"
  },
  {
    uuid: "9734c7d8-6e45-42b3-9363-d55d80c2a8d6",
    iconSet: "FontAwesome5",
    iconName: "phabricator"
  },
  {
    uuid: "a890ffae-8cce-4f20-92f3-5f48c69a2c8b",
    iconSet: "FontAwesome5",
    iconName: "phoenix-framework"
  },
  {
    uuid: "0db16cc4-c3fc-4c24-bf67-29e7ce154fed",
    iconSet: "FontAwesome5",
    iconName: "phoenix-squadron"
  },
  {
    uuid: "6c4e514d-2574-4881-9517-6834cf1d6234",
    iconSet: "FontAwesome5",
    iconName: "phone"
  },
  {
    uuid: "911b3bea-da02-4318-9267-b35e5cc922d8",
    iconSet: "FontAwesome5",
    iconName: "phone-alt"
  },
  {
    uuid: "9577b11e-5a0b-4d67-8419-be59d4216f9c",
    iconSet: "FontAwesome5",
    iconName: "phone-slash"
  },
  {
    uuid: "e9c64371-24aa-4aae-a550-85dc7edefb78",
    iconSet: "FontAwesome5",
    iconName: "phone-square"
  },
  {
    uuid: "a714c13b-6b3f-4622-b8c6-5d9463701c58",
    iconSet: "FontAwesome5",
    iconName: "phone-square-alt"
  },
  {
    uuid: "d6209c4b-e403-498d-8455-563fd9a7cf40",
    iconSet: "FontAwesome5",
    iconName: "phone-volume"
  },
  {
    uuid: "93a9d722-9cc4-4e6b-a4d9-f699146decae",
    iconSet: "FontAwesome5",
    iconName: "photo-video"
  },
  {
    uuid: "aa67610a-3f6d-4631-bafe-6e303d6f69a4",
    iconSet: "FontAwesome5",
    iconName: "php"
  },
  {
    uuid: "c90fc91b-6242-4512-af77-579547f3b642",
    iconSet: "FontAwesome5",
    iconName: "pied-piper"
  },
  {
    uuid: "87333562-3cc7-4c98-b398-349be52e9763",
    iconSet: "FontAwesome5",
    iconName: "pied-piper-alt"
  },
  {
    uuid: "2329f8f5-3384-4fe1-9a2a-fb28e35ecbda",
    iconSet: "FontAwesome5",
    iconName: "pied-piper-hat"
  },
  {
    uuid: "c3fe71cc-14ad-44b5-8587-9258000c7549",
    iconSet: "FontAwesome5",
    iconName: "pied-piper-pp"
  },
  {
    uuid: "721498a3-4abe-45bc-98d9-19774fcfd10f",
    iconSet: "FontAwesome5",
    iconName: "pied-piper-square"
  },
  {
    uuid: "9a86cdbb-4228-4a8d-9d08-ac611676167c",
    iconSet: "FontAwesome5",
    iconName: "piggy-bank"
  },
  {
    uuid: "4a946b21-0bb6-4112-83de-e631d0f69750",
    iconSet: "FontAwesome5",
    iconName: "pills"
  },
  {
    uuid: "707f6a17-8666-48b6-9689-2a43dc90d6ad",
    iconSet: "FontAwesome5",
    iconName: "pinterest"
  },
  {
    uuid: "68ed71ac-d957-4e51-aee5-d40b518a13ac",
    iconSet: "FontAwesome5",
    iconName: "pinterest-p"
  },
  {
    uuid: "968671df-129f-4abf-97ff-ec4d8adcd3b2",
    iconSet: "FontAwesome5",
    iconName: "pinterest-square"
  },
  {
    uuid: "cb65f756-d352-4a2a-9197-18425b72082a",
    iconSet: "FontAwesome5",
    iconName: "pizza-slice"
  },
  {
    uuid: "99f58c1b-8df2-43d5-9640-4a636eb0566b",
    iconSet: "FontAwesome5",
    iconName: "place-of-worship"
  },
  {
    uuid: "02a6d955-39dc-4623-b225-1fca5363634a",
    iconSet: "FontAwesome5",
    iconName: "plane"
  },
  {
    uuid: "ebc3d487-0da2-424c-87ff-6bd8bfd3f575",
    iconSet: "FontAwesome5",
    iconName: "plane-arrival"
  },
  {
    uuid: "8c33a06d-e880-4864-bc60-d6811212ea7b",
    iconSet: "FontAwesome5",
    iconName: "plane-departure"
  },
  {
    uuid: "2f1f33f7-99d6-476c-80b1-66fb38602c65",
    iconSet: "FontAwesome5",
    iconName: "plane-slash"
  },
  {
    uuid: "d5dfcbe1-24ea-4878-8612-d0ec5030535d",
    iconSet: "FontAwesome5",
    iconName: "play"
  },
  {
    uuid: "1552412f-a86b-4521-b689-9c56bb5bc730",
    iconSet: "FontAwesome5",
    iconName: "play-circle"
  },
  {
    uuid: "213c238d-0e98-4096-be53-1b538cf50a29",
    iconSet: "FontAwesome5",
    iconName: "playstation"
  },
  {
    uuid: "a81a2e2d-8770-43ee-a3ce-c856e65d952f",
    iconSet: "FontAwesome5",
    iconName: "plug"
  },
  {
    uuid: "9188a3ab-4d28-4881-94ad-70f33f8d6d0c",
    iconSet: "FontAwesome5",
    iconName: "plus"
  },
  {
    uuid: "f1cf0894-9b60-4695-90ab-9857cdb47bac",
    iconSet: "FontAwesome5",
    iconName: "plus-circle"
  },
  {
    uuid: "e1f73aed-8163-4718-a02f-9f6db58e17f0",
    iconSet: "FontAwesome5",
    iconName: "plus-square"
  },
  {
    uuid: "db0ba32c-6c87-4019-b609-c290d60a4a02",
    iconSet: "FontAwesome5",
    iconName: "podcast"
  },
  {
    uuid: "f2e44f3b-78f3-4a4c-ad87-9b7751a75c41",
    iconSet: "FontAwesome5",
    iconName: "poll"
  },
  {
    uuid: "91616fc7-1c7f-48e9-9b54-a79bfb85fe87",
    iconSet: "FontAwesome5",
    iconName: "poll-h"
  },
  {
    uuid: "991b4bba-08a1-49cb-8588-60725aab56df",
    iconSet: "FontAwesome5",
    iconName: "poo"
  },
  {
    uuid: "30815704-96a9-48c8-a61c-751014848a3c",
    iconSet: "FontAwesome5",
    iconName: "poo-storm"
  },
  {
    uuid: "8d79a5c0-ac9b-49b0-9530-0f2dd2efc3e0",
    iconSet: "FontAwesome5",
    iconName: "poop"
  },
  {
    uuid: "6ad50d1c-1f9c-436c-9553-747ab80feb75",
    iconSet: "FontAwesome5",
    iconName: "portrait"
  },
  {
    uuid: "fea85161-69d8-4f9f-a1fd-7735b7ca9181",
    iconSet: "FontAwesome5",
    iconName: "pound-sign"
  },
  {
    uuid: "0ae15a24-821d-4841-b6bc-70cf4cd6cbdc",
    iconSet: "FontAwesome5",
    iconName: "power-off"
  },
  {
    uuid: "8129d243-3d3b-432e-9d1c-c30574f835d2",
    iconSet: "FontAwesome5",
    iconName: "pray"
  },
  {
    uuid: "49d6708e-360c-4d8e-acea-0ce6f1072e66",
    iconSet: "FontAwesome5",
    iconName: "praying-hands"
  },
  {
    uuid: "8c438589-32b2-4d2b-9ac6-1d9adc6a05e3",
    iconSet: "FontAwesome5",
    iconName: "prescription"
  },
  {
    uuid: "f361b827-e0a5-423e-9b3d-4a98f0e77df7",
    iconSet: "FontAwesome5",
    iconName: "prescription-bottle"
  },
  {
    uuid: "11a7563f-1e64-42fe-8ff5-6abeefbdbfe4",
    iconSet: "FontAwesome5",
    iconName: "prescription-bottle-alt"
  },
  {
    uuid: "4f24f43e-6c5f-4a81-97fe-00266cffc2fd",
    iconSet: "FontAwesome5",
    iconName: "print"
  },
  {
    uuid: "62cad91b-fb9e-40c3-a2c9-78576f594919",
    iconSet: "FontAwesome5",
    iconName: "procedures"
  },
  {
    uuid: "2dd7edf1-f3ba-4116-950b-e9032ed6e9a6",
    iconSet: "FontAwesome5",
    iconName: "product-hunt"
  },
  {
    uuid: "b9cd77d1-18ba-47df-a005-beb34e5889b9",
    iconSet: "FontAwesome5",
    iconName: "project-diagram"
  },
  {
    uuid: "3ec228ba-864f-4a25-beb4-0c87b684ad48",
    iconSet: "FontAwesome5",
    iconName: "pump-medical"
  },
  {
    uuid: "c44c433b-cc01-4733-b730-84accdffb1cc",
    iconSet: "FontAwesome5",
    iconName: "pump-soap"
  },
  {
    uuid: "90630aee-7327-43e3-a012-03c925aa5c70",
    iconSet: "FontAwesome5",
    iconName: "pushed"
  },
  {
    uuid: "491615f0-403d-4c42-bc5e-73982962efe6",
    iconSet: "FontAwesome5",
    iconName: "puzzle-piece"
  },
  {
    uuid: "d2626635-5be6-4d5d-af37-92e1a70e1b33",
    iconSet: "FontAwesome5",
    iconName: "python"
  },
  {
    uuid: "e924a5fa-6723-47e4-b002-b85f9243d7ef",
    iconSet: "FontAwesome5",
    iconName: "qq"
  },
  {
    uuid: "1510c6e3-06c0-452a-97dd-a8c0815b3aa4",
    iconSet: "FontAwesome5",
    iconName: "qrcode"
  },
  {
    uuid: "b05363e7-0087-4760-9ca1-25edb7ad1ae5",
    iconSet: "FontAwesome5",
    iconName: "question"
  },
  {
    uuid: "dc7a5faf-3cf8-41b7-9428-86959f29441f",
    iconSet: "FontAwesome5",
    iconName: "question-circle"
  },
  {
    uuid: "e5bec4e7-5fe5-439f-8989-34b4541d12c1",
    iconSet: "FontAwesome5",
    iconName: "quidditch"
  },
  {
    uuid: "18cd6ccb-2bf8-4203-b060-0d994aa1a1bb",
    iconSet: "FontAwesome5",
    iconName: "quinscape"
  },
  {
    uuid: "21300ebf-f3f2-4965-b744-3fc4759a37d2",
    iconSet: "FontAwesome5",
    iconName: "quora"
  },
  {
    uuid: "8114cfca-abc0-4f8e-af60-b66f943c5c0f",
    iconSet: "FontAwesome5",
    iconName: "quote-left"
  },
  {
    uuid: "7a9524ed-0edf-4f15-a434-59f5f5edeb50",
    iconSet: "FontAwesome5",
    iconName: "quote-right"
  },
  {
    uuid: "79af1612-e57a-484c-a252-6621e856cedf",
    iconSet: "FontAwesome5",
    iconName: "quran"
  },
  {
    uuid: "094f9742-f5ef-4c30-86a5-8afee72f75d8",
    iconSet: "FontAwesome5",
    iconName: "r-project"
  },
  {
    uuid: "e6532755-034d-489d-add2-b63c02420516",
    iconSet: "FontAwesome5",
    iconName: "radiation"
  },
  {
    uuid: "00a6f63d-b097-43e5-8254-81a39f426c69",
    iconSet: "FontAwesome5",
    iconName: "radiation-alt"
  },
  {
    uuid: "558419cf-914e-4994-8f0e-0378d27da82b",
    iconSet: "FontAwesome5",
    iconName: "rainbow"
  },
  {
    uuid: "7498db33-720b-4ff2-8d35-19fc8471b611",
    iconSet: "FontAwesome5",
    iconName: "random"
  },
  {
    uuid: "3f54f3b7-7ec8-4f4c-a2a8-31ff8f648b9e",
    iconSet: "FontAwesome5",
    iconName: "raspberry-pi"
  },
  {
    uuid: "7211242a-ea04-41d8-b2d4-605fc63b2dbe",
    iconSet: "FontAwesome5",
    iconName: "ravelry"
  },
  {
    uuid: "a3a8cb0d-7dd3-4a08-a317-999d8f536dc4",
    iconSet: "FontAwesome5",
    iconName: "react"
  },
  {
    uuid: "ea207b08-9fc2-4bcb-8cfe-8915510b182c",
    iconSet: "FontAwesome5",
    iconName: "reacteurope"
  },
  {
    uuid: "de82233f-23a5-42c8-93f6-91a06b3db759",
    iconSet: "FontAwesome5",
    iconName: "readme"
  },
  {
    uuid: "d380853e-3601-44a3-949b-ed1e804aa481",
    iconSet: "FontAwesome5",
    iconName: "rebel"
  },
  {
    uuid: "cd32aa51-96b6-4453-aa1c-e7fba0f818d6",
    iconSet: "FontAwesome5",
    iconName: "receipt"
  },
  {
    uuid: "27487ee9-caf0-4445-846e-d61af9a716c8",
    iconSet: "FontAwesome5",
    iconName: "record-vinyl"
  },
  {
    uuid: "9c263bf6-c48b-4230-8453-62f95fe9f066",
    iconSet: "FontAwesome5",
    iconName: "recycle"
  },
  {
    uuid: "97d74774-e211-43f3-9f4b-5eb73c33361b",
    iconSet: "FontAwesome5",
    iconName: "red-river"
  },
  {
    uuid: "dab7780b-efca-4c4f-ad65-b3e2c9986b8b",
    iconSet: "FontAwesome5",
    iconName: "reddit"
  },
  {
    uuid: "af36433d-a0a3-4db0-8ac6-1dcf0773e584",
    iconSet: "FontAwesome5",
    iconName: "reddit-alien"
  },
  {
    uuid: "7a4d92c7-9969-48d4-b690-4b6acc3697d0",
    iconSet: "FontAwesome5",
    iconName: "reddit-square"
  },
  {
    uuid: "a488c92b-675e-493d-ad6e-859ce46b6b95",
    iconSet: "FontAwesome5",
    iconName: "redhat"
  },
  {
    uuid: "f51f413b-6814-4a8a-aa58-9afd42cb7bf1",
    iconSet: "FontAwesome5",
    iconName: "redo"
  },
  {
    uuid: "51e2527c-aae1-4500-9919-549256a2dcf0",
    iconSet: "FontAwesome5",
    iconName: "redo-alt"
  },
  {
    uuid: "d7e448aa-028c-4794-ac1d-43e05a967fa2",
    iconSet: "FontAwesome5",
    iconName: "registered"
  },
  {
    uuid: "601e04be-8f32-4b48-8353-5bdf89b3fc1b",
    iconSet: "FontAwesome5",
    iconName: "remove-format"
  },
  {
    uuid: "bf0d8d02-3985-4678-b564-9e5eea5a2495",
    iconSet: "FontAwesome5",
    iconName: "renren"
  },
  {
    uuid: "cf567340-e11c-40ff-aa7f-282682c69de0",
    iconSet: "FontAwesome5",
    iconName: "reply"
  },
  {
    uuid: "ad9608f0-0db6-4193-9090-4ceae4472b5b",
    iconSet: "FontAwesome5",
    iconName: "reply-all"
  },
  {
    uuid: "cedf4b1f-59df-40eb-aa8e-c40476d2e9de",
    iconSet: "FontAwesome5",
    iconName: "replyd"
  },
  {
    uuid: "f92ad909-d436-4b81-918f-5105a231c6fb",
    iconSet: "FontAwesome5",
    iconName: "republican"
  },
  {
    uuid: "4f9c5349-465e-4afb-9840-f41d3b620162",
    iconSet: "FontAwesome5",
    iconName: "researchgate"
  },
  {
    uuid: "f61c66fb-3802-4c32-bc4e-1ebf74df0e09",
    iconSet: "FontAwesome5",
    iconName: "resolving"
  },
  {
    uuid: "4d0487d9-4503-4962-8151-cfcaba459c1b",
    iconSet: "FontAwesome5",
    iconName: "restroom"
  },
  {
    uuid: "37251502-fbe4-4543-b78c-9f837b2dcb01",
    iconSet: "FontAwesome5",
    iconName: "retweet"
  },
  {
    uuid: "5d203597-e30d-4bcf-b5b4-bb359951d165",
    iconSet: "FontAwesome5",
    iconName: "rev"
  },
  {
    uuid: "495c7699-8662-4b55-b3b0-8f8d82f7517b",
    iconSet: "FontAwesome5",
    iconName: "ribbon"
  },
  {
    uuid: "b19dba84-116c-4fc4-9bc9-25b510c17531",
    iconSet: "FontAwesome5",
    iconName: "ring"
  },
  {
    uuid: "bc813c14-16ad-44af-8343-950486072736",
    iconSet: "FontAwesome5",
    iconName: "road"
  },
  {
    uuid: "3be93bd2-2dca-473a-8cd9-fe85e7414c5e",
    iconSet: "FontAwesome5",
    iconName: "robot"
  },
  {
    uuid: "4d8938b6-b397-4be7-bd27-59ed652cb9c7",
    iconSet: "FontAwesome5",
    iconName: "rocket"
  },
  {
    uuid: "d4389e8e-e905-44bd-a2b6-de614efb421e",
    iconSet: "FontAwesome5",
    iconName: "rocketchat"
  },
  {
    uuid: "41652c54-3652-4556-b694-c6f760633f28",
    iconSet: "FontAwesome5",
    iconName: "rockrms"
  },
  {
    uuid: "fbc6f042-2164-4209-8f5c-94704dbbb0d4",
    iconSet: "FontAwesome5",
    iconName: "route"
  },
  {
    uuid: "ab0df431-1da1-455d-90dc-adf047fa37d0",
    iconSet: "FontAwesome5",
    iconName: "rss"
  },
  {
    uuid: "7d06c275-1a74-498f-8c42-c3451a5ec460",
    iconSet: "FontAwesome5",
    iconName: "rss-square"
  },
  {
    uuid: "1d63a3bd-4b3f-474a-98d3-cbd19f41e372",
    iconSet: "FontAwesome5",
    iconName: "ruble-sign"
  },
  {
    uuid: "e937b960-5d3f-4e50-ba59-efa82d3f45de",
    iconSet: "FontAwesome5",
    iconName: "ruler"
  },
  {
    uuid: "53134e75-2b90-410d-b170-3e620262c1bb",
    iconSet: "FontAwesome5",
    iconName: "ruler-combined"
  },
  {
    uuid: "b235fe63-2574-474f-b57f-b0048d55493d",
    iconSet: "FontAwesome5",
    iconName: "ruler-horizontal"
  },
  {
    uuid: "1ecdee00-a008-4ae7-a80c-4cbc1f7c7424",
    iconSet: "FontAwesome5",
    iconName: "ruler-vertical"
  },
  {
    uuid: "0d8dd3e3-499d-4b70-95dd-d5cd884f8ee3",
    iconSet: "FontAwesome5",
    iconName: "running"
  },
  {
    uuid: "016c33f9-ce91-4641-b9f4-039f60df8c58",
    iconSet: "FontAwesome5",
    iconName: "rupee-sign"
  },
  {
    uuid: "e2941395-4226-4abf-bdf6-dd804ca1dd17",
    iconSet: "FontAwesome5",
    iconName: "sad-cry"
  },
  {
    uuid: "2777da82-6902-47ba-97b3-6121d2d8989f",
    iconSet: "FontAwesome5",
    iconName: "sad-tear"
  },
  {
    uuid: "e7f716a9-5f3b-4708-b224-578e15c02467",
    iconSet: "FontAwesome5",
    iconName: "safari"
  },
  {
    uuid: "977f3d32-2a36-4b26-befc-ee21429dc0fb",
    iconSet: "FontAwesome5",
    iconName: "salesforce"
  },
  {
    uuid: "7fa4eafa-1bac-4a99-ac18-73f9b9f808b0",
    iconSet: "FontAwesome5",
    iconName: "sass"
  },
  {
    uuid: "79a3b6b4-32a5-4090-ae82-25dbed10a6f7",
    iconSet: "FontAwesome5",
    iconName: "satellite"
  },
  {
    uuid: "ad7ea658-6cbf-4bee-86ca-adba3be15d60",
    iconSet: "FontAwesome5",
    iconName: "satellite-dish"
  },
  {
    uuid: "f559990f-af99-4518-900f-6b3e5dc94faf",
    iconSet: "FontAwesome5",
    iconName: "save"
  },
  {
    uuid: "3588fa49-b65a-4d72-b3ff-33d6789ca2d9",
    iconSet: "FontAwesome5",
    iconName: "schlix"
  },
  {
    uuid: "ee624859-0a99-4531-b9cc-55758ec8855b",
    iconSet: "FontAwesome5",
    iconName: "school"
  },
  {
    uuid: "8d41901a-506b-499b-86de-e127e980beb5",
    iconSet: "FontAwesome5",
    iconName: "screwdriver"
  },
  {
    uuid: "6fc82b4d-05d1-4a24-86ae-93dd5eadd57f",
    iconSet: "FontAwesome5",
    iconName: "scribd"
  },
  {
    uuid: "2e0f1fcc-8828-4805-8ab7-afc8cfe9871c",
    iconSet: "FontAwesome5",
    iconName: "scroll"
  },
  {
    uuid: "39a1594e-e81d-4af8-8e58-becca54c43f1",
    iconSet: "FontAwesome5",
    iconName: "sd-card"
  },
  {
    uuid: "b40d60fe-4cda-4381-afbf-ac27db6869b7",
    iconSet: "FontAwesome5",
    iconName: "search"
  },
  {
    uuid: "70c4c1fb-1e1d-447d-b68a-a6562cc8a3dd",
    iconSet: "FontAwesome5",
    iconName: "search-dollar"
  },
  {
    uuid: "041e5e76-3c18-4f35-8a55-3a781cd436b1",
    iconSet: "FontAwesome5",
    iconName: "search-location"
  },
  {
    uuid: "2edd19e9-bb9f-4e35-82f3-71fbcb775e4e",
    iconSet: "FontAwesome5",
    iconName: "search-minus"
  },
  {
    uuid: "c92b2d7b-1735-4f1d-b242-b2ded3a0c161",
    iconSet: "FontAwesome5",
    iconName: "search-plus"
  },
  {
    uuid: "5dab46b7-9e9b-4301-bcf2-afa1e1f43c3e",
    iconSet: "FontAwesome5",
    iconName: "searchengin"
  },
  {
    uuid: "ed8e4610-c16d-47f7-8322-1a627df8c046",
    iconSet: "FontAwesome5",
    iconName: "seedling"
  },
  {
    uuid: "2c7f7464-4fde-4feb-a9ba-a677d940d1ae",
    iconSet: "FontAwesome5",
    iconName: "sellcast"
  },
  {
    uuid: "f88e8a5d-3d12-4242-a598-772f1b4da434",
    iconSet: "FontAwesome5",
    iconName: "sellsy"
  },
  {
    uuid: "1b1f62d7-70cf-444a-84a4-1860d18f9a41",
    iconSet: "FontAwesome5",
    iconName: "server"
  },
  {
    uuid: "85d9d1e6-adb1-4ee6-a236-960ea0139340",
    iconSet: "FontAwesome5",
    iconName: "servicestack"
  },
  {
    uuid: "e08a18d2-0783-44fb-bbf3-bcf825b25115",
    iconSet: "FontAwesome5",
    iconName: "shapes"
  },
  {
    uuid: "216dea1d-c3a4-4dc4-8f6b-a62423dec4ec",
    iconSet: "FontAwesome5",
    iconName: "share"
  },
  {
    uuid: "de8cdc21-67c5-4ca7-bd9f-fe5597c4d345",
    iconSet: "FontAwesome5",
    iconName: "share-alt"
  },
  {
    uuid: "0646ae39-c729-498f-acda-e98bc027c398",
    iconSet: "FontAwesome5",
    iconName: "share-alt-square"
  },
  {
    uuid: "23383ddc-026a-4860-8d15-8bbecbe75abe",
    iconSet: "FontAwesome5",
    iconName: "share-square"
  },
  {
    uuid: "c6f05424-e36a-4205-b6ad-a878bc091cd0",
    iconSet: "FontAwesome5",
    iconName: "shekel-sign"
  },
  {
    uuid: "537db9fb-b60c-45a1-9d40-27127c8b1535",
    iconSet: "FontAwesome5",
    iconName: "shield-alt"
  },
  {
    uuid: "fcaa3943-f9bb-4b51-a748-329a1d9bba33",
    iconSet: "FontAwesome5",
    iconName: "shield-virus"
  },
  {
    uuid: "f1211b8b-9c14-425a-8f7d-edf0419adb26",
    iconSet: "FontAwesome5",
    iconName: "ship"
  },
  {
    uuid: "7a329df3-1eff-4e4f-92ff-0496e13054b9",
    iconSet: "FontAwesome5",
    iconName: "shipping-fast"
  },
  {
    uuid: "de275a19-1ab9-499e-87de-224af9e761c9",
    iconSet: "FontAwesome5",
    iconName: "shirtsinbulk"
  },
  {
    uuid: "1d3b531b-1349-4c67-a62e-40d2db1d6a43",
    iconSet: "FontAwesome5",
    iconName: "shoe-prints"
  },
  {
    uuid: "93f5c33c-b35a-420b-a293-984bf5f93afb",
    iconSet: "FontAwesome5",
    iconName: "shopify"
  },
  {
    uuid: "52957879-14d7-4b33-b526-fa3ffb093139",
    iconSet: "FontAwesome5",
    iconName: "shopping-bag"
  },
  {
    uuid: "c4637af9-b644-4911-b11c-bbb3bd092301",
    iconSet: "FontAwesome5",
    iconName: "shopping-basket"
  },
  {
    uuid: "2a58831e-d982-4944-a041-7f05fcead1c6",
    iconSet: "FontAwesome5",
    iconName: "shopping-cart"
  },
  {
    uuid: "f765e8d7-09d1-4f23-9a4a-0d111fab8dac",
    iconSet: "FontAwesome5",
    iconName: "shopware"
  },
  {
    uuid: "02934803-233a-4790-b0d7-813c4e7a61e3",
    iconSet: "FontAwesome5",
    iconName: "shower"
  },
  {
    uuid: "9f057a31-0479-42bf-99de-695f1a9198c3",
    iconSet: "FontAwesome5",
    iconName: "shuttle-van"
  },
  {
    uuid: "f4772f8d-cfc2-40b5-82b7-2e254fb2496b",
    iconSet: "FontAwesome5",
    iconName: "sign"
  },
  {
    uuid: "a51a9ede-906d-45bf-b963-52da02e4457b",
    iconSet: "FontAwesome5",
    iconName: "sign-in-alt"
  },
  {
    uuid: "6e7217f0-6652-4f1c-b9db-a2467a93c54f",
    iconSet: "FontAwesome5",
    iconName: "sign-language"
  },
  {
    uuid: "6a93c606-8c31-4c96-bd2c-d7d1bb556754",
    iconSet: "FontAwesome5",
    iconName: "sign-out-alt"
  },
  {
    uuid: "e1b8dffa-38c7-43b1-85e8-6a21be8ddb55",
    iconSet: "FontAwesome5",
    iconName: "signal"
  },
  {
    uuid: "ec2b74d5-a5b1-4ad1-a750-16d7aa5073c5",
    iconSet: "FontAwesome5",
    iconName: "signature"
  },
  {
    uuid: "db3d855f-fc10-47ef-925e-a48e74438668",
    iconSet: "FontAwesome5",
    iconName: "sim-card"
  },
  {
    uuid: "c887afee-7e74-41a8-ae22-42399ad0bd10",
    iconSet: "FontAwesome5",
    iconName: "simplybuilt"
  },
  {
    uuid: "1680c0b1-dfe5-43c9-8249-4820085f703e",
    iconSet: "FontAwesome5",
    iconName: "sistrix"
  },
  {
    uuid: "b588ea14-dd9d-40bd-b1ba-7426de886e9e",
    iconSet: "FontAwesome5",
    iconName: "sitemap"
  },
  {
    uuid: "b54e4634-825f-4f8c-9769-853e6b35a76c",
    iconSet: "FontAwesome5",
    iconName: "sith"
  },
  {
    uuid: "ab3ff8ac-ad1e-4a99-a1db-e939b24cf6ef",
    iconSet: "FontAwesome5",
    iconName: "skating"
  },
  {
    uuid: "c0d904f1-9192-4876-8392-7fcd33d88101",
    iconSet: "FontAwesome5",
    iconName: "sketch"
  },
  {
    uuid: "43a7eda0-57c5-427b-a2b5-3926f28845ee",
    iconSet: "FontAwesome5",
    iconName: "skiing"
  },
  {
    uuid: "47ff5787-12d8-4043-956b-003f5af51b23",
    iconSet: "FontAwesome5",
    iconName: "skiing-nordic"
  },
  {
    uuid: "cfce935f-2e37-4849-a397-4b43c993fabf",
    iconSet: "FontAwesome5",
    iconName: "skull"
  },
  {
    uuid: "487ffd8e-2654-4800-b56e-cbc58e33d0c2",
    iconSet: "FontAwesome5",
    iconName: "skull-crossbones"
  },
  {
    uuid: "534bfd82-39ce-42e1-8a62-731d9205f5fc",
    iconSet: "FontAwesome5",
    iconName: "skyatlas"
  },
  {
    uuid: "01d45325-27c0-4e9f-8ced-8dc660bd733d",
    iconSet: "FontAwesome5",
    iconName: "skype"
  },
  {
    uuid: "8516cd7a-c4e3-4b97-ab75-1ae626e8348f",
    iconSet: "FontAwesome5",
    iconName: "slack"
  },
  {
    uuid: "bde23c60-39a6-4476-bb2b-996f62a9bce5",
    iconSet: "FontAwesome5",
    iconName: "slack-hash"
  },
  {
    uuid: "f2cd7ac4-dee7-44f5-aa93-69020c6378f6",
    iconSet: "FontAwesome5",
    iconName: "slash"
  },
  {
    uuid: "1b70e5eb-e975-4c7a-90ed-10ed564690ed",
    iconSet: "FontAwesome5",
    iconName: "sleigh"
  },
  {
    uuid: "6dd11b64-1cee-43ac-9660-e86c9c4e9f17",
    iconSet: "FontAwesome5",
    iconName: "sliders-h"
  },
  {
    uuid: "3e454d01-83ff-4780-b914-b7fdd756cc25",
    iconSet: "FontAwesome5",
    iconName: "slideshare"
  },
  {
    uuid: "24dad2ea-7e43-4a0a-b441-424a83154860",
    iconSet: "FontAwesome5",
    iconName: "smile"
  },
  {
    uuid: "56e47c17-da8e-46b6-8ca9-c46795e3e617",
    iconSet: "FontAwesome5",
    iconName: "smile-beam"
  },
  {
    uuid: "9f16fde4-a2d3-4b8b-8636-4fe4f5899de9",
    iconSet: "FontAwesome5",
    iconName: "smile-wink"
  },
  {
    uuid: "7d8ff5aa-a1bb-4823-b431-de8dd4e245cc",
    iconSet: "FontAwesome5",
    iconName: "smog"
  },
  {
    uuid: "95da9d99-af00-451a-bc94-3f4d93a80c3b",
    iconSet: "FontAwesome5",
    iconName: "smoking"
  },
  {
    uuid: "f29019c0-f483-44fd-9ecd-229bd535e7ad",
    iconSet: "FontAwesome5",
    iconName: "smoking-ban"
  },
  {
    uuid: "9db428c6-5df8-4997-a468-a0bf8841fe49",
    iconSet: "FontAwesome5",
    iconName: "sms"
  },
  {
    uuid: "3dbf031e-ad3d-4978-9f11-f6f76c515f19",
    iconSet: "FontAwesome5",
    iconName: "snapchat"
  },
  {
    uuid: "45fa716a-3346-48a2-a849-689c456e5287",
    iconSet: "FontAwesome5",
    iconName: "snapchat-ghost"
  },
  {
    uuid: "24ea3088-727a-4ddf-9842-3dcf8819463a",
    iconSet: "FontAwesome5",
    iconName: "snapchat-square"
  },
  {
    uuid: "67768d59-3fef-4f8b-9c9f-3e687a9b928d",
    iconSet: "FontAwesome5",
    iconName: "snowboarding"
  },
  {
    uuid: "848cbe34-1aaa-4fec-a717-84c47e53695c",
    iconSet: "FontAwesome5",
    iconName: "snowflake"
  },
  {
    uuid: "1efbbec9-78bb-410b-987a-d78a45d47e13",
    iconSet: "FontAwesome5",
    iconName: "snowman"
  },
  {
    uuid: "78b1568f-1cb6-4330-91c7-be0702741344",
    iconSet: "FontAwesome5",
    iconName: "snowplow"
  },
  {
    uuid: "c185c41a-cf03-4625-a7b4-e292ea82ca14",
    iconSet: "FontAwesome5",
    iconName: "soap"
  },
  {
    uuid: "2a368e72-c17a-4c46-a711-4e419a71556a",
    iconSet: "FontAwesome5",
    iconName: "socks"
  },
  {
    uuid: "d696623f-ee58-426d-9c4f-28e7519cfdb5",
    iconSet: "FontAwesome5",
    iconName: "solar-panel"
  },
  {
    uuid: "31d719a2-f513-4883-afab-57049ef2c6ea",
    iconSet: "FontAwesome5",
    iconName: "sort"
  },
  {
    uuid: "ced6d69f-4e02-4de1-b300-978b912512a6",
    iconSet: "FontAwesome5",
    iconName: "sort-alpha-down"
  },
  {
    uuid: "e138a03e-dc17-435d-911f-cbd968b286e7",
    iconSet: "FontAwesome5",
    iconName: "sort-alpha-down-alt"
  },
  {
    uuid: "1aa909d8-6cc1-49ab-8e2f-5feb2230bb4a",
    iconSet: "FontAwesome5",
    iconName: "sort-alpha-up"
  },
  {
    uuid: "7fbf5910-4c0d-4fa2-9c06-4e303aa0bc58",
    iconSet: "FontAwesome5",
    iconName: "sort-alpha-up-alt"
  },
  {
    uuid: "ce19ceff-654b-408f-9795-59b0ae49b20c",
    iconSet: "FontAwesome5",
    iconName: "sort-amount-down"
  },
  {
    uuid: "1b9126eb-f5c0-4902-9d84-16849551a60f",
    iconSet: "FontAwesome5",
    iconName: "sort-amount-down-alt"
  },
  {
    uuid: "d0f65c69-60ed-4411-bf95-1d5591156810",
    iconSet: "FontAwesome5",
    iconName: "sort-amount-up"
  },
  {
    uuid: "05602e5d-0c46-4b34-bb27-a17b09f28034",
    iconSet: "FontAwesome5",
    iconName: "sort-amount-up-alt"
  },
  {
    uuid: "88cd2d9f-0390-4bdd-a6d7-fc7d2623e7b7",
    iconSet: "FontAwesome5",
    iconName: "sort-down"
  },
  {
    uuid: "26021ac0-636e-45f6-825f-2e3bafac8fd2",
    iconSet: "FontAwesome5",
    iconName: "sort-numeric-down"
  },
  {
    uuid: "14e05093-4138-4874-8178-397199be16aa",
    iconSet: "FontAwesome5",
    iconName: "sort-numeric-down-alt"
  },
  {
    uuid: "cc16b44c-1911-4df2-a850-6f89ea6e6b81",
    iconSet: "FontAwesome5",
    iconName: "sort-numeric-up"
  },
  {
    uuid: "230d630d-ed65-4991-a7cd-bde05b273b77",
    iconSet: "FontAwesome5",
    iconName: "sort-numeric-up-alt"
  },
  {
    uuid: "e8c0c0ea-e7b7-424c-afc8-5711cc16638d",
    iconSet: "FontAwesome5",
    iconName: "sort-up"
  },
  {
    uuid: "bfa8a3fa-0c50-4de5-af88-feaf4951acce",
    iconSet: "FontAwesome5",
    iconName: "soundcloud"
  },
  {
    uuid: "adcdcb37-bdb9-454c-9ec5-f4ef65b23b38",
    iconSet: "FontAwesome5",
    iconName: "sourcetree"
  },
  {
    uuid: "b7a2e682-7c0c-43a6-983d-1813a6117991",
    iconSet: "FontAwesome5",
    iconName: "spa"
  },
  {
    uuid: "9f494575-5190-42dd-b16b-6e1f6de0a457",
    iconSet: "FontAwesome5",
    iconName: "space-shuttle"
  },
  {
    uuid: "53abf257-871d-4b7f-96ec-5d8ef7077941",
    iconSet: "FontAwesome5",
    iconName: "speakap"
  },
  {
    uuid: "70689568-0236-4e4f-97b2-f3eef4939f44",
    iconSet: "FontAwesome5",
    iconName: "speaker-deck"
  },
  {
    uuid: "845a0612-2977-48aa-90ef-10ed688ffb24",
    iconSet: "FontAwesome5",
    iconName: "spell-check"
  },
  {
    uuid: "8b3a8ed3-0352-40ad-9cda-b63f789fb069",
    iconSet: "FontAwesome5",
    iconName: "spider"
  },
  {
    uuid: "ec0ab196-2cbd-45bb-bad4-a32c6c58ac5e",
    iconSet: "FontAwesome5",
    iconName: "spinner"
  },
  {
    uuid: "87ad01e3-947d-4cb6-9e29-17ba0aceb0d1",
    iconSet: "FontAwesome5",
    iconName: "splotch"
  },
  {
    uuid: "7d52f389-0fb0-4e67-90f7-649226627dd8",
    iconSet: "FontAwesome5",
    iconName: "spotify"
  },
  {
    uuid: "93783446-04d2-4dad-9bb8-73ea4bcc3b4d",
    iconSet: "FontAwesome5",
    iconName: "spray-can"
  },
  {
    uuid: "986a550c-fa12-46d6-a5bf-e07c6c05ae9c",
    iconSet: "FontAwesome5",
    iconName: "square"
  },
  {
    uuid: "272e375e-e8d2-441c-8b6e-f48cffde83d0",
    iconSet: "FontAwesome5",
    iconName: "square-full"
  },
  {
    uuid: "de0b5b1e-49e4-49e1-9709-a898180ddae2",
    iconSet: "FontAwesome5",
    iconName: "square-root-alt"
  },
  {
    uuid: "3620a788-4665-4529-a611-e21e9f824ae8",
    iconSet: "FontAwesome5",
    iconName: "squarespace"
  },
  {
    uuid: "7ce02bf2-ce6d-4351-9517-9b7069b93075",
    iconSet: "FontAwesome5",
    iconName: "stack-exchange"
  },
  {
    uuid: "5a2e0f10-803a-4811-a4e5-a82deeaca7aa",
    iconSet: "FontAwesome5",
    iconName: "stack-overflow"
  },
  {
    uuid: "20ab2f06-0d84-48a9-b41b-9378d7caf0a7",
    iconSet: "FontAwesome5",
    iconName: "stackpath"
  },
  {
    uuid: "66dfc015-023c-4a49-b1ab-474bca761ec9",
    iconSet: "FontAwesome5",
    iconName: "stamp"
  },
  {
    uuid: "c89a369f-eca9-426d-bf14-5edc86526acc",
    iconSet: "FontAwesome5",
    iconName: "star"
  },
  {
    uuid: "2c951a5a-7651-4710-8350-c8c46dc4256d",
    iconSet: "FontAwesome5",
    iconName: "star-and-crescent"
  },
  {
    uuid: "18b98014-3d26-4176-8e46-43eb42262e79",
    iconSet: "FontAwesome5",
    iconName: "star-half"
  },
  {
    uuid: "acc6b71f-3105-42f8-b140-45d458ad6b49",
    iconSet: "FontAwesome5",
    iconName: "star-half-alt"
  },
  {
    uuid: "b581680d-2d05-46ed-bc72-b84ff397ae1f",
    iconSet: "FontAwesome5",
    iconName: "star-of-david"
  },
  {
    uuid: "001090a3-8ad2-470b-b8dc-45a45ffa8cd2",
    iconSet: "FontAwesome5",
    iconName: "star-of-life"
  },
  {
    uuid: "c2d44420-73f6-4172-ae3c-5f8ec6d2a2b7",
    iconSet: "FontAwesome5",
    iconName: "staylinked"
  },
  {
    uuid: "05c312ca-6a68-44d2-8164-c337c6823140",
    iconSet: "FontAwesome5",
    iconName: "steam"
  },
  {
    uuid: "55d251d5-fb8f-4d88-833b-1c9719336148",
    iconSet: "FontAwesome5",
    iconName: "steam-square"
  },
  {
    uuid: "166036de-240f-4eb7-acba-f574e2b1e64f",
    iconSet: "FontAwesome5",
    iconName: "steam-symbol"
  },
  {
    uuid: "69b115aa-9f4f-42d7-91f5-a274b318ee8f",
    iconSet: "FontAwesome5",
    iconName: "step-backward"
  },
  {
    uuid: "4152909a-50fe-46cb-98cd-a658e66177d9",
    iconSet: "FontAwesome5",
    iconName: "step-forward"
  },
  {
    uuid: "c318048b-d5b3-48e0-9c4d-44563a326730",
    iconSet: "FontAwesome5",
    iconName: "stethoscope"
  },
  {
    uuid: "a9844b7a-16bd-4103-ab3f-e120faa0a0e7",
    iconSet: "FontAwesome5",
    iconName: "sticker-mule"
  },
  {
    uuid: "a828fcb0-5ff0-4876-bb72-876cf74ac1ea",
    iconSet: "FontAwesome5",
    iconName: "sticky-note"
  },
  {
    uuid: "9aadb6bd-cfca-49bd-809e-2f99679ce95d",
    iconSet: "FontAwesome5",
    iconName: "stop"
  },
  {
    uuid: "5baea00c-4af8-46b7-84b9-0e50252eba83",
    iconSet: "FontAwesome5",
    iconName: "stop-circle"
  },
  {
    uuid: "b6516922-3b20-49c0-813c-987418cfdef3",
    iconSet: "FontAwesome5",
    iconName: "stopwatch"
  },
  {
    uuid: "6637d5b1-a3b6-4313-b31d-a09c70e17b72",
    iconSet: "FontAwesome5",
    iconName: "stopwatch-20"
  },
  {
    uuid: "a251a49f-aeb2-4464-b7c6-e89eb9f820d2",
    iconSet: "FontAwesome5",
    iconName: "store"
  },
  {
    uuid: "a91cff02-5352-40fe-b744-bce689b4e25f",
    iconSet: "FontAwesome5",
    iconName: "store-alt"
  },
  {
    uuid: "e2836cf7-ce58-480d-881d-a12c8f8a5a70",
    iconSet: "FontAwesome5",
    iconName: "store-alt-slash"
  },
  {
    uuid: "8091f002-b738-4a76-a737-2e219245f905",
    iconSet: "FontAwesome5",
    iconName: "store-slash"
  },
  {
    uuid: "389dfd2a-1cc9-4e54-bbae-aa93fbfa19ce",
    iconSet: "FontAwesome5",
    iconName: "strava"
  },
  {
    uuid: "649a233e-bb95-46dd-946c-c9baeedf95b3",
    iconSet: "FontAwesome5",
    iconName: "stream"
  },
  {
    uuid: "7bf6d331-c071-4b94-b955-b82f5b8b2ae8",
    iconSet: "FontAwesome5",
    iconName: "street-view"
  },
  {
    uuid: "c15121ef-6d6c-43dc-aeae-60e9e4402c7d",
    iconSet: "FontAwesome5",
    iconName: "strikethrough"
  },
  {
    uuid: "1567d04e-0e84-41fa-9cf8-9942f096e9e1",
    iconSet: "FontAwesome5",
    iconName: "stripe"
  },
  {
    uuid: "0cbc5d85-a5c2-44a8-9b5d-cf046260b02b",
    iconSet: "FontAwesome5",
    iconName: "stripe-s"
  },
  {
    uuid: "47e28f98-c83c-4202-8dba-25dbf031287f",
    iconSet: "FontAwesome5",
    iconName: "stroopwafel"
  },
  {
    uuid: "a6f996ef-efa8-4f01-b608-6906da606510",
    iconSet: "FontAwesome5",
    iconName: "studiovinari"
  },
  {
    uuid: "1b6d235b-39d6-47b0-92b2-00aeda4e2479",
    iconSet: "FontAwesome5",
    iconName: "stumbleupon"
  },
  {
    uuid: "16af4f1d-b057-4434-a76b-875379ddb08b",
    iconSet: "FontAwesome5",
    iconName: "stumbleupon-circle"
  },
  {
    uuid: "fdd87203-c923-4421-b10b-a1a44f9af474",
    iconSet: "FontAwesome5",
    iconName: "subscript"
  },
  {
    uuid: "8a92fd29-c3b1-46f7-b496-863d146afa00",
    iconSet: "FontAwesome5",
    iconName: "subway"
  },
  {
    uuid: "a7dbefbe-f324-4023-a9b4-581cb4ec9887",
    iconSet: "FontAwesome5",
    iconName: "suitcase"
  },
  {
    uuid: "2930e004-1800-431b-91e4-57c89757c49a",
    iconSet: "FontAwesome5",
    iconName: "suitcase-rolling"
  },
  {
    uuid: "3960a182-c06d-407e-9b66-ac059e81ee90",
    iconSet: "FontAwesome5",
    iconName: "sun"
  },
  {
    uuid: "edc3d39c-32b4-4a66-8df4-75ecdc3dfb78",
    iconSet: "FontAwesome5",
    iconName: "superpowers"
  },
  {
    uuid: "761e4238-d3f4-41d1-9520-ed6965667466",
    iconSet: "FontAwesome5",
    iconName: "superscript"
  },
  {
    uuid: "540e0caf-6211-46a5-bb58-e118d02dc622",
    iconSet: "FontAwesome5",
    iconName: "supple"
  },
  {
    uuid: "ac6ac4b7-3726-4d93-a9fd-d3c1d0e19a4c",
    iconSet: "FontAwesome5",
    iconName: "surprise"
  },
  {
    uuid: "ea1a4079-db1c-43c5-8705-7909d043ee1a",
    iconSet: "FontAwesome5",
    iconName: "suse"
  },
  {
    uuid: "b18b7fe8-80c9-4cf1-bf93-2271b1318823",
    iconSet: "FontAwesome5",
    iconName: "swatchbook"
  },
  {
    uuid: "4bd6a534-3299-4664-9875-66ccfe85e991",
    iconSet: "FontAwesome5",
    iconName: "swift"
  },
  {
    uuid: "2339972f-d5d1-4ef0-a5ff-8dbb491231b5",
    iconSet: "FontAwesome5",
    iconName: "swimmer"
  },
  {
    uuid: "ec411295-c4bc-47a1-8557-76db08f54f0f",
    iconSet: "FontAwesome5",
    iconName: "swimming-pool"
  },
  {
    uuid: "018db484-3ca6-4747-9226-0ba643f91b1b",
    iconSet: "FontAwesome5",
    iconName: "symfony"
  },
  {
    uuid: "85a60336-beac-41ff-9d44-287f389cfbae",
    iconSet: "FontAwesome5",
    iconName: "synagogue"
  },
  {
    uuid: "7632f389-8b41-40fd-a3bd-5b139ee2d30d",
    iconSet: "FontAwesome5",
    iconName: "sync"
  },
  {
    uuid: "86336e50-343c-4e11-a791-2316bb4ec9f5",
    iconSet: "FontAwesome5",
    iconName: "sync-alt"
  },
  {
    uuid: "f17d6112-9afa-4cf6-83c0-58402325d3d1",
    iconSet: "FontAwesome5",
    iconName: "syringe"
  },
  {
    uuid: "7921bdbd-18b3-408d-98db-caca89fbe966",
    iconSet: "FontAwesome5",
    iconName: "table"
  },
  {
    uuid: "ca79edd2-16c2-49d8-baf9-7d88d206f710",
    iconSet: "FontAwesome5",
    iconName: "table-tennis"
  },
  {
    uuid: "bfc904ec-578c-46ad-9af0-8915724475ff",
    iconSet: "FontAwesome5",
    iconName: "tablet"
  },
  {
    uuid: "b9480f9a-59f5-488f-9f38-dc7c07bb7b6e",
    iconSet: "FontAwesome5",
    iconName: "tablet-alt"
  },
  {
    uuid: "ee2c8bc6-208f-4bc9-9fb6-978e35971ff7",
    iconSet: "FontAwesome5",
    iconName: "tablets"
  },
  {
    uuid: "6036a0ae-5fcd-45c8-a620-890370541275",
    iconSet: "FontAwesome5",
    iconName: "tachometer-alt"
  },
  {
    uuid: "5cfbe8c4-73b2-4988-bdb3-f93bcd0c75b4",
    iconSet: "FontAwesome5",
    iconName: "tag"
  },
  {
    uuid: "419f4b16-fef5-4628-8121-d46fb2353bc9",
    iconSet: "FontAwesome5",
    iconName: "tags"
  },
  {
    uuid: "4083b2c1-eff1-475d-b6cf-875ded7d6304",
    iconSet: "FontAwesome5",
    iconName: "tape"
  },
  {
    uuid: "a8e31a93-14a7-42aa-b3af-b3012069b567",
    iconSet: "FontAwesome5",
    iconName: "tasks"
  },
  {
    uuid: "99c3e696-e405-4aea-a0ca-57bded739691",
    iconSet: "FontAwesome5",
    iconName: "taxi"
  },
  {
    uuid: "673c69a1-1a1e-4ef4-b566-c59947fa6b3e",
    iconSet: "FontAwesome5",
    iconName: "teamspeak"
  },
  {
    uuid: "22fc5e1b-71ef-4664-8328-ebe5d5d303f7",
    iconSet: "FontAwesome5",
    iconName: "teeth"
  },
  {
    uuid: "12cf9cde-6522-4327-9320-4d1cd8b45a09",
    iconSet: "FontAwesome5",
    iconName: "teeth-open"
  },
  {
    uuid: "dbddbc48-2dbe-4181-ae1c-a1308ce29aff",
    iconSet: "FontAwesome5",
    iconName: "telegram"
  },
  {
    uuid: "4758a1df-ffaf-49f8-897e-a5f0653404f5",
    iconSet: "FontAwesome5",
    iconName: "telegram-plane"
  },
  {
    uuid: "5be5ff3a-db9b-40e8-ba74-b3ca0bf84673",
    iconSet: "FontAwesome5",
    iconName: "temperature-high"
  },
  {
    uuid: "d2f8c59a-fab2-4b44-b4d9-522169ed377f",
    iconSet: "FontAwesome5",
    iconName: "temperature-low"
  },
  {
    uuid: "4609a8c5-1d6f-45b3-a2bf-7619e96c3dc2",
    iconSet: "FontAwesome5",
    iconName: "tencent-weibo"
  },
  {
    uuid: "630edc5c-29a7-43a8-8703-10a1769b67a9",
    iconSet: "FontAwesome5",
    iconName: "tenge"
  },
  {
    uuid: "af5113eb-3968-4704-af05-4d41949d5cde",
    iconSet: "FontAwesome5",
    iconName: "terminal"
  },
  {
    uuid: "3c4dd2fd-eaee-4cfd-9bea-0c4ddd400676",
    iconSet: "FontAwesome5",
    iconName: "text-height"
  },
  {
    uuid: "cb54acd4-f808-43be-b7c7-b10049b988be",
    iconSet: "FontAwesome5",
    iconName: "text-width"
  },
  {
    uuid: "2ece83bd-cfde-4bc0-8bcd-71c6ce6519f8",
    iconSet: "FontAwesome5",
    iconName: "th"
  },
  {
    uuid: "28e8f3b5-603a-40f7-8728-643b69d35946",
    iconSet: "FontAwesome5",
    iconName: "th-large"
  },
  {
    uuid: "ac9b4427-9580-4398-b532-40a1a2dc5e1e",
    iconSet: "FontAwesome5",
    iconName: "th-list"
  },
  {
    uuid: "9d7482b2-4fa7-45d7-9fdd-3a26779a48fe",
    iconSet: "FontAwesome5",
    iconName: "the-red-yeti"
  },
  {
    uuid: "5942f4b1-e213-4e9a-ba9b-af6a8cc2600a",
    iconSet: "FontAwesome5",
    iconName: "theater-masks"
  },
  {
    uuid: "972e0d1a-6dea-453f-9ead-90b8ac34f163",
    iconSet: "FontAwesome5",
    iconName: "themeco"
  },
  {
    uuid: "8ccfa5a7-cf96-4316-b75f-9959c4bd4813",
    iconSet: "FontAwesome5",
    iconName: "themeisle"
  },
  {
    uuid: "c69a1bc1-b6ab-45a0-8dce-7ffabe74584e",
    iconSet: "FontAwesome5",
    iconName: "thermometer"
  },
  {
    uuid: "f24beda2-ef96-44c0-938e-276cb6c4ff13",
    iconSet: "FontAwesome5",
    iconName: "thermometer-empty"
  },
  {
    uuid: "1fdced41-90d7-48f3-837e-0013b79e81e8",
    iconSet: "FontAwesome5",
    iconName: "thermometer-full"
  },
  {
    uuid: "523dd920-9589-41ce-a8e7-a5dc10bcbb76",
    iconSet: "FontAwesome5",
    iconName: "thermometer-half"
  },
  {
    uuid: "ac4b5b0c-6223-4390-8505-7e5e390689bd",
    iconSet: "FontAwesome5",
    iconName: "thermometer-quarter"
  },
  {
    uuid: "264afa50-28c7-4889-a315-cbe073751a9e",
    iconSet: "FontAwesome5",
    iconName: "thermometer-three-quarters"
  },
  {
    uuid: "a709280d-555e-42fa-80c3-e8b3717d7fd1",
    iconSet: "FontAwesome5",
    iconName: "think-peaks"
  },
  {
    uuid: "2720ee8c-8625-455a-a16f-b3a1ca6d51cc",
    iconSet: "FontAwesome5",
    iconName: "thumbs-down"
  },
  {
    uuid: "63731915-3c07-4372-9434-addcf54f483c",
    iconSet: "FontAwesome5",
    iconName: "thumbs-up"
  },
  {
    uuid: "66b1136a-1e34-4447-984c-d923dc25b7cb",
    iconSet: "FontAwesome5",
    iconName: "thumbtack"
  },
  {
    uuid: "a29c2b5d-6f00-4c35-99ea-f46fe4b9b437",
    iconSet: "FontAwesome5",
    iconName: "ticket-alt"
  },
  {
    uuid: "c8955196-8520-43c7-8051-5f12bf7dcf87",
    iconSet: "FontAwesome5",
    iconName: "times"
  },
  {
    uuid: "f212ba6b-c41b-4c59-a06c-e1065acd7367",
    iconSet: "FontAwesome5",
    iconName: "times-circle"
  },
  {
    uuid: "219bc958-400a-4dff-b14a-3926f272d26c",
    iconSet: "FontAwesome5",
    iconName: "tint"
  },
  {
    uuid: "7083e592-3fee-4498-b4a0-73b6d40405c5",
    iconSet: "FontAwesome5",
    iconName: "tint-slash"
  },
  {
    uuid: "9aeb2897-0f5c-42e5-a033-3c56cbc9065b",
    iconSet: "FontAwesome5",
    iconName: "tired"
  },
  {
    uuid: "4b14978a-59c0-4ed4-9e18-c1219624975c",
    iconSet: "FontAwesome5",
    iconName: "toggle-off"
  },
  {
    uuid: "17d7e7ca-b3db-46c4-ae7e-68a5a32410eb",
    iconSet: "FontAwesome5",
    iconName: "toggle-on"
  },
  {
    uuid: "139fa2a4-5504-47bf-8ab1-7b75869f1918",
    iconSet: "FontAwesome5",
    iconName: "toilet"
  },
  {
    uuid: "8f29a140-9aa0-4bc8-814f-a53a6640303f",
    iconSet: "FontAwesome5",
    iconName: "toilet-paper"
  },
  {
    uuid: "de02cd37-5ca8-43ba-91d5-c8c6dc34dd46",
    iconSet: "FontAwesome5",
    iconName: "toilet-paper-slash"
  },
  {
    uuid: "20ab87ad-5c08-4235-a442-e2abb51ff8f7",
    iconSet: "FontAwesome5",
    iconName: "toolbox"
  },
  {
    uuid: "c292969d-6179-4530-a7db-d52d4e3a81a3",
    iconSet: "FontAwesome5",
    iconName: "tools"
  },
  {
    uuid: "2cac60d2-b935-426b-9334-8a879ef5a8f3",
    iconSet: "FontAwesome5",
    iconName: "tooth"
  },
  {
    uuid: "dbc1d2e4-72fe-4a4e-8e1e-2151c8a2abed",
    iconSet: "FontAwesome5",
    iconName: "torah"
  },
  {
    uuid: "52c4ecbe-082d-436b-9512-c3a02e13d0eb",
    iconSet: "FontAwesome5",
    iconName: "torii-gate"
  },
  {
    uuid: "d5b1d038-6422-437f-b7c2-2bf39f27ea19",
    iconSet: "FontAwesome5",
    iconName: "tractor"
  },
  {
    uuid: "9c3f49bf-716d-4829-8359-2094b90c8d03",
    iconSet: "FontAwesome5",
    iconName: "trade-federation"
  },
  {
    uuid: "48dc634c-cc18-4a04-ab68-f5652d64430b",
    iconSet: "FontAwesome5",
    iconName: "trademark"
  },
  {
    uuid: "d053c65e-aaf2-4734-bc22-06f7e9b5e778",
    iconSet: "FontAwesome5",
    iconName: "traffic-light"
  },
  {
    uuid: "edccdaf8-80f9-44a8-abab-ebea9a768d6a",
    iconSet: "FontAwesome5",
    iconName: "trailer"
  },
  {
    uuid: "353d42d6-c5cc-4944-bacd-23b4ebbf42aa",
    iconSet: "FontAwesome5",
    iconName: "train"
  },
  {
    uuid: "75297ade-30e7-4d9a-adf4-8ace94837132",
    iconSet: "FontAwesome5",
    iconName: "tram"
  },
  {
    uuid: "a2b1580a-1e7c-4dd3-abaf-bf0239456a61",
    iconSet: "FontAwesome5",
    iconName: "transgender"
  },
  {
    uuid: "e90a90d6-cdd5-47cb-a649-f1620036e631",
    iconSet: "FontAwesome5",
    iconName: "transgender-alt"
  },
  {
    uuid: "1f9dcc81-61ba-4eeb-b2a3-0551db86f826",
    iconSet: "FontAwesome5",
    iconName: "trash"
  },
  {
    uuid: "b6d1cc3d-288b-44f8-a110-01e74c18ed3e",
    iconSet: "FontAwesome5",
    iconName: "trash-alt"
  },
  {
    uuid: "49f24bf6-0be8-4f34-a43b-35b94ecf31d2",
    iconSet: "FontAwesome5",
    iconName: "trash-restore"
  },
  {
    uuid: "8f99de14-0e8a-419a-aa9d-7ee955ec0211",
    iconSet: "FontAwesome5",
    iconName: "trash-restore-alt"
  },
  {
    uuid: "a43e5ef1-c860-4aec-a7ba-f89a017d19e7",
    iconSet: "FontAwesome5",
    iconName: "tree"
  },
  {
    uuid: "50c5a504-75ed-4e8a-a38e-92fd93b0dd1f",
    iconSet: "FontAwesome5",
    iconName: "trello"
  },
  {
    uuid: "943ba013-4317-478b-91fe-30e72e289f81",
    iconSet: "FontAwesome5",
    iconName: "tripadvisor"
  },
  {
    uuid: "340e7497-30e2-471c-9eef-3a0bed98ecb5",
    iconSet: "FontAwesome5",
    iconName: "trophy"
  },
  {
    uuid: "577ec981-5168-4bfb-a180-eb1870a392d0",
    iconSet: "FontAwesome5",
    iconName: "truck"
  },
  {
    uuid: "e2271f96-6c99-403b-9cdb-fba95f9ad32c",
    iconSet: "FontAwesome5",
    iconName: "truck-loading"
  },
  {
    uuid: "36d0868f-deb6-4c0d-9ed9-beee018c8c3a",
    iconSet: "FontAwesome5",
    iconName: "truck-monster"
  },
  {
    uuid: "fffd15ea-3109-4fe1-8881-73f9348d870a",
    iconSet: "FontAwesome5",
    iconName: "truck-moving"
  },
  {
    uuid: "f3c89346-ec45-42a8-84f0-e48fbdcd3720",
    iconSet: "FontAwesome5",
    iconName: "truck-pickup"
  },
  {
    uuid: "f222861a-0ef7-4107-8943-bb2bc87315c7",
    iconSet: "FontAwesome5",
    iconName: "tshirt"
  },
  {
    uuid: "91a023f6-aace-42bf-bb49-830153a479a9",
    iconSet: "FontAwesome5",
    iconName: "tty"
  },
  {
    uuid: "ab5d7c49-b3c0-46eb-a256-92ff00c5fc73",
    iconSet: "FontAwesome5",
    iconName: "tumblr"
  },
  {
    uuid: "755fbef2-39a3-4940-a07a-24a8facb746e",
    iconSet: "FontAwesome5",
    iconName: "tumblr-square"
  },
  {
    uuid: "edfc94b0-e675-4773-9ee6-0bdbcbc9d55c",
    iconSet: "FontAwesome5",
    iconName: "tv"
  },
  {
    uuid: "8b228d44-ea83-40a1-b5c7-2ad9aa670723",
    iconSet: "FontAwesome5",
    iconName: "twitch"
  },
  {
    uuid: "a7ad1fdf-d833-4ae3-91d5-67925ff183a1",
    iconSet: "FontAwesome5",
    iconName: "twitter"
  },
  {
    uuid: "dd984d74-b3fb-4d6d-9fe2-18c2cb50e4e3",
    iconSet: "FontAwesome5",
    iconName: "twitter-square"
  },
  {
    uuid: "4e7083cb-501b-48e9-aa69-e5ebdf7eb083",
    iconSet: "FontAwesome5",
    iconName: "typo3"
  },
  {
    uuid: "0b0a26f0-47b6-413f-91eb-d001afd7a34a",
    iconSet: "FontAwesome5",
    iconName: "uber"
  },
  {
    uuid: "a4cc161d-1a88-47a2-b04b-57817b073671",
    iconSet: "FontAwesome5",
    iconName: "ubuntu"
  },
  {
    uuid: "750dffa9-d83e-4db1-938e-90ca82982119",
    iconSet: "FontAwesome5",
    iconName: "uikit"
  },
  {
    uuid: "9385c289-d024-46ef-b388-bb82c2aa7ad5",
    iconSet: "FontAwesome5",
    iconName: "umbraco"
  },
  {
    uuid: "9853816b-3b11-448d-b9ec-f193a55e2ab6",
    iconSet: "FontAwesome5",
    iconName: "umbrella"
  },
  {
    uuid: "12168607-8d4c-43ef-8ae5-28e9000d5454",
    iconSet: "FontAwesome5",
    iconName: "umbrella-beach"
  },
  {
    uuid: "ff7cc669-fd5e-43be-93c3-f4f4e09f9cb6",
    iconSet: "FontAwesome5",
    iconName: "underline"
  },
  {
    uuid: "54b4ab75-0511-409b-8c45-4ea124588f5e",
    iconSet: "FontAwesome5",
    iconName: "undo"
  },
  {
    uuid: "939d88d3-5602-43de-b930-f7851c65f6ad",
    iconSet: "FontAwesome5",
    iconName: "undo-alt"
  },
  {
    uuid: "960fc2e2-01d7-46bc-8b59-b84ef4c6ac4d",
    iconSet: "FontAwesome5",
    iconName: "uniregistry"
  },
  {
    uuid: "92012d4a-5495-4df2-bb10-cf5cc4e8a92c",
    iconSet: "FontAwesome5",
    iconName: "unity"
  },
  {
    uuid: "4a494c43-fbee-4ca8-b36a-09800ea35b78",
    iconSet: "FontAwesome5",
    iconName: "universal-access"
  },
  {
    uuid: "0db2dd5e-cb8e-4e8d-af7f-a86cad21cf68",
    iconSet: "FontAwesome5",
    iconName: "university"
  },
  {
    uuid: "723aff3e-b90f-4e51-9239-060623034534",
    iconSet: "FontAwesome5",
    iconName: "unlink"
  },
  {
    uuid: "2ae20e7d-474a-49de-a89a-c29dd3ad70ff",
    iconSet: "FontAwesome5",
    iconName: "unlock"
  },
  {
    uuid: "59d82c01-68a6-44fe-b5f1-90e8a7beaa8b",
    iconSet: "FontAwesome5",
    iconName: "unlock-alt"
  },
  {
    uuid: "3de1ddd6-e991-4ca6-aec8-ca7f429f1410",
    iconSet: "FontAwesome5",
    iconName: "untappd"
  },
  {
    uuid: "74d737a8-6c88-4882-abf1-0744bf58e503",
    iconSet: "FontAwesome5",
    iconName: "upload"
  },
  {
    uuid: "c20114f8-af20-4793-bc24-7f1699a9f194",
    iconSet: "FontAwesome5",
    iconName: "ups"
  },
  {
    uuid: "f6140d47-c6da-4592-b8bd-ae10e8b7dbd2",
    iconSet: "FontAwesome5",
    iconName: "usb"
  },
  {
    uuid: "0533b79a-0176-4bed-94a7-171a73d3aa40",
    iconSet: "FontAwesome5",
    iconName: "user"
  },
  {
    uuid: "465fe12b-d9d5-48f5-9f88-f969d27c5d8d",
    iconSet: "FontAwesome5",
    iconName: "user-alt"
  },
  {
    uuid: "85b85580-2779-4fb5-9dcf-4add530aebe7",
    iconSet: "FontAwesome5",
    iconName: "user-alt-slash"
  },
  {
    uuid: "8deb1407-633c-471a-914d-54f88c46fabb",
    iconSet: "FontAwesome5",
    iconName: "user-astronaut"
  },
  {
    uuid: "0e866c6c-da2b-4852-973d-bc39d769e7a7",
    iconSet: "FontAwesome5",
    iconName: "user-check"
  },
  {
    uuid: "a893dde3-5302-40f1-a697-6c71fed43bea",
    iconSet: "FontAwesome5",
    iconName: "user-circle"
  },
  {
    uuid: "8e1a0d70-aa9e-4988-9a13-c784b784f046",
    iconSet: "FontAwesome5",
    iconName: "user-clock"
  },
  {
    uuid: "8311a1aa-3408-4e34-a13c-88d61c8a84e5",
    iconSet: "FontAwesome5",
    iconName: "user-cog"
  },
  {
    uuid: "b0ec90dd-8381-423f-b3d5-a7d36b52a453",
    iconSet: "FontAwesome5",
    iconName: "user-edit"
  },
  {
    uuid: "1809ef50-c24a-4ad3-b223-d21cabf6d5c3",
    iconSet: "FontAwesome5",
    iconName: "user-friends"
  },
  {
    uuid: "33ed7b7d-9cb9-4f9d-a4fb-9be27b682baa",
    iconSet: "FontAwesome5",
    iconName: "user-graduate"
  },
  {
    uuid: "9febb726-2857-4d07-8716-2012cd6528d2",
    iconSet: "FontAwesome5",
    iconName: "user-injured"
  },
  {
    uuid: "7d16f6f2-eee3-4d48-9510-367a8b9227a4",
    iconSet: "FontAwesome5",
    iconName: "user-lock"
  },
  {
    uuid: "2e755e64-df05-42d2-b8e1-82e4c085918d",
    iconSet: "FontAwesome5",
    iconName: "user-md"
  },
  {
    uuid: "4deed6f7-47dc-47f5-a269-835d5635dd4b",
    iconSet: "FontAwesome5",
    iconName: "user-minus"
  },
  {
    uuid: "c24f708e-271f-4e8f-87e4-383928420b61",
    iconSet: "FontAwesome5",
    iconName: "user-ninja"
  },
  {
    uuid: "4f6a41d4-fa7e-4361-a062-4bbeaba98707",
    iconSet: "FontAwesome5",
    iconName: "user-nurse"
  },
  {
    uuid: "07d7f105-6d63-4915-80fb-ea4a6cbc2d52",
    iconSet: "FontAwesome5",
    iconName: "user-plus"
  },
  {
    uuid: "0742f0a3-ef57-4cf3-bda3-f2bbbd6ef3ea",
    iconSet: "FontAwesome5",
    iconName: "user-secret"
  },
  {
    uuid: "73331d36-be50-40d2-9cbb-8c5d536d1515",
    iconSet: "FontAwesome5",
    iconName: "user-shield"
  },
  {
    uuid: "4daf375a-e725-4534-a98e-801132c6388e",
    iconSet: "FontAwesome5",
    iconName: "user-slash"
  },
  {
    uuid: "768ae146-37d1-4769-a98d-00683614ae2d",
    iconSet: "FontAwesome5",
    iconName: "user-tag"
  },
  {
    uuid: "53c253b0-b1f5-4aca-a382-d1ecd02d613b",
    iconSet: "FontAwesome5",
    iconName: "user-tie"
  },
  {
    uuid: "abd04c1f-9447-4f9c-b60b-0353b0ca3787",
    iconSet: "FontAwesome5",
    iconName: "user-times"
  },
  {
    uuid: "05037f15-a97c-4e52-a6bf-77f29e0233ef",
    iconSet: "FontAwesome5",
    iconName: "users"
  },
  {
    uuid: "58dfc80b-9159-4c9c-987c-a84fc973464d",
    iconSet: "FontAwesome5",
    iconName: "users-cog"
  },
  {
    uuid: "8193f130-2161-4f8d-89dc-70cab4b6519b",
    iconSet: "FontAwesome5",
    iconName: "usps"
  },
  {
    uuid: "a835114d-2145-4109-8d1c-c3e5402270e7",
    iconSet: "FontAwesome5",
    iconName: "ussunnah"
  },
  {
    uuid: "462af5f0-de49-4dc5-bf83-2c6c8e596af8",
    iconSet: "FontAwesome5",
    iconName: "utensil-spoon"
  },
  {
    uuid: "99e00426-1fb8-4ed7-8c9b-577fa32a969e",
    iconSet: "FontAwesome5",
    iconName: "utensils"
  },
  {
    uuid: "f1ff87f1-3eec-42a5-a874-818dd0e440a5",
    iconSet: "FontAwesome5",
    iconName: "vaadin"
  },
  {
    uuid: "de9c5701-2fe4-4f76-8f83-542e1edf60a7",
    iconSet: "FontAwesome5",
    iconName: "vector-square"
  },
  {
    uuid: "cf6acaa0-40de-420a-8c5c-741952a04313",
    iconSet: "FontAwesome5",
    iconName: "venus"
  },
  {
    uuid: "7186c19f-07ff-4ac8-b442-2c898c4518cf",
    iconSet: "FontAwesome5",
    iconName: "venus-double"
  },
  {
    uuid: "81942d8a-1cc5-4566-9b06-ff8dd3289e12",
    iconSet: "FontAwesome5",
    iconName: "venus-mars"
  },
  {
    uuid: "734023b6-66b4-4f66-8217-efd1befe5b45",
    iconSet: "FontAwesome5",
    iconName: "viacoin"
  },
  {
    uuid: "a033a803-8c70-45b0-bc83-20c8067b3857",
    iconSet: "FontAwesome5",
    iconName: "viadeo"
  },
  {
    uuid: "d65dc9ed-b1b5-4548-a795-e5de78ad6a11",
    iconSet: "FontAwesome5",
    iconName: "viadeo-square"
  },
  {
    uuid: "a496570c-2c40-4403-9737-36258e6cc121",
    iconSet: "FontAwesome5",
    iconName: "vial"
  },
  {
    uuid: "3c041909-b351-4317-aabf-0175c3fbc689",
    iconSet: "FontAwesome5",
    iconName: "vials"
  },
  {
    uuid: "1f4de983-029d-4545-ba4d-b43df4f024b7",
    iconSet: "FontAwesome5",
    iconName: "viber"
  },
  {
    uuid: "46eadcdc-f2f1-490d-90ed-5bb3058f0e7f",
    iconSet: "FontAwesome5",
    iconName: "video"
  },
  {
    uuid: "b460b900-6cce-47a4-a14c-fd7316dec463",
    iconSet: "FontAwesome5",
    iconName: "video-slash"
  },
  {
    uuid: "cd4b0294-53dc-450d-8c34-8d81292fd648",
    iconSet: "FontAwesome5",
    iconName: "vihara"
  },
  {
    uuid: "a69de417-7e0d-4328-b70c-cea2774f5253",
    iconSet: "FontAwesome5",
    iconName: "vimeo"
  },
  {
    uuid: "a5630264-abdd-4dba-9235-3cba7af5c057",
    iconSet: "FontAwesome5",
    iconName: "vimeo-square"
  },
  {
    uuid: "39dcc23a-f856-42f1-9d7d-08aaf0cf42bb",
    iconSet: "FontAwesome5",
    iconName: "vimeo-v"
  },
  {
    uuid: "f5b359ad-8f46-491a-8cd3-eba882164478",
    iconSet: "FontAwesome5",
    iconName: "vine"
  },
  {
    uuid: "e923a739-8aee-4cb1-9033-6f5f80de8c41",
    iconSet: "FontAwesome5",
    iconName: "virus"
  },
  {
    uuid: "054a14df-1d8b-4748-97ff-93ea204fefd4",
    iconSet: "FontAwesome5",
    iconName: "virus-slash"
  },
  {
    uuid: "76562b4d-9b81-42bc-92a2-77037e7f24b6",
    iconSet: "FontAwesome5",
    iconName: "viruses"
  },
  {
    uuid: "c91dc205-67dd-44be-a416-f537b3637238",
    iconSet: "FontAwesome5",
    iconName: "vk"
  },
  {
    uuid: "c3a9539c-288a-478b-8309-676024173279",
    iconSet: "FontAwesome5",
    iconName: "vnv"
  },
  {
    uuid: "9f9866d8-ad18-4379-aa7a-525296114cfa",
    iconSet: "FontAwesome5",
    iconName: "voicemail"
  },
  {
    uuid: "53b06225-3a30-4e5d-9acc-0035b073da21",
    iconSet: "FontAwesome5",
    iconName: "volleyball-ball"
  },
  {
    uuid: "49419c53-f6da-423b-8c82-3b96cf6973ff",
    iconSet: "FontAwesome5",
    iconName: "volume-down"
  },
  {
    uuid: "68d49486-374a-42a4-afaa-d2a8986e5e00",
    iconSet: "FontAwesome5",
    iconName: "volume-mute"
  },
  {
    uuid: "1b69b0c4-1d05-4a76-9def-037dcaea1b51",
    iconSet: "FontAwesome5",
    iconName: "volume-off"
  },
  {
    uuid: "2b155540-0dc5-413e-a9ff-7b0a935801d7",
    iconSet: "FontAwesome5",
    iconName: "volume-up"
  },
  {
    uuid: "ec694244-0287-4847-8d6b-a7cb30e46a48",
    iconSet: "FontAwesome5",
    iconName: "vote-yea"
  },
  {
    uuid: "c370ae16-565f-4c6c-b1c4-073e139311fd",
    iconSet: "FontAwesome5",
    iconName: "vr-cardboard"
  },
  {
    uuid: "059dc2a2-9831-4403-95ea-ad71214629b9",
    iconSet: "FontAwesome5",
    iconName: "vuejs"
  },
  {
    uuid: "4dd76353-58f3-4e8d-9fda-45a22c26ca59",
    iconSet: "FontAwesome5",
    iconName: "walking"
  },
  {
    uuid: "71b9df4e-2523-4a77-b5db-4388cb3f306e",
    iconSet: "FontAwesome5",
    iconName: "wallet"
  },
  {
    uuid: "107d241e-a198-4261-9559-8991ea3c1f8e",
    iconSet: "FontAwesome5",
    iconName: "warehouse"
  },
  {
    uuid: "223382b2-4eee-4426-a9e3-b9d555749f04",
    iconSet: "FontAwesome5",
    iconName: "water"
  },
  {
    uuid: "84e43b5c-e5a6-4a51-ad46-fb3f3135c0aa",
    iconSet: "FontAwesome5",
    iconName: "wave-square"
  },
  {
    uuid: "2ade48b9-6a85-4a2a-a3dc-902ed15724bf",
    iconSet: "FontAwesome5",
    iconName: "waze"
  },
  {
    uuid: "b07e58c1-2c10-4730-9f66-f503edd7beb6",
    iconSet: "FontAwesome5",
    iconName: "weebly"
  },
  {
    uuid: "ee431e74-f376-4730-a7b0-d2f39f9e715c",
    iconSet: "FontAwesome5",
    iconName: "weibo"
  },
  {
    uuid: "42eb0f3d-4266-4e4d-8935-5e31d791f23a",
    iconSet: "FontAwesome5",
    iconName: "weight"
  },
  {
    uuid: "49ead324-826b-429c-8b60-d8a9ab3b54f6",
    iconSet: "FontAwesome5",
    iconName: "weight-hanging"
  },
  {
    uuid: "a1958d15-3531-4133-b471-3a63ac9e650b",
    iconSet: "FontAwesome5",
    iconName: "weixin"
  },
  {
    uuid: "25cd8502-e114-4ed8-9b23-5f4add8f7f3a",
    iconSet: "FontAwesome5",
    iconName: "whatsapp"
  },
  {
    uuid: "7d59862a-9f9d-493d-8e9f-7bcc80f1794d",
    iconSet: "FontAwesome5",
    iconName: "whatsapp-square"
  },
  {
    uuid: "0c7fae7c-a52f-4115-9b6d-3afcaf4e2cb8",
    iconSet: "FontAwesome5",
    iconName: "wheelchair"
  },
  {
    uuid: "6c63d298-592d-4b08-a3b6-39a66e271fc8",
    iconSet: "FontAwesome5",
    iconName: "whmcs"
  },
  {
    uuid: "b267414a-d10a-47cc-86a4-37aa8bd20245",
    iconSet: "FontAwesome5",
    iconName: "wifi"
  },
  {
    uuid: "96e4e4d8-6437-4a9c-9470-6aa51fd1ef34",
    iconSet: "FontAwesome5",
    iconName: "wikipedia-w"
  },
  {
    uuid: "174e75d1-cda1-45cd-92af-030b10bfd0a8",
    iconSet: "FontAwesome5",
    iconName: "wind"
  },
  {
    uuid: "32844be5-6d64-4d5f-b532-b88286291bd1",
    iconSet: "FontAwesome5",
    iconName: "window-close"
  },
  {
    uuid: "cf31ed6d-9fe4-4243-b2cf-3e8cb26ebff3",
    iconSet: "FontAwesome5",
    iconName: "window-maximize"
  },
  {
    uuid: "3b53b8d9-653a-40d0-ae3b-db7ef311465e",
    iconSet: "FontAwesome5",
    iconName: "window-minimize"
  },
  {
    uuid: "40380ef5-23ec-41ca-b7f8-c78551232b8c",
    iconSet: "FontAwesome5",
    iconName: "window-restore"
  },
  {
    uuid: "38ac9720-76a4-4022-8c41-2e31169b9c56",
    iconSet: "FontAwesome5",
    iconName: "windows"
  },
  {
    uuid: "9b732779-393f-497a-89d3-94166376e232",
    iconSet: "FontAwesome5",
    iconName: "wine-bottle"
  },
  {
    uuid: "717b7277-9818-436a-a7e1-08fd41aeffa1",
    iconSet: "FontAwesome5",
    iconName: "wine-glass"
  },
  {
    uuid: "95284293-373d-4766-a59d-ce29db2bf075",
    iconSet: "FontAwesome5",
    iconName: "wine-glass-alt"
  },
  {
    uuid: "f1b73b3f-4195-4cc7-89c8-de4e78f707ce",
    iconSet: "FontAwesome5",
    iconName: "wix"
  },
  {
    uuid: "1d228b73-35c7-42db-9e09-034eca310b89",
    iconSet: "FontAwesome5",
    iconName: "wizards-of-the-coast"
  },
  {
    uuid: "d49f7b1e-6a6a-4404-b9ba-59bb0c8e9c30",
    iconSet: "FontAwesome5",
    iconName: "wolf-pack-battalion"
  },
  {
    uuid: "f478e714-4ee8-4cdb-867c-51db2f59017e",
    iconSet: "FontAwesome5",
    iconName: "won-sign"
  },
  {
    uuid: "a9e48242-201c-4b5d-8832-927b836216a1",
    iconSet: "FontAwesome5",
    iconName: "wordpress"
  },
  {
    uuid: "951bfcd5-3fe8-4ad9-9b1a-6b16a49bc710",
    iconSet: "FontAwesome5",
    iconName: "wordpress-simple"
  },
  {
    uuid: "3aec92e9-d2a5-4ff2-a44a-1ed911d2f1cc",
    iconSet: "FontAwesome5",
    iconName: "wpbeginner"
  },
  {
    uuid: "4c6bf572-43cd-4a4b-bb7e-625402dc458d",
    iconSet: "FontAwesome5",
    iconName: "wpexplorer"
  },
  {
    uuid: "313dc100-4f2f-4279-baf3-45d434abeac2",
    iconSet: "FontAwesome5",
    iconName: "wpforms"
  },
  {
    uuid: "ff20d8f4-c361-4f15-838a-1b143ccc369a",
    iconSet: "FontAwesome5",
    iconName: "wpressr"
  },
  {
    uuid: "396b3e96-17b6-4844-b131-5e71ace91e45",
    iconSet: "FontAwesome5",
    iconName: "wrench"
  },
  {
    uuid: "4fe769e5-d346-43de-b68d-1b2ea9021f5e",
    iconSet: "FontAwesome5",
    iconName: "x-ray"
  },
  {
    uuid: "a24215d9-bb43-4c23-a4d4-394b3e63176d",
    iconSet: "FontAwesome5",
    iconName: "xbox"
  },
  {
    uuid: "c3ef25da-1a8c-47bf-ade3-c0a01d0ec2c5",
    iconSet: "FontAwesome5",
    iconName: "xing"
  },
  {
    uuid: "b1131ced-7d22-4bf5-8981-d7ea696cd657",
    iconSet: "FontAwesome5",
    iconName: "xing-square"
  },
  {
    uuid: "458539d9-2373-471b-9d96-accd54b84cc7",
    iconSet: "FontAwesome5",
    iconName: "y-combinator"
  },
  {
    uuid: "1a58bf77-d79f-4b8e-beec-dcc2931290bb",
    iconSet: "FontAwesome5",
    iconName: "yahoo"
  },
  {
    uuid: "3bc856c2-793a-4e89-b191-b1af393f8810",
    iconSet: "FontAwesome5",
    iconName: "yammer"
  },
  {
    uuid: "8e05d05d-abe9-4498-bc9d-b0a4f32915c5",
    iconSet: "FontAwesome5",
    iconName: "yandex"
  },
  {
    uuid: "659de9b8-5ef3-42b4-bb0d-addd5d5f05d4",
    iconSet: "FontAwesome5",
    iconName: "yandex-international"
  },
  {
    uuid: "4bf39df1-0d0a-425d-9f23-eb628be2e8ae",
    iconSet: "FontAwesome5",
    iconName: "yarn"
  },
  {
    uuid: "f9aaab02-10fb-41a5-8c4b-50253c166211",
    iconSet: "FontAwesome5",
    iconName: "yelp"
  },
  {
    uuid: "75586aae-6804-4e7e-b9b8-639c2b1a2f4f",
    iconSet: "FontAwesome5",
    iconName: "yen-sign"
  },
  {
    uuid: "5217845d-f246-40a1-81be-d316963f055f",
    iconSet: "FontAwesome5",
    iconName: "yin-yang"
  },
  {
    uuid: "454f8dd1-ca22-4b4e-bc59-bf106e9f70ba",
    iconSet: "FontAwesome5",
    iconName: "yoast"
  },
  {
    uuid: "988f45cd-74d6-4e6a-a62a-7f08331a4b24",
    iconSet: "FontAwesome5",
    iconName: "youtube"
  },
  {
    uuid: "59d5bbe5-94a4-4e69-bc7b-047b7a9979be",
    iconSet: "FontAwesome5",
    iconName: "youtube-square"
  },
  {
    uuid: "3b2a66d6-d44d-4873-b9bf-4a0b7745ddd6",
    iconSet: "FontAwesome5",
    iconName: "zhihu"
  },
  {
    uuid: "0cf331d2-d68c-476f-8289-7eddb8e2ccbc",
    iconSet: "Fontisto",
    iconName: "acrobat-reader"
  },
  {
    uuid: "937ea560-842a-42c0-92e4-b3a9ff20c617",
    iconSet: "Fontisto",
    iconName: "applemusic"
  },
  {
    uuid: "c8aa174e-adc8-4fd4-8f89-157617dafc29",
    iconSet: "Fontisto",
    iconName: "atlassian"
  },
  {
    uuid: "2b832104-614a-41ff-8cbf-4780a4c6590b",
    iconSet: "Fontisto",
    iconName: "aws"
  },
  {
    uuid: "e274b271-e1f1-47c4-9a14-a6a90e762ff5",
    iconSet: "Fontisto",
    iconName: "baidu"
  },
  {
    uuid: "53c16ca1-c7a2-41ef-9dfd-7b081e827108",
    iconSet: "Fontisto",
    iconName: "bing"
  },
  {
    uuid: "77995587-7e19-4a8f-a644-a5c27e155d96",
    iconSet: "Fontisto",
    iconName: "bower"
  },
  {
    uuid: "79d969a1-690e-4946-b2cc-08291b4d7a0f",
    iconSet: "Fontisto",
    iconName: "dailymotion"
  },
  {
    uuid: "f2d3fc70-6a00-495b-ba86-02da0a505c15",
    iconSet: "Fontisto",
    iconName: "delicious"
  },
  {
    uuid: "f29ae412-7f50-41d6-8b8b-6a2440b44b12",
    iconSet: "Fontisto",
    iconName: "deviantart"
  },
  {
    uuid: "cabb6a40-3e89-4485-bf42-baebdc675031",
    iconSet: "Fontisto",
    iconName: "disqus"
  },
  {
    uuid: "571c8e44-8492-4d1b-85c1-786b7ee71cc2",
    iconSet: "Fontisto",
    iconName: "flipboard"
  },
  {
    uuid: "1d777f6d-796f-4f73-aa6c-a7733a6b4be8",
    iconSet: "Fontisto",
    iconName: "graphql"
  },
  {
    uuid: "68508fca-31d5-4fa3-a89c-2d13d98a00fb",
    iconSet: "Fontisto",
    iconName: "hexo"
  },
  {
    uuid: "f4627b8a-b68a-41d4-86eb-0a8362669a19",
    iconSet: "Fontisto",
    iconName: "hipchat"
  },
  {
    uuid: "4d9bdf35-7540-49fe-84ef-22199ccfcd9f",
    iconSet: "Fontisto",
    iconName: "icq"
  },
  {
    uuid: "fbe2a93c-6933-4ee3-aa29-82b70b84797e",
    iconSet: "Fontisto",
    iconName: "invision"
  },
  {
    uuid: "51393e1f-c326-4ec5-a107-5f61e5d89fd5",
    iconSet: "Fontisto",
    iconName: "jekyll"
  },
  {
    uuid: "d774d28c-a13f-4fcc-8f42-abe07c8d4ea8",
    iconSet: "Fontisto",
    iconName: "jira"
  },
  {
    uuid: "23c521f8-7e5c-486f-8922-2307be59f15b",
    iconSet: "Fontisto",
    iconName: "json"
  },
  {
    uuid: "89d086b6-c180-4b5d-9751-c395a7d9e1a1",
    iconSet: "Fontisto",
    iconName: "livestream"
  },
  {
    uuid: "5d37920a-bc64-4324-a20c-5b89a6b65179",
    iconSet: "Fontisto",
    iconName: "messenger"
  },
  {
    uuid: "ba477735-0704-4b20-a0d0-577339946f50",
    iconSet: "Fontisto",
    iconName: "meteor"
  },
  {
    uuid: "aa75b245-1762-4d3b-9257-0b5da28aff6e",
    iconSet: "Fontisto",
    iconName: "onenote"
  },
  {
    uuid: "0b655ce5-431a-4180-ba7d-5d398509d6d6",
    iconSet: "Fontisto",
    iconName: "mongodb"
  },
  {
    uuid: "1c0f4dee-a37d-4b04-91c6-19736e38e0c1",
    iconSet: "Fontisto",
    iconName: "netflix"
  },
  {
    uuid: "7b76cff5-fa6f-41f7-9a9e-60c982498cfc",
    iconSet: "Fontisto",
    iconName: "nginx"
  },
  {
    uuid: "22f97816-49d4-447f-aeeb-4c4503ab54ad",
    iconSet: "Fontisto",
    iconName: "odnoklassniki"
  },
  {
    uuid: "dc677e36-fb6f-4372-9dd2-74e355111fa8",
    iconSet: "Fontisto",
    iconName: "onedrive"
  },
  {
    uuid: "54516bc7-b998-44d8-be96-d37ff214d8df",
    iconSet: "Fontisto",
    iconName: "origin"
  },
  {
    uuid: "45ec605a-d87f-4b7d-84cb-24070fc36e9f",
    iconSet: "Fontisto",
    iconName: "pingdom"
  },
  {
    uuid: "06d1c415-ca2c-4a18-9a3f-e14c697cba0b",
    iconSet: "Fontisto",
    iconName: "rails"
  },
  {
    uuid: "75497725-66f0-4d6a-94b0-e9eacd3e81dd",
    iconSet: "Fontisto",
    iconName: "raspberry-pi"
  },
  {
    uuid: "56f5999a-3f8a-44c9-94ad-81aa5065bfbb",
    iconSet: "Fontisto",
    iconName: "redis"
  },
  {
    uuid: "67f2b1ba-eb89-4163-a105-71ff1072e06e",
    iconSet: "Fontisto",
    iconName: "redux"
  },
  {
    uuid: "a5eb81a7-a7e8-4333-b79c-c8b533c447f6",
    iconSet: "Fontisto",
    iconName: "saucelabs"
  },
  {
    uuid: "b6f4e445-defd-46dc-b365-eff53938a902",
    iconSet: "Fontisto",
    iconName: "scorp"
  },
  {
    uuid: "980610b7-209e-4c1f-ba40-6153c825667f",
    iconSet: "Fontisto",
    iconName: "sentry"
  },
  {
    uuid: "0a1140e9-4749-4c93-9d7b-d8dd511b8410",
    iconSet: "Fontisto",
    iconName: "shazam"
  },
  {
    uuid: "5a203d5b-04bb-41e4-b6cd-647bca0740f0",
    iconSet: "Fontisto",
    iconName: "shopify"
  },
  {
    uuid: "855ee059-8bb2-4730-8026-9b526ae72c6f",
    iconSet: "Fontisto",
    iconName: "sinaweibo"
  },
  {
    uuid: "02f8cf33-7509-4bf3-9359-a1015071d29c",
    iconSet: "Fontisto",
    iconName: "slides"
  },
  {
    uuid: "a2bb8cd2-0349-4507-8718-7c55fee7448a",
    iconSet: "Fontisto",
    iconName: "sublimetext"
  },
  {
    uuid: "3315b228-a7c3-4301-8aa5-ef93019fcdd2",
    iconSet: "Fontisto",
    iconName: "swift"
  },
  {
    uuid: "a7b6ba01-4a9a-4eb6-b1d6-2265d9200587",
    iconSet: "Fontisto",
    iconName: "ted"
  },
  {
    uuid: "7ea8adb3-1047-4ff5-bc03-a9c5f4e16c1e",
    iconSet: "Fontisto",
    iconName: "telegram"
  },
  {
    uuid: "cdbef8d5-5d0b-40e8-ae21-6719f5a8ec55",
    iconSet: "Fontisto",
    iconName: "tesla"
  },
  {
    uuid: "d7be68a6-517e-40bf-a51a-831ba49dd61b",
    iconSet: "Fontisto",
    iconName: "tinder"
  },
  {
    uuid: "29182002-2437-4375-acad-4553940a8a36",
    iconSet: "Fontisto",
    iconName: "treehouse"
  },
  {
    uuid: "c848d8cd-0be2-4caf-b242-370ab2eec89c",
    iconSet: "Fontisto",
    iconName: "twoo"
  },
  {
    uuid: "9d20418a-b45b-47f7-998c-8953e95b3dd9",
    iconSet: "Fontisto",
    iconName: "udacity"
  },
  {
    uuid: "a43f4846-b321-4466-9963-0eb955f75959",
    iconSet: "Fontisto",
    iconName: "webstorm"
  },
  {
    uuid: "7a3f3f93-c0e9-45a3-b27d-617ec90c966f",
    iconSet: "Fontisto",
    iconName: "wix"
  },
  {
    uuid: "40ffc3b6-9a76-4e90-8ee5-994cfcb843f1",
    iconSet: "Fontisto",
    iconName: "yandex-international"
  },
  {
    uuid: "80fe3b2e-684b-424f-885f-712c3e272ecd",
    iconSet: "Fontisto",
    iconName: "yandex"
  },
  {
    uuid: "adb9c517-dd0e-4ed1-beb4-e46fcf357a6c",
    iconSet: "Fontisto",
    iconName: "ember"
  },
  {
    uuid: "c26348f2-6c8c-495a-b0a2-407eb82f0666",
    iconSet: "Fontisto",
    iconName: "cpanel"
  },
  {
    uuid: "7c3b290c-8b10-4c21-bc12-d0dcb17d9d42",
    iconSet: "Fontisto",
    iconName: "viber"
  },
  {
    uuid: "19843165-bee9-4c38-a105-11be42d81c81",
    iconSet: "Fontisto",
    iconName: "deskpro"
  },
  {
    uuid: "08196a53-3fa4-4ca5-a722-878b162838eb",
    iconSet: "Fontisto",
    iconName: "discord"
  },
  {
    uuid: "c0b2b420-31b7-459d-86ce-ae72070ae564",
    iconSet: "Fontisto",
    iconName: "discourse"
  },
  {
    uuid: "317bdfd2-c7fa-46c7-b6e1-4544916772a9",
    iconSet: "Fontisto",
    iconName: "adobe"
  },
  {
    uuid: "377e7427-c437-4139-b951-be5b1cc07e0a",
    iconSet: "Fontisto",
    iconName: "algolia"
  },
  {
    uuid: "064a59ed-a6c7-49c3-928e-8ce55bb97a56",
    iconSet: "Fontisto",
    iconName: "atom"
  },
  {
    uuid: "752f8d37-0e6a-49b0-a961-ebc99bd3f3df",
    iconSet: "Fontisto",
    iconName: "babel"
  },
  {
    uuid: "f3f84c59-46e9-4980-9c83-64b562b8de62",
    iconSet: "Fontisto",
    iconName: "coffeescript"
  },
  {
    uuid: "4fdebecf-9052-4ba6-b9eb-93b39dec40a3",
    iconSet: "Fontisto",
    iconName: "electronjs"
  },
  {
    uuid: "49d18b93-56b8-4e54-a82f-f1651495e532",
    iconSet: "Fontisto",
    iconName: "mysql"
  },
  {
    uuid: "fa49e7f2-0f59-4bd8-bbb4-86ba52b59f5b",
    iconSet: "Fontisto",
    iconName: "oracle"
  },
  {
    uuid: "9d89dd5b-eabf-4a51-97c7-d66084e49bcc",
    iconSet: "Fontisto",
    iconName: "php"
  },
  {
    uuid: "e345777e-5dfa-4429-b208-c8474744d8e5",
    iconSet: "Fontisto",
    iconName: "sourcetree"
  },
  {
    uuid: "dad8fd31-c8c0-44a4-b328-5182693cd713",
    iconSet: "Fontisto",
    iconName: "ubuntu"
  },
  {
    uuid: "450fb00c-8f49-48af-8ccf-93a9c4cb9d50",
    iconSet: "Fontisto",
    iconName: "unity"
  },
  {
    uuid: "f0458d42-c48f-49a3-a599-8821aa9b3a07",
    iconSet: "Fontisto",
    iconName: "unreal-engine"
  },
  {
    uuid: "6fd62b9c-d935-448c-9131-ef03114f6542",
    iconSet: "Fontisto",
    iconName: "webpack"
  },
  {
    uuid: "0dffb50b-24b2-4eb3-94e7-f8edd6889f5f",
    iconSet: "Fontisto",
    iconName: "angelist"
  },
  {
    uuid: "3fd5338d-ba41-4c16-a3b0-08e5ca5fa7d1",
    iconSet: "Fontisto",
    iconName: "app-store"
  },
  {
    uuid: "782be843-cd9f-469e-aba0-9eebba0da287",
    iconSet: "Fontisto",
    iconName: "digg"
  },
  {
    uuid: "e7dd0c9a-aa3d-4079-bb0b-609567d7bdc1",
    iconSet: "Fontisto",
    iconName: "dockers"
  },
  {
    uuid: "203851c1-92fd-4074-be9a-162c5ffe7655",
    iconSet: "Fontisto",
    iconName: "envato"
  },
  {
    uuid: "8b92621a-32ef-4610-b302-582e541c28d2",
    iconSet: "Fontisto",
    iconName: "gitlab"
  },
  {
    uuid: "29d82e85-f56d-4211-978e-affa54dad204",
    iconSet: "Fontisto",
    iconName: "google-drive"
  },
  {
    uuid: "cbd8eee7-970a-4080-9833-3de402fa190e",
    iconSet: "Fontisto",
    iconName: "google-play"
  },
  {
    uuid: "a4f84adc-52c6-4f17-b81e-3e2bdb92388f",
    iconSet: "Fontisto",
    iconName: "grunt"
  },
  {
    uuid: "0e857468-4239-4a09-a7f4-bdeb3af2f483",
    iconSet: "Fontisto",
    iconName: "gulp"
  },
  {
    uuid: "395c0648-6c69-4c35-a0e3-1ae2bc1fec16",
    iconSet: "Fontisto",
    iconName: "hacker-news"
  },
  {
    uuid: "2cc316e5-0350-45e2-b955-4d71c6f36b59",
    iconSet: "Fontisto",
    iconName: "imdb"
  },
  {
    uuid: "5e1fbc0b-bc78-41e1-929d-0c32d9b63db6",
    iconSet: "Fontisto",
    iconName: "jenkins"
  },
  {
    uuid: "00f5e518-b8f6-489e-876c-ed1e5687775f",
    iconSet: "Fontisto",
    iconName: "joomla"
  },
  {
    uuid: "875d0e43-64bf-4b10-b4ed-38a9c7eef43c",
    iconSet: "Fontisto",
    iconName: "kickstarter"
  },
  {
    uuid: "202896be-de81-4a8a-ae5e-fab94ce878df",
    iconSet: "Fontisto",
    iconName: "laravel"
  },
  {
    uuid: "5c39c00a-4e7e-4a72-8cef-4bff32243b04",
    iconSet: "Fontisto",
    iconName: "less"
  },
  {
    uuid: "7ff28283-74df-4eca-9324-b10a3cccaf4e",
    iconSet: "Fontisto",
    iconName: "line"
  },
  {
    uuid: "6f32e7fa-e1da-4775-b18c-1f9fd881748e",
    iconSet: "Fontisto",
    iconName: "npm"
  },
  {
    uuid: "65d7e5ff-8471-4fc8-a194-3d6d0724149f",
    iconSet: "Fontisto",
    iconName: "periscope"
  },
  {
    uuid: "6b8ccb66-1aec-4104-b59f-f726f1bcdae2",
    iconSet: "Fontisto",
    iconName: "product-hunt"
  },
  {
    uuid: "f6fffd00-09e8-4962-a218-f223435cf6a4",
    iconSet: "Fontisto",
    iconName: "quora"
  },
  {
    uuid: "6b4b7596-1192-405b-bb74-fe93f70de8a6",
    iconSet: "Fontisto",
    iconName: "skyatlas"
  },
  {
    uuid: "427854ed-a1a2-47eb-8a45-cc758249290f",
    iconSet: "Fontisto",
    iconName: "stylus"
  },
  {
    uuid: "ed4c1dc2-c070-4101-a3d7-dc035e09e30d",
    iconSet: "Fontisto",
    iconName: "travis"
  },
  {
    uuid: "597176c2-4bc0-4ffa-adc4-0dffe32359d1",
    iconSet: "Fontisto",
    iconName: "trello"
  },
  {
    uuid: "487fd664-db65-42c0-a731-30fb7523701b",
    iconSet: "Fontisto",
    iconName: "uber"
  },
  {
    uuid: "6fb5cf0e-20e0-4f4b-96bc-0173f3876c7b",
    iconSet: "Fontisto",
    iconName: "vine"
  },
  {
    uuid: "770942c5-d361-4d82-bb88-84e029815f2d",
    iconSet: "Fontisto",
    iconName: "visual-studio"
  },
  {
    uuid: "c117885e-7745-420a-8758-3b3875cf70b6",
    iconSet: "Fontisto",
    iconName: "vk"
  },
  {
    uuid: "69131b0f-69a2-41c7-b7d9-9f515368591c",
    iconSet: "Fontisto",
    iconName: "vuejs"
  },
  {
    uuid: "79c763d2-cac7-4abf-b08a-ce362ac475bc",
    iconSet: "Fontisto",
    iconName: "microsoft"
  },
  {
    uuid: "31fabe2c-2ee0-40d7-85a1-311f6ad78419",
    iconSet: "Fontisto",
    iconName: "blogger"
  },
  {
    uuid: "01813dd6-227c-4be0-8119-45b6b6640b4e",
    iconSet: "Fontisto",
    iconName: "500px"
  },
  {
    uuid: "cdeb8ce5-c14d-46a0-bf41-1b1028c3d1f2",
    iconSet: "Fontisto",
    iconName: "amazon"
  },
  {
    uuid: "2ed5323d-c840-44d2-a736-40354eb9aa16",
    iconSet: "Fontisto",
    iconName: "ampproject"
  },
  {
    uuid: "be532280-5257-4d67-89c1-0b1b97f0ff52",
    iconSet: "Fontisto",
    iconName: "android"
  },
  {
    uuid: "b2c7068f-9ab0-41c7-8e5d-ae99dc0b19ea",
    iconSet: "Fontisto",
    iconName: "angularjs"
  },
  {
    uuid: "c391984d-cea1-4408-9c4d-6990e9f76cd6",
    iconSet: "Fontisto",
    iconName: "apple"
  },
  {
    uuid: "be66e8a3-c9a7-4e56-b83b-56078cb2b369",
    iconSet: "Fontisto",
    iconName: "behance"
  },
  {
    uuid: "6e553a14-93da-4482-8218-84d9a977d651",
    iconSet: "Fontisto",
    iconName: "bitbucket"
  },
  {
    uuid: "f7b231c3-a273-4cf9-9f49-6e9f2222d87b",
    iconSet: "Fontisto",
    iconName: "bluetooth-b"
  },
  {
    uuid: "024c2c7e-a860-4370-8e88-232f7323a707",
    iconSet: "Fontisto",
    iconName: "cloudflare"
  },
  {
    uuid: "d3da2f04-532a-4fa6-b3dc-231373cd5ecf",
    iconSet: "Fontisto",
    iconName: "codepen"
  },
  {
    uuid: "6a951274-8393-4eb1-9647-d6641e2afafa",
    iconSet: "Fontisto",
    iconName: "css3"
  },
  {
    uuid: "fc5d81f7-0bd3-4da1-afab-dd1240187e47",
    iconSet: "Fontisto",
    iconName: "dribbble"
  },
  {
    uuid: "ebd1546b-e2b6-4fad-8825-b35d6d1c55fb",
    iconSet: "Fontisto",
    iconName: "dropbox"
  },
  {
    uuid: "d1f8bdad-2bde-4a58-86b2-3524c6dc5e6a",
    iconSet: "Fontisto",
    iconName: "facebook"
  },
  {
    uuid: "a9e7b4da-a1de-4d1c-a748-acf7cdd87bcf",
    iconSet: "Fontisto",
    iconName: "flickr"
  },
  {
    uuid: "a46d2762-b47e-4db2-9287-08f926431680",
    iconSet: "Fontisto",
    iconName: "foursquare"
  },
  {
    uuid: "d4834cfa-813f-456a-870c-b2aa15589d28",
    iconSet: "Fontisto",
    iconName: "git"
  },
  {
    uuid: "9c54cecd-829a-4004-bc13-60e0176ba032",
    iconSet: "Fontisto",
    iconName: "github"
  },
  {
    uuid: "77622bb2-c445-47c5-8237-d69504f294ce",
    iconSet: "Fontisto",
    iconName: "google-plus"
  },
  {
    uuid: "f894b617-b71f-4429-8670-108c036e9c9d",
    iconSet: "Fontisto",
    iconName: "google"
  },
  {
    uuid: "1916679a-f8ce-4262-b3a1-eac922a068bf",
    iconSet: "Fontisto",
    iconName: "hangout"
  },
  {
    uuid: "d53b7a51-5ff8-4ff2-882f-f0dfd263a423",
    iconSet: "Fontisto",
    iconName: "houzz"
  },
  {
    uuid: "520159eb-313e-4c1c-b444-5d18a0ee7cb5",
    iconSet: "Fontisto",
    iconName: "html5"
  },
  {
    uuid: "bd8ebc4e-3013-49a4-b23d-159da3b397c1",
    iconSet: "Fontisto",
    iconName: "instagram"
  },
  {
    uuid: "3dbe2350-d2f9-4d92-9837-c248b091ab75",
    iconSet: "Fontisto",
    iconName: "java"
  },
  {
    uuid: "7db89d7c-f1b9-40d2-8411-1a0fc1a23c88",
    iconSet: "Fontisto",
    iconName: "jquery"
  },
  {
    uuid: "fa7bcd53-5c4d-4fd3-b6fb-d8d9e0da2e36",
    iconSet: "Fontisto",
    iconName: "jsfiddle"
  },
  {
    uuid: "0e704570-eb69-45bc-9ebf-73b1367853ab",
    iconSet: "Fontisto",
    iconName: "linkedin"
  },
  {
    uuid: "742152b3-25fc-4d03-9dce-afb01fb40f8d",
    iconSet: "Fontisto",
    iconName: "linux"
  },
  {
    uuid: "d63c5a72-1748-457f-99ae-260c297b5540",
    iconSet: "Fontisto",
    iconName: "magento"
  },
  {
    uuid: "fa53bdf8-3b2e-477a-b908-359911fc2d11",
    iconSet: "Fontisto",
    iconName: "maxcdn"
  },
  {
    uuid: "95a367ff-6c9a-4af0-9a29-adbff2cda633",
    iconSet: "Fontisto",
    iconName: "medium"
  },
  {
    uuid: "0891efd6-9b5c-43b2-ba7c-e25e97a4553b",
    iconSet: "Fontisto",
    iconName: "meetup"
  },
  {
    uuid: "7ddd45a8-4147-4d1f-9faf-5091fd2ef290",
    iconSet: "Fontisto",
    iconName: "nodejs"
  },
  {
    uuid: "32c0cf31-38b3-47a2-9786-8a76c0a2ec04",
    iconSet: "Fontisto",
    iconName: "opencart"
  },
  {
    uuid: "48a6359f-fe8b-40d4-b0aa-9e9a85574a58",
    iconSet: "Fontisto",
    iconName: "pinterest"
  },
  {
    uuid: "8ead5d8d-4cdc-4979-9ebb-8803350af3b7",
    iconSet: "Fontisto",
    iconName: "playstation"
  },
  {
    uuid: "fd5d7d6e-4d05-49f4-8d47-6479c7b8b9ff",
    iconSet: "Fontisto",
    iconName: "python"
  },
  {
    uuid: "49596521-ef93-4536-a075-a06d1b85fb0b",
    iconSet: "Fontisto",
    iconName: "react"
  },
  {
    uuid: "1132ff62-bc04-4594-ac4d-3684d1afa0dc",
    iconSet: "Fontisto",
    iconName: "reddit"
  },
  {
    uuid: "06681e02-f655-46dd-b14a-91b1a229053e",
    iconSet: "Fontisto",
    iconName: "ruby"
  },
  {
    uuid: "e417614f-b048-4989-9ac1-fec773dd23d1",
    iconSet: "Fontisto",
    iconName: "sass"
  },
  {
    uuid: "b2ecf226-b0c9-42db-8df5-e9eeb6d218e1",
    iconSet: "Fontisto",
    iconName: "skype"
  },
  {
    uuid: "6dc48dd9-ac7c-4efa-a14a-2edea3760c84",
    iconSet: "Fontisto",
    iconName: "slack"
  },
  {
    uuid: "54a69caa-71c4-4a81-9c41-7439b18f79c4",
    iconSet: "Fontisto",
    iconName: "snapchat"
  },
  {
    uuid: "bdd60eab-a913-45ec-967b-3728f7233623",
    iconSet: "Fontisto",
    iconName: "soundcloud"
  },
  {
    uuid: "c59e4651-d2ba-4809-a312-0ef6cbec2eb6",
    iconSet: "Fontisto",
    iconName: "spotify"
  },
  {
    uuid: "c445c15d-d5d8-47ee-a32b-edd081f1095d",
    iconSet: "Fontisto",
    iconName: "stack-overflow"
  },
  {
    uuid: "25a9ed7e-1301-4fbd-9914-3cc3cc799d15",
    iconSet: "Fontisto",
    iconName: "steam"
  },
  {
    uuid: "f8eb0bca-342c-4bee-9515-c4fbc0e09e75",
    iconSet: "Fontisto",
    iconName: "stumbleupon"
  },
  {
    uuid: "6da87cd2-8ee8-4dd9-8a53-883ec889f57c",
    iconSet: "Fontisto",
    iconName: "svn"
  },
  {
    uuid: "2e6d1004-3122-4e51-86f0-afbf5d9eff62",
    iconSet: "Fontisto",
    iconName: "swarm"
  },
  {
    uuid: "9d775cb3-fdba-479a-91ce-74ba3fee5921",
    iconSet: "Fontisto",
    iconName: "tripadvisor"
  },
  {
    uuid: "7b73f671-86c0-4546-b5c6-042f4cdd297c",
    iconSet: "Fontisto",
    iconName: "tumblr"
  },
  {
    uuid: "2f494df8-8110-4d37-95bb-7473f9b55ae7",
    iconSet: "Fontisto",
    iconName: "twitch"
  },
  {
    uuid: "ada5659b-1c16-4139-ab9c-77a5d44287c3",
    iconSet: "Fontisto",
    iconName: "twitter"
  },
  {
    uuid: "d6913504-4dbe-49da-b4e8-42c3245e59f6",
    iconSet: "Fontisto",
    iconName: "vimeo"
  },
  {
    uuid: "86ffedb6-09e8-459a-b94e-0085fa2f1113",
    iconSet: "Fontisto",
    iconName: "wetransfer"
  },
  {
    uuid: "e9d8ee8e-12bc-4851-95a2-89c6cace398c",
    iconSet: "Fontisto",
    iconName: "whatsapp"
  },
  {
    uuid: "eb35bef3-066a-4483-bcc7-44ab083179a9",
    iconSet: "Fontisto",
    iconName: "wifi-logo"
  },
  {
    uuid: "d9b91f62-9885-458d-89b4-c41d87f0c0b6",
    iconSet: "Fontisto",
    iconName: "wikipedia"
  },
  {
    uuid: "1cd52a34-9d43-42e4-8e3b-73e37844b63f",
    iconSet: "Fontisto",
    iconName: "windows"
  },
  {
    uuid: "4854c3ab-235e-4a97-a25b-261ad73e6002",
    iconSet: "Fontisto",
    iconName: "wordpress"
  },
  {
    uuid: "8dd52e53-1859-49a0-9297-030b06dfae34",
    iconSet: "Fontisto",
    iconName: "xbox"
  },
  {
    uuid: "80d8a503-6b2e-45d0-b89b-8d436363f0be",
    iconSet: "Fontisto",
    iconName: "yahoo"
  },
  {
    uuid: "9303c1e7-a26f-466f-940c-8d6aebddcbe0",
    iconSet: "Fontisto",
    iconName: "yelp"
  },
  {
    uuid: "c417ec72-56b1-41a3-a1c0-18c78a2f1118",
    iconSet: "Fontisto",
    iconName: "youtube-play"
  },
  {
    uuid: "79f8976d-59f5-42b8-a076-cdaeca8e525d",
    iconSet: "Fontisto",
    iconName: "cocoapods"
  },
  {
    uuid: "74e3211f-a8b6-40c0-a634-41054ba78990",
    iconSet: "Fontisto",
    iconName: "composer"
  },
  {
    uuid: "101a0948-b990-41f5-b355-189e744196b4",
    iconSet: "Fontisto",
    iconName: "yarn"
  },
  {
    uuid: "9442355c-f669-4274-a8a0-e3fafaf6ff3f",
    iconSet: "Fontisto",
    iconName: "language"
  },
  {
    uuid: "10c6a8f3-7b1b-419c-81b9-0dd7c6f3b820",
    iconSet: "Fontisto",
    iconName: "toggle-off"
  },
  {
    uuid: "484292da-ced4-4d6a-9fad-1319b3fdbf3d",
    iconSet: "Fontisto",
    iconName: "toggle-on"
  },
  {
    uuid: "ff870a93-5e03-4390-aa2a-15d728f60c1a",
    iconSet: "Fontisto",
    iconName: "anchor"
  },
  {
    uuid: "90bd35d2-e6c6-4788-a8fa-3bd9f648e037",
    iconSet: "Fontisto",
    iconName: "archive"
  },
  {
    uuid: "d3cadeba-d348-46e1-bbce-f53220061d03",
    iconSet: "Fontisto",
    iconName: "at"
  },
  {
    uuid: "d076ee25-c3ae-4b6a-a915-83d051fb1bce",
    iconSet: "Fontisto",
    iconName: "ban"
  },
  {
    uuid: "81e8b3f7-5e6c-40ec-b2f0-e383fc939614",
    iconSet: "Fontisto",
    iconName: "battery-half"
  },
  {
    uuid: "46aeaf6d-b39d-4682-9b27-a96c7df975bc",
    iconSet: "Fontisto",
    iconName: "battery-full"
  },
  {
    uuid: "3d1598b1-4518-4473-b4e7-67956e64d14b",
    iconSet: "Fontisto",
    iconName: "battery-empty"
  },
  {
    uuid: "ba9645aa-92f5-4c9d-be7e-e89dc8dc11c3",
    iconSet: "Fontisto",
    iconName: "battery-quarter"
  },
  {
    uuid: "20770a49-6cee-469d-a14e-70ccaba0382a",
    iconSet: "Fontisto",
    iconName: "battery-three-quarters"
  },
  {
    uuid: "eabe2548-cdb2-4b7d-9954-91a979e46028",
    iconSet: "Fontisto",
    iconName: "bell-alt"
  },
  {
    uuid: "021ff08c-9dce-4bba-963d-1b54403b2813",
    iconSet: "Fontisto",
    iconName: "bell"
  },
  {
    uuid: "70633bd5-58c9-4170-b9dc-93f0878850ef",
    iconSet: "Fontisto",
    iconName: "bookmark-alt"
  },
  {
    uuid: "1974d96f-4a46-43df-8634-4f3730bb8858",
    iconSet: "Fontisto",
    iconName: "bookmark"
  },
  {
    uuid: "1dfc9c2f-ece3-448a-90e9-5f9b8a1ec86c",
    iconSet: "Fontisto",
    iconName: "bug"
  },
  {
    uuid: "42c2faca-112f-4b78-bcf2-83e9fac84ad4",
    iconSet: "Fontisto",
    iconName: "calculator"
  },
  {
    uuid: "2a449e60-a8c4-4dfc-9331-4f61780148f0",
    iconSet: "Fontisto",
    iconName: "calendar"
  },
  {
    uuid: "9a539683-d7ce-40c6-b841-74eb2ce130de",
    iconSet: "Fontisto",
    iconName: "crosshairs"
  },
  {
    uuid: "293bf1f0-eb97-4ad2-8c6b-685181552abb",
    iconSet: "Fontisto",
    iconName: "desktop"
  },
  {
    uuid: "26eaa244-240c-4dd1-9a35-9db51ba66663",
    iconSet: "Fontisto",
    iconName: "download"
  },
  {
    uuid: "677e8f22-8631-4f43-b70a-0cd88f074917",
    iconSet: "Fontisto",
    iconName: "film"
  },
  {
    uuid: "95f16e5c-3124-4755-b1b2-869144ada352",
    iconSet: "Fontisto",
    iconName: "history"
  },
  {
    uuid: "8992aef9-9574-425f-91d3-6ef2a26ffb04",
    iconSet: "Fontisto",
    iconName: "hourglass-end"
  },
  {
    uuid: "6fcb62d9-1367-42f4-b814-e69ef2a8a533",
    iconSet: "Fontisto",
    iconName: "hourglass-half"
  },
  {
    uuid: "9c0d477f-4ffa-4175-a313-d0eccc42383f",
    iconSet: "Fontisto",
    iconName: "hourglass-start"
  },
  {
    uuid: "15a91be7-a80f-4bfd-b38c-1a96fbbacedc",
    iconSet: "Fontisto",
    iconName: "hourglass"
  },
  {
    uuid: "ca07c335-bff8-4768-84f0-c37f91d9678f",
    iconSet: "Fontisto",
    iconName: "info"
  },
  {
    uuid: "320e4838-86b2-4dc2-a85a-761bb37e5fa1",
    iconSet: "Fontisto",
    iconName: "key"
  },
  {
    uuid: "77b6a398-0e37-4eba-b750-e186df9ed984",
    iconSet: "Fontisto",
    iconName: "keyboard"
  },
  {
    uuid: "2eae44b1-c4e1-43e5-8928-27a2cb7995f4",
    iconSet: "Fontisto",
    iconName: "laptop"
  },
  {
    uuid: "0d5a7488-aba9-4027-9fe4-dd217249c3f8",
    iconSet: "Fontisto",
    iconName: "lightbulb"
  },
  {
    uuid: "e18c31a4-78e2-422c-abf8-d38d445f107f",
    iconSet: "Fontisto",
    iconName: "magnet"
  },
  {
    uuid: "f6dc8b79-8e88-42f1-93b1-56c977a521f9",
    iconSet: "Fontisto",
    iconName: "map-marker-alt"
  },
  {
    uuid: "46378cb2-3eb3-48fa-96da-3094d09593d7",
    iconSet: "Fontisto",
    iconName: "map-marker"
  },
  {
    uuid: "8ba1170c-9c78-40ec-b400-a03eefade63b",
    iconSet: "Fontisto",
    iconName: "map"
  },
  {
    uuid: "082890dd-d0ff-49ba-84cc-07a62c0b9f39",
    iconSet: "Fontisto",
    iconName: "mobile-alt"
  },
  {
    uuid: "a61dc2d8-cb19-4e7c-98b2-76e3249084af",
    iconSet: "Fontisto",
    iconName: "mobile"
  },
  {
    uuid: "23b9c9bc-0203-439c-b3a8-0a870aad4347",
    iconSet: "Fontisto",
    iconName: "paw"
  },
  {
    uuid: "a9cbc43b-462b-4434-9da4-c6f696182d89",
    iconSet: "Fontisto",
    iconName: "phone"
  },
  {
    uuid: "07d476fe-7ef6-4bf7-8faf-b131e1ac8c9f",
    iconSet: "Fontisto",
    iconName: "power"
  },
  {
    uuid: "82e24555-24af-4913-ac35-81548a4ff851",
    iconSet: "Fontisto",
    iconName: "qrcode"
  },
  {
    uuid: "eaa5bc1d-5392-4439-beae-dea2f7c85b49",
    iconSet: "Fontisto",
    iconName: "question"
  },
  {
    uuid: "6af36f27-33bf-4482-b19d-33c6e226aa91",
    iconSet: "Fontisto",
    iconName: "search"
  },
  {
    uuid: "7174bcc7-f91a-4673-b6bc-1dfdbbf33336",
    iconSet: "Fontisto",
    iconName: "sitemap"
  },
  {
    uuid: "aa068652-65cd-4c05-b652-dad321799107",
    iconSet: "Fontisto",
    iconName: "star-half"
  },
  {
    uuid: "5299b76c-abc9-4181-b524-0c61e7b85ce7",
    iconSet: "Fontisto",
    iconName: "stopwatch"
  },
  {
    uuid: "bb9d9da0-6c99-48f8-9704-3d2d9b23e927",
    iconSet: "Fontisto",
    iconName: "tablet-alt"
  },
  {
    uuid: "82e9bd6f-a553-4868-9583-ce70393ec671",
    iconSet: "Fontisto",
    iconName: "tablet"
  },
  {
    uuid: "7b0ba793-c49b-4f9f-b53d-23075b0faf21",
    iconSet: "Fontisto",
    iconName: "ticket"
  },
  {
    uuid: "a9bc299c-91ae-4da4-aa6c-f2215f49e3b3",
    iconSet: "Fontisto",
    iconName: "tv"
  },
  {
    uuid: "46b0cd58-73c1-4ae6-8ebb-ddb635726607",
    iconSet: "Fontisto",
    iconName: "upload"
  },
  {
    uuid: "5a1fb5d4-1ab3-4b7d-bfcb-6e1f9b07efd4",
    iconSet: "Fontisto",
    iconName: "user-secret"
  },
  {
    uuid: "21ea2914-7013-44ec-8894-a7401589c904",
    iconSet: "Fontisto",
    iconName: "camera"
  },
  {
    uuid: "12fb3785-cadc-4abc-b61a-34cc095631f5",
    iconSet: "Fontisto",
    iconName: "clock"
  },
  {
    uuid: "0f7640b2-fb8b-4302-bee5-063d09c3ca86",
    iconSet: "Fontisto",
    iconName: "close-a"
  },
  {
    uuid: "ef532d7a-7821-4ca3-b993-9d37fe40ebb7",
    iconSet: "Fontisto",
    iconName: "code"
  },
  {
    uuid: "fb362054-5927-454a-9c97-f3bbc5378c96",
    iconSet: "Fontisto",
    iconName: "comment"
  },
  {
    uuid: "ef1a5429-944c-47cf-9c39-fcaf2474d362",
    iconSet: "Fontisto",
    iconName: "commenting"
  },
  {
    uuid: "226975c1-378e-4fcd-bca7-575a9bba35c7",
    iconSet: "Fontisto",
    iconName: "comments"
  },
  {
    uuid: "2ef214b0-0fe4-4e6a-901b-7d4bfdd70e24",
    iconSet: "Fontisto",
    iconName: "crop"
  },
  {
    uuid: "896796c6-e940-49bd-a584-0d373d20611b",
    iconSet: "Fontisto",
    iconName: "cursor"
  },
  {
    uuid: "13c9654d-deb5-449a-8d95-a788f4f34b77",
    iconSet: "Fontisto",
    iconName: "database"
  },
  {
    uuid: "35713886-91a9-4d4e-bb18-8c00d2f25453",
    iconSet: "Fontisto",
    iconName: "date"
  },
  {
    uuid: "b90297c7-d88b-41ac-b2ea-cb0f519af03b",
    iconSet: "Fontisto",
    iconName: "earth"
  },
  {
    uuid: "e0143aa6-0e36-469d-aec3-d241b03683f3",
    iconSet: "Fontisto",
    iconName: "email"
  },
  {
    uuid: "fb2d94a0-837c-442e-9a06-f25a2a676d27",
    iconSet: "Fontisto",
    iconName: "eye"
  },
  {
    uuid: "3922d74f-2df4-4002-b2b9-42b01d8f2a14",
    iconSet: "Fontisto",
    iconName: "female"
  },
  {
    uuid: "c9ea56fd-f2e9-4873-98f2-4ebbd7511260",
    iconSet: "Fontisto",
    iconName: "favorite"
  },
  {
    uuid: "c8aa78e6-5178-4065-a0bb-7093503589f5",
    iconSet: "Fontisto",
    iconName: "filter"
  },
  {
    uuid: "906af5a1-7ce9-40b9-a663-d13de102b184",
    iconSet: "Fontisto",
    iconName: "fire"
  },
  {
    uuid: "ab4b2253-aec1-45ba-8491-4122a5d7943e",
    iconSet: "Fontisto",
    iconName: "flag"
  },
  {
    uuid: "30aa7b1c-2490-4e8a-a22b-4b76f9e35d2f",
    iconSet: "Fontisto",
    iconName: "flash"
  },
  {
    uuid: "0c79a1d1-6489-4ab3-ac56-0aab47fccbfe",
    iconSet: "Fontisto",
    iconName: "home"
  },
  {
    uuid: "ee37c021-b26f-44c2-96a3-00f214c01f78",
    iconSet: "Fontisto",
    iconName: "link"
  },
  {
    uuid: "1af2c599-0057-4aa6-a462-4b85ba935e62",
    iconSet: "Fontisto",
    iconName: "locked"
  },
  {
    uuid: "56235dc7-f236-40db-b8c0-69b4148bd883",
    iconSet: "Fontisto",
    iconName: "male"
  },
  {
    uuid: "273e8f25-823a-4e28-99cf-6810d3796048",
    iconSet: "Fontisto",
    iconName: "minus-a"
  },
  {
    uuid: "63c3a787-5f31-4b3f-b3d8-1e121760f3b2",
    iconSet: "Fontisto",
    iconName: "more-v-a"
  },
  {
    uuid: "98afd05a-e7bd-4dba-9163-2b141b46e962",
    iconSet: "Fontisto",
    iconName: "more-v"
  },
  {
    uuid: "2aed0cb2-167d-4b22-be4d-6cff51fef1ff",
    iconSet: "Fontisto",
    iconName: "move-h-a"
  },
  {
    uuid: "38ec29be-641b-4216-9781-2bcabb5d9c5c",
    iconSet: "Fontisto",
    iconName: "move-h"
  },
  {
    uuid: "e5ccd0f8-cf26-4a9d-ba0e-4cb2848a502d",
    iconSet: "Fontisto",
    iconName: "nav-icon-a"
  },
  {
    uuid: "4c52868b-734c-4c1b-afb6-6c11a9283006",
    iconSet: "Fontisto",
    iconName: "nav-icon-grid-a"
  },
  {
    uuid: "13cee87e-2bd1-4673-a0d8-edd7fd075f81",
    iconSet: "Fontisto",
    iconName: "nav-icon-grid"
  },
  {
    uuid: "1ee7f765-d976-4d27-a7c6-d9dfd71e8609",
    iconSet: "Fontisto",
    iconName: "nav-icon-list-a"
  },
  {
    uuid: "032a81b8-113d-4f85-8aaa-6343ab72331e",
    iconSet: "Fontisto",
    iconName: "nav-icon-list"
  },
  {
    uuid: "c724c0c9-6d8b-41b0-a2df-eb6300d940dd",
    iconSet: "Fontisto",
    iconName: "nav-icon"
  },
  {
    uuid: "7b8fef8a-e1e1-47bc-acef-5b3f7a7c2990",
    iconSet: "Fontisto",
    iconName: "navigate"
  },
  {
    uuid: "47e686b7-c911-4352-a299-e5d6d036664a",
    iconSet: "Fontisto",
    iconName: "paper-plane"
  },
  {
    uuid: "e1fa8f98-8d70-4bf1-b03c-7491c144a46f",
    iconSet: "Fontisto",
    iconName: "person"
  },
  {
    uuid: "5e4ff6d7-1087-4427-98b9-71f091b3d1ef",
    iconSet: "Fontisto",
    iconName: "persons"
  },
  {
    uuid: "68260747-8c90-4289-bdaf-cf9914728222",
    iconSet: "Fontisto",
    iconName: "picture"
  },
  {
    uuid: "655a2e24-de0a-42fd-9f60-1013833a11da",
    iconSet: "Fontisto",
    iconName: "plus-a"
  },
  {
    uuid: "2ee537d7-6bbd-4c93-84b8-e463e84ab6ad",
    iconSet: "Fontisto",
    iconName: "print"
  },
  {
    uuid: "b31cb7ca-3ea3-4127-9dc2-deaa230b7306",
    iconSet: "Fontisto",
    iconName: "quote-a-left"
  },
  {
    uuid: "5e0772b0-0a48-4cbb-aad2-bfc0351b1527",
    iconSet: "Fontisto",
    iconName: "quote-a-right"
  },
  {
    uuid: "0ddc94be-9c1d-4de7-b07f-dafb9a12852a",
    iconSet: "Fontisto",
    iconName: "quote-left"
  },
  {
    uuid: "a81bb728-2b7e-42ee-936a-89f6a7fff30f",
    iconSet: "Fontisto",
    iconName: "quote-right"
  },
  {
    uuid: "eab735f6-b15f-4d3d-b9a4-22bffa356191",
    iconSet: "Fontisto",
    iconName: "reply"
  },
  {
    uuid: "3bfe5723-96e4-4a18-b2e5-a8cb3cebf22c",
    iconSet: "Fontisto",
    iconName: "rss"
  },
  {
    uuid: "8594faab-8801-4dad-9768-0af51d0bd2b7",
    iconSet: "Fontisto",
    iconName: "scissors"
  },
  {
    uuid: "5314f020-bb65-469c-bdac-00d8e59176a7",
    iconSet: "Fontisto",
    iconName: "share-a"
  },
  {
    uuid: "95a8051b-28f7-403a-aec3-aa01bcb411ec",
    iconSet: "Fontisto",
    iconName: "share"
  },
  {
    uuid: "6c289001-0a15-4e8f-ba95-a9ff9d1b151f",
    iconSet: "Fontisto",
    iconName: "trash"
  },
  {
    uuid: "8331391f-89b0-4393-895d-3e17cba98310",
    iconSet: "Fontisto",
    iconName: "unlocked"
  },
  {
    uuid: "fdc51fcd-d2f7-4561-9d39-43d6be81a748",
    iconSet: "Fontisto",
    iconName: "usb"
  },
  {
    uuid: "929b6ae1-2ba6-4d87-a38b-b8f63954115b",
    iconSet: "Fontisto",
    iconName: "wifi"
  },
  {
    uuid: "f1d02e8e-6f3a-4a12-a8b6-da8e77955950",
    iconSet: "Fontisto",
    iconName: "world-o"
  },
  {
    uuid: "0f68ce95-aad2-4be4-809a-76676908b2ef",
    iconSet: "Fontisto",
    iconName: "world"
  },
  {
    uuid: "36683454-66ec-443c-9e20-5539dbf21407",
    iconSet: "Fontisto",
    iconName: "zoom"
  },
  {
    uuid: "74d471fb-97f4-4580-b2bd-0951ee492e48",
    iconSet: "Fontisto",
    iconName: "adjust"
  },
  {
    uuid: "5e132e3f-ce74-4735-9328-96d027e35b6d",
    iconSet: "Fontisto",
    iconName: "recycle"
  },
  {
    uuid: "8d6cf05f-a207-4b23-9507-2f5110d37da0",
    iconSet: "Fontisto",
    iconName: "pinboard"
  },
  {
    uuid: "149796be-d78c-4020-a7d3-0649b407cd62",
    iconSet: "Fontisto",
    iconName: "zoom-minus"
  },
  {
    uuid: "94ac82cc-453c-4f5e-ad4f-9048dfe0a9c2",
    iconSet: "Fontisto",
    iconName: "zoom-plus"
  },
  {
    uuid: "f9a3c882-708c-4471-a302-f2069223070c",
    iconSet: "Fontisto",
    iconName: "check"
  },
  {
    uuid: "736e8e38-cfa7-4f9f-aa8a-9376b56f9573",
    iconSet: "Fontisto",
    iconName: "asterisk"
  },
  {
    uuid: "3f42e9e1-4aa0-4d0e-b6e2-97a18528e24d",
    iconSet: "Fontisto",
    iconName: "hashtag"
  },
  {
    uuid: "1dbc914b-1570-4d16-9ab1-6ce6d7c2fe5f",
    iconSet: "Fontisto",
    iconName: "checkbox-active"
  },
  {
    uuid: "9f7b9d2f-b899-448c-813d-154eb1305a05",
    iconSet: "Fontisto",
    iconName: "checkbox-passive"
  },
  {
    uuid: "bd98845f-9e4c-4ce4-acf1-1adedf63faab",
    iconSet: "Fontisto",
    iconName: "radio-btn-active"
  },
  {
    uuid: "a30832e1-5efe-41d7-b661-c4553a8b2496",
    iconSet: "Fontisto",
    iconName: "radio-btn-passive"
  },
  {
    uuid: "dd9db0bd-5351-40c2-8f98-670ec9232b66",
    iconSet: "Fontisto",
    iconName: "shopping-bag-1"
  },
  {
    uuid: "f040bcbc-41c2-4b2c-8472-322f113dbde4",
    iconSet: "Fontisto",
    iconName: "shopping-bag"
  },
  {
    uuid: "b3deeea2-a8eb-4793-ab48-f106c8b6439b",
    iconSet: "Fontisto",
    iconName: "shopping-barcode"
  },
  {
    uuid: "9440839c-8798-42dd-a5a0-3c2a1400c721",
    iconSet: "Fontisto",
    iconName: "shopping-basket-add"
  },
  {
    uuid: "d3682b56-f465-48cd-8a2f-60301d7326eb",
    iconSet: "Fontisto",
    iconName: "shopping-basket-remove"
  },
  {
    uuid: "3a91fb3a-75eb-40cd-b95b-9d774fa14d46",
    iconSet: "Fontisto",
    iconName: "shopping-basket"
  },
  {
    uuid: "29f5f5df-965d-4513-b6e5-a59b28dea9fb",
    iconSet: "Fontisto",
    iconName: "shopping-package"
  },
  {
    uuid: "5e53e226-fee7-4b3d-b548-ff14bedd1350",
    iconSet: "Fontisto",
    iconName: "shopping-pos-machine"
  },
  {
    uuid: "32033689-f89c-4c0e-b75d-7648f490f96e",
    iconSet: "Fontisto",
    iconName: "shopping-sale"
  },
  {
    uuid: "9b855571-1eb3-4450-bb49-7057732b6534",
    iconSet: "Fontisto",
    iconName: "shopping-store"
  },
  {
    uuid: "d472a138-77f4-41ad-a7bb-d36f4cb70aa6",
    iconSet: "Fontisto",
    iconName: "angle-dobule-down"
  },
  {
    uuid: "6f411654-066d-45e0-9d73-923abb4651e3",
    iconSet: "Fontisto",
    iconName: "angle-dobule-left"
  },
  {
    uuid: "c565edc5-32a6-4c41-a18d-7221cc6c36ff",
    iconSet: "Fontisto",
    iconName: "angle-dobule-right"
  },
  {
    uuid: "80988815-f5a9-4618-9df5-eedc06eb2903",
    iconSet: "Fontisto",
    iconName: "angle-dobule-up"
  },
  {
    uuid: "3136486a-a907-4bd7-93c3-0d2e8e1a5162",
    iconSet: "Fontisto",
    iconName: "angle-down"
  },
  {
    uuid: "37c067f3-44af-4611-bbf0-361c1e3243da",
    iconSet: "Fontisto",
    iconName: "angle-left"
  },
  {
    uuid: "6756d8d2-e32f-4315-84ad-4508a701d0f3",
    iconSet: "Fontisto",
    iconName: "angle-right"
  },
  {
    uuid: "0a86e102-60c5-42ae-af7d-c5b962f79060",
    iconSet: "Fontisto",
    iconName: "angle-up"
  },
  {
    uuid: "991836a5-3fa5-45c7-b0eb-7c35cfaad323",
    iconSet: "Fontisto",
    iconName: "arrow-down-l"
  },
  {
    uuid: "461f1386-0e1f-4e62-b2c7-825548c283f2",
    iconSet: "Fontisto",
    iconName: "arrow-down"
  },
  {
    uuid: "52763123-cfba-4049-a64b-5f9ba653fa18",
    iconSet: "Fontisto",
    iconName: "arrow-expand"
  },
  {
    uuid: "70c86ef3-a89a-460b-9968-c7cac1d40828",
    iconSet: "Fontisto",
    iconName: "arrow-h"
  },
  {
    uuid: "34cbeb78-56f9-4649-b45e-3fbc8b982fe1",
    iconSet: "Fontisto",
    iconName: "arrow-left-l"
  },
  {
    uuid: "ed20d540-865a-4b04-b154-6309ab77cc05",
    iconSet: "Fontisto",
    iconName: "arrow-left"
  },
  {
    uuid: "f1a0fb59-ca66-43da-904e-394fa11a43e3",
    iconSet: "Fontisto",
    iconName: "arrow-move"
  },
  {
    uuid: "2cad00fd-a7f6-46b3-af3f-ad83637af105",
    iconSet: "Fontisto",
    iconName: "arrow-resize"
  },
  {
    uuid: "7360ccf9-7d9f-4600-ae34-cabb46f9e4ba",
    iconSet: "Fontisto",
    iconName: "arrow-return-left"
  },
  {
    uuid: "f4bd549b-6e48-4191-bc64-4a8527da6119",
    iconSet: "Fontisto",
    iconName: "arrow-return-right"
  },
  {
    uuid: "c0b095ba-8352-4b77-b597-2fa9654ed09a",
    iconSet: "Fontisto",
    iconName: "arrow-right-l"
  },
  {
    uuid: "d53b3985-1cb0-4657-8109-567fd8d3bd30",
    iconSet: "Fontisto",
    iconName: "arrow-right"
  },
  {
    uuid: "9aed2a2f-6c82-4e0f-979a-878069ef501d",
    iconSet: "Fontisto",
    iconName: "arrow-swap"
  },
  {
    uuid: "1ef3da69-3577-463c-8085-41bfd2b24047",
    iconSet: "Fontisto",
    iconName: "arrow-up-l"
  },
  {
    uuid: "1f15ed44-cf69-4974-bf6e-6759ad136f71",
    iconSet: "Fontisto",
    iconName: "arrow-up"
  },
  {
    uuid: "1246919c-7d53-4616-b33d-67d15835624f",
    iconSet: "Fontisto",
    iconName: "arrow-v"
  },
  {
    uuid: "aa2a0618-4d26-4b74-b035-5199ce6f5bd7",
    iconSet: "Fontisto",
    iconName: "caret-down"
  },
  {
    uuid: "1e2208a0-d5be-4a1a-a726-60940e2e8e26",
    iconSet: "Fontisto",
    iconName: "caret-left"
  },
  {
    uuid: "1a66b8df-b38a-44e6-b8d7-1a2d20a7cdbf",
    iconSet: "Fontisto",
    iconName: "caret-right"
  },
  {
    uuid: "cc18338c-a18c-49c1-85b5-6e58d3ef6e12",
    iconSet: "Fontisto",
    iconName: "caret-up"
  },
  {
    uuid: "6733a1d7-5a19-4b1d-9f1e-5270c6d0414a",
    iconSet: "Fontisto",
    iconName: "fi"
  },
  {
    uuid: "bcf67021-4dba-4529-856a-417506e1b727",
    iconSet: "Fontisto",
    iconName: "fontisto"
  },
  {
    uuid: "2becc276-a20b-4fa7-a093-47f7ba4776f1",
    iconSet: "Fontisto",
    iconName: "backward"
  },
  {
    uuid: "0835db33-389a-4f11-8abf-1be863a2078a",
    iconSet: "Fontisto",
    iconName: "eject"
  },
  {
    uuid: "6c85c4d9-5eab-439d-bbf9-bb4d9873ebf0",
    iconSet: "Fontisto",
    iconName: "equalizer"
  },
  {
    uuid: "48b655ab-6a05-42e9-8585-9edb5fbea046",
    iconSet: "Fontisto",
    iconName: "forward"
  },
  {
    uuid: "919aa123-7ed4-4c85-9e0e-1ecd99ac3456",
    iconSet: "Fontisto",
    iconName: "headphone"
  },
  {
    uuid: "6533ee06-18bd-44f7-9100-45412794a1e7",
    iconSet: "Fontisto",
    iconName: "heart"
  },
  {
    uuid: "9e5d0c6b-90a1-478e-99d9-489670e741a6",
    iconSet: "Fontisto",
    iconName: "mic"
  },
  {
    uuid: "9a44f0b2-be44-4c82-92fe-e51c7ec9da0f",
    iconSet: "Fontisto",
    iconName: "music-note"
  },
  {
    uuid: "b278b880-7ee0-4277-a6d8-1576fee2451d",
    iconSet: "Fontisto",
    iconName: "pause"
  },
  {
    uuid: "034c1af9-a46b-40de-ba14-02af63007fa6",
    iconSet: "Fontisto",
    iconName: "play-list"
  },
  {
    uuid: "ca7d6970-1494-492f-8b3c-628b7b61c388",
    iconSet: "Fontisto",
    iconName: "play"
  },
  {
    uuid: "cf09d231-edb3-44c9-87f8-4e2d311fe3f0",
    iconSet: "Fontisto",
    iconName: "player-settings"
  },
  {
    uuid: "d4a2e8fc-d801-415b-8b1b-5363777a6746",
    iconSet: "Fontisto",
    iconName: "podcast"
  },
  {
    uuid: "74437102-5495-4efe-b21d-dac9ed151f6c",
    iconSet: "Fontisto",
    iconName: "random"
  },
  {
    uuid: "ff8a0b48-6a34-4ee4-9e03-fc8da91a3f5d",
    iconSet: "Fontisto",
    iconName: "record"
  },
  {
    uuid: "0260e1f0-f893-4b49-927f-0c5fa0be8c85",
    iconSet: "Fontisto",
    iconName: "star"
  },
  {
    uuid: "f2922264-0a83-42a4-a544-4a10e65e0f23",
    iconSet: "Fontisto",
    iconName: "step-backwrad"
  },
  {
    uuid: "84b1a65c-1c1c-4f9a-916b-845494bda297",
    iconSet: "Fontisto",
    iconName: "step-forward"
  },
  {
    uuid: "ebedf179-d6d8-4326-9453-6fd70b22c169",
    iconSet: "Fontisto",
    iconName: "stop"
  },
  {
    uuid: "073ecb69-fa94-4a2f-9fa1-8dd26c2022c1",
    iconSet: "Fontisto",
    iconName: "volume-down"
  },
  {
    uuid: "785b3ee0-9fce-4363-a58f-33624bc4371a",
    iconSet: "Fontisto",
    iconName: "volume-mute"
  },
  {
    uuid: "4f29951e-2da4-4cf3-a36f-58740f88e102",
    iconSet: "Fontisto",
    iconName: "volume-off"
  },
  {
    uuid: "08d8b991-595c-4a0f-9577-7862e5bd71ea",
    iconSet: "Fontisto",
    iconName: "volume-up"
  },
  {
    uuid: "4dc2bcc9-7734-476b-816b-875d4a883f9a",
    iconSet: "Fontisto",
    iconName: "airplay"
  },
  {
    uuid: "9947ad87-7165-4dbe-bc3a-d984f883c318",
    iconSet: "Fontisto",
    iconName: "bold"
  },
  {
    uuid: "92f3024c-749b-404d-9317-7af089ced275",
    iconSet: "Fontisto",
    iconName: "broken-link"
  },
  {
    uuid: "faf9dac7-9ae8-4518-9d17-57e07165bb23",
    iconSet: "Fontisto",
    iconName: "center-align"
  },
  {
    uuid: "32d55718-b43f-44d4-b1f1-6bdc34d6ba87",
    iconSet: "Fontisto",
    iconName: "close"
  },
  {
    uuid: "6110bfe7-be59-45d8-b528-055e1e681535",
    iconSet: "Fontisto",
    iconName: "columns"
  },
  {
    uuid: "7301d379-f9a8-45b6-be30-59431ef2fa53",
    iconSet: "Fontisto",
    iconName: "copy"
  },
  {
    uuid: "31c4ea87-b5b0-4aaa-ab04-b667d1dda23c",
    iconSet: "Fontisto",
    iconName: "eraser"
  },
  {
    uuid: "d900ddd5-7a97-4119-99df-e0a558c38b88",
    iconSet: "Fontisto",
    iconName: "export"
  },
  {
    uuid: "fa72a83f-6ea5-4950-8b8d-0d8be02e51e9",
    iconSet: "Fontisto",
    iconName: "file-1"
  },
  {
    uuid: "471b7e87-1e1e-4282-8532-8ca3c7d0994b",
    iconSet: "Fontisto",
    iconName: "file-2"
  },
  {
    uuid: "1d1e7d40-6baf-4122-8aba-2b0b6e89690f",
    iconSet: "Fontisto",
    iconName: "folder"
  },
  {
    uuid: "be3fa1f2-e305-4d86-b011-f597f8d67f90",
    iconSet: "Fontisto",
    iconName: "font"
  },
  {
    uuid: "5d53d202-ddff-49a8-9de3-dd506834c637",
    iconSet: "Fontisto",
    iconName: "import"
  },
  {
    uuid: "59d8f2af-0023-4d2f-b3e2-f089f34d842f",
    iconSet: "Fontisto",
    iconName: "indent"
  },
  {
    uuid: "ea40b443-f612-4bde-aaa1-4180e980dbdf",
    iconSet: "Fontisto",
    iconName: "italic"
  },
  {
    uuid: "6ebafa70-eb64-487e-9483-5c1c60df64d0",
    iconSet: "Fontisto",
    iconName: "justify"
  },
  {
    uuid: "4f97b03c-1c34-4564-a5bf-208a67c13b35",
    iconSet: "Fontisto",
    iconName: "left-align"
  },
  {
    uuid: "0a051e9d-4ec3-4aae-b6cb-4ccb34ab151b",
    iconSet: "Fontisto",
    iconName: "link2"
  },
  {
    uuid: "57318132-06f7-4dc9-8181-3f4ee2983b8a",
    iconSet: "Fontisto",
    iconName: "list-1"
  },
  {
    uuid: "5dbf941a-98c2-4282-9905-c30fde492b1e",
    iconSet: "Fontisto",
    iconName: "list-2"
  },
  {
    uuid: "b2e9a803-43a5-47d7-b4b0-ffa594c1f797",
    iconSet: "Fontisto",
    iconName: "outdent"
  },
  {
    uuid: "0ca54815-bcee-4b38-b82a-cac9291d5595",
    iconSet: "Fontisto",
    iconName: "paperclip"
  },
  {
    uuid: "2dff9e31-99e1-456c-be71-c6034f5bf303",
    iconSet: "Fontisto",
    iconName: "paragraph"
  },
  {
    uuid: "bf33dbc4-930e-4ad9-93ab-c85ccf03689c",
    iconSet: "Fontisto",
    iconName: "paste"
  },
  {
    uuid: "cdb33fc5-e51d-405a-b69f-8e167f0ce7c3",
    iconSet: "Fontisto",
    iconName: "preview"
  },
  {
    uuid: "30397cfa-d976-4e25-9efd-e62643c92516",
    iconSet: "Fontisto",
    iconName: "print2"
  },
  {
    uuid: "fcfcd0f4-c824-46a9-86de-1c97276cb5d1",
    iconSet: "Fontisto",
    iconName: "redo"
  },
  {
    uuid: "8266ffb1-ed13-480b-b5ea-04f4a6757baf",
    iconSet: "Fontisto",
    iconName: "right-align"
  },
  {
    uuid: "927513ed-8a9f-4418-9e01-6aac2a370959",
    iconSet: "Fontisto",
    iconName: "save-1"
  },
  {
    uuid: "e871b044-f275-4147-9f37-37ce5d5945a1",
    iconSet: "Fontisto",
    iconName: "save"
  },
  {
    uuid: "a81e02a2-47bb-4184-bedc-9b60ba30f13b",
    iconSet: "Fontisto",
    iconName: "scissors2"
  },
  {
    uuid: "992b7996-89ed-49da-9d02-46c5229b6937",
    iconSet: "Fontisto",
    iconName: "strikethrough"
  },
  {
    uuid: "88b5af7c-55ad-4569-a21b-99288429d139",
    iconSet: "Fontisto",
    iconName: "subscript"
  },
  {
    uuid: "46c9f3d2-0ede-40e3-b678-dec544c6b592",
    iconSet: "Fontisto",
    iconName: "superscript"
  },
  {
    uuid: "750dc05d-241d-47ab-98be-0f66009da303",
    iconSet: "Fontisto",
    iconName: "table-1"
  },
  {
    uuid: "2210e7eb-9556-493e-b937-986a66700890",
    iconSet: "Fontisto",
    iconName: "table-2"
  },
  {
    uuid: "d12b2d40-242e-424a-afe7-b9302d8d44b9",
    iconSet: "Fontisto",
    iconName: "text-height"
  },
  {
    uuid: "235f187a-ab58-4d11-9de3-66d7ec14308d",
    iconSet: "Fontisto",
    iconName: "text-width"
  },
  {
    uuid: "42f3555c-6128-4325-b26a-2db10b03194c",
    iconSet: "Fontisto",
    iconName: "underline"
  },
  {
    uuid: "07f57d95-8bd1-4b54-ad9c-65bae0d53216",
    iconSet: "Fontisto",
    iconName: "undo"
  },
  {
    uuid: "3ab384b9-8342-4249-a033-32297048ed12",
    iconSet: "Fontisto",
    iconName: "cloud-down"
  },
  {
    uuid: "45ffceaa-fef2-4179-95ac-ac5a3a89b17f",
    iconSet: "Fontisto",
    iconName: "cloud-refresh"
  },
  {
    uuid: "fdfac445-fb72-4f2e-b00e-aa76e7212d5c",
    iconSet: "Fontisto",
    iconName: "cloud-up"
  },
  {
    uuid: "064952a6-96ba-465f-8b3b-4c6792fc996e",
    iconSet: "Fontisto",
    iconName: "cloudy-gusts"
  },
  {
    uuid: "0a78bae8-f096-4b35-9cc3-503f2e7483c3",
    iconSet: "Fontisto",
    iconName: "cloudy"
  },
  {
    uuid: "0bd16501-8645-46b3-bc80-41d02a235f75",
    iconSet: "Fontisto",
    iconName: "compass"
  },
  {
    uuid: "eaf7d261-9ff4-4ade-9cf2-45d14f664a49",
    iconSet: "Fontisto",
    iconName: "day-cloudy"
  },
  {
    uuid: "cf1b04ca-1442-41ec-9ce8-8420752c80f2",
    iconSet: "Fontisto",
    iconName: "day-haze"
  },
  {
    uuid: "7be720ad-c354-4503-b5ee-4fc2782e96d8",
    iconSet: "Fontisto",
    iconName: "day-lightning"
  },
  {
    uuid: "6f80ab22-6922-42de-8c27-eecc1af38f01",
    iconSet: "Fontisto",
    iconName: "day-rain"
  },
  {
    uuid: "1a1c9e57-cca7-466c-acbe-833d7fb9f8b8",
    iconSet: "Fontisto",
    iconName: "day-snow"
  },
  {
    uuid: "e6a71765-4dde-4804-8401-cf4e216e173f",
    iconSet: "Fontisto",
    iconName: "day-sunny"
  },
  {
    uuid: "d021bbdf-b47f-43ea-8f48-d2c504c45851",
    iconSet: "Fontisto",
    iconName: "fog"
  },
  {
    uuid: "f6b1fe07-f89a-4102-83b8-5209a469c41b",
    iconSet: "Fontisto",
    iconName: "horizon-alt"
  },
  {
    uuid: "3633411d-8851-4aac-87c7-bada3cf75062",
    iconSet: "Fontisto",
    iconName: "horizon"
  },
  {
    uuid: "1feb75fa-443f-4851-9d50-149a54a7e3e6",
    iconSet: "Fontisto",
    iconName: "lightning"
  },
  {
    uuid: "ce439c2a-ffae-483a-a7da-a0f4e8de949c",
    iconSet: "Fontisto",
    iconName: "lightnings"
  },
  {
    uuid: "6a9a06ff-68a2-4c31-98aa-f6190d4243f3",
    iconSet: "Fontisto",
    iconName: "night-alt-cloudy"
  },
  {
    uuid: "690aa81c-c881-4589-b9dc-08d551e764b7",
    iconSet: "Fontisto",
    iconName: "night-alt-lightning"
  },
  {
    uuid: "81b0d658-8526-41b2-b0db-0502d8fe255f",
    iconSet: "Fontisto",
    iconName: "night-alt-rain"
  },
  {
    uuid: "55527151-2e18-40eb-b525-9b00a4805d12",
    iconSet: "Fontisto",
    iconName: "night-alt-snow"
  },
  {
    uuid: "5eb58353-1138-4ad1-9318-022dea3732e1",
    iconSet: "Fontisto",
    iconName: "night-clear"
  },
  {
    uuid: "b4bda688-0912-431c-96b8-757cf503c085",
    iconSet: "Fontisto",
    iconName: "rain"
  },
  {
    uuid: "94baf1f6-a86b-4c12-ac23-fe0f613b9439",
    iconSet: "Fontisto",
    iconName: "rainbow"
  },
  {
    uuid: "01d240ce-1004-4be5-9071-975d8714246f",
    iconSet: "Fontisto",
    iconName: "rains"
  },
  {
    uuid: "5739ef1c-e8fa-4122-8091-48d85c041b21",
    iconSet: "Fontisto",
    iconName: "snow"
  },
  {
    uuid: "665a12c7-efce-453a-b960-a3bc74d17338",
    iconSet: "Fontisto",
    iconName: "snows"
  },
  {
    uuid: "52f4ab64-f3b5-4c8a-871c-951e770f4e33",
    iconSet: "Fontisto",
    iconName: "thermometer"
  },
  {
    uuid: "4e63dcc1-62bc-4b98-a4c7-68b97130d26b",
    iconSet: "Fontisto",
    iconName: "umbrella"
  },
  {
    uuid: "1b1c9ca7-8a74-4c03-9631-f54f3312f913",
    iconSet: "Fontisto",
    iconName: "wind"
  },
  {
    uuid: "aae37087-fa00-4b97-b65d-9f1fcebf180d",
    iconSet: "Fontisto",
    iconName: "confused"
  },
  {
    uuid: "ff534e93-8a37-426e-9eb7-4c2b435cd316",
    iconSet: "Fontisto",
    iconName: "dizzy"
  },
  {
    uuid: "8f06f5b2-3574-4a82-9c39-9bd71a6a7faf",
    iconSet: "Fontisto",
    iconName: "expressionless"
  },
  {
    uuid: "e5a846e9-4e32-488a-9b8e-dc08b8a47313",
    iconSet: "Fontisto",
    iconName: "frowning"
  },
  {
    uuid: "852eec5e-aae8-4fe1-a9a0-f98edf67d01e",
    iconSet: "Fontisto",
    iconName: "heart-eyes"
  },
  {
    uuid: "f4ad6d43-eb68-46a7-bb54-f06904de4e5b",
    iconSet: "Fontisto",
    iconName: "laughing"
  },
  {
    uuid: "a1eb21b7-43b7-402b-baae-d4e0e9906569",
    iconSet: "Fontisto",
    iconName: "mad"
  },
  {
    uuid: "19b0adff-4f7c-4192-8815-acd5349dc0bc",
    iconSet: "Fontisto",
    iconName: "nervous"
  },
  {
    uuid: "27c75589-8bc7-4f3c-9a74-e65214e3bdfa",
    iconSet: "Fontisto",
    iconName: "neutral"
  },
  {
    uuid: "9564457f-e07f-4629-90e1-15360a2e2008",
    iconSet: "Fontisto",
    iconName: "open-mouth"
  },
  {
    uuid: "5b3de364-2ff9-4672-9bd5-454f2be94a82",
    iconSet: "Fontisto",
    iconName: "rage"
  },
  {
    uuid: "21b3f6da-a9e0-4b33-aab7-041abb4ecebc",
    iconSet: "Fontisto",
    iconName: "slightly-smile"
  },
  {
    uuid: "9c864446-6be0-4c4d-b3f3-47d46cb2578c",
    iconSet: "Fontisto",
    iconName: "smiley"
  },
  {
    uuid: "698c7371-0f86-4f63-a7fc-e2ba29dee87d",
    iconSet: "Fontisto",
    iconName: "smiling"
  },
  {
    uuid: "14f57de8-9334-496d-aac4-a6114b757b0a",
    iconSet: "Fontisto",
    iconName: "stuck-out-tongue"
  },
  {
    uuid: "12284a37-83f6-4c94-ad3b-5bdfa6b7726a",
    iconSet: "Fontisto",
    iconName: "sunglasses"
  },
  {
    uuid: "83cdf5e2-9aba-43af-9c55-77cb17d57b0a",
    iconSet: "Fontisto",
    iconName: "surprised"
  },
  {
    uuid: "e6739ec2-3ecd-4e40-81a7-a220b1369408",
    iconSet: "Fontisto",
    iconName: "tongue"
  },
  {
    uuid: "9de792b2-6228-4539-9866-d4bd51f73c52",
    iconSet: "Fontisto",
    iconName: "wink"
  },
  {
    uuid: "9ac1dfd9-02dd-40ff-ac14-71ad64b835d6",
    iconSet: "Fontisto",
    iconName: "zipper-mouth"
  },
  {
    uuid: "000acdf4-d715-4cc0-987e-e5803fe2b8fa",
    iconSet: "Fontisto",
    iconName: "aids"
  },
  {
    uuid: "ad952c31-3b3c-431f-a35b-b923a25a4443",
    iconSet: "Fontisto",
    iconName: "ambulance"
  },
  {
    uuid: "c96ca50e-a964-4bd3-a233-c4f2d7f1bc57",
    iconSet: "Fontisto",
    iconName: "bandage"
  },
  {
    uuid: "5d3777b2-4648-4069-a74e-332a718aee26",
    iconSet: "Fontisto",
    iconName: "bed-patient"
  },
  {
    uuid: "14b64c4b-3843-4be9-a5f2-bc44ed96c73c",
    iconSet: "Fontisto",
    iconName: "blood-drop"
  },
  {
    uuid: "6b19acfc-9ad5-4307-a917-675438fd9880",
    iconSet: "Fontisto",
    iconName: "blood-test"
  },
  {
    uuid: "f0d7aa9b-88b4-4358-847d-f2d7aa62364d",
    iconSet: "Fontisto",
    iconName: "blood"
  },
  {
    uuid: "60885a29-8eb3-4b97-ad19-26e5a8765967",
    iconSet: "Fontisto",
    iconName: "dna"
  },
  {
    uuid: "f4ba0576-956f-45f3-bf9c-184b4e25c99e",
    iconSet: "Fontisto",
    iconName: "doctor"
  },
  {
    uuid: "10bcc0e0-3496-4412-a8a6-c2c1c9d3d396",
    iconSet: "Fontisto",
    iconName: "drug-pack"
  },
  {
    uuid: "bb801a16-42a0-4968-ab24-dcc4e9284b83",
    iconSet: "Fontisto",
    iconName: "first-aid-alt"
  },
  {
    uuid: "8166cf71-f9a5-4df9-a6c4-a65109950cac",
    iconSet: "Fontisto",
    iconName: "heart-alt"
  },
  {
    uuid: "a0cd86a6-f6bc-496a-afa1-daaee0d1978c",
    iconSet: "Fontisto",
    iconName: "heartbeat-alt"
  },
  {
    uuid: "d10bf454-1d89-4289-9fe4-1047c884e8c4",
    iconSet: "Fontisto",
    iconName: "heartbeat"
  },
  {
    uuid: "4d64c577-6e7d-4b47-a0c0-1b92ab201ec4",
    iconSet: "Fontisto",
    iconName: "helicopter-ambulance"
  },
  {
    uuid: "eb54f0e8-8c2b-43f1-a9ef-99008b9f0052",
    iconSet: "Fontisto",
    iconName: "hospital"
  },
  {
    uuid: "b7dbe7aa-28a2-4bbc-9daa-183c39d76757",
    iconSet: "Fontisto",
    iconName: "injection-syringe"
  },
  {
    uuid: "dac6e1f1-2df7-46be-bffb-90fdfb4d9270",
    iconSet: "Fontisto",
    iconName: "laboratory"
  },
  {
    uuid: "a93564df-138f-463c-bf1b-d7387242ea88",
    iconSet: "Fontisto",
    iconName: "nurse"
  },
  {
    uuid: "ceb84588-beb9-4bba-92e3-62e8b1d7a5f4",
    iconSet: "Fontisto",
    iconName: "nursing-home"
  },
  {
    uuid: "fc3510c8-68ba-4ef1-a4a0-144e32beae53",
    iconSet: "Fontisto",
    iconName: "paralysis-disability"
  },
  {
    uuid: "49cdc2f8-fc34-4b14-87a2-158a52511f52",
    iconSet: "Fontisto",
    iconName: "pills"
  },
  {
    uuid: "1f6dbd34-0090-4a71-80b2-5346c17b7bc7",
    iconSet: "Fontisto",
    iconName: "prescription"
  },
  {
    uuid: "bb4a9b7f-0ff5-4069-ab3c-686fdd1c1c6a",
    iconSet: "Fontisto",
    iconName: "pulse"
  },
  {
    uuid: "ab439ff8-4fa6-4155-9f4d-74010ade8a10",
    iconSet: "Fontisto",
    iconName: "stethoscope"
  },
  {
    uuid: "30127841-c6a7-46f1-a912-5524a33e3395",
    iconSet: "Fontisto",
    iconName: "surgical-knife"
  },
  {
    uuid: "57f23945-5404-4ec1-b74e-fe68eaaf0431",
    iconSet: "Fontisto",
    iconName: "tablets"
  },
  {
    uuid: "8d56e71a-d588-479f-afbf-0cc13a3eecbf",
    iconSet: "Fontisto",
    iconName: "test-bottle"
  },
  {
    uuid: "a41cf684-7709-454d-8784-1f2d5fe13a81",
    iconSet: "Fontisto",
    iconName: "test-tube-alt"
  },
  {
    uuid: "73f64794-bb42-4e4c-9b48-21ee84311eab",
    iconSet: "Fontisto",
    iconName: "test-tube"
  },
  {
    uuid: "5d063be6-195a-4783-916e-39f1418dd6a5",
    iconSet: "Fontisto",
    iconName: "thermometer-alt"
  },
  {
    uuid: "dc4cd1ae-c298-4aae-8b15-a15214d5ba36",
    iconSet: "Fontisto",
    iconName: "american-express"
  },
  {
    uuid: "a48f7acf-182f-4385-ace7-aaf4ad0a2a0d",
    iconSet: "Fontisto",
    iconName: "credit-card"
  },
  {
    uuid: "5ef499ae-f168-428f-9b9b-19dfb3b17f16",
    iconSet: "Fontisto",
    iconName: "google-wallet"
  },
  {
    uuid: "d7c9e7f9-601d-4f70-bd81-ca7cd9266726",
    iconSet: "Fontisto",
    iconName: "iyzigo"
  },
  {
    uuid: "7b2c29df-005f-4d50-a0ed-08dc986be1eb",
    iconSet: "Fontisto",
    iconName: "mastercard"
  },
  {
    uuid: "01d6d16f-f89c-40f8-baab-ed6ed4766050",
    iconSet: "Fontisto",
    iconName: "paypal-p"
  },
  {
    uuid: "684795e0-f73d-4f93-befd-65f5147e737c",
    iconSet: "Fontisto",
    iconName: "paypal"
  },
  {
    uuid: "3fd58026-68e7-49a4-90a5-5a859b0066eb",
    iconSet: "Fontisto",
    iconName: "payu"
  },
  {
    uuid: "8df092c3-3382-431f-b3ae-fe44d7e0cf66",
    iconSet: "Fontisto",
    iconName: "troy"
  },
  {
    uuid: "8792164a-e98e-476d-8545-4f5290fe705e",
    iconSet: "Fontisto",
    iconName: "visa"
  },
  {
    uuid: "ed8203d3-ec24-4962-a531-cdbc0ccac2c6",
    iconSet: "Fontisto",
    iconName: "dinners-club"
  },
  {
    uuid: "ed9438d3-13e2-45d5-a6d5-6c712826c548",
    iconSet: "Fontisto",
    iconName: "apple-pay"
  },
  {
    uuid: "f01d5601-96c7-4f76-88f7-664baa9b326c",
    iconSet: "Fontisto",
    iconName: "discover"
  },
  {
    uuid: "cba120b1-9ce0-4fbd-8212-5d9e5d3db870",
    iconSet: "Fontisto",
    iconName: "jcb"
  },
  {
    uuid: "82fdbe34-714e-4ced-a9ca-fd20f5f04f38",
    iconSet: "Fontisto",
    iconName: "dislike"
  },
  {
    uuid: "08408202-0278-4963-94ea-1e55b18ae1a7",
    iconSet: "Fontisto",
    iconName: "like"
  },
  {
    uuid: "c654a89c-f1d6-4066-9433-bf2cb17f791b",
    iconSet: "Fontisto",
    iconName: "audio-description"
  },
  {
    uuid: "7a0b930e-7780-470c-868e-4e8589341d22",
    iconSet: "Fontisto",
    iconName: "blind"
  },
  {
    uuid: "a38ede50-5382-4a81-81a7-3df1f64eecba",
    iconSet: "Fontisto",
    iconName: "braille"
  },
  {
    uuid: "5a3ca3ec-9b7a-47c2-9d14-f4746c97d69a",
    iconSet: "Fontisto",
    iconName: "deaf"
  },
  {
    uuid: "bded3f92-c3b7-4232-b7b2-c59629a667a2",
    iconSet: "Fontisto",
    iconName: "fa-american-sign-language-interpreting"
  },
  {
    uuid: "1ade3730-e02b-45ef-82f7-ce274484007f",
    iconSet: "Fontisto",
    iconName: "low-vision"
  },
  {
    uuid: "630731cc-f6b2-44d2-89c8-fe94ec084bba",
    iconSet: "Fontisto",
    iconName: "tty"
  },
  {
    uuid: "aecbeaab-063c-48ec-8080-d68a7a308b96",
    iconSet: "Fontisto",
    iconName: "universal-acces"
  },
  {
    uuid: "6c549a72-fdfb-4515-bacd-a741f16bd8e5",
    iconSet: "Fontisto",
    iconName: "wheelchair"
  },
  {
    uuid: "49743a26-6092-4b7d-8447-053520b184b3",
    iconSet: "Fontisto",
    iconName: "area-chart"
  },
  {
    uuid: "a75d6102-9aa6-4250-80b2-7ab19e016781",
    iconSet: "Fontisto",
    iconName: "bar-chart"
  },
  {
    uuid: "a4610793-382c-41fb-80f5-2c2caa945bed",
    iconSet: "Fontisto",
    iconName: "line-chart"
  },
  {
    uuid: "0f007c56-26f6-4a03-bea2-634554f91140",
    iconSet: "Fontisto",
    iconName: "pie-chart-1"
  },
  {
    uuid: "2f9e1c1d-88d6-40ab-96ef-a0549d87c6e5",
    iconSet: "Fontisto",
    iconName: "pie-chart-2"
  },
  {
    uuid: "093ba314-c391-4d7d-8aeb-e9045dcaff01",
    iconSet: "Fontisto",
    iconName: "chrome"
  },
  {
    uuid: "da303088-6088-40f8-b2d4-7e8aeaa9a8cb",
    iconSet: "Fontisto",
    iconName: "edge"
  },
  {
    uuid: "558e27ba-f482-4dc6-b956-4a002c83c869",
    iconSet: "Fontisto",
    iconName: "firefox"
  },
  {
    uuid: "f27f4ed3-7330-42cb-b3c5-ec6c660b91ff",
    iconSet: "Fontisto",
    iconName: "internet-explorer"
  },
  {
    uuid: "c63b84f8-2127-46be-a4a1-d7bdc1b0bf44",
    iconSet: "Fontisto",
    iconName: "opera"
  },
  {
    uuid: "eb913e29-4e50-4b79-8da6-8c9cfa3c3563",
    iconSet: "Fontisto",
    iconName: "safari"
  },
  {
    uuid: "c4d2ebee-f632-471c-86a2-8a284b66a94e",
    iconSet: "Fontisto",
    iconName: "bitcoin"
  },
  {
    uuid: "821b6ead-d2e4-4992-bb3f-df3af6fa63cc",
    iconSet: "Fontisto",
    iconName: "dollar"
  },
  {
    uuid: "874c10aa-4cc3-459d-9914-58cb686379f3",
    iconSet: "Fontisto",
    iconName: "euro"
  },
  {
    uuid: "f9ad38a3-5b06-45d2-ae08-c395e6321875",
    iconSet: "Fontisto",
    iconName: "gbp"
  },
  {
    uuid: "901f8a0b-6869-4ab1-ac76-7c868c8fe5e8",
    iconSet: "Fontisto",
    iconName: "gg"
  },
  {
    uuid: "7b791a7b-553c-43b5-953c-73925a017637",
    iconSet: "Fontisto",
    iconName: "ils"
  },
  {
    uuid: "43da91cb-4f87-4595-845a-028a16f15ac3",
    iconSet: "Fontisto",
    iconName: "inr"
  },
  {
    uuid: "7effea2c-f3aa-462b-b018-4cb74cc2e5ac",
    iconSet: "Fontisto",
    iconName: "krw"
  },
  {
    uuid: "6d992c06-f77a-4ed7-adb4-92e09646eac5",
    iconSet: "Fontisto",
    iconName: "rouble"
  },
  {
    uuid: "5e426a23-cb21-44dc-aad2-ab6766218660",
    iconSet: "Fontisto",
    iconName: "tl"
  },
  {
    uuid: "dae9aeb4-d2b6-4f07-914d-cb524ff03a66",
    iconSet: "Fontisto",
    iconName: "yen"
  },
  {
    uuid: "a23ea762-8a6a-49a2-bf1b-6e9501214468",
    iconSet: "Fontisto",
    iconName: "genderless"
  },
  {
    uuid: "47432278-fe27-4bcf-b21c-52ee0e97ad57",
    iconSet: "Fontisto",
    iconName: "intersex"
  },
  {
    uuid: "5e21036f-b008-452c-a110-332ed0caba8f",
    iconSet: "Fontisto",
    iconName: "mars-double"
  },
  {
    uuid: "18b3f707-3ef9-4c5a-b765-dc508891c0d2",
    iconSet: "Fontisto",
    iconName: "mars-stroke-h"
  },
  {
    uuid: "d6877236-16c8-4542-b785-1b6765daefe5",
    iconSet: "Fontisto",
    iconName: "mars-stroke-v"
  },
  {
    uuid: "e3722edb-3472-49d4-abf4-e32703041aab",
    iconSet: "Fontisto",
    iconName: "mars-stroke"
  },
  {
    uuid: "20927b39-4d7f-4919-abbf-e79cbef41f16",
    iconSet: "Fontisto",
    iconName: "mars"
  },
  {
    uuid: "23ed5d76-5fb5-4a2b-a861-c18d6dc62b7f",
    iconSet: "Fontisto",
    iconName: "mercury"
  },
  {
    uuid: "bd4e346f-0195-4392-b5b1-2201d4bcd946",
    iconSet: "Fontisto",
    iconName: "neuter"
  },
  {
    uuid: "baa89c58-810b-402e-bd92-1f8e4b22bcbc",
    iconSet: "Fontisto",
    iconName: "transgender-alt"
  },
  {
    uuid: "31eac362-5eb3-4371-ac13-6ee16429c980",
    iconSet: "Fontisto",
    iconName: "transgender"
  },
  {
    uuid: "a931a937-d766-4e58-aee0-9225c1fa266b",
    iconSet: "Fontisto",
    iconName: "venus-double"
  },
  {
    uuid: "2a7ed1fb-db95-4767-8cae-7b46874165d2",
    iconSet: "Fontisto",
    iconName: "venus-mars"
  },
  {
    uuid: "30b50d4f-2802-4e7c-980d-0b4c918023c6",
    iconSet: "Fontisto",
    iconName: "venus"
  },
  {
    uuid: "eadca3e6-62b9-408f-81fe-cf8b37a70ab0",
    iconSet: "Fontisto",
    iconName: "automobile"
  },
  {
    uuid: "4d505857-0b54-4a32-96a6-0e8ae9b57a0d",
    iconSet: "Fontisto",
    iconName: "bicycle"
  },
  {
    uuid: "2d352c19-077a-48e1-8848-1546ee375dcd",
    iconSet: "Fontisto",
    iconName: "bus"
  },
  {
    uuid: "8db46c9c-06db-49ad-8e8d-cec63b4cf727",
    iconSet: "Fontisto",
    iconName: "car"
  },
  {
    uuid: "f7fa838a-f61c-4cda-abe3-acd67ff27c8e",
    iconSet: "Fontisto",
    iconName: "helicopter"
  },
  {
    uuid: "61ab0646-7763-4459-bbd0-d1333b9aeb5c",
    iconSet: "Fontisto",
    iconName: "metro"
  },
  {
    uuid: "9cb0c07c-4bf5-45b2-9392-4c56962476f3",
    iconSet: "Fontisto",
    iconName: "motorcycle"
  },
  {
    uuid: "83140bdc-0fdd-461b-9396-d9f29ac1aa0c",
    iconSet: "Fontisto",
    iconName: "plane"
  },
  {
    uuid: "ea3b571e-5196-4f25-8a79-8aa6390ecf7b",
    iconSet: "Fontisto",
    iconName: "rocket"
  },
  {
    uuid: "d7facffc-e8b5-4681-a17e-4fa72865ea59",
    iconSet: "Fontisto",
    iconName: "ship"
  },
  {
    uuid: "8923004a-8139-43cf-bab2-0b17fec17bf4",
    iconSet: "Fontisto",
    iconName: "subway"
  },
  {
    uuid: "0d05bfb5-1be2-4674-bc97-ede536c45f0c",
    iconSet: "Fontisto",
    iconName: "taxi"
  },
  {
    uuid: "91a5a5e3-7f92-4e00-8604-9be9dd183ddd",
    iconSet: "Fontisto",
    iconName: "train"
  },
  {
    uuid: "12c66903-b178-4240-b9b9-220994117cfe",
    iconSet: "Fontisto",
    iconName: "truck"
  },
  {
    uuid: "e804e2a1-df2b-4bf8-9bba-1611fd8eab4a",
    iconSet: "Fontisto",
    iconName: "yacht"
  },
  {
    uuid: "956931ae-864c-484a-84e2-dbbcdd0ff7ac",
    iconSet: "Fontisto",
    iconName: "beach-slipper"
  },
  {
    uuid: "b52d6f86-c1eb-4ed7-a847-ed9ffb32dd53",
    iconSet: "Fontisto",
    iconName: "bus-ticket"
  },
  {
    uuid: "9e086740-f014-4d08-a8af-d058cbea2160",
    iconSet: "Fontisto",
    iconName: "cocktail"
  },
  {
    uuid: "5d1b8d59-957d-4afd-a9a4-04c350215c18",
    iconSet: "Fontisto",
    iconName: "compass-alt"
  },
  {
    uuid: "5a33a872-adbb-4d0d-8932-c0472937af91",
    iconSet: "Fontisto",
    iconName: "direction-sign"
  },
  {
    uuid: "e720aefa-1621-48c1-9ef0-151923503e17",
    iconSet: "Fontisto",
    iconName: "do-not-disturb"
  },
  {
    uuid: "0bd51ef8-b2d6-4cc6-8099-30fa81941240",
    iconSet: "Fontisto",
    iconName: "flotation-ring"
  },
  {
    uuid: "30197815-e2b0-4afc-ae09-8e6a247d9e39",
    iconSet: "Fontisto",
    iconName: "holiday-village"
  },
  {
    uuid: "7c86d097-2551-4540-9e65-f91217b5bda1",
    iconSet: "Fontisto",
    iconName: "hot-air-balloon"
  },
  {
    uuid: "80ee73e0-402b-4bd3-b8d3-863dc615f9cd",
    iconSet: "Fontisto",
    iconName: "hotel-alt"
  },
  {
    uuid: "a010ea65-fd38-4d39-9dd7-771cf8b57847",
    iconSet: "Fontisto",
    iconName: "hotel"
  },
  {
    uuid: "0b3ad90c-fc08-4199-9089-0842a208ae9e",
    iconSet: "Fontisto",
    iconName: "island"
  },
  {
    uuid: "ae496c0f-8f37-4878-9205-6218074fffde",
    iconSet: "Fontisto",
    iconName: "money-symbol"
  },
  {
    uuid: "b13751db-c1e0-4a6d-8878-66e25ff86231",
    iconSet: "Fontisto",
    iconName: "parasol"
  },
  {
    uuid: "717381db-aab7-4c60-8c24-b71de41d9714",
    iconSet: "Fontisto",
    iconName: "passport-alt"
  },
  {
    uuid: "3c3b76ac-e257-4316-a53b-67a29beaf55e",
    iconSet: "Fontisto",
    iconName: "passport"
  },
  {
    uuid: "0008cfc5-b9b1-4454-adc2-40803e08b37e",
    iconSet: "Fontisto",
    iconName: "photograph"
  },
  {
    uuid: "788933a3-2ac1-4242-bae6-01626fea52d6",
    iconSet: "Fontisto",
    iconName: "plane-ticket"
  },
  {
    uuid: "956f15b3-dff8-424b-8a21-eb84921b5dcc",
    iconSet: "Fontisto",
    iconName: "room"
  },
  {
    uuid: "da0352b2-c77b-4822-9b56-9823913c50c3",
    iconSet: "Fontisto",
    iconName: "sait-boat"
  },
  {
    uuid: "a169feaa-6606-40a5-8583-3168e533c87c",
    iconSet: "Fontisto",
    iconName: "snorkel"
  },
  {
    uuid: "f84eb70e-67a0-4770-8b31-d3a377802ecc",
    iconSet: "Fontisto",
    iconName: "suitcase-alt"
  },
  {
    uuid: "3028bdf1-fc77-4798-ad52-efc2336a2f82",
    iconSet: "Fontisto",
    iconName: "suitcase"
  },
  {
    uuid: "b20478ce-9e2d-4e69-9e15-54541bc5d193",
    iconSet: "Fontisto",
    iconName: "sun"
  },
  {
    uuid: "26575868-3882-4bd1-8f00-5690e338d926",
    iconSet: "Fontisto",
    iconName: "sunglasses-alt"
  },
  {
    uuid: "0442b6f8-ee51-46e8-90e6-eb0958b678bf",
    iconSet: "Fontisto",
    iconName: "swimsuit"
  },
  {
    uuid: "694ae69d-efe5-4f64-8818-e31c427b77fa",
    iconSet: "Fontisto",
    iconName: "tent"
  },
  {
    uuid: "74e692bc-2a3f-48d8-9d57-54ef77b8e26b",
    iconSet: "Fontisto",
    iconName: "ticket-alt"
  },
  {
    uuid: "2093d06b-4810-426e-9c20-80c89f7c9243",
    iconSet: "Fontisto",
    iconName: "train-ticket"
  },
  {
    uuid: "8399f189-f985-4abb-b246-1ca6fef0b1de",
    iconSet: "Fontisto",
    iconName: "wallet"
  },
  {
    uuid: "baced982-2a61-49ab-94eb-cdb302aff30c",
    iconSet: "Fontisto",
    iconName: "circle-o-notch"
  },
  {
    uuid: "1618bd3f-5fe4-4e8c-a9ab-9d5df5f436ed",
    iconSet: "Fontisto",
    iconName: "propeller-1"
  },
  {
    uuid: "4c7fce7f-3c42-42a7-8d21-a132f6e43840",
    iconSet: "Fontisto",
    iconName: "propeller-2"
  },
  {
    uuid: "86da16f1-ee8d-4f03-8e2b-bbc52adf764c",
    iconSet: "Fontisto",
    iconName: "propeller-3"
  },
  {
    uuid: "b2764f68-a3ac-47ff-9258-7bc60200f7fa",
    iconSet: "Fontisto",
    iconName: "propeller-4"
  },
  {
    uuid: "4995621d-4a4a-4dba-9033-8add796aded4",
    iconSet: "Fontisto",
    iconName: "spinner-cog"
  },
  {
    uuid: "fa00b32f-3d75-4a21-89eb-cd2e0863a888",
    iconSet: "Fontisto",
    iconName: "spinner-fidget"
  },
  {
    uuid: "5ea78fde-1000-4bc8-9420-94ba01f49a34",
    iconSet: "Fontisto",
    iconName: "spinner-refresh"
  },
  {
    uuid: "87528b6e-436e-4b8f-a3e8-d9734ba40e7e",
    iconSet: "Fontisto",
    iconName: "spinner-rotate-forward"
  },
  {
    uuid: "5ab955dd-2ea3-4eed-af90-d7f56a8cd511",
    iconSet: "Fontisto",
    iconName: "spinner"
  },
  {
    uuid: "739f95ea-6d0d-410c-abff-bafab475655a",
    iconSet: "Fontisto",
    iconName: "snowflake"
  },
  {
    uuid: "01b50fe4-6f6b-4844-8106-87e6fcc69607",
    iconSet: "Fontisto",
    iconName: "snowflake-1"
  },
  {
    uuid: "063d634a-f629-4b99-8633-f6f5f6f1ba22",
    iconSet: "Fontisto",
    iconName: "snowflake-2"
  },
  {
    uuid: "906af18b-8401-4e81-80d1-01127b3aa826",
    iconSet: "Fontisto",
    iconName: "snowflake-3"
  },
  {
    uuid: "3cb5d4df-82ec-4603-ab7e-8d21aeb6b250",
    iconSet: "Fontisto",
    iconName: "snowflake-4"
  },
  {
    uuid: "caa6d36f-1bf0-4048-818d-6816b13969ad",
    iconSet: "Fontisto",
    iconName: "snowflake-5"
  },
  {
    uuid: "b898b191-abc3-4ea6-aa49-d62b4db84b82",
    iconSet: "Fontisto",
    iconName: "snowflake-6"
  },
  {
    uuid: "b6f833fc-5beb-456a-852f-26ff51c7d0ab",
    iconSet: "Fontisto",
    iconName: "snowflake-7"
  },
  {
    uuid: "9f874b9f-ff43-43d9-a513-07191963bf2e",
    iconSet: "Fontisto",
    iconName: "snowflake-8"
  },
  {
    uuid: "2b4fe567-9e7d-4dbe-8c0e-55348a35bdcc",
    iconSet: "Fontisto",
    iconName: "curve"
  },
  {
    uuid: "a8cf430c-ab34-4c1e-819e-e24a568b2fe0",
    iconSet: "Fontisto",
    iconName: "ellipse"
  },
  {
    uuid: "52b01931-ff98-463f-9b97-e761c95421ce",
    iconSet: "Fontisto",
    iconName: "rectangle"
  },
  {
    uuid: "ef85af56-41ac-465f-8c6a-ecd6e5a081cd",
    iconSet: "Fontisto",
    iconName: "shield"
  },
  {
    uuid: "dbef7e86-19a4-4f2f-a923-7db2d32c540a",
    iconSet: "Foundation",
    iconName: "address-book"
  },
  {
    uuid: "3c6e7482-13ca-4c71-adef-5580cb30119e",
    iconSet: "Foundation",
    iconName: "alert"
  },
  {
    uuid: "6b2a9ef5-1f51-4c33-ac48-8edc3db961f8",
    iconSet: "Foundation",
    iconName: "align-center"
  },
  {
    uuid: "da4a112e-3de1-42f0-a664-05c29cf4473f",
    iconSet: "Foundation",
    iconName: "align-justify"
  },
  {
    uuid: "3d08e40a-80d8-4a3c-8ba6-e5ca502270bd",
    iconSet: "Foundation",
    iconName: "align-left"
  },
  {
    uuid: "5c14dd12-f986-4874-9980-16bcb3e86ce4",
    iconSet: "Foundation",
    iconName: "align-right"
  },
  {
    uuid: "6407aabe-acff-4aab-9149-57adf153057d",
    iconSet: "Foundation",
    iconName: "anchor"
  },
  {
    uuid: "718d7dc9-4061-42f2-be93-c53b81ce77b1",
    iconSet: "Foundation",
    iconName: "annotate"
  },
  {
    uuid: "79f90870-be81-4856-bda6-0f944edb5d2f",
    iconSet: "Foundation",
    iconName: "archive"
  },
  {
    uuid: "b2715de9-e1a3-4043-b4b4-ba39dcfe1e5d",
    iconSet: "Foundation",
    iconName: "arrow-down"
  },
  {
    uuid: "0e031a37-3fe4-4304-aef3-37c8101a9dd1",
    iconSet: "Foundation",
    iconName: "arrow-left"
  },
  {
    uuid: "22cf80ad-9334-42a3-b00c-db78c77f1988",
    iconSet: "Foundation",
    iconName: "arrow-right"
  },
  {
    uuid: "2a752c46-11dd-4bc1-8214-e0bfa012a644",
    iconSet: "Foundation",
    iconName: "arrow-up"
  },
  {
    uuid: "aa5a19b9-4ef4-4525-ab0d-0b5a689fdbb9",
    iconSet: "Foundation",
    iconName: "arrows-compress"
  },
  {
    uuid: "86ffbd0f-7b01-4f8b-ae6b-91cbd49ab6a4",
    iconSet: "Foundation",
    iconName: "arrows-expand"
  },
  {
    uuid: "6409e9d6-5bef-41fe-b0b6-bfffe713a35d",
    iconSet: "Foundation",
    iconName: "arrows-in"
  },
  {
    uuid: "a968737f-30a7-4c1d-9005-47d3a533c7f4",
    iconSet: "Foundation",
    iconName: "arrows-out"
  },
  {
    uuid: "8d4c38c8-aa24-402a-86e3-ea8fbebf849b",
    iconSet: "Foundation",
    iconName: "asl"
  },
  {
    uuid: "3cb6a3f1-8d94-4ee8-8722-c2753900b20b",
    iconSet: "Foundation",
    iconName: "asterisk"
  },
  {
    uuid: "1550aa7d-624d-476e-b3a4-448b987a32cc",
    iconSet: "Foundation",
    iconName: "at-sign"
  },
  {
    uuid: "1f035ba2-a499-4e89-8534-4f6cacb5f8c5",
    iconSet: "Foundation",
    iconName: "background-color"
  },
  {
    uuid: "a0537508-a5fe-4a16-b65e-f34503d4238d",
    iconSet: "Foundation",
    iconName: "battery-empty"
  },
  {
    uuid: "e2404066-8256-4e23-98ec-aede2e9a909d",
    iconSet: "Foundation",
    iconName: "battery-full"
  },
  {
    uuid: "80ca4f9b-2b21-4040-9240-1ac6bc6461b7",
    iconSet: "Foundation",
    iconName: "battery-half"
  },
  {
    uuid: "88c57985-c9ae-4811-a1bd-bff4442aca6b",
    iconSet: "Foundation",
    iconName: "bitcoin-circle"
  },
  {
    uuid: "afeda640-ae54-4c6a-867e-ad7e73fcd4f6",
    iconSet: "Foundation",
    iconName: "bitcoin"
  },
  {
    uuid: "2364e55e-bbf6-443c-9523-1697d9bbee4a",
    iconSet: "Foundation",
    iconName: "blind"
  },
  {
    uuid: "8cdbcf8d-536e-44db-af8c-1b67af2e06a6",
    iconSet: "Foundation",
    iconName: "bluetooth"
  },
  {
    uuid: "d48bc089-2428-4377-80d1-80877124a07b",
    iconSet: "Foundation",
    iconName: "bold"
  },
  {
    uuid: "5c461cfd-1ed1-4d86-8839-75a75f49d278",
    iconSet: "Foundation",
    iconName: "book-bookmark"
  },
  {
    uuid: "009ef045-a09c-404d-80a2-2b18ba860574",
    iconSet: "Foundation",
    iconName: "book"
  },
  {
    uuid: "0e3c6529-93b4-4241-8dab-4756954fb405",
    iconSet: "Foundation",
    iconName: "bookmark"
  },
  {
    uuid: "ec699091-5950-4348-afba-b644cc53debb",
    iconSet: "Foundation",
    iconName: "braille"
  },
  {
    uuid: "51cff31a-e692-4d67-9113-8ed3ba826cbb",
    iconSet: "Foundation",
    iconName: "burst-new"
  },
  {
    uuid: "9b369e6f-31d9-49ca-b84d-06688ea1e88b",
    iconSet: "Foundation",
    iconName: "burst-sale"
  },
  {
    uuid: "018e4df8-297c-4818-9b65-9228af161379",
    iconSet: "Foundation",
    iconName: "burst"
  },
  {
    uuid: "64c212ea-bbc9-4f07-bab7-ae27b49da400",
    iconSet: "Foundation",
    iconName: "calendar"
  },
  {
    uuid: "39787e23-0ec4-42c7-ae0b-1f5c8ca22120",
    iconSet: "Foundation",
    iconName: "camera"
  },
  {
    uuid: "74bb9128-e4e4-4642-adff-97c54b7a6673",
    iconSet: "Foundation",
    iconName: "check"
  },
  {
    uuid: "7092898c-a4e5-4e27-b3e1-93909c0100ce",
    iconSet: "Foundation",
    iconName: "checkbox"
  },
  {
    uuid: "112ceeb9-b026-47fb-98b2-ec433026fe58",
    iconSet: "Foundation",
    iconName: "clipboard-notes"
  },
  {
    uuid: "7b353848-057e-4bf9-a759-2112b2b1301e",
    iconSet: "Foundation",
    iconName: "clipboard-pencil"
  },
  {
    uuid: "e608310b-2dd7-4830-a8ee-784214ca6582",
    iconSet: "Foundation",
    iconName: "clipboard"
  },
  {
    uuid: "bc9a29a8-af5b-4d71-b400-f69a3de7fc8e",
    iconSet: "Foundation",
    iconName: "clock"
  },
  {
    uuid: "c1cb1a52-9e4a-4e39-9bdc-9fd0300ff66b",
    iconSet: "Foundation",
    iconName: "closed-caption"
  },
  {
    uuid: "05a4a56d-2293-4604-bcf7-577ee22044fb",
    iconSet: "Foundation",
    iconName: "cloud"
  },
  {
    uuid: "52cae9db-3672-4e6d-ae42-7b5bc1c3a779",
    iconSet: "Foundation",
    iconName: "comment-minus"
  },
  {
    uuid: "416538d4-1866-45f9-ae80-618771a5e66e",
    iconSet: "Foundation",
    iconName: "comment-quotes"
  },
  {
    uuid: "e0f03d0b-3657-40bb-bc65-f3f3bafdeb0a",
    iconSet: "Foundation",
    iconName: "comment-video"
  },
  {
    uuid: "2657d60e-4f01-4f47-a36e-215bd49e7f62",
    iconSet: "Foundation",
    iconName: "comment"
  },
  {
    uuid: "67d472de-b51e-4a0c-bf78-a2c64c78f8c1",
    iconSet: "Foundation",
    iconName: "comments"
  },
  {
    uuid: "013c3aa7-2b49-430a-9421-495ae7f79069",
    iconSet: "Foundation",
    iconName: "compass"
  },
  {
    uuid: "a490e584-5790-4918-a702-3f834c6b5029",
    iconSet: "Foundation",
    iconName: "contrast"
  },
  {
    uuid: "08f06b25-3ce5-4bfe-add6-0d61f53b2dde",
    iconSet: "Foundation",
    iconName: "credit-card"
  },
  {
    uuid: "458df48f-e307-4970-b024-d2b391d3ddc4",
    iconSet: "Foundation",
    iconName: "crop"
  },
  {
    uuid: "eb427c37-db00-4b1d-b49a-3ff8f898b443",
    iconSet: "Foundation",
    iconName: "crown"
  },
  {
    uuid: "53732005-5cbe-459a-9e78-d1a158a2bf1a",
    iconSet: "Foundation",
    iconName: "css3"
  },
  {
    uuid: "ccf6a0ac-afd0-4987-b701-9bf012217977",
    iconSet: "Foundation",
    iconName: "database"
  },
  {
    uuid: "f555a426-9241-4b9c-beec-c8f2de8ee82b",
    iconSet: "Foundation",
    iconName: "die-five"
  },
  {
    uuid: "dfe4dd92-d098-4f13-a32b-1576be23f112",
    iconSet: "Foundation",
    iconName: "die-four"
  },
  {
    uuid: "7f0d4740-b3c1-40c6-b01c-5c765c1ae2bc",
    iconSet: "Foundation",
    iconName: "die-one"
  },
  {
    uuid: "14a3c89b-f205-4592-8248-65bdbf46e41b",
    iconSet: "Foundation",
    iconName: "die-six"
  },
  {
    uuid: "aa54f409-74e6-4389-816c-e7b60a950b3e",
    iconSet: "Foundation",
    iconName: "die-three"
  },
  {
    uuid: "f1a2ca9e-b562-47b2-bbd1-4df5e28e7456",
    iconSet: "Foundation",
    iconName: "die-two"
  },
  {
    uuid: "494c9a78-384e-4d1b-b054-233c3b3b5cea",
    iconSet: "Foundation",
    iconName: "dislike"
  },
  {
    uuid: "bf3aa5ba-8d45-4371-8ac3-cffb992eeb20",
    iconSet: "Foundation",
    iconName: "dollar-bill"
  },
  {
    uuid: "6fd17110-4a75-43bb-a500-1d7a6c20b76e",
    iconSet: "Foundation",
    iconName: "dollar"
  },
  {
    uuid: "16aed56a-e2db-4135-a0b7-889215a9c5ec",
    iconSet: "Foundation",
    iconName: "download"
  },
  {
    uuid: "3cc37790-b048-4e6f-806f-3886ec4aa106",
    iconSet: "Foundation",
    iconName: "eject"
  },
  {
    uuid: "a024b64b-f1cb-4796-b303-1f9bdca3a493",
    iconSet: "Foundation",
    iconName: "elevator"
  },
  {
    uuid: "cbfa436d-8d53-4fee-9418-8e494fbe7a0e",
    iconSet: "Foundation",
    iconName: "euro"
  },
  {
    uuid: "2d3f8bbf-7755-40fe-a112-48867b012869",
    iconSet: "Foundation",
    iconName: "eye"
  },
  {
    uuid: "d30aebf6-6d41-4496-948d-d1349964d92e",
    iconSet: "Foundation",
    iconName: "fast-forward"
  },
  {
    uuid: "61b6cb4e-2444-490b-bafb-d11447452cab",
    iconSet: "Foundation",
    iconName: "female-symbol"
  },
  {
    uuid: "cba0243e-0ca2-4b95-83bb-24f0f29cc173",
    iconSet: "Foundation",
    iconName: "female"
  },
  {
    uuid: "01ba956e-b724-46a8-aaf4-0cd32e19ecbc",
    iconSet: "Foundation",
    iconName: "filter"
  },
  {
    uuid: "e657473a-e6fb-43c2-86d5-2754b26a1073",
    iconSet: "Foundation",
    iconName: "first-aid"
  },
  {
    uuid: "0cfc0e99-081f-41fb-bec8-45f25bd734cb",
    iconSet: "Foundation",
    iconName: "flag"
  },
  {
    uuid: "38721930-3a9e-4018-b4d6-2c69dafd5293",
    iconSet: "Foundation",
    iconName: "folder-add"
  },
  {
    uuid: "ebc1b2ca-9ef9-4c9f-9a4e-a631fb569a8d",
    iconSet: "Foundation",
    iconName: "folder-lock"
  },
  {
    uuid: "43961d51-762f-4c94-997d-700a3dba2920",
    iconSet: "Foundation",
    iconName: "folder"
  },
  {
    uuid: "51ddb41a-3a62-4fdb-b672-a804472a9c78",
    iconSet: "Foundation",
    iconName: "foot"
  },
  {
    uuid: "7d88f909-e875-4a69-9c34-745cce2f030f",
    iconSet: "Foundation",
    iconName: "foundation"
  },
  {
    uuid: "ff52bd0f-0d5a-47c7-b085-760e7824fcd3",
    iconSet: "Foundation",
    iconName: "graph-bar"
  },
  {
    uuid: "04bbe10a-c68d-414b-8b6a-eaf2eb93a7f5",
    iconSet: "Foundation",
    iconName: "graph-horizontal"
  },
  {
    uuid: "54b99f9e-1487-4d5a-a0be-eb5b4cac8a05",
    iconSet: "Foundation",
    iconName: "graph-pie"
  },
  {
    uuid: "f07c2b8a-d3d9-48d2-8a3f-c9a779f84a3f",
    iconSet: "Foundation",
    iconName: "graph-trend"
  },
  {
    uuid: "b01f2ed9-5021-4bbd-a237-850b4bfef81a",
    iconSet: "Foundation",
    iconName: "guide-dog"
  },
  {
    uuid: "eadd4a31-f5b4-4233-ae43-11960e1db4fc",
    iconSet: "Foundation",
    iconName: "hearing-aid"
  },
  {
    uuid: "1905e3a2-4ecd-4b65-8057-802987025044",
    iconSet: "Foundation",
    iconName: "heart"
  },
  {
    uuid: "83a973df-3708-4e3c-8daa-9a1c27c25107",
    iconSet: "Foundation",
    iconName: "home"
  },
  {
    uuid: "50523a5d-acc0-4f34-890c-1a47434f06c9",
    iconSet: "Foundation",
    iconName: "html5"
  },
  {
    uuid: "ca60f483-7e0f-437d-af75-d69e5b1ebaea",
    iconSet: "Foundation",
    iconName: "indent-less"
  },
  {
    uuid: "8ba5fcc6-9c83-44cb-9554-27bad1fc973a",
    iconSet: "Foundation",
    iconName: "indent-more"
  },
  {
    uuid: "9a981571-6f37-4ae1-aff4-d60b0dd3e123",
    iconSet: "Foundation",
    iconName: "info"
  },
  {
    uuid: "aec72257-8be3-41e5-bfcf-eff4d3e2af66",
    iconSet: "Foundation",
    iconName: "italic"
  },
  {
    uuid: "b2c160da-0e3b-4db3-9ad7-eebd21f2cb84",
    iconSet: "Foundation",
    iconName: "key"
  },
  {
    uuid: "b50257bb-803f-4523-93f9-e12135882119",
    iconSet: "Foundation",
    iconName: "laptop"
  },
  {
    uuid: "1e764364-9ac2-442b-bd0c-649212f8198b",
    iconSet: "Foundation",
    iconName: "layout"
  },
  {
    uuid: "415d51a4-df28-4f7b-948e-eb154d4b4def",
    iconSet: "Foundation",
    iconName: "lightbulb"
  },
  {
    uuid: "835298b2-452d-4e17-8f49-36c19c0000e7",
    iconSet: "Foundation",
    iconName: "like"
  },
  {
    uuid: "4927760c-04f9-49b9-9461-eb085713ebf9",
    iconSet: "Foundation",
    iconName: "link"
  },
  {
    uuid: "29824779-d3d6-41d2-8444-2afad07e546e",
    iconSet: "Foundation",
    iconName: "list-bullet"
  },
  {
    uuid: "159c3cd8-7fef-4661-8df3-9a83263143e0",
    iconSet: "Foundation",
    iconName: "list-number"
  },
  {
    uuid: "ff8d3970-64f5-4d66-8469-07d5a52c8152",
    iconSet: "Foundation",
    iconName: "list-thumbnails"
  },
  {
    uuid: "e52b57e5-8229-4023-a739-172dc86a4aaa",
    iconSet: "Foundation",
    iconName: "list"
  },
  {
    uuid: "70edd5fb-0f67-4bc1-9932-4eb632bb7a40",
    iconSet: "Foundation",
    iconName: "lock"
  },
  {
    uuid: "7d189f26-b78b-4bfc-8c0a-0b1b409b27b8",
    iconSet: "Foundation",
    iconName: "loop"
  },
  {
    uuid: "df5833a1-5914-4709-9843-96fbf9d90fbd",
    iconSet: "Foundation",
    iconName: "magnifying-glass"
  },
  {
    uuid: "46285131-b9b0-42a3-9e4e-3a8e9c8018c8",
    iconSet: "Foundation",
    iconName: "mail"
  },
  {
    uuid: "8dbefe6a-1b6e-4a36-906f-ddb9a3933b87",
    iconSet: "Foundation",
    iconName: "male-female"
  },
  {
    uuid: "578155ee-63a9-4be5-bbf1-52908b03d902",
    iconSet: "Foundation",
    iconName: "male-symbol"
  },
  {
    uuid: "a441f432-9e8a-4f1b-9a79-3c1304a977ec",
    iconSet: "Foundation",
    iconName: "male"
  },
  {
    uuid: "73765754-cf8c-4a69-86f6-c0246560a56c",
    iconSet: "Foundation",
    iconName: "map"
  },
  {
    uuid: "85fcdbdd-c5b0-497b-854f-c97d510affdf",
    iconSet: "Foundation",
    iconName: "marker"
  },
  {
    uuid: "ff7f8984-1949-4af8-8711-3748bcabb43b",
    iconSet: "Foundation",
    iconName: "megaphone"
  },
  {
    uuid: "f1bcf69e-0855-4874-ad6b-255e44b24ffc",
    iconSet: "Foundation",
    iconName: "microphone"
  },
  {
    uuid: "28cc435e-296c-480a-abf5-304a29201611",
    iconSet: "Foundation",
    iconName: "minus-circle"
  },
  {
    uuid: "84e89272-6874-4227-9d58-73a05e819f5e",
    iconSet: "Foundation",
    iconName: "minus"
  },
  {
    uuid: "d4e99b07-4634-4f16-8bbe-43a65bf1d5aa",
    iconSet: "Foundation",
    iconName: "mobile-signal"
  },
  {
    uuid: "b166de50-1afa-48a0-85ac-8bf99ba48317",
    iconSet: "Foundation",
    iconName: "mobile"
  },
  {
    uuid: "3606f408-3474-4d1b-b40a-8dd5e8c01341",
    iconSet: "Foundation",
    iconName: "monitor"
  },
  {
    uuid: "561699a4-ef12-49e9-9de8-9e2ca9fdf72f",
    iconSet: "Foundation",
    iconName: "mountains"
  },
  {
    uuid: "5095d98e-379b-4b20-a8a2-9d23bb45bc3e",
    iconSet: "Foundation",
    iconName: "music"
  },
  {
    uuid: "eb815a44-5709-423d-bc68-928f6abf0d9a",
    iconSet: "Foundation",
    iconName: "next"
  },
  {
    uuid: "d9838251-d547-4e00-8e21-feface2d0b60",
    iconSet: "Foundation",
    iconName: "no-dogs"
  },
  {
    uuid: "6740bf06-e4e3-4a71-a924-837e05211cfb",
    iconSet: "Foundation",
    iconName: "no-smoking"
  },
  {
    uuid: "93faf175-c3b6-44b5-b68c-42a185c8a1c4",
    iconSet: "Foundation",
    iconName: "page-add"
  },
  {
    uuid: "5469c97d-b56a-4e05-842c-5c476d4afa5e",
    iconSet: "Foundation",
    iconName: "page-copy"
  },
  {
    uuid: "9f69baaf-8066-4589-b798-cfc1ce1132b3",
    iconSet: "Foundation",
    iconName: "page-csv"
  },
  {
    uuid: "8f9adbea-83e1-45c8-8e2f-0535c765d980",
    iconSet: "Foundation",
    iconName: "page-delete"
  },
  {
    uuid: "4d03a1b8-a083-462a-b4eb-7164820230e6",
    iconSet: "Foundation",
    iconName: "page-doc"
  },
  {
    uuid: "28cfef40-3ccf-4483-be61-15a48ba77d2a",
    iconSet: "Foundation",
    iconName: "page-edit"
  },
  {
    uuid: "1b452683-e459-4b6c-85dc-81cfd5c5a7df",
    iconSet: "Foundation",
    iconName: "page-export-csv"
  },
  {
    uuid: "5064a56c-a2c2-40fb-b9b4-aec48665894d",
    iconSet: "Foundation",
    iconName: "page-export-doc"
  },
  {
    uuid: "9dbae417-0b31-43ee-9d03-43c002b9d0bc",
    iconSet: "Foundation",
    iconName: "page-export-pdf"
  },
  {
    uuid: "9ab8f49a-6b21-4755-ae11-afeb370fbcfd",
    iconSet: "Foundation",
    iconName: "page-export"
  },
  {
    uuid: "aa0701e3-503b-4c54-90a5-629f5c3c13e2",
    iconSet: "Foundation",
    iconName: "page-filled"
  },
  {
    uuid: "0a257487-4733-4fe9-b055-9137aca44675",
    iconSet: "Foundation",
    iconName: "page-multiple"
  },
  {
    uuid: "ae1c6491-f5a8-4f04-bc7a-2470d692da8f",
    iconSet: "Foundation",
    iconName: "page-pdf"
  },
  {
    uuid: "1e797ad8-5b05-4c6d-b1d0-7adca85eb4c9",
    iconSet: "Foundation",
    iconName: "page-remove"
  },
  {
    uuid: "e6827dc9-e91d-403e-8a0c-b3161b2319d9",
    iconSet: "Foundation",
    iconName: "page-search"
  },
  {
    uuid: "716429d6-4bc2-4641-9dc2-d5f1140e188e",
    iconSet: "Foundation",
    iconName: "page"
  },
  {
    uuid: "d9b2163e-c7f2-4672-b993-9aed2cb35acc",
    iconSet: "Foundation",
    iconName: "paint-bucket"
  },
  {
    uuid: "3b75247e-44df-4cf8-95ad-c1df58ab61d3",
    iconSet: "Foundation",
    iconName: "paperclip"
  },
  {
    uuid: "353d8547-2b11-444d-8837-a6566aa6f028",
    iconSet: "Foundation",
    iconName: "pause"
  },
  {
    uuid: "c5074878-51e0-4439-acfb-e4b4e04ea8b2",
    iconSet: "Foundation",
    iconName: "paw"
  },
  {
    uuid: "9c756e50-feae-4277-8eba-292b0b527216",
    iconSet: "Foundation",
    iconName: "paypal"
  },
  {
    uuid: "bab97d7b-d6d7-4ba4-b0d6-2cd28d437980",
    iconSet: "Foundation",
    iconName: "pencil"
  },
  {
    uuid: "85d4c0e1-1655-4131-83bb-7cb5946a59e2",
    iconSet: "Foundation",
    iconName: "photo"
  },
  {
    uuid: "d9e5d18c-da65-4a00-b93d-3bbfd5b0d709",
    iconSet: "Foundation",
    iconName: "play-circle"
  },
  {
    uuid: "5aaeb8bd-f67f-4b54-a73c-9ebbc0756789",
    iconSet: "Foundation",
    iconName: "play-video"
  },
  {
    uuid: "20ed9bae-c6ec-4e36-a2ce-849eac69a191",
    iconSet: "Foundation",
    iconName: "play"
  },
  {
    uuid: "9598239c-a065-4b24-b98f-52e978b3ac3c",
    iconSet: "Foundation",
    iconName: "plus"
  },
  {
    uuid: "96ea0752-baaf-44f3-88a1-407e0ee641d8",
    iconSet: "Foundation",
    iconName: "pound"
  },
  {
    uuid: "cb767779-4aa0-4cc4-ace3-e1025ea85cc1",
    iconSet: "Foundation",
    iconName: "power"
  },
  {
    uuid: "4c8003d0-90f8-45e1-8197-1e725b8164df",
    iconSet: "Foundation",
    iconName: "previous"
  },
  {
    uuid: "cd748fd9-ece4-40ab-b3a4-d1a5395cbd36",
    iconSet: "Foundation",
    iconName: "price-tag"
  },
  {
    uuid: "e528c8d7-05d9-4c05-afcf-668cc80958f7",
    iconSet: "Foundation",
    iconName: "pricetag-multiple"
  },
  {
    uuid: "b84a1ab3-6ecf-48f7-b049-032c3143b20b",
    iconSet: "Foundation",
    iconName: "print"
  },
  {
    uuid: "42993d60-c2fd-4d61-983d-0beb9f3f8801",
    iconSet: "Foundation",
    iconName: "prohibited"
  },
  {
    uuid: "65d93ca2-cd29-4d21-a041-3416f2446a54",
    iconSet: "Foundation",
    iconName: "projection-screen"
  },
  {
    uuid: "a6d9e1a3-0008-4bc6-afa4-87caed93ca0f",
    iconSet: "Foundation",
    iconName: "puzzle"
  },
  {
    uuid: "9430ed13-7c71-4903-b19e-ca8f0b1bf223",
    iconSet: "Foundation",
    iconName: "quote"
  },
  {
    uuid: "fe859712-3915-4930-b2bd-390cb27c502f",
    iconSet: "Foundation",
    iconName: "record"
  },
  {
    uuid: "5bdf5e2e-19bd-4d16-8200-6fe8e704506a",
    iconSet: "Foundation",
    iconName: "refresh"
  },
  {
    uuid: "196f71af-46a6-4bdf-a07f-3815163df42e",
    iconSet: "Foundation",
    iconName: "results-demographics"
  },
  {
    uuid: "6806b78f-1f61-4fc5-bc5f-c980fdb6a8d4",
    iconSet: "Foundation",
    iconName: "results"
  },
  {
    uuid: "7f754f82-a1b0-4322-8256-d2c1fa291ee8",
    iconSet: "Foundation",
    iconName: "rewind-ten"
  },
  {
    uuid: "17fde91e-73a2-483b-9267-bd446b00c9ee",
    iconSet: "Foundation",
    iconName: "rewind"
  },
  {
    uuid: "89dd311f-7651-46f8-9160-f88f051075ad",
    iconSet: "Foundation",
    iconName: "rss"
  },
  {
    uuid: "453128e0-7e1d-453a-992b-eeda2add4975",
    iconSet: "Foundation",
    iconName: "safety-cone"
  },
  {
    uuid: "cefc6941-980b-476a-ba53-2c856ce28fd2",
    iconSet: "Foundation",
    iconName: "save"
  },
  {
    uuid: "048b29f2-f68b-4215-bd0c-9821ad06ff15",
    iconSet: "Foundation",
    iconName: "share"
  },
  {
    uuid: "07defc7a-6259-417a-9794-9ea3469c37b9",
    iconSet: "Foundation",
    iconName: "sheriff-badge"
  },
  {
    uuid: "0db494bf-00fb-40e6-8b65-e331eeaaaf21",
    iconSet: "Foundation",
    iconName: "shield"
  },
  {
    uuid: "5303c75f-5bde-450f-ada1-42a76b150c82",
    iconSet: "Foundation",
    iconName: "shopping-bag"
  },
  {
    uuid: "fb6a7f41-4051-4ce8-8040-b595ada8f710",
    iconSet: "Foundation",
    iconName: "shopping-cart"
  },
  {
    uuid: "3d7b8400-32f0-47ea-9187-45ad916c55c7",
    iconSet: "Foundation",
    iconName: "shuffle"
  },
  {
    uuid: "6493b7e8-e247-48c6-b74f-7c43e4804060",
    iconSet: "Foundation",
    iconName: "skull"
  },
  {
    uuid: "c9a7179e-1c1b-4a92-8975-3247ec2b866b",
    iconSet: "Foundation",
    iconName: "social-500px"
  },
  {
    uuid: "f25430b6-1b8b-4d77-ac43-2e876d8e5255",
    iconSet: "Foundation",
    iconName: "social-adobe"
  },
  {
    uuid: "6cd9d3a9-62e0-4f1c-b2ad-27df9ab52b4b",
    iconSet: "Foundation",
    iconName: "social-amazon"
  },
  {
    uuid: "13ce9936-014b-483a-9943-8d7fd0321dc1",
    iconSet: "Foundation",
    iconName: "social-android"
  },
  {
    uuid: "ef22ce15-1322-4f28-8cfa-997a5de32e84",
    iconSet: "Foundation",
    iconName: "social-apple"
  },
  {
    uuid: "7fa366a7-ba2f-4422-8fa9-5cc09f73abe6",
    iconSet: "Foundation",
    iconName: "social-behance"
  },
  {
    uuid: "e7ff0c6b-fb03-4e52-ad32-9bb138c6a92a",
    iconSet: "Foundation",
    iconName: "social-bing"
  },
  {
    uuid: "f98089b2-82f7-42a0-85e6-1ba5eeed3fe0",
    iconSet: "Foundation",
    iconName: "social-blogger"
  },
  {
    uuid: "5f452e94-1ab9-4bb8-9476-c2d5c456cec2",
    iconSet: "Foundation",
    iconName: "social-delicious"
  },
  {
    uuid: "d1cdc078-46bf-4560-9905-68fcb2d389c7",
    iconSet: "Foundation",
    iconName: "social-designer-news"
  },
  {
    uuid: "1db2587a-353d-4131-8f73-6e6c90094ea6",
    iconSet: "Foundation",
    iconName: "social-deviant-art"
  },
  {
    uuid: "5cb738db-ff8d-4fcf-8686-6a822f80396c",
    iconSet: "Foundation",
    iconName: "social-digg"
  },
  {
    uuid: "1dbe8e4f-48ee-4160-ab46-29543552e4c6",
    iconSet: "Foundation",
    iconName: "social-dribbble"
  },
  {
    uuid: "6eb97a36-d125-4ea8-bc47-d438ada414b5",
    iconSet: "Foundation",
    iconName: "social-drive"
  },
  {
    uuid: "087f0a26-2357-47a6-aee5-2ae27b0b1233",
    iconSet: "Foundation",
    iconName: "social-dropbox"
  },
  {
    uuid: "a221603d-f928-4bf4-b4d1-e0ef514df9bf",
    iconSet: "Foundation",
    iconName: "social-evernote"
  },
  {
    uuid: "89ab2626-0df5-4d40-99aa-3ac9db7a2585",
    iconSet: "Foundation",
    iconName: "social-facebook"
  },
  {
    uuid: "0a4887dd-bb51-40ce-b7da-acb1b814d0aa",
    iconSet: "Foundation",
    iconName: "social-flickr"
  },
  {
    uuid: "c7375c2b-917a-4b67-baef-7c70c3cdd5d1",
    iconSet: "Foundation",
    iconName: "social-forrst"
  },
  {
    uuid: "38d48872-f0b6-49a1-82f5-58ff7252137f",
    iconSet: "Foundation",
    iconName: "social-foursquare"
  },
  {
    uuid: "846bd34f-2f38-419c-9582-521041901804",
    iconSet: "Foundation",
    iconName: "social-game-center"
  },
  {
    uuid: "1685bf93-ee97-4bb6-93bc-94ccc97ba26f",
    iconSet: "Foundation",
    iconName: "social-github"
  },
  {
    uuid: "aa9a67e2-134e-4903-8ec8-cbba654ec87f",
    iconSet: "Foundation",
    iconName: "social-google-plus"
  },
  {
    uuid: "7bc07915-ca2f-4198-ba84-59a0cffa4e00",
    iconSet: "Foundation",
    iconName: "social-hacker-news"
  },
  {
    uuid: "01ccf6c3-3e6d-4ed8-ab50-19cb663d4063",
    iconSet: "Foundation",
    iconName: "social-hi5"
  },
  {
    uuid: "4e47f308-3936-4d56-8953-fed709621d38",
    iconSet: "Foundation",
    iconName: "social-instagram"
  },
  {
    uuid: "057f7f0a-603e-49fd-85e1-78bfe17cae87",
    iconSet: "Foundation",
    iconName: "social-joomla"
  },
  {
    uuid: "2821ca7d-3fd0-4cdc-a590-fc31b05dea34",
    iconSet: "Foundation",
    iconName: "social-lastfm"
  },
  {
    uuid: "6de40a7d-ff28-4661-b96a-7b6c737a2248",
    iconSet: "Foundation",
    iconName: "social-linkedin"
  },
  {
    uuid: "84ff3b33-621c-4afa-9759-ab3d079bc945",
    iconSet: "Foundation",
    iconName: "social-medium"
  },
  {
    uuid: "443b03b0-9f0e-496b-938f-4370e207618f",
    iconSet: "Foundation",
    iconName: "social-myspace"
  },
  {
    uuid: "6e74c055-69a1-4a3b-af9e-339a3988ecce",
    iconSet: "Foundation",
    iconName: "social-orkut"
  },
  {
    uuid: "e6371185-c857-4cf9-a64a-e8e876ac1cb0",
    iconSet: "Foundation",
    iconName: "social-path"
  },
  {
    uuid: "19662120-73c5-4405-8ea4-42fc4225620a",
    iconSet: "Foundation",
    iconName: "social-picasa"
  },
  {
    uuid: "46626df5-d860-4f48-b78c-33b503cbd0a1",
    iconSet: "Foundation",
    iconName: "social-pinterest"
  },
  {
    uuid: "aac82ed6-bbb7-4d73-b53d-e61de8b818ab",
    iconSet: "Foundation",
    iconName: "social-rdio"
  },
  {
    uuid: "8407be92-0e75-4571-b811-51c1d9589a2e",
    iconSet: "Foundation",
    iconName: "social-reddit"
  },
  {
    uuid: "6fe82608-fb6c-4c65-9123-34504af25530",
    iconSet: "Foundation",
    iconName: "social-skillshare"
  },
  {
    uuid: "acaa26c8-4dd1-4b04-8ab1-12e523ce0309",
    iconSet: "Foundation",
    iconName: "social-skype"
  },
  {
    uuid: "9a66e481-800f-4d1b-bb93-e5635da27ccc",
    iconSet: "Foundation",
    iconName: "social-smashing-mag"
  },
  {
    uuid: "effd267d-10c4-42c1-b81c-663b530b4713",
    iconSet: "Foundation",
    iconName: "social-snapchat"
  },
  {
    uuid: "31335822-09e1-4678-b227-563861e5ac8a",
    iconSet: "Foundation",
    iconName: "social-spotify"
  },
  {
    uuid: "a1514b19-d8b6-4e49-8edc-7838f93be361",
    iconSet: "Foundation",
    iconName: "social-squidoo"
  },
  {
    uuid: "148d3f5a-fb17-454c-8134-8d2cd7258a14",
    iconSet: "Foundation",
    iconName: "social-stack-overflow"
  },
  {
    uuid: "ad055b71-2dd2-4a02-a13d-11906d70610f",
    iconSet: "Foundation",
    iconName: "social-steam"
  },
  {
    uuid: "98712802-8f6a-4ba5-9f6a-e17d1c6c80af",
    iconSet: "Foundation",
    iconName: "social-stumbleupon"
  },
  {
    uuid: "06f6f17d-0635-4df8-8f98-cda71d973894",
    iconSet: "Foundation",
    iconName: "social-treehouse"
  },
  {
    uuid: "27d5574f-e474-470b-9a21-cdac251f3eab",
    iconSet: "Foundation",
    iconName: "social-tumblr"
  },
  {
    uuid: "236764ce-bf3c-4a61-b4fe-6db2dca7f0f0",
    iconSet: "Foundation",
    iconName: "social-twitter"
  },
  {
    uuid: "8a7bdf28-0ee2-4520-89e9-f9d407da9efa",
    iconSet: "Foundation",
    iconName: "social-vimeo"
  },
  {
    uuid: "6a036038-fad6-4d30-afea-99c8dc5a4261",
    iconSet: "Foundation",
    iconName: "social-windows"
  },
  {
    uuid: "44babe47-d747-4aff-9a6e-8407322c034f",
    iconSet: "Foundation",
    iconName: "social-xbox"
  },
  {
    uuid: "848c6ef3-895c-4ba7-a126-824e0f72f216",
    iconSet: "Foundation",
    iconName: "social-yahoo"
  },
  {
    uuid: "2de85376-b48d-474d-9a5a-53f739eb7249",
    iconSet: "Foundation",
    iconName: "social-yelp"
  },
  {
    uuid: "cd22add6-f4cf-4f39-9670-38debf0bced6",
    iconSet: "Foundation",
    iconName: "social-youtube"
  },
  {
    uuid: "6b68d0a9-25ae-4dc6-b925-2a389032dd1a",
    iconSet: "Foundation",
    iconName: "social-zerply"
  },
  {
    uuid: "8ae3e5ae-2b20-420b-965d-26bd3d83a7eb",
    iconSet: "Foundation",
    iconName: "social-zurb"
  },
  {
    uuid: "7451a84f-4928-4250-a7ad-8dadff477dca",
    iconSet: "Foundation",
    iconName: "sound"
  },
  {
    uuid: "961a5c5f-39b3-474a-9ff8-97ccec9b65ae",
    iconSet: "Foundation",
    iconName: "star"
  },
  {
    uuid: "60d9b888-0a0c-4085-8b35-b3121b481f8e",
    iconSet: "Foundation",
    iconName: "stop"
  },
  {
    uuid: "e93268a3-d0f3-4324-a232-9b8c7c129201",
    iconSet: "Foundation",
    iconName: "strikethrough"
  },
  {
    uuid: "623079f2-e527-46b3-82f3-396e97efb5f6",
    iconSet: "Foundation",
    iconName: "subscript"
  },
  {
    uuid: "dafc746e-32ef-41aa-95f8-3b36f5e28ccd",
    iconSet: "Foundation",
    iconName: "superscript"
  },
  {
    uuid: "f6cd83c3-b984-4851-98b0-6f06adaa3ec6",
    iconSet: "Foundation",
    iconName: "tablet-landscape"
  },
  {
    uuid: "63e87150-128a-4ff7-99ae-983a94aeb79d",
    iconSet: "Foundation",
    iconName: "tablet-portrait"
  },
  {
    uuid: "050ad616-2d48-44ba-8425-9b35c0048aa4",
    iconSet: "Foundation",
    iconName: "target-two"
  },
  {
    uuid: "20692bbe-4e1d-45ca-974a-f76d8055405c",
    iconSet: "Foundation",
    iconName: "target"
  },
  {
    uuid: "5ee666c7-042a-46d5-988e-609bc206db11",
    iconSet: "Foundation",
    iconName: "telephone-accessible"
  },
  {
    uuid: "7c14e12d-fb06-45c3-9a80-decca9d52702",
    iconSet: "Foundation",
    iconName: "telephone"
  },
  {
    uuid: "39a27862-4278-4f7c-9901-65e5da2ac027",
    iconSet: "Foundation",
    iconName: "text-color"
  },
  {
    uuid: "a8170a46-5da5-4e82-84bb-16e3ce533a31",
    iconSet: "Foundation",
    iconName: "thumbnails"
  },
  {
    uuid: "f724266f-3267-4913-b699-211de4e34a64",
    iconSet: "Foundation",
    iconName: "ticket"
  },
  {
    uuid: "256a4a78-906b-497b-b481-837f588731bb",
    iconSet: "Foundation",
    iconName: "torso-business"
  },
  {
    uuid: "68bfd6bf-d9df-424a-b4aa-2ddaa9c2c8ef",
    iconSet: "Foundation",
    iconName: "torso-female"
  },
  {
    uuid: "4c00e5dd-fd28-4509-8351-7b1fddb4353e",
    iconSet: "Foundation",
    iconName: "torso"
  },
  {
    uuid: "a60a8356-fea1-4e80-aa5a-d795c1ff87e2",
    iconSet: "Foundation",
    iconName: "torsos-all-female"
  },
  {
    uuid: "0da3182e-0d17-4cae-83a0-57e267f6d6b4",
    iconSet: "Foundation",
    iconName: "torsos-all"
  },
  {
    uuid: "73958020-cae1-4c18-b008-d22e0091d4a8",
    iconSet: "Foundation",
    iconName: "torsos-female-male"
  },
  {
    uuid: "812e3f04-7dd5-400b-a6c1-c9d88441d734",
    iconSet: "Foundation",
    iconName: "torsos-male-female"
  },
  {
    uuid: "f4ad6263-acd8-4593-a728-0f734c38aa57",
    iconSet: "Foundation",
    iconName: "torsos"
  },
  {
    uuid: "a046df50-6072-477e-814e-a6c6ffb586b5",
    iconSet: "Foundation",
    iconName: "trash"
  },
  {
    uuid: "a442a5b4-2651-42d0-b87d-01f1574a7446",
    iconSet: "Foundation",
    iconName: "trees"
  },
  {
    uuid: "ddf6127b-c02a-469d-9fea-7b4627d339fb",
    iconSet: "Foundation",
    iconName: "trophy"
  },
  {
    uuid: "8dce4e50-f905-4466-8232-69444fb3c6e6",
    iconSet: "Foundation",
    iconName: "underline"
  },
  {
    uuid: "3ef642fb-351d-45fa-8da9-c619b9e67db2",
    iconSet: "Foundation",
    iconName: "universal-access"
  },
  {
    uuid: "c7d0827b-289f-4f18-a7f3-e05af9dbe088",
    iconSet: "Foundation",
    iconName: "unlink"
  },
  {
    uuid: "728d47e3-513b-480a-a1a1-4106b263f245",
    iconSet: "Foundation",
    iconName: "unlock"
  },
  {
    uuid: "5355354d-f5f1-4c6d-b820-681bb5d9ae6b",
    iconSet: "Foundation",
    iconName: "upload-cloud"
  },
  {
    uuid: "e3873005-2d1d-4e1f-8217-1c664b52a6dc",
    iconSet: "Foundation",
    iconName: "upload"
  },
  {
    uuid: "d260c299-4a97-4d29-82ce-1bc942773400",
    iconSet: "Foundation",
    iconName: "usb"
  },
  {
    uuid: "643bfb22-47dc-4cbe-b5f8-5a0ce71cc9fd",
    iconSet: "Foundation",
    iconName: "video"
  },
  {
    uuid: "2baf4156-f853-46e1-bde2-91c849220a84",
    iconSet: "Foundation",
    iconName: "volume-none"
  },
  {
    uuid: "bea0abe5-4aa4-4936-8d46-af7346619920",
    iconSet: "Foundation",
    iconName: "volume-strike"
  },
  {
    uuid: "2ee04786-e0c4-4410-9d3d-ffb6ec74e0f9",
    iconSet: "Foundation",
    iconName: "volume"
  },
  {
    uuid: "82f258a3-bb07-40c6-bf3d-1b4aff82b777",
    iconSet: "Foundation",
    iconName: "web"
  },
  {
    uuid: "6017a33d-0498-4c7a-8916-c9f25cb88465",
    iconSet: "Foundation",
    iconName: "wheelchair"
  },
  {
    uuid: "ee52979c-a3ba-4df6-9315-d50a59ddabd9",
    iconSet: "Foundation",
    iconName: "widget"
  },
  {
    uuid: "cde3d90e-52bf-4755-b59e-04a39219a685",
    iconSet: "Foundation",
    iconName: "wrench"
  },
  {
    uuid: "d020808e-d8a0-4625-b948-9bab9a6d3d99",
    iconSet: "Foundation",
    iconName: "x-circle"
  },
  {
    uuid: "eb851362-9da6-48cf-a551-202ddc5ec878",
    iconSet: "Foundation",
    iconName: "x"
  },
  {
    uuid: "b567c9e5-da0f-4aff-88e1-2e1523cef48d",
    iconSet: "Foundation",
    iconName: "yen"
  },
  {
    uuid: "ead450c8-3c30-485c-8a72-84cc02c1bdde",
    iconSet: "Foundation",
    iconName: "zoom-in"
  },
  {
    uuid: "005512f5-7de1-4aac-9fc5-a46428a312e5",
    iconSet: "Foundation",
    iconName: "zoom-out"
  },
  {
    uuid: "4720515c-116b-4659-8551-8c025d2f12a9",
    iconSet: "Ionicons",
    iconName: "add"
  },
  {
    uuid: "3ec261d2-14cb-44df-80a7-cdeeba927f20",
    iconSet: "Ionicons",
    iconName: "add-circle"
  },
  {
    uuid: "4f565b03-4a95-44fd-8323-2dbc16423bd0",
    iconSet: "Ionicons",
    iconName: "add-circle-outline"
  },
  {
    uuid: "ee66c5ea-32a5-4c7e-b66d-df2af82afb2c",
    iconSet: "Ionicons",
    iconName: "add-circle-sharp"
  },
  {
    uuid: "28b1e0b0-21df-457c-84c8-1451fa47e756",
    iconSet: "Ionicons",
    iconName: "add-outline"
  },
  {
    uuid: "8e203c72-5ef6-4759-94ef-bf2c5b0a1420",
    iconSet: "Ionicons",
    iconName: "add-sharp"
  },
  {
    uuid: "ca07c7af-9553-49ee-9dae-ac1e2ebee050",
    iconSet: "Ionicons",
    iconName: "airplane"
  },
  {
    uuid: "37276bbd-c7da-459c-b6fa-4cb97e4b4cec",
    iconSet: "Ionicons",
    iconName: "airplane-outline"
  },
  {
    uuid: "dc541a0e-63b9-441d-a210-1c1bb2d476bd",
    iconSet: "Ionicons",
    iconName: "airplane-sharp"
  },
  {
    uuid: "46e112a8-61a1-4aee-950e-c03e4b2ca5c5",
    iconSet: "Ionicons",
    iconName: "alarm"
  },
  {
    uuid: "f94d4de3-f7ad-429a-9738-4d05e708e49a",
    iconSet: "Ionicons",
    iconName: "alarm-outline"
  },
  {
    uuid: "e432bb74-9099-4e93-8bee-769e2ff96daf",
    iconSet: "Ionicons",
    iconName: "alarm-sharp"
  },
  {
    uuid: "34c44449-356e-4735-a95a-2b725d283b54",
    iconSet: "Ionicons",
    iconName: "albums"
  },
  {
    uuid: "dd0034c2-d227-4d18-a235-fd7f46019226",
    iconSet: "Ionicons",
    iconName: "albums-outline"
  },
  {
    uuid: "33612a49-0bf1-486c-a80d-589f377a1ec1",
    iconSet: "Ionicons",
    iconName: "albums-sharp"
  },
  {
    uuid: "9c3b6eff-9394-4477-95ef-a69029069a55",
    iconSet: "Ionicons",
    iconName: "alert"
  },
  {
    uuid: "8cf5116f-e16c-4239-8584-466a651f2be8",
    iconSet: "Ionicons",
    iconName: "alert-circle"
  },
  {
    uuid: "c94f9bed-dd0e-47b2-9397-a861c03fcd97",
    iconSet: "Ionicons",
    iconName: "alert-circle-outline"
  },
  {
    uuid: "b4bcb9bf-54f1-432b-b289-d571347cb8c5",
    iconSet: "Ionicons",
    iconName: "alert-circle-sharp"
  },
  {
    uuid: "f431564e-4e24-407f-83f6-a222c8cab2e6",
    iconSet: "Ionicons",
    iconName: "alert-outline"
  },
  {
    uuid: "60c46a72-18ee-4bfe-9525-4cf06348d14a",
    iconSet: "Ionicons",
    iconName: "alert-sharp"
  },
  {
    uuid: "0972464e-a6de-4bf7-98e8-d4a3e4f06ae6",
    iconSet: "Ionicons",
    iconName: "american-football"
  },
  {
    uuid: "133f7ab1-8ac5-4be8-a66e-89fee65f481f",
    iconSet: "Ionicons",
    iconName: "american-football-outline"
  },
  {
    uuid: "4e879e26-b619-443b-9730-691eccda16ae",
    iconSet: "Ionicons",
    iconName: "american-football-sharp"
  },
  {
    uuid: "a497cc58-9670-4225-a4a9-453587553fef",
    iconSet: "Ionicons",
    iconName: "analytics"
  },
  {
    uuid: "9e139f1a-a25c-4c66-a595-624980cdd0f7",
    iconSet: "Ionicons",
    iconName: "analytics-outline"
  },
  {
    uuid: "656b198f-512b-4cf7-b58c-4f1a683bcc98",
    iconSet: "Ionicons",
    iconName: "analytics-sharp"
  },
  {
    uuid: "887af854-9c6f-4eb0-a597-e70653775264",
    iconSet: "Ionicons",
    iconName: "aperture"
  },
  {
    uuid: "01a108b3-d638-4ba0-8e77-ebd4ff4acc91",
    iconSet: "Ionicons",
    iconName: "aperture-outline"
  },
  {
    uuid: "ea4a06a3-00c4-4bca-bee7-fab86797a1a2",
    iconSet: "Ionicons",
    iconName: "aperture-sharp"
  },
  {
    uuid: "de65fd3b-40a7-4c9c-b4da-7cdf32451f15",
    iconSet: "Ionicons",
    iconName: "apps"
  },
  {
    uuid: "9cabf39f-49e8-4ac9-b7bd-b89610df94e5",
    iconSet: "Ionicons",
    iconName: "apps-outline"
  },
  {
    uuid: "19ab701f-bdf9-462a-9523-973fb029ff35",
    iconSet: "Ionicons",
    iconName: "apps-sharp"
  },
  {
    uuid: "cef0c2b8-8039-4d34-bb2d-8da1f1a12912",
    iconSet: "Ionicons",
    iconName: "archive"
  },
  {
    uuid: "cce99dbe-a132-40cd-a8eb-ca2200abb4dc",
    iconSet: "Ionicons",
    iconName: "archive-outline"
  },
  {
    uuid: "873b53cf-4811-4b7c-9ba7-f2579bb8e5b8",
    iconSet: "Ionicons",
    iconName: "archive-sharp"
  },
  {
    uuid: "b8704c64-9bb0-410a-b475-f49bbbd31f60",
    iconSet: "Ionicons",
    iconName: "arrow-back"
  },
  {
    uuid: "63de2644-9dc8-414d-a1f2-3d18f28c99e6",
    iconSet: "Ionicons",
    iconName: "arrow-back-circle"
  },
  {
    uuid: "8244a4d7-0593-4830-9881-5e423001fdd0",
    iconSet: "Ionicons",
    iconName: "arrow-back-circle-outline"
  },
  {
    uuid: "a06cdcf6-10ca-47ad-8efe-671b941b187f",
    iconSet: "Ionicons",
    iconName: "arrow-back-circle-sharp"
  },
  {
    uuid: "4eaab07f-2c55-4135-b7db-0d492243acd1",
    iconSet: "Ionicons",
    iconName: "arrow-back-outline"
  },
  {
    uuid: "3e757c7b-5d32-4655-bc1b-bc6b353df783",
    iconSet: "Ionicons",
    iconName: "arrow-back-sharp"
  },
  {
    uuid: "7333a981-9f18-4565-89c9-5b7f7a700ecd",
    iconSet: "Ionicons",
    iconName: "arrow-down"
  },
  {
    uuid: "35925773-0274-4de4-9090-183051e082b2",
    iconSet: "Ionicons",
    iconName: "arrow-down-circle"
  },
  {
    uuid: "119498f6-b3b0-4a21-b767-6351360e3d0d",
    iconSet: "Ionicons",
    iconName: "arrow-down-circle-outline"
  },
  {
    uuid: "dcd99834-ce75-414e-b424-bc01f021aaa7",
    iconSet: "Ionicons",
    iconName: "arrow-down-circle-sharp"
  },
  {
    uuid: "e8429f29-a099-490e-b4f2-d6d8c618b5bc",
    iconSet: "Ionicons",
    iconName: "arrow-down-outline"
  },
  {
    uuid: "232bd4c0-a587-4e7e-b4c3-b802f4568ce8",
    iconSet: "Ionicons",
    iconName: "arrow-down-sharp"
  },
  {
    uuid: "3d017241-3305-4022-8332-3e2ea918f217",
    iconSet: "Ionicons",
    iconName: "arrow-forward"
  },
  {
    uuid: "d779201a-43d8-465e-b659-bee93e2d4e7e",
    iconSet: "Ionicons",
    iconName: "arrow-forward-circle"
  },
  {
    uuid: "50e7917e-5783-4dd2-af90-204d05eaafd6",
    iconSet: "Ionicons",
    iconName: "arrow-forward-circle-outline"
  },
  {
    uuid: "99682f77-f23c-4a85-a962-7a4f8ed48d05",
    iconSet: "Ionicons",
    iconName: "arrow-forward-circle-sharp"
  },
  {
    uuid: "d979d9ba-9fd5-4f88-b23b-c3cc18bf7c39",
    iconSet: "Ionicons",
    iconName: "arrow-forward-outline"
  },
  {
    uuid: "752016e3-ba16-40b3-aca5-44d232e996ed",
    iconSet: "Ionicons",
    iconName: "arrow-forward-sharp"
  },
  {
    uuid: "9eb7bf7f-7b50-419c-b700-7ba1f152002c",
    iconSet: "Ionicons",
    iconName: "arrow-redo"
  },
  {
    uuid: "cc7e3c50-1fb4-4d63-91aa-97e26ae8b72b",
    iconSet: "Ionicons",
    iconName: "arrow-redo-circle"
  },
  {
    uuid: "b923da6e-7d2a-4ee2-9bab-6144ee5c1a83",
    iconSet: "Ionicons",
    iconName: "arrow-redo-circle-outline"
  },
  {
    uuid: "0d2309a9-e095-4031-8c0b-9861d2c64053",
    iconSet: "Ionicons",
    iconName: "arrow-redo-circle-sharp"
  },
  {
    uuid: "c6a424db-d3b3-4868-9e46-6dd36ec40dba",
    iconSet: "Ionicons",
    iconName: "arrow-redo-outline"
  },
  {
    uuid: "ee18f5e4-e6ae-45ed-ae43-201daf6ad541",
    iconSet: "Ionicons",
    iconName: "arrow-redo-sharp"
  },
  {
    uuid: "346534ac-1585-4b36-bcd8-3c7ca0d33800",
    iconSet: "Ionicons",
    iconName: "arrow-undo"
  },
  {
    uuid: "ea729a61-8e45-497c-89f3-bb1a8f968cc7",
    iconSet: "Ionicons",
    iconName: "arrow-undo-circle"
  },
  {
    uuid: "41e8b929-4074-4120-a133-65354317c135",
    iconSet: "Ionicons",
    iconName: "arrow-undo-circle-outline"
  },
  {
    uuid: "aaa3c585-f8ca-4b96-bf15-a723f210b1e1",
    iconSet: "Ionicons",
    iconName: "arrow-undo-circle-sharp"
  },
  {
    uuid: "83da7ece-dcf5-4b12-a39f-9f2a0c9d7590",
    iconSet: "Ionicons",
    iconName: "arrow-undo-outline"
  },
  {
    uuid: "64d66035-fdde-4d1b-bd35-1c3daf450da2",
    iconSet: "Ionicons",
    iconName: "arrow-undo-sharp"
  },
  {
    uuid: "96c77568-c213-424a-886b-54b075826e56",
    iconSet: "Ionicons",
    iconName: "arrow-up"
  },
  {
    uuid: "2a1a3631-864f-4ce1-88b6-e6b04c5d6411",
    iconSet: "Ionicons",
    iconName: "arrow-up-circle"
  },
  {
    uuid: "8cc49ce6-0a0d-47a2-8309-9ed6e9fafbd2",
    iconSet: "Ionicons",
    iconName: "arrow-up-circle-outline"
  },
  {
    uuid: "462499d8-3adf-4797-9f59-de0d05a7c8d6",
    iconSet: "Ionicons",
    iconName: "arrow-up-circle-sharp"
  },
  {
    uuid: "908a05b8-fcd3-49cd-841c-bf99610a8dea",
    iconSet: "Ionicons",
    iconName: "arrow-up-outline"
  },
  {
    uuid: "00c3c959-9d4b-46e8-bf84-008dc0e16e86",
    iconSet: "Ionicons",
    iconName: "arrow-up-sharp"
  },
  {
    uuid: "074ce37a-986d-4635-b0f1-7b86c8200f98",
    iconSet: "Ionicons",
    iconName: "at"
  },
  {
    uuid: "3ee14d36-b01c-494e-b631-a5e5e8008ae7",
    iconSet: "Ionicons",
    iconName: "at-circle"
  },
  {
    uuid: "afd6a657-3c60-4c13-9510-7c95ba45bdcd",
    iconSet: "Ionicons",
    iconName: "at-circle-outline"
  },
  {
    uuid: "c1cc6f30-9935-450b-ab84-dfbc186d639d",
    iconSet: "Ionicons",
    iconName: "at-circle-sharp"
  },
  {
    uuid: "ebb078ae-d669-489a-8c6a-2c264c502026",
    iconSet: "Ionicons",
    iconName: "at-outline"
  },
  {
    uuid: "f2b0c8d4-42a3-447a-9326-51214e4e9273",
    iconSet: "Ionicons",
    iconName: "at-sharp"
  },
  {
    uuid: "3770bc24-e375-4dd8-8199-b641c9d9111e",
    iconSet: "Ionicons",
    iconName: "attach"
  },
  {
    uuid: "c6d6f305-2e37-4a02-9002-e79f7aa806a0",
    iconSet: "Ionicons",
    iconName: "attach-outline"
  },
  {
    uuid: "8ff7f86d-6248-40de-8b8d-3dd8b524cbac",
    iconSet: "Ionicons",
    iconName: "attach-sharp"
  },
  {
    uuid: "7a0e1108-b618-412c-a016-6ad2495bff07",
    iconSet: "Ionicons",
    iconName: "backspace"
  },
  {
    uuid: "221b9c46-024e-42e7-bd49-e0a7fc161dd5",
    iconSet: "Ionicons",
    iconName: "backspace-outline"
  },
  {
    uuid: "97568c99-2d23-426b-9542-07b74ca7f0e6",
    iconSet: "Ionicons",
    iconName: "backspace-sharp"
  },
  {
    uuid: "a6f37727-6a4e-482f-bd73-9401c208a209",
    iconSet: "Ionicons",
    iconName: "bandage"
  },
  {
    uuid: "72bb83cb-a5da-44a4-8703-347315c73637",
    iconSet: "Ionicons",
    iconName: "bandage-outline"
  },
  {
    uuid: "1da03d44-4a75-45c5-b686-49337919e64e",
    iconSet: "Ionicons",
    iconName: "bandage-sharp"
  },
  {
    uuid: "924ba24e-e4b4-44c2-8922-b067211d79d8",
    iconSet: "Ionicons",
    iconName: "bar-chart"
  },
  {
    uuid: "87a68681-9e88-407a-9bb1-e646ab39ca08",
    iconSet: "Ionicons",
    iconName: "bar-chart-outline"
  },
  {
    uuid: "17e09d4e-d3cb-4a6a-a7ca-48023750051e",
    iconSet: "Ionicons",
    iconName: "bar-chart-sharp"
  },
  {
    uuid: "914ed0e3-db01-44ad-bfaa-83be6febc8cc",
    iconSet: "Ionicons",
    iconName: "barbell"
  },
  {
    uuid: "5f4e270d-9c32-4ba6-a1ba-bfe83cd9846a",
    iconSet: "Ionicons",
    iconName: "barbell-outline"
  },
  {
    uuid: "bc44a29e-35b0-4938-adbb-b78fecfa9352",
    iconSet: "Ionicons",
    iconName: "barbell-sharp"
  },
  {
    uuid: "f96f894b-a7f8-49b9-abdc-c42ad2742d2a",
    iconSet: "Ionicons",
    iconName: "barcode"
  },
  {
    uuid: "e6bcdea1-8d19-4f3c-8ac6-96dc8f5effa0",
    iconSet: "Ionicons",
    iconName: "barcode-outline"
  },
  {
    uuid: "f1720eba-d198-459a-89e4-50d1dcef5f3c",
    iconSet: "Ionicons",
    iconName: "barcode-sharp"
  },
  {
    uuid: "a21e657e-343c-45fc-a542-c4918c0ed192",
    iconSet: "Ionicons",
    iconName: "baseball"
  },
  {
    uuid: "7561faa5-d4ea-4f60-8ee6-93627243c715",
    iconSet: "Ionicons",
    iconName: "baseball-outline"
  },
  {
    uuid: "bfdf4697-da80-498c-b78a-d7776a5a6283",
    iconSet: "Ionicons",
    iconName: "baseball-sharp"
  },
  {
    uuid: "61982a75-fbaa-4274-aebd-027cb6e9cd32",
    iconSet: "Ionicons",
    iconName: "basket"
  },
  {
    uuid: "f6ba1814-0844-48ff-9b63-dfc15cba1710",
    iconSet: "Ionicons",
    iconName: "basket-outline"
  },
  {
    uuid: "5228f8ee-b542-4444-8115-461e87945ecd",
    iconSet: "Ionicons",
    iconName: "basket-sharp"
  },
  {
    uuid: "07b411d3-b8bb-42ef-900e-8ed55d45ab04",
    iconSet: "Ionicons",
    iconName: "basketball"
  },
  {
    uuid: "c941b533-4f4d-4e80-908e-fc6a023f989a",
    iconSet: "Ionicons",
    iconName: "basketball-outline"
  },
  {
    uuid: "880fa8e3-05f8-453e-8160-8ac898cd6169",
    iconSet: "Ionicons",
    iconName: "basketball-sharp"
  },
  {
    uuid: "571b2f9e-8e93-42a6-b165-511c38773da2",
    iconSet: "Ionicons",
    iconName: "battery-charging"
  },
  {
    uuid: "14df69af-04ab-48e4-bd2f-87ec8e8f09ac",
    iconSet: "Ionicons",
    iconName: "battery-charging-outline"
  },
  {
    uuid: "fbd168d9-3fd0-4a3f-ad91-8b742e7294d9",
    iconSet: "Ionicons",
    iconName: "battery-charging-sharp"
  },
  {
    uuid: "4e361fdf-07a7-4719-aeb2-34ff39d3e859",
    iconSet: "Ionicons",
    iconName: "battery-dead"
  },
  {
    uuid: "5fd557af-2caa-45e2-b820-e3194227a934",
    iconSet: "Ionicons",
    iconName: "battery-dead-outline"
  },
  {
    uuid: "1ad415e3-8573-4aa9-9eb0-86e004fb46cb",
    iconSet: "Ionicons",
    iconName: "battery-dead-sharp"
  },
  {
    uuid: "9c36e55b-4d28-45cf-946b-2571a90d59a4",
    iconSet: "Ionicons",
    iconName: "battery-full"
  },
  {
    uuid: "eb36de6c-20b5-4e94-b3a9-d68e2906ad0a",
    iconSet: "Ionicons",
    iconName: "battery-full-outline"
  },
  {
    uuid: "795e0962-71cb-48b0-9773-e331bbbe58a9",
    iconSet: "Ionicons",
    iconName: "battery-full-sharp"
  },
  {
    uuid: "82b77606-b31a-4a7d-95b2-1b65edb8f4f1",
    iconSet: "Ionicons",
    iconName: "battery-half"
  },
  {
    uuid: "b31f0bd1-60a1-4a10-a1fd-d021de996e92",
    iconSet: "Ionicons",
    iconName: "battery-half-outline"
  },
  {
    uuid: "9cb044ac-62b3-460e-b19d-6ad3007049e1",
    iconSet: "Ionicons",
    iconName: "battery-half-sharp"
  },
  {
    uuid: "32267f02-4698-40a3-960e-d1160b70fb51",
    iconSet: "Ionicons",
    iconName: "beaker"
  },
  {
    uuid: "6cc4c62a-b6e7-473e-b25a-6227fe364fd3",
    iconSet: "Ionicons",
    iconName: "beaker-outline"
  },
  {
    uuid: "466bf602-2913-4c14-bfb2-3ab706e515c9",
    iconSet: "Ionicons",
    iconName: "beaker-sharp"
  },
  {
    uuid: "ae41c389-ba69-4f89-a193-d9cfdef64c74",
    iconSet: "Ionicons",
    iconName: "bed"
  },
  {
    uuid: "fdbd3d03-68ca-4d5f-9805-826731400742",
    iconSet: "Ionicons",
    iconName: "bed-outline"
  },
  {
    uuid: "c82ed240-5337-44bc-ad45-962361215820",
    iconSet: "Ionicons",
    iconName: "bed-sharp"
  },
  {
    uuid: "c5c630f5-80f8-4594-a657-b8fe80178a02",
    iconSet: "Ionicons",
    iconName: "beer"
  },
  {
    uuid: "b1feae50-2340-4156-8bf5-80c2d8d9ca21",
    iconSet: "Ionicons",
    iconName: "beer-outline"
  },
  {
    uuid: "4ad74131-58f6-4bea-997a-2e12f5edad01",
    iconSet: "Ionicons",
    iconName: "beer-sharp"
  },
  {
    uuid: "957b0f10-4871-4f44-9ce7-8321ce5b6078",
    iconSet: "Ionicons",
    iconName: "bicycle"
  },
  {
    uuid: "8d8eeb51-d191-4816-b1d2-df839b1b58da",
    iconSet: "Ionicons",
    iconName: "bicycle-outline"
  },
  {
    uuid: "941b93ad-fabf-4076-b94a-88f8819d2da8",
    iconSet: "Ionicons",
    iconName: "bicycle-sharp"
  },
  {
    uuid: "ba1c2f3a-567a-4cf6-be6f-10b968c43f83",
    iconSet: "Ionicons",
    iconName: "bluetooth"
  },
  {
    uuid: "aed994e0-ee1d-4614-948a-be8c0edd5c53",
    iconSet: "Ionicons",
    iconName: "bluetooth-outline"
  },
  {
    uuid: "895cde6f-5a50-448d-932a-42e8a0e3cbc7",
    iconSet: "Ionicons",
    iconName: "bluetooth-sharp"
  },
  {
    uuid: "634595e6-ce19-4742-aca3-0a91d11fbb93",
    iconSet: "Ionicons",
    iconName: "boat"
  },
  {
    uuid: "75d0e371-e59d-437b-98e7-8a8d317f55ce",
    iconSet: "Ionicons",
    iconName: "boat-outline"
  },
  {
    uuid: "4b2397db-7f39-40d9-9887-c186df1da023",
    iconSet: "Ionicons",
    iconName: "boat-sharp"
  },
  {
    uuid: "b882622f-af52-42de-8472-df2f39188d04",
    iconSet: "Ionicons",
    iconName: "body"
  },
  {
    uuid: "a4cc4413-8c0f-4911-9fc5-cd8241ecdd0c",
    iconSet: "Ionicons",
    iconName: "body-outline"
  },
  {
    uuid: "4fdeb66c-a9bf-44c9-beb9-6805038b0ed7",
    iconSet: "Ionicons",
    iconName: "body-sharp"
  },
  {
    uuid: "d86d2cfd-e69b-40cf-b93f-1f95e01b8f30",
    iconSet: "Ionicons",
    iconName: "bonfire"
  },
  {
    uuid: "7b9c91c9-faaa-4f9c-8cfc-047254f9e19f",
    iconSet: "Ionicons",
    iconName: "bonfire-outline"
  },
  {
    uuid: "1208fbaf-e214-4379-b367-bf9f9e6302a3",
    iconSet: "Ionicons",
    iconName: "bonfire-sharp"
  },
  {
    uuid: "a38e81b9-a1b3-4b0f-8a20-27f6ba79103a",
    iconSet: "Ionicons",
    iconName: "book"
  },
  {
    uuid: "1c866033-8973-4ef9-b757-b6457c2a9219",
    iconSet: "Ionicons",
    iconName: "book-outline"
  },
  {
    uuid: "4a8a726d-ef06-43f6-a2ca-ad1bd1c19ca8",
    iconSet: "Ionicons",
    iconName: "book-sharp"
  },
  {
    uuid: "451b5924-39dc-426c-be0c-06f3954572df",
    iconSet: "Ionicons",
    iconName: "bookmark"
  },
  {
    uuid: "90a8e094-0128-4665-8de4-658d13c4a8e9",
    iconSet: "Ionicons",
    iconName: "bookmark-outline"
  },
  {
    uuid: "4a14d2d6-14d8-4a6f-bee3-4c84f19ea956",
    iconSet: "Ionicons",
    iconName: "bookmark-sharp"
  },
  {
    uuid: "da7bc6e2-0422-40b1-8c4f-225923f9e49c",
    iconSet: "Ionicons",
    iconName: "bookmarks"
  },
  {
    uuid: "763e44aa-f1bb-4366-bf64-30c389ce4d48",
    iconSet: "Ionicons",
    iconName: "bookmarks-outline"
  },
  {
    uuid: "6a1a6989-0ae2-45eb-8f50-55298ff8449b",
    iconSet: "Ionicons",
    iconName: "bookmarks-sharp"
  },
  {
    uuid: "18c6ad35-07ad-4582-97a1-a5c527df691a",
    iconSet: "Ionicons",
    iconName: "briefcase"
  },
  {
    uuid: "03f09770-75d5-4f61-8999-cd4a5ec90c97",
    iconSet: "Ionicons",
    iconName: "briefcase-outline"
  },
  {
    uuid: "933442ca-2def-48f9-b285-9da3f0cdd58f",
    iconSet: "Ionicons",
    iconName: "briefcase-sharp"
  },
  {
    uuid: "7ef3f922-f16e-4f8a-a816-d23a1f5da058",
    iconSet: "Ionicons",
    iconName: "browsers"
  },
  {
    uuid: "e55bb2f6-962b-436b-823b-2b7cbcc74360",
    iconSet: "Ionicons",
    iconName: "browsers-outline"
  },
  {
    uuid: "d7a3863e-f4e5-459a-9fd4-31cf1400b1a5",
    iconSet: "Ionicons",
    iconName: "browsers-sharp"
  },
  {
    uuid: "0157ceef-111a-409a-8dc6-1937338dda1d",
    iconSet: "Ionicons",
    iconName: "brush"
  },
  {
    uuid: "b899ade7-5997-4694-ab9d-61f8b671c7cf",
    iconSet: "Ionicons",
    iconName: "brush-outline"
  },
  {
    uuid: "93bfa679-8bce-4633-965c-c99bae5daaf1",
    iconSet: "Ionicons",
    iconName: "brush-sharp"
  },
  {
    uuid: "6259dfe7-fdd4-47cd-ab41-83fb4526ab10",
    iconSet: "Ionicons",
    iconName: "bug"
  },
  {
    uuid: "d71b5461-cec0-481e-9329-a161f71b369f",
    iconSet: "Ionicons",
    iconName: "bug-outline"
  },
  {
    uuid: "586187a3-20e3-49f5-aadc-8396ff630af1",
    iconSet: "Ionicons",
    iconName: "bug-sharp"
  },
  {
    uuid: "a56036b8-9e95-4f9d-be00-3f4f7bc10613",
    iconSet: "Ionicons",
    iconName: "build"
  },
  {
    uuid: "03d9901f-b4a1-453a-bab1-aab0807f4bde",
    iconSet: "Ionicons",
    iconName: "build-outline"
  },
  {
    uuid: "40e34fe3-9d88-4173-a731-31d13a79a4e1",
    iconSet: "Ionicons",
    iconName: "build-sharp"
  },
  {
    uuid: "f68ca7c2-2f5a-493e-83f4-f0ebdbce2021",
    iconSet: "Ionicons",
    iconName: "bulb"
  },
  {
    uuid: "f42a80ce-4830-4108-bf4e-574c11620bc2",
    iconSet: "Ionicons",
    iconName: "bulb-outline"
  },
  {
    uuid: "6f358795-58ac-4059-97a0-37e9566c6820",
    iconSet: "Ionicons",
    iconName: "bulb-sharp"
  },
  {
    uuid: "88d7024a-de9c-48e2-a3aa-616cb31cd7a4",
    iconSet: "Ionicons",
    iconName: "bus"
  },
  {
    uuid: "2d9945b2-6da9-4b19-bedf-d0b591826ffb",
    iconSet: "Ionicons",
    iconName: "bus-outline"
  },
  {
    uuid: "a95e2e20-b9fe-4456-9dde-fc3c3ce923b8",
    iconSet: "Ionicons",
    iconName: "bus-sharp"
  },
  {
    uuid: "5255ab84-cb83-400b-af5f-f0a229967a92",
    iconSet: "Ionicons",
    iconName: "business"
  },
  {
    uuid: "532943e7-67c6-4546-876a-0e5cc365c876",
    iconSet: "Ionicons",
    iconName: "business-outline"
  },
  {
    uuid: "523bacfc-4def-4583-99d1-f77d933fdd8a",
    iconSet: "Ionicons",
    iconName: "business-sharp"
  },
  {
    uuid: "79b73768-def5-4f70-9754-e42706b8feae",
    iconSet: "Ionicons",
    iconName: "cafe"
  },
  {
    uuid: "95ac341c-988e-4886-bbca-f51cdf9346c5",
    iconSet: "Ionicons",
    iconName: "cafe-outline"
  },
  {
    uuid: "8bcc2100-6d2c-48e4-8332-251004fe8dba",
    iconSet: "Ionicons",
    iconName: "cafe-sharp"
  },
  {
    uuid: "b0b2bcba-b21f-4674-85d9-7977c0db9967",
    iconSet: "Ionicons",
    iconName: "calculator"
  },
  {
    uuid: "e3aeb357-2248-490e-9473-0672931fea35",
    iconSet: "Ionicons",
    iconName: "calculator-outline"
  },
  {
    uuid: "714ac5c8-73a8-493c-b4a7-a92496271087",
    iconSet: "Ionicons",
    iconName: "calculator-sharp"
  },
  {
    uuid: "7556caf6-3dd9-45a0-9351-d201f9d806f7",
    iconSet: "Ionicons",
    iconName: "calendar"
  },
  {
    uuid: "f56091df-1c52-4ce7-b3ac-2d762daf6cba",
    iconSet: "Ionicons",
    iconName: "calendar-outline"
  },
  {
    uuid: "47ad0258-68a3-4187-ab7a-db5900942d0b",
    iconSet: "Ionicons",
    iconName: "calendar-sharp"
  },
  {
    uuid: "ab4a7eca-b6b5-4921-9f1a-3d185069e06f",
    iconSet: "Ionicons",
    iconName: "call"
  },
  {
    uuid: "495f4f3e-5f15-4376-83e6-05365e07950c",
    iconSet: "Ionicons",
    iconName: "call-outline"
  },
  {
    uuid: "21a72f67-2a7a-4e2a-8ad3-b24661ee7ccb",
    iconSet: "Ionicons",
    iconName: "call-sharp"
  },
  {
    uuid: "4efbf020-5212-4c8f-a992-b84329e3b0d8",
    iconSet: "Ionicons",
    iconName: "camera"
  },
  {
    uuid: "14752aca-7071-4391-ab15-a75d3e7c3036",
    iconSet: "Ionicons",
    iconName: "camera-outline"
  },
  {
    uuid: "41a4c61a-5327-4524-adbc-297ef8d85f4f",
    iconSet: "Ionicons",
    iconName: "camera-reverse"
  },
  {
    uuid: "87ee470f-e626-42b0-8381-1a7c761e420c",
    iconSet: "Ionicons",
    iconName: "camera-reverse-outline"
  },
  {
    uuid: "00f752c1-23a4-49a3-b85a-51c8a7b42d16",
    iconSet: "Ionicons",
    iconName: "camera-reverse-sharp"
  },
  {
    uuid: "ac5ce39b-81c5-47ef-82b0-0b97cc322d2b",
    iconSet: "Ionicons",
    iconName: "camera-sharp"
  },
  {
    uuid: "39b9863d-f1dd-4fc1-8fa8-c3b9da6f5762",
    iconSet: "Ionicons",
    iconName: "car"
  },
  {
    uuid: "c57e9057-5a7c-4156-b18b-e6764f4a29ac",
    iconSet: "Ionicons",
    iconName: "car-outline"
  },
  {
    uuid: "23dd05dc-4b91-4b78-a2f4-5a215db1f730",
    iconSet: "Ionicons",
    iconName: "car-sharp"
  },
  {
    uuid: "2b2c0322-f1c8-4c40-b3c0-f91f442ad1cc",
    iconSet: "Ionicons",
    iconName: "car-sport"
  },
  {
    uuid: "e19a2261-03e6-4800-87fc-e44c77faa0f6",
    iconSet: "Ionicons",
    iconName: "car-sport-outline"
  },
  {
    uuid: "ff113531-a89f-49e6-95ae-8f568f40d3d2",
    iconSet: "Ionicons",
    iconName: "car-sport-sharp"
  },
  {
    uuid: "809cbf31-9657-4e68-bbfa-e1f7d03e0ed8",
    iconSet: "Ionicons",
    iconName: "card"
  },
  {
    uuid: "4ae2c277-9b94-4f73-99c3-6e74ad73bdac",
    iconSet: "Ionicons",
    iconName: "card-outline"
  },
  {
    uuid: "96979fde-20a7-47a3-bb07-18e0499e8dc9",
    iconSet: "Ionicons",
    iconName: "card-sharp"
  },
  {
    uuid: "810ce58a-b44a-4dfd-81eb-06cbd4f5d2cc",
    iconSet: "Ionicons",
    iconName: "caret-back"
  },
  {
    uuid: "d902e118-cf67-49d4-a8ff-613f4e538c72",
    iconSet: "Ionicons",
    iconName: "caret-back-circle"
  },
  {
    uuid: "fa6a4dc1-faca-44a8-b9c9-4a39b5d09201",
    iconSet: "Ionicons",
    iconName: "caret-back-circle-outline"
  },
  {
    uuid: "e8715a01-d83a-4b17-8eb8-c5637b7bcd08",
    iconSet: "Ionicons",
    iconName: "caret-back-circle-sharp"
  },
  {
    uuid: "eb7c7cc7-2d75-462f-96bc-8287a6c153bc",
    iconSet: "Ionicons",
    iconName: "caret-back-outline"
  },
  {
    uuid: "7e625e1c-f244-4f46-9fc9-d719570721f2",
    iconSet: "Ionicons",
    iconName: "caret-back-sharp"
  },
  {
    uuid: "86b34737-b387-421b-99fa-556227024fef",
    iconSet: "Ionicons",
    iconName: "caret-down"
  },
  {
    uuid: "03bf72a5-41ab-4645-b81e-31bb7814f91d",
    iconSet: "Ionicons",
    iconName: "caret-down-circle"
  },
  {
    uuid: "980e6ff4-a166-4864-b4be-c09cffd90276",
    iconSet: "Ionicons",
    iconName: "caret-down-circle-outline"
  },
  {
    uuid: "cf32fd2a-d434-419d-8daa-369604dff148",
    iconSet: "Ionicons",
    iconName: "caret-down-circle-sharp"
  },
  {
    uuid: "f2ec45d8-2210-446c-bcff-fc3c7a4e0e1c",
    iconSet: "Ionicons",
    iconName: "caret-down-outline"
  },
  {
    uuid: "6e788dbc-2e37-4e73-9226-ccbbe0cc887e",
    iconSet: "Ionicons",
    iconName: "caret-down-sharp"
  },
  {
    uuid: "92b4231f-1dbe-4ef8-9458-3dcd98728b2b",
    iconSet: "Ionicons",
    iconName: "caret-forward"
  },
  {
    uuid: "9d910d13-e889-449c-807b-06d692e0026f",
    iconSet: "Ionicons",
    iconName: "caret-forward-circle"
  },
  {
    uuid: "ceb40eb8-7931-40c9-81ee-d7a1aaf0e517",
    iconSet: "Ionicons",
    iconName: "caret-forward-circle-outline"
  },
  {
    uuid: "e7bcc1f9-642d-4e3a-98b9-992c03406861",
    iconSet: "Ionicons",
    iconName: "caret-forward-circle-sharp"
  },
  {
    uuid: "6c082aee-1c68-4d61-9778-42ccf875cacc",
    iconSet: "Ionicons",
    iconName: "caret-forward-outline"
  },
  {
    uuid: "12a03a01-b175-48bb-9be1-a62bf183ce47",
    iconSet: "Ionicons",
    iconName: "caret-forward-sharp"
  },
  {
    uuid: "8f448c6a-44c3-48ee-bf78-6d13a0e85983",
    iconSet: "Ionicons",
    iconName: "caret-up"
  },
  {
    uuid: "ca6acbd3-543c-441a-8ac0-5f8027b45fb7",
    iconSet: "Ionicons",
    iconName: "caret-up-circle"
  },
  {
    uuid: "59724384-a2bc-4ab1-aec4-6d1a1ca76b14",
    iconSet: "Ionicons",
    iconName: "caret-up-circle-outline"
  },
  {
    uuid: "1ceee5a8-d8a6-4d25-95ed-56aa85cbc780",
    iconSet: "Ionicons",
    iconName: "caret-up-circle-sharp"
  },
  {
    uuid: "b0cc9fa8-01d3-4431-b1eb-80b64902763b",
    iconSet: "Ionicons",
    iconName: "caret-up-outline"
  },
  {
    uuid: "95e0b329-ccfd-44a6-b38e-2f55b4a3c8d9",
    iconSet: "Ionicons",
    iconName: "caret-up-sharp"
  },
  {
    uuid: "15737ec0-0705-43a9-be27-8a323e6138b0",
    iconSet: "Ionicons",
    iconName: "cart"
  },
  {
    uuid: "1ed97715-947d-4200-9f82-c682f5329473",
    iconSet: "Ionicons",
    iconName: "cart-outline"
  },
  {
    uuid: "6de554b3-7e25-4c6e-a1bc-d88bc308273a",
    iconSet: "Ionicons",
    iconName: "cart-sharp"
  },
  {
    uuid: "7a75a2c3-de67-4a43-a7f7-ee9d5a1b9ada",
    iconSet: "Ionicons",
    iconName: "cash"
  },
  {
    uuid: "42be9bce-37cd-4131-8cc4-56f80429a196",
    iconSet: "Ionicons",
    iconName: "cash-outline"
  },
  {
    uuid: "fc692da8-4a86-45f0-9041-2c37098c9612",
    iconSet: "Ionicons",
    iconName: "cash-sharp"
  },
  {
    uuid: "1c272273-9f2b-477e-acda-14c717677566",
    iconSet: "Ionicons",
    iconName: "cellular"
  },
  {
    uuid: "c78aa377-4e45-4170-9363-74d2e92b247a",
    iconSet: "Ionicons",
    iconName: "cellular-outline"
  },
  {
    uuid: "e85dea92-1410-4e3c-9eb7-398fcb925188",
    iconSet: "Ionicons",
    iconName: "cellular-sharp"
  },
  {
    uuid: "ae772a17-caee-40e5-b446-f1a0bcd540d4",
    iconSet: "Ionicons",
    iconName: "chatbox"
  },
  {
    uuid: "5ada1553-d875-4609-adf5-de9671a33458",
    iconSet: "Ionicons",
    iconName: "chatbox-ellipses"
  },
  {
    uuid: "562fab21-12aa-4770-94f5-b94fd3946767",
    iconSet: "Ionicons",
    iconName: "chatbox-ellipses-outline"
  },
  {
    uuid: "21b100f4-3950-4b83-91de-caf8a45d7448",
    iconSet: "Ionicons",
    iconName: "chatbox-ellipses-sharp"
  },
  {
    uuid: "519c0389-4960-4990-a4d3-36fabaf8c5ee",
    iconSet: "Ionicons",
    iconName: "chatbox-outline"
  },
  {
    uuid: "1314f105-3af2-48bc-8365-a118174b94c4",
    iconSet: "Ionicons",
    iconName: "chatbox-sharp"
  },
  {
    uuid: "80c3f328-a25b-4e7d-9d6b-bf94a92a7a97",
    iconSet: "Ionicons",
    iconName: "chatbubble"
  },
  {
    uuid: "36739846-1eeb-4e37-83bb-aa006f2b26c1",
    iconSet: "Ionicons",
    iconName: "chatbubble-ellipses"
  },
  {
    uuid: "0e9e36b0-51a7-4c4c-ae8b-1aade032caf4",
    iconSet: "Ionicons",
    iconName: "chatbubble-ellipses-outline"
  },
  {
    uuid: "f440f5f7-89fa-4e6c-ac89-e565eae76963",
    iconSet: "Ionicons",
    iconName: "chatbubble-ellipses-sharp"
  },
  {
    uuid: "ca1e2481-ebbc-4de1-858c-e65d8194ee7b",
    iconSet: "Ionicons",
    iconName: "chatbubble-outline"
  },
  {
    uuid: "f490a772-228b-4815-abc5-8c6951a97392",
    iconSet: "Ionicons",
    iconName: "chatbubble-sharp"
  },
  {
    uuid: "2945e8af-b72c-4567-9f85-214e29133770",
    iconSet: "Ionicons",
    iconName: "chatbubbles"
  },
  {
    uuid: "8bbff17c-69ee-40b4-9774-5b4db7c1d211",
    iconSet: "Ionicons",
    iconName: "chatbubbles-outline"
  },
  {
    uuid: "05f85d0f-4170-493d-b57d-e63009c41b89",
    iconSet: "Ionicons",
    iconName: "chatbubbles-sharp"
  },
  {
    uuid: "82be48bf-dc05-4211-96f9-1a9f6d274811",
    iconSet: "Ionicons",
    iconName: "checkbox"
  },
  {
    uuid: "9ec52500-d72f-462b-8037-a712a03cb1f5",
    iconSet: "Ionicons",
    iconName: "checkbox-outline"
  },
  {
    uuid: "27b76a67-de19-4716-a824-29cbbfbd44f7",
    iconSet: "Ionicons",
    iconName: "checkbox-sharp"
  },
  {
    uuid: "e5a00fa6-e944-4e54-9571-893e46eb19d3",
    iconSet: "Ionicons",
    iconName: "checkmark"
  },
  {
    uuid: "9362c872-697a-40a6-92d3-4c67b7fb9386",
    iconSet: "Ionicons",
    iconName: "checkmark-circle"
  },
  {
    uuid: "dd9d9f1d-06d5-4c48-82ab-90bcd50791ef",
    iconSet: "Ionicons",
    iconName: "checkmark-circle-outline"
  },
  {
    uuid: "3f1c2c48-428b-4f75-9ddf-e827a5041ad7",
    iconSet: "Ionicons",
    iconName: "checkmark-circle-sharp"
  },
  {
    uuid: "81787292-62da-4310-a33a-041078500302",
    iconSet: "Ionicons",
    iconName: "checkmark-done"
  },
  {
    uuid: "3745d598-18ce-4cc9-99aa-f9d1b6cb58fc",
    iconSet: "Ionicons",
    iconName: "checkmark-done-circle"
  },
  {
    uuid: "8f414918-4cd1-4004-b6a8-26158bd69849",
    iconSet: "Ionicons",
    iconName: "checkmark-done-circle-outline"
  },
  {
    uuid: "2a92ac6c-b0ff-4baf-b3ce-636ccde25845",
    iconSet: "Ionicons",
    iconName: "checkmark-done-circle-sharp"
  },
  {
    uuid: "cc2129c8-fd9e-4f3d-b7a7-be2b81a9e3ea",
    iconSet: "Ionicons",
    iconName: "checkmark-done-outline"
  },
  {
    uuid: "83f1fe4e-c677-4b52-b96d-b745ff8b8668",
    iconSet: "Ionicons",
    iconName: "checkmark-done-sharp"
  },
  {
    uuid: "2fe77cc0-30c8-490e-ba06-8904feb66d57",
    iconSet: "Ionicons",
    iconName: "checkmark-outline"
  },
  {
    uuid: "8bec13cd-6343-476b-9ad2-33d0bd1c1de1",
    iconSet: "Ionicons",
    iconName: "checkmark-sharp"
  },
  {
    uuid: "36d894b3-e6ae-4fb4-834d-539bc6997195",
    iconSet: "Ionicons",
    iconName: "chevron-back"
  },
  {
    uuid: "26cd0da5-26e8-494e-a9e2-0bbcc677bc87",
    iconSet: "Ionicons",
    iconName: "chevron-back-circle"
  },
  {
    uuid: "367ac481-4a62-4730-ad5d-d14fdde48ce3",
    iconSet: "Ionicons",
    iconName: "chevron-back-circle-outline"
  },
  {
    uuid: "a67cb8e8-8f11-4a88-8055-1e67f86cb157",
    iconSet: "Ionicons",
    iconName: "chevron-back-circle-sharp"
  },
  {
    uuid: "d001542d-b444-4082-b75e-59da7af3efd6",
    iconSet: "Ionicons",
    iconName: "chevron-back-outline"
  },
  {
    uuid: "96ab0f6c-868d-420f-b2f7-03575999f7ae",
    iconSet: "Ionicons",
    iconName: "chevron-back-sharp"
  },
  {
    uuid: "5925f5b0-726d-4466-8505-244eeb79917f",
    iconSet: "Ionicons",
    iconName: "chevron-down"
  },
  {
    uuid: "1b140416-b38c-4bcb-807c-b6ed4ff901c3",
    iconSet: "Ionicons",
    iconName: "chevron-down-circle"
  },
  {
    uuid: "97a9f8f8-ac39-4a97-9dca-fa011222b933",
    iconSet: "Ionicons",
    iconName: "chevron-down-circle-outline"
  },
  {
    uuid: "971c463b-f63c-48df-a26a-b905abcb2460",
    iconSet: "Ionicons",
    iconName: "chevron-down-circle-sharp"
  },
  {
    uuid: "f2e67787-581f-4507-a608-5bf60df90c59",
    iconSet: "Ionicons",
    iconName: "chevron-down-outline"
  },
  {
    uuid: "b0197164-0890-4a40-8321-0cd5f7080173",
    iconSet: "Ionicons",
    iconName: "chevron-down-sharp"
  },
  {
    uuid: "f27e8d79-dc55-475a-8b89-3df872cbb534",
    iconSet: "Ionicons",
    iconName: "chevron-forward"
  },
  {
    uuid: "9ab534f1-331f-48ca-8e51-68ef3e3acafe",
    iconSet: "Ionicons",
    iconName: "chevron-forward-circle"
  },
  {
    uuid: "8d958941-5032-47d9-be2f-f810b6ec6845",
    iconSet: "Ionicons",
    iconName: "chevron-forward-circle-outline"
  },
  {
    uuid: "95f260b9-3b4e-4d71-9c0f-f3645e25e1d5",
    iconSet: "Ionicons",
    iconName: "chevron-forward-circle-sharp"
  },
  {
    uuid: "3e41d732-e8e5-44f8-9023-f1667fecafcc",
    iconSet: "Ionicons",
    iconName: "chevron-forward-outline"
  },
  {
    uuid: "bb0be80e-83ee-438d-b276-5e0ff0b9562f",
    iconSet: "Ionicons",
    iconName: "chevron-forward-sharp"
  },
  {
    uuid: "02fd5bf9-cf04-497d-8ddd-7dad9dac041f",
    iconSet: "Ionicons",
    iconName: "chevron-up"
  },
  {
    uuid: "d90a2c10-da21-4b14-9fc0-d5d40e577a0e",
    iconSet: "Ionicons",
    iconName: "chevron-up-circle"
  },
  {
    uuid: "1c5b5b2d-3eed-45bc-b4b0-488e7c6a149e",
    iconSet: "Ionicons",
    iconName: "chevron-up-circle-outline"
  },
  {
    uuid: "04b32d1e-4789-4d5b-a7c3-754a8b48c893",
    iconSet: "Ionicons",
    iconName: "chevron-up-circle-sharp"
  },
  {
    uuid: "89264420-1a33-452a-829b-08f291bcb2e4",
    iconSet: "Ionicons",
    iconName: "chevron-up-outline"
  },
  {
    uuid: "9df485cf-7ec2-492a-aabd-9cf92cdd89c0",
    iconSet: "Ionicons",
    iconName: "chevron-up-sharp"
  },
  {
    uuid: "650a402a-4c26-4a52-b433-e905b74db3a2",
    iconSet: "Ionicons",
    iconName: "clipboard"
  },
  {
    uuid: "a3d27084-48d5-4ad5-a2b7-2b2a95be1d49",
    iconSet: "Ionicons",
    iconName: "clipboard-outline"
  },
  {
    uuid: "5c35e824-f18d-43b1-9933-556fdf197259",
    iconSet: "Ionicons",
    iconName: "clipboard-sharp"
  },
  {
    uuid: "b687d75a-9687-487f-9002-6c85b9b4c8ee",
    iconSet: "Ionicons",
    iconName: "close"
  },
  {
    uuid: "79f785b9-b848-4018-8742-713dfed73de1",
    iconSet: "Ionicons",
    iconName: "close-circle"
  },
  {
    uuid: "21bfaf90-01be-4292-aa4d-339ed5311ef0",
    iconSet: "Ionicons",
    iconName: "close-circle-outline"
  },
  {
    uuid: "e922014c-d9c7-4f6f-ad4b-77382aa50bb6",
    iconSet: "Ionicons",
    iconName: "close-circle-sharp"
  },
  {
    uuid: "329696bb-e537-4f0a-be82-68ade6064238",
    iconSet: "Ionicons",
    iconName: "close-outline"
  },
  {
    uuid: "ee3d69a8-2aa8-440c-be30-fb7c6a4ce7ca",
    iconSet: "Ionicons",
    iconName: "close-sharp"
  },
  {
    uuid: "e010c940-2f26-437a-9da1-f55bf064922a",
    iconSet: "Ionicons",
    iconName: "cloud"
  },
  {
    uuid: "b891a2f3-0216-4f5a-9a3d-fccd64cfad40",
    iconSet: "Ionicons",
    iconName: "cloud-circle"
  },
  {
    uuid: "e6f665d8-fe33-4f37-bbc3-3cb7292d334a",
    iconSet: "Ionicons",
    iconName: "cloud-circle-outline"
  },
  {
    uuid: "e6d1c1eb-b842-4efa-a157-7bfee0d021a8",
    iconSet: "Ionicons",
    iconName: "cloud-circle-sharp"
  },
  {
    uuid: "f2aca7a8-4d34-4224-89d7-121b7dcc58b8",
    iconSet: "Ionicons",
    iconName: "cloud-done"
  },
  {
    uuid: "fa371517-2667-4775-bf6f-e0ce4d31312c",
    iconSet: "Ionicons",
    iconName: "cloud-done-outline"
  },
  {
    uuid: "7d9b1d7c-4087-4796-a6ad-85d2a9252849",
    iconSet: "Ionicons",
    iconName: "cloud-done-sharp"
  },
  {
    uuid: "429e098c-5ebf-421d-a505-7d8b11e992ca",
    iconSet: "Ionicons",
    iconName: "cloud-download"
  },
  {
    uuid: "a705df06-926d-4a7b-b20f-5dfd36c5543c",
    iconSet: "Ionicons",
    iconName: "cloud-download-outline"
  },
  {
    uuid: "c99eba2c-46b5-4996-b90a-2c4db73944a3",
    iconSet: "Ionicons",
    iconName: "cloud-download-sharp"
  },
  {
    uuid: "255b92aa-caee-4b41-b671-03876c9e4362",
    iconSet: "Ionicons",
    iconName: "cloud-offline"
  },
  {
    uuid: "de31f525-759f-43ca-b351-6397407158af",
    iconSet: "Ionicons",
    iconName: "cloud-offline-outline"
  },
  {
    uuid: "3b7b9b10-0367-4b38-a9b9-f48faf6c7300",
    iconSet: "Ionicons",
    iconName: "cloud-offline-sharp"
  },
  {
    uuid: "68e6adb5-c051-4c28-95f8-486a400fca33",
    iconSet: "Ionicons",
    iconName: "cloud-outline"
  },
  {
    uuid: "eb934ccb-d20c-4f25-a659-234d27d6c81d",
    iconSet: "Ionicons",
    iconName: "cloud-sharp"
  },
  {
    uuid: "c9c95888-765c-4eb9-9c40-16e5ccdfd586",
    iconSet: "Ionicons",
    iconName: "cloud-upload"
  },
  {
    uuid: "f1f86434-e4ea-4c34-9392-53fde8e45946",
    iconSet: "Ionicons",
    iconName: "cloud-upload-outline"
  },
  {
    uuid: "ef8f7c7e-1b48-402a-9a8a-00fb02abe4de",
    iconSet: "Ionicons",
    iconName: "cloud-upload-sharp"
  },
  {
    uuid: "06013fb8-a00a-4f78-b198-167525e23cb5",
    iconSet: "Ionicons",
    iconName: "cloudy"
  },
  {
    uuid: "d318b91a-b823-4376-a766-b6e9f9e2954f",
    iconSet: "Ionicons",
    iconName: "cloudy-night"
  },
  {
    uuid: "aa4ce720-f917-4555-a244-df0ab17863ce",
    iconSet: "Ionicons",
    iconName: "cloudy-night-outline"
  },
  {
    uuid: "0decc902-abe7-4da8-a071-40d87c309926",
    iconSet: "Ionicons",
    iconName: "cloudy-night-sharp"
  },
  {
    uuid: "d44a89fb-39ec-43a3-935f-89e733f9ea7d",
    iconSet: "Ionicons",
    iconName: "cloudy-outline"
  },
  {
    uuid: "9ceb33ad-5ce8-4b51-be3d-079cad74accc",
    iconSet: "Ionicons",
    iconName: "cloudy-sharp"
  },
  {
    uuid: "ed0363b8-bbb0-464f-b6d6-05614c9919a4",
    iconSet: "Ionicons",
    iconName: "code"
  },
  {
    uuid: "55c54e9e-629c-49da-bcd7-c0bb82e3c658",
    iconSet: "Ionicons",
    iconName: "code-download"
  },
  {
    uuid: "ac3efe6c-38a5-4b8d-b507-07a1ee3902de",
    iconSet: "Ionicons",
    iconName: "code-download-outline"
  },
  {
    uuid: "9bd2d0c5-6446-4b94-ad25-5fe6e38f652e",
    iconSet: "Ionicons",
    iconName: "code-download-sharp"
  },
  {
    uuid: "ee2a6c00-3194-45a2-933c-e18caf2347ef",
    iconSet: "Ionicons",
    iconName: "code-outline"
  },
  {
    uuid: "b36888e4-a3e9-4e9c-8772-bdd50d55feac",
    iconSet: "Ionicons",
    iconName: "code-sharp"
  },
  {
    uuid: "17be0272-b86f-4e3a-8e1b-4efd50b6e8aa",
    iconSet: "Ionicons",
    iconName: "code-slash"
  },
  {
    uuid: "a41e9bce-0909-4f8d-8b14-a9e002fd08f2",
    iconSet: "Ionicons",
    iconName: "code-slash-outline"
  },
  {
    uuid: "573f68fc-205c-479f-8e84-7dbfbb78dbd1",
    iconSet: "Ionicons",
    iconName: "code-slash-sharp"
  },
  {
    uuid: "d31fb6af-f48e-4b0f-985c-f4a8fff81580",
    iconSet: "Ionicons",
    iconName: "code-working"
  },
  {
    uuid: "a8254129-1911-4b9a-ae59-1ac564039e0e",
    iconSet: "Ionicons",
    iconName: "code-working-outline"
  },
  {
    uuid: "7dbe2c8d-c61a-45d8-b896-781894f31bf0",
    iconSet: "Ionicons",
    iconName: "code-working-sharp"
  },
  {
    uuid: "9f1aebcd-e564-4c87-b937-4fb39d677bd3",
    iconSet: "Ionicons",
    iconName: "cog"
  },
  {
    uuid: "37ae5fb7-3e68-4ddd-8c65-3668968b3f79",
    iconSet: "Ionicons",
    iconName: "cog-outline"
  },
  {
    uuid: "a8f5d474-b31f-4285-b021-9fcceaef7cf3",
    iconSet: "Ionicons",
    iconName: "cog-sharp"
  },
  {
    uuid: "85186dac-ba0c-46b6-af55-44a5bd954f6e",
    iconSet: "Ionicons",
    iconName: "color-fill"
  },
  {
    uuid: "c7078278-6753-4be7-82dd-95351e888c27",
    iconSet: "Ionicons",
    iconName: "color-fill-outline"
  },
  {
    uuid: "dbc80991-0e2a-4347-98e6-ffbb8be66adf",
    iconSet: "Ionicons",
    iconName: "color-fill-sharp"
  },
  {
    uuid: "0f4731ab-fafa-484e-9a71-8f33f060de67",
    iconSet: "Ionicons",
    iconName: "color-filter"
  },
  {
    uuid: "34a9d8e3-d448-4e65-a352-5b65b12a2fa9",
    iconSet: "Ionicons",
    iconName: "color-filter-outline"
  },
  {
    uuid: "ce2bc3c0-7b4d-4050-a97c-2b095abdc388",
    iconSet: "Ionicons",
    iconName: "color-filter-sharp"
  },
  {
    uuid: "e74d3db1-4935-4071-a1f9-19ffe80af1ea",
    iconSet: "Ionicons",
    iconName: "color-palette"
  },
  {
    uuid: "96895ce3-3d89-4d62-be93-d99852cd9981",
    iconSet: "Ionicons",
    iconName: "color-palette-outline"
  },
  {
    uuid: "756aada1-1cbe-4bad-bda3-617d04e34ff6",
    iconSet: "Ionicons",
    iconName: "color-palette-sharp"
  },
  {
    uuid: "31499b58-ee71-459a-a57a-13a6049a92d2",
    iconSet: "Ionicons",
    iconName: "color-wand"
  },
  {
    uuid: "fd21e0df-f4ec-4981-88a1-60d683e288ea",
    iconSet: "Ionicons",
    iconName: "color-wand-outline"
  },
  {
    uuid: "282e5878-ae46-40ef-93be-337d9747985b",
    iconSet: "Ionicons",
    iconName: "color-wand-sharp"
  },
  {
    uuid: "a25898e8-53fa-4aa0-95ad-dcb2b2689362",
    iconSet: "Ionicons",
    iconName: "compass"
  },
  {
    uuid: "d22a4b98-1e36-499d-86e2-a21f17538a94",
    iconSet: "Ionicons",
    iconName: "compass-outline"
  },
  {
    uuid: "6aa8b1b3-91d8-483c-bf13-15e1549766ee",
    iconSet: "Ionicons",
    iconName: "compass-sharp"
  },
  {
    uuid: "fd4a4336-6d9c-4bb4-ad3e-9a9cf79a54b2",
    iconSet: "Ionicons",
    iconName: "construct"
  },
  {
    uuid: "9cda9c70-9dae-45df-bf56-e1a8a40541a3",
    iconSet: "Ionicons",
    iconName: "construct-outline"
  },
  {
    uuid: "58906e79-78cd-4952-87ac-09dfe3352e62",
    iconSet: "Ionicons",
    iconName: "construct-sharp"
  },
  {
    uuid: "98814575-13d3-41a6-b987-85363cbdac44",
    iconSet: "Ionicons",
    iconName: "contract"
  },
  {
    uuid: "b9af7638-d2c8-4ea8-bdbe-ddb14e490f6e",
    iconSet: "Ionicons",
    iconName: "contract-outline"
  },
  {
    uuid: "81a4120f-8dec-41c5-867c-43982cd11ce7",
    iconSet: "Ionicons",
    iconName: "contract-sharp"
  },
  {
    uuid: "87d5c4dd-53c5-4060-b09a-257d22f4b49a",
    iconSet: "Ionicons",
    iconName: "contrast"
  },
  {
    uuid: "6962884e-e7e5-4fac-8f79-4717a6274977",
    iconSet: "Ionicons",
    iconName: "contrast-outline"
  },
  {
    uuid: "9dab09c6-cc25-48f5-83ae-2cf12c583e10",
    iconSet: "Ionicons",
    iconName: "contrast-sharp"
  },
  {
    uuid: "101c543f-62e4-46a5-8a38-e66fd2631ca0",
    iconSet: "Ionicons",
    iconName: "copy"
  },
  {
    uuid: "88956514-d7ad-4323-bf36-fbc9b5709572",
    iconSet: "Ionicons",
    iconName: "copy-outline"
  },
  {
    uuid: "184b8fbe-07a0-4ce6-95b7-c0ed6031c3b3",
    iconSet: "Ionicons",
    iconName: "copy-sharp"
  },
  {
    uuid: "828e9f2c-7aff-49e2-be84-3e8557bbeeb9",
    iconSet: "Ionicons",
    iconName: "create"
  },
  {
    uuid: "0a94f591-4df6-4f80-abc5-2c374cf25eb9",
    iconSet: "Ionicons",
    iconName: "create-outline"
  },
  {
    uuid: "b838f967-d8a3-499a-b9ad-22f9cd9399ce",
    iconSet: "Ionicons",
    iconName: "create-sharp"
  },
  {
    uuid: "905d81d1-3cb6-46e5-bc97-edde03626230",
    iconSet: "Ionicons",
    iconName: "crop"
  },
  {
    uuid: "5c33fbf6-4151-4988-821c-3e5129b219b4",
    iconSet: "Ionicons",
    iconName: "crop-outline"
  },
  {
    uuid: "54316f90-9015-4ab8-a66f-d1b838b26511",
    iconSet: "Ionicons",
    iconName: "crop-sharp"
  },
  {
    uuid: "f9d77a06-5f1b-4005-943e-141395f84177",
    iconSet: "Ionicons",
    iconName: "cube"
  },
  {
    uuid: "7b1600b7-59cc-4a29-91e5-bc91e024bb3f",
    iconSet: "Ionicons",
    iconName: "cube-outline"
  },
  {
    uuid: "f30cb013-cd73-4439-b187-d271274edbd4",
    iconSet: "Ionicons",
    iconName: "cube-sharp"
  },
  {
    uuid: "c6f5a40a-f8a1-4176-873b-14232759a5f3",
    iconSet: "Ionicons",
    iconName: "cut"
  },
  {
    uuid: "239cec36-bac1-4bc6-959b-6fdb7eaa2262",
    iconSet: "Ionicons",
    iconName: "cut-outline"
  },
  {
    uuid: "6a475aae-acd6-49da-9146-e148cbf34dd5",
    iconSet: "Ionicons",
    iconName: "cut-sharp"
  },
  {
    uuid: "adc57112-908a-4b8c-9394-299bd32ca45a",
    iconSet: "Ionicons",
    iconName: "desktop"
  },
  {
    uuid: "67dfcaeb-4393-4872-8f61-ec1fab89afec",
    iconSet: "Ionicons",
    iconName: "desktop-outline"
  },
  {
    uuid: "2736e16b-4cd6-4ff3-881a-1c7350f3cd90",
    iconSet: "Ionicons",
    iconName: "desktop-sharp"
  },
  {
    uuid: "7912cdb5-e351-4199-bfb5-13abb418f285",
    iconSet: "Ionicons",
    iconName: "disc"
  },
  {
    uuid: "313411a6-8994-490f-b440-28218954d5a4",
    iconSet: "Ionicons",
    iconName: "disc-outline"
  },
  {
    uuid: "691e23a3-77ad-4205-bc38-a91f4ba15d63",
    iconSet: "Ionicons",
    iconName: "disc-sharp"
  },
  {
    uuid: "7319a933-98af-4dbb-89fa-e5b3c6001b41",
    iconSet: "Ionicons",
    iconName: "document"
  },
  {
    uuid: "a443162a-6c2d-4070-b956-41bde1c6fb26",
    iconSet: "Ionicons",
    iconName: "document-attach"
  },
  {
    uuid: "7b320323-3eea-496e-99d4-080687ffff73",
    iconSet: "Ionicons",
    iconName: "document-attach-outline"
  },
  {
    uuid: "520a50e3-4f6f-447f-a41b-dd1ceb320726",
    iconSet: "Ionicons",
    iconName: "document-attach-sharp"
  },
  {
    uuid: "00ef8811-30c4-4f55-8098-f978f0ac092a",
    iconSet: "Ionicons",
    iconName: "document-outline"
  },
  {
    uuid: "4797af7a-5252-4eca-8131-dc268cb480ac",
    iconSet: "Ionicons",
    iconName: "document-sharp"
  },
  {
    uuid: "629c35bf-2be8-4127-9cfd-0e91e40ec78d",
    iconSet: "Ionicons",
    iconName: "document-text"
  },
  {
    uuid: "bb46876e-2374-4d25-a75d-5b50d179ecac",
    iconSet: "Ionicons",
    iconName: "document-text-outline"
  },
  {
    uuid: "c4a792b5-1127-4ad0-8e13-a5418c783ad9",
    iconSet: "Ionicons",
    iconName: "document-text-sharp"
  },
  {
    uuid: "eb910d09-95a2-4580-9794-1f4ba4c6da62",
    iconSet: "Ionicons",
    iconName: "documents"
  },
  {
    uuid: "04ccce4d-61c2-4220-8155-bf44ef8375ad",
    iconSet: "Ionicons",
    iconName: "documents-outline"
  },
  {
    uuid: "00da393f-e85a-4be8-904e-e60cf8e69859",
    iconSet: "Ionicons",
    iconName: "documents-sharp"
  },
  {
    uuid: "31e112ee-74ba-41e6-92fa-d9f50d6a8d32",
    iconSet: "Ionicons",
    iconName: "download"
  },
  {
    uuid: "a8bbd32c-5f6e-4844-b043-42985385fd77",
    iconSet: "Ionicons",
    iconName: "download-outline"
  },
  {
    uuid: "4b6ddd3e-4958-441f-9ab2-569574fc97fd",
    iconSet: "Ionicons",
    iconName: "download-sharp"
  },
  {
    uuid: "a1d2cfd1-54c2-4908-8951-2a98d320f32d",
    iconSet: "Ionicons",
    iconName: "duplicate"
  },
  {
    uuid: "cb44ffa6-b4b0-4cf3-a476-e9536de3f32a",
    iconSet: "Ionicons",
    iconName: "duplicate-outline"
  },
  {
    uuid: "544b57b2-15e7-4bd4-afe1-f946048fcfcb",
    iconSet: "Ionicons",
    iconName: "duplicate-sharp"
  },
  {
    uuid: "b40548f5-73e7-4560-9b26-11022c169f8b",
    iconSet: "Ionicons",
    iconName: "ear"
  },
  {
    uuid: "43917e01-7eec-43dc-8079-c8758dd625f5",
    iconSet: "Ionicons",
    iconName: "ear-outline"
  },
  {
    uuid: "31043ebf-b316-4978-a4f7-e252a3ecb03c",
    iconSet: "Ionicons",
    iconName: "ear-sharp"
  },
  {
    uuid: "0b3ce830-71bd-44aa-8c78-700e8c13da52",
    iconSet: "Ionicons",
    iconName: "earth"
  },
  {
    uuid: "f4d903c0-50f3-47d9-b126-d4974145d73f",
    iconSet: "Ionicons",
    iconName: "earth-outline"
  },
  {
    uuid: "b657ff50-45d6-4004-a95a-d46d35660b66",
    iconSet: "Ionicons",
    iconName: "earth-sharp"
  },
  {
    uuid: "40ec0101-7d2b-49ce-8b57-543e482a048d",
    iconSet: "Ionicons",
    iconName: "easel"
  },
  {
    uuid: "d6a008f5-86de-4f0c-814d-4098fb2cdc4c",
    iconSet: "Ionicons",
    iconName: "easel-outline"
  },
  {
    uuid: "031cb702-14d1-451f-af6c-e3a385cb27d6",
    iconSet: "Ionicons",
    iconName: "easel-sharp"
  },
  {
    uuid: "65f99acc-c0db-441a-9d4b-c35455dcaef9",
    iconSet: "Ionicons",
    iconName: "egg"
  },
  {
    uuid: "22994808-17d7-46f4-befd-b7ada6a9e007",
    iconSet: "Ionicons",
    iconName: "egg-outline"
  },
  {
    uuid: "33cf1629-a617-4c00-91e9-1ad1348adf02",
    iconSet: "Ionicons",
    iconName: "egg-sharp"
  },
  {
    uuid: "4f1a666e-fcca-4618-a855-2fd94733cdfa",
    iconSet: "Ionicons",
    iconName: "ellipse"
  },
  {
    uuid: "fba186c6-6df9-4bc4-8a57-f38b33761fd5",
    iconSet: "Ionicons",
    iconName: "ellipse-outline"
  },
  {
    uuid: "264e5a8a-f06b-42bf-b561-4436c6caa698",
    iconSet: "Ionicons",
    iconName: "ellipse-sharp"
  },
  {
    uuid: "5d66eb3a-4dfd-4384-9ed7-379ac508cb0c",
    iconSet: "Ionicons",
    iconName: "ellipsis-horizontal"
  },
  {
    uuid: "647c0880-a651-4201-a436-24e902be707e",
    iconSet: "Ionicons",
    iconName: "ellipsis-horizontal-circle"
  },
  {
    uuid: "5d6f98f4-720e-4678-86a4-e0e0f879def8",
    iconSet: "Ionicons",
    iconName: "ellipsis-horizontal-circle-outline"
  },
  {
    uuid: "ca84923f-4d8d-4bd4-b897-e551d0a8670b",
    iconSet: "Ionicons",
    iconName: "ellipsis-horizontal-circle-sharp"
  },
  {
    uuid: "6c6d6bd2-5a3b-40bc-9bee-d0736e0b5211",
    iconSet: "Ionicons",
    iconName: "ellipsis-horizontal-outline"
  },
  {
    uuid: "35045c1e-ff50-46dc-90e7-43fb89cd0895",
    iconSet: "Ionicons",
    iconName: "ellipsis-horizontal-sharp"
  },
  {
    uuid: "2fb8a9ab-df58-4f6e-9ed1-baefed997864",
    iconSet: "Ionicons",
    iconName: "ellipsis-vertical"
  },
  {
    uuid: "151d424d-19bb-4ab3-98ab-901189d2e53a",
    iconSet: "Ionicons",
    iconName: "ellipsis-vertical-circle"
  },
  {
    uuid: "5d6cdfae-4d87-413d-8ae0-f2eca9eb80aa",
    iconSet: "Ionicons",
    iconName: "ellipsis-vertical-circle-outline"
  },
  {
    uuid: "e7b154d8-f35d-484c-bb68-ebd2cabf67f4",
    iconSet: "Ionicons",
    iconName: "ellipsis-vertical-circle-sharp"
  },
  {
    uuid: "14fa52c1-8a64-4f26-8d85-379c3bac090d",
    iconSet: "Ionicons",
    iconName: "ellipsis-vertical-outline"
  },
  {
    uuid: "098d80d7-4668-4085-a34a-73b5b7fd7533",
    iconSet: "Ionicons",
    iconName: "ellipsis-vertical-sharp"
  },
  {
    uuid: "4c6d1310-7821-4375-a237-e6553b6c47d1",
    iconSet: "Ionicons",
    iconName: "enter"
  },
  {
    uuid: "7414197c-a90e-4c80-87f0-6df2c61cd60f",
    iconSet: "Ionicons",
    iconName: "enter-outline"
  },
  {
    uuid: "49a09bcd-3ad2-4eed-97a9-710fec060922",
    iconSet: "Ionicons",
    iconName: "enter-sharp"
  },
  {
    uuid: "1ab3775d-c6d3-4d26-a3ce-0418d840a4b5",
    iconSet: "Ionicons",
    iconName: "exit"
  },
  {
    uuid: "7b8a159a-ab1c-4744-af68-98aecfa8d9cc",
    iconSet: "Ionicons",
    iconName: "exit-outline"
  },
  {
    uuid: "aefa3ba1-7ba5-4e73-9c85-22f6fc906a8b",
    iconSet: "Ionicons",
    iconName: "exit-sharp"
  },
  {
    uuid: "98511b11-112c-4ebb-99c4-b5e1c11d40a9",
    iconSet: "Ionicons",
    iconName: "expand"
  },
  {
    uuid: "ce354fb9-ef49-489e-b8d4-9a67a1ccfe80",
    iconSet: "Ionicons",
    iconName: "expand-outline"
  },
  {
    uuid: "32094092-fdc4-4b57-89d8-4c5bb68934f7",
    iconSet: "Ionicons",
    iconName: "expand-sharp"
  },
  {
    uuid: "cf7170dc-3401-4588-a368-6c9506c8aeb8",
    iconSet: "Ionicons",
    iconName: "eye"
  },
  {
    uuid: "ea62152a-8ee3-444e-83b2-236b5c49f9db",
    iconSet: "Ionicons",
    iconName: "eye-off"
  },
  {
    uuid: "0cc8f94d-d712-48c0-b4ca-bcc156dbcbb7",
    iconSet: "Ionicons",
    iconName: "eye-off-outline"
  },
  {
    uuid: "93305ead-bb34-4cbf-be2e-e027bb5fa23f",
    iconSet: "Ionicons",
    iconName: "eye-off-sharp"
  },
  {
    uuid: "47c7c1e6-0762-4c09-86f2-a8f508b67f18",
    iconSet: "Ionicons",
    iconName: "eye-outline"
  },
  {
    uuid: "b661700d-5bcf-4c19-9045-e32bd6272ab6",
    iconSet: "Ionicons",
    iconName: "eye-sharp"
  },
  {
    uuid: "441bb869-eff6-4d1a-8937-80b6b08952a1",
    iconSet: "Ionicons",
    iconName: "eyedrop"
  },
  {
    uuid: "bc9b0249-f630-438e-aac5-47c1ef9d895f",
    iconSet: "Ionicons",
    iconName: "eyedrop-outline"
  },
  {
    uuid: "e97fda09-4735-4e76-8907-290893d57210",
    iconSet: "Ionicons",
    iconName: "eyedrop-sharp"
  },
  {
    uuid: "8063e858-c5e6-4d1a-a33d-770d605623d4",
    iconSet: "Ionicons",
    iconName: "fast-food"
  },
  {
    uuid: "fc382e2d-942a-4f05-8a5c-3174f63cae6e",
    iconSet: "Ionicons",
    iconName: "fast-food-outline"
  },
  {
    uuid: "1dbd2ae1-faa9-4062-bf99-f9c795c66782",
    iconSet: "Ionicons",
    iconName: "fast-food-sharp"
  },
  {
    uuid: "2abe67ee-2380-4791-afe2-16ac7a4a44c5",
    iconSet: "Ionicons",
    iconName: "female"
  },
  {
    uuid: "c21c5f26-4655-4b34-9353-bb945b97d9e9",
    iconSet: "Ionicons",
    iconName: "female-outline"
  },
  {
    uuid: "40e839d3-360b-4dd9-91f6-9e2830ba5586",
    iconSet: "Ionicons",
    iconName: "female-sharp"
  },
  {
    uuid: "dd31a22f-ec0d-4662-9736-9bb5daf9db7a",
    iconSet: "Ionicons",
    iconName: "file-tray"
  },
  {
    uuid: "bbf97c2b-7fe3-4900-af82-69c9b8aa822e",
    iconSet: "Ionicons",
    iconName: "file-tray-full"
  },
  {
    uuid: "2d0e5abf-6d35-4d0d-9239-911387ff9516",
    iconSet: "Ionicons",
    iconName: "file-tray-full-outline"
  },
  {
    uuid: "2335f2db-148b-46cf-9f76-674b42081be2",
    iconSet: "Ionicons",
    iconName: "file-tray-full-sharp"
  },
  {
    uuid: "f5423b48-9c57-4722-9da0-2b81a951d967",
    iconSet: "Ionicons",
    iconName: "file-tray-outline"
  },
  {
    uuid: "fe8bba95-50e1-4397-8ecc-2a3573cdedd8",
    iconSet: "Ionicons",
    iconName: "file-tray-sharp"
  },
  {
    uuid: "76d2355a-efb2-4396-8baf-d084d2dc977a",
    iconSet: "Ionicons",
    iconName: "file-tray-stacked"
  },
  {
    uuid: "ae267ca5-64c7-4c63-b4d1-70e459b55223",
    iconSet: "Ionicons",
    iconName: "file-tray-stacked-outline"
  },
  {
    uuid: "f043d6e1-76b9-4328-8a1a-3d1daa7dbbc9",
    iconSet: "Ionicons",
    iconName: "file-tray-stacked-sharp"
  },
  {
    uuid: "fa5bf6ac-ed7a-4fd6-983c-61e3bdd26ed1",
    iconSet: "Ionicons",
    iconName: "film"
  },
  {
    uuid: "aef338d1-41d7-42e1-bcc2-3db2b8a24777",
    iconSet: "Ionicons",
    iconName: "film-outline"
  },
  {
    uuid: "c8f837fc-04b3-45a6-9da2-1cf06e877ca0",
    iconSet: "Ionicons",
    iconName: "film-sharp"
  },
  {
    uuid: "cf8121e5-b4bf-4b32-96cb-fd059c1bd913",
    iconSet: "Ionicons",
    iconName: "filter"
  },
  {
    uuid: "146cadc1-ca9e-4c65-bf45-9f697869d790",
    iconSet: "Ionicons",
    iconName: "filter-outline"
  },
  {
    uuid: "5db3ffc8-7ea8-4ec7-9363-ed7bfe5dfa9f",
    iconSet: "Ionicons",
    iconName: "filter-sharp"
  },
  {
    uuid: "53bb8063-8a95-4d98-8c9b-07a1edef12a5",
    iconSet: "Ionicons",
    iconName: "finger-print"
  },
  {
    uuid: "1be9fa41-4c00-4d9a-bd81-bb3dc84c0ad8",
    iconSet: "Ionicons",
    iconName: "finger-print-outline"
  },
  {
    uuid: "7d3383f6-5b9a-4b78-8790-d7f281bb07fe",
    iconSet: "Ionicons",
    iconName: "finger-print-sharp"
  },
  {
    uuid: "cbd9ba48-bfa5-4c79-80ce-4d15d39ccd23",
    iconSet: "Ionicons",
    iconName: "fitness"
  },
  {
    uuid: "c705dded-f577-4149-b6b1-30ec5af4ade8",
    iconSet: "Ionicons",
    iconName: "fitness-outline"
  },
  {
    uuid: "71a4c15f-7823-4038-98bc-34a855990c98",
    iconSet: "Ionicons",
    iconName: "fitness-sharp"
  },
  {
    uuid: "d3adfed4-37e4-4c12-b36d-d4efb3506e6a",
    iconSet: "Ionicons",
    iconName: "flag"
  },
  {
    uuid: "a7e348d8-9855-4518-af97-ea72c30f057d",
    iconSet: "Ionicons",
    iconName: "flag-outline"
  },
  {
    uuid: "a4781c96-f293-43ce-8dcf-77aa502f92fb",
    iconSet: "Ionicons",
    iconName: "flag-sharp"
  },
  {
    uuid: "e3d6a18f-53d9-47a2-bf90-3203d277e493",
    iconSet: "Ionicons",
    iconName: "flame"
  },
  {
    uuid: "b1845da0-b7e4-4f9e-9c54-036e69e78ac4",
    iconSet: "Ionicons",
    iconName: "flame-outline"
  },
  {
    uuid: "c8793692-df7e-4d53-a831-3aa5526871ba",
    iconSet: "Ionicons",
    iconName: "flame-sharp"
  },
  {
    uuid: "e7651078-5a87-4a1b-aa98-a5a7acd63a98",
    iconSet: "Ionicons",
    iconName: "flash"
  },
  {
    uuid: "e4bda9c2-debd-45e7-817d-23fd31815f1a",
    iconSet: "Ionicons",
    iconName: "flash-off"
  },
  {
    uuid: "fdff7b5e-207a-4495-b76b-73a02a1d0838",
    iconSet: "Ionicons",
    iconName: "flash-off-outline"
  },
  {
    uuid: "b379b843-a4bb-4749-8581-478aae7315dc",
    iconSet: "Ionicons",
    iconName: "flash-off-sharp"
  },
  {
    uuid: "b6d28139-8348-4aab-ab5d-775a046c8f20",
    iconSet: "Ionicons",
    iconName: "flash-outline"
  },
  {
    uuid: "d78f0278-ce21-4f22-8496-afee34ac3091",
    iconSet: "Ionicons",
    iconName: "flash-sharp"
  },
  {
    uuid: "feefbdb4-00e7-400a-a010-c3f4eacb4de2",
    iconSet: "Ionicons",
    iconName: "flashlight"
  },
  {
    uuid: "01e7247d-f3c1-4a58-b9db-af8f1a702b0f",
    iconSet: "Ionicons",
    iconName: "flashlight-outline"
  },
  {
    uuid: "5d2e315b-1faa-4a80-8217-28e53ea897b7",
    iconSet: "Ionicons",
    iconName: "flashlight-sharp"
  },
  {
    uuid: "4b3ad959-5b50-4594-ab36-b51f0ba42e1d",
    iconSet: "Ionicons",
    iconName: "flask"
  },
  {
    uuid: "e8fd7696-421c-422f-ab8c-304312807d21",
    iconSet: "Ionicons",
    iconName: "flask-outline"
  },
  {
    uuid: "b14d1cff-4dec-4727-b16e-8ec651a7b125",
    iconSet: "Ionicons",
    iconName: "flask-sharp"
  },
  {
    uuid: "ac45641a-c9dd-4c81-afe5-9d94f3bdc5de",
    iconSet: "Ionicons",
    iconName: "flower"
  },
  {
    uuid: "1aa86aa2-a5a9-40ee-ab99-9ec54b0f73f3",
    iconSet: "Ionicons",
    iconName: "flower-outline"
  },
  {
    uuid: "d7510d0e-05d5-4afd-8c78-e0b3e03fc088",
    iconSet: "Ionicons",
    iconName: "flower-sharp"
  },
  {
    uuid: "3c4825a2-3a06-48c4-b5e5-aaeee49833c2",
    iconSet: "Ionicons",
    iconName: "folder"
  },
  {
    uuid: "29f5a7d9-52e9-47ff-8f0c-46277b04a8ad",
    iconSet: "Ionicons",
    iconName: "folder-open"
  },
  {
    uuid: "606ca971-2219-441b-afdf-59d8793cec9c",
    iconSet: "Ionicons",
    iconName: "folder-open-outline"
  },
  {
    uuid: "85fb9a0f-7681-4ab0-a631-19f816b70d22",
    iconSet: "Ionicons",
    iconName: "folder-open-sharp"
  },
  {
    uuid: "fc311bc2-1a8e-4d7b-915e-9833cab7d1a3",
    iconSet: "Ionicons",
    iconName: "folder-outline"
  },
  {
    uuid: "c9e39b78-e666-4788-a20c-b67730c2c3d6",
    iconSet: "Ionicons",
    iconName: "folder-sharp"
  },
  {
    uuid: "8ffe6bf3-0cfc-44f8-86b1-57c997f2d316",
    iconSet: "Ionicons",
    iconName: "football"
  },
  {
    uuid: "51d37fb8-293f-4fd1-baca-a887114b0bda",
    iconSet: "Ionicons",
    iconName: "football-outline"
  },
  {
    uuid: "b4c602b6-0f91-4c22-a877-f513210df6e2",
    iconSet: "Ionicons",
    iconName: "football-sharp"
  },
  {
    uuid: "3ed19f58-872c-44e9-aa6b-84a6a999d707",
    iconSet: "Ionicons",
    iconName: "funnel"
  },
  {
    uuid: "39399bf8-a4c9-4271-ba9a-fd600d16988a",
    iconSet: "Ionicons",
    iconName: "funnel-outline"
  },
  {
    uuid: "b209b1f3-8bb2-4084-a465-e927ae852d8a",
    iconSet: "Ionicons",
    iconName: "funnel-sharp"
  },
  {
    uuid: "56abd483-c1b0-4063-a493-320f1f002eba",
    iconSet: "Ionicons",
    iconName: "game-controller"
  },
  {
    uuid: "ed3dd436-cdb1-48fc-ab1e-575997c371e3",
    iconSet: "Ionicons",
    iconName: "game-controller-outline"
  },
  {
    uuid: "a411197a-f6ee-4ebc-aa15-9f384d83f94a",
    iconSet: "Ionicons",
    iconName: "game-controller-sharp"
  },
  {
    uuid: "e4b9dd15-ff4b-4d82-83b5-f3ce4ded5ebd",
    iconSet: "Ionicons",
    iconName: "gift"
  },
  {
    uuid: "e0d87cde-d628-449a-9d13-bc0d894d5290",
    iconSet: "Ionicons",
    iconName: "gift-outline"
  },
  {
    uuid: "f7322a3d-f607-447e-8b12-bbf76ce916fe",
    iconSet: "Ionicons",
    iconName: "gift-sharp"
  },
  {
    uuid: "47296557-e737-4cf9-b033-7f38a2661cd4",
    iconSet: "Ionicons",
    iconName: "git-branch"
  },
  {
    uuid: "399d64b0-40f6-4a85-9bcd-f4196a97dfb0",
    iconSet: "Ionicons",
    iconName: "git-branch-outline"
  },
  {
    uuid: "19a665f4-bc19-4c62-9853-3c00ef6384ea",
    iconSet: "Ionicons",
    iconName: "git-branch-sharp"
  },
  {
    uuid: "be3923d2-e7dc-4a9e-b52c-4a9021b6a6f5",
    iconSet: "Ionicons",
    iconName: "git-commit"
  },
  {
    uuid: "cbf4b73d-9303-4da3-89c7-3d4f53aabfbb",
    iconSet: "Ionicons",
    iconName: "git-commit-outline"
  },
  {
    uuid: "4d7e9672-ffa7-4040-90f0-3a84fdd79235",
    iconSet: "Ionicons",
    iconName: "git-commit-sharp"
  },
  {
    uuid: "0bfc3030-7d56-4c30-b80c-2607dc84bc8c",
    iconSet: "Ionicons",
    iconName: "git-compare"
  },
  {
    uuid: "853df09c-4a27-4511-88d6-ddc351731cd6",
    iconSet: "Ionicons",
    iconName: "git-compare-outline"
  },
  {
    uuid: "28a07b1a-2f20-44dc-9389-3833707f2164",
    iconSet: "Ionicons",
    iconName: "git-compare-sharp"
  },
  {
    uuid: "f2b1dd9d-6584-4735-b812-db6d7d875520",
    iconSet: "Ionicons",
    iconName: "git-merge"
  },
  {
    uuid: "a794f406-a5bd-41ab-93c4-aaea346c48e4",
    iconSet: "Ionicons",
    iconName: "git-merge-outline"
  },
  {
    uuid: "88485df6-5971-467b-bc63-821aea7b7e0d",
    iconSet: "Ionicons",
    iconName: "git-merge-sharp"
  },
  {
    uuid: "239ecc0e-c97c-48ef-af02-52ee1d2af311",
    iconSet: "Ionicons",
    iconName: "git-network"
  },
  {
    uuid: "abd3371b-5765-461b-acb1-19744519875e",
    iconSet: "Ionicons",
    iconName: "git-network-outline"
  },
  {
    uuid: "ccb77798-34e5-4d24-87f3-953034964450",
    iconSet: "Ionicons",
    iconName: "git-network-sharp"
  },
  {
    uuid: "708146ef-194d-40f2-8b2a-f5886d4aaf83",
    iconSet: "Ionicons",
    iconName: "git-pull-request"
  },
  {
    uuid: "d6c5ff82-90b4-4a41-8786-6e6e5265eba0",
    iconSet: "Ionicons",
    iconName: "git-pull-request-outline"
  },
  {
    uuid: "d8c31887-378d-4c5d-a475-29eaa9e923d8",
    iconSet: "Ionicons",
    iconName: "git-pull-request-sharp"
  },
  {
    uuid: "eced8b6a-94ec-4c90-94b3-98bcfd9565be",
    iconSet: "Ionicons",
    iconName: "glasses"
  },
  {
    uuid: "5d3a7ca1-82fa-4246-ae51-4f66725ee5fa",
    iconSet: "Ionicons",
    iconName: "glasses-outline"
  },
  {
    uuid: "e0428005-89d0-4933-9079-906480fa8259",
    iconSet: "Ionicons",
    iconName: "glasses-sharp"
  },
  {
    uuid: "ab6f49b8-27af-45a3-9c02-a07f0a0a83c5",
    iconSet: "Ionicons",
    iconName: "globe"
  },
  {
    uuid: "9481ed24-b289-48c2-a1f6-52da24853a1f",
    iconSet: "Ionicons",
    iconName: "globe-outline"
  },
  {
    uuid: "92a35f48-bc00-4612-9780-b2cd690795a6",
    iconSet: "Ionicons",
    iconName: "globe-sharp"
  },
  {
    uuid: "29646597-242d-40e1-8060-383234667ce4",
    iconSet: "Ionicons",
    iconName: "golf"
  },
  {
    uuid: "671985cb-6fb1-4b0a-8d2f-995309c2b564",
    iconSet: "Ionicons",
    iconName: "golf-outline"
  },
  {
    uuid: "47e4e0f2-d9e0-4191-a32f-94d564c18c8f",
    iconSet: "Ionicons",
    iconName: "golf-sharp"
  },
  {
    uuid: "cc6b6ae4-92f8-4e0a-81a7-044973b3909c",
    iconSet: "Ionicons",
    iconName: "grid"
  },
  {
    uuid: "24631974-db14-4fb4-a85f-d88d5a84d309",
    iconSet: "Ionicons",
    iconName: "grid-outline"
  },
  {
    uuid: "748931ac-6a41-4b73-b183-7126730a630e",
    iconSet: "Ionicons",
    iconName: "grid-sharp"
  },
  {
    uuid: "a333a877-bb7f-4b01-9eb1-a59cfd49e43f",
    iconSet: "Ionicons",
    iconName: "hammer"
  },
  {
    uuid: "6cca8f22-9654-42b6-ae18-9b79e977ce25",
    iconSet: "Ionicons",
    iconName: "hammer-outline"
  },
  {
    uuid: "cc1a4139-4e6a-4feb-807d-2135b2e9c1c7",
    iconSet: "Ionicons",
    iconName: "hammer-sharp"
  },
  {
    uuid: "b4a5cc79-505f-421c-893a-96146607aa6f",
    iconSet: "Ionicons",
    iconName: "hand-left"
  },
  {
    uuid: "00ba2469-5130-47db-bd16-cdbfacf3219d",
    iconSet: "Ionicons",
    iconName: "hand-left-outline"
  },
  {
    uuid: "7a172639-5a2c-4058-807a-09acda5d04f1",
    iconSet: "Ionicons",
    iconName: "hand-left-sharp"
  },
  {
    uuid: "3a34f346-d141-4361-9d99-aba7f1c30911",
    iconSet: "Ionicons",
    iconName: "hand-right"
  },
  {
    uuid: "d31b1506-ca25-4f45-8e62-1579a0c75161",
    iconSet: "Ionicons",
    iconName: "hand-right-outline"
  },
  {
    uuid: "197bfad4-c2e6-4b07-ac2b-4522df17b618",
    iconSet: "Ionicons",
    iconName: "hand-right-sharp"
  },
  {
    uuid: "9ef968b0-1a25-4b9b-b92d-bd7baaffca09",
    iconSet: "Ionicons",
    iconName: "happy"
  },
  {
    uuid: "e89894a5-294f-4685-a63c-90e32f9215af",
    iconSet: "Ionicons",
    iconName: "happy-outline"
  },
  {
    uuid: "9857d2c5-e6c0-4a08-9a12-7b7ff7ee5616",
    iconSet: "Ionicons",
    iconName: "happy-sharp"
  },
  {
    uuid: "8988b3c9-9264-4f4f-b23a-7190e1360464",
    iconSet: "Ionicons",
    iconName: "hardware-chip"
  },
  {
    uuid: "9e5c669c-241b-4f4d-b53b-1f984fd87dcb",
    iconSet: "Ionicons",
    iconName: "hardware-chip-outline"
  },
  {
    uuid: "a14b43a2-713b-4b44-a01e-24c7eea00d73",
    iconSet: "Ionicons",
    iconName: "hardware-chip-sharp"
  },
  {
    uuid: "e898e549-5849-41c6-9312-0b2969dc79aa",
    iconSet: "Ionicons",
    iconName: "headset"
  },
  {
    uuid: "a56e5a0b-935a-47ae-a714-dfcf0c56cc7d",
    iconSet: "Ionicons",
    iconName: "headset-outline"
  },
  {
    uuid: "1c7f49d7-b5be-4ac6-b712-83b433cd671b",
    iconSet: "Ionicons",
    iconName: "headset-sharp"
  },
  {
    uuid: "fc2e7665-e10a-4a65-b7f0-e68412228a04",
    iconSet: "Ionicons",
    iconName: "heart"
  },
  {
    uuid: "59b1ad5d-123a-4317-8baa-a9f7e4fa20ac",
    iconSet: "Ionicons",
    iconName: "heart-circle"
  },
  {
    uuid: "86649696-5b37-4cff-8b60-4daf686019cf",
    iconSet: "Ionicons",
    iconName: "heart-circle-outline"
  },
  {
    uuid: "a0fef8a5-d0f7-4449-bbc2-302bb98072b2",
    iconSet: "Ionicons",
    iconName: "heart-circle-sharp"
  },
  {
    uuid: "7fc640c5-02d1-410c-ad89-5bf5f2c9a51b",
    iconSet: "Ionicons",
    iconName: "heart-dislike"
  },
  {
    uuid: "f0e2d158-5e53-413a-bf0c-4f3491b02d78",
    iconSet: "Ionicons",
    iconName: "heart-dislike-circle"
  },
  {
    uuid: "c3007788-48a7-4331-9ac9-55185f61323f",
    iconSet: "Ionicons",
    iconName: "heart-dislike-circle-outline"
  },
  {
    uuid: "2cb28490-87e9-4d66-b980-3783f6db340d",
    iconSet: "Ionicons",
    iconName: "heart-dislike-circle-sharp"
  },
  {
    uuid: "d47ac383-2602-40dc-8743-c27c5274e9f2",
    iconSet: "Ionicons",
    iconName: "heart-dislike-outline"
  },
  {
    uuid: "1d8efc76-684c-43eb-bcf5-eb05b1cd1605",
    iconSet: "Ionicons",
    iconName: "heart-dislike-sharp"
  },
  {
    uuid: "05ad4d00-1766-4f84-9d8a-5b1fc0f63081",
    iconSet: "Ionicons",
    iconName: "heart-half"
  },
  {
    uuid: "6e9bec1f-53d4-4ae7-81fb-6118a17eff61",
    iconSet: "Ionicons",
    iconName: "heart-half-outline"
  },
  {
    uuid: "32f72684-bc83-4911-b375-a3c7885346fc",
    iconSet: "Ionicons",
    iconName: "heart-half-sharp"
  },
  {
    uuid: "27f7eb60-e3a7-488a-9cff-00736a0527fa",
    iconSet: "Ionicons",
    iconName: "heart-outline"
  },
  {
    uuid: "0df72191-aa7d-4477-884c-147cdecd5b13",
    iconSet: "Ionicons",
    iconName: "heart-sharp"
  },
  {
    uuid: "2fcb4ba4-3327-46dd-942b-a67288fba31b",
    iconSet: "Ionicons",
    iconName: "help"
  },
  {
    uuid: "e618881a-5f99-4dde-8d5a-d4136a346413",
    iconSet: "Ionicons",
    iconName: "help-buoy"
  },
  {
    uuid: "bf83a2c3-94f0-4d6b-82fe-9131e266179b",
    iconSet: "Ionicons",
    iconName: "help-buoy-outline"
  },
  {
    uuid: "e6757a2b-12d4-424f-b79a-46a90b18a59d",
    iconSet: "Ionicons",
    iconName: "help-buoy-sharp"
  },
  {
    uuid: "266e58fa-b23b-4f61-a9a3-b8558056af50",
    iconSet: "Ionicons",
    iconName: "help-circle"
  },
  {
    uuid: "72e9fe29-5347-41c2-a1f4-4cad02b7bf21",
    iconSet: "Ionicons",
    iconName: "help-circle-outline"
  },
  {
    uuid: "e1b6985e-6fcf-49f6-9532-34c82107b7ab",
    iconSet: "Ionicons",
    iconName: "help-circle-sharp"
  },
  {
    uuid: "ed9067a4-3da0-420a-bc1e-69449483b2b2",
    iconSet: "Ionicons",
    iconName: "help-outline"
  },
  {
    uuid: "23075881-696c-46f6-8b99-b5bd9fda48da",
    iconSet: "Ionicons",
    iconName: "help-sharp"
  },
  {
    uuid: "b8521cd0-4549-4c6c-b0a0-c39fea69c502",
    iconSet: "Ionicons",
    iconName: "home"
  },
  {
    uuid: "8d439026-bbc1-4b1d-8c3f-4e373f8b2372",
    iconSet: "Ionicons",
    iconName: "home-outline"
  },
  {
    uuid: "41791d28-e518-4070-a1ad-e92e4e70f7ea",
    iconSet: "Ionicons",
    iconName: "home-sharp"
  },
  {
    uuid: "d4800f4e-71d7-4730-92b6-177e63400e0d",
    iconSet: "Ionicons",
    iconName: "hourglass"
  },
  {
    uuid: "d97b376a-3b99-43c1-93f1-6a069c0e511e",
    iconSet: "Ionicons",
    iconName: "hourglass-outline"
  },
  {
    uuid: "e5f4481e-a4b1-4453-ba67-111d65d189ca",
    iconSet: "Ionicons",
    iconName: "hourglass-sharp"
  },
  {
    uuid: "f4330b6f-cd4b-48f4-a657-f7e337e22be3",
    iconSet: "Ionicons",
    iconName: "ice-cream"
  },
  {
    uuid: "50869533-3186-4ba4-9f10-2cbb9e6ba4ad",
    iconSet: "Ionicons",
    iconName: "ice-cream-outline"
  },
  {
    uuid: "1f259476-c9c0-454f-8914-cd41846c4f2e",
    iconSet: "Ionicons",
    iconName: "ice-cream-sharp"
  },
  {
    uuid: "6c54c187-acf9-4024-a4ad-e24193071d54",
    iconSet: "Ionicons",
    iconName: "image"
  },
  {
    uuid: "bee58b89-4a77-493b-b51a-529e5f7727b8",
    iconSet: "Ionicons",
    iconName: "image-outline"
  },
  {
    uuid: "63d342a8-8276-42f8-8a22-33d79336f2c0",
    iconSet: "Ionicons",
    iconName: "image-sharp"
  },
  {
    uuid: "354e92f7-e29c-41c6-b021-55d8c78c22e4",
    iconSet: "Ionicons",
    iconName: "images"
  },
  {
    uuid: "62518fc7-ba70-4f86-bd33-5f81c89b5f49",
    iconSet: "Ionicons",
    iconName: "images-outline"
  },
  {
    uuid: "4f6296f3-4070-4e18-9f69-b36f016dc63f",
    iconSet: "Ionicons",
    iconName: "images-sharp"
  },
  {
    uuid: "528425b4-3dad-4412-b191-d700e72d2dea",
    iconSet: "Ionicons",
    iconName: "infinite"
  },
  {
    uuid: "43b4086a-6f5b-4d38-bc8f-7923fae4c3b5",
    iconSet: "Ionicons",
    iconName: "infinite-outline"
  },
  {
    uuid: "9b083574-3a1e-480e-8c2f-d1310366d997",
    iconSet: "Ionicons",
    iconName: "infinite-sharp"
  },
  {
    uuid: "6ec98373-2a48-4418-8e35-4c6bcdf59d4c",
    iconSet: "Ionicons",
    iconName: "information"
  },
  {
    uuid: "e6eb802f-b0ed-42db-ab56-b608aad63e45",
    iconSet: "Ionicons",
    iconName: "information-circle"
  },
  {
    uuid: "0114e68e-abc3-4168-b99c-da0c57028f92",
    iconSet: "Ionicons",
    iconName: "information-circle-outline"
  },
  {
    uuid: "9989dc9b-b5a5-498d-9af7-36b107dd86ba",
    iconSet: "Ionicons",
    iconName: "information-circle-sharp"
  },
  {
    uuid: "8e61a8b9-b078-4f13-9453-57263e347f8c",
    iconSet: "Ionicons",
    iconName: "information-outline"
  },
  {
    uuid: "5ded0143-5ed2-4166-9ae4-1c2ca2b5864d",
    iconSet: "Ionicons",
    iconName: "information-sharp"
  },
  {
    uuid: "eca89462-5022-4993-9cf6-501dfc62d865",
    iconSet: "Ionicons",
    iconName: "journal"
  },
  {
    uuid: "a7c92e41-1685-441d-b55a-3a93f0b4ad14",
    iconSet: "Ionicons",
    iconName: "journal-outline"
  },
  {
    uuid: "f5472347-bb10-40b0-8e56-74850d81f9b0",
    iconSet: "Ionicons",
    iconName: "journal-sharp"
  },
  {
    uuid: "760726f1-e434-4da0-8732-de96a01696b8",
    iconSet: "Ionicons",
    iconName: "key"
  },
  {
    uuid: "8e4e505d-bcc9-445d-a842-c2a792aca31e",
    iconSet: "Ionicons",
    iconName: "key-outline"
  },
  {
    uuid: "427dd12f-5543-4dd9-b255-4dbed9eea1a2",
    iconSet: "Ionicons",
    iconName: "key-sharp"
  },
  {
    uuid: "74eff1ea-3aa4-4b44-bef0-c2d25bd743c5",
    iconSet: "Ionicons",
    iconName: "keypad"
  },
  {
    uuid: "553c610e-8df8-47ff-a87f-2fec8fa7df54",
    iconSet: "Ionicons",
    iconName: "keypad-outline"
  },
  {
    uuid: "aa64bff9-cfa1-4b10-a6ab-a45e95c25bf3",
    iconSet: "Ionicons",
    iconName: "keypad-sharp"
  },
  {
    uuid: "67ffea62-4f76-4b87-8f8b-c6de33a7b5c0",
    iconSet: "Ionicons",
    iconName: "language"
  },
  {
    uuid: "9b1386e5-7e40-4ca3-9a69-b55fb6e5d351",
    iconSet: "Ionicons",
    iconName: "language-outline"
  },
  {
    uuid: "443709f2-7895-4057-a7d5-75c02d3120f9",
    iconSet: "Ionicons",
    iconName: "language-sharp"
  },
  {
    uuid: "3b2b3a5f-dbe0-4242-b425-d1006e9fb4e6",
    iconSet: "Ionicons",
    iconName: "laptop"
  },
  {
    uuid: "6785bd71-86bb-4d10-8f26-203afba5262c",
    iconSet: "Ionicons",
    iconName: "laptop-outline"
  },
  {
    uuid: "d1387842-d08e-4b75-b6fa-16316357d0f2",
    iconSet: "Ionicons",
    iconName: "laptop-sharp"
  },
  {
    uuid: "d94200b2-4f6f-4c88-91b1-2d8068a60a16",
    iconSet: "Ionicons",
    iconName: "layers"
  },
  {
    uuid: "d06db50f-cefd-44e0-8227-9de0aed3cf8e",
    iconSet: "Ionicons",
    iconName: "layers-outline"
  },
  {
    uuid: "f0009f86-f4ca-4070-b3ab-c2f52b71c5ab",
    iconSet: "Ionicons",
    iconName: "layers-sharp"
  },
  {
    uuid: "b7b5f5df-d12e-4502-b54e-9bf87ad0cd87",
    iconSet: "Ionicons",
    iconName: "leaf"
  },
  {
    uuid: "db3b6340-e2b9-4611-bce9-f3eee1e45f6a",
    iconSet: "Ionicons",
    iconName: "leaf-outline"
  },
  {
    uuid: "898fd85d-a6ab-46d6-946a-384bf4770dec",
    iconSet: "Ionicons",
    iconName: "leaf-sharp"
  },
  {
    uuid: "4c43b630-1804-4343-bb71-608226f51ea1",
    iconSet: "Ionicons",
    iconName: "library"
  },
  {
    uuid: "a3733148-ecc7-4baa-af32-dc388602bcd5",
    iconSet: "Ionicons",
    iconName: "library-outline"
  },
  {
    uuid: "11618f2b-f40c-4bfa-ba34-f11af7489af4",
    iconSet: "Ionicons",
    iconName: "library-sharp"
  },
  {
    uuid: "369d3258-acd3-4c82-9a67-55e539e39d60",
    iconSet: "Ionicons",
    iconName: "link"
  },
  {
    uuid: "9e488505-be11-40ee-9a85-204e5df4828d",
    iconSet: "Ionicons",
    iconName: "link-outline"
  },
  {
    uuid: "45ee7d23-f11a-4ee0-b844-433410ae3fcb",
    iconSet: "Ionicons",
    iconName: "link-sharp"
  },
  {
    uuid: "2fcc656f-121e-47de-81ae-5e7cf7ebc91b",
    iconSet: "Ionicons",
    iconName: "list"
  },
  {
    uuid: "9221db46-d0bb-482d-ab20-c82a69cb06fe",
    iconSet: "Ionicons",
    iconName: "list-circle"
  },
  {
    uuid: "432f50a8-756a-4c13-9c42-af88db822a16",
    iconSet: "Ionicons",
    iconName: "list-circle-outline"
  },
  {
    uuid: "0a655c7d-8150-44b1-b0f2-945677419f9a",
    iconSet: "Ionicons",
    iconName: "list-circle-sharp"
  },
  {
    uuid: "93315f12-710d-42ac-8594-8b53cb0579bd",
    iconSet: "Ionicons",
    iconName: "list-outline"
  },
  {
    uuid: "5383f88f-7289-4c14-aaa8-d50c9fdbe14d",
    iconSet: "Ionicons",
    iconName: "list-sharp"
  },
  {
    uuid: "ff69e49f-614e-4b41-b450-d4f35bd39f10",
    iconSet: "Ionicons",
    iconName: "locate"
  },
  {
    uuid: "118ebb43-be76-4e3e-8d5d-c030d3d720f0",
    iconSet: "Ionicons",
    iconName: "locate-outline"
  },
  {
    uuid: "038d797d-bf62-4ffe-b8d4-39942f84a7ff",
    iconSet: "Ionicons",
    iconName: "locate-sharp"
  },
  {
    uuid: "c214af51-2f0a-4389-90c1-863f6e5b2ced",
    iconSet: "Ionicons",
    iconName: "location"
  },
  {
    uuid: "a1948114-1cce-4acd-9c1a-3369febd8f42",
    iconSet: "Ionicons",
    iconName: "location-outline"
  },
  {
    uuid: "c985d8e7-9a31-4435-a84b-d90551ce8b5f",
    iconSet: "Ionicons",
    iconName: "location-sharp"
  },
  {
    uuid: "c6c654bf-9e4c-4ccc-8f46-f976b0b10454",
    iconSet: "Ionicons",
    iconName: "lock-closed"
  },
  {
    uuid: "71eebdcd-a638-4e0b-bb37-ba85544c8b2e",
    iconSet: "Ionicons",
    iconName: "lock-closed-outline"
  },
  {
    uuid: "9ed20cfa-af92-4d35-9bb9-b31314cd0c12",
    iconSet: "Ionicons",
    iconName: "lock-closed-sharp"
  },
  {
    uuid: "691ed54f-5d1a-4961-bde5-f869d38bd46a",
    iconSet: "Ionicons",
    iconName: "lock-open"
  },
  {
    uuid: "b30c2233-da5f-4e99-b0d9-6adfcd6ba94b",
    iconSet: "Ionicons",
    iconName: "lock-open-outline"
  },
  {
    uuid: "aa27a6fd-a754-42bd-aee4-27b5846166ef",
    iconSet: "Ionicons",
    iconName: "lock-open-sharp"
  },
  {
    uuid: "38a211c2-fa92-410b-82d6-6f290cebf198",
    iconSet: "Ionicons",
    iconName: "log-in"
  },
  {
    uuid: "04b518be-9f95-4370-97ed-462d88547deb",
    iconSet: "Ionicons",
    iconName: "log-in-outline"
  },
  {
    uuid: "0b12b47a-403c-4da4-9087-00de67dd6dbd",
    iconSet: "Ionicons",
    iconName: "log-in-sharp"
  },
  {
    uuid: "5d6811bb-38af-490c-8670-6697db43a5a6",
    iconSet: "Ionicons",
    iconName: "log-out"
  },
  {
    uuid: "dbc6a4bb-cf73-46f7-8c83-dbc2dfbcaf14",
    iconSet: "Ionicons",
    iconName: "log-out-outline"
  },
  {
    uuid: "42d59005-6c13-46ff-939d-a9f8836f5ae2",
    iconSet: "Ionicons",
    iconName: "log-out-sharp"
  },
  {
    uuid: "4ac60c8b-0d61-4eac-b3e3-3fb534818214",
    iconSet: "Ionicons",
    iconName: "logo-amazon"
  },
  {
    uuid: "7b2218cf-c91a-4de9-990e-21bbfad92a59",
    iconSet: "Ionicons",
    iconName: "logo-amplify"
  },
  {
    uuid: "cbbce8f3-ca20-4802-828a-c8672821f7a9",
    iconSet: "Ionicons",
    iconName: "logo-android"
  },
  {
    uuid: "4d5ea028-5fcb-4841-a16b-7fada30a9862",
    iconSet: "Ionicons",
    iconName: "logo-angular"
  },
  {
    uuid: "ae3c1341-d225-4c22-bfa5-4a92cb952a83",
    iconSet: "Ionicons",
    iconName: "logo-apple"
  },
  {
    uuid: "6d8d3595-491e-449c-8aa4-07208fe35ea2",
    iconSet: "Ionicons",
    iconName: "logo-apple-appstore"
  },
  {
    uuid: "7d847165-3021-4ed9-b752-be955676b3a6",
    iconSet: "Ionicons",
    iconName: "logo-bitbucket"
  },
  {
    uuid: "b61e6c29-1beb-43f8-970c-b42d257c2e0f",
    iconSet: "Ionicons",
    iconName: "logo-bitcoin"
  },
  {
    uuid: "e60ce9a5-f0de-41a3-9683-102be261a91c",
    iconSet: "Ionicons",
    iconName: "logo-buffer"
  },
  {
    uuid: "f1f67c45-713f-4463-bc15-2d2a4ca89817",
    iconSet: "Ionicons",
    iconName: "logo-capacitor"
  },
  {
    uuid: "78311d2e-bce3-4f66-ad74-6c5941f36d9a",
    iconSet: "Ionicons",
    iconName: "logo-chrome"
  },
  {
    uuid: "99a76761-6c61-44e1-a677-75e182a2f949",
    iconSet: "Ionicons",
    iconName: "logo-closed-captioning"
  },
  {
    uuid: "eaf082f6-d517-468f-8e97-13e6e7c47329",
    iconSet: "Ionicons",
    iconName: "logo-codepen"
  },
  {
    uuid: "086592d1-e6b1-410c-9c54-8f45cab1c323",
    iconSet: "Ionicons",
    iconName: "logo-css3"
  },
  {
    uuid: "57f30727-8bab-4291-99be-99343efc5f78",
    iconSet: "Ionicons",
    iconName: "logo-designernews"
  },
  {
    uuid: "a9653511-3669-4a40-95cb-97e7acd247de",
    iconSet: "Ionicons",
    iconName: "logo-dribbble"
  },
  {
    uuid: "92b85650-0e4d-4629-aaa6-4742974fc45f",
    iconSet: "Ionicons",
    iconName: "logo-dropbox"
  },
  {
    uuid: "345079f4-ffb6-4a91-be53-59d31c4e1f84",
    iconSet: "Ionicons",
    iconName: "logo-edge"
  },
  {
    uuid: "cc69c38b-a3c3-4612-8148-88e5557de8f8",
    iconSet: "Ionicons",
    iconName: "logo-electron"
  },
  {
    uuid: "6e6777b0-b5b7-42c4-979f-f0c5f2880b58",
    iconSet: "Ionicons",
    iconName: "logo-euro"
  },
  {
    uuid: "a074adee-6ad4-4f55-9d23-f3ebd52c7593",
    iconSet: "Ionicons",
    iconName: "logo-facebook"
  },
  {
    uuid: "9b77ba96-98e1-48a1-b90e-c84179881bbb",
    iconSet: "Ionicons",
    iconName: "logo-firebase"
  },
  {
    uuid: "a7566c30-729f-4d02-a04f-1316a8e0c951",
    iconSet: "Ionicons",
    iconName: "logo-firefox"
  },
  {
    uuid: "b8af2f3a-aaae-47e1-9975-ac7713e39fe2",
    iconSet: "Ionicons",
    iconName: "logo-flickr"
  },
  {
    uuid: "d5df7092-8b9b-4a5e-bb73-b60f057beaa2",
    iconSet: "Ionicons",
    iconName: "logo-foursquare"
  },
  {
    uuid: "b1bf9b1a-70eb-47a0-9214-443740fbb9f8",
    iconSet: "Ionicons",
    iconName: "logo-github"
  },
  {
    uuid: "762985ce-bb84-4e70-93e4-ef2d3c53cc2c",
    iconSet: "Ionicons",
    iconName: "logo-google"
  },
  {
    uuid: "8eefa6e9-a1ca-4373-86ce-b0acc39a95e7",
    iconSet: "Ionicons",
    iconName: "logo-google-playstore"
  },
  {
    uuid: "2f146568-aebb-46c1-9573-3f9a093b42c9",
    iconSet: "Ionicons",
    iconName: "logo-hackernews"
  },
  {
    uuid: "b0d4d6b4-e102-4447-8fa7-9341312d998a",
    iconSet: "Ionicons",
    iconName: "logo-html5"
  },
  {
    uuid: "616ef3ec-0eb2-4515-8b5c-6babb105fe69",
    iconSet: "Ionicons",
    iconName: "logo-instagram"
  },
  {
    uuid: "789575ab-1c3f-4cbd-ab7d-9600e233e453",
    iconSet: "Ionicons",
    iconName: "logo-ionic"
  },
  {
    uuid: "017095d2-84ec-426f-b07d-2ccc75a9cf56",
    iconSet: "Ionicons",
    iconName: "logo-ionitron"
  },
  {
    uuid: "098fd68f-6eb9-4f24-af2c-1e5cb0620b2a",
    iconSet: "Ionicons",
    iconName: "logo-javascript"
  },
  {
    uuid: "c40b209b-39f3-4481-87f7-3bfcf77e160a",
    iconSet: "Ionicons",
    iconName: "logo-laravel"
  },
  {
    uuid: "5fecec1f-a599-4cb3-9caa-299ede0053f7",
    iconSet: "Ionicons",
    iconName: "logo-linkedin"
  },
  {
    uuid: "35c2df37-69c7-409b-9258-98ca4e6185d9",
    iconSet: "Ionicons",
    iconName: "logo-markdown"
  },
  {
    uuid: "80ecb67d-c84b-4988-aada-47658d3c0e94",
    iconSet: "Ionicons",
    iconName: "logo-no-smoking"
  },
  {
    uuid: "cfb589f7-094e-4820-b1b8-64d2f57639d8",
    iconSet: "Ionicons",
    iconName: "logo-nodejs"
  },
  {
    uuid: "8f2541a0-b484-429d-bc28-6962fddfa09f",
    iconSet: "Ionicons",
    iconName: "logo-npm"
  },
  {
    uuid: "35426b05-4465-4d4e-9fd2-2418511f9797",
    iconSet: "Ionicons",
    iconName: "logo-octocat"
  },
  {
    uuid: "547059ef-6f90-4c5b-a125-d6b89ddcc2f7",
    iconSet: "Ionicons",
    iconName: "logo-pinterest"
  },
  {
    uuid: "a4665f22-e1e3-485f-8df2-b8c6fdb1ec53",
    iconSet: "Ionicons",
    iconName: "logo-playstation"
  },
  {
    uuid: "a7f45401-7a1a-4b15-bbac-f2edf6023e2c",
    iconSet: "Ionicons",
    iconName: "logo-pwa"
  },
  {
    uuid: "975f2799-4617-4314-a5bc-3c481d1ccda7",
    iconSet: "Ionicons",
    iconName: "logo-python"
  },
  {
    uuid: "0bc2eb37-0de1-4a57-ad27-06ba540678d7",
    iconSet: "Ionicons",
    iconName: "logo-react"
  },
  {
    uuid: "37349ae8-6fcd-444f-9a06-99d546053256",
    iconSet: "Ionicons",
    iconName: "logo-reddit"
  },
  {
    uuid: "0681e089-eca3-4d9e-a2b1-3658bca7248f",
    iconSet: "Ionicons",
    iconName: "logo-rss"
  },
  {
    uuid: "677925d8-816f-4aa0-a63e-1b3659aff294",
    iconSet: "Ionicons",
    iconName: "logo-sass"
  },
  {
    uuid: "0a8e586b-734f-48b7-9183-d09c29248e0b",
    iconSet: "Ionicons",
    iconName: "logo-skype"
  },
  {
    uuid: "198c9948-404e-458e-b1db-6fedd18e3263",
    iconSet: "Ionicons",
    iconName: "logo-slack"
  },
  {
    uuid: "dd2687cd-536e-4069-8f6a-7126c735c14b",
    iconSet: "Ionicons",
    iconName: "logo-snapchat"
  },
  {
    uuid: "300964a2-7067-43ab-895a-cd4d0594b55b",
    iconSet: "Ionicons",
    iconName: "logo-stackoverflow"
  },
  {
    uuid: "3ba1f561-f3b7-48ee-8611-54fc77c4eba5",
    iconSet: "Ionicons",
    iconName: "logo-steam"
  },
  {
    uuid: "1ec515d1-0769-4a80-ad00-72a21de0b7ef",
    iconSet: "Ionicons",
    iconName: "logo-stencil"
  },
  {
    uuid: "f03e5d5f-767f-4c5a-8733-f81d4f0a1a44",
    iconSet: "Ionicons",
    iconName: "logo-tumblr"
  },
  {
    uuid: "040cf3ea-9689-4608-b97e-49030c3f5518",
    iconSet: "Ionicons",
    iconName: "logo-tux"
  },
  {
    uuid: "fe5bb23b-2151-46cc-bff5-85d085ffcfa5",
    iconSet: "Ionicons",
    iconName: "logo-twitch"
  },
  {
    uuid: "871a1f0f-5da5-4086-b3a5-3d96807a712b",
    iconSet: "Ionicons",
    iconName: "logo-twitter"
  },
  {
    uuid: "43cfb7ec-f124-48bc-9d24-3836d2c6720c",
    iconSet: "Ionicons",
    iconName: "logo-usd"
  },
  {
    uuid: "c7ed0347-aa87-4768-beb1-1402ccf1c078",
    iconSet: "Ionicons",
    iconName: "logo-vimeo"
  },
  {
    uuid: "b51dbd28-b2aa-408d-8a6c-4677d85ae5de",
    iconSet: "Ionicons",
    iconName: "logo-vk"
  },
  {
    uuid: "ffe452b0-aeca-4d4e-98f1-bd3f1403d26e",
    iconSet: "Ionicons",
    iconName: "logo-vue"
  },
  {
    uuid: "40c9a2ae-9b00-4143-bbb4-5de5d8b9a244",
    iconSet: "Ionicons",
    iconName: "logo-web-component"
  },
  {
    uuid: "59f9c671-bb80-445f-8d74-84f1fba12354",
    iconSet: "Ionicons",
    iconName: "logo-whatsapp"
  },
  {
    uuid: "fb16f793-dd63-421e-baa9-963ab8dd5c38",
    iconSet: "Ionicons",
    iconName: "logo-windows"
  },
  {
    uuid: "2066b283-5c00-42c6-b72c-975389864144",
    iconSet: "Ionicons",
    iconName: "logo-wordpress"
  },
  {
    uuid: "812c18d7-1fad-4bb3-906c-dd7fc5f63621",
    iconSet: "Ionicons",
    iconName: "logo-xbox"
  },
  {
    uuid: "b90e53f2-d2a7-4824-88c5-fbe19449685e",
    iconSet: "Ionicons",
    iconName: "logo-xing"
  },
  {
    uuid: "f1a84bbf-9c06-4b11-bd28-88003548f18b",
    iconSet: "Ionicons",
    iconName: "logo-yahoo"
  },
  {
    uuid: "0de49113-7179-403b-861f-8271798dada7",
    iconSet: "Ionicons",
    iconName: "logo-yen"
  },
  {
    uuid: "f5193df8-7e3a-416b-b2a7-c9b69c03f8d2",
    iconSet: "Ionicons",
    iconName: "logo-youtube"
  },
  {
    uuid: "aa428a5b-0cb5-40b1-b49c-1b23b7e2af3a",
    iconSet: "Ionicons",
    iconName: "magnet"
  },
  {
    uuid: "c15be559-9a73-4a77-96f0-4e4f6c86b113",
    iconSet: "Ionicons",
    iconName: "magnet-outline"
  },
  {
    uuid: "733830b3-8b9c-4c0a-9875-3a5e3cf46a3a",
    iconSet: "Ionicons",
    iconName: "magnet-sharp"
  },
  {
    uuid: "b91fcc15-ef9c-4581-90c0-dd1ee887b003",
    iconSet: "Ionicons",
    iconName: "mail"
  },
  {
    uuid: "fccd7acb-26d7-43e6-a266-21c56bbe729c",
    iconSet: "Ionicons",
    iconName: "mail-open"
  },
  {
    uuid: "2b59ec30-92d5-4256-a3cf-337ff20977b2",
    iconSet: "Ionicons",
    iconName: "mail-open-outline"
  },
  {
    uuid: "9882e939-fc01-4931-8d26-26c44c6c09c0",
    iconSet: "Ionicons",
    iconName: "mail-open-sharp"
  },
  {
    uuid: "f3e672e8-9f25-41e5-bdc3-a270ada6264c",
    iconSet: "Ionicons",
    iconName: "mail-outline"
  },
  {
    uuid: "03ef571a-1b61-4876-99b3-80c8dfd59227",
    iconSet: "Ionicons",
    iconName: "mail-sharp"
  },
  {
    uuid: "68c6641c-d1ba-4320-82a0-5de263397e3a",
    iconSet: "Ionicons",
    iconName: "mail-unread"
  },
  {
    uuid: "6446ad08-b496-4b83-82a9-d1a515ce3781",
    iconSet: "Ionicons",
    iconName: "mail-unread-outline"
  },
  {
    uuid: "19b452fa-b0a4-4ad8-978f-feb25d0e7e0d",
    iconSet: "Ionicons",
    iconName: "mail-unread-sharp"
  },
  {
    uuid: "362a5511-7a3e-4712-82b8-fedc33ce3519",
    iconSet: "Ionicons",
    iconName: "male"
  },
  {
    uuid: "7da940be-ec78-4a9f-ac40-acf2cf87474c",
    iconSet: "Ionicons",
    iconName: "male-female"
  },
  {
    uuid: "fddc7005-5bf4-4102-b6f4-edaccb03e10e",
    iconSet: "Ionicons",
    iconName: "male-female-outline"
  },
  {
    uuid: "e9f3740d-1851-4a15-ae82-a48d84b912c8",
    iconSet: "Ionicons",
    iconName: "male-female-sharp"
  },
  {
    uuid: "e34b91f0-0a0d-44d6-b89a-43c909b0a7e2",
    iconSet: "Ionicons",
    iconName: "male-outline"
  },
  {
    uuid: "fbbf2671-4a9f-4263-84d5-0b92cb72fbf9",
    iconSet: "Ionicons",
    iconName: "male-sharp"
  },
  {
    uuid: "f0452ccf-60e9-4f87-98c3-f8b1d93d62a3",
    iconSet: "Ionicons",
    iconName: "man"
  },
  {
    uuid: "7eae406a-bc1b-42f9-94ba-1fa4dfad7aaf",
    iconSet: "Ionicons",
    iconName: "man-outline"
  },
  {
    uuid: "85968e89-344e-4d0d-aa3a-9aaf22957ffa",
    iconSet: "Ionicons",
    iconName: "man-sharp"
  },
  {
    uuid: "0c3031c7-9ed5-4cc5-aa53-19fea0105cb6",
    iconSet: "Ionicons",
    iconName: "map"
  },
  {
    uuid: "ef86365d-5dc9-4414-bc40-8ab2c2c85c12",
    iconSet: "Ionicons",
    iconName: "map-outline"
  },
  {
    uuid: "835ebb13-3f70-4de4-ac2d-403794c24b23",
    iconSet: "Ionicons",
    iconName: "map-sharp"
  },
  {
    uuid: "efe28246-7241-4f02-b39b-37df20808926",
    iconSet: "Ionicons",
    iconName: "medal"
  },
  {
    uuid: "9e2d797a-86f6-4015-86b8-5f03b4b8cfe1",
    iconSet: "Ionicons",
    iconName: "medal-outline"
  },
  {
    uuid: "870c0bad-f45a-47a1-aab8-88b94eab3a18",
    iconSet: "Ionicons",
    iconName: "medal-sharp"
  },
  {
    uuid: "2b4283eb-7279-4dc3-8c6b-74066b6aca70",
    iconSet: "Ionicons",
    iconName: "medical"
  },
  {
    uuid: "738749d4-515a-4bff-96fd-65bb6007874d",
    iconSet: "Ionicons",
    iconName: "medical-outline"
  },
  {
    uuid: "64f2981c-4c67-4010-b21d-26705dd65aad",
    iconSet: "Ionicons",
    iconName: "medical-sharp"
  },
  {
    uuid: "9f3ea4b9-8d13-4c20-9857-e029e9cf8aa5",
    iconSet: "Ionicons",
    iconName: "medkit"
  },
  {
    uuid: "7b31fa87-019a-4041-9354-558ff8cea72f",
    iconSet: "Ionicons",
    iconName: "medkit-outline"
  },
  {
    uuid: "32d472d9-4ed3-4504-afac-4f20e636fea6",
    iconSet: "Ionicons",
    iconName: "medkit-sharp"
  },
  {
    uuid: "4ee1d65b-6611-41af-a787-9493190ee228",
    iconSet: "Ionicons",
    iconName: "megaphone"
  },
  {
    uuid: "56663b8e-505f-4e17-a799-84b349750bd3",
    iconSet: "Ionicons",
    iconName: "megaphone-outline"
  },
  {
    uuid: "e8a3e3d5-71d6-4b2f-91bb-0054ef9a26d0",
    iconSet: "Ionicons",
    iconName: "megaphone-sharp"
  },
  {
    uuid: "80abb6f6-48fd-4f5d-ae83-531e97b51a0d",
    iconSet: "Ionicons",
    iconName: "menu"
  },
  {
    uuid: "045660d2-2d42-44d6-87d8-647242a79584",
    iconSet: "Ionicons",
    iconName: "menu-outline"
  },
  {
    uuid: "07747370-2e82-4516-a98d-0753e45287f6",
    iconSet: "Ionicons",
    iconName: "menu-sharp"
  },
  {
    uuid: "2d3b3538-b4dd-4ee6-ac72-40d1c63a8e8f",
    iconSet: "Ionicons",
    iconName: "mic"
  },
  {
    uuid: "b030a444-7db0-4e55-b383-0303edd54ed7",
    iconSet: "Ionicons",
    iconName: "mic-circle"
  },
  {
    uuid: "c0af2725-be85-41ac-b539-70d12857d401",
    iconSet: "Ionicons",
    iconName: "mic-circle-outline"
  },
  {
    uuid: "d808cad0-a4fe-4d6a-b93f-9e8c65d66792",
    iconSet: "Ionicons",
    iconName: "mic-circle-sharp"
  },
  {
    uuid: "e6755250-87c2-4b65-b6b6-9c7b29a4426e",
    iconSet: "Ionicons",
    iconName: "mic-off"
  },
  {
    uuid: "fd7c7903-ffe2-4434-9d72-5bb31ffb1b89",
    iconSet: "Ionicons",
    iconName: "mic-off-circle"
  },
  {
    uuid: "828c9176-fa5f-4c9d-9173-6cbbcedfacf9",
    iconSet: "Ionicons",
    iconName: "mic-off-circle-outline"
  },
  {
    uuid: "3b8d3609-65b8-4cd4-938d-e2944c2db669",
    iconSet: "Ionicons",
    iconName: "mic-off-circle-sharp"
  },
  {
    uuid: "00a2f3e3-b56b-4143-a5e6-f48a3ec35fdc",
    iconSet: "Ionicons",
    iconName: "mic-off-outline"
  },
  {
    uuid: "d9b973de-1ff7-42c3-aa61-7cdfe8d4da87",
    iconSet: "Ionicons",
    iconName: "mic-off-sharp"
  },
  {
    uuid: "c7185122-f668-4191-90c0-56a294353be0",
    iconSet: "Ionicons",
    iconName: "mic-outline"
  },
  {
    uuid: "e88cad37-0978-42c6-838c-58f0fdb332d8",
    iconSet: "Ionicons",
    iconName: "mic-sharp"
  },
  {
    uuid: "e1047274-9ff1-4e75-ba86-029247fcf267",
    iconSet: "Ionicons",
    iconName: "moon"
  },
  {
    uuid: "bfb9dde3-b0f1-4d96-afd8-9c8fcfc6c01f",
    iconSet: "Ionicons",
    iconName: "moon-outline"
  },
  {
    uuid: "de8d6e48-5e9c-40d9-885a-a4c07a9d6aad",
    iconSet: "Ionicons",
    iconName: "moon-sharp"
  },
  {
    uuid: "96b679d2-2067-4951-bef6-6e462c1ee028",
    iconSet: "Ionicons",
    iconName: "move"
  },
  {
    uuid: "e844bd0f-1180-40ab-9469-73c8ec8177d3",
    iconSet: "Ionicons",
    iconName: "move-outline"
  },
  {
    uuid: "e3b4c1d2-f6a3-4c16-8fb6-2d8f88fe6266",
    iconSet: "Ionicons",
    iconName: "move-sharp"
  },
  {
    uuid: "e6f1a95e-3491-4854-b11f-889d46c80ed0",
    iconSet: "Ionicons",
    iconName: "musical-note"
  },
  {
    uuid: "298eae40-482d-4dfe-8346-1d981b7da208",
    iconSet: "Ionicons",
    iconName: "musical-note-outline"
  },
  {
    uuid: "15466fb1-8d15-4182-9810-3a1e5a06c469",
    iconSet: "Ionicons",
    iconName: "musical-note-sharp"
  },
  {
    uuid: "1d31b76f-1eb9-4ed5-be8e-779605f10523",
    iconSet: "Ionicons",
    iconName: "musical-notes"
  },
  {
    uuid: "56f893c4-d838-4103-bab6-7190c2ebff67",
    iconSet: "Ionicons",
    iconName: "musical-notes-outline"
  },
  {
    uuid: "cf4d21ec-33a3-41fe-b937-e20d10c5990d",
    iconSet: "Ionicons",
    iconName: "musical-notes-sharp"
  },
  {
    uuid: "0cbe4069-cc1f-4da5-b755-32b318f06d73",
    iconSet: "Ionicons",
    iconName: "navigate"
  },
  {
    uuid: "5476edd0-ceb1-4f4f-a625-7ae25f98c84a",
    iconSet: "Ionicons",
    iconName: "navigate-circle"
  },
  {
    uuid: "0adc32e8-fc05-4d27-9565-a7ac934705d7",
    iconSet: "Ionicons",
    iconName: "navigate-circle-outline"
  },
  {
    uuid: "e0101308-10e2-4ae9-86a5-683ed91b80f9",
    iconSet: "Ionicons",
    iconName: "navigate-circle-sharp"
  },
  {
    uuid: "81a6074d-8af4-4e02-9a82-87f0361c7caf",
    iconSet: "Ionicons",
    iconName: "navigate-outline"
  },
  {
    uuid: "bc7eca55-7993-49c7-90db-b62e4df1b127",
    iconSet: "Ionicons",
    iconName: "navigate-sharp"
  },
  {
    uuid: "27c31f8f-df8d-449b-97d4-9499fe64512a",
    iconSet: "Ionicons",
    iconName: "newspaper"
  },
  {
    uuid: "99cb1b01-b613-454b-95a0-0ca203083e28",
    iconSet: "Ionicons",
    iconName: "newspaper-outline"
  },
  {
    uuid: "201a3127-7ee7-4be1-bbd8-cc5065b7e88e",
    iconSet: "Ionicons",
    iconName: "newspaper-sharp"
  },
  {
    uuid: "dc1a6329-e14a-4460-8f7d-e2b6a21299a1",
    iconSet: "Ionicons",
    iconName: "notifications"
  },
  {
    uuid: "6dbfe1d9-14fe-4f13-9ee9-25721246e318",
    iconSet: "Ionicons",
    iconName: "notifications-circle"
  },
  {
    uuid: "7df7dab1-60b6-4084-9de6-4242e3d8d383",
    iconSet: "Ionicons",
    iconName: "notifications-circle-outline"
  },
  {
    uuid: "89616329-f2bc-44ba-b6df-52f6fc42445e",
    iconSet: "Ionicons",
    iconName: "notifications-circle-sharp"
  },
  {
    uuid: "a79c26a2-daad-4137-b8e3-8edb50d607ff",
    iconSet: "Ionicons",
    iconName: "notifications-off"
  },
  {
    uuid: "896d736f-72f9-4633-9a61-c70a7c633439",
    iconSet: "Ionicons",
    iconName: "notifications-off-circle"
  },
  {
    uuid: "fe5a9140-6f51-4ba8-9730-0d38b7cac680",
    iconSet: "Ionicons",
    iconName: "notifications-off-circle-outline"
  },
  {
    uuid: "33f4ac09-b482-4509-bfd3-2c71a6254423",
    iconSet: "Ionicons",
    iconName: "notifications-off-circle-sharp"
  },
  {
    uuid: "bc39799c-f067-4d54-a682-fac96e35ad2a",
    iconSet: "Ionicons",
    iconName: "notifications-off-outline"
  },
  {
    uuid: "b07a41ae-34f4-4794-b4fe-e63b3bad7545",
    iconSet: "Ionicons",
    iconName: "notifications-off-sharp"
  },
  {
    uuid: "46a76db7-22f8-4366-bc36-b24a669ed8a5",
    iconSet: "Ionicons",
    iconName: "notifications-outline"
  },
  {
    uuid: "db62f0aa-ebe5-4510-bb6a-f170cdbcbeb6",
    iconSet: "Ionicons",
    iconName: "notifications-sharp"
  },
  {
    uuid: "fcd867c4-6c4c-4b35-b4e8-ff47df29ce79",
    iconSet: "Ionicons",
    iconName: "nuclear"
  },
  {
    uuid: "e9449b10-5815-4e6f-898d-51836fa3ff20",
    iconSet: "Ionicons",
    iconName: "nuclear-outline"
  },
  {
    uuid: "f967d899-ba8d-4307-bd6c-c555c3276216",
    iconSet: "Ionicons",
    iconName: "nuclear-sharp"
  },
  {
    uuid: "9215d294-5c96-47b5-a40b-63f4de906dc1",
    iconSet: "Ionicons",
    iconName: "nutrition"
  },
  {
    uuid: "d1b572d8-37e6-4bff-aaa0-c4d528e9fae4",
    iconSet: "Ionicons",
    iconName: "nutrition-outline"
  },
  {
    uuid: "736dadb9-6027-4202-acf8-a50118691f71",
    iconSet: "Ionicons",
    iconName: "nutrition-sharp"
  },
  {
    uuid: "00560a09-97d4-4f05-99c4-47482441d8bf",
    iconSet: "Ionicons",
    iconName: "open"
  },
  {
    uuid: "7a38b403-3c42-4ba5-985f-e2fda84d299b",
    iconSet: "Ionicons",
    iconName: "open-outline"
  },
  {
    uuid: "f3f76277-f0ae-48b2-a2b1-2bc288a9d004",
    iconSet: "Ionicons",
    iconName: "open-sharp"
  },
  {
    uuid: "43e79e76-ce47-4d27-ae15-979687acd054",
    iconSet: "Ionicons",
    iconName: "options"
  },
  {
    uuid: "816a9193-beeb-455c-8686-21645f87bf3d",
    iconSet: "Ionicons",
    iconName: "options-outline"
  },
  {
    uuid: "2d2ef6ea-2bee-42f2-b310-3bdc628bc049",
    iconSet: "Ionicons",
    iconName: "options-sharp"
  },
  {
    uuid: "d06263f2-4fea-4068-9471-4824909a851e",
    iconSet: "Ionicons",
    iconName: "paper-plane"
  },
  {
    uuid: "9d81bdfb-12c9-41e0-994e-83d005ea7b1e",
    iconSet: "Ionicons",
    iconName: "paper-plane-outline"
  },
  {
    uuid: "2011bcb2-92f0-4185-9e69-cc7e5f8429a0",
    iconSet: "Ionicons",
    iconName: "paper-plane-sharp"
  },
  {
    uuid: "3dabda06-a72d-49bb-96db-e21814cee8c4",
    iconSet: "Ionicons",
    iconName: "partly-sunny"
  },
  {
    uuid: "8932eb2e-8c85-463d-9209-6a557ccc6624",
    iconSet: "Ionicons",
    iconName: "partly-sunny-outline"
  },
  {
    uuid: "f4d95775-dd77-49e1-a5cb-d587a6e90bbc",
    iconSet: "Ionicons",
    iconName: "partly-sunny-sharp"
  },
  {
    uuid: "b4e46e74-0580-4d3d-846e-4ee715aa6f06",
    iconSet: "Ionicons",
    iconName: "pause"
  },
  {
    uuid: "05d2bf3d-38cf-4ee6-ab01-6361d25b2941",
    iconSet: "Ionicons",
    iconName: "pause-circle"
  },
  {
    uuid: "3391b782-7028-4150-883d-246ead1ebb7a",
    iconSet: "Ionicons",
    iconName: "pause-circle-outline"
  },
  {
    uuid: "e7dd10c0-d35e-49ce-894b-b3895dfd459a",
    iconSet: "Ionicons",
    iconName: "pause-circle-sharp"
  },
  {
    uuid: "2a8df24a-1a85-4561-aaab-1c28ae573532",
    iconSet: "Ionicons",
    iconName: "pause-outline"
  },
  {
    uuid: "f7bde234-17a9-4887-9b78-f178531231dd",
    iconSet: "Ionicons",
    iconName: "pause-sharp"
  },
  {
    uuid: "d4b5f5fd-7a0d-4156-9b92-351768d0be24",
    iconSet: "Ionicons",
    iconName: "paw"
  },
  {
    uuid: "2376950b-be76-407d-ba69-aeed0960af4d",
    iconSet: "Ionicons",
    iconName: "paw-outline"
  },
  {
    uuid: "87381340-a627-496c-9cf0-d36595a0f9f3",
    iconSet: "Ionicons",
    iconName: "paw-sharp"
  },
  {
    uuid: "ff82435d-f3ee-4b08-b447-73a1208dce66",
    iconSet: "Ionicons",
    iconName: "pencil"
  },
  {
    uuid: "64341846-1407-4ed2-8e6e-fc62fe28dd8c",
    iconSet: "Ionicons",
    iconName: "pencil-outline"
  },
  {
    uuid: "14cd2c33-0872-4335-9e16-d5b8d0964001",
    iconSet: "Ionicons",
    iconName: "pencil-sharp"
  },
  {
    uuid: "13dcc0d0-0104-444a-843d-6d6ee4cd582a",
    iconSet: "Ionicons",
    iconName: "people"
  },
  {
    uuid: "831c3b58-e665-4267-b9e5-a655f70fc19e",
    iconSet: "Ionicons",
    iconName: "people-circle"
  },
  {
    uuid: "180dbe07-6c70-4284-a3d0-a5137d1c2a68",
    iconSet: "Ionicons",
    iconName: "people-circle-outline"
  },
  {
    uuid: "ac27459e-b462-454a-9bb1-037b70646404",
    iconSet: "Ionicons",
    iconName: "people-circle-sharp"
  },
  {
    uuid: "5014d390-cea2-4c21-83ad-06e83744b5b5",
    iconSet: "Ionicons",
    iconName: "people-outline"
  },
  {
    uuid: "1466f1ab-7321-4b12-8e6d-65d0864483b2",
    iconSet: "Ionicons",
    iconName: "people-sharp"
  },
  {
    uuid: "1b0091cf-243d-4a1e-be23-9ed2ac19169b",
    iconSet: "Ionicons",
    iconName: "person"
  },
  {
    uuid: "5402550a-698b-49f1-ae99-563479d43a66",
    iconSet: "Ionicons",
    iconName: "person-add"
  },
  {
    uuid: "0ae9abf7-771f-4940-a3df-6e2f045e8e72",
    iconSet: "Ionicons",
    iconName: "person-add-outline"
  },
  {
    uuid: "4b787a06-42a8-43d6-99ac-7092f4158bb7",
    iconSet: "Ionicons",
    iconName: "person-add-sharp"
  },
  {
    uuid: "8a14709a-b6e7-4bff-baf6-ffe66ed6cda6",
    iconSet: "Ionicons",
    iconName: "person-circle"
  },
  {
    uuid: "fddc87dc-6341-45c2-ad3c-6efe6800890a",
    iconSet: "Ionicons",
    iconName: "person-circle-outline"
  },
  {
    uuid: "92778d84-c0bc-4cb9-8953-01e69619649b",
    iconSet: "Ionicons",
    iconName: "person-circle-sharp"
  },
  {
    uuid: "b7d92b2e-5e07-4b3f-82fd-fad4ae95705f",
    iconSet: "Ionicons",
    iconName: "person-outline"
  },
  {
    uuid: "6ea1fec1-e46c-4286-a681-b600dcd3cd30",
    iconSet: "Ionicons",
    iconName: "person-remove"
  },
  {
    uuid: "d38530a1-6208-4b37-ab58-621eb1a71885",
    iconSet: "Ionicons",
    iconName: "person-remove-outline"
  },
  {
    uuid: "ede1136b-10c7-4a21-8ded-8963b3503284",
    iconSet: "Ionicons",
    iconName: "person-remove-sharp"
  },
  {
    uuid: "96ecd6c6-99c2-408e-b5d5-87940b1693e2",
    iconSet: "Ionicons",
    iconName: "person-sharp"
  },
  {
    uuid: "aa00d6fd-ee75-4c78-a384-858f04c89a34",
    iconSet: "Ionicons",
    iconName: "phone-landscape"
  },
  {
    uuid: "156c2888-81fa-4fd8-8470-d39f024b86fb",
    iconSet: "Ionicons",
    iconName: "phone-landscape-outline"
  },
  {
    uuid: "e54ef726-33d2-491f-9c83-2e2f4f4ce53c",
    iconSet: "Ionicons",
    iconName: "phone-landscape-sharp"
  },
  {
    uuid: "020fa425-d944-4db0-a1c4-de82d7ea7736",
    iconSet: "Ionicons",
    iconName: "phone-portrait"
  },
  {
    uuid: "64b37377-014b-44b7-8cc9-99e6504bf2a1",
    iconSet: "Ionicons",
    iconName: "phone-portrait-outline"
  },
  {
    uuid: "16b15877-205f-44e3-be67-4792b544c2f6",
    iconSet: "Ionicons",
    iconName: "phone-portrait-sharp"
  },
  {
    uuid: "2d24a2e2-a924-4cb4-95e5-b5524d0a50fd",
    iconSet: "Ionicons",
    iconName: "pie-chart"
  },
  {
    uuid: "2e04bf73-edf8-4fd4-b5d1-047b956ccb78",
    iconSet: "Ionicons",
    iconName: "pie-chart-outline"
  },
  {
    uuid: "89b11392-c8fa-46d3-ad95-b4f77c1c0ac3",
    iconSet: "Ionicons",
    iconName: "pie-chart-sharp"
  },
  {
    uuid: "75e5cc9a-b76d-496c-bfed-ec7bfa00dc47",
    iconSet: "Ionicons",
    iconName: "pin"
  },
  {
    uuid: "afc038dc-ad97-491d-b023-d6d681265b71",
    iconSet: "Ionicons",
    iconName: "pin-outline"
  },
  {
    uuid: "1608ff54-aa0f-4fa6-9473-35163f8ef4d4",
    iconSet: "Ionicons",
    iconName: "pin-sharp"
  },
  {
    uuid: "b692f189-3e1d-4feb-96c0-9caf62441b2a",
    iconSet: "Ionicons",
    iconName: "pint"
  },
  {
    uuid: "9253dd66-7859-4de9-86a4-140fe0df220a",
    iconSet: "Ionicons",
    iconName: "pint-outline"
  },
  {
    uuid: "d10078f2-174f-4d35-bd4b-4c617fb5de8b",
    iconSet: "Ionicons",
    iconName: "pint-sharp"
  },
  {
    uuid: "8f4b2aa7-c700-475c-81a0-b53411caab34",
    iconSet: "Ionicons",
    iconName: "pizza"
  },
  {
    uuid: "639263d5-8261-4be4-bc03-1a17f22c54d3",
    iconSet: "Ionicons",
    iconName: "pizza-outline"
  },
  {
    uuid: "83e028c6-b77b-450f-8031-324fe16b79b6",
    iconSet: "Ionicons",
    iconName: "pizza-sharp"
  },
  {
    uuid: "1a5c2f1f-6191-4816-8313-139c934cf168",
    iconSet: "Ionicons",
    iconName: "planet"
  },
  {
    uuid: "847d7b72-905c-4758-b8fd-aa8249ee3a80",
    iconSet: "Ionicons",
    iconName: "planet-outline"
  },
  {
    uuid: "379f684a-b563-4285-a9fc-012b59007129",
    iconSet: "Ionicons",
    iconName: "planet-sharp"
  },
  {
    uuid: "e4625aa9-9b0f-4705-ab1b-45b5a807f25f",
    iconSet: "Ionicons",
    iconName: "play"
  },
  {
    uuid: "c5910b8c-d41d-4f60-ba40-5c8d996a79a7",
    iconSet: "Ionicons",
    iconName: "play-back"
  },
  {
    uuid: "9e44cbc6-0038-4c46-85c7-2f075b13c0db",
    iconSet: "Ionicons",
    iconName: "play-back-circle"
  },
  {
    uuid: "761494ef-0209-42ca-a316-6084f929694f",
    iconSet: "Ionicons",
    iconName: "play-back-circle-outline"
  },
  {
    uuid: "27e69e66-2525-4142-b8f9-74e569351fab",
    iconSet: "Ionicons",
    iconName: "play-back-circle-sharp"
  },
  {
    uuid: "8e7c5dea-feb8-4aa9-aaa2-f53467cc6b01",
    iconSet: "Ionicons",
    iconName: "play-back-outline"
  },
  {
    uuid: "cbfe165e-d14c-4843-b662-7f4911eba7d3",
    iconSet: "Ionicons",
    iconName: "play-back-sharp"
  },
  {
    uuid: "fedc96e8-8518-480a-9dd5-2a2b72a0caf2",
    iconSet: "Ionicons",
    iconName: "play-circle"
  },
  {
    uuid: "33327606-f9fa-46aa-bec6-8832535cb955",
    iconSet: "Ionicons",
    iconName: "play-circle-outline"
  },
  {
    uuid: "b1a89bbc-c644-4865-b6b2-a897e006261f",
    iconSet: "Ionicons",
    iconName: "play-circle-sharp"
  },
  {
    uuid: "6c43e1db-6c26-4663-b0fa-e8ab05658ee8",
    iconSet: "Ionicons",
    iconName: "play-forward"
  },
  {
    uuid: "c39efd58-5fa1-4e27-be9f-e53ec2a3660a",
    iconSet: "Ionicons",
    iconName: "play-forward-circle"
  },
  {
    uuid: "2d0ddf02-a514-4ab1-bdb1-2342f7e62a34",
    iconSet: "Ionicons",
    iconName: "play-forward-circle-outline"
  },
  {
    uuid: "91365c75-a93d-4299-a922-464d84a6f252",
    iconSet: "Ionicons",
    iconName: "play-forward-circle-sharp"
  },
  {
    uuid: "44185019-20f9-4ac9-95a9-c8de5fd1fd3a",
    iconSet: "Ionicons",
    iconName: "play-forward-outline"
  },
  {
    uuid: "77dc2c73-3e81-4f83-a99f-eaa94bdadb11",
    iconSet: "Ionicons",
    iconName: "play-forward-sharp"
  },
  {
    uuid: "06efac03-2450-480d-b273-4dfc4daa5dff",
    iconSet: "Ionicons",
    iconName: "play-outline"
  },
  {
    uuid: "0798cb6f-26fd-4188-ae8a-37ecba2bbdfc",
    iconSet: "Ionicons",
    iconName: "play-sharp"
  },
  {
    uuid: "ab0a8f69-130e-4544-ae25-ec04538db9ed",
    iconSet: "Ionicons",
    iconName: "play-skip-back"
  },
  {
    uuid: "be331cda-7d49-4b21-bb09-70b2d2dc64e5",
    iconSet: "Ionicons",
    iconName: "play-skip-back-circle"
  },
  {
    uuid: "94f3ebe2-8b10-421c-838d-d344e55d874f",
    iconSet: "Ionicons",
    iconName: "play-skip-back-circle-outline"
  },
  {
    uuid: "126b6e6c-082e-484d-8a22-dfc01f791670",
    iconSet: "Ionicons",
    iconName: "play-skip-back-circle-sharp"
  },
  {
    uuid: "e7edd063-63a8-448d-8728-f73556a33545",
    iconSet: "Ionicons",
    iconName: "play-skip-back-outline"
  },
  {
    uuid: "64900d64-bd95-4185-813b-c633944522f3",
    iconSet: "Ionicons",
    iconName: "play-skip-back-sharp"
  },
  {
    uuid: "42a143a0-f355-418e-aa26-801adcc0911c",
    iconSet: "Ionicons",
    iconName: "play-skip-forward"
  },
  {
    uuid: "a98870e5-a7a3-4904-8bf3-3a253e2a19ee",
    iconSet: "Ionicons",
    iconName: "play-skip-forward-circle"
  },
  {
    uuid: "82464e46-d2d2-44fe-94f8-b68d13359895",
    iconSet: "Ionicons",
    iconName: "play-skip-forward-circle-outline"
  },
  {
    uuid: "d794660b-11b6-47e0-bc61-84b6825261a7",
    iconSet: "Ionicons",
    iconName: "play-skip-forward-circle-sharp"
  },
  {
    uuid: "907f9c29-c430-46fa-834c-494e0104c83f",
    iconSet: "Ionicons",
    iconName: "play-skip-forward-outline"
  },
  {
    uuid: "013eea07-fa55-42af-998b-a50a19ea07b7",
    iconSet: "Ionicons",
    iconName: "play-skip-forward-sharp"
  },
  {
    uuid: "a486af7a-745d-454e-b8a8-1b1a1dd494f7",
    iconSet: "Ionicons",
    iconName: "podium"
  },
  {
    uuid: "5a1a188b-7f8b-4a0b-8238-bf1857a6d7d2",
    iconSet: "Ionicons",
    iconName: "podium-outline"
  },
  {
    uuid: "04d60961-aaa7-4f92-9e08-931100b5f7d7",
    iconSet: "Ionicons",
    iconName: "podium-sharp"
  },
  {
    uuid: "e21ac13b-bdc5-4c95-93b5-c3880cb4eef1",
    iconSet: "Ionicons",
    iconName: "power"
  },
  {
    uuid: "84d64d57-d3c0-4a79-9225-bac839766cc8",
    iconSet: "Ionicons",
    iconName: "power-outline"
  },
  {
    uuid: "7e1bf2d2-91c3-450a-a1d1-a36525f31520",
    iconSet: "Ionicons",
    iconName: "power-sharp"
  },
  {
    uuid: "086fa58b-5b36-450a-9898-35cb6a6d9d2f",
    iconSet: "Ionicons",
    iconName: "pricetag"
  },
  {
    uuid: "e871a2c5-5f5d-4968-8eeb-6ce1424ef010",
    iconSet: "Ionicons",
    iconName: "pricetag-outline"
  },
  {
    uuid: "147628bc-8ca4-47ab-aff1-0360f6bda30b",
    iconSet: "Ionicons",
    iconName: "pricetag-sharp"
  },
  {
    uuid: "326a6c5f-2f50-48c7-9489-5509447040c0",
    iconSet: "Ionicons",
    iconName: "pricetags"
  },
  {
    uuid: "570c615d-11a9-4b85-8acd-d5fb4da03ac2",
    iconSet: "Ionicons",
    iconName: "pricetags-outline"
  },
  {
    uuid: "672c1085-dace-48ca-8265-8ee37392df3f",
    iconSet: "Ionicons",
    iconName: "pricetags-sharp"
  },
  {
    uuid: "b00bd619-ef30-4c6a-8ba9-49ca9a6e2666",
    iconSet: "Ionicons",
    iconName: "print"
  },
  {
    uuid: "ff64e927-981e-449e-945a-8cc55c3e0b57",
    iconSet: "Ionicons",
    iconName: "print-outline"
  },
  {
    uuid: "bdc9794d-29b4-42e1-a91f-192ee203d7ca",
    iconSet: "Ionicons",
    iconName: "print-sharp"
  },
  {
    uuid: "a1da22cc-abaa-411e-b1a5-d12d98a24c60",
    iconSet: "Ionicons",
    iconName: "pulse"
  },
  {
    uuid: "bcaa5d09-ca4a-44f0-bb57-b2574f35eff5",
    iconSet: "Ionicons",
    iconName: "pulse-outline"
  },
  {
    uuid: "c4710a34-48b9-4147-bb78-6e5062880a9d",
    iconSet: "Ionicons",
    iconName: "pulse-sharp"
  },
  {
    uuid: "9a05a78c-f44e-47b6-9d8c-33abcdca3da7",
    iconSet: "Ionicons",
    iconName: "push"
  },
  {
    uuid: "718b2bc5-05e9-4d01-9b50-9dc38bc5f9ad",
    iconSet: "Ionicons",
    iconName: "push-outline"
  },
  {
    uuid: "115de965-5c24-4aa3-9a83-b9bd624bf50c",
    iconSet: "Ionicons",
    iconName: "push-sharp"
  },
  {
    uuid: "dd528f1c-5afe-486b-aa81-5f0f1c8f2467",
    iconSet: "Ionicons",
    iconName: "qr-code"
  },
  {
    uuid: "c2e0d83e-9a8c-45c2-9e21-4e93862ecc98",
    iconSet: "Ionicons",
    iconName: "qr-code-outline"
  },
  {
    uuid: "4c79f577-b0f2-43ed-ae7f-dcb40459ed37",
    iconSet: "Ionicons",
    iconName: "qr-code-sharp"
  },
  {
    uuid: "be371928-b559-42da-bbd2-b7eb0452bd56",
    iconSet: "Ionicons",
    iconName: "radio"
  },
  {
    uuid: "b65c4053-35fd-4c9f-a8a0-4f491e0c77d0",
    iconSet: "Ionicons",
    iconName: "radio-button-off"
  },
  {
    uuid: "50ab0eee-8372-421a-b609-aafa43370330",
    iconSet: "Ionicons",
    iconName: "radio-button-off-outline"
  },
  {
    uuid: "4affe9d9-bdce-4837-a77c-6e2808dbdef1",
    iconSet: "Ionicons",
    iconName: "radio-button-off-sharp"
  },
  {
    uuid: "0b3fb7a1-aee6-44cf-adaf-a949b9796ab3",
    iconSet: "Ionicons",
    iconName: "radio-button-on"
  },
  {
    uuid: "eb7eec23-72a0-47de-b26d-8b163eac217c",
    iconSet: "Ionicons",
    iconName: "radio-button-on-outline"
  },
  {
    uuid: "cf50e539-7f1d-4304-9f99-567385708128",
    iconSet: "Ionicons",
    iconName: "radio-button-on-sharp"
  },
  {
    uuid: "319018cd-2b6b-434e-8618-c8c415eee046",
    iconSet: "Ionicons",
    iconName: "radio-outline"
  },
  {
    uuid: "cc1f29fb-c261-4baf-ad04-05a0b796c918",
    iconSet: "Ionicons",
    iconName: "radio-sharp"
  },
  {
    uuid: "5ef1a975-5452-45a3-99b3-fbd05aec5d21",
    iconSet: "Ionicons",
    iconName: "rainy"
  },
  {
    uuid: "88aa27dd-2530-44b8-a7cd-5188fa55bfa9",
    iconSet: "Ionicons",
    iconName: "rainy-outline"
  },
  {
    uuid: "57e5d3c7-7011-4c7f-a2a1-b868fd45bc3e",
    iconSet: "Ionicons",
    iconName: "rainy-sharp"
  },
  {
    uuid: "885f77e7-d730-4aee-bcd2-608c562a8ecc",
    iconSet: "Ionicons",
    iconName: "reader"
  },
  {
    uuid: "75cb8703-8fe5-4127-bba3-8bf2a2c05321",
    iconSet: "Ionicons",
    iconName: "reader-outline"
  },
  {
    uuid: "8cdd09c5-43b8-4a5f-ad83-fefb9e63dfe5",
    iconSet: "Ionicons",
    iconName: "reader-sharp"
  },
  {
    uuid: "45adac7e-a117-4294-b92c-ebc57903cd93",
    iconSet: "Ionicons",
    iconName: "receipt"
  },
  {
    uuid: "8550bb5b-0756-4180-b079-e149fbf28f7a",
    iconSet: "Ionicons",
    iconName: "receipt-outline"
  },
  {
    uuid: "96280d03-a537-4221-8218-73148e3ffdda",
    iconSet: "Ionicons",
    iconName: "receipt-sharp"
  },
  {
    uuid: "7421167c-72ab-43ec-99e2-02f67f018125",
    iconSet: "Ionicons",
    iconName: "recording"
  },
  {
    uuid: "617ba455-a27c-4fbf-abcb-ebd7a92acb62",
    iconSet: "Ionicons",
    iconName: "recording-outline"
  },
  {
    uuid: "3c443e68-7902-4bec-b8c5-f9b1988374db",
    iconSet: "Ionicons",
    iconName: "recording-sharp"
  },
  {
    uuid: "9a70b94c-df52-42b9-a06a-96aadb4bab05",
    iconSet: "Ionicons",
    iconName: "refresh"
  },
  {
    uuid: "00b59fb5-7ee7-4f9f-b4d1-2ac49825f3ad",
    iconSet: "Ionicons",
    iconName: "refresh-circle"
  },
  {
    uuid: "492fa6c0-a843-4671-9a59-75f6ccb12c40",
    iconSet: "Ionicons",
    iconName: "refresh-circle-outline"
  },
  {
    uuid: "a99afffe-5e9c-455c-adb4-6b17fb7e7fb7",
    iconSet: "Ionicons",
    iconName: "refresh-circle-sharp"
  },
  {
    uuid: "9636ffb9-1cef-4e37-8dbd-911b4914e97f",
    iconSet: "Ionicons",
    iconName: "refresh-outline"
  },
  {
    uuid: "0cfa73e4-7f7d-4ac9-970d-86cef72a04d9",
    iconSet: "Ionicons",
    iconName: "refresh-sharp"
  },
  {
    uuid: "d737e381-c8d0-4619-851d-f0d417bc46df",
    iconSet: "Ionicons",
    iconName: "reload"
  },
  {
    uuid: "c3c54d2c-2c24-4c19-aa00-c9498da3c576",
    iconSet: "Ionicons",
    iconName: "reload-circle"
  },
  {
    uuid: "1185e0d6-6e91-4427-aad9-f1f9066fdec4",
    iconSet: "Ionicons",
    iconName: "reload-circle-outline"
  },
  {
    uuid: "5a38a4aa-e22a-44af-bf77-f433c0d9ec59",
    iconSet: "Ionicons",
    iconName: "reload-circle-sharp"
  },
  {
    uuid: "a2f8e7d9-a3cb-4c07-80ac-66e89f548b22",
    iconSet: "Ionicons",
    iconName: "reload-outline"
  },
  {
    uuid: "817f89c9-a084-4309-9ffa-b353281057b4",
    iconSet: "Ionicons",
    iconName: "reload-sharp"
  },
  {
    uuid: "255bb48f-da32-441a-8d17-c65e1ac3c284",
    iconSet: "Ionicons",
    iconName: "remove"
  },
  {
    uuid: "eb06435d-c749-455b-89e1-be74495e98fa",
    iconSet: "Ionicons",
    iconName: "remove-circle"
  },
  {
    uuid: "7eb37386-1bea-4b45-84b9-ba7f1f95b4ea",
    iconSet: "Ionicons",
    iconName: "remove-circle-outline"
  },
  {
    uuid: "eaf99526-3648-4006-8c57-9d9a182cbb7d",
    iconSet: "Ionicons",
    iconName: "remove-circle-sharp"
  },
  {
    uuid: "a72a51cc-97c7-411c-80a2-4cf44c7b8438",
    iconSet: "Ionicons",
    iconName: "remove-outline"
  },
  {
    uuid: "a7a662c1-a877-49ed-8a42-d91bacf51422",
    iconSet: "Ionicons",
    iconName: "remove-sharp"
  },
  {
    uuid: "66f9e788-281d-4a8c-a0b2-a7c3d47582f5",
    iconSet: "Ionicons",
    iconName: "reorder-four"
  },
  {
    uuid: "3595019e-804e-4c32-b61a-730dd83aef00",
    iconSet: "Ionicons",
    iconName: "reorder-four-outline"
  },
  {
    uuid: "c71841aa-985a-41c4-a7f6-83c4c9fba761",
    iconSet: "Ionicons",
    iconName: "reorder-four-sharp"
  },
  {
    uuid: "44c915f8-d936-46d6-a0cf-95bc39c42421",
    iconSet: "Ionicons",
    iconName: "reorder-three"
  },
  {
    uuid: "e281b33f-7b7d-4cc9-b195-fd1b51c43102",
    iconSet: "Ionicons",
    iconName: "reorder-three-outline"
  },
  {
    uuid: "9ba6bb4a-f52c-4baa-893b-9de697fa2244",
    iconSet: "Ionicons",
    iconName: "reorder-three-sharp"
  },
  {
    uuid: "17703d70-5e46-490f-b76c-188e67171c02",
    iconSet: "Ionicons",
    iconName: "reorder-two"
  },
  {
    uuid: "60bbdb81-f19a-4e4e-86fd-98047d502d35",
    iconSet: "Ionicons",
    iconName: "reorder-two-outline"
  },
  {
    uuid: "13c2cbcb-1dcb-4151-bd7a-42fc0113d622",
    iconSet: "Ionicons",
    iconName: "reorder-two-sharp"
  },
  {
    uuid: "4da5230c-8aaa-4d83-8e1b-3f3fc8930312",
    iconSet: "Ionicons",
    iconName: "repeat"
  },
  {
    uuid: "6900923c-4328-4ad4-8363-ab4228c20f1d",
    iconSet: "Ionicons",
    iconName: "repeat-outline"
  },
  {
    uuid: "61d4891d-9cb0-4b27-89d6-e412549c6cad",
    iconSet: "Ionicons",
    iconName: "repeat-sharp"
  },
  {
    uuid: "9aaa6945-7bc8-4a3b-b488-2ccba9d969d6",
    iconSet: "Ionicons",
    iconName: "resize"
  },
  {
    uuid: "7473dbc7-4357-4dbe-a9d3-9c26fd1b3d60",
    iconSet: "Ionicons",
    iconName: "resize-outline"
  },
  {
    uuid: "2a8b2881-cf48-4fdf-bbe6-944b34850ce0",
    iconSet: "Ionicons",
    iconName: "resize-sharp"
  },
  {
    uuid: "e9be71f8-a707-44d0-b09d-7248a00680c5",
    iconSet: "Ionicons",
    iconName: "restaurant"
  },
  {
    uuid: "5bf24715-7019-48b5-b0c5-2c2269473806",
    iconSet: "Ionicons",
    iconName: "restaurant-outline"
  },
  {
    uuid: "27f232ed-7372-4fbd-92bc-c7a86826a8d1",
    iconSet: "Ionicons",
    iconName: "restaurant-sharp"
  },
  {
    uuid: "fbaaa2ac-e07f-4170-90f2-545dfe28d8f9",
    iconSet: "Ionicons",
    iconName: "return-down-back"
  },
  {
    uuid: "dc8a7315-c573-443c-bd10-e4233e4ade34",
    iconSet: "Ionicons",
    iconName: "return-down-back-outline"
  },
  {
    uuid: "f6226462-1205-4476-81ca-7cd3c07597f0",
    iconSet: "Ionicons",
    iconName: "return-down-back-sharp"
  },
  {
    uuid: "7c4fe28c-5e2e-4856-b023-2be02b807fa2",
    iconSet: "Ionicons",
    iconName: "return-down-forward"
  },
  {
    uuid: "decde46e-97be-4d70-bf6e-650fc7ce15aa",
    iconSet: "Ionicons",
    iconName: "return-down-forward-outline"
  },
  {
    uuid: "8da7bd92-ed53-4fb4-96e1-73e4d150fb0a",
    iconSet: "Ionicons",
    iconName: "return-down-forward-sharp"
  },
  {
    uuid: "edd70a67-4e67-443e-810b-eb957b1b6143",
    iconSet: "Ionicons",
    iconName: "return-up-back"
  },
  {
    uuid: "83974e92-d53a-4d3a-a413-80eb44b82b65",
    iconSet: "Ionicons",
    iconName: "return-up-back-outline"
  },
  {
    uuid: "c097e517-d0bd-447a-9a8f-5cb8cdb1f067",
    iconSet: "Ionicons",
    iconName: "return-up-back-sharp"
  },
  {
    uuid: "cbd8125e-1929-4237-8964-490c4f8b5865",
    iconSet: "Ionicons",
    iconName: "return-up-forward"
  },
  {
    uuid: "962e75a4-7a63-4c86-a04e-03cfecf68c0f",
    iconSet: "Ionicons",
    iconName: "return-up-forward-outline"
  },
  {
    uuid: "91bb5bd2-ff36-4ebb-b58f-3159fa576647",
    iconSet: "Ionicons",
    iconName: "return-up-forward-sharp"
  },
  {
    uuid: "6b8275b8-331a-4009-9ff9-af45a6a4b8bd",
    iconSet: "Ionicons",
    iconName: "ribbon"
  },
  {
    uuid: "13d74c58-c380-4adc-acb0-02d2bf2b8378",
    iconSet: "Ionicons",
    iconName: "ribbon-outline"
  },
  {
    uuid: "3a9e3d12-681f-4373-b331-1f1b4b19e3b0",
    iconSet: "Ionicons",
    iconName: "ribbon-sharp"
  },
  {
    uuid: "97d4f3af-940e-4ddf-a3d4-9feac8474cca",
    iconSet: "Ionicons",
    iconName: "rocket"
  },
  {
    uuid: "d95dff5f-33fa-47cd-9264-1fae2a48dc72",
    iconSet: "Ionicons",
    iconName: "rocket-outline"
  },
  {
    uuid: "e5b7d9ad-c76f-4b7e-9e77-7b1f4333f69f",
    iconSet: "Ionicons",
    iconName: "rocket-sharp"
  },
  {
    uuid: "fb065a61-1390-45e3-83bc-f22766419c43",
    iconSet: "Ionicons",
    iconName: "rose"
  },
  {
    uuid: "9e10b12b-dba6-4bf1-b409-78bdd67ebe53",
    iconSet: "Ionicons",
    iconName: "rose-outline"
  },
  {
    uuid: "08790165-ad13-4d42-82b6-39d7c4ac4da0",
    iconSet: "Ionicons",
    iconName: "rose-sharp"
  },
  {
    uuid: "915e51a6-50a6-4dd3-bd27-c7ad275de21b",
    iconSet: "Ionicons",
    iconName: "sad"
  },
  {
    uuid: "231d048b-aaca-4685-b3fc-036b02b22ada",
    iconSet: "Ionicons",
    iconName: "sad-outline"
  },
  {
    uuid: "2b84e2cb-650e-4a23-a0d1-3d85d158264b",
    iconSet: "Ionicons",
    iconName: "sad-sharp"
  },
  {
    uuid: "9bebd744-787e-4125-a6c2-42688be1a3c5",
    iconSet: "Ionicons",
    iconName: "save"
  },
  {
    uuid: "2f1e9aa1-5fa9-4d68-b65e-8b73aa28ef18",
    iconSet: "Ionicons",
    iconName: "save-outline"
  },
  {
    uuid: "37f03e9f-8fbc-4387-a049-c0ec7b81399b",
    iconSet: "Ionicons",
    iconName: "save-sharp"
  },
  {
    uuid: "f9fb15e8-2ccf-448d-8a1c-b7e272c3a970",
    iconSet: "Ionicons",
    iconName: "scan"
  },
  {
    uuid: "63ed587f-710f-4308-a44c-5ce656fffc5f",
    iconSet: "Ionicons",
    iconName: "scan-circle"
  },
  {
    uuid: "a5c27391-a0c4-46d2-b8ef-246b4525006c",
    iconSet: "Ionicons",
    iconName: "scan-circle-outline"
  },
  {
    uuid: "f786feb1-444a-40eb-a9c0-cea8a957665e",
    iconSet: "Ionicons",
    iconName: "scan-circle-sharp"
  },
  {
    uuid: "b97fc2b7-23e7-4407-928f-93f08b1a993e",
    iconSet: "Ionicons",
    iconName: "scan-outline"
  },
  {
    uuid: "095c95ba-c2ed-4347-ae29-1331ec294b61",
    iconSet: "Ionicons",
    iconName: "scan-sharp"
  },
  {
    uuid: "e2b60a4a-9491-4ca1-b527-a1eefbd28d2c",
    iconSet: "Ionicons",
    iconName: "school"
  },
  {
    uuid: "6d24a003-7062-4f56-bfef-91f03bdbe56d",
    iconSet: "Ionicons",
    iconName: "school-outline"
  },
  {
    uuid: "1d936f5b-e334-4a98-869d-7dccece39c35",
    iconSet: "Ionicons",
    iconName: "school-sharp"
  },
  {
    uuid: "2b288899-33e8-418d-bce8-0f874407af9c",
    iconSet: "Ionicons",
    iconName: "search"
  },
  {
    uuid: "ddd22e9e-438b-416e-b95e-ca6ba803bdbd",
    iconSet: "Ionicons",
    iconName: "search-circle"
  },
  {
    uuid: "9caf1821-d00b-4c47-99a8-a97794556a26",
    iconSet: "Ionicons",
    iconName: "search-circle-outline"
  },
  {
    uuid: "64950410-712c-421b-a5ff-8ddea8e8fe0f",
    iconSet: "Ionicons",
    iconName: "search-circle-sharp"
  },
  {
    uuid: "ab8c79d6-806e-4923-ae61-34c433a4e919",
    iconSet: "Ionicons",
    iconName: "search-outline"
  },
  {
    uuid: "15757375-5ee5-479d-9f98-2903f759861a",
    iconSet: "Ionicons",
    iconName: "search-sharp"
  },
  {
    uuid: "80907b90-e7bb-469e-a1bd-bbb7d851d46e",
    iconSet: "Ionicons",
    iconName: "send"
  },
  {
    uuid: "5f1418f0-678f-4287-9b92-710ac98278af",
    iconSet: "Ionicons",
    iconName: "send-outline"
  },
  {
    uuid: "fd971a9f-4d36-412f-9554-e2571e6c489d",
    iconSet: "Ionicons",
    iconName: "send-sharp"
  },
  {
    uuid: "7b7242aa-1a2c-4351-8753-464c93a95874",
    iconSet: "Ionicons",
    iconName: "server"
  },
  {
    uuid: "c872f052-5bf0-4578-976d-e11e87562fd5",
    iconSet: "Ionicons",
    iconName: "server-outline"
  },
  {
    uuid: "c2a9fa9b-3082-4438-ac32-e352485d6029",
    iconSet: "Ionicons",
    iconName: "server-sharp"
  },
  {
    uuid: "097d7a01-0c34-4974-8f86-b870e72d78cf",
    iconSet: "Ionicons",
    iconName: "settings"
  },
  {
    uuid: "11393758-2970-41ef-a69a-934c0bb96fd8",
    iconSet: "Ionicons",
    iconName: "settings-outline"
  },
  {
    uuid: "57b9c3ef-685a-4446-9d49-5457666a014c",
    iconSet: "Ionicons",
    iconName: "settings-sharp"
  },
  {
    uuid: "0cea6c6f-e86f-49bf-b1b4-78da2ecfe6ea",
    iconSet: "Ionicons",
    iconName: "shapes"
  },
  {
    uuid: "5669ffc1-8ae7-44de-b42c-ce2b60439e8e",
    iconSet: "Ionicons",
    iconName: "shapes-outline"
  },
  {
    uuid: "5d3a0742-6aaf-48d1-82fb-2f7ca4478479",
    iconSet: "Ionicons",
    iconName: "shapes-sharp"
  },
  {
    uuid: "09d4d0a2-cf26-49c0-987b-a66f17dd08d0",
    iconSet: "Ionicons",
    iconName: "share"
  },
  {
    uuid: "788398a0-e3e5-4bab-9670-cb73ccf78128",
    iconSet: "Ionicons",
    iconName: "share-outline"
  },
  {
    uuid: "8c578335-32c2-4427-891b-dfbb96b57a51",
    iconSet: "Ionicons",
    iconName: "share-sharp"
  },
  {
    uuid: "6d1c1da8-b365-4131-845b-758f57d2ae62",
    iconSet: "Ionicons",
    iconName: "share-social"
  },
  {
    uuid: "8c9327df-9e13-4870-82e8-fff9f3564547",
    iconSet: "Ionicons",
    iconName: "share-social-outline"
  },
  {
    uuid: "2f5affd8-502c-4984-bd44-1d06cb9fe4d7",
    iconSet: "Ionicons",
    iconName: "share-social-sharp"
  },
  {
    uuid: "45ae8fe1-b039-4b2c-b3ec-7c3c8f1d53e1",
    iconSet: "Ionicons",
    iconName: "shield"
  },
  {
    uuid: "e5b88fd9-0426-42a6-9e78-6f2c88f02437",
    iconSet: "Ionicons",
    iconName: "shield-checkmark"
  },
  {
    uuid: "14a55bfc-9480-46a5-ad91-a9fe31081281",
    iconSet: "Ionicons",
    iconName: "shield-checkmark-outline"
  },
  {
    uuid: "15e3927f-4da2-4e2d-9e7a-c2cf2f7d559e",
    iconSet: "Ionicons",
    iconName: "shield-checkmark-sharp"
  },
  {
    uuid: "5fabadd4-ecc1-4017-b010-c2d5e166fc99",
    iconSet: "Ionicons",
    iconName: "shield-outline"
  },
  {
    uuid: "ed997380-f81c-47e5-89b0-a112cda3af4b",
    iconSet: "Ionicons",
    iconName: "shield-sharp"
  },
  {
    uuid: "242f4205-a2ff-48c4-a227-4bd9a2796c31",
    iconSet: "Ionicons",
    iconName: "shirt"
  },
  {
    uuid: "8fad60f0-e1c5-4688-af85-1bcf34d3a6c4",
    iconSet: "Ionicons",
    iconName: "shirt-outline"
  },
  {
    uuid: "71dd13ce-3802-49fe-b8d6-33a73756c863",
    iconSet: "Ionicons",
    iconName: "shirt-sharp"
  },
  {
    uuid: "fb39e5d9-8cc9-418e-9902-48c3bbcaf480",
    iconSet: "Ionicons",
    iconName: "shuffle"
  },
  {
    uuid: "e2b626e5-177f-455d-9ee4-b6216a26f5c9",
    iconSet: "Ionicons",
    iconName: "shuffle-outline"
  },
  {
    uuid: "3dccebd2-7a12-4fab-95b2-7273fa81a616",
    iconSet: "Ionicons",
    iconName: "shuffle-sharp"
  },
  {
    uuid: "e6f71664-3c84-4edb-9a8c-8d0d8b87df65",
    iconSet: "Ionicons",
    iconName: "skull"
  },
  {
    uuid: "88504d05-d62d-4e51-a385-6b9b47946fb2",
    iconSet: "Ionicons",
    iconName: "skull-outline"
  },
  {
    uuid: "ce97d739-9e6a-4df5-8b1e-95f72dab33d8",
    iconSet: "Ionicons",
    iconName: "skull-sharp"
  },
  {
    uuid: "56af1f45-f4ba-4314-b835-04cbdac56655",
    iconSet: "Ionicons",
    iconName: "snow"
  },
  {
    uuid: "79220067-9b88-44e8-812a-d4305f70b342",
    iconSet: "Ionicons",
    iconName: "snow-outline"
  },
  {
    uuid: "ea78fe45-bc77-47ae-b917-55cc18f0a40e",
    iconSet: "Ionicons",
    iconName: "snow-sharp"
  },
  {
    uuid: "489d329f-8aea-4052-a7ef-4bba894ff988",
    iconSet: "Ionicons",
    iconName: "speedometer"
  },
  {
    uuid: "c1c21918-3bba-4066-a9c1-9cd68f8a03d7",
    iconSet: "Ionicons",
    iconName: "speedometer-outline"
  },
  {
    uuid: "4d30bd55-cebc-48c1-bf71-3620c702dea6",
    iconSet: "Ionicons",
    iconName: "speedometer-sharp"
  },
  {
    uuid: "7b9aeef3-8295-4061-adcd-adac042e9a40",
    iconSet: "Ionicons",
    iconName: "square"
  },
  {
    uuid: "5adb4f39-f6f8-4950-b6ea-9e0fdae3ffea",
    iconSet: "Ionicons",
    iconName: "square-outline"
  },
  {
    uuid: "1835eab6-913d-40e6-b926-6f65c93415ad",
    iconSet: "Ionicons",
    iconName: "square-sharp"
  },
  {
    uuid: "4ab33fe7-7166-4c0f-9204-9c9138a5e9bd",
    iconSet: "Ionicons",
    iconName: "star"
  },
  {
    uuid: "92563f05-c61f-4193-82b9-a5bc73b865d3",
    iconSet: "Ionicons",
    iconName: "star-half"
  },
  {
    uuid: "8c44b830-7322-4654-883b-3a9f9f9bc216",
    iconSet: "Ionicons",
    iconName: "star-half-outline"
  },
  {
    uuid: "581d3466-40c9-4c94-b578-71c70b2e2951",
    iconSet: "Ionicons",
    iconName: "star-half-sharp"
  },
  {
    uuid: "2e892afe-86a9-4cd5-93f4-d16713ba0091",
    iconSet: "Ionicons",
    iconName: "star-outline"
  },
  {
    uuid: "ff2f4d9e-0231-4396-857a-781e949f2660",
    iconSet: "Ionicons",
    iconName: "star-sharp"
  },
  {
    uuid: "77219001-4d17-48c9-8420-87349272d090",
    iconSet: "Ionicons",
    iconName: "stats-chart"
  },
  {
    uuid: "af45a034-2bd4-4969-ad8b-eb1f672b7e75",
    iconSet: "Ionicons",
    iconName: "stats-chart-outline"
  },
  {
    uuid: "8ccafc0f-5b48-4713-9617-148185dbe1e9",
    iconSet: "Ionicons",
    iconName: "stats-chart-sharp"
  },
  {
    uuid: "de4b1010-2a4d-4152-880a-ba30e29c9fea",
    iconSet: "Ionicons",
    iconName: "stop"
  },
  {
    uuid: "f71289db-5a94-4c94-801a-b3cf6cee964a",
    iconSet: "Ionicons",
    iconName: "stop-circle"
  },
  {
    uuid: "91c8fda9-7804-4aea-8a68-49173338a086",
    iconSet: "Ionicons",
    iconName: "stop-circle-outline"
  },
  {
    uuid: "aed356dd-ed03-4ddc-a912-59a07e035c2d",
    iconSet: "Ionicons",
    iconName: "stop-circle-sharp"
  },
  {
    uuid: "fae4adf6-e514-4499-80a6-03d621ab7160",
    iconSet: "Ionicons",
    iconName: "stop-outline"
  },
  {
    uuid: "7d325044-14a9-41b0-ad23-74ecddeec880",
    iconSet: "Ionicons",
    iconName: "stop-sharp"
  },
  {
    uuid: "60187f60-7cb8-47ac-9249-96d7538b5ecd",
    iconSet: "Ionicons",
    iconName: "stopwatch"
  },
  {
    uuid: "41338c27-e757-4f7f-a8c7-3087d87f9158",
    iconSet: "Ionicons",
    iconName: "stopwatch-outline"
  },
  {
    uuid: "787ffb61-caa7-4c4d-8559-ab1c16fa6eda",
    iconSet: "Ionicons",
    iconName: "stopwatch-sharp"
  },
  {
    uuid: "b1821890-e6bd-4ee9-872a-4744f756ac8e",
    iconSet: "Ionicons",
    iconName: "subway"
  },
  {
    uuid: "a066d0f8-178c-4a20-a11e-dd9ed0b245a5",
    iconSet: "Ionicons",
    iconName: "subway-outline"
  },
  {
    uuid: "48e20da5-6d92-4666-8a7d-bd5f4c7022cb",
    iconSet: "Ionicons",
    iconName: "subway-sharp"
  },
  {
    uuid: "86aab037-af24-4317-8cb6-8dfb7c8aabbe",
    iconSet: "Ionicons",
    iconName: "sunny"
  },
  {
    uuid: "ce535d06-1ba9-45c8-925a-e3d244e4f301",
    iconSet: "Ionicons",
    iconName: "sunny-outline"
  },
  {
    uuid: "0f4156e9-ac2a-405a-b354-edd796291531",
    iconSet: "Ionicons",
    iconName: "sunny-sharp"
  },
  {
    uuid: "4bf4bc16-af85-49e3-83d3-776d4c43e270",
    iconSet: "Ionicons",
    iconName: "swap-horizontal"
  },
  {
    uuid: "040cf52f-1bdc-45ea-bdf3-badc818c2158",
    iconSet: "Ionicons",
    iconName: "swap-horizontal-outline"
  },
  {
    uuid: "da9f9ac2-99ff-4ea3-9818-a4f508dcf8c3",
    iconSet: "Ionicons",
    iconName: "swap-horizontal-sharp"
  },
  {
    uuid: "4b5029d5-75ea-4f40-93c1-5380a927dba8",
    iconSet: "Ionicons",
    iconName: "swap-vertical"
  },
  {
    uuid: "d46676e3-eb15-4c58-88c1-b44c8b9329d5",
    iconSet: "Ionicons",
    iconName: "swap-vertical-outline"
  },
  {
    uuid: "da0085f9-579b-4845-89f1-891fae951c4e",
    iconSet: "Ionicons",
    iconName: "swap-vertical-sharp"
  },
  {
    uuid: "62224b05-394a-4c0d-874c-e121da7baad5",
    iconSet: "Ionicons",
    iconName: "sync"
  },
  {
    uuid: "4ca2d76c-81d6-4539-8b9e-0ed7f905a4c3",
    iconSet: "Ionicons",
    iconName: "sync-circle"
  },
  {
    uuid: "a5458d0b-2708-4b08-b106-4c5247394757",
    iconSet: "Ionicons",
    iconName: "sync-circle-outline"
  },
  {
    uuid: "b358b344-7e75-489b-83c2-95926200d0bf",
    iconSet: "Ionicons",
    iconName: "sync-circle-sharp"
  },
  {
    uuid: "6afae342-6246-49ed-8ede-f7fe3d1d0d41",
    iconSet: "Ionicons",
    iconName: "sync-outline"
  },
  {
    uuid: "2aa07c76-d640-4052-9a2f-4d8c56885aa0",
    iconSet: "Ionicons",
    iconName: "sync-sharp"
  },
  {
    uuid: "1513620b-0306-406d-ad35-cd49eff7e785",
    iconSet: "Ionicons",
    iconName: "tablet-landscape"
  },
  {
    uuid: "3e382812-dab0-4b34-b2a2-7e252d1bb064",
    iconSet: "Ionicons",
    iconName: "tablet-landscape-outline"
  },
  {
    uuid: "13fb00cd-072a-410a-846f-9a2ff6e76ee4",
    iconSet: "Ionicons",
    iconName: "tablet-landscape-sharp"
  },
  {
    uuid: "91e31cad-7aff-4491-b65e-5e5f7e11fa8c",
    iconSet: "Ionicons",
    iconName: "tablet-portrait"
  },
  {
    uuid: "0bc02c2d-0870-42b6-b891-814dc8550bdb",
    iconSet: "Ionicons",
    iconName: "tablet-portrait-outline"
  },
  {
    uuid: "88974f10-4a9d-47a7-b3c3-309fc9430442",
    iconSet: "Ionicons",
    iconName: "tablet-portrait-sharp"
  },
  {
    uuid: "96ba5d36-6a4b-424c-90d9-2805f8230ce1",
    iconSet: "Ionicons",
    iconName: "tennisball"
  },
  {
    uuid: "a98496d6-a1a7-432c-abce-294ebae8e7fe",
    iconSet: "Ionicons",
    iconName: "tennisball-outline"
  },
  {
    uuid: "b61e9fd0-4751-4f86-8f1f-ec2f18f3eaaa",
    iconSet: "Ionicons",
    iconName: "tennisball-sharp"
  },
  {
    uuid: "74d1afb4-a5bd-4c43-8654-ecd73e45bdc7",
    iconSet: "Ionicons",
    iconName: "terminal"
  },
  {
    uuid: "be0dab67-30e1-4a4d-997b-d0341a4f5def",
    iconSet: "Ionicons",
    iconName: "terminal-outline"
  },
  {
    uuid: "45088670-dd35-4866-b6fa-a124839fcdb0",
    iconSet: "Ionicons",
    iconName: "terminal-sharp"
  },
  {
    uuid: "2123bd36-d27b-47ca-9eed-77f63e6db037",
    iconSet: "Ionicons",
    iconName: "text"
  },
  {
    uuid: "3bdd815e-cf28-4723-8c35-ff21c2397d53",
    iconSet: "Ionicons",
    iconName: "text-outline"
  },
  {
    uuid: "d35b0864-87ee-4819-9e0d-7ba85c511a9f",
    iconSet: "Ionicons",
    iconName: "text-sharp"
  },
  {
    uuid: "278c2a22-39b7-4734-88f1-9721d3907758",
    iconSet: "Ionicons",
    iconName: "thermometer"
  },
  {
    uuid: "bb7abfdc-4382-4169-ba58-87bcebae2641",
    iconSet: "Ionicons",
    iconName: "thermometer-outline"
  },
  {
    uuid: "75effe3e-1451-4cfd-9f44-910070954dcd",
    iconSet: "Ionicons",
    iconName: "thermometer-sharp"
  },
  {
    uuid: "c9528455-92be-4873-a1a7-5336702dc855",
    iconSet: "Ionicons",
    iconName: "thumbs-down"
  },
  {
    uuid: "1fa0eee1-5419-45d1-87a5-f23531a133c2",
    iconSet: "Ionicons",
    iconName: "thumbs-down-outline"
  },
  {
    uuid: "562f54b2-ddd2-4209-905e-bb99b10bc73a",
    iconSet: "Ionicons",
    iconName: "thumbs-down-sharp"
  },
  {
    uuid: "c162e71a-f56f-41a2-8cbb-e90ea09e4ce5",
    iconSet: "Ionicons",
    iconName: "thumbs-up"
  },
  {
    uuid: "b6343c2e-8aa4-49b9-be80-496185f45ba3",
    iconSet: "Ionicons",
    iconName: "thumbs-up-outline"
  },
  {
    uuid: "76530d1b-2be2-4de0-8df3-1e98e0fcedf3",
    iconSet: "Ionicons",
    iconName: "thumbs-up-sharp"
  },
  {
    uuid: "718670d1-b6b8-424b-95d0-8a3ef666f810",
    iconSet: "Ionicons",
    iconName: "thunderstorm"
  },
  {
    uuid: "9a8b7487-5b20-49b6-9b58-a1720842b065",
    iconSet: "Ionicons",
    iconName: "thunderstorm-outline"
  },
  {
    uuid: "d9206cd1-b6e1-43e2-862b-c97b61309b5d",
    iconSet: "Ionicons",
    iconName: "thunderstorm-sharp"
  },
  {
    uuid: "c15c98bd-8d65-42a5-b012-5fe29234777d",
    iconSet: "Ionicons",
    iconName: "time"
  },
  {
    uuid: "524791f2-42d5-4f90-a5cf-ab8f3173734a",
    iconSet: "Ionicons",
    iconName: "time-outline"
  },
  {
    uuid: "2fc1ae6f-43c3-4eeb-8070-d99e230c7ffb",
    iconSet: "Ionicons",
    iconName: "time-sharp"
  },
  {
    uuid: "f3838a74-d439-4689-b59a-afb6d0e252c0",
    iconSet: "Ionicons",
    iconName: "timer"
  },
  {
    uuid: "6086d2b4-31a6-4279-926b-c586587179e7",
    iconSet: "Ionicons",
    iconName: "timer-outline"
  },
  {
    uuid: "865c6325-8acf-42bf-b951-ffab177b37f8",
    iconSet: "Ionicons",
    iconName: "timer-sharp"
  },
  {
    uuid: "72714e55-7660-4ea7-bace-c6bd965fcd07",
    iconSet: "Ionicons",
    iconName: "today"
  },
  {
    uuid: "89062204-d62d-491b-a834-d41ff686fb4c",
    iconSet: "Ionicons",
    iconName: "today-outline"
  },
  {
    uuid: "3a28bb8f-d62c-400d-8b6c-220d96d5cbea",
    iconSet: "Ionicons",
    iconName: "today-sharp"
  },
  {
    uuid: "540f3edf-3e87-4eff-8cfa-49f045b33d39",
    iconSet: "Ionicons",
    iconName: "toggle"
  },
  {
    uuid: "6cfe1c25-7b1d-4e1d-8275-b53f280aac2a",
    iconSet: "Ionicons",
    iconName: "toggle-outline"
  },
  {
    uuid: "69ca0f84-09d6-4f16-bc42-2de02e381b40",
    iconSet: "Ionicons",
    iconName: "toggle-sharp"
  },
  {
    uuid: "686a80cf-bd77-48cc-ba44-1aaed680c372",
    iconSet: "Ionicons",
    iconName: "trail-sign"
  },
  {
    uuid: "cbd56b2a-d46b-4a49-ae35-b3577cde2e46",
    iconSet: "Ionicons",
    iconName: "trail-sign-outline"
  },
  {
    uuid: "9ac11789-3f07-42be-a953-298e3da3069d",
    iconSet: "Ionicons",
    iconName: "trail-sign-sharp"
  },
  {
    uuid: "fe29985d-b7dc-4af7-af1e-ce521d5d4c2f",
    iconSet: "Ionicons",
    iconName: "train"
  },
  {
    uuid: "15383b4c-1d5b-4e13-b017-414ed480bf26",
    iconSet: "Ionicons",
    iconName: "train-outline"
  },
  {
    uuid: "6bf480ba-aaa7-4146-9ece-d67beb96e489",
    iconSet: "Ionicons",
    iconName: "train-sharp"
  },
  {
    uuid: "456b1229-ae24-4534-a686-4529325bbae8",
    iconSet: "Ionicons",
    iconName: "transgender"
  },
  {
    uuid: "21e4f980-03ab-4f36-bd59-77608894f3a7",
    iconSet: "Ionicons",
    iconName: "transgender-outline"
  },
  {
    uuid: "ef6ea441-3243-4c63-8419-09dea7f9e57d",
    iconSet: "Ionicons",
    iconName: "transgender-sharp"
  },
  {
    uuid: "ec79581a-c8a5-4021-a5c1-82cc8e51ea00",
    iconSet: "Ionicons",
    iconName: "trash"
  },
  {
    uuid: "3b49d27d-d6b4-44a8-9a0f-a37b14e178f2",
    iconSet: "Ionicons",
    iconName: "trash-bin"
  },
  {
    uuid: "1c0cd112-d2f1-41f7-b78d-bc8098afd992",
    iconSet: "Ionicons",
    iconName: "trash-bin-outline"
  },
  {
    uuid: "ae380f7a-1b91-450a-ac78-c717cfeed121",
    iconSet: "Ionicons",
    iconName: "trash-bin-sharp"
  },
  {
    uuid: "27ada0ae-9949-455e-ac78-8b467674a736",
    iconSet: "Ionicons",
    iconName: "trash-outline"
  },
  {
    uuid: "1badce8b-d622-4173-b060-ef497c88b554",
    iconSet: "Ionicons",
    iconName: "trash-sharp"
  },
  {
    uuid: "42b3014a-5316-4d18-a751-ffb4ba023a3d",
    iconSet: "Ionicons",
    iconName: "trending-down"
  },
  {
    uuid: "bea7528b-e0a0-4853-96ff-9d35c8f4d0f4",
    iconSet: "Ionicons",
    iconName: "trending-down-outline"
  },
  {
    uuid: "35cc8e96-3bac-4dd7-84bb-978d0ab10685",
    iconSet: "Ionicons",
    iconName: "trending-down-sharp"
  },
  {
    uuid: "bb85ed2c-2aad-48bc-a7de-cad0a9c31615",
    iconSet: "Ionicons",
    iconName: "trending-up"
  },
  {
    uuid: "14752dbe-3137-4ce9-b211-aecd661a185e",
    iconSet: "Ionicons",
    iconName: "trending-up-outline"
  },
  {
    uuid: "26a58151-6793-4f37-88fa-9cae7d8bd36c",
    iconSet: "Ionicons",
    iconName: "trending-up-sharp"
  },
  {
    uuid: "e8551807-b21e-46da-90ba-ab1889ed70d6",
    iconSet: "Ionicons",
    iconName: "triangle"
  },
  {
    uuid: "b61ecd1c-a35d-4417-8375-1998a1d8d481",
    iconSet: "Ionicons",
    iconName: "triangle-outline"
  },
  {
    uuid: "f6f3ae2c-4676-4a69-90a3-d4070db32c1e",
    iconSet: "Ionicons",
    iconName: "triangle-sharp"
  },
  {
    uuid: "e8084e69-edb7-4e76-bc66-187b940f0ab6",
    iconSet: "Ionicons",
    iconName: "trophy"
  },
  {
    uuid: "c40a6ce3-d9d7-468f-a4ea-23292303b48f",
    iconSet: "Ionicons",
    iconName: "trophy-outline"
  },
  {
    uuid: "3a853632-64e4-4cff-9098-04aee44acf62",
    iconSet: "Ionicons",
    iconName: "trophy-sharp"
  },
  {
    uuid: "7e04ad85-e7b0-45fa-919a-7d711e437ebb",
    iconSet: "Ionicons",
    iconName: "tv"
  },
  {
    uuid: "270a47f3-9a21-450e-9238-0649d0826f1a",
    iconSet: "Ionicons",
    iconName: "tv-outline"
  },
  {
    uuid: "01f4741b-c9fb-412d-a96e-d89d695377bd",
    iconSet: "Ionicons",
    iconName: "tv-sharp"
  },
  {
    uuid: "dc219d14-4d0a-455e-a90f-31ce572ce20f",
    iconSet: "Ionicons",
    iconName: "umbrella"
  },
  {
    uuid: "d7f48baf-4bdf-45e0-937b-1b0098871398",
    iconSet: "Ionicons",
    iconName: "umbrella-outline"
  },
  {
    uuid: "b52242d8-0baf-4933-9f17-9ffd26899c93",
    iconSet: "Ionicons",
    iconName: "umbrella-sharp"
  },
  {
    uuid: "b2aff22a-8829-49e3-adfa-cf6ad62a6365",
    iconSet: "Ionicons",
    iconName: "videocam"
  },
  {
    uuid: "860d2e51-b5ec-415e-8b5d-48ec18854b8b",
    iconSet: "Ionicons",
    iconName: "videocam-outline"
  },
  {
    uuid: "e067a37e-ffa7-4fde-bce7-2165cb139c02",
    iconSet: "Ionicons",
    iconName: "videocam-sharp"
  },
  {
    uuid: "75577c80-4105-459b-8d30-84b10729242f",
    iconSet: "Ionicons",
    iconName: "volume-high"
  },
  {
    uuid: "bb84f3da-ce84-4f65-8e2d-bc97e7341560",
    iconSet: "Ionicons",
    iconName: "volume-high-outline"
  },
  {
    uuid: "65f325d2-ed83-4756-bd57-ac81601abebe",
    iconSet: "Ionicons",
    iconName: "volume-high-sharp"
  },
  {
    uuid: "92bdf853-055e-4424-8e4e-cdad77325e8b",
    iconSet: "Ionicons",
    iconName: "volume-low"
  },
  {
    uuid: "84803342-d54f-44d2-9d24-e9417fd26b34",
    iconSet: "Ionicons",
    iconName: "volume-low-outline"
  },
  {
    uuid: "24162a30-41a3-4a98-88e4-d203098fd0f6",
    iconSet: "Ionicons",
    iconName: "volume-low-sharp"
  },
  {
    uuid: "93d85688-e7e6-4013-b4a7-4201bf7b134c",
    iconSet: "Ionicons",
    iconName: "volume-medium"
  },
  {
    uuid: "e418b662-eb29-455d-8f82-0afb2af62e1e",
    iconSet: "Ionicons",
    iconName: "volume-medium-outline"
  },
  {
    uuid: "504065ef-d88d-4847-88be-db0b65411045",
    iconSet: "Ionicons",
    iconName: "volume-medium-sharp"
  },
  {
    uuid: "acca7bc3-1184-4fca-b30d-e95533c86322",
    iconSet: "Ionicons",
    iconName: "volume-mute"
  },
  {
    uuid: "7bf48291-629a-4ff3-8549-e5fc200bdba7",
    iconSet: "Ionicons",
    iconName: "volume-mute-outline"
  },
  {
    uuid: "ead96dc0-2708-4718-bedf-d2cd6e14e0e1",
    iconSet: "Ionicons",
    iconName: "volume-mute-sharp"
  },
  {
    uuid: "7e45eb82-bd37-4395-8d36-680558416d54",
    iconSet: "Ionicons",
    iconName: "volume-off"
  },
  {
    uuid: "8cd31acf-ae83-45ea-adbb-522f5a69085c",
    iconSet: "Ionicons",
    iconName: "volume-off-outline"
  },
  {
    uuid: "e6db8054-5344-47de-8538-34027e97a9a3",
    iconSet: "Ionicons",
    iconName: "volume-off-sharp"
  },
  {
    uuid: "c082d89a-f1b4-4087-b4fa-6d3a4b00bef0",
    iconSet: "Ionicons",
    iconName: "walk"
  },
  {
    uuid: "66aa772b-6497-460f-b377-72dcae1e14a2",
    iconSet: "Ionicons",
    iconName: "walk-outline"
  },
  {
    uuid: "cb8f5b45-3bc0-4639-ae29-350d898b5195",
    iconSet: "Ionicons",
    iconName: "walk-sharp"
  },
  {
    uuid: "95671267-f920-41c3-9c18-628c3e0f6574",
    iconSet: "Ionicons",
    iconName: "wallet"
  },
  {
    uuid: "5f1dd95d-f5aa-4db6-981f-c4a1a8cba4d2",
    iconSet: "Ionicons",
    iconName: "wallet-outline"
  },
  {
    uuid: "ef2c7e12-527c-47ec-b4c1-3e1e42657ff7",
    iconSet: "Ionicons",
    iconName: "wallet-sharp"
  },
  {
    uuid: "1ed66b10-269a-4dac-b15f-088f7b67fdc3",
    iconSet: "Ionicons",
    iconName: "warning"
  },
  {
    uuid: "96fa0780-4513-4cdd-a96e-1591498bc223",
    iconSet: "Ionicons",
    iconName: "warning-outline"
  },
  {
    uuid: "bf42ec87-722f-4785-afc4-28d9038e2423",
    iconSet: "Ionicons",
    iconName: "warning-sharp"
  },
  {
    uuid: "2e7a836f-3ecd-4339-8060-b54019fd4a1d",
    iconSet: "Ionicons",
    iconName: "watch"
  },
  {
    uuid: "75795ee0-6c4f-4fe3-bd3f-2b109e467184",
    iconSet: "Ionicons",
    iconName: "watch-outline"
  },
  {
    uuid: "69483a1f-eb57-4e67-a0a7-73dfa1db97a6",
    iconSet: "Ionicons",
    iconName: "watch-sharp"
  },
  {
    uuid: "9e742975-8a32-4b63-88fd-1d539b1f0695",
    iconSet: "Ionicons",
    iconName: "water"
  },
  {
    uuid: "34305095-ae42-4a67-a2f1-60ef497e9e74",
    iconSet: "Ionicons",
    iconName: "water-outline"
  },
  {
    uuid: "95cbcc51-e767-458f-91c9-591b5b34e4d7",
    iconSet: "Ionicons",
    iconName: "water-sharp"
  },
  {
    uuid: "d4c6d29e-9e06-4684-bbcb-fd25016c4ba2",
    iconSet: "Ionicons",
    iconName: "wifi"
  },
  {
    uuid: "4ee79771-1139-4db3-afce-f5d4a4d4def3",
    iconSet: "Ionicons",
    iconName: "wifi-outline"
  },
  {
    uuid: "400a31ac-861a-4207-af09-1cfa26705544",
    iconSet: "Ionicons",
    iconName: "wifi-sharp"
  },
  {
    uuid: "3c9952d5-854e-4da5-8947-ee2e2690bc07",
    iconSet: "Ionicons",
    iconName: "wine"
  },
  {
    uuid: "838d7923-1a8a-4153-9564-213fef82161e",
    iconSet: "Ionicons",
    iconName: "wine-outline"
  },
  {
    uuid: "fa534ecb-3a17-41a6-8835-e1540249e9ab",
    iconSet: "Ionicons",
    iconName: "wine-sharp"
  },
  {
    uuid: "7303b482-ca26-4d6e-9a05-d5b557dca35b",
    iconSet: "Ionicons",
    iconName: "woman"
  },
  {
    uuid: "d95ab44f-86a1-4573-9f1f-4552ec94d1aa",
    iconSet: "Ionicons",
    iconName: "woman-outline"
  },
  {
    uuid: "544d9643-186e-482b-872e-2bb26a81c51d",
    iconSet: "Ionicons",
    iconName: "woman-sharp"
  },
  {
    uuid: "c6158c68-f86e-4fce-86bf-b2908912c38c",
    iconSet: "Ionicons",
    iconName: "add"
  },
  {
    uuid: "e92ba410-a4e6-4113-8c78-1ad012d419ce",
    iconSet: "Ionicons",
    iconName: "add-circle"
  },
  {
    uuid: "ade9d856-f352-4366-bca3-920179bc9d3f",
    iconSet: "Ionicons",
    iconName: "add-circle-outline"
  },
  {
    uuid: "92a2c090-f1b8-4f66-823a-25076e6fb5f3",
    iconSet: "Ionicons",
    iconName: "add-circle-sharp"
  },
  {
    uuid: "7c06636a-e00b-4ae2-b0a1-ba0654299323",
    iconSet: "Ionicons",
    iconName: "add-outline"
  },
  {
    uuid: "4d5893d2-2106-4499-83e7-741d3be62616",
    iconSet: "Ionicons",
    iconName: "add-sharp"
  },
  {
    uuid: "4a70a685-7a9c-4541-a617-0356d416d789",
    iconSet: "Ionicons",
    iconName: "airplane"
  },
  {
    uuid: "4cd4e56e-a5d8-48ee-8f5f-a8a248a79c14",
    iconSet: "Ionicons",
    iconName: "airplane-outline"
  },
  {
    uuid: "86be78ea-1daa-4145-b623-a1813b6614eb",
    iconSet: "Ionicons",
    iconName: "airplane-sharp"
  },
  {
    uuid: "a240da9a-bd7e-4c65-8db7-d03110581fa5",
    iconSet: "Ionicons",
    iconName: "alarm"
  },
  {
    uuid: "caa8bff4-ca8a-4500-b524-ddf771404a7a",
    iconSet: "Ionicons",
    iconName: "alarm-outline"
  },
  {
    uuid: "cc3407f1-8150-4258-a085-360330ed7dc6",
    iconSet: "Ionicons",
    iconName: "alarm-sharp"
  },
  {
    uuid: "db8f5945-2597-42f2-9cfa-3fab25900ab8",
    iconSet: "Ionicons",
    iconName: "albums"
  },
  {
    uuid: "649c53d3-59c9-4fba-97de-9dfd73f49020",
    iconSet: "Ionicons",
    iconName: "albums-outline"
  },
  {
    uuid: "ce0e3766-1dc3-4be5-bd9a-75736fd6d45b",
    iconSet: "Ionicons",
    iconName: "albums-sharp"
  },
  {
    uuid: "1b58990e-9bc8-4f78-a9c5-de768434254e",
    iconSet: "Ionicons",
    iconName: "alert"
  },
  {
    uuid: "0d94e973-c035-4751-897e-bbfcb79d6908",
    iconSet: "Ionicons",
    iconName: "alert-circle"
  },
  {
    uuid: "74ed4dea-473a-45b9-a015-304bd0887acb",
    iconSet: "Ionicons",
    iconName: "alert-circle-outline"
  },
  {
    uuid: "ee744e43-3a3f-4561-9afa-e79fdb6c3811",
    iconSet: "Ionicons",
    iconName: "alert-circle-sharp"
  },
  {
    uuid: "9c2298c0-98a8-450e-9fc2-9d185747ee45",
    iconSet: "Ionicons",
    iconName: "alert-outline"
  },
  {
    uuid: "da4b1ac8-f153-43c1-8f37-2cd864e4146a",
    iconSet: "Ionicons",
    iconName: "alert-sharp"
  },
  {
    uuid: "842a8f84-e95f-4e36-b65c-b8db088115d0",
    iconSet: "Ionicons",
    iconName: "american-football"
  },
  {
    uuid: "8f3f8fed-1367-4b8c-aaf0-334519bfa715",
    iconSet: "Ionicons",
    iconName: "american-football-outline"
  },
  {
    uuid: "693abc3a-8e0e-46a9-bd53-2b0fe40306a0",
    iconSet: "Ionicons",
    iconName: "american-football-sharp"
  },
  {
    uuid: "713d07a4-2739-4dcc-8ef4-8907c23239f9",
    iconSet: "Ionicons",
    iconName: "analytics"
  },
  {
    uuid: "25afdf08-0cce-492e-bdbc-d9f8217f04db",
    iconSet: "Ionicons",
    iconName: "analytics-outline"
  },
  {
    uuid: "b0f05d29-ab3b-4af9-a864-458bcaae1ef5",
    iconSet: "Ionicons",
    iconName: "analytics-sharp"
  },
  {
    uuid: "9208daf5-3624-46ac-b1c4-13d92544da61",
    iconSet: "Ionicons",
    iconName: "aperture"
  },
  {
    uuid: "fd673842-8c70-495b-98fe-4a88ac65a211",
    iconSet: "Ionicons",
    iconName: "aperture-outline"
  },
  {
    uuid: "0fc5e887-fc33-4d8f-a1de-92ccbe7cfd40",
    iconSet: "Ionicons",
    iconName: "aperture-sharp"
  },
  {
    uuid: "d4670c68-b47f-4eab-ad22-cac369d51148",
    iconSet: "Ionicons",
    iconName: "apps"
  },
  {
    uuid: "e8328072-8bf7-4313-858e-111cd770f74b",
    iconSet: "Ionicons",
    iconName: "apps-outline"
  },
  {
    uuid: "f6a79362-4a31-4c46-a45e-c7cc0fe624c5",
    iconSet: "Ionicons",
    iconName: "apps-sharp"
  },
  {
    uuid: "01ac96a4-63c2-4992-a71f-1d9e2e2e1228",
    iconSet: "Ionicons",
    iconName: "archive"
  },
  {
    uuid: "1e34a193-a712-4ae0-ae4d-914fec381e9a",
    iconSet: "Ionicons",
    iconName: "archive-outline"
  },
  {
    uuid: "53d247c9-d235-4566-ae22-9d3a24d5b929",
    iconSet: "Ionicons",
    iconName: "archive-sharp"
  },
  {
    uuid: "44fd4035-373e-4d2c-9446-e40bee68d580",
    iconSet: "Ionicons",
    iconName: "arrow-back"
  },
  {
    uuid: "f6e1cd72-09c3-4d87-b3eb-c7f22fe86d6a",
    iconSet: "Ionicons",
    iconName: "arrow-back-circle"
  },
  {
    uuid: "6d6caa97-c657-4217-96a8-5463e95f3393",
    iconSet: "Ionicons",
    iconName: "arrow-back-circle-outline"
  },
  {
    uuid: "f1ee1ce4-5b62-4d73-b811-84a3df09f13b",
    iconSet: "Ionicons",
    iconName: "arrow-back-circle-sharp"
  },
  {
    uuid: "87e80ca1-e389-4d62-ab29-e6467a551cc1",
    iconSet: "Ionicons",
    iconName: "arrow-back-outline"
  },
  {
    uuid: "0ef0d1c5-2eba-497c-9478-eff269f995df",
    iconSet: "Ionicons",
    iconName: "arrow-back-sharp"
  },
  {
    uuid: "33bbdb8d-0597-49ea-8ddd-da261482b97d",
    iconSet: "Ionicons",
    iconName: "arrow-down"
  },
  {
    uuid: "d84014cb-2b5e-4e64-b55d-aeced19e3f16",
    iconSet: "Ionicons",
    iconName: "arrow-down-circle"
  },
  {
    uuid: "7969db04-b14c-461f-a413-f2b7ce19b2b5",
    iconSet: "Ionicons",
    iconName: "arrow-down-circle-outline"
  },
  {
    uuid: "2a56d70d-23e1-410c-99d4-4af799dba702",
    iconSet: "Ionicons",
    iconName: "arrow-down-circle-sharp"
  },
  {
    uuid: "05e6b4be-a2d4-4ae9-a331-4d712b6be8d8",
    iconSet: "Ionicons",
    iconName: "arrow-down-outline"
  },
  {
    uuid: "93e01543-fab4-4390-bdbf-afa013126eef",
    iconSet: "Ionicons",
    iconName: "arrow-down-sharp"
  },
  {
    uuid: "ec48dac9-29cb-4c8f-a2a3-d8a0296ab46b",
    iconSet: "Ionicons",
    iconName: "arrow-forward"
  },
  {
    uuid: "0aecf7a5-0fb6-4384-89d2-58d93a7789b5",
    iconSet: "Ionicons",
    iconName: "arrow-forward-circle"
  },
  {
    uuid: "0baa85b6-3614-4354-bcd3-4c48df61268c",
    iconSet: "Ionicons",
    iconName: "arrow-forward-circle-outline"
  },
  {
    uuid: "ead719a7-6d11-4e65-b56e-a15e4e26c7b3",
    iconSet: "Ionicons",
    iconName: "arrow-forward-circle-sharp"
  },
  {
    uuid: "ff5817bf-5385-4b19-8f2a-3fef3a75e6a6",
    iconSet: "Ionicons",
    iconName: "arrow-forward-outline"
  },
  {
    uuid: "4f063967-ff13-43a8-84c2-a361fd7d84c1",
    iconSet: "Ionicons",
    iconName: "arrow-forward-sharp"
  },
  {
    uuid: "f60fef47-f8f7-4438-9c67-a01bb01d9870",
    iconSet: "Ionicons",
    iconName: "arrow-redo"
  },
  {
    uuid: "c554f923-64f7-4333-92ed-d13737f2e36d",
    iconSet: "Ionicons",
    iconName: "arrow-redo-circle"
  },
  {
    uuid: "3188fbea-3805-4b4c-b477-79c4c07d0c01",
    iconSet: "Ionicons",
    iconName: "arrow-redo-circle-outline"
  },
  {
    uuid: "0c1ab5db-b5d8-4e53-82a3-2646717faad1",
    iconSet: "Ionicons",
    iconName: "arrow-redo-circle-sharp"
  },
  {
    uuid: "759ca74b-d1e8-4759-afb5-65f52ab2ace5",
    iconSet: "Ionicons",
    iconName: "arrow-redo-outline"
  },
  {
    uuid: "8df25c98-b21e-457e-a75d-e0c7dc26b7f0",
    iconSet: "Ionicons",
    iconName: "arrow-redo-sharp"
  },
  {
    uuid: "359e2d67-3e1c-4c3c-9e78-753560503bba",
    iconSet: "Ionicons",
    iconName: "arrow-undo"
  },
  {
    uuid: "cac1f162-07ad-4169-9094-2604faac5f60",
    iconSet: "Ionicons",
    iconName: "arrow-undo-circle"
  },
  {
    uuid: "b937fa25-bd30-4add-9dd0-5d49f5a29edc",
    iconSet: "Ionicons",
    iconName: "arrow-undo-circle-outline"
  },
  {
    uuid: "a78a4643-b8ed-40a1-9caa-ea38cc0189ef",
    iconSet: "Ionicons",
    iconName: "arrow-undo-circle-sharp"
  },
  {
    uuid: "b719dcf7-2d65-4545-8955-aa9548669710",
    iconSet: "Ionicons",
    iconName: "arrow-undo-outline"
  },
  {
    uuid: "54c1b70f-ad48-41cb-8749-ab9c41eaaf44",
    iconSet: "Ionicons",
    iconName: "arrow-undo-sharp"
  },
  {
    uuid: "f560020f-236a-4a95-9aff-83c08ff667ee",
    iconSet: "Ionicons",
    iconName: "arrow-up"
  },
  {
    uuid: "7c53351f-1860-49c1-8611-17d9b746794d",
    iconSet: "Ionicons",
    iconName: "arrow-up-circle"
  },
  {
    uuid: "62830916-1466-4246-8f15-430700ab98a5",
    iconSet: "Ionicons",
    iconName: "arrow-up-circle-outline"
  },
  {
    uuid: "60463b3b-e828-494d-8d5b-f519fc93540e",
    iconSet: "Ionicons",
    iconName: "arrow-up-circle-sharp"
  },
  {
    uuid: "44420498-5c41-4699-96c9-9675ce1bddb0",
    iconSet: "Ionicons",
    iconName: "arrow-up-outline"
  },
  {
    uuid: "ed7a95b5-e5e1-4716-a51e-e1eb146b216c",
    iconSet: "Ionicons",
    iconName: "arrow-up-sharp"
  },
  {
    uuid: "6c8ed699-383a-49fe-86db-678e0dd1508a",
    iconSet: "Ionicons",
    iconName: "at"
  },
  {
    uuid: "53eb0883-65a9-4153-bc01-16315ca77fa9",
    iconSet: "Ionicons",
    iconName: "at-circle"
  },
  {
    uuid: "bc82f82c-9470-4feb-ba9b-797a8cd35892",
    iconSet: "Ionicons",
    iconName: "at-circle-outline"
  },
  {
    uuid: "023c97f1-19fc-4c78-a6a9-25049d426760",
    iconSet: "Ionicons",
    iconName: "at-circle-sharp"
  },
  {
    uuid: "6c0ecf20-5a56-41ec-9abb-42372007883f",
    iconSet: "Ionicons",
    iconName: "at-outline"
  },
  {
    uuid: "a7dfaf35-ae1f-40d4-9cf3-be3672e00a42",
    iconSet: "Ionicons",
    iconName: "at-sharp"
  },
  {
    uuid: "02978fce-2224-4891-bc57-99a574c07ced",
    iconSet: "Ionicons",
    iconName: "attach"
  },
  {
    uuid: "bb3244bb-7b1e-4a96-9121-2cc575d6c3f8",
    iconSet: "Ionicons",
    iconName: "attach-outline"
  },
  {
    uuid: "a8445be8-1d25-43a7-aa8a-a38779f8863d",
    iconSet: "Ionicons",
    iconName: "attach-sharp"
  },
  {
    uuid: "20e6bcf2-f0b1-42da-aa91-89a6de6a18ca",
    iconSet: "Ionicons",
    iconName: "backspace"
  },
  {
    uuid: "232d8b3c-6060-48e8-b9c9-ea9cb3711356",
    iconSet: "Ionicons",
    iconName: "backspace-outline"
  },
  {
    uuid: "1f4e4ed3-13a0-4627-b5cc-4b6283cd38c8",
    iconSet: "Ionicons",
    iconName: "backspace-sharp"
  },
  {
    uuid: "9c31d345-a47d-456c-8e5d-cb656a83f054",
    iconSet: "Ionicons",
    iconName: "bandage"
  },
  {
    uuid: "e32d9717-9c34-4a3a-8b13-0d56572963cc",
    iconSet: "Ionicons",
    iconName: "bandage-outline"
  },
  {
    uuid: "24aeab90-0bd4-4af6-b8dc-339a0b946417",
    iconSet: "Ionicons",
    iconName: "bandage-sharp"
  },
  {
    uuid: "45d78272-b6b3-4b89-bb26-15b7a5dd5278",
    iconSet: "Ionicons",
    iconName: "bar-chart"
  },
  {
    uuid: "54c4969a-e8fe-4a5e-8db6-bbd03087330b",
    iconSet: "Ionicons",
    iconName: "bar-chart-outline"
  },
  {
    uuid: "c0af28cc-c86c-4bcf-9912-4e307071b969",
    iconSet: "Ionicons",
    iconName: "bar-chart-sharp"
  },
  {
    uuid: "f4c211f6-eed1-46d4-8109-e714c1b26c49",
    iconSet: "Ionicons",
    iconName: "barbell"
  },
  {
    uuid: "35be569a-9409-4db3-99f5-ead203af8666",
    iconSet: "Ionicons",
    iconName: "barbell-outline"
  },
  {
    uuid: "f87ce6e1-a43a-4f98-8aa3-fe24952a583a",
    iconSet: "Ionicons",
    iconName: "barbell-sharp"
  },
  {
    uuid: "8ec5fc23-6ca0-4320-ae9d-fc1b2bb9123c",
    iconSet: "Ionicons",
    iconName: "barcode"
  },
  {
    uuid: "daa33af2-8306-4bd6-a43d-711543e793a3",
    iconSet: "Ionicons",
    iconName: "barcode-outline"
  },
  {
    uuid: "3ecdab71-4b6f-4ffd-9a4f-5effe8e71a63",
    iconSet: "Ionicons",
    iconName: "barcode-sharp"
  },
  {
    uuid: "cde56c2a-8483-4466-bdb3-8653da0c8a3e",
    iconSet: "Ionicons",
    iconName: "baseball"
  },
  {
    uuid: "36d10578-987a-43ec-8094-3dbbe8ca66f1",
    iconSet: "Ionicons",
    iconName: "baseball-outline"
  },
  {
    uuid: "bf69f777-47d1-47df-8a41-2c5c93509f58",
    iconSet: "Ionicons",
    iconName: "baseball-sharp"
  },
  {
    uuid: "b35ae1c0-ff31-4c67-a5f6-e418b4453b22",
    iconSet: "Ionicons",
    iconName: "basket"
  },
  {
    uuid: "a3635178-88d4-4471-86c8-a2a72eca3c8c",
    iconSet: "Ionicons",
    iconName: "basket-outline"
  },
  {
    uuid: "0a9cc357-fda9-4395-89fa-a53321dc7eed",
    iconSet: "Ionicons",
    iconName: "basket-sharp"
  },
  {
    uuid: "86f9181a-5517-4fae-8ccc-a48d9bfd2be5",
    iconSet: "Ionicons",
    iconName: "basketball"
  },
  {
    uuid: "db9581c5-baac-4dc5-9d3d-04b02169fcfb",
    iconSet: "Ionicons",
    iconName: "basketball-outline"
  },
  {
    uuid: "649d025e-1011-4f59-b41f-527dbf9127bb",
    iconSet: "Ionicons",
    iconName: "basketball-sharp"
  },
  {
    uuid: "8279cde7-299b-4cf6-9108-cc7872d49be3",
    iconSet: "Ionicons",
    iconName: "battery-charging"
  },
  {
    uuid: "2636f22c-e87d-4df2-a94d-8e25dfc24076",
    iconSet: "Ionicons",
    iconName: "battery-charging-outline"
  },
  {
    uuid: "c8a4d892-436a-417c-b3ba-8d215910ce35",
    iconSet: "Ionicons",
    iconName: "battery-charging-sharp"
  },
  {
    uuid: "23c980c1-7020-4da5-b015-736f9aa49bd7",
    iconSet: "Ionicons",
    iconName: "battery-dead"
  },
  {
    uuid: "e15bc418-a1d6-4d94-9bf8-da8d5c2bc9ce",
    iconSet: "Ionicons",
    iconName: "battery-dead-outline"
  },
  {
    uuid: "495d51c2-a2a3-4e78-a0ce-060768c346af",
    iconSet: "Ionicons",
    iconName: "battery-dead-sharp"
  },
  {
    uuid: "bcb8a8a6-f779-4bd2-afd6-db809e4afe9e",
    iconSet: "Ionicons",
    iconName: "battery-full"
  },
  {
    uuid: "0393d9c9-ee4d-489e-9179-4c78727b66c2",
    iconSet: "Ionicons",
    iconName: "battery-full-outline"
  },
  {
    uuid: "bf12aeb6-2a82-4aec-84df-72ff54f71382",
    iconSet: "Ionicons",
    iconName: "battery-full-sharp"
  },
  {
    uuid: "c06550e2-57df-4fef-a952-2db2c88bd217",
    iconSet: "Ionicons",
    iconName: "battery-half"
  },
  {
    uuid: "992504ec-c6d2-4cda-9f31-def0e3ea8c88",
    iconSet: "Ionicons",
    iconName: "battery-half-outline"
  },
  {
    uuid: "27389d08-1c51-4668-924b-06938f183476",
    iconSet: "Ionicons",
    iconName: "battery-half-sharp"
  },
  {
    uuid: "e3bf346e-d86a-4438-a121-7f0f6f4c834e",
    iconSet: "Ionicons",
    iconName: "beaker"
  },
  {
    uuid: "073d501d-1793-4a12-9039-e5444d9e99da",
    iconSet: "Ionicons",
    iconName: "beaker-outline"
  },
  {
    uuid: "567518d2-9b5b-4837-b91a-2584d90ac5cd",
    iconSet: "Ionicons",
    iconName: "beaker-sharp"
  },
  {
    uuid: "1b1d3561-6fe8-42c3-a911-8f6fc6f26c05",
    iconSet: "Ionicons",
    iconName: "bed"
  },
  {
    uuid: "9fa40981-f91b-44bf-906d-9711435d1df1",
    iconSet: "Ionicons",
    iconName: "bed-outline"
  },
  {
    uuid: "38c53f66-9515-42cf-ab2e-301fcef20bb8",
    iconSet: "Ionicons",
    iconName: "bed-sharp"
  },
  {
    uuid: "118f63c3-786b-468d-99fb-e7f79d66994a",
    iconSet: "Ionicons",
    iconName: "beer"
  },
  {
    uuid: "d988e774-f382-4a30-ba10-32d4d48daad5",
    iconSet: "Ionicons",
    iconName: "beer-outline"
  },
  {
    uuid: "c5333bba-55d1-4e2a-be1e-de928071b470",
    iconSet: "Ionicons",
    iconName: "beer-sharp"
  },
  {
    uuid: "be4912e0-b734-48e1-a568-85e5c1bbff4d",
    iconSet: "Ionicons",
    iconName: "bicycle"
  },
  {
    uuid: "b6250642-c76c-47ab-9578-ea34feed90f5",
    iconSet: "Ionicons",
    iconName: "bicycle-outline"
  },
  {
    uuid: "8148dda3-e2af-46a7-9fd7-c46dfd958ed7",
    iconSet: "Ionicons",
    iconName: "bicycle-sharp"
  },
  {
    uuid: "22b0ec36-a78c-4e43-9493-20be5c504792",
    iconSet: "Ionicons",
    iconName: "bluetooth"
  },
  {
    uuid: "69ccd61e-9f54-4cb2-a4e3-a01470c5272a",
    iconSet: "Ionicons",
    iconName: "bluetooth-outline"
  },
  {
    uuid: "47d7fd26-7937-4084-8335-836c0d274e94",
    iconSet: "Ionicons",
    iconName: "bluetooth-sharp"
  },
  {
    uuid: "535f6a9b-5c2e-4032-b776-4e004ff44e0d",
    iconSet: "Ionicons",
    iconName: "boat"
  },
  {
    uuid: "e28ee6f0-d1af-4ddc-bdc3-bde26a06d100",
    iconSet: "Ionicons",
    iconName: "boat-outline"
  },
  {
    uuid: "e73563ad-21e8-430b-b073-de5bba2a0af8",
    iconSet: "Ionicons",
    iconName: "boat-sharp"
  },
  {
    uuid: "4ead2c12-7ab7-49ce-a4a7-5dcbdeba765b",
    iconSet: "Ionicons",
    iconName: "body"
  },
  {
    uuid: "f5612436-c070-4cb6-9066-3bb09fc89dab",
    iconSet: "Ionicons",
    iconName: "body-outline"
  },
  {
    uuid: "1792c993-58b1-4bbf-b161-365b8493d4d5",
    iconSet: "Ionicons",
    iconName: "body-sharp"
  },
  {
    uuid: "d0ad378d-d4e3-4c99-a1d2-41f1f67f1e4e",
    iconSet: "Ionicons",
    iconName: "bonfire"
  },
  {
    uuid: "04edd66d-2120-4fd3-8f96-405c2b8939d2",
    iconSet: "Ionicons",
    iconName: "bonfire-outline"
  },
  {
    uuid: "6dcba98e-b9ae-4889-8cb7-8dafd8e5492e",
    iconSet: "Ionicons",
    iconName: "bonfire-sharp"
  },
  {
    uuid: "b2201bc3-753e-478b-a1f2-908c09088907",
    iconSet: "Ionicons",
    iconName: "book"
  },
  {
    uuid: "3d15c2a7-9640-4860-ab21-12ccaf1da822",
    iconSet: "Ionicons",
    iconName: "book-outline"
  },
  {
    uuid: "11e4d4aa-9b4c-4a24-9e2c-74f12ad24f50",
    iconSet: "Ionicons",
    iconName: "book-sharp"
  },
  {
    uuid: "596203c0-f317-4284-8110-a74552562324",
    iconSet: "Ionicons",
    iconName: "bookmark"
  },
  {
    uuid: "2ba2659d-5131-42ba-a396-fdf4bae2ee6b",
    iconSet: "Ionicons",
    iconName: "bookmark-outline"
  },
  {
    uuid: "b3d2e20c-f6dc-43ef-8160-0f89fc2da954",
    iconSet: "Ionicons",
    iconName: "bookmark-sharp"
  },
  {
    uuid: "3e1a13c4-8545-4f57-b3f9-0ffc7b7f4711",
    iconSet: "Ionicons",
    iconName: "bookmarks"
  },
  {
    uuid: "59028de1-a275-48a5-8ce1-b863409a7fdc",
    iconSet: "Ionicons",
    iconName: "bookmarks-outline"
  },
  {
    uuid: "e1a67a47-2d74-4307-a38b-1a4ee6cad035",
    iconSet: "Ionicons",
    iconName: "bookmarks-sharp"
  },
  {
    uuid: "d4922eef-0ff1-45d6-a356-e2444c7d4c66",
    iconSet: "Ionicons",
    iconName: "briefcase"
  },
  {
    uuid: "63069afd-ea83-4501-adeb-934ec4b5df22",
    iconSet: "Ionicons",
    iconName: "briefcase-outline"
  },
  {
    uuid: "e53cfdd7-11ee-47a0-b75c-bde694b469d6",
    iconSet: "Ionicons",
    iconName: "briefcase-sharp"
  },
  {
    uuid: "986be46e-6469-43a8-975e-3b410d9d18d5",
    iconSet: "Ionicons",
    iconName: "browsers"
  },
  {
    uuid: "8b2c8747-4100-4c58-b9ec-8241baf78c90",
    iconSet: "Ionicons",
    iconName: "browsers-outline"
  },
  {
    uuid: "7dae1998-86eb-4004-8edc-1162a994fe8f",
    iconSet: "Ionicons",
    iconName: "browsers-sharp"
  },
  {
    uuid: "d5d589e7-7095-4551-9614-2e0f6a09c12b",
    iconSet: "Ionicons",
    iconName: "brush"
  },
  {
    uuid: "f1f38d42-0ad4-44f3-97e1-8995cd717b23",
    iconSet: "Ionicons",
    iconName: "brush-outline"
  },
  {
    uuid: "8c3d9034-2162-4685-92db-934d79e5beca",
    iconSet: "Ionicons",
    iconName: "brush-sharp"
  },
  {
    uuid: "8dbcccf8-0b2e-47c5-b9d2-e252187dde75",
    iconSet: "Ionicons",
    iconName: "bug"
  },
  {
    uuid: "92eb6a77-af20-48a8-8dec-3a6aa261cbcb",
    iconSet: "Ionicons",
    iconName: "bug-outline"
  },
  {
    uuid: "6dc249f8-6465-4979-a785-9e60d604f72b",
    iconSet: "Ionicons",
    iconName: "bug-sharp"
  },
  {
    uuid: "5275745a-d191-485e-9925-29e2ea77ab8b",
    iconSet: "Ionicons",
    iconName: "build"
  },
  {
    uuid: "faa9da62-6928-4785-a1d7-1eff57490c76",
    iconSet: "Ionicons",
    iconName: "build-outline"
  },
  {
    uuid: "82a54770-5769-493f-8f31-2064bcec1986",
    iconSet: "Ionicons",
    iconName: "build-sharp"
  },
  {
    uuid: "798b3bf3-c3c6-4f9e-bb34-7715153e23c9",
    iconSet: "Ionicons",
    iconName: "bulb"
  },
  {
    uuid: "df1023b9-0ed8-42e6-8422-2312d489bddf",
    iconSet: "Ionicons",
    iconName: "bulb-outline"
  },
  {
    uuid: "e8046564-0e30-4b54-b65f-d89100f95e94",
    iconSet: "Ionicons",
    iconName: "bulb-sharp"
  },
  {
    uuid: "26b04895-203f-4778-8e9f-7ea724f7ba5c",
    iconSet: "Ionicons",
    iconName: "bus"
  },
  {
    uuid: "c23e734c-f6c7-43fe-97cf-eca44557a731",
    iconSet: "Ionicons",
    iconName: "bus-outline"
  },
  {
    uuid: "44c60670-123b-4def-9ce6-2794d7e3b766",
    iconSet: "Ionicons",
    iconName: "bus-sharp"
  },
  {
    uuid: "16bba24b-a0c5-420a-802b-53cdd14c702d",
    iconSet: "Ionicons",
    iconName: "business"
  },
  {
    uuid: "7c2a140a-a878-4584-aa92-b9ab80c4ae41",
    iconSet: "Ionicons",
    iconName: "business-outline"
  },
  {
    uuid: "bb2f45e5-4622-4792-8903-8d3d2e8801ee",
    iconSet: "Ionicons",
    iconName: "business-sharp"
  },
  {
    uuid: "a4977c3e-65f0-4990-9010-8c5f4cc902ca",
    iconSet: "Ionicons",
    iconName: "cafe"
  },
  {
    uuid: "07ed7ce8-47a6-4f75-8077-751f89432017",
    iconSet: "Ionicons",
    iconName: "cafe-outline"
  },
  {
    uuid: "6e34be75-0e52-4afb-a80a-c78f49d7f0ff",
    iconSet: "Ionicons",
    iconName: "cafe-sharp"
  },
  {
    uuid: "840e67a5-00b9-48d9-945e-79eca9d7508c",
    iconSet: "Ionicons",
    iconName: "calculator"
  },
  {
    uuid: "fc83d150-c568-400e-b634-86b91c86de02",
    iconSet: "Ionicons",
    iconName: "calculator-outline"
  },
  {
    uuid: "88c2014a-4aec-46e6-b2fd-b0ab10ae666a",
    iconSet: "Ionicons",
    iconName: "calculator-sharp"
  },
  {
    uuid: "7b3e612e-5969-4cf0-8dc2-4a2e23a30e61",
    iconSet: "Ionicons",
    iconName: "calendar"
  },
  {
    uuid: "f1008327-c8bc-4110-a0ef-f72a2cf57316",
    iconSet: "Ionicons",
    iconName: "calendar-outline"
  },
  {
    uuid: "1cd620e4-af93-438a-ac12-cefd87f989d7",
    iconSet: "Ionicons",
    iconName: "calendar-sharp"
  },
  {
    uuid: "5380f574-c207-44cf-b999-34e86cc3b66c",
    iconSet: "Ionicons",
    iconName: "call"
  },
  {
    uuid: "0c355e2d-6efe-4ff2-b4a0-c1ecf11ad32d",
    iconSet: "Ionicons",
    iconName: "call-outline"
  },
  {
    uuid: "53d9586f-3688-4fb4-a13f-9880968c4c56",
    iconSet: "Ionicons",
    iconName: "call-sharp"
  },
  {
    uuid: "23b342ce-e49f-4a01-bde8-0af3c488f7b0",
    iconSet: "Ionicons",
    iconName: "camera"
  },
  {
    uuid: "1029b3c2-a871-4dcb-bec2-a42bc1e3b23b",
    iconSet: "Ionicons",
    iconName: "camera-outline"
  },
  {
    uuid: "d19c6fbe-9e51-4a39-9f1e-8ba473085f91",
    iconSet: "Ionicons",
    iconName: "camera-reverse"
  },
  {
    uuid: "bb19974d-5e03-4e71-b265-7eeee96bd934",
    iconSet: "Ionicons",
    iconName: "camera-reverse-outline"
  },
  {
    uuid: "a64ae2d1-e556-4062-8f8d-ed0cf9249a02",
    iconSet: "Ionicons",
    iconName: "camera-reverse-sharp"
  },
  {
    uuid: "600661eb-2a36-409f-b195-3adfb3601472",
    iconSet: "Ionicons",
    iconName: "camera-sharp"
  },
  {
    uuid: "fe51bfe3-4b67-43c8-8352-ac26f3082563",
    iconSet: "Ionicons",
    iconName: "car"
  },
  {
    uuid: "e2cedc64-017b-41d3-9239-882c8319010a",
    iconSet: "Ionicons",
    iconName: "car-outline"
  },
  {
    uuid: "4878f620-517a-4ed4-ae76-053c44ed3376",
    iconSet: "Ionicons",
    iconName: "car-sharp"
  },
  {
    uuid: "7c3ac3db-1d53-4a22-8fb2-2bc7cda029d9",
    iconSet: "Ionicons",
    iconName: "car-sport"
  },
  {
    uuid: "854a4590-25c8-461e-ab82-4aaa5677122c",
    iconSet: "Ionicons",
    iconName: "car-sport-outline"
  },
  {
    uuid: "445bc1fb-4b62-4364-bb2f-fa62247f88b7",
    iconSet: "Ionicons",
    iconName: "car-sport-sharp"
  },
  {
    uuid: "58907100-5aa5-41e8-ad73-b874cc6a4a63",
    iconSet: "Ionicons",
    iconName: "card"
  },
  {
    uuid: "67ac7851-b10d-4d0e-a710-2af9ce727d41",
    iconSet: "Ionicons",
    iconName: "card-outline"
  },
  {
    uuid: "fdff9ff2-1ad1-4275-abb3-6fed96203d84",
    iconSet: "Ionicons",
    iconName: "card-sharp"
  },
  {
    uuid: "83763338-ccac-4ef0-b9d4-36c2201b776a",
    iconSet: "Ionicons",
    iconName: "caret-back"
  },
  {
    uuid: "25ec03d3-2639-4a07-857f-9b8cca27525f",
    iconSet: "Ionicons",
    iconName: "caret-back-circle"
  },
  {
    uuid: "95756e2f-f8ea-4915-8cec-988da5f4fbc2",
    iconSet: "Ionicons",
    iconName: "caret-back-circle-outline"
  },
  {
    uuid: "11bc8381-2044-43b8-a254-d8b04e14b5e9",
    iconSet: "Ionicons",
    iconName: "caret-back-circle-sharp"
  },
  {
    uuid: "c538e5f3-797f-4304-8500-5f5f6581c074",
    iconSet: "Ionicons",
    iconName: "caret-back-outline"
  },
  {
    uuid: "5c377056-080a-4c27-9468-4bca4112fca8",
    iconSet: "Ionicons",
    iconName: "caret-back-sharp"
  },
  {
    uuid: "23fed4c5-f644-4e56-b718-da6856e03300",
    iconSet: "Ionicons",
    iconName: "caret-down"
  },
  {
    uuid: "9aaa4126-bb33-4aa7-92d2-9e58bc956221",
    iconSet: "Ionicons",
    iconName: "caret-down-circle"
  },
  {
    uuid: "3c24822a-b1c2-4882-9baa-f8be1f8cf12f",
    iconSet: "Ionicons",
    iconName: "caret-down-circle-outline"
  },
  {
    uuid: "469e4084-abd0-4999-92ec-428122f7d1b1",
    iconSet: "Ionicons",
    iconName: "caret-down-circle-sharp"
  },
  {
    uuid: "e605a0a8-fb33-4a50-9036-490a2ea2d97e",
    iconSet: "Ionicons",
    iconName: "caret-down-outline"
  },
  {
    uuid: "bb52b997-ddf9-4881-854b-136aff15c9d6",
    iconSet: "Ionicons",
    iconName: "caret-down-sharp"
  },
  {
    uuid: "b7484aec-113c-4411-a526-923618cb48f0",
    iconSet: "Ionicons",
    iconName: "caret-forward"
  },
  {
    uuid: "a283b408-0a24-4659-a955-8821803c4136",
    iconSet: "Ionicons",
    iconName: "caret-forward-circle"
  },
  {
    uuid: "8e441f5c-073e-41e8-b445-4c157b6baa70",
    iconSet: "Ionicons",
    iconName: "caret-forward-circle-outline"
  },
  {
    uuid: "4ae18bb4-a434-4376-9cf2-3421c8c955db",
    iconSet: "Ionicons",
    iconName: "caret-forward-circle-sharp"
  },
  {
    uuid: "fc9f1bb4-289e-4f34-b0ac-c75ca0d17af3",
    iconSet: "Ionicons",
    iconName: "caret-forward-outline"
  },
  {
    uuid: "b3b611d5-8e6f-43b9-b416-0cfadbb812e5",
    iconSet: "Ionicons",
    iconName: "caret-forward-sharp"
  },
  {
    uuid: "1380c9b0-53d3-4311-9d4e-93cb905acdf9",
    iconSet: "Ionicons",
    iconName: "caret-up"
  },
  {
    uuid: "a3015de7-56b2-4ac5-8af3-4ed08514e05d",
    iconSet: "Ionicons",
    iconName: "caret-up-circle"
  },
  {
    uuid: "89aa991f-ed54-4be8-a781-8cb9d993d0c5",
    iconSet: "Ionicons",
    iconName: "caret-up-circle-outline"
  },
  {
    uuid: "14dd7e36-94de-4fd7-9f6d-fd52b4b66a87",
    iconSet: "Ionicons",
    iconName: "caret-up-circle-sharp"
  },
  {
    uuid: "c934cfd7-f098-46fc-a784-becf4e3f8f2f",
    iconSet: "Ionicons",
    iconName: "caret-up-outline"
  },
  {
    uuid: "ed676721-02dd-4234-b24b-a2582307adfb",
    iconSet: "Ionicons",
    iconName: "caret-up-sharp"
  },
  {
    uuid: "0855dd3f-45b7-44f8-a382-ff02445c1b4e",
    iconSet: "Ionicons",
    iconName: "cart"
  },
  {
    uuid: "f6c74cee-b366-428c-a67a-69303a4f4a0d",
    iconSet: "Ionicons",
    iconName: "cart-outline"
  },
  {
    uuid: "130703ba-ff03-4090-a14d-8085aaf866f1",
    iconSet: "Ionicons",
    iconName: "cart-sharp"
  },
  {
    uuid: "32cbd49c-7cfc-4c4b-bc11-15449d30a39c",
    iconSet: "Ionicons",
    iconName: "cash"
  },
  {
    uuid: "e795a2f2-0bd5-422a-8f11-3dfab19b7403",
    iconSet: "Ionicons",
    iconName: "cash-outline"
  },
  {
    uuid: "129f9a38-fc54-48db-8856-48b55189aa64",
    iconSet: "Ionicons",
    iconName: "cash-sharp"
  },
  {
    uuid: "4a7107f9-df36-40ae-9d9a-17a42d6f7467",
    iconSet: "Ionicons",
    iconName: "cellular"
  },
  {
    uuid: "eb832bea-5f69-42a7-91ed-d92a31bf8a0d",
    iconSet: "Ionicons",
    iconName: "cellular-outline"
  },
  {
    uuid: "a14f2fcb-6b9e-41c7-9469-ae345c86dd89",
    iconSet: "Ionicons",
    iconName: "cellular-sharp"
  },
  {
    uuid: "f9fe8846-4727-41d9-a1f9-59e016db44cc",
    iconSet: "Ionicons",
    iconName: "chatbox"
  },
  {
    uuid: "ef78f81c-e375-4cd9-ab83-1d390ba49fc4",
    iconSet: "Ionicons",
    iconName: "chatbox-ellipses"
  },
  {
    uuid: "f2a98e8c-11bf-4f1a-8ca8-d8c7edef01c2",
    iconSet: "Ionicons",
    iconName: "chatbox-ellipses-outline"
  },
  {
    uuid: "c9a089b6-93b9-4edb-bf01-50b0494249e3",
    iconSet: "Ionicons",
    iconName: "chatbox-ellipses-sharp"
  },
  {
    uuid: "a591947b-3e67-40e8-be9f-96401c8e6eb3",
    iconSet: "Ionicons",
    iconName: "chatbox-outline"
  },
  {
    uuid: "ee0675c9-31a2-4f00-a407-faf8c9e743b2",
    iconSet: "Ionicons",
    iconName: "chatbox-sharp"
  },
  {
    uuid: "c7058d3a-f026-406b-8293-ec5aa625f578",
    iconSet: "Ionicons",
    iconName: "chatbubble"
  },
  {
    uuid: "1cfab638-e9e9-4c34-b9fb-cff055d75bcd",
    iconSet: "Ionicons",
    iconName: "chatbubble-ellipses"
  },
  {
    uuid: "f9627193-cb67-4fbf-8033-fe69cd22f46a",
    iconSet: "Ionicons",
    iconName: "chatbubble-ellipses-outline"
  },
  {
    uuid: "ec5a5338-4f14-42e5-a248-a7bdf45275b8",
    iconSet: "Ionicons",
    iconName: "chatbubble-ellipses-sharp"
  },
  {
    uuid: "7a67cf3e-2308-43f5-bc13-cf2aee02528d",
    iconSet: "Ionicons",
    iconName: "chatbubble-outline"
  },
  {
    uuid: "d755b6d7-dd44-4495-a2c9-62b685333720",
    iconSet: "Ionicons",
    iconName: "chatbubble-sharp"
  },
  {
    uuid: "628c1cb9-e89c-4a4d-b578-064949059b0a",
    iconSet: "Ionicons",
    iconName: "chatbubbles"
  },
  {
    uuid: "471edae1-29f4-4ad8-bf46-40ec9330787d",
    iconSet: "Ionicons",
    iconName: "chatbubbles-outline"
  },
  {
    uuid: "794572e3-95e7-44d1-98f7-6de55a19f4e7",
    iconSet: "Ionicons",
    iconName: "chatbubbles-sharp"
  },
  {
    uuid: "efa07c72-40d1-4df1-ba7f-346ac8359d4f",
    iconSet: "Ionicons",
    iconName: "checkbox"
  },
  {
    uuid: "a41a4949-33cd-4dbf-a1f6-f91a48f20aae",
    iconSet: "Ionicons",
    iconName: "checkbox-outline"
  },
  {
    uuid: "b5a85f67-9eab-476a-8997-c9883cf19ebb",
    iconSet: "Ionicons",
    iconName: "checkbox-sharp"
  },
  {
    uuid: "0487010d-b42c-4c31-b36e-618203cd1bdb",
    iconSet: "Ionicons",
    iconName: "checkmark"
  },
  {
    uuid: "cfdc4033-50f9-4378-a92d-05fae0e3cd9b",
    iconSet: "Ionicons",
    iconName: "checkmark-circle"
  },
  {
    uuid: "48d66c25-96b6-4a4f-9ee1-de4e17d0f7fb",
    iconSet: "Ionicons",
    iconName: "checkmark-circle-outline"
  },
  {
    uuid: "1b77bd0e-a730-43d1-baea-ff3250e9cb06",
    iconSet: "Ionicons",
    iconName: "checkmark-circle-sharp"
  },
  {
    uuid: "ae7ff22b-c9be-4c4c-bb9d-5d342e6420be",
    iconSet: "Ionicons",
    iconName: "checkmark-done"
  },
  {
    uuid: "1a71390d-946a-4eb4-9434-f006c063efe9",
    iconSet: "Ionicons",
    iconName: "checkmark-done-circle"
  },
  {
    uuid: "8ed93bb7-38f0-471a-bed4-0bdf126f7086",
    iconSet: "Ionicons",
    iconName: "checkmark-done-circle-outline"
  },
  {
    uuid: "9532007c-31d5-463b-b2a0-f546f09160db",
    iconSet: "Ionicons",
    iconName: "checkmark-done-circle-sharp"
  },
  {
    uuid: "134fd3cf-3a82-458e-9072-8b32d8b2a0d2",
    iconSet: "Ionicons",
    iconName: "checkmark-done-outline"
  },
  {
    uuid: "1ef648fd-7c76-46d7-87fe-c72c317d32b8",
    iconSet: "Ionicons",
    iconName: "checkmark-done-sharp"
  },
  {
    uuid: "3a84a5b2-158c-40da-ab5b-122d134b5c3c",
    iconSet: "Ionicons",
    iconName: "checkmark-outline"
  },
  {
    uuid: "80dae7da-6bf5-4141-984e-bf872d62041b",
    iconSet: "Ionicons",
    iconName: "checkmark-sharp"
  },
  {
    uuid: "9e8431ee-aa50-499d-95ab-4c9e1bf42b2a",
    iconSet: "Ionicons",
    iconName: "chevron-back"
  },
  {
    uuid: "cd20b6bd-476c-4482-9283-83213338b092",
    iconSet: "Ionicons",
    iconName: "chevron-back-circle"
  },
  {
    uuid: "799204b9-d48e-4187-b9a2-833d607fff97",
    iconSet: "Ionicons",
    iconName: "chevron-back-circle-outline"
  },
  {
    uuid: "29359ae9-a5fe-405e-ab90-147e7920d77a",
    iconSet: "Ionicons",
    iconName: "chevron-back-circle-sharp"
  },
  {
    uuid: "683f5642-63e1-4796-a895-cc65ed8779a4",
    iconSet: "Ionicons",
    iconName: "chevron-back-outline"
  },
  {
    uuid: "c2d6b2c0-bb4b-47b7-81fc-01ad9888d997",
    iconSet: "Ionicons",
    iconName: "chevron-back-sharp"
  },
  {
    uuid: "da639fbc-a801-445e-b3e3-1f60ec6e6ae6",
    iconSet: "Ionicons",
    iconName: "chevron-down"
  },
  {
    uuid: "9d2243f7-2429-48f1-a6e9-0b02ab541240",
    iconSet: "Ionicons",
    iconName: "chevron-down-circle"
  },
  {
    uuid: "00417bbb-32ca-42c8-b743-00de0d9be279",
    iconSet: "Ionicons",
    iconName: "chevron-down-circle-outline"
  },
  {
    uuid: "ba3b9a14-df6a-49af-957c-f9c16a1dc153",
    iconSet: "Ionicons",
    iconName: "chevron-down-circle-sharp"
  },
  {
    uuid: "d470f737-7b89-48ba-9211-0abc175ce6a1",
    iconSet: "Ionicons",
    iconName: "chevron-down-outline"
  },
  {
    uuid: "5cff472c-31b5-4cc4-bf5e-7c24ec72c8ec",
    iconSet: "Ionicons",
    iconName: "chevron-down-sharp"
  },
  {
    uuid: "b9e85400-63b4-4c5c-b6c7-4eb70622f3bc",
    iconSet: "Ionicons",
    iconName: "chevron-forward"
  },
  {
    uuid: "d5d8e5f8-1f22-4e6f-8cc2-12e8ec06f0ce",
    iconSet: "Ionicons",
    iconName: "chevron-forward-circle"
  },
  {
    uuid: "79960d4b-48d1-411b-85cb-36fdc3ee0ec4",
    iconSet: "Ionicons",
    iconName: "chevron-forward-circle-outline"
  },
  {
    uuid: "aa532a3f-a65d-4e53-8053-49d208996d01",
    iconSet: "Ionicons",
    iconName: "chevron-forward-circle-sharp"
  },
  {
    uuid: "a6542df1-c0ef-40be-b6fa-33c79d9a94df",
    iconSet: "Ionicons",
    iconName: "chevron-forward-outline"
  },
  {
    uuid: "ba3c61dc-dac4-4249-a577-7121bf6c02fc",
    iconSet: "Ionicons",
    iconName: "chevron-forward-sharp"
  },
  {
    uuid: "f1e8e03b-4eea-4687-b7b9-0af8bb9e99df",
    iconSet: "Ionicons",
    iconName: "chevron-up"
  },
  {
    uuid: "88cd02de-dbe0-4725-a1e5-495595a2cf3c",
    iconSet: "Ionicons",
    iconName: "chevron-up-circle"
  },
  {
    uuid: "9eca7900-0d3a-4c25-8d7c-6ee6f32dc947",
    iconSet: "Ionicons",
    iconName: "chevron-up-circle-outline"
  },
  {
    uuid: "de21ba5f-7b30-4714-8791-e2727eb3117c",
    iconSet: "Ionicons",
    iconName: "chevron-up-circle-sharp"
  },
  {
    uuid: "790a032a-bf8b-45ec-ad6d-61ad2ad1e6b9",
    iconSet: "Ionicons",
    iconName: "chevron-up-outline"
  },
  {
    uuid: "4c8c438c-a876-45cd-8e26-a47d042be908",
    iconSet: "Ionicons",
    iconName: "chevron-up-sharp"
  },
  {
    uuid: "2362e1f8-6282-4fce-a2b2-9b6945cad085",
    iconSet: "Ionicons",
    iconName: "clipboard"
  },
  {
    uuid: "2e5a90e1-cc1c-474f-97b3-cdb252799ee4",
    iconSet: "Ionicons",
    iconName: "clipboard-outline"
  },
  {
    uuid: "7f4efffc-0336-424f-8ddb-c847e132cef1",
    iconSet: "Ionicons",
    iconName: "clipboard-sharp"
  },
  {
    uuid: "b8f168c7-9a3c-48c4-9427-f4a226cd7f96",
    iconSet: "Ionicons",
    iconName: "close"
  },
  {
    uuid: "68830dea-214c-47c8-a292-33769e6a3bcd",
    iconSet: "Ionicons",
    iconName: "close-circle"
  },
  {
    uuid: "dddbe9c5-ca5d-4126-882d-df09b2408e23",
    iconSet: "Ionicons",
    iconName: "close-circle-outline"
  },
  {
    uuid: "0635280d-097a-4a9c-ae2b-aa39d7c641ea",
    iconSet: "Ionicons",
    iconName: "close-circle-sharp"
  },
  {
    uuid: "ae4cacf5-a763-443b-8701-a72d7494e6ac",
    iconSet: "Ionicons",
    iconName: "close-outline"
  },
  {
    uuid: "12c3808d-19a7-4a47-bd87-f60acaadf69b",
    iconSet: "Ionicons",
    iconName: "close-sharp"
  },
  {
    uuid: "82b0095e-0f13-4113-8822-da1f5e54a029",
    iconSet: "Ionicons",
    iconName: "cloud"
  },
  {
    uuid: "b3cca283-9d10-40b6-b07a-131f8a8e2053",
    iconSet: "Ionicons",
    iconName: "cloud-circle"
  },
  {
    uuid: "42b9fcd7-0f53-4109-8eda-e71645b121bd",
    iconSet: "Ionicons",
    iconName: "cloud-circle-outline"
  },
  {
    uuid: "f58d4c2d-35bc-4609-ada1-58a264381976",
    iconSet: "Ionicons",
    iconName: "cloud-circle-sharp"
  },
  {
    uuid: "a8b7b85a-23bc-4bec-8d24-24ded5a48155",
    iconSet: "Ionicons",
    iconName: "cloud-done"
  },
  {
    uuid: "2dff3f4d-4a49-488f-b82d-25b0c3b3b75b",
    iconSet: "Ionicons",
    iconName: "cloud-done-outline"
  },
  {
    uuid: "44b58345-899f-4545-b21b-03d608c19020",
    iconSet: "Ionicons",
    iconName: "cloud-done-sharp"
  },
  {
    uuid: "ba3f8e82-cb91-446d-a4d1-3b8ea03e63ae",
    iconSet: "Ionicons",
    iconName: "cloud-download"
  },
  {
    uuid: "2c5c004d-4832-47fa-91ce-1789d57cfd93",
    iconSet: "Ionicons",
    iconName: "cloud-download-outline"
  },
  {
    uuid: "f9772d3a-7f90-45e3-a18f-16f53bb0e3be",
    iconSet: "Ionicons",
    iconName: "cloud-download-sharp"
  },
  {
    uuid: "0c0a0747-9b0f-49d4-8f5d-f0d58f26dbb2",
    iconSet: "Ionicons",
    iconName: "cloud-offline"
  },
  {
    uuid: "4e5305ed-ad22-4e1f-8de6-cb7123ac67b7",
    iconSet: "Ionicons",
    iconName: "cloud-offline-outline"
  },
  {
    uuid: "981c1b3d-f480-4c82-a08a-bc212c89117d",
    iconSet: "Ionicons",
    iconName: "cloud-offline-sharp"
  },
  {
    uuid: "40c6dfba-e7c4-4bf0-b9ce-d2e5ae832ba9",
    iconSet: "Ionicons",
    iconName: "cloud-outline"
  },
  {
    uuid: "c09f128b-75e6-4422-a557-3e75b3a53a6c",
    iconSet: "Ionicons",
    iconName: "cloud-sharp"
  },
  {
    uuid: "bfa3f1e2-3f1b-4e2e-a61a-27f1fb7b9636",
    iconSet: "Ionicons",
    iconName: "cloud-upload"
  },
  {
    uuid: "a4fe2a32-62ca-4c59-b32e-789f4cff1538",
    iconSet: "Ionicons",
    iconName: "cloud-upload-outline"
  },
  {
    uuid: "ff35ab0f-1a38-4dc1-a909-930234cbeb81",
    iconSet: "Ionicons",
    iconName: "cloud-upload-sharp"
  },
  {
    uuid: "c93b91bd-6dfa-4b30-b16d-10fe8031ad4d",
    iconSet: "Ionicons",
    iconName: "cloudy"
  },
  {
    uuid: "7125eb0b-0a27-4589-93c6-8a69ee6eee8b",
    iconSet: "Ionicons",
    iconName: "cloudy-night"
  },
  {
    uuid: "9280984a-8ca9-4c1b-a75a-e0e849db59cc",
    iconSet: "Ionicons",
    iconName: "cloudy-night-outline"
  },
  {
    uuid: "f28fa450-9fec-40e0-803c-f4a0216fec3a",
    iconSet: "Ionicons",
    iconName: "cloudy-night-sharp"
  },
  {
    uuid: "a50338c8-37a8-489e-a4d8-d3670c716d82",
    iconSet: "Ionicons",
    iconName: "cloudy-outline"
  },
  {
    uuid: "4f2b72cb-9885-49fd-8d37-92dd482853f4",
    iconSet: "Ionicons",
    iconName: "cloudy-sharp"
  },
  {
    uuid: "d6a0c0a3-4e34-4015-a04b-8c323e01b2a2",
    iconSet: "Ionicons",
    iconName: "code"
  },
  {
    uuid: "68135248-d58e-40bd-a77e-7a2e475900dc",
    iconSet: "Ionicons",
    iconName: "code-download"
  },
  {
    uuid: "6e19898e-9fcd-4ee3-8b45-41946e2c9522",
    iconSet: "Ionicons",
    iconName: "code-download-outline"
  },
  {
    uuid: "74242992-37c1-45e6-8d1e-fc83cf55b2e1",
    iconSet: "Ionicons",
    iconName: "code-download-sharp"
  },
  {
    uuid: "6b78d42a-6568-43b2-b6d7-4ed0caba6734",
    iconSet: "Ionicons",
    iconName: "code-outline"
  },
  {
    uuid: "1254ac35-a330-4f16-8b96-d5fec10ca968",
    iconSet: "Ionicons",
    iconName: "code-sharp"
  },
  {
    uuid: "56c7b267-5da4-453f-9bd0-5f357e6b5b0a",
    iconSet: "Ionicons",
    iconName: "code-slash"
  },
  {
    uuid: "9fc2f5bf-98ec-48eb-b012-7a594192372b",
    iconSet: "Ionicons",
    iconName: "code-slash-outline"
  },
  {
    uuid: "7e927ce6-aab0-478f-bb6e-9359fc71e67a",
    iconSet: "Ionicons",
    iconName: "code-slash-sharp"
  },
  {
    uuid: "4bede02e-df76-43b4-bd86-e5d6bb668293",
    iconSet: "Ionicons",
    iconName: "code-working"
  },
  {
    uuid: "ff9448be-d454-4ee9-a6b5-2455deb34faa",
    iconSet: "Ionicons",
    iconName: "code-working-outline"
  },
  {
    uuid: "5e0d25f0-29a6-4ec3-a8f7-e7beeb84bfc7",
    iconSet: "Ionicons",
    iconName: "code-working-sharp"
  },
  {
    uuid: "4ab64892-70e1-4cab-9f82-09fed0342a34",
    iconSet: "Ionicons",
    iconName: "cog"
  },
  {
    uuid: "dac326a2-b45b-4988-9459-eeeb6a875f6d",
    iconSet: "Ionicons",
    iconName: "cog-outline"
  },
  {
    uuid: "a33654f2-071b-475d-8554-d37bab8a6361",
    iconSet: "Ionicons",
    iconName: "cog-sharp"
  },
  {
    uuid: "df96bcec-e5e1-4bfd-924e-0c3878ceb56d",
    iconSet: "Ionicons",
    iconName: "color-fill"
  },
  {
    uuid: "9c0ae910-a44a-41fb-b460-dfc51b6513fa",
    iconSet: "Ionicons",
    iconName: "color-fill-outline"
  },
  {
    uuid: "2137cc40-8ecc-4a38-bc4d-62788c9f333c",
    iconSet: "Ionicons",
    iconName: "color-fill-sharp"
  },
  {
    uuid: "b5e2e457-5c8f-44aa-ae3d-686d2c0e088d",
    iconSet: "Ionicons",
    iconName: "color-filter"
  },
  {
    uuid: "a4bbe35b-3b8c-4fce-804e-71adff57b585",
    iconSet: "Ionicons",
    iconName: "color-filter-outline"
  },
  {
    uuid: "ad629b8a-956e-423d-b6bf-7e23e5378d0c",
    iconSet: "Ionicons",
    iconName: "color-filter-sharp"
  },
  {
    uuid: "18cec79f-8dcd-42a1-ba84-355cee459cf7",
    iconSet: "Ionicons",
    iconName: "color-palette"
  },
  {
    uuid: "97179949-c63b-4432-af0b-6a927b1680bc",
    iconSet: "Ionicons",
    iconName: "color-palette-outline"
  },
  {
    uuid: "03082862-079e-4112-a5d8-a1869224e505",
    iconSet: "Ionicons",
    iconName: "color-palette-sharp"
  },
  {
    uuid: "5b7b8391-b6fb-4a68-b441-d021116d929a",
    iconSet: "Ionicons",
    iconName: "color-wand"
  },
  {
    uuid: "7a4473a8-a6d0-4e89-b9d4-4f578e253772",
    iconSet: "Ionicons",
    iconName: "color-wand-outline"
  },
  {
    uuid: "d4b29e1b-8a4f-44f7-b9c9-07573541f4bf",
    iconSet: "Ionicons",
    iconName: "color-wand-sharp"
  },
  {
    uuid: "464e9944-b3bc-485e-89b8-e695343db2b9",
    iconSet: "Ionicons",
    iconName: "compass"
  },
  {
    uuid: "4e662c3e-8cbd-4bab-a41a-d13436260dd0",
    iconSet: "Ionicons",
    iconName: "compass-outline"
  },
  {
    uuid: "db37f76d-7d42-4f62-934b-4df8e83c40ef",
    iconSet: "Ionicons",
    iconName: "compass-sharp"
  },
  {
    uuid: "1c211ab5-ecfb-43ec-8403-159f3de3dcfe",
    iconSet: "Ionicons",
    iconName: "construct"
  },
  {
    uuid: "e618c17c-d1dd-4795-85ce-ea8714dc3f8c",
    iconSet: "Ionicons",
    iconName: "construct-outline"
  },
  {
    uuid: "561f5d03-0d65-4b86-9c16-41bfb493977d",
    iconSet: "Ionicons",
    iconName: "construct-sharp"
  },
  {
    uuid: "7f22c99c-8be7-487e-b105-bda2fb18013a",
    iconSet: "Ionicons",
    iconName: "contract"
  },
  {
    uuid: "05a6d9e6-206f-4428-9521-b0d4da8cfe74",
    iconSet: "Ionicons",
    iconName: "contract-outline"
  },
  {
    uuid: "1dc7704f-31ef-4938-bbd8-d64b8bf64925",
    iconSet: "Ionicons",
    iconName: "contract-sharp"
  },
  {
    uuid: "29ceb2b1-8413-4b7f-a44a-e92b44219ccb",
    iconSet: "Ionicons",
    iconName: "contrast"
  },
  {
    uuid: "a39ec56e-9f89-4f83-891f-56157a32e098",
    iconSet: "Ionicons",
    iconName: "contrast-outline"
  },
  {
    uuid: "faf9cfe6-b275-492e-bf8d-e6c6edea5b7a",
    iconSet: "Ionicons",
    iconName: "contrast-sharp"
  },
  {
    uuid: "26cc92cd-c895-4423-bf4f-6bf5b3a9d406",
    iconSet: "Ionicons",
    iconName: "copy"
  },
  {
    uuid: "8cada1ca-a9fd-473f-b18c-5b4ad00efabe",
    iconSet: "Ionicons",
    iconName: "copy-outline"
  },
  {
    uuid: "68d88fae-cb9e-443c-b9e7-c8ce77dd40e2",
    iconSet: "Ionicons",
    iconName: "copy-sharp"
  },
  {
    uuid: "887a463d-35ac-4992-b19c-aae61e42f478",
    iconSet: "Ionicons",
    iconName: "create"
  },
  {
    uuid: "3bf5363f-1bb0-40dc-80fd-e815f70a5d78",
    iconSet: "Ionicons",
    iconName: "create-outline"
  },
  {
    uuid: "30671607-6698-4c6a-9f41-642fab879059",
    iconSet: "Ionicons",
    iconName: "create-sharp"
  },
  {
    uuid: "855c22b2-4101-4803-92e5-96a6411e99cf",
    iconSet: "Ionicons",
    iconName: "crop"
  },
  {
    uuid: "8c750cdf-1a0e-49bb-8d7a-cdd5ae1916c6",
    iconSet: "Ionicons",
    iconName: "crop-outline"
  },
  {
    uuid: "c7a9f542-6260-4b4d-98a5-a1f7a24db0b3",
    iconSet: "Ionicons",
    iconName: "crop-sharp"
  },
  {
    uuid: "96ae9f80-a0cb-4819-bc17-cdd2c83f8cf0",
    iconSet: "Ionicons",
    iconName: "cube"
  },
  {
    uuid: "f9146679-99b2-4810-b059-e5783fdd2862",
    iconSet: "Ionicons",
    iconName: "cube-outline"
  },
  {
    uuid: "9cd5ecbb-8c33-4682-9180-a62783499466",
    iconSet: "Ionicons",
    iconName: "cube-sharp"
  },
  {
    uuid: "353bdd86-8803-4a07-9685-0723cd91040c",
    iconSet: "Ionicons",
    iconName: "cut"
  },
  {
    uuid: "98bf45ce-97a7-4416-9ad3-8a1ab1f6dfe8",
    iconSet: "Ionicons",
    iconName: "cut-outline"
  },
  {
    uuid: "f9fed99a-195f-41ea-aa02-ca6a64f6810e",
    iconSet: "Ionicons",
    iconName: "cut-sharp"
  },
  {
    uuid: "25841524-03c9-4868-907b-f661b76da0b3",
    iconSet: "Ionicons",
    iconName: "desktop"
  },
  {
    uuid: "fbb41111-9e56-4ad0-a532-4a15c75969d2",
    iconSet: "Ionicons",
    iconName: "desktop-outline"
  },
  {
    uuid: "c64c209d-f7ac-42f4-89ce-a743144c0a71",
    iconSet: "Ionicons",
    iconName: "desktop-sharp"
  },
  {
    uuid: "f11fe96f-41ff-44b4-ad03-b64d823dcfc3",
    iconSet: "Ionicons",
    iconName: "disc"
  },
  {
    uuid: "a1442ce1-c6a2-4ee5-9f7e-9cdccf6534fa",
    iconSet: "Ionicons",
    iconName: "disc-outline"
  },
  {
    uuid: "9d66c64c-5835-43a7-8089-013c15c8da79",
    iconSet: "Ionicons",
    iconName: "disc-sharp"
  },
  {
    uuid: "787bab8f-7f99-475f-a709-85595a4326b8",
    iconSet: "Ionicons",
    iconName: "document"
  },
  {
    uuid: "d14af34d-94c7-40d8-a9bf-016254f90aa7",
    iconSet: "Ionicons",
    iconName: "document-attach"
  },
  {
    uuid: "c1730fba-854c-496c-b4a2-64d978871a30",
    iconSet: "Ionicons",
    iconName: "document-attach-outline"
  },
  {
    uuid: "2c80ff3a-c99b-4b36-acd6-047c1ac371e0",
    iconSet: "Ionicons",
    iconName: "document-attach-sharp"
  },
  {
    uuid: "b90607d2-8f68-4f9b-98a2-0a1d05d9dde3",
    iconSet: "Ionicons",
    iconName: "document-outline"
  },
  {
    uuid: "0328cbcb-7a42-4b99-ab9e-705cd6315eba",
    iconSet: "Ionicons",
    iconName: "document-sharp"
  },
  {
    uuid: "2850d4d3-cc4f-48b3-8c97-70cf119cbfcc",
    iconSet: "Ionicons",
    iconName: "document-text"
  },
  {
    uuid: "5dd35bb0-a2a7-4a29-8d7c-6d4f5819638e",
    iconSet: "Ionicons",
    iconName: "document-text-outline"
  },
  {
    uuid: "0a6d40af-0111-47b6-b647-418b616a7c12",
    iconSet: "Ionicons",
    iconName: "document-text-sharp"
  },
  {
    uuid: "fbd909fe-897c-4c45-9ce3-f91363a3e408",
    iconSet: "Ionicons",
    iconName: "documents"
  },
  {
    uuid: "81dbdde4-4fab-4e10-bf53-22ad01376152",
    iconSet: "Ionicons",
    iconName: "documents-outline"
  },
  {
    uuid: "be51f1c0-5648-476c-99d6-dae70cc3912d",
    iconSet: "Ionicons",
    iconName: "documents-sharp"
  },
  {
    uuid: "949c58a9-24a4-4a51-9e05-ccfb5888db31",
    iconSet: "Ionicons",
    iconName: "download"
  },
  {
    uuid: "5b64ee6e-4626-4758-a640-d58d9172f82a",
    iconSet: "Ionicons",
    iconName: "download-outline"
  },
  {
    uuid: "977ede15-84c1-418a-9edc-a2621bbd1e17",
    iconSet: "Ionicons",
    iconName: "download-sharp"
  },
  {
    uuid: "d462976c-0950-438d-b22f-d2b8e2c30543",
    iconSet: "Ionicons",
    iconName: "duplicate"
  },
  {
    uuid: "d1afa92b-6986-4ce7-aacd-9c5f60b88c04",
    iconSet: "Ionicons",
    iconName: "duplicate-outline"
  },
  {
    uuid: "29ff32ca-1786-4f91-a5d3-35df8c4caf2d",
    iconSet: "Ionicons",
    iconName: "duplicate-sharp"
  },
  {
    uuid: "cdbbdda9-36f7-45ec-a273-ddd9d537fc3c",
    iconSet: "Ionicons",
    iconName: "ear"
  },
  {
    uuid: "c9195c80-09ea-49b2-819d-68697b03c09e",
    iconSet: "Ionicons",
    iconName: "ear-outline"
  },
  {
    uuid: "02d7584f-c104-4e27-ad15-1046bbc5d62c",
    iconSet: "Ionicons",
    iconName: "ear-sharp"
  },
  {
    uuid: "be176166-99e8-4aea-b86f-4a113e050874",
    iconSet: "Ionicons",
    iconName: "earth"
  },
  {
    uuid: "60ba2baa-e6f8-4c7f-923c-c135b102f13f",
    iconSet: "Ionicons",
    iconName: "earth-outline"
  },
  {
    uuid: "e08e68a5-e19f-4a45-940b-0190696b6ea2",
    iconSet: "Ionicons",
    iconName: "earth-sharp"
  },
  {
    uuid: "f759a327-a6d6-4d46-af73-5b3b7b32eebb",
    iconSet: "Ionicons",
    iconName: "easel"
  },
  {
    uuid: "2fc6949c-0c3e-4f66-9a33-c0a109223d2f",
    iconSet: "Ionicons",
    iconName: "easel-outline"
  },
  {
    uuid: "7e60800a-17c3-4aca-b5cd-cff91b9daacc",
    iconSet: "Ionicons",
    iconName: "easel-sharp"
  },
  {
    uuid: "cc15a1fb-53e4-45c5-92ea-b1a552b8f700",
    iconSet: "Ionicons",
    iconName: "egg"
  },
  {
    uuid: "16136499-4e9c-474b-8c38-d8098171b974",
    iconSet: "Ionicons",
    iconName: "egg-outline"
  },
  {
    uuid: "629e7efc-c41c-42d6-9266-a147c44b27c4",
    iconSet: "Ionicons",
    iconName: "egg-sharp"
  },
  {
    uuid: "5f843f34-e622-43eb-b729-7f5981cf9a77",
    iconSet: "Ionicons",
    iconName: "ellipse"
  },
  {
    uuid: "a646736a-b901-4b45-bcc1-09ce5998448d",
    iconSet: "Ionicons",
    iconName: "ellipse-outline"
  },
  {
    uuid: "196857d0-2591-4bb6-a55b-7e4ba55a38cf",
    iconSet: "Ionicons",
    iconName: "ellipse-sharp"
  },
  {
    uuid: "a3c2f60d-dd8a-4db1-8a50-52053dd9d4d8",
    iconSet: "Ionicons",
    iconName: "ellipsis-horizontal"
  },
  {
    uuid: "3e25423a-a8ed-474e-be29-55f01e2d0824",
    iconSet: "Ionicons",
    iconName: "ellipsis-horizontal-circle"
  },
  {
    uuid: "7a7d0166-5bfb-4c54-ac53-f55c26df51fe",
    iconSet: "Ionicons",
    iconName: "ellipsis-horizontal-circle-outline"
  },
  {
    uuid: "3cb249ee-a202-4cba-90cb-c8bc921d3324",
    iconSet: "Ionicons",
    iconName: "ellipsis-horizontal-circle-sharp"
  },
  {
    uuid: "c5636659-bb7e-4f96-bbf6-5a17c29bf6b1",
    iconSet: "Ionicons",
    iconName: "ellipsis-horizontal-outline"
  },
  {
    uuid: "b02879a1-680d-4fac-b3af-223eda2d5594",
    iconSet: "Ionicons",
    iconName: "ellipsis-horizontal-sharp"
  },
  {
    uuid: "b7c04c5e-67aa-470a-a582-ca6161a3d825",
    iconSet: "Ionicons",
    iconName: "ellipsis-vertical"
  },
  {
    uuid: "30b9a037-4a46-4a72-959b-a6885c09da79",
    iconSet: "Ionicons",
    iconName: "ellipsis-vertical-circle"
  },
  {
    uuid: "5f3db815-558b-4fe0-9749-08be045288c5",
    iconSet: "Ionicons",
    iconName: "ellipsis-vertical-circle-outline"
  },
  {
    uuid: "d03f969b-cc8e-49e5-9697-372f0686c237",
    iconSet: "Ionicons",
    iconName: "ellipsis-vertical-circle-sharp"
  },
  {
    uuid: "586e1f36-efef-489b-a92f-b37c67ff4db7",
    iconSet: "Ionicons",
    iconName: "ellipsis-vertical-outline"
  },
  {
    uuid: "40c08132-dd10-42c7-898e-67b006ec6b64",
    iconSet: "Ionicons",
    iconName: "ellipsis-vertical-sharp"
  },
  {
    uuid: "acc24840-9314-420b-9ef4-1997facde954",
    iconSet: "Ionicons",
    iconName: "enter"
  },
  {
    uuid: "9802978e-209a-496e-9cde-49852195d13d",
    iconSet: "Ionicons",
    iconName: "enter-outline"
  },
  {
    uuid: "10bd0483-3505-48ab-a796-106a846c2f66",
    iconSet: "Ionicons",
    iconName: "enter-sharp"
  },
  {
    uuid: "990fc76b-b12f-4a90-994b-bdb4c3d06d6b",
    iconSet: "Ionicons",
    iconName: "exit"
  },
  {
    uuid: "bfad9124-8bce-4993-8d4d-c2dd52beb1d7",
    iconSet: "Ionicons",
    iconName: "exit-outline"
  },
  {
    uuid: "55579b29-f663-454f-bd4e-379cac40a5a3",
    iconSet: "Ionicons",
    iconName: "exit-sharp"
  },
  {
    uuid: "ae82f709-7c29-46b8-b6c0-a97b2e3b42cc",
    iconSet: "Ionicons",
    iconName: "expand"
  },
  {
    uuid: "00cd4ee8-cca9-4709-aa76-27b764ce278e",
    iconSet: "Ionicons",
    iconName: "expand-outline"
  },
  {
    uuid: "ca903201-d130-4a43-996e-710296c78c61",
    iconSet: "Ionicons",
    iconName: "expand-sharp"
  },
  {
    uuid: "b8176819-621a-4dfe-8442-b428b0ef9e4d",
    iconSet: "Ionicons",
    iconName: "eye"
  },
  {
    uuid: "985bd96a-bbe7-4912-a931-8d9133de5ce4",
    iconSet: "Ionicons",
    iconName: "eye-off"
  },
  {
    uuid: "e4d3a45d-64c5-4ca0-8a90-5584e92bb3fb",
    iconSet: "Ionicons",
    iconName: "eye-off-outline"
  },
  {
    uuid: "5a250a85-81d6-43f1-80eb-91934b7f2382",
    iconSet: "Ionicons",
    iconName: "eye-off-sharp"
  },
  {
    uuid: "8cb141ad-669a-4962-a5c7-77c1578b8d13",
    iconSet: "Ionicons",
    iconName: "eye-outline"
  },
  {
    uuid: "4c32c6ae-949f-494d-a785-7f0dd0ae85da",
    iconSet: "Ionicons",
    iconName: "eye-sharp"
  },
  {
    uuid: "a6607bd0-99d7-4aef-a4b5-effddb01dc8a",
    iconSet: "Ionicons",
    iconName: "eyedrop"
  },
  {
    uuid: "a612fecc-9860-4996-b8e4-91248332ee0d",
    iconSet: "Ionicons",
    iconName: "eyedrop-outline"
  },
  {
    uuid: "f4ec6af4-0702-4a3d-a3fd-c23c04e02645",
    iconSet: "Ionicons",
    iconName: "eyedrop-sharp"
  },
  {
    uuid: "15bc8223-1938-4881-94d3-51d26587c83d",
    iconSet: "Ionicons",
    iconName: "fast-food"
  },
  {
    uuid: "08390ddb-fc90-4187-82ff-afff2df93a5d",
    iconSet: "Ionicons",
    iconName: "fast-food-outline"
  },
  {
    uuid: "0256b4fc-9c55-49cc-9a4e-dcd6704ffbd9",
    iconSet: "Ionicons",
    iconName: "fast-food-sharp"
  },
  {
    uuid: "b7b3bb68-5ee8-48c4-8c83-063a921a1721",
    iconSet: "Ionicons",
    iconName: "female"
  },
  {
    uuid: "28e43e8e-5922-4362-89b5-52466b4e3d0f",
    iconSet: "Ionicons",
    iconName: "female-outline"
  },
  {
    uuid: "5879b9ec-4976-48de-b410-092c8d934fba",
    iconSet: "Ionicons",
    iconName: "female-sharp"
  },
  {
    uuid: "9935f402-c39a-4421-a6c5-2f21a90e7ffa",
    iconSet: "Ionicons",
    iconName: "file-tray"
  },
  {
    uuid: "8e811587-dc8a-4d1e-aed4-f3a005172270",
    iconSet: "Ionicons",
    iconName: "file-tray-full"
  },
  {
    uuid: "7d2d9d2e-f939-48c6-863a-4cc42fa06438",
    iconSet: "Ionicons",
    iconName: "file-tray-full-outline"
  },
  {
    uuid: "70cdea7c-7b57-45b2-ab7f-aa92d490effa",
    iconSet: "Ionicons",
    iconName: "file-tray-full-sharp"
  },
  {
    uuid: "c266d570-3841-4df9-9aaf-1cff15d9e190",
    iconSet: "Ionicons",
    iconName: "file-tray-outline"
  },
  {
    uuid: "4e832b74-45e4-4000-9958-bd862b7542af",
    iconSet: "Ionicons",
    iconName: "file-tray-sharp"
  },
  {
    uuid: "526534e0-a43e-4f36-895b-ce7770842e3f",
    iconSet: "Ionicons",
    iconName: "file-tray-stacked"
  },
  {
    uuid: "0540fc87-74eb-4a0b-891d-c3fa61c84397",
    iconSet: "Ionicons",
    iconName: "file-tray-stacked-outline"
  },
  {
    uuid: "6f35a4c2-1f5f-40fb-a9c7-e545bf55b6c6",
    iconSet: "Ionicons",
    iconName: "file-tray-stacked-sharp"
  },
  {
    uuid: "3826ffe3-428a-4cff-80f1-a2eed078534f",
    iconSet: "Ionicons",
    iconName: "film"
  },
  {
    uuid: "68f877d0-47fa-4eba-bf1f-cc0c91796a92",
    iconSet: "Ionicons",
    iconName: "film-outline"
  },
  {
    uuid: "f42885ed-e0f3-4f9e-b4e8-918c94ae10b1",
    iconSet: "Ionicons",
    iconName: "film-sharp"
  },
  {
    uuid: "9d6c614c-f700-4406-8a78-3f7c466072f7",
    iconSet: "Ionicons",
    iconName: "filter"
  },
  {
    uuid: "f2eb6f98-cec4-43d0-9dea-8676cd9892e9",
    iconSet: "Ionicons",
    iconName: "filter-outline"
  },
  {
    uuid: "4cddfa96-bef3-491c-a2c6-5d870123d6aa",
    iconSet: "Ionicons",
    iconName: "filter-sharp"
  },
  {
    uuid: "c6960918-ae40-4dbb-8b03-5f36fb538b57",
    iconSet: "Ionicons",
    iconName: "finger-print"
  },
  {
    uuid: "1a24c085-0b11-46bc-975a-0d6807e20d57",
    iconSet: "Ionicons",
    iconName: "finger-print-outline"
  },
  {
    uuid: "eefa4520-941d-4bd7-812b-e52099da30a0",
    iconSet: "Ionicons",
    iconName: "finger-print-sharp"
  },
  {
    uuid: "631735e4-52c5-4b6f-a73d-0685ab37a9be",
    iconSet: "Ionicons",
    iconName: "fitness"
  },
  {
    uuid: "d43c3305-e174-44cc-b801-aa1416ab51bd",
    iconSet: "Ionicons",
    iconName: "fitness-outline"
  },
  {
    uuid: "910f5a44-34df-4668-bfee-49b54877daa9",
    iconSet: "Ionicons",
    iconName: "fitness-sharp"
  },
  {
    uuid: "12d74ddd-0d6e-4905-9b06-67d09bf30dce",
    iconSet: "Ionicons",
    iconName: "flag"
  },
  {
    uuid: "7edb67ea-d9cc-4ddc-a784-fc5a4c57ca29",
    iconSet: "Ionicons",
    iconName: "flag-outline"
  },
  {
    uuid: "3f11a723-a3ce-4a47-bc64-c36692e3b30a",
    iconSet: "Ionicons",
    iconName: "flag-sharp"
  },
  {
    uuid: "37e2e230-8c05-4cee-b5fa-2ea6587dbc83",
    iconSet: "Ionicons",
    iconName: "flame"
  },
  {
    uuid: "781d64b0-2487-43b3-900e-f066e1b53dbe",
    iconSet: "Ionicons",
    iconName: "flame-outline"
  },
  {
    uuid: "6b860d86-1124-431c-8ab0-3dcd4be93b54",
    iconSet: "Ionicons",
    iconName: "flame-sharp"
  },
  {
    uuid: "2a925a11-b35c-4fb5-b54c-80f40294e50e",
    iconSet: "Ionicons",
    iconName: "flash"
  },
  {
    uuid: "569e8d56-9e16-4301-9ff5-376a2168b8ff",
    iconSet: "Ionicons",
    iconName: "flash-off"
  },
  {
    uuid: "3af17955-56de-43e2-a5df-856521e1f491",
    iconSet: "Ionicons",
    iconName: "flash-off-outline"
  },
  {
    uuid: "8e0f5550-829e-4c23-a023-7e2ba40a546c",
    iconSet: "Ionicons",
    iconName: "flash-off-sharp"
  },
  {
    uuid: "97f4fb82-97b0-47a8-8cec-9aef71ff8304",
    iconSet: "Ionicons",
    iconName: "flash-outline"
  },
  {
    uuid: "d5462b7b-0b96-4f30-9098-80f3cffb9bf8",
    iconSet: "Ionicons",
    iconName: "flash-sharp"
  },
  {
    uuid: "22d7b08f-6571-44ba-98cf-b1041fe92835",
    iconSet: "Ionicons",
    iconName: "flashlight"
  },
  {
    uuid: "764936f3-d27c-41ce-a5f0-0538bf919c6b",
    iconSet: "Ionicons",
    iconName: "flashlight-outline"
  },
  {
    uuid: "fbce4bd1-39c3-4092-9c19-4b132f3ccba8",
    iconSet: "Ionicons",
    iconName: "flashlight-sharp"
  },
  {
    uuid: "4ec9b5a9-19a4-40fc-8a90-3c8da45a95e5",
    iconSet: "Ionicons",
    iconName: "flask"
  },
  {
    uuid: "17f7244f-517c-4454-aae4-94e7eda85696",
    iconSet: "Ionicons",
    iconName: "flask-outline"
  },
  {
    uuid: "65c8367f-2db6-4272-a5ea-9434e8d284b2",
    iconSet: "Ionicons",
    iconName: "flask-sharp"
  },
  {
    uuid: "2e6b2e92-9372-47e3-8e3e-1fed67327654",
    iconSet: "Ionicons",
    iconName: "flower"
  },
  {
    uuid: "db128ef6-d647-4c98-b152-5f599d2169bd",
    iconSet: "Ionicons",
    iconName: "flower-outline"
  },
  {
    uuid: "0ab9a3a9-bbb8-4065-a583-3976aa93980d",
    iconSet: "Ionicons",
    iconName: "flower-sharp"
  },
  {
    uuid: "2f9b4f1b-f10e-4114-a9b8-99bebeb3befa",
    iconSet: "Ionicons",
    iconName: "folder"
  },
  {
    uuid: "ab360aba-077d-4c13-8912-052c241de6f5",
    iconSet: "Ionicons",
    iconName: "folder-open"
  },
  {
    uuid: "da05dc50-a2ac-4328-a21e-cdb9b6d46f6f",
    iconSet: "Ionicons",
    iconName: "folder-open-outline"
  },
  {
    uuid: "bb25a94c-6711-40ea-8d01-2f91e8ff5a58",
    iconSet: "Ionicons",
    iconName: "folder-open-sharp"
  },
  {
    uuid: "180595f8-57af-4641-b222-dea247b9a902",
    iconSet: "Ionicons",
    iconName: "folder-outline"
  },
  {
    uuid: "f90eb9c6-359a-4dd4-83a2-1549f4d4e32a",
    iconSet: "Ionicons",
    iconName: "folder-sharp"
  },
  {
    uuid: "4f96a75d-2400-432a-a832-40e0c1b2c6fb",
    iconSet: "Ionicons",
    iconName: "football"
  },
  {
    uuid: "9e7a62ee-8bde-4a0a-911a-cc2c50bd32d1",
    iconSet: "Ionicons",
    iconName: "football-outline"
  },
  {
    uuid: "e89767a3-e6fc-42d3-82d4-229c95dc1712",
    iconSet: "Ionicons",
    iconName: "football-sharp"
  },
  {
    uuid: "eac9e984-3eb5-4669-b358-3c46c0d8f1cb",
    iconSet: "Ionicons",
    iconName: "funnel"
  },
  {
    uuid: "7d28a8e6-8d3c-4f94-ad95-f2204a5c9b8a",
    iconSet: "Ionicons",
    iconName: "funnel-outline"
  },
  {
    uuid: "613123d5-d901-4116-96e0-479242867802",
    iconSet: "Ionicons",
    iconName: "funnel-sharp"
  },
  {
    uuid: "693cf35b-a247-4e19-b077-aebbb6a13507",
    iconSet: "Ionicons",
    iconName: "game-controller"
  },
  {
    uuid: "42f43d00-433c-4f4a-9795-eec0fcb41bb4",
    iconSet: "Ionicons",
    iconName: "game-controller-outline"
  },
  {
    uuid: "3bfedd38-53b2-4eb0-ae33-937c08ec155b",
    iconSet: "Ionicons",
    iconName: "game-controller-sharp"
  },
  {
    uuid: "3e0d5b21-39f8-4554-9759-495cf4e06c3a",
    iconSet: "Ionicons",
    iconName: "gift"
  },
  {
    uuid: "c7495e3c-5e14-403c-aca8-b426950af9f8",
    iconSet: "Ionicons",
    iconName: "gift-outline"
  },
  {
    uuid: "1bf0ced3-73dd-470a-9e38-9b87e0f9ebe4",
    iconSet: "Ionicons",
    iconName: "gift-sharp"
  },
  {
    uuid: "2106639c-5c9a-4793-b09a-5d3ee2a754e5",
    iconSet: "Ionicons",
    iconName: "git-branch"
  },
  {
    uuid: "d8275281-5dbb-4cdb-b791-96569d8ed999",
    iconSet: "Ionicons",
    iconName: "git-branch-outline"
  },
  {
    uuid: "58b22dbf-5763-486a-91bb-a296fce5a97f",
    iconSet: "Ionicons",
    iconName: "git-branch-sharp"
  },
  {
    uuid: "fd3c1d1a-ae93-421b-8693-b82fb665f464",
    iconSet: "Ionicons",
    iconName: "git-commit"
  },
  {
    uuid: "970afe85-e2b0-47e9-b4e3-dc4097b42fd3",
    iconSet: "Ionicons",
    iconName: "git-commit-outline"
  },
  {
    uuid: "eb21d17a-6d76-4c75-8166-571bdaae99ef",
    iconSet: "Ionicons",
    iconName: "git-commit-sharp"
  },
  {
    uuid: "02bc6829-1fd9-49dc-914c-0f4e6d51d953",
    iconSet: "Ionicons",
    iconName: "git-compare"
  },
  {
    uuid: "5c0ac1a6-6244-44bc-b5dc-7a94793a50f0",
    iconSet: "Ionicons",
    iconName: "git-compare-outline"
  },
  {
    uuid: "5127c909-d611-4bef-8e79-4e8a0f96e3cd",
    iconSet: "Ionicons",
    iconName: "git-compare-sharp"
  },
  {
    uuid: "31523130-b2ba-48aa-a81b-e8b4f4caf325",
    iconSet: "Ionicons",
    iconName: "git-merge"
  },
  {
    uuid: "314039f8-4234-4a5a-98c2-3ee89c061fb6",
    iconSet: "Ionicons",
    iconName: "git-merge-outline"
  },
  {
    uuid: "a047df60-9c30-41b5-8dba-c9ea606fd226",
    iconSet: "Ionicons",
    iconName: "git-merge-sharp"
  },
  {
    uuid: "0b1a9e51-1974-431e-b2bd-3073ce6b191d",
    iconSet: "Ionicons",
    iconName: "git-network"
  },
  {
    uuid: "708e48ee-e76f-4f97-9c35-fbd9b54aec4b",
    iconSet: "Ionicons",
    iconName: "git-network-outline"
  },
  {
    uuid: "fa0a7494-8008-467e-a80d-db7ae2bb3ec2",
    iconSet: "Ionicons",
    iconName: "git-network-sharp"
  },
  {
    uuid: "5ce12bd3-8033-4ecc-910e-8862acfd7348",
    iconSet: "Ionicons",
    iconName: "git-pull-request"
  },
  {
    uuid: "8f43d980-364e-4d36-bb78-a3984dc9289c",
    iconSet: "Ionicons",
    iconName: "git-pull-request-outline"
  },
  {
    uuid: "6601d154-9ed0-41fe-a2b4-382511310526",
    iconSet: "Ionicons",
    iconName: "git-pull-request-sharp"
  },
  {
    uuid: "2fd7d0b6-8654-47fe-ba13-bd1b58ec6b0c",
    iconSet: "Ionicons",
    iconName: "glasses"
  },
  {
    uuid: "72ec1369-af0a-4c0a-a8ef-f42e3d6e584e",
    iconSet: "Ionicons",
    iconName: "glasses-outline"
  },
  {
    uuid: "e71d28b4-8d97-4baf-b41b-5e357ad34b49",
    iconSet: "Ionicons",
    iconName: "glasses-sharp"
  },
  {
    uuid: "6486fe76-2f7b-487b-8602-38e7d33ba852",
    iconSet: "Ionicons",
    iconName: "globe"
  },
  {
    uuid: "9d2461ea-c45b-403f-af09-753afc12791d",
    iconSet: "Ionicons",
    iconName: "globe-outline"
  },
  {
    uuid: "33268396-e835-4e06-8f4b-4c95a77811d8",
    iconSet: "Ionicons",
    iconName: "globe-sharp"
  },
  {
    uuid: "d917ceae-ae62-4974-824d-cdfe622d7a8f",
    iconSet: "Ionicons",
    iconName: "golf"
  },
  {
    uuid: "72bb10d7-388c-4f37-b8d2-79fa2019fc81",
    iconSet: "Ionicons",
    iconName: "golf-outline"
  },
  {
    uuid: "48487ed4-e350-4092-adec-496f517e9bd1",
    iconSet: "Ionicons",
    iconName: "golf-sharp"
  },
  {
    uuid: "ad42d214-2188-4e58-b556-748f1d465fc0",
    iconSet: "Ionicons",
    iconName: "grid"
  },
  {
    uuid: "9980d880-73ba-4d77-a6e6-c5d8a2937068",
    iconSet: "Ionicons",
    iconName: "grid-outline"
  },
  {
    uuid: "a44aace9-e18f-47cc-8b5b-3c3c3e151e6a",
    iconSet: "Ionicons",
    iconName: "grid-sharp"
  },
  {
    uuid: "5ed130aa-42a8-4025-8d09-87e21beb93fd",
    iconSet: "Ionicons",
    iconName: "hammer"
  },
  {
    uuid: "9728ddc5-bd30-47f0-b179-58b8cdda778a",
    iconSet: "Ionicons",
    iconName: "hammer-outline"
  },
  {
    uuid: "bb47a81b-57ea-45bd-8b2e-008d824e1965",
    iconSet: "Ionicons",
    iconName: "hammer-sharp"
  },
  {
    uuid: "48023453-c3d1-492f-9ab9-927da6195292",
    iconSet: "Ionicons",
    iconName: "hand-left"
  },
  {
    uuid: "129b2bc6-cc9b-4c0a-819d-a6b955755909",
    iconSet: "Ionicons",
    iconName: "hand-left-outline"
  },
  {
    uuid: "c6e95fc2-4806-422b-bef0-e80cb8307e72",
    iconSet: "Ionicons",
    iconName: "hand-left-sharp"
  },
  {
    uuid: "568a5b18-50f1-4097-9a9b-c1799cbeb9c0",
    iconSet: "Ionicons",
    iconName: "hand-right"
  },
  {
    uuid: "8e5048da-2121-469c-85cc-425ad0224f2c",
    iconSet: "Ionicons",
    iconName: "hand-right-outline"
  },
  {
    uuid: "1c287443-be3f-4283-969f-2ee10eae3570",
    iconSet: "Ionicons",
    iconName: "hand-right-sharp"
  },
  {
    uuid: "5fcb6a15-3fbd-4298-a398-7f2c223b4220",
    iconSet: "Ionicons",
    iconName: "happy"
  },
  {
    uuid: "eb6600a4-c909-4320-b112-1059d56b7105",
    iconSet: "Ionicons",
    iconName: "happy-outline"
  },
  {
    uuid: "f7562139-1141-4056-b946-272a979cecef",
    iconSet: "Ionicons",
    iconName: "happy-sharp"
  },
  {
    uuid: "37e7a7b8-475a-4b49-800b-ecc3e209899d",
    iconSet: "Ionicons",
    iconName: "hardware-chip"
  },
  {
    uuid: "62b8a088-3214-49d3-a599-240604913501",
    iconSet: "Ionicons",
    iconName: "hardware-chip-outline"
  },
  {
    uuid: "5abc3a5f-5256-424d-9b5d-7d46db79e206",
    iconSet: "Ionicons",
    iconName: "hardware-chip-sharp"
  },
  {
    uuid: "bc60d1a7-acb8-474f-98a0-cfbee80b4110",
    iconSet: "Ionicons",
    iconName: "headset"
  },
  {
    uuid: "ae320a43-109d-4623-9bda-fc7260c609d1",
    iconSet: "Ionicons",
    iconName: "headset-outline"
  },
  {
    uuid: "6e2f1424-bf95-4d99-a48a-2ee0286120ee",
    iconSet: "Ionicons",
    iconName: "headset-sharp"
  },
  {
    uuid: "9db36269-3299-40b5-97ec-533d4377773f",
    iconSet: "Ionicons",
    iconName: "heart"
  },
  {
    uuid: "d394e39d-d579-44f6-9116-92803c2a802b",
    iconSet: "Ionicons",
    iconName: "heart-circle"
  },
  {
    uuid: "9fee990e-d6ee-41f7-b23c-1608e9109931",
    iconSet: "Ionicons",
    iconName: "heart-circle-outline"
  },
  {
    uuid: "b6f74b6b-e50e-49e6-93b3-a4ac8af1bdb3",
    iconSet: "Ionicons",
    iconName: "heart-circle-sharp"
  },
  {
    uuid: "7260d30a-316c-4df9-b48a-f31e0361840b",
    iconSet: "Ionicons",
    iconName: "heart-dislike"
  },
  {
    uuid: "824084a2-d940-46e3-9158-0ef2fa0355f8",
    iconSet: "Ionicons",
    iconName: "heart-dislike-circle"
  },
  {
    uuid: "572a992e-d144-4df4-9642-c267c6d7ea01",
    iconSet: "Ionicons",
    iconName: "heart-dislike-circle-outline"
  },
  {
    uuid: "34166aed-386f-4df0-87d2-ae2df0381ba3",
    iconSet: "Ionicons",
    iconName: "heart-dislike-circle-sharp"
  },
  {
    uuid: "eb449c6c-5f21-4bb9-b38b-c746e07b7de5",
    iconSet: "Ionicons",
    iconName: "heart-dislike-outline"
  },
  {
    uuid: "df457faf-8d8b-4116-ae3e-f4694f6958a5",
    iconSet: "Ionicons",
    iconName: "heart-dislike-sharp"
  },
  {
    uuid: "b5fa4b6a-f332-4c69-9b86-c52650b9809b",
    iconSet: "Ionicons",
    iconName: "heart-half"
  },
  {
    uuid: "eb3ed304-e273-4c3c-a1fa-a01730032968",
    iconSet: "Ionicons",
    iconName: "heart-half-outline"
  },
  {
    uuid: "42d40838-018b-4dab-bb6f-4223763e7f06",
    iconSet: "Ionicons",
    iconName: "heart-half-sharp"
  },
  {
    uuid: "a29d175c-58e1-4853-9796-6e19cb3473f5",
    iconSet: "Ionicons",
    iconName: "heart-outline"
  },
  {
    uuid: "454923d4-a418-4476-a95e-bd822b1550a8",
    iconSet: "Ionicons",
    iconName: "heart-sharp"
  },
  {
    uuid: "3cc82baa-b6bc-4df3-8c30-df0b15cece4b",
    iconSet: "Ionicons",
    iconName: "help"
  },
  {
    uuid: "d6466a50-a330-4bc5-bc34-d6f9286579ff",
    iconSet: "Ionicons",
    iconName: "help-buoy"
  },
  {
    uuid: "18fa1f00-a6d0-47a5-87be-9e49c14b7c94",
    iconSet: "Ionicons",
    iconName: "help-buoy-outline"
  },
  {
    uuid: "9b50cb40-ad94-4886-aa5c-4204b54a567c",
    iconSet: "Ionicons",
    iconName: "help-buoy-sharp"
  },
  {
    uuid: "96b17765-2cfa-4dc9-8766-16a8d154420f",
    iconSet: "Ionicons",
    iconName: "help-circle"
  },
  {
    uuid: "8826eee2-e465-4fd8-beec-28808a5787c8",
    iconSet: "Ionicons",
    iconName: "help-circle-outline"
  },
  {
    uuid: "0b3af6d2-1d91-4a54-a406-f4cb3cd7fcce",
    iconSet: "Ionicons",
    iconName: "help-circle-sharp"
  },
  {
    uuid: "bfa65f3e-3500-4b49-9a95-d828aeac7503",
    iconSet: "Ionicons",
    iconName: "help-outline"
  },
  {
    uuid: "785b5df8-b5c0-4539-9a01-355ba31ce03f",
    iconSet: "Ionicons",
    iconName: "help-sharp"
  },
  {
    uuid: "3eaa7023-c38e-488c-9a21-b2483743bfa0",
    iconSet: "Ionicons",
    iconName: "home"
  },
  {
    uuid: "f557862a-1a89-4c1e-a0a1-9cac8b0ae64c",
    iconSet: "Ionicons",
    iconName: "home-outline"
  },
  {
    uuid: "145bc81c-f5cf-43c8-a3c1-2542a6a0ba76",
    iconSet: "Ionicons",
    iconName: "home-sharp"
  },
  {
    uuid: "1ae9227c-e5b6-4f84-b148-a5b895928668",
    iconSet: "Ionicons",
    iconName: "hourglass"
  },
  {
    uuid: "4e3f6761-f6fd-46e3-8004-62f6a09b1444",
    iconSet: "Ionicons",
    iconName: "hourglass-outline"
  },
  {
    uuid: "fd5ac53e-a222-4cc3-981b-c3a9d19ddac0",
    iconSet: "Ionicons",
    iconName: "hourglass-sharp"
  },
  {
    uuid: "2732db14-299a-4faf-b53a-d0befd73fe00",
    iconSet: "Ionicons",
    iconName: "ice-cream"
  },
  {
    uuid: "576706aa-9d07-44e9-be56-b5cb63e6d2f6",
    iconSet: "Ionicons",
    iconName: "ice-cream-outline"
  },
  {
    uuid: "b40c1dec-87f6-4aa3-8413-948addb9d291",
    iconSet: "Ionicons",
    iconName: "ice-cream-sharp"
  },
  {
    uuid: "01892bbe-8201-4370-afca-5448d6efa3d6",
    iconSet: "Ionicons",
    iconName: "image"
  },
  {
    uuid: "cb3c3ff2-5d2f-4bbe-86c8-75b24a7c0906",
    iconSet: "Ionicons",
    iconName: "image-outline"
  },
  {
    uuid: "109b5b1f-c90d-4e5d-a228-e487a9ed00ee",
    iconSet: "Ionicons",
    iconName: "image-sharp"
  },
  {
    uuid: "69322429-bdbf-4768-8b1d-0076ed91a856",
    iconSet: "Ionicons",
    iconName: "images"
  },
  {
    uuid: "0adb1547-cc50-48c2-9252-2e49652a18e8",
    iconSet: "Ionicons",
    iconName: "images-outline"
  },
  {
    uuid: "bf684bf9-5b7d-497c-ac71-5d7e49b6a7bd",
    iconSet: "Ionicons",
    iconName: "images-sharp"
  },
  {
    uuid: "fbf20b39-b945-4ed4-b19b-4b1a2276b53d",
    iconSet: "Ionicons",
    iconName: "infinite"
  },
  {
    uuid: "de453709-f8c5-478f-944b-3d8a853ea4d9",
    iconSet: "Ionicons",
    iconName: "infinite-outline"
  },
  {
    uuid: "588a97d4-4af1-4f48-8f1a-7027480ea9d2",
    iconSet: "Ionicons",
    iconName: "infinite-sharp"
  },
  {
    uuid: "7e996a51-f174-4169-8696-8ca0f48340c0",
    iconSet: "Ionicons",
    iconName: "information"
  },
  {
    uuid: "50e7b2bf-c3a7-43f5-923c-1629cb576529",
    iconSet: "Ionicons",
    iconName: "information-circle"
  },
  {
    uuid: "f5803fe0-3e66-42b0-9adb-314c0f48289f",
    iconSet: "Ionicons",
    iconName: "information-circle-outline"
  },
  {
    uuid: "c6836da8-7255-4b6a-8853-31d8cb33f5f2",
    iconSet: "Ionicons",
    iconName: "information-circle-sharp"
  },
  {
    uuid: "6d7c5d03-2387-4ff6-8d68-3557853b606a",
    iconSet: "Ionicons",
    iconName: "information-outline"
  },
  {
    uuid: "beac34bb-7d7b-40cb-9040-5710393645a1",
    iconSet: "Ionicons",
    iconName: "information-sharp"
  },
  {
    uuid: "31d6f18e-7f00-4cf0-9a96-67535be17257",
    iconSet: "Ionicons",
    iconName: "journal"
  },
  {
    uuid: "81c5c2b1-88f0-4843-9000-c56a896de24b",
    iconSet: "Ionicons",
    iconName: "journal-outline"
  },
  {
    uuid: "2f5fdb44-8f63-416a-a6e5-e43c2fbe50f7",
    iconSet: "Ionicons",
    iconName: "journal-sharp"
  },
  {
    uuid: "5a55cdcf-aa3e-4790-9e48-11b7cadbdb10",
    iconSet: "Ionicons",
    iconName: "key"
  },
  {
    uuid: "10a23f05-f77e-4c80-94bb-6ad96942d031",
    iconSet: "Ionicons",
    iconName: "key-outline"
  },
  {
    uuid: "de19b0f8-3070-4bac-b476-406f80872215",
    iconSet: "Ionicons",
    iconName: "key-sharp"
  },
  {
    uuid: "3ee2eb09-d84b-40ea-b9b4-8219ddc51ad1",
    iconSet: "Ionicons",
    iconName: "keypad"
  },
  {
    uuid: "edc51cfe-35eb-467e-add6-047e780cba59",
    iconSet: "Ionicons",
    iconName: "keypad-outline"
  },
  {
    uuid: "ec798e82-3583-47ac-8ac4-df4354d0538d",
    iconSet: "Ionicons",
    iconName: "keypad-sharp"
  },
  {
    uuid: "6ba84d62-4b2c-4b57-a4da-d22ce2e4852a",
    iconSet: "Ionicons",
    iconName: "language"
  },
  {
    uuid: "97f09750-26f1-4254-9c0e-f4dc34b4d296",
    iconSet: "Ionicons",
    iconName: "language-outline"
  },
  {
    uuid: "0458201d-d62a-42a5-a239-bed4fe217d05",
    iconSet: "Ionicons",
    iconName: "language-sharp"
  },
  {
    uuid: "151bfd77-7b9a-44cc-ac2b-804096b135b6",
    iconSet: "Ionicons",
    iconName: "laptop"
  },
  {
    uuid: "600e35a4-b0d1-4917-8282-09e35e19eb08",
    iconSet: "Ionicons",
    iconName: "laptop-outline"
  },
  {
    uuid: "305482db-081a-4991-9e38-2bfface912f2",
    iconSet: "Ionicons",
    iconName: "laptop-sharp"
  },
  {
    uuid: "f32d64dd-7200-456b-847f-76ed911d99e4",
    iconSet: "Ionicons",
    iconName: "layers"
  },
  {
    uuid: "3c7989e8-d72a-433c-94aa-14f302ce622d",
    iconSet: "Ionicons",
    iconName: "layers-outline"
  },
  {
    uuid: "453172d8-5786-4b45-b53c-a90dabdc5664",
    iconSet: "Ionicons",
    iconName: "layers-sharp"
  },
  {
    uuid: "d7de190e-92f8-4271-8acb-551d6cb315a9",
    iconSet: "Ionicons",
    iconName: "leaf"
  },
  {
    uuid: "c0491035-e192-4c7d-9abb-4ba004653d50",
    iconSet: "Ionicons",
    iconName: "leaf-outline"
  },
  {
    uuid: "68ea872b-235b-446e-a09f-abc0fdd26cbc",
    iconSet: "Ionicons",
    iconName: "leaf-sharp"
  },
  {
    uuid: "bf8b613e-bf33-4839-a0f4-3450c5d43e1f",
    iconSet: "Ionicons",
    iconName: "library"
  },
  {
    uuid: "a74ea204-7706-4ed9-8f77-aae49e2c4933",
    iconSet: "Ionicons",
    iconName: "library-outline"
  },
  {
    uuid: "72f81890-467d-4d38-b3f7-0cae790cfe39",
    iconSet: "Ionicons",
    iconName: "library-sharp"
  },
  {
    uuid: "f178a56d-b41d-4d07-8465-9c62087e5028",
    iconSet: "Ionicons",
    iconName: "link"
  },
  {
    uuid: "32e4fece-a6db-43e6-a45d-5efbe61d7d63",
    iconSet: "Ionicons",
    iconName: "link-outline"
  },
  {
    uuid: "282f7eb2-4113-440a-b09d-4604883e3fd3",
    iconSet: "Ionicons",
    iconName: "link-sharp"
  },
  {
    uuid: "48e69f72-9a6f-41c1-a045-f80ac932f4a4",
    iconSet: "Ionicons",
    iconName: "list"
  },
  {
    uuid: "a684ad3a-cade-4055-a81e-de04dd5c0c5b",
    iconSet: "Ionicons",
    iconName: "list-circle"
  },
  {
    uuid: "0d8b2aca-0d63-475c-af61-727fcd402bb4",
    iconSet: "Ionicons",
    iconName: "list-circle-outline"
  },
  {
    uuid: "4633789b-52b7-4e27-960b-4e3c9dba9ee2",
    iconSet: "Ionicons",
    iconName: "list-circle-sharp"
  },
  {
    uuid: "438ffddc-d9f3-4f97-96c8-652c63fbf6d4",
    iconSet: "Ionicons",
    iconName: "list-outline"
  },
  {
    uuid: "f58cdbd9-748f-4aa2-9f6d-1ebf808d17de",
    iconSet: "Ionicons",
    iconName: "list-sharp"
  },
  {
    uuid: "d1e53982-b437-48d6-a7aa-c9860b68d06c",
    iconSet: "Ionicons",
    iconName: "locate"
  },
  {
    uuid: "7b16a2e1-bbba-4a0b-ad49-67d3ab367655",
    iconSet: "Ionicons",
    iconName: "locate-outline"
  },
  {
    uuid: "69f79e21-e1e3-4918-a4e0-4a3cd71305ba",
    iconSet: "Ionicons",
    iconName: "locate-sharp"
  },
  {
    uuid: "d13ffd20-f5f2-43fc-9069-4a27b14a20d7",
    iconSet: "Ionicons",
    iconName: "location"
  },
  {
    uuid: "e2830172-7de4-455e-abd0-ccbb0c1890eb",
    iconSet: "Ionicons",
    iconName: "location-outline"
  },
  {
    uuid: "24ee7953-1849-4410-9c18-506e6ee6161a",
    iconSet: "Ionicons",
    iconName: "location-sharp"
  },
  {
    uuid: "852ace2b-db00-4e8d-a4e4-75501f1cd16c",
    iconSet: "Ionicons",
    iconName: "lock-closed"
  },
  {
    uuid: "ef59edfa-38b9-4869-a6b9-b45c44a0a836",
    iconSet: "Ionicons",
    iconName: "lock-closed-outline"
  },
  {
    uuid: "d4bbb62a-4344-45d4-813c-e5051d761a70",
    iconSet: "Ionicons",
    iconName: "lock-closed-sharp"
  },
  {
    uuid: "0b29fda0-8c7f-43fb-8f94-fb1237cbb135",
    iconSet: "Ionicons",
    iconName: "lock-open"
  },
  {
    uuid: "4f9e72fa-54a3-4708-9bd9-084512fd910a",
    iconSet: "Ionicons",
    iconName: "lock-open-outline"
  },
  {
    uuid: "4182fe3b-0473-4d1a-9903-c3f98c7f06c0",
    iconSet: "Ionicons",
    iconName: "lock-open-sharp"
  },
  {
    uuid: "8d08edee-accc-4505-86c3-e250095dfde1",
    iconSet: "Ionicons",
    iconName: "log-in"
  },
  {
    uuid: "99b31565-f6cd-4a65-88ae-437fbf879c97",
    iconSet: "Ionicons",
    iconName: "log-in-outline"
  },
  {
    uuid: "0da74ea2-3956-4152-ba1b-5ccd2185fae3",
    iconSet: "Ionicons",
    iconName: "log-in-sharp"
  },
  {
    uuid: "c7f45619-85c0-4f54-a0fe-ef26928c2d4d",
    iconSet: "Ionicons",
    iconName: "log-out"
  },
  {
    uuid: "51393919-f53c-40ce-a5d8-b36288d0eacf",
    iconSet: "Ionicons",
    iconName: "log-out-outline"
  },
  {
    uuid: "4990696e-482b-471f-9bae-851106d96ac7",
    iconSet: "Ionicons",
    iconName: "log-out-sharp"
  },
  {
    uuid: "d1f39d95-23f8-4697-bd76-398f9cefa248",
    iconSet: "Ionicons",
    iconName: "logo-amazon"
  },
  {
    uuid: "7b129117-8413-45e9-9457-56e17adcd776",
    iconSet: "Ionicons",
    iconName: "logo-amplify"
  },
  {
    uuid: "b09f1d63-4ecb-45bc-8d3b-4be00035f435",
    iconSet: "Ionicons",
    iconName: "logo-android"
  },
  {
    uuid: "48be7b59-1a45-4bd2-a0b8-d0eecd2d9063",
    iconSet: "Ionicons",
    iconName: "logo-angular"
  },
  {
    uuid: "15e4b25d-4ae1-43cb-aaac-b1bca2c21d6c",
    iconSet: "Ionicons",
    iconName: "logo-apple"
  },
  {
    uuid: "5dc4aadc-23e0-4079-8afc-3d49fb6c3b40",
    iconSet: "Ionicons",
    iconName: "logo-apple-appstore"
  },
  {
    uuid: "758ad663-8d9c-43b6-8f95-6ab16b514456",
    iconSet: "Ionicons",
    iconName: "logo-bitbucket"
  },
  {
    uuid: "32dccf79-b931-43f9-9550-ecab53d9daf9",
    iconSet: "Ionicons",
    iconName: "logo-bitcoin"
  },
  {
    uuid: "7ec2c75a-c23a-4215-a596-da0e13ea61bc",
    iconSet: "Ionicons",
    iconName: "logo-buffer"
  },
  {
    uuid: "57362a4a-b981-4057-9460-20f8263c446c",
    iconSet: "Ionicons",
    iconName: "logo-capacitor"
  },
  {
    uuid: "8d380405-d245-45b3-9447-8a6fe77cc7b4",
    iconSet: "Ionicons",
    iconName: "logo-chrome"
  },
  {
    uuid: "84627bc2-c2cd-4553-b223-bbadfd620e5b",
    iconSet: "Ionicons",
    iconName: "logo-closed-captioning"
  },
  {
    uuid: "b399da6c-1314-40c4-9288-e178f3e5ff75",
    iconSet: "Ionicons",
    iconName: "logo-codepen"
  },
  {
    uuid: "7fd90247-9ae1-462f-9fe9-26494c452902",
    iconSet: "Ionicons",
    iconName: "logo-css3"
  },
  {
    uuid: "3503dd02-e53e-4b81-b448-d918328ca696",
    iconSet: "Ionicons",
    iconName: "logo-designernews"
  },
  {
    uuid: "1d937269-0c2f-48de-b7a7-9234a0c75327",
    iconSet: "Ionicons",
    iconName: "logo-dribbble"
  },
  {
    uuid: "68adf76a-2740-414b-bb77-4e0c2ec3c48e",
    iconSet: "Ionicons",
    iconName: "logo-dropbox"
  },
  {
    uuid: "d44c5319-a797-4560-8d3a-113e6cfec006",
    iconSet: "Ionicons",
    iconName: "logo-edge"
  },
  {
    uuid: "052468be-8227-4a95-b728-96003152a17f",
    iconSet: "Ionicons",
    iconName: "logo-electron"
  },
  {
    uuid: "7d7c3554-ff66-4ced-a798-faade6d50ae3",
    iconSet: "Ionicons",
    iconName: "logo-euro"
  },
  {
    uuid: "8b2040bc-3da0-48db-b815-dfac79389e35",
    iconSet: "Ionicons",
    iconName: "logo-facebook"
  },
  {
    uuid: "d9122dec-1bba-48dd-98ef-14d62877631f",
    iconSet: "Ionicons",
    iconName: "logo-firebase"
  },
  {
    uuid: "baa55541-aa52-4f5f-8be2-003aa8559323",
    iconSet: "Ionicons",
    iconName: "logo-firefox"
  },
  {
    uuid: "831b6a8e-40e1-494a-9af4-9a573d8350f7",
    iconSet: "Ionicons",
    iconName: "logo-flickr"
  },
  {
    uuid: "5d15ec1b-6b72-4b15-a7d3-6c9a696bbd5b",
    iconSet: "Ionicons",
    iconName: "logo-foursquare"
  },
  {
    uuid: "f534d5b7-3f42-4ca6-9fbe-269b80a8a886",
    iconSet: "Ionicons",
    iconName: "logo-github"
  },
  {
    uuid: "c1dd7f9a-692a-46b7-a8e8-44b513315cc8",
    iconSet: "Ionicons",
    iconName: "logo-google"
  },
  {
    uuid: "bc754b94-1c6a-433c-899a-60bc7fe6ed71",
    iconSet: "Ionicons",
    iconName: "logo-google-playstore"
  },
  {
    uuid: "4af4300c-4e18-4b56-9c13-7d66264379ad",
    iconSet: "Ionicons",
    iconName: "logo-hackernews"
  },
  {
    uuid: "58bd4ace-435c-4563-97ce-35cc3329fef0",
    iconSet: "Ionicons",
    iconName: "logo-html5"
  },
  {
    uuid: "0864f0b0-90b4-4584-a17a-6e48a29cc4e6",
    iconSet: "Ionicons",
    iconName: "logo-instagram"
  },
  {
    uuid: "f4d5f901-b697-40df-847b-e33315e0533b",
    iconSet: "Ionicons",
    iconName: "logo-ionic"
  },
  {
    uuid: "01802156-04f3-425f-87a1-a1eb8096b022",
    iconSet: "Ionicons",
    iconName: "logo-ionitron"
  },
  {
    uuid: "7891beb9-5634-404b-9af8-364a0d43d77c",
    iconSet: "Ionicons",
    iconName: "logo-javascript"
  },
  {
    uuid: "75d7771c-05ed-4694-b95f-4885c4459b00",
    iconSet: "Ionicons",
    iconName: "logo-laravel"
  },
  {
    uuid: "0bedd6fa-3e27-4bb8-8240-ff2f7f3bf918",
    iconSet: "Ionicons",
    iconName: "logo-linkedin"
  },
  {
    uuid: "1267a64d-8524-4024-8d5c-f7ee09078a6e",
    iconSet: "Ionicons",
    iconName: "logo-markdown"
  },
  {
    uuid: "70a1d129-c7d3-4cb9-b7d1-87964241b7f5",
    iconSet: "Ionicons",
    iconName: "logo-no-smoking"
  },
  {
    uuid: "2d8eaea8-6fe6-4619-8450-31c16c13a14a",
    iconSet: "Ionicons",
    iconName: "logo-nodejs"
  },
  {
    uuid: "3b5df5b7-7091-4a8b-b039-048de18d544d",
    iconSet: "Ionicons",
    iconName: "logo-npm"
  },
  {
    uuid: "e491c17f-55fe-4ffa-b9dd-c2614faa458b",
    iconSet: "Ionicons",
    iconName: "logo-octocat"
  },
  {
    uuid: "87b66374-c7c4-4878-80b3-94b58b9c7712",
    iconSet: "Ionicons",
    iconName: "logo-pinterest"
  },
  {
    uuid: "96ff0039-775d-410f-84c0-1973a0a32c24",
    iconSet: "Ionicons",
    iconName: "logo-playstation"
  },
  {
    uuid: "200f68fc-a3e9-4cdc-ab0c-1b59a9dd66d5",
    iconSet: "Ionicons",
    iconName: "logo-pwa"
  },
  {
    uuid: "c3e8fc4a-771c-4a88-9f82-da8c659d52b6",
    iconSet: "Ionicons",
    iconName: "logo-python"
  },
  {
    uuid: "f9238583-c5e1-4985-8192-8ed6ff862584",
    iconSet: "Ionicons",
    iconName: "logo-react"
  },
  {
    uuid: "c7866f9e-078c-4425-8717-68fef1e2c2fe",
    iconSet: "Ionicons",
    iconName: "logo-reddit"
  },
  {
    uuid: "2ae0b5ee-f433-4d22-a6ed-3c107c5833d4",
    iconSet: "Ionicons",
    iconName: "logo-rss"
  },
  {
    uuid: "d1c4c41c-fe55-4207-af7e-9ee0b1392730",
    iconSet: "Ionicons",
    iconName: "logo-sass"
  },
  {
    uuid: "0b7df3c0-303e-4344-814d-d5c3ea80b4ad",
    iconSet: "Ionicons",
    iconName: "logo-skype"
  },
  {
    uuid: "6470e98b-1fdc-4d6d-8d9c-c348ef401e60",
    iconSet: "Ionicons",
    iconName: "logo-slack"
  },
  {
    uuid: "b2ae5300-7c48-49fb-b87e-93c48dbcee69",
    iconSet: "Ionicons",
    iconName: "logo-snapchat"
  },
  {
    uuid: "cc60296f-e437-48de-84c0-8343e33f7579",
    iconSet: "Ionicons",
    iconName: "logo-stackoverflow"
  },
  {
    uuid: "678375e3-53b2-467b-8553-9971d7a4b065",
    iconSet: "Ionicons",
    iconName: "logo-steam"
  },
  {
    uuid: "aa7dea98-3cd6-40db-a17c-25caa23e6b83",
    iconSet: "Ionicons",
    iconName: "logo-stencil"
  },
  {
    uuid: "e4ac631b-0052-4d49-8cfc-fa11c3f4815d",
    iconSet: "Ionicons",
    iconName: "logo-tumblr"
  },
  {
    uuid: "371c9d4c-a1db-4db1-921e-4138f25a3e8c",
    iconSet: "Ionicons",
    iconName: "logo-tux"
  },
  {
    uuid: "381dcfe6-1ff9-4e76-89a6-fefb9026abe0",
    iconSet: "Ionicons",
    iconName: "logo-twitch"
  },
  {
    uuid: "1df1170e-28b6-4d24-8d5c-0410672d4edd",
    iconSet: "Ionicons",
    iconName: "logo-twitter"
  },
  {
    uuid: "ded2a87e-ffa2-42c6-bce4-48fb98b3cb9c",
    iconSet: "Ionicons",
    iconName: "logo-usd"
  },
  {
    uuid: "ccb3af37-066b-476a-bc0b-b7b71b73b2e7",
    iconSet: "Ionicons",
    iconName: "logo-vimeo"
  },
  {
    uuid: "65d698e9-3671-4ada-a147-22729d1c21f2",
    iconSet: "Ionicons",
    iconName: "logo-vk"
  },
  {
    uuid: "167846ab-1727-411b-88a5-57bafca82038",
    iconSet: "Ionicons",
    iconName: "logo-vue"
  },
  {
    uuid: "290d7c96-a850-45f6-ab50-aaa9f65356ce",
    iconSet: "Ionicons",
    iconName: "logo-web-component"
  },
  {
    uuid: "62c21aa3-0033-400d-8986-4df757ff7a7a",
    iconSet: "Ionicons",
    iconName: "logo-whatsapp"
  },
  {
    uuid: "690900a1-41da-4e7d-a3af-fe525ec9043b",
    iconSet: "Ionicons",
    iconName: "logo-windows"
  },
  {
    uuid: "6f740c81-0590-4a67-8151-f9c53dc88e7b",
    iconSet: "Ionicons",
    iconName: "logo-wordpress"
  },
  {
    uuid: "ee59ca0b-7aa5-459f-918d-de51db78ebaf",
    iconSet: "Ionicons",
    iconName: "logo-xbox"
  },
  {
    uuid: "b7a1e122-697b-405e-9fcb-7b553dfb01b2",
    iconSet: "Ionicons",
    iconName: "logo-xing"
  },
  {
    uuid: "74447655-2a6c-4ea9-b1e3-880e2ca7efff",
    iconSet: "Ionicons",
    iconName: "logo-yahoo"
  },
  {
    uuid: "9edc2be7-aca4-4696-a049-b230ad8aaed9",
    iconSet: "Ionicons",
    iconName: "logo-yen"
  },
  {
    uuid: "3d359f7e-ca23-418e-9dca-78b746e4f76e",
    iconSet: "Ionicons",
    iconName: "logo-youtube"
  },
  {
    uuid: "4a8b0afe-c2e4-49c9-875a-14cf7556615a",
    iconSet: "Ionicons",
    iconName: "magnet"
  },
  {
    uuid: "1da70bed-0666-4afe-80a6-f72f758b7ce3",
    iconSet: "Ionicons",
    iconName: "magnet-outline"
  },
  {
    uuid: "79837320-007a-4fa7-82f5-15ffc7cac6b2",
    iconSet: "Ionicons",
    iconName: "magnet-sharp"
  },
  {
    uuid: "eb849f60-ee39-4afd-92a5-535846eb989b",
    iconSet: "Ionicons",
    iconName: "mail"
  },
  {
    uuid: "8190902d-01ec-40bc-b99e-80e41a5ebde0",
    iconSet: "Ionicons",
    iconName: "mail-open"
  },
  {
    uuid: "1c4ea403-6cdf-4bfc-82e3-afd5fafa3c64",
    iconSet: "Ionicons",
    iconName: "mail-open-outline"
  },
  {
    uuid: "41ef223a-1ed5-440e-b44a-e44fd7f2fe19",
    iconSet: "Ionicons",
    iconName: "mail-open-sharp"
  },
  {
    uuid: "e07fbe7c-14ce-4553-b71a-80359b41aa24",
    iconSet: "Ionicons",
    iconName: "mail-outline"
  },
  {
    uuid: "2fb3059f-b939-471a-ba4e-778c70242a8a",
    iconSet: "Ionicons",
    iconName: "mail-sharp"
  },
  {
    uuid: "742dc921-3cf3-439f-9419-ec1edb46304a",
    iconSet: "Ionicons",
    iconName: "mail-unread"
  },
  {
    uuid: "fcef11b1-859b-4f9b-b83b-8da56d678df6",
    iconSet: "Ionicons",
    iconName: "mail-unread-outline"
  },
  {
    uuid: "5e102aea-bff3-4115-a973-b28f284a161b",
    iconSet: "Ionicons",
    iconName: "mail-unread-sharp"
  },
  {
    uuid: "08303403-edc9-46ed-9489-a60558971ab9",
    iconSet: "Ionicons",
    iconName: "male"
  },
  {
    uuid: "bd874a84-86cc-4e95-91ab-9e1db7e46cec",
    iconSet: "Ionicons",
    iconName: "male-female"
  },
  {
    uuid: "53e85c06-5fcc-4626-a78d-22c58ad3a3b3",
    iconSet: "Ionicons",
    iconName: "male-female-outline"
  },
  {
    uuid: "29e00dc6-8492-409d-8d48-6f672e06d78d",
    iconSet: "Ionicons",
    iconName: "male-female-sharp"
  },
  {
    uuid: "52cbcfb1-558e-4a93-867c-5644c3572dec",
    iconSet: "Ionicons",
    iconName: "male-outline"
  },
  {
    uuid: "7abceee8-546d-42e4-bedb-edb3b8c94dc7",
    iconSet: "Ionicons",
    iconName: "male-sharp"
  },
  {
    uuid: "7b27aa41-e29a-4add-ac04-73083d3b6ee4",
    iconSet: "Ionicons",
    iconName: "man"
  },
  {
    uuid: "3acbc783-737c-4100-9406-f0a316fff99c",
    iconSet: "Ionicons",
    iconName: "man-outline"
  },
  {
    uuid: "c932a5ae-eb53-4a7c-91ad-8c53353525a1",
    iconSet: "Ionicons",
    iconName: "man-sharp"
  },
  {
    uuid: "d846d2c6-2208-4cf9-b3bb-c69201b01e09",
    iconSet: "Ionicons",
    iconName: "map"
  },
  {
    uuid: "f87bf3d3-465d-48c6-a843-e8efcb6ef95e",
    iconSet: "Ionicons",
    iconName: "map-outline"
  },
  {
    uuid: "ce8ef51c-1652-4b26-b4f0-3ce124b01744",
    iconSet: "Ionicons",
    iconName: "map-sharp"
  },
  {
    uuid: "0ee9c391-f5f0-4838-8a70-5cee5d42717a",
    iconSet: "Ionicons",
    iconName: "medal"
  },
  {
    uuid: "f5dc385b-5d32-4d25-808e-da30bf885fa5",
    iconSet: "Ionicons",
    iconName: "medal-outline"
  },
  {
    uuid: "b1679ef4-aa3d-4034-962a-babb89e9339d",
    iconSet: "Ionicons",
    iconName: "medal-sharp"
  },
  {
    uuid: "036f428f-b3a7-4135-8e73-16f7a5b62e2c",
    iconSet: "Ionicons",
    iconName: "medical"
  },
  {
    uuid: "f610aca9-93fa-4d65-b0b7-e63a2bd2621f",
    iconSet: "Ionicons",
    iconName: "medical-outline"
  },
  {
    uuid: "7222876b-9156-46f6-8ccd-024fc523c17e",
    iconSet: "Ionicons",
    iconName: "medical-sharp"
  },
  {
    uuid: "29a03a7c-e2aa-48b9-b69d-6ce4c2dbc309",
    iconSet: "Ionicons",
    iconName: "medkit"
  },
  {
    uuid: "a5d67078-cdb0-4ea4-b1ac-36bd6dcab710",
    iconSet: "Ionicons",
    iconName: "medkit-outline"
  },
  {
    uuid: "3b6cfc95-bc74-4822-9298-fbce22f61ccb",
    iconSet: "Ionicons",
    iconName: "medkit-sharp"
  },
  {
    uuid: "7e751cfc-efaf-403c-be3a-307f98f5a07d",
    iconSet: "Ionicons",
    iconName: "megaphone"
  },
  {
    uuid: "505a2f09-3ab8-410f-920e-dce2683d2616",
    iconSet: "Ionicons",
    iconName: "megaphone-outline"
  },
  {
    uuid: "c8518e70-aa63-4de8-ab54-ce6b3804f0bd",
    iconSet: "Ionicons",
    iconName: "megaphone-sharp"
  },
  {
    uuid: "5c0c8330-7a4d-4950-8650-a5969c7178c0",
    iconSet: "Ionicons",
    iconName: "menu"
  },
  {
    uuid: "007528b8-52aa-4ec2-8caf-5fc3917e1e79",
    iconSet: "Ionicons",
    iconName: "menu-outline"
  },
  {
    uuid: "0c791ab5-1d6f-455c-abfa-32843b048a60",
    iconSet: "Ionicons",
    iconName: "menu-sharp"
  },
  {
    uuid: "81771a9c-a22d-44c3-858c-6a989f36e92b",
    iconSet: "Ionicons",
    iconName: "mic"
  },
  {
    uuid: "9a5f944f-6fd1-4215-a6f0-4995b42c2c99",
    iconSet: "Ionicons",
    iconName: "mic-circle"
  },
  {
    uuid: "b0c16a8d-f207-4f0e-b291-66fd478c5586",
    iconSet: "Ionicons",
    iconName: "mic-circle-outline"
  },
  {
    uuid: "c0060e7c-7924-40c3-b654-41993248e0c6",
    iconSet: "Ionicons",
    iconName: "mic-circle-sharp"
  },
  {
    uuid: "3ed2823c-ff14-4b7f-96e5-19cedc355950",
    iconSet: "Ionicons",
    iconName: "mic-off"
  },
  {
    uuid: "44a2a304-1ed8-4df6-b14d-956506f13cff",
    iconSet: "Ionicons",
    iconName: "mic-off-circle"
  },
  {
    uuid: "ccd7c02e-d8b8-472a-8e59-3f9687dd89f5",
    iconSet: "Ionicons",
    iconName: "mic-off-circle-outline"
  },
  {
    uuid: "af5c1123-feee-42e4-9e7a-78ee81edab54",
    iconSet: "Ionicons",
    iconName: "mic-off-circle-sharp"
  },
  {
    uuid: "5369cd0e-9824-4ecb-92dd-063a1021cc87",
    iconSet: "Ionicons",
    iconName: "mic-off-outline"
  },
  {
    uuid: "c8539b9e-cbdc-4788-aae2-4e675067d08e",
    iconSet: "Ionicons",
    iconName: "mic-off-sharp"
  },
  {
    uuid: "3d3b9f43-2bfd-425c-9429-346aaab11bbb",
    iconSet: "Ionicons",
    iconName: "mic-outline"
  },
  {
    uuid: "3ec43147-c31a-43e7-9ee5-75344600ce22",
    iconSet: "Ionicons",
    iconName: "mic-sharp"
  },
  {
    uuid: "dd2600c9-eb57-46b0-aebd-452ba293e282",
    iconSet: "Ionicons",
    iconName: "moon"
  },
  {
    uuid: "69a80825-2bd3-40e8-ba4d-afd81294e9fe",
    iconSet: "Ionicons",
    iconName: "moon-outline"
  },
  {
    uuid: "4b8b5658-7fd3-4789-bdbc-41877f20b876",
    iconSet: "Ionicons",
    iconName: "moon-sharp"
  },
  {
    uuid: "970c1145-c890-4b78-bcb4-46b2fbb6c5fe",
    iconSet: "Ionicons",
    iconName: "move"
  },
  {
    uuid: "9420d390-112b-4db8-bbeb-ecbd0423d369",
    iconSet: "Ionicons",
    iconName: "move-outline"
  },
  {
    uuid: "0c0c99fc-3cc0-4fc4-b9fd-d4da7be62aa2",
    iconSet: "Ionicons",
    iconName: "move-sharp"
  },
  {
    uuid: "8ee843eb-0437-4266-bdbe-be8699390568",
    iconSet: "Ionicons",
    iconName: "musical-note"
  },
  {
    uuid: "db93a857-6b4a-4246-9300-db8d4e0028c4",
    iconSet: "Ionicons",
    iconName: "musical-note-outline"
  },
  {
    uuid: "e85faf4b-90a4-4c91-b428-7c731603519a",
    iconSet: "Ionicons",
    iconName: "musical-note-sharp"
  },
  {
    uuid: "a69ce211-6678-400b-a0ef-e81dc0dbd47d",
    iconSet: "Ionicons",
    iconName: "musical-notes"
  },
  {
    uuid: "5f92efaa-07ac-451e-8b88-314dbc7ea918",
    iconSet: "Ionicons",
    iconName: "musical-notes-outline"
  },
  {
    uuid: "de8c470b-fc8f-48da-b9da-2b739d015f5d",
    iconSet: "Ionicons",
    iconName: "musical-notes-sharp"
  },
  {
    uuid: "9ad1c3db-fba7-4886-82f0-03b047196978",
    iconSet: "Ionicons",
    iconName: "navigate"
  },
  {
    uuid: "0747c144-e623-4eff-aa02-c95dfd26ae28",
    iconSet: "Ionicons",
    iconName: "navigate-circle"
  },
  {
    uuid: "0b7d3f65-951b-468c-b2d5-30f81e20b680",
    iconSet: "Ionicons",
    iconName: "navigate-circle-outline"
  },
  {
    uuid: "efbed0c5-1b17-467c-b780-1e3820b9f859",
    iconSet: "Ionicons",
    iconName: "navigate-circle-sharp"
  },
  {
    uuid: "2b03b1b0-d488-49d2-9146-a95b2a3a8dce",
    iconSet: "Ionicons",
    iconName: "navigate-outline"
  },
  {
    uuid: "5b425cf4-bdf4-43d6-b533-98aff0b5c6cd",
    iconSet: "Ionicons",
    iconName: "navigate-sharp"
  },
  {
    uuid: "7b11e099-6f53-42bd-84b5-2a6c90922bd3",
    iconSet: "Ionicons",
    iconName: "newspaper"
  },
  {
    uuid: "cdbc54f0-3fa2-4753-ad89-b9c859a908d0",
    iconSet: "Ionicons",
    iconName: "newspaper-outline"
  },
  {
    uuid: "2ca8ab42-4c34-411c-9286-72a9fced60f6",
    iconSet: "Ionicons",
    iconName: "newspaper-sharp"
  },
  {
    uuid: "8fb4e65e-2cd1-43da-b821-d9a0ffef56f8",
    iconSet: "Ionicons",
    iconName: "notifications"
  },
  {
    uuid: "1724b267-444a-443e-b538-4adca85f679f",
    iconSet: "Ionicons",
    iconName: "notifications-circle"
  },
  {
    uuid: "00039377-bb19-4c47-b630-0d3cc6193752",
    iconSet: "Ionicons",
    iconName: "notifications-circle-outline"
  },
  {
    uuid: "e3ded020-2f2e-4113-ba9f-2df0593a2ce3",
    iconSet: "Ionicons",
    iconName: "notifications-circle-sharp"
  },
  {
    uuid: "d4dfdb12-6c32-4bec-998b-514448d010d3",
    iconSet: "Ionicons",
    iconName: "notifications-off"
  },
  {
    uuid: "f1d46d2c-1a13-4d80-85f5-0df22bf14e0e",
    iconSet: "Ionicons",
    iconName: "notifications-off-circle"
  },
  {
    uuid: "d388c89f-07da-4002-96d7-d0db8547f48c",
    iconSet: "Ionicons",
    iconName: "notifications-off-circle-outline"
  },
  {
    uuid: "201df090-6029-4c07-b361-222899fe2b94",
    iconSet: "Ionicons",
    iconName: "notifications-off-circle-sharp"
  },
  {
    uuid: "ed3df922-7d9b-4710-8cf7-5f5b00315dfc",
    iconSet: "Ionicons",
    iconName: "notifications-off-outline"
  },
  {
    uuid: "6e713848-d0ad-4351-aae0-77936e7d7f40",
    iconSet: "Ionicons",
    iconName: "notifications-off-sharp"
  },
  {
    uuid: "29fdd3e3-bc85-45b0-976c-fb3b6287cbe8",
    iconSet: "Ionicons",
    iconName: "notifications-outline"
  },
  {
    uuid: "ac2e100e-a7fa-4a9f-9515-9876b2900bb7",
    iconSet: "Ionicons",
    iconName: "notifications-sharp"
  },
  {
    uuid: "24dadfb6-6ee5-4da4-8d88-45a79880f3e0",
    iconSet: "Ionicons",
    iconName: "nuclear"
  },
  {
    uuid: "e14e0cbf-0554-4682-be3a-3bf4c014e090",
    iconSet: "Ionicons",
    iconName: "nuclear-outline"
  },
  {
    uuid: "a2fabde1-cd35-4316-b941-773c1d6116eb",
    iconSet: "Ionicons",
    iconName: "nuclear-sharp"
  },
  {
    uuid: "3ad9ad73-7920-471e-bf38-4c66e41f923f",
    iconSet: "Ionicons",
    iconName: "nutrition"
  },
  {
    uuid: "17161a2f-5ef9-481e-896a-2af035416c44",
    iconSet: "Ionicons",
    iconName: "nutrition-outline"
  },
  {
    uuid: "32a5b0b6-9024-4e01-a891-b3538bfa0f0a",
    iconSet: "Ionicons",
    iconName: "nutrition-sharp"
  },
  {
    uuid: "fb3a1f57-c151-4608-b31a-1977a69b2150",
    iconSet: "Ionicons",
    iconName: "open"
  },
  {
    uuid: "7a10b89a-2f31-4f34-80a5-f9df44b519ab",
    iconSet: "Ionicons",
    iconName: "open-outline"
  },
  {
    uuid: "078cc205-6219-456a-b68f-e4d492e1aef8",
    iconSet: "Ionicons",
    iconName: "open-sharp"
  },
  {
    uuid: "229f5c55-adf4-499c-a1e0-cd57a8d0acee",
    iconSet: "Ionicons",
    iconName: "options"
  },
  {
    uuid: "b9bc2f6d-5e55-4c82-a662-1544d891a16c",
    iconSet: "Ionicons",
    iconName: "options-outline"
  },
  {
    uuid: "f3b1789d-4db2-49f7-bc56-a7b832bcd1b7",
    iconSet: "Ionicons",
    iconName: "options-sharp"
  },
  {
    uuid: "559ba488-aef8-4103-b9da-f7489d6e3c8e",
    iconSet: "Ionicons",
    iconName: "paper-plane"
  },
  {
    uuid: "4a924c77-1d6c-4786-a9a5-e6357676c357",
    iconSet: "Ionicons",
    iconName: "paper-plane-outline"
  },
  {
    uuid: "2618200d-6a1d-4f21-8677-075c05fbae7d",
    iconSet: "Ionicons",
    iconName: "paper-plane-sharp"
  },
  {
    uuid: "41a68f7e-14fe-4dfd-b26b-b4f812cd91e0",
    iconSet: "Ionicons",
    iconName: "partly-sunny"
  },
  {
    uuid: "6251b2af-a603-4973-9854-9897e6a0e5af",
    iconSet: "Ionicons",
    iconName: "partly-sunny-outline"
  },
  {
    uuid: "568ae3f9-d8ba-4360-b41d-b9f46d02af6f",
    iconSet: "Ionicons",
    iconName: "partly-sunny-sharp"
  },
  {
    uuid: "4f7fd0d9-fd73-4bb9-80ff-ffd30597f088",
    iconSet: "Ionicons",
    iconName: "pause"
  },
  {
    uuid: "d14db34f-227a-4204-af16-64c06fbd0caf",
    iconSet: "Ionicons",
    iconName: "pause-circle"
  },
  {
    uuid: "dd1cac78-e306-4d68-a9de-9ad50a47d0d3",
    iconSet: "Ionicons",
    iconName: "pause-circle-outline"
  },
  {
    uuid: "9feeb051-4eb1-4f76-b3d7-ee28889aa744",
    iconSet: "Ionicons",
    iconName: "pause-circle-sharp"
  },
  {
    uuid: "3ad52e14-7585-4cf4-8b23-7a636868f41f",
    iconSet: "Ionicons",
    iconName: "pause-outline"
  },
  {
    uuid: "9c260040-d2fe-4db4-b1fd-d1fae0e55db2",
    iconSet: "Ionicons",
    iconName: "pause-sharp"
  },
  {
    uuid: "0ab6169c-fa37-4dc0-82c2-2b1d3b57ff9e",
    iconSet: "Ionicons",
    iconName: "paw"
  },
  {
    uuid: "69575b07-0c8c-4fcd-b9be-ed7b55cf38d4",
    iconSet: "Ionicons",
    iconName: "paw-outline"
  },
  {
    uuid: "41968638-23de-4469-9a7d-4cf8d32cb487",
    iconSet: "Ionicons",
    iconName: "paw-sharp"
  },
  {
    uuid: "7e1e7946-6acf-4d2a-a6bb-9799839d408e",
    iconSet: "Ionicons",
    iconName: "pencil"
  },
  {
    uuid: "2a74b17c-349c-47cf-bdbf-860e271aaf0e",
    iconSet: "Ionicons",
    iconName: "pencil-outline"
  },
  {
    uuid: "914abc41-edfc-4409-8cdd-26f06a1a3f3d",
    iconSet: "Ionicons",
    iconName: "pencil-sharp"
  },
  {
    uuid: "c8f72f22-6d9c-47ec-8ee8-8b7a59b6ee87",
    iconSet: "Ionicons",
    iconName: "people"
  },
  {
    uuid: "a5ec2d0d-c3f8-4bc3-90ac-81097c6917b8",
    iconSet: "Ionicons",
    iconName: "people-circle"
  },
  {
    uuid: "3d759b5b-58b4-4f89-9991-88b1d671dad7",
    iconSet: "Ionicons",
    iconName: "people-circle-outline"
  },
  {
    uuid: "b990b8b5-2545-4fa3-97eb-f2d1263496c7",
    iconSet: "Ionicons",
    iconName: "people-circle-sharp"
  },
  {
    uuid: "f6e2c3d3-a799-482c-b557-b65f6c5a6925",
    iconSet: "Ionicons",
    iconName: "people-outline"
  },
  {
    uuid: "074e5bc2-8d34-4037-a792-e630899280ad",
    iconSet: "Ionicons",
    iconName: "people-sharp"
  },
  {
    uuid: "7158674b-ddb6-4635-a665-45df69ab3ec3",
    iconSet: "Ionicons",
    iconName: "person"
  },
  {
    uuid: "a7ff05e4-5760-4754-b80d-9690a4a08239",
    iconSet: "Ionicons",
    iconName: "person-add"
  },
  {
    uuid: "6945e8be-2ed0-4d5a-80ea-743649c856f7",
    iconSet: "Ionicons",
    iconName: "person-add-outline"
  },
  {
    uuid: "68481f56-656d-47d2-8913-f1c399ccbb4e",
    iconSet: "Ionicons",
    iconName: "person-add-sharp"
  },
  {
    uuid: "6e986b84-627c-4ead-952e-e8c7d120ce90",
    iconSet: "Ionicons",
    iconName: "person-circle"
  },
  {
    uuid: "9f7e1a54-779b-41ca-93d3-2bc03099077e",
    iconSet: "Ionicons",
    iconName: "person-circle-outline"
  },
  {
    uuid: "44d056b3-4ed3-43cb-b7b7-019a2af82d66",
    iconSet: "Ionicons",
    iconName: "person-circle-sharp"
  },
  {
    uuid: "57ec0be9-756d-45d5-bedc-81940f4b8bc2",
    iconSet: "Ionicons",
    iconName: "person-outline"
  },
  {
    uuid: "f3c7f1e7-aa86-4ba3-946c-3e400d0f729d",
    iconSet: "Ionicons",
    iconName: "person-remove"
  },
  {
    uuid: "89d74fb9-98a2-4b69-9ff1-87e522e7ba96",
    iconSet: "Ionicons",
    iconName: "person-remove-outline"
  },
  {
    uuid: "c8afb4df-3119-4407-8f20-d005797cbddd",
    iconSet: "Ionicons",
    iconName: "person-remove-sharp"
  },
  {
    uuid: "2b347f36-0fcb-43a3-98db-cad9f2840e95",
    iconSet: "Ionicons",
    iconName: "person-sharp"
  },
  {
    uuid: "6c0068b0-12c3-4eee-a256-d72f8b017736",
    iconSet: "Ionicons",
    iconName: "phone-landscape"
  },
  {
    uuid: "06dafe10-d06f-462e-aa1c-deffa279fc4f",
    iconSet: "Ionicons",
    iconName: "phone-landscape-outline"
  },
  {
    uuid: "3a8b87b7-e9d2-45cb-a4a4-8c8a98c2b49f",
    iconSet: "Ionicons",
    iconName: "phone-landscape-sharp"
  },
  {
    uuid: "a1867008-73db-4b70-9b42-0014f592928c",
    iconSet: "Ionicons",
    iconName: "phone-portrait"
  },
  {
    uuid: "e7256323-1d49-4b6d-a9d8-2a5af93c9a16",
    iconSet: "Ionicons",
    iconName: "phone-portrait-outline"
  },
  {
    uuid: "b2e4411e-3ea4-4098-878f-016e3ecad29d",
    iconSet: "Ionicons",
    iconName: "phone-portrait-sharp"
  },
  {
    uuid: "ad999ced-cfdb-4dc5-b172-db4a30d4761d",
    iconSet: "Ionicons",
    iconName: "pie-chart"
  },
  {
    uuid: "2f1c6348-8095-47c8-a000-bc1367559e24",
    iconSet: "Ionicons",
    iconName: "pie-chart-outline"
  },
  {
    uuid: "fc1ed9de-9bd5-4dad-bc37-17fb9d53f7fa",
    iconSet: "Ionicons",
    iconName: "pie-chart-sharp"
  },
  {
    uuid: "c20bb102-c431-4cc8-aa83-6b3e92d1a081",
    iconSet: "Ionicons",
    iconName: "pin"
  },
  {
    uuid: "59af987b-1508-410e-a7cd-ea3538605529",
    iconSet: "Ionicons",
    iconName: "pin-outline"
  },
  {
    uuid: "4400cffc-2344-420d-8976-d0ac519935cb",
    iconSet: "Ionicons",
    iconName: "pin-sharp"
  },
  {
    uuid: "b7823bb2-3628-4495-9973-8828bc56b3f9",
    iconSet: "Ionicons",
    iconName: "pint"
  },
  {
    uuid: "6abf64cc-6ee4-4016-9303-c61db0fd5df1",
    iconSet: "Ionicons",
    iconName: "pint-outline"
  },
  {
    uuid: "074ebd1b-5c07-4562-8f3f-626aa1f1810b",
    iconSet: "Ionicons",
    iconName: "pint-sharp"
  },
  {
    uuid: "6aa955be-4c79-4b57-a908-929987ccb3b1",
    iconSet: "Ionicons",
    iconName: "pizza"
  },
  {
    uuid: "86a67478-c80c-40c7-93ba-29195b0493e8",
    iconSet: "Ionicons",
    iconName: "pizza-outline"
  },
  {
    uuid: "982ccc0d-ff07-4c3a-a51b-c028ab992738",
    iconSet: "Ionicons",
    iconName: "pizza-sharp"
  },
  {
    uuid: "22ade130-195b-4099-b630-e2ed5134e1ac",
    iconSet: "Ionicons",
    iconName: "planet"
  },
  {
    uuid: "59d64a27-a348-4c53-b09b-e9e36d5337fd",
    iconSet: "Ionicons",
    iconName: "planet-outline"
  },
  {
    uuid: "e38e80c6-3f84-4709-9a39-c777fa6d2c94",
    iconSet: "Ionicons",
    iconName: "planet-sharp"
  },
  {
    uuid: "89621816-1bd3-4cf3-aa63-7a84fa473506",
    iconSet: "Ionicons",
    iconName: "play"
  },
  {
    uuid: "2f4b738f-7f87-432b-80d2-f67aaf59cc1a",
    iconSet: "Ionicons",
    iconName: "play-back"
  },
  {
    uuid: "3d27172b-d11d-439b-a05f-768f3602ba01",
    iconSet: "Ionicons",
    iconName: "play-back-circle"
  },
  {
    uuid: "a910d1f1-3a97-4da3-94e1-c1fd6120f99c",
    iconSet: "Ionicons",
    iconName: "play-back-circle-outline"
  },
  {
    uuid: "123fa80b-9a20-48a5-a5f2-b47f870c7adb",
    iconSet: "Ionicons",
    iconName: "play-back-circle-sharp"
  },
  {
    uuid: "b039fb45-ac8f-4de9-9d59-1f8493765d72",
    iconSet: "Ionicons",
    iconName: "play-back-outline"
  },
  {
    uuid: "dcddaa45-6ee7-49c6-8581-fd4deff88be7",
    iconSet: "Ionicons",
    iconName: "play-back-sharp"
  },
  {
    uuid: "c04dba19-7af2-4ddb-9433-1f5f0452f738",
    iconSet: "Ionicons",
    iconName: "play-circle"
  },
  {
    uuid: "e7817419-8a6a-4074-9aff-828497e85db4",
    iconSet: "Ionicons",
    iconName: "play-circle-outline"
  },
  {
    uuid: "4857007d-d4c5-49ad-8be7-1fa2f77b99b9",
    iconSet: "Ionicons",
    iconName: "play-circle-sharp"
  },
  {
    uuid: "e20b83d7-b228-4611-8857-f062300ecc94",
    iconSet: "Ionicons",
    iconName: "play-forward"
  },
  {
    uuid: "16c2144c-7a41-4afa-ad45-9613972aaf8d",
    iconSet: "Ionicons",
    iconName: "play-forward-circle"
  },
  {
    uuid: "51915c02-7f7d-476e-94b5-550b96e2135c",
    iconSet: "Ionicons",
    iconName: "play-forward-circle-outline"
  },
  {
    uuid: "a2bc4030-f278-4ad2-b7d1-de542fee0935",
    iconSet: "Ionicons",
    iconName: "play-forward-circle-sharp"
  },
  {
    uuid: "56914cba-862b-4c3d-bbf4-48cebbc16fc2",
    iconSet: "Ionicons",
    iconName: "play-forward-outline"
  },
  {
    uuid: "676bddb2-e5a4-43dd-8bd8-6739a8dfebaa",
    iconSet: "Ionicons",
    iconName: "play-forward-sharp"
  },
  {
    uuid: "5361ac7a-0dce-43a6-91bc-1ce49b9142c0",
    iconSet: "Ionicons",
    iconName: "play-outline"
  },
  {
    uuid: "e08562f9-76f5-4149-b168-877736868dbf",
    iconSet: "Ionicons",
    iconName: "play-sharp"
  },
  {
    uuid: "adf35447-18c1-4143-9798-0976773001c6",
    iconSet: "Ionicons",
    iconName: "play-skip-back"
  },
  {
    uuid: "1568d06b-6e6c-4bd8-a5d9-e32c37bc3964",
    iconSet: "Ionicons",
    iconName: "play-skip-back-circle"
  },
  {
    uuid: "51cfa708-79bc-44aa-aa22-571fd94e036f",
    iconSet: "Ionicons",
    iconName: "play-skip-back-circle-outline"
  },
  {
    uuid: "88e1e37d-3f53-472c-9df7-311eeb7b37e7",
    iconSet: "Ionicons",
    iconName: "play-skip-back-circle-sharp"
  },
  {
    uuid: "52870f03-a811-4572-9adb-b55dea5f5a0e",
    iconSet: "Ionicons",
    iconName: "play-skip-back-outline"
  },
  {
    uuid: "5b923ce3-a894-4ba8-9ee0-ef00ae535a97",
    iconSet: "Ionicons",
    iconName: "play-skip-back-sharp"
  },
  {
    uuid: "c63c353c-fff3-484b-8410-9f4d87e9b90b",
    iconSet: "Ionicons",
    iconName: "play-skip-forward"
  },
  {
    uuid: "19a20e74-d088-4218-bb16-734e83da3f59",
    iconSet: "Ionicons",
    iconName: "play-skip-forward-circle"
  },
  {
    uuid: "236da48e-4c9d-498f-9a03-3e4a1d31025a",
    iconSet: "Ionicons",
    iconName: "play-skip-forward-circle-outline"
  },
  {
    uuid: "d1947f4e-c7a9-4356-9c03-03bfdbe16b16",
    iconSet: "Ionicons",
    iconName: "play-skip-forward-circle-sharp"
  },
  {
    uuid: "fa0e7d72-2ba9-4498-b292-ed7404e8c469",
    iconSet: "Ionicons",
    iconName: "play-skip-forward-outline"
  },
  {
    uuid: "5af2f1d4-a4c0-4b51-9404-c53be2131d4e",
    iconSet: "Ionicons",
    iconName: "play-skip-forward-sharp"
  },
  {
    uuid: "c86498a8-a91e-465d-826c-1278b3a60f02",
    iconSet: "Ionicons",
    iconName: "podium"
  },
  {
    uuid: "ac65e4d2-6c58-4e23-8bcc-64b2e4f8028e",
    iconSet: "Ionicons",
    iconName: "podium-outline"
  },
  {
    uuid: "bcc996f4-a49b-43ba-927a-22e17d981c95",
    iconSet: "Ionicons",
    iconName: "podium-sharp"
  },
  {
    uuid: "45e440c5-2e57-406d-a617-aea113e3dbc5",
    iconSet: "Ionicons",
    iconName: "power"
  },
  {
    uuid: "f1a4ddf7-e471-4938-a377-02db645ccfb6",
    iconSet: "Ionicons",
    iconName: "power-outline"
  },
  {
    uuid: "75fed43d-e38f-4b9f-a524-7a8d479d9734",
    iconSet: "Ionicons",
    iconName: "power-sharp"
  },
  {
    uuid: "54cc5e0e-d85f-4e74-8051-c97627ec2271",
    iconSet: "Ionicons",
    iconName: "pricetag"
  },
  {
    uuid: "92998ce1-af3d-4a89-a43e-d85eb995643d",
    iconSet: "Ionicons",
    iconName: "pricetag-outline"
  },
  {
    uuid: "157b85f5-a96c-47c8-aab6-cc0a04dd5456",
    iconSet: "Ionicons",
    iconName: "pricetag-sharp"
  },
  {
    uuid: "e5447e10-1799-4666-8da6-d97ee832ef21",
    iconSet: "Ionicons",
    iconName: "pricetags"
  },
  {
    uuid: "c03f1665-9d36-43b2-aa1e-b552339591fc",
    iconSet: "Ionicons",
    iconName: "pricetags-outline"
  },
  {
    uuid: "e9cd416d-e87e-45df-99ea-e66cee125130",
    iconSet: "Ionicons",
    iconName: "pricetags-sharp"
  },
  {
    uuid: "ff5999bf-c10a-449d-800f-ed59c8d79b0a",
    iconSet: "Ionicons",
    iconName: "print"
  },
  {
    uuid: "869cbe2a-3bca-4894-816f-59385f9e2435",
    iconSet: "Ionicons",
    iconName: "print-outline"
  },
  {
    uuid: "89ce4b0c-e151-4e75-95f9-652584d280d8",
    iconSet: "Ionicons",
    iconName: "print-sharp"
  },
  {
    uuid: "8edfd81b-593b-40b0-9e0c-32f45b945375",
    iconSet: "Ionicons",
    iconName: "pulse"
  },
  {
    uuid: "70e61bf5-affc-45c9-80c6-66450f54d987",
    iconSet: "Ionicons",
    iconName: "pulse-outline"
  },
  {
    uuid: "6ce6d2b5-1977-4c98-9fea-c8aac5e6c1c5",
    iconSet: "Ionicons",
    iconName: "pulse-sharp"
  },
  {
    uuid: "c0283440-cbbb-4e73-bb2e-37e1212c7c2e",
    iconSet: "Ionicons",
    iconName: "push"
  },
  {
    uuid: "978d130b-92f7-43df-b47f-6ccc2ab2ac12",
    iconSet: "Ionicons",
    iconName: "push-outline"
  },
  {
    uuid: "64154094-4606-4917-bfc0-f7be1323f819",
    iconSet: "Ionicons",
    iconName: "push-sharp"
  },
  {
    uuid: "14c2123e-a2de-4f7f-8948-9d8d9d42eabc",
    iconSet: "Ionicons",
    iconName: "qr-code"
  },
  {
    uuid: "36119cbb-a8e0-4e80-850c-ea72d7c927a2",
    iconSet: "Ionicons",
    iconName: "qr-code-outline"
  },
  {
    uuid: "8459a7e8-3f66-46d7-a961-74ad2885c923",
    iconSet: "Ionicons",
    iconName: "qr-code-sharp"
  },
  {
    uuid: "734dbe2b-1ef1-4355-9026-ae72756fefec",
    iconSet: "Ionicons",
    iconName: "radio"
  },
  {
    uuid: "9e3c81c9-9df7-4008-81e2-0068b8d13d3f",
    iconSet: "Ionicons",
    iconName: "radio-button-off"
  },
  {
    uuid: "3e4a344e-30c1-4d60-994e-47273fb3f31c",
    iconSet: "Ionicons",
    iconName: "radio-button-off-outline"
  },
  {
    uuid: "109d6603-b815-416f-8548-b76b18350c3d",
    iconSet: "Ionicons",
    iconName: "radio-button-off-sharp"
  },
  {
    uuid: "9bed0520-7f68-460b-ac47-6989de59fe35",
    iconSet: "Ionicons",
    iconName: "radio-button-on"
  },
  {
    uuid: "fa2a454e-6a20-46ac-b677-b4a43ca65e28",
    iconSet: "Ionicons",
    iconName: "radio-button-on-outline"
  },
  {
    uuid: "35ec2937-a2ef-4eec-a9be-1ddb3df47a21",
    iconSet: "Ionicons",
    iconName: "radio-button-on-sharp"
  },
  {
    uuid: "c310c315-4b3f-4869-8884-a21a74ced126",
    iconSet: "Ionicons",
    iconName: "radio-outline"
  },
  {
    uuid: "6286f35e-6d22-4794-adf0-46719421c93c",
    iconSet: "Ionicons",
    iconName: "radio-sharp"
  },
  {
    uuid: "8e03cf55-c45f-475e-a23f-ef28a09fe68e",
    iconSet: "Ionicons",
    iconName: "rainy"
  },
  {
    uuid: "1a3d7454-2fd6-418a-ab8d-eac5268a061a",
    iconSet: "Ionicons",
    iconName: "rainy-outline"
  },
  {
    uuid: "142ea9af-dbad-444e-8d05-b8305cebea4a",
    iconSet: "Ionicons",
    iconName: "rainy-sharp"
  },
  {
    uuid: "a7fce9c9-9c75-4e52-b5a7-9460b90494ca",
    iconSet: "Ionicons",
    iconName: "reader"
  },
  {
    uuid: "7ead2043-ff88-4aae-8f59-97c412492ff7",
    iconSet: "Ionicons",
    iconName: "reader-outline"
  },
  {
    uuid: "29478687-d1ca-464f-b54a-c5a2302b6df1",
    iconSet: "Ionicons",
    iconName: "reader-sharp"
  },
  {
    uuid: "9817e97f-5360-490e-af7e-3064be88397b",
    iconSet: "Ionicons",
    iconName: "receipt"
  },
  {
    uuid: "5abf56a1-7654-45ad-a4ae-fa0aa30a9bf7",
    iconSet: "Ionicons",
    iconName: "receipt-outline"
  },
  {
    uuid: "51b649be-5651-4f16-b583-d58a0c00d366",
    iconSet: "Ionicons",
    iconName: "receipt-sharp"
  },
  {
    uuid: "ccb29e3c-33d0-4223-9861-b7e38c86a466",
    iconSet: "Ionicons",
    iconName: "recording"
  },
  {
    uuid: "86a555ca-c5b6-4d18-96f4-dc7821415869",
    iconSet: "Ionicons",
    iconName: "recording-outline"
  },
  {
    uuid: "474c7f0f-1ad5-494a-a6df-1a637fe31a32",
    iconSet: "Ionicons",
    iconName: "recording-sharp"
  },
  {
    uuid: "5bff678d-2ea9-4942-973d-269e147cef88",
    iconSet: "Ionicons",
    iconName: "refresh"
  },
  {
    uuid: "063ae2fb-2559-4e67-86a9-03ebe78a3290",
    iconSet: "Ionicons",
    iconName: "refresh-circle"
  },
  {
    uuid: "bf508744-0bc4-47be-8759-1f685feb6c69",
    iconSet: "Ionicons",
    iconName: "refresh-circle-outline"
  },
  {
    uuid: "c785f8db-783d-4f0b-9696-275693876a00",
    iconSet: "Ionicons",
    iconName: "refresh-circle-sharp"
  },
  {
    uuid: "559b986c-f273-4812-9829-619460baa107",
    iconSet: "Ionicons",
    iconName: "refresh-outline"
  },
  {
    uuid: "faf2ba2c-c5b1-40c5-9cbe-7158e77cefb3",
    iconSet: "Ionicons",
    iconName: "refresh-sharp"
  },
  {
    uuid: "abdcb6c1-34d4-4687-9bea-593ee69eccd5",
    iconSet: "Ionicons",
    iconName: "reload"
  },
  {
    uuid: "c420810d-2bfa-4630-a139-8edc7b508dfb",
    iconSet: "Ionicons",
    iconName: "reload-circle"
  },
  {
    uuid: "67e7d6b4-1810-4b4d-ba7d-408e5405046c",
    iconSet: "Ionicons",
    iconName: "reload-circle-outline"
  },
  {
    uuid: "bf5fda1d-2287-4a22-a35f-e3507fc91384",
    iconSet: "Ionicons",
    iconName: "reload-circle-sharp"
  },
  {
    uuid: "8aca96c1-4543-406b-926d-55ef084a8f4a",
    iconSet: "Ionicons",
    iconName: "reload-outline"
  },
  {
    uuid: "b66b8ccd-1666-4d89-9874-fad40795b2a8",
    iconSet: "Ionicons",
    iconName: "reload-sharp"
  },
  {
    uuid: "b08aaef0-94bc-43d6-8dca-0eb00e6b67eb",
    iconSet: "Ionicons",
    iconName: "remove"
  },
  {
    uuid: "4d3f37bd-aad0-4550-b4c8-207e1a94e40c",
    iconSet: "Ionicons",
    iconName: "remove-circle"
  },
  {
    uuid: "fc4eeefa-8748-4807-b90f-ee2bc70a9f71",
    iconSet: "Ionicons",
    iconName: "remove-circle-outline"
  },
  {
    uuid: "f7ca777b-835d-4564-b7fd-317a7b35e23d",
    iconSet: "Ionicons",
    iconName: "remove-circle-sharp"
  },
  {
    uuid: "0af93dd1-5f7b-4e5a-92f3-ca82e2792908",
    iconSet: "Ionicons",
    iconName: "remove-outline"
  },
  {
    uuid: "a184fe5b-56ce-4a6e-b2bc-9748b7f7045a",
    iconSet: "Ionicons",
    iconName: "remove-sharp"
  },
  {
    uuid: "478da62d-19f6-41c6-9a4d-9e630a7e35d3",
    iconSet: "Ionicons",
    iconName: "reorder-four"
  },
  {
    uuid: "d02f4b1b-4bcd-4ea3-a30c-3c34950d7a32",
    iconSet: "Ionicons",
    iconName: "reorder-four-outline"
  },
  {
    uuid: "b435f20b-773a-4449-ab95-6b28feac6b85",
    iconSet: "Ionicons",
    iconName: "reorder-four-sharp"
  },
  {
    uuid: "adebf7f3-6aba-4f3e-b4b0-8ab8e182785e",
    iconSet: "Ionicons",
    iconName: "reorder-three"
  },
  {
    uuid: "40ed4fe6-47b4-4360-97d1-1d10a756d04b",
    iconSet: "Ionicons",
    iconName: "reorder-three-outline"
  },
  {
    uuid: "fa79d85d-2585-4be0-b5c2-72547a4f9afe",
    iconSet: "Ionicons",
    iconName: "reorder-three-sharp"
  },
  {
    uuid: "2c4fc9b9-f900-46ff-9cf5-212b6667789b",
    iconSet: "Ionicons",
    iconName: "reorder-two"
  },
  {
    uuid: "1a5683d4-1e90-4d8f-9429-1946c27ff44d",
    iconSet: "Ionicons",
    iconName: "reorder-two-outline"
  },
  {
    uuid: "866f3aa8-e7dd-4ffe-adb3-22ce58d0c46c",
    iconSet: "Ionicons",
    iconName: "reorder-two-sharp"
  },
  {
    uuid: "d2e3b674-c034-4451-b2f7-fea4938669b0",
    iconSet: "Ionicons",
    iconName: "repeat"
  },
  {
    uuid: "dc07366b-fb09-4c3e-8ada-50dab21b8f85",
    iconSet: "Ionicons",
    iconName: "repeat-outline"
  },
  {
    uuid: "be8c9b2f-7eec-40d4-8ee2-9483bc84e68d",
    iconSet: "Ionicons",
    iconName: "repeat-sharp"
  },
  {
    uuid: "cdc5e784-de36-41c7-ad68-36dd68b8a0ec",
    iconSet: "Ionicons",
    iconName: "resize"
  },
  {
    uuid: "336135cd-1df5-4303-a9df-5df8abe3a797",
    iconSet: "Ionicons",
    iconName: "resize-outline"
  },
  {
    uuid: "a9753422-e530-4e55-8b4d-f927006e1e4c",
    iconSet: "Ionicons",
    iconName: "resize-sharp"
  },
  {
    uuid: "49950cc4-ff1c-4452-83cd-face3f7967a9",
    iconSet: "Ionicons",
    iconName: "restaurant"
  },
  {
    uuid: "dae13fae-9a17-4b1e-bb78-d5f0fbafdd90",
    iconSet: "Ionicons",
    iconName: "restaurant-outline"
  },
  {
    uuid: "b0e75714-e203-4526-b7f9-ad31f180a90a",
    iconSet: "Ionicons",
    iconName: "restaurant-sharp"
  },
  {
    uuid: "ff768330-14f6-4a4a-9294-203ab1c9fc15",
    iconSet: "Ionicons",
    iconName: "return-down-back"
  },
  {
    uuid: "2b0c2620-f06c-4ffa-ad64-3f027e08283b",
    iconSet: "Ionicons",
    iconName: "return-down-back-outline"
  },
  {
    uuid: "8329dcd5-11d8-4365-a1bf-5a3ff93589f2",
    iconSet: "Ionicons",
    iconName: "return-down-back-sharp"
  },
  {
    uuid: "3b3c2cc0-a666-4310-ad53-a3d4ded9cd8e",
    iconSet: "Ionicons",
    iconName: "return-down-forward"
  },
  {
    uuid: "1207ec46-3f3e-4425-a83e-442edef1577a",
    iconSet: "Ionicons",
    iconName: "return-down-forward-outline"
  },
  {
    uuid: "d5b302ae-e059-4767-a34c-a686ad714437",
    iconSet: "Ionicons",
    iconName: "return-down-forward-sharp"
  },
  {
    uuid: "6138df4f-dee2-42dc-b232-7ef3cee7e6d8",
    iconSet: "Ionicons",
    iconName: "return-up-back"
  },
  {
    uuid: "20ab8387-4488-4bfe-9ad6-c66ea14e3338",
    iconSet: "Ionicons",
    iconName: "return-up-back-outline"
  },
  {
    uuid: "ada159e7-345d-44e9-821d-ad90c6e8a6ce",
    iconSet: "Ionicons",
    iconName: "return-up-back-sharp"
  },
  {
    uuid: "a374528c-5d09-47f4-8b1c-793045c575bc",
    iconSet: "Ionicons",
    iconName: "return-up-forward"
  },
  {
    uuid: "d174b417-e700-43f3-b290-9ee46f299dce",
    iconSet: "Ionicons",
    iconName: "return-up-forward-outline"
  },
  {
    uuid: "5ec351e6-ef0e-4f0f-b5b3-1268eb24bb7a",
    iconSet: "Ionicons",
    iconName: "return-up-forward-sharp"
  },
  {
    uuid: "4e6a0d9c-f9ec-45b6-ab0d-633037aec5ef",
    iconSet: "Ionicons",
    iconName: "ribbon"
  },
  {
    uuid: "482b83d0-1ea4-4888-9f75-97dfabccfd1b",
    iconSet: "Ionicons",
    iconName: "ribbon-outline"
  },
  {
    uuid: "6870401e-1022-42f4-a594-630a2e67021c",
    iconSet: "Ionicons",
    iconName: "ribbon-sharp"
  },
  {
    uuid: "49a0b4c8-93bc-4def-9f1d-5925ebb3a399",
    iconSet: "Ionicons",
    iconName: "rocket"
  },
  {
    uuid: "48bba042-103c-4cfb-905c-0c93e3436ed9",
    iconSet: "Ionicons",
    iconName: "rocket-outline"
  },
  {
    uuid: "381660e0-ccd8-4629-b086-e445a13d6e35",
    iconSet: "Ionicons",
    iconName: "rocket-sharp"
  },
  {
    uuid: "c640d228-a7f4-4fef-8fe5-9554414a9cf5",
    iconSet: "Ionicons",
    iconName: "rose"
  },
  {
    uuid: "09e22110-208a-45d1-a58f-4bb6bc586786",
    iconSet: "Ionicons",
    iconName: "rose-outline"
  },
  {
    uuid: "b6dc6ef6-9081-4200-939f-ddff367abf17",
    iconSet: "Ionicons",
    iconName: "rose-sharp"
  },
  {
    uuid: "83812851-5af7-4b99-8cdd-4a8e2f449057",
    iconSet: "Ionicons",
    iconName: "sad"
  },
  {
    uuid: "f4c01b74-0589-4dd0-9965-c4245e3535ec",
    iconSet: "Ionicons",
    iconName: "sad-outline"
  },
  {
    uuid: "3ec9a57c-d828-4c36-9550-bd75f52c38bd",
    iconSet: "Ionicons",
    iconName: "sad-sharp"
  },
  {
    uuid: "c3aa5e74-13c6-4665-afc6-d26204bfaf0e",
    iconSet: "Ionicons",
    iconName: "save"
  },
  {
    uuid: "b621944d-8d17-4151-afa1-afca81f52344",
    iconSet: "Ionicons",
    iconName: "save-outline"
  },
  {
    uuid: "3b59bbc4-1da0-46c6-baa5-1c794e771fc7",
    iconSet: "Ionicons",
    iconName: "save-sharp"
  },
  {
    uuid: "f41a5d22-f526-405b-992f-b4250c572af3",
    iconSet: "Ionicons",
    iconName: "scan"
  },
  {
    uuid: "53cd8c78-0680-47e7-8494-f67e68fa6ead",
    iconSet: "Ionicons",
    iconName: "scan-circle"
  },
  {
    uuid: "06171c87-970a-45c4-b3e0-1c96fb9d6158",
    iconSet: "Ionicons",
    iconName: "scan-circle-outline"
  },
  {
    uuid: "579da2dd-7dd8-4b16-b25e-f4b6d3787e0d",
    iconSet: "Ionicons",
    iconName: "scan-circle-sharp"
  },
  {
    uuid: "80f2d9e1-f1e1-47c9-8a0e-d03d844aa26a",
    iconSet: "Ionicons",
    iconName: "scan-outline"
  },
  {
    uuid: "765d8666-cb89-44be-b81e-ba38c5850b55",
    iconSet: "Ionicons",
    iconName: "scan-sharp"
  },
  {
    uuid: "06739d3c-8219-41db-8c9d-dd6ed55c3c2d",
    iconSet: "Ionicons",
    iconName: "school"
  },
  {
    uuid: "ddb40b70-245e-48a8-8b50-3cef50690e0e",
    iconSet: "Ionicons",
    iconName: "school-outline"
  },
  {
    uuid: "cbf0a4a4-2d1c-4f68-99fb-7fff3747883c",
    iconSet: "Ionicons",
    iconName: "school-sharp"
  },
  {
    uuid: "7e02f95a-5500-4a5a-a1a4-f4dcecdd3174",
    iconSet: "Ionicons",
    iconName: "search"
  },
  {
    uuid: "76df4d87-fff3-4131-af57-62a0e00c3219",
    iconSet: "Ionicons",
    iconName: "search-circle"
  },
  {
    uuid: "63ac1581-7736-4131-82d9-efd07ebd509a",
    iconSet: "Ionicons",
    iconName: "search-circle-outline"
  },
  {
    uuid: "0a3eb13a-9409-49e9-9a82-08943831ee5c",
    iconSet: "Ionicons",
    iconName: "search-circle-sharp"
  },
  {
    uuid: "a0177657-cfe5-42ce-8f6e-d17119694df5",
    iconSet: "Ionicons",
    iconName: "search-outline"
  },
  {
    uuid: "72a55db3-f936-4440-9812-14a208d1dc4e",
    iconSet: "Ionicons",
    iconName: "search-sharp"
  },
  {
    uuid: "d2f67f87-53de-4148-bf23-c5014747129f",
    iconSet: "Ionicons",
    iconName: "send"
  },
  {
    uuid: "cebd8974-4aac-4d5b-9160-a390f4129c54",
    iconSet: "Ionicons",
    iconName: "send-outline"
  },
  {
    uuid: "9d1ab2d8-c75c-4daa-b720-825809304dc7",
    iconSet: "Ionicons",
    iconName: "send-sharp"
  },
  {
    uuid: "adfde97e-1e88-4779-b4bf-bfb0f481cc1a",
    iconSet: "Ionicons",
    iconName: "server"
  },
  {
    uuid: "e5dda825-ccc1-478c-8ac6-3353b45e4116",
    iconSet: "Ionicons",
    iconName: "server-outline"
  },
  {
    uuid: "4dc6109c-59d0-4a15-a4de-ea8947b20ee8",
    iconSet: "Ionicons",
    iconName: "server-sharp"
  },
  {
    uuid: "70a52cc4-4a19-4caf-8d54-8a6175c60a62",
    iconSet: "Ionicons",
    iconName: "settings"
  },
  {
    uuid: "fd380052-6f38-4301-b445-345a85530f1a",
    iconSet: "Ionicons",
    iconName: "settings-outline"
  },
  {
    uuid: "d0916900-e2c4-405a-ad23-713087bc2ef0",
    iconSet: "Ionicons",
    iconName: "settings-sharp"
  },
  {
    uuid: "a84d8cbc-bf81-49e4-977a-9d3a5cbe5d75",
    iconSet: "Ionicons",
    iconName: "shapes"
  },
  {
    uuid: "1ebf9cb8-6460-4e3d-a9b6-8a87b3a69e0e",
    iconSet: "Ionicons",
    iconName: "shapes-outline"
  },
  {
    uuid: "c3c41ec8-3462-4a30-8284-0f3309f27e76",
    iconSet: "Ionicons",
    iconName: "shapes-sharp"
  },
  {
    uuid: "c7db4a47-b89d-4760-b31f-cbb1c804e866",
    iconSet: "Ionicons",
    iconName: "share"
  },
  {
    uuid: "0f676b6c-e7da-4bc1-af5e-85f7fea47512",
    iconSet: "Ionicons",
    iconName: "share-outline"
  },
  {
    uuid: "3e4aaf48-4e75-4fac-84bb-ecdf42a93101",
    iconSet: "Ionicons",
    iconName: "share-sharp"
  },
  {
    uuid: "29f7dc44-3703-482b-833e-2136258274b2",
    iconSet: "Ionicons",
    iconName: "share-social"
  },
  {
    uuid: "bd7c8035-7b0b-48a0-abf9-a135d8a8cdf1",
    iconSet: "Ionicons",
    iconName: "share-social-outline"
  },
  {
    uuid: "285f7e81-1afa-4bad-af48-f776b349681d",
    iconSet: "Ionicons",
    iconName: "share-social-sharp"
  },
  {
    uuid: "69726467-2d64-44ba-9b68-5b35eb03fac7",
    iconSet: "Ionicons",
    iconName: "shield"
  },
  {
    uuid: "522d851f-3f35-4d91-a341-dd9fa950fa19",
    iconSet: "Ionicons",
    iconName: "shield-checkmark"
  },
  {
    uuid: "99b7df89-c258-41b5-bc46-8a5dc8cca082",
    iconSet: "Ionicons",
    iconName: "shield-checkmark-outline"
  },
  {
    uuid: "af1fc688-07ec-42d9-984d-d75d326e18fe",
    iconSet: "Ionicons",
    iconName: "shield-checkmark-sharp"
  },
  {
    uuid: "97838fa3-bde3-4b27-ac7d-e0c53fdd7e74",
    iconSet: "Ionicons",
    iconName: "shield-outline"
  },
  {
    uuid: "9c9fb263-bb77-4687-b6f4-0b1695c08f6c",
    iconSet: "Ionicons",
    iconName: "shield-sharp"
  },
  {
    uuid: "8fb4e727-1727-430f-a911-2f54f6dad4dd",
    iconSet: "Ionicons",
    iconName: "shirt"
  },
  {
    uuid: "191295f6-080b-4ea6-b40b-e2cd9b323b29",
    iconSet: "Ionicons",
    iconName: "shirt-outline"
  },
  {
    uuid: "80e1913c-9029-405e-8664-3e2ee86d9138",
    iconSet: "Ionicons",
    iconName: "shirt-sharp"
  },
  {
    uuid: "00380672-b6f1-41a9-87ad-8d9b4d87363e",
    iconSet: "Ionicons",
    iconName: "shuffle"
  },
  {
    uuid: "2d0da54d-9f95-4d9e-96de-27f0565e950a",
    iconSet: "Ionicons",
    iconName: "shuffle-outline"
  },
  {
    uuid: "13255765-5474-461a-8a5b-58725188876f",
    iconSet: "Ionicons",
    iconName: "shuffle-sharp"
  },
  {
    uuid: "120aa127-cb9f-41f1-b4b1-cca7266017c4",
    iconSet: "Ionicons",
    iconName: "skull"
  },
  {
    uuid: "442b2f44-b021-49c8-b94d-b13f7c47ba81",
    iconSet: "Ionicons",
    iconName: "skull-outline"
  },
  {
    uuid: "72c667ae-d3c4-4598-8680-d69b869e32b5",
    iconSet: "Ionicons",
    iconName: "skull-sharp"
  },
  {
    uuid: "c1b47cf4-9bf2-4ac4-a335-6607f215c730",
    iconSet: "Ionicons",
    iconName: "snow"
  },
  {
    uuid: "76818f67-eeaf-46ae-9c14-a892242b451e",
    iconSet: "Ionicons",
    iconName: "snow-outline"
  },
  {
    uuid: "dbb36300-698c-4187-a1c4-bfec0be2a395",
    iconSet: "Ionicons",
    iconName: "snow-sharp"
  },
  {
    uuid: "358d3ed6-b188-4182-b99d-7371ddeeb443",
    iconSet: "Ionicons",
    iconName: "speedometer"
  },
  {
    uuid: "a3500979-a8fd-4015-8ec4-91ede2300dab",
    iconSet: "Ionicons",
    iconName: "speedometer-outline"
  },
  {
    uuid: "75907241-38e1-47fe-af39-57f4018ac57e",
    iconSet: "Ionicons",
    iconName: "speedometer-sharp"
  },
  {
    uuid: "3ac98c4b-564d-493a-a98a-3d1e80c8962f",
    iconSet: "Ionicons",
    iconName: "square"
  },
  {
    uuid: "73bfa888-daf3-44c2-aa2f-a0d24349e981",
    iconSet: "Ionicons",
    iconName: "square-outline"
  },
  {
    uuid: "0e9376cf-05e2-4b45-9bd5-f6bcb5fd49ee",
    iconSet: "Ionicons",
    iconName: "square-sharp"
  },
  {
    uuid: "5930950c-f325-4b3d-8328-46a97a5976b8",
    iconSet: "Ionicons",
    iconName: "star"
  },
  {
    uuid: "d479e146-b848-4f77-97b2-b105da25b69d",
    iconSet: "Ionicons",
    iconName: "star-half"
  },
  {
    uuid: "45579450-963d-4066-a580-688d5596bef6",
    iconSet: "Ionicons",
    iconName: "star-half-outline"
  },
  {
    uuid: "208e1475-8e89-4b2c-bc62-0b44a3499cdb",
    iconSet: "Ionicons",
    iconName: "star-half-sharp"
  },
  {
    uuid: "08eb7d0f-5a3e-4daf-9239-b06d8ccc9497",
    iconSet: "Ionicons",
    iconName: "star-outline"
  },
  {
    uuid: "e87a72b8-5750-429d-9d56-a47916f65245",
    iconSet: "Ionicons",
    iconName: "star-sharp"
  },
  {
    uuid: "dcadcace-6c2c-489a-88b0-58059ef0c659",
    iconSet: "Ionicons",
    iconName: "stats-chart"
  },
  {
    uuid: "eb198cae-4455-4ee2-af46-711895c0e845",
    iconSet: "Ionicons",
    iconName: "stats-chart-outline"
  },
  {
    uuid: "bccc028d-f0a9-41a8-a426-4c3b75d631d9",
    iconSet: "Ionicons",
    iconName: "stats-chart-sharp"
  },
  {
    uuid: "db23d740-3cfd-455c-94f5-3e247361fad1",
    iconSet: "Ionicons",
    iconName: "stop"
  },
  {
    uuid: "9ca39ccf-9cd7-4017-b6dc-41d1108b7b7b",
    iconSet: "Ionicons",
    iconName: "stop-circle"
  },
  {
    uuid: "b3fd7c34-b54c-4006-8cec-a2da483ffe8c",
    iconSet: "Ionicons",
    iconName: "stop-circle-outline"
  },
  {
    uuid: "78077c70-0fa7-431d-a79a-fedd80a27ea1",
    iconSet: "Ionicons",
    iconName: "stop-circle-sharp"
  },
  {
    uuid: "d3bcbced-30ae-45b5-a42b-f39ad2ad1064",
    iconSet: "Ionicons",
    iconName: "stop-outline"
  },
  {
    uuid: "328a659c-81ed-4e3d-bf23-fb5f631eacf3",
    iconSet: "Ionicons",
    iconName: "stop-sharp"
  },
  {
    uuid: "857c7f6d-ba95-44ed-a271-7fef7dd317fd",
    iconSet: "Ionicons",
    iconName: "stopwatch"
  },
  {
    uuid: "c3514034-4ec2-4d5d-a7be-d2943926411d",
    iconSet: "Ionicons",
    iconName: "stopwatch-outline"
  },
  {
    uuid: "6a46b681-09b0-4a15-81a3-142b8563dae0",
    iconSet: "Ionicons",
    iconName: "stopwatch-sharp"
  },
  {
    uuid: "c1a96d0a-700a-4670-b99d-aae055b533c2",
    iconSet: "Ionicons",
    iconName: "subway"
  },
  {
    uuid: "c5c964c6-8f05-4c27-9801-c773381cb6d3",
    iconSet: "Ionicons",
    iconName: "subway-outline"
  },
  {
    uuid: "90bbd947-bf4c-421e-870d-4213237dc57b",
    iconSet: "Ionicons",
    iconName: "subway-sharp"
  },
  {
    uuid: "82ef4dfd-06eb-4751-a75e-74740942fe0b",
    iconSet: "Ionicons",
    iconName: "sunny"
  },
  {
    uuid: "4a88987e-4140-43ad-b12b-ecd327667fb1",
    iconSet: "Ionicons",
    iconName: "sunny-outline"
  },
  {
    uuid: "de74794b-3266-4a09-9a8a-5055d2c83039",
    iconSet: "Ionicons",
    iconName: "sunny-sharp"
  },
  {
    uuid: "df2d4deb-e7b5-4ea3-8e0c-8c951d51b4ed",
    iconSet: "Ionicons",
    iconName: "swap-horizontal"
  },
  {
    uuid: "d39cb08d-5b6b-4f5d-ab15-736f49ab3f73",
    iconSet: "Ionicons",
    iconName: "swap-horizontal-outline"
  },
  {
    uuid: "01374645-1069-4b44-aac2-224b19af6f84",
    iconSet: "Ionicons",
    iconName: "swap-horizontal-sharp"
  },
  {
    uuid: "3d32cb03-2f64-4530-98f5-17dbc93c32bf",
    iconSet: "Ionicons",
    iconName: "swap-vertical"
  },
  {
    uuid: "e41971ce-f38f-4d9c-b865-e21dc931ac85",
    iconSet: "Ionicons",
    iconName: "swap-vertical-outline"
  },
  {
    uuid: "69805a66-9b22-46a0-998c-7226a2e847d7",
    iconSet: "Ionicons",
    iconName: "swap-vertical-sharp"
  },
  {
    uuid: "907d394b-5819-441b-bd03-6e41e5754f49",
    iconSet: "Ionicons",
    iconName: "sync"
  },
  {
    uuid: "83e383cb-b848-4f7a-89e8-2f8d7d267946",
    iconSet: "Ionicons",
    iconName: "sync-circle"
  },
  {
    uuid: "2a92213d-aacf-4e57-a989-6beeea2051fc",
    iconSet: "Ionicons",
    iconName: "sync-circle-outline"
  },
  {
    uuid: "c6ff1789-418d-45b0-8144-a40580584cd4",
    iconSet: "Ionicons",
    iconName: "sync-circle-sharp"
  },
  {
    uuid: "3e23cf47-a661-40f9-854a-80db39f9c1b5",
    iconSet: "Ionicons",
    iconName: "sync-outline"
  },
  {
    uuid: "d693fb04-80d5-4a76-b88a-7e1c556e083b",
    iconSet: "Ionicons",
    iconName: "sync-sharp"
  },
  {
    uuid: "336f88f9-77fc-48c7-9a16-6313dea0ba19",
    iconSet: "Ionicons",
    iconName: "tablet-landscape"
  },
  {
    uuid: "8f85f6f9-794f-4e27-9c5f-f39fb6585786",
    iconSet: "Ionicons",
    iconName: "tablet-landscape-outline"
  },
  {
    uuid: "cf426909-396e-4721-b38f-6e8bedd44b4c",
    iconSet: "Ionicons",
    iconName: "tablet-landscape-sharp"
  },
  {
    uuid: "1db266bb-c849-4105-9560-a57e485fdb73",
    iconSet: "Ionicons",
    iconName: "tablet-portrait"
  },
  {
    uuid: "8d8d0f68-805e-4a9a-ab27-c4f98ac78828",
    iconSet: "Ionicons",
    iconName: "tablet-portrait-outline"
  },
  {
    uuid: "435930f7-27f5-4a7c-9d3b-2970219d27c7",
    iconSet: "Ionicons",
    iconName: "tablet-portrait-sharp"
  },
  {
    uuid: "2ea4f6fd-31e1-4b43-b01f-4ae1be3f0c07",
    iconSet: "Ionicons",
    iconName: "tennisball"
  },
  {
    uuid: "5dafee54-b94a-4ca0-9bf2-9921c4b3bfaa",
    iconSet: "Ionicons",
    iconName: "tennisball-outline"
  },
  {
    uuid: "b0e84e28-403c-4d7d-97ba-bdfa3b08a55f",
    iconSet: "Ionicons",
    iconName: "tennisball-sharp"
  },
  {
    uuid: "9195d61f-192e-4368-bbd2-6521758c8fde",
    iconSet: "Ionicons",
    iconName: "terminal"
  },
  {
    uuid: "68f056e0-ebb3-48e2-8678-8a75d2829864",
    iconSet: "Ionicons",
    iconName: "terminal-outline"
  },
  {
    uuid: "42413b3f-bff6-4ce7-a7be-8493e2a67e43",
    iconSet: "Ionicons",
    iconName: "terminal-sharp"
  },
  {
    uuid: "98ef907f-8c0c-4bde-8d5a-79af707c2e2c",
    iconSet: "Ionicons",
    iconName: "text"
  },
  {
    uuid: "924a8c2b-9113-48c6-ae3c-05f0ec7b8579",
    iconSet: "Ionicons",
    iconName: "text-outline"
  },
  {
    uuid: "db117e2a-9543-4b12-aec9-5ed921b25340",
    iconSet: "Ionicons",
    iconName: "text-sharp"
  },
  {
    uuid: "08c3ae2d-987e-45fc-9ac8-4d87c4a98116",
    iconSet: "Ionicons",
    iconName: "thermometer"
  },
  {
    uuid: "1fd1fa36-8d96-4ce2-ad09-7b3cf87ff9c4",
    iconSet: "Ionicons",
    iconName: "thermometer-outline"
  },
  {
    uuid: "5e67b50e-8f2a-4d15-a1fc-d298ed948af4",
    iconSet: "Ionicons",
    iconName: "thermometer-sharp"
  },
  {
    uuid: "67092870-1141-41dc-b3b4-20ab15161a83",
    iconSet: "Ionicons",
    iconName: "thumbs-down"
  },
  {
    uuid: "97f93886-8bdd-4a65-833f-3372a7c027ff",
    iconSet: "Ionicons",
    iconName: "thumbs-down-outline"
  },
  {
    uuid: "f9027039-2171-441d-8c6f-9fd220ac3bc3",
    iconSet: "Ionicons",
    iconName: "thumbs-down-sharp"
  },
  {
    uuid: "dd842456-df00-4c45-beb1-093bbc2bb657",
    iconSet: "Ionicons",
    iconName: "thumbs-up"
  },
  {
    uuid: "d185dd88-7bb9-499d-a24f-47b21940407e",
    iconSet: "Ionicons",
    iconName: "thumbs-up-outline"
  },
  {
    uuid: "d4a0aecb-da7c-421f-bd13-95a4653b932c",
    iconSet: "Ionicons",
    iconName: "thumbs-up-sharp"
  },
  {
    uuid: "6f4a02eb-a245-43ac-a3d7-0ad14ed34c7f",
    iconSet: "Ionicons",
    iconName: "thunderstorm"
  },
  {
    uuid: "5c3bd46f-bf7e-4936-8e10-4461555fa8d8",
    iconSet: "Ionicons",
    iconName: "thunderstorm-outline"
  },
  {
    uuid: "d9c4aca2-0688-4037-b3db-20e482b472f9",
    iconSet: "Ionicons",
    iconName: "thunderstorm-sharp"
  },
  {
    uuid: "0f5db6d8-caed-40f4-8693-1108f5b2ccfa",
    iconSet: "Ionicons",
    iconName: "time"
  },
  {
    uuid: "ca30209e-8636-4029-847b-9d9953241219",
    iconSet: "Ionicons",
    iconName: "time-outline"
  },
  {
    uuid: "dd7e8ea8-3388-428b-a6af-bd9a5a79857a",
    iconSet: "Ionicons",
    iconName: "time-sharp"
  },
  {
    uuid: "83a51a21-facd-40c1-aa86-bb204376817a",
    iconSet: "Ionicons",
    iconName: "timer"
  },
  {
    uuid: "6f3019b5-d385-4838-82ae-7a845861c1e8",
    iconSet: "Ionicons",
    iconName: "timer-outline"
  },
  {
    uuid: "2f458bbd-3209-4191-a433-87171eb990bb",
    iconSet: "Ionicons",
    iconName: "timer-sharp"
  },
  {
    uuid: "a1aae5d6-da2a-4ff7-8a61-4d29270cdf72",
    iconSet: "Ionicons",
    iconName: "today"
  },
  {
    uuid: "0162fc18-0d0e-43ad-ad3e-aba1b5add2f1",
    iconSet: "Ionicons",
    iconName: "today-outline"
  },
  {
    uuid: "b4f80147-25a1-467e-a44c-da5a087d99ca",
    iconSet: "Ionicons",
    iconName: "today-sharp"
  },
  {
    uuid: "d74ec68e-300b-41d2-8c11-b009a8355cda",
    iconSet: "Ionicons",
    iconName: "toggle"
  },
  {
    uuid: "db3b1d5e-23e7-441d-9e1f-29f1e6d4624d",
    iconSet: "Ionicons",
    iconName: "toggle-outline"
  },
  {
    uuid: "8917e49b-80c1-4f2b-b497-985d2ff4d9f7",
    iconSet: "Ionicons",
    iconName: "toggle-sharp"
  },
  {
    uuid: "7aa08218-2029-4c72-a5db-34e14d06479a",
    iconSet: "Ionicons",
    iconName: "trail-sign"
  },
  {
    uuid: "6f24da77-e076-4506-9c65-cb7e3548cd5b",
    iconSet: "Ionicons",
    iconName: "trail-sign-outline"
  },
  {
    uuid: "7aca9ddf-62c3-46f7-ad01-8d5baa925d74",
    iconSet: "Ionicons",
    iconName: "trail-sign-sharp"
  },
  {
    uuid: "130e0c8f-f9e1-4877-bd92-5406622a7aa4",
    iconSet: "Ionicons",
    iconName: "train"
  },
  {
    uuid: "37af9eec-5155-4579-8a9b-57fb8a1e4d5f",
    iconSet: "Ionicons",
    iconName: "train-outline"
  },
  {
    uuid: "c6a4cce2-be67-46f4-90c3-85c0243e192b",
    iconSet: "Ionicons",
    iconName: "train-sharp"
  },
  {
    uuid: "316cbca4-0f53-4978-b220-ad2f49e10c3a",
    iconSet: "Ionicons",
    iconName: "transgender"
  },
  {
    uuid: "eec9f719-1de5-46e4-8ab4-b9f1045defe5",
    iconSet: "Ionicons",
    iconName: "transgender-outline"
  },
  {
    uuid: "d86eeb05-682a-4c08-8b93-eb0ffd597c31",
    iconSet: "Ionicons",
    iconName: "transgender-sharp"
  },
  {
    uuid: "8bce2409-e937-49a8-a246-b33a1d75354f",
    iconSet: "Ionicons",
    iconName: "trash"
  },
  {
    uuid: "0a2e5611-2c80-430c-a717-5f546dd59088",
    iconSet: "Ionicons",
    iconName: "trash-bin"
  },
  {
    uuid: "7c61f51a-de6e-47c9-9189-91fc18b9751e",
    iconSet: "Ionicons",
    iconName: "trash-bin-outline"
  },
  {
    uuid: "bdd5d677-b544-430b-9837-6022210b506b",
    iconSet: "Ionicons",
    iconName: "trash-bin-sharp"
  },
  {
    uuid: "fd265a46-7266-47d3-bc34-47b62b8ae2eb",
    iconSet: "Ionicons",
    iconName: "trash-outline"
  },
  {
    uuid: "6697cb5f-07a1-4cb8-a303-e802047bc245",
    iconSet: "Ionicons",
    iconName: "trash-sharp"
  },
  {
    uuid: "af0b16f8-908a-442e-bf0e-b8697406ba0f",
    iconSet: "Ionicons",
    iconName: "trending-down"
  },
  {
    uuid: "220ce2ab-c467-4d30-b13f-364354131f59",
    iconSet: "Ionicons",
    iconName: "trending-down-outline"
  },
  {
    uuid: "cce553d2-110f-468d-b2dd-e289a48c1f50",
    iconSet: "Ionicons",
    iconName: "trending-down-sharp"
  },
  {
    uuid: "c2898727-7694-4133-877a-db6c7bb007cf",
    iconSet: "Ionicons",
    iconName: "trending-up"
  },
  {
    uuid: "41185298-b741-46be-af4a-7add3aceeaa2",
    iconSet: "Ionicons",
    iconName: "trending-up-outline"
  },
  {
    uuid: "c2aba973-66e2-44cd-90c3-a047096a3f58",
    iconSet: "Ionicons",
    iconName: "trending-up-sharp"
  },
  {
    uuid: "7ec2a9ed-f255-44ee-8689-9da346f0dbab",
    iconSet: "Ionicons",
    iconName: "triangle"
  },
  {
    uuid: "1abe214b-58a0-4b2e-8d91-d7f659bed2db",
    iconSet: "Ionicons",
    iconName: "triangle-outline"
  },
  {
    uuid: "2ffab810-f3c8-4380-b65c-9ce17d710ab9",
    iconSet: "Ionicons",
    iconName: "triangle-sharp"
  },
  {
    uuid: "3b567f8d-8776-4cea-ab5a-52c835f652ef",
    iconSet: "Ionicons",
    iconName: "trophy"
  },
  {
    uuid: "303f8721-ceaf-4de1-a242-5820ec1e2cc3",
    iconSet: "Ionicons",
    iconName: "trophy-outline"
  },
  {
    uuid: "a4f13d12-9f79-4bee-908a-c626facf387b",
    iconSet: "Ionicons",
    iconName: "trophy-sharp"
  },
  {
    uuid: "8e2fda3b-4290-4789-aa1e-ca81c74f10d6",
    iconSet: "Ionicons",
    iconName: "tv"
  },
  {
    uuid: "9df391ba-ca01-4982-8af2-aa4e83867e94",
    iconSet: "Ionicons",
    iconName: "tv-outline"
  },
  {
    uuid: "4bdc7625-7b4b-4e9a-b8e3-418dc617c0fe",
    iconSet: "Ionicons",
    iconName: "tv-sharp"
  },
  {
    uuid: "a211aade-cdaa-4c28-857a-7190ff50809b",
    iconSet: "Ionicons",
    iconName: "umbrella"
  },
  {
    uuid: "360c6e2e-031f-452b-a0ce-ede65192f2d5",
    iconSet: "Ionicons",
    iconName: "umbrella-outline"
  },
  {
    uuid: "f2128af4-f442-4eb9-b286-a9125cc75e5d",
    iconSet: "Ionicons",
    iconName: "umbrella-sharp"
  },
  {
    uuid: "7480707c-039e-4d36-aef3-635bc31deb86",
    iconSet: "Ionicons",
    iconName: "videocam"
  },
  {
    uuid: "d860c91e-0808-42b0-b763-41e7c5085f3a",
    iconSet: "Ionicons",
    iconName: "videocam-outline"
  },
  {
    uuid: "13aa8cb1-a573-4940-aa38-769250d19822",
    iconSet: "Ionicons",
    iconName: "videocam-sharp"
  },
  {
    uuid: "028bd21c-912a-42d9-b9a6-3926e31e15e9",
    iconSet: "Ionicons",
    iconName: "volume-high"
  },
  {
    uuid: "125d0d44-8c2f-4a3d-aedf-34f8fb9fd81e",
    iconSet: "Ionicons",
    iconName: "volume-high-outline"
  },
  {
    uuid: "7fd1c5da-3833-4ed6-b5ad-13760f113611",
    iconSet: "Ionicons",
    iconName: "volume-high-sharp"
  },
  {
    uuid: "19de44dd-ed8a-4e75-a861-74a920ac28d0",
    iconSet: "Ionicons",
    iconName: "volume-low"
  },
  {
    uuid: "ae568a14-f186-46de-9f81-89b5fc078971",
    iconSet: "Ionicons",
    iconName: "volume-low-outline"
  },
  {
    uuid: "56d6b285-815a-470b-8d49-c3d79de695f2",
    iconSet: "Ionicons",
    iconName: "volume-low-sharp"
  },
  {
    uuid: "cff20f7d-d945-4d95-864f-ec6ff3139f2d",
    iconSet: "Ionicons",
    iconName: "volume-medium"
  },
  {
    uuid: "2fbf3bf4-558f-40be-9b38-1d104ed9a36c",
    iconSet: "Ionicons",
    iconName: "volume-medium-outline"
  },
  {
    uuid: "d0c5e496-3dff-4109-8001-0ec1b40bf2d6",
    iconSet: "Ionicons",
    iconName: "volume-medium-sharp"
  },
  {
    uuid: "b173dc1a-28f9-42c3-8e1f-58216618fb35",
    iconSet: "Ionicons",
    iconName: "volume-mute"
  },
  {
    uuid: "e2017bfa-3214-4878-84ad-0e392e3b9063",
    iconSet: "Ionicons",
    iconName: "volume-mute-outline"
  },
  {
    uuid: "58758d54-eafb-4f7a-81b6-d17ae5645774",
    iconSet: "Ionicons",
    iconName: "volume-mute-sharp"
  },
  {
    uuid: "8f9c4930-9271-4ebd-847b-718f203aae97",
    iconSet: "Ionicons",
    iconName: "volume-off"
  },
  {
    uuid: "1707cae8-a1d9-42d1-9c4e-9ea6e2fa5ab4",
    iconSet: "Ionicons",
    iconName: "volume-off-outline"
  },
  {
    uuid: "8bb71c5a-3320-4884-b5bf-1e52b1994fcb",
    iconSet: "Ionicons",
    iconName: "volume-off-sharp"
  },
  {
    uuid: "ff4000c9-b998-44dd-89c6-a7dcd738cf3a",
    iconSet: "Ionicons",
    iconName: "walk"
  },
  {
    uuid: "788fc40d-7cf6-4c4b-8c1a-0b84ebab4997",
    iconSet: "Ionicons",
    iconName: "walk-outline"
  },
  {
    uuid: "2d53e180-9c3e-44f9-ab1a-9c38753f849c",
    iconSet: "Ionicons",
    iconName: "walk-sharp"
  },
  {
    uuid: "8aef0f67-bcdc-4378-ba9d-6ad6b8e49562",
    iconSet: "Ionicons",
    iconName: "wallet"
  },
  {
    uuid: "0ff6034e-d20d-4e93-b053-6217b8f5ece3",
    iconSet: "Ionicons",
    iconName: "wallet-outline"
  },
  {
    uuid: "6070e9bc-0a47-4bfc-9678-50a04451d892",
    iconSet: "Ionicons",
    iconName: "wallet-sharp"
  },
  {
    uuid: "64d73f40-1c95-44a9-acd0-3ebd756a656e",
    iconSet: "Ionicons",
    iconName: "warning"
  },
  {
    uuid: "16d2d5aa-b8ab-439e-81a7-0e11d3388ca5",
    iconSet: "Ionicons",
    iconName: "warning-outline"
  },
  {
    uuid: "676fea01-a515-44b4-9623-9cca6cb2d33a",
    iconSet: "Ionicons",
    iconName: "warning-sharp"
  },
  {
    uuid: "8e0576f0-2e91-4f63-b5c8-8c40bd924834",
    iconSet: "Ionicons",
    iconName: "watch"
  },
  {
    uuid: "8d77f41b-8e9c-4449-9c88-1f2fafb46b72",
    iconSet: "Ionicons",
    iconName: "watch-outline"
  },
  {
    uuid: "7815c7ca-b787-4ccd-ac97-b9ed473bfe0b",
    iconSet: "Ionicons",
    iconName: "watch-sharp"
  },
  {
    uuid: "83c3c06f-6845-4e1f-b628-c587ad593e20",
    iconSet: "Ionicons",
    iconName: "water"
  },
  {
    uuid: "f4901322-8023-4b52-9209-24492322a7f0",
    iconSet: "Ionicons",
    iconName: "water-outline"
  },
  {
    uuid: "952ceba9-b637-4aad-811a-0bb33c5232cc",
    iconSet: "Ionicons",
    iconName: "water-sharp"
  },
  {
    uuid: "bce6e24a-93fc-475f-9fdf-d808b24f7f34",
    iconSet: "Ionicons",
    iconName: "wifi"
  },
  {
    uuid: "38ab6cef-0f9e-427a-97b3-9507baf21064",
    iconSet: "Ionicons",
    iconName: "wifi-outline"
  },
  {
    uuid: "ae262bac-aab7-4a3f-8485-dbeae3557849",
    iconSet: "Ionicons",
    iconName: "wifi-sharp"
  },
  {
    uuid: "49141eb4-ddf8-4ab4-a619-964628d1bb5f",
    iconSet: "Ionicons",
    iconName: "wine"
  },
  {
    uuid: "2bb8c199-1a88-4676-a78f-e02fc3544576",
    iconSet: "Ionicons",
    iconName: "wine-outline"
  },
  {
    uuid: "d3554bb0-b239-416e-ae06-2d6c564084bb",
    iconSet: "Ionicons",
    iconName: "wine-sharp"
  },
  {
    uuid: "7d8c5cd9-92db-47fc-bac5-5e7d7d44e996",
    iconSet: "Ionicons",
    iconName: "woman"
  },
  {
    uuid: "cd20c28f-69fb-4061-9016-9f523d38a3a2",
    iconSet: "Ionicons",
    iconName: "woman-outline"
  },
  {
    uuid: "f03bbef3-b2ee-4162-b62f-971b3fb93c75",
    iconSet: "Ionicons",
    iconName: "woman-sharp"
  },
  {
    uuid: "5869e828-5440-4850-b272-c93cd015e183",
    iconSet: "Ionicons",
    iconName: "add"
  },
  {
    uuid: "f92c0342-6cef-493e-9af4-e3ea27e2e389",
    iconSet: "Ionicons",
    iconName: "add-circle"
  },
  {
    uuid: "f9f65b7f-b29e-48ea-b7f8-e1ee35da49d3",
    iconSet: "Ionicons",
    iconName: "add-circle-outline"
  },
  {
    uuid: "51a34d2c-2dcd-4b8d-a2db-d1474d8deb8e",
    iconSet: "Ionicons",
    iconName: "add-circle-sharp"
  },
  {
    uuid: "96b546a0-2b88-406b-9059-77b4bfad7471",
    iconSet: "Ionicons",
    iconName: "add-outline"
  },
  {
    uuid: "5cf230f0-8821-4649-b5c8-facf0fdd5a01",
    iconSet: "Ionicons",
    iconName: "add-sharp"
  },
  {
    uuid: "6ed7ebd7-952b-4651-b705-df9c6e2e5254",
    iconSet: "Ionicons",
    iconName: "airplane"
  },
  {
    uuid: "5c824d3c-2037-4695-8c7a-95cfa96bbeab",
    iconSet: "Ionicons",
    iconName: "airplane-outline"
  },
  {
    uuid: "dc406428-111a-4406-8961-5071a8e364a4",
    iconSet: "Ionicons",
    iconName: "airplane-sharp"
  },
  {
    uuid: "e4ee4bc1-6fce-4c28-b0c6-60beb00ad396",
    iconSet: "Ionicons",
    iconName: "alarm"
  },
  {
    uuid: "12937bbc-2b92-4ad5-901f-3e548a2e12f1",
    iconSet: "Ionicons",
    iconName: "alarm-outline"
  },
  {
    uuid: "49a06997-6d59-4d70-abd8-76cca2882876",
    iconSet: "Ionicons",
    iconName: "alarm-sharp"
  },
  {
    uuid: "19ae9b64-32e5-4a49-9ad2-66d24405d963",
    iconSet: "Ionicons",
    iconName: "albums"
  },
  {
    uuid: "8a7eb543-e0f1-4ae1-916b-08591fac4ba1",
    iconSet: "Ionicons",
    iconName: "albums-outline"
  },
  {
    uuid: "105b8bb6-72f0-4cec-9677-6a3ef0c8bc67",
    iconSet: "Ionicons",
    iconName: "albums-sharp"
  },
  {
    uuid: "e0650c3f-9418-44fc-a4d4-9c6aa258e179",
    iconSet: "Ionicons",
    iconName: "alert"
  },
  {
    uuid: "2107313a-d113-4ce0-a2dd-13d12db873e4",
    iconSet: "Ionicons",
    iconName: "alert-circle"
  },
  {
    uuid: "524810d7-8d3e-4899-ace7-86b82443acc3",
    iconSet: "Ionicons",
    iconName: "alert-circle-outline"
  },
  {
    uuid: "de624d4a-249c-4085-9db2-ba47a2e82af1",
    iconSet: "Ionicons",
    iconName: "alert-circle-sharp"
  },
  {
    uuid: "05bf11c0-75eb-4198-be35-d325db4aff92",
    iconSet: "Ionicons",
    iconName: "alert-outline"
  },
  {
    uuid: "fccb9c97-2f08-43ca-af90-1c3653503fa6",
    iconSet: "Ionicons",
    iconName: "alert-sharp"
  },
  {
    uuid: "36089571-5754-4fdc-abd4-08a1243574d3",
    iconSet: "Ionicons",
    iconName: "american-football"
  },
  {
    uuid: "08a60a74-41ce-42fd-9b49-47925b8f2fc3",
    iconSet: "Ionicons",
    iconName: "american-football-outline"
  },
  {
    uuid: "d12590d1-96d4-4622-ba32-407b83642bc6",
    iconSet: "Ionicons",
    iconName: "american-football-sharp"
  },
  {
    uuid: "3b58329b-c46e-419a-a4ce-48aaa9948526",
    iconSet: "Ionicons",
    iconName: "analytics"
  },
  {
    uuid: "31a0e864-67aa-4e8a-a654-30d5d12fa445",
    iconSet: "Ionicons",
    iconName: "analytics-outline"
  },
  {
    uuid: "4918c1b6-10d2-4027-9d64-fc0f549da0ec",
    iconSet: "Ionicons",
    iconName: "analytics-sharp"
  },
  {
    uuid: "bca7a3eb-ff9b-4403-b7fc-f644f9d3e135",
    iconSet: "Ionicons",
    iconName: "aperture"
  },
  {
    uuid: "d0e308f2-816e-4236-b5f6-acd66707a3ed",
    iconSet: "Ionicons",
    iconName: "aperture-outline"
  },
  {
    uuid: "c897ec96-464f-4537-8986-6c88ff08d061",
    iconSet: "Ionicons",
    iconName: "aperture-sharp"
  },
  {
    uuid: "f3438087-d574-4053-83d2-f89926442113",
    iconSet: "Ionicons",
    iconName: "apps"
  },
  {
    uuid: "b7562700-1254-424c-a010-2d2005809180",
    iconSet: "Ionicons",
    iconName: "apps-outline"
  },
  {
    uuid: "dead5fec-bfd6-4909-b5bb-3ead602a8c49",
    iconSet: "Ionicons",
    iconName: "apps-sharp"
  },
  {
    uuid: "93b62044-78ab-41a8-97d1-18340c47a05d",
    iconSet: "Ionicons",
    iconName: "archive"
  },
  {
    uuid: "fe581e4d-5329-4e93-a08f-1cdb005cbfbc",
    iconSet: "Ionicons",
    iconName: "archive-outline"
  },
  {
    uuid: "2bd479bb-9d7c-4b75-8680-bbbff86987f0",
    iconSet: "Ionicons",
    iconName: "archive-sharp"
  },
  {
    uuid: "71723360-05e1-4ac3-a9c9-c3f3e257d073",
    iconSet: "Ionicons",
    iconName: "arrow-back"
  },
  {
    uuid: "06e77b18-f534-40a9-9a7d-a6e38b7d4f07",
    iconSet: "Ionicons",
    iconName: "arrow-back-circle"
  },
  {
    uuid: "31fa03b3-21c3-44c9-8974-51a98bb8a573",
    iconSet: "Ionicons",
    iconName: "arrow-back-circle-outline"
  },
  {
    uuid: "3403be46-78b3-4747-8317-7dc42a4470a7",
    iconSet: "Ionicons",
    iconName: "arrow-back-circle-sharp"
  },
  {
    uuid: "f3e757a3-4e57-4fbd-9c3c-975bee9fc461",
    iconSet: "Ionicons",
    iconName: "arrow-back-outline"
  },
  {
    uuid: "33497330-9514-47cc-9f97-0c5944f86dc5",
    iconSet: "Ionicons",
    iconName: "arrow-back-sharp"
  },
  {
    uuid: "ce91fb9f-d0a2-430a-8fe4-9338c7af1874",
    iconSet: "Ionicons",
    iconName: "arrow-down"
  },
  {
    uuid: "0105112f-7d1b-4a2e-b421-349818a4abcc",
    iconSet: "Ionicons",
    iconName: "arrow-down-circle"
  },
  {
    uuid: "3ae8e51f-cb52-4bae-ae53-4f5589106420",
    iconSet: "Ionicons",
    iconName: "arrow-down-circle-outline"
  },
  {
    uuid: "6ae14eb1-cbb5-460f-b954-dcffb5ecff38",
    iconSet: "Ionicons",
    iconName: "arrow-down-circle-sharp"
  },
  {
    uuid: "b973f7ff-c686-4d8b-8c44-ccd72f2c422b",
    iconSet: "Ionicons",
    iconName: "arrow-down-outline"
  },
  {
    uuid: "580db902-a780-435b-915c-28b7b8a615d8",
    iconSet: "Ionicons",
    iconName: "arrow-down-sharp"
  },
  {
    uuid: "902e45a4-00ff-4434-88cf-23adff3515c3",
    iconSet: "Ionicons",
    iconName: "arrow-forward"
  },
  {
    uuid: "596b14e5-7be2-43bb-8762-dac5938cb472",
    iconSet: "Ionicons",
    iconName: "arrow-forward-circle"
  },
  {
    uuid: "8b746149-5915-4e6c-a284-a33bb569290f",
    iconSet: "Ionicons",
    iconName: "arrow-forward-circle-outline"
  },
  {
    uuid: "93895532-8361-4815-87dd-a924dd78fff2",
    iconSet: "Ionicons",
    iconName: "arrow-forward-circle-sharp"
  },
  {
    uuid: "9338df59-ece1-413e-87f4-5587eae514e9",
    iconSet: "Ionicons",
    iconName: "arrow-forward-outline"
  },
  {
    uuid: "37aef280-62e6-406c-b8d2-a2a59d3fa972",
    iconSet: "Ionicons",
    iconName: "arrow-forward-sharp"
  },
  {
    uuid: "1fa58a3f-f613-4dca-9180-93765edb91a0",
    iconSet: "Ionicons",
    iconName: "arrow-redo"
  },
  {
    uuid: "33a7b8fb-be2f-4550-a8f8-717a903c475b",
    iconSet: "Ionicons",
    iconName: "arrow-redo-circle"
  },
  {
    uuid: "62f32f6e-03e8-4683-a473-6e98aabf87e4",
    iconSet: "Ionicons",
    iconName: "arrow-redo-circle-outline"
  },
  {
    uuid: "e3ffd187-8731-42f7-a95c-dbbd31e5151e",
    iconSet: "Ionicons",
    iconName: "arrow-redo-circle-sharp"
  },
  {
    uuid: "d344d5e9-2965-4dcd-b923-b88c24c4afda",
    iconSet: "Ionicons",
    iconName: "arrow-redo-outline"
  },
  {
    uuid: "85261fa3-4778-4c68-ab43-489418cf2806",
    iconSet: "Ionicons",
    iconName: "arrow-redo-sharp"
  },
  {
    uuid: "fddce683-318b-4659-ad8d-3c6b06339e1a",
    iconSet: "Ionicons",
    iconName: "arrow-undo"
  },
  {
    uuid: "64b50fa5-cf91-4667-b271-f812dea14f83",
    iconSet: "Ionicons",
    iconName: "arrow-undo-circle"
  },
  {
    uuid: "64f4bc1d-2d55-4c4d-a854-7f77c902c72a",
    iconSet: "Ionicons",
    iconName: "arrow-undo-circle-outline"
  },
  {
    uuid: "94e1aa3f-3262-4154-9b03-a3a1c7b5c3ba",
    iconSet: "Ionicons",
    iconName: "arrow-undo-circle-sharp"
  },
  {
    uuid: "39165862-b2d6-4fa7-9d75-ce1ef5bbdbd8",
    iconSet: "Ionicons",
    iconName: "arrow-undo-outline"
  },
  {
    uuid: "885ff7e1-5034-4b67-8734-bfbcb3251e97",
    iconSet: "Ionicons",
    iconName: "arrow-undo-sharp"
  },
  {
    uuid: "a9ac667a-cd92-42f7-b0d7-db9c64b8c3e7",
    iconSet: "Ionicons",
    iconName: "arrow-up"
  },
  {
    uuid: "6785833d-c67e-4e0c-bfd2-fc7d8aa4a1c5",
    iconSet: "Ionicons",
    iconName: "arrow-up-circle"
  },
  {
    uuid: "61cefea5-c301-495b-98db-0013fd4ae77d",
    iconSet: "Ionicons",
    iconName: "arrow-up-circle-outline"
  },
  {
    uuid: "9d8791ec-3463-4412-9d12-6bc67909824f",
    iconSet: "Ionicons",
    iconName: "arrow-up-circle-sharp"
  },
  {
    uuid: "dc1a71ca-7e14-4fdf-8d27-398fe486210a",
    iconSet: "Ionicons",
    iconName: "arrow-up-outline"
  },
  {
    uuid: "99aafb84-26c7-4b62-a490-d0bc31a876e8",
    iconSet: "Ionicons",
    iconName: "arrow-up-sharp"
  },
  {
    uuid: "fc8a4140-7976-45c9-b74d-a17b9aa442db",
    iconSet: "Ionicons",
    iconName: "at"
  },
  {
    uuid: "c070d7ca-767b-45b7-8f4e-095fb6079145",
    iconSet: "Ionicons",
    iconName: "at-circle"
  },
  {
    uuid: "d3ad1919-f65d-4bbf-9731-16769cbad01b",
    iconSet: "Ionicons",
    iconName: "at-circle-outline"
  },
  {
    uuid: "0d4a3bb4-a4b1-4894-80a8-d378b36bd173",
    iconSet: "Ionicons",
    iconName: "at-circle-sharp"
  },
  {
    uuid: "194e4aea-7595-4795-a783-b846509a3ceb",
    iconSet: "Ionicons",
    iconName: "at-outline"
  },
  {
    uuid: "d8c43c4f-d6a2-415f-8085-ab74e02836c4",
    iconSet: "Ionicons",
    iconName: "at-sharp"
  },
  {
    uuid: "62c06a35-20ce-4fb3-a4c7-060921b329a1",
    iconSet: "Ionicons",
    iconName: "attach"
  },
  {
    uuid: "cafb1ccb-41d1-4abe-aaba-1d2191b9d494",
    iconSet: "Ionicons",
    iconName: "attach-outline"
  },
  {
    uuid: "f7da8b19-feee-4f27-a10b-2482ea879c4b",
    iconSet: "Ionicons",
    iconName: "attach-sharp"
  },
  {
    uuid: "12845db2-8d08-459a-8786-5ca732df148a",
    iconSet: "Ionicons",
    iconName: "backspace"
  },
  {
    uuid: "254be8b9-c3b1-4c9a-b290-08f866677481",
    iconSet: "Ionicons",
    iconName: "backspace-outline"
  },
  {
    uuid: "49582d06-38dc-4f3a-bf4b-e61960575160",
    iconSet: "Ionicons",
    iconName: "backspace-sharp"
  },
  {
    uuid: "c65d1e9d-8dcf-4893-b24c-25ffa6bc1ecd",
    iconSet: "Ionicons",
    iconName: "bandage"
  },
  {
    uuid: "1aabf9f6-10f9-4431-8a75-0d607230e600",
    iconSet: "Ionicons",
    iconName: "bandage-outline"
  },
  {
    uuid: "d378619b-b364-4f79-bdb4-ff4e68d648c5",
    iconSet: "Ionicons",
    iconName: "bandage-sharp"
  },
  {
    uuid: "db5e74f6-41c3-4bcf-a688-77b1b3f07b8b",
    iconSet: "Ionicons",
    iconName: "bar-chart"
  },
  {
    uuid: "23d3180d-b9db-473c-86b8-8f0acac06cb2",
    iconSet: "Ionicons",
    iconName: "bar-chart-outline"
  },
  {
    uuid: "befcfb1e-4fef-4ead-9cb6-13ddba4cec9a",
    iconSet: "Ionicons",
    iconName: "bar-chart-sharp"
  },
  {
    uuid: "a109aea2-0c9e-459c-9a2d-872962f73ee0",
    iconSet: "Ionicons",
    iconName: "barbell"
  },
  {
    uuid: "26690c6e-c30b-45ba-a82b-615c5388df03",
    iconSet: "Ionicons",
    iconName: "barbell-outline"
  },
  {
    uuid: "90f1fc1e-783e-4613-a45c-a6f1ab9167b4",
    iconSet: "Ionicons",
    iconName: "barbell-sharp"
  },
  {
    uuid: "d3b2e03f-3eda-446c-bc98-d3b43fd903fe",
    iconSet: "Ionicons",
    iconName: "barcode"
  },
  {
    uuid: "56b9f964-5018-4603-9a3c-3105e7f94635",
    iconSet: "Ionicons",
    iconName: "barcode-outline"
  },
  {
    uuid: "d73f707b-4d69-46bc-80ed-c39ebf408cd7",
    iconSet: "Ionicons",
    iconName: "barcode-sharp"
  },
  {
    uuid: "002178c1-4a62-42de-8a33-2ccd87fe54b9",
    iconSet: "Ionicons",
    iconName: "baseball"
  },
  {
    uuid: "dbc9c583-8aca-4829-a8fb-ebd95a224239",
    iconSet: "Ionicons",
    iconName: "baseball-outline"
  },
  {
    uuid: "0b01b1c6-b6c8-463d-8a63-57e7c13437de",
    iconSet: "Ionicons",
    iconName: "baseball-sharp"
  },
  {
    uuid: "8b07b770-0982-4418-a511-c5eec89194e5",
    iconSet: "Ionicons",
    iconName: "basket"
  },
  {
    uuid: "66d7c31d-68ec-43b8-9ba0-28844cc5602a",
    iconSet: "Ionicons",
    iconName: "basket-outline"
  },
  {
    uuid: "6fb4e7c6-cf23-4ae2-9a95-a89175b45979",
    iconSet: "Ionicons",
    iconName: "basket-sharp"
  },
  {
    uuid: "7d1ae835-ac23-4ebb-bd87-d736f1ef5771",
    iconSet: "Ionicons",
    iconName: "basketball"
  },
  {
    uuid: "0d92c548-f52e-41e4-8b22-b713cf545aff",
    iconSet: "Ionicons",
    iconName: "basketball-outline"
  },
  {
    uuid: "12b60aab-7925-4fab-8505-7c77ae07e792",
    iconSet: "Ionicons",
    iconName: "basketball-sharp"
  },
  {
    uuid: "9f693c6a-dae7-4f59-9180-b326f5581554",
    iconSet: "Ionicons",
    iconName: "battery-charging"
  },
  {
    uuid: "078dcdb7-bd37-4e01-9289-440667fc2d6d",
    iconSet: "Ionicons",
    iconName: "battery-charging-outline"
  },
  {
    uuid: "2a16ccda-bb2d-42d0-85e9-49fa17400c7d",
    iconSet: "Ionicons",
    iconName: "battery-charging-sharp"
  },
  {
    uuid: "716702fe-8a7b-4daa-b9fe-c3a729e7ea02",
    iconSet: "Ionicons",
    iconName: "battery-dead"
  },
  {
    uuid: "0ad3c6aa-fdbb-434d-a305-ade9c4ca4dd4",
    iconSet: "Ionicons",
    iconName: "battery-dead-outline"
  },
  {
    uuid: "208647df-df98-4f43-b837-6a3a00de284c",
    iconSet: "Ionicons",
    iconName: "battery-dead-sharp"
  },
  {
    uuid: "2ffa3727-d68c-48eb-991d-fd8e21549801",
    iconSet: "Ionicons",
    iconName: "battery-full"
  },
  {
    uuid: "3d172df6-74ac-4816-b542-f41c50c28b35",
    iconSet: "Ionicons",
    iconName: "battery-full-outline"
  },
  {
    uuid: "5e18dcde-8307-4a67-a0ae-05547d6b09e8",
    iconSet: "Ionicons",
    iconName: "battery-full-sharp"
  },
  {
    uuid: "2599a267-0a64-4f72-a6e9-2238a150a0dd",
    iconSet: "Ionicons",
    iconName: "battery-half"
  },
  {
    uuid: "d5389112-36f2-42f9-be9f-684f77faf14c",
    iconSet: "Ionicons",
    iconName: "battery-half-outline"
  },
  {
    uuid: "12f74da4-e9e6-4e17-a4ee-54db0446a5a9",
    iconSet: "Ionicons",
    iconName: "battery-half-sharp"
  },
  {
    uuid: "043aa8b3-094e-4124-be95-817711750382",
    iconSet: "Ionicons",
    iconName: "beaker"
  },
  {
    uuid: "cd3a5516-6f94-4c41-b090-13d2d25c6ab6",
    iconSet: "Ionicons",
    iconName: "beaker-outline"
  },
  {
    uuid: "5fad6f29-74a5-4b72-96a2-c70f44573493",
    iconSet: "Ionicons",
    iconName: "beaker-sharp"
  },
  {
    uuid: "64107c99-b07b-455e-b84d-ec1124461530",
    iconSet: "Ionicons",
    iconName: "bed"
  },
  {
    uuid: "5890d9b6-424d-4e53-b473-8392ce4b5197",
    iconSet: "Ionicons",
    iconName: "bed-outline"
  },
  {
    uuid: "fae228cf-bd05-4dd6-a11f-fdc722bf19f6",
    iconSet: "Ionicons",
    iconName: "bed-sharp"
  },
  {
    uuid: "a8deae41-7413-4c48-874e-a2977fdd114d",
    iconSet: "Ionicons",
    iconName: "beer"
  },
  {
    uuid: "106dde1f-2bc7-48eb-97e2-d7fa1d3fe5bd",
    iconSet: "Ionicons",
    iconName: "beer-outline"
  },
  {
    uuid: "879a700a-677e-47a1-8c88-3e5afd096ac4",
    iconSet: "Ionicons",
    iconName: "beer-sharp"
  },
  {
    uuid: "4bf7b384-36c6-4191-b03a-37484107cb2f",
    iconSet: "Ionicons",
    iconName: "bicycle"
  },
  {
    uuid: "c5954d05-12d2-477c-9cab-a6b939bfea2a",
    iconSet: "Ionicons",
    iconName: "bicycle-outline"
  },
  {
    uuid: "73358c21-a177-422f-b1c2-da091eeebd55",
    iconSet: "Ionicons",
    iconName: "bicycle-sharp"
  },
  {
    uuid: "d3975f0b-24e0-4d94-b797-d88b6ef0a53e",
    iconSet: "Ionicons",
    iconName: "bluetooth"
  },
  {
    uuid: "5c117a96-7ec7-4b9a-ba9d-2e71df983e70",
    iconSet: "Ionicons",
    iconName: "bluetooth-outline"
  },
  {
    uuid: "a6d0223c-2b2a-4775-a2a2-b13bc0b663e3",
    iconSet: "Ionicons",
    iconName: "bluetooth-sharp"
  },
  {
    uuid: "862be931-5122-44ff-b196-0870d0833e01",
    iconSet: "Ionicons",
    iconName: "boat"
  },
  {
    uuid: "5ae46bb8-244b-4dac-a9bd-437eda0d301c",
    iconSet: "Ionicons",
    iconName: "boat-outline"
  },
  {
    uuid: "5c9d4030-ab66-49b6-a3f6-6e123188f66d",
    iconSet: "Ionicons",
    iconName: "boat-sharp"
  },
  {
    uuid: "bcad2fbc-b405-4b53-8757-908ec435d506",
    iconSet: "Ionicons",
    iconName: "body"
  },
  {
    uuid: "fdbc0f37-520d-4a7a-b858-a1d6f970d53f",
    iconSet: "Ionicons",
    iconName: "body-outline"
  },
  {
    uuid: "2507de0d-31cd-4e23-968c-eb0c4ae0b439",
    iconSet: "Ionicons",
    iconName: "body-sharp"
  },
  {
    uuid: "411c7b7d-d7bf-4f8e-9941-4150a93a14a5",
    iconSet: "Ionicons",
    iconName: "bonfire"
  },
  {
    uuid: "da26c5fd-bf9f-4b65-83d5-11af78132425",
    iconSet: "Ionicons",
    iconName: "bonfire-outline"
  },
  {
    uuid: "4ae66708-653c-49cf-9bff-14ee19cd0d6f",
    iconSet: "Ionicons",
    iconName: "bonfire-sharp"
  },
  {
    uuid: "72713f1e-c5b8-4774-844d-585faf1343d1",
    iconSet: "Ionicons",
    iconName: "book"
  },
  {
    uuid: "ac84f035-3633-4850-8744-8fa7be1e8c53",
    iconSet: "Ionicons",
    iconName: "book-outline"
  },
  {
    uuid: "25232f39-3183-4662-83bc-e5deac6f175d",
    iconSet: "Ionicons",
    iconName: "book-sharp"
  },
  {
    uuid: "a58058c1-fb39-4750-8e1f-3a668e951b1a",
    iconSet: "Ionicons",
    iconName: "bookmark"
  },
  {
    uuid: "9820ff4a-32bc-407f-bc7e-6bdd2115e80d",
    iconSet: "Ionicons",
    iconName: "bookmark-outline"
  },
  {
    uuid: "daaa6478-2982-474c-ac91-082471c6a8ca",
    iconSet: "Ionicons",
    iconName: "bookmark-sharp"
  },
  {
    uuid: "dcdb2d57-8bbb-4b4a-8381-0f450ab7884d",
    iconSet: "Ionicons",
    iconName: "bookmarks"
  },
  {
    uuid: "1ed3c898-2269-4f0c-baf2-845ff028f4b7",
    iconSet: "Ionicons",
    iconName: "bookmarks-outline"
  },
  {
    uuid: "29e601bc-ea5d-4319-a437-16ea669ed644",
    iconSet: "Ionicons",
    iconName: "bookmarks-sharp"
  },
  {
    uuid: "534c4bb4-75cf-44d2-8c0f-6323f9b9877c",
    iconSet: "Ionicons",
    iconName: "briefcase"
  },
  {
    uuid: "e457e63e-c937-427e-9077-c9e4cca75264",
    iconSet: "Ionicons",
    iconName: "briefcase-outline"
  },
  {
    uuid: "79b53a93-c33e-4771-b19c-3cb5c2a0e2b5",
    iconSet: "Ionicons",
    iconName: "briefcase-sharp"
  },
  {
    uuid: "08408ecd-cb2b-4ced-97f5-80a5abf85dc7",
    iconSet: "Ionicons",
    iconName: "browsers"
  },
  {
    uuid: "02b6f0af-1cda-45bd-b77b-d19c11098883",
    iconSet: "Ionicons",
    iconName: "browsers-outline"
  },
  {
    uuid: "9e8161ea-d793-4971-a89d-0840a291d18e",
    iconSet: "Ionicons",
    iconName: "browsers-sharp"
  },
  {
    uuid: "2e60cc33-787e-4f81-8e68-b32b9163ecee",
    iconSet: "Ionicons",
    iconName: "brush"
  },
  {
    uuid: "303a4da3-05ea-4955-acc7-198b648e29f8",
    iconSet: "Ionicons",
    iconName: "brush-outline"
  },
  {
    uuid: "b576b5e2-596c-461b-855d-12837324cddc",
    iconSet: "Ionicons",
    iconName: "brush-sharp"
  },
  {
    uuid: "87a9f0d1-9792-43b2-991a-1adb52306e50",
    iconSet: "Ionicons",
    iconName: "bug"
  },
  {
    uuid: "24600159-d1d9-4677-b2ed-cb4fa60c9fc5",
    iconSet: "Ionicons",
    iconName: "bug-outline"
  },
  {
    uuid: "828cb0a9-c4a5-40ba-b924-b66b9b21238a",
    iconSet: "Ionicons",
    iconName: "bug-sharp"
  },
  {
    uuid: "dd3fd2dd-d838-4673-bf74-828dbf108262",
    iconSet: "Ionicons",
    iconName: "build"
  },
  {
    uuid: "8544865a-9cd6-425a-b751-d1b1c3c12b33",
    iconSet: "Ionicons",
    iconName: "build-outline"
  },
  {
    uuid: "8c7aecd9-3056-4308-a57d-06b84ce60311",
    iconSet: "Ionicons",
    iconName: "build-sharp"
  },
  {
    uuid: "22cc1225-10ef-47a0-ba05-73b84e65ec89",
    iconSet: "Ionicons",
    iconName: "bulb"
  },
  {
    uuid: "c5581c61-bdd2-457e-914e-79f73cd61410",
    iconSet: "Ionicons",
    iconName: "bulb-outline"
  },
  {
    uuid: "c695ce77-b03c-4685-9ee3-53585cdecab9",
    iconSet: "Ionicons",
    iconName: "bulb-sharp"
  },
  {
    uuid: "a921330c-e1e4-452f-b880-b98231296cf0",
    iconSet: "Ionicons",
    iconName: "bus"
  },
  {
    uuid: "8cf30e5b-bfc7-407a-ba48-a973cc3a25d0",
    iconSet: "Ionicons",
    iconName: "bus-outline"
  },
  {
    uuid: "f7dd5a25-eca6-48e8-9a18-c467f13f295f",
    iconSet: "Ionicons",
    iconName: "bus-sharp"
  },
  {
    uuid: "ba3b2956-54a9-45d2-b2a3-2af2e786e6b3",
    iconSet: "Ionicons",
    iconName: "business"
  },
  {
    uuid: "995bb57b-086b-4ce8-a514-c96aac5a811f",
    iconSet: "Ionicons",
    iconName: "business-outline"
  },
  {
    uuid: "3d21c330-3740-4e7f-a089-2ffe795addca",
    iconSet: "Ionicons",
    iconName: "business-sharp"
  },
  {
    uuid: "224c0718-0e31-4925-8db0-531eedbce74d",
    iconSet: "Ionicons",
    iconName: "cafe"
  },
  {
    uuid: "921f8b30-0b2c-4ace-b66a-e440ce3454a0",
    iconSet: "Ionicons",
    iconName: "cafe-outline"
  },
  {
    uuid: "9e0266f1-5049-4d57-9599-91f4024af6ae",
    iconSet: "Ionicons",
    iconName: "cafe-sharp"
  },
  {
    uuid: "86591796-384c-46a4-a1cb-aeea5e31f490",
    iconSet: "Ionicons",
    iconName: "calculator"
  },
  {
    uuid: "8a424248-9203-4b63-be64-c85066ee53eb",
    iconSet: "Ionicons",
    iconName: "calculator-outline"
  },
  {
    uuid: "6d04b380-cbac-4a18-81ad-d743cc0a86e9",
    iconSet: "Ionicons",
    iconName: "calculator-sharp"
  },
  {
    uuid: "b8f195ec-a7ab-4783-b77e-267acf21d688",
    iconSet: "Ionicons",
    iconName: "calendar"
  },
  {
    uuid: "c621102a-3fa6-4986-9f6e-e89ccfff967f",
    iconSet: "Ionicons",
    iconName: "calendar-outline"
  },
  {
    uuid: "83559075-0779-42c5-880a-00d5360126ef",
    iconSet: "Ionicons",
    iconName: "calendar-sharp"
  },
  {
    uuid: "a546b180-d17a-44b7-918f-307989e6f96a",
    iconSet: "Ionicons",
    iconName: "call"
  },
  {
    uuid: "c633db41-7973-47c6-9b21-74857c92782b",
    iconSet: "Ionicons",
    iconName: "call-outline"
  },
  {
    uuid: "7958a99b-4cb6-4686-9526-5a00d83f8144",
    iconSet: "Ionicons",
    iconName: "call-sharp"
  },
  {
    uuid: "0d99ef17-c99f-49d5-b6af-b214adcebbee",
    iconSet: "Ionicons",
    iconName: "camera"
  },
  {
    uuid: "98d1a559-696b-42f3-b2d3-41198dc4d00f",
    iconSet: "Ionicons",
    iconName: "camera-outline"
  },
  {
    uuid: "09c01391-2868-4d73-b11d-8967b50368f3",
    iconSet: "Ionicons",
    iconName: "camera-reverse"
  },
  {
    uuid: "e40a2fbf-aa37-45bd-ba64-32d5ff118d91",
    iconSet: "Ionicons",
    iconName: "camera-reverse-outline"
  },
  {
    uuid: "d0570a8c-0260-4f85-970c-0d9b20f46dea",
    iconSet: "Ionicons",
    iconName: "camera-reverse-sharp"
  },
  {
    uuid: "b8f5df27-7cad-4b8f-85fd-61328b22ba1b",
    iconSet: "Ionicons",
    iconName: "camera-sharp"
  },
  {
    uuid: "ccbf9190-6507-4ef8-857e-48bc35a8f0b8",
    iconSet: "Ionicons",
    iconName: "car"
  },
  {
    uuid: "ae7b4ac6-3d31-433f-b8e8-82e8580a2011",
    iconSet: "Ionicons",
    iconName: "car-outline"
  },
  {
    uuid: "f994adba-58c3-4258-bb11-91707a446439",
    iconSet: "Ionicons",
    iconName: "car-sharp"
  },
  {
    uuid: "32b0714a-0a98-48e3-b3d7-f28a1a517bae",
    iconSet: "Ionicons",
    iconName: "car-sport"
  },
  {
    uuid: "c53eec99-a52c-4b0e-9336-6d0aff87b36f",
    iconSet: "Ionicons",
    iconName: "car-sport-outline"
  },
  {
    uuid: "99c40786-af37-4f01-8ac6-39c7a3d467cf",
    iconSet: "Ionicons",
    iconName: "car-sport-sharp"
  },
  {
    uuid: "f01c4a48-d728-4e0e-84e1-ad62c8d21bbe",
    iconSet: "Ionicons",
    iconName: "card"
  },
  {
    uuid: "ea21b06b-51be-48de-b4a2-18b5f9e6af2b",
    iconSet: "Ionicons",
    iconName: "card-outline"
  },
  {
    uuid: "5e790b8c-63c3-4bdf-ac40-afc635fadbae",
    iconSet: "Ionicons",
    iconName: "card-sharp"
  },
  {
    uuid: "67708166-96b6-44c6-8f10-e0d19780b0a3",
    iconSet: "Ionicons",
    iconName: "caret-back"
  },
  {
    uuid: "af96f266-ad43-451e-b239-682884c8d5aa",
    iconSet: "Ionicons",
    iconName: "caret-back-circle"
  },
  {
    uuid: "ee5bb892-b49e-48c5-a88a-ed08739ea2d8",
    iconSet: "Ionicons",
    iconName: "caret-back-circle-outline"
  },
  {
    uuid: "b0406e6a-e772-4e04-8763-8664a7498546",
    iconSet: "Ionicons",
    iconName: "caret-back-circle-sharp"
  },
  {
    uuid: "7228eedc-67e8-4ecf-879d-e9d0a908b732",
    iconSet: "Ionicons",
    iconName: "caret-back-outline"
  },
  {
    uuid: "7e9de465-3d45-4e7d-a6cf-5eaf1e8a5309",
    iconSet: "Ionicons",
    iconName: "caret-back-sharp"
  },
  {
    uuid: "a47dc436-27d7-41c5-ad5a-fbbb4f9098bd",
    iconSet: "Ionicons",
    iconName: "caret-down"
  },
  {
    uuid: "8f1b9d26-7530-4a66-88b9-835fe634cf3d",
    iconSet: "Ionicons",
    iconName: "caret-down-circle"
  },
  {
    uuid: "aa3fdd2b-7ff7-4df5-b517-f488fd39e14a",
    iconSet: "Ionicons",
    iconName: "caret-down-circle-outline"
  },
  {
    uuid: "abdd0260-b757-4f8c-8fc4-de4142b70d44",
    iconSet: "Ionicons",
    iconName: "caret-down-circle-sharp"
  },
  {
    uuid: "0c778546-512a-4126-b90b-b98244943508",
    iconSet: "Ionicons",
    iconName: "caret-down-outline"
  },
  {
    uuid: "5e39130c-8c12-4aa4-9b02-c12d100a938a",
    iconSet: "Ionicons",
    iconName: "caret-down-sharp"
  },
  {
    uuid: "90872051-05a7-46f7-85f4-1c332cb23d17",
    iconSet: "Ionicons",
    iconName: "caret-forward"
  },
  {
    uuid: "213f1fe3-78c2-480d-9df9-44cfaf3e7689",
    iconSet: "Ionicons",
    iconName: "caret-forward-circle"
  },
  {
    uuid: "bc3db1d6-5165-4efb-b65f-020390c5d326",
    iconSet: "Ionicons",
    iconName: "caret-forward-circle-outline"
  },
  {
    uuid: "d194b6c8-c848-4605-bc5b-003ebdfce259",
    iconSet: "Ionicons",
    iconName: "caret-forward-circle-sharp"
  },
  {
    uuid: "adbc6ebd-5a63-4326-ac56-50ed6512f0d0",
    iconSet: "Ionicons",
    iconName: "caret-forward-outline"
  },
  {
    uuid: "84dad754-04a9-40e8-bc41-59c572d19f48",
    iconSet: "Ionicons",
    iconName: "caret-forward-sharp"
  },
  {
    uuid: "26853e34-d42e-4628-a16b-37c7ada52ca9",
    iconSet: "Ionicons",
    iconName: "caret-up"
  },
  {
    uuid: "a65340ec-c654-416b-834c-a0e1e236fd18",
    iconSet: "Ionicons",
    iconName: "caret-up-circle"
  },
  {
    uuid: "8e07a5cd-2d08-4a3c-b346-878f9d1997b6",
    iconSet: "Ionicons",
    iconName: "caret-up-circle-outline"
  },
  {
    uuid: "00b3e563-49e1-48a3-ae18-e5c7395e370a",
    iconSet: "Ionicons",
    iconName: "caret-up-circle-sharp"
  },
  {
    uuid: "c2745b33-4d1e-4549-a347-a94593f51365",
    iconSet: "Ionicons",
    iconName: "caret-up-outline"
  },
  {
    uuid: "1dfe429e-215e-42fb-a33d-a39ecce719ea",
    iconSet: "Ionicons",
    iconName: "caret-up-sharp"
  },
  {
    uuid: "1e96ac81-bc04-4a44-bca2-311a52f1fc31",
    iconSet: "Ionicons",
    iconName: "cart"
  },
  {
    uuid: "f311dbef-6012-4df4-9364-e64978eab790",
    iconSet: "Ionicons",
    iconName: "cart-outline"
  },
  {
    uuid: "371ada26-9511-430a-adce-6ebed4c02607",
    iconSet: "Ionicons",
    iconName: "cart-sharp"
  },
  {
    uuid: "0ad377a9-3365-47e0-82c1-1df25094ef25",
    iconSet: "Ionicons",
    iconName: "cash"
  },
  {
    uuid: "7cccbf5d-edad-47fc-b3fc-03cb417cfa4e",
    iconSet: "Ionicons",
    iconName: "cash-outline"
  },
  {
    uuid: "592c8bd8-a6f7-49d1-a9d9-ab9e8a3a0d92",
    iconSet: "Ionicons",
    iconName: "cash-sharp"
  },
  {
    uuid: "23ec27b1-73b9-4760-8ef6-ae113d84ec4f",
    iconSet: "Ionicons",
    iconName: "cellular"
  },
  {
    uuid: "3d448475-29f4-46b2-9f0e-4cea9bdb1f89",
    iconSet: "Ionicons",
    iconName: "cellular-outline"
  },
  {
    uuid: "85ca38e6-0f56-47db-af16-0bef797a33f5",
    iconSet: "Ionicons",
    iconName: "cellular-sharp"
  },
  {
    uuid: "3db862dc-d95f-494d-8387-356bf57ba258",
    iconSet: "Ionicons",
    iconName: "chatbox"
  },
  {
    uuid: "6297e404-50c9-4892-86d2-ba16d53ab66c",
    iconSet: "Ionicons",
    iconName: "chatbox-ellipses"
  },
  {
    uuid: "90983596-e822-4ff1-a79d-181d68888aed",
    iconSet: "Ionicons",
    iconName: "chatbox-ellipses-outline"
  },
  {
    uuid: "9b6dcd3e-d6f1-48fe-af46-e32ec16001c5",
    iconSet: "Ionicons",
    iconName: "chatbox-ellipses-sharp"
  },
  {
    uuid: "0d96b732-b662-4a60-97c9-d38a485039c7",
    iconSet: "Ionicons",
    iconName: "chatbox-outline"
  },
  {
    uuid: "9c035233-de39-4208-a07e-33584c303b6e",
    iconSet: "Ionicons",
    iconName: "chatbox-sharp"
  },
  {
    uuid: "713c37f0-4779-43b2-a27d-2a6b35f72347",
    iconSet: "Ionicons",
    iconName: "chatbubble"
  },
  {
    uuid: "70379a61-87c2-4401-b004-3e385b86e358",
    iconSet: "Ionicons",
    iconName: "chatbubble-ellipses"
  },
  {
    uuid: "6264f323-7c75-403a-b134-2906dbfdfbe9",
    iconSet: "Ionicons",
    iconName: "chatbubble-ellipses-outline"
  },
  {
    uuid: "38006b4a-1fc9-46a1-a337-71e40b3ba359",
    iconSet: "Ionicons",
    iconName: "chatbubble-ellipses-sharp"
  },
  {
    uuid: "01816da3-7a78-401e-9371-80b0833ff1f8",
    iconSet: "Ionicons",
    iconName: "chatbubble-outline"
  },
  {
    uuid: "fa2c2c89-04dc-42a2-9dde-c9408bb7f3d7",
    iconSet: "Ionicons",
    iconName: "chatbubble-sharp"
  },
  {
    uuid: "fd2087d3-51de-4937-9016-fe670b210f45",
    iconSet: "Ionicons",
    iconName: "chatbubbles"
  },
  {
    uuid: "6439d70c-9d02-403f-af11-15d4d20a1331",
    iconSet: "Ionicons",
    iconName: "chatbubbles-outline"
  },
  {
    uuid: "21953cf8-20c5-45c0-909a-f49ebcb975a8",
    iconSet: "Ionicons",
    iconName: "chatbubbles-sharp"
  },
  {
    uuid: "2310cdd1-4099-4ae0-a8b4-dc85c4dae90e",
    iconSet: "Ionicons",
    iconName: "checkbox"
  },
  {
    uuid: "7876a684-8704-4fb4-a196-b43ac3516798",
    iconSet: "Ionicons",
    iconName: "checkbox-outline"
  },
  {
    uuid: "20c574ac-eef4-4fd0-a5f9-a93fe0a7c732",
    iconSet: "Ionicons",
    iconName: "checkbox-sharp"
  },
  {
    uuid: "89969d8f-b01d-4760-aa3c-4a9cceb792bb",
    iconSet: "Ionicons",
    iconName: "checkmark"
  },
  {
    uuid: "844792dc-5079-4ce7-9768-6e99f5061898",
    iconSet: "Ionicons",
    iconName: "checkmark-circle"
  },
  {
    uuid: "bb8d37cc-d2da-4c8c-a491-179c4b3a9c47",
    iconSet: "Ionicons",
    iconName: "checkmark-circle-outline"
  },
  {
    uuid: "f993a247-1f00-4add-8db8-e66e962116f6",
    iconSet: "Ionicons",
    iconName: "checkmark-circle-sharp"
  },
  {
    uuid: "22506e81-141e-439e-9fe8-c4008a4bebda",
    iconSet: "Ionicons",
    iconName: "checkmark-done"
  },
  {
    uuid: "a18a1a11-df41-4ee4-9005-fa225b7cdfd7",
    iconSet: "Ionicons",
    iconName: "checkmark-done-circle"
  },
  {
    uuid: "d956356e-87a8-4f81-b52c-07c11e46fb32",
    iconSet: "Ionicons",
    iconName: "checkmark-done-circle-outline"
  },
  {
    uuid: "c01d215c-7b5e-410b-9dcf-92d312216b25",
    iconSet: "Ionicons",
    iconName: "checkmark-done-circle-sharp"
  },
  {
    uuid: "add43f78-e56f-4db7-aa21-5e192776eccf",
    iconSet: "Ionicons",
    iconName: "checkmark-done-outline"
  },
  {
    uuid: "742db246-9435-413b-b8ab-bdc405e872e5",
    iconSet: "Ionicons",
    iconName: "checkmark-done-sharp"
  },
  {
    uuid: "3d623179-e05f-47bd-95fe-bb146b0eb2fe",
    iconSet: "Ionicons",
    iconName: "checkmark-outline"
  },
  {
    uuid: "c4fcff62-5cdc-4b13-b118-7502fd43075e",
    iconSet: "Ionicons",
    iconName: "checkmark-sharp"
  },
  {
    uuid: "a6306f13-1dbe-4949-aa2c-874984a8c784",
    iconSet: "Ionicons",
    iconName: "chevron-back"
  },
  {
    uuid: "65f6fb71-c277-41e8-bc4a-9d9f1d4e7cde",
    iconSet: "Ionicons",
    iconName: "chevron-back-circle"
  },
  {
    uuid: "fbd63bc5-19c7-4188-a804-1ac98e8d8248",
    iconSet: "Ionicons",
    iconName: "chevron-back-circle-outline"
  },
  {
    uuid: "222d7d3e-7b34-49aa-bce6-8db05d695636",
    iconSet: "Ionicons",
    iconName: "chevron-back-circle-sharp"
  },
  {
    uuid: "2176cf0a-cc0e-4c3c-89ac-5153f9e2bac4",
    iconSet: "Ionicons",
    iconName: "chevron-back-outline"
  },
  {
    uuid: "4d752c8c-01d0-4243-b0e0-f382e9af5c52",
    iconSet: "Ionicons",
    iconName: "chevron-back-sharp"
  },
  {
    uuid: "8ff1ed38-755b-4780-a34d-83abe19c0029",
    iconSet: "Ionicons",
    iconName: "chevron-down"
  },
  {
    uuid: "55146e07-77e2-4265-9c5c-8ef8d2d70a90",
    iconSet: "Ionicons",
    iconName: "chevron-down-circle"
  },
  {
    uuid: "e6335170-12fa-4c5d-baa4-a73b79a7dbac",
    iconSet: "Ionicons",
    iconName: "chevron-down-circle-outline"
  },
  {
    uuid: "9c2e3a91-ac51-47b3-b65a-1bd4a08b0adf",
    iconSet: "Ionicons",
    iconName: "chevron-down-circle-sharp"
  },
  {
    uuid: "515f5060-266f-40c3-a520-b13dac9da105",
    iconSet: "Ionicons",
    iconName: "chevron-down-outline"
  },
  {
    uuid: "aac49e30-848b-435b-8cb7-5afb1ee4f7ab",
    iconSet: "Ionicons",
    iconName: "chevron-down-sharp"
  },
  {
    uuid: "0a288e2a-1735-4786-a981-929bcfe8777e",
    iconSet: "Ionicons",
    iconName: "chevron-forward"
  },
  {
    uuid: "82340c6e-8909-4db0-bd62-01c48bb9e0ea",
    iconSet: "Ionicons",
    iconName: "chevron-forward-circle"
  },
  {
    uuid: "505ef582-d92b-4b96-bada-d90365e3592f",
    iconSet: "Ionicons",
    iconName: "chevron-forward-circle-outline"
  },
  {
    uuid: "b09f297e-04fd-450c-8784-6d2bbe47d516",
    iconSet: "Ionicons",
    iconName: "chevron-forward-circle-sharp"
  },
  {
    uuid: "1160444b-d11e-4d6b-bc45-b387fc5cb090",
    iconSet: "Ionicons",
    iconName: "chevron-forward-outline"
  },
  {
    uuid: "d52e9a22-bc7d-461e-b700-5a34198d2c79",
    iconSet: "Ionicons",
    iconName: "chevron-forward-sharp"
  },
  {
    uuid: "b0939b53-b066-488e-9e18-640ad1a4b6a1",
    iconSet: "Ionicons",
    iconName: "chevron-up"
  },
  {
    uuid: "9278cad5-7aca-4cf4-aeec-73bcf4485107",
    iconSet: "Ionicons",
    iconName: "chevron-up-circle"
  },
  {
    uuid: "303476a3-b0c0-48d7-a4e3-4b8b97ef4ce2",
    iconSet: "Ionicons",
    iconName: "chevron-up-circle-outline"
  },
  {
    uuid: "caa4ff9a-2dc2-4d67-9bcc-16d3317e017c",
    iconSet: "Ionicons",
    iconName: "chevron-up-circle-sharp"
  },
  {
    uuid: "b21b705f-15cb-485a-bf1c-fbecb049c19f",
    iconSet: "Ionicons",
    iconName: "chevron-up-outline"
  },
  {
    uuid: "acbccdb3-dbc2-4e84-9d67-3058fd06a05f",
    iconSet: "Ionicons",
    iconName: "chevron-up-sharp"
  },
  {
    uuid: "80f0b40c-0ad6-4f34-8995-5449240ae196",
    iconSet: "Ionicons",
    iconName: "clipboard"
  },
  {
    uuid: "f6dc97ca-1b34-4966-812e-b2e7c3fbe60e",
    iconSet: "Ionicons",
    iconName: "clipboard-outline"
  },
  {
    uuid: "5e8ac301-f9f7-49ad-9830-ea3b862363ba",
    iconSet: "Ionicons",
    iconName: "clipboard-sharp"
  },
  {
    uuid: "0fba40a3-590e-4bc0-9f70-fc5ac5440423",
    iconSet: "Ionicons",
    iconName: "close"
  },
  {
    uuid: "1705d4d4-8ef4-4684-99be-9058dba1c14e",
    iconSet: "Ionicons",
    iconName: "close-circle"
  },
  {
    uuid: "0c1c80eb-09af-4d02-847f-6e746dd15cf4",
    iconSet: "Ionicons",
    iconName: "close-circle-outline"
  },
  {
    uuid: "b893ecbf-2fe0-4426-ab3b-987126f3f25f",
    iconSet: "Ionicons",
    iconName: "close-circle-sharp"
  },
  {
    uuid: "db9d9487-b4ae-41c1-b18b-fb9458295eca",
    iconSet: "Ionicons",
    iconName: "close-outline"
  },
  {
    uuid: "4d325d58-3095-4568-9eee-482b4e6ea015",
    iconSet: "Ionicons",
    iconName: "close-sharp"
  },
  {
    uuid: "4088f1f4-3f7f-461b-9586-9853db893613",
    iconSet: "Ionicons",
    iconName: "cloud"
  },
  {
    uuid: "d95a6067-6644-4408-a84d-e137617c1872",
    iconSet: "Ionicons",
    iconName: "cloud-circle"
  },
  {
    uuid: "00be2e97-ba5c-4259-9b7a-ffd01e358eec",
    iconSet: "Ionicons",
    iconName: "cloud-circle-outline"
  },
  {
    uuid: "ecfc2b78-042d-4972-a6dd-c71c6796a62c",
    iconSet: "Ionicons",
    iconName: "cloud-circle-sharp"
  },
  {
    uuid: "51e10cde-3f78-40b9-a65f-b3ffced5a28f",
    iconSet: "Ionicons",
    iconName: "cloud-done"
  },
  {
    uuid: "94393a96-8bec-4730-ae28-768e698a2c6d",
    iconSet: "Ionicons",
    iconName: "cloud-done-outline"
  },
  {
    uuid: "490fe086-3af3-4bc6-b9c1-381f6b65e645",
    iconSet: "Ionicons",
    iconName: "cloud-done-sharp"
  },
  {
    uuid: "abfd31b4-09ae-44e2-8313-fffeda4f85ee",
    iconSet: "Ionicons",
    iconName: "cloud-download"
  },
  {
    uuid: "37c5618b-4ebc-4600-b27b-9de8dea4cec9",
    iconSet: "Ionicons",
    iconName: "cloud-download-outline"
  },
  {
    uuid: "63cb7d5b-63ab-4763-a320-70cf814c7054",
    iconSet: "Ionicons",
    iconName: "cloud-download-sharp"
  },
  {
    uuid: "508fe2d2-47f4-450d-85d4-6cb35ef7e1e4",
    iconSet: "Ionicons",
    iconName: "cloud-offline"
  },
  {
    uuid: "2b927928-c573-4c5e-995c-c8c823669965",
    iconSet: "Ionicons",
    iconName: "cloud-offline-outline"
  },
  {
    uuid: "fc32783b-573a-4bb1-87c5-820d3d554c15",
    iconSet: "Ionicons",
    iconName: "cloud-offline-sharp"
  },
  {
    uuid: "97b10b0d-47b5-4392-ab3c-ffed46bcdb17",
    iconSet: "Ionicons",
    iconName: "cloud-outline"
  },
  {
    uuid: "4be4dba5-436b-4025-a3b3-0ad22d27aa4e",
    iconSet: "Ionicons",
    iconName: "cloud-sharp"
  },
  {
    uuid: "14b8969d-577d-47be-92f3-dabbee895170",
    iconSet: "Ionicons",
    iconName: "cloud-upload"
  },
  {
    uuid: "285a3927-c2e7-4550-805f-926f6dc757a1",
    iconSet: "Ionicons",
    iconName: "cloud-upload-outline"
  },
  {
    uuid: "06d03687-283d-4a38-8347-696e83cb768d",
    iconSet: "Ionicons",
    iconName: "cloud-upload-sharp"
  },
  {
    uuid: "67302e7f-dc5b-41e6-b780-99c3378ae98d",
    iconSet: "Ionicons",
    iconName: "cloudy"
  },
  {
    uuid: "d04bc133-2779-4fd5-9b70-e57cedce1290",
    iconSet: "Ionicons",
    iconName: "cloudy-night"
  },
  {
    uuid: "cf68d861-680d-4719-8df0-f7225fb508df",
    iconSet: "Ionicons",
    iconName: "cloudy-night-outline"
  },
  {
    uuid: "7af15c61-6bd0-4e6f-bf66-82cdce5de7bd",
    iconSet: "Ionicons",
    iconName: "cloudy-night-sharp"
  },
  {
    uuid: "08620212-67dc-4c4a-8083-c774225971bf",
    iconSet: "Ionicons",
    iconName: "cloudy-outline"
  },
  {
    uuid: "eb3911bc-255f-4202-99a0-940ca368b221",
    iconSet: "Ionicons",
    iconName: "cloudy-sharp"
  },
  {
    uuid: "b66f5478-b166-4338-9bb5-ea91e9f66877",
    iconSet: "Ionicons",
    iconName: "code"
  },
  {
    uuid: "d0ded154-9a65-4477-9837-ef1580c965d8",
    iconSet: "Ionicons",
    iconName: "code-download"
  },
  {
    uuid: "3e00d25d-e18f-40d1-8f60-4e508c4009f2",
    iconSet: "Ionicons",
    iconName: "code-download-outline"
  },
  {
    uuid: "ac1b9a84-a951-4d54-a97b-a9b182cf7c08",
    iconSet: "Ionicons",
    iconName: "code-download-sharp"
  },
  {
    uuid: "4aedfdd7-c52d-4b97-b4c9-5f234c8acd99",
    iconSet: "Ionicons",
    iconName: "code-outline"
  },
  {
    uuid: "e6503457-9715-49c2-ac4b-8f54d3fc3164",
    iconSet: "Ionicons",
    iconName: "code-sharp"
  },
  {
    uuid: "e2311ef5-ec5e-4fbf-9e3e-8bf8a01d0d0f",
    iconSet: "Ionicons",
    iconName: "code-slash"
  },
  {
    uuid: "f3821b98-9969-4d0e-be94-86de6636a412",
    iconSet: "Ionicons",
    iconName: "code-slash-outline"
  },
  {
    uuid: "d171fc42-8b56-4351-a725-dedcca62ef7e",
    iconSet: "Ionicons",
    iconName: "code-slash-sharp"
  },
  {
    uuid: "a6ebc264-bb83-403b-a184-f01e240620ce",
    iconSet: "Ionicons",
    iconName: "code-working"
  },
  {
    uuid: "be370743-f510-41a1-bc58-f6418367370d",
    iconSet: "Ionicons",
    iconName: "code-working-outline"
  },
  {
    uuid: "8597d0ba-05ef-4ce8-91a9-fa3edf3a5be4",
    iconSet: "Ionicons",
    iconName: "code-working-sharp"
  },
  {
    uuid: "79dbebd5-2874-493c-b558-b268bf10e762",
    iconSet: "Ionicons",
    iconName: "cog"
  },
  {
    uuid: "a046fb5b-bed1-4f8f-a320-98f81d35e78c",
    iconSet: "Ionicons",
    iconName: "cog-outline"
  },
  {
    uuid: "738ffe0e-b2e6-486c-95de-a3d816eb5db9",
    iconSet: "Ionicons",
    iconName: "cog-sharp"
  },
  {
    uuid: "7d43cb49-5b6c-47b8-abe8-98e9a662e6c0",
    iconSet: "Ionicons",
    iconName: "color-fill"
  },
  {
    uuid: "4f656cb1-a2d8-4cbe-8c87-0fae2e5bcb07",
    iconSet: "Ionicons",
    iconName: "color-fill-outline"
  },
  {
    uuid: "495c8270-e017-4ce3-98f3-6e5cdebcf426",
    iconSet: "Ionicons",
    iconName: "color-fill-sharp"
  },
  {
    uuid: "7ce2d186-29b7-4cf8-ae98-1a28852258f8",
    iconSet: "Ionicons",
    iconName: "color-filter"
  },
  {
    uuid: "1699daf9-3fec-4b8f-9ff5-ccb287fe1dc1",
    iconSet: "Ionicons",
    iconName: "color-filter-outline"
  },
  {
    uuid: "aaa2e5ef-674f-43af-ae83-a0b8db9ed1fb",
    iconSet: "Ionicons",
    iconName: "color-filter-sharp"
  },
  {
    uuid: "27df7c46-32b1-4af8-9735-811828a42153",
    iconSet: "Ionicons",
    iconName: "color-palette"
  },
  {
    uuid: "b15b1bb4-a727-42d1-b108-1d2d493ecefb",
    iconSet: "Ionicons",
    iconName: "color-palette-outline"
  },
  {
    uuid: "9ad51995-1ce8-4a8f-beca-af1184cac2aa",
    iconSet: "Ionicons",
    iconName: "color-palette-sharp"
  },
  {
    uuid: "6b68d9a0-d0d7-4253-b843-a70cd89cd268",
    iconSet: "Ionicons",
    iconName: "color-wand"
  },
  {
    uuid: "bbed8907-b62d-4f35-9b07-719d3a57f083",
    iconSet: "Ionicons",
    iconName: "color-wand-outline"
  },
  {
    uuid: "f19c84c0-1ea9-4d0a-be68-620f1c86f4d6",
    iconSet: "Ionicons",
    iconName: "color-wand-sharp"
  },
  {
    uuid: "df31bcc7-e4c4-47ff-a419-dc5e87e0987c",
    iconSet: "Ionicons",
    iconName: "compass"
  },
  {
    uuid: "ac095f05-efeb-466e-9ba6-97c4891f44b0",
    iconSet: "Ionicons",
    iconName: "compass-outline"
  },
  {
    uuid: "c4d12444-8d89-441c-be1e-39ace3d9d5ba",
    iconSet: "Ionicons",
    iconName: "compass-sharp"
  },
  {
    uuid: "cb46dc08-6349-465a-a49f-1094210092b3",
    iconSet: "Ionicons",
    iconName: "construct"
  },
  {
    uuid: "c909f88c-d981-487d-8824-6848f7c3eae7",
    iconSet: "Ionicons",
    iconName: "construct-outline"
  },
  {
    uuid: "4f0bf6bf-33ee-4915-b546-275151c68cca",
    iconSet: "Ionicons",
    iconName: "construct-sharp"
  },
  {
    uuid: "c831bd49-39e2-443c-93f6-b491466ff29d",
    iconSet: "Ionicons",
    iconName: "contract"
  },
  {
    uuid: "ba181740-d6ac-416b-97a6-9deaf1e47576",
    iconSet: "Ionicons",
    iconName: "contract-outline"
  },
  {
    uuid: "dc1916b5-c449-4c92-b1ad-6345a5f7a8ac",
    iconSet: "Ionicons",
    iconName: "contract-sharp"
  },
  {
    uuid: "0ead6ad6-0815-41dc-ab22-32c48c3cbaaa",
    iconSet: "Ionicons",
    iconName: "contrast"
  },
  {
    uuid: "a03b24e9-226f-4834-935e-4a2b7a9afa8f",
    iconSet: "Ionicons",
    iconName: "contrast-outline"
  },
  {
    uuid: "6a7fe479-8a1f-4dd7-8716-cfe28fffb069",
    iconSet: "Ionicons",
    iconName: "contrast-sharp"
  },
  {
    uuid: "1038aa69-bbb1-4a2f-b6f8-8b27e76e0982",
    iconSet: "Ionicons",
    iconName: "copy"
  },
  {
    uuid: "fe53becb-f83a-49d3-ad1f-16342b13cac8",
    iconSet: "Ionicons",
    iconName: "copy-outline"
  },
  {
    uuid: "11750630-5cef-4a87-8d87-32b1d79021ab",
    iconSet: "Ionicons",
    iconName: "copy-sharp"
  },
  {
    uuid: "0d14525a-e144-4908-a172-0976ccc383ca",
    iconSet: "Ionicons",
    iconName: "create"
  },
  {
    uuid: "f1963d0d-0979-4dc6-8e89-dc2d13b94932",
    iconSet: "Ionicons",
    iconName: "create-outline"
  },
  {
    uuid: "f016e609-f67f-4ea0-b998-bd96b9e981d0",
    iconSet: "Ionicons",
    iconName: "create-sharp"
  },
  {
    uuid: "2344ff1b-b548-4fab-aca2-18a7f17546aa",
    iconSet: "Ionicons",
    iconName: "crop"
  },
  {
    uuid: "e7fcf7fb-2176-4d9d-a778-8e5ef71da083",
    iconSet: "Ionicons",
    iconName: "crop-outline"
  },
  {
    uuid: "304645e1-c3d1-4747-9471-d367e0a98d2c",
    iconSet: "Ionicons",
    iconName: "crop-sharp"
  },
  {
    uuid: "88fd61f8-1fe2-4506-aba5-b4065b82dd72",
    iconSet: "Ionicons",
    iconName: "cube"
  },
  {
    uuid: "3ad27e75-4777-4619-ad9e-0f21b1a0f5d5",
    iconSet: "Ionicons",
    iconName: "cube-outline"
  },
  {
    uuid: "b11e2590-3b92-4eef-a179-92827e0854da",
    iconSet: "Ionicons",
    iconName: "cube-sharp"
  },
  {
    uuid: "db9ec889-c359-43ff-aae0-04c6d4c035d0",
    iconSet: "Ionicons",
    iconName: "cut"
  },
  {
    uuid: "6c0ca626-ec01-4eaa-aef1-5562c4fcb8e0",
    iconSet: "Ionicons",
    iconName: "cut-outline"
  },
  {
    uuid: "be31a2cb-cf5b-4d06-b39e-27adb3a4a34f",
    iconSet: "Ionicons",
    iconName: "cut-sharp"
  },
  {
    uuid: "e1bd2739-4caa-4900-9f84-c7e9055a48b9",
    iconSet: "Ionicons",
    iconName: "desktop"
  },
  {
    uuid: "dbcbd8db-2a91-4df7-93ae-975484948adb",
    iconSet: "Ionicons",
    iconName: "desktop-outline"
  },
  {
    uuid: "53e509c8-562c-4f4b-b1c9-235c9e7ea437",
    iconSet: "Ionicons",
    iconName: "desktop-sharp"
  },
  {
    uuid: "2c3832e4-2dbe-40b5-b192-8dcc77376097",
    iconSet: "Ionicons",
    iconName: "disc"
  },
  {
    uuid: "e4e06c73-2ce3-4fd5-b835-aefd7cf3e33c",
    iconSet: "Ionicons",
    iconName: "disc-outline"
  },
  {
    uuid: "8ccf4a69-c758-4c02-8fb6-954c94795367",
    iconSet: "Ionicons",
    iconName: "disc-sharp"
  },
  {
    uuid: "bdc936ec-b7e9-4e15-83b3-053e0a00171e",
    iconSet: "Ionicons",
    iconName: "document"
  },
  {
    uuid: "5ef50c46-8fc7-45ae-96c2-f8a93799acc4",
    iconSet: "Ionicons",
    iconName: "document-attach"
  },
  {
    uuid: "412159cc-0dd5-41a6-a374-613110c2a577",
    iconSet: "Ionicons",
    iconName: "document-attach-outline"
  },
  {
    uuid: "d6fdf3ec-9870-49db-bcc9-add7b07a3a45",
    iconSet: "Ionicons",
    iconName: "document-attach-sharp"
  },
  {
    uuid: "3ac6b9cf-6ed7-4ed6-9f11-58bf63accab6",
    iconSet: "Ionicons",
    iconName: "document-outline"
  },
  {
    uuid: "cbf3f992-7f35-42fc-a891-a2642274c9f9",
    iconSet: "Ionicons",
    iconName: "document-sharp"
  },
  {
    uuid: "f5669ddf-4daa-4f05-9c31-84173b3da474",
    iconSet: "Ionicons",
    iconName: "document-text"
  },
  {
    uuid: "ebd454df-9383-4c7d-a02f-820f78a8c25f",
    iconSet: "Ionicons",
    iconName: "document-text-outline"
  },
  {
    uuid: "2a7bb9de-0296-4102-ac62-76dd81760e21",
    iconSet: "Ionicons",
    iconName: "document-text-sharp"
  },
  {
    uuid: "1ecfd770-981a-4baa-91b1-80b5861e759a",
    iconSet: "Ionicons",
    iconName: "documents"
  },
  {
    uuid: "1814b037-4a90-454b-a848-3bfe1027441d",
    iconSet: "Ionicons",
    iconName: "documents-outline"
  },
  {
    uuid: "54e032d6-848d-4307-aec4-c8b8aa77518c",
    iconSet: "Ionicons",
    iconName: "documents-sharp"
  },
  {
    uuid: "f55bc92e-5efe-4b88-a4d3-777b22a8ea4b",
    iconSet: "Ionicons",
    iconName: "download"
  },
  {
    uuid: "69b77f8e-177d-479e-acf2-fa07d9ae1094",
    iconSet: "Ionicons",
    iconName: "download-outline"
  },
  {
    uuid: "c99765ae-5266-497d-9675-d3c5cd51e20a",
    iconSet: "Ionicons",
    iconName: "download-sharp"
  },
  {
    uuid: "36c2daef-6b6a-4489-89bf-d15f0fb9d50f",
    iconSet: "Ionicons",
    iconName: "duplicate"
  },
  {
    uuid: "aa243bc2-9429-43fd-a434-55c22da71418",
    iconSet: "Ionicons",
    iconName: "duplicate-outline"
  },
  {
    uuid: "4a90ada3-e555-40ac-a3fb-4b8709eb962d",
    iconSet: "Ionicons",
    iconName: "duplicate-sharp"
  },
  {
    uuid: "b4df30ef-03e2-423f-a1fb-e4d12052ff0b",
    iconSet: "Ionicons",
    iconName: "ear"
  },
  {
    uuid: "8cdf357a-db77-48cc-aece-f70f6c882688",
    iconSet: "Ionicons",
    iconName: "ear-outline"
  },
  {
    uuid: "84b2766b-d673-45fb-b3c7-e306f5d66554",
    iconSet: "Ionicons",
    iconName: "ear-sharp"
  },
  {
    uuid: "8ffe55a8-a8c2-4175-9479-d2c722b2feb8",
    iconSet: "Ionicons",
    iconName: "earth"
  },
  {
    uuid: "7fafa4d1-ab6c-46b4-b5c2-42ce649b99c9",
    iconSet: "Ionicons",
    iconName: "earth-outline"
  },
  {
    uuid: "32cdca30-4290-44e2-bb0a-6274b34a742e",
    iconSet: "Ionicons",
    iconName: "earth-sharp"
  },
  {
    uuid: "2f0e8048-8021-4a6c-88f3-17471baecbf1",
    iconSet: "Ionicons",
    iconName: "easel"
  },
  {
    uuid: "2744a402-634f-4ee5-aa4e-b337452a33b5",
    iconSet: "Ionicons",
    iconName: "easel-outline"
  },
  {
    uuid: "1e0d519a-6f8c-4301-a825-43324fe78e43",
    iconSet: "Ionicons",
    iconName: "easel-sharp"
  },
  {
    uuid: "21104673-be21-4d02-8c9f-65030b2ed1ca",
    iconSet: "Ionicons",
    iconName: "egg"
  },
  {
    uuid: "428d1dbb-b030-409e-9cf7-4c3fdadaf2f7",
    iconSet: "Ionicons",
    iconName: "egg-outline"
  },
  {
    uuid: "3950a95f-ac0c-4634-892f-93a71510e6a3",
    iconSet: "Ionicons",
    iconName: "egg-sharp"
  },
  {
    uuid: "08f83002-c66f-44ee-b60c-9415462ebff8",
    iconSet: "Ionicons",
    iconName: "ellipse"
  },
  {
    uuid: "10943bda-115b-45f7-9aef-ff60a0e23f0d",
    iconSet: "Ionicons",
    iconName: "ellipse-outline"
  },
  {
    uuid: "577377db-75c8-43dc-a3f4-79e4d0c48658",
    iconSet: "Ionicons",
    iconName: "ellipse-sharp"
  },
  {
    uuid: "e1a3aca6-0852-4a94-b5a5-187c772ab9ff",
    iconSet: "Ionicons",
    iconName: "ellipsis-horizontal"
  },
  {
    uuid: "29e698a2-1edf-44f9-bd21-a76dae7cc570",
    iconSet: "Ionicons",
    iconName: "ellipsis-horizontal-circle"
  },
  {
    uuid: "149772d1-f214-4727-9d18-9f60bc3c923b",
    iconSet: "Ionicons",
    iconName: "ellipsis-horizontal-circle-outline"
  },
  {
    uuid: "09a6e7fa-1cf5-4be8-be02-3bc8da9c854e",
    iconSet: "Ionicons",
    iconName: "ellipsis-horizontal-circle-sharp"
  },
  {
    uuid: "25c3071a-b9ab-4cff-a382-e9cdf171fd01",
    iconSet: "Ionicons",
    iconName: "ellipsis-horizontal-outline"
  },
  {
    uuid: "4a86a623-644b-4b02-978e-ece0c6590f34",
    iconSet: "Ionicons",
    iconName: "ellipsis-horizontal-sharp"
  },
  {
    uuid: "16a5af79-2cd8-4daa-a667-46ff89893a35",
    iconSet: "Ionicons",
    iconName: "ellipsis-vertical"
  },
  {
    uuid: "172e2570-c892-4645-8b65-999d99130b72",
    iconSet: "Ionicons",
    iconName: "ellipsis-vertical-circle"
  },
  {
    uuid: "4f3466e2-8c94-4d29-b32f-3f79aa1caca6",
    iconSet: "Ionicons",
    iconName: "ellipsis-vertical-circle-outline"
  },
  {
    uuid: "2169eff4-09f0-4a77-a504-1fa091d0ee08",
    iconSet: "Ionicons",
    iconName: "ellipsis-vertical-circle-sharp"
  },
  {
    uuid: "2000db91-da3a-4d62-a144-dd1964226e57",
    iconSet: "Ionicons",
    iconName: "ellipsis-vertical-outline"
  },
  {
    uuid: "0957641a-36ae-44c4-b9df-3160284c6d60",
    iconSet: "Ionicons",
    iconName: "ellipsis-vertical-sharp"
  },
  {
    uuid: "037da0ac-76c1-4311-b8b3-e034be3bce76",
    iconSet: "Ionicons",
    iconName: "enter"
  },
  {
    uuid: "5c9836e7-12f6-4d7f-85bf-efd42137ce84",
    iconSet: "Ionicons",
    iconName: "enter-outline"
  },
  {
    uuid: "28cdac1b-a0a5-4b30-a9e0-db49c75c7d79",
    iconSet: "Ionicons",
    iconName: "enter-sharp"
  },
  {
    uuid: "d47c9263-d5ae-4796-8378-dc447fa008c9",
    iconSet: "Ionicons",
    iconName: "exit"
  },
  {
    uuid: "22c9812a-156d-426f-bc7a-ab0278940b61",
    iconSet: "Ionicons",
    iconName: "exit-outline"
  },
  {
    uuid: "a8cf6ad0-1e79-49f6-9791-f4724aee711d",
    iconSet: "Ionicons",
    iconName: "exit-sharp"
  },
  {
    uuid: "02fdbb0e-16ab-4267-9170-045a4d5440a6",
    iconSet: "Ionicons",
    iconName: "expand"
  },
  {
    uuid: "e4eb63a5-f7f5-445b-8178-805ee2262d20",
    iconSet: "Ionicons",
    iconName: "expand-outline"
  },
  {
    uuid: "6893d27e-0516-4ec7-8685-d0b062b17ec7",
    iconSet: "Ionicons",
    iconName: "expand-sharp"
  },
  {
    uuid: "b3273cb7-6d53-4b37-ad7a-2d882a4d5951",
    iconSet: "Ionicons",
    iconName: "eye"
  },
  {
    uuid: "867b9519-3ec7-4b9e-a9ee-e8d2afc03874",
    iconSet: "Ionicons",
    iconName: "eye-off"
  },
  {
    uuid: "a274161e-98e9-46e9-bd8f-69c5802f0783",
    iconSet: "Ionicons",
    iconName: "eye-off-outline"
  },
  {
    uuid: "5e44445c-5b73-4a7f-a94e-b20019e3cc56",
    iconSet: "Ionicons",
    iconName: "eye-off-sharp"
  },
  {
    uuid: "710d49fa-7c30-411b-a84d-9e360b6f0b3b",
    iconSet: "Ionicons",
    iconName: "eye-outline"
  },
  {
    uuid: "ce5a9a99-9a4a-4272-9baa-33b6fb8ab81c",
    iconSet: "Ionicons",
    iconName: "eye-sharp"
  },
  {
    uuid: "fb64f76e-14e6-474a-817b-4c798a0b092d",
    iconSet: "Ionicons",
    iconName: "eyedrop"
  },
  {
    uuid: "f8291b60-14f0-43c8-bccd-9cd549bbefd7",
    iconSet: "Ionicons",
    iconName: "eyedrop-outline"
  },
  {
    uuid: "946102db-603e-46fe-83a4-e4cd0465baab",
    iconSet: "Ionicons",
    iconName: "eyedrop-sharp"
  },
  {
    uuid: "6f56e6d8-3d97-4645-a378-42f45da07bd4",
    iconSet: "Ionicons",
    iconName: "fast-food"
  },
  {
    uuid: "1a0459bd-3064-4115-bd5c-3a496ff17918",
    iconSet: "Ionicons",
    iconName: "fast-food-outline"
  },
  {
    uuid: "6c5fc8a5-3012-4d6b-b33b-5da5950b8b48",
    iconSet: "Ionicons",
    iconName: "fast-food-sharp"
  },
  {
    uuid: "66af8243-89d0-43c5-ad22-51931a436f72",
    iconSet: "Ionicons",
    iconName: "female"
  },
  {
    uuid: "c8638f5e-e75e-4967-a1e1-d603e81cefbf",
    iconSet: "Ionicons",
    iconName: "female-outline"
  },
  {
    uuid: "84dfd8cb-906d-4fad-9a50-4d876a0dde13",
    iconSet: "Ionicons",
    iconName: "female-sharp"
  },
  {
    uuid: "08669693-4f6b-4fd2-a987-1ecfa1e8e2af",
    iconSet: "Ionicons",
    iconName: "file-tray"
  },
  {
    uuid: "39ffb8ce-a9a1-4fec-af93-d8b6722ef866",
    iconSet: "Ionicons",
    iconName: "file-tray-full"
  },
  {
    uuid: "071bec5e-d410-4e4a-8cc3-4b1a08b00c2f",
    iconSet: "Ionicons",
    iconName: "file-tray-full-outline"
  },
  {
    uuid: "c42a9fcd-35ed-4df9-9cbb-5e6149db4c20",
    iconSet: "Ionicons",
    iconName: "file-tray-full-sharp"
  },
  {
    uuid: "144d8e71-f37f-4a36-b750-d25319232fa1",
    iconSet: "Ionicons",
    iconName: "file-tray-outline"
  },
  {
    uuid: "e3e139fd-093c-495f-b1e8-665eecb939ca",
    iconSet: "Ionicons",
    iconName: "file-tray-sharp"
  },
  {
    uuid: "c29bacf2-18a9-4ef3-9056-89672dc950b7",
    iconSet: "Ionicons",
    iconName: "file-tray-stacked"
  },
  {
    uuid: "b3663676-61d7-4627-a2c2-d8d607765ca9",
    iconSet: "Ionicons",
    iconName: "file-tray-stacked-outline"
  },
  {
    uuid: "bea875e6-325a-4fc4-8422-d039ef65736b",
    iconSet: "Ionicons",
    iconName: "file-tray-stacked-sharp"
  },
  {
    uuid: "6f1253bc-e44c-4a92-88cc-c28e23e13241",
    iconSet: "Ionicons",
    iconName: "film"
  },
  {
    uuid: "ca79fcd4-545e-4477-a9c3-e409b05ed033",
    iconSet: "Ionicons",
    iconName: "film-outline"
  },
  {
    uuid: "da837bb2-4493-43f9-95c7-f91f3e62d2e7",
    iconSet: "Ionicons",
    iconName: "film-sharp"
  },
  {
    uuid: "2824b907-c52b-42d5-a834-1b9d8cacbdc8",
    iconSet: "Ionicons",
    iconName: "filter"
  },
  {
    uuid: "afe18669-adbe-4d85-8908-3a6f6408fb9a",
    iconSet: "Ionicons",
    iconName: "filter-outline"
  },
  {
    uuid: "a0b08058-5771-4c8a-b4b0-d2189325f70d",
    iconSet: "Ionicons",
    iconName: "filter-sharp"
  },
  {
    uuid: "14256119-bb11-43af-8b03-6ee4ad421ae1",
    iconSet: "Ionicons",
    iconName: "finger-print"
  },
  {
    uuid: "4b32edde-3c74-4232-9717-8ba4a828a203",
    iconSet: "Ionicons",
    iconName: "finger-print-outline"
  },
  {
    uuid: "8796cc70-9e21-4c71-99fd-a89164e2dc9d",
    iconSet: "Ionicons",
    iconName: "finger-print-sharp"
  },
  {
    uuid: "0656aaf2-8525-40f0-b411-48c5ff4d7781",
    iconSet: "Ionicons",
    iconName: "fitness"
  },
  {
    uuid: "89a4fa92-f887-4d55-96c5-efde792775fb",
    iconSet: "Ionicons",
    iconName: "fitness-outline"
  },
  {
    uuid: "52e0ace9-ab8f-4fbe-ad0c-d1338774532d",
    iconSet: "Ionicons",
    iconName: "fitness-sharp"
  },
  {
    uuid: "641fba42-4996-4d2a-9ea9-10bcb7b55952",
    iconSet: "Ionicons",
    iconName: "flag"
  },
  {
    uuid: "56579ee2-872a-413a-81eb-5a2cae82e201",
    iconSet: "Ionicons",
    iconName: "flag-outline"
  },
  {
    uuid: "17fca7c9-aa0b-4671-bbfe-33d6a6cde871",
    iconSet: "Ionicons",
    iconName: "flag-sharp"
  },
  {
    uuid: "6e23c63d-f246-475d-93b9-634b775916ac",
    iconSet: "Ionicons",
    iconName: "flame"
  },
  {
    uuid: "be05fe0c-ce64-4457-94fc-80a3de487bf1",
    iconSet: "Ionicons",
    iconName: "flame-outline"
  },
  {
    uuid: "292641fa-faaa-4392-b659-e3b048e2fcb9",
    iconSet: "Ionicons",
    iconName: "flame-sharp"
  },
  {
    uuid: "89fd88a8-471a-4272-b8be-d9fe170b59cf",
    iconSet: "Ionicons",
    iconName: "flash"
  },
  {
    uuid: "18a5a8bd-2555-4b79-bf8a-59da86553608",
    iconSet: "Ionicons",
    iconName: "flash-off"
  },
  {
    uuid: "20a2a1ac-daf3-4ab2-b626-0e0b545dd871",
    iconSet: "Ionicons",
    iconName: "flash-off-outline"
  },
  {
    uuid: "2cb6fbb7-19b9-457d-95f2-38267f774555",
    iconSet: "Ionicons",
    iconName: "flash-off-sharp"
  },
  {
    uuid: "5b4a5405-3f1e-414d-905e-0032d84da603",
    iconSet: "Ionicons",
    iconName: "flash-outline"
  },
  {
    uuid: "22598207-fce5-4521-b804-cbfeff6a3381",
    iconSet: "Ionicons",
    iconName: "flash-sharp"
  },
  {
    uuid: "e3647ca6-3cc0-48fb-95c7-9d80b9bbee08",
    iconSet: "Ionicons",
    iconName: "flashlight"
  },
  {
    uuid: "deac41a6-aeae-43d4-8234-bc220f617057",
    iconSet: "Ionicons",
    iconName: "flashlight-outline"
  },
  {
    uuid: "c1089dae-e641-4b45-b420-c3cb33c1623f",
    iconSet: "Ionicons",
    iconName: "flashlight-sharp"
  },
  {
    uuid: "c7f448e8-275c-48d0-a6ca-ae0f0d353e21",
    iconSet: "Ionicons",
    iconName: "flask"
  },
  {
    uuid: "993b0439-1f55-432b-a75f-973e745c786f",
    iconSet: "Ionicons",
    iconName: "flask-outline"
  },
  {
    uuid: "66994f36-5d5f-4511-9e97-643a0ccf1c41",
    iconSet: "Ionicons",
    iconName: "flask-sharp"
  },
  {
    uuid: "62283190-28cc-4594-a065-fe253f1ab06f",
    iconSet: "Ionicons",
    iconName: "flower"
  },
  {
    uuid: "3c9c2932-4fa9-448f-9f5a-84f097ed212c",
    iconSet: "Ionicons",
    iconName: "flower-outline"
  },
  {
    uuid: "fd42f891-3d14-45c6-851d-7bd8016925d6",
    iconSet: "Ionicons",
    iconName: "flower-sharp"
  },
  {
    uuid: "cd290a96-9e1d-452f-9837-0b16de18c6cc",
    iconSet: "Ionicons",
    iconName: "folder"
  },
  {
    uuid: "a14ee968-4760-4580-aa25-1234898b83d1",
    iconSet: "Ionicons",
    iconName: "folder-open"
  },
  {
    uuid: "d51ab9d7-dc67-4471-96e1-43152f7312db",
    iconSet: "Ionicons",
    iconName: "folder-open-outline"
  },
  {
    uuid: "75df2a02-83ec-4944-9d50-3d210896b3e6",
    iconSet: "Ionicons",
    iconName: "folder-open-sharp"
  },
  {
    uuid: "09c2f9d9-6985-40c8-a368-055883163086",
    iconSet: "Ionicons",
    iconName: "folder-outline"
  },
  {
    uuid: "a2081095-9da8-4598-aa96-3744fcf50954",
    iconSet: "Ionicons",
    iconName: "folder-sharp"
  },
  {
    uuid: "e2557cdb-6c2d-44c5-afce-5da5194c0e1d",
    iconSet: "Ionicons",
    iconName: "football"
  },
  {
    uuid: "e0d149b5-85c0-4bd0-a677-8b3eee34706d",
    iconSet: "Ionicons",
    iconName: "football-outline"
  },
  {
    uuid: "331e4523-666d-4696-9214-32439ce70878",
    iconSet: "Ionicons",
    iconName: "football-sharp"
  },
  {
    uuid: "c49ba057-2a2c-42b6-8171-596e4c31a94d",
    iconSet: "Ionicons",
    iconName: "funnel"
  },
  {
    uuid: "837afe86-243e-4a5c-9da2-57a5e8de2014",
    iconSet: "Ionicons",
    iconName: "funnel-outline"
  },
  {
    uuid: "60a84f4d-f935-488a-a559-743a87196890",
    iconSet: "Ionicons",
    iconName: "funnel-sharp"
  },
  {
    uuid: "9c0cf64c-ffaa-4201-b779-5e0a6fa07cad",
    iconSet: "Ionicons",
    iconName: "game-controller"
  },
  {
    uuid: "ad9ef7d1-e961-4dab-95e9-22cb968923c4",
    iconSet: "Ionicons",
    iconName: "game-controller-outline"
  },
  {
    uuid: "a127bac9-3101-4e81-b61a-4c69ee768059",
    iconSet: "Ionicons",
    iconName: "game-controller-sharp"
  },
  {
    uuid: "d0cf0cf4-d086-4e0d-b2cc-a837f4f94fed",
    iconSet: "Ionicons",
    iconName: "gift"
  },
  {
    uuid: "a388ad3e-cdb8-4aeb-bf8e-0f800d097b69",
    iconSet: "Ionicons",
    iconName: "gift-outline"
  },
  {
    uuid: "1359136f-47ae-4d1c-a3c1-f2f307ab2938",
    iconSet: "Ionicons",
    iconName: "gift-sharp"
  },
  {
    uuid: "484e8d8a-af67-4ec2-b52e-157d15d4eb85",
    iconSet: "Ionicons",
    iconName: "git-branch"
  },
  {
    uuid: "864d51d7-d898-41c0-b2d7-edc0ffd6acbe",
    iconSet: "Ionicons",
    iconName: "git-branch-outline"
  },
  {
    uuid: "85af34ae-7a79-40be-b170-ffc99f143447",
    iconSet: "Ionicons",
    iconName: "git-branch-sharp"
  },
  {
    uuid: "668c7a71-c8b6-460c-a2f8-0787a67d3e3b",
    iconSet: "Ionicons",
    iconName: "git-commit"
  },
  {
    uuid: "d602ac14-ee5b-489d-93b0-43a0625564ad",
    iconSet: "Ionicons",
    iconName: "git-commit-outline"
  },
  {
    uuid: "7d5faba2-73b2-48b8-b891-48c66393bf51",
    iconSet: "Ionicons",
    iconName: "git-commit-sharp"
  },
  {
    uuid: "87f478bd-1738-48d3-bff4-c5fa8fcac2ed",
    iconSet: "Ionicons",
    iconName: "git-compare"
  },
  {
    uuid: "377bcc44-c322-4fba-8806-5a38ce1e147f",
    iconSet: "Ionicons",
    iconName: "git-compare-outline"
  },
  {
    uuid: "b9c6c37a-bd46-4a0e-9374-d4ca8e8636f3",
    iconSet: "Ionicons",
    iconName: "git-compare-sharp"
  },
  {
    uuid: "27e3341c-ae4e-4a6d-ae07-85e40f3b0936",
    iconSet: "Ionicons",
    iconName: "git-merge"
  },
  {
    uuid: "63247add-dede-400b-8277-75c5b1c56e39",
    iconSet: "Ionicons",
    iconName: "git-merge-outline"
  },
  {
    uuid: "7ef11946-5dfc-47ce-9fad-e1f5132c0c6b",
    iconSet: "Ionicons",
    iconName: "git-merge-sharp"
  },
  {
    uuid: "8b69f737-5c86-4fa0-8ffd-71b36e386fa9",
    iconSet: "Ionicons",
    iconName: "git-network"
  },
  {
    uuid: "10035c61-3101-4c2f-ba8e-d3bc7ee81e23",
    iconSet: "Ionicons",
    iconName: "git-network-outline"
  },
  {
    uuid: "a22662b5-5a44-4165-9577-d5537206d223",
    iconSet: "Ionicons",
    iconName: "git-network-sharp"
  },
  {
    uuid: "5a0be7aa-f574-438b-8128-c92ac78632b7",
    iconSet: "Ionicons",
    iconName: "git-pull-request"
  },
  {
    uuid: "147349f7-79ad-4b3e-84ff-7df5eb25b458",
    iconSet: "Ionicons",
    iconName: "git-pull-request-outline"
  },
  {
    uuid: "10c77224-19f4-490b-965b-1b6af0300162",
    iconSet: "Ionicons",
    iconName: "git-pull-request-sharp"
  },
  {
    uuid: "d812d899-f15b-4ca1-8f7c-f1635a89b085",
    iconSet: "Ionicons",
    iconName: "glasses"
  },
  {
    uuid: "1d0accfe-9bd1-4bb4-a57b-4fcb50e7e3a8",
    iconSet: "Ionicons",
    iconName: "glasses-outline"
  },
  {
    uuid: "48d769d3-a008-44f1-a160-e82d8a0fe1f2",
    iconSet: "Ionicons",
    iconName: "glasses-sharp"
  },
  {
    uuid: "dd15c0fc-ae71-443b-a82a-7ce4c9098121",
    iconSet: "Ionicons",
    iconName: "globe"
  },
  {
    uuid: "85c7d643-4219-4712-b567-07390b24beb0",
    iconSet: "Ionicons",
    iconName: "globe-outline"
  },
  {
    uuid: "5e24bbf4-ef68-4056-b4fb-62c002c74bc5",
    iconSet: "Ionicons",
    iconName: "globe-sharp"
  },
  {
    uuid: "c84df069-7915-49e6-b28a-861e9dea6515",
    iconSet: "Ionicons",
    iconName: "golf"
  },
  {
    uuid: "c1c3c438-8fd4-46cc-afdd-3b058839e34c",
    iconSet: "Ionicons",
    iconName: "golf-outline"
  },
  {
    uuid: "92dd32f4-207e-4a9c-bd49-8227b40733d1",
    iconSet: "Ionicons",
    iconName: "golf-sharp"
  },
  {
    uuid: "281ee460-7b6e-4d5d-ae4a-cc4fb4e7f53a",
    iconSet: "Ionicons",
    iconName: "grid"
  },
  {
    uuid: "8ad0b80f-925f-4ff4-b9a1-5cf48245ff98",
    iconSet: "Ionicons",
    iconName: "grid-outline"
  },
  {
    uuid: "2dba793d-479d-452c-8fc1-d06344bfdb22",
    iconSet: "Ionicons",
    iconName: "grid-sharp"
  },
  {
    uuid: "66b6cf70-b321-4929-a8bf-7342c1f699ab",
    iconSet: "Ionicons",
    iconName: "hammer"
  },
  {
    uuid: "ca3e96bb-7449-4a6c-bed6-a1a3c90fd873",
    iconSet: "Ionicons",
    iconName: "hammer-outline"
  },
  {
    uuid: "9a1edc55-71d5-4d80-a4cf-a6673dcc7ea2",
    iconSet: "Ionicons",
    iconName: "hammer-sharp"
  },
  {
    uuid: "7fd9ce87-5855-49e5-855b-dc4cb1d2e0c8",
    iconSet: "Ionicons",
    iconName: "hand-left"
  },
  {
    uuid: "8fb65838-8369-4600-8cc3-aca04d79eabd",
    iconSet: "Ionicons",
    iconName: "hand-left-outline"
  },
  {
    uuid: "20e743d2-efa7-49b2-83ea-f314898fe000",
    iconSet: "Ionicons",
    iconName: "hand-left-sharp"
  },
  {
    uuid: "b61d6273-2c31-441a-9bfd-486b73c16dcf",
    iconSet: "Ionicons",
    iconName: "hand-right"
  },
  {
    uuid: "300e85a7-6d89-4ab3-a598-a4c81d81c333",
    iconSet: "Ionicons",
    iconName: "hand-right-outline"
  },
  {
    uuid: "8fea2946-1465-41e5-bc49-dc7237d8a357",
    iconSet: "Ionicons",
    iconName: "hand-right-sharp"
  },
  {
    uuid: "9764e125-f532-4e1a-8bcf-d06a88563462",
    iconSet: "Ionicons",
    iconName: "happy"
  },
  {
    uuid: "1c0a30b3-ceb9-47ca-afc6-56b6d7d896ad",
    iconSet: "Ionicons",
    iconName: "happy-outline"
  },
  {
    uuid: "92dbc00e-ee2d-4178-af04-32b146c7bc06",
    iconSet: "Ionicons",
    iconName: "happy-sharp"
  },
  {
    uuid: "fa1e91f7-e612-45e5-8586-d95d9b59645f",
    iconSet: "Ionicons",
    iconName: "hardware-chip"
  },
  {
    uuid: "4297aa52-1e1f-434f-8e11-209cc4512e04",
    iconSet: "Ionicons",
    iconName: "hardware-chip-outline"
  },
  {
    uuid: "f8104fa5-5e22-457c-a9b9-481f71a27d10",
    iconSet: "Ionicons",
    iconName: "hardware-chip-sharp"
  },
  {
    uuid: "4dfd2eaa-cd5f-49e0-9d0d-80706ed3b021",
    iconSet: "Ionicons",
    iconName: "headset"
  },
  {
    uuid: "7885826a-f6a4-4cb7-85fa-845f02bc8e63",
    iconSet: "Ionicons",
    iconName: "headset-outline"
  },
  {
    uuid: "e6800671-559e-46c4-ba7b-e01fc8c71b57",
    iconSet: "Ionicons",
    iconName: "headset-sharp"
  },
  {
    uuid: "d6ee42d0-92a6-4985-9498-e742651b7593",
    iconSet: "Ionicons",
    iconName: "heart"
  },
  {
    uuid: "4a215de9-c8a6-44b3-8197-51664c621fd1",
    iconSet: "Ionicons",
    iconName: "heart-circle"
  },
  {
    uuid: "746db477-980c-4b2f-a475-63752f80ad19",
    iconSet: "Ionicons",
    iconName: "heart-circle-outline"
  },
  {
    uuid: "af16a9f8-f011-436d-a185-452da5779e5d",
    iconSet: "Ionicons",
    iconName: "heart-circle-sharp"
  },
  {
    uuid: "0c62c9df-be19-41be-9289-3081f4fbc49e",
    iconSet: "Ionicons",
    iconName: "heart-dislike"
  },
  {
    uuid: "6d6a9495-9ec7-44e7-ba8c-eb1662f2346f",
    iconSet: "Ionicons",
    iconName: "heart-dislike-circle"
  },
  {
    uuid: "d6722f7f-ee4a-4778-ac82-fe43a9aa83a9",
    iconSet: "Ionicons",
    iconName: "heart-dislike-circle-outline"
  },
  {
    uuid: "10886977-10de-49b9-a848-45c56b0e2ac3",
    iconSet: "Ionicons",
    iconName: "heart-dislike-circle-sharp"
  },
  {
    uuid: "1793a598-baf1-4f60-b9f1-16bbe70a05d1",
    iconSet: "Ionicons",
    iconName: "heart-dislike-outline"
  },
  {
    uuid: "b66fd981-c746-4789-9f9c-3f52661af77d",
    iconSet: "Ionicons",
    iconName: "heart-dislike-sharp"
  },
  {
    uuid: "3a7beb6c-b2df-4f12-ab35-9807da9a40ff",
    iconSet: "Ionicons",
    iconName: "heart-half"
  },
  {
    uuid: "339f8b98-c11b-4e9c-9ee1-fd24c8d0e2b4",
    iconSet: "Ionicons",
    iconName: "heart-half-outline"
  },
  {
    uuid: "3f97cfc9-4dab-4bda-bdd8-1d9f2220fae8",
    iconSet: "Ionicons",
    iconName: "heart-half-sharp"
  },
  {
    uuid: "24d9da6b-a878-4f12-a493-ace7f49db121",
    iconSet: "Ionicons",
    iconName: "heart-outline"
  },
  {
    uuid: "5d5e383b-8045-42b9-a775-1f7201e8e1bc",
    iconSet: "Ionicons",
    iconName: "heart-sharp"
  },
  {
    uuid: "81e6276f-7599-4a49-8e3b-9510890c5fef",
    iconSet: "Ionicons",
    iconName: "help"
  },
  {
    uuid: "e22c066b-0701-41c1-b1da-8f19165bb1a1",
    iconSet: "Ionicons",
    iconName: "help-buoy"
  },
  {
    uuid: "0ad5d0df-9bd1-4dca-ada1-7aa49c2f0ea9",
    iconSet: "Ionicons",
    iconName: "help-buoy-outline"
  },
  {
    uuid: "70949d7a-2d33-4246-a199-7b50b59555bf",
    iconSet: "Ionicons",
    iconName: "help-buoy-sharp"
  },
  {
    uuid: "9a278a8d-e16d-47b2-9f66-54c29e2c6a30",
    iconSet: "Ionicons",
    iconName: "help-circle"
  },
  {
    uuid: "06098657-e23f-42de-a94e-9272c7e0a9e3",
    iconSet: "Ionicons",
    iconName: "help-circle-outline"
  },
  {
    uuid: "6cff8d6e-f190-4d93-8ee5-96bf1bf69621",
    iconSet: "Ionicons",
    iconName: "help-circle-sharp"
  },
  {
    uuid: "6769d95c-2a18-4bfb-9a7d-b306e408d58e",
    iconSet: "Ionicons",
    iconName: "help-outline"
  },
  {
    uuid: "a1b8918c-4120-48a8-99c2-eb44259c5270",
    iconSet: "Ionicons",
    iconName: "help-sharp"
  },
  {
    uuid: "69257a6b-94c2-429d-9f74-064dc013770c",
    iconSet: "Ionicons",
    iconName: "home"
  },
  {
    uuid: "f14376b1-ad90-4ef4-841a-6a75261067b6",
    iconSet: "Ionicons",
    iconName: "home-outline"
  },
  {
    uuid: "427e8322-adb1-4245-830b-21e887988e38",
    iconSet: "Ionicons",
    iconName: "home-sharp"
  },
  {
    uuid: "73c0b321-04ca-4f6f-acf9-e39aa7be87ee",
    iconSet: "Ionicons",
    iconName: "hourglass"
  },
  {
    uuid: "dc2f1b68-065e-4d34-a3b0-c220a8f1acd6",
    iconSet: "Ionicons",
    iconName: "hourglass-outline"
  },
  {
    uuid: "940d8a67-0529-4832-8898-d99af10c4e25",
    iconSet: "Ionicons",
    iconName: "hourglass-sharp"
  },
  {
    uuid: "32a07774-99ca-4b85-8b88-b249e1de9a2e",
    iconSet: "Ionicons",
    iconName: "ice-cream"
  },
  {
    uuid: "f60d0d47-da2f-4fcc-9b13-21c6f14bc2a8",
    iconSet: "Ionicons",
    iconName: "ice-cream-outline"
  },
  {
    uuid: "005b9ef0-8714-4b10-ba25-fd12ed44d2d5",
    iconSet: "Ionicons",
    iconName: "ice-cream-sharp"
  },
  {
    uuid: "98168e18-18e0-4c87-aec5-e61de89aabb1",
    iconSet: "Ionicons",
    iconName: "image"
  },
  {
    uuid: "3f9edae5-648c-411f-923f-4c50e203ab8f",
    iconSet: "Ionicons",
    iconName: "image-outline"
  },
  {
    uuid: "7b5055ff-8f1c-4cf7-b984-5c856c701b50",
    iconSet: "Ionicons",
    iconName: "image-sharp"
  },
  {
    uuid: "63f1c2a3-3e7f-42f1-99e1-03ee158e70e9",
    iconSet: "Ionicons",
    iconName: "images"
  },
  {
    uuid: "d8fdb7e8-1f29-4402-868e-e8ae6dca5a07",
    iconSet: "Ionicons",
    iconName: "images-outline"
  },
  {
    uuid: "afb67e87-f1a3-4bfb-9022-6a3147b78f8b",
    iconSet: "Ionicons",
    iconName: "images-sharp"
  },
  {
    uuid: "83d806da-ce33-420c-b475-b25974a85519",
    iconSet: "Ionicons",
    iconName: "infinite"
  },
  {
    uuid: "cda14963-b9ab-42c2-a8dc-cefdc422612d",
    iconSet: "Ionicons",
    iconName: "infinite-outline"
  },
  {
    uuid: "2251462f-c0eb-46ee-911b-2d626c2bf4b1",
    iconSet: "Ionicons",
    iconName: "infinite-sharp"
  },
  {
    uuid: "a2f0d90f-1c2f-44e0-8975-d7ef67884ed1",
    iconSet: "Ionicons",
    iconName: "information"
  },
  {
    uuid: "34f5c3ff-e03a-4371-9e3d-54dbb9dd872e",
    iconSet: "Ionicons",
    iconName: "information-circle"
  },
  {
    uuid: "0b547fa8-cd8d-4273-97ff-7f0eb26a2dac",
    iconSet: "Ionicons",
    iconName: "information-circle-outline"
  },
  {
    uuid: "5bf070ed-d523-4edc-b4de-c091010aa2df",
    iconSet: "Ionicons",
    iconName: "information-circle-sharp"
  },
  {
    uuid: "4b5ec215-b050-4f50-8595-ba78dfa3f0b2",
    iconSet: "Ionicons",
    iconName: "information-outline"
  },
  {
    uuid: "6fd220aa-62db-4130-afb9-f9c4d0c46224",
    iconSet: "Ionicons",
    iconName: "information-sharp"
  },
  {
    uuid: "06a82e39-b18a-482b-b7a9-1aadb6861d11",
    iconSet: "Ionicons",
    iconName: "journal"
  },
  {
    uuid: "c6a4be35-f3df-48a2-8d9e-963ae56feedd",
    iconSet: "Ionicons",
    iconName: "journal-outline"
  },
  {
    uuid: "58143428-6165-445c-9c2c-a2648bd2f27b",
    iconSet: "Ionicons",
    iconName: "journal-sharp"
  },
  {
    uuid: "5793ed4c-d89f-4ca1-8b45-7096f2799c29",
    iconSet: "Ionicons",
    iconName: "key"
  },
  {
    uuid: "6dbfac21-1acb-483b-b463-aa28b773d1b8",
    iconSet: "Ionicons",
    iconName: "key-outline"
  },
  {
    uuid: "3193937c-58d8-441f-8a21-2a40b1efd1d8",
    iconSet: "Ionicons",
    iconName: "key-sharp"
  },
  {
    uuid: "c3c6be0d-0840-4694-810b-b4dc65081b59",
    iconSet: "Ionicons",
    iconName: "keypad"
  },
  {
    uuid: "0103d5dd-f4e9-4f49-8561-5abfc319c3a7",
    iconSet: "Ionicons",
    iconName: "keypad-outline"
  },
  {
    uuid: "c831a514-3222-4a28-887f-b8123b7a8a68",
    iconSet: "Ionicons",
    iconName: "keypad-sharp"
  },
  {
    uuid: "6daecf4a-a3c2-45d5-b75f-cef2ee214123",
    iconSet: "Ionicons",
    iconName: "language"
  },
  {
    uuid: "bd60c5b6-3457-4fbf-8f7a-79b1ec4cba34",
    iconSet: "Ionicons",
    iconName: "language-outline"
  },
  {
    uuid: "9f49f76c-9e70-4ed5-8b98-dc0e61a37263",
    iconSet: "Ionicons",
    iconName: "language-sharp"
  },
  {
    uuid: "5aecc5b0-3d7f-4a60-b318-4b64c9d4d996",
    iconSet: "Ionicons",
    iconName: "laptop"
  },
  {
    uuid: "e3d5e765-c119-49cc-9376-1c49ed75e75a",
    iconSet: "Ionicons",
    iconName: "laptop-outline"
  },
  {
    uuid: "5c386882-5d14-4409-9a2a-f9c6a312d8d9",
    iconSet: "Ionicons",
    iconName: "laptop-sharp"
  },
  {
    uuid: "f85e0ad6-b671-4b26-8d5c-508f2befa188",
    iconSet: "Ionicons",
    iconName: "layers"
  },
  {
    uuid: "4b9ac87f-7dbb-482c-b76f-66ce23b1c6e4",
    iconSet: "Ionicons",
    iconName: "layers-outline"
  },
  {
    uuid: "46cd2889-7343-4d3b-b90c-099a2cb2eb1c",
    iconSet: "Ionicons",
    iconName: "layers-sharp"
  },
  {
    uuid: "d61d81f8-4831-4821-91bc-cf5f523e3f9e",
    iconSet: "Ionicons",
    iconName: "leaf"
  },
  {
    uuid: "41ed9a6c-cb0c-432e-875a-d4cb7a4bfc53",
    iconSet: "Ionicons",
    iconName: "leaf-outline"
  },
  {
    uuid: "4a45e5c9-8900-426e-b0af-c4de242fc44b",
    iconSet: "Ionicons",
    iconName: "leaf-sharp"
  },
  {
    uuid: "3424f2cc-a93c-48f6-aa47-4878dc543492",
    iconSet: "Ionicons",
    iconName: "library"
  },
  {
    uuid: "c5656370-3157-49e5-a41d-ce6b21b3535c",
    iconSet: "Ionicons",
    iconName: "library-outline"
  },
  {
    uuid: "054aa187-bdce-493f-954f-618946128f2a",
    iconSet: "Ionicons",
    iconName: "library-sharp"
  },
  {
    uuid: "3a69b00e-5106-4847-9d7d-e8c03c90cb75",
    iconSet: "Ionicons",
    iconName: "link"
  },
  {
    uuid: "e0bcaecc-217b-4d1a-b9f3-14c50adc6b9a",
    iconSet: "Ionicons",
    iconName: "link-outline"
  },
  {
    uuid: "3efa05da-793c-4c55-9ce0-8356676f9642",
    iconSet: "Ionicons",
    iconName: "link-sharp"
  },
  {
    uuid: "a34207a7-3e19-4e94-aa8a-7958608e3060",
    iconSet: "Ionicons",
    iconName: "list"
  },
  {
    uuid: "29d013e1-30fa-4cf0-b804-29bbd8e8e720",
    iconSet: "Ionicons",
    iconName: "list-circle"
  },
  {
    uuid: "d7412606-1c37-4fea-b4d9-0a912711ce26",
    iconSet: "Ionicons",
    iconName: "list-circle-outline"
  },
  {
    uuid: "df48a85a-619c-4379-a042-ecea12861ed7",
    iconSet: "Ionicons",
    iconName: "list-circle-sharp"
  },
  {
    uuid: "3c500688-26ad-48aa-aaf7-c74c407ea9e5",
    iconSet: "Ionicons",
    iconName: "list-outline"
  },
  {
    uuid: "df9ff1a7-b20d-409a-883b-63abe8b9b723",
    iconSet: "Ionicons",
    iconName: "list-sharp"
  },
  {
    uuid: "d0009ad8-97af-4f6a-b160-891743f2a871",
    iconSet: "Ionicons",
    iconName: "locate"
  },
  {
    uuid: "58f5d8ff-f27a-467f-b9f0-5391d158de0d",
    iconSet: "Ionicons",
    iconName: "locate-outline"
  },
  {
    uuid: "ee5f394c-88e5-490d-a1c1-fb2940932614",
    iconSet: "Ionicons",
    iconName: "locate-sharp"
  },
  {
    uuid: "f4059b4c-00f3-44a4-ba35-dd98e4475dd4",
    iconSet: "Ionicons",
    iconName: "location"
  },
  {
    uuid: "aa840401-13b6-4646-850d-ed76276cb6fb",
    iconSet: "Ionicons",
    iconName: "location-outline"
  },
  {
    uuid: "4204511e-726b-4602-b3c7-dcbd8c840f76",
    iconSet: "Ionicons",
    iconName: "location-sharp"
  },
  {
    uuid: "c3c882a7-66b1-4e52-a790-fd931d2a9945",
    iconSet: "Ionicons",
    iconName: "lock-closed"
  },
  {
    uuid: "28326038-6d2b-46cb-b776-3dd9b0a3ed74",
    iconSet: "Ionicons",
    iconName: "lock-closed-outline"
  },
  {
    uuid: "9fcb3c50-4501-4597-83ec-8be2984ad6d0",
    iconSet: "Ionicons",
    iconName: "lock-closed-sharp"
  },
  {
    uuid: "1955e03c-6944-424c-8fa0-4ad608f2dc2b",
    iconSet: "Ionicons",
    iconName: "lock-open"
  },
  {
    uuid: "1c11c67b-be84-4067-9d92-aabb3a8b7b1a",
    iconSet: "Ionicons",
    iconName: "lock-open-outline"
  },
  {
    uuid: "97634a8b-5499-4b54-bf06-59dfb372230b",
    iconSet: "Ionicons",
    iconName: "lock-open-sharp"
  },
  {
    uuid: "28e368b4-32a6-4ba7-9083-da4b5eda4058",
    iconSet: "Ionicons",
    iconName: "log-in"
  },
  {
    uuid: "8d720357-3ef4-48b1-bcf0-0d169494dd3c",
    iconSet: "Ionicons",
    iconName: "log-in-outline"
  },
  {
    uuid: "efa9dd1b-4851-4679-8db4-df8915fa8bed",
    iconSet: "Ionicons",
    iconName: "log-in-sharp"
  },
  {
    uuid: "2c6d7eec-4c9a-4e0e-8747-ff87858baf30",
    iconSet: "Ionicons",
    iconName: "log-out"
  },
  {
    uuid: "410dd7ac-cf7f-4bd2-93fa-92c8da62a5c6",
    iconSet: "Ionicons",
    iconName: "log-out-outline"
  },
  {
    uuid: "dc782054-ba97-4207-8fc9-815c2f71344d",
    iconSet: "Ionicons",
    iconName: "log-out-sharp"
  },
  {
    uuid: "08fa7a6e-e8fb-4983-b11b-55f3e22c731e",
    iconSet: "Ionicons",
    iconName: "logo-amazon"
  },
  {
    uuid: "dc670861-b182-4ad0-9cd0-f5918904b4e5",
    iconSet: "Ionicons",
    iconName: "logo-amplify"
  },
  {
    uuid: "bbfc1e24-2614-4dd3-86c2-3a4b2a24db4d",
    iconSet: "Ionicons",
    iconName: "logo-android"
  },
  {
    uuid: "4eb0556a-3c23-4705-8dfd-77a9998ebe05",
    iconSet: "Ionicons",
    iconName: "logo-angular"
  },
  {
    uuid: "8a70248a-64c5-4d48-9540-896e36e8a17c",
    iconSet: "Ionicons",
    iconName: "logo-apple"
  },
  {
    uuid: "9bb540b8-8b69-4a80-8448-38813c799461",
    iconSet: "Ionicons",
    iconName: "logo-apple-appstore"
  },
  {
    uuid: "3f8afe2d-4ccd-4762-9b80-41b4d72e554e",
    iconSet: "Ionicons",
    iconName: "logo-bitbucket"
  },
  {
    uuid: "4eb0e555-7ab2-4266-a76f-029c2c593a44",
    iconSet: "Ionicons",
    iconName: "logo-bitcoin"
  },
  {
    uuid: "fc54f8ad-6986-45e4-81f0-667492027c6e",
    iconSet: "Ionicons",
    iconName: "logo-buffer"
  },
  {
    uuid: "15b8363b-f695-4af8-b7f3-3a4e4de9881f",
    iconSet: "Ionicons",
    iconName: "logo-capacitor"
  },
  {
    uuid: "95413efe-7e7b-4bf3-8f7a-3208a4798210",
    iconSet: "Ionicons",
    iconName: "logo-chrome"
  },
  {
    uuid: "cd357e32-3355-4f05-ad3b-1a81c996f38a",
    iconSet: "Ionicons",
    iconName: "logo-closed-captioning"
  },
  {
    uuid: "84ee7172-326a-45f4-8ea2-f3ac18d641f3",
    iconSet: "Ionicons",
    iconName: "logo-codepen"
  },
  {
    uuid: "a283e6ff-27bf-4683-8be4-d119feb591f1",
    iconSet: "Ionicons",
    iconName: "logo-css3"
  },
  {
    uuid: "148ca52f-3ee4-47f6-a559-84caba45a81a",
    iconSet: "Ionicons",
    iconName: "logo-designernews"
  },
  {
    uuid: "f3e53249-acc7-473e-a101-c416b37d32c7",
    iconSet: "Ionicons",
    iconName: "logo-dribbble"
  },
  {
    uuid: "43073b6c-fd7d-431d-ada4-26db5ba6f891",
    iconSet: "Ionicons",
    iconName: "logo-dropbox"
  },
  {
    uuid: "86d04307-a1a2-406d-a207-db8bbe0c4e13",
    iconSet: "Ionicons",
    iconName: "logo-edge"
  },
  {
    uuid: "cc9e93d4-c1fe-4c9f-9f34-54f34d2dfad7",
    iconSet: "Ionicons",
    iconName: "logo-electron"
  },
  {
    uuid: "0c4e34d7-549d-4088-a136-f2a890456b92",
    iconSet: "Ionicons",
    iconName: "logo-euro"
  },
  {
    uuid: "4ab0a931-efae-4e11-9177-00f2a9cf5291",
    iconSet: "Ionicons",
    iconName: "logo-facebook"
  },
  {
    uuid: "8535faf4-89c7-4a78-bce1-60de1d42baaa",
    iconSet: "Ionicons",
    iconName: "logo-firebase"
  },
  {
    uuid: "a7cbfd70-c492-487e-b025-f8c64437c706",
    iconSet: "Ionicons",
    iconName: "logo-firefox"
  },
  {
    uuid: "47368391-ef01-4a60-a3d5-8cddca2711ed",
    iconSet: "Ionicons",
    iconName: "logo-flickr"
  },
  {
    uuid: "8cf4f358-d4d8-4834-afcd-37df30907444",
    iconSet: "Ionicons",
    iconName: "logo-foursquare"
  },
  {
    uuid: "b708d2cc-e0c4-48c1-8690-e204edf863e0",
    iconSet: "Ionicons",
    iconName: "logo-github"
  },
  {
    uuid: "7a24b0e0-3950-4bd5-8307-9610774b11df",
    iconSet: "Ionicons",
    iconName: "logo-google"
  },
  {
    uuid: "b6eba96d-7cf0-4538-8ff2-56e8982dbb10",
    iconSet: "Ionicons",
    iconName: "logo-google-playstore"
  },
  {
    uuid: "50c2592c-9150-4eec-8aee-c79f7de7d36b",
    iconSet: "Ionicons",
    iconName: "logo-hackernews"
  },
  {
    uuid: "ffd2d4dd-c267-4dd0-9770-b60a863aee07",
    iconSet: "Ionicons",
    iconName: "logo-html5"
  },
  {
    uuid: "7ab8bb95-0063-4c8e-a8cc-38a910603f19",
    iconSet: "Ionicons",
    iconName: "logo-instagram"
  },
  {
    uuid: "1976c144-f3a3-4497-9a88-e58031dbb49a",
    iconSet: "Ionicons",
    iconName: "logo-ionic"
  },
  {
    uuid: "a5ecdfcd-0ab2-419d-af9b-afc6b0037e25",
    iconSet: "Ionicons",
    iconName: "logo-ionitron"
  },
  {
    uuid: "848314c4-31c6-436e-92dd-f34b64eeac86",
    iconSet: "Ionicons",
    iconName: "logo-javascript"
  },
  {
    uuid: "ea958b7c-6a40-4379-a52b-57b8e3717e54",
    iconSet: "Ionicons",
    iconName: "logo-laravel"
  },
  {
    uuid: "c16aa708-b37a-48be-a3ae-88c8089bbe0c",
    iconSet: "Ionicons",
    iconName: "logo-linkedin"
  },
  {
    uuid: "0a750815-a8bd-4891-a5eb-f4e0de68e4dc",
    iconSet: "Ionicons",
    iconName: "logo-markdown"
  },
  {
    uuid: "7512be2f-8818-43cf-b379-cf0e54686b13",
    iconSet: "Ionicons",
    iconName: "logo-no-smoking"
  },
  {
    uuid: "f053cf84-8b0f-44e0-a76e-a5bb852c7146",
    iconSet: "Ionicons",
    iconName: "logo-nodejs"
  },
  {
    uuid: "3cb469ec-df52-4b14-b7ab-14ae8705728a",
    iconSet: "Ionicons",
    iconName: "logo-npm"
  },
  {
    uuid: "348d0082-8d93-41ed-95f0-dbaf5403ff8d",
    iconSet: "Ionicons",
    iconName: "logo-octocat"
  },
  {
    uuid: "b816ee18-3333-47c9-acd0-745ea7a415b5",
    iconSet: "Ionicons",
    iconName: "logo-pinterest"
  },
  {
    uuid: "8b943059-69b0-4efe-8302-5189aa9b9724",
    iconSet: "Ionicons",
    iconName: "logo-playstation"
  },
  {
    uuid: "a03320ff-81a2-4254-bd4f-b73273ef7874",
    iconSet: "Ionicons",
    iconName: "logo-pwa"
  },
  {
    uuid: "3c824fd9-557c-4580-846b-adb71b887167",
    iconSet: "Ionicons",
    iconName: "logo-python"
  },
  {
    uuid: "d2fbcb41-d746-4c79-a850-5664968d5151",
    iconSet: "Ionicons",
    iconName: "logo-react"
  },
  {
    uuid: "6ef81596-6691-4d27-b6c8-0d87f9926b89",
    iconSet: "Ionicons",
    iconName: "logo-reddit"
  },
  {
    uuid: "7486d4f2-4f41-4dfd-9988-b3c6863e8db0",
    iconSet: "Ionicons",
    iconName: "logo-rss"
  },
  {
    uuid: "7f4abfcb-d314-4a5a-aed1-63fdbfc99b03",
    iconSet: "Ionicons",
    iconName: "logo-sass"
  },
  {
    uuid: "5a7562fb-badf-438d-9c40-9e602b10c858",
    iconSet: "Ionicons",
    iconName: "logo-skype"
  },
  {
    uuid: "be163fff-ce38-4144-84b7-478fcd3d16cc",
    iconSet: "Ionicons",
    iconName: "logo-slack"
  },
  {
    uuid: "97644d0e-6023-4f55-b481-c7bf8b36f1a4",
    iconSet: "Ionicons",
    iconName: "logo-snapchat"
  },
  {
    uuid: "25d12380-2b85-41ee-9801-e93afed10b10",
    iconSet: "Ionicons",
    iconName: "logo-stackoverflow"
  },
  {
    uuid: "d2672d11-bf75-4d41-99c4-35066560cded",
    iconSet: "Ionicons",
    iconName: "logo-steam"
  },
  {
    uuid: "87f5ddc6-bcaa-4754-905e-ab7acc7d32dc",
    iconSet: "Ionicons",
    iconName: "logo-stencil"
  },
  {
    uuid: "0a7010f0-5408-4d3e-938e-01343dacc4f6",
    iconSet: "Ionicons",
    iconName: "logo-tumblr"
  },
  {
    uuid: "1bb9beaa-3ce4-469d-a465-21b114a09a99",
    iconSet: "Ionicons",
    iconName: "logo-tux"
  },
  {
    uuid: "4b260132-22d5-481f-9ebd-c92eca3b0a66",
    iconSet: "Ionicons",
    iconName: "logo-twitch"
  },
  {
    uuid: "c29366e5-cdeb-40eb-a71f-a6b8e1e14125",
    iconSet: "Ionicons",
    iconName: "logo-twitter"
  },
  {
    uuid: "aa42834d-4c11-4072-97ab-7bafe3cffbdf",
    iconSet: "Ionicons",
    iconName: "logo-usd"
  },
  {
    uuid: "433bff33-373e-4187-ad4f-02c573e34519",
    iconSet: "Ionicons",
    iconName: "logo-vimeo"
  },
  {
    uuid: "f4656ddb-1af7-46e2-8fda-2ae199640b46",
    iconSet: "Ionicons",
    iconName: "logo-vk"
  },
  {
    uuid: "a9a59833-95c1-4351-859d-e810bb2ba6a9",
    iconSet: "Ionicons",
    iconName: "logo-vue"
  },
  {
    uuid: "a162bb4f-be3b-4c6c-a7fe-2b78cddd5c28",
    iconSet: "Ionicons",
    iconName: "logo-web-component"
  },
  {
    uuid: "da9ed8aa-7897-4c7d-9c9e-58f4ac7f0fef",
    iconSet: "Ionicons",
    iconName: "logo-whatsapp"
  },
  {
    uuid: "81cda52f-e177-4217-ab77-f253418e1518",
    iconSet: "Ionicons",
    iconName: "logo-windows"
  },
  {
    uuid: "4f82587a-d1ac-4da7-b3af-61d1608e1cac",
    iconSet: "Ionicons",
    iconName: "logo-wordpress"
  },
  {
    uuid: "bcf0d0a1-18e2-4db0-a720-9897ff7c4564",
    iconSet: "Ionicons",
    iconName: "logo-xbox"
  },
  {
    uuid: "53f6c0c1-60c3-4785-adc8-89037001e7a1",
    iconSet: "Ionicons",
    iconName: "logo-xing"
  },
  {
    uuid: "c001060d-7074-40dd-809a-0bf7ea4c68ac",
    iconSet: "Ionicons",
    iconName: "logo-yahoo"
  },
  {
    uuid: "69fa5cde-388a-42cc-9bd4-365850c0d1af",
    iconSet: "Ionicons",
    iconName: "logo-yen"
  },
  {
    uuid: "8091f011-bf6a-4891-8cb3-65e8a123a41a",
    iconSet: "Ionicons",
    iconName: "logo-youtube"
  },
  {
    uuid: "41706e4a-a93c-48f1-b017-895178f4cf83",
    iconSet: "Ionicons",
    iconName: "magnet"
  },
  {
    uuid: "6e6556bc-e863-47ec-9c84-0be057d07974",
    iconSet: "Ionicons",
    iconName: "magnet-outline"
  },
  {
    uuid: "d6c33cde-4670-4e7e-86c2-003f6c37ddec",
    iconSet: "Ionicons",
    iconName: "magnet-sharp"
  },
  {
    uuid: "c132a045-180e-4404-9359-6d66e2a58cdc",
    iconSet: "Ionicons",
    iconName: "mail"
  },
  {
    uuid: "9b185308-a1ae-436e-a21c-f9b1a3f6316b",
    iconSet: "Ionicons",
    iconName: "mail-open"
  },
  {
    uuid: "b359eecc-f603-4362-8916-959e4a9f6256",
    iconSet: "Ionicons",
    iconName: "mail-open-outline"
  },
  {
    uuid: "bc491814-cec0-44de-aed6-c44c40d480c3",
    iconSet: "Ionicons",
    iconName: "mail-open-sharp"
  },
  {
    uuid: "f61abb5c-c261-4b75-96ca-8b98549f8055",
    iconSet: "Ionicons",
    iconName: "mail-outline"
  },
  {
    uuid: "d8d811a0-6df2-41ef-b34d-c6c6d897ec30",
    iconSet: "Ionicons",
    iconName: "mail-sharp"
  },
  {
    uuid: "d4c7a7ad-3cda-4953-87c1-d5701a103264",
    iconSet: "Ionicons",
    iconName: "mail-unread"
  },
  {
    uuid: "914d5457-caa5-43ff-9213-47c0f91db6a8",
    iconSet: "Ionicons",
    iconName: "mail-unread-outline"
  },
  {
    uuid: "302c4cc8-88e6-4692-9ea1-3893c13be167",
    iconSet: "Ionicons",
    iconName: "mail-unread-sharp"
  },
  {
    uuid: "9855e603-72cf-430d-b329-ea8125783441",
    iconSet: "Ionicons",
    iconName: "male"
  },
  {
    uuid: "389c42bc-8d54-4238-b037-37953b1b7a50",
    iconSet: "Ionicons",
    iconName: "male-female"
  },
  {
    uuid: "998805e4-d642-4f3c-9c69-9ae25d97ef66",
    iconSet: "Ionicons",
    iconName: "male-female-outline"
  },
  {
    uuid: "26b1609f-225d-46fc-975a-77c121bbc2c0",
    iconSet: "Ionicons",
    iconName: "male-female-sharp"
  },
  {
    uuid: "070bc1b5-fdc3-4560-b16d-1796d9e1fe5f",
    iconSet: "Ionicons",
    iconName: "male-outline"
  },
  {
    uuid: "04cc9f1b-9b01-4d90-b8fe-f31fee63dc56",
    iconSet: "Ionicons",
    iconName: "male-sharp"
  },
  {
    uuid: "b25e1bec-5132-4547-b13c-234d352422ae",
    iconSet: "Ionicons",
    iconName: "man"
  },
  {
    uuid: "3c84504b-95d4-4714-b197-fe1873d3cf31",
    iconSet: "Ionicons",
    iconName: "man-outline"
  },
  {
    uuid: "34fac6b8-4647-4cac-8151-bc4eb544d194",
    iconSet: "Ionicons",
    iconName: "man-sharp"
  },
  {
    uuid: "c41b100a-cc7b-400f-9ee1-2f08523b930e",
    iconSet: "Ionicons",
    iconName: "map"
  },
  {
    uuid: "a1b5fb40-6719-4ff2-b920-a96f0562de7c",
    iconSet: "Ionicons",
    iconName: "map-outline"
  },
  {
    uuid: "f7eea7ff-1cb9-4697-9180-72b283025880",
    iconSet: "Ionicons",
    iconName: "map-sharp"
  },
  {
    uuid: "800a7c50-cd89-41d1-b290-b3c08444cf90",
    iconSet: "Ionicons",
    iconName: "medal"
  },
  {
    uuid: "74ae1cf3-66cb-4543-af24-3169c3179c1b",
    iconSet: "Ionicons",
    iconName: "medal-outline"
  },
  {
    uuid: "038fd2f0-0108-4713-a260-4a45a8c2ba6f",
    iconSet: "Ionicons",
    iconName: "medal-sharp"
  },
  {
    uuid: "772bcb0f-7dac-4f12-80a5-bbb8e005c71c",
    iconSet: "Ionicons",
    iconName: "medical"
  },
  {
    uuid: "956f42ea-dfc6-4963-ba1a-2a79190f7457",
    iconSet: "Ionicons",
    iconName: "medical-outline"
  },
  {
    uuid: "1b412152-0678-4804-97a7-5f9b50eaa419",
    iconSet: "Ionicons",
    iconName: "medical-sharp"
  },
  {
    uuid: "a78bf366-356a-43e5-9942-ab67ab2ed3be",
    iconSet: "Ionicons",
    iconName: "medkit"
  },
  {
    uuid: "7dbd099e-933a-4dd3-9af4-569d5ed3dff1",
    iconSet: "Ionicons",
    iconName: "medkit-outline"
  },
  {
    uuid: "785c9a86-0191-4c09-bbc3-e81c8c826a39",
    iconSet: "Ionicons",
    iconName: "medkit-sharp"
  },
  {
    uuid: "a1df56a9-cc7e-4076-aaef-aa8de16420e5",
    iconSet: "Ionicons",
    iconName: "megaphone"
  },
  {
    uuid: "953a6f2c-3e93-46b6-b6a3-10d13663f957",
    iconSet: "Ionicons",
    iconName: "megaphone-outline"
  },
  {
    uuid: "b202db1a-aba3-487f-8d93-942bbdee31c1",
    iconSet: "Ionicons",
    iconName: "megaphone-sharp"
  },
  {
    uuid: "4c2e16dd-221e-46aa-93d3-813c48a9e813",
    iconSet: "Ionicons",
    iconName: "menu"
  },
  {
    uuid: "eda96571-7e91-480f-918e-720375a14e8a",
    iconSet: "Ionicons",
    iconName: "menu-outline"
  },
  {
    uuid: "467a350c-1cd9-4062-9f56-bbb2037a52d0",
    iconSet: "Ionicons",
    iconName: "menu-sharp"
  },
  {
    uuid: "02b3df36-bcc5-429e-ac21-68a08075dc8a",
    iconSet: "Ionicons",
    iconName: "mic"
  },
  {
    uuid: "d631d0db-5764-4a0b-a9d1-6e36abd731b9",
    iconSet: "Ionicons",
    iconName: "mic-circle"
  },
  {
    uuid: "1b17c971-b18d-458f-b9af-1872f0a3403a",
    iconSet: "Ionicons",
    iconName: "mic-circle-outline"
  },
  {
    uuid: "8c821df2-2edf-4e01-a0f6-937cdc51e476",
    iconSet: "Ionicons",
    iconName: "mic-circle-sharp"
  },
  {
    uuid: "1df7bad0-5e56-4bcc-bbdc-9315df0d2208",
    iconSet: "Ionicons",
    iconName: "mic-off"
  },
  {
    uuid: "48f43135-5cda-4b8f-a54e-fb516ddfc86b",
    iconSet: "Ionicons",
    iconName: "mic-off-circle"
  },
  {
    uuid: "151406f7-c5eb-431b-824e-ce1dc545804b",
    iconSet: "Ionicons",
    iconName: "mic-off-circle-outline"
  },
  {
    uuid: "c15c6ef9-2342-4733-b549-3665c99e1dee",
    iconSet: "Ionicons",
    iconName: "mic-off-circle-sharp"
  },
  {
    uuid: "bf753598-2a2b-4a26-bd30-4c02beb33e51",
    iconSet: "Ionicons",
    iconName: "mic-off-outline"
  },
  {
    uuid: "64940d30-5f2b-458e-8fbb-fe8a726f085e",
    iconSet: "Ionicons",
    iconName: "mic-off-sharp"
  },
  {
    uuid: "02167871-9f7d-4201-926c-bc97bec593ff",
    iconSet: "Ionicons",
    iconName: "mic-outline"
  },
  {
    uuid: "014189df-f3bc-4924-a9f0-c6abbedf62d1",
    iconSet: "Ionicons",
    iconName: "mic-sharp"
  },
  {
    uuid: "15bc8bdf-7996-43a3-b335-f8c66bf77a85",
    iconSet: "Ionicons",
    iconName: "moon"
  },
  {
    uuid: "4daa9e13-53af-4e36-97b6-af113683abf8",
    iconSet: "Ionicons",
    iconName: "moon-outline"
  },
  {
    uuid: "d51dbf20-01db-453c-8667-cea64c442d90",
    iconSet: "Ionicons",
    iconName: "moon-sharp"
  },
  {
    uuid: "e423265a-fdf3-4483-8d62-6fb5ab8c4869",
    iconSet: "Ionicons",
    iconName: "move"
  },
  {
    uuid: "3a4cbb23-b3f4-467f-9231-4e2ab319122b",
    iconSet: "Ionicons",
    iconName: "move-outline"
  },
  {
    uuid: "2d499bed-6439-45b5-a3d5-9f8ed3368b07",
    iconSet: "Ionicons",
    iconName: "move-sharp"
  },
  {
    uuid: "fda01f50-dade-4ca6-bf0a-8e9ee019caf1",
    iconSet: "Ionicons",
    iconName: "musical-note"
  },
  {
    uuid: "dfbd3e2b-f876-4ee0-b1ff-7ee685633611",
    iconSet: "Ionicons",
    iconName: "musical-note-outline"
  },
  {
    uuid: "a33e2e0b-0760-4808-a694-194079a3130a",
    iconSet: "Ionicons",
    iconName: "musical-note-sharp"
  },
  {
    uuid: "0aff9b9c-a298-4223-9b9b-90d096b8b77f",
    iconSet: "Ionicons",
    iconName: "musical-notes"
  },
  {
    uuid: "823531d2-4413-4ca0-a844-7a32a12127fa",
    iconSet: "Ionicons",
    iconName: "musical-notes-outline"
  },
  {
    uuid: "1f3fd6a7-52f6-4a7d-9890-cd35ff320bea",
    iconSet: "Ionicons",
    iconName: "musical-notes-sharp"
  },
  {
    uuid: "b4393415-a4b0-4af4-b492-558fd03b3d07",
    iconSet: "Ionicons",
    iconName: "navigate"
  },
  {
    uuid: "e58e7537-e100-4604-b647-289ba711c871",
    iconSet: "Ionicons",
    iconName: "navigate-circle"
  },
  {
    uuid: "b7964f9c-a2fc-496b-a071-5b1b5693d0f5",
    iconSet: "Ionicons",
    iconName: "navigate-circle-outline"
  },
  {
    uuid: "351e5eb2-9077-4c4f-9c1f-ff562f16f961",
    iconSet: "Ionicons",
    iconName: "navigate-circle-sharp"
  },
  {
    uuid: "1fb260b7-f6ed-45f7-a98e-bc08859e9c77",
    iconSet: "Ionicons",
    iconName: "navigate-outline"
  },
  {
    uuid: "8b9609e8-176a-474e-9a1a-f838a7512ddb",
    iconSet: "Ionicons",
    iconName: "navigate-sharp"
  },
  {
    uuid: "37d0867d-f6c5-4429-b9dc-d4b6e4379e8d",
    iconSet: "Ionicons",
    iconName: "newspaper"
  },
  {
    uuid: "9892931a-2719-4db3-b391-18a4be2d9e7d",
    iconSet: "Ionicons",
    iconName: "newspaper-outline"
  },
  {
    uuid: "ce3b6166-24a6-41b3-a7ea-aacdbe472a81",
    iconSet: "Ionicons",
    iconName: "newspaper-sharp"
  },
  {
    uuid: "c0cca311-a1bc-4e60-85c2-f54c8d792c71",
    iconSet: "Ionicons",
    iconName: "notifications"
  },
  {
    uuid: "b141dda6-8d99-460d-88b1-de881be23df7",
    iconSet: "Ionicons",
    iconName: "notifications-circle"
  },
  {
    uuid: "a6c9633c-238d-4b79-8ccd-d3fa8422d6ce",
    iconSet: "Ionicons",
    iconName: "notifications-circle-outline"
  },
  {
    uuid: "eaa6f250-a75e-4754-a7bf-3003baecbff1",
    iconSet: "Ionicons",
    iconName: "notifications-circle-sharp"
  },
  {
    uuid: "c5230a01-19e2-49ee-90b0-8d58fe195db0",
    iconSet: "Ionicons",
    iconName: "notifications-off"
  },
  {
    uuid: "7c92dc9a-5e25-493b-bec8-c76c5d33a0cd",
    iconSet: "Ionicons",
    iconName: "notifications-off-circle"
  },
  {
    uuid: "71f3345d-f257-44a9-a41e-c4603dfd1aba",
    iconSet: "Ionicons",
    iconName: "notifications-off-circle-outline"
  },
  {
    uuid: "2214a9dc-82bb-4798-8af5-8479d0317b90",
    iconSet: "Ionicons",
    iconName: "notifications-off-circle-sharp"
  },
  {
    uuid: "903655d1-afb6-4d9e-8d20-1ade5745ee52",
    iconSet: "Ionicons",
    iconName: "notifications-off-outline"
  },
  {
    uuid: "8787836d-5638-48f7-b3e3-1629b9f326b4",
    iconSet: "Ionicons",
    iconName: "notifications-off-sharp"
  },
  {
    uuid: "9dc9e9ac-5441-4009-986a-df082574bb30",
    iconSet: "Ionicons",
    iconName: "notifications-outline"
  },
  {
    uuid: "63da02a2-1df1-4bb4-83f4-1cc17c90788d",
    iconSet: "Ionicons",
    iconName: "notifications-sharp"
  },
  {
    uuid: "280c1f04-6f12-4be3-900e-aea7bf83a0e4",
    iconSet: "Ionicons",
    iconName: "nuclear"
  },
  {
    uuid: "84326c82-ee3a-4dc8-8cef-43669ac56ce8",
    iconSet: "Ionicons",
    iconName: "nuclear-outline"
  },
  {
    uuid: "8946b230-5108-49d8-bbf4-ca4e1c837cf0",
    iconSet: "Ionicons",
    iconName: "nuclear-sharp"
  },
  {
    uuid: "1f12a443-7df7-484b-ba48-5b350ea8ef5a",
    iconSet: "Ionicons",
    iconName: "nutrition"
  },
  {
    uuid: "8c5a8186-ac93-4a85-813b-db73bf2fd1bd",
    iconSet: "Ionicons",
    iconName: "nutrition-outline"
  },
  {
    uuid: "0ca0be19-904d-4ec9-a76a-2a883258803d",
    iconSet: "Ionicons",
    iconName: "nutrition-sharp"
  },
  {
    uuid: "6cef6f4f-6a63-4d66-96b7-f84376b62426",
    iconSet: "Ionicons",
    iconName: "open"
  },
  {
    uuid: "36008ef5-3926-4ee6-9a01-e271ee04a0dc",
    iconSet: "Ionicons",
    iconName: "open-outline"
  },
  {
    uuid: "fc8fe440-c817-4b26-b272-c1fa53e3c4c2",
    iconSet: "Ionicons",
    iconName: "open-sharp"
  },
  {
    uuid: "8f26eaee-6dac-411b-b72a-23fa1f32fe6a",
    iconSet: "Ionicons",
    iconName: "options"
  },
  {
    uuid: "abee8374-d666-48c6-9447-965ebec0bb92",
    iconSet: "Ionicons",
    iconName: "options-outline"
  },
  {
    uuid: "6148270a-42f6-4c4c-9abd-f5a2a7f55d9a",
    iconSet: "Ionicons",
    iconName: "options-sharp"
  },
  {
    uuid: "ab2c8914-04bb-40ca-a046-613796b3d1f7",
    iconSet: "Ionicons",
    iconName: "paper-plane"
  },
  {
    uuid: "747faee8-1ebe-4d0c-a5c8-450563d60cd0",
    iconSet: "Ionicons",
    iconName: "paper-plane-outline"
  },
  {
    uuid: "3ce219b1-30aa-492e-9121-b65928399c2b",
    iconSet: "Ionicons",
    iconName: "paper-plane-sharp"
  },
  {
    uuid: "9fc69d08-1f75-4e48-9a83-30821a41f558",
    iconSet: "Ionicons",
    iconName: "partly-sunny"
  },
  {
    uuid: "ea2cb8ff-7b33-4c2e-9fe8-a7df1464071e",
    iconSet: "Ionicons",
    iconName: "partly-sunny-outline"
  },
  {
    uuid: "9829aad2-8395-470b-a2da-aa00779b1c38",
    iconSet: "Ionicons",
    iconName: "partly-sunny-sharp"
  },
  {
    uuid: "67697fdd-6dea-4251-8b0d-85384c5af7a2",
    iconSet: "Ionicons",
    iconName: "pause"
  },
  {
    uuid: "cc56e841-94fd-47ab-854f-abc855983cc4",
    iconSet: "Ionicons",
    iconName: "pause-circle"
  },
  {
    uuid: "94aa5f87-ca56-4857-8882-8acab8f3a517",
    iconSet: "Ionicons",
    iconName: "pause-circle-outline"
  },
  {
    uuid: "42d1e3c2-0f88-47a8-b024-2a5d42f73e09",
    iconSet: "Ionicons",
    iconName: "pause-circle-sharp"
  },
  {
    uuid: "0662e71f-f06d-4047-90fe-cbf03132908f",
    iconSet: "Ionicons",
    iconName: "pause-outline"
  },
  {
    uuid: "f422070d-d0d7-47b7-bd86-292ed8044fa1",
    iconSet: "Ionicons",
    iconName: "pause-sharp"
  },
  {
    uuid: "f6e32374-1762-4d42-aa80-fe8291ac6027",
    iconSet: "Ionicons",
    iconName: "paw"
  },
  {
    uuid: "4a4a1ea7-82e6-4c2e-87fb-4c2c9d088b92",
    iconSet: "Ionicons",
    iconName: "paw-outline"
  },
  {
    uuid: "3bf88524-fc8a-4d56-a1d8-3c1099e3a4b0",
    iconSet: "Ionicons",
    iconName: "paw-sharp"
  },
  {
    uuid: "7077df08-4266-4b57-bd92-6bf51def476b",
    iconSet: "Ionicons",
    iconName: "pencil"
  },
  {
    uuid: "5dceb311-8670-4bb7-b2ef-e0547e1a1b0e",
    iconSet: "Ionicons",
    iconName: "pencil-outline"
  },
  {
    uuid: "69c821bc-c602-4d06-b94b-94b046998a2c",
    iconSet: "Ionicons",
    iconName: "pencil-sharp"
  },
  {
    uuid: "443cc107-ab34-4bc5-81af-b62c332ebe53",
    iconSet: "Ionicons",
    iconName: "people"
  },
  {
    uuid: "c5fce6bf-9426-4ae4-a887-df73df8be102",
    iconSet: "Ionicons",
    iconName: "people-circle"
  },
  {
    uuid: "0e49475e-14a4-492c-a86f-120a30b72869",
    iconSet: "Ionicons",
    iconName: "people-circle-outline"
  },
  {
    uuid: "dcb9e81e-e48c-4b94-ac9a-def8b8478dca",
    iconSet: "Ionicons",
    iconName: "people-circle-sharp"
  },
  {
    uuid: "c33012d1-649b-4671-abfa-2692b67a2b5c",
    iconSet: "Ionicons",
    iconName: "people-outline"
  },
  {
    uuid: "3fccd25d-2235-49ef-b20f-7eb9a9c11d46",
    iconSet: "Ionicons",
    iconName: "people-sharp"
  },
  {
    uuid: "b6d60c65-356c-4294-8c53-86b3936ec4ec",
    iconSet: "Ionicons",
    iconName: "person"
  },
  {
    uuid: "8864ed07-3ec2-4017-8863-322419d55063",
    iconSet: "Ionicons",
    iconName: "person-add"
  },
  {
    uuid: "cb314d44-55dc-4dc1-81be-67aacaa30ff7",
    iconSet: "Ionicons",
    iconName: "person-add-outline"
  },
  {
    uuid: "c09757b3-ddf2-4d8a-88f8-56fe26d59c50",
    iconSet: "Ionicons",
    iconName: "person-add-sharp"
  },
  {
    uuid: "b463e0fd-333e-49f3-b024-817e88d7eb4d",
    iconSet: "Ionicons",
    iconName: "person-circle"
  },
  {
    uuid: "c6c0f0e2-95a6-4b7c-94be-5ccfee7cbdc8",
    iconSet: "Ionicons",
    iconName: "person-circle-outline"
  },
  {
    uuid: "5a1f193d-dfaf-4428-be6a-9a7dc6143dce",
    iconSet: "Ionicons",
    iconName: "person-circle-sharp"
  },
  {
    uuid: "043aa93b-ccfe-43e9-b4eb-4cbcd8e5d82f",
    iconSet: "Ionicons",
    iconName: "person-outline"
  },
  {
    uuid: "c82c1696-10b5-405c-bae0-10400c23e7f5",
    iconSet: "Ionicons",
    iconName: "person-remove"
  },
  {
    uuid: "3a47961d-3f59-4a69-9191-64bb1b6263a9",
    iconSet: "Ionicons",
    iconName: "person-remove-outline"
  },
  {
    uuid: "15dd9e26-80c9-47c3-80a7-94f982431280",
    iconSet: "Ionicons",
    iconName: "person-remove-sharp"
  },
  {
    uuid: "e92f9844-b13f-4b80-9227-0085fb9731b2",
    iconSet: "Ionicons",
    iconName: "person-sharp"
  },
  {
    uuid: "b388e5e8-16d1-4b15-916b-61a64c981405",
    iconSet: "Ionicons",
    iconName: "phone-landscape"
  },
  {
    uuid: "306059eb-64b8-4f60-a5d5-679c3d065b57",
    iconSet: "Ionicons",
    iconName: "phone-landscape-outline"
  },
  {
    uuid: "64a1ab82-5b63-4a6b-beb1-e718ce99dfe0",
    iconSet: "Ionicons",
    iconName: "phone-landscape-sharp"
  },
  {
    uuid: "7d7854d7-ac60-4339-ab33-029a5505713f",
    iconSet: "Ionicons",
    iconName: "phone-portrait"
  },
  {
    uuid: "30ac1b5e-8150-4327-afd9-4fbba069cff1",
    iconSet: "Ionicons",
    iconName: "phone-portrait-outline"
  },
  {
    uuid: "abe8b3e7-7453-4b65-b8df-78d7677f9aeb",
    iconSet: "Ionicons",
    iconName: "phone-portrait-sharp"
  },
  {
    uuid: "cbb3a9b4-e98d-4fa8-a397-4e651f9ec090",
    iconSet: "Ionicons",
    iconName: "pie-chart"
  },
  {
    uuid: "e80a903d-3af2-4dee-a546-84146aa2a74e",
    iconSet: "Ionicons",
    iconName: "pie-chart-outline"
  },
  {
    uuid: "97a9bba4-c375-4a73-80f7-8fac445bf3b7",
    iconSet: "Ionicons",
    iconName: "pie-chart-sharp"
  },
  {
    uuid: "70f52052-2d1b-4295-8523-bee43850c4f2",
    iconSet: "Ionicons",
    iconName: "pin"
  },
  {
    uuid: "efceac05-3dcb-4743-95fd-448b5657843e",
    iconSet: "Ionicons",
    iconName: "pin-outline"
  },
  {
    uuid: "f0b19484-c7cc-4bef-9b2d-32824b6d325f",
    iconSet: "Ionicons",
    iconName: "pin-sharp"
  },
  {
    uuid: "f433b14c-2db1-4424-b5cf-36f1a8d33642",
    iconSet: "Ionicons",
    iconName: "pint"
  },
  {
    uuid: "c58e02e8-de62-463c-a4ef-05ad09ffc8f6",
    iconSet: "Ionicons",
    iconName: "pint-outline"
  },
  {
    uuid: "6426db3f-dc56-4233-b535-52043b640e8e",
    iconSet: "Ionicons",
    iconName: "pint-sharp"
  },
  {
    uuid: "a220aa31-1a67-4b5b-a60e-084ca30272e1",
    iconSet: "Ionicons",
    iconName: "pizza"
  },
  {
    uuid: "93020b0d-1951-489f-9cc5-0bb5300fcc26",
    iconSet: "Ionicons",
    iconName: "pizza-outline"
  },
  {
    uuid: "df0c11c0-8198-4f4a-9b83-d479318800d9",
    iconSet: "Ionicons",
    iconName: "pizza-sharp"
  },
  {
    uuid: "bb32aa60-b518-4ff3-8952-06c9362545b0",
    iconSet: "Ionicons",
    iconName: "planet"
  },
  {
    uuid: "00aa7bac-2773-430f-9870-c37272ca9bb2",
    iconSet: "Ionicons",
    iconName: "planet-outline"
  },
  {
    uuid: "31a350b8-0587-46a6-8ebd-744b868e105e",
    iconSet: "Ionicons",
    iconName: "planet-sharp"
  },
  {
    uuid: "4f0ea426-9939-4a72-a997-0a5047c08374",
    iconSet: "Ionicons",
    iconName: "play"
  },
  {
    uuid: "2df7c163-b1c4-4b3e-981e-e72f12f19802",
    iconSet: "Ionicons",
    iconName: "play-back"
  },
  {
    uuid: "bcfbfb99-c94c-4a4a-b976-aeb0514373e3",
    iconSet: "Ionicons",
    iconName: "play-back-circle"
  },
  {
    uuid: "4c3ff17c-1983-4563-85da-7aa1a69970f9",
    iconSet: "Ionicons",
    iconName: "play-back-circle-outline"
  },
  {
    uuid: "2bffb211-9a2a-47e9-9eb3-62556a45bb7b",
    iconSet: "Ionicons",
    iconName: "play-back-circle-sharp"
  },
  {
    uuid: "d96975cb-ece9-4c76-ba92-9b27cd0ba1bf",
    iconSet: "Ionicons",
    iconName: "play-back-outline"
  },
  {
    uuid: "ac3462d3-73d7-4aa3-b92a-c8d7fe8cd6da",
    iconSet: "Ionicons",
    iconName: "play-back-sharp"
  },
  {
    uuid: "31a9abeb-7c2a-4163-9d58-b120b76c71fa",
    iconSet: "Ionicons",
    iconName: "play-circle"
  },
  {
    uuid: "3cc64b3b-7a35-4abb-8e94-5eab354c9616",
    iconSet: "Ionicons",
    iconName: "play-circle-outline"
  },
  {
    uuid: "4961eb4a-eeb3-45e2-aa29-f63b939c7422",
    iconSet: "Ionicons",
    iconName: "play-circle-sharp"
  },
  {
    uuid: "7826696b-851e-4f80-b0f9-a0bf2c3987c4",
    iconSet: "Ionicons",
    iconName: "play-forward"
  },
  {
    uuid: "e74b7c6e-45b4-41c7-87cc-932839a92ab7",
    iconSet: "Ionicons",
    iconName: "play-forward-circle"
  },
  {
    uuid: "a8907b78-2f1b-424a-8f9e-29788ed63ff5",
    iconSet: "Ionicons",
    iconName: "play-forward-circle-outline"
  },
  {
    uuid: "24462afc-e1fb-477f-887f-1bb9eb3de30c",
    iconSet: "Ionicons",
    iconName: "play-forward-circle-sharp"
  },
  {
    uuid: "70fc2f01-dfab-4b61-9afe-9e7e01f536e3",
    iconSet: "Ionicons",
    iconName: "play-forward-outline"
  },
  {
    uuid: "62c974e2-d36b-48a8-8f09-0f2252d78b88",
    iconSet: "Ionicons",
    iconName: "play-forward-sharp"
  },
  {
    uuid: "6b97dd5c-4553-4313-839a-3cb2864b5180",
    iconSet: "Ionicons",
    iconName: "play-outline"
  },
  {
    uuid: "92d7e359-f6a8-44c2-ac8d-1bbb27d99942",
    iconSet: "Ionicons",
    iconName: "play-sharp"
  },
  {
    uuid: "30f0e76c-c1f7-4488-9d7f-9ae6f3e566c8",
    iconSet: "Ionicons",
    iconName: "play-skip-back"
  },
  {
    uuid: "c3b4d015-1a4e-44a6-9a67-f7908c458792",
    iconSet: "Ionicons",
    iconName: "play-skip-back-circle"
  },
  {
    uuid: "6a5c8347-5345-49b7-87be-6ed2976dd701",
    iconSet: "Ionicons",
    iconName: "play-skip-back-circle-outline"
  },
  {
    uuid: "6b395cc7-9926-425a-a788-eb2197159fd7",
    iconSet: "Ionicons",
    iconName: "play-skip-back-circle-sharp"
  },
  {
    uuid: "d65a7df9-5ba7-4000-a6de-d73faf0383d1",
    iconSet: "Ionicons",
    iconName: "play-skip-back-outline"
  },
  {
    uuid: "575e9cf6-ea40-4ff3-a961-207e9024e315",
    iconSet: "Ionicons",
    iconName: "play-skip-back-sharp"
  },
  {
    uuid: "db68c12a-803b-49ca-a025-bee71387bb3c",
    iconSet: "Ionicons",
    iconName: "play-skip-forward"
  },
  {
    uuid: "c7200a24-a53d-4161-aafd-3a7655e14e44",
    iconSet: "Ionicons",
    iconName: "play-skip-forward-circle"
  },
  {
    uuid: "73e32fb4-ca15-4dfc-8ed5-07de541e43e1",
    iconSet: "Ionicons",
    iconName: "play-skip-forward-circle-outline"
  },
  {
    uuid: "52ceae79-3636-40d9-a417-563c3f81f964",
    iconSet: "Ionicons",
    iconName: "play-skip-forward-circle-sharp"
  },
  {
    uuid: "81eb197c-b34f-4ac7-b1f8-8229c7a7dd9a",
    iconSet: "Ionicons",
    iconName: "play-skip-forward-outline"
  },
  {
    uuid: "ce4407ee-1abf-499a-9847-ef06c9e85efe",
    iconSet: "Ionicons",
    iconName: "play-skip-forward-sharp"
  },
  {
    uuid: "8cf44a70-e5d1-45bb-be83-60638fd8e9c4",
    iconSet: "Ionicons",
    iconName: "podium"
  },
  {
    uuid: "bd965673-9cd4-43c2-8cf3-17d0d39993ff",
    iconSet: "Ionicons",
    iconName: "podium-outline"
  },
  {
    uuid: "41f1d08f-6757-4338-8a8f-199e947c948a",
    iconSet: "Ionicons",
    iconName: "podium-sharp"
  },
  {
    uuid: "09a42752-3c28-4dc6-9f9e-8aafa233ba80",
    iconSet: "Ionicons",
    iconName: "power"
  },
  {
    uuid: "35a9ed63-854a-46ff-8387-933327dc239a",
    iconSet: "Ionicons",
    iconName: "power-outline"
  },
  {
    uuid: "4042caec-9caf-41c9-a721-4b4218aebf07",
    iconSet: "Ionicons",
    iconName: "power-sharp"
  },
  {
    uuid: "8a064521-017a-4e5b-9e2b-9aceee2211ba",
    iconSet: "Ionicons",
    iconName: "pricetag"
  },
  {
    uuid: "ef86bcf3-b147-4a16-9edb-c63e49892b07",
    iconSet: "Ionicons",
    iconName: "pricetag-outline"
  },
  {
    uuid: "070ae10f-8a6c-434f-82fd-a397b0b596bd",
    iconSet: "Ionicons",
    iconName: "pricetag-sharp"
  },
  {
    uuid: "6e572394-1e52-4ad5-8525-f59dee0da298",
    iconSet: "Ionicons",
    iconName: "pricetags"
  },
  {
    uuid: "048db78a-667d-4eb4-b6c7-86a6d1542e9d",
    iconSet: "Ionicons",
    iconName: "pricetags-outline"
  },
  {
    uuid: "f76e7fb6-31fa-47fd-bc8a-20aeeafec184",
    iconSet: "Ionicons",
    iconName: "pricetags-sharp"
  },
  {
    uuid: "c30deaf5-cabd-4bc2-b9df-9d5cfe95280c",
    iconSet: "Ionicons",
    iconName: "print"
  },
  {
    uuid: "d9791543-2cef-4fde-8fbc-cb69622b65bb",
    iconSet: "Ionicons",
    iconName: "print-outline"
  },
  {
    uuid: "865ab7fa-c045-4eec-8be4-91a8fd5f3d01",
    iconSet: "Ionicons",
    iconName: "print-sharp"
  },
  {
    uuid: "95dc29f5-1f7b-4510-97f0-6cf9f27ee65c",
    iconSet: "Ionicons",
    iconName: "pulse"
  },
  {
    uuid: "a5161b6c-94f2-4c43-b02a-69f8caafc3cd",
    iconSet: "Ionicons",
    iconName: "pulse-outline"
  },
  {
    uuid: "acc10fc0-2233-466a-afcb-8749bec43209",
    iconSet: "Ionicons",
    iconName: "pulse-sharp"
  },
  {
    uuid: "0ecbb7e7-dafd-4fd4-9996-e5d50f2b99cf",
    iconSet: "Ionicons",
    iconName: "push"
  },
  {
    uuid: "0a3ddda8-aecb-45ff-9f82-6ad628bad221",
    iconSet: "Ionicons",
    iconName: "push-outline"
  },
  {
    uuid: "d3643439-636f-4b62-ad71-7df7f568acc8",
    iconSet: "Ionicons",
    iconName: "push-sharp"
  },
  {
    uuid: "4ab2f6a2-b90a-4feb-a21d-4625af5ba8f5",
    iconSet: "Ionicons",
    iconName: "qr-code"
  },
  {
    uuid: "ee3370b2-186e-49b7-b492-0e8f17b0aef6",
    iconSet: "Ionicons",
    iconName: "qr-code-outline"
  },
  {
    uuid: "ae2c35e3-e8f1-4de3-a6c5-9e60f6550749",
    iconSet: "Ionicons",
    iconName: "qr-code-sharp"
  },
  {
    uuid: "2b22e6db-68fd-461d-aaa5-c1690d61e68c",
    iconSet: "Ionicons",
    iconName: "radio"
  },
  {
    uuid: "9da2bbe5-00fa-45bb-a011-1c5be80f75b5",
    iconSet: "Ionicons",
    iconName: "radio-button-off"
  },
  {
    uuid: "bf06264a-ae2c-4344-b84e-860ef45d38a3",
    iconSet: "Ionicons",
    iconName: "radio-button-off-outline"
  },
  {
    uuid: "a20814bc-84f6-4a0d-9049-5d3ed60f775a",
    iconSet: "Ionicons",
    iconName: "radio-button-off-sharp"
  },
  {
    uuid: "08bb843f-c803-4797-b689-6cdd40135333",
    iconSet: "Ionicons",
    iconName: "radio-button-on"
  },
  {
    uuid: "f98fffe5-0613-4d75-94b5-65cef8ca5ede",
    iconSet: "Ionicons",
    iconName: "radio-button-on-outline"
  },
  {
    uuid: "6e0b0d6d-6839-4870-8c9d-c7da6f2ebc2e",
    iconSet: "Ionicons",
    iconName: "radio-button-on-sharp"
  },
  {
    uuid: "539fb3e2-35ee-42d1-86b3-6cd00f394e97",
    iconSet: "Ionicons",
    iconName: "radio-outline"
  },
  {
    uuid: "2548a396-5ac0-4b27-85e9-57fe4bd94227",
    iconSet: "Ionicons",
    iconName: "radio-sharp"
  },
  {
    uuid: "fa9870e0-2699-4b25-9489-1c9c2c0a5ab5",
    iconSet: "Ionicons",
    iconName: "rainy"
  },
  {
    uuid: "cc5da464-ca99-49a6-90e0-c4749aa1e3f5",
    iconSet: "Ionicons",
    iconName: "rainy-outline"
  },
  {
    uuid: "c9ce96e4-1f2a-41d5-8b25-c1f9f533d308",
    iconSet: "Ionicons",
    iconName: "rainy-sharp"
  },
  {
    uuid: "5c4a5dac-7755-465a-a6dc-a26f960f7c39",
    iconSet: "Ionicons",
    iconName: "reader"
  },
  {
    uuid: "490f5bdf-01ac-44b9-b1ac-2311c393696a",
    iconSet: "Ionicons",
    iconName: "reader-outline"
  },
  {
    uuid: "c744be19-127b-4667-bebb-11cb33c3b488",
    iconSet: "Ionicons",
    iconName: "reader-sharp"
  },
  {
    uuid: "97eb9220-a293-4960-b7e8-4eda3b34c2dd",
    iconSet: "Ionicons",
    iconName: "receipt"
  },
  {
    uuid: "a56aceb9-88e8-435f-98f3-a9685a204612",
    iconSet: "Ionicons",
    iconName: "receipt-outline"
  },
  {
    uuid: "b19923b0-776c-4987-acb0-012070da5b3b",
    iconSet: "Ionicons",
    iconName: "receipt-sharp"
  },
  {
    uuid: "20050578-4091-4314-b675-e449524a9896",
    iconSet: "Ionicons",
    iconName: "recording"
  },
  {
    uuid: "1299dd3a-f0c4-4cd5-bcee-f38e2fe0532b",
    iconSet: "Ionicons",
    iconName: "recording-outline"
  },
  {
    uuid: "f1e62063-b2a4-43fe-b783-4d5aa2b911f4",
    iconSet: "Ionicons",
    iconName: "recording-sharp"
  },
  {
    uuid: "8497a542-ed36-4687-a62e-37454ad66716",
    iconSet: "Ionicons",
    iconName: "refresh"
  },
  {
    uuid: "1dffa7e4-055d-44fc-896a-a7418ae8f2af",
    iconSet: "Ionicons",
    iconName: "refresh-circle"
  },
  {
    uuid: "03abf7e5-267d-4af5-9b11-d015dacee211",
    iconSet: "Ionicons",
    iconName: "refresh-circle-outline"
  },
  {
    uuid: "b0a6bfe7-aede-4d32-af6a-15cde0996b8f",
    iconSet: "Ionicons",
    iconName: "refresh-circle-sharp"
  },
  {
    uuid: "a2af0230-7b5d-4a92-96e7-16fc45c2cc6a",
    iconSet: "Ionicons",
    iconName: "refresh-outline"
  },
  {
    uuid: "26e7fe79-d61f-4dd6-a35f-7eedb614cbb1",
    iconSet: "Ionicons",
    iconName: "refresh-sharp"
  },
  {
    uuid: "459a89bf-645f-4d6e-9f9d-1ec4e3af6ea0",
    iconSet: "Ionicons",
    iconName: "reload"
  },
  {
    uuid: "f4fc441e-afd9-4bf1-9319-0e3b876d9b97",
    iconSet: "Ionicons",
    iconName: "reload-circle"
  },
  {
    uuid: "3552ac48-d26b-4e70-ac3b-839d35de9bc7",
    iconSet: "Ionicons",
    iconName: "reload-circle-outline"
  },
  {
    uuid: "d6d51e91-ec6a-41d6-85fb-abb7ad4aed1e",
    iconSet: "Ionicons",
    iconName: "reload-circle-sharp"
  },
  {
    uuid: "62bcb8f2-1f08-48a1-a5d3-558440ed7329",
    iconSet: "Ionicons",
    iconName: "reload-outline"
  },
  {
    uuid: "bb4e8a1d-22e6-45f0-bcba-0b145f057c97",
    iconSet: "Ionicons",
    iconName: "reload-sharp"
  },
  {
    uuid: "36579c9a-e23e-471d-84f0-78dda6cb4848",
    iconSet: "Ionicons",
    iconName: "remove"
  },
  {
    uuid: "a66f6c57-ab78-4863-9d88-05e80ade0693",
    iconSet: "Ionicons",
    iconName: "remove-circle"
  },
  {
    uuid: "94179143-7da5-4843-bdab-9593b9913851",
    iconSet: "Ionicons",
    iconName: "remove-circle-outline"
  },
  {
    uuid: "cd0692c2-7d47-43e9-b90b-9a669282ebd6",
    iconSet: "Ionicons",
    iconName: "remove-circle-sharp"
  },
  {
    uuid: "50e72d12-4b3a-49f0-b227-16ed7a5c8f21",
    iconSet: "Ionicons",
    iconName: "remove-outline"
  },
  {
    uuid: "8df3ac70-d453-4cce-a71e-bdf7699e63af",
    iconSet: "Ionicons",
    iconName: "remove-sharp"
  },
  {
    uuid: "e081c82a-c324-4a5e-bc92-77ba532a4c33",
    iconSet: "Ionicons",
    iconName: "reorder-four"
  },
  {
    uuid: "ce05372e-5217-4cb0-bead-6bfbad0785a2",
    iconSet: "Ionicons",
    iconName: "reorder-four-outline"
  },
  {
    uuid: "cf89dd41-81ad-4910-867d-c7fb06db0199",
    iconSet: "Ionicons",
    iconName: "reorder-four-sharp"
  },
  {
    uuid: "d0c5e168-831c-4c0a-8c40-fc9b34678032",
    iconSet: "Ionicons",
    iconName: "reorder-three"
  },
  {
    uuid: "b244925b-5321-4300-8835-d44085ccb5ab",
    iconSet: "Ionicons",
    iconName: "reorder-three-outline"
  },
  {
    uuid: "c249ce39-f651-446a-9d3c-f79c96ea3ab5",
    iconSet: "Ionicons",
    iconName: "reorder-three-sharp"
  },
  {
    uuid: "370b9226-3bde-45e2-abec-3ebe826cefa1",
    iconSet: "Ionicons",
    iconName: "reorder-two"
  },
  {
    uuid: "939c072a-71c1-45ba-bf67-89c71da2be94",
    iconSet: "Ionicons",
    iconName: "reorder-two-outline"
  },
  {
    uuid: "99c681c1-03e8-4acd-9381-85ccbc66c92b",
    iconSet: "Ionicons",
    iconName: "reorder-two-sharp"
  },
  {
    uuid: "3e1031ef-9aca-415d-b0bc-2efec0b26f73",
    iconSet: "Ionicons",
    iconName: "repeat"
  },
  {
    uuid: "c1948b6a-dd9e-45b3-86c3-423197d6dbc4",
    iconSet: "Ionicons",
    iconName: "repeat-outline"
  },
  {
    uuid: "caf40d7b-5401-48ec-b21f-a97350ca6810",
    iconSet: "Ionicons",
    iconName: "repeat-sharp"
  },
  {
    uuid: "75c1e7d8-9fe0-4cd4-9caf-3d323f51094e",
    iconSet: "Ionicons",
    iconName: "resize"
  },
  {
    uuid: "15a74e6f-bd94-4967-8284-41bd47cff89b",
    iconSet: "Ionicons",
    iconName: "resize-outline"
  },
  {
    uuid: "8e674140-9801-403f-a437-2b6dc4a72ac6",
    iconSet: "Ionicons",
    iconName: "resize-sharp"
  },
  {
    uuid: "640f8a2e-b877-4225-b9a3-896c5f89bf52",
    iconSet: "Ionicons",
    iconName: "restaurant"
  },
  {
    uuid: "4bc52939-73bb-4b89-a012-9e2f1ad512e0",
    iconSet: "Ionicons",
    iconName: "restaurant-outline"
  },
  {
    uuid: "a3c3e674-b46f-4716-a1a8-502d01e1d1f9",
    iconSet: "Ionicons",
    iconName: "restaurant-sharp"
  },
  {
    uuid: "ecdd9a63-a126-457f-8a53-9b790969ced4",
    iconSet: "Ionicons",
    iconName: "return-down-back"
  },
  {
    uuid: "9343a777-5a7c-44a7-b81e-f1eea873b8d8",
    iconSet: "Ionicons",
    iconName: "return-down-back-outline"
  },
  {
    uuid: "22e21b55-f287-44ee-8aef-bc18378cd94b",
    iconSet: "Ionicons",
    iconName: "return-down-back-sharp"
  },
  {
    uuid: "551ee9e0-fa3a-412d-a104-c50e9a2c1dfe",
    iconSet: "Ionicons",
    iconName: "return-down-forward"
  },
  {
    uuid: "aa19a60d-c7b5-4f3f-8c72-f7c6c6b052e2",
    iconSet: "Ionicons",
    iconName: "return-down-forward-outline"
  },
  {
    uuid: "2d086200-44c4-428f-8d8c-979176b4cc8a",
    iconSet: "Ionicons",
    iconName: "return-down-forward-sharp"
  },
  {
    uuid: "6f7bef06-26b7-49a9-9dfb-e324cc744260",
    iconSet: "Ionicons",
    iconName: "return-up-back"
  },
  {
    uuid: "54b29c97-7a91-48c7-9026-213d84802e91",
    iconSet: "Ionicons",
    iconName: "return-up-back-outline"
  },
  {
    uuid: "df6c62e1-682c-4332-bcc8-f437ebc22603",
    iconSet: "Ionicons",
    iconName: "return-up-back-sharp"
  },
  {
    uuid: "6f01b352-713a-4432-a3f2-273409df354d",
    iconSet: "Ionicons",
    iconName: "return-up-forward"
  },
  {
    uuid: "bfccd691-3bdf-40cc-b0c3-cae389df8d8d",
    iconSet: "Ionicons",
    iconName: "return-up-forward-outline"
  },
  {
    uuid: "5d9c8d4f-1e86-447e-9831-4732b59e9d00",
    iconSet: "Ionicons",
    iconName: "return-up-forward-sharp"
  },
  {
    uuid: "a0bd8502-0279-4eb3-8773-7e5a6e3523c4",
    iconSet: "Ionicons",
    iconName: "ribbon"
  },
  {
    uuid: "4b49b63b-0fad-40d7-b757-3b48d022978f",
    iconSet: "Ionicons",
    iconName: "ribbon-outline"
  },
  {
    uuid: "2d931cf3-405a-4f71-a39f-414dd7a1dc7e",
    iconSet: "Ionicons",
    iconName: "ribbon-sharp"
  },
  {
    uuid: "9b9623c6-9244-4cb8-8429-895b8bfabcf1",
    iconSet: "Ionicons",
    iconName: "rocket"
  },
  {
    uuid: "57130823-d86d-4703-824f-54f9a5fc6139",
    iconSet: "Ionicons",
    iconName: "rocket-outline"
  },
  {
    uuid: "5c6b7904-8032-470d-a7c0-1c73c994efcc",
    iconSet: "Ionicons",
    iconName: "rocket-sharp"
  },
  {
    uuid: "d873a747-5376-4b5e-ac06-4d7ce8032f71",
    iconSet: "Ionicons",
    iconName: "rose"
  },
  {
    uuid: "93e721c9-2fce-4516-9162-66cdf281ef13",
    iconSet: "Ionicons",
    iconName: "rose-outline"
  },
  {
    uuid: "4de2ee90-7579-4043-babb-6e5676c48180",
    iconSet: "Ionicons",
    iconName: "rose-sharp"
  },
  {
    uuid: "e0abbdd1-af9d-46db-bf6f-7afd9d89232f",
    iconSet: "Ionicons",
    iconName: "sad"
  },
  {
    uuid: "9456d68f-a387-4640-9adb-985f4d9292ed",
    iconSet: "Ionicons",
    iconName: "sad-outline"
  },
  {
    uuid: "ea70da76-6c6a-49ab-b8e2-a2e2beb02150",
    iconSet: "Ionicons",
    iconName: "sad-sharp"
  },
  {
    uuid: "145b15b9-3a34-4a67-a0b9-25a85386add5",
    iconSet: "Ionicons",
    iconName: "save"
  },
  {
    uuid: "6d72c35b-4e79-463e-9322-c791914ff6e5",
    iconSet: "Ionicons",
    iconName: "save-outline"
  },
  {
    uuid: "143a8234-d1fb-42a6-85ce-9c973d0d6410",
    iconSet: "Ionicons",
    iconName: "save-sharp"
  },
  {
    uuid: "2c260e43-2226-4a23-9300-0d23ee7a7a2b",
    iconSet: "Ionicons",
    iconName: "scan"
  },
  {
    uuid: "6c2e0c84-d697-4bee-b8db-be03c3f19ef0",
    iconSet: "Ionicons",
    iconName: "scan-circle"
  },
  {
    uuid: "8feaf03d-2227-431f-96a7-2fb338b18761",
    iconSet: "Ionicons",
    iconName: "scan-circle-outline"
  },
  {
    uuid: "0116baaa-2668-4d96-8ed6-4f30f7720c2f",
    iconSet: "Ionicons",
    iconName: "scan-circle-sharp"
  },
  {
    uuid: "1db67710-dc8d-485b-96ab-b40ec6e7b248",
    iconSet: "Ionicons",
    iconName: "scan-outline"
  },
  {
    uuid: "89557f8c-f35b-463b-955a-300994142db2",
    iconSet: "Ionicons",
    iconName: "scan-sharp"
  },
  {
    uuid: "907888c3-f9e1-4391-80ad-8c4096ffb81b",
    iconSet: "Ionicons",
    iconName: "school"
  },
  {
    uuid: "3a8c9e80-b17c-4edd-9e17-28ec321f67ec",
    iconSet: "Ionicons",
    iconName: "school-outline"
  },
  {
    uuid: "e27fee6e-f9ef-4e3c-a4de-9a6d7628afdd",
    iconSet: "Ionicons",
    iconName: "school-sharp"
  },
  {
    uuid: "caf6e4cb-5c91-43ce-8da7-dc60230c3616",
    iconSet: "Ionicons",
    iconName: "search"
  },
  {
    uuid: "456dac99-4134-438d-8857-39f2376c3edf",
    iconSet: "Ionicons",
    iconName: "search-circle"
  },
  {
    uuid: "64e2ab94-1907-4fab-83e7-71f5306a7cec",
    iconSet: "Ionicons",
    iconName: "search-circle-outline"
  },
  {
    uuid: "10bf7ec3-6138-4adf-8cc7-ea6eac355ce7",
    iconSet: "Ionicons",
    iconName: "search-circle-sharp"
  },
  {
    uuid: "586ba219-59b1-4725-825a-59ce22876302",
    iconSet: "Ionicons",
    iconName: "search-outline"
  },
  {
    uuid: "62347c07-92d4-48bc-b22f-ac91a0263f09",
    iconSet: "Ionicons",
    iconName: "search-sharp"
  },
  {
    uuid: "a2bad872-9c29-4db4-8396-54657a52b3ab",
    iconSet: "Ionicons",
    iconName: "send"
  },
  {
    uuid: "1d8419b8-3dce-4844-9e7d-5345b90a0943",
    iconSet: "Ionicons",
    iconName: "send-outline"
  },
  {
    uuid: "02f6e9f5-4bdd-4962-925b-e23d3823d145",
    iconSet: "Ionicons",
    iconName: "send-sharp"
  },
  {
    uuid: "12b47f87-a417-453c-ae3c-42dc325e5ffa",
    iconSet: "Ionicons",
    iconName: "server"
  },
  {
    uuid: "b22d9a0b-6a89-4737-b286-8696f1ccf999",
    iconSet: "Ionicons",
    iconName: "server-outline"
  },
  {
    uuid: "f963329e-763c-46e2-a644-2ae4c9f4c0de",
    iconSet: "Ionicons",
    iconName: "server-sharp"
  },
  {
    uuid: "1548e28b-f691-4a11-ac1d-017cf5b689fd",
    iconSet: "Ionicons",
    iconName: "settings"
  },
  {
    uuid: "6eef07d0-09c2-40ea-abf2-b02419bbaca7",
    iconSet: "Ionicons",
    iconName: "settings-outline"
  },
  {
    uuid: "5059f543-9256-4a0a-a4ba-94f5bb5fd38a",
    iconSet: "Ionicons",
    iconName: "settings-sharp"
  },
  {
    uuid: "00dfa21d-4fd6-4722-b2d1-358574757465",
    iconSet: "Ionicons",
    iconName: "shapes"
  },
  {
    uuid: "363614ed-fc04-4fa6-910f-deecc30fa4b0",
    iconSet: "Ionicons",
    iconName: "shapes-outline"
  },
  {
    uuid: "d5eba603-c62c-4806-99b0-333ee69965f8",
    iconSet: "Ionicons",
    iconName: "shapes-sharp"
  },
  {
    uuid: "fc9fe0b3-a205-4cf0-9677-ec37e2293392",
    iconSet: "Ionicons",
    iconName: "share"
  },
  {
    uuid: "7d1f8767-b932-4eb2-a190-aa093444997c",
    iconSet: "Ionicons",
    iconName: "share-outline"
  },
  {
    uuid: "7063fa3a-075f-4e5f-9be2-2d0f479042de",
    iconSet: "Ionicons",
    iconName: "share-sharp"
  },
  {
    uuid: "69bad206-8323-4b8c-9e85-87192381a0cd",
    iconSet: "Ionicons",
    iconName: "share-social"
  },
  {
    uuid: "6842c4f4-f4a6-4d08-b07f-75e45e81082c",
    iconSet: "Ionicons",
    iconName: "share-social-outline"
  },
  {
    uuid: "489d217a-4c69-4f53-8edd-ac556b38dec3",
    iconSet: "Ionicons",
    iconName: "share-social-sharp"
  },
  {
    uuid: "9a9c5ee3-e968-4235-844a-4e3e0f144cfc",
    iconSet: "Ionicons",
    iconName: "shield"
  },
  {
    uuid: "1248f46d-def5-40b5-b479-74ad1f961bab",
    iconSet: "Ionicons",
    iconName: "shield-checkmark"
  },
  {
    uuid: "6f731dc9-ca1b-4e24-a57a-43aa3f4507f5",
    iconSet: "Ionicons",
    iconName: "shield-checkmark-outline"
  },
  {
    uuid: "d215d088-7198-4344-b423-ed593146a9ec",
    iconSet: "Ionicons",
    iconName: "shield-checkmark-sharp"
  },
  {
    uuid: "6d0e0732-e34f-4482-be9b-f21a7a1571e5",
    iconSet: "Ionicons",
    iconName: "shield-outline"
  },
  {
    uuid: "161c7542-c387-43e6-ab53-3eba6e1367a5",
    iconSet: "Ionicons",
    iconName: "shield-sharp"
  },
  {
    uuid: "4b863b39-4d05-435b-90a7-63509f099cab",
    iconSet: "Ionicons",
    iconName: "shirt"
  },
  {
    uuid: "be7e3f03-f2c0-4ea6-ba1a-382d77b9e593",
    iconSet: "Ionicons",
    iconName: "shirt-outline"
  },
  {
    uuid: "f767fd46-89bb-4e6d-95d1-7532a42bba37",
    iconSet: "Ionicons",
    iconName: "shirt-sharp"
  },
  {
    uuid: "1893058d-c102-4406-929c-592cd1c5f7da",
    iconSet: "Ionicons",
    iconName: "shuffle"
  },
  {
    uuid: "2ed57aa4-db67-4c78-b0e6-d542c984c80e",
    iconSet: "Ionicons",
    iconName: "shuffle-outline"
  },
  {
    uuid: "d162cda2-31b1-4b27-8c80-c20eec17bafd",
    iconSet: "Ionicons",
    iconName: "shuffle-sharp"
  },
  {
    uuid: "6537eb5a-2a7f-4538-a660-ad5971e589cb",
    iconSet: "Ionicons",
    iconName: "skull"
  },
  {
    uuid: "a37c433b-8acf-495a-a49d-59979f123f5a",
    iconSet: "Ionicons",
    iconName: "skull-outline"
  },
  {
    uuid: "a6025de4-1150-49d0-aad9-25e1cd26907a",
    iconSet: "Ionicons",
    iconName: "skull-sharp"
  },
  {
    uuid: "b2db011a-9581-4b51-bb92-ceb962eadf71",
    iconSet: "Ionicons",
    iconName: "snow"
  },
  {
    uuid: "347e32f3-3977-4fc8-b684-8bf70ed3672d",
    iconSet: "Ionicons",
    iconName: "snow-outline"
  },
  {
    uuid: "28a68974-188a-4166-b332-85469fc86c56",
    iconSet: "Ionicons",
    iconName: "snow-sharp"
  },
  {
    uuid: "91ca708e-7597-4063-b63d-bfaa4e646bad",
    iconSet: "Ionicons",
    iconName: "speedometer"
  },
  {
    uuid: "07e798b8-8932-42f4-b7e7-52d17fb34ea4",
    iconSet: "Ionicons",
    iconName: "speedometer-outline"
  },
  {
    uuid: "2b9cc994-1a95-4dae-b893-1f6a82e5f39e",
    iconSet: "Ionicons",
    iconName: "speedometer-sharp"
  },
  {
    uuid: "07d9dd9c-17b8-46a2-94c7-327004318e28",
    iconSet: "Ionicons",
    iconName: "square"
  },
  {
    uuid: "7134604b-e950-43e0-8723-94ce9e92de0b",
    iconSet: "Ionicons",
    iconName: "square-outline"
  },
  {
    uuid: "2d5561c6-7013-4826-98af-5056b4d7702c",
    iconSet: "Ionicons",
    iconName: "square-sharp"
  },
  {
    uuid: "a8ed0b47-dbf9-4f99-9e09-c0e805d1e8c0",
    iconSet: "Ionicons",
    iconName: "star"
  },
  {
    uuid: "c751066c-fb0a-4e01-b5a4-d1ad975c12f2",
    iconSet: "Ionicons",
    iconName: "star-half"
  },
  {
    uuid: "12ee867a-5a42-44f3-9d0d-81cce04ae3cb",
    iconSet: "Ionicons",
    iconName: "star-half-outline"
  },
  {
    uuid: "35825721-0f40-432d-a9fb-85af8dd610b2",
    iconSet: "Ionicons",
    iconName: "star-half-sharp"
  },
  {
    uuid: "fe8d9ff4-b019-42f4-af06-1dc57716d1a3",
    iconSet: "Ionicons",
    iconName: "star-outline"
  },
  {
    uuid: "79e9e773-ece1-4167-8983-94c38057786d",
    iconSet: "Ionicons",
    iconName: "star-sharp"
  },
  {
    uuid: "17f4387c-6f8a-4c70-8ad2-60a5c004e6ed",
    iconSet: "Ionicons",
    iconName: "stats-chart"
  },
  {
    uuid: "f8e553f5-604c-42fb-bef7-750e1236e747",
    iconSet: "Ionicons",
    iconName: "stats-chart-outline"
  },
  {
    uuid: "f6415b4a-df90-4848-b192-74816fe6d30d",
    iconSet: "Ionicons",
    iconName: "stats-chart-sharp"
  },
  {
    uuid: "1f12c7a2-a933-4ad7-a809-eb8e1eca7101",
    iconSet: "Ionicons",
    iconName: "stop"
  },
  {
    uuid: "eef3afc7-bde6-490e-b5db-ca2c2ad9ab95",
    iconSet: "Ionicons",
    iconName: "stop-circle"
  },
  {
    uuid: "0d54d90c-070d-4fb8-ae8e-f49ca5b172e3",
    iconSet: "Ionicons",
    iconName: "stop-circle-outline"
  },
  {
    uuid: "3701ecf3-e370-4497-b589-cad02a311dea",
    iconSet: "Ionicons",
    iconName: "stop-circle-sharp"
  },
  {
    uuid: "b75892f8-4daf-4420-a567-af9d67a0843b",
    iconSet: "Ionicons",
    iconName: "stop-outline"
  },
  {
    uuid: "e06ac8ae-a062-4923-83df-61180b5a9442",
    iconSet: "Ionicons",
    iconName: "stop-sharp"
  },
  {
    uuid: "85218b59-c1a7-45f4-b402-54fe8e9b364a",
    iconSet: "Ionicons",
    iconName: "stopwatch"
  },
  {
    uuid: "05597404-1260-415a-a02f-7e823ca12930",
    iconSet: "Ionicons",
    iconName: "stopwatch-outline"
  },
  {
    uuid: "a6fc0932-37d4-41df-a02e-102a32cb2388",
    iconSet: "Ionicons",
    iconName: "stopwatch-sharp"
  },
  {
    uuid: "ce82fd5f-9723-459e-9039-8e0416954104",
    iconSet: "Ionicons",
    iconName: "subway"
  },
  {
    uuid: "49f664fa-a9d0-4c60-a81c-5a42f00a2db4",
    iconSet: "Ionicons",
    iconName: "subway-outline"
  },
  {
    uuid: "c7af7f92-5bfa-48cb-8c70-362d251cac83",
    iconSet: "Ionicons",
    iconName: "subway-sharp"
  },
  {
    uuid: "38e02529-4b85-484c-a1a8-16073c532b86",
    iconSet: "Ionicons",
    iconName: "sunny"
  },
  {
    uuid: "b6d2b200-ba3b-4fed-9fd2-5c7b6a5dfdda",
    iconSet: "Ionicons",
    iconName: "sunny-outline"
  },
  {
    uuid: "1d6db1fe-3a24-44e5-b3a1-3908682b22f1",
    iconSet: "Ionicons",
    iconName: "sunny-sharp"
  },
  {
    uuid: "293d7514-667a-441b-b833-a781bc7902d0",
    iconSet: "Ionicons",
    iconName: "swap-horizontal"
  },
  {
    uuid: "11d3a080-9b03-4318-af35-765a25aea96a",
    iconSet: "Ionicons",
    iconName: "swap-horizontal-outline"
  },
  {
    uuid: "1035a2f3-022e-4b57-a19b-abfd48a74162",
    iconSet: "Ionicons",
    iconName: "swap-horizontal-sharp"
  },
  {
    uuid: "ccf6459c-86ba-4896-b21d-cbc433be7426",
    iconSet: "Ionicons",
    iconName: "swap-vertical"
  },
  {
    uuid: "dc5368e9-5869-49ea-a01e-e95d4a5e641f",
    iconSet: "Ionicons",
    iconName: "swap-vertical-outline"
  },
  {
    uuid: "c184ee9f-e05d-49c9-a426-79268c9f4bca",
    iconSet: "Ionicons",
    iconName: "swap-vertical-sharp"
  },
  {
    uuid: "2ae5ca3f-1468-43e3-8013-3c657ab7f92d",
    iconSet: "Ionicons",
    iconName: "sync"
  },
  {
    uuid: "28544707-c2eb-4671-a5a0-88d6931662c5",
    iconSet: "Ionicons",
    iconName: "sync-circle"
  },
  {
    uuid: "b5af56d2-a913-4465-9d70-21bab17e0113",
    iconSet: "Ionicons",
    iconName: "sync-circle-outline"
  },
  {
    uuid: "d97d92ea-a440-48e1-a073-3d87ff523b04",
    iconSet: "Ionicons",
    iconName: "sync-circle-sharp"
  },
  {
    uuid: "3decce50-c454-4b6a-ad59-530c172cc05c",
    iconSet: "Ionicons",
    iconName: "sync-outline"
  },
  {
    uuid: "d5f9dae7-01b1-4d27-ba7c-339ed5a20bba",
    iconSet: "Ionicons",
    iconName: "sync-sharp"
  },
  {
    uuid: "d9237358-1cd2-4554-b990-60530112d747",
    iconSet: "Ionicons",
    iconName: "tablet-landscape"
  },
  {
    uuid: "abf145b8-7623-4919-a39a-444a79fa0fea",
    iconSet: "Ionicons",
    iconName: "tablet-landscape-outline"
  },
  {
    uuid: "b3c2d7e9-8e79-4c63-81f8-72e88e38ffee",
    iconSet: "Ionicons",
    iconName: "tablet-landscape-sharp"
  },
  {
    uuid: "2b71db39-9424-441f-8097-f715bdd13bbc",
    iconSet: "Ionicons",
    iconName: "tablet-portrait"
  },
  {
    uuid: "0eb15343-685d-4c95-adbf-9b7dcc6aec9b",
    iconSet: "Ionicons",
    iconName: "tablet-portrait-outline"
  },
  {
    uuid: "40a384b3-6217-49fd-b7a5-f5ef3fb9e624",
    iconSet: "Ionicons",
    iconName: "tablet-portrait-sharp"
  },
  {
    uuid: "05ab09fa-7144-4f26-8745-b45e89be2a6f",
    iconSet: "Ionicons",
    iconName: "tennisball"
  },
  {
    uuid: "325dff64-1c6f-4b9e-9c93-b330745f961e",
    iconSet: "Ionicons",
    iconName: "tennisball-outline"
  },
  {
    uuid: "a4f135d5-fdfe-4849-9b60-5b72eb1e9dc2",
    iconSet: "Ionicons",
    iconName: "tennisball-sharp"
  },
  {
    uuid: "e342b106-6ff4-4dba-89f8-5c2c67141d54",
    iconSet: "Ionicons",
    iconName: "terminal"
  },
  {
    uuid: "55e31017-18fd-4287-85e0-75d3eedadab9",
    iconSet: "Ionicons",
    iconName: "terminal-outline"
  },
  {
    uuid: "47ab3796-c087-445b-b970-f762efec963c",
    iconSet: "Ionicons",
    iconName: "terminal-sharp"
  },
  {
    uuid: "7d284b91-7036-4b2e-a174-561ec5df3fdd",
    iconSet: "Ionicons",
    iconName: "text"
  },
  {
    uuid: "fca6825e-3ed7-4e43-8ee2-3c9d92e466fe",
    iconSet: "Ionicons",
    iconName: "text-outline"
  },
  {
    uuid: "4f4de580-f129-41aa-bf0d-7898d368bc8e",
    iconSet: "Ionicons",
    iconName: "text-sharp"
  },
  {
    uuid: "3b22d273-4346-4a18-9f4e-bb0ecfbc8514",
    iconSet: "Ionicons",
    iconName: "thermometer"
  },
  {
    uuid: "2e33c64e-7c14-4f9f-87fa-16329d4eff1b",
    iconSet: "Ionicons",
    iconName: "thermometer-outline"
  },
  {
    uuid: "d5e1751f-4acd-46f5-a7d1-82aee5d74895",
    iconSet: "Ionicons",
    iconName: "thermometer-sharp"
  },
  {
    uuid: "24f17658-8d2b-40fb-9321-e8025b82ad17",
    iconSet: "Ionicons",
    iconName: "thumbs-down"
  },
  {
    uuid: "1ee19424-c91f-46f0-a8aa-6dba09d7e0de",
    iconSet: "Ionicons",
    iconName: "thumbs-down-outline"
  },
  {
    uuid: "bbd7ac1e-fba3-4b3f-b202-c5b2cc2cc930",
    iconSet: "Ionicons",
    iconName: "thumbs-down-sharp"
  },
  {
    uuid: "efceccfb-f988-4f09-8fdb-e8b1ade01000",
    iconSet: "Ionicons",
    iconName: "thumbs-up"
  },
  {
    uuid: "c723e7bd-1078-4561-af9f-74f2698ec8f7",
    iconSet: "Ionicons",
    iconName: "thumbs-up-outline"
  },
  {
    uuid: "3fdc6cb0-fd03-4f93-bfcd-eacd557a19f9",
    iconSet: "Ionicons",
    iconName: "thumbs-up-sharp"
  },
  {
    uuid: "fddc937c-ed1d-4dc6-afca-536c3408d633",
    iconSet: "Ionicons",
    iconName: "thunderstorm"
  },
  {
    uuid: "1973c3eb-b1ef-49fa-8759-60001ddce0bc",
    iconSet: "Ionicons",
    iconName: "thunderstorm-outline"
  },
  {
    uuid: "a5927ac5-c455-43c2-8ba6-643a15328833",
    iconSet: "Ionicons",
    iconName: "thunderstorm-sharp"
  },
  {
    uuid: "5a50a25e-008c-479e-a719-0efe2e219255",
    iconSet: "Ionicons",
    iconName: "time"
  },
  {
    uuid: "95d6bace-9e47-4e6b-a8b3-75eb21067adb",
    iconSet: "Ionicons",
    iconName: "time-outline"
  },
  {
    uuid: "ac401be8-f5f2-4150-a169-7c2d99376ea4",
    iconSet: "Ionicons",
    iconName: "time-sharp"
  },
  {
    uuid: "a255a168-44c5-4fb8-bef3-f9147571f080",
    iconSet: "Ionicons",
    iconName: "timer"
  },
  {
    uuid: "cf59a34a-740f-44af-bdde-99220e25912f",
    iconSet: "Ionicons",
    iconName: "timer-outline"
  },
  {
    uuid: "4140188a-777e-44d8-a98a-96d71b0b8035",
    iconSet: "Ionicons",
    iconName: "timer-sharp"
  },
  {
    uuid: "713d1488-f28f-4b60-ba39-9b57e8d6890f",
    iconSet: "Ionicons",
    iconName: "today"
  },
  {
    uuid: "3efffab9-2c6d-45cf-9a41-23b4f3356bb3",
    iconSet: "Ionicons",
    iconName: "today-outline"
  },
  {
    uuid: "44d1ae7d-e1d4-4335-b140-64e1e7ce10c7",
    iconSet: "Ionicons",
    iconName: "today-sharp"
  },
  {
    uuid: "94758979-2a74-44ff-8f40-f1a7d3103d85",
    iconSet: "Ionicons",
    iconName: "toggle"
  },
  {
    uuid: "85569d9b-82b7-48a7-84f4-1edfca031e3d",
    iconSet: "Ionicons",
    iconName: "toggle-outline"
  },
  {
    uuid: "48109c60-7e0d-439d-8d4f-625a49b4643e",
    iconSet: "Ionicons",
    iconName: "toggle-sharp"
  },
  {
    uuid: "84f652dc-27c2-4fb4-9cc6-b5e535a62555",
    iconSet: "Ionicons",
    iconName: "trail-sign"
  },
  {
    uuid: "6521fde1-b495-4438-895f-62d2c5775701",
    iconSet: "Ionicons",
    iconName: "trail-sign-outline"
  },
  {
    uuid: "1a41bddb-d093-4dda-b097-4665e927e4bd",
    iconSet: "Ionicons",
    iconName: "trail-sign-sharp"
  },
  {
    uuid: "ee4d78db-279c-4d1c-8e49-e3144541be34",
    iconSet: "Ionicons",
    iconName: "train"
  },
  {
    uuid: "bbdf8283-821e-48cc-b99e-e14ae2ae2ac1",
    iconSet: "Ionicons",
    iconName: "train-outline"
  },
  {
    uuid: "95f56835-ab46-41de-9e44-bb82689cbc4d",
    iconSet: "Ionicons",
    iconName: "train-sharp"
  },
  {
    uuid: "72a063b5-8f79-4a6a-9c9e-0fc48e138a9e",
    iconSet: "Ionicons",
    iconName: "transgender"
  },
  {
    uuid: "52bb88a9-d9d7-4a91-9327-026289089c37",
    iconSet: "Ionicons",
    iconName: "transgender-outline"
  },
  {
    uuid: "c2649345-d972-4b98-a121-404c1da2cc16",
    iconSet: "Ionicons",
    iconName: "transgender-sharp"
  },
  {
    uuid: "2031b017-d63b-4e3d-9561-c254c1c01c0a",
    iconSet: "Ionicons",
    iconName: "trash"
  },
  {
    uuid: "d249a12f-7e14-4ada-b22a-cdbf5039ea43",
    iconSet: "Ionicons",
    iconName: "trash-bin"
  },
  {
    uuid: "9f301b29-3e73-4055-a543-461815ab721e",
    iconSet: "Ionicons",
    iconName: "trash-bin-outline"
  },
  {
    uuid: "e0f8ca9a-8afd-4452-a17d-9b12c5caa939",
    iconSet: "Ionicons",
    iconName: "trash-bin-sharp"
  },
  {
    uuid: "4181bf53-1606-42eb-bc1d-420779c8fac6",
    iconSet: "Ionicons",
    iconName: "trash-outline"
  },
  {
    uuid: "6254ea43-39a7-46e0-a3a1-f0caa79643e7",
    iconSet: "Ionicons",
    iconName: "trash-sharp"
  },
  {
    uuid: "faf840e9-44b9-4c6a-aa5a-8fa0ba232a9a",
    iconSet: "Ionicons",
    iconName: "trending-down"
  },
  {
    uuid: "f3d35463-0d21-4139-be6e-e7cd093b6f91",
    iconSet: "Ionicons",
    iconName: "trending-down-outline"
  },
  {
    uuid: "b475f3b6-bc87-4ed2-803e-e7a555a58247",
    iconSet: "Ionicons",
    iconName: "trending-down-sharp"
  },
  {
    uuid: "31937e4b-88bc-4c64-af6a-0e7b9bd9da36",
    iconSet: "Ionicons",
    iconName: "trending-up"
  },
  {
    uuid: "31736fe2-ef00-4672-a0ac-54bcc9750e56",
    iconSet: "Ionicons",
    iconName: "trending-up-outline"
  },
  {
    uuid: "2864a721-307d-4777-948a-14435b1edfa3",
    iconSet: "Ionicons",
    iconName: "trending-up-sharp"
  },
  {
    uuid: "701f9646-126a-4c94-9888-5dbd4f74f265",
    iconSet: "Ionicons",
    iconName: "triangle"
  },
  {
    uuid: "0374834b-69a1-4b68-9780-b75bc9f51f0e",
    iconSet: "Ionicons",
    iconName: "triangle-outline"
  },
  {
    uuid: "662cb6d5-5072-450c-aee2-0c5a0e659766",
    iconSet: "Ionicons",
    iconName: "triangle-sharp"
  },
  {
    uuid: "52faa5a3-829e-49c0-80df-f7803291f3f1",
    iconSet: "Ionicons",
    iconName: "trophy"
  },
  {
    uuid: "d7ec32a5-8791-4be5-bac8-d36eda22441d",
    iconSet: "Ionicons",
    iconName: "trophy-outline"
  },
  {
    uuid: "4e806fa9-50d1-4b7c-9d1c-1ffde1b87794",
    iconSet: "Ionicons",
    iconName: "trophy-sharp"
  },
  {
    uuid: "d057fc01-b41f-4f93-88e4-cf8708373f42",
    iconSet: "Ionicons",
    iconName: "tv"
  },
  {
    uuid: "edcd4f57-f950-4ece-ade2-c91b4e153054",
    iconSet: "Ionicons",
    iconName: "tv-outline"
  },
  {
    uuid: "7128948e-6e19-4d4d-af2b-fb8290d648f6",
    iconSet: "Ionicons",
    iconName: "tv-sharp"
  },
  {
    uuid: "59ca2bf8-7c6e-4ecd-997c-c7e4e7721976",
    iconSet: "Ionicons",
    iconName: "umbrella"
  },
  {
    uuid: "ce9645e5-aed3-4a65-930d-0532c1d6e4ef",
    iconSet: "Ionicons",
    iconName: "umbrella-outline"
  },
  {
    uuid: "8fbd8889-0034-4e45-9af6-c20c4e043059",
    iconSet: "Ionicons",
    iconName: "umbrella-sharp"
  },
  {
    uuid: "44aa69fa-aa39-49b2-aee1-594df6b318e0",
    iconSet: "Ionicons",
    iconName: "videocam"
  },
  {
    uuid: "176cebf0-4a47-4905-9862-149b12a553c8",
    iconSet: "Ionicons",
    iconName: "videocam-outline"
  },
  {
    uuid: "b0ecf129-481c-4cbe-a3b7-ee3e82c6a8b8",
    iconSet: "Ionicons",
    iconName: "videocam-sharp"
  },
  {
    uuid: "6c59e8d2-c32f-4fbd-806f-ed620609e5e2",
    iconSet: "Ionicons",
    iconName: "volume-high"
  },
  {
    uuid: "1b4df975-e7b1-469b-b126-09a68c5928f7",
    iconSet: "Ionicons",
    iconName: "volume-high-outline"
  },
  {
    uuid: "57d30f63-43c6-42e5-8894-094f3b00241f",
    iconSet: "Ionicons",
    iconName: "volume-high-sharp"
  },
  {
    uuid: "75de31fb-f443-4b8f-a5c5-29f55fbb6846",
    iconSet: "Ionicons",
    iconName: "volume-low"
  },
  {
    uuid: "5aad9bb9-fbca-4ad0-acef-96c9cc0b0a21",
    iconSet: "Ionicons",
    iconName: "volume-low-outline"
  },
  {
    uuid: "36d6044d-c12a-4cdd-bfc5-a0eefdebfc73",
    iconSet: "Ionicons",
    iconName: "volume-low-sharp"
  },
  {
    uuid: "6bfbe73d-e176-414a-9587-efcbfed04994",
    iconSet: "Ionicons",
    iconName: "volume-medium"
  },
  {
    uuid: "9faf2cc2-4cda-46bb-9007-271f80bb3022",
    iconSet: "Ionicons",
    iconName: "volume-medium-outline"
  },
  {
    uuid: "07b87af4-069b-4490-8186-c63b6b705bcf",
    iconSet: "Ionicons",
    iconName: "volume-medium-sharp"
  },
  {
    uuid: "ac40da3d-f7a6-44ad-9f76-9ea98ca74f9b",
    iconSet: "Ionicons",
    iconName: "volume-mute"
  },
  {
    uuid: "98d9618a-2e70-4ec8-9d60-648b3d7cecdf",
    iconSet: "Ionicons",
    iconName: "volume-mute-outline"
  },
  {
    uuid: "8d9800f7-23e4-44ed-955e-a5874d171fc5",
    iconSet: "Ionicons",
    iconName: "volume-mute-sharp"
  },
  {
    uuid: "9cd7ae98-f37c-4a18-b31f-c1ecf1be4cfe",
    iconSet: "Ionicons",
    iconName: "volume-off"
  },
  {
    uuid: "1e3a3e28-80b9-4783-b891-9b288cf6d230",
    iconSet: "Ionicons",
    iconName: "volume-off-outline"
  },
  {
    uuid: "c7c6362d-32f2-4e10-af3c-a0ff72c1eb52",
    iconSet: "Ionicons",
    iconName: "volume-off-sharp"
  },
  {
    uuid: "07eb9669-c82c-4cea-9ac2-70c250c94bff",
    iconSet: "Ionicons",
    iconName: "walk"
  },
  {
    uuid: "93c1b847-ea7a-41ab-a8cd-0ce66a9c1965",
    iconSet: "Ionicons",
    iconName: "walk-outline"
  },
  {
    uuid: "15a3d565-5384-4d22-b9a4-e9bda1a3596d",
    iconSet: "Ionicons",
    iconName: "walk-sharp"
  },
  {
    uuid: "eafd7be9-a483-4515-af34-d189af7648ea",
    iconSet: "Ionicons",
    iconName: "wallet"
  },
  {
    uuid: "2a6137dc-e3f3-4666-8eb4-7389140477d2",
    iconSet: "Ionicons",
    iconName: "wallet-outline"
  },
  {
    uuid: "c3c94af3-1f99-42f5-ab04-6c30e9343ad9",
    iconSet: "Ionicons",
    iconName: "wallet-sharp"
  },
  {
    uuid: "a4c557b9-af4b-4ce7-9cc0-e92ac0408608",
    iconSet: "Ionicons",
    iconName: "warning"
  },
  {
    uuid: "b572bbee-0304-4e3a-b1ef-baf3dde70da2",
    iconSet: "Ionicons",
    iconName: "warning-outline"
  },
  {
    uuid: "e9ddcd69-fc9d-4e48-8f9c-4781cba50dfd",
    iconSet: "Ionicons",
    iconName: "warning-sharp"
  },
  {
    uuid: "0cb740df-7995-4790-af6e-5ed20f5acab9",
    iconSet: "Ionicons",
    iconName: "watch"
  },
  {
    uuid: "9e31bae0-a2ac-41bd-bd00-35de7410be1c",
    iconSet: "Ionicons",
    iconName: "watch-outline"
  },
  {
    uuid: "b8c6f84e-c034-42dc-a0f1-01b974558d4c",
    iconSet: "Ionicons",
    iconName: "watch-sharp"
  },
  {
    uuid: "7efc272e-1f64-447b-97bc-deee34ec6e94",
    iconSet: "Ionicons",
    iconName: "water"
  },
  {
    uuid: "a0b720ad-403d-4d45-8133-cf1db4e1b9d4",
    iconSet: "Ionicons",
    iconName: "water-outline"
  },
  {
    uuid: "e0dc1d93-564b-4477-a9ff-6548fdf72e4f",
    iconSet: "Ionicons",
    iconName: "water-sharp"
  },
  {
    uuid: "e5f39092-eac4-49d4-8a59-9c8685e91cb4",
    iconSet: "Ionicons",
    iconName: "wifi"
  },
  {
    uuid: "dda2d9df-e636-4e22-af51-35076402047a",
    iconSet: "Ionicons",
    iconName: "wifi-outline"
  },
  {
    uuid: "16d20d56-42b7-41d6-9434-01f28a62ca1f",
    iconSet: "Ionicons",
    iconName: "wifi-sharp"
  },
  {
    uuid: "8ea6ff25-75b7-46a2-ae83-02e3b924be23",
    iconSet: "Ionicons",
    iconName: "wine"
  },
  {
    uuid: "01d05193-6fd3-4022-a1b5-fd7063341f68",
    iconSet: "Ionicons",
    iconName: "wine-outline"
  },
  {
    uuid: "fb91312d-7b36-4890-a2a7-bcb2950d5480",
    iconSet: "Ionicons",
    iconName: "wine-sharp"
  },
  {
    uuid: "caf7a52b-621c-463f-aa60-2865586a8120",
    iconSet: "Ionicons",
    iconName: "woman"
  },
  {
    uuid: "b7255186-117b-4112-88ab-d26da0a8a1c0",
    iconSet: "Ionicons",
    iconName: "woman-outline"
  },
  {
    uuid: "3feef755-6788-4613-9228-3c38f26ba45e",
    iconSet: "Ionicons",
    iconName: "woman-sharp"
  },
  {
    uuid: "868cbfe6-009b-43fc-8807-377b5400514a",
    iconSet: "MaterialCommunityIcons",
    iconName: "ab-testing"
  },
  {
    uuid: "3134f799-3fca-47db-9f81-17481567d12d",
    iconSet: "MaterialCommunityIcons",
    iconName: "abjad-arabic"
  },
  {
    uuid: "8c2683fe-367a-4aaf-a2e0-f84459c0fc96",
    iconSet: "MaterialCommunityIcons",
    iconName: "abjad-hebrew"
  },
  {
    uuid: "405c84d5-ca88-4718-9341-50abdc808502",
    iconSet: "MaterialCommunityIcons",
    iconName: "abugida-devanagari"
  },
  {
    uuid: "d5a319ce-45a8-4ae8-b6bc-84427cb4ecf3",
    iconSet: "MaterialCommunityIcons",
    iconName: "abugida-thai"
  },
  {
    uuid: "b52b7f55-c0ea-4985-b0c3-6f4e1027ad88",
    iconSet: "MaterialCommunityIcons",
    iconName: "access-point"
  },
  {
    uuid: "4954666b-4482-439a-b031-98cabe86de52",
    iconSet: "MaterialCommunityIcons",
    iconName: "access-point-network"
  },
  {
    uuid: "7b126e94-5ca6-4776-b1bc-1bc28ae68925",
    iconSet: "MaterialCommunityIcons",
    iconName: "access-point-network-off"
  },
  {
    uuid: "d94733b0-1bc6-4d41-b0a6-0f29ca69c8e2",
    iconSet: "MaterialCommunityIcons",
    iconName: "account"
  },
  {
    uuid: "b74b8426-6665-4665-9d21-33138be7d560",
    iconSet: "MaterialCommunityIcons",
    iconName: "account-alert"
  },
  {
    uuid: "b7c2fe05-0002-4e02-92ed-eac9dc0eb435",
    iconSet: "MaterialCommunityIcons",
    iconName: "account-alert-outline"
  },
  {
    uuid: "4f7f5590-bba1-4027-b077-9394382b2df6",
    iconSet: "MaterialCommunityIcons",
    iconName: "account-arrow-left"
  },
  {
    uuid: "1b895a00-3fe7-4046-b2e3-2ee218d73011",
    iconSet: "MaterialCommunityIcons",
    iconName: "account-arrow-left-outline"
  },
  {
    uuid: "789bd3ce-7e3c-42d6-8471-17d8904c5547",
    iconSet: "MaterialCommunityIcons",
    iconName: "account-arrow-right"
  },
  {
    uuid: "c3c53405-857f-41a2-ad08-1b1a9a449d71",
    iconSet: "MaterialCommunityIcons",
    iconName: "account-arrow-right-outline"
  },
  {
    uuid: "7b528912-4f7a-4c72-8ee5-498439ebabdf",
    iconSet: "MaterialCommunityIcons",
    iconName: "account-box"
  },
  {
    uuid: "68ae79a1-df1a-4ade-adb7-f05f84d00373",
    iconSet: "MaterialCommunityIcons",
    iconName: "account-box-multiple"
  },
  {
    uuid: "f3260463-48c0-4b2c-b239-78a13c51d66f",
    iconSet: "MaterialCommunityIcons",
    iconName: "account-box-multiple-outline"
  },
  {
    uuid: "3f5aaf25-d8c7-479f-96ae-7c9af8b6743b",
    iconSet: "MaterialCommunityIcons",
    iconName: "account-box-outline"
  },
  {
    uuid: "fbdaa229-7eb2-4ef9-a833-d5de73864373",
    iconSet: "MaterialCommunityIcons",
    iconName: "account-cancel"
  },
  {
    uuid: "8f64883c-40f7-417e-b650-d963c390e2e7",
    iconSet: "MaterialCommunityIcons",
    iconName: "account-cancel-outline"
  },
  {
    uuid: "3612653d-735c-4634-a3f3-5f5596865172",
    iconSet: "MaterialCommunityIcons",
    iconName: "account-cash"
  },
  {
    uuid: "e788d056-5655-41b4-9d60-5ec7c4b01c25",
    iconSet: "MaterialCommunityIcons",
    iconName: "account-cash-outline"
  },
  {
    uuid: "84fd56d8-df65-43df-b889-9ed5863528a2",
    iconSet: "MaterialCommunityIcons",
    iconName: "account-check"
  },
  {
    uuid: "9b095aa2-9b75-41e7-87a4-4545d7e8bf0b",
    iconSet: "MaterialCommunityIcons",
    iconName: "account-check-outline"
  },
  {
    uuid: "6d631598-35d8-4cd7-b664-5c7575f22354",
    iconSet: "MaterialCommunityIcons",
    iconName: "account-child"
  },
  {
    uuid: "0fedbc9b-c1d2-479f-9025-bfa6dc9b3e8a",
    iconSet: "MaterialCommunityIcons",
    iconName: "account-child-circle"
  },
  {
    uuid: "12f92c99-ae51-44b8-8f49-774a21237d6c",
    iconSet: "MaterialCommunityIcons",
    iconName: "account-child-outline"
  },
  {
    uuid: "31e98d15-ce6f-480e-b09e-e858809f1fa8",
    iconSet: "MaterialCommunityIcons",
    iconName: "account-circle"
  },
  {
    uuid: "f49a97ae-5457-40e1-90d4-4810f9e22dcc",
    iconSet: "MaterialCommunityIcons",
    iconName: "account-circle-outline"
  },
  {
    uuid: "f74f5855-c339-4f42-825a-132e66d5eb21",
    iconSet: "MaterialCommunityIcons",
    iconName: "account-clock"
  },
  {
    uuid: "9c457d68-e366-4c25-9080-ff2aef6b8bb6",
    iconSet: "MaterialCommunityIcons",
    iconName: "account-clock-outline"
  },
  {
    uuid: "9375ff4d-1430-49c7-aecb-2b0776c0efbe",
    iconSet: "MaterialCommunityIcons",
    iconName: "account-cog"
  },
  {
    uuid: "32c1262c-8835-4f88-a056-b13ffe92f0b8",
    iconSet: "MaterialCommunityIcons",
    iconName: "account-cog-outline"
  },
  {
    uuid: "5914e996-57bc-4584-b96c-b2d93ac5ba49",
    iconSet: "MaterialCommunityIcons",
    iconName: "account-convert"
  },
  {
    uuid: "27323e61-5684-4c23-8178-6abc020e3dee",
    iconSet: "MaterialCommunityIcons",
    iconName: "account-convert-outline"
  },
  {
    uuid: "85c7a69e-f2d8-4df0-aff5-e7b9273c4b3f",
    iconSet: "MaterialCommunityIcons",
    iconName: "account-cowboy-hat"
  },
  {
    uuid: "76db79ad-8cbb-4a30-bd5b-18776bc8182d",
    iconSet: "MaterialCommunityIcons",
    iconName: "account-details"
  },
  {
    uuid: "819fcdd4-b6ac-48f6-adbe-59f539edd257",
    iconSet: "MaterialCommunityIcons",
    iconName: "account-details-outline"
  },
  {
    uuid: "01f4f330-b29e-481a-a90a-8b5601e9c0bf",
    iconSet: "MaterialCommunityIcons",
    iconName: "account-edit"
  },
  {
    uuid: "98065d49-2b26-46e3-9f74-88d6cdf4c20b",
    iconSet: "MaterialCommunityIcons",
    iconName: "account-edit-outline"
  },
  {
    uuid: "04133c6d-ed1d-436a-9ae5-75289578ae97",
    iconSet: "MaterialCommunityIcons",
    iconName: "account-group"
  },
  {
    uuid: "f8d57aed-e427-44f9-8b72-00fd3b670122",
    iconSet: "MaterialCommunityIcons",
    iconName: "account-group-outline"
  },
  {
    uuid: "1b30896b-114f-4e03-8f3d-68119c7a50b3",
    iconSet: "MaterialCommunityIcons",
    iconName: "account-hard-hat"
  },
  {
    uuid: "be1c5e61-0b7f-4ba8-94b2-4e7acf27d5cd",
    iconSet: "MaterialCommunityIcons",
    iconName: "account-heart"
  },
  {
    uuid: "3646c6a2-1b38-4f88-9baf-2fef483423b9",
    iconSet: "MaterialCommunityIcons",
    iconName: "account-heart-outline"
  },
  {
    uuid: "ca1e088a-0c2f-489c-862c-411d9d705d48",
    iconSet: "MaterialCommunityIcons",
    iconName: "account-key"
  },
  {
    uuid: "4617c20a-d8b3-4da3-9126-b35a8ac4b314",
    iconSet: "MaterialCommunityIcons",
    iconName: "account-key-outline"
  },
  {
    uuid: "ff84ff43-6a4a-4aa6-9f6d-28705ac646f1",
    iconSet: "MaterialCommunityIcons",
    iconName: "account-lock"
  },
  {
    uuid: "75f9e477-7bed-40ad-b4e9-fca0f72d8751",
    iconSet: "MaterialCommunityIcons",
    iconName: "account-lock-outline"
  },
  {
    uuid: "45695be3-fe43-4384-abd4-bc7dfbee9a76",
    iconSet: "MaterialCommunityIcons",
    iconName: "account-minus"
  },
  {
    uuid: "d016d6e7-5ef5-4f46-871e-1e66c617c01c",
    iconSet: "MaterialCommunityIcons",
    iconName: "account-minus-outline"
  },
  {
    uuid: "55644b39-36e7-4065-9c77-220aea587fac",
    iconSet: "MaterialCommunityIcons",
    iconName: "account-multiple"
  },
  {
    uuid: "6ba56da7-e21f-4921-9150-d4ba487e3d57",
    iconSet: "MaterialCommunityIcons",
    iconName: "account-multiple-check"
  },
  {
    uuid: "892c697a-f6b9-4560-93d7-5545d2801a76",
    iconSet: "MaterialCommunityIcons",
    iconName: "account-multiple-check-outline"
  },
  {
    uuid: "d1d641e7-202b-4f64-90fd-f5e4b919d368",
    iconSet: "MaterialCommunityIcons",
    iconName: "account-multiple-minus"
  },
  {
    uuid: "fd2d7203-8422-4587-b8f7-5af2395f2da7",
    iconSet: "MaterialCommunityIcons",
    iconName: "account-multiple-minus-outline"
  },
  {
    uuid: "1857cc41-85e8-4393-afae-2d0c08750d4f",
    iconSet: "MaterialCommunityIcons",
    iconName: "account-multiple-outline"
  },
  {
    uuid: "8625a7a9-179a-4b70-ad76-cb5e3ddf2c25",
    iconSet: "MaterialCommunityIcons",
    iconName: "account-multiple-plus"
  },
  {
    uuid: "9fb5e5bc-8e8d-4aff-a574-766f443aa5cb",
    iconSet: "MaterialCommunityIcons",
    iconName: "account-multiple-plus-outline"
  },
  {
    uuid: "2cc32e92-1a27-4df3-b8d7-b01e8ec7e3a1",
    iconSet: "MaterialCommunityIcons",
    iconName: "account-multiple-remove"
  },
  {
    uuid: "1f88c467-ff74-4a05-9ebb-e2d988df0241",
    iconSet: "MaterialCommunityIcons",
    iconName: "account-multiple-remove-outline"
  },
  {
    uuid: "fe7adaeb-0b0b-4343-96dc-9f420ef310f5",
    iconSet: "MaterialCommunityIcons",
    iconName: "account-music"
  },
  {
    uuid: "afb54f61-e7bd-4b92-bd61-30a63dcb45b6",
    iconSet: "MaterialCommunityIcons",
    iconName: "account-music-outline"
  },
  {
    uuid: "5ade3711-e77c-4a15-ab1a-e3ae973a3004",
    iconSet: "MaterialCommunityIcons",
    iconName: "account-network"
  },
  {
    uuid: "02a92116-e55c-491d-8666-b39f84f9676a",
    iconSet: "MaterialCommunityIcons",
    iconName: "account-network-outline"
  },
  {
    uuid: "555086b4-de5d-448b-97a8-50efa89bd581",
    iconSet: "MaterialCommunityIcons",
    iconName: "account-off"
  },
  {
    uuid: "195c8cc2-248b-45f1-b1c4-3ee65bfa84d2",
    iconSet: "MaterialCommunityIcons",
    iconName: "account-off-outline"
  },
  {
    uuid: "7ba3385a-f2ac-41d7-93d9-8138f4a7e433",
    iconSet: "MaterialCommunityIcons",
    iconName: "account-outline"
  },
  {
    uuid: "b45729ca-f33f-4089-847a-31b08e1c9ae7",
    iconSet: "MaterialCommunityIcons",
    iconName: "account-plus"
  },
  {
    uuid: "0539800b-1cb6-4603-9bf9-c08b235265a8",
    iconSet: "MaterialCommunityIcons",
    iconName: "account-plus-outline"
  },
  {
    uuid: "8c738fb9-e7fd-4c90-b2a7-e7e96c03dc5c",
    iconSet: "MaterialCommunityIcons",
    iconName: "account-question"
  },
  {
    uuid: "0965fdba-9c2f-466d-b5c9-4002453cbb51",
    iconSet: "MaterialCommunityIcons",
    iconName: "account-question-outline"
  },
  {
    uuid: "2ccd57fe-1ac5-4de8-b0c7-82c1812377d2",
    iconSet: "MaterialCommunityIcons",
    iconName: "account-remove"
  },
  {
    uuid: "037bf6d6-ab1e-4400-b7ca-921da4af568c",
    iconSet: "MaterialCommunityIcons",
    iconName: "account-remove-outline"
  },
  {
    uuid: "1e201801-812a-4211-a346-80baf4cba96f",
    iconSet: "MaterialCommunityIcons",
    iconName: "account-search"
  },
  {
    uuid: "22d7f4c3-34fb-4a52-83c0-ab828b51503b",
    iconSet: "MaterialCommunityIcons",
    iconName: "account-search-outline"
  },
  {
    uuid: "9b5478a7-eaf6-4bfe-bbc5-12ce455aa55b",
    iconSet: "MaterialCommunityIcons",
    iconName: "account-settings"
  },
  {
    uuid: "a56efc1f-9595-4aaa-abf7-475fc869062f",
    iconSet: "MaterialCommunityIcons",
    iconName: "account-settings-outline"
  },
  {
    uuid: "c3714dbd-8975-4868-babd-49e7ba5ab164",
    iconSet: "MaterialCommunityIcons",
    iconName: "account-star"
  },
  {
    uuid: "e8bbfe51-1398-4690-bec0-2d0581314749",
    iconSet: "MaterialCommunityIcons",
    iconName: "account-star-outline"
  },
  {
    uuid: "861893cd-f879-4af3-babc-3715c7d18fcc",
    iconSet: "MaterialCommunityIcons",
    iconName: "account-supervisor"
  },
  {
    uuid: "2c54677e-e94e-4c5b-a8a6-63b63ed59f99",
    iconSet: "MaterialCommunityIcons",
    iconName: "account-supervisor-circle"
  },
  {
    uuid: "cef75dc9-fd18-49d3-aef9-8cd783050d17",
    iconSet: "MaterialCommunityIcons",
    iconName: "account-supervisor-outline"
  },
  {
    uuid: "4ff7fe5b-60c9-4bde-ae56-3f43530529a6",
    iconSet: "MaterialCommunityIcons",
    iconName: "account-switch"
  },
  {
    uuid: "70305999-c899-4eb6-a63b-ccf174581915",
    iconSet: "MaterialCommunityIcons",
    iconName: "account-switch-outline"
  },
  {
    uuid: "71a2b3a7-2e61-465e-a074-c5ebd3af1730",
    iconSet: "MaterialCommunityIcons",
    iconName: "account-tie"
  },
  {
    uuid: "a6d2425f-00b5-4281-a0fa-710155f83287",
    iconSet: "MaterialCommunityIcons",
    iconName: "account-tie-outline"
  },
  {
    uuid: "15ff600f-2e7c-4226-9462-5c5a32c739c7",
    iconSet: "MaterialCommunityIcons",
    iconName: "account-tie-voice"
  },
  {
    uuid: "9b89fbec-7362-4d2e-a6f5-f33d0160266a",
    iconSet: "MaterialCommunityIcons",
    iconName: "account-tie-voice-off"
  },
  {
    uuid: "60181d45-1016-4fdf-877d-7da25910c013",
    iconSet: "MaterialCommunityIcons",
    iconName: "account-tie-voice-off-outline"
  },
  {
    uuid: "9df032a6-3f36-4ddc-9a1c-cb34fce34ced",
    iconSet: "MaterialCommunityIcons",
    iconName: "account-tie-voice-outline"
  },
  {
    uuid: "34db0352-a050-42ea-8110-5e621b946d70",
    iconSet: "MaterialCommunityIcons",
    iconName: "account-voice"
  },
  {
    uuid: "9702fa51-ab73-47f5-8793-a546267fd6c0",
    iconSet: "MaterialCommunityIcons",
    iconName: "adjust"
  },
  {
    uuid: "5118b71b-6701-4e5f-98d7-5ef10dd5500b",
    iconSet: "MaterialCommunityIcons",
    iconName: "adobe"
  },
  {
    uuid: "9d112ec3-ffe4-406b-9130-cd1fda2dc5ec",
    iconSet: "MaterialCommunityIcons",
    iconName: "adobe-acrobat"
  },
  {
    uuid: "bdff9898-8580-44ec-a450-85a37249e290",
    iconSet: "MaterialCommunityIcons",
    iconName: "air-conditioner"
  },
  {
    uuid: "79aefd62-7f1d-4743-a6e8-0948d5ee1d4f",
    iconSet: "MaterialCommunityIcons",
    iconName: "air-filter"
  },
  {
    uuid: "77f6715f-c03e-421e-b97e-e8f385c7da15",
    iconSet: "MaterialCommunityIcons",
    iconName: "air-horn"
  },
  {
    uuid: "57d3da9e-98b0-4308-934f-67b7897b70f0",
    iconSet: "MaterialCommunityIcons",
    iconName: "air-humidifier"
  },
  {
    uuid: "7d099317-6391-46de-90d2-e1a5a29c5f62",
    iconSet: "MaterialCommunityIcons",
    iconName: "air-humidifier-off"
  },
  {
    uuid: "0f333278-6f71-4dae-bfd4-4507811417e7",
    iconSet: "MaterialCommunityIcons",
    iconName: "air-purifier"
  },
  {
    uuid: "7a43739c-7d4c-4569-8275-c91763fc3d7d",
    iconSet: "MaterialCommunityIcons",
    iconName: "airbag"
  },
  {
    uuid: "728b906b-d7b8-4ff8-a7b7-3f7564971d13",
    iconSet: "MaterialCommunityIcons",
    iconName: "airballoon"
  },
  {
    uuid: "93b38409-ccad-43a3-9415-c67e329e07b9",
    iconSet: "MaterialCommunityIcons",
    iconName: "airballoon-outline"
  },
  {
    uuid: "34a2152b-5efd-4ffb-8996-0e54b1cc38d8",
    iconSet: "MaterialCommunityIcons",
    iconName: "airplane"
  },
  {
    uuid: "e0a467d2-e2c8-4ef5-9b24-eace364c6fc5",
    iconSet: "MaterialCommunityIcons",
    iconName: "airplane-landing"
  },
  {
    uuid: "a456c39e-50f8-4ff6-8f04-1f43339c2316",
    iconSet: "MaterialCommunityIcons",
    iconName: "airplane-off"
  },
  {
    uuid: "b8cd5034-1410-444f-b88e-3908e6cdaef8",
    iconSet: "MaterialCommunityIcons",
    iconName: "airplane-takeoff"
  },
  {
    uuid: "3a7d4a2f-c328-4478-869f-44840d7969ba",
    iconSet: "MaterialCommunityIcons",
    iconName: "airport"
  },
  {
    uuid: "2cbd4f38-2d5b-4047-8c6e-89d8915e1059",
    iconSet: "MaterialCommunityIcons",
    iconName: "alarm"
  },
  {
    uuid: "50afad52-cc30-45db-bb47-044ccfe07258",
    iconSet: "MaterialCommunityIcons",
    iconName: "alarm-bell"
  },
  {
    uuid: "8ebb9004-3d0d-4a14-b2c2-14c962fd2ded",
    iconSet: "MaterialCommunityIcons",
    iconName: "alarm-check"
  },
  {
    uuid: "f86a5c8c-2343-41ad-8150-aa73bb305596",
    iconSet: "MaterialCommunityIcons",
    iconName: "alarm-light"
  },
  {
    uuid: "eaba3650-53c5-41a8-8da0-1a0329d2f03b",
    iconSet: "MaterialCommunityIcons",
    iconName: "alarm-light-outline"
  },
  {
    uuid: "500ffdf9-e2ad-4c76-a441-4abf93305548",
    iconSet: "MaterialCommunityIcons",
    iconName: "alarm-multiple"
  },
  {
    uuid: "aeb067b5-ca79-408b-8d4e-f5af18ffdadd",
    iconSet: "MaterialCommunityIcons",
    iconName: "alarm-note"
  },
  {
    uuid: "75ac3325-d761-433f-be94-e5027c82d010",
    iconSet: "MaterialCommunityIcons",
    iconName: "alarm-note-off"
  },
  {
    uuid: "80beb024-6ea9-4321-a13c-5c106ec01513",
    iconSet: "MaterialCommunityIcons",
    iconName: "alarm-off"
  },
  {
    uuid: "5f88b9dc-7941-4b50-98ad-a0cc9c9e8276",
    iconSet: "MaterialCommunityIcons",
    iconName: "alarm-plus"
  },
  {
    uuid: "9eb6ed4c-0a86-4f4a-8d98-e2c968fc1408",
    iconSet: "MaterialCommunityIcons",
    iconName: "alarm-snooze"
  },
  {
    uuid: "343c9e96-6dd4-4748-8cba-3931197dc3dc",
    iconSet: "MaterialCommunityIcons",
    iconName: "album"
  },
  {
    uuid: "d36ceb8f-b6c6-4bbd-a697-bfd3cd17b087",
    iconSet: "MaterialCommunityIcons",
    iconName: "alert"
  },
  {
    uuid: "a875f754-2286-40f0-9a0f-b77456c6f2a6",
    iconSet: "MaterialCommunityIcons",
    iconName: "alert-box"
  },
  {
    uuid: "b9117e9d-54fd-46eb-b06d-3afdc69c9176",
    iconSet: "MaterialCommunityIcons",
    iconName: "alert-box-outline"
  },
  {
    uuid: "b34eab17-7654-413d-bd8d-06978d7fc1e5",
    iconSet: "MaterialCommunityIcons",
    iconName: "alert-circle"
  },
  {
    uuid: "e4dad3db-8c58-40f5-9107-d7ded8fcde6b",
    iconSet: "MaterialCommunityIcons",
    iconName: "alert-circle-check"
  },
  {
    uuid: "c25b2996-54d6-462a-9eaf-531213b0b021",
    iconSet: "MaterialCommunityIcons",
    iconName: "alert-circle-check-outline"
  },
  {
    uuid: "829a9430-fe20-48d7-b06e-c78a32756834",
    iconSet: "MaterialCommunityIcons",
    iconName: "alert-circle-outline"
  },
  {
    uuid: "7743a9ff-9c86-4310-b293-98af867b40e3",
    iconSet: "MaterialCommunityIcons",
    iconName: "alert-decagram"
  },
  {
    uuid: "4faeb0c3-87b7-441c-8cbd-c6bd31ec0649",
    iconSet: "MaterialCommunityIcons",
    iconName: "alert-decagram-outline"
  },
  {
    uuid: "3fbee760-6730-41c8-8881-a485da59f4a3",
    iconSet: "MaterialCommunityIcons",
    iconName: "alert-minus"
  },
  {
    uuid: "7f4b9670-2539-43d5-9400-03d0c251c850",
    iconSet: "MaterialCommunityIcons",
    iconName: "alert-minus-outline"
  },
  {
    uuid: "5a60e30e-5ece-4701-ad0d-223131460c27",
    iconSet: "MaterialCommunityIcons",
    iconName: "alert-octagon"
  },
  {
    uuid: "c3df3fc4-f09d-4ff2-b308-4b2d91ef1b43",
    iconSet: "MaterialCommunityIcons",
    iconName: "alert-octagon-outline"
  },
  {
    uuid: "29e25000-4378-4ebe-b76d-535140e15dba",
    iconSet: "MaterialCommunityIcons",
    iconName: "alert-octagram"
  },
  {
    uuid: "3cd49cb8-1624-4746-ab53-41b684f99b84",
    iconSet: "MaterialCommunityIcons",
    iconName: "alert-octagram-outline"
  },
  {
    uuid: "deac232a-3d8f-4bf4-a4f4-4e265417f1dd",
    iconSet: "MaterialCommunityIcons",
    iconName: "alert-outline"
  },
  {
    uuid: "4b347ee5-5194-4895-af8c-6dd82d5621d0",
    iconSet: "MaterialCommunityIcons",
    iconName: "alert-plus"
  },
  {
    uuid: "c9c6b8a6-3411-4676-97c6-22a9d5d11998",
    iconSet: "MaterialCommunityIcons",
    iconName: "alert-plus-outline"
  },
  {
    uuid: "077cc12c-ec02-4404-8e59-13c5fc4d6bad",
    iconSet: "MaterialCommunityIcons",
    iconName: "alert-remove"
  },
  {
    uuid: "5126056b-de97-4035-910d-0b2fe905e9d9",
    iconSet: "MaterialCommunityIcons",
    iconName: "alert-remove-outline"
  },
  {
    uuid: "62ec6ce7-b94d-4085-9258-624cc9bac5a8",
    iconSet: "MaterialCommunityIcons",
    iconName: "alert-rhombus"
  },
  {
    uuid: "9ae83704-d0d0-416d-b11e-5bb6833e26ad",
    iconSet: "MaterialCommunityIcons",
    iconName: "alert-rhombus-outline"
  },
  {
    uuid: "205ce150-f197-4f7a-867e-b7c7a69b720b",
    iconSet: "MaterialCommunityIcons",
    iconName: "alien"
  },
  {
    uuid: "68a9ab70-79e9-48fe-96af-8773e8697509",
    iconSet: "MaterialCommunityIcons",
    iconName: "alien-outline"
  },
  {
    uuid: "1aebc597-dd23-48b4-a0e8-09400943957e",
    iconSet: "MaterialCommunityIcons",
    iconName: "align-horizontal-center"
  },
  {
    uuid: "d7147de5-aab6-4563-a336-70d371df756c",
    iconSet: "MaterialCommunityIcons",
    iconName: "align-horizontal-left"
  },
  {
    uuid: "240df034-17cf-4bda-a279-3d9070e8a6cb",
    iconSet: "MaterialCommunityIcons",
    iconName: "align-horizontal-right"
  },
  {
    uuid: "65360804-d580-48ef-9385-1db72c2880da",
    iconSet: "MaterialCommunityIcons",
    iconName: "align-vertical-bottom"
  },
  {
    uuid: "f10446e2-497a-475d-9011-702a64e675e5",
    iconSet: "MaterialCommunityIcons",
    iconName: "align-vertical-center"
  },
  {
    uuid: "e62d7826-40d9-43b9-a0fd-7c06775907cd",
    iconSet: "MaterialCommunityIcons",
    iconName: "align-vertical-top"
  },
  {
    uuid: "1dec1f95-111a-4dfe-a717-be921a629ead",
    iconSet: "MaterialCommunityIcons",
    iconName: "all-inclusive"
  },
  {
    uuid: "ab63e4da-7ea6-4f8e-a437-4687707517a2",
    iconSet: "MaterialCommunityIcons",
    iconName: "allergy"
  },
  {
    uuid: "ac6f2890-e4b9-42db-949f-6e996486c5a6",
    iconSet: "MaterialCommunityIcons",
    iconName: "alpha"
  },
  {
    uuid: "8476d945-7353-414b-947d-b533dc6f8a96",
    iconSet: "MaterialCommunityIcons",
    iconName: "alpha-a"
  },
  {
    uuid: "a7e3268a-9d8a-4a71-bd8c-a0a5256b27cd",
    iconSet: "MaterialCommunityIcons",
    iconName: "alpha-a-box"
  },
  {
    uuid: "f177435d-e700-4b64-adb4-d16dde364120",
    iconSet: "MaterialCommunityIcons",
    iconName: "alpha-a-box-outline"
  },
  {
    uuid: "6dcdf370-bd01-42f1-8f5f-9cd4ffefd81d",
    iconSet: "MaterialCommunityIcons",
    iconName: "alpha-a-circle"
  },
  {
    uuid: "ef18bdee-e704-4752-8410-90bb835f20ef",
    iconSet: "MaterialCommunityIcons",
    iconName: "alpha-a-circle-outline"
  },
  {
    uuid: "206e10e2-b080-466a-9044-47725ab29388",
    iconSet: "MaterialCommunityIcons",
    iconName: "alpha-b"
  },
  {
    uuid: "7f511ffe-46f2-4959-92da-74a54bcf7a6f",
    iconSet: "MaterialCommunityIcons",
    iconName: "alpha-b-box"
  },
  {
    uuid: "7bb7f847-0d29-4d4c-a539-53f3f9e6e0b3",
    iconSet: "MaterialCommunityIcons",
    iconName: "alpha-b-box-outline"
  },
  {
    uuid: "bd1fe4ca-fee0-4247-be5b-18a7bf81e759",
    iconSet: "MaterialCommunityIcons",
    iconName: "alpha-b-circle"
  },
  {
    uuid: "8fd05189-fecb-4cb6-915b-58d32eae0a90",
    iconSet: "MaterialCommunityIcons",
    iconName: "alpha-b-circle-outline"
  },
  {
    uuid: "ead1677e-0f9e-4d5e-9230-dce52f8d3dae",
    iconSet: "MaterialCommunityIcons",
    iconName: "alpha-c"
  },
  {
    uuid: "2dd9f66a-bd61-4867-95ce-6d86eb3c0224",
    iconSet: "MaterialCommunityIcons",
    iconName: "alpha-c-box"
  },
  {
    uuid: "5b36c59b-e0b6-4f32-809f-60c747cac8b0",
    iconSet: "MaterialCommunityIcons",
    iconName: "alpha-c-box-outline"
  },
  {
    uuid: "fa97a154-2bba-47ab-9ee1-99f36a2c29f7",
    iconSet: "MaterialCommunityIcons",
    iconName: "alpha-c-circle"
  },
  {
    uuid: "a61493d5-4edf-4060-a665-d350d1763ce3",
    iconSet: "MaterialCommunityIcons",
    iconName: "alpha-c-circle-outline"
  },
  {
    uuid: "a8974ea3-c44e-43a8-9b97-f596d1fdf3da",
    iconSet: "MaterialCommunityIcons",
    iconName: "alpha-d"
  },
  {
    uuid: "077d27e9-8865-4899-a161-d8b886206271",
    iconSet: "MaterialCommunityIcons",
    iconName: "alpha-d-box"
  },
  {
    uuid: "63473808-9074-47fc-b4d6-3104f20d3a09",
    iconSet: "MaterialCommunityIcons",
    iconName: "alpha-d-box-outline"
  },
  {
    uuid: "6ab84223-427d-47dc-ad2a-e2c5ae1708a9",
    iconSet: "MaterialCommunityIcons",
    iconName: "alpha-d-circle"
  },
  {
    uuid: "4629fa93-07ec-4cc4-a5c1-e904d7349a4c",
    iconSet: "MaterialCommunityIcons",
    iconName: "alpha-d-circle-outline"
  },
  {
    uuid: "8b77aa77-1ff8-42b7-969a-17d425881460",
    iconSet: "MaterialCommunityIcons",
    iconName: "alpha-e"
  },
  {
    uuid: "2f6a70dd-e297-4f75-b1da-d926e352a106",
    iconSet: "MaterialCommunityIcons",
    iconName: "alpha-e-box"
  },
  {
    uuid: "f59ce683-ce94-461b-a03e-6091689c9eb8",
    iconSet: "MaterialCommunityIcons",
    iconName: "alpha-e-box-outline"
  },
  {
    uuid: "1fd6fca8-d9f4-4262-b2ed-9cde8ea2c510",
    iconSet: "MaterialCommunityIcons",
    iconName: "alpha-e-circle"
  },
  {
    uuid: "d11ee3e5-1be0-4d15-8c9c-5df4d1487b36",
    iconSet: "MaterialCommunityIcons",
    iconName: "alpha-e-circle-outline"
  },
  {
    uuid: "7287e195-6c67-43e4-b97a-de4d485c9ce6",
    iconSet: "MaterialCommunityIcons",
    iconName: "alpha-f"
  },
  {
    uuid: "57db49c7-a25e-4e7d-96c3-152964ecd833",
    iconSet: "MaterialCommunityIcons",
    iconName: "alpha-f-box"
  },
  {
    uuid: "5815d182-2fbf-4184-b9f4-13c40eed1c41",
    iconSet: "MaterialCommunityIcons",
    iconName: "alpha-f-box-outline"
  },
  {
    uuid: "df0c180d-d63b-4b64-b8aa-e661238bc980",
    iconSet: "MaterialCommunityIcons",
    iconName: "alpha-f-circle"
  },
  {
    uuid: "73341a67-26f9-412b-becb-ecfee84ebe91",
    iconSet: "MaterialCommunityIcons",
    iconName: "alpha-f-circle-outline"
  },
  {
    uuid: "07a116bf-2ebf-4779-8f15-4b9cf5cad2ca",
    iconSet: "MaterialCommunityIcons",
    iconName: "alpha-g"
  },
  {
    uuid: "4c61814e-0124-4046-a21e-88b2db387c6b",
    iconSet: "MaterialCommunityIcons",
    iconName: "alpha-g-box"
  },
  {
    uuid: "396d4290-df29-4f85-a9cf-aba4821d58cb",
    iconSet: "MaterialCommunityIcons",
    iconName: "alpha-g-box-outline"
  },
  {
    uuid: "0be81124-9f3b-45c3-b4bf-9622304b3c28",
    iconSet: "MaterialCommunityIcons",
    iconName: "alpha-g-circle"
  },
  {
    uuid: "1caa8347-9324-4dcf-814e-43806e91e00b",
    iconSet: "MaterialCommunityIcons",
    iconName: "alpha-g-circle-outline"
  },
  {
    uuid: "c682e4b2-fab5-4e09-9507-9201d2558656",
    iconSet: "MaterialCommunityIcons",
    iconName: "alpha-h"
  },
  {
    uuid: "2eda87d3-f488-4f46-9cff-15a7bbc6d85b",
    iconSet: "MaterialCommunityIcons",
    iconName: "alpha-h-box"
  },
  {
    uuid: "44e0ec3e-2cdb-44c8-baf2-d7a955150249",
    iconSet: "MaterialCommunityIcons",
    iconName: "alpha-h-box-outline"
  },
  {
    uuid: "a3b3fc91-5b78-4edd-9039-a92834161492",
    iconSet: "MaterialCommunityIcons",
    iconName: "alpha-h-circle"
  },
  {
    uuid: "0463bc3c-1f9c-4e37-b264-b36daa31a66b",
    iconSet: "MaterialCommunityIcons",
    iconName: "alpha-h-circle-outline"
  },
  {
    uuid: "7030029a-d99d-4cdb-ade4-4c3a57b7c224",
    iconSet: "MaterialCommunityIcons",
    iconName: "alpha-i"
  },
  {
    uuid: "57e9da50-5f28-480b-b83b-3e90682e0179",
    iconSet: "MaterialCommunityIcons",
    iconName: "alpha-i-box"
  },
  {
    uuid: "dbec0c63-858d-4828-886f-288ed83388e6",
    iconSet: "MaterialCommunityIcons",
    iconName: "alpha-i-box-outline"
  },
  {
    uuid: "b5fc42bd-0a49-4b35-9bc7-fc2b693190ee",
    iconSet: "MaterialCommunityIcons",
    iconName: "alpha-i-circle"
  },
  {
    uuid: "7552492d-232a-4780-8c5c-a4e3bbeb736e",
    iconSet: "MaterialCommunityIcons",
    iconName: "alpha-i-circle-outline"
  },
  {
    uuid: "1c4138e7-faca-4d17-8cd5-c70953b9b852",
    iconSet: "MaterialCommunityIcons",
    iconName: "alpha-j"
  },
  {
    uuid: "3aa74b00-393e-483c-914e-0105120e2fe8",
    iconSet: "MaterialCommunityIcons",
    iconName: "alpha-j-box"
  },
  {
    uuid: "654c8e8f-078c-41fb-9c6f-39fcac42187c",
    iconSet: "MaterialCommunityIcons",
    iconName: "alpha-j-box-outline"
  },
  {
    uuid: "2b9f5f65-d412-428c-a934-019760fdbb58",
    iconSet: "MaterialCommunityIcons",
    iconName: "alpha-j-circle"
  },
  {
    uuid: "0e86167d-836d-4d53-a171-4e8cdc6e0839",
    iconSet: "MaterialCommunityIcons",
    iconName: "alpha-j-circle-outline"
  },
  {
    uuid: "26b42444-4492-46a4-bf96-c21b87d00bef",
    iconSet: "MaterialCommunityIcons",
    iconName: "alpha-k"
  },
  {
    uuid: "044bb7c1-645f-40f1-9e97-83013484769d",
    iconSet: "MaterialCommunityIcons",
    iconName: "alpha-k-box"
  },
  {
    uuid: "67a4e6d0-29fe-4e9c-9285-d010a6cf3249",
    iconSet: "MaterialCommunityIcons",
    iconName: "alpha-k-box-outline"
  },
  {
    uuid: "a6333295-100c-4e68-b35e-60e4af85e027",
    iconSet: "MaterialCommunityIcons",
    iconName: "alpha-k-circle"
  },
  {
    uuid: "575e6101-cfc6-41e5-9e60-f21e951abc2a",
    iconSet: "MaterialCommunityIcons",
    iconName: "alpha-k-circle-outline"
  },
  {
    uuid: "c58edfc0-df6e-4b01-a891-cbd5c444c43d",
    iconSet: "MaterialCommunityIcons",
    iconName: "alpha-l"
  },
  {
    uuid: "eebdf0de-59e4-422b-82fd-d9f50b8afed4",
    iconSet: "MaterialCommunityIcons",
    iconName: "alpha-l-box"
  },
  {
    uuid: "9dba7ad5-e010-4303-9706-cdcb667390ae",
    iconSet: "MaterialCommunityIcons",
    iconName: "alpha-l-box-outline"
  },
  {
    uuid: "8cd985b5-401f-40a5-90b6-2d7ca982232c",
    iconSet: "MaterialCommunityIcons",
    iconName: "alpha-l-circle"
  },
  {
    uuid: "1d6c87c9-ec01-4610-a4d8-d431a777dd26",
    iconSet: "MaterialCommunityIcons",
    iconName: "alpha-l-circle-outline"
  },
  {
    uuid: "9295dc7a-3d88-4fde-9615-0b1a56ea75d8",
    iconSet: "MaterialCommunityIcons",
    iconName: "alpha-m"
  },
  {
    uuid: "061c0da8-0a00-4979-93e7-c27d2b32526c",
    iconSet: "MaterialCommunityIcons",
    iconName: "alpha-m-box"
  },
  {
    uuid: "455577db-36b7-4256-a924-d51d30dbe48d",
    iconSet: "MaterialCommunityIcons",
    iconName: "alpha-m-box-outline"
  },
  {
    uuid: "3b20cd9b-25c6-4af1-a11a-22487d4a35ae",
    iconSet: "MaterialCommunityIcons",
    iconName: "alpha-m-circle"
  },
  {
    uuid: "fe67edbb-5ec2-4f2a-85f8-a322243d992f",
    iconSet: "MaterialCommunityIcons",
    iconName: "alpha-m-circle-outline"
  },
  {
    uuid: "0b057eb0-1cb4-4f56-8f2c-7ddaa8d46513",
    iconSet: "MaterialCommunityIcons",
    iconName: "alpha-n"
  },
  {
    uuid: "a8673bc0-1c4a-4bbc-b8cf-472d545670ca",
    iconSet: "MaterialCommunityIcons",
    iconName: "alpha-n-box"
  },
  {
    uuid: "4f869d37-3a37-42b1-9489-77f1deba7ace",
    iconSet: "MaterialCommunityIcons",
    iconName: "alpha-n-box-outline"
  },
  {
    uuid: "6f1202c0-a022-4c33-ac81-23a581d7f549",
    iconSet: "MaterialCommunityIcons",
    iconName: "alpha-n-circle"
  },
  {
    uuid: "1609c3d2-8683-4e2a-8b28-d8a1457e31df",
    iconSet: "MaterialCommunityIcons",
    iconName: "alpha-n-circle-outline"
  },
  {
    uuid: "f5c8f070-c15b-40fb-9ebd-3c16309af394",
    iconSet: "MaterialCommunityIcons",
    iconName: "alpha-o"
  },
  {
    uuid: "3bc4f075-35d4-4b66-9edd-2e8308b4388e",
    iconSet: "MaterialCommunityIcons",
    iconName: "alpha-o-box"
  },
  {
    uuid: "8d492ffc-d745-45b0-b83f-78cfabd4b49a",
    iconSet: "MaterialCommunityIcons",
    iconName: "alpha-o-box-outline"
  },
  {
    uuid: "67edb4b0-445b-4e90-8078-24d930487e87",
    iconSet: "MaterialCommunityIcons",
    iconName: "alpha-o-circle"
  },
  {
    uuid: "eeb0dc45-b4ad-4750-a8f1-eb857a897b26",
    iconSet: "MaterialCommunityIcons",
    iconName: "alpha-o-circle-outline"
  },
  {
    uuid: "1feb49cf-f105-40d5-bdf4-f3aac5163407",
    iconSet: "MaterialCommunityIcons",
    iconName: "alpha-p"
  },
  {
    uuid: "5a998a9a-c817-416e-bf88-0de893ee728e",
    iconSet: "MaterialCommunityIcons",
    iconName: "alpha-p-box"
  },
  {
    uuid: "694a6ed4-e800-483b-900e-bed451367756",
    iconSet: "MaterialCommunityIcons",
    iconName: "alpha-p-box-outline"
  },
  {
    uuid: "ca64364f-32ba-4f56-b455-f7ba3cfd029a",
    iconSet: "MaterialCommunityIcons",
    iconName: "alpha-p-circle"
  },
  {
    uuid: "eb1ec376-480d-4d14-8fc8-916a27a9d95e",
    iconSet: "MaterialCommunityIcons",
    iconName: "alpha-p-circle-outline"
  },
  {
    uuid: "ba1ddaa8-102f-4f90-b57d-a414565d9c95",
    iconSet: "MaterialCommunityIcons",
    iconName: "alpha-q"
  },
  {
    uuid: "913cba63-cebb-4fb1-bc9b-08f9faf438d3",
    iconSet: "MaterialCommunityIcons",
    iconName: "alpha-q-box"
  },
  {
    uuid: "da9eaae1-03dd-49ff-82bd-14dec8832aae",
    iconSet: "MaterialCommunityIcons",
    iconName: "alpha-q-box-outline"
  },
  {
    uuid: "dd09708b-bf63-4d65-9cc3-4923ab3aa6bd",
    iconSet: "MaterialCommunityIcons",
    iconName: "alpha-q-circle"
  },
  {
    uuid: "2113728a-8942-4662-9e9b-4fbb89b15288",
    iconSet: "MaterialCommunityIcons",
    iconName: "alpha-q-circle-outline"
  },
  {
    uuid: "1f02cf4c-2724-4a3e-881f-2b0209c589f5",
    iconSet: "MaterialCommunityIcons",
    iconName: "alpha-r"
  },
  {
    uuid: "bcf2ba7a-5a34-4eff-8618-fdc4296be695",
    iconSet: "MaterialCommunityIcons",
    iconName: "alpha-r-box"
  },
  {
    uuid: "6f39aeb6-7fec-439f-9cf8-d241c1993050",
    iconSet: "MaterialCommunityIcons",
    iconName: "alpha-r-box-outline"
  },
  {
    uuid: "a1d5f431-c76a-4198-980c-96201bb728bb",
    iconSet: "MaterialCommunityIcons",
    iconName: "alpha-r-circle"
  },
  {
    uuid: "fdb6f0b1-cc45-49ad-9feb-dedecc34ba06",
    iconSet: "MaterialCommunityIcons",
    iconName: "alpha-r-circle-outline"
  },
  {
    uuid: "30ed35d6-db9f-4f4c-bd31-267e21980b89",
    iconSet: "MaterialCommunityIcons",
    iconName: "alpha-s"
  },
  {
    uuid: "c3a06b44-a278-4d0f-8318-594a3e5bcd33",
    iconSet: "MaterialCommunityIcons",
    iconName: "alpha-s-box"
  },
  {
    uuid: "ead8f2ab-caac-4e13-88ee-b9efbf926b15",
    iconSet: "MaterialCommunityIcons",
    iconName: "alpha-s-box-outline"
  },
  {
    uuid: "39377c18-1419-4aec-9257-c2e87669eac9",
    iconSet: "MaterialCommunityIcons",
    iconName: "alpha-s-circle"
  },
  {
    uuid: "5c131bf4-30e8-49c6-aea0-fd7df05c20cf",
    iconSet: "MaterialCommunityIcons",
    iconName: "alpha-s-circle-outline"
  },
  {
    uuid: "c3756880-18d5-4842-b501-fb01719c2bf9",
    iconSet: "MaterialCommunityIcons",
    iconName: "alpha-t"
  },
  {
    uuid: "fc66ad6c-6060-46b4-8b3c-1981957502b1",
    iconSet: "MaterialCommunityIcons",
    iconName: "alpha-t-box"
  },
  {
    uuid: "a7c0f855-ce1b-46ed-b1e0-50e669eb710a",
    iconSet: "MaterialCommunityIcons",
    iconName: "alpha-t-box-outline"
  },
  {
    uuid: "999e2394-9dd5-4059-b0b5-58bd2bda6828",
    iconSet: "MaterialCommunityIcons",
    iconName: "alpha-t-circle"
  },
  {
    uuid: "097fde18-dfcb-44a9-8886-6d1683483139",
    iconSet: "MaterialCommunityIcons",
    iconName: "alpha-t-circle-outline"
  },
  {
    uuid: "79817e46-650e-40e6-96ab-25b3fb892a32",
    iconSet: "MaterialCommunityIcons",
    iconName: "alpha-u"
  },
  {
    uuid: "711ef0a8-8623-484a-9153-9748a4a750ef",
    iconSet: "MaterialCommunityIcons",
    iconName: "alpha-u-box"
  },
  {
    uuid: "b3870f10-3416-49f4-b6bc-a6317bc475d7",
    iconSet: "MaterialCommunityIcons",
    iconName: "alpha-u-box-outline"
  },
  {
    uuid: "bd91c4d0-692e-4584-823d-42c03deaf2bf",
    iconSet: "MaterialCommunityIcons",
    iconName: "alpha-u-circle"
  },
  {
    uuid: "c937c66a-353f-47f0-a273-419f911c0e3b",
    iconSet: "MaterialCommunityIcons",
    iconName: "alpha-u-circle-outline"
  },
  {
    uuid: "409732c9-c7d2-4eaa-9e54-ae0afa66722d",
    iconSet: "MaterialCommunityIcons",
    iconName: "alpha-v"
  },
  {
    uuid: "02808177-2472-4caa-a7a4-9275dd04ab8c",
    iconSet: "MaterialCommunityIcons",
    iconName: "alpha-v-box"
  },
  {
    uuid: "8d1d3875-6066-46b8-8c77-87ab6eda0daa",
    iconSet: "MaterialCommunityIcons",
    iconName: "alpha-v-box-outline"
  },
  {
    uuid: "ed547fbc-1513-4219-bc14-c12cd37171a3",
    iconSet: "MaterialCommunityIcons",
    iconName: "alpha-v-circle"
  },
  {
    uuid: "033c704a-36cd-4858-bb37-ee2033c49dd5",
    iconSet: "MaterialCommunityIcons",
    iconName: "alpha-v-circle-outline"
  },
  {
    uuid: "8ffafbcc-d0c5-492c-be21-453e6342da73",
    iconSet: "MaterialCommunityIcons",
    iconName: "alpha-w"
  },
  {
    uuid: "d5a76218-e564-4b41-9939-a9a083177e43",
    iconSet: "MaterialCommunityIcons",
    iconName: "alpha-w-box"
  },
  {
    uuid: "02e9fe92-956d-43e8-bc26-25762b346746",
    iconSet: "MaterialCommunityIcons",
    iconName: "alpha-w-box-outline"
  },
  {
    uuid: "44361954-8a1a-4377-997e-db356adef44d",
    iconSet: "MaterialCommunityIcons",
    iconName: "alpha-w-circle"
  },
  {
    uuid: "bb3808aa-3bd8-4a14-b76b-42832ab278d6",
    iconSet: "MaterialCommunityIcons",
    iconName: "alpha-w-circle-outline"
  },
  {
    uuid: "3599417d-4c55-45f5-ace4-e6625415f2b0",
    iconSet: "MaterialCommunityIcons",
    iconName: "alpha-x"
  },
  {
    uuid: "b8bafc9d-f8cd-4f48-b859-778562d7c4f8",
    iconSet: "MaterialCommunityIcons",
    iconName: "alpha-x-box"
  },
  {
    uuid: "1561601d-18eb-4578-b715-026f3e94bc48",
    iconSet: "MaterialCommunityIcons",
    iconName: "alpha-x-box-outline"
  },
  {
    uuid: "da7a1a29-0f71-4d9a-9690-5a80ecabc201",
    iconSet: "MaterialCommunityIcons",
    iconName: "alpha-x-circle"
  },
  {
    uuid: "c708f53f-188e-4cb2-b3b1-c43050f44418",
    iconSet: "MaterialCommunityIcons",
    iconName: "alpha-x-circle-outline"
  },
  {
    uuid: "b241a7be-5e50-4b4d-9d0a-06be0a6591ba",
    iconSet: "MaterialCommunityIcons",
    iconName: "alpha-y"
  },
  {
    uuid: "a982aa23-a051-431c-8465-c941fda82839",
    iconSet: "MaterialCommunityIcons",
    iconName: "alpha-y-box"
  },
  {
    uuid: "b19046fc-94f4-4664-adc8-b15b8b378f7e",
    iconSet: "MaterialCommunityIcons",
    iconName: "alpha-y-box-outline"
  },
  {
    uuid: "bd30bff3-3456-42ea-a98b-34f60c73078c",
    iconSet: "MaterialCommunityIcons",
    iconName: "alpha-y-circle"
  },
  {
    uuid: "16c239d4-7cc4-4a43-9678-660f45c5fd78",
    iconSet: "MaterialCommunityIcons",
    iconName: "alpha-y-circle-outline"
  },
  {
    uuid: "a726b041-3ed4-4224-a068-5335fec433af",
    iconSet: "MaterialCommunityIcons",
    iconName: "alpha-z"
  },
  {
    uuid: "91dc83da-776b-432e-ad0a-e7113bd4a91b",
    iconSet: "MaterialCommunityIcons",
    iconName: "alpha-z-box"
  },
  {
    uuid: "66305f26-34e7-48d6-b14c-ea503e3045af",
    iconSet: "MaterialCommunityIcons",
    iconName: "alpha-z-box-outline"
  },
  {
    uuid: "a73d89f4-5701-4632-b6ae-1737b50d3316",
    iconSet: "MaterialCommunityIcons",
    iconName: "alpha-z-circle"
  },
  {
    uuid: "b643552e-7ecf-47bf-ae55-52cb3873c340",
    iconSet: "MaterialCommunityIcons",
    iconName: "alpha-z-circle-outline"
  },
  {
    uuid: "386f8679-7053-478d-98ee-052ddd8e2a06",
    iconSet: "MaterialCommunityIcons",
    iconName: "alphabet-aurebesh"
  },
  {
    uuid: "eb3f060f-36bf-4110-8709-605aff39a344",
    iconSet: "MaterialCommunityIcons",
    iconName: "alphabet-cyrillic"
  },
  {
    uuid: "6347e735-0091-4835-ad07-e3cb937abdf7",
    iconSet: "MaterialCommunityIcons",
    iconName: "alphabet-greek"
  },
  {
    uuid: "a6a544e4-f926-4a64-9ddc-69fe9fbd59c3",
    iconSet: "MaterialCommunityIcons",
    iconName: "alphabet-latin"
  },
  {
    uuid: "02a07c91-63cf-42e0-ae8f-ea9cc90c86f4",
    iconSet: "MaterialCommunityIcons",
    iconName: "alphabet-piqad"
  },
  {
    uuid: "5f8325bf-8893-465e-9b85-bfa8625fe48e",
    iconSet: "MaterialCommunityIcons",
    iconName: "alphabet-tengwar"
  },
  {
    uuid: "1610e107-c55c-440d-9d43-0f51ff893710",
    iconSet: "MaterialCommunityIcons",
    iconName: "alphabetical"
  },
  {
    uuid: "9cf3c6d5-ff8b-4808-b417-d4ee8e3e1d48",
    iconSet: "MaterialCommunityIcons",
    iconName: "alphabetical-off"
  },
  {
    uuid: "059c482f-6a9d-4fd7-9393-eb2b4d77adab",
    iconSet: "MaterialCommunityIcons",
    iconName: "alphabetical-variant"
  },
  {
    uuid: "998fd9f2-ac01-4829-9464-0d6f827a4f06",
    iconSet: "MaterialCommunityIcons",
    iconName: "alphabetical-variant-off"
  },
  {
    uuid: "bb22731a-558d-49a8-b267-d5f2fe4d4518",
    iconSet: "MaterialCommunityIcons",
    iconName: "altimeter"
  },
  {
    uuid: "99f0207e-f45b-4f06-b19f-211a4d1a7a5a",
    iconSet: "MaterialCommunityIcons",
    iconName: "amazon"
  },
  {
    uuid: "8a508a63-1047-48d3-b5e7-99e094ab9e18",
    iconSet: "MaterialCommunityIcons",
    iconName: "amazon-alexa"
  },
  {
    uuid: "aa7200aa-efa7-4a49-af17-6d995a8d59ac",
    iconSet: "MaterialCommunityIcons",
    iconName: "ambulance"
  },
  {
    uuid: "88bb4c7b-830f-4721-9abc-31b3e9b13b4b",
    iconSet: "MaterialCommunityIcons",
    iconName: "ammunition"
  },
  {
    uuid: "d90775e1-6ebd-4c06-a493-c481bc354d34",
    iconSet: "MaterialCommunityIcons",
    iconName: "ampersand"
  },
  {
    uuid: "5f0f07e3-5a63-4573-86de-411df556eaaf",
    iconSet: "MaterialCommunityIcons",
    iconName: "amplifier"
  },
  {
    uuid: "33c04c33-82b4-4575-97a5-a90c7bfea0d7",
    iconSet: "MaterialCommunityIcons",
    iconName: "amplifier-off"
  },
  {
    uuid: "755db7d8-7231-4fd9-98b9-c3929aebdad7",
    iconSet: "MaterialCommunityIcons",
    iconName: "anchor"
  },
  {
    uuid: "eb3da8cf-a3e8-469d-b6da-257df2df5fb5",
    iconSet: "MaterialCommunityIcons",
    iconName: "android"
  },
  {
    uuid: "d57aba6c-40dc-4c38-8e74-b20ef42f285e",
    iconSet: "MaterialCommunityIcons",
    iconName: "android-auto"
  },
  {
    uuid: "e97f4bee-7a68-4c56-ac7e-ed1bf02cb699",
    iconSet: "MaterialCommunityIcons",
    iconName: "android-debug-bridge"
  },
  {
    uuid: "9b4fcdf0-b4da-4350-9e26-e78f7738083d",
    iconSet: "MaterialCommunityIcons",
    iconName: "android-messages"
  },
  {
    uuid: "a84e2321-1492-4111-a913-a3e310ede33e",
    iconSet: "MaterialCommunityIcons",
    iconName: "android-studio"
  },
  {
    uuid: "a8d39562-524d-4e2d-8789-72db6123720e",
    iconSet: "MaterialCommunityIcons",
    iconName: "angle-acute"
  },
  {
    uuid: "9d12ed72-ae70-48d5-b2c4-a811bd371bf8",
    iconSet: "MaterialCommunityIcons",
    iconName: "angle-obtuse"
  },
  {
    uuid: "ee8fac19-6ba7-4f76-b9f9-45a8b29b937a",
    iconSet: "MaterialCommunityIcons",
    iconName: "angle-right"
  },
  {
    uuid: "0943831a-a907-4d73-8bab-10fbe9f553a8",
    iconSet: "MaterialCommunityIcons",
    iconName: "angular"
  },
  {
    uuid: "d6a72779-73b1-4159-ab43-f3c2f7567ff2",
    iconSet: "MaterialCommunityIcons",
    iconName: "angularjs"
  },
  {
    uuid: "706e2824-3012-4293-b4fa-af1eec2f35bf",
    iconSet: "MaterialCommunityIcons",
    iconName: "animation"
  },
  {
    uuid: "f62b5a78-e5f9-4700-aed4-8c598d171760",
    iconSet: "MaterialCommunityIcons",
    iconName: "animation-outline"
  },
  {
    uuid: "ec6b04ff-72ef-4acb-b3d6-8361a425d6ed",
    iconSet: "MaterialCommunityIcons",
    iconName: "animation-play"
  },
  {
    uuid: "014be48e-0ce7-4cef-89d7-845227a12041",
    iconSet: "MaterialCommunityIcons",
    iconName: "animation-play-outline"
  },
  {
    uuid: "8a5f31e5-88a0-4b57-b05d-971aee5fcebf",
    iconSet: "MaterialCommunityIcons",
    iconName: "ansible"
  },
  {
    uuid: "998fa896-6736-4b33-93d6-5753dc07ac8c",
    iconSet: "MaterialCommunityIcons",
    iconName: "antenna"
  },
  {
    uuid: "dc4fef3d-3ed7-45f4-902f-15df7242a936",
    iconSet: "MaterialCommunityIcons",
    iconName: "anvil"
  },
  {
    uuid: "013ae0c4-d1ef-43f4-811d-4d9198d18e77",
    iconSet: "MaterialCommunityIcons",
    iconName: "apache-kafka"
  },
  {
    uuid: "6f3b8984-31bc-428d-b473-062a3fd7ff33",
    iconSet: "MaterialCommunityIcons",
    iconName: "api"
  },
  {
    uuid: "1a2da34a-ebdd-48e2-b334-014d16ce6996",
    iconSet: "MaterialCommunityIcons",
    iconName: "api-off"
  },
  {
    uuid: "8a8a7210-1b29-45a8-b04a-d5684827ddf7",
    iconSet: "MaterialCommunityIcons",
    iconName: "apple"
  },
  {
    uuid: "70b702d0-439f-436e-a42c-9ae8b2d9f2d0",
    iconSet: "MaterialCommunityIcons",
    iconName: "apple-airplay"
  },
  {
    uuid: "f04c7b0f-b210-4c95-ab9e-7adadd22e580",
    iconSet: "MaterialCommunityIcons",
    iconName: "apple-finder"
  },
  {
    uuid: "b51def17-b49c-4c00-875d-cb2240fc31f3",
    iconSet: "MaterialCommunityIcons",
    iconName: "apple-icloud"
  },
  {
    uuid: "d5e33779-ec6b-43c9-aafc-d0dc14ebc5a5",
    iconSet: "MaterialCommunityIcons",
    iconName: "apple-ios"
  },
  {
    uuid: "f9988523-ebcc-43ec-b42f-daab35b820cf",
    iconSet: "MaterialCommunityIcons",
    iconName: "apple-keyboard-caps"
  },
  {
    uuid: "74639a5c-0537-4b32-99e0-dce5fc07a187",
    iconSet: "MaterialCommunityIcons",
    iconName: "apple-keyboard-command"
  },
  {
    uuid: "77cee709-1681-41b5-b215-ad94970ef330",
    iconSet: "MaterialCommunityIcons",
    iconName: "apple-keyboard-control"
  },
  {
    uuid: "7f5748cb-3c5d-4c58-ac02-26563ed9b0c0",
    iconSet: "MaterialCommunityIcons",
    iconName: "apple-keyboard-option"
  },
  {
    uuid: "e3303673-1b95-429e-9f2e-802e2fb13e76",
    iconSet: "MaterialCommunityIcons",
    iconName: "apple-keyboard-shift"
  },
  {
    uuid: "1b29a71a-1785-452c-9da8-cbf9855d9cae",
    iconSet: "MaterialCommunityIcons",
    iconName: "apple-safari"
  },
  {
    uuid: "de41181e-54fe-4a87-b199-d8aaa9e665a7",
    iconSet: "MaterialCommunityIcons",
    iconName: "application"
  },
  {
    uuid: "72d90ff5-c815-47cc-b5af-38e263e976b4",
    iconSet: "MaterialCommunityIcons",
    iconName: "application-export"
  },
  {
    uuid: "f8ae0ba5-f531-435f-a7be-84ceeb00de4c",
    iconSet: "MaterialCommunityIcons",
    iconName: "application-import"
  },
  {
    uuid: "e22b1eee-215d-4a20-bdb9-4857baf5adc1",
    iconSet: "MaterialCommunityIcons",
    iconName: "approximately-equal"
  },
  {
    uuid: "325e669e-0215-44c4-ae1d-f6da82fb6421",
    iconSet: "MaterialCommunityIcons",
    iconName: "approximately-equal-box"
  },
  {
    uuid: "775c7543-c239-46a8-8e31-c702835f0132",
    iconSet: "MaterialCommunityIcons",
    iconName: "apps"
  },
  {
    uuid: "fbcc77b1-88c1-474a-961a-a250cebc79e2",
    iconSet: "MaterialCommunityIcons",
    iconName: "apps-box"
  },
  {
    uuid: "b8962a93-c418-452c-8237-61a72227614b",
    iconSet: "MaterialCommunityIcons",
    iconName: "arch"
  },
  {
    uuid: "fbd6a276-9ded-46f4-9fd2-cbdf0a666c2c",
    iconSet: "MaterialCommunityIcons",
    iconName: "archive"
  },
  {
    uuid: "b69a87ea-d066-4554-b1dd-3ac774089a66",
    iconSet: "MaterialCommunityIcons",
    iconName: "archive-arrow-down"
  },
  {
    uuid: "81aba371-32cf-466b-800a-e32c546e981c",
    iconSet: "MaterialCommunityIcons",
    iconName: "archive-arrow-down-outline"
  },
  {
    uuid: "7e3e6f92-da73-4f31-ab98-178bc4274177",
    iconSet: "MaterialCommunityIcons",
    iconName: "archive-arrow-up"
  },
  {
    uuid: "22875b00-b384-46d1-8fc8-22348899b5d4",
    iconSet: "MaterialCommunityIcons",
    iconName: "archive-arrow-up-outline"
  },
  {
    uuid: "a8f1897a-e4fe-4335-8e56-e90b81937874",
    iconSet: "MaterialCommunityIcons",
    iconName: "archive-outline"
  },
  {
    uuid: "4d0cfa4c-8987-4ec6-ad7d-60395ce139a8",
    iconSet: "MaterialCommunityIcons",
    iconName: "arm-flex"
  },
  {
    uuid: "a0bbbe72-d593-4eb8-bda6-8f5414c21cfc",
    iconSet: "MaterialCommunityIcons",
    iconName: "arm-flex-outline"
  },
  {
    uuid: "7b0259cc-3848-48c4-ae95-5e3e256fd686",
    iconSet: "MaterialCommunityIcons",
    iconName: "arrange-bring-forward"
  },
  {
    uuid: "a8183b28-a895-4b44-9e7e-0c2ba7c045a6",
    iconSet: "MaterialCommunityIcons",
    iconName: "arrange-bring-to-front"
  },
  {
    uuid: "2329a432-7ee5-4a46-911b-073679c3e8df",
    iconSet: "MaterialCommunityIcons",
    iconName: "arrange-send-backward"
  },
  {
    uuid: "3737d20c-8ac5-456d-b9b3-4afb258fc15f",
    iconSet: "MaterialCommunityIcons",
    iconName: "arrange-send-to-back"
  },
  {
    uuid: "ddc2ffed-be3d-49f7-b8e3-261f834c3e01",
    iconSet: "MaterialCommunityIcons",
    iconName: "arrow-all"
  },
  {
    uuid: "3b9a6d7f-779b-44c6-aa8c-5840313db02c",
    iconSet: "MaterialCommunityIcons",
    iconName: "arrow-bottom-left"
  },
  {
    uuid: "078ed843-015a-4507-9a08-8290f195e1b0",
    iconSet: "MaterialCommunityIcons",
    iconName: "arrow-bottom-left-bold-outline"
  },
  {
    uuid: "451d24ea-94af-4fb1-8c71-c07af3763e63",
    iconSet: "MaterialCommunityIcons",
    iconName: "arrow-bottom-left-thick"
  },
  {
    uuid: "d6cb8e48-13ac-4198-952c-cd62ff8e6a0e",
    iconSet: "MaterialCommunityIcons",
    iconName: "arrow-bottom-right"
  },
  {
    uuid: "10f2dc8f-900d-45fe-a929-bc3228a44d21",
    iconSet: "MaterialCommunityIcons",
    iconName: "arrow-bottom-right-bold-outline"
  },
  {
    uuid: "bc081a58-47d2-48d9-9bf4-9fb777111cbc",
    iconSet: "MaterialCommunityIcons",
    iconName: "arrow-bottom-right-thick"
  },
  {
    uuid: "644363ad-a842-470c-b432-5a48adc87141",
    iconSet: "MaterialCommunityIcons",
    iconName: "arrow-collapse"
  },
  {
    uuid: "091ace0a-ce63-4924-b72f-06e1ace61ab9",
    iconSet: "MaterialCommunityIcons",
    iconName: "arrow-collapse-all"
  },
  {
    uuid: "2596dd6e-b6ff-4005-94bb-af9ef6539bb3",
    iconSet: "MaterialCommunityIcons",
    iconName: "arrow-collapse-down"
  },
  {
    uuid: "500edea0-609d-4d20-b4d5-2096ab8ef8c6",
    iconSet: "MaterialCommunityIcons",
    iconName: "arrow-collapse-horizontal"
  },
  {
    uuid: "281d4546-f1ed-4cdf-9214-953f817b4b65",
    iconSet: "MaterialCommunityIcons",
    iconName: "arrow-collapse-left"
  },
  {
    uuid: "5316a23e-657e-4b1b-86d3-36030e491509",
    iconSet: "MaterialCommunityIcons",
    iconName: "arrow-collapse-right"
  },
  {
    uuid: "276f6167-0cbd-4b53-b486-0f1ce212855a",
    iconSet: "MaterialCommunityIcons",
    iconName: "arrow-collapse-up"
  },
  {
    uuid: "1c9c7025-18ba-4388-b1c6-6c46b61bea09",
    iconSet: "MaterialCommunityIcons",
    iconName: "arrow-collapse-vertical"
  },
  {
    uuid: "b5f507ba-c452-44bb-9a06-088caca69ee3",
    iconSet: "MaterialCommunityIcons",
    iconName: "arrow-decision"
  },
  {
    uuid: "e258e0af-e1e6-460b-8a95-e209f5836c60",
    iconSet: "MaterialCommunityIcons",
    iconName: "arrow-decision-auto"
  },
  {
    uuid: "f20a30c0-f16c-46f4-8d9e-208bd791bbb6",
    iconSet: "MaterialCommunityIcons",
    iconName: "arrow-decision-auto-outline"
  },
  {
    uuid: "52366891-cf4a-4afd-af4b-2ce4387e3cd7",
    iconSet: "MaterialCommunityIcons",
    iconName: "arrow-decision-outline"
  },
  {
    uuid: "434e9fab-662b-45ea-befc-5a638d8b6d02",
    iconSet: "MaterialCommunityIcons",
    iconName: "arrow-down"
  },
  {
    uuid: "07973081-00f1-4049-96e1-910d6accc100",
    iconSet: "MaterialCommunityIcons",
    iconName: "arrow-down-bold"
  },
  {
    uuid: "50db3e75-3fdb-48bf-b5e6-6d99cdb3455c",
    iconSet: "MaterialCommunityIcons",
    iconName: "arrow-down-bold-box"
  },
  {
    uuid: "e0bdaf84-0a37-4e5b-b6d9-fb714fab7f48",
    iconSet: "MaterialCommunityIcons",
    iconName: "arrow-down-bold-box-outline"
  },
  {
    uuid: "b20b98aa-0cd7-4611-a75b-79a013788fb4",
    iconSet: "MaterialCommunityIcons",
    iconName: "arrow-down-bold-circle"
  },
  {
    uuid: "b010f21f-d95c-43e3-8fdf-c90c731b1f98",
    iconSet: "MaterialCommunityIcons",
    iconName: "arrow-down-bold-circle-outline"
  },
  {
    uuid: "3b8dc859-449b-456e-83c4-cabcec93e34c",
    iconSet: "MaterialCommunityIcons",
    iconName: "arrow-down-bold-hexagon-outline"
  },
  {
    uuid: "cf0f029e-b592-4e00-98b9-eefc72055785",
    iconSet: "MaterialCommunityIcons",
    iconName: "arrow-down-bold-outline"
  },
  {
    uuid: "1f9ab774-5c26-43b7-87fb-66b454606d91",
    iconSet: "MaterialCommunityIcons",
    iconName: "arrow-down-box"
  },
  {
    uuid: "fcf44f88-af0b-4d20-b22d-8f1e0da8c2a9",
    iconSet: "MaterialCommunityIcons",
    iconName: "arrow-down-circle"
  },
  {
    uuid: "1b19871b-3d0c-4d64-bce0-108108ac7f9f",
    iconSet: "MaterialCommunityIcons",
    iconName: "arrow-down-circle-outline"
  },
  {
    uuid: "62306e06-f240-49e6-88ba-5b49adc76161",
    iconSet: "MaterialCommunityIcons",
    iconName: "arrow-down-drop-circle"
  },
  {
    uuid: "4d87b1ed-778c-4fdc-a517-6c63db6deca3",
    iconSet: "MaterialCommunityIcons",
    iconName: "arrow-down-drop-circle-outline"
  },
  {
    uuid: "1d1e8b11-d38b-4222-8c03-e1e2eb05cebf",
    iconSet: "MaterialCommunityIcons",
    iconName: "arrow-down-thick"
  },
  {
    uuid: "6c68ac8d-1520-438c-872c-15a6f0f0d1cd",
    iconSet: "MaterialCommunityIcons",
    iconName: "arrow-expand"
  },
  {
    uuid: "409dc130-f3dd-4a11-849b-71f0315aaa90",
    iconSet: "MaterialCommunityIcons",
    iconName: "arrow-expand-all"
  },
  {
    uuid: "178ce685-a1c6-44b0-9dde-2e88d22b5c08",
    iconSet: "MaterialCommunityIcons",
    iconName: "arrow-expand-down"
  },
  {
    uuid: "174c0b96-e7f2-4f15-b70f-4a61987b794f",
    iconSet: "MaterialCommunityIcons",
    iconName: "arrow-expand-horizontal"
  },
  {
    uuid: "59df2f38-4df1-4304-a2dc-8cacfef62961",
    iconSet: "MaterialCommunityIcons",
    iconName: "arrow-expand-left"
  },
  {
    uuid: "b4696fb7-fbe0-4cec-a8d5-e3bee58f8b5b",
    iconSet: "MaterialCommunityIcons",
    iconName: "arrow-expand-right"
  },
  {
    uuid: "872652c1-3ba0-4334-b9dc-8f9490f5c8a5",
    iconSet: "MaterialCommunityIcons",
    iconName: "arrow-expand-up"
  },
  {
    uuid: "3283cf03-6bcf-41a0-b9e8-6c14f04d16e0",
    iconSet: "MaterialCommunityIcons",
    iconName: "arrow-expand-vertical"
  },
  {
    uuid: "59a81c5b-47e6-4c57-af02-5713eb63bbdb",
    iconSet: "MaterialCommunityIcons",
    iconName: "arrow-horizontal-lock"
  },
  {
    uuid: "95aa2724-dce3-4ae1-940f-b3aedd6a79b4",
    iconSet: "MaterialCommunityIcons",
    iconName: "arrow-left"
  },
  {
    uuid: "8ed33337-b021-4a59-9e2d-592c75c714e2",
    iconSet: "MaterialCommunityIcons",
    iconName: "arrow-left-bold"
  },
  {
    uuid: "02e6b651-10b3-485a-961f-79ca350a33e4",
    iconSet: "MaterialCommunityIcons",
    iconName: "arrow-left-bold-box"
  },
  {
    uuid: "de097af7-3a94-41ea-af74-2a771c97a84d",
    iconSet: "MaterialCommunityIcons",
    iconName: "arrow-left-bold-box-outline"
  },
  {
    uuid: "5c896061-e9c2-4341-bfa2-afc9f163c305",
    iconSet: "MaterialCommunityIcons",
    iconName: "arrow-left-bold-circle"
  },
  {
    uuid: "cbfeb9ab-7db1-442f-a18c-77b94c86b11c",
    iconSet: "MaterialCommunityIcons",
    iconName: "arrow-left-bold-circle-outline"
  },
  {
    uuid: "4b081209-cad6-4fec-a4ef-37e00a6c55ac",
    iconSet: "MaterialCommunityIcons",
    iconName: "arrow-left-bold-hexagon-outline"
  },
  {
    uuid: "0eee68ea-2b1e-46b7-8caa-89e0676b67db",
    iconSet: "MaterialCommunityIcons",
    iconName: "arrow-left-bold-outline"
  },
  {
    uuid: "ee52880e-7a98-43bd-9e9d-612b0e2584a8",
    iconSet: "MaterialCommunityIcons",
    iconName: "arrow-left-box"
  },
  {
    uuid: "f39f1560-5a41-4352-b8a9-b154db9c131f",
    iconSet: "MaterialCommunityIcons",
    iconName: "arrow-left-circle"
  },
  {
    uuid: "6d86210a-7d3d-4caa-85f3-a91dcac2ca6b",
    iconSet: "MaterialCommunityIcons",
    iconName: "arrow-left-circle-outline"
  },
  {
    uuid: "cf2e934a-96eb-4fe2-99df-8bddf10b1c28",
    iconSet: "MaterialCommunityIcons",
    iconName: "arrow-left-drop-circle"
  },
  {
    uuid: "872eb24d-3870-44ae-b701-7dcca1ea52b4",
    iconSet: "MaterialCommunityIcons",
    iconName: "arrow-left-drop-circle-outline"
  },
  {
    uuid: "6d9f6506-9589-4e8a-b068-35f02187ae17",
    iconSet: "MaterialCommunityIcons",
    iconName: "arrow-left-right"
  },
  {
    uuid: "72647a7b-5866-4325-8ebe-6ad6dd95c4d4",
    iconSet: "MaterialCommunityIcons",
    iconName: "arrow-left-right-bold"
  },
  {
    uuid: "d87ed81c-a106-4a73-b616-7194f0a7d602",
    iconSet: "MaterialCommunityIcons",
    iconName: "arrow-left-right-bold-outline"
  },
  {
    uuid: "54627ec8-a414-48c6-8f32-e80fc9d8c944",
    iconSet: "MaterialCommunityIcons",
    iconName: "arrow-left-thick"
  },
  {
    uuid: "9a1f345a-7726-4d14-84ee-b6271653a448",
    iconSet: "MaterialCommunityIcons",
    iconName: "arrow-right"
  },
  {
    uuid: "07e28047-7c7c-42c5-9775-3d0de82ad3c9",
    iconSet: "MaterialCommunityIcons",
    iconName: "arrow-right-bold"
  },
  {
    uuid: "8957bb14-9658-4c8d-b82a-b10f0c475264",
    iconSet: "MaterialCommunityIcons",
    iconName: "arrow-right-bold-box"
  },
  {
    uuid: "b6850712-5f57-4624-8121-874612d9dd9f",
    iconSet: "MaterialCommunityIcons",
    iconName: "arrow-right-bold-box-outline"
  },
  {
    uuid: "1ffed393-fbc4-43f3-ae99-2fde4ce70b18",
    iconSet: "MaterialCommunityIcons",
    iconName: "arrow-right-bold-circle"
  },
  {
    uuid: "0b1e78b2-f9f8-4956-951e-d578389ed81b",
    iconSet: "MaterialCommunityIcons",
    iconName: "arrow-right-bold-circle-outline"
  },
  {
    uuid: "0875375f-b1ae-4f70-9bbb-64699c4a06fa",
    iconSet: "MaterialCommunityIcons",
    iconName: "arrow-right-bold-hexagon-outline"
  },
  {
    uuid: "b05ce094-e547-49be-83e4-067abc1d44af",
    iconSet: "MaterialCommunityIcons",
    iconName: "arrow-right-bold-outline"
  },
  {
    uuid: "da01e4f7-b4f2-48ae-85c9-defd4754db46",
    iconSet: "MaterialCommunityIcons",
    iconName: "arrow-right-box"
  },
  {
    uuid: "fbbb48b7-0eea-44c4-9e96-17aba9013add",
    iconSet: "MaterialCommunityIcons",
    iconName: "arrow-right-circle"
  },
  {
    uuid: "3d15c171-0711-4e71-843b-29138f3ff62c",
    iconSet: "MaterialCommunityIcons",
    iconName: "arrow-right-circle-outline"
  },
  {
    uuid: "1386d731-ad42-4d82-be9f-6a904d90ba1d",
    iconSet: "MaterialCommunityIcons",
    iconName: "arrow-right-drop-circle"
  },
  {
    uuid: "d63dc124-9dd5-42ad-b7ad-bf5fe3fc35a2",
    iconSet: "MaterialCommunityIcons",
    iconName: "arrow-right-drop-circle-outline"
  },
  {
    uuid: "68bf3639-d0fa-4345-a9a7-1acce3559890",
    iconSet: "MaterialCommunityIcons",
    iconName: "arrow-right-thick"
  },
  {
    uuid: "dc66c632-a990-42d5-8556-c7b6dd7a7d6f",
    iconSet: "MaterialCommunityIcons",
    iconName: "arrow-split-horizontal"
  },
  {
    uuid: "fd16104a-9b07-41a5-bb2c-8bacb1bf9183",
    iconSet: "MaterialCommunityIcons",
    iconName: "arrow-split-vertical"
  },
  {
    uuid: "877e447d-3778-483f-b9e4-3cb3dcaf0d86",
    iconSet: "MaterialCommunityIcons",
    iconName: "arrow-top-left"
  },
  {
    uuid: "1db5566b-aac3-46de-9767-34edf6132bbb",
    iconSet: "MaterialCommunityIcons",
    iconName: "arrow-top-left-bold-outline"
  },
  {
    uuid: "fc3c46dc-004f-4111-b34e-a91583e5c3b7",
    iconSet: "MaterialCommunityIcons",
    iconName: "arrow-top-left-bottom-right"
  },
  {
    uuid: "88f7070e-2354-48c1-a98e-aff63b5a5dda",
    iconSet: "MaterialCommunityIcons",
    iconName: "arrow-top-left-bottom-right-bold"
  },
  {
    uuid: "c0c96955-b513-4409-bf72-66ee8e94d93d",
    iconSet: "MaterialCommunityIcons",
    iconName: "arrow-top-left-thick"
  },
  {
    uuid: "e8325e4f-709f-4cb9-8408-7bde6396f196",
    iconSet: "MaterialCommunityIcons",
    iconName: "arrow-top-right"
  },
  {
    uuid: "c266e695-e75e-4b58-aa02-afa8e625fd53",
    iconSet: "MaterialCommunityIcons",
    iconName: "arrow-top-right-bold-outline"
  },
  {
    uuid: "4786769c-5f67-4fd6-be6b-7458d40fe974",
    iconSet: "MaterialCommunityIcons",
    iconName: "arrow-top-right-bottom-left"
  },
  {
    uuid: "c4fcb6f7-3726-48e4-b4ea-ada4b2815764",
    iconSet: "MaterialCommunityIcons",
    iconName: "arrow-top-right-bottom-left-bold"
  },
  {
    uuid: "d0208e1b-3a24-4961-a2fb-fc31a973fcd4",
    iconSet: "MaterialCommunityIcons",
    iconName: "arrow-top-right-thick"
  },
  {
    uuid: "ec172f85-f3bb-40bd-88e5-669c8c1823a6",
    iconSet: "MaterialCommunityIcons",
    iconName: "arrow-up"
  },
  {
    uuid: "a9658bf4-c83b-464f-838c-ceae5f230c85",
    iconSet: "MaterialCommunityIcons",
    iconName: "arrow-up-bold"
  },
  {
    uuid: "4943c219-df6c-4008-a93f-98bd61ac75a4",
    iconSet: "MaterialCommunityIcons",
    iconName: "arrow-up-bold-box"
  },
  {
    uuid: "3b50dd47-8bc7-4081-ab98-516810eb737c",
    iconSet: "MaterialCommunityIcons",
    iconName: "arrow-up-bold-box-outline"
  },
  {
    uuid: "6bf0acda-8280-4d9d-b4e1-0b17a839fcdf",
    iconSet: "MaterialCommunityIcons",
    iconName: "arrow-up-bold-circle"
  },
  {
    uuid: "d85d1992-080e-4da3-a560-144acf0fce58",
    iconSet: "MaterialCommunityIcons",
    iconName: "arrow-up-bold-circle-outline"
  },
  {
    uuid: "5427f452-621a-48cb-b35b-d3cdf1425ba6",
    iconSet: "MaterialCommunityIcons",
    iconName: "arrow-up-bold-hexagon-outline"
  },
  {
    uuid: "93b29a86-4362-4041-86bd-585500eddf32",
    iconSet: "MaterialCommunityIcons",
    iconName: "arrow-up-bold-outline"
  },
  {
    uuid: "d3322c00-4f43-44fb-8bc3-97ac3d665421",
    iconSet: "MaterialCommunityIcons",
    iconName: "arrow-up-box"
  },
  {
    uuid: "703574ba-3757-473e-9e93-d7ecd1a9551b",
    iconSet: "MaterialCommunityIcons",
    iconName: "arrow-up-circle"
  },
  {
    uuid: "9231ab50-da07-40d5-ae77-b27a37bfd73b",
    iconSet: "MaterialCommunityIcons",
    iconName: "arrow-up-circle-outline"
  },
  {
    uuid: "5c924be5-476e-42fc-8d27-3eaec88b7e08",
    iconSet: "MaterialCommunityIcons",
    iconName: "arrow-up-down"
  },
  {
    uuid: "de54c1cd-f43d-4f88-8c2e-06db69c93b76",
    iconSet: "MaterialCommunityIcons",
    iconName: "arrow-up-down-bold"
  },
  {
    uuid: "d06145ad-6296-42eb-a00f-70924581e841",
    iconSet: "MaterialCommunityIcons",
    iconName: "arrow-up-down-bold-outline"
  },
  {
    uuid: "a086f98e-2e7b-410b-8c4f-03280469c918",
    iconSet: "MaterialCommunityIcons",
    iconName: "arrow-up-drop-circle"
  },
  {
    uuid: "d562c295-5ace-4745-96a2-f28219f5bb7a",
    iconSet: "MaterialCommunityIcons",
    iconName: "arrow-up-drop-circle-outline"
  },
  {
    uuid: "3b4885fa-b698-43a1-b273-16e9b4224f9a",
    iconSet: "MaterialCommunityIcons",
    iconName: "arrow-up-thick"
  },
  {
    uuid: "b7db1828-a265-4701-ac7b-bb5ba20d11e5",
    iconSet: "MaterialCommunityIcons",
    iconName: "arrow-vertical-lock"
  },
  {
    uuid: "cbb04736-8d1c-4ddf-8998-09c4ed1b24ea",
    iconSet: "MaterialCommunityIcons",
    iconName: "artstation"
  },
  {
    uuid: "d01e898c-d591-45ac-82fc-d11325b79d23",
    iconSet: "MaterialCommunityIcons",
    iconName: "aspect-ratio"
  },
  {
    uuid: "ad55d7e6-e1ba-4de6-8f82-8ba7f7694bcc",
    iconSet: "MaterialCommunityIcons",
    iconName: "assistant"
  },
  {
    uuid: "da4770a0-b91e-4e22-a223-d83c8b9aefc5",
    iconSet: "MaterialCommunityIcons",
    iconName: "asterisk"
  },
  {
    uuid: "73f6bf52-54eb-410a-8cd5-55001b3551b6",
    iconSet: "MaterialCommunityIcons",
    iconName: "at"
  },
  {
    uuid: "94e98996-c47e-4bca-a104-d575c7540a04",
    iconSet: "MaterialCommunityIcons",
    iconName: "atlassian"
  },
  {
    uuid: "0c8db0ff-708d-4819-8788-00d3992bb9a2",
    iconSet: "MaterialCommunityIcons",
    iconName: "atm"
  },
  {
    uuid: "a896dfa6-75a7-47ec-aba9-66c8fe77e2dc",
    iconSet: "MaterialCommunityIcons",
    iconName: "atom"
  },
  {
    uuid: "c17fe2d3-a0cf-4c3d-b847-aaf51413bcd2",
    iconSet: "MaterialCommunityIcons",
    iconName: "atom-variant"
  },
  {
    uuid: "5c24e121-2bce-46db-8a1e-fad11f8c6a83",
    iconSet: "MaterialCommunityIcons",
    iconName: "attachment"
  },
  {
    uuid: "61d6ce3e-4ef4-4524-986a-72d72d7d22fc",
    iconSet: "MaterialCommunityIcons",
    iconName: "audio-video"
  },
  {
    uuid: "ad08c206-7991-466d-a384-1e8693b16d92",
    iconSet: "MaterialCommunityIcons",
    iconName: "audio-video-off"
  },
  {
    uuid: "69be36e5-ef83-4b9e-a245-fb8e5d88a7d5",
    iconSet: "MaterialCommunityIcons",
    iconName: "augmented-reality"
  },
  {
    uuid: "aa18c20a-0178-4541-b605-bf281bf461c5",
    iconSet: "MaterialCommunityIcons",
    iconName: "auto-download"
  },
  {
    uuid: "494fbc0e-bdbc-459b-b887-f5fa0ad7b2f8",
    iconSet: "MaterialCommunityIcons",
    iconName: "auto-fix"
  },
  {
    uuid: "272c127b-f56b-4888-a097-9f2926d683e4",
    iconSet: "MaterialCommunityIcons",
    iconName: "auto-upload"
  },
  {
    uuid: "9c1a80ef-1e28-4fdb-b9e2-798316c1a9db",
    iconSet: "MaterialCommunityIcons",
    iconName: "autorenew"
  },
  {
    uuid: "f929ec60-8d70-4fda-b378-9176caa1c4d1",
    iconSet: "MaterialCommunityIcons",
    iconName: "av-timer"
  },
  {
    uuid: "1fb16b14-f768-4731-b9ab-69e7520d0c71",
    iconSet: "MaterialCommunityIcons",
    iconName: "aws"
  },
  {
    uuid: "37b89b84-0054-42dc-bcc0-475c985612b1",
    iconSet: "MaterialCommunityIcons",
    iconName: "axe"
  },
  {
    uuid: "0b177668-1e56-4ef1-88af-1f11585e32aa",
    iconSet: "MaterialCommunityIcons",
    iconName: "axis"
  },
  {
    uuid: "2853f28a-fb46-485b-a778-9c09f5b3872a",
    iconSet: "MaterialCommunityIcons",
    iconName: "axis-arrow"
  },
  {
    uuid: "5c221fd6-2774-451e-8103-720d0d67cfbb",
    iconSet: "MaterialCommunityIcons",
    iconName: "axis-arrow-info"
  },
  {
    uuid: "37f4c643-80c4-4b81-b29a-f399b50747e0",
    iconSet: "MaterialCommunityIcons",
    iconName: "axis-arrow-lock"
  },
  {
    uuid: "002cf0da-d8b2-419a-8324-3d171ffb55b8",
    iconSet: "MaterialCommunityIcons",
    iconName: "axis-lock"
  },
  {
    uuid: "a322082a-2807-4135-9829-447cfc3dc7c6",
    iconSet: "MaterialCommunityIcons",
    iconName: "axis-x-arrow"
  },
  {
    uuid: "0880b910-d0e2-4375-9921-2e17e8aadb30",
    iconSet: "MaterialCommunityIcons",
    iconName: "axis-x-arrow-lock"
  },
  {
    uuid: "7b6f4bea-8900-4be1-a5fa-b7cb7898fe20",
    iconSet: "MaterialCommunityIcons",
    iconName: "axis-x-rotate-clockwise"
  },
  {
    uuid: "755dffba-4c69-4120-85b5-3b3fd36b9388",
    iconSet: "MaterialCommunityIcons",
    iconName: "axis-x-rotate-counterclockwise"
  },
  {
    uuid: "7899c4c2-9c91-4448-a70e-59319394bdcd",
    iconSet: "MaterialCommunityIcons",
    iconName: "axis-x-y-arrow-lock"
  },
  {
    uuid: "5bd5de25-9303-4c10-9934-fba02a4d60e1",
    iconSet: "MaterialCommunityIcons",
    iconName: "axis-y-arrow"
  },
  {
    uuid: "b7fb8e75-6b53-4784-b7c1-d91c2c5663e2",
    iconSet: "MaterialCommunityIcons",
    iconName: "axis-y-arrow-lock"
  },
  {
    uuid: "80aafe5d-392d-418b-a98d-053ad38172b7",
    iconSet: "MaterialCommunityIcons",
    iconName: "axis-y-rotate-clockwise"
  },
  {
    uuid: "d8b3d984-cbf9-4544-941d-0421fd7c8467",
    iconSet: "MaterialCommunityIcons",
    iconName: "axis-y-rotate-counterclockwise"
  },
  {
    uuid: "9e1f433d-572d-4cf4-afe3-1d890295f38c",
    iconSet: "MaterialCommunityIcons",
    iconName: "axis-z-arrow"
  },
  {
    uuid: "32a5fd22-024f-4365-8e84-f190224fd563",
    iconSet: "MaterialCommunityIcons",
    iconName: "axis-z-arrow-lock"
  },
  {
    uuid: "ec303b92-461c-4849-932e-5fbb9dd9bf3a",
    iconSet: "MaterialCommunityIcons",
    iconName: "axis-z-rotate-clockwise"
  },
  {
    uuid: "2bf3cae9-5ec7-47f2-bc22-792a50574252",
    iconSet: "MaterialCommunityIcons",
    iconName: "axis-z-rotate-counterclockwise"
  },
  {
    uuid: "685843f3-0f52-46de-afcc-4d281a9ea580",
    iconSet: "MaterialCommunityIcons",
    iconName: "babel"
  },
  {
    uuid: "e6ae5ec5-1431-40e5-bbfb-8ba63a715a7d",
    iconSet: "MaterialCommunityIcons",
    iconName: "baby"
  },
  {
    uuid: "12b0b4e0-6b15-4161-b533-b01c918dc629",
    iconSet: "MaterialCommunityIcons",
    iconName: "baby-bottle"
  },
  {
    uuid: "8e28f0c0-5581-47d0-80e5-b073eb94aba8",
    iconSet: "MaterialCommunityIcons",
    iconName: "baby-bottle-outline"
  },
  {
    uuid: "1b324337-d900-4e58-8a17-0452a094d56d",
    iconSet: "MaterialCommunityIcons",
    iconName: "baby-buggy"
  },
  {
    uuid: "ae8fc022-eeea-4c84-8027-911b4065835b",
    iconSet: "MaterialCommunityIcons",
    iconName: "baby-carriage"
  },
  {
    uuid: "957516a3-6222-4f2a-a01c-0c78ad6cdf03",
    iconSet: "MaterialCommunityIcons",
    iconName: "baby-carriage-off"
  },
  {
    uuid: "9f1b1d2e-d28c-436d-ba25-6752ea0acf45",
    iconSet: "MaterialCommunityIcons",
    iconName: "baby-face"
  },
  {
    uuid: "ba8f7333-f606-4ef0-8b1f-ac181939923d",
    iconSet: "MaterialCommunityIcons",
    iconName: "baby-face-outline"
  },
  {
    uuid: "8d45a257-d241-41cc-bba8-604f2b283a6d",
    iconSet: "MaterialCommunityIcons",
    iconName: "backburger"
  },
  {
    uuid: "3fdcd724-98c8-44be-9d57-6c40d6a1eb67",
    iconSet: "MaterialCommunityIcons",
    iconName: "backspace"
  },
  {
    uuid: "b1f725b2-9080-41f9-be7a-a3a08228d6a8",
    iconSet: "MaterialCommunityIcons",
    iconName: "backspace-outline"
  },
  {
    uuid: "a28b3ba2-b6fa-4fad-9fb1-8c033f8b4b5e",
    iconSet: "MaterialCommunityIcons",
    iconName: "backspace-reverse"
  },
  {
    uuid: "1839f259-db76-47bf-8046-1602d5451382",
    iconSet: "MaterialCommunityIcons",
    iconName: "backspace-reverse-outline"
  },
  {
    uuid: "f1ed30cf-ef26-43b9-8caf-41ccf9c2c322",
    iconSet: "MaterialCommunityIcons",
    iconName: "backup-restore"
  },
  {
    uuid: "917503f3-8114-49d5-9401-158056cf61f8",
    iconSet: "MaterialCommunityIcons",
    iconName: "bacteria"
  },
  {
    uuid: "3a52f4a7-7bdf-42ed-9855-8c45ac81e454",
    iconSet: "MaterialCommunityIcons",
    iconName: "bacteria-outline"
  },
  {
    uuid: "0e4cd169-2e76-4c6e-8038-f7bb302841fe",
    iconSet: "MaterialCommunityIcons",
    iconName: "badge-account"
  },
  {
    uuid: "588440e7-8cd8-4bba-b7d2-7cc052b28de8",
    iconSet: "MaterialCommunityIcons",
    iconName: "badge-account-alert"
  },
  {
    uuid: "70c64a33-5a33-486d-939f-eed01e731b7c",
    iconSet: "MaterialCommunityIcons",
    iconName: "badge-account-alert-outline"
  },
  {
    uuid: "2dee4f05-96a8-4f03-9986-69ea44366fca",
    iconSet: "MaterialCommunityIcons",
    iconName: "badge-account-horizontal"
  },
  {
    uuid: "8a85a673-30f8-46fe-8b79-db6e2bd1a2bd",
    iconSet: "MaterialCommunityIcons",
    iconName: "badge-account-horizontal-outline"
  },
  {
    uuid: "b61c90a4-9a54-42ac-a6c9-86b1cae0f6ad",
    iconSet: "MaterialCommunityIcons",
    iconName: "badge-account-outline"
  },
  {
    uuid: "429e06dc-bc61-46ca-afc3-2887cc54b61a",
    iconSet: "MaterialCommunityIcons",
    iconName: "badminton"
  },
  {
    uuid: "f71677ec-af3e-49a4-b4fd-abd803da7587",
    iconSet: "MaterialCommunityIcons",
    iconName: "bag-carry-on"
  },
  {
    uuid: "2d56a596-3630-452c-baec-fd5f22195f92",
    iconSet: "MaterialCommunityIcons",
    iconName: "bag-carry-on-check"
  },
  {
    uuid: "dad7a23a-32fe-4233-9ac0-9681a7e5daeb",
    iconSet: "MaterialCommunityIcons",
    iconName: "bag-carry-on-off"
  },
  {
    uuid: "dee3c572-ea30-4a46-8a7f-cb76985324ba",
    iconSet: "MaterialCommunityIcons",
    iconName: "bag-checked"
  },
  {
    uuid: "b3a074da-d721-4122-8880-d4e6fa3ef637",
    iconSet: "MaterialCommunityIcons",
    iconName: "bag-personal"
  },
  {
    uuid: "68598d69-8d31-4677-8936-3f448f7b04b4",
    iconSet: "MaterialCommunityIcons",
    iconName: "bag-personal-off"
  },
  {
    uuid: "d2c56e00-72c7-4998-b32e-cf4d40a6b2d3",
    iconSet: "MaterialCommunityIcons",
    iconName: "bag-personal-off-outline"
  },
  {
    uuid: "d91e8913-a800-47e6-8368-318da4afcea8",
    iconSet: "MaterialCommunityIcons",
    iconName: "bag-personal-outline"
  },
  {
    uuid: "fe7f92ee-0265-4943-b9ec-ca0f73c9e894",
    iconSet: "MaterialCommunityIcons",
    iconName: "baguette"
  },
  {
    uuid: "4565cf29-f922-4c0e-9187-6fa69399513d",
    iconSet: "MaterialCommunityIcons",
    iconName: "balloon"
  },
  {
    uuid: "3fb51e90-5768-43f0-8a2a-4a8b26732464",
    iconSet: "MaterialCommunityIcons",
    iconName: "ballot"
  },
  {
    uuid: "c06f7dac-b0dd-4339-9176-cf094482d6dc",
    iconSet: "MaterialCommunityIcons",
    iconName: "ballot-outline"
  },
  {
    uuid: "a8662131-a62c-4c36-9ca1-a03cf424ddba",
    iconSet: "MaterialCommunityIcons",
    iconName: "ballot-recount"
  },
  {
    uuid: "087b74b3-ff6d-447d-9c85-dd27fd20c485",
    iconSet: "MaterialCommunityIcons",
    iconName: "ballot-recount-outline"
  },
  {
    uuid: "d503958e-317f-47ee-8509-0841d98ea2ee",
    iconSet: "MaterialCommunityIcons",
    iconName: "bandage"
  },
  {
    uuid: "cf61262b-0122-4152-a0cb-3f819e1a5171",
    iconSet: "MaterialCommunityIcons",
    iconName: "bandcamp"
  },
  {
    uuid: "4b42ab52-af9f-478d-9c5a-a3edbdfcdabf",
    iconSet: "MaterialCommunityIcons",
    iconName: "bank"
  },
  {
    uuid: "30cede37-13ee-406a-90fb-96b4c0ecdabd",
    iconSet: "MaterialCommunityIcons",
    iconName: "bank-minus"
  },
  {
    uuid: "3fa48d56-fcad-4bf8-b2c8-088870b9e535",
    iconSet: "MaterialCommunityIcons",
    iconName: "bank-outline"
  },
  {
    uuid: "7591c70a-af4e-4f91-b433-28a3612144fe",
    iconSet: "MaterialCommunityIcons",
    iconName: "bank-plus"
  },
  {
    uuid: "4bf937c3-61de-4e23-af75-d7df6ecfb5cc",
    iconSet: "MaterialCommunityIcons",
    iconName: "bank-remove"
  },
  {
    uuid: "71f88ebf-9bc3-4521-9014-6688e8f09948",
    iconSet: "MaterialCommunityIcons",
    iconName: "bank-transfer"
  },
  {
    uuid: "22cf6ccd-850d-4a35-b6fb-35c2db719538",
    iconSet: "MaterialCommunityIcons",
    iconName: "bank-transfer-in"
  },
  {
    uuid: "9278c8d3-6cab-44cf-ade5-ba50a0d0a73a",
    iconSet: "MaterialCommunityIcons",
    iconName: "bank-transfer-out"
  },
  {
    uuid: "71d7ae19-94c7-4154-9342-eecd32015bc6",
    iconSet: "MaterialCommunityIcons",
    iconName: "barcode"
  },
  {
    uuid: "260de8fb-b100-4cc9-a335-319e116df458",
    iconSet: "MaterialCommunityIcons",
    iconName: "barcode-off"
  },
  {
    uuid: "0115f09d-6fb1-496e-ac1a-7a4ea96eff82",
    iconSet: "MaterialCommunityIcons",
    iconName: "barcode-scan"
  },
  {
    uuid: "f08ef256-38d3-4be7-b160-5cd9b3d9e9e0",
    iconSet: "MaterialCommunityIcons",
    iconName: "barley"
  },
  {
    uuid: "1044335f-186a-410e-a647-6d446055beff",
    iconSet: "MaterialCommunityIcons",
    iconName: "barley-off"
  },
  {
    uuid: "85801f6c-1c97-494c-86f8-da2b97f08f99",
    iconSet: "MaterialCommunityIcons",
    iconName: "barn"
  },
  {
    uuid: "261dbfb0-adef-4862-ad50-52cd88397346",
    iconSet: "MaterialCommunityIcons",
    iconName: "barrel"
  },
  {
    uuid: "943f328a-3bcc-4419-ae25-50254851a9ca",
    iconSet: "MaterialCommunityIcons",
    iconName: "baseball"
  },
  {
    uuid: "81b277c0-9326-4aee-a845-aadc6b140075",
    iconSet: "MaterialCommunityIcons",
    iconName: "baseball-bat"
  },
  {
    uuid: "ecdb901d-68b6-4de3-93f6-b0885b1e5b21",
    iconSet: "MaterialCommunityIcons",
    iconName: "bash"
  },
  {
    uuid: "1e1d7d10-fc3a-4760-ab97-8a62a89534da",
    iconSet: "MaterialCommunityIcons",
    iconName: "basket"
  },
  {
    uuid: "03572586-b14a-4e1c-baed-b325fa8e5f48",
    iconSet: "MaterialCommunityIcons",
    iconName: "basket-fill"
  },
  {
    uuid: "aa32ca66-1b55-4599-aeb6-7b0f0e66ef5b",
    iconSet: "MaterialCommunityIcons",
    iconName: "basket-outline"
  },
  {
    uuid: "e185f6a3-723a-4ede-a8d8-e61a2fc5fc62",
    iconSet: "MaterialCommunityIcons",
    iconName: "basket-unfill"
  },
  {
    uuid: "b58afd75-3ae6-46ef-8863-e65b5e933aae",
    iconSet: "MaterialCommunityIcons",
    iconName: "basketball"
  },
  {
    uuid: "d733adc9-648d-4931-bd6f-1021a3c83377",
    iconSet: "MaterialCommunityIcons",
    iconName: "basketball-hoop"
  },
  {
    uuid: "48ab48eb-9327-42c5-b194-d54985ddab91",
    iconSet: "MaterialCommunityIcons",
    iconName: "basketball-hoop-outline"
  },
  {
    uuid: "559ca9fa-bb59-4350-8d03-fc5c76b71296",
    iconSet: "MaterialCommunityIcons",
    iconName: "bat"
  },
  {
    uuid: "4accce33-5eda-40d9-9161-2a9975425db1",
    iconSet: "MaterialCommunityIcons",
    iconName: "battery"
  },
  {
    uuid: "a948f604-02a6-4789-bb59-e0bf6b396bb1",
    iconSet: "MaterialCommunityIcons",
    iconName: "battery-10"
  },
  {
    uuid: "73c8ede5-6427-41f6-8f80-deef432cafcd",
    iconSet: "MaterialCommunityIcons",
    iconName: "battery-10-bluetooth"
  },
  {
    uuid: "56f19c77-3f17-4d0d-b259-a78ac2956870",
    iconSet: "MaterialCommunityIcons",
    iconName: "battery-20"
  },
  {
    uuid: "96c8c9f9-fad5-4bcb-88e5-c70c413f166c",
    iconSet: "MaterialCommunityIcons",
    iconName: "battery-20-bluetooth"
  },
  {
    uuid: "6bff2038-2737-4e5b-9fda-fc857ff2c3e6",
    iconSet: "MaterialCommunityIcons",
    iconName: "battery-30"
  },
  {
    uuid: "9ab6cba9-79f8-4214-96e0-b3b4ad450b2e",
    iconSet: "MaterialCommunityIcons",
    iconName: "battery-30-bluetooth"
  },
  {
    uuid: "bf61c440-cc94-4101-bff7-f332c0043c85",
    iconSet: "MaterialCommunityIcons",
    iconName: "battery-40"
  },
  {
    uuid: "623a2643-c3e4-4712-827f-c0835e5c4069",
    iconSet: "MaterialCommunityIcons",
    iconName: "battery-40-bluetooth"
  },
  {
    uuid: "4c377544-7a07-4459-a339-51cef1331921",
    iconSet: "MaterialCommunityIcons",
    iconName: "battery-50"
  },
  {
    uuid: "5ef9129a-9c55-4027-be7e-e06536fcc18a",
    iconSet: "MaterialCommunityIcons",
    iconName: "battery-50-bluetooth"
  },
  {
    uuid: "97dda2e9-37a2-4f07-a866-338df12b0fdf",
    iconSet: "MaterialCommunityIcons",
    iconName: "battery-60"
  },
  {
    uuid: "69fd40a7-daac-464f-9a76-4574028c258d",
    iconSet: "MaterialCommunityIcons",
    iconName: "battery-60-bluetooth"
  },
  {
    uuid: "7bf18e29-8f6e-4022-ad05-f0bab2c7bc1a",
    iconSet: "MaterialCommunityIcons",
    iconName: "battery-70"
  },
  {
    uuid: "1732d1b0-dc7c-4939-afab-c8c1b6ed603c",
    iconSet: "MaterialCommunityIcons",
    iconName: "battery-70-bluetooth"
  },
  {
    uuid: "5a1eba55-116f-4eef-90bd-5f30fd032f7c",
    iconSet: "MaterialCommunityIcons",
    iconName: "battery-80"
  },
  {
    uuid: "29236877-28fb-4eb7-8c05-d2fa7d320382",
    iconSet: "MaterialCommunityIcons",
    iconName: "battery-80-bluetooth"
  },
  {
    uuid: "22a8dd2c-1286-414e-ab55-fd1b7ffb8592",
    iconSet: "MaterialCommunityIcons",
    iconName: "battery-90"
  },
  {
    uuid: "e8bc9e10-b974-4065-b7fb-10b6b2fd0c13",
    iconSet: "MaterialCommunityIcons",
    iconName: "battery-90-bluetooth"
  },
  {
    uuid: "06eb30aa-7963-4f6b-b9dd-316d863c1675",
    iconSet: "MaterialCommunityIcons",
    iconName: "battery-alert"
  },
  {
    uuid: "9788a140-9ad3-4fb4-b0aa-7436e3a2d6a6",
    iconSet: "MaterialCommunityIcons",
    iconName: "battery-alert-bluetooth"
  },
  {
    uuid: "50731893-8ef0-4924-87c7-7c39a8dacd73",
    iconSet: "MaterialCommunityIcons",
    iconName: "battery-alert-variant"
  },
  {
    uuid: "361fa558-40e6-4d3c-ab4e-22e32fecb12f",
    iconSet: "MaterialCommunityIcons",
    iconName: "battery-alert-variant-outline"
  },
  {
    uuid: "19bc0915-140a-4c2d-919f-5bdb0f18a6fe",
    iconSet: "MaterialCommunityIcons",
    iconName: "battery-bluetooth"
  },
  {
    uuid: "4b754b58-bf5f-4ab8-beb9-616af28c3ed8",
    iconSet: "MaterialCommunityIcons",
    iconName: "battery-bluetooth-variant"
  },
  {
    uuid: "4b69faad-f364-4283-a0fb-59ff7551432e",
    iconSet: "MaterialCommunityIcons",
    iconName: "battery-charging"
  },
  {
    uuid: "808c9e93-af81-4415-96e5-3e2467d3b843",
    iconSet: "MaterialCommunityIcons",
    iconName: "battery-charging-10"
  },
  {
    uuid: "f3684b94-a19a-4c8c-9c07-2e1cfcf763bc",
    iconSet: "MaterialCommunityIcons",
    iconName: "battery-charging-100"
  },
  {
    uuid: "7c0a8f0d-fc67-4c59-bf9a-4bdd17779aac",
    iconSet: "MaterialCommunityIcons",
    iconName: "battery-charging-20"
  },
  {
    uuid: "511b8285-9e38-494b-a73a-b04fd9bebcea",
    iconSet: "MaterialCommunityIcons",
    iconName: "battery-charging-30"
  },
  {
    uuid: "5ec0e9b2-47c5-49cd-8218-a93132c36be8",
    iconSet: "MaterialCommunityIcons",
    iconName: "battery-charging-40"
  },
  {
    uuid: "e0f93618-2683-4fad-a689-7c3feb8e3bd5",
    iconSet: "MaterialCommunityIcons",
    iconName: "battery-charging-50"
  },
  {
    uuid: "1c20f904-4a02-448e-91fb-d34a84a698c6",
    iconSet: "MaterialCommunityIcons",
    iconName: "battery-charging-60"
  },
  {
    uuid: "6a35dd54-7632-4eaa-8705-c25b72dffd45",
    iconSet: "MaterialCommunityIcons",
    iconName: "battery-charging-70"
  },
  {
    uuid: "137c72e8-a650-4dc6-9fad-aba786266e8c",
    iconSet: "MaterialCommunityIcons",
    iconName: "battery-charging-80"
  },
  {
    uuid: "1bc6361d-f927-451b-841d-e53188294230",
    iconSet: "MaterialCommunityIcons",
    iconName: "battery-charging-90"
  },
  {
    uuid: "542bc4dc-b4f7-4410-9b47-c28f20b53fd2",
    iconSet: "MaterialCommunityIcons",
    iconName: "battery-charging-high"
  },
  {
    uuid: "e61e33f8-e5f0-4d49-9125-de82d93db80a",
    iconSet: "MaterialCommunityIcons",
    iconName: "battery-charging-low"
  },
  {
    uuid: "74b893f1-e926-43e6-8f40-f9eb53f215b1",
    iconSet: "MaterialCommunityIcons",
    iconName: "battery-charging-medium"
  },
  {
    uuid: "92ca32e9-00cf-481b-97eb-a0873c69431e",
    iconSet: "MaterialCommunityIcons",
    iconName: "battery-charging-outline"
  },
  {
    uuid: "946ccdb0-d317-46cc-9b42-97bd56786cd6",
    iconSet: "MaterialCommunityIcons",
    iconName: "battery-charging-wireless"
  },
  {
    uuid: "8c90a00e-220f-4d3c-a947-427d8c00170c",
    iconSet: "MaterialCommunityIcons",
    iconName: "battery-charging-wireless-10"
  },
  {
    uuid: "1479d6c2-bda6-4585-b380-6e125638a1ea",
    iconSet: "MaterialCommunityIcons",
    iconName: "battery-charging-wireless-20"
  },
  {
    uuid: "a2d57fa9-b40d-401a-9d9e-0a2080e1c051",
    iconSet: "MaterialCommunityIcons",
    iconName: "battery-charging-wireless-30"
  },
  {
    uuid: "a3173814-3a3e-4db2-bfeb-71e559e3402e",
    iconSet: "MaterialCommunityIcons",
    iconName: "battery-charging-wireless-40"
  },
  {
    uuid: "c13ac79e-1417-490d-bff5-90b66ff67a28",
    iconSet: "MaterialCommunityIcons",
    iconName: "battery-charging-wireless-50"
  },
  {
    uuid: "675e734f-6826-4750-8fdd-d3102c530e52",
    iconSet: "MaterialCommunityIcons",
    iconName: "battery-charging-wireless-60"
  },
  {
    uuid: "b4e1582f-21c6-422f-961c-30be979c836c",
    iconSet: "MaterialCommunityIcons",
    iconName: "battery-charging-wireless-70"
  },
  {
    uuid: "2153295d-c5ac-4e21-bf64-5d1abb0600be",
    iconSet: "MaterialCommunityIcons",
    iconName: "battery-charging-wireless-80"
  },
  {
    uuid: "58d93269-07d5-4199-896a-260395dd6e91",
    iconSet: "MaterialCommunityIcons",
    iconName: "battery-charging-wireless-90"
  },
  {
    uuid: "d94ee520-bbc3-4562-99af-dbf45574b58b",
    iconSet: "MaterialCommunityIcons",
    iconName: "battery-charging-wireless-alert"
  },
  {
    uuid: "3fe05bea-8eee-4c65-85fe-715f31c88a24",
    iconSet: "MaterialCommunityIcons",
    iconName: "battery-charging-wireless-outline"
  },
  {
    uuid: "cdd728b4-55b2-4f38-ba5a-8e921915fa78",
    iconSet: "MaterialCommunityIcons",
    iconName: "battery-heart"
  },
  {
    uuid: "a3bb3c84-50fb-445a-af0d-608f0cb21ce8",
    iconSet: "MaterialCommunityIcons",
    iconName: "battery-heart-outline"
  },
  {
    uuid: "222e032d-0d20-401c-abfd-2845c7c00636",
    iconSet: "MaterialCommunityIcons",
    iconName: "battery-heart-variant"
  },
  {
    uuid: "3162634d-41d2-4640-bcaa-05874c3d03f6",
    iconSet: "MaterialCommunityIcons",
    iconName: "battery-high"
  },
  {
    uuid: "c4e9f2fe-95d0-40c0-9812-e57146524b9a",
    iconSet: "MaterialCommunityIcons",
    iconName: "battery-low"
  },
  {
    uuid: "b0125d27-f94d-432b-bdc9-422a4250a4a1",
    iconSet: "MaterialCommunityIcons",
    iconName: "battery-medium"
  },
  {
    uuid: "abd9a81f-b9ba-4db4-8480-45c473525fc7",
    iconSet: "MaterialCommunityIcons",
    iconName: "battery-minus"
  },
  {
    uuid: "e468fd3e-e977-46c9-9a6a-d0ffda382044",
    iconSet: "MaterialCommunityIcons",
    iconName: "battery-negative"
  },
  {
    uuid: "ad53e239-4904-418d-b8d2-6c7a2ff9047c",
    iconSet: "MaterialCommunityIcons",
    iconName: "battery-off"
  },
  {
    uuid: "657ecfe0-6fa5-4dab-8091-56d662177c75",
    iconSet: "MaterialCommunityIcons",
    iconName: "battery-off-outline"
  },
  {
    uuid: "a7c12f22-149f-40fd-94f5-fe671fc0d90a",
    iconSet: "MaterialCommunityIcons",
    iconName: "battery-outline"
  },
  {
    uuid: "0fb1cf08-0e38-4e6b-9858-3fff20bdafa1",
    iconSet: "MaterialCommunityIcons",
    iconName: "battery-plus"
  },
  {
    uuid: "6f6c7a12-c6ac-4706-9d9e-c94d43fa5925",
    iconSet: "MaterialCommunityIcons",
    iconName: "battery-positive"
  },
  {
    uuid: "e15e4a91-8d79-4b3e-aa2d-33f153810aef",
    iconSet: "MaterialCommunityIcons",
    iconName: "battery-unknown"
  },
  {
    uuid: "ec212a9b-7705-4708-b7c3-d98c2698ee0e",
    iconSet: "MaterialCommunityIcons",
    iconName: "battery-unknown-bluetooth"
  },
  {
    uuid: "997938d9-bc53-45fc-b85b-52dacb91aa94",
    iconSet: "MaterialCommunityIcons",
    iconName: "battlenet"
  },
  {
    uuid: "ae6b4247-991f-4a06-927d-65eb3491635d",
    iconSet: "MaterialCommunityIcons",
    iconName: "beach"
  },
  {
    uuid: "bb1d0913-9ed8-48d3-83a1-fde12a6dbadb",
    iconSet: "MaterialCommunityIcons",
    iconName: "beaker"
  },
  {
    uuid: "33c9840d-4035-4ee4-8ec0-868a5447d9f2",
    iconSet: "MaterialCommunityIcons",
    iconName: "beaker-alert"
  },
  {
    uuid: "52003366-2082-4ca9-8a6f-0de6d1e82304",
    iconSet: "MaterialCommunityIcons",
    iconName: "beaker-alert-outline"
  },
  {
    uuid: "e1c1d0eb-2d97-4c78-86e6-657f5f5e8158",
    iconSet: "MaterialCommunityIcons",
    iconName: "beaker-check"
  },
  {
    uuid: "6e681f6d-9950-4122-85e3-84603a62fbdc",
    iconSet: "MaterialCommunityIcons",
    iconName: "beaker-check-outline"
  },
  {
    uuid: "6aaf3f8f-817e-4fcb-a5a1-2e81e532eb1a",
    iconSet: "MaterialCommunityIcons",
    iconName: "beaker-minus"
  },
  {
    uuid: "64c9070e-285f-4d69-a44c-c3380bfcf32a",
    iconSet: "MaterialCommunityIcons",
    iconName: "beaker-minus-outline"
  },
  {
    uuid: "b3f8e2db-e61d-4aa1-818f-8738612443cf",
    iconSet: "MaterialCommunityIcons",
    iconName: "beaker-outline"
  },
  {
    uuid: "1f37ab8d-524a-4f9e-85de-4653c456f741",
    iconSet: "MaterialCommunityIcons",
    iconName: "beaker-plus"
  },
  {
    uuid: "617e89aa-0a01-4d55-bfeb-f0c9fe0ed18f",
    iconSet: "MaterialCommunityIcons",
    iconName: "beaker-plus-outline"
  },
  {
    uuid: "87d45af7-f005-4552-b47d-cd2ef318d97a",
    iconSet: "MaterialCommunityIcons",
    iconName: "beaker-question"
  },
  {
    uuid: "22e6e2b7-cbdd-4153-8989-87bef0fce642",
    iconSet: "MaterialCommunityIcons",
    iconName: "beaker-question-outline"
  },
  {
    uuid: "b4074970-4605-49e5-9554-781d7cbf8276",
    iconSet: "MaterialCommunityIcons",
    iconName: "beaker-remove"
  },
  {
    uuid: "fceacef9-ee4f-44e5-a98b-326ecd58baba",
    iconSet: "MaterialCommunityIcons",
    iconName: "beaker-remove-outline"
  },
  {
    uuid: "d413f60c-fc95-4d39-9892-27de40e2c7f4",
    iconSet: "MaterialCommunityIcons",
    iconName: "bed"
  },
  {
    uuid: "e891f488-fffe-4ad6-a4ac-bb018e990cea",
    iconSet: "MaterialCommunityIcons",
    iconName: "bed-double"
  },
  {
    uuid: "05b2a4f8-0b14-43f0-8c46-dfc544f67329",
    iconSet: "MaterialCommunityIcons",
    iconName: "bed-double-outline"
  },
  {
    uuid: "255356fa-dc7e-4370-b52d-3b19be6c6671",
    iconSet: "MaterialCommunityIcons",
    iconName: "bed-empty"
  },
  {
    uuid: "c9cd8721-f457-4b59-9c43-ca9ce27247cf",
    iconSet: "MaterialCommunityIcons",
    iconName: "bed-king"
  },
  {
    uuid: "9902b8d5-4a3f-4893-9774-a4bd3af3cac9",
    iconSet: "MaterialCommunityIcons",
    iconName: "bed-king-outline"
  },
  {
    uuid: "d79d1c15-8ca2-42be-afda-c2d3cf2a30d6",
    iconSet: "MaterialCommunityIcons",
    iconName: "bed-outline"
  },
  {
    uuid: "53fa4c16-6f6f-4275-9afc-d2513fa81eb4",
    iconSet: "MaterialCommunityIcons",
    iconName: "bed-queen"
  },
  {
    uuid: "941bb6a9-2668-4fce-a836-87059235cb54",
    iconSet: "MaterialCommunityIcons",
    iconName: "bed-queen-outline"
  },
  {
    uuid: "f69adb9c-020f-4f03-b82d-e7d9443b408f",
    iconSet: "MaterialCommunityIcons",
    iconName: "bed-single"
  },
  {
    uuid: "5cc57f07-19ef-475d-899a-4d7160b400ec",
    iconSet: "MaterialCommunityIcons",
    iconName: "bed-single-outline"
  },
  {
    uuid: "a4477770-146e-4ee8-a234-a0e7d1d306ec",
    iconSet: "MaterialCommunityIcons",
    iconName: "bee"
  },
  {
    uuid: "6ba185c6-0b0e-4b01-bc38-8ccda1aed27e",
    iconSet: "MaterialCommunityIcons",
    iconName: "bee-flower"
  },
  {
    uuid: "789f8af0-bde1-4923-93bb-6b213195770e",
    iconSet: "MaterialCommunityIcons",
    iconName: "beehive-off-outline"
  },
  {
    uuid: "af865f6b-11fd-420b-a36c-753668e3ddc2",
    iconSet: "MaterialCommunityIcons",
    iconName: "beehive-outline"
  },
  {
    uuid: "b78f6824-c9f8-41ef-85b2-2372cc47406c",
    iconSet: "MaterialCommunityIcons",
    iconName: "beer"
  },
  {
    uuid: "97bfc96a-aa23-4ba8-ac54-152867a91132",
    iconSet: "MaterialCommunityIcons",
    iconName: "beer-outline"
  },
  {
    uuid: "025af4b1-ee49-453f-998c-7f9a04275c7f",
    iconSet: "MaterialCommunityIcons",
    iconName: "bell"
  },
  {
    uuid: "c67485cf-76c6-4c9c-af09-d75f7ab45b47",
    iconSet: "MaterialCommunityIcons",
    iconName: "bell-alert"
  },
  {
    uuid: "b2ac896b-6b12-4e0d-9351-deebdac5bb52",
    iconSet: "MaterialCommunityIcons",
    iconName: "bell-alert-outline"
  },
  {
    uuid: "a8656bba-e85a-40bd-bc7b-bbbee6e74bff",
    iconSet: "MaterialCommunityIcons",
    iconName: "bell-cancel"
  },
  {
    uuid: "be6180e7-6e51-40f7-ad9e-6d46eae8fb65",
    iconSet: "MaterialCommunityIcons",
    iconName: "bell-cancel-outline"
  },
  {
    uuid: "2f589d65-15f0-4a1c-afbd-b95719606a36",
    iconSet: "MaterialCommunityIcons",
    iconName: "bell-check"
  },
  {
    uuid: "cbd11931-fa23-447a-bdfb-c3567401b9d2",
    iconSet: "MaterialCommunityIcons",
    iconName: "bell-check-outline"
  },
  {
    uuid: "166e8abe-8668-4001-a433-e4393f191eb2",
    iconSet: "MaterialCommunityIcons",
    iconName: "bell-circle"
  },
  {
    uuid: "31405ccc-0cd8-452a-a110-b0a81ce74590",
    iconSet: "MaterialCommunityIcons",
    iconName: "bell-circle-outline"
  },
  {
    uuid: "7b1c14d4-1b94-4f23-9e46-a84382ba3ff6",
    iconSet: "MaterialCommunityIcons",
    iconName: "bell-minus"
  },
  {
    uuid: "c88275d4-b801-4b5a-9aaf-a3f5a8614ba5",
    iconSet: "MaterialCommunityIcons",
    iconName: "bell-minus-outline"
  },
  {
    uuid: "b732bc37-88d6-47c9-8e2e-45709dd8580f",
    iconSet: "MaterialCommunityIcons",
    iconName: "bell-off"
  },
  {
    uuid: "0a64ad51-7d92-4cba-9f2e-5b6e629ebaea",
    iconSet: "MaterialCommunityIcons",
    iconName: "bell-off-outline"
  },
  {
    uuid: "bee2c7ec-492f-482b-92a5-9692e6f1f17a",
    iconSet: "MaterialCommunityIcons",
    iconName: "bell-outline"
  },
  {
    uuid: "3d9b5eb9-b797-49e0-b74b-79e651f76a0f",
    iconSet: "MaterialCommunityIcons",
    iconName: "bell-plus"
  },
  {
    uuid: "9f4b25d6-71f3-4659-ab56-1fa44e1a485e",
    iconSet: "MaterialCommunityIcons",
    iconName: "bell-plus-outline"
  },
  {
    uuid: "a541ce80-3983-48c2-a9d3-a545c153331e",
    iconSet: "MaterialCommunityIcons",
    iconName: "bell-remove"
  },
  {
    uuid: "ea7c42b1-30c6-4e46-883d-2854d4b659b0",
    iconSet: "MaterialCommunityIcons",
    iconName: "bell-remove-outline"
  },
  {
    uuid: "93e98c8b-d7fa-4ae9-aa31-a4d08e9eedef",
    iconSet: "MaterialCommunityIcons",
    iconName: "bell-ring"
  },
  {
    uuid: "acf637c3-3e21-4484-9ea4-bd94156e9647",
    iconSet: "MaterialCommunityIcons",
    iconName: "bell-ring-outline"
  },
  {
    uuid: "bdd9e48d-35cc-41ad-a27a-ffe2f9dee680",
    iconSet: "MaterialCommunityIcons",
    iconName: "bell-sleep"
  },
  {
    uuid: "25b8dc7d-0317-4875-a5d2-03c987073af7",
    iconSet: "MaterialCommunityIcons",
    iconName: "bell-sleep-outline"
  },
  {
    uuid: "63fb7267-82c0-4685-880f-19714cefec4a",
    iconSet: "MaterialCommunityIcons",
    iconName: "beta"
  },
  {
    uuid: "56d523bb-6506-4d01-b90b-bdd8de41b512",
    iconSet: "MaterialCommunityIcons",
    iconName: "betamax"
  },
  {
    uuid: "c82684ae-79d8-4fa8-aaf6-0dddb10c6be3",
    iconSet: "MaterialCommunityIcons",
    iconName: "biathlon"
  },
  {
    uuid: "eb4781e0-4c9f-45e2-adfb-a4fd19945dbe",
    iconSet: "MaterialCommunityIcons",
    iconName: "bicycle"
  },
  {
    uuid: "d5468695-828c-4785-a8e2-5b803700dae9",
    iconSet: "MaterialCommunityIcons",
    iconName: "bicycle-basket"
  },
  {
    uuid: "bb45291d-983a-43f7-ace5-eb88ef1b144f",
    iconSet: "MaterialCommunityIcons",
    iconName: "bike"
  },
  {
    uuid: "8aedf3ae-3a93-4e17-9685-f64a51095213",
    iconSet: "MaterialCommunityIcons",
    iconName: "bike-fast"
  },
  {
    uuid: "339017e2-981f-492a-bcef-7c1a58d84bd7",
    iconSet: "MaterialCommunityIcons",
    iconName: "billboard"
  },
  {
    uuid: "bc6a7855-5845-461c-99a2-2aa22c526a18",
    iconSet: "MaterialCommunityIcons",
    iconName: "billiards"
  },
  {
    uuid: "a5cc66ee-8ee8-43e7-bc6e-d4934583eb66",
    iconSet: "MaterialCommunityIcons",
    iconName: "billiards-rack"
  },
  {
    uuid: "3a6588df-aa55-4d2b-a542-897bd4d9e94d",
    iconSet: "MaterialCommunityIcons",
    iconName: "binoculars"
  },
  {
    uuid: "5a646689-db97-4a3d-b995-d8dac4a4f6d6",
    iconSet: "MaterialCommunityIcons",
    iconName: "bio"
  },
  {
    uuid: "bf9c72eb-5114-43f4-8af7-55bf6cbe5af2",
    iconSet: "MaterialCommunityIcons",
    iconName: "biohazard"
  },
  {
    uuid: "bf290445-3937-4cc7-a5eb-448c4565ab73",
    iconSet: "MaterialCommunityIcons",
    iconName: "bitbucket"
  },
  {
    uuid: "f7ad4ed2-8333-48c9-a5a4-bd97a492ea00",
    iconSet: "MaterialCommunityIcons",
    iconName: "bitcoin"
  },
  {
    uuid: "444f80e6-73c9-47f8-acda-5eeb2871f023",
    iconSet: "MaterialCommunityIcons",
    iconName: "black-mesa"
  },
  {
    uuid: "c62ec7bb-6ba3-4582-be4e-ea193f6419d0",
    iconSet: "MaterialCommunityIcons",
    iconName: "blender"
  },
  {
    uuid: "9fbfbfef-ae7b-4165-9b5f-019487f8c10a",
    iconSet: "MaterialCommunityIcons",
    iconName: "blender-software"
  },
  {
    uuid: "9d131709-801f-4634-aa28-4896be7df549",
    iconSet: "MaterialCommunityIcons",
    iconName: "blinds"
  },
  {
    uuid: "c6f93404-c3fd-48be-9bc5-a4bdc3eec2a4",
    iconSet: "MaterialCommunityIcons",
    iconName: "blinds-open"
  },
  {
    uuid: "7811a847-71bc-4cfc-9896-63a43e3c64ec",
    iconSet: "MaterialCommunityIcons",
    iconName: "block-helper"
  },
  {
    uuid: "0eed0e25-35f9-4dc5-80e1-115547da42d8",
    iconSet: "MaterialCommunityIcons",
    iconName: "blogger"
  },
  {
    uuid: "9b6a7b6d-2243-4b94-8edf-096e66c47c55",
    iconSet: "MaterialCommunityIcons",
    iconName: "blood-bag"
  },
  {
    uuid: "4bc4072d-6608-464b-902b-a288bcb73044",
    iconSet: "MaterialCommunityIcons",
    iconName: "bluetooth"
  },
  {
    uuid: "2bd2a429-7cc9-458a-8887-2ba0f03c9be3",
    iconSet: "MaterialCommunityIcons",
    iconName: "bluetooth-audio"
  },
  {
    uuid: "50a4a852-36a3-48c0-a629-3b24cb345db3",
    iconSet: "MaterialCommunityIcons",
    iconName: "bluetooth-connect"
  },
  {
    uuid: "f6042814-4dcc-4061-9886-f20764a52561",
    iconSet: "MaterialCommunityIcons",
    iconName: "bluetooth-off"
  },
  {
    uuid: "57ed8386-323e-4677-aaa5-2bc5013a4618",
    iconSet: "MaterialCommunityIcons",
    iconName: "bluetooth-settings"
  },
  {
    uuid: "ee49ce5b-ed4e-43db-b4dc-589da4bb1883",
    iconSet: "MaterialCommunityIcons",
    iconName: "bluetooth-transfer"
  },
  {
    uuid: "2c31a51e-d5b2-420a-a06f-98879d840e74",
    iconSet: "MaterialCommunityIcons",
    iconName: "blur"
  },
  {
    uuid: "f9438d1a-44ce-4ba7-84da-ac47e64687d1",
    iconSet: "MaterialCommunityIcons",
    iconName: "blur-linear"
  },
  {
    uuid: "6da02176-de8f-4a69-ae11-33ad928be7f0",
    iconSet: "MaterialCommunityIcons",
    iconName: "blur-off"
  },
  {
    uuid: "edf94dda-eed2-484c-8b0d-321042853b5f",
    iconSet: "MaterialCommunityIcons",
    iconName: "blur-radial"
  },
  {
    uuid: "95b75723-7766-4f0c-997e-c8435c1f08c0",
    iconSet: "MaterialCommunityIcons",
    iconName: "bolnisi-cross"
  },
  {
    uuid: "fe08cc45-9e5b-4f76-a42a-4006532061c2",
    iconSet: "MaterialCommunityIcons",
    iconName: "bolt"
  },
  {
    uuid: "ac6d7315-9e47-44f7-bb00-c7f9d590fffa",
    iconSet: "MaterialCommunityIcons",
    iconName: "bomb"
  },
  {
    uuid: "b3d3672a-c1ff-460c-8cf9-8750e13d80d3",
    iconSet: "MaterialCommunityIcons",
    iconName: "bomb-off"
  },
  {
    uuid: "556f78fd-1cd2-427b-afbe-7b538f81abbc",
    iconSet: "MaterialCommunityIcons",
    iconName: "bone"
  },
  {
    uuid: "ec16af4d-98e2-49e4-85f1-9f058d04af35",
    iconSet: "MaterialCommunityIcons",
    iconName: "book"
  },
  {
    uuid: "468c2cd3-6719-46a2-b26b-980f32ea7cd5",
    iconSet: "MaterialCommunityIcons",
    iconName: "book-account"
  },
  {
    uuid: "8c3bac61-9656-4d90-b87e-4efea4a40bcd",
    iconSet: "MaterialCommunityIcons",
    iconName: "book-account-outline"
  },
  {
    uuid: "db7fbc7e-05ac-46e5-8ebf-8d29fedc7bd2",
    iconSet: "MaterialCommunityIcons",
    iconName: "book-alphabet"
  },
  {
    uuid: "8c1dfcf8-5468-422c-9629-0a2aa34c9cce",
    iconSet: "MaterialCommunityIcons",
    iconName: "book-cross"
  },
  {
    uuid: "200fca1d-6d45-446d-827e-39322064d58c",
    iconSet: "MaterialCommunityIcons",
    iconName: "book-information-variant"
  },
  {
    uuid: "d88fb9bd-c93d-4c55-9b23-095090b97941",
    iconSet: "MaterialCommunityIcons",
    iconName: "book-lock"
  },
  {
    uuid: "18d35db2-ef26-4b62-a375-a41b4b5a41a2",
    iconSet: "MaterialCommunityIcons",
    iconName: "book-lock-open"
  },
  {
    uuid: "225836bf-7547-4268-aa2a-dee062a7cec6",
    iconSet: "MaterialCommunityIcons",
    iconName: "book-minus"
  },
  {
    uuid: "0afd6930-35b5-40ab-954a-77147c22ca67",
    iconSet: "MaterialCommunityIcons",
    iconName: "book-minus-multiple"
  },
  {
    uuid: "1b84622c-db06-4a3d-8d51-ed936d08f718",
    iconSet: "MaterialCommunityIcons",
    iconName: "book-minus-multiple-outline"
  },
  {
    uuid: "f649ec70-698b-4869-8d7c-5fe1c4ba9f0e",
    iconSet: "MaterialCommunityIcons",
    iconName: "book-multiple"
  },
  {
    uuid: "5a083849-a08b-47ec-a4ed-7781e3995f74",
    iconSet: "MaterialCommunityIcons",
    iconName: "book-multiple-outline"
  },
  {
    uuid: "c0446d96-497a-4e95-ac5e-bfee6f40fc8b",
    iconSet: "MaterialCommunityIcons",
    iconName: "book-music"
  },
  {
    uuid: "a8eb0220-707c-42a8-856b-acbc9bd7dc74",
    iconSet: "MaterialCommunityIcons",
    iconName: "book-open"
  },
  {
    uuid: "f0b28ab0-a4e7-4952-aa59-ec213fbe1a47",
    iconSet: "MaterialCommunityIcons",
    iconName: "book-open-outline"
  },
  {
    uuid: "d4d3818d-8510-4ad3-a95b-21bde4f79102",
    iconSet: "MaterialCommunityIcons",
    iconName: "book-open-page-variant"
  },
  {
    uuid: "f4dc360c-32d0-4f0e-a03c-706cd6444705",
    iconSet: "MaterialCommunityIcons",
    iconName: "book-open-variant"
  },
  {
    uuid: "c0a6b824-b8b1-4a25-91e6-1c4210f04343",
    iconSet: "MaterialCommunityIcons",
    iconName: "book-outline"
  },
  {
    uuid: "b065717c-c154-4e99-b004-9996b64bb995",
    iconSet: "MaterialCommunityIcons",
    iconName: "book-play"
  },
  {
    uuid: "5b277baf-9177-497d-9566-e4902bb816b2",
    iconSet: "MaterialCommunityIcons",
    iconName: "book-play-outline"
  },
  {
    uuid: "10e4a212-d97b-4139-b7a7-5ce0e3e0a0d1",
    iconSet: "MaterialCommunityIcons",
    iconName: "book-plus"
  },
  {
    uuid: "7805e589-df31-4bd7-ba71-3498807c98d5",
    iconSet: "MaterialCommunityIcons",
    iconName: "book-plus-multiple"
  },
  {
    uuid: "50ac890d-9c10-4bef-a6d0-c6e87a22e548",
    iconSet: "MaterialCommunityIcons",
    iconName: "book-plus-multiple-outline"
  },
  {
    uuid: "7b6ec0b5-a334-4980-9744-00142f5bff49",
    iconSet: "MaterialCommunityIcons",
    iconName: "book-remove"
  },
  {
    uuid: "039c8737-c224-433f-9e32-67edf798a69b",
    iconSet: "MaterialCommunityIcons",
    iconName: "book-remove-multiple"
  },
  {
    uuid: "514422a2-8481-46fc-9b28-25706a274100",
    iconSet: "MaterialCommunityIcons",
    iconName: "book-remove-multiple-outline"
  },
  {
    uuid: "f7df3c35-ddda-4148-9949-918889e16906",
    iconSet: "MaterialCommunityIcons",
    iconName: "book-search"
  },
  {
    uuid: "cec7f5df-612f-41e5-91d1-7a39ab85bfce",
    iconSet: "MaterialCommunityIcons",
    iconName: "book-search-outline"
  },
  {
    uuid: "46a60459-a2fa-46cc-a7e8-57e07b75dba3",
    iconSet: "MaterialCommunityIcons",
    iconName: "book-variant"
  },
  {
    uuid: "fe782289-f861-4942-86d6-e1965e069ce0",
    iconSet: "MaterialCommunityIcons",
    iconName: "book-variant-multiple"
  },
  {
    uuid: "16c74d88-c13c-4dd7-b539-c967daca5ab7",
    iconSet: "MaterialCommunityIcons",
    iconName: "bookmark"
  },
  {
    uuid: "8ad07d70-5fa8-4a9e-b480-a9b6f9a7204d",
    iconSet: "MaterialCommunityIcons",
    iconName: "bookmark-check"
  },
  {
    uuid: "422d38bc-1e86-4bd8-af3c-14c86aeb8998",
    iconSet: "MaterialCommunityIcons",
    iconName: "bookmark-check-outline"
  },
  {
    uuid: "a1e699f9-1d8e-4426-8966-24298444cb80",
    iconSet: "MaterialCommunityIcons",
    iconName: "bookmark-minus"
  },
  {
    uuid: "75e6a922-3757-4edf-8760-abbb24fb49f0",
    iconSet: "MaterialCommunityIcons",
    iconName: "bookmark-minus-outline"
  },
  {
    uuid: "6c9a7283-0bac-4d21-b8a8-d93725c588a6",
    iconSet: "MaterialCommunityIcons",
    iconName: "bookmark-multiple"
  },
  {
    uuid: "758df066-2b85-4104-969c-64073957e8cd",
    iconSet: "MaterialCommunityIcons",
    iconName: "bookmark-multiple-outline"
  },
  {
    uuid: "7832dd07-a5df-4604-8419-2854236dab38",
    iconSet: "MaterialCommunityIcons",
    iconName: "bookmark-music"
  },
  {
    uuid: "c30be209-8ebc-4083-9ba9-bc7bf6088f57",
    iconSet: "MaterialCommunityIcons",
    iconName: "bookmark-music-outline"
  },
  {
    uuid: "486d082c-4b0e-4824-ad9f-bc13432bf7d0",
    iconSet: "MaterialCommunityIcons",
    iconName: "bookmark-off"
  },
  {
    uuid: "1ec32669-f673-4407-8514-8ac73f3e7d21",
    iconSet: "MaterialCommunityIcons",
    iconName: "bookmark-off-outline"
  },
  {
    uuid: "412843bf-4347-4030-8426-66f0f974f6c8",
    iconSet: "MaterialCommunityIcons",
    iconName: "bookmark-outline"
  },
  {
    uuid: "0044c5ef-9b7d-4d3d-9274-087748e6eae5",
    iconSet: "MaterialCommunityIcons",
    iconName: "bookmark-plus"
  },
  {
    uuid: "5b8a916d-ce99-4a36-8e61-e8371f7c608e",
    iconSet: "MaterialCommunityIcons",
    iconName: "bookmark-plus-outline"
  },
  {
    uuid: "22f37015-cdf4-42cf-b95f-592cb098d6d0",
    iconSet: "MaterialCommunityIcons",
    iconName: "bookmark-remove"
  },
  {
    uuid: "0a0fcf8e-a40f-45de-ae54-51ec8099de5f",
    iconSet: "MaterialCommunityIcons",
    iconName: "bookmark-remove-outline"
  },
  {
    uuid: "8310b956-d164-4dcb-878d-3c5684b18457",
    iconSet: "MaterialCommunityIcons",
    iconName: "bookshelf"
  },
  {
    uuid: "dc925747-161e-4831-bc08-e57959bd75fa",
    iconSet: "MaterialCommunityIcons",
    iconName: "boom-gate"
  },
  {
    uuid: "ab922239-f48e-4698-ac0d-23078a7d61d0",
    iconSet: "MaterialCommunityIcons",
    iconName: "boom-gate-alert"
  },
  {
    uuid: "ee8ad925-1b0f-408d-9114-f9aef6007cab",
    iconSet: "MaterialCommunityIcons",
    iconName: "boom-gate-alert-outline"
  },
  {
    uuid: "08c5ffc6-5cdb-480d-aafe-33d8f2c253c5",
    iconSet: "MaterialCommunityIcons",
    iconName: "boom-gate-down"
  },
  {
    uuid: "74023df2-9c03-44bf-b223-3d3115674600",
    iconSet: "MaterialCommunityIcons",
    iconName: "boom-gate-down-outline"
  },
  {
    uuid: "b547d876-77d4-4b6a-bf18-3b650c1c6b0b",
    iconSet: "MaterialCommunityIcons",
    iconName: "boom-gate-outline"
  },
  {
    uuid: "90f6f896-98e0-48f7-b373-0794486bf275",
    iconSet: "MaterialCommunityIcons",
    iconName: "boom-gate-up"
  },
  {
    uuid: "31462db0-3dae-4ae8-b16d-03d66355e709",
    iconSet: "MaterialCommunityIcons",
    iconName: "boom-gate-up-outline"
  },
  {
    uuid: "62f03cb7-c554-403c-81df-0eb34229ff74",
    iconSet: "MaterialCommunityIcons",
    iconName: "boombox"
  },
  {
    uuid: "df41ffbd-8510-4c4a-8036-49818f2572d7",
    iconSet: "MaterialCommunityIcons",
    iconName: "boomerang"
  },
  {
    uuid: "92423723-f056-4c44-adef-1ef4c0d13f70",
    iconSet: "MaterialCommunityIcons",
    iconName: "bootstrap"
  },
  {
    uuid: "6da95900-aa2b-4e51-9f9b-fe9a2e8bd325",
    iconSet: "MaterialCommunityIcons",
    iconName: "border-all"
  },
  {
    uuid: "054eb1bf-951e-4939-bac6-bf84ea5a8f55",
    iconSet: "MaterialCommunityIcons",
    iconName: "border-all-variant"
  },
  {
    uuid: "ef0c75ff-aed9-46fc-916a-9eeedec3341d",
    iconSet: "MaterialCommunityIcons",
    iconName: "border-bottom"
  },
  {
    uuid: "be28388a-8104-4799-9d23-c2dca2128a4f",
    iconSet: "MaterialCommunityIcons",
    iconName: "border-bottom-variant"
  },
  {
    uuid: "b3e6961e-07cf-4fa1-828b-4eec019ef785",
    iconSet: "MaterialCommunityIcons",
    iconName: "border-color"
  },
  {
    uuid: "d355948c-b67d-45a3-98d4-67d0c553c36b",
    iconSet: "MaterialCommunityIcons",
    iconName: "border-horizontal"
  },
  {
    uuid: "63aef0c8-97bb-4768-be5e-162352908f77",
    iconSet: "MaterialCommunityIcons",
    iconName: "border-inside"
  },
  {
    uuid: "4dbedac4-cbff-40d9-9347-28a760df230c",
    iconSet: "MaterialCommunityIcons",
    iconName: "border-left"
  },
  {
    uuid: "5f4bda53-faa5-44e0-96a7-50207a851a29",
    iconSet: "MaterialCommunityIcons",
    iconName: "border-left-variant"
  },
  {
    uuid: "ba48c468-96b7-4a16-afe7-bf5ed3839d18",
    iconSet: "MaterialCommunityIcons",
    iconName: "border-none"
  },
  {
    uuid: "f7cb0dcd-d414-464a-b3f5-c094f94542d4",
    iconSet: "MaterialCommunityIcons",
    iconName: "border-none-variant"
  },
  {
    uuid: "c12a93fe-d338-4fa0-9478-2fcf76bc9cea",
    iconSet: "MaterialCommunityIcons",
    iconName: "border-outside"
  },
  {
    uuid: "d1400ab0-e00a-458e-a32a-f742c140f5bf",
    iconSet: "MaterialCommunityIcons",
    iconName: "border-right"
  },
  {
    uuid: "a13baba6-24bf-439b-afed-c0d27da5dd0e",
    iconSet: "MaterialCommunityIcons",
    iconName: "border-right-variant"
  },
  {
    uuid: "01c68a9d-c89f-4d86-b2d3-e741d6726d97",
    iconSet: "MaterialCommunityIcons",
    iconName: "border-style"
  },
  {
    uuid: "f168ff94-b442-4ac9-b459-035ea553dc0a",
    iconSet: "MaterialCommunityIcons",
    iconName: "border-top"
  },
  {
    uuid: "5e6e70b1-4999-4716-9e33-b9f8cf673b85",
    iconSet: "MaterialCommunityIcons",
    iconName: "border-top-variant"
  },
  {
    uuid: "9d3e0503-c2e7-4972-923e-1ad58558ccbc",
    iconSet: "MaterialCommunityIcons",
    iconName: "border-vertical"
  },
  {
    uuid: "8af96c29-cda0-4f86-a54a-e64ea153f91f",
    iconSet: "MaterialCommunityIcons",
    iconName: "bottle-soda"
  },
  {
    uuid: "c1decfbc-bc73-47a0-94e3-f4d72282f88a",
    iconSet: "MaterialCommunityIcons",
    iconName: "bottle-soda-classic"
  },
  {
    uuid: "1663d58d-40c2-4709-97f1-105b7bac07bc",
    iconSet: "MaterialCommunityIcons",
    iconName: "bottle-soda-classic-outline"
  },
  {
    uuid: "022dd7b8-966f-487f-9678-bdcffa1a8064",
    iconSet: "MaterialCommunityIcons",
    iconName: "bottle-soda-outline"
  },
  {
    uuid: "df5c92e2-110b-4a6d-835e-45bece144ed1",
    iconSet: "MaterialCommunityIcons",
    iconName: "bottle-tonic"
  },
  {
    uuid: "a6b40673-4a06-420d-b617-1e05c8a5074c",
    iconSet: "MaterialCommunityIcons",
    iconName: "bottle-tonic-outline"
  },
  {
    uuid: "5ce12c22-5128-4c3d-9774-56a2ec6b6bb5",
    iconSet: "MaterialCommunityIcons",
    iconName: "bottle-tonic-plus"
  },
  {
    uuid: "59826205-c9e2-43ae-aa42-28a0a7f5c74d",
    iconSet: "MaterialCommunityIcons",
    iconName: "bottle-tonic-plus-outline"
  },
  {
    uuid: "b99dcdc9-e1f2-488e-99b9-1c3efa9051d7",
    iconSet: "MaterialCommunityIcons",
    iconName: "bottle-tonic-skull"
  },
  {
    uuid: "61b513cb-396f-4bce-8b98-3744c7d4b56c",
    iconSet: "MaterialCommunityIcons",
    iconName: "bottle-tonic-skull-outline"
  },
  {
    uuid: "ed162f7d-f9eb-4426-8db2-553c4fb2fafe",
    iconSet: "MaterialCommunityIcons",
    iconName: "bottle-wine"
  },
  {
    uuid: "d7d4fa0c-47da-4c29-951a-e7e67ec12631",
    iconSet: "MaterialCommunityIcons",
    iconName: "bottle-wine-outline"
  },
  {
    uuid: "46e26d6f-aa93-452f-b61c-b43c6d7e2fa0",
    iconSet: "MaterialCommunityIcons",
    iconName: "bow-tie"
  },
  {
    uuid: "95f64891-2d3e-42c9-90f1-177d31c89042",
    iconSet: "MaterialCommunityIcons",
    iconName: "bowl"
  },
  {
    uuid: "17499d55-2f9d-4adf-a671-58701b77b307",
    iconSet: "MaterialCommunityIcons",
    iconName: "bowl-mix"
  },
  {
    uuid: "832e0eb6-0de1-4f29-9a4c-a522e09b7620",
    iconSet: "MaterialCommunityIcons",
    iconName: "bowl-mix-outline"
  },
  {
    uuid: "066a3f03-7614-4817-ac3f-77c6cd1e9bb2",
    iconSet: "MaterialCommunityIcons",
    iconName: "bowl-outline"
  },
  {
    uuid: "87dad4ff-5b3b-406a-8c92-0cc2101cfc87",
    iconSet: "MaterialCommunityIcons",
    iconName: "bowling"
  },
  {
    uuid: "fcb81cce-28d6-475b-9e76-cc5e0205b258",
    iconSet: "MaterialCommunityIcons",
    iconName: "box"
  },
  {
    uuid: "33e12bfd-6a77-4a51-b546-8bed95bed7d4",
    iconSet: "MaterialCommunityIcons",
    iconName: "box-cutter"
  },
  {
    uuid: "0a138855-2ced-42ac-ac19-c7d3427a7247",
    iconSet: "MaterialCommunityIcons",
    iconName: "box-cutter-off"
  },
  {
    uuid: "ddaaca11-725b-4d59-b0cc-5aceedad964f",
    iconSet: "MaterialCommunityIcons",
    iconName: "box-shadow"
  },
  {
    uuid: "63f54811-59cb-4454-9562-93ecab7ab77e",
    iconSet: "MaterialCommunityIcons",
    iconName: "boxing-glove"
  },
  {
    uuid: "eb16ddb5-c3d3-4ae5-adca-f07230874a6b",
    iconSet: "MaterialCommunityIcons",
    iconName: "braille"
  },
  {
    uuid: "653daa0b-3d03-4b67-9c11-c303260e1ffd",
    iconSet: "MaterialCommunityIcons",
    iconName: "brain"
  },
  {
    uuid: "3308c3b9-334a-482a-bdc8-7e5ac233f6af",
    iconSet: "MaterialCommunityIcons",
    iconName: "bread-slice"
  },
  {
    uuid: "e8436efb-85c5-4cf9-95b5-beafe7a457df",
    iconSet: "MaterialCommunityIcons",
    iconName: "bread-slice-outline"
  },
  {
    uuid: "606e1c43-6293-4c10-a11c-72bd4b8cae17",
    iconSet: "MaterialCommunityIcons",
    iconName: "bridge"
  },
  {
    uuid: "4ffb6052-d259-40ba-af5d-afa4598e1ebe",
    iconSet: "MaterialCommunityIcons",
    iconName: "briefcase"
  },
  {
    uuid: "44d41dd2-6c1b-4765-aced-8b75dad23e53",
    iconSet: "MaterialCommunityIcons",
    iconName: "briefcase-account"
  },
  {
    uuid: "aa5dd372-5b35-4918-90eb-87e0109d7178",
    iconSet: "MaterialCommunityIcons",
    iconName: "briefcase-account-outline"
  },
  {
    uuid: "09c01530-ef4a-420c-8931-577757a80cfb",
    iconSet: "MaterialCommunityIcons",
    iconName: "briefcase-check"
  },
  {
    uuid: "f89574d2-2a69-46dc-8c9d-dfdcf0ca0214",
    iconSet: "MaterialCommunityIcons",
    iconName: "briefcase-check-outline"
  },
  {
    uuid: "d526e5e6-d5c2-44cb-9827-6ed494c9f216",
    iconSet: "MaterialCommunityIcons",
    iconName: "briefcase-clock"
  },
  {
    uuid: "e123891a-b195-4fff-b235-4c23277ac878",
    iconSet: "MaterialCommunityIcons",
    iconName: "briefcase-clock-outline"
  },
  {
    uuid: "f9069aef-dca2-42c6-b453-c06e7cb6c411",
    iconSet: "MaterialCommunityIcons",
    iconName: "briefcase-download"
  },
  {
    uuid: "21baadb5-d246-4682-a829-b1a5e951a58e",
    iconSet: "MaterialCommunityIcons",
    iconName: "briefcase-download-outline"
  },
  {
    uuid: "f9f7ee65-8bc5-4627-a706-7c770c2f2a97",
    iconSet: "MaterialCommunityIcons",
    iconName: "briefcase-edit"
  },
  {
    uuid: "a7b3ebd4-f6ef-47a0-ba46-155a91c21de6",
    iconSet: "MaterialCommunityIcons",
    iconName: "briefcase-edit-outline"
  },
  {
    uuid: "ceede228-98fb-4b5d-86c3-60c8afa61d1a",
    iconSet: "MaterialCommunityIcons",
    iconName: "briefcase-minus"
  },
  {
    uuid: "6ba7db7d-4361-41c9-8aaa-165f3a95ae44",
    iconSet: "MaterialCommunityIcons",
    iconName: "briefcase-minus-outline"
  },
  {
    uuid: "e2d3d7d1-bbd2-4c2a-9f9c-1e86654709f6",
    iconSet: "MaterialCommunityIcons",
    iconName: "briefcase-outline"
  },
  {
    uuid: "b9a4c9d3-e117-44d8-9c2d-0c1d9c9a07f2",
    iconSet: "MaterialCommunityIcons",
    iconName: "briefcase-plus"
  },
  {
    uuid: "bef5b3d1-c7d0-43ad-9fe2-bfd335cd68aa",
    iconSet: "MaterialCommunityIcons",
    iconName: "briefcase-plus-outline"
  },
  {
    uuid: "72115ec5-30f9-4917-b0ce-d54d225d257d",
    iconSet: "MaterialCommunityIcons",
    iconName: "briefcase-remove"
  },
  {
    uuid: "305500e7-1327-433d-999f-0ebd0b7f85d8",
    iconSet: "MaterialCommunityIcons",
    iconName: "briefcase-remove-outline"
  },
  {
    uuid: "6ca95c27-76d9-4a48-8925-2bcf4b5d44d4",
    iconSet: "MaterialCommunityIcons",
    iconName: "briefcase-search"
  },
  {
    uuid: "6250b3f8-0fc3-4dd6-a990-d0d5850e8858",
    iconSet: "MaterialCommunityIcons",
    iconName: "briefcase-search-outline"
  },
  {
    uuid: "29e98e9b-b233-493b-9b1c-cb9545ca58cd",
    iconSet: "MaterialCommunityIcons",
    iconName: "briefcase-upload"
  },
  {
    uuid: "e660da24-311d-4e8c-b31a-076e3bb06f8d",
    iconSet: "MaterialCommunityIcons",
    iconName: "briefcase-upload-outline"
  },
  {
    uuid: "52380024-4074-4c1f-ac49-43498e9cde76",
    iconSet: "MaterialCommunityIcons",
    iconName: "briefcase-variant"
  },
  {
    uuid: "2e904921-c615-43eb-9575-cc4a9e6df3f1",
    iconSet: "MaterialCommunityIcons",
    iconName: "briefcase-variant-outline"
  },
  {
    uuid: "101e8490-fae1-4b20-b6f8-7dbfe7ea9e5d",
    iconSet: "MaterialCommunityIcons",
    iconName: "brightness-1"
  },
  {
    uuid: "6ec913a7-e834-48fb-b13e-2cac13406c8d",
    iconSet: "MaterialCommunityIcons",
    iconName: "brightness-2"
  },
  {
    uuid: "a13ca2ce-cef1-42b2-9287-a454d1b257ff",
    iconSet: "MaterialCommunityIcons",
    iconName: "brightness-3"
  },
  {
    uuid: "fae1c358-0b24-4134-897f-5a583cefcc6c",
    iconSet: "MaterialCommunityIcons",
    iconName: "brightness-4"
  },
  {
    uuid: "ad818ba1-ec8e-4283-b99f-29c4809b24e4",
    iconSet: "MaterialCommunityIcons",
    iconName: "brightness-5"
  },
  {
    uuid: "7b6353ea-a08f-4cbd-ab8e-949658cf91dd",
    iconSet: "MaterialCommunityIcons",
    iconName: "brightness-6"
  },
  {
    uuid: "f875d226-c35b-4773-b9da-64ed9f45b87f",
    iconSet: "MaterialCommunityIcons",
    iconName: "brightness-7"
  },
  {
    uuid: "eb1c0569-aa1d-4e20-9c38-b349af087f4a",
    iconSet: "MaterialCommunityIcons",
    iconName: "brightness-auto"
  },
  {
    uuid: "4814c752-430a-4f90-b397-50bc6ddfbeca",
    iconSet: "MaterialCommunityIcons",
    iconName: "brightness-percent"
  },
  {
    uuid: "bcd10a5c-aa24-4844-aa47-257048ddea06",
    iconSet: "MaterialCommunityIcons",
    iconName: "broom"
  },
  {
    uuid: "5bdf4aa5-d175-4dc2-9517-4df75db3f67c",
    iconSet: "MaterialCommunityIcons",
    iconName: "brush"
  },
  {
    uuid: "47379829-6a8f-47cb-a32b-348ece1d82f0",
    iconSet: "MaterialCommunityIcons",
    iconName: "bucket"
  },
  {
    uuid: "b34711ce-0811-4da6-9bdb-2df3dbce5b47",
    iconSet: "MaterialCommunityIcons",
    iconName: "bucket-outline"
  },
  {
    uuid: "49be3a49-28a5-4d2c-abd4-0e803e45d133",
    iconSet: "MaterialCommunityIcons",
    iconName: "buddhism"
  },
  {
    uuid: "96e02c95-f38e-46af-b76d-f2b2e3619224",
    iconSet: "MaterialCommunityIcons",
    iconName: "buffer"
  },
  {
    uuid: "6e9f94e1-e9ca-46b6-91f6-0d153a911359",
    iconSet: "MaterialCommunityIcons",
    iconName: "buffet"
  },
  {
    uuid: "e5d3259a-bb02-46b1-be0b-f34516171f07",
    iconSet: "MaterialCommunityIcons",
    iconName: "bug"
  },
  {
    uuid: "136e99eb-f4f3-4c4b-86dc-0152ab9e7c8c",
    iconSet: "MaterialCommunityIcons",
    iconName: "bug-check"
  },
  {
    uuid: "705ed008-6799-4a95-ab75-b064dd3044f8",
    iconSet: "MaterialCommunityIcons",
    iconName: "bug-check-outline"
  },
  {
    uuid: "f6323908-d8b7-4290-82af-75467a010930",
    iconSet: "MaterialCommunityIcons",
    iconName: "bug-outline"
  },
  {
    uuid: "526e88c4-7d72-472a-9d3e-fcfae27bf156",
    iconSet: "MaterialCommunityIcons",
    iconName: "bugle"
  },
  {
    uuid: "06cdd5d9-b54c-4580-8ec7-ad7a27f74d3d",
    iconSet: "MaterialCommunityIcons",
    iconName: "bulldozer"
  },
  {
    uuid: "c0350555-279d-4f9b-ae7d-04c4a04c567f",
    iconSet: "MaterialCommunityIcons",
    iconName: "bullet"
  },
  {
    uuid: "33a60fa5-40f9-42d7-9fa1-31a43cb6efd5",
    iconSet: "MaterialCommunityIcons",
    iconName: "bulletin-board"
  },
  {
    uuid: "536fb7fc-d1c4-45df-84ba-398eab51e14b",
    iconSet: "MaterialCommunityIcons",
    iconName: "bullhorn"
  },
  {
    uuid: "975b7f3a-19f2-41e1-a036-a9d1fc4ee89a",
    iconSet: "MaterialCommunityIcons",
    iconName: "bullhorn-outline"
  },
  {
    uuid: "f459aa06-e2df-4e64-844c-f63fc00414fd",
    iconSet: "MaterialCommunityIcons",
    iconName: "bullseye"
  },
  {
    uuid: "bd422d56-680a-40c9-a9eb-a406cccd883e",
    iconSet: "MaterialCommunityIcons",
    iconName: "bullseye-arrow"
  },
  {
    uuid: "2ef68ab4-6b32-4770-be2e-066103c0380f",
    iconSet: "MaterialCommunityIcons",
    iconName: "bulma"
  },
  {
    uuid: "aa0e82d2-ba76-481a-b034-d8eada29f5df",
    iconSet: "MaterialCommunityIcons",
    iconName: "bunk-bed"
  },
  {
    uuid: "31130c9a-bb4a-4201-b3a2-0f32ae5b4e4a",
    iconSet: "MaterialCommunityIcons",
    iconName: "bunk-bed-outline"
  },
  {
    uuid: "4789cf83-b220-45d3-b9cf-4c3ad3af499b",
    iconSet: "MaterialCommunityIcons",
    iconName: "bus"
  },
  {
    uuid: "f3e6c50d-4995-49f4-aa50-756e6ae818dc",
    iconSet: "MaterialCommunityIcons",
    iconName: "bus-alert"
  },
  {
    uuid: "683c6869-8d61-4466-bc8f-f2ef2eeff8df",
    iconSet: "MaterialCommunityIcons",
    iconName: "bus-articulated-end"
  },
  {
    uuid: "acd8525f-e649-4253-a17f-6c46d5178388",
    iconSet: "MaterialCommunityIcons",
    iconName: "bus-articulated-front"
  },
  {
    uuid: "2809b1ac-ecc3-475e-8761-505eb9a24156",
    iconSet: "MaterialCommunityIcons",
    iconName: "bus-clock"
  },
  {
    uuid: "3f06e75d-e28d-4d11-8c5c-6d5941bb947f",
    iconSet: "MaterialCommunityIcons",
    iconName: "bus-double-decker"
  },
  {
    uuid: "a5d99476-bb91-4e63-876a-536c65e28f8a",
    iconSet: "MaterialCommunityIcons",
    iconName: "bus-marker"
  },
  {
    uuid: "ae153992-d614-47a8-b7b8-1029cb80df28",
    iconSet: "MaterialCommunityIcons",
    iconName: "bus-multiple"
  },
  {
    uuid: "41080cb1-01ab-4792-b468-9d9823d551c6",
    iconSet: "MaterialCommunityIcons",
    iconName: "bus-school"
  },
  {
    uuid: "846587bd-5ec3-4fd2-9179-1ebd056c0a55",
    iconSet: "MaterialCommunityIcons",
    iconName: "bus-side"
  },
  {
    uuid: "b73cba0e-f447-4ab3-8214-67f14b3cf83c",
    iconSet: "MaterialCommunityIcons",
    iconName: "bus-stop"
  },
  {
    uuid: "2fc231cf-f626-4a14-8aba-c54ca35304e0",
    iconSet: "MaterialCommunityIcons",
    iconName: "bus-stop-covered"
  },
  {
    uuid: "3ac90ee4-e45a-472d-a6d0-413e78586b0b",
    iconSet: "MaterialCommunityIcons",
    iconName: "bus-stop-uncovered"
  },
  {
    uuid: "139fd665-6be4-4c31-b55d-421a8242cbee",
    iconSet: "MaterialCommunityIcons",
    iconName: "cable-data"
  },
  {
    uuid: "84f3eb59-f2d5-4812-aa54-3a82af07cd16",
    iconSet: "MaterialCommunityIcons",
    iconName: "cached"
  },
  {
    uuid: "fed44d25-27a4-4bd2-ab59-d6bc243d0ebe",
    iconSet: "MaterialCommunityIcons",
    iconName: "cactus"
  },
  {
    uuid: "4a135e52-6f14-4d06-9e46-95c9bfc091c9",
    iconSet: "MaterialCommunityIcons",
    iconName: "cake"
  },
  {
    uuid: "29cc17bf-284d-43d9-96d4-155dfbfceeb8",
    iconSet: "MaterialCommunityIcons",
    iconName: "cake-layered"
  },
  {
    uuid: "05183850-fc83-4b2d-9f13-45a44fbdb304",
    iconSet: "MaterialCommunityIcons",
    iconName: "cake-variant"
  },
  {
    uuid: "a32bf122-ae71-4478-9f5d-2d9859ec4b24",
    iconSet: "MaterialCommunityIcons",
    iconName: "calculator"
  },
  {
    uuid: "3e48d037-b965-40c3-8e6d-05b4b7c9249b",
    iconSet: "MaterialCommunityIcons",
    iconName: "calculator-variant"
  },
  {
    uuid: "820fa785-2213-457e-b686-ef0310ca326c",
    iconSet: "MaterialCommunityIcons",
    iconName: "calendar"
  },
  {
    uuid: "9ef942b9-410b-46ea-ab92-34ea174f341c",
    iconSet: "MaterialCommunityIcons",
    iconName: "calendar-account"
  },
  {
    uuid: "5af4d141-6130-4a6a-b4e2-0446cc9f7e24",
    iconSet: "MaterialCommunityIcons",
    iconName: "calendar-account-outline"
  },
  {
    uuid: "c872b08f-7612-45f6-8803-960a0c9f8399",
    iconSet: "MaterialCommunityIcons",
    iconName: "calendar-alert"
  },
  {
    uuid: "f623465a-0af5-46a7-acd2-96fb9c760650",
    iconSet: "MaterialCommunityIcons",
    iconName: "calendar-arrow-left"
  },
  {
    uuid: "ea829cbc-5db1-40a6-ac47-6dfc91bdd905",
    iconSet: "MaterialCommunityIcons",
    iconName: "calendar-arrow-right"
  },
  {
    uuid: "0cd49cb3-8048-441d-b130-a2ba0611c10c",
    iconSet: "MaterialCommunityIcons",
    iconName: "calendar-blank"
  },
  {
    uuid: "5ff9826e-629e-4513-9504-217369c568a5",
    iconSet: "MaterialCommunityIcons",
    iconName: "calendar-blank-multiple"
  },
  {
    uuid: "5c82d461-6c8d-40a8-9917-ee6ec394d974",
    iconSet: "MaterialCommunityIcons",
    iconName: "calendar-blank-outline"
  },
  {
    uuid: "55576951-85b7-4c9a-ab09-292668379a29",
    iconSet: "MaterialCommunityIcons",
    iconName: "calendar-check"
  },
  {
    uuid: "1f19fbac-1d47-408e-89f8-a29e290720ac",
    iconSet: "MaterialCommunityIcons",
    iconName: "calendar-check-outline"
  },
  {
    uuid: "2176e2d7-1d44-44c9-a6c8-82a7afa66aa5",
    iconSet: "MaterialCommunityIcons",
    iconName: "calendar-clock"
  },
  {
    uuid: "d8d47690-0f9b-4ae8-bbb0-3e7880dfd7c1",
    iconSet: "MaterialCommunityIcons",
    iconName: "calendar-edit"
  },
  {
    uuid: "f3c62b10-5231-4328-9e1c-7f2f78ed242e",
    iconSet: "MaterialCommunityIcons",
    iconName: "calendar-export"
  },
  {
    uuid: "c95bfc3b-97a5-4ea2-8711-3dc0ccc86fc8",
    iconSet: "MaterialCommunityIcons",
    iconName: "calendar-heart"
  },
  {
    uuid: "b8bf8724-d744-44dd-b051-4281fab4b8d6",
    iconSet: "MaterialCommunityIcons",
    iconName: "calendar-import"
  },
  {
    uuid: "aa772355-1d6f-49f5-87b7-cea5b01d5853",
    iconSet: "MaterialCommunityIcons",
    iconName: "calendar-minus"
  },
  {
    uuid: "a523b67a-b84b-47e3-93b9-ab156ef652af",
    iconSet: "MaterialCommunityIcons",
    iconName: "calendar-month"
  },
  {
    uuid: "fa6076a2-0836-4a1b-9996-166659f2eb71",
    iconSet: "MaterialCommunityIcons",
    iconName: "calendar-month-outline"
  },
  {
    uuid: "f0768827-d2f4-4fc3-9f3d-3ce4fc080257",
    iconSet: "MaterialCommunityIcons",
    iconName: "calendar-multiple"
  },
  {
    uuid: "5de1662f-7dc1-4bfe-8c2c-dd04e0be11e6",
    iconSet: "MaterialCommunityIcons",
    iconName: "calendar-multiple-check"
  },
  {
    uuid: "cdb512b3-11de-4437-a96a-ccb9bb990226",
    iconSet: "MaterialCommunityIcons",
    iconName: "calendar-multiselect"
  },
  {
    uuid: "a31d70db-731b-41d7-a5d1-fc94a0149c46",
    iconSet: "MaterialCommunityIcons",
    iconName: "calendar-outline"
  },
  {
    uuid: "163aa5ff-fc88-47ab-8bd0-69aa418da183",
    iconSet: "MaterialCommunityIcons",
    iconName: "calendar-plus"
  },
  {
    uuid: "140379a1-9295-4dfb-8664-03ca36cdc789",
    iconSet: "MaterialCommunityIcons",
    iconName: "calendar-question"
  },
  {
    uuid: "a960e974-990b-4a44-b851-985f8127d188",
    iconSet: "MaterialCommunityIcons",
    iconName: "calendar-range"
  },
  {
    uuid: "e519579c-19ae-4dab-a898-5dcc9170deef",
    iconSet: "MaterialCommunityIcons",
    iconName: "calendar-range-outline"
  },
  {
    uuid: "4d8121d3-11f4-40cd-b025-52b2ed2c9c1d",
    iconSet: "MaterialCommunityIcons",
    iconName: "calendar-refresh"
  },
  {
    uuid: "b6c12ebe-09bd-4cbf-a1dc-5106cba0206b",
    iconSet: "MaterialCommunityIcons",
    iconName: "calendar-refresh-outline"
  },
  {
    uuid: "a60310cd-961c-4b2f-961b-35ddc337427c",
    iconSet: "MaterialCommunityIcons",
    iconName: "calendar-remove"
  },
  {
    uuid: "ac45b333-169f-42dd-a94c-e1997e973413",
    iconSet: "MaterialCommunityIcons",
    iconName: "calendar-remove-outline"
  },
  {
    uuid: "ffb14a81-a274-469d-a450-8406731f13fd",
    iconSet: "MaterialCommunityIcons",
    iconName: "calendar-search"
  },
  {
    uuid: "30dddf2b-f0bd-4008-80b9-9d5d879e554f",
    iconSet: "MaterialCommunityIcons",
    iconName: "calendar-star"
  },
  {
    uuid: "5b756da9-2a7e-4ed2-b523-e8b517497e50",
    iconSet: "MaterialCommunityIcons",
    iconName: "calendar-sync"
  },
  {
    uuid: "64d74559-cd4c-4f76-ac06-674d3152c968",
    iconSet: "MaterialCommunityIcons",
    iconName: "calendar-sync-outline"
  },
  {
    uuid: "a53261b5-f39a-4435-82c8-00533c7a589e",
    iconSet: "MaterialCommunityIcons",
    iconName: "calendar-text"
  },
  {
    uuid: "2c762240-f524-4479-bc26-132d0a4b11d4",
    iconSet: "MaterialCommunityIcons",
    iconName: "calendar-text-outline"
  },
  {
    uuid: "eb192069-df7d-481e-8bbd-82ad3910d29c",
    iconSet: "MaterialCommunityIcons",
    iconName: "calendar-today"
  },
  {
    uuid: "5ce4b96b-c2f2-4365-81d6-350fe951bbad",
    iconSet: "MaterialCommunityIcons",
    iconName: "calendar-week"
  },
  {
    uuid: "2f0d1188-a758-4679-b5d6-88ec5da52c5b",
    iconSet: "MaterialCommunityIcons",
    iconName: "calendar-week-begin"
  },
  {
    uuid: "309175c6-e83c-487c-b598-fbdb9647665e",
    iconSet: "MaterialCommunityIcons",
    iconName: "calendar-weekend"
  },
  {
    uuid: "28a847f7-0287-43ff-a2ac-de8db47b82ab",
    iconSet: "MaterialCommunityIcons",
    iconName: "calendar-weekend-outline"
  },
  {
    uuid: "f3d72aaf-7b84-4413-89b5-d957cad54b99",
    iconSet: "MaterialCommunityIcons",
    iconName: "call-made"
  },
  {
    uuid: "9b62ef2f-648f-4471-afd0-00c5f1811cbb",
    iconSet: "MaterialCommunityIcons",
    iconName: "call-merge"
  },
  {
    uuid: "9885078d-f9da-4f7a-8609-a23e705074ec",
    iconSet: "MaterialCommunityIcons",
    iconName: "call-missed"
  },
  {
    uuid: "5d186be2-5fa6-4974-89e2-548f371421f8",
    iconSet: "MaterialCommunityIcons",
    iconName: "call-received"
  },
  {
    uuid: "5744be34-5f3f-4fc0-afcd-41dbcf28bb17",
    iconSet: "MaterialCommunityIcons",
    iconName: "call-split"
  },
  {
    uuid: "0e48ad5d-386c-4e13-b9eb-5506c56dfad3",
    iconSet: "MaterialCommunityIcons",
    iconName: "camcorder"
  },
  {
    uuid: "684ce1de-770f-4c64-b243-de1512ad7542",
    iconSet: "MaterialCommunityIcons",
    iconName: "camcorder-off"
  },
  {
    uuid: "87f8576f-49a4-4e03-97f3-e7397febae74",
    iconSet: "MaterialCommunityIcons",
    iconName: "camera"
  },
  {
    uuid: "04cb7600-e957-429e-84a0-dad64c01f98a",
    iconSet: "MaterialCommunityIcons",
    iconName: "camera-account"
  },
  {
    uuid: "8b31a684-b11f-499f-9e36-4ddac23b426b",
    iconSet: "MaterialCommunityIcons",
    iconName: "camera-burst"
  },
  {
    uuid: "56fd4964-6f8b-4469-9d63-95c2932a5cd8",
    iconSet: "MaterialCommunityIcons",
    iconName: "camera-control"
  },
  {
    uuid: "61f8744c-612f-4af0-b513-612c8320ded7",
    iconSet: "MaterialCommunityIcons",
    iconName: "camera-enhance"
  },
  {
    uuid: "5fe35b69-a90b-49f9-b609-a112c474e9ba",
    iconSet: "MaterialCommunityIcons",
    iconName: "camera-enhance-outline"
  },
  {
    uuid: "e82e3e1e-49c3-4d63-aa78-9cdd4ff87feb",
    iconSet: "MaterialCommunityIcons",
    iconName: "camera-front"
  },
  {
    uuid: "e1ee5aa2-c51a-4d13-a6e2-2a04c75dddf3",
    iconSet: "MaterialCommunityIcons",
    iconName: "camera-front-variant"
  },
  {
    uuid: "8ab109a9-7f77-4ee0-90b3-30a7bf6a5b2e",
    iconSet: "MaterialCommunityIcons",
    iconName: "camera-gopro"
  },
  {
    uuid: "11b6fae5-6b49-4e22-b209-7077bb9006c7",
    iconSet: "MaterialCommunityIcons",
    iconName: "camera-image"
  },
  {
    uuid: "93be94ad-4b16-49bd-9add-0373299f00c5",
    iconSet: "MaterialCommunityIcons",
    iconName: "camera-iris"
  },
  {
    uuid: "1f7d3f2a-db88-4492-a629-fd7a282ec71c",
    iconSet: "MaterialCommunityIcons",
    iconName: "camera-metering-center"
  },
  {
    uuid: "71ef7cd7-1aac-4fa5-94be-984c7b02da71",
    iconSet: "MaterialCommunityIcons",
    iconName: "camera-metering-matrix"
  },
  {
    uuid: "9085be85-d76a-4073-956c-b50734ba70fb",
    iconSet: "MaterialCommunityIcons",
    iconName: "camera-metering-partial"
  },
  {
    uuid: "2a2df2e7-22ff-48d8-aefa-881cd572f754",
    iconSet: "MaterialCommunityIcons",
    iconName: "camera-metering-spot"
  },
  {
    uuid: "41b33fe3-154e-49f0-a1e4-6162994e7384",
    iconSet: "MaterialCommunityIcons",
    iconName: "camera-off"
  },
  {
    uuid: "8b508d86-d114-401c-b377-9c6a3236cb73",
    iconSet: "MaterialCommunityIcons",
    iconName: "camera-outline"
  },
  {
    uuid: "7cb146ca-1b9f-4946-9a3a-0ba2b05de3a1",
    iconSet: "MaterialCommunityIcons",
    iconName: "camera-party-mode"
  },
  {
    uuid: "2323b172-cf0d-4eee-8f3e-da1ec1b806ac",
    iconSet: "MaterialCommunityIcons",
    iconName: "camera-plus"
  },
  {
    uuid: "009628ea-8d3a-4e92-a0bd-90172f35108f",
    iconSet: "MaterialCommunityIcons",
    iconName: "camera-plus-outline"
  },
  {
    uuid: "910ba202-9fca-4f0e-80e2-ff5128a78dfa",
    iconSet: "MaterialCommunityIcons",
    iconName: "camera-rear"
  },
  {
    uuid: "5aeece8e-31bf-4626-bb0f-aa5fc41c62cb",
    iconSet: "MaterialCommunityIcons",
    iconName: "camera-rear-variant"
  },
  {
    uuid: "43c5131b-7a58-4cde-9f5e-a630a5523668",
    iconSet: "MaterialCommunityIcons",
    iconName: "camera-retake"
  },
  {
    uuid: "da998248-78ab-40ac-9e42-03de25849269",
    iconSet: "MaterialCommunityIcons",
    iconName: "camera-retake-outline"
  },
  {
    uuid: "adba6bc9-b2f0-4837-b2dd-982270bcd18c",
    iconSet: "MaterialCommunityIcons",
    iconName: "camera-switch"
  },
  {
    uuid: "5fe36289-082e-43d9-9ff3-519872c358ef",
    iconSet: "MaterialCommunityIcons",
    iconName: "camera-switch-outline"
  },
  {
    uuid: "e3426aec-2df5-4214-b261-2ff12613effb",
    iconSet: "MaterialCommunityIcons",
    iconName: "camera-timer"
  },
  {
    uuid: "c3dd1304-aa1c-4b73-a6ae-907c53ffc0cf",
    iconSet: "MaterialCommunityIcons",
    iconName: "camera-wireless"
  },
  {
    uuid: "37e07abb-6053-4e9d-bd59-c5ebd1ec69e9",
    iconSet: "MaterialCommunityIcons",
    iconName: "camera-wireless-outline"
  },
  {
    uuid: "784997a7-c41e-4d62-a18a-ae53923006b3",
    iconSet: "MaterialCommunityIcons",
    iconName: "campfire"
  },
  {
    uuid: "61e098c3-850f-4e71-a949-63b6d5e79321",
    iconSet: "MaterialCommunityIcons",
    iconName: "cancel"
  },
  {
    uuid: "d87bb2ae-5a69-463f-8291-fa0f45fe8aff",
    iconSet: "MaterialCommunityIcons",
    iconName: "candle"
  },
  {
    uuid: "aaf64c0a-33d4-4f51-9d9e-179840596764",
    iconSet: "MaterialCommunityIcons",
    iconName: "candycane"
  },
  {
    uuid: "1f3099ca-9edd-4307-a2cb-0b9cc5465a05",
    iconSet: "MaterialCommunityIcons",
    iconName: "cannabis"
  },
  {
    uuid: "499525a5-d969-4d7c-a6e9-9c870a718739",
    iconSet: "MaterialCommunityIcons",
    iconName: "caps-lock"
  },
  {
    uuid: "9953f337-0525-447e-9b85-36047571b183",
    iconSet: "MaterialCommunityIcons",
    iconName: "car"
  },
  {
    uuid: "48b27e04-8cbd-48f7-b441-d8901625a26f",
    iconSet: "MaterialCommunityIcons",
    iconName: "car-2-plus"
  },
  {
    uuid: "8e14dbc3-f7d9-48c3-9bd3-9839d4ffc243",
    iconSet: "MaterialCommunityIcons",
    iconName: "car-3-plus"
  },
  {
    uuid: "0da5c001-f5d9-4909-8fb6-3567cb0022a3",
    iconSet: "MaterialCommunityIcons",
    iconName: "car-arrow-left"
  },
  {
    uuid: "28dd104b-e89d-43ee-a9a3-36d697e2b768",
    iconSet: "MaterialCommunityIcons",
    iconName: "car-arrow-right"
  },
  {
    uuid: "b92d0549-1773-4ad4-aed7-4613ab04eab6",
    iconSet: "MaterialCommunityIcons",
    iconName: "car-back"
  },
  {
    uuid: "9907cfe2-0850-4ce1-bac9-8ca448a4bea8",
    iconSet: "MaterialCommunityIcons",
    iconName: "car-battery"
  },
  {
    uuid: "671c4a38-fafd-4728-ad70-aab24a7da43c",
    iconSet: "MaterialCommunityIcons",
    iconName: "car-brake-abs"
  },
  {
    uuid: "b5109b41-a0a5-45da-9486-2c3031400471",
    iconSet: "MaterialCommunityIcons",
    iconName: "car-brake-alert"
  },
  {
    uuid: "c69d284b-25b2-4d5a-b336-386ed0fef324",
    iconSet: "MaterialCommunityIcons",
    iconName: "car-brake-hold"
  },
  {
    uuid: "e990b9ca-03d8-47c3-b32c-b77d93297ca7",
    iconSet: "MaterialCommunityIcons",
    iconName: "car-brake-parking"
  },
  {
    uuid: "8daae3b1-5bbd-439a-945a-f04a9ae1f46b",
    iconSet: "MaterialCommunityIcons",
    iconName: "car-brake-retarder"
  },
  {
    uuid: "556c4e12-be1f-4a99-a6ec-5ec7ca01b8de",
    iconSet: "MaterialCommunityIcons",
    iconName: "car-child-seat"
  },
  {
    uuid: "e6fb1c6f-22a3-4609-86c3-f2e2a12d53af",
    iconSet: "MaterialCommunityIcons",
    iconName: "car-clutch"
  },
  {
    uuid: "ea6623ac-51f8-421b-ab32-9bfbd9573646",
    iconSet: "MaterialCommunityIcons",
    iconName: "car-cog"
  },
  {
    uuid: "ea5f2358-fb37-4de1-a6ae-f94c8cc71b16",
    iconSet: "MaterialCommunityIcons",
    iconName: "car-connected"
  },
  {
    uuid: "0d74bc38-f23b-451d-ac89-37d40fdd4455",
    iconSet: "MaterialCommunityIcons",
    iconName: "car-convertible"
  },
  {
    uuid: "ed6b7fc6-033c-4bd1-8c4a-af77a7a7f6e1",
    iconSet: "MaterialCommunityIcons",
    iconName: "car-coolant-level"
  },
  {
    uuid: "0c1715ac-4047-4a0b-9b98-1ca11a7fa7ac",
    iconSet: "MaterialCommunityIcons",
    iconName: "car-cruise-control"
  },
  {
    uuid: "cb6b55d5-a27d-49dc-b35d-bc8b8d73f757",
    iconSet: "MaterialCommunityIcons",
    iconName: "car-defrost-front"
  },
  {
    uuid: "c4416bbb-376e-4f14-85a7-843cc18c8df9",
    iconSet: "MaterialCommunityIcons",
    iconName: "car-defrost-rear"
  },
  {
    uuid: "61194f45-7361-429e-97af-49c3150b676c",
    iconSet: "MaterialCommunityIcons",
    iconName: "car-door"
  },
  {
    uuid: "f865b819-c775-4e91-989d-551a07550ffb",
    iconSet: "MaterialCommunityIcons",
    iconName: "car-door-lock"
  },
  {
    uuid: "0b7efea1-eff2-4079-9872-efccbbd26172",
    iconSet: "MaterialCommunityIcons",
    iconName: "car-electric"
  },
  {
    uuid: "888c81f4-3c93-414c-b0cd-2fccff9fb449",
    iconSet: "MaterialCommunityIcons",
    iconName: "car-esp"
  },
  {
    uuid: "a3e18df9-4155-4229-ba46-1333c86eadc9",
    iconSet: "MaterialCommunityIcons",
    iconName: "car-estate"
  },
  {
    uuid: "4c759e26-80b0-4d06-b68d-35742be286eb",
    iconSet: "MaterialCommunityIcons",
    iconName: "car-hatchback"
  },
  {
    uuid: "ad31d9f3-f594-4117-956f-9d19cb69edca",
    iconSet: "MaterialCommunityIcons",
    iconName: "car-info"
  },
  {
    uuid: "65a34508-a043-474e-9a50-6806c85f5025",
    iconSet: "MaterialCommunityIcons",
    iconName: "car-key"
  },
  {
    uuid: "c0982e73-22cd-4637-9dd7-1097963a462c",
    iconSet: "MaterialCommunityIcons",
    iconName: "car-light-dimmed"
  },
  {
    uuid: "553a36b2-b31d-4e2f-be21-2fa6e963de89",
    iconSet: "MaterialCommunityIcons",
    iconName: "car-light-fog"
  },
  {
    uuid: "59c35ea6-f4cf-4471-b57b-ca01effbec36",
    iconSet: "MaterialCommunityIcons",
    iconName: "car-light-high"
  },
  {
    uuid: "d1df82cb-f1f5-455e-ad4f-538f97cb0c1f",
    iconSet: "MaterialCommunityIcons",
    iconName: "car-limousine"
  },
  {
    uuid: "0aa3c416-e0ba-423a-9086-0ce491f8e740",
    iconSet: "MaterialCommunityIcons",
    iconName: "car-multiple"
  },
  {
    uuid: "6d13b518-2506-4d08-ab07-b631c504e65c",
    iconSet: "MaterialCommunityIcons",
    iconName: "car-off"
  },
  {
    uuid: "f5c4697a-497d-4e9b-bcf6-1468ea5e1567",
    iconSet: "MaterialCommunityIcons",
    iconName: "car-parking-lights"
  },
  {
    uuid: "bee6d879-0964-406c-af28-666c114212d7",
    iconSet: "MaterialCommunityIcons",
    iconName: "car-pickup"
  },
  {
    uuid: "aaa557a7-2895-493c-834d-694664f6600a",
    iconSet: "MaterialCommunityIcons",
    iconName: "car-seat"
  },
  {
    uuid: "e9f422eb-bcfc-4eb4-8622-1543ee4dfdcc",
    iconSet: "MaterialCommunityIcons",
    iconName: "car-seat-cooler"
  },
  {
    uuid: "09dd30ae-e24d-4996-9d21-baf6a2da57b5",
    iconSet: "MaterialCommunityIcons",
    iconName: "car-seat-heater"
  },
  {
    uuid: "db74ffb4-8841-4ee1-9d5d-bdce54699817",
    iconSet: "MaterialCommunityIcons",
    iconName: "car-settings"
  },
  {
    uuid: "359d0b13-3c04-48e7-93fa-b6bc53976640",
    iconSet: "MaterialCommunityIcons",
    iconName: "car-shift-pattern"
  },
  {
    uuid: "27ce8b62-ad13-4949-958b-01fef345c7f2",
    iconSet: "MaterialCommunityIcons",
    iconName: "car-side"
  },
  {
    uuid: "26446215-70d7-497b-8ef7-6668fec9ef28",
    iconSet: "MaterialCommunityIcons",
    iconName: "car-sports"
  },
  {
    uuid: "f30ed203-dba6-42a1-9a26-05f9dc3129d0",
    iconSet: "MaterialCommunityIcons",
    iconName: "car-tire-alert"
  },
  {
    uuid: "095f95c3-78c4-458a-85a6-56c28c5de319",
    iconSet: "MaterialCommunityIcons",
    iconName: "car-traction-control"
  },
  {
    uuid: "c3e2498d-4318-4a10-b719-f4aaa3f714d9",
    iconSet: "MaterialCommunityIcons",
    iconName: "car-turbocharger"
  },
  {
    uuid: "34260c99-1f37-4c19-bd9c-81c0d747d430",
    iconSet: "MaterialCommunityIcons",
    iconName: "car-wash"
  },
  {
    uuid: "ebe900ed-0bbf-4ac8-9978-7a8e6bab61ea",
    iconSet: "MaterialCommunityIcons",
    iconName: "car-windshield"
  },
  {
    uuid: "7f2a9866-e00a-4708-8388-ece9471292c0",
    iconSet: "MaterialCommunityIcons",
    iconName: "car-windshield-outline"
  },
  {
    uuid: "777e9786-6bc9-4a6f-99c7-52b1f999a3c8",
    iconSet: "MaterialCommunityIcons",
    iconName: "carabiner"
  },
  {
    uuid: "9f57c4b6-e993-4f8f-b548-e6625e80093d",
    iconSet: "MaterialCommunityIcons",
    iconName: "caravan"
  },
  {
    uuid: "9b68c647-6b12-4278-a5ac-68f613e62954",
    iconSet: "MaterialCommunityIcons",
    iconName: "card"
  },
  {
    uuid: "068d4731-606a-4613-8cf4-e27843f0b632",
    iconSet: "MaterialCommunityIcons",
    iconName: "card-account-details"
  },
  {
    uuid: "7d015616-7b10-493e-a508-49a376b3150d",
    iconSet: "MaterialCommunityIcons",
    iconName: "card-account-details-outline"
  },
  {
    uuid: "64dfb940-88f1-415e-9d80-cec88bc8ff03",
    iconSet: "MaterialCommunityIcons",
    iconName: "card-account-details-star"
  },
  {
    uuid: "12940b3e-d2fc-4bae-baf6-85e3f03c64a1",
    iconSet: "MaterialCommunityIcons",
    iconName: "card-account-details-star-outline"
  },
  {
    uuid: "dbf55348-539d-4664-9fbc-da5697fdbbde",
    iconSet: "MaterialCommunityIcons",
    iconName: "card-account-mail"
  },
  {
    uuid: "3c5ac5f2-3ecf-40f8-ad26-2d88d5293fb5",
    iconSet: "MaterialCommunityIcons",
    iconName: "card-account-mail-outline"
  },
  {
    uuid: "809e9bc7-2227-48ce-aa56-f44e75029fbd",
    iconSet: "MaterialCommunityIcons",
    iconName: "card-account-phone"
  },
  {
    uuid: "d4f1ed23-c080-466e-b429-79e48cfd8759",
    iconSet: "MaterialCommunityIcons",
    iconName: "card-account-phone-outline"
  },
  {
    uuid: "cc124af1-6468-4fa2-9fe2-a7f9445697b7",
    iconSet: "MaterialCommunityIcons",
    iconName: "card-bulleted"
  },
  {
    uuid: "f07d8823-fd32-42e9-b9f8-6783c0567c06",
    iconSet: "MaterialCommunityIcons",
    iconName: "card-bulleted-off"
  },
  {
    uuid: "b732b7a7-91fb-4310-a1a7-46fd39093902",
    iconSet: "MaterialCommunityIcons",
    iconName: "card-bulleted-off-outline"
  },
  {
    uuid: "67841534-7157-40dc-b795-61da67548256",
    iconSet: "MaterialCommunityIcons",
    iconName: "card-bulleted-outline"
  },
  {
    uuid: "59a5e0f5-8b6e-4403-a011-3bb50bd33bd7",
    iconSet: "MaterialCommunityIcons",
    iconName: "card-bulleted-settings"
  },
  {
    uuid: "47bd907e-33c5-4777-baaf-7f6d05a4f821",
    iconSet: "MaterialCommunityIcons",
    iconName: "card-bulleted-settings-outline"
  },
  {
    uuid: "65ad69b0-6e50-432a-8cf0-8a50b9164e2a",
    iconSet: "MaterialCommunityIcons",
    iconName: "card-outline"
  },
  {
    uuid: "cbf31b9b-bfd9-4bc8-b963-c5f804c08a65",
    iconSet: "MaterialCommunityIcons",
    iconName: "card-plus"
  },
  {
    uuid: "56ad08bb-5eaa-4e5e-809d-3ce3b8528a2f",
    iconSet: "MaterialCommunityIcons",
    iconName: "card-plus-outline"
  },
  {
    uuid: "927ecbb0-31e8-427f-b34b-486420048f38",
    iconSet: "MaterialCommunityIcons",
    iconName: "card-search"
  },
  {
    uuid: "152650e5-f507-4552-9840-c693e0a29f93",
    iconSet: "MaterialCommunityIcons",
    iconName: "card-search-outline"
  },
  {
    uuid: "ba8346e5-09d0-4f82-9ea9-12df79a8676d",
    iconSet: "MaterialCommunityIcons",
    iconName: "card-text"
  },
  {
    uuid: "d8fa2b8a-a636-439f-8864-f4e1a49477d0",
    iconSet: "MaterialCommunityIcons",
    iconName: "card-text-outline"
  },
  {
    uuid: "9bd66f87-c1bf-4113-acff-add69a761b73",
    iconSet: "MaterialCommunityIcons",
    iconName: "cards"
  },
  {
    uuid: "f900744a-611e-4736-86f6-e6c3b82753a2",
    iconSet: "MaterialCommunityIcons",
    iconName: "cards-club"
  },
  {
    uuid: "8c31d31b-4775-42fd-9e18-ae4094709ed6",
    iconSet: "MaterialCommunityIcons",
    iconName: "cards-diamond"
  },
  {
    uuid: "6919af4d-bf46-4bdb-b8a0-6af453652ad4",
    iconSet: "MaterialCommunityIcons",
    iconName: "cards-diamond-outline"
  },
  {
    uuid: "963865a8-b41f-44d0-b94f-efbd8d0a2149",
    iconSet: "MaterialCommunityIcons",
    iconName: "cards-heart"
  },
  {
    uuid: "ef7ca72a-aee7-4d26-96f1-ac075e887b7f",
    iconSet: "MaterialCommunityIcons",
    iconName: "cards-outline"
  },
  {
    uuid: "7b95b78a-9b58-4190-8185-1f2cd62d5c2e",
    iconSet: "MaterialCommunityIcons",
    iconName: "cards-playing-outline"
  },
  {
    uuid: "34413d86-e907-4b6a-ac46-1944b022c278",
    iconSet: "MaterialCommunityIcons",
    iconName: "cards-spade"
  },
  {
    uuid: "664b1e97-f738-446c-bd64-ee0b8d41aea8",
    iconSet: "MaterialCommunityIcons",
    iconName: "cards-variant"
  },
  {
    uuid: "324e22ba-c063-43cd-9ee6-41084edd3c11",
    iconSet: "MaterialCommunityIcons",
    iconName: "carrot"
  },
  {
    uuid: "4a71ae89-7d56-4042-a4c5-c996d11263fd",
    iconSet: "MaterialCommunityIcons",
    iconName: "cart"
  },
  {
    uuid: "03678bcc-85dc-4a85-b257-2d2dc1d1f8b7",
    iconSet: "MaterialCommunityIcons",
    iconName: "cart-arrow-down"
  },
  {
    uuid: "267febce-424d-41fc-8d4c-72fdd9ff4761",
    iconSet: "MaterialCommunityIcons",
    iconName: "cart-arrow-right"
  },
  {
    uuid: "187d800d-7cea-4013-9736-57b01e301fc5",
    iconSet: "MaterialCommunityIcons",
    iconName: "cart-arrow-up"
  },
  {
    uuid: "cda3c65d-950d-4293-86e7-6d0ddc250dde",
    iconSet: "MaterialCommunityIcons",
    iconName: "cart-minus"
  },
  {
    uuid: "1bbb5284-e092-4745-811e-bf0ea61b9b1c",
    iconSet: "MaterialCommunityIcons",
    iconName: "cart-off"
  },
  {
    uuid: "61a6848a-8f51-4edc-9d3d-ab70c90887a8",
    iconSet: "MaterialCommunityIcons",
    iconName: "cart-outline"
  },
  {
    uuid: "2cfb2f2d-cd0a-4b1e-a5f0-3e7c6f8214fd",
    iconSet: "MaterialCommunityIcons",
    iconName: "cart-plus"
  },
  {
    uuid: "927c3667-54fa-4c18-abdc-4bb605287b1b",
    iconSet: "MaterialCommunityIcons",
    iconName: "cart-remove"
  },
  {
    uuid: "6a81494d-11c8-49b4-922b-17208a380abc",
    iconSet: "MaterialCommunityIcons",
    iconName: "case-sensitive-alt"
  },
  {
    uuid: "f488c883-d17a-4e42-b6d3-8b00441b5a05",
    iconSet: "MaterialCommunityIcons",
    iconName: "cash"
  },
  {
    uuid: "fbc72d57-2559-40c7-ad82-efabc4cb6670",
    iconSet: "MaterialCommunityIcons",
    iconName: "cash-100"
  },
  {
    uuid: "6fce4d51-ee56-4486-9e5e-33079b9163ec",
    iconSet: "MaterialCommunityIcons",
    iconName: "cash-marker"
  },
  {
    uuid: "580707c7-fb16-4539-aee6-2d320fae4700",
    iconSet: "MaterialCommunityIcons",
    iconName: "cash-minus"
  },
  {
    uuid: "710ce155-0a17-4d24-be08-a02dd3843b0d",
    iconSet: "MaterialCommunityIcons",
    iconName: "cash-multiple"
  },
  {
    uuid: "154bd4cf-4afb-45f3-9da9-7c8f791a5c40",
    iconSet: "MaterialCommunityIcons",
    iconName: "cash-plus"
  },
  {
    uuid: "bac921da-6b66-4614-8b11-df1ef2dbb531",
    iconSet: "MaterialCommunityIcons",
    iconName: "cash-refund"
  },
  {
    uuid: "a19db690-d575-401c-9721-a47b342b360a",
    iconSet: "MaterialCommunityIcons",
    iconName: "cash-register"
  },
  {
    uuid: "aa65b723-e83c-4a6e-bcdd-84f65a14158c",
    iconSet: "MaterialCommunityIcons",
    iconName: "cash-remove"
  },
  {
    uuid: "6d6ec8ac-3e99-4075-96e7-72836b718366",
    iconSet: "MaterialCommunityIcons",
    iconName: "cash-usd"
  },
  {
    uuid: "3d951ce5-a1b2-4d09-b975-133f81e36a56",
    iconSet: "MaterialCommunityIcons",
    iconName: "cash-usd-outline"
  },
  {
    uuid: "768e1c90-aad9-4bd7-a731-9cf31d2f45f8",
    iconSet: "MaterialCommunityIcons",
    iconName: "cassette"
  },
  {
    uuid: "8886f31d-20f1-4e2f-984c-1e33dcb9cd73",
    iconSet: "MaterialCommunityIcons",
    iconName: "cast"
  },
  {
    uuid: "550dd03b-5145-48ac-941b-cde4de0c3e8e",
    iconSet: "MaterialCommunityIcons",
    iconName: "cast-audio"
  },
  {
    uuid: "243e7077-4804-4dbe-a6a6-b0dad10a4ee3",
    iconSet: "MaterialCommunityIcons",
    iconName: "cast-connected"
  },
  {
    uuid: "a45ec02f-c5a9-4d09-b125-8336106bb7a8",
    iconSet: "MaterialCommunityIcons",
    iconName: "cast-education"
  },
  {
    uuid: "9ddfe051-b798-4359-88c9-234c8a9a1518",
    iconSet: "MaterialCommunityIcons",
    iconName: "cast-off"
  },
  {
    uuid: "2ef27fb7-203c-4de7-85a4-87dcb05a94b5",
    iconSet: "MaterialCommunityIcons",
    iconName: "castle"
  },
  {
    uuid: "623939a4-d066-4493-9d3e-8df8db0f7ac6",
    iconSet: "MaterialCommunityIcons",
    iconName: "cat"
  },
  {
    uuid: "2ff3d2c5-0573-4007-a496-67172e53065c",
    iconSet: "MaterialCommunityIcons",
    iconName: "cctv"
  },
  {
    uuid: "137bec08-0b18-447a-bb7f-74d30844aba2",
    iconSet: "MaterialCommunityIcons",
    iconName: "ceiling-light"
  },
  {
    uuid: "ff708004-038b-4736-8d9f-cb4d25094d5d",
    iconSet: "MaterialCommunityIcons",
    iconName: "cellphone"
  },
  {
    uuid: "54242c2a-15a3-4166-a622-abed6c833e41",
    iconSet: "MaterialCommunityIcons",
    iconName: "cellphone-android"
  },
  {
    uuid: "427a6eb8-644d-4f83-b239-8e970de22565",
    iconSet: "MaterialCommunityIcons",
    iconName: "cellphone-arrow-down"
  },
  {
    uuid: "f2e670b8-c3a8-4c12-8ea9-504189f933e4",
    iconSet: "MaterialCommunityIcons",
    iconName: "cellphone-basic"
  },
  {
    uuid: "76057479-7c57-4900-9661-9ceadc632dfb",
    iconSet: "MaterialCommunityIcons",
    iconName: "cellphone-charging"
  },
  {
    uuid: "de2cd06d-ff32-451f-8fe6-83bef3a21286",
    iconSet: "MaterialCommunityIcons",
    iconName: "cellphone-cog"
  },
  {
    uuid: "6a86d7b6-86e2-4fee-8744-9303753009cb",
    iconSet: "MaterialCommunityIcons",
    iconName: "cellphone-dock"
  },
  {
    uuid: "1347e1fd-76e4-4a18-913f-96b1d89fbb20",
    iconSet: "MaterialCommunityIcons",
    iconName: "cellphone-erase"
  },
  {
    uuid: "54a879c9-dce0-4743-89b5-99f4f0035549",
    iconSet: "MaterialCommunityIcons",
    iconName: "cellphone-information"
  },
  {
    uuid: "a9f680a6-68d2-429b-9ce0-c3babffcd79e",
    iconSet: "MaterialCommunityIcons",
    iconName: "cellphone-iphone"
  },
  {
    uuid: "df896cf1-5799-4493-8fe2-890623b35694",
    iconSet: "MaterialCommunityIcons",
    iconName: "cellphone-key"
  },
  {
    uuid: "c83a8069-6d26-48e1-aa3c-ed2bff7ff883",
    iconSet: "MaterialCommunityIcons",
    iconName: "cellphone-link"
  },
  {
    uuid: "12a4f113-d9eb-4501-9b24-75411dd0cc5d",
    iconSet: "MaterialCommunityIcons",
    iconName: "cellphone-link-off"
  },
  {
    uuid: "29916300-7143-4180-961e-59bff8632fd0",
    iconSet: "MaterialCommunityIcons",
    iconName: "cellphone-lock"
  },
  {
    uuid: "f825ea8e-8d55-43cb-b31d-2b7dc8560e88",
    iconSet: "MaterialCommunityIcons",
    iconName: "cellphone-message"
  },
  {
    uuid: "80a026be-d6d8-4fda-a234-12ae04946dfc",
    iconSet: "MaterialCommunityIcons",
    iconName: "cellphone-message-off"
  },
  {
    uuid: "73678e53-1e70-4050-86b0-ece86e99508d",
    iconSet: "MaterialCommunityIcons",
    iconName: "cellphone-nfc"
  },
  {
    uuid: "ed3f5f2f-e962-4bc2-a099-79eba6d56ae1",
    iconSet: "MaterialCommunityIcons",
    iconName: "cellphone-nfc-off"
  },
  {
    uuid: "fab5ff87-8bb5-4012-bf26-60a95f753986",
    iconSet: "MaterialCommunityIcons",
    iconName: "cellphone-off"
  },
  {
    uuid: "99742654-d04e-489f-88bb-14b56b48328b",
    iconSet: "MaterialCommunityIcons",
    iconName: "cellphone-play"
  },
  {
    uuid: "10128b4e-b7e7-4e31-a5d7-e523f7e27372",
    iconSet: "MaterialCommunityIcons",
    iconName: "cellphone-screenshot"
  },
  {
    uuid: "83d105f8-f592-47b6-a903-f56072c94cc5",
    iconSet: "MaterialCommunityIcons",
    iconName: "cellphone-settings"
  },
  {
    uuid: "cad5b829-9e05-4c42-b260-19275a51c3d1",
    iconSet: "MaterialCommunityIcons",
    iconName: "cellphone-sound"
  },
  {
    uuid: "eaa24ea7-88ff-4736-bb7a-e621c81e6855",
    iconSet: "MaterialCommunityIcons",
    iconName: "cellphone-text"
  },
  {
    uuid: "381e1a87-3527-4d3f-bfde-196e7e3dda28",
    iconSet: "MaterialCommunityIcons",
    iconName: "cellphone-wireless"
  },
  {
    uuid: "5c209dc8-f7d7-4297-8a44-7b0703d45448",
    iconSet: "MaterialCommunityIcons",
    iconName: "celtic-cross"
  },
  {
    uuid: "cec880af-cbb1-4014-abbd-f7812cf8e759",
    iconSet: "MaterialCommunityIcons",
    iconName: "centos"
  },
  {
    uuid: "1ce380d7-17b1-4702-a8b5-dba7acb67da3",
    iconSet: "MaterialCommunityIcons",
    iconName: "certificate"
  },
  {
    uuid: "8db0f42c-3842-49ef-9113-a8c0a0b3d00c",
    iconSet: "MaterialCommunityIcons",
    iconName: "certificate-outline"
  },
  {
    uuid: "7d95e834-4be6-4794-b733-ad9905030d08",
    iconSet: "MaterialCommunityIcons",
    iconName: "chair-rolling"
  },
  {
    uuid: "39ab19ff-2674-4dfa-9e44-2b6230676707",
    iconSet: "MaterialCommunityIcons",
    iconName: "chair-school"
  },
  {
    uuid: "a8b735e8-d849-42cc-94dc-b0ac66feeead",
    iconSet: "MaterialCommunityIcons",
    iconName: "charity"
  },
  {
    uuid: "621c7866-e9ff-483c-bace-933f8634df39",
    iconSet: "MaterialCommunityIcons",
    iconName: "chart-arc"
  },
  {
    uuid: "3864ef6d-5113-4d5f-884b-8a4b6e6e48bb",
    iconSet: "MaterialCommunityIcons",
    iconName: "chart-areaspline"
  },
  {
    uuid: "966f028c-4306-44ee-a546-9dc8debfc819",
    iconSet: "MaterialCommunityIcons",
    iconName: "chart-areaspline-variant"
  },
  {
    uuid: "f34a7318-14e5-4a9e-9c49-3a891280ca83",
    iconSet: "MaterialCommunityIcons",
    iconName: "chart-bar"
  },
  {
    uuid: "f676fac7-c502-4e3b-ba28-dfaf954bc9fa",
    iconSet: "MaterialCommunityIcons",
    iconName: "chart-bar-stacked"
  },
  {
    uuid: "52445f20-3fbc-449c-96a2-94a3246565a5",
    iconSet: "MaterialCommunityIcons",
    iconName: "chart-bell-curve"
  },
  {
    uuid: "0ae27a0b-b489-4c65-9d82-e40719dfb8e8",
    iconSet: "MaterialCommunityIcons",
    iconName: "chart-bell-curve-cumulative"
  },
  {
    uuid: "e452fffc-6d5c-434b-a7f2-d1809c495dc8",
    iconSet: "MaterialCommunityIcons",
    iconName: "chart-bubble"
  },
  {
    uuid: "aea7385d-b99f-4bf2-94db-ab81546f2886",
    iconSet: "MaterialCommunityIcons",
    iconName: "chart-donut"
  },
  {
    uuid: "59e0dca6-0a6d-4942-b21b-a5f26472eff1",
    iconSet: "MaterialCommunityIcons",
    iconName: "chart-donut-variant"
  },
  {
    uuid: "9463b569-efcb-497d-a077-5400917523d5",
    iconSet: "MaterialCommunityIcons",
    iconName: "chart-gantt"
  },
  {
    uuid: "dc9eeec4-dd41-401a-9994-f230a17f2455",
    iconSet: "MaterialCommunityIcons",
    iconName: "chart-histogram"
  },
  {
    uuid: "2a39039c-60f7-49be-9b34-3d37048964d6",
    iconSet: "MaterialCommunityIcons",
    iconName: "chart-line"
  },
  {
    uuid: "080276f5-018e-4ccb-87d2-29fd5ed4c79d",
    iconSet: "MaterialCommunityIcons",
    iconName: "chart-line-stacked"
  },
  {
    uuid: "2597d4ee-b975-49e5-b3e7-a239f12c5f4d",
    iconSet: "MaterialCommunityIcons",
    iconName: "chart-line-variant"
  },
  {
    uuid: "e692710d-157e-46cd-81f7-4b2d1c9e09c0",
    iconSet: "MaterialCommunityIcons",
    iconName: "chart-multiline"
  },
  {
    uuid: "70a5166c-3a65-46f6-bcd9-31928565486c",
    iconSet: "MaterialCommunityIcons",
    iconName: "chart-multiple"
  },
  {
    uuid: "31525bd8-f20b-4cd0-8375-8a81eb727c0c",
    iconSet: "MaterialCommunityIcons",
    iconName: "chart-pie"
  },
  {
    uuid: "888efdfa-4629-473e-95d2-72a28c495efb",
    iconSet: "MaterialCommunityIcons",
    iconName: "chart-ppf"
  },
  {
    uuid: "dd4d176d-cf89-4eba-8f79-0f77c2f457ca",
    iconSet: "MaterialCommunityIcons",
    iconName: "chart-sankey"
  },
  {
    uuid: "51c21aab-9c31-4c19-ac4f-a284d41cca4f",
    iconSet: "MaterialCommunityIcons",
    iconName: "chart-sankey-variant"
  },
  {
    uuid: "73506551-5565-4c79-8206-ee23f1146be8",
    iconSet: "MaterialCommunityIcons",
    iconName: "chart-scatter-plot"
  },
  {
    uuid: "8c84fb42-362b-4fe4-a7cb-3bf93ca43d0c",
    iconSet: "MaterialCommunityIcons",
    iconName: "chart-scatter-plot-hexbin"
  },
  {
    uuid: "a3384513-8b68-4f11-b150-92905d5265ad",
    iconSet: "MaterialCommunityIcons",
    iconName: "chart-timeline"
  },
  {
    uuid: "c7549d48-f1a7-471e-b8f4-4af0e13359d2",
    iconSet: "MaterialCommunityIcons",
    iconName: "chart-timeline-variant"
  },
  {
    uuid: "f4bd76b1-cf87-4652-b1b9-20559a54ebaf",
    iconSet: "MaterialCommunityIcons",
    iconName: "chart-tree"
  },
  {
    uuid: "29865048-4ce7-4ac5-a78c-15fab49fa75c",
    iconSet: "MaterialCommunityIcons",
    iconName: "chat"
  },
  {
    uuid: "019565dc-3af4-45a6-a203-df4ddcd40ce7",
    iconSet: "MaterialCommunityIcons",
    iconName: "chat-alert"
  },
  {
    uuid: "999c43e3-fd5d-4efa-bc35-b2c9e109c132",
    iconSet: "MaterialCommunityIcons",
    iconName: "chat-alert-outline"
  },
  {
    uuid: "b9990377-5a42-49a4-a843-6c48829d6dc9",
    iconSet: "MaterialCommunityIcons",
    iconName: "chat-minus"
  },
  {
    uuid: "259454a1-2b9f-46fa-bfe6-341932a91360",
    iconSet: "MaterialCommunityIcons",
    iconName: "chat-minus-outline"
  },
  {
    uuid: "148961ea-4fb9-4695-b7b6-b12c9a5822cb",
    iconSet: "MaterialCommunityIcons",
    iconName: "chat-outline"
  },
  {
    uuid: "b1526542-e198-4896-8dd8-57607fd46a16",
    iconSet: "MaterialCommunityIcons",
    iconName: "chat-plus"
  },
  {
    uuid: "4b056a2c-d2be-4b76-bde6-31ca1d5d1dff",
    iconSet: "MaterialCommunityIcons",
    iconName: "chat-plus-outline"
  },
  {
    uuid: "b56c7c8d-8d74-4307-88b1-4b2f48cbad01",
    iconSet: "MaterialCommunityIcons",
    iconName: "chat-processing"
  },
  {
    uuid: "57c41496-f98a-4d96-8b6d-1de8abec98b9",
    iconSet: "MaterialCommunityIcons",
    iconName: "chat-processing-outline"
  },
  {
    uuid: "2a10f32e-4721-4e80-aa4b-b4e9aeb44eac",
    iconSet: "MaterialCommunityIcons",
    iconName: "chat-remove"
  },
  {
    uuid: "91758796-7d41-43ef-9e04-f00b50b01b20",
    iconSet: "MaterialCommunityIcons",
    iconName: "chat-remove-outline"
  },
  {
    uuid: "e12cc971-1bf6-443f-a400-5c11e51a9094",
    iconSet: "MaterialCommunityIcons",
    iconName: "chat-sleep"
  },
  {
    uuid: "67e419ff-ebaa-4b63-9b44-599b98cc5382",
    iconSet: "MaterialCommunityIcons",
    iconName: "chat-sleep-outline"
  },
  {
    uuid: "00af79ca-5aad-4a66-9fcb-48bd875da54f",
    iconSet: "MaterialCommunityIcons",
    iconName: "check"
  },
  {
    uuid: "b412e914-3652-4ecb-bc7b-c34bb07212a4",
    iconSet: "MaterialCommunityIcons",
    iconName: "check-all"
  },
  {
    uuid: "0029462b-f312-40ab-a814-510960322250",
    iconSet: "MaterialCommunityIcons",
    iconName: "check-bold"
  },
  {
    uuid: "cff0435a-1be3-46f2-a3fe-c13e98a89fde",
    iconSet: "MaterialCommunityIcons",
    iconName: "check-box-multiple-outline"
  },
  {
    uuid: "ef051e82-8090-4a53-9927-078b21267f63",
    iconSet: "MaterialCommunityIcons",
    iconName: "check-box-outline"
  },
  {
    uuid: "61407047-6ebf-4ff2-9de9-9c6409e80976",
    iconSet: "MaterialCommunityIcons",
    iconName: "check-circle"
  },
  {
    uuid: "76520ba7-5116-4e90-be76-582af30cd408",
    iconSet: "MaterialCommunityIcons",
    iconName: "check-circle-outline"
  },
  {
    uuid: "2899d7ce-4030-4a0d-ab90-3f03c420284e",
    iconSet: "MaterialCommunityIcons",
    iconName: "check-decagram"
  },
  {
    uuid: "38208690-cc24-4d0f-8cc7-6b6fd2172afb",
    iconSet: "MaterialCommunityIcons",
    iconName: "check-network"
  },
  {
    uuid: "c4a34d9a-89fe-4cb8-ac94-536d083e1f21",
    iconSet: "MaterialCommunityIcons",
    iconName: "check-network-outline"
  },
  {
    uuid: "ac0674a2-2f4e-48ed-94ee-f1c7d98830d6",
    iconSet: "MaterialCommunityIcons",
    iconName: "check-outline"
  },
  {
    uuid: "3df61dce-f90b-4902-8e96-0d480b844fd3",
    iconSet: "MaterialCommunityIcons",
    iconName: "check-underline"
  },
  {
    uuid: "f464ae55-1228-4d37-9de9-13ae363bfb46",
    iconSet: "MaterialCommunityIcons",
    iconName: "check-underline-circle"
  },
  {
    uuid: "1e6f6dfa-cbc1-4b37-acb4-97b794e9667d",
    iconSet: "MaterialCommunityIcons",
    iconName: "check-underline-circle-outline"
  },
  {
    uuid: "bc89d38b-6a73-437d-b5be-0a45b61a1955",
    iconSet: "MaterialCommunityIcons",
    iconName: "checkbook"
  },
  {
    uuid: "77b804e4-6dbf-448e-b220-93312667c6d6",
    iconSet: "MaterialCommunityIcons",
    iconName: "checkbox-blank"
  },
  {
    uuid: "ac102407-e631-49d9-9267-dc04997e7951",
    iconSet: "MaterialCommunityIcons",
    iconName: "checkbox-blank-circle"
  },
  {
    uuid: "0c8e8619-c8eb-45f0-864b-d638cf6979f6",
    iconSet: "MaterialCommunityIcons",
    iconName: "checkbox-blank-circle-outline"
  },
  {
    uuid: "1d930d5d-c3e9-44ff-95ba-35d581308dca",
    iconSet: "MaterialCommunityIcons",
    iconName: "checkbox-blank-off"
  },
  {
    uuid: "4da1182d-0141-4c10-a83d-50b7dec3f0ec",
    iconSet: "MaterialCommunityIcons",
    iconName: "checkbox-blank-off-outline"
  },
  {
    uuid: "18d4cd85-670b-4509-9483-263f1f1b5e80",
    iconSet: "MaterialCommunityIcons",
    iconName: "checkbox-blank-outline"
  },
  {
    uuid: "a4f28db2-5fa5-4a0d-b76b-1c8a9281f922",
    iconSet: "MaterialCommunityIcons",
    iconName: "checkbox-intermediate"
  },
  {
    uuid: "2504cb16-ae89-40ec-82fe-b944fbb171e4",
    iconSet: "MaterialCommunityIcons",
    iconName: "checkbox-marked"
  },
  {
    uuid: "bf866971-2bce-45fd-abea-1fbf9f8d7af7",
    iconSet: "MaterialCommunityIcons",
    iconName: "checkbox-marked-circle"
  },
  {
    uuid: "e9e68888-cf0d-4ccf-b768-7db06ba5adcc",
    iconSet: "MaterialCommunityIcons",
    iconName: "checkbox-marked-circle-outline"
  },
  {
    uuid: "d6288f79-3550-4bc5-ae16-9d4bc42f7e5d",
    iconSet: "MaterialCommunityIcons",
    iconName: "checkbox-marked-outline"
  },
  {
    uuid: "14440244-dc7b-4aad-8335-815e8fd672cd",
    iconSet: "MaterialCommunityIcons",
    iconName: "checkbox-multiple-blank"
  },
  {
    uuid: "bca56952-95d6-4020-912d-3097d2e6efc7",
    iconSet: "MaterialCommunityIcons",
    iconName: "checkbox-multiple-blank-circle"
  },
  {
    uuid: "2f9d0fcc-e098-4b9d-a73c-f52bd5044e7c",
    iconSet: "MaterialCommunityIcons",
    iconName: "checkbox-multiple-blank-circle-outline"
  },
  {
    uuid: "9cde5a13-5766-42d4-a97b-ad3302259cee",
    iconSet: "MaterialCommunityIcons",
    iconName: "checkbox-multiple-blank-outline"
  },
  {
    uuid: "10fe82a9-549e-4378-8d1d-c4c4f9fbb7c0",
    iconSet: "MaterialCommunityIcons",
    iconName: "checkbox-multiple-marked"
  },
  {
    uuid: "fb80e4f1-be8b-45a5-95c0-38eb39e72a41",
    iconSet: "MaterialCommunityIcons",
    iconName: "checkbox-multiple-marked-circle"
  },
  {
    uuid: "74784534-2b4f-4dec-a425-976feba73296",
    iconSet: "MaterialCommunityIcons",
    iconName: "checkbox-multiple-marked-circle-outline"
  },
  {
    uuid: "bd07b2c2-f2f2-48d8-a892-85e1c117cdd3",
    iconSet: "MaterialCommunityIcons",
    iconName: "checkbox-multiple-marked-outline"
  },
  {
    uuid: "ae36951b-eefc-482c-8a70-e5d919ce1bd8",
    iconSet: "MaterialCommunityIcons",
    iconName: "checkerboard"
  },
  {
    uuid: "032b8610-5103-49cc-9d3b-c2e5327b4728",
    iconSet: "MaterialCommunityIcons",
    iconName: "checkerboard-minus"
  },
  {
    uuid: "eb8e2f3c-940e-4753-9d3f-7ed1e22adc5a",
    iconSet: "MaterialCommunityIcons",
    iconName: "checkerboard-plus"
  },
  {
    uuid: "eecc9e92-98fd-4515-af1a-7f80dc5b8c75",
    iconSet: "MaterialCommunityIcons",
    iconName: "checkerboard-remove"
  },
  {
    uuid: "3f88d7fc-47b2-4f2f-8029-6c3e3c001c8d",
    iconSet: "MaterialCommunityIcons",
    iconName: "cheese"
  },
  {
    uuid: "4ff58908-110d-40df-a8fe-19ebada04120",
    iconSet: "MaterialCommunityIcons",
    iconName: "cheese-off"
  },
  {
    uuid: "691bf5df-b0f1-45b4-a1d1-7856a2d54674",
    iconSet: "MaterialCommunityIcons",
    iconName: "chef-hat"
  },
  {
    uuid: "c4044a43-c54a-459b-98ca-d5c2d2baea9c",
    iconSet: "MaterialCommunityIcons",
    iconName: "chemical-weapon"
  },
  {
    uuid: "c9fd0d57-c744-4344-8ad1-a2bf3bcf1895",
    iconSet: "MaterialCommunityIcons",
    iconName: "chess-bishop"
  },
  {
    uuid: "4e88bd57-f501-4ab9-92ff-9cc3006501e7",
    iconSet: "MaterialCommunityIcons",
    iconName: "chess-king"
  },
  {
    uuid: "4e0c4716-6dc0-4cc8-851e-7fcf58611844",
    iconSet: "MaterialCommunityIcons",
    iconName: "chess-knight"
  },
  {
    uuid: "78b1144c-9120-4eb6-a8c6-8193000e8ae5",
    iconSet: "MaterialCommunityIcons",
    iconName: "chess-pawn"
  },
  {
    uuid: "f00ca0d1-0053-4aca-9614-6d7b4bbfa400",
    iconSet: "MaterialCommunityIcons",
    iconName: "chess-queen"
  },
  {
    uuid: "2ccfef63-c948-48dd-b6cd-a679fbe200ef",
    iconSet: "MaterialCommunityIcons",
    iconName: "chess-rook"
  },
  {
    uuid: "556f6f83-b366-4bf3-ad6e-9ccc9bc9e3d6",
    iconSet: "MaterialCommunityIcons",
    iconName: "chevron-double-down"
  },
  {
    uuid: "d2a139c0-f5c0-45a8-a87a-2b24e6d6e2e4",
    iconSet: "MaterialCommunityIcons",
    iconName: "chevron-double-left"
  },
  {
    uuid: "ff75962e-f659-41f1-b7c2-559fea97c2d2",
    iconSet: "MaterialCommunityIcons",
    iconName: "chevron-double-right"
  },
  {
    uuid: "484a99d5-2924-43fc-995b-e376220a3545",
    iconSet: "MaterialCommunityIcons",
    iconName: "chevron-double-up"
  },
  {
    uuid: "b567e6e7-bead-4f6a-a8de-2a7a93747c07",
    iconSet: "MaterialCommunityIcons",
    iconName: "chevron-down"
  },
  {
    uuid: "4b7d4f36-0958-4b97-bd58-90899cbc6b32",
    iconSet: "MaterialCommunityIcons",
    iconName: "chevron-down-box"
  },
  {
    uuid: "7a250e8a-99ae-4d13-ad72-101e95fbf513",
    iconSet: "MaterialCommunityIcons",
    iconName: "chevron-down-box-outline"
  },
  {
    uuid: "02fd157d-d5b5-4908-9b5a-5f1a4fadb820",
    iconSet: "MaterialCommunityIcons",
    iconName: "chevron-down-circle"
  },
  {
    uuid: "68b8c0ed-8e24-4b63-bb90-89c86961bde4",
    iconSet: "MaterialCommunityIcons",
    iconName: "chevron-down-circle-outline"
  },
  {
    uuid: "b7d64c0c-9dde-4a61-8422-6f92fcbab7e9",
    iconSet: "MaterialCommunityIcons",
    iconName: "chevron-left"
  },
  {
    uuid: "c7f4398d-678b-4e96-a32f-404f2095d49e",
    iconSet: "MaterialCommunityIcons",
    iconName: "chevron-left-box"
  },
  {
    uuid: "8bb7549a-b6cf-478d-a533-5826688da635",
    iconSet: "MaterialCommunityIcons",
    iconName: "chevron-left-box-outline"
  },
  {
    uuid: "51a13e50-a001-4756-91b4-6cb8bc72e659",
    iconSet: "MaterialCommunityIcons",
    iconName: "chevron-left-circle"
  },
  {
    uuid: "4ad4f12e-8b7b-4937-822a-4c5931ac0188",
    iconSet: "MaterialCommunityIcons",
    iconName: "chevron-left-circle-outline"
  },
  {
    uuid: "b3d3b185-0570-4da8-9097-8645bbe707da",
    iconSet: "MaterialCommunityIcons",
    iconName: "chevron-right"
  },
  {
    uuid: "d5d5487e-33c8-4cb7-9e41-581b200eb1ec",
    iconSet: "MaterialCommunityIcons",
    iconName: "chevron-right-box"
  },
  {
    uuid: "fdf73461-d7e6-4efe-a43a-702d5b1493de",
    iconSet: "MaterialCommunityIcons",
    iconName: "chevron-right-box-outline"
  },
  {
    uuid: "5c287814-8f1c-43de-b8b7-952fdca87ae9",
    iconSet: "MaterialCommunityIcons",
    iconName: "chevron-right-circle"
  },
  {
    uuid: "fc8e59ee-63e5-4496-8380-6f94925ec0bd",
    iconSet: "MaterialCommunityIcons",
    iconName: "chevron-right-circle-outline"
  },
  {
    uuid: "6f657a59-291c-4b4e-86e7-7b93d2d899cc",
    iconSet: "MaterialCommunityIcons",
    iconName: "chevron-triple-down"
  },
  {
    uuid: "ed476187-3047-4dc6-a528-2fee85c98602",
    iconSet: "MaterialCommunityIcons",
    iconName: "chevron-triple-left"
  },
  {
    uuid: "52c093bd-f895-4393-b4a5-09d58872867f",
    iconSet: "MaterialCommunityIcons",
    iconName: "chevron-triple-right"
  },
  {
    uuid: "205a48d8-315f-4033-9c65-218e3d0e1fe8",
    iconSet: "MaterialCommunityIcons",
    iconName: "chevron-triple-up"
  },
  {
    uuid: "cdaedb0e-de01-4094-aae1-88e13fd9561c",
    iconSet: "MaterialCommunityIcons",
    iconName: "chevron-up"
  },
  {
    uuid: "8b831e7e-4970-4047-9ab8-35e58e4650cc",
    iconSet: "MaterialCommunityIcons",
    iconName: "chevron-up-box"
  },
  {
    uuid: "20e509ba-b204-4925-8ef7-f35c2d50806e",
    iconSet: "MaterialCommunityIcons",
    iconName: "chevron-up-box-outline"
  },
  {
    uuid: "42d541a2-97f5-4a24-a316-b908f33db221",
    iconSet: "MaterialCommunityIcons",
    iconName: "chevron-up-circle"
  },
  {
    uuid: "5a947e21-fb64-4b83-b439-f0c52e56e7a8",
    iconSet: "MaterialCommunityIcons",
    iconName: "chevron-up-circle-outline"
  },
  {
    uuid: "edd8cd4f-cf21-48fb-8b0a-f28cc0795d0f",
    iconSet: "MaterialCommunityIcons",
    iconName: "chili-hot"
  },
  {
    uuid: "c38d2ce7-e7f7-4394-b884-593fcb4032b1",
    iconSet: "MaterialCommunityIcons",
    iconName: "chili-medium"
  },
  {
    uuid: "94fa28a3-5745-416e-b61e-dbfa80159526",
    iconSet: "MaterialCommunityIcons",
    iconName: "chili-mild"
  },
  {
    uuid: "a98da206-f318-4761-844e-ccf2f7f3e811",
    iconSet: "MaterialCommunityIcons",
    iconName: "chili-off"
  },
  {
    uuid: "62bc9ef2-8e9c-4101-a0d9-673b0ae04ec9",
    iconSet: "MaterialCommunityIcons",
    iconName: "chip"
  },
  {
    uuid: "0430239f-0b4b-4f49-9c3f-1f9fe918087d",
    iconSet: "MaterialCommunityIcons",
    iconName: "christianity"
  },
  {
    uuid: "9497a2ae-a2aa-4b1b-9b98-8a1c6998cc85",
    iconSet: "MaterialCommunityIcons",
    iconName: "christianity-outline"
  },
  {
    uuid: "33cd4cd0-60f5-4ea6-a6d9-d4e93f19481f",
    iconSet: "MaterialCommunityIcons",
    iconName: "church"
  },
  {
    uuid: "137834c3-0304-4b94-8423-a982d293d238",
    iconSet: "MaterialCommunityIcons",
    iconName: "cigar"
  },
  {
    uuid: "56c3862c-f23e-456e-b08f-52244aa03036",
    iconSet: "MaterialCommunityIcons",
    iconName: "cigar-off"
  },
  {
    uuid: "874b9830-377a-4df8-94fd-7d00cec7197c",
    iconSet: "MaterialCommunityIcons",
    iconName: "circle"
  },
  {
    uuid: "da351d77-14db-47a0-9477-73a46adc8b48",
    iconSet: "MaterialCommunityIcons",
    iconName: "circle-double"
  },
  {
    uuid: "c642a217-557e-49cf-865a-1b52446a7555",
    iconSet: "MaterialCommunityIcons",
    iconName: "circle-edit-outline"
  },
  {
    uuid: "35fda7e1-5d56-4eaf-88cc-316bc9bf0f7a",
    iconSet: "MaterialCommunityIcons",
    iconName: "circle-expand"
  },
  {
    uuid: "a915e926-e5c9-47e3-ae62-b849b819d217",
    iconSet: "MaterialCommunityIcons",
    iconName: "circle-half"
  },
  {
    uuid: "e1d49bed-7309-4bdc-bfb9-d016a86568c2",
    iconSet: "MaterialCommunityIcons",
    iconName: "circle-half-full"
  },
  {
    uuid: "cb69d72e-da4c-481a-829d-afe6de9825c8",
    iconSet: "MaterialCommunityIcons",
    iconName: "circle-medium"
  },
  {
    uuid: "0c626629-e90a-45d9-9392-0b5de9393010",
    iconSet: "MaterialCommunityIcons",
    iconName: "circle-multiple"
  },
  {
    uuid: "07490362-a58d-4b2a-80c2-db4b02a0e89e",
    iconSet: "MaterialCommunityIcons",
    iconName: "circle-multiple-outline"
  },
  {
    uuid: "dd6de7f8-c46e-45c7-92ea-2386e112c17b",
    iconSet: "MaterialCommunityIcons",
    iconName: "circle-off-outline"
  },
  {
    uuid: "59224d11-88ed-4779-8b81-2756e4810f07",
    iconSet: "MaterialCommunityIcons",
    iconName: "circle-outline"
  },
  {
    uuid: "096605fd-a925-49bf-803f-0bc9feb9675d",
    iconSet: "MaterialCommunityIcons",
    iconName: "circle-slice-1"
  },
  {
    uuid: "1e1c42c9-7bce-458b-8fd6-93c9e3d1b575",
    iconSet: "MaterialCommunityIcons",
    iconName: "circle-slice-2"
  },
  {
    uuid: "2859362c-87f5-46be-bcfc-c833a6b2a43c",
    iconSet: "MaterialCommunityIcons",
    iconName: "circle-slice-3"
  },
  {
    uuid: "39b38d0e-d6f6-49df-b78e-67a69fe6e1cc",
    iconSet: "MaterialCommunityIcons",
    iconName: "circle-slice-4"
  },
  {
    uuid: "dd236a54-b6d2-488f-8796-73c0d7d24eb3",
    iconSet: "MaterialCommunityIcons",
    iconName: "circle-slice-5"
  },
  {
    uuid: "3641ead0-994d-4097-8ca9-750c03ca08cb",
    iconSet: "MaterialCommunityIcons",
    iconName: "circle-slice-6"
  },
  {
    uuid: "239ab27e-edad-485e-8187-d310cfa95d67",
    iconSet: "MaterialCommunityIcons",
    iconName: "circle-slice-7"
  },
  {
    uuid: "a4cbde90-adce-42cf-8c3e-998bbfb363f4",
    iconSet: "MaterialCommunityIcons",
    iconName: "circle-slice-8"
  },
  {
    uuid: "21bdab39-271c-40ff-9806-889e07311f0c",
    iconSet: "MaterialCommunityIcons",
    iconName: "circle-small"
  },
  {
    uuid: "f97a7784-4649-43f8-b48c-749928019b49",
    iconSet: "MaterialCommunityIcons",
    iconName: "circular-saw"
  },
  {
    uuid: "d901ad68-fb0a-447f-aad7-8b28b3a3f26b",
    iconSet: "MaterialCommunityIcons",
    iconName: "city"
  },
  {
    uuid: "db11dcd7-3cd0-40af-acd4-41d9612cf739",
    iconSet: "MaterialCommunityIcons",
    iconName: "city-variant"
  },
  {
    uuid: "ac15896d-aae8-4fc2-9c2f-6243ce81d26d",
    iconSet: "MaterialCommunityIcons",
    iconName: "city-variant-outline"
  },
  {
    uuid: "c945b510-0a2e-4c88-8d27-6c050caba96c",
    iconSet: "MaterialCommunityIcons",
    iconName: "clipboard"
  },
  {
    uuid: "1af7fd92-b4e8-453a-b716-e210a5596722",
    iconSet: "MaterialCommunityIcons",
    iconName: "clipboard-account"
  },
  {
    uuid: "93bbeae9-e234-4d9c-bf0a-be9028bdcfde",
    iconSet: "MaterialCommunityIcons",
    iconName: "clipboard-account-outline"
  },
  {
    uuid: "2524ac8f-c731-40b6-b2a8-bb43f3a98981",
    iconSet: "MaterialCommunityIcons",
    iconName: "clipboard-alert"
  },
  {
    uuid: "d252c988-3305-442d-8878-da8730f8027d",
    iconSet: "MaterialCommunityIcons",
    iconName: "clipboard-alert-outline"
  },
  {
    uuid: "a055bb04-5a66-4980-9f19-e64067917460",
    iconSet: "MaterialCommunityIcons",
    iconName: "clipboard-arrow-down"
  },
  {
    uuid: "30162fb8-dce3-4a00-9f48-71e27afc306c",
    iconSet: "MaterialCommunityIcons",
    iconName: "clipboard-arrow-down-outline"
  },
  {
    uuid: "ea96b3cf-57e4-4d89-bc7a-9ea8344c4154",
    iconSet: "MaterialCommunityIcons",
    iconName: "clipboard-arrow-left"
  },
  {
    uuid: "826804fd-8aa2-42c6-909b-3440fe5699fd",
    iconSet: "MaterialCommunityIcons",
    iconName: "clipboard-arrow-left-outline"
  },
  {
    uuid: "175aa29e-8e66-4806-a2b0-6a69ca7706ee",
    iconSet: "MaterialCommunityIcons",
    iconName: "clipboard-arrow-right"
  },
  {
    uuid: "6a04b8df-b00a-475c-ba19-096a4f7f1b61",
    iconSet: "MaterialCommunityIcons",
    iconName: "clipboard-arrow-right-outline"
  },
  {
    uuid: "ab04f30d-fba3-411b-bb7e-783b47125634",
    iconSet: "MaterialCommunityIcons",
    iconName: "clipboard-arrow-up"
  },
  {
    uuid: "3e6e76d8-8fe5-4b77-ab60-ef3281f5f3f5",
    iconSet: "MaterialCommunityIcons",
    iconName: "clipboard-arrow-up-outline"
  },
  {
    uuid: "f5fc92df-a434-40a0-bff1-3d01ca1b537b",
    iconSet: "MaterialCommunityIcons",
    iconName: "clipboard-check"
  },
  {
    uuid: "71f1319c-2f49-4000-92d4-15cf31621e72",
    iconSet: "MaterialCommunityIcons",
    iconName: "clipboard-check-multiple"
  },
  {
    uuid: "0fd53eb2-f1d3-4e26-b8df-492d76456235",
    iconSet: "MaterialCommunityIcons",
    iconName: "clipboard-check-multiple-outline"
  },
  {
    uuid: "148b889a-970d-4931-9355-818959ff46b0",
    iconSet: "MaterialCommunityIcons",
    iconName: "clipboard-check-outline"
  },
  {
    uuid: "8d924748-ea6f-410e-9091-a56593f2c896",
    iconSet: "MaterialCommunityIcons",
    iconName: "clipboard-file"
  },
  {
    uuid: "8e19b607-f31f-419b-bd0e-276d570aa995",
    iconSet: "MaterialCommunityIcons",
    iconName: "clipboard-file-outline"
  },
  {
    uuid: "6f59f37b-9542-4437-ab79-fc0f01b15b84",
    iconSet: "MaterialCommunityIcons",
    iconName: "clipboard-flow"
  },
  {
    uuid: "3db76e5b-eb22-4b7f-899d-d85eeca718df",
    iconSet: "MaterialCommunityIcons",
    iconName: "clipboard-flow-outline"
  },
  {
    uuid: "1f2dcbf0-04fc-4bfb-baf9-4dcb953b3b35",
    iconSet: "MaterialCommunityIcons",
    iconName: "clipboard-list"
  },
  {
    uuid: "05dee1e7-7f6f-40d5-8013-61a4647fc3fa",
    iconSet: "MaterialCommunityIcons",
    iconName: "clipboard-list-outline"
  },
  {
    uuid: "cbaac4d3-0988-4f3e-a0b6-9464bd0aa60c",
    iconSet: "MaterialCommunityIcons",
    iconName: "clipboard-multiple"
  },
  {
    uuid: "09ab5925-5ca1-4287-bbb4-0fe5d2d05074",
    iconSet: "MaterialCommunityIcons",
    iconName: "clipboard-multiple-outline"
  },
  {
    uuid: "5fd2c302-934c-4721-9dab-e7de1bd7492c",
    iconSet: "MaterialCommunityIcons",
    iconName: "clipboard-outline"
  },
  {
    uuid: "f4b0d06d-0560-418e-bd52-0441a29feea5",
    iconSet: "MaterialCommunityIcons",
    iconName: "clipboard-play"
  },
  {
    uuid: "c10bfdca-5e2f-4624-aa5c-4b142198d454",
    iconSet: "MaterialCommunityIcons",
    iconName: "clipboard-play-multiple"
  },
  {
    uuid: "9b9d91f5-1318-4790-90aa-04c4f8ac7d94",
    iconSet: "MaterialCommunityIcons",
    iconName: "clipboard-play-multiple-outline"
  },
  {
    uuid: "98fef562-02f0-460f-8ca6-a243c3cbc8bb",
    iconSet: "MaterialCommunityIcons",
    iconName: "clipboard-play-outline"
  },
  {
    uuid: "da48e7d9-8bf6-407c-9a73-9897cb256f13",
    iconSet: "MaterialCommunityIcons",
    iconName: "clipboard-plus"
  },
  {
    uuid: "0b72bd51-9848-423d-a9d5-4d2fe66a4e76",
    iconSet: "MaterialCommunityIcons",
    iconName: "clipboard-plus-outline"
  },
  {
    uuid: "2eddda41-5945-484a-8d06-687407556079",
    iconSet: "MaterialCommunityIcons",
    iconName: "clipboard-pulse"
  },
  {
    uuid: "3ed663c5-b140-4623-9da5-497574ff58b4",
    iconSet: "MaterialCommunityIcons",
    iconName: "clipboard-pulse-outline"
  },
  {
    uuid: "11d9d2f4-f495-42fb-9ce1-dfefb1662fe8",
    iconSet: "MaterialCommunityIcons",
    iconName: "clipboard-text"
  },
  {
    uuid: "c4239def-22c0-4451-b36f-fff53528e5de",
    iconSet: "MaterialCommunityIcons",
    iconName: "clipboard-text-multiple"
  },
  {
    uuid: "003344fb-3d7e-44c5-ae0f-15d28b586c39",
    iconSet: "MaterialCommunityIcons",
    iconName: "clipboard-text-multiple-outline"
  },
  {
    uuid: "39d61d9b-dcde-475b-a7de-5f3d07fec82f",
    iconSet: "MaterialCommunityIcons",
    iconName: "clipboard-text-outline"
  },
  {
    uuid: "c1f3ea9b-add4-43c4-8f35-603760efe966",
    iconSet: "MaterialCommunityIcons",
    iconName: "clipboard-text-play"
  },
  {
    uuid: "25be2183-31f8-42a6-949a-35378b85fd22",
    iconSet: "MaterialCommunityIcons",
    iconName: "clipboard-text-play-outline"
  },
  {
    uuid: "73085a2c-610b-4b1a-872c-4a0c75dc382c",
    iconSet: "MaterialCommunityIcons",
    iconName: "clippy"
  },
  {
    uuid: "b85d96f3-6d4b-4f11-9a22-a4aff73b027a",
    iconSet: "MaterialCommunityIcons",
    iconName: "clock"
  },
  {
    uuid: "67cd8850-faef-4371-87bf-f38a8a0843a5",
    iconSet: "MaterialCommunityIcons",
    iconName: "clock-alert"
  },
  {
    uuid: "a4a5afbc-d169-4c31-af70-5d2bb0396a21",
    iconSet: "MaterialCommunityIcons",
    iconName: "clock-alert-outline"
  },
  {
    uuid: "011d87a2-f130-4acf-89ae-88105eda6192",
    iconSet: "MaterialCommunityIcons",
    iconName: "clock-check"
  },
  {
    uuid: "d4faaef7-305e-4748-a4b1-f98f425a2326",
    iconSet: "MaterialCommunityIcons",
    iconName: "clock-check-outline"
  },
  {
    uuid: "21dce6ca-654a-464f-a020-ee40ec7be2cd",
    iconSet: "MaterialCommunityIcons",
    iconName: "clock-digital"
  },
  {
    uuid: "7f5d4209-83fe-4509-ae07-3117f218d36f",
    iconSet: "MaterialCommunityIcons",
    iconName: "clock-end"
  },
  {
    uuid: "87905f9c-9072-4f32-8574-7aec104758a1",
    iconSet: "MaterialCommunityIcons",
    iconName: "clock-fast"
  },
  {
    uuid: "762f64b6-d3ac-4bc6-b5f3-6c173b323aaf",
    iconSet: "MaterialCommunityIcons",
    iconName: "clock-in"
  },
  {
    uuid: "839e8385-d855-4c30-98cf-b0e4ce89898d",
    iconSet: "MaterialCommunityIcons",
    iconName: "clock-out"
  },
  {
    uuid: "5b399710-a106-4f53-9dd7-08e2c4d75bc5",
    iconSet: "MaterialCommunityIcons",
    iconName: "clock-outline"
  },
  {
    uuid: "5bcf5946-bea2-4d60-9123-eeaa4887b9fc",
    iconSet: "MaterialCommunityIcons",
    iconName: "clock-start"
  },
  {
    uuid: "6ab15afe-ecc6-4d89-9503-abe066da8bee",
    iconSet: "MaterialCommunityIcons",
    iconName: "clock-time-eight"
  },
  {
    uuid: "437aa24f-4109-4029-ad68-b93d61fd2f91",
    iconSet: "MaterialCommunityIcons",
    iconName: "clock-time-eight-outline"
  },
  {
    uuid: "964b898a-52c3-43d8-91e9-a8b5d837dc4c",
    iconSet: "MaterialCommunityIcons",
    iconName: "clock-time-eleven"
  },
  {
    uuid: "303594e3-f8f7-4ac6-a189-113e9ef17683",
    iconSet: "MaterialCommunityIcons",
    iconName: "clock-time-eleven-outline"
  },
  {
    uuid: "cf1db026-aa85-43a3-a0c3-7720f6dc970d",
    iconSet: "MaterialCommunityIcons",
    iconName: "clock-time-five"
  },
  {
    uuid: "850771d0-4819-4668-ac3c-5ec253fd5eff",
    iconSet: "MaterialCommunityIcons",
    iconName: "clock-time-five-outline"
  },
  {
    uuid: "91bc22b9-4573-4eba-9b7e-23df04139238",
    iconSet: "MaterialCommunityIcons",
    iconName: "clock-time-four"
  },
  {
    uuid: "0796e744-2dd6-4c2c-92dd-08a984568c19",
    iconSet: "MaterialCommunityIcons",
    iconName: "clock-time-four-outline"
  },
  {
    uuid: "df1e7149-aa5e-4e60-b9ff-a6cd271441ec",
    iconSet: "MaterialCommunityIcons",
    iconName: "clock-time-nine"
  },
  {
    uuid: "ed04f679-c7cb-498a-972a-880ac16b0331",
    iconSet: "MaterialCommunityIcons",
    iconName: "clock-time-nine-outline"
  },
  {
    uuid: "48d7bf51-dad0-4337-9f8f-10e36386eb1e",
    iconSet: "MaterialCommunityIcons",
    iconName: "clock-time-one"
  },
  {
    uuid: "c3ee7278-93b8-433d-a266-c4b62c6dbb66",
    iconSet: "MaterialCommunityIcons",
    iconName: "clock-time-one-outline"
  },
  {
    uuid: "c5bb265b-98d1-48c4-97c7-9262061398fb",
    iconSet: "MaterialCommunityIcons",
    iconName: "clock-time-seven"
  },
  {
    uuid: "7e62bf0d-8956-471f-84d9-ffea84acfab9",
    iconSet: "MaterialCommunityIcons",
    iconName: "clock-time-seven-outline"
  },
  {
    uuid: "61a3e458-6a84-4f17-a4ed-72d5d3cfe507",
    iconSet: "MaterialCommunityIcons",
    iconName: "clock-time-six"
  },
  {
    uuid: "e302f88a-c654-4f91-867f-9e91cc11562b",
    iconSet: "MaterialCommunityIcons",
    iconName: "clock-time-six-outline"
  },
  {
    uuid: "8acb2e8b-ce88-4a4a-b832-919c10fde00a",
    iconSet: "MaterialCommunityIcons",
    iconName: "clock-time-ten"
  },
  {
    uuid: "95329153-8227-41ab-ae93-9f96eda93606",
    iconSet: "MaterialCommunityIcons",
    iconName: "clock-time-ten-outline"
  },
  {
    uuid: "c57cc072-c5bb-4f69-83ef-be3bf53a3ce5",
    iconSet: "MaterialCommunityIcons",
    iconName: "clock-time-three"
  },
  {
    uuid: "10fb662d-5f2f-45db-aa87-81d8e677af1b",
    iconSet: "MaterialCommunityIcons",
    iconName: "clock-time-three-outline"
  },
  {
    uuid: "cda317ab-8988-4365-8f9e-257514920a5d",
    iconSet: "MaterialCommunityIcons",
    iconName: "clock-time-twelve"
  },
  {
    uuid: "20fcaa4d-fe36-4412-8df7-b407c6e14cdd",
    iconSet: "MaterialCommunityIcons",
    iconName: "clock-time-twelve-outline"
  },
  {
    uuid: "1fe239ac-2f3c-4648-a6ff-7b68495bc729",
    iconSet: "MaterialCommunityIcons",
    iconName: "clock-time-two"
  },
  {
    uuid: "380c4526-deb1-4aed-a15d-f0c0d3c0c294",
    iconSet: "MaterialCommunityIcons",
    iconName: "clock-time-two-outline"
  },
  {
    uuid: "b084dd48-b870-458f-9967-800d98960fab",
    iconSet: "MaterialCommunityIcons",
    iconName: "close"
  },
  {
    uuid: "a3036714-2e0a-4334-8183-11777cf6a823",
    iconSet: "MaterialCommunityIcons",
    iconName: "close-box"
  },
  {
    uuid: "d57e63d6-ea8b-40f2-a4da-7bfcb37ec4d3",
    iconSet: "MaterialCommunityIcons",
    iconName: "close-box-multiple"
  },
  {
    uuid: "6b4d9bea-eecb-40e4-b483-84057170b27f",
    iconSet: "MaterialCommunityIcons",
    iconName: "close-box-multiple-outline"
  },
  {
    uuid: "eddb2e34-7e90-46a4-8095-5f82cc430a20",
    iconSet: "MaterialCommunityIcons",
    iconName: "close-box-outline"
  },
  {
    uuid: "c0dda730-1b43-4543-a427-38673be0d3a0",
    iconSet: "MaterialCommunityIcons",
    iconName: "close-circle"
  },
  {
    uuid: "9604add6-486b-49bf-b483-63d70730b61a",
    iconSet: "MaterialCommunityIcons",
    iconName: "close-circle-multiple"
  },
  {
    uuid: "1ce07e80-683d-4ca3-be9f-cea8e349fd81",
    iconSet: "MaterialCommunityIcons",
    iconName: "close-circle-multiple-outline"
  },
  {
    uuid: "c10a5927-71cc-4079-bed9-5f0516fc6a7d",
    iconSet: "MaterialCommunityIcons",
    iconName: "close-circle-outline"
  },
  {
    uuid: "ca88ea48-c66d-4358-a7ff-b7cbd7664c5c",
    iconSet: "MaterialCommunityIcons",
    iconName: "close-network"
  },
  {
    uuid: "3ba580e4-2db7-4cdb-93f9-5cbea78f2df1",
    iconSet: "MaterialCommunityIcons",
    iconName: "close-network-outline"
  },
  {
    uuid: "441ce7d9-ddb0-4165-993e-cb34b19504c1",
    iconSet: "MaterialCommunityIcons",
    iconName: "close-octagon"
  },
  {
    uuid: "f3829aad-f0a3-4262-8292-a97e546723b1",
    iconSet: "MaterialCommunityIcons",
    iconName: "close-octagon-outline"
  },
  {
    uuid: "1bba678a-e51e-45c7-bb81-4f2df705eb93",
    iconSet: "MaterialCommunityIcons",
    iconName: "close-outline"
  },
  {
    uuid: "465c6fc5-0619-43b7-83c7-6b481dbf9216",
    iconSet: "MaterialCommunityIcons",
    iconName: "close-thick"
  },
  {
    uuid: "77f1b20d-8c0b-460f-81ef-a19b6f8ce772",
    iconSet: "MaterialCommunityIcons",
    iconName: "closed-caption"
  },
  {
    uuid: "a0dbfb1e-b178-4df0-be66-194832559078",
    iconSet: "MaterialCommunityIcons",
    iconName: "closed-caption-outline"
  },
  {
    uuid: "ad282327-a23d-47ed-8957-a49d656b4818",
    iconSet: "MaterialCommunityIcons",
    iconName: "cloud"
  },
  {
    uuid: "f61edab8-40e2-45db-ab8d-de867250be49",
    iconSet: "MaterialCommunityIcons",
    iconName: "cloud-alert"
  },
  {
    uuid: "5565d288-c4c0-4a2b-b05c-8a57095ebbca",
    iconSet: "MaterialCommunityIcons",
    iconName: "cloud-braces"
  },
  {
    uuid: "fd625312-465d-4a87-ad90-cd2cc109a610",
    iconSet: "MaterialCommunityIcons",
    iconName: "cloud-check"
  },
  {
    uuid: "6e61b614-8902-414a-9380-a4d9fbacd498",
    iconSet: "MaterialCommunityIcons",
    iconName: "cloud-check-outline"
  },
  {
    uuid: "018daf21-b84c-4724-a6e4-86ad78190628",
    iconSet: "MaterialCommunityIcons",
    iconName: "cloud-circle"
  },
  {
    uuid: "fd95c7a7-e2b8-49a4-8a62-80d5a10f75b5",
    iconSet: "MaterialCommunityIcons",
    iconName: "cloud-download"
  },
  {
    uuid: "70b238aa-6bee-4f44-8297-953d6731f031",
    iconSet: "MaterialCommunityIcons",
    iconName: "cloud-download-outline"
  },
  {
    uuid: "6b300902-bd00-488f-b1b6-82e85ef9ec39",
    iconSet: "MaterialCommunityIcons",
    iconName: "cloud-lock"
  },
  {
    uuid: "14d8dcd6-879d-4e57-970e-1459b8acfa80",
    iconSet: "MaterialCommunityIcons",
    iconName: "cloud-lock-outline"
  },
  {
    uuid: "5b5d998c-6044-48f8-8bb3-59fe30f9ed37",
    iconSet: "MaterialCommunityIcons",
    iconName: "cloud-off-outline"
  },
  {
    uuid: "812fc925-199f-4fd2-9303-a3cd72f37734",
    iconSet: "MaterialCommunityIcons",
    iconName: "cloud-outline"
  },
  {
    uuid: "aef3fb7d-af45-4844-b7b6-8a1c912e4869",
    iconSet: "MaterialCommunityIcons",
    iconName: "cloud-print"
  },
  {
    uuid: "1b891267-3223-47cb-b767-c470dc91ca57",
    iconSet: "MaterialCommunityIcons",
    iconName: "cloud-print-outline"
  },
  {
    uuid: "f04b92f1-7154-481f-98c5-4cff11b92576",
    iconSet: "MaterialCommunityIcons",
    iconName: "cloud-question"
  },
  {
    uuid: "7b275a03-f8ae-427d-9759-545b83030e69",
    iconSet: "MaterialCommunityIcons",
    iconName: "cloud-refresh"
  },
  {
    uuid: "bb6163fc-e1ac-4998-98b9-67ae402cba30",
    iconSet: "MaterialCommunityIcons",
    iconName: "cloud-search"
  },
  {
    uuid: "b34bc866-0dd3-449b-a42b-94b2cb069d7e",
    iconSet: "MaterialCommunityIcons",
    iconName: "cloud-search-outline"
  },
  {
    uuid: "e8742516-e2e1-4ab9-98c1-2c17b4d9f6b2",
    iconSet: "MaterialCommunityIcons",
    iconName: "cloud-sync"
  },
  {
    uuid: "7392cf5f-46a6-41e7-8feb-ecb1ccaaae94",
    iconSet: "MaterialCommunityIcons",
    iconName: "cloud-sync-outline"
  },
  {
    uuid: "dd5bf29e-607d-4eb8-8ed2-6c80b61c60ca",
    iconSet: "MaterialCommunityIcons",
    iconName: "cloud-tags"
  },
  {
    uuid: "f7cb3131-a4b2-4f73-b70c-a45afbdb3f57",
    iconSet: "MaterialCommunityIcons",
    iconName: "cloud-upload"
  },
  {
    uuid: "f011d0d6-2411-4249-a55f-ac0f64dd1fd8",
    iconSet: "MaterialCommunityIcons",
    iconName: "cloud-upload-outline"
  },
  {
    uuid: "73613b38-6242-4609-a538-01876f7e710b",
    iconSet: "MaterialCommunityIcons",
    iconName: "clover"
  },
  {
    uuid: "80f1fa2c-670f-4138-9511-ee1b1a128b11",
    iconSet: "MaterialCommunityIcons",
    iconName: "coach-lamp"
  },
  {
    uuid: "c8a38d3b-f358-47ea-8251-78a222f26866",
    iconSet: "MaterialCommunityIcons",
    iconName: "coat-rack"
  },
  {
    uuid: "6b551819-dbdd-49b3-9f45-64a1ef2de5c4",
    iconSet: "MaterialCommunityIcons",
    iconName: "code-array"
  },
  {
    uuid: "d4ccf843-9c95-4f6c-87b6-37aa8df2c807",
    iconSet: "MaterialCommunityIcons",
    iconName: "code-braces"
  },
  {
    uuid: "c51fa486-f5b1-450d-9453-a3c3b78ec80c",
    iconSet: "MaterialCommunityIcons",
    iconName: "code-braces-box"
  },
  {
    uuid: "d1568434-f77f-4ae0-81f5-a3319b1c9888",
    iconSet: "MaterialCommunityIcons",
    iconName: "code-brackets"
  },
  {
    uuid: "8bbf9194-c77e-4250-b456-55f5694b0450",
    iconSet: "MaterialCommunityIcons",
    iconName: "code-equal"
  },
  {
    uuid: "a8f61afb-f760-4e8c-be92-3ca595783575",
    iconSet: "MaterialCommunityIcons",
    iconName: "code-greater-than"
  },
  {
    uuid: "80fa1e57-5595-42e6-9a6d-f33b1e9539c0",
    iconSet: "MaterialCommunityIcons",
    iconName: "code-greater-than-or-equal"
  },
  {
    uuid: "feae9308-b6ef-4884-a5aa-a1938b9bef8f",
    iconSet: "MaterialCommunityIcons",
    iconName: "code-json"
  },
  {
    uuid: "14ea5d0f-01e4-468b-a4f3-44835e08db7e",
    iconSet: "MaterialCommunityIcons",
    iconName: "code-less-than"
  },
  {
    uuid: "83f18e5e-2054-444a-ba5c-f644d9eeb9f7",
    iconSet: "MaterialCommunityIcons",
    iconName: "code-less-than-or-equal"
  },
  {
    uuid: "dbfaad0b-c035-4f34-b7d3-08b9f34653bd",
    iconSet: "MaterialCommunityIcons",
    iconName: "code-not-equal"
  },
  {
    uuid: "370c5d50-2a5f-4ed2-9e77-0361df8ab101",
    iconSet: "MaterialCommunityIcons",
    iconName: "code-not-equal-variant"
  },
  {
    uuid: "b0c35776-5711-4ff5-b8b8-3a7ec61c411c",
    iconSet: "MaterialCommunityIcons",
    iconName: "code-parentheses"
  },
  {
    uuid: "7fd31bcb-5806-43c6-abcb-083ea4563ced",
    iconSet: "MaterialCommunityIcons",
    iconName: "code-parentheses-box"
  },
  {
    uuid: "0718699b-efd8-4687-8413-5e75c1dde6a8",
    iconSet: "MaterialCommunityIcons",
    iconName: "code-string"
  },
  {
    uuid: "28a3c3b9-f316-4a3e-a21a-0022bd91a0ca",
    iconSet: "MaterialCommunityIcons",
    iconName: "code-tags"
  },
  {
    uuid: "79990feb-778c-40ca-8755-d30ae3898513",
    iconSet: "MaterialCommunityIcons",
    iconName: "code-tags-check"
  },
  {
    uuid: "e2997a96-e255-4cfd-8017-0980efe8b9c1",
    iconSet: "MaterialCommunityIcons",
    iconName: "codepen"
  },
  {
    uuid: "96d09c79-4558-4ebf-a4a2-19d084c5cd73",
    iconSet: "MaterialCommunityIcons",
    iconName: "coffee"
  },
  {
    uuid: "398214d9-a14b-4572-9e39-05f738280150",
    iconSet: "MaterialCommunityIcons",
    iconName: "coffee-maker"
  },
  {
    uuid: "ef14208f-6bda-4997-ad00-06f594356e18",
    iconSet: "MaterialCommunityIcons",
    iconName: "coffee-off"
  },
  {
    uuid: "b27ad581-f299-40c5-a514-39d92b396355",
    iconSet: "MaterialCommunityIcons",
    iconName: "coffee-off-outline"
  },
  {
    uuid: "1289c331-6154-4f9f-add4-8927cdfca83c",
    iconSet: "MaterialCommunityIcons",
    iconName: "coffee-outline"
  },
  {
    uuid: "ef61b999-d947-40c0-800f-1647144cf103",
    iconSet: "MaterialCommunityIcons",
    iconName: "coffee-to-go"
  },
  {
    uuid: "891ec3e9-6789-4fdb-86ca-49d44295fd07",
    iconSet: "MaterialCommunityIcons",
    iconName: "coffee-to-go-outline"
  },
  {
    uuid: "f7bc17a9-0f6f-4d7d-aa2a-4f56ef82409d",
    iconSet: "MaterialCommunityIcons",
    iconName: "coffin"
  },
  {
    uuid: "cf2ed9d9-f73c-4e4c-b55e-c459b79f41ac",
    iconSet: "MaterialCommunityIcons",
    iconName: "cog"
  },
  {
    uuid: "a2faa3d8-b82e-40e1-a4cb-6115a89bef90",
    iconSet: "MaterialCommunityIcons",
    iconName: "cog-box"
  },
  {
    uuid: "aadd0ea1-fedc-443d-aba0-6ad18c0953f9",
    iconSet: "MaterialCommunityIcons",
    iconName: "cog-clockwise"
  },
  {
    uuid: "558a981b-6d4d-4abd-a5e8-a1876c27731a",
    iconSet: "MaterialCommunityIcons",
    iconName: "cog-counterclockwise"
  },
  {
    uuid: "ddbb1eb1-092d-4ebd-8045-db39d863a1af",
    iconSet: "MaterialCommunityIcons",
    iconName: "cog-off"
  },
  {
    uuid: "57918b1e-e2cb-4837-9196-5a4f5aee446a",
    iconSet: "MaterialCommunityIcons",
    iconName: "cog-off-outline"
  },
  {
    uuid: "fe8a791f-e5c6-4938-b04c-971e6f752a4d",
    iconSet: "MaterialCommunityIcons",
    iconName: "cog-outline"
  },
  {
    uuid: "955b3272-a234-4cc9-b00c-2437a1d9f56c",
    iconSet: "MaterialCommunityIcons",
    iconName: "cog-refresh"
  },
  {
    uuid: "cef570c6-a974-4166-8251-e18520797609",
    iconSet: "MaterialCommunityIcons",
    iconName: "cog-refresh-outline"
  },
  {
    uuid: "a902b131-cd42-4153-ab1c-b2be1bd4741f",
    iconSet: "MaterialCommunityIcons",
    iconName: "cog-sync"
  },
  {
    uuid: "625ea726-40f5-4205-a389-14a0ff050766",
    iconSet: "MaterialCommunityIcons",
    iconName: "cog-sync-outline"
  },
  {
    uuid: "1f9d74b3-90d7-48ad-b54d-4bb82b4a3777",
    iconSet: "MaterialCommunityIcons",
    iconName: "cog-transfer"
  },
  {
    uuid: "e227e643-72eb-4828-9e02-7216e609101e",
    iconSet: "MaterialCommunityIcons",
    iconName: "cog-transfer-outline"
  },
  {
    uuid: "0f1c0d9c-9db3-4f66-acbe-f6565c6cc0c1",
    iconSet: "MaterialCommunityIcons",
    iconName: "cogs"
  },
  {
    uuid: "98bbd7d6-8483-4988-a74f-2d11b9719307",
    iconSet: "MaterialCommunityIcons",
    iconName: "collage"
  },
  {
    uuid: "531946a6-aba2-40d0-9b5d-44417f4b461e",
    iconSet: "MaterialCommunityIcons",
    iconName: "collapse-all"
  },
  {
    uuid: "1c42858d-53b9-43b2-9c5f-f5657d7e4d17",
    iconSet: "MaterialCommunityIcons",
    iconName: "collapse-all-outline"
  },
  {
    uuid: "fcd78536-458f-4b89-9cc3-b8a3306e0dd2",
    iconSet: "MaterialCommunityIcons",
    iconName: "color-helper"
  },
  {
    uuid: "4769f285-092b-41a5-aaf3-6ff99a8f1c75",
    iconSet: "MaterialCommunityIcons",
    iconName: "comma"
  },
  {
    uuid: "885dfbb6-21c3-4b94-b452-04582f8e95c0",
    iconSet: "MaterialCommunityIcons",
    iconName: "comma-box"
  },
  {
    uuid: "45e0c922-cc26-4551-b102-169d4969e2e5",
    iconSet: "MaterialCommunityIcons",
    iconName: "comma-box-outline"
  },
  {
    uuid: "0185e74f-e889-426e-921b-dfedd45db13d",
    iconSet: "MaterialCommunityIcons",
    iconName: "comma-circle"
  },
  {
    uuid: "f758c46a-aa31-4004-bfd4-f793883dd269",
    iconSet: "MaterialCommunityIcons",
    iconName: "comma-circle-outline"
  },
  {
    uuid: "2a7e508c-f5d6-48ed-a9a3-6b0ea12a547c",
    iconSet: "MaterialCommunityIcons",
    iconName: "comment"
  },
  {
    uuid: "f3b90d05-69c9-47fc-84cd-c00bc8b9dbd0",
    iconSet: "MaterialCommunityIcons",
    iconName: "comment-account"
  },
  {
    uuid: "beaabc71-6a17-496a-9e4f-dfdc587306f9",
    iconSet: "MaterialCommunityIcons",
    iconName: "comment-account-outline"
  },
  {
    uuid: "aa7f3572-bd7c-4bcb-a19d-98d3cc7023fb",
    iconSet: "MaterialCommunityIcons",
    iconName: "comment-alert"
  },
  {
    uuid: "d6780a6c-8a61-4caa-a0be-90fc498c9958",
    iconSet: "MaterialCommunityIcons",
    iconName: "comment-alert-outline"
  },
  {
    uuid: "964abb8e-4b44-44e4-ba90-70331b5e8766",
    iconSet: "MaterialCommunityIcons",
    iconName: "comment-arrow-left"
  },
  {
    uuid: "b163a347-fd93-4a27-8831-fc060a5ee0b2",
    iconSet: "MaterialCommunityIcons",
    iconName: "comment-arrow-left-outline"
  },
  {
    uuid: "8c831cd0-8c71-49cd-856a-5e53bae0a0be",
    iconSet: "MaterialCommunityIcons",
    iconName: "comment-arrow-right"
  },
  {
    uuid: "c65902d1-8b2c-48d4-9dc6-0c748060286d",
    iconSet: "MaterialCommunityIcons",
    iconName: "comment-arrow-right-outline"
  },
  {
    uuid: "adbb4da5-bcc6-44d9-bd20-f23730398f68",
    iconSet: "MaterialCommunityIcons",
    iconName: "comment-check"
  },
  {
    uuid: "4039c8a1-160d-4da4-aebb-b4af3075d187",
    iconSet: "MaterialCommunityIcons",
    iconName: "comment-check-outline"
  },
  {
    uuid: "f90674fa-4951-48ee-ba76-db1d3e2df28c",
    iconSet: "MaterialCommunityIcons",
    iconName: "comment-edit"
  },
  {
    uuid: "79d82fe5-ec38-4359-ba69-1e80fb14e349",
    iconSet: "MaterialCommunityIcons",
    iconName: "comment-edit-outline"
  },
  {
    uuid: "089da16c-0526-41d0-b3fa-a894732ab31e",
    iconSet: "MaterialCommunityIcons",
    iconName: "comment-eye"
  },
  {
    uuid: "98377c0e-00d3-4910-9187-681e0f3629ff",
    iconSet: "MaterialCommunityIcons",
    iconName: "comment-eye-outline"
  },
  {
    uuid: "49e2e2e7-2e3f-44cc-85fd-a40800daad6c",
    iconSet: "MaterialCommunityIcons",
    iconName: "comment-multiple"
  },
  {
    uuid: "7e4b9a14-3774-4fff-897f-2f5bd88d6a06",
    iconSet: "MaterialCommunityIcons",
    iconName: "comment-multiple-outline"
  },
  {
    uuid: "6090c90d-f3c6-422c-8da7-2724939c3070",
    iconSet: "MaterialCommunityIcons",
    iconName: "comment-outline"
  },
  {
    uuid: "c5ee1122-1830-47c4-93da-5b1a3344105d",
    iconSet: "MaterialCommunityIcons",
    iconName: "comment-plus"
  },
  {
    uuid: "9aae7ebf-11c7-410d-9fbc-20e92d4696d2",
    iconSet: "MaterialCommunityIcons",
    iconName: "comment-plus-outline"
  },
  {
    uuid: "5d66dae1-1cb8-4d68-b877-f56665ae9d4d",
    iconSet: "MaterialCommunityIcons",
    iconName: "comment-processing"
  },
  {
    uuid: "903dbc98-c989-41b7-92bd-275751a2731b",
    iconSet: "MaterialCommunityIcons",
    iconName: "comment-processing-outline"
  },
  {
    uuid: "9a127695-e99d-4034-9fa6-584df0bfcd2d",
    iconSet: "MaterialCommunityIcons",
    iconName: "comment-question"
  },
  {
    uuid: "4ea8097f-2260-4795-b561-b926708037c0",
    iconSet: "MaterialCommunityIcons",
    iconName: "comment-question-outline"
  },
  {
    uuid: "2d6a971b-9678-493f-8dca-206a4fc303f9",
    iconSet: "MaterialCommunityIcons",
    iconName: "comment-quote"
  },
  {
    uuid: "5daa7410-4753-4882-80e4-7f92cb1243c9",
    iconSet: "MaterialCommunityIcons",
    iconName: "comment-quote-outline"
  },
  {
    uuid: "6a9a5aab-c96d-402a-a7f5-64a283edb79b",
    iconSet: "MaterialCommunityIcons",
    iconName: "comment-remove"
  },
  {
    uuid: "7e1fadfd-768f-471e-9c29-d4e73018c990",
    iconSet: "MaterialCommunityIcons",
    iconName: "comment-remove-outline"
  },
  {
    uuid: "d983375a-7afb-4e8a-8c61-9bb13758774c",
    iconSet: "MaterialCommunityIcons",
    iconName: "comment-search"
  },
  {
    uuid: "54e56ef2-897a-4e6c-a81d-8a481e122bfd",
    iconSet: "MaterialCommunityIcons",
    iconName: "comment-search-outline"
  },
  {
    uuid: "7cda6154-65ae-4124-a223-04e738f47999",
    iconSet: "MaterialCommunityIcons",
    iconName: "comment-text"
  },
  {
    uuid: "aef003ca-54b4-4551-8b2f-b15f19bf819b",
    iconSet: "MaterialCommunityIcons",
    iconName: "comment-text-multiple"
  },
  {
    uuid: "2da4d926-1d28-4ab9-a04a-812f908f312e",
    iconSet: "MaterialCommunityIcons",
    iconName: "comment-text-multiple-outline"
  },
  {
    uuid: "22b47819-117b-479c-9143-fbd9b0342ef1",
    iconSet: "MaterialCommunityIcons",
    iconName: "comment-text-outline"
  },
  {
    uuid: "3263abf2-f0ec-4eb4-bbb0-9a55ba712d6a",
    iconSet: "MaterialCommunityIcons",
    iconName: "compare"
  },
  {
    uuid: "3e712dab-ff1b-436e-9684-6a56771ec60a",
    iconSet: "MaterialCommunityIcons",
    iconName: "compare-horizontal"
  },
  {
    uuid: "b1cd78c1-933f-4ca6-a4ac-276375cb04a5",
    iconSet: "MaterialCommunityIcons",
    iconName: "compare-vertical"
  },
  {
    uuid: "4a7d872a-a407-437b-83f7-70d76dcc478e",
    iconSet: "MaterialCommunityIcons",
    iconName: "compass"
  },
  {
    uuid: "966e45c9-980c-49f6-abaa-27fa4afa9518",
    iconSet: "MaterialCommunityIcons",
    iconName: "compass-off"
  },
  {
    uuid: "ff8a90ad-fcf6-41d2-8f05-7dd8599baa26",
    iconSet: "MaterialCommunityIcons",
    iconName: "compass-off-outline"
  },
  {
    uuid: "f833f167-016d-446d-9ec3-23e2a06ac9e7",
    iconSet: "MaterialCommunityIcons",
    iconName: "compass-outline"
  },
  {
    uuid: "8a812255-74db-4d22-9922-26572f4523d6",
    iconSet: "MaterialCommunityIcons",
    iconName: "compass-rose"
  },
  {
    uuid: "6c514b22-903b-4718-a6fc-6c9c3b9217fe",
    iconSet: "MaterialCommunityIcons",
    iconName: "concourse-ci"
  },
  {
    uuid: "a98cfd8d-5634-4c12-8776-a815ef23dff1",
    iconSet: "MaterialCommunityIcons",
    iconName: "console"
  },
  {
    uuid: "e8a5bd4f-85dd-472c-80da-5ae169edb3f1",
    iconSet: "MaterialCommunityIcons",
    iconName: "console-line"
  },
  {
    uuid: "40f95a1c-294f-4a37-a9af-964dc5b56897",
    iconSet: "MaterialCommunityIcons",
    iconName: "console-network"
  },
  {
    uuid: "5e87a573-391a-44c2-932e-431d81227b82",
    iconSet: "MaterialCommunityIcons",
    iconName: "console-network-outline"
  },
  {
    uuid: "c86486bb-ba86-4660-90b6-0e718a914360",
    iconSet: "MaterialCommunityIcons",
    iconName: "consolidate"
  },
  {
    uuid: "684ee650-9eca-475c-a961-4200f9e2cc75",
    iconSet: "MaterialCommunityIcons",
    iconName: "contactless-payment"
  },
  {
    uuid: "0411ba56-2a47-481b-9503-2fdaf8ee6c2d",
    iconSet: "MaterialCommunityIcons",
    iconName: "contactless-payment-circle"
  },
  {
    uuid: "d7d3940c-daff-4019-ace7-125f2c2b4646",
    iconSet: "MaterialCommunityIcons",
    iconName: "contactless-payment-circle-outline"
  },
  {
    uuid: "1f6b94d7-19ca-4299-a516-f0793d71363c",
    iconSet: "MaterialCommunityIcons",
    iconName: "contacts"
  },
  {
    uuid: "747fc799-96ff-4508-8089-f42ee963b276",
    iconSet: "MaterialCommunityIcons",
    iconName: "contacts-outline"
  },
  {
    uuid: "58fb6fac-24b2-4de1-b19d-1d7fd4e73639",
    iconSet: "MaterialCommunityIcons",
    iconName: "contain"
  },
  {
    uuid: "11a9e674-4581-4679-8849-2494b1b4d167",
    iconSet: "MaterialCommunityIcons",
    iconName: "contain-end"
  },
  {
    uuid: "7375d085-18eb-4fc1-84bf-459dac2b72d7",
    iconSet: "MaterialCommunityIcons",
    iconName: "contain-start"
  },
  {
    uuid: "7d0481e7-5783-4c2f-8901-91817395a723",
    iconSet: "MaterialCommunityIcons",
    iconName: "content-copy"
  },
  {
    uuid: "7365db6a-a0d0-4729-a4a6-629cbd1a60c8",
    iconSet: "MaterialCommunityIcons",
    iconName: "content-cut"
  },
  {
    uuid: "4eafbfb4-625d-4706-a438-52506863ea87",
    iconSet: "MaterialCommunityIcons",
    iconName: "content-duplicate"
  },
  {
    uuid: "d9995074-1e2f-48fb-8bf8-7859fb9ff5f6",
    iconSet: "MaterialCommunityIcons",
    iconName: "content-paste"
  },
  {
    uuid: "649e32c2-d30c-4555-86b8-80284b94d20d",
    iconSet: "MaterialCommunityIcons",
    iconName: "content-save"
  },
  {
    uuid: "11aade48-11f1-49c0-913b-3e3fdde3f734",
    iconSet: "MaterialCommunityIcons",
    iconName: "content-save-alert"
  },
  {
    uuid: "2968785d-b1fb-4b52-bcd0-ac87ce78ff0b",
    iconSet: "MaterialCommunityIcons",
    iconName: "content-save-alert-outline"
  },
  {
    uuid: "221d3e61-ca22-4394-bc96-00c649359910",
    iconSet: "MaterialCommunityIcons",
    iconName: "content-save-all"
  },
  {
    uuid: "f1d8bc84-6986-4cf8-bd86-ead83c89a662",
    iconSet: "MaterialCommunityIcons",
    iconName: "content-save-all-outline"
  },
  {
    uuid: "a16727c1-b8ac-4620-a245-d7ba9ab53cfc",
    iconSet: "MaterialCommunityIcons",
    iconName: "content-save-cog"
  },
  {
    uuid: "51ba327a-f1bd-4143-b8e3-b8ae30a84623",
    iconSet: "MaterialCommunityIcons",
    iconName: "content-save-cog-outline"
  },
  {
    uuid: "68c4190c-73a6-47cc-8b58-60405c1c7aca",
    iconSet: "MaterialCommunityIcons",
    iconName: "content-save-edit"
  },
  {
    uuid: "b1159409-3e53-4652-8973-9b772fb0703d",
    iconSet: "MaterialCommunityIcons",
    iconName: "content-save-edit-outline"
  },
  {
    uuid: "73be536d-fafa-44a7-845c-e05896c25ffe",
    iconSet: "MaterialCommunityIcons",
    iconName: "content-save-move"
  },
  {
    uuid: "6897bd3f-50c8-40ce-b1fc-0890ca15b33c",
    iconSet: "MaterialCommunityIcons",
    iconName: "content-save-move-outline"
  },
  {
    uuid: "e82dd13d-718b-4589-bca6-7ddd29c7dcd9",
    iconSet: "MaterialCommunityIcons",
    iconName: "content-save-outline"
  },
  {
    uuid: "8e334157-9c7b-4d28-a231-182cca04b5e1",
    iconSet: "MaterialCommunityIcons",
    iconName: "content-save-settings"
  },
  {
    uuid: "b4cd1f5c-aaef-4524-a9c0-4080ef09dfb5",
    iconSet: "MaterialCommunityIcons",
    iconName: "content-save-settings-outline"
  },
  {
    uuid: "3c1abf20-1963-43ac-9c14-e27c04b54ee1",
    iconSet: "MaterialCommunityIcons",
    iconName: "contrast"
  },
  {
    uuid: "4f1f4519-3702-4521-bca4-34b03d371038",
    iconSet: "MaterialCommunityIcons",
    iconName: "contrast-box"
  },
  {
    uuid: "96177899-af84-4392-a645-753999228981",
    iconSet: "MaterialCommunityIcons",
    iconName: "contrast-circle"
  },
  {
    uuid: "4a914395-4373-49e3-82f1-a96f1dcc90e6",
    iconSet: "MaterialCommunityIcons",
    iconName: "controller-classic"
  },
  {
    uuid: "424e53e2-8200-46ad-a6e2-c45250318ae3",
    iconSet: "MaterialCommunityIcons",
    iconName: "controller-classic-outline"
  },
  {
    uuid: "d529744a-d853-48bb-aa6e-de76039c5463",
    iconSet: "MaterialCommunityIcons",
    iconName: "cookie"
  },
  {
    uuid: "b9ec6b48-8abf-4bf1-b255-9b281bfa5a1c",
    iconSet: "MaterialCommunityIcons",
    iconName: "coolant-temperature"
  },
  {
    uuid: "095d34c1-c408-44ee-b748-08d7d8860dba",
    iconSet: "MaterialCommunityIcons",
    iconName: "copyright"
  },
  {
    uuid: "05c5bca5-c5b4-4f17-9c6f-5674a31b7e4b",
    iconSet: "MaterialCommunityIcons",
    iconName: "cordova"
  },
  {
    uuid: "ee8384b9-d5b8-4889-980c-95404600f2d3",
    iconSet: "MaterialCommunityIcons",
    iconName: "corn"
  },
  {
    uuid: "46b440ad-095f-4b40-9143-ab6ddbd95d97",
    iconSet: "MaterialCommunityIcons",
    iconName: "corn-off"
  },
  {
    uuid: "9995f291-1665-4b20-a193-848af21f2813",
    iconSet: "MaterialCommunityIcons",
    iconName: "cosine-wave"
  },
  {
    uuid: "9d128acd-9390-4108-8959-07f3b4a0d5c4",
    iconSet: "MaterialCommunityIcons",
    iconName: "counter"
  },
  {
    uuid: "cc28d53e-414c-4a86-a2ca-7b26448d4e2f",
    iconSet: "MaterialCommunityIcons",
    iconName: "cow"
  },
  {
    uuid: "4328d519-5eaf-4fb4-aed3-3e61b62e628f",
    iconSet: "MaterialCommunityIcons",
    iconName: "cpu-32-bit"
  },
  {
    uuid: "e701ce33-0d4b-47c7-bf12-c27627ad7329",
    iconSet: "MaterialCommunityIcons",
    iconName: "cpu-64-bit"
  },
  {
    uuid: "6f752b25-a4d1-43d4-8501-090e987f5b94",
    iconSet: "MaterialCommunityIcons",
    iconName: "crane"
  },
  {
    uuid: "3a73acbd-e9cf-4b83-907d-7f9a56768b64",
    iconSet: "MaterialCommunityIcons",
    iconName: "creation"
  },
  {
    uuid: "d060d93e-5562-4ce4-a71c-d271325bdf5f",
    iconSet: "MaterialCommunityIcons",
    iconName: "creative-commons"
  },
  {
    uuid: "99b72a1f-0ec6-4c33-8938-d443ca937395",
    iconSet: "MaterialCommunityIcons",
    iconName: "credit-card"
  },
  {
    uuid: "b2b6f5b6-78f2-467b-8759-13ca46c3e172",
    iconSet: "MaterialCommunityIcons",
    iconName: "credit-card-check"
  },
  {
    uuid: "cf2e84ad-ce97-4c18-b04b-60b27f9ffe8b",
    iconSet: "MaterialCommunityIcons",
    iconName: "credit-card-check-outline"
  },
  {
    uuid: "7a21410f-6ccb-467a-965d-a6beb41a1bef",
    iconSet: "MaterialCommunityIcons",
    iconName: "credit-card-clock"
  },
  {
    uuid: "f6214e3b-8743-4d39-95cf-e7303b32d9fe",
    iconSet: "MaterialCommunityIcons",
    iconName: "credit-card-clock-outline"
  },
  {
    uuid: "4bc50ee7-c4f8-48fc-8c50-f794238726f7",
    iconSet: "MaterialCommunityIcons",
    iconName: "credit-card-marker"
  },
  {
    uuid: "c737bf48-ed9f-4d99-ada2-63a7055d9ced",
    iconSet: "MaterialCommunityIcons",
    iconName: "credit-card-marker-outline"
  },
  {
    uuid: "2c258e6e-9acf-45c2-a1a1-593b40bb3f3a",
    iconSet: "MaterialCommunityIcons",
    iconName: "credit-card-minus"
  },
  {
    uuid: "4d40828c-6cf6-4ac7-b92b-f3df63eb61e7",
    iconSet: "MaterialCommunityIcons",
    iconName: "credit-card-minus-outline"
  },
  {
    uuid: "f521825f-3f12-468a-bb28-e84a43f82a22",
    iconSet: "MaterialCommunityIcons",
    iconName: "credit-card-multiple"
  },
  {
    uuid: "d932cf00-7e25-4809-8797-20628d5708a3",
    iconSet: "MaterialCommunityIcons",
    iconName: "credit-card-multiple-outline"
  },
  {
    uuid: "1dbd5ef5-056a-4bea-80fb-1f0f8440a2b5",
    iconSet: "MaterialCommunityIcons",
    iconName: "credit-card-off"
  },
  {
    uuid: "616b0f11-47f2-4117-a2c7-4b76368ff569",
    iconSet: "MaterialCommunityIcons",
    iconName: "credit-card-off-outline"
  },
  {
    uuid: "cffdcbc2-da77-409e-afd6-5a3e082d7f2a",
    iconSet: "MaterialCommunityIcons",
    iconName: "credit-card-outline"
  },
  {
    uuid: "508c8cb9-8910-4f2a-8b1d-6e0df869aa48",
    iconSet: "MaterialCommunityIcons",
    iconName: "credit-card-plus"
  },
  {
    uuid: "5b7ca856-bc07-4845-8c9f-d76c56e8b9d4",
    iconSet: "MaterialCommunityIcons",
    iconName: "credit-card-plus-outline"
  },
  {
    uuid: "64d2dcdb-f8b9-49f1-b394-5bfddbbf9463",
    iconSet: "MaterialCommunityIcons",
    iconName: "credit-card-refund"
  },
  {
    uuid: "6db108ab-45ff-4f99-a57b-c8809ab7f035",
    iconSet: "MaterialCommunityIcons",
    iconName: "credit-card-refund-outline"
  },
  {
    uuid: "98ea4a1c-0552-4978-9c07-6c3897947957",
    iconSet: "MaterialCommunityIcons",
    iconName: "credit-card-remove"
  },
  {
    uuid: "f5fad59e-21a5-48da-8721-57e1efcb7418",
    iconSet: "MaterialCommunityIcons",
    iconName: "credit-card-remove-outline"
  },
  {
    uuid: "fafed551-ff80-4af0-ac32-59a14851a617",
    iconSet: "MaterialCommunityIcons",
    iconName: "credit-card-scan"
  },
  {
    uuid: "8f3a2c3f-c0ff-435f-9142-8a808227e6b0",
    iconSet: "MaterialCommunityIcons",
    iconName: "credit-card-scan-outline"
  },
  {
    uuid: "e416d681-7ae4-4ae2-9885-543e8915d0a9",
    iconSet: "MaterialCommunityIcons",
    iconName: "credit-card-settings"
  },
  {
    uuid: "b95516c2-f2b7-45af-9a80-6ed62281bdf1",
    iconSet: "MaterialCommunityIcons",
    iconName: "credit-card-settings-outline"
  },
  {
    uuid: "40cf2c05-2f2e-4cd7-b747-e4b72fbce734",
    iconSet: "MaterialCommunityIcons",
    iconName: "credit-card-wireless"
  },
  {
    uuid: "ac1b3156-b4e6-4866-be5e-e302974d7ee7",
    iconSet: "MaterialCommunityIcons",
    iconName: "credit-card-wireless-off"
  },
  {
    uuid: "48ebe536-ebd9-418f-9368-b4b57de48a8a",
    iconSet: "MaterialCommunityIcons",
    iconName: "credit-card-wireless-off-outline"
  },
  {
    uuid: "f1ee2fed-28e7-4fe1-b504-8c873a71f3b8",
    iconSet: "MaterialCommunityIcons",
    iconName: "credit-card-wireless-outline"
  },
  {
    uuid: "18b471b0-cbe4-4b33-9290-c6525074c215",
    iconSet: "MaterialCommunityIcons",
    iconName: "cricket"
  },
  {
    uuid: "ceb7ded5-b350-4dd7-95eb-8a2ba5806899",
    iconSet: "MaterialCommunityIcons",
    iconName: "crop"
  },
  {
    uuid: "0d8994ab-2d64-4085-a4c6-9c3f9e376c6c",
    iconSet: "MaterialCommunityIcons",
    iconName: "crop-free"
  },
  {
    uuid: "d9263bb9-92fb-4ef4-a764-059c0e05026a",
    iconSet: "MaterialCommunityIcons",
    iconName: "crop-landscape"
  },
  {
    uuid: "0bd231db-3296-4f06-87e3-598a8d27197b",
    iconSet: "MaterialCommunityIcons",
    iconName: "crop-portrait"
  },
  {
    uuid: "7072ce96-99e3-4f4b-ab73-25f7576d07d2",
    iconSet: "MaterialCommunityIcons",
    iconName: "crop-rotate"
  },
  {
    uuid: "70b79c90-eb60-4bd2-bd16-85c27b1cb291",
    iconSet: "MaterialCommunityIcons",
    iconName: "crop-square"
  },
  {
    uuid: "3b227ce7-1bb5-4f8d-8558-0a64cd90f814",
    iconSet: "MaterialCommunityIcons",
    iconName: "crosshairs"
  },
  {
    uuid: "836f659b-b4a2-479a-99fa-730843db41d1",
    iconSet: "MaterialCommunityIcons",
    iconName: "crosshairs-gps"
  },
  {
    uuid: "6d19c7c5-01e4-4649-bc31-689f3f9bf03a",
    iconSet: "MaterialCommunityIcons",
    iconName: "crosshairs-off"
  },
  {
    uuid: "082c8902-d9f2-46ed-9ead-f1e1eede96ae",
    iconSet: "MaterialCommunityIcons",
    iconName: "crosshairs-question"
  },
  {
    uuid: "cfd9a54d-5c41-49c6-b212-0c6df13e917c",
    iconSet: "MaterialCommunityIcons",
    iconName: "crown"
  },
  {
    uuid: "cb1c77b1-47ec-4272-a695-11b928c8530c",
    iconSet: "MaterialCommunityIcons",
    iconName: "crown-outline"
  },
  {
    uuid: "807a4b98-fc9f-49c4-a589-3b188e93750b",
    iconSet: "MaterialCommunityIcons",
    iconName: "cryengine"
  },
  {
    uuid: "34d538a9-a638-4dca-ba0a-308ad56e58bc",
    iconSet: "MaterialCommunityIcons",
    iconName: "crystal-ball"
  },
  {
    uuid: "16c588de-f327-4578-855d-38d8488d4a5a",
    iconSet: "MaterialCommunityIcons",
    iconName: "cube"
  },
  {
    uuid: "01635bc4-eb0c-43f3-a1cd-69f9822bf394",
    iconSet: "MaterialCommunityIcons",
    iconName: "cube-off"
  },
  {
    uuid: "16c0423b-1abc-4c21-b550-37cf122238aa",
    iconSet: "MaterialCommunityIcons",
    iconName: "cube-off-outline"
  },
  {
    uuid: "06d67710-d13d-4dd6-9652-e275f991a8fd",
    iconSet: "MaterialCommunityIcons",
    iconName: "cube-outline"
  },
  {
    uuid: "0fabeeb8-e3ef-4665-8875-918801d2ad7a",
    iconSet: "MaterialCommunityIcons",
    iconName: "cube-scan"
  },
  {
    uuid: "67d5255b-4d47-4d48-8b79-49408fabd217",
    iconSet: "MaterialCommunityIcons",
    iconName: "cube-send"
  },
  {
    uuid: "033e4481-ce2d-4750-87e9-72f5b2ced347",
    iconSet: "MaterialCommunityIcons",
    iconName: "cube-unfolded"
  },
  {
    uuid: "fcdbc4a8-adf4-48c9-ae5b-4ff3af9c45e3",
    iconSet: "MaterialCommunityIcons",
    iconName: "cup"
  },
  {
    uuid: "90becf47-5fc9-496c-8a40-4d84825e3a15",
    iconSet: "MaterialCommunityIcons",
    iconName: "cup-off"
  },
  {
    uuid: "5b631f70-1eef-4644-bdb9-7eb8f5b12138",
    iconSet: "MaterialCommunityIcons",
    iconName: "cup-off-outline"
  },
  {
    uuid: "2c466382-5470-4105-bcbc-2ed31a7cbe6c",
    iconSet: "MaterialCommunityIcons",
    iconName: "cup-outline"
  },
  {
    uuid: "870ff05c-742c-4695-b027-82380f6c05b0",
    iconSet: "MaterialCommunityIcons",
    iconName: "cup-water"
  },
  {
    uuid: "59b265ae-30fd-40e6-8422-66d1dbdc4543",
    iconSet: "MaterialCommunityIcons",
    iconName: "cupboard"
  },
  {
    uuid: "6d3a89ef-f93b-4ce7-b9fa-1778cda555b4",
    iconSet: "MaterialCommunityIcons",
    iconName: "cupboard-outline"
  },
  {
    uuid: "6fe1d8d7-575e-49d5-b226-dccf6a18dbc4",
    iconSet: "MaterialCommunityIcons",
    iconName: "cupcake"
  },
  {
    uuid: "4362f92d-0295-4e81-8415-eadd875bc0eb",
    iconSet: "MaterialCommunityIcons",
    iconName: "curling"
  },
  {
    uuid: "7c4f94a1-91c1-4ef4-acfb-7dcf0cf9556d",
    iconSet: "MaterialCommunityIcons",
    iconName: "currency-bdt"
  },
  {
    uuid: "19e27f6a-917c-44c6-ae1b-e21d5b731e32",
    iconSet: "MaterialCommunityIcons",
    iconName: "currency-brl"
  },
  {
    uuid: "c8600aed-d944-4a95-8726-9dd8c25c63ba",
    iconSet: "MaterialCommunityIcons",
    iconName: "currency-btc"
  },
  {
    uuid: "dc4be0f8-7e8d-4a63-9006-a7adda39d3c6",
    iconSet: "MaterialCommunityIcons",
    iconName: "currency-cny"
  },
  {
    uuid: "a4628df0-5262-46ee-838c-c9b8bfcc0ddd",
    iconSet: "MaterialCommunityIcons",
    iconName: "currency-eth"
  },
  {
    uuid: "cf54342c-2aba-4933-be34-807d274885ce",
    iconSet: "MaterialCommunityIcons",
    iconName: "currency-eur"
  },
  {
    uuid: "df73c7ad-5b49-4b57-a394-671d7594606c",
    iconSet: "MaterialCommunityIcons",
    iconName: "currency-eur-off"
  },
  {
    uuid: "f0e7398a-5b87-4e1a-8689-5c6d2d37050e",
    iconSet: "MaterialCommunityIcons",
    iconName: "currency-gbp"
  },
  {
    uuid: "bbafc65c-03ab-454c-9531-58cb3b7392cb",
    iconSet: "MaterialCommunityIcons",
    iconName: "currency-ils"
  },
  {
    uuid: "f9150901-78da-4c34-974f-47334b1717e3",
    iconSet: "MaterialCommunityIcons",
    iconName: "currency-inr"
  },
  {
    uuid: "e17572d5-b610-408d-a464-6715b90e58d6",
    iconSet: "MaterialCommunityIcons",
    iconName: "currency-jpy"
  },
  {
    uuid: "6b90d352-8c71-43cc-b77f-65ccc6102e72",
    iconSet: "MaterialCommunityIcons",
    iconName: "currency-krw"
  },
  {
    uuid: "b6b43f47-357c-4a27-95ad-7d09c323237a",
    iconSet: "MaterialCommunityIcons",
    iconName: "currency-kzt"
  },
  {
    uuid: "c91ea44b-b9e9-491a-a215-1c1ff7f781e9",
    iconSet: "MaterialCommunityIcons",
    iconName: "currency-ngn"
  },
  {
    uuid: "9d816173-658c-4ac8-93f4-ec4384981a74",
    iconSet: "MaterialCommunityIcons",
    iconName: "currency-php"
  },
  {
    uuid: "230b5b75-b598-4855-9d68-a8669f0a1a5c",
    iconSet: "MaterialCommunityIcons",
    iconName: "currency-rial"
  },
  {
    uuid: "44beab49-5a4c-4721-a8fd-55781e53e0b6",
    iconSet: "MaterialCommunityIcons",
    iconName: "currency-rub"
  },
  {
    uuid: "05f89146-9257-4026-bda7-b35bb354ff1e",
    iconSet: "MaterialCommunityIcons",
    iconName: "currency-sign"
  },
  {
    uuid: "b513993e-904b-4db4-82bc-184f2ae5084f",
    iconSet: "MaterialCommunityIcons",
    iconName: "currency-try"
  },
  {
    uuid: "aade7970-82cb-4a77-ae76-936a87703b32",
    iconSet: "MaterialCommunityIcons",
    iconName: "currency-twd"
  },
  {
    uuid: "f8974d68-83ff-4579-96c0-5ebe10ff8b5f",
    iconSet: "MaterialCommunityIcons",
    iconName: "currency-usd"
  },
  {
    uuid: "fe8c0150-1d71-43bb-9dce-826a8193f7ca",
    iconSet: "MaterialCommunityIcons",
    iconName: "currency-usd-circle"
  },
  {
    uuid: "d09b7000-987a-4a43-a3c7-a92e6398475b",
    iconSet: "MaterialCommunityIcons",
    iconName: "currency-usd-circle-outline"
  },
  {
    uuid: "af552c4f-aeb0-4aa5-9e1c-0c97ef0a9d09",
    iconSet: "MaterialCommunityIcons",
    iconName: "currency-usd-off"
  },
  {
    uuid: "18b48029-38eb-4b9c-87fb-fad182d4418d",
    iconSet: "MaterialCommunityIcons",
    iconName: "current-ac"
  },
  {
    uuid: "aacdbcaa-a475-4bce-8355-6936bcf363e4",
    iconSet: "MaterialCommunityIcons",
    iconName: "current-dc"
  },
  {
    uuid: "9f296cf7-0ef7-4650-9335-35c41e795f39",
    iconSet: "MaterialCommunityIcons",
    iconName: "cursor-default"
  },
  {
    uuid: "fc19852d-6010-492d-909e-59eaa6aa7da3",
    iconSet: "MaterialCommunityIcons",
    iconName: "cursor-default-click"
  },
  {
    uuid: "4c30ee51-6aab-46e0-b2d2-506fa441f880",
    iconSet: "MaterialCommunityIcons",
    iconName: "cursor-default-click-outline"
  },
  {
    uuid: "9625d042-89e1-4055-b8f6-c7e48eca9c1e",
    iconSet: "MaterialCommunityIcons",
    iconName: "cursor-default-gesture"
  },
  {
    uuid: "89aee88a-e482-43a6-8d8f-3daf8e6af071",
    iconSet: "MaterialCommunityIcons",
    iconName: "cursor-default-gesture-outline"
  },
  {
    uuid: "e8e8c5d2-692f-4811-967a-2475d49c767c",
    iconSet: "MaterialCommunityIcons",
    iconName: "cursor-default-outline"
  },
  {
    uuid: "a8b916dc-6c72-43d7-bf01-a1239fa3dbe4",
    iconSet: "MaterialCommunityIcons",
    iconName: "cursor-move"
  },
  {
    uuid: "51db7da7-7b78-4110-8d68-12435a58047c",
    iconSet: "MaterialCommunityIcons",
    iconName: "cursor-pointer"
  },
  {
    uuid: "1a3f7492-ad3e-4f39-95c9-07852d875d60",
    iconSet: "MaterialCommunityIcons",
    iconName: "cursor-text"
  },
  {
    uuid: "38dbcbc0-0183-476f-96c0-b4fd5d603d95",
    iconSet: "MaterialCommunityIcons",
    iconName: "database"
  },
  {
    uuid: "a8c63e6f-c11f-41ba-96ca-d2fa0fe84480",
    iconSet: "MaterialCommunityIcons",
    iconName: "database-check"
  },
  {
    uuid: "a3eb559e-5250-45d4-9491-09dacc6a0faa",
    iconSet: "MaterialCommunityIcons",
    iconName: "database-edit"
  },
  {
    uuid: "800012b6-8bac-4228-8054-21e302f6d58d",
    iconSet: "MaterialCommunityIcons",
    iconName: "database-export"
  },
  {
    uuid: "24931e45-bfda-4e14-8eae-b08d5deb8cad",
    iconSet: "MaterialCommunityIcons",
    iconName: "database-import"
  },
  {
    uuid: "2d76d2a1-7faf-470f-a3b7-a5a4b3f99059",
    iconSet: "MaterialCommunityIcons",
    iconName: "database-lock"
  },
  {
    uuid: "7ac6945f-6376-40b9-b999-d19f15034425",
    iconSet: "MaterialCommunityIcons",
    iconName: "database-marker"
  },
  {
    uuid: "8b3da4b0-7a0f-428c-8a6b-c4f72c8a5aa4",
    iconSet: "MaterialCommunityIcons",
    iconName: "database-minus"
  },
  {
    uuid: "f03d81ed-3d48-4a42-9891-10c144be9d62",
    iconSet: "MaterialCommunityIcons",
    iconName: "database-plus"
  },
  {
    uuid: "026ab306-b03a-4b59-9d73-831aba81a985",
    iconSet: "MaterialCommunityIcons",
    iconName: "database-refresh"
  },
  {
    uuid: "9ac4d623-c49a-447d-bde5-19580930dcef",
    iconSet: "MaterialCommunityIcons",
    iconName: "database-remove"
  },
  {
    uuid: "e3cc8728-a96f-4b41-960e-0a77a81b5948",
    iconSet: "MaterialCommunityIcons",
    iconName: "database-search"
  },
  {
    uuid: "5f6699f1-fd30-469e-b322-6491b3660176",
    iconSet: "MaterialCommunityIcons",
    iconName: "database-settings"
  },
  {
    uuid: "dc2b5854-87b3-4169-a27e-0690a1ed52f6",
    iconSet: "MaterialCommunityIcons",
    iconName: "database-sync"
  },
  {
    uuid: "02349885-6f00-407f-81c3-a65212090973",
    iconSet: "MaterialCommunityIcons",
    iconName: "death-star"
  },
  {
    uuid: "d3e07e15-fb1f-416b-8b97-08ec271bcb39",
    iconSet: "MaterialCommunityIcons",
    iconName: "death-star-variant"
  },
  {
    uuid: "9788952d-cc07-4a06-bbeb-0ca808497246",
    iconSet: "MaterialCommunityIcons",
    iconName: "deathly-hallows"
  },
  {
    uuid: "f72eec59-013f-4820-b70a-d3fbb0a038b5",
    iconSet: "MaterialCommunityIcons",
    iconName: "debian"
  },
  {
    uuid: "169da484-f2e4-4137-9b54-c21427cdff55",
    iconSet: "MaterialCommunityIcons",
    iconName: "debug-step-into"
  },
  {
    uuid: "2118d33c-bf62-47d9-9f8c-0899c20215cd",
    iconSet: "MaterialCommunityIcons",
    iconName: "debug-step-out"
  },
  {
    uuid: "b4002db7-385c-469e-a41d-4d057edcf6a9",
    iconSet: "MaterialCommunityIcons",
    iconName: "debug-step-over"
  },
  {
    uuid: "934d5064-69cf-4667-9252-0c26d3b7ca0f",
    iconSet: "MaterialCommunityIcons",
    iconName: "decagram"
  },
  {
    uuid: "d1b7660e-f03a-46e1-b2a4-bc165e290ec4",
    iconSet: "MaterialCommunityIcons",
    iconName: "decagram-outline"
  },
  {
    uuid: "bce1e57b-c91e-4787-b6bc-c2889d029e74",
    iconSet: "MaterialCommunityIcons",
    iconName: "decimal"
  },
  {
    uuid: "8bd8ee36-a6fb-4f50-86b4-8d12d2a275b1",
    iconSet: "MaterialCommunityIcons",
    iconName: "decimal-comma"
  },
  {
    uuid: "e85a3718-ace6-4f31-8939-e947aba43318",
    iconSet: "MaterialCommunityIcons",
    iconName: "decimal-comma-decrease"
  },
  {
    uuid: "cf47448b-3b94-4f74-bbfb-8e73b3cfef2a",
    iconSet: "MaterialCommunityIcons",
    iconName: "decimal-comma-increase"
  },
  {
    uuid: "767fc6bc-1ad8-4f92-8ef6-d22e483f8a4b",
    iconSet: "MaterialCommunityIcons",
    iconName: "decimal-decrease"
  },
  {
    uuid: "c190207d-6de3-4788-b814-5fba8fcad5b4",
    iconSet: "MaterialCommunityIcons",
    iconName: "decimal-increase"
  },
  {
    uuid: "b37b1051-4442-4b4e-a61d-2991d87283ac",
    iconSet: "MaterialCommunityIcons",
    iconName: "delete"
  },
  {
    uuid: "5ff0be2c-2217-47fe-9fb8-0a8fb4382e6a",
    iconSet: "MaterialCommunityIcons",
    iconName: "delete-alert"
  },
  {
    uuid: "6be6fe23-bd9c-4a0a-8ccd-9bec7fa89141",
    iconSet: "MaterialCommunityIcons",
    iconName: "delete-alert-outline"
  },
  {
    uuid: "8b1d610a-1ace-4dd2-8cc7-22da7d4c01b4",
    iconSet: "MaterialCommunityIcons",
    iconName: "delete-circle"
  },
  {
    uuid: "86306d61-3aa2-4a49-b3ed-b73dcb1c2048",
    iconSet: "MaterialCommunityIcons",
    iconName: "delete-circle-outline"
  },
  {
    uuid: "8212e761-16c6-4a1f-9a71-f5b78b2b3856",
    iconSet: "MaterialCommunityIcons",
    iconName: "delete-empty"
  },
  {
    uuid: "f7cf1af9-41b1-4fa4-9974-53df3c3e77df",
    iconSet: "MaterialCommunityIcons",
    iconName: "delete-empty-outline"
  },
  {
    uuid: "52bd7a79-5d02-4c5b-b83f-6b8161e642ab",
    iconSet: "MaterialCommunityIcons",
    iconName: "delete-forever"
  },
  {
    uuid: "00acd146-284c-495a-a8ef-3f46a128fce0",
    iconSet: "MaterialCommunityIcons",
    iconName: "delete-forever-outline"
  },
  {
    uuid: "ebc878e5-5f52-4ce2-ab28-cbe94ac5fe37",
    iconSet: "MaterialCommunityIcons",
    iconName: "delete-off"
  },
  {
    uuid: "189a4de9-1a4d-47e6-9c89-3cc7f26f9ff6",
    iconSet: "MaterialCommunityIcons",
    iconName: "delete-off-outline"
  },
  {
    uuid: "f296e445-7261-43c6-9a92-a60e2720b91a",
    iconSet: "MaterialCommunityIcons",
    iconName: "delete-outline"
  },
  {
    uuid: "14fcbcad-c5f0-4366-8915-b2f4f1802675",
    iconSet: "MaterialCommunityIcons",
    iconName: "delete-restore"
  },
  {
    uuid: "70fba259-be4a-4034-83d4-1a016b867b8b",
    iconSet: "MaterialCommunityIcons",
    iconName: "delete-sweep"
  },
  {
    uuid: "a4fc29ca-8fa9-4477-87f2-72cdd9d3ba81",
    iconSet: "MaterialCommunityIcons",
    iconName: "delete-sweep-outline"
  },
  {
    uuid: "28c92a5d-ecc1-44e2-9588-dd5298ecdfd8",
    iconSet: "MaterialCommunityIcons",
    iconName: "delete-variant"
  },
  {
    uuid: "f59e4ad9-1a84-44da-979b-00d561376e7d",
    iconSet: "MaterialCommunityIcons",
    iconName: "delta"
  },
  {
    uuid: "705df5c9-572b-4702-8b3c-790117aea2f3",
    iconSet: "MaterialCommunityIcons",
    iconName: "desk"
  },
  {
    uuid: "da859d87-e12e-4821-bc99-379452373822",
    iconSet: "MaterialCommunityIcons",
    iconName: "desk-lamp"
  },
  {
    uuid: "c5d4d53b-e5d8-4f58-85ec-1e323a017af8",
    iconSet: "MaterialCommunityIcons",
    iconName: "deskphone"
  },
  {
    uuid: "392cafba-1032-4b96-9d5c-3dd812068264",
    iconSet: "MaterialCommunityIcons",
    iconName: "desktop-classic"
  },
  {
    uuid: "001e85ed-1961-47c1-a458-74958f5637fc",
    iconSet: "MaterialCommunityIcons",
    iconName: "desktop-mac"
  },
  {
    uuid: "1866881d-2a71-40a6-b837-d25d0b00b808",
    iconSet: "MaterialCommunityIcons",
    iconName: "desktop-mac-dashboard"
  },
  {
    uuid: "b7f910fe-a85d-4fb4-b985-8a8320111ac2",
    iconSet: "MaterialCommunityIcons",
    iconName: "desktop-tower"
  },
  {
    uuid: "66b4ba61-4fa4-438d-8f0d-25ecfa024683",
    iconSet: "MaterialCommunityIcons",
    iconName: "desktop-tower-monitor"
  },
  {
    uuid: "51c16bef-f129-4b49-a334-c671501497dc",
    iconSet: "MaterialCommunityIcons",
    iconName: "details"
  },
  {
    uuid: "ad7508bd-5a4d-45b0-86bb-ba5d68f3abf2",
    iconSet: "MaterialCommunityIcons",
    iconName: "dev-to"
  },
  {
    uuid: "51abe0db-41f1-40fa-ac2b-96699654ace2",
    iconSet: "MaterialCommunityIcons",
    iconName: "developer-board"
  },
  {
    uuid: "0e751322-2f56-4ca4-8358-9a730b78adc9",
    iconSet: "MaterialCommunityIcons",
    iconName: "deviantart"
  },
  {
    uuid: "f30e1ef5-03c4-4be1-881a-e5aad5917b42",
    iconSet: "MaterialCommunityIcons",
    iconName: "devices"
  },
  {
    uuid: "59d7b4dd-258d-4cc2-9f25-2c152813db7e",
    iconSet: "MaterialCommunityIcons",
    iconName: "diabetes"
  },
  {
    uuid: "2855a905-528e-4429-9969-4eafa0b199ec",
    iconSet: "MaterialCommunityIcons",
    iconName: "dialpad"
  },
  {
    uuid: "a20a13b4-541e-40c1-bc5f-e625c7a60abd",
    iconSet: "MaterialCommunityIcons",
    iconName: "diameter"
  },
  {
    uuid: "2b0f6dfc-50d8-4f0b-a4df-76804c73cb4c",
    iconSet: "MaterialCommunityIcons",
    iconName: "diameter-outline"
  },
  {
    uuid: "8eb35964-28a9-4eaa-b02f-674b2865e9eb",
    iconSet: "MaterialCommunityIcons",
    iconName: "diameter-variant"
  },
  {
    uuid: "4ff05f11-f2aa-4375-b224-0090200c5bdc",
    iconSet: "MaterialCommunityIcons",
    iconName: "diamond"
  },
  {
    uuid: "7b6b7854-9f31-48bc-bff2-4df387ca8408",
    iconSet: "MaterialCommunityIcons",
    iconName: "diamond-outline"
  },
  {
    uuid: "d7afd0a8-c1e1-496a-b674-ae0a5268e438",
    iconSet: "MaterialCommunityIcons",
    iconName: "diamond-stone"
  },
  {
    uuid: "2481b6ba-e92c-4004-a0ac-22493b5b02ba",
    iconSet: "MaterialCommunityIcons",
    iconName: "dice-1"
  },
  {
    uuid: "cd694895-9b78-4494-a65a-f66eeb4c4870",
    iconSet: "MaterialCommunityIcons",
    iconName: "dice-1-outline"
  },
  {
    uuid: "06d0e002-f774-413a-a943-6161c853f9b6",
    iconSet: "MaterialCommunityIcons",
    iconName: "dice-2"
  },
  {
    uuid: "95880937-b593-4dd8-8457-cd727067200c",
    iconSet: "MaterialCommunityIcons",
    iconName: "dice-2-outline"
  },
  {
    uuid: "aff8c460-5d65-481c-92e3-e32bd86ff99f",
    iconSet: "MaterialCommunityIcons",
    iconName: "dice-3"
  },
  {
    uuid: "c357954c-7761-42a3-8af4-5a1f7dd00b6a",
    iconSet: "MaterialCommunityIcons",
    iconName: "dice-3-outline"
  },
  {
    uuid: "938f0d93-7fba-40fe-8219-d5a37c69d00d",
    iconSet: "MaterialCommunityIcons",
    iconName: "dice-4"
  },
  {
    uuid: "18551f67-2eb7-47b6-b5fc-9cceab8839aa",
    iconSet: "MaterialCommunityIcons",
    iconName: "dice-4-outline"
  },
  {
    uuid: "6264e0f9-9902-4933-8e84-44a302715ae6",
    iconSet: "MaterialCommunityIcons",
    iconName: "dice-5"
  },
  {
    uuid: "e65e98f7-8cf0-468f-9c15-ddad2f26fbca",
    iconSet: "MaterialCommunityIcons",
    iconName: "dice-5-outline"
  },
  {
    uuid: "68b3084c-01d7-4ffe-a224-d4edd8525e21",
    iconSet: "MaterialCommunityIcons",
    iconName: "dice-6"
  },
  {
    uuid: "89837d7b-0b44-430b-88d8-cb84b9fd6d35",
    iconSet: "MaterialCommunityIcons",
    iconName: "dice-6-outline"
  },
  {
    uuid: "b110b8d1-9dce-414e-ae41-91b964ae3e78",
    iconSet: "MaterialCommunityIcons",
    iconName: "dice-d10"
  },
  {
    uuid: "329cf586-28b5-40dc-8d46-7329a9b6166c",
    iconSet: "MaterialCommunityIcons",
    iconName: "dice-d10-outline"
  },
  {
    uuid: "c50732b3-301a-4c5c-9ac4-cda2f947dcbf",
    iconSet: "MaterialCommunityIcons",
    iconName: "dice-d12"
  },
  {
    uuid: "69cc8416-75ed-4b78-a38b-b074326369c9",
    iconSet: "MaterialCommunityIcons",
    iconName: "dice-d12-outline"
  },
  {
    uuid: "42343966-4259-4c6a-8ae1-930cdb534914",
    iconSet: "MaterialCommunityIcons",
    iconName: "dice-d20"
  },
  {
    uuid: "32812bb6-1646-43c9-a5f0-04904bce849d",
    iconSet: "MaterialCommunityIcons",
    iconName: "dice-d20-outline"
  },
  {
    uuid: "9bf719c6-23df-4152-a0d6-30bb0b5eba7e",
    iconSet: "MaterialCommunityIcons",
    iconName: "dice-d4"
  },
  {
    uuid: "9b72af9e-b70b-4d6f-ad5e-57a574eb4389",
    iconSet: "MaterialCommunityIcons",
    iconName: "dice-d4-outline"
  },
  {
    uuid: "2930760f-ad6a-4a48-b408-2a115eedb6e1",
    iconSet: "MaterialCommunityIcons",
    iconName: "dice-d6"
  },
  {
    uuid: "b3301202-f9ce-4b12-ab99-117edb54aefe",
    iconSet: "MaterialCommunityIcons",
    iconName: "dice-d6-outline"
  },
  {
    uuid: "12f79b06-1d83-426c-b574-cc698397c0d8",
    iconSet: "MaterialCommunityIcons",
    iconName: "dice-d8"
  },
  {
    uuid: "fecdec21-51ca-46c0-9723-b9e6b9a6c3cd",
    iconSet: "MaterialCommunityIcons",
    iconName: "dice-d8-outline"
  },
  {
    uuid: "403e1949-6f19-4875-abe4-d09a91cb8b1c",
    iconSet: "MaterialCommunityIcons",
    iconName: "dice-multiple"
  },
  {
    uuid: "afbe6402-8ace-4702-8230-a2953fe81f69",
    iconSet: "MaterialCommunityIcons",
    iconName: "dice-multiple-outline"
  },
  {
    uuid: "d9281fc9-52cd-4e11-8a5a-f983a8930630",
    iconSet: "MaterialCommunityIcons",
    iconName: "digital-ocean"
  },
  {
    uuid: "065c3ba3-db42-493c-a6a5-4a380ab114a8",
    iconSet: "MaterialCommunityIcons",
    iconName: "dip-switch"
  },
  {
    uuid: "9722c0cf-327e-48d0-9c17-e4576e059999",
    iconSet: "MaterialCommunityIcons",
    iconName: "directions"
  },
  {
    uuid: "9aa5d639-2c6a-4ecb-b484-b0a5a4222346",
    iconSet: "MaterialCommunityIcons",
    iconName: "directions-fork"
  },
  {
    uuid: "a5b6001e-4345-4df0-a469-ea98b16731a1",
    iconSet: "MaterialCommunityIcons",
    iconName: "disc"
  },
  {
    uuid: "b0f2f351-0dc5-4fc3-856c-a9d265aa42b7",
    iconSet: "MaterialCommunityIcons",
    iconName: "disc-alert"
  },
  {
    uuid: "4e77e933-d031-4b50-baa8-c3a365a4ccb5",
    iconSet: "MaterialCommunityIcons",
    iconName: "disc-player"
  },
  {
    uuid: "911fd972-c2ad-4414-9165-5f26ee6fff83",
    iconSet: "MaterialCommunityIcons",
    iconName: "discord"
  },
  {
    uuid: "0de6530b-85cd-40e5-8edf-af240472c3b7",
    iconSet: "MaterialCommunityIcons",
    iconName: "dishwasher"
  },
  {
    uuid: "982699ed-109f-40c0-8022-a8bc09075973",
    iconSet: "MaterialCommunityIcons",
    iconName: "dishwasher-alert"
  },
  {
    uuid: "5591ba44-a5d1-4dfe-99a5-b8f373596022",
    iconSet: "MaterialCommunityIcons",
    iconName: "dishwasher-off"
  },
  {
    uuid: "c4029cef-e769-4b85-9560-485e518fcd03",
    iconSet: "MaterialCommunityIcons",
    iconName: "disqus"
  },
  {
    uuid: "cb6b89c1-2672-4ddf-88e3-267ce358230c",
    iconSet: "MaterialCommunityIcons",
    iconName: "distribute-horizontal-center"
  },
  {
    uuid: "fa1e1b13-4481-4142-89ec-7eb5dde27e0d",
    iconSet: "MaterialCommunityIcons",
    iconName: "distribute-horizontal-left"
  },
  {
    uuid: "0d23d627-0489-4d38-9e5b-b8056a1f999c",
    iconSet: "MaterialCommunityIcons",
    iconName: "distribute-horizontal-right"
  },
  {
    uuid: "70661407-2c1d-4c55-8e86-2f2dc9d9d1c8",
    iconSet: "MaterialCommunityIcons",
    iconName: "distribute-vertical-bottom"
  },
  {
    uuid: "5a392e24-b6b5-4000-ad1e-098b85ba8edd",
    iconSet: "MaterialCommunityIcons",
    iconName: "distribute-vertical-center"
  },
  {
    uuid: "68425e81-2a55-4a7f-8d7b-f8d85f56d1ca",
    iconSet: "MaterialCommunityIcons",
    iconName: "distribute-vertical-top"
  },
  {
    uuid: "fbf57c03-7c74-47ec-bb06-2f827f1a782d",
    iconSet: "MaterialCommunityIcons",
    iconName: "diving-flippers"
  },
  {
    uuid: "8f8cba0b-7843-4178-8d6f-a4ae5741dcf3",
    iconSet: "MaterialCommunityIcons",
    iconName: "diving-helmet"
  },
  {
    uuid: "d085eeae-cdb5-4ad1-adc0-d98acacccb20",
    iconSet: "MaterialCommunityIcons",
    iconName: "diving-scuba"
  },
  {
    uuid: "8e80c59f-fced-4f8a-9ffa-6f9323e0bee9",
    iconSet: "MaterialCommunityIcons",
    iconName: "diving-scuba-flag"
  },
  {
    uuid: "309b35f2-1409-442f-9d87-e1b603d537a6",
    iconSet: "MaterialCommunityIcons",
    iconName: "diving-scuba-tank"
  },
  {
    uuid: "7bfe6c67-3399-4d48-abb7-a78507df3b40",
    iconSet: "MaterialCommunityIcons",
    iconName: "diving-scuba-tank-multiple"
  },
  {
    uuid: "d81cf6c0-77ac-4bbe-a27e-a3c3aa52639b",
    iconSet: "MaterialCommunityIcons",
    iconName: "diving-snorkel"
  },
  {
    uuid: "72007dfe-acd0-400c-8f36-8a97f6487fe9",
    iconSet: "MaterialCommunityIcons",
    iconName: "division"
  },
  {
    uuid: "887a7a5d-7aa2-4c83-b42d-8dc81bb7ebff",
    iconSet: "MaterialCommunityIcons",
    iconName: "division-box"
  },
  {
    uuid: "4fdf9e81-929f-454a-a2f8-8aec15901a4d",
    iconSet: "MaterialCommunityIcons",
    iconName: "dlna"
  },
  {
    uuid: "90ff5d37-3908-49ad-bdfa-9cd8d6a3ec9c",
    iconSet: "MaterialCommunityIcons",
    iconName: "dna"
  },
  {
    uuid: "bb0398c7-cb23-4d24-afb5-d29439bbfa09",
    iconSet: "MaterialCommunityIcons",
    iconName: "dns"
  },
  {
    uuid: "3f84edd4-8e2b-432e-ba61-a4adce0b2d5f",
    iconSet: "MaterialCommunityIcons",
    iconName: "dns-outline"
  },
  {
    uuid: "442e225e-0b1d-4dc8-964d-75c7dee20306",
    iconSet: "MaterialCommunityIcons",
    iconName: "do-not-disturb"
  },
  {
    uuid: "4439509f-d9a8-4660-abfe-738152a741c0",
    iconSet: "MaterialCommunityIcons",
    iconName: "do-not-disturb-off"
  },
  {
    uuid: "c8e41000-52ff-4be4-ba98-52881150c662",
    iconSet: "MaterialCommunityIcons",
    iconName: "dock-bottom"
  },
  {
    uuid: "e94a728a-be5d-4e15-99c7-f8dfaf6a1568",
    iconSet: "MaterialCommunityIcons",
    iconName: "dock-left"
  },
  {
    uuid: "a07cd9c4-dbe6-4f50-8692-c755f40b9395",
    iconSet: "MaterialCommunityIcons",
    iconName: "dock-right"
  },
  {
    uuid: "23a61724-9b0f-4831-8940-624a65cd780d",
    iconSet: "MaterialCommunityIcons",
    iconName: "dock-window"
  },
  {
    uuid: "5da27c13-e749-4286-ab8f-8ae2a86c9cda",
    iconSet: "MaterialCommunityIcons",
    iconName: "docker"
  },
  {
    uuid: "8ab241cf-f847-42a0-9547-9af4ee017872",
    iconSet: "MaterialCommunityIcons",
    iconName: "doctor"
  },
  {
    uuid: "1a78c37e-26e5-4c61-809c-bfc10a1dd122",
    iconSet: "MaterialCommunityIcons",
    iconName: "dog"
  },
  {
    uuid: "a756d18d-c6d8-4374-9aa1-85f8afa883ee",
    iconSet: "MaterialCommunityIcons",
    iconName: "dog-service"
  },
  {
    uuid: "4dd53d19-63b2-469d-a636-6cc5495cff2c",
    iconSet: "MaterialCommunityIcons",
    iconName: "dog-side"
  },
  {
    uuid: "cc235bd9-dd8d-41ca-a7bb-e98d69cc864d",
    iconSet: "MaterialCommunityIcons",
    iconName: "dolby"
  },
  {
    uuid: "62335711-2a14-4bce-935a-9c5e5fab79de",
    iconSet: "MaterialCommunityIcons",
    iconName: "dolly"
  },
  {
    uuid: "ec2ae949-5fe7-4d79-9140-ca6403fb468e",
    iconSet: "MaterialCommunityIcons",
    iconName: "domain"
  },
  {
    uuid: "9fa8b8f6-9222-4a77-986b-54d7886a679b",
    iconSet: "MaterialCommunityIcons",
    iconName: "domain-off"
  },
  {
    uuid: "be5c7b8f-0729-4277-8995-4e818d7b6394",
    iconSet: "MaterialCommunityIcons",
    iconName: "domain-plus"
  },
  {
    uuid: "75fd0763-6f9b-4d44-9e2a-80d0e7dda616",
    iconSet: "MaterialCommunityIcons",
    iconName: "domain-remove"
  },
  {
    uuid: "9c75a837-421d-42a9-ab20-5e0776639567",
    iconSet: "MaterialCommunityIcons",
    iconName: "dome-light"
  },
  {
    uuid: "81e26ba3-d14f-40ea-90db-ad378672fc66",
    iconSet: "MaterialCommunityIcons",
    iconName: "domino-mask"
  },
  {
    uuid: "96430962-e25d-4c13-a66e-2c8209c20688",
    iconSet: "MaterialCommunityIcons",
    iconName: "donkey"
  },
  {
    uuid: "66132f14-60c4-47d1-ac1f-2d080a235156",
    iconSet: "MaterialCommunityIcons",
    iconName: "door"
  },
  {
    uuid: "733e1563-9a9f-489f-89d5-974496aaccb1",
    iconSet: "MaterialCommunityIcons",
    iconName: "door-closed"
  },
  {
    uuid: "2aca5275-2471-4301-83c5-7990ca4eea8c",
    iconSet: "MaterialCommunityIcons",
    iconName: "door-closed-lock"
  },
  {
    uuid: "7548438c-1809-490e-a0d5-2453551d776f",
    iconSet: "MaterialCommunityIcons",
    iconName: "door-open"
  },
  {
    uuid: "76fa14d6-047a-42b1-93da-765897b3ba97",
    iconSet: "MaterialCommunityIcons",
    iconName: "doorbell"
  },
  {
    uuid: "6260ac75-5d70-4277-960e-4ce05eec5479",
    iconSet: "MaterialCommunityIcons",
    iconName: "doorbell-video"
  },
  {
    uuid: "1e98cc95-26ff-411d-9858-66c7960d68fb",
    iconSet: "MaterialCommunityIcons",
    iconName: "dot-net"
  },
  {
    uuid: "6e339b08-75b2-4721-89ef-dba87914396d",
    iconSet: "MaterialCommunityIcons",
    iconName: "dots-horizontal"
  },
  {
    uuid: "03c27114-932b-49d3-afbe-92533dafcd47",
    iconSet: "MaterialCommunityIcons",
    iconName: "dots-horizontal-circle"
  },
  {
    uuid: "06b67ed5-2dde-45ba-b0af-adc0c85d93bb",
    iconSet: "MaterialCommunityIcons",
    iconName: "dots-horizontal-circle-outline"
  },
  {
    uuid: "1dfbe181-3bb2-42b5-ad17-12e3ad0a274f",
    iconSet: "MaterialCommunityIcons",
    iconName: "dots-vertical"
  },
  {
    uuid: "9ad39d6c-0f0b-4c98-88e3-3244affc8282",
    iconSet: "MaterialCommunityIcons",
    iconName: "dots-vertical-circle"
  },
  {
    uuid: "2ad46fbd-b40c-4262-968e-bfc25f7431d1",
    iconSet: "MaterialCommunityIcons",
    iconName: "dots-vertical-circle-outline"
  },
  {
    uuid: "f475ef8c-eedd-4393-970f-23eb217bc675",
    iconSet: "MaterialCommunityIcons",
    iconName: "douban"
  },
  {
    uuid: "d44ee97b-cabe-4f03-929a-94736e32f9df",
    iconSet: "MaterialCommunityIcons",
    iconName: "download"
  },
  {
    uuid: "633d43f9-1fc3-46cc-8259-a6fa32355531",
    iconSet: "MaterialCommunityIcons",
    iconName: "download-box"
  },
  {
    uuid: "548fd19e-cf7f-4ace-8958-4adb1ed3e820",
    iconSet: "MaterialCommunityIcons",
    iconName: "download-box-outline"
  },
  {
    uuid: "679d55ca-f750-4d7d-9020-8d910f35b5b0",
    iconSet: "MaterialCommunityIcons",
    iconName: "download-circle"
  },
  {
    uuid: "77698245-579b-4376-aa8c-e249a235ea76",
    iconSet: "MaterialCommunityIcons",
    iconName: "download-circle-outline"
  },
  {
    uuid: "7035bcdf-31fc-4106-b9e9-b641dd08169a",
    iconSet: "MaterialCommunityIcons",
    iconName: "download-lock"
  },
  {
    uuid: "c1c1eed0-c897-4d51-8096-54fb77d43341",
    iconSet: "MaterialCommunityIcons",
    iconName: "download-lock-outline"
  },
  {
    uuid: "8de7c7c5-0a6d-451e-8705-4623f0bbfe2b",
    iconSet: "MaterialCommunityIcons",
    iconName: "download-multiple"
  },
  {
    uuid: "32a6ea7f-ccac-4f2e-aeb7-550505be80bf",
    iconSet: "MaterialCommunityIcons",
    iconName: "download-network"
  },
  {
    uuid: "1465a9e4-d0d8-4cc1-855e-bc6e97949f25",
    iconSet: "MaterialCommunityIcons",
    iconName: "download-network-outline"
  },
  {
    uuid: "e4ce990b-79e6-492e-b26e-8dafb4591845",
    iconSet: "MaterialCommunityIcons",
    iconName: "download-off"
  },
  {
    uuid: "a18e413e-5a9e-4f9a-9573-ba1c08661439",
    iconSet: "MaterialCommunityIcons",
    iconName: "download-off-outline"
  },
  {
    uuid: "f82f42fc-0078-46e6-b52e-7eb6b68a64a8",
    iconSet: "MaterialCommunityIcons",
    iconName: "download-outline"
  },
  {
    uuid: "d17da19f-e70f-4e04-bf63-c1c25987ce8c",
    iconSet: "MaterialCommunityIcons",
    iconName: "drag"
  },
  {
    uuid: "3a74bb0b-eccc-48f1-b72a-0e000dbcc25a",
    iconSet: "MaterialCommunityIcons",
    iconName: "drag-horizontal"
  },
  {
    uuid: "4b7ea0c7-ceda-4be8-b5f1-48a55e6730d9",
    iconSet: "MaterialCommunityIcons",
    iconName: "drag-horizontal-variant"
  },
  {
    uuid: "b6728d78-1cef-4f8b-a0c9-c815955ee7cd",
    iconSet: "MaterialCommunityIcons",
    iconName: "drag-variant"
  },
  {
    uuid: "8c2a0e01-a38e-4cef-8fc4-6a123ba8e848",
    iconSet: "MaterialCommunityIcons",
    iconName: "drag-vertical"
  },
  {
    uuid: "0873ee66-d005-4c89-a648-67b582d0cfa6",
    iconSet: "MaterialCommunityIcons",
    iconName: "drag-vertical-variant"
  },
  {
    uuid: "18156450-e7a6-4b67-bf5a-5a04b2bc39bb",
    iconSet: "MaterialCommunityIcons",
    iconName: "drama-masks"
  },
  {
    uuid: "f6c6b0f5-01d3-4c48-b37b-7101bda44fb2",
    iconSet: "MaterialCommunityIcons",
    iconName: "draw"
  },
  {
    uuid: "6c34aae9-f60f-4d11-b65d-0a02244ba54d",
    iconSet: "MaterialCommunityIcons",
    iconName: "drawing"
  },
  {
    uuid: "1a2ed9be-d0e2-4a7b-823e-4762f5584126",
    iconSet: "MaterialCommunityIcons",
    iconName: "drawing-box"
  },
  {
    uuid: "309cdb11-07ad-44f5-b323-d5528b5a64ae",
    iconSet: "MaterialCommunityIcons",
    iconName: "dresser"
  },
  {
    uuid: "1aed2725-5e70-45db-b3f5-5199b96d99b0",
    iconSet: "MaterialCommunityIcons",
    iconName: "dresser-outline"
  },
  {
    uuid: "6badd8e3-2fb6-4f9b-9c23-cbc7f16af7cf",
    iconSet: "MaterialCommunityIcons",
    iconName: "drone"
  },
  {
    uuid: "a439d07d-6476-4035-95d8-31c0c0be2d1b",
    iconSet: "MaterialCommunityIcons",
    iconName: "dropbox"
  },
  {
    uuid: "db8b1e19-3cf2-4f80-867e-e5ad288dfb05",
    iconSet: "MaterialCommunityIcons",
    iconName: "drupal"
  },
  {
    uuid: "0f9258d7-07b4-4a91-a11c-51452c60090f",
    iconSet: "MaterialCommunityIcons",
    iconName: "duck"
  },
  {
    uuid: "e2720a5f-cf04-4840-9640-a219bdb2cd86",
    iconSet: "MaterialCommunityIcons",
    iconName: "dumbbell"
  },
  {
    uuid: "9f710c5b-80be-4c73-b7ce-07de063f1459",
    iconSet: "MaterialCommunityIcons",
    iconName: "dump-truck"
  },
  {
    uuid: "3554a6bd-2d36-4ef5-92c0-4ca58c0b40a5",
    iconSet: "MaterialCommunityIcons",
    iconName: "ear-hearing"
  },
  {
    uuid: "3b39ae9e-fbe3-44e2-8994-6be208495ad7",
    iconSet: "MaterialCommunityIcons",
    iconName: "ear-hearing-off"
  },
  {
    uuid: "c49d32fe-5d0c-438c-9fea-064e3f435e5b",
    iconSet: "MaterialCommunityIcons",
    iconName: "earth"
  },
  {
    uuid: "ae9ea745-6313-4be1-abcc-113a5de3071e",
    iconSet: "MaterialCommunityIcons",
    iconName: "earth-arrow-right"
  },
  {
    uuid: "a1878b97-6072-497a-a34e-5bab805c4a12",
    iconSet: "MaterialCommunityIcons",
    iconName: "earth-box"
  },
  {
    uuid: "b1775deb-d466-4148-8d80-48bb7e5b310a",
    iconSet: "MaterialCommunityIcons",
    iconName: "earth-box-minus"
  },
  {
    uuid: "ed56ac68-ea8c-4bb6-9ea9-64abd49306c5",
    iconSet: "MaterialCommunityIcons",
    iconName: "earth-box-off"
  },
  {
    uuid: "3958ab38-422d-4c99-ae6b-dd497e6927c8",
    iconSet: "MaterialCommunityIcons",
    iconName: "earth-box-plus"
  },
  {
    uuid: "72d51262-c407-4cf7-a5e4-095c1d0f8202",
    iconSet: "MaterialCommunityIcons",
    iconName: "earth-box-remove"
  },
  {
    uuid: "d03a7314-f88e-4d50-85e3-d599828b8b24",
    iconSet: "MaterialCommunityIcons",
    iconName: "earth-minus"
  },
  {
    uuid: "cf2354d0-d068-4a4f-9d6f-39eebee596d2",
    iconSet: "MaterialCommunityIcons",
    iconName: "earth-off"
  },
  {
    uuid: "fa87d137-97ca-4d6d-b92a-fef945254bf5",
    iconSet: "MaterialCommunityIcons",
    iconName: "earth-plus"
  },
  {
    uuid: "bfa71693-4d91-4359-831b-dbe70279c67b",
    iconSet: "MaterialCommunityIcons",
    iconName: "earth-remove"
  },
  {
    uuid: "8ec85511-4a26-4e7b-b8aa-1a8b3c148bdf",
    iconSet: "MaterialCommunityIcons",
    iconName: "egg"
  },
  {
    uuid: "791e291f-7262-4b7c-8fc2-7920e378ccf3",
    iconSet: "MaterialCommunityIcons",
    iconName: "egg-easter"
  },
  {
    uuid: "55f8230f-77d0-4525-9466-0d35051e3859",
    iconSet: "MaterialCommunityIcons",
    iconName: "egg-off"
  },
  {
    uuid: "c8a87433-61b5-478f-adb0-87a8e695a983",
    iconSet: "MaterialCommunityIcons",
    iconName: "egg-off-outline"
  },
  {
    uuid: "e1570d1b-75cf-4327-953c-b564a88c2859",
    iconSet: "MaterialCommunityIcons",
    iconName: "egg-outline"
  },
  {
    uuid: "f9d56d3d-214a-493a-a7f4-500462603102",
    iconSet: "MaterialCommunityIcons",
    iconName: "eight-track"
  },
  {
    uuid: "6605d39c-24d9-4d86-a0fb-a908cf5c1e6e",
    iconSet: "MaterialCommunityIcons",
    iconName: "eject"
  },
  {
    uuid: "d278e908-f6e3-4a3e-8ab9-27caf8f5e3ec",
    iconSet: "MaterialCommunityIcons",
    iconName: "eject-outline"
  },
  {
    uuid: "dc7a8f6d-4be0-43f9-ac1e-8db9978888c8",
    iconSet: "MaterialCommunityIcons",
    iconName: "electric-switch"
  },
  {
    uuid: "30231057-337e-44a9-aa86-6c811cde9781",
    iconSet: "MaterialCommunityIcons",
    iconName: "electric-switch-closed"
  },
  {
    uuid: "364fca3a-ef85-4f6d-aebe-4d3716b5b8af",
    iconSet: "MaterialCommunityIcons",
    iconName: "electron-framework"
  },
  {
    uuid: "b87e484b-a1cb-4649-a524-b5302355e600",
    iconSet: "MaterialCommunityIcons",
    iconName: "elephant"
  },
  {
    uuid: "8621c02f-3c2d-4416-bcb5-0328b2b3c03f",
    iconSet: "MaterialCommunityIcons",
    iconName: "elevation-decline"
  },
  {
    uuid: "bd869abc-447b-4e96-828e-3c6ff033e081",
    iconSet: "MaterialCommunityIcons",
    iconName: "elevation-rise"
  },
  {
    uuid: "e4a3809f-1b7c-4aec-8988-c80b5f7e7953",
    iconSet: "MaterialCommunityIcons",
    iconName: "elevator"
  },
  {
    uuid: "cae48427-cad8-4914-8bbc-abfc84fbd681",
    iconSet: "MaterialCommunityIcons",
    iconName: "elevator-down"
  },
  {
    uuid: "fc7535e7-e174-4583-b1fd-fd384c851908",
    iconSet: "MaterialCommunityIcons",
    iconName: "elevator-passenger"
  },
  {
    uuid: "8cc23b17-6250-4521-aa2e-b16b1547cd77",
    iconSet: "MaterialCommunityIcons",
    iconName: "elevator-up"
  },
  {
    uuid: "07dc27ad-2401-4efe-896c-a4f3f154faed",
    iconSet: "MaterialCommunityIcons",
    iconName: "ellipse"
  },
  {
    uuid: "56e49a03-ca39-44c3-bdb4-876ddabbfd64",
    iconSet: "MaterialCommunityIcons",
    iconName: "ellipse-outline"
  },
  {
    uuid: "4c85f699-7d5b-4010-aea1-58af880a14c2",
    iconSet: "MaterialCommunityIcons",
    iconName: "email"
  },
  {
    uuid: "37f0eb18-0c95-4826-8248-62388ca1369a",
    iconSet: "MaterialCommunityIcons",
    iconName: "email-alert"
  },
  {
    uuid: "62afc0c2-1a8e-43f2-bae6-9b8c5dc6b2ab",
    iconSet: "MaterialCommunityIcons",
    iconName: "email-alert-outline"
  },
  {
    uuid: "1ed7080b-7c22-43af-a51f-fd7560dc2b19",
    iconSet: "MaterialCommunityIcons",
    iconName: "email-box"
  },
  {
    uuid: "ce4bc176-d174-4ab5-8039-dc0f2a6d947d",
    iconSet: "MaterialCommunityIcons",
    iconName: "email-check"
  },
  {
    uuid: "1e7ec760-3c3b-4f10-a085-68128e682965",
    iconSet: "MaterialCommunityIcons",
    iconName: "email-check-outline"
  },
  {
    uuid: "3ac03743-291a-493c-beee-f371ee099fa0",
    iconSet: "MaterialCommunityIcons",
    iconName: "email-edit"
  },
  {
    uuid: "8f4691d5-6d7d-4f8b-bd78-d456bfe7546f",
    iconSet: "MaterialCommunityIcons",
    iconName: "email-edit-outline"
  },
  {
    uuid: "f0e05ae8-e1ff-4b9b-9180-501611faddfe",
    iconSet: "MaterialCommunityIcons",
    iconName: "email-lock"
  },
  {
    uuid: "dffc365f-3e81-40c7-a3e1-40ec660f9841",
    iconSet: "MaterialCommunityIcons",
    iconName: "email-mark-as-unread"
  },
  {
    uuid: "d90ce685-628b-45a3-ba79-2ede462687dc",
    iconSet: "MaterialCommunityIcons",
    iconName: "email-minus"
  },
  {
    uuid: "a2cf6ec4-eb98-4e02-ae3a-2d43d5bb5da0",
    iconSet: "MaterialCommunityIcons",
    iconName: "email-minus-outline"
  },
  {
    uuid: "5ea295c7-735f-433d-82e1-2a2abbbf15ff",
    iconSet: "MaterialCommunityIcons",
    iconName: "email-multiple"
  },
  {
    uuid: "79027eba-9daf-479d-9bc3-3b3505ffaef8",
    iconSet: "MaterialCommunityIcons",
    iconName: "email-multiple-outline"
  },
  {
    uuid: "5d80da0e-7cff-4dee-931d-e37bbf46ecd1",
    iconSet: "MaterialCommunityIcons",
    iconName: "email-newsletter"
  },
  {
    uuid: "1b1cfba2-ef7b-4c4b-8ecf-26ec6ffb5e61",
    iconSet: "MaterialCommunityIcons",
    iconName: "email-off"
  },
  {
    uuid: "db2979da-fff6-45bb-a146-35d04337b4bc",
    iconSet: "MaterialCommunityIcons",
    iconName: "email-off-outline"
  },
  {
    uuid: "201ae9b5-ae34-413e-b4c0-53a783d3b56d",
    iconSet: "MaterialCommunityIcons",
    iconName: "email-open"
  },
  {
    uuid: "30afe127-f771-41bb-8d71-59f6502b04e8",
    iconSet: "MaterialCommunityIcons",
    iconName: "email-open-multiple"
  },
  {
    uuid: "6fa868cd-e9f0-4245-8fd9-43222359a289",
    iconSet: "MaterialCommunityIcons",
    iconName: "email-open-multiple-outline"
  },
  {
    uuid: "66ad7e30-44b1-49db-a4c8-2456de9f089d",
    iconSet: "MaterialCommunityIcons",
    iconName: "email-open-outline"
  },
  {
    uuid: "73add31a-6f3c-4319-abee-01f36d655abf",
    iconSet: "MaterialCommunityIcons",
    iconName: "email-outline"
  },
  {
    uuid: "9979f838-b770-4b78-97dc-c37bd7a5412b",
    iconSet: "MaterialCommunityIcons",
    iconName: "email-plus"
  },
  {
    uuid: "39b80f6c-b37b-4557-84d4-6e19dfbb04af",
    iconSet: "MaterialCommunityIcons",
    iconName: "email-plus-outline"
  },
  {
    uuid: "337ee161-d38d-4dcb-b757-4e1fd81d12c0",
    iconSet: "MaterialCommunityIcons",
    iconName: "email-receive"
  },
  {
    uuid: "c2155f74-6710-42d1-8005-555c1db4f136",
    iconSet: "MaterialCommunityIcons",
    iconName: "email-receive-outline"
  },
  {
    uuid: "a7be8916-384d-4ffa-bad3-73590e22c7a7",
    iconSet: "MaterialCommunityIcons",
    iconName: "email-search"
  },
  {
    uuid: "972e9aa9-91b7-4bae-a268-8cd50a3ec3f9",
    iconSet: "MaterialCommunityIcons",
    iconName: "email-search-outline"
  },
  {
    uuid: "ad774dd4-bfb3-4321-8ef9-364f3cbbc196",
    iconSet: "MaterialCommunityIcons",
    iconName: "email-send"
  },
  {
    uuid: "dcfca7f1-9b33-417d-a2e9-367f0fe82d7e",
    iconSet: "MaterialCommunityIcons",
    iconName: "email-send-outline"
  },
  {
    uuid: "662a1001-4110-4382-aa27-108349cccfc2",
    iconSet: "MaterialCommunityIcons",
    iconName: "email-sync"
  },
  {
    uuid: "f6d6e6dd-77a2-40b0-a4e3-9d07b012f434",
    iconSet: "MaterialCommunityIcons",
    iconName: "email-sync-outline"
  },
  {
    uuid: "02bd8beb-e87c-4aff-93ee-4d91798d9996",
    iconSet: "MaterialCommunityIcons",
    iconName: "email-variant"
  },
  {
    uuid: "ee581c26-5ee1-48d8-bbb1-4d98d5d745ef",
    iconSet: "MaterialCommunityIcons",
    iconName: "ember"
  },
  {
    uuid: "3249498e-c17e-4bc0-ab63-5e30f1e1eb99",
    iconSet: "MaterialCommunityIcons",
    iconName: "emby"
  },
  {
    uuid: "4f86300f-4280-478d-9f92-9d07cacd57f7",
    iconSet: "MaterialCommunityIcons",
    iconName: "emoticon"
  },
  {
    uuid: "dc720e19-4791-4ee3-b7b0-4946fb0fc689",
    iconSet: "MaterialCommunityIcons",
    iconName: "emoticon-angry"
  },
  {
    uuid: "1c0d3a4c-4efb-4e19-84ef-8a79f942ba68",
    iconSet: "MaterialCommunityIcons",
    iconName: "emoticon-angry-outline"
  },
  {
    uuid: "a15b5a37-98c5-4548-a57b-3309abe34ac9",
    iconSet: "MaterialCommunityIcons",
    iconName: "emoticon-confused"
  },
  {
    uuid: "05d3e1e9-a7c0-48f1-8c48-ba507a93b367",
    iconSet: "MaterialCommunityIcons",
    iconName: "emoticon-confused-outline"
  },
  {
    uuid: "b11b356e-1510-4760-900f-f27fae7b4914",
    iconSet: "MaterialCommunityIcons",
    iconName: "emoticon-cool"
  },
  {
    uuid: "8b4951ce-c604-4d17-b536-949d38330a4b",
    iconSet: "MaterialCommunityIcons",
    iconName: "emoticon-cool-outline"
  },
  {
    uuid: "83d4a48b-f610-41e8-96d6-3e640df9a2e7",
    iconSet: "MaterialCommunityIcons",
    iconName: "emoticon-cry"
  },
  {
    uuid: "9457b9e1-cdf2-434b-b87b-474598910ac2",
    iconSet: "MaterialCommunityIcons",
    iconName: "emoticon-cry-outline"
  },
  {
    uuid: "b3dddbc5-d0ea-48ca-9260-32f7616589cc",
    iconSet: "MaterialCommunityIcons",
    iconName: "emoticon-dead"
  },
  {
    uuid: "89e9c462-bccb-4dbc-bbbd-fb4c0e16222d",
    iconSet: "MaterialCommunityIcons",
    iconName: "emoticon-dead-outline"
  },
  {
    uuid: "6e3d8255-c2de-4cc7-b9f9-24b12a9dcc50",
    iconSet: "MaterialCommunityIcons",
    iconName: "emoticon-devil"
  },
  {
    uuid: "3af06857-d25f-4312-bbd3-8403c551f53e",
    iconSet: "MaterialCommunityIcons",
    iconName: "emoticon-devil-outline"
  },
  {
    uuid: "4bb62b38-612b-4673-9ba1-9f8786698385",
    iconSet: "MaterialCommunityIcons",
    iconName: "emoticon-excited"
  },
  {
    uuid: "1b7f59b2-07ce-4956-aaab-07349cbfcb08",
    iconSet: "MaterialCommunityIcons",
    iconName: "emoticon-excited-outline"
  },
  {
    uuid: "b3cf6ad0-8a8f-4a09-88dd-de33f658c917",
    iconSet: "MaterialCommunityIcons",
    iconName: "emoticon-frown"
  },
  {
    uuid: "240c8deb-bacd-49b4-9140-1be03f6a324e",
    iconSet: "MaterialCommunityIcons",
    iconName: "emoticon-frown-outline"
  },
  {
    uuid: "df482cc2-11fc-4481-b64a-af9749fa4378",
    iconSet: "MaterialCommunityIcons",
    iconName: "emoticon-happy"
  },
  {
    uuid: "b78479e7-170b-413f-97be-834136d262c4",
    iconSet: "MaterialCommunityIcons",
    iconName: "emoticon-happy-outline"
  },
  {
    uuid: "9f470836-2470-4c04-be78-331039797b3c",
    iconSet: "MaterialCommunityIcons",
    iconName: "emoticon-kiss"
  },
  {
    uuid: "daebbb0d-66ed-4d4b-807e-bdc84e446b1f",
    iconSet: "MaterialCommunityIcons",
    iconName: "emoticon-kiss-outline"
  },
  {
    uuid: "4b3aee11-e406-4179-91fa-67c8f7a66c17",
    iconSet: "MaterialCommunityIcons",
    iconName: "emoticon-lol"
  },
  {
    uuid: "9718d286-0627-4e9d-ae6a-1ee357291155",
    iconSet: "MaterialCommunityIcons",
    iconName: "emoticon-lol-outline"
  },
  {
    uuid: "c4579077-9743-46ce-9a5a-f5db29a3dd58",
    iconSet: "MaterialCommunityIcons",
    iconName: "emoticon-neutral"
  },
  {
    uuid: "4eaf0d72-3e55-46c8-aad2-8d8a896375fb",
    iconSet: "MaterialCommunityIcons",
    iconName: "emoticon-neutral-outline"
  },
  {
    uuid: "bbdc65ba-0a8c-453c-b717-730c437cf599",
    iconSet: "MaterialCommunityIcons",
    iconName: "emoticon-outline"
  },
  {
    uuid: "dbf0761d-925b-4a0b-baa0-ac5f41fb0bcb",
    iconSet: "MaterialCommunityIcons",
    iconName: "emoticon-poop"
  },
  {
    uuid: "7f6fe591-b0fb-41ad-84c9-dc842d5823ca",
    iconSet: "MaterialCommunityIcons",
    iconName: "emoticon-poop-outline"
  },
  {
    uuid: "3913041f-2899-41b5-adfa-2447ef74424b",
    iconSet: "MaterialCommunityIcons",
    iconName: "emoticon-sad"
  },
  {
    uuid: "faf20637-7a96-4075-a0dc-59b2abdb8497",
    iconSet: "MaterialCommunityIcons",
    iconName: "emoticon-sad-outline"
  },
  {
    uuid: "aa3ee559-91ba-4c42-9414-122f68834fc0",
    iconSet: "MaterialCommunityIcons",
    iconName: "emoticon-tongue"
  },
  {
    uuid: "920b9e82-940a-4f38-8e38-768267b119e4",
    iconSet: "MaterialCommunityIcons",
    iconName: "emoticon-tongue-outline"
  },
  {
    uuid: "8503c2b5-c351-4277-95e2-1c9a07bac6a0",
    iconSet: "MaterialCommunityIcons",
    iconName: "emoticon-wink"
  },
  {
    uuid: "d474bd3d-6baa-44e5-8688-e069e318206b",
    iconSet: "MaterialCommunityIcons",
    iconName: "emoticon-wink-outline"
  },
  {
    uuid: "e37cade7-eec1-4592-ac55-626b11da3832",
    iconSet: "MaterialCommunityIcons",
    iconName: "engine"
  },
  {
    uuid: "3d2ee097-439e-44a6-801b-c3bb6eec079a",
    iconSet: "MaterialCommunityIcons",
    iconName: "engine-off"
  },
  {
    uuid: "bcacc40d-57f8-4571-8c73-d992e767f03f",
    iconSet: "MaterialCommunityIcons",
    iconName: "engine-off-outline"
  },
  {
    uuid: "d79389b0-8fd7-4f5e-b629-00beb90c1b8e",
    iconSet: "MaterialCommunityIcons",
    iconName: "engine-outline"
  },
  {
    uuid: "132d007e-fbc7-492e-a571-fd91e65e95be",
    iconSet: "MaterialCommunityIcons",
    iconName: "epsilon"
  },
  {
    uuid: "043b6d83-8947-4c53-ad52-2e2b32bc6712",
    iconSet: "MaterialCommunityIcons",
    iconName: "equal"
  },
  {
    uuid: "bf40dec0-c603-4263-85f9-4f26f7d0717c",
    iconSet: "MaterialCommunityIcons",
    iconName: "equal-box"
  },
  {
    uuid: "a2418cab-c828-46e6-8df2-915a13b50b9a",
    iconSet: "MaterialCommunityIcons",
    iconName: "equalizer"
  },
  {
    uuid: "fc30d6f4-5c9d-4420-af55-12a77230af4b",
    iconSet: "MaterialCommunityIcons",
    iconName: "equalizer-outline"
  },
  {
    uuid: "ac9082f8-dd31-491c-adec-8a9b7a5e91b4",
    iconSet: "MaterialCommunityIcons",
    iconName: "eraser"
  },
  {
    uuid: "3b2258de-56ca-46f4-8c3e-f39678ae323c",
    iconSet: "MaterialCommunityIcons",
    iconName: "eraser-variant"
  },
  {
    uuid: "345eb7ba-abe0-46da-aab8-621995a12190",
    iconSet: "MaterialCommunityIcons",
    iconName: "escalator"
  },
  {
    uuid: "ff882fed-6e63-42bd-b9f2-b3bfd042e225",
    iconSet: "MaterialCommunityIcons",
    iconName: "escalator-box"
  },
  {
    uuid: "9fd04829-1081-4ab4-be02-8c32e393a1c9",
    iconSet: "MaterialCommunityIcons",
    iconName: "escalator-down"
  },
  {
    uuid: "8e50e396-5944-4230-8022-7a727645ffc1",
    iconSet: "MaterialCommunityIcons",
    iconName: "escalator-up"
  },
  {
    uuid: "bb888e71-50cc-4464-a436-aba4d13ee9cb",
    iconSet: "MaterialCommunityIcons",
    iconName: "eslint"
  },
  {
    uuid: "beda0d77-a0a7-4598-87c3-1f6bec72bbf3",
    iconSet: "MaterialCommunityIcons",
    iconName: "et"
  },
  {
    uuid: "3cab2e96-7e75-4753-bef2-a1eb4f4a5b6f",
    iconSet: "MaterialCommunityIcons",
    iconName: "ethereum"
  },
  {
    uuid: "330fe562-7d42-44fb-bd4e-2643f82bb5e2",
    iconSet: "MaterialCommunityIcons",
    iconName: "ethernet"
  },
  {
    uuid: "c79d089c-c7a5-4259-b92a-b43dcf9d15ce",
    iconSet: "MaterialCommunityIcons",
    iconName: "ethernet-cable"
  },
  {
    uuid: "fd7d28a7-41f9-409f-a60f-0db76eba59a2",
    iconSet: "MaterialCommunityIcons",
    iconName: "ethernet-cable-off"
  },
  {
    uuid: "3ab8b821-9871-4063-9814-5bcb6240f2da",
    iconSet: "MaterialCommunityIcons",
    iconName: "ev-station"
  },
  {
    uuid: "21217f15-1009-4216-82d6-14f08faa02e2",
    iconSet: "MaterialCommunityIcons",
    iconName: "evernote"
  },
  {
    uuid: "ff24f31f-06d9-4ba5-bdcb-72ff481037c7",
    iconSet: "MaterialCommunityIcons",
    iconName: "excavator"
  },
  {
    uuid: "8a776503-6708-45a9-81c2-e8204a7b3064",
    iconSet: "MaterialCommunityIcons",
    iconName: "exclamation"
  },
  {
    uuid: "c8ec6b6a-2f60-47ea-af6b-97060e4f376f",
    iconSet: "MaterialCommunityIcons",
    iconName: "exclamation-thick"
  },
  {
    uuid: "c3a95268-5644-4013-8662-6523e5c5e576",
    iconSet: "MaterialCommunityIcons",
    iconName: "exit-run"
  },
  {
    uuid: "adc8b330-2355-491a-9d3b-9e15f99579cf",
    iconSet: "MaterialCommunityIcons",
    iconName: "exit-to-app"
  },
  {
    uuid: "800e1d03-46bc-4cd9-a317-2fe311b2747c",
    iconSet: "MaterialCommunityIcons",
    iconName: "expand-all"
  },
  {
    uuid: "cb1eef90-e11a-4b81-a506-902c70a8ce57",
    iconSet: "MaterialCommunityIcons",
    iconName: "expand-all-outline"
  },
  {
    uuid: "1f2a5ac1-5502-4345-ba17-f2a355516814",
    iconSet: "MaterialCommunityIcons",
    iconName: "expansion-card"
  },
  {
    uuid: "89173d1c-7ee3-4418-a1b2-919d9e4097ee",
    iconSet: "MaterialCommunityIcons",
    iconName: "expansion-card-variant"
  },
  {
    uuid: "d3ccfab3-c236-43d6-8b38-05cd3d9d358f",
    iconSet: "MaterialCommunityIcons",
    iconName: "exponent"
  },
  {
    uuid: "d14f08d2-0a31-45dc-b1e2-2bcabd188ab6",
    iconSet: "MaterialCommunityIcons",
    iconName: "exponent-box"
  },
  {
    uuid: "4694be7c-987c-44d5-983d-7741303b59fe",
    iconSet: "MaterialCommunityIcons",
    iconName: "export"
  },
  {
    uuid: "ff87f2c7-80bc-4e84-839f-0ba26e81e178",
    iconSet: "MaterialCommunityIcons",
    iconName: "export-variant"
  },
  {
    uuid: "75339103-549a-4e9e-931b-d4a82924a080",
    iconSet: "MaterialCommunityIcons",
    iconName: "eye"
  },
  {
    uuid: "0d55d63c-3b22-4ce5-bf26-4927de60106e",
    iconSet: "MaterialCommunityIcons",
    iconName: "eye-check"
  },
  {
    uuid: "03465569-5fbf-4a2f-ae4d-192c40d96670",
    iconSet: "MaterialCommunityIcons",
    iconName: "eye-check-outline"
  },
  {
    uuid: "3fbf16e0-9b49-447e-a1fc-ab2c4240a9ce",
    iconSet: "MaterialCommunityIcons",
    iconName: "eye-circle"
  },
  {
    uuid: "4c3b4992-7a61-4d8c-9067-dcb52eeec47c",
    iconSet: "MaterialCommunityIcons",
    iconName: "eye-circle-outline"
  },
  {
    uuid: "f3868072-0520-4c97-91cb-069550717950",
    iconSet: "MaterialCommunityIcons",
    iconName: "eye-minus"
  },
  {
    uuid: "cba08bad-0c67-4bb1-80d9-3a574a43c967",
    iconSet: "MaterialCommunityIcons",
    iconName: "eye-minus-outline"
  },
  {
    uuid: "d643812e-608b-4bc1-8eca-55968229762a",
    iconSet: "MaterialCommunityIcons",
    iconName: "eye-off"
  },
  {
    uuid: "c7d4d67e-3597-430b-9690-36de3f7a95dc",
    iconSet: "MaterialCommunityIcons",
    iconName: "eye-off-outline"
  },
  {
    uuid: "77243b01-d94b-4ac8-b4a6-24033a8f2fc6",
    iconSet: "MaterialCommunityIcons",
    iconName: "eye-outline"
  },
  {
    uuid: "24808ec4-f787-43e3-9c4e-f878db990806",
    iconSet: "MaterialCommunityIcons",
    iconName: "eye-plus"
  },
  {
    uuid: "c190cf11-e7df-4c34-bc28-f45ae08d4967",
    iconSet: "MaterialCommunityIcons",
    iconName: "eye-plus-outline"
  },
  {
    uuid: "baf4bb87-5cff-4fda-b175-4a0cbad15318",
    iconSet: "MaterialCommunityIcons",
    iconName: "eye-settings"
  },
  {
    uuid: "9bb5cf7a-9c13-4e26-a121-2b1501d9b1de",
    iconSet: "MaterialCommunityIcons",
    iconName: "eye-settings-outline"
  },
  {
    uuid: "1c7b120f-6e6e-437f-a5a6-a77ceb87d94f",
    iconSet: "MaterialCommunityIcons",
    iconName: "eyedropper"
  },
  {
    uuid: "48becc1e-e4aa-4414-9552-36c8969de560",
    iconSet: "MaterialCommunityIcons",
    iconName: "eyedropper-minus"
  },
  {
    uuid: "1aaeb703-67a1-4ae3-97aa-fa5386c7ca10",
    iconSet: "MaterialCommunityIcons",
    iconName: "eyedropper-off"
  },
  {
    uuid: "d6148763-5a9c-4b3b-90e3-8e2398900ee2",
    iconSet: "MaterialCommunityIcons",
    iconName: "eyedropper-plus"
  },
  {
    uuid: "622648a7-2279-4b76-87fb-457209325c1c",
    iconSet: "MaterialCommunityIcons",
    iconName: "eyedropper-remove"
  },
  {
    uuid: "9f13a744-13e9-445e-9a5b-4222065627f5",
    iconSet: "MaterialCommunityIcons",
    iconName: "eyedropper-variant"
  },
  {
    uuid: "55f3c20b-73e3-4e9d-b27d-a74e60efd555",
    iconSet: "MaterialCommunityIcons",
    iconName: "face"
  },
  {
    uuid: "0107b245-1f56-46a5-8a44-8eb03a2760b2",
    iconSet: "MaterialCommunityIcons",
    iconName: "face-agent"
  },
  {
    uuid: "db5a9492-5238-4d3b-a3ba-d7837fb7b64e",
    iconSet: "MaterialCommunityIcons",
    iconName: "face-outline"
  },
  {
    uuid: "0e3c3911-f0e2-4ca9-89a3-6d13b840542b",
    iconSet: "MaterialCommunityIcons",
    iconName: "face-profile"
  },
  {
    uuid: "6a808ace-d9a5-4d33-9dec-baccc2ab2967",
    iconSet: "MaterialCommunityIcons",
    iconName: "face-profile-woman"
  },
  {
    uuid: "2df30946-9ff7-4e6e-8b96-b4310bb064b1",
    iconSet: "MaterialCommunityIcons",
    iconName: "face-recognition"
  },
  {
    uuid: "52ad4da9-db54-4147-8cd9-f1ca7c31b572",
    iconSet: "MaterialCommunityIcons",
    iconName: "face-woman"
  },
  {
    uuid: "13f23f76-ce55-4554-898f-2a788d9af824",
    iconSet: "MaterialCommunityIcons",
    iconName: "face-woman-outline"
  },
  {
    uuid: "2d9c6324-5198-4004-afcf-9bb27676c7d4",
    iconSet: "MaterialCommunityIcons",
    iconName: "facebook"
  },
  {
    uuid: "91ad6a3d-f458-4bc2-a816-cc421f6b4b54",
    iconSet: "MaterialCommunityIcons",
    iconName: "facebook-messenger"
  },
  {
    uuid: "2f9da9cb-b1c5-44ff-92e1-02701368a77e",
    iconSet: "MaterialCommunityIcons",
    iconName: "facebook-workplace"
  },
  {
    uuid: "c82de188-566e-41a2-9521-9ddc94b8482e",
    iconSet: "MaterialCommunityIcons",
    iconName: "factory"
  },
  {
    uuid: "e251d22b-7324-4696-9e67-b436ceb39b2e",
    iconSet: "MaterialCommunityIcons",
    iconName: "fan"
  },
  {
    uuid: "b337774c-a79e-4a4e-acc7-5bb4d1486b6e",
    iconSet: "MaterialCommunityIcons",
    iconName: "fan-alert"
  },
  {
    uuid: "1ba68f96-db55-4a47-a04d-66a60350d955",
    iconSet: "MaterialCommunityIcons",
    iconName: "fan-chevron-down"
  },
  {
    uuid: "20a8ae4d-127d-4da5-a786-1645572b8449",
    iconSet: "MaterialCommunityIcons",
    iconName: "fan-chevron-up"
  },
  {
    uuid: "f2b85e8b-a094-485a-a162-4bf4bf9ed284",
    iconSet: "MaterialCommunityIcons",
    iconName: "fan-minus"
  },
  {
    uuid: "fdf72663-ec6e-41ad-bfed-27a6e0428b09",
    iconSet: "MaterialCommunityIcons",
    iconName: "fan-off"
  },
  {
    uuid: "cf6d547e-d050-4343-b5a2-c7457a307619",
    iconSet: "MaterialCommunityIcons",
    iconName: "fan-plus"
  },
  {
    uuid: "229bf401-0df1-4c33-9895-01521c73c6f9",
    iconSet: "MaterialCommunityIcons",
    iconName: "fan-remove"
  },
  {
    uuid: "893b2946-d9d1-446d-8e24-699afd1fc4bb",
    iconSet: "MaterialCommunityIcons",
    iconName: "fan-speed-1"
  },
  {
    uuid: "1aa62de9-40c4-4d39-91de-45f3dc8c221e",
    iconSet: "MaterialCommunityIcons",
    iconName: "fan-speed-2"
  },
  {
    uuid: "380b70df-8563-4653-baac-8fb54f70257f",
    iconSet: "MaterialCommunityIcons",
    iconName: "fan-speed-3"
  },
  {
    uuid: "8915ab8e-6d7a-4db8-8a2a-c3f6610e4b88",
    iconSet: "MaterialCommunityIcons",
    iconName: "fast-forward"
  },
  {
    uuid: "66510443-a8da-4dc8-90df-d32df7a5a2c5",
    iconSet: "MaterialCommunityIcons",
    iconName: "fast-forward-10"
  },
  {
    uuid: "ac88ae38-e933-406d-973d-4aa9857c6049",
    iconSet: "MaterialCommunityIcons",
    iconName: "fast-forward-30"
  },
  {
    uuid: "40c7d11f-1209-49a4-b033-b5dab313291b",
    iconSet: "MaterialCommunityIcons",
    iconName: "fast-forward-5"
  },
  {
    uuid: "88f95181-f2ad-4d34-b884-9971a7fce0f2",
    iconSet: "MaterialCommunityIcons",
    iconName: "fast-forward-outline"
  },
  {
    uuid: "4dc8a7c3-6bf9-41aa-901f-598c61e1e5d8",
    iconSet: "MaterialCommunityIcons",
    iconName: "fax"
  },
  {
    uuid: "d7be9ac4-f338-4ac6-a668-9c71444a5470",
    iconSet: "MaterialCommunityIcons",
    iconName: "feather"
  },
  {
    uuid: "a58d9833-a81b-4c4d-9bed-878d0910c9c5",
    iconSet: "MaterialCommunityIcons",
    iconName: "feature-search"
  },
  {
    uuid: "2ba3811e-4fdb-42f1-9df8-1a951b8564ae",
    iconSet: "MaterialCommunityIcons",
    iconName: "feature-search-outline"
  },
  {
    uuid: "d7ab6c44-9d1c-45c7-a982-015e3d4bb0f6",
    iconSet: "MaterialCommunityIcons",
    iconName: "fedora"
  },
  {
    uuid: "2385f9e1-03fa-4433-b707-b4816efad242",
    iconSet: "MaterialCommunityIcons",
    iconName: "fencing"
  },
  {
    uuid: "16a614a2-3bba-4155-92f1-31be3168528d",
    iconSet: "MaterialCommunityIcons",
    iconName: "ferris-wheel"
  },
  {
    uuid: "90d6134a-8ec3-48f4-aca1-a040521e3901",
    iconSet: "MaterialCommunityIcons",
    iconName: "ferry"
  },
  {
    uuid: "f4d0429b-4541-498b-a379-eb2af72a057a",
    iconSet: "MaterialCommunityIcons",
    iconName: "file"
  },
  {
    uuid: "6154898a-1bab-4370-8132-4dd3f13628cf",
    iconSet: "MaterialCommunityIcons",
    iconName: "file-account"
  },
  {
    uuid: "7fd62f84-f721-46f8-8fb5-2a1e221d59d1",
    iconSet: "MaterialCommunityIcons",
    iconName: "file-account-outline"
  },
  {
    uuid: "0b335d67-8821-456c-aa8f-09c2fd5240d4",
    iconSet: "MaterialCommunityIcons",
    iconName: "file-alert"
  },
  {
    uuid: "141e8dd9-b27f-400b-8d64-1dadbde5d062",
    iconSet: "MaterialCommunityIcons",
    iconName: "file-alert-outline"
  },
  {
    uuid: "e0ecbb90-4543-435e-bc78-5a7ee2da65c2",
    iconSet: "MaterialCommunityIcons",
    iconName: "file-cabinet"
  },
  {
    uuid: "869f51b4-2ea6-4111-977d-f175f8d10b02",
    iconSet: "MaterialCommunityIcons",
    iconName: "file-cad"
  },
  {
    uuid: "62ff6637-dbb8-4828-a9e8-3d17ffe2ea55",
    iconSet: "MaterialCommunityIcons",
    iconName: "file-cad-box"
  },
  {
    uuid: "386f9f28-69ed-4ba8-8a94-1b8d5dade0e3",
    iconSet: "MaterialCommunityIcons",
    iconName: "file-cancel"
  },
  {
    uuid: "244aecf1-2d93-4ab7-bc3a-62a7fcc40276",
    iconSet: "MaterialCommunityIcons",
    iconName: "file-cancel-outline"
  },
  {
    uuid: "e25bf683-13f5-484d-8a79-eb57b51e9bcb",
    iconSet: "MaterialCommunityIcons",
    iconName: "file-certificate"
  },
  {
    uuid: "40f9fe87-3cfa-445c-b35f-0d07b05c9fcf",
    iconSet: "MaterialCommunityIcons",
    iconName: "file-certificate-outline"
  },
  {
    uuid: "08bc13d7-90e4-4b02-9991-cf713f5efb05",
    iconSet: "MaterialCommunityIcons",
    iconName: "file-chart"
  },
  {
    uuid: "2ffb948c-4d8f-4087-b782-9d5819cab8aa",
    iconSet: "MaterialCommunityIcons",
    iconName: "file-chart-outline"
  },
  {
    uuid: "c573d06b-bd39-4176-b288-c7027485e8b1",
    iconSet: "MaterialCommunityIcons",
    iconName: "file-check"
  },
  {
    uuid: "a661b821-a861-409f-8e86-ecebcb8a4ac9",
    iconSet: "MaterialCommunityIcons",
    iconName: "file-check-outline"
  },
  {
    uuid: "1af1380b-ee0d-412d-b2b1-2bcf1e87d990",
    iconSet: "MaterialCommunityIcons",
    iconName: "file-clock"
  },
  {
    uuid: "270778a3-87c2-4ce6-b509-1541ba959561",
    iconSet: "MaterialCommunityIcons",
    iconName: "file-clock-outline"
  },
  {
    uuid: "493bf0aa-316f-42e9-84be-8072bf79d245",
    iconSet: "MaterialCommunityIcons",
    iconName: "file-cloud"
  },
  {
    uuid: "b6cedaaf-7d9d-4dca-8622-7cd128e87861",
    iconSet: "MaterialCommunityIcons",
    iconName: "file-cloud-outline"
  },
  {
    uuid: "e9af966f-c09d-4e45-8bb9-f0e7f9b99dc3",
    iconSet: "MaterialCommunityIcons",
    iconName: "file-code"
  },
  {
    uuid: "805d6b1e-32f2-46a9-8182-ed7e97112003",
    iconSet: "MaterialCommunityIcons",
    iconName: "file-code-outline"
  },
  {
    uuid: "e4d748cd-0213-45a1-93a2-0e73bf6b8920",
    iconSet: "MaterialCommunityIcons",
    iconName: "file-cog"
  },
  {
    uuid: "dce700ae-807a-4a16-8358-e98235b8187a",
    iconSet: "MaterialCommunityIcons",
    iconName: "file-cog-outline"
  },
  {
    uuid: "9b2e5ad8-016e-4f38-9033-72a983b04fd9",
    iconSet: "MaterialCommunityIcons",
    iconName: "file-compare"
  },
  {
    uuid: "c153a4cf-82fd-4eaa-b07d-369c9679dfbb",
    iconSet: "MaterialCommunityIcons",
    iconName: "file-delimited"
  },
  {
    uuid: "6dd59c82-0bf0-4b24-8725-6783b8ded817",
    iconSet: "MaterialCommunityIcons",
    iconName: "file-delimited-outline"
  },
  {
    uuid: "3a6d7679-6ab6-4723-8030-3dc23726f8ed",
    iconSet: "MaterialCommunityIcons",
    iconName: "file-document"
  },
  {
    uuid: "9ca143f6-978b-498f-a0e0-55eafca0a8c4",
    iconSet: "MaterialCommunityIcons",
    iconName: "file-document-edit"
  },
  {
    uuid: "0e1ad1ea-7ecf-4703-946d-1a3dbdebeaf2",
    iconSet: "MaterialCommunityIcons",
    iconName: "file-document-edit-outline"
  },
  {
    uuid: "5c663499-d510-4626-9030-6a5f41e5a6e4",
    iconSet: "MaterialCommunityIcons",
    iconName: "file-document-outline"
  },
  {
    uuid: "52982a5c-f006-4b39-a827-370b7fd1a805",
    iconSet: "MaterialCommunityIcons",
    iconName: "file-download"
  },
  {
    uuid: "744de417-ffda-490d-af88-7404eec63176",
    iconSet: "MaterialCommunityIcons",
    iconName: "file-download-outline"
  },
  {
    uuid: "db622918-1cfd-4974-b9ad-4cf744f568b4",
    iconSet: "MaterialCommunityIcons",
    iconName: "file-edit"
  },
  {
    uuid: "a13aa48c-938a-49fe-80d6-08df7106e647",
    iconSet: "MaterialCommunityIcons",
    iconName: "file-edit-outline"
  },
  {
    uuid: "52d52097-6a3f-4667-ab29-d3d9e9796176",
    iconSet: "MaterialCommunityIcons",
    iconName: "file-excel"
  },
  {
    uuid: "9791885c-ee07-4d93-a245-bbe518568aa6",
    iconSet: "MaterialCommunityIcons",
    iconName: "file-excel-box"
  },
  {
    uuid: "94c69b47-0116-4222-927a-d66a6b74dc0e",
    iconSet: "MaterialCommunityIcons",
    iconName: "file-excel-box-outline"
  },
  {
    uuid: "7914aadb-6126-4be2-9a3d-a567f3070b82",
    iconSet: "MaterialCommunityIcons",
    iconName: "file-excel-outline"
  },
  {
    uuid: "25d55457-1947-42ca-87c7-012724549a08",
    iconSet: "MaterialCommunityIcons",
    iconName: "file-export"
  },
  {
    uuid: "5c092b30-818f-4bd4-965a-e583e25eed84",
    iconSet: "MaterialCommunityIcons",
    iconName: "file-export-outline"
  },
  {
    uuid: "ce8b8dbb-5aec-482c-95b5-2c680a907f80",
    iconSet: "MaterialCommunityIcons",
    iconName: "file-eye"
  },
  {
    uuid: "db033a96-bc2e-4b57-8c26-eb6b8d8e365d",
    iconSet: "MaterialCommunityIcons",
    iconName: "file-eye-outline"
  },
  {
    uuid: "e081ce8b-17ae-48c9-b946-03b62bafa7b1",
    iconSet: "MaterialCommunityIcons",
    iconName: "file-find"
  },
  {
    uuid: "d8fdcef4-4505-4bc5-b64d-f8c41c5c3075",
    iconSet: "MaterialCommunityIcons",
    iconName: "file-find-outline"
  },
  {
    uuid: "20bbf5c5-367f-45de-af50-2b79fa5de6a4",
    iconSet: "MaterialCommunityIcons",
    iconName: "file-hidden"
  },
  {
    uuid: "ed3339eb-8136-425c-b183-31cdc33a1629",
    iconSet: "MaterialCommunityIcons",
    iconName: "file-image"
  },
  {
    uuid: "850ea53a-d8c8-46ef-ba5a-5daf6ed28f7f",
    iconSet: "MaterialCommunityIcons",
    iconName: "file-image-outline"
  },
  {
    uuid: "c911bc77-8a53-4277-a565-a89ef7b23773",
    iconSet: "MaterialCommunityIcons",
    iconName: "file-import"
  },
  {
    uuid: "4c345e74-3c81-4545-92bb-6ed0e5f7544e",
    iconSet: "MaterialCommunityIcons",
    iconName: "file-import-outline"
  },
  {
    uuid: "ea412038-f788-4d1e-9a8f-dee15ada028a",
    iconSet: "MaterialCommunityIcons",
    iconName: "file-key"
  },
  {
    uuid: "825e5802-71dd-4b32-93b4-419f04806c76",
    iconSet: "MaterialCommunityIcons",
    iconName: "file-key-outline"
  },
  {
    uuid: "c825637b-cd83-49b5-907b-685c2b545d5f",
    iconSet: "MaterialCommunityIcons",
    iconName: "file-link"
  },
  {
    uuid: "a9710e90-609e-4321-9e00-5d5f718ef560",
    iconSet: "MaterialCommunityIcons",
    iconName: "file-link-outline"
  },
  {
    uuid: "24847ff6-3a40-4bde-b25f-a77d78541199",
    iconSet: "MaterialCommunityIcons",
    iconName: "file-lock"
  },
  {
    uuid: "5157e996-0c25-422a-a9d0-be683c33ed4d",
    iconSet: "MaterialCommunityIcons",
    iconName: "file-lock-outline"
  },
  {
    uuid: "0fbae400-32a4-49ad-bcf6-a795d8b605fb",
    iconSet: "MaterialCommunityIcons",
    iconName: "file-move"
  },
  {
    uuid: "e4f9c7f5-65de-4e82-9438-b7e0de12fd0b",
    iconSet: "MaterialCommunityIcons",
    iconName: "file-move-outline"
  },
  {
    uuid: "604023bd-85fa-40b7-b44e-78bd24145e1c",
    iconSet: "MaterialCommunityIcons",
    iconName: "file-multiple"
  },
  {
    uuid: "9a2bb065-0b7f-4781-a941-2cf4c5f363b2",
    iconSet: "MaterialCommunityIcons",
    iconName: "file-multiple-outline"
  },
  {
    uuid: "33d44cd6-e7ca-436b-abda-61118a2a26bf",
    iconSet: "MaterialCommunityIcons",
    iconName: "file-music"
  },
  {
    uuid: "4b2b87b7-c463-4243-baaf-fb8b083d5b14",
    iconSet: "MaterialCommunityIcons",
    iconName: "file-music-outline"
  },
  {
    uuid: "e670ed26-3c2a-490e-81da-4043c6fee912",
    iconSet: "MaterialCommunityIcons",
    iconName: "file-outline"
  },
  {
    uuid: "45d61108-4332-40c5-9ce3-99d962528956",
    iconSet: "MaterialCommunityIcons",
    iconName: "file-pdf"
  },
  {
    uuid: "a162f706-ca9e-48c9-8e0b-18f273b4c5eb",
    iconSet: "MaterialCommunityIcons",
    iconName: "file-pdf-box"
  },
  {
    uuid: "2a140117-e37b-4d6a-ad97-9ccdd7dae8b3",
    iconSet: "MaterialCommunityIcons",
    iconName: "file-pdf-box-outline"
  },
  {
    uuid: "264c0427-046f-4ff1-b21b-1f778a321ba9",
    iconSet: "MaterialCommunityIcons",
    iconName: "file-pdf-outline"
  },
  {
    uuid: "b72eea6e-f54c-4710-ad3a-b2b2d86d1ea4",
    iconSet: "MaterialCommunityIcons",
    iconName: "file-percent"
  },
  {
    uuid: "85057879-6732-4903-a866-12e5654174b4",
    iconSet: "MaterialCommunityIcons",
    iconName: "file-percent-outline"
  },
  {
    uuid: "987844dd-1f76-44b9-881d-f144f674f2d8",
    iconSet: "MaterialCommunityIcons",
    iconName: "file-phone"
  },
  {
    uuid: "7981a65b-d1b2-48f4-9b9c-af37bdd226a9",
    iconSet: "MaterialCommunityIcons",
    iconName: "file-phone-outline"
  },
  {
    uuid: "dbea07c8-ee0f-49ec-9601-96c3f7b1b375",
    iconSet: "MaterialCommunityIcons",
    iconName: "file-plus"
  },
  {
    uuid: "6a91910e-2aa0-4999-adc6-a15e8c9a15a9",
    iconSet: "MaterialCommunityIcons",
    iconName: "file-plus-outline"
  },
  {
    uuid: "cb47af36-3e2c-4bbf-a645-adf47f3b3858",
    iconSet: "MaterialCommunityIcons",
    iconName: "file-powerpoint"
  },
  {
    uuid: "e6f0bcc8-87d1-47b2-9d97-1acf45bcfd74",
    iconSet: "MaterialCommunityIcons",
    iconName: "file-powerpoint-box"
  },
  {
    uuid: "eef5dc68-9270-4df6-9fa0-440c65f75b83",
    iconSet: "MaterialCommunityIcons",
    iconName: "file-powerpoint-box-outline"
  },
  {
    uuid: "680a4f80-961b-487c-aff3-b2dd9fa7ba3d",
    iconSet: "MaterialCommunityIcons",
    iconName: "file-powerpoint-outline"
  },
  {
    uuid: "25cbd879-0863-4eae-9e5e-661a9359e20a",
    iconSet: "MaterialCommunityIcons",
    iconName: "file-presentation-box"
  },
  {
    uuid: "75d85c21-90d4-4f81-b9e8-38417fd8f742",
    iconSet: "MaterialCommunityIcons",
    iconName: "file-question"
  },
  {
    uuid: "7bb035bc-f603-4764-bab4-5be6c632846c",
    iconSet: "MaterialCommunityIcons",
    iconName: "file-question-outline"
  },
  {
    uuid: "37861de0-b499-4e9b-a874-63d6ade085ee",
    iconSet: "MaterialCommunityIcons",
    iconName: "file-refresh"
  },
  {
    uuid: "24893242-8a3a-496e-9d24-81d85c1f9fb2",
    iconSet: "MaterialCommunityIcons",
    iconName: "file-refresh-outline"
  },
  {
    uuid: "9124fffd-2dd9-4841-8255-7c930f957924",
    iconSet: "MaterialCommunityIcons",
    iconName: "file-remove"
  },
  {
    uuid: "4bd46a0d-2e87-4c76-a78c-6e6a8b972862",
    iconSet: "MaterialCommunityIcons",
    iconName: "file-remove-outline"
  },
  {
    uuid: "20d58e96-210b-4929-99f8-6f594f99c9a1",
    iconSet: "MaterialCommunityIcons",
    iconName: "file-replace"
  },
  {
    uuid: "1a0fb204-0d7e-4fc2-8c65-66c3f15fb6ab",
    iconSet: "MaterialCommunityIcons",
    iconName: "file-replace-outline"
  },
  {
    uuid: "78476104-2538-4275-aff8-ffdd9fd0cf94",
    iconSet: "MaterialCommunityIcons",
    iconName: "file-restore"
  },
  {
    uuid: "823d0bef-dc43-45e8-88b3-7504cb34c102",
    iconSet: "MaterialCommunityIcons",
    iconName: "file-restore-outline"
  },
  {
    uuid: "f99aa9ea-74a4-42be-b28f-e96df01d3fc0",
    iconSet: "MaterialCommunityIcons",
    iconName: "file-search"
  },
  {
    uuid: "d44b470d-1f1d-454e-8afe-2396b76ad272",
    iconSet: "MaterialCommunityIcons",
    iconName: "file-search-outline"
  },
  {
    uuid: "359e16da-5ce0-4462-831a-2cb28af0c858",
    iconSet: "MaterialCommunityIcons",
    iconName: "file-send"
  },
  {
    uuid: "2132e800-3931-45bd-9043-c40221d306da",
    iconSet: "MaterialCommunityIcons",
    iconName: "file-send-outline"
  },
  {
    uuid: "ba673cac-cbe4-4657-874c-2718458945b0",
    iconSet: "MaterialCommunityIcons",
    iconName: "file-settings"
  },
  {
    uuid: "2ec2b7a3-4a60-44dc-a501-46b9b21d07ee",
    iconSet: "MaterialCommunityIcons",
    iconName: "file-settings-outline"
  },
  {
    uuid: "874449a2-962c-4dd8-9e15-955bea08d852",
    iconSet: "MaterialCommunityIcons",
    iconName: "file-star"
  },
  {
    uuid: "9184a26a-b0f4-4994-b3be-4e66220b5da7",
    iconSet: "MaterialCommunityIcons",
    iconName: "file-star-outline"
  },
  {
    uuid: "e9fe369f-070d-4576-9c34-c3514d5f821c",
    iconSet: "MaterialCommunityIcons",
    iconName: "file-swap"
  },
  {
    uuid: "15047852-bf5b-4ef4-b485-0a940640fd0a",
    iconSet: "MaterialCommunityIcons",
    iconName: "file-swap-outline"
  },
  {
    uuid: "207893a7-c3ae-4a64-969f-69a00e5dc618",
    iconSet: "MaterialCommunityIcons",
    iconName: "file-sync"
  },
  {
    uuid: "aa05dc26-e4b9-4d50-ae14-abd04c29cd83",
    iconSet: "MaterialCommunityIcons",
    iconName: "file-sync-outline"
  },
  {
    uuid: "3199f92a-aa8d-4986-bd91-c39cb6bc40a6",
    iconSet: "MaterialCommunityIcons",
    iconName: "file-table"
  },
  {
    uuid: "ab9a8ca3-e26f-4bb4-8dc9-c047752c8ce9",
    iconSet: "MaterialCommunityIcons",
    iconName: "file-table-box"
  },
  {
    uuid: "77b509bd-6da0-4810-b658-8cc44df06060",
    iconSet: "MaterialCommunityIcons",
    iconName: "file-table-box-multiple"
  },
  {
    uuid: "edf5be1a-047f-4026-bba8-3b4c336509a4",
    iconSet: "MaterialCommunityIcons",
    iconName: "file-table-box-multiple-outline"
  },
  {
    uuid: "8dcb6022-a2fd-44da-a925-c081c3821b13",
    iconSet: "MaterialCommunityIcons",
    iconName: "file-table-box-outline"
  },
  {
    uuid: "da33c432-b313-4d1d-ac55-c1ddf26139f1",
    iconSet: "MaterialCommunityIcons",
    iconName: "file-table-outline"
  },
  {
    uuid: "be13851a-0e0c-4a9e-9648-79b4f36176e1",
    iconSet: "MaterialCommunityIcons",
    iconName: "file-tree"
  },
  {
    uuid: "20481fc2-8922-4fe6-af98-42785e1d289f",
    iconSet: "MaterialCommunityIcons",
    iconName: "file-tree-outline"
  },
  {
    uuid: "48880de0-ead0-49de-bf0d-bbd109fb65b8",
    iconSet: "MaterialCommunityIcons",
    iconName: "file-undo"
  },
  {
    uuid: "c75620e8-264b-46d0-b486-50228ef59879",
    iconSet: "MaterialCommunityIcons",
    iconName: "file-undo-outline"
  },
  {
    uuid: "4c839f50-6701-4ed7-98db-0cc64ba567bf",
    iconSet: "MaterialCommunityIcons",
    iconName: "file-upload"
  },
  {
    uuid: "9ac06ac2-cf27-4db8-bbcd-5cb3ffdc0a85",
    iconSet: "MaterialCommunityIcons",
    iconName: "file-upload-outline"
  },
  {
    uuid: "354d13c0-60ec-4702-87fb-a2b4b61ce947",
    iconSet: "MaterialCommunityIcons",
    iconName: "file-video"
  },
  {
    uuid: "2d8366a3-bb08-45e7-a8a4-370d521743e1",
    iconSet: "MaterialCommunityIcons",
    iconName: "file-video-outline"
  },
  {
    uuid: "e956b7e6-d081-4fbd-ade1-f9b88bd97809",
    iconSet: "MaterialCommunityIcons",
    iconName: "file-word"
  },
  {
    uuid: "331cf75c-8efe-4e58-878c-2dce1f60641a",
    iconSet: "MaterialCommunityIcons",
    iconName: "file-word-box"
  },
  {
    uuid: "3f2a3b15-367f-441d-89fb-0de3f43394fc",
    iconSet: "MaterialCommunityIcons",
    iconName: "file-word-box-outline"
  },
  {
    uuid: "94c2e65e-eea1-4320-a20d-df8f2aeb045a",
    iconSet: "MaterialCommunityIcons",
    iconName: "file-word-outline"
  },
  {
    uuid: "8f92e442-1bcc-44dd-b0ed-6d97ef40ec00",
    iconSet: "MaterialCommunityIcons",
    iconName: "film"
  },
  {
    uuid: "03486b8c-857c-4d59-89a3-02107c6ae40f",
    iconSet: "MaterialCommunityIcons",
    iconName: "filmstrip"
  },
  {
    uuid: "50e34d83-a218-46d2-9731-28f780e6fbe7",
    iconSet: "MaterialCommunityIcons",
    iconName: "filmstrip-box"
  },
  {
    uuid: "2d0f2e86-ff2d-48ef-b3b8-160dcab8b038",
    iconSet: "MaterialCommunityIcons",
    iconName: "filmstrip-box-multiple"
  },
  {
    uuid: "37fbafd6-8a45-468b-af94-780e323d6344",
    iconSet: "MaterialCommunityIcons",
    iconName: "filmstrip-off"
  },
  {
    uuid: "9df7236e-e45a-435b-9a8e-ff93b46be438",
    iconSet: "MaterialCommunityIcons",
    iconName: "filter"
  },
  {
    uuid: "f75f6a6c-9d9d-4a60-9f93-b2ad946317ea",
    iconSet: "MaterialCommunityIcons",
    iconName: "filter-menu"
  },
  {
    uuid: "3f7c0b08-cf44-4d02-9401-6eb6de2d0ab4",
    iconSet: "MaterialCommunityIcons",
    iconName: "filter-menu-outline"
  },
  {
    uuid: "040e9a28-97b7-4604-b713-6d34ef661ba5",
    iconSet: "MaterialCommunityIcons",
    iconName: "filter-minus"
  },
  {
    uuid: "e9e4030f-e74c-4985-93be-b48e235da88f",
    iconSet: "MaterialCommunityIcons",
    iconName: "filter-minus-outline"
  },
  {
    uuid: "9520ee3b-8e8d-466c-bde2-96c5877e71ef",
    iconSet: "MaterialCommunityIcons",
    iconName: "filter-outline"
  },
  {
    uuid: "f9b8f638-59f5-430d-a0d3-6d07dc79904d",
    iconSet: "MaterialCommunityIcons",
    iconName: "filter-plus"
  },
  {
    uuid: "e2d22819-5149-4cfc-b331-67fff07ceaee",
    iconSet: "MaterialCommunityIcons",
    iconName: "filter-plus-outline"
  },
  {
    uuid: "84000af2-6153-49b5-b971-1d4d091f11b1",
    iconSet: "MaterialCommunityIcons",
    iconName: "filter-remove"
  },
  {
    uuid: "2f5e5126-9d61-426d-b10d-0239e00ee417",
    iconSet: "MaterialCommunityIcons",
    iconName: "filter-remove-outline"
  },
  {
    uuid: "cbde811b-90e5-48a3-b2b1-91ae65757368",
    iconSet: "MaterialCommunityIcons",
    iconName: "filter-variant"
  },
  {
    uuid: "af187636-03b4-445c-a623-da250c45c758",
    iconSet: "MaterialCommunityIcons",
    iconName: "filter-variant-minus"
  },
  {
    uuid: "76dc19ba-40b7-4ad0-a6bd-9312956249a1",
    iconSet: "MaterialCommunityIcons",
    iconName: "filter-variant-plus"
  },
  {
    uuid: "e41aeced-5ed9-4541-88d2-89fb2323fb55",
    iconSet: "MaterialCommunityIcons",
    iconName: "filter-variant-remove"
  },
  {
    uuid: "66b4724a-a4a4-40de-beeb-eeea44d64e0d",
    iconSet: "MaterialCommunityIcons",
    iconName: "finance"
  },
  {
    uuid: "f119a5a4-d0ae-4fea-b990-1a62dbba91aa",
    iconSet: "MaterialCommunityIcons",
    iconName: "find-replace"
  },
  {
    uuid: "99b2b2e2-2a10-4d5a-a94e-556ac7440ec3",
    iconSet: "MaterialCommunityIcons",
    iconName: "fingerprint"
  },
  {
    uuid: "cd7f63da-a9c4-4751-b294-97d6589c5c17",
    iconSet: "MaterialCommunityIcons",
    iconName: "fingerprint-off"
  },
  {
    uuid: "a9af1831-3394-419f-b468-a45c6412a742",
    iconSet: "MaterialCommunityIcons",
    iconName: "fire"
  },
  {
    uuid: "d582142f-ca3b-43b7-844d-2cf33389c751",
    iconSet: "MaterialCommunityIcons",
    iconName: "fire-extinguisher"
  },
  {
    uuid: "2fff029d-85af-4e3b-a381-ab1eb1c34556",
    iconSet: "MaterialCommunityIcons",
    iconName: "fire-hydrant"
  },
  {
    uuid: "dbbf2fed-bcd5-478b-8625-8337fc2be873",
    iconSet: "MaterialCommunityIcons",
    iconName: "fire-hydrant-alert"
  },
  {
    uuid: "489e4387-15c8-491e-8fa3-9104ca241d3c",
    iconSet: "MaterialCommunityIcons",
    iconName: "fire-hydrant-off"
  },
  {
    uuid: "3e17c5ce-d149-4ad2-8b01-ed8e2016dcf7",
    iconSet: "MaterialCommunityIcons",
    iconName: "fire-truck"
  },
  {
    uuid: "b3055e6f-5478-43ed-b440-1382c28812cd",
    iconSet: "MaterialCommunityIcons",
    iconName: "firebase"
  },
  {
    uuid: "a8250009-6e5d-4d0e-abf1-b8d3e3cb7659",
    iconSet: "MaterialCommunityIcons",
    iconName: "firefox"
  },
  {
    uuid: "1b936db1-701c-419f-8b32-6ac2f715a22e",
    iconSet: "MaterialCommunityIcons",
    iconName: "fireplace"
  },
  {
    uuid: "a3341c2d-dbc3-4455-840b-d3429ee94bc4",
    iconSet: "MaterialCommunityIcons",
    iconName: "fireplace-off"
  },
  {
    uuid: "8ea2d4cb-0d60-4ff2-90ad-41a5d2990ee1",
    iconSet: "MaterialCommunityIcons",
    iconName: "firework"
  },
  {
    uuid: "031d1ad9-3119-4236-a4a7-db0a6a54e78c",
    iconSet: "MaterialCommunityIcons",
    iconName: "fish"
  },
  {
    uuid: "d5a0e618-79db-417b-a338-9072f9e2ac92",
    iconSet: "MaterialCommunityIcons",
    iconName: "fish-off"
  },
  {
    uuid: "33ad6776-a193-493c-ac84-cbce7f57cf3d",
    iconSet: "MaterialCommunityIcons",
    iconName: "fishbowl"
  },
  {
    uuid: "3f11634c-ee5a-499f-bfc1-733a3fc3a8c3",
    iconSet: "MaterialCommunityIcons",
    iconName: "fishbowl-outline"
  },
  {
    uuid: "db4715f5-74d1-4478-a9b5-fd87930ef612",
    iconSet: "MaterialCommunityIcons",
    iconName: "fit-to-page"
  },
  {
    uuid: "fdd2ab5b-8fc8-4af9-bcd3-ef0d6bdb5dfb",
    iconSet: "MaterialCommunityIcons",
    iconName: "fit-to-page-outline"
  },
  {
    uuid: "7afffd97-660f-427b-a678-1c27f987d54b",
    iconSet: "MaterialCommunityIcons",
    iconName: "flag"
  },
  {
    uuid: "7d483ef9-0ccd-4e62-8a7f-0c75d22d6776",
    iconSet: "MaterialCommunityIcons",
    iconName: "flag-checkered"
  },
  {
    uuid: "34c8b6a5-cb64-4073-8e0e-081c6a277c9b",
    iconSet: "MaterialCommunityIcons",
    iconName: "flag-minus"
  },
  {
    uuid: "a15b09d1-5c0c-41fe-a931-2eff5fc138a9",
    iconSet: "MaterialCommunityIcons",
    iconName: "flag-minus-outline"
  },
  {
    uuid: "8f3008ea-59ed-48d4-957f-04db48b07efc",
    iconSet: "MaterialCommunityIcons",
    iconName: "flag-outline"
  },
  {
    uuid: "3cb2d41b-f4de-4e2d-90b6-c45977b83e07",
    iconSet: "MaterialCommunityIcons",
    iconName: "flag-plus"
  },
  {
    uuid: "f720cbb7-6a6d-4b68-af32-ec57e78b59c9",
    iconSet: "MaterialCommunityIcons",
    iconName: "flag-plus-outline"
  },
  {
    uuid: "cdb54114-e36e-4023-a2e8-852018de5285",
    iconSet: "MaterialCommunityIcons",
    iconName: "flag-remove"
  },
  {
    uuid: "c25e93d1-89d8-4358-a1ef-143fe3f6a8c9",
    iconSet: "MaterialCommunityIcons",
    iconName: "flag-remove-outline"
  },
  {
    uuid: "18bff782-808e-4a65-b673-cde1950c8863",
    iconSet: "MaterialCommunityIcons",
    iconName: "flag-triangle"
  },
  {
    uuid: "36d90e8a-e84c-4c75-b88a-a37e88d83dd9",
    iconSet: "MaterialCommunityIcons",
    iconName: "flag-variant"
  },
  {
    uuid: "52efac47-e672-4fb5-ac23-45ef50487a64",
    iconSet: "MaterialCommunityIcons",
    iconName: "flag-variant-outline"
  },
  {
    uuid: "3c22b277-8db9-4807-b985-db0a85d594ec",
    iconSet: "MaterialCommunityIcons",
    iconName: "flare"
  },
  {
    uuid: "b5e9e854-407a-40e2-8fb8-0884cf91f532",
    iconSet: "MaterialCommunityIcons",
    iconName: "flash"
  },
  {
    uuid: "c2a8ff14-7c5e-4b54-9ecf-56e45026d387",
    iconSet: "MaterialCommunityIcons",
    iconName: "flash-alert"
  },
  {
    uuid: "6a86ab18-b21b-4165-b6a6-d69bfa8fca69",
    iconSet: "MaterialCommunityIcons",
    iconName: "flash-alert-outline"
  },
  {
    uuid: "58c194ac-b804-4636-aa52-0ba89cff0ccc",
    iconSet: "MaterialCommunityIcons",
    iconName: "flash-auto"
  },
  {
    uuid: "9ba05f19-cdd0-4fa0-97f9-48fb3c09c46e",
    iconSet: "MaterialCommunityIcons",
    iconName: "flash-circle"
  },
  {
    uuid: "8c401868-89a9-4b14-8284-a3a056c708a9",
    iconSet: "MaterialCommunityIcons",
    iconName: "flash-off"
  },
  {
    uuid: "9d21bc89-ea6f-4b5e-ac7a-9219b8de3bf0",
    iconSet: "MaterialCommunityIcons",
    iconName: "flash-outline"
  },
  {
    uuid: "0b9d3c22-baf3-451c-8dd7-80bae52fe6f2",
    iconSet: "MaterialCommunityIcons",
    iconName: "flash-red-eye"
  },
  {
    uuid: "8302a69c-e752-4205-ae44-8bd822104b31",
    iconSet: "MaterialCommunityIcons",
    iconName: "flashlight"
  },
  {
    uuid: "e914c949-dd64-4308-bac8-8ad1352c9ce2",
    iconSet: "MaterialCommunityIcons",
    iconName: "flashlight-off"
  },
  {
    uuid: "231162af-2702-461b-8c63-09bd3b73c413",
    iconSet: "MaterialCommunityIcons",
    iconName: "flask"
  },
  {
    uuid: "fbd28307-41a7-4e47-982e-02fee7b0859c",
    iconSet: "MaterialCommunityIcons",
    iconName: "flask-empty"
  },
  {
    uuid: "dbd1e628-b70e-43a4-b65f-320f293cc61d",
    iconSet: "MaterialCommunityIcons",
    iconName: "flask-empty-minus"
  },
  {
    uuid: "7ef898fb-90ed-43b6-8644-bb0b95aee34d",
    iconSet: "MaterialCommunityIcons",
    iconName: "flask-empty-minus-outline"
  },
  {
    uuid: "dac553d3-5231-4c7f-a0f5-6f79e0593480",
    iconSet: "MaterialCommunityIcons",
    iconName: "flask-empty-off"
  },
  {
    uuid: "dff028cb-1917-4d5b-9b07-022557536b8f",
    iconSet: "MaterialCommunityIcons",
    iconName: "flask-empty-off-outline"
  },
  {
    uuid: "8f9b986c-e3a4-4ef6-88b2-dedb993d0e68",
    iconSet: "MaterialCommunityIcons",
    iconName: "flask-empty-outline"
  },
  {
    uuid: "01ea2dbc-b8b8-4f7f-8932-1ca1274dccdf",
    iconSet: "MaterialCommunityIcons",
    iconName: "flask-empty-plus"
  },
  {
    uuid: "60b244f8-5e1f-439c-8e81-74808bc73290",
    iconSet: "MaterialCommunityIcons",
    iconName: "flask-empty-plus-outline"
  },
  {
    uuid: "6ce81f9b-93e6-4a6a-a2b4-0cdc1fb10b8d",
    iconSet: "MaterialCommunityIcons",
    iconName: "flask-empty-remove"
  },
  {
    uuid: "d61eda74-9d5e-427c-9d03-14d37e1109a9",
    iconSet: "MaterialCommunityIcons",
    iconName: "flask-empty-remove-outline"
  },
  {
    uuid: "1b824b9b-117c-4aa6-a327-b5b0d4d19879",
    iconSet: "MaterialCommunityIcons",
    iconName: "flask-minus"
  },
  {
    uuid: "17cb1421-5de8-41a8-927e-a1006b5ec99f",
    iconSet: "MaterialCommunityIcons",
    iconName: "flask-minus-outline"
  },
  {
    uuid: "43cc883f-e894-4da1-9631-d35ff25490fa",
    iconSet: "MaterialCommunityIcons",
    iconName: "flask-off"
  },
  {
    uuid: "9f174caa-1153-474b-ba2a-339394cb4cef",
    iconSet: "MaterialCommunityIcons",
    iconName: "flask-off-outline"
  },
  {
    uuid: "0c90f9f5-4e60-47fa-9430-5940c886f8af",
    iconSet: "MaterialCommunityIcons",
    iconName: "flask-outline"
  },
  {
    uuid: "f2c75daa-ad5a-4fbe-9e5c-0559a14ea30b",
    iconSet: "MaterialCommunityIcons",
    iconName: "flask-plus"
  },
  {
    uuid: "f050b057-3f0b-4f16-8155-952ee72cbfec",
    iconSet: "MaterialCommunityIcons",
    iconName: "flask-plus-outline"
  },
  {
    uuid: "2236d3ec-ecb1-466d-9da3-084b4cf5c4f9",
    iconSet: "MaterialCommunityIcons",
    iconName: "flask-remove"
  },
  {
    uuid: "f9d9c11b-5132-42bb-97e8-f9ba75287916",
    iconSet: "MaterialCommunityIcons",
    iconName: "flask-remove-outline"
  },
  {
    uuid: "71732823-f37c-48ba-8c65-f57587960aa9",
    iconSet: "MaterialCommunityIcons",
    iconName: "flask-round-bottom"
  },
  {
    uuid: "4c7ac36b-a469-4758-88d2-86cd3b414f56",
    iconSet: "MaterialCommunityIcons",
    iconName: "flask-round-bottom-empty"
  },
  {
    uuid: "f5dc5ab1-a4ee-4065-86db-2e6af404f6d2",
    iconSet: "MaterialCommunityIcons",
    iconName: "flask-round-bottom-empty-outline"
  },
  {
    uuid: "da80fc19-0cad-40e2-8cd6-3352e3b25f29",
    iconSet: "MaterialCommunityIcons",
    iconName: "flask-round-bottom-outline"
  },
  {
    uuid: "56da1cb3-7e23-4238-88e6-e2318286b90d",
    iconSet: "MaterialCommunityIcons",
    iconName: "fleur-de-lis"
  },
  {
    uuid: "e5a6b33f-00c9-4ef6-8355-3e86ed1d6373",
    iconSet: "MaterialCommunityIcons",
    iconName: "flip-horizontal"
  },
  {
    uuid: "61b83454-6b92-4be9-a7b2-8fe8adc543aa",
    iconSet: "MaterialCommunityIcons",
    iconName: "flip-to-back"
  },
  {
    uuid: "1889c7c2-db93-4eff-a8ef-ef3c65813586",
    iconSet: "MaterialCommunityIcons",
    iconName: "flip-to-front"
  },
  {
    uuid: "c8fdae43-24ca-47cc-b1ee-394be474908d",
    iconSet: "MaterialCommunityIcons",
    iconName: "flip-vertical"
  },
  {
    uuid: "8dc0433d-8ff5-4db5-a462-5300d7987e63",
    iconSet: "MaterialCommunityIcons",
    iconName: "floor-lamp"
  },
  {
    uuid: "d53a22fb-9625-42e5-af13-201005a8d16e",
    iconSet: "MaterialCommunityIcons",
    iconName: "floor-lamp-dual"
  },
  {
    uuid: "08fa2113-7673-46fb-80d9-ac3406b57ea6",
    iconSet: "MaterialCommunityIcons",
    iconName: "floor-lamp-variant"
  },
  {
    uuid: "990c031d-3b76-4847-a6f1-5956c7123bac",
    iconSet: "MaterialCommunityIcons",
    iconName: "floor-plan"
  },
  {
    uuid: "4bc4b9be-2491-4cc5-afbd-2b4f2b277bb9",
    iconSet: "MaterialCommunityIcons",
    iconName: "floppy"
  },
  {
    uuid: "1cf23838-046e-49f3-8c44-a09e29e36e6a",
    iconSet: "MaterialCommunityIcons",
    iconName: "floppy-variant"
  },
  {
    uuid: "d68318d6-cc06-4e34-8455-ff7028b80ded",
    iconSet: "MaterialCommunityIcons",
    iconName: "flower"
  },
  {
    uuid: "c4ff91bb-dab2-4001-901d-ab52e5c2ace6",
    iconSet: "MaterialCommunityIcons",
    iconName: "flower-outline"
  },
  {
    uuid: "e0ac5ddc-dbc0-422e-aae5-3a52b987d736",
    iconSet: "MaterialCommunityIcons",
    iconName: "flower-poppy"
  },
  {
    uuid: "1d3b5924-de8e-4c0e-99bd-c5411dcddcd0",
    iconSet: "MaterialCommunityIcons",
    iconName: "flower-tulip"
  },
  {
    uuid: "cca5e50f-0861-4cff-a2de-3d4ec11722ca",
    iconSet: "MaterialCommunityIcons",
    iconName: "flower-tulip-outline"
  },
  {
    uuid: "c553624e-21e2-4e67-af39-637840c7f506",
    iconSet: "MaterialCommunityIcons",
    iconName: "focus-auto"
  },
  {
    uuid: "ca611300-b69c-4514-916c-9d39c0304679",
    iconSet: "MaterialCommunityIcons",
    iconName: "focus-field"
  },
  {
    uuid: "b8c289ac-bce0-444a-b5b9-8cbf7050a604",
    iconSet: "MaterialCommunityIcons",
    iconName: "focus-field-horizontal"
  },
  {
    uuid: "63bcb4bb-bd05-42dc-81ba-5c3cb1d34aec",
    iconSet: "MaterialCommunityIcons",
    iconName: "focus-field-vertical"
  },
  {
    uuid: "0cb47044-76f2-40db-86f3-f57227eeea5e",
    iconSet: "MaterialCommunityIcons",
    iconName: "folder"
  },
  {
    uuid: "e9fccffe-319a-4f93-b560-e24620c34d0a",
    iconSet: "MaterialCommunityIcons",
    iconName: "folder-account"
  },
  {
    uuid: "9c713b42-6982-4141-aaf3-032b7b49d663",
    iconSet: "MaterialCommunityIcons",
    iconName: "folder-account-outline"
  },
  {
    uuid: "4fda957d-b984-4345-a1da-88c85b7dda27",
    iconSet: "MaterialCommunityIcons",
    iconName: "folder-alert"
  },
  {
    uuid: "ee627d16-8d5f-4ace-978f-87d948afb8ba",
    iconSet: "MaterialCommunityIcons",
    iconName: "folder-alert-outline"
  },
  {
    uuid: "73ac23ff-2d77-4a49-84b7-17becfd77d85",
    iconSet: "MaterialCommunityIcons",
    iconName: "folder-clock"
  },
  {
    uuid: "c8026929-2996-4815-a70e-8471cec3385d",
    iconSet: "MaterialCommunityIcons",
    iconName: "folder-clock-outline"
  },
  {
    uuid: "d8d70ac9-cacd-46c9-8df3-c0269f194b1d",
    iconSet: "MaterialCommunityIcons",
    iconName: "folder-cog"
  },
  {
    uuid: "bf3f52bd-ad64-4d72-86ea-6784efb9f0dd",
    iconSet: "MaterialCommunityIcons",
    iconName: "folder-cog-outline"
  },
  {
    uuid: "9e80d853-8fdd-499a-99ea-60b19b9202ce",
    iconSet: "MaterialCommunityIcons",
    iconName: "folder-download"
  },
  {
    uuid: "503cbb20-6b57-42b8-a545-93385e8f5a65",
    iconSet: "MaterialCommunityIcons",
    iconName: "folder-download-outline"
  },
  {
    uuid: "b387373b-2977-457f-b2f0-f6cc59dee77f",
    iconSet: "MaterialCommunityIcons",
    iconName: "folder-edit"
  },
  {
    uuid: "f369a722-12b2-460e-ad32-4ac9ff759b92",
    iconSet: "MaterialCommunityIcons",
    iconName: "folder-edit-outline"
  },
  {
    uuid: "29e1b1f6-14ac-4cf5-a172-a729c6c07e3b",
    iconSet: "MaterialCommunityIcons",
    iconName: "folder-google-drive"
  },
  {
    uuid: "e34b72df-fb5d-4ce2-a6f7-91b4181eba17",
    iconSet: "MaterialCommunityIcons",
    iconName: "folder-heart"
  },
  {
    uuid: "0a74cdd2-2a9e-4652-b702-44cd2b5adb58",
    iconSet: "MaterialCommunityIcons",
    iconName: "folder-heart-outline"
  },
  {
    uuid: "dc61eb62-ffbe-438c-b86c-2b02f96abe0d",
    iconSet: "MaterialCommunityIcons",
    iconName: "folder-home"
  },
  {
    uuid: "885303b8-55ac-442c-b49f-8780bc5dc0c0",
    iconSet: "MaterialCommunityIcons",
    iconName: "folder-home-outline"
  },
  {
    uuid: "62cd4c4d-bdcc-4f83-8f60-d4a8d114f765",
    iconSet: "MaterialCommunityIcons",
    iconName: "folder-image"
  },
  {
    uuid: "bffab7fc-ac3d-4d74-8f2a-7456e46adb1d",
    iconSet: "MaterialCommunityIcons",
    iconName: "folder-information"
  },
  {
    uuid: "322068f6-1e9c-4afe-978c-43da1c57d888",
    iconSet: "MaterialCommunityIcons",
    iconName: "folder-information-outline"
  },
  {
    uuid: "79417c82-7dae-41eb-8e3a-620808fcdd10",
    iconSet: "MaterialCommunityIcons",
    iconName: "folder-key"
  },
  {
    uuid: "bd277968-8f6d-4812-9aa4-0a065ece5983",
    iconSet: "MaterialCommunityIcons",
    iconName: "folder-key-network"
  },
  {
    uuid: "b02dffe5-291b-4c73-b9e4-2a9212695dcf",
    iconSet: "MaterialCommunityIcons",
    iconName: "folder-key-network-outline"
  },
  {
    uuid: "c3349b95-3a46-4d64-a354-ed1c55b2524f",
    iconSet: "MaterialCommunityIcons",
    iconName: "folder-key-outline"
  },
  {
    uuid: "e01399d7-6ccb-4e2f-90b0-4451c7fef704",
    iconSet: "MaterialCommunityIcons",
    iconName: "folder-lock"
  },
  {
    uuid: "c417ee89-7690-4dcd-a0bd-f9a37bb19213",
    iconSet: "MaterialCommunityIcons",
    iconName: "folder-lock-open"
  },
  {
    uuid: "260ce19c-1e28-4ecc-a307-ba51e441407b",
    iconSet: "MaterialCommunityIcons",
    iconName: "folder-marker"
  },
  {
    uuid: "1a6dfaa5-f727-4e7d-81d3-c3145a94dab9",
    iconSet: "MaterialCommunityIcons",
    iconName: "folder-marker-outline"
  },
  {
    uuid: "a1a5c35a-6813-4a0b-9b92-d5019427719b",
    iconSet: "MaterialCommunityIcons",
    iconName: "folder-move"
  },
  {
    uuid: "44589e2d-bde4-4ccd-b290-a001b76459e2",
    iconSet: "MaterialCommunityIcons",
    iconName: "folder-move-outline"
  },
  {
    uuid: "a32c891a-acc6-4b1a-b2aa-d7139238cb1b",
    iconSet: "MaterialCommunityIcons",
    iconName: "folder-multiple"
  },
  {
    uuid: "e92dfa16-14a6-4857-b14a-822c0af2c4fc",
    iconSet: "MaterialCommunityIcons",
    iconName: "folder-multiple-image"
  },
  {
    uuid: "316877c1-5e00-4023-bb40-0cc8238ec6e7",
    iconSet: "MaterialCommunityIcons",
    iconName: "folder-multiple-outline"
  },
  {
    uuid: "778c0e6b-0303-4948-8a65-0b045020baf9",
    iconSet: "MaterialCommunityIcons",
    iconName: "folder-multiple-plus"
  },
  {
    uuid: "c34e6154-aee0-452b-8b1a-f8917b934f01",
    iconSet: "MaterialCommunityIcons",
    iconName: "folder-multiple-plus-outline"
  },
  {
    uuid: "dc12f104-2398-40c3-bc05-b1f4280ab55d",
    iconSet: "MaterialCommunityIcons",
    iconName: "folder-music"
  },
  {
    uuid: "9bb92601-020e-4a37-a885-7555b054dcd9",
    iconSet: "MaterialCommunityIcons",
    iconName: "folder-music-outline"
  },
  {
    uuid: "ca3f1084-a5db-4d37-afa5-061e15648a9d",
    iconSet: "MaterialCommunityIcons",
    iconName: "folder-network"
  },
  {
    uuid: "91388ba7-1014-4a5a-9959-a584489ebf9e",
    iconSet: "MaterialCommunityIcons",
    iconName: "folder-network-outline"
  },
  {
    uuid: "a4989e51-22cd-41b1-a259-248912d06b4f",
    iconSet: "MaterialCommunityIcons",
    iconName: "folder-open"
  },
  {
    uuid: "30dc6fe7-6500-4a69-89ac-9dc6e55bdbed",
    iconSet: "MaterialCommunityIcons",
    iconName: "folder-open-outline"
  },
  {
    uuid: "dce78dba-ca26-4f80-9c8a-0cd6dbc8a2da",
    iconSet: "MaterialCommunityIcons",
    iconName: "folder-outline"
  },
  {
    uuid: "2cc31150-897d-415b-aabf-d6b259a8c718",
    iconSet: "MaterialCommunityIcons",
    iconName: "folder-plus"
  },
  {
    uuid: "933a74e5-426d-4e2e-9fa3-701088c26c47",
    iconSet: "MaterialCommunityIcons",
    iconName: "folder-plus-outline"
  },
  {
    uuid: "066f1c2e-5b9b-4ddb-ab30-70f5b7be5169",
    iconSet: "MaterialCommunityIcons",
    iconName: "folder-pound"
  },
  {
    uuid: "432eb429-703c-4cc5-9365-7398e78e1835",
    iconSet: "MaterialCommunityIcons",
    iconName: "folder-pound-outline"
  },
  {
    uuid: "65c6e74e-5367-40da-ba90-699a69a18c05",
    iconSet: "MaterialCommunityIcons",
    iconName: "folder-refresh"
  },
  {
    uuid: "70dee2a5-7b68-4c45-8edb-3ecd7f17e9b5",
    iconSet: "MaterialCommunityIcons",
    iconName: "folder-refresh-outline"
  },
  {
    uuid: "76d1f609-1f6e-4f42-8ea7-51544cf32993",
    iconSet: "MaterialCommunityIcons",
    iconName: "folder-remove"
  },
  {
    uuid: "d7c57d70-b678-430b-8285-5f4faaf865c1",
    iconSet: "MaterialCommunityIcons",
    iconName: "folder-remove-outline"
  },
  {
    uuid: "7503fe9a-5afd-4683-b65a-b3285f875592",
    iconSet: "MaterialCommunityIcons",
    iconName: "folder-search"
  },
  {
    uuid: "aaf8db1e-e1f6-45b6-9241-2da4eb7af98e",
    iconSet: "MaterialCommunityIcons",
    iconName: "folder-search-outline"
  },
  {
    uuid: "bf7c3fe8-d6cb-4432-9b0f-b96cc51ab3b5",
    iconSet: "MaterialCommunityIcons",
    iconName: "folder-settings"
  },
  {
    uuid: "d4cbcad4-b02b-48bc-8182-c2224a0083c8",
    iconSet: "MaterialCommunityIcons",
    iconName: "folder-settings-outline"
  },
  {
    uuid: "23e2e49b-7e63-41dc-944c-8dbde7be4115",
    iconSet: "MaterialCommunityIcons",
    iconName: "folder-star"
  },
  {
    uuid: "afee8ab9-f74c-4ce0-b437-55821cd6001b",
    iconSet: "MaterialCommunityIcons",
    iconName: "folder-star-multiple"
  },
  {
    uuid: "ad3ac222-5978-4f77-a5dd-4da5a4456e4e",
    iconSet: "MaterialCommunityIcons",
    iconName: "folder-star-multiple-outline"
  },
  {
    uuid: "8fac2404-13cf-4be6-b78b-ebc0c73557db",
    iconSet: "MaterialCommunityIcons",
    iconName: "folder-star-outline"
  },
  {
    uuid: "cd31dd1b-9da3-4af8-8298-9aa56d63e100",
    iconSet: "MaterialCommunityIcons",
    iconName: "folder-swap"
  },
  {
    uuid: "88366a1e-137e-476f-9ce9-724f3388d0b4",
    iconSet: "MaterialCommunityIcons",
    iconName: "folder-swap-outline"
  },
  {
    uuid: "0d1a57e5-88c4-4714-82dc-e24a5ab1259f",
    iconSet: "MaterialCommunityIcons",
    iconName: "folder-sync"
  },
  {
    uuid: "0dd56675-18d0-4643-94bd-c575322c9f17",
    iconSet: "MaterialCommunityIcons",
    iconName: "folder-sync-outline"
  },
  {
    uuid: "57aed08f-8cdd-4d05-acb5-af71d1d72e25",
    iconSet: "MaterialCommunityIcons",
    iconName: "folder-table"
  },
  {
    uuid: "fb794985-8f89-4fcd-9766-174b9107b688",
    iconSet: "MaterialCommunityIcons",
    iconName: "folder-table-outline"
  },
  {
    uuid: "31a89db5-b4be-4833-b15a-51bc481f3d97",
    iconSet: "MaterialCommunityIcons",
    iconName: "folder-text"
  },
  {
    uuid: "4b3e9567-9585-420e-a4d7-aa1baea8cd4b",
    iconSet: "MaterialCommunityIcons",
    iconName: "folder-text-outline"
  },
  {
    uuid: "277e07f1-3db0-4040-9c49-01fc0da39083",
    iconSet: "MaterialCommunityIcons",
    iconName: "folder-upload"
  },
  {
    uuid: "d6b53145-5624-4d1f-b364-d17f587bc7d4",
    iconSet: "MaterialCommunityIcons",
    iconName: "folder-upload-outline"
  },
  {
    uuid: "bcb86080-630d-4a3c-9068-a0d5638c786f",
    iconSet: "MaterialCommunityIcons",
    iconName: "folder-zip"
  },
  {
    uuid: "e4be480a-8e41-4d2c-9520-bb4a217a7a59",
    iconSet: "MaterialCommunityIcons",
    iconName: "folder-zip-outline"
  },
  {
    uuid: "0b5efbb6-1bc1-409e-a2c9-868ea0d9d86f",
    iconSet: "MaterialCommunityIcons",
    iconName: "font-awesome"
  },
  {
    uuid: "1a86f256-ebc0-48b5-ae0a-0ac2630c815e",
    iconSet: "MaterialCommunityIcons",
    iconName: "food"
  },
  {
    uuid: "05ea5a1b-7ea7-45f5-91ff-ad9bc3f1bc6b",
    iconSet: "MaterialCommunityIcons",
    iconName: "food-apple"
  },
  {
    uuid: "9146e33b-0c9a-40eb-a9a0-e87ade574c41",
    iconSet: "MaterialCommunityIcons",
    iconName: "food-apple-outline"
  },
  {
    uuid: "1c6d0a35-b2b9-429b-9083-2a521731a646",
    iconSet: "MaterialCommunityIcons",
    iconName: "food-croissant"
  },
  {
    uuid: "1c081a6b-d1a2-4f9e-8782-7dd61bb33147",
    iconSet: "MaterialCommunityIcons",
    iconName: "food-drumstick"
  },
  {
    uuid: "87745600-12cf-41f5-b0a0-5a6d9cca5321",
    iconSet: "MaterialCommunityIcons",
    iconName: "food-drumstick-off"
  },
  {
    uuid: "f9d93ca1-11fd-4032-9679-2e064bb2d1e7",
    iconSet: "MaterialCommunityIcons",
    iconName: "food-drumstick-off-outline"
  },
  {
    uuid: "585c90cf-c387-4462-bce6-c696ce189de7",
    iconSet: "MaterialCommunityIcons",
    iconName: "food-drumstick-outline"
  },
  {
    uuid: "aae863b3-9c4a-477a-975a-63380d23db7c",
    iconSet: "MaterialCommunityIcons",
    iconName: "food-fork-drink"
  },
  {
    uuid: "532f0c16-382c-4a59-8d06-7ba72ad0d483",
    iconSet: "MaterialCommunityIcons",
    iconName: "food-off"
  },
  {
    uuid: "9ede9545-f48d-42e5-86c1-533d69d644e7",
    iconSet: "MaterialCommunityIcons",
    iconName: "food-steak"
  },
  {
    uuid: "c34e90a6-7f9b-4536-bfcf-d62d72d1f7b8",
    iconSet: "MaterialCommunityIcons",
    iconName: "food-steak-off"
  },
  {
    uuid: "b65920dc-14e4-43d7-8fd9-43d1da68a28f",
    iconSet: "MaterialCommunityIcons",
    iconName: "food-variant"
  },
  {
    uuid: "a89af9c5-3ff1-4bab-a3e8-eb6c1a926af5",
    iconSet: "MaterialCommunityIcons",
    iconName: "food-variant-off"
  },
  {
    uuid: "aba84f14-1b62-4985-9441-332d63e18f8c",
    iconSet: "MaterialCommunityIcons",
    iconName: "foot-print"
  },
  {
    uuid: "3d9a7161-9f29-44dc-a79c-0a28a074273a",
    iconSet: "MaterialCommunityIcons",
    iconName: "football"
  },
  {
    uuid: "a1d9b7ce-b116-41be-a5d2-fc1f4c7b4f21",
    iconSet: "MaterialCommunityIcons",
    iconName: "football-australian"
  },
  {
    uuid: "53513403-8805-4e24-addd-75d913d747c0",
    iconSet: "MaterialCommunityIcons",
    iconName: "football-helmet"
  },
  {
    uuid: "61f5b8d6-7a86-4af3-8506-d3ca65325690",
    iconSet: "MaterialCommunityIcons",
    iconName: "forklift"
  },
  {
    uuid: "fc1a4221-05fa-47c1-864a-b6268101cfdb",
    iconSet: "MaterialCommunityIcons",
    iconName: "form-dropdown"
  },
  {
    uuid: "fbd688f3-1f17-4b16-8c62-3b65a2b12929",
    iconSet: "MaterialCommunityIcons",
    iconName: "form-select"
  },
  {
    uuid: "0975d662-fee1-46d6-b37e-8065a8f268c6",
    iconSet: "MaterialCommunityIcons",
    iconName: "form-textarea"
  },
  {
    uuid: "ca0cdf4c-06bf-4022-a32f-9be6d8e8d16c",
    iconSet: "MaterialCommunityIcons",
    iconName: "form-textbox"
  },
  {
    uuid: "db4b1c17-24e4-455e-aa8c-9347cb322e25",
    iconSet: "MaterialCommunityIcons",
    iconName: "form-textbox-lock"
  },
  {
    uuid: "35ad7882-dbd6-47d3-9f30-0206f4f5126e",
    iconSet: "MaterialCommunityIcons",
    iconName: "form-textbox-password"
  },
  {
    uuid: "57c4720e-a841-4ffa-9e33-ae32899ab059",
    iconSet: "MaterialCommunityIcons",
    iconName: "format-align-bottom"
  },
  {
    uuid: "ffaf80d3-df97-4b0b-b366-0397e37a84b2",
    iconSet: "MaterialCommunityIcons",
    iconName: "format-align-center"
  },
  {
    uuid: "05afeba2-14b8-4546-b079-b93c58f20db8",
    iconSet: "MaterialCommunityIcons",
    iconName: "format-align-justify"
  },
  {
    uuid: "53d89825-0b3d-45b8-a1cc-e012fa3a5435",
    iconSet: "MaterialCommunityIcons",
    iconName: "format-align-left"
  },
  {
    uuid: "dd9aebb0-99b2-418d-a589-21be4c4251d1",
    iconSet: "MaterialCommunityIcons",
    iconName: "format-align-middle"
  },
  {
    uuid: "0975c59d-77de-41cb-a037-472e00c84b1a",
    iconSet: "MaterialCommunityIcons",
    iconName: "format-align-right"
  },
  {
    uuid: "25977b98-a694-41c5-b434-4e61029ff5cd",
    iconSet: "MaterialCommunityIcons",
    iconName: "format-align-top"
  },
  {
    uuid: "0e9a2acb-3014-4fd5-b0d1-0ba4ebbc814e",
    iconSet: "MaterialCommunityIcons",
    iconName: "format-annotation-minus"
  },
  {
    uuid: "5bfb10c0-d96c-4bde-907b-b34143f4aba4",
    iconSet: "MaterialCommunityIcons",
    iconName: "format-annotation-plus"
  },
  {
    uuid: "1b119e22-d198-45e5-adb7-f05b2e8d499c",
    iconSet: "MaterialCommunityIcons",
    iconName: "format-bold"
  },
  {
    uuid: "670ecce6-0f98-4181-b7a8-473bf564f353",
    iconSet: "MaterialCommunityIcons",
    iconName: "format-clear"
  },
  {
    uuid: "771c9195-43d8-4604-ac08-631014c7542c",
    iconSet: "MaterialCommunityIcons",
    iconName: "format-color-fill"
  },
  {
    uuid: "2c73b491-7799-4415-abef-4e0c48eb44ff",
    iconSet: "MaterialCommunityIcons",
    iconName: "format-color-highlight"
  },
  {
    uuid: "e52a848c-f41a-470b-9966-5a66ada76ff6",
    iconSet: "MaterialCommunityIcons",
    iconName: "format-color-marker-cancel"
  },
  {
    uuid: "6e4e9a57-f0ea-42e6-9099-bfa5822d8875",
    iconSet: "MaterialCommunityIcons",
    iconName: "format-color-text"
  },
  {
    uuid: "42200686-d610-454f-ae27-0508f075f49d",
    iconSet: "MaterialCommunityIcons",
    iconName: "format-columns"
  },
  {
    uuid: "3666b5de-1bd0-46a2-bbc0-360cdd4fbc12",
    iconSet: "MaterialCommunityIcons",
    iconName: "format-float-center"
  },
  {
    uuid: "32a76b30-b0c5-43dc-9bde-3255f3922362",
    iconSet: "MaterialCommunityIcons",
    iconName: "format-float-left"
  },
  {
    uuid: "e63a8838-8551-416c-90f7-18eb950e6c49",
    iconSet: "MaterialCommunityIcons",
    iconName: "format-float-none"
  },
  {
    uuid: "aedb1e77-15c5-4a96-862a-3c6869c17403",
    iconSet: "MaterialCommunityIcons",
    iconName: "format-float-right"
  },
  {
    uuid: "75b88dec-4eb2-41eb-96b4-1102bfa89a90",
    iconSet: "MaterialCommunityIcons",
    iconName: "format-font"
  },
  {
    uuid: "983a3cf6-13a6-4161-9866-7775d28a5702",
    iconSet: "MaterialCommunityIcons",
    iconName: "format-font-size-decrease"
  },
  {
    uuid: "1b6e5ec2-a1c5-403c-bb15-38884706910e",
    iconSet: "MaterialCommunityIcons",
    iconName: "format-font-size-increase"
  },
  {
    uuid: "27a359b2-1941-4422-a55b-66892dc934f3",
    iconSet: "MaterialCommunityIcons",
    iconName: "format-header-1"
  },
  {
    uuid: "0c7500ba-4608-4ca7-b277-d24b021224d6",
    iconSet: "MaterialCommunityIcons",
    iconName: "format-header-2"
  },
  {
    uuid: "6911f3c8-b0f0-4a46-ae2d-44671622fbaa",
    iconSet: "MaterialCommunityIcons",
    iconName: "format-header-3"
  },
  {
    uuid: "26fd799b-e238-4928-9240-3ea44d2a4a65",
    iconSet: "MaterialCommunityIcons",
    iconName: "format-header-4"
  },
  {
    uuid: "c30743c4-054c-4c51-85c2-75e3cbe89fd2",
    iconSet: "MaterialCommunityIcons",
    iconName: "format-header-5"
  },
  {
    uuid: "2f96726a-b40f-4ad9-8134-2b603ca04b12",
    iconSet: "MaterialCommunityIcons",
    iconName: "format-header-6"
  },
  {
    uuid: "5159c553-6184-4a88-ac54-1cbef3383e49",
    iconSet: "MaterialCommunityIcons",
    iconName: "format-header-decrease"
  },
  {
    uuid: "496ceddc-7f61-4f6b-93f7-7ab1e1bc78bd",
    iconSet: "MaterialCommunityIcons",
    iconName: "format-header-equal"
  },
  {
    uuid: "9dc6283b-bcc8-4761-9533-a3d9aec91a22",
    iconSet: "MaterialCommunityIcons",
    iconName: "format-header-increase"
  },
  {
    uuid: "a5a51413-b3bb-4d7d-bc71-8f351251a6fe",
    iconSet: "MaterialCommunityIcons",
    iconName: "format-header-pound"
  },
  {
    uuid: "d7ce8347-0f22-4154-acf8-d564536e4ca3",
    iconSet: "MaterialCommunityIcons",
    iconName: "format-horizontal-align-center"
  },
  {
    uuid: "671a9444-de90-44a1-a836-ba9b6168ee1b",
    iconSet: "MaterialCommunityIcons",
    iconName: "format-horizontal-align-left"
  },
  {
    uuid: "15ed2df7-542e-47b5-8b74-a1657395aa1c",
    iconSet: "MaterialCommunityIcons",
    iconName: "format-horizontal-align-right"
  },
  {
    uuid: "13183604-2b7b-4572-a529-8269c763682d",
    iconSet: "MaterialCommunityIcons",
    iconName: "format-indent-decrease"
  },
  {
    uuid: "df13aba4-e20b-4b55-a830-4e85c982100a",
    iconSet: "MaterialCommunityIcons",
    iconName: "format-indent-increase"
  },
  {
    uuid: "1dd158c5-3f3a-4e90-a070-f063e9fe06c6",
    iconSet: "MaterialCommunityIcons",
    iconName: "format-italic"
  },
  {
    uuid: "97b5472d-85ba-4dfd-bcca-64f3ce0c0280",
    iconSet: "MaterialCommunityIcons",
    iconName: "format-letter-case"
  },
  {
    uuid: "3cb31589-443f-4356-952c-2216257e0ec4",
    iconSet: "MaterialCommunityIcons",
    iconName: "format-letter-case-lower"
  },
  {
    uuid: "d3b4a7ed-62af-469f-8321-26fb0d29651c",
    iconSet: "MaterialCommunityIcons",
    iconName: "format-letter-case-upper"
  },
  {
    uuid: "45c59c73-c2ad-49d2-932f-8826d0446e34",
    iconSet: "MaterialCommunityIcons",
    iconName: "format-letter-ends-with"
  },
  {
    uuid: "3206c153-d759-4240-b5b9-7a707f534980",
    iconSet: "MaterialCommunityIcons",
    iconName: "format-letter-matches"
  },
  {
    uuid: "fe86abae-4e3c-4e86-ac7b-f6cd8a445ff1",
    iconSet: "MaterialCommunityIcons",
    iconName: "format-letter-starts-with"
  },
  {
    uuid: "8ec0cee7-5bf9-478b-86f7-5e76cab46239",
    iconSet: "MaterialCommunityIcons",
    iconName: "format-line-spacing"
  },
  {
    uuid: "28acac9b-84a0-4ea6-9bd0-f0eaa827ba3a",
    iconSet: "MaterialCommunityIcons",
    iconName: "format-line-style"
  },
  {
    uuid: "c8b6b7cd-a82b-4d0d-8f8a-ace6e8cbd3c8",
    iconSet: "MaterialCommunityIcons",
    iconName: "format-line-weight"
  },
  {
    uuid: "ba4e0eeb-bcc7-4558-9c8f-736de81c9b85",
    iconSet: "MaterialCommunityIcons",
    iconName: "format-list-bulleted"
  },
  {
    uuid: "a668ff38-9fec-457f-b49e-a0154cef1abf",
    iconSet: "MaterialCommunityIcons",
    iconName: "format-list-bulleted-square"
  },
  {
    uuid: "f2e5d714-6ab9-4e2a-925f-04a20df4099e",
    iconSet: "MaterialCommunityIcons",
    iconName: "format-list-bulleted-triangle"
  },
  {
    uuid: "d410c38a-3e5f-438f-9371-02c555388456",
    iconSet: "MaterialCommunityIcons",
    iconName: "format-list-bulleted-type"
  },
  {
    uuid: "8656fccf-e95d-481f-b1e2-00a54dc47f7b",
    iconSet: "MaterialCommunityIcons",
    iconName: "format-list-checkbox"
  },
  {
    uuid: "b1897d99-dc9d-4a19-8b6e-87cb3385b2d8",
    iconSet: "MaterialCommunityIcons",
    iconName: "format-list-checks"
  },
  {
    uuid: "bb0bc591-683f-4323-bf26-fb5c5fcdaa0c",
    iconSet: "MaterialCommunityIcons",
    iconName: "format-list-numbered"
  },
  {
    uuid: "ade53c48-45fd-45a8-a659-8edb44d63a9d",
    iconSet: "MaterialCommunityIcons",
    iconName: "format-list-numbered-rtl"
  },
  {
    uuid: "b5c46e33-6e57-4df8-ab08-46f296609caa",
    iconSet: "MaterialCommunityIcons",
    iconName: "format-list-text"
  },
  {
    uuid: "a168c9e7-76df-47f7-a8e6-01a6b83c5544",
    iconSet: "MaterialCommunityIcons",
    iconName: "format-overline"
  },
  {
    uuid: "3ee166d9-04dc-4162-878a-6cbd21c3bcb6",
    iconSet: "MaterialCommunityIcons",
    iconName: "format-page-break"
  },
  {
    uuid: "9cc5ed15-3f3c-4a9a-8832-27d923cf3917",
    iconSet: "MaterialCommunityIcons",
    iconName: "format-paint"
  },
  {
    uuid: "51b433a3-4119-4d9d-960c-197d18084ad8",
    iconSet: "MaterialCommunityIcons",
    iconName: "format-paragraph"
  },
  {
    uuid: "87bf9b8f-c382-4f96-88d2-ccd6714d82d8",
    iconSet: "MaterialCommunityIcons",
    iconName: "format-pilcrow"
  },
  {
    uuid: "9ee97491-9ee9-4a1e-bde6-0e1e8ce03724",
    iconSet: "MaterialCommunityIcons",
    iconName: "format-quote-close"
  },
  {
    uuid: "60d55ab6-9355-47d5-a079-6b7b1997daa7",
    iconSet: "MaterialCommunityIcons",
    iconName: "format-quote-close-outline"
  },
  {
    uuid: "7c1738f9-60c4-4001-b197-7ecc7d30133e",
    iconSet: "MaterialCommunityIcons",
    iconName: "format-quote-open"
  },
  {
    uuid: "7727934f-7f1e-4e1f-9dec-6657a4781b72",
    iconSet: "MaterialCommunityIcons",
    iconName: "format-quote-open-outline"
  },
  {
    uuid: "ee2c37b1-ff9d-47dd-b2a8-121e0bf2e2f2",
    iconSet: "MaterialCommunityIcons",
    iconName: "format-rotate-90"
  },
  {
    uuid: "6c131caf-df86-447a-af6c-a1ae95851fd1",
    iconSet: "MaterialCommunityIcons",
    iconName: "format-section"
  },
  {
    uuid: "99ed7544-cef4-4cdf-99df-352547a89192",
    iconSet: "MaterialCommunityIcons",
    iconName: "format-size"
  },
  {
    uuid: "ccacb159-8491-4e0e-924b-02092744045a",
    iconSet: "MaterialCommunityIcons",
    iconName: "format-strikethrough"
  },
  {
    uuid: "909e9a2d-88d6-4f35-a0de-586dfb3e3210",
    iconSet: "MaterialCommunityIcons",
    iconName: "format-strikethrough-variant"
  },
  {
    uuid: "e2ddd636-4c97-478e-b57b-493d984730fa",
    iconSet: "MaterialCommunityIcons",
    iconName: "format-subscript"
  },
  {
    uuid: "399364df-6bf0-4689-aa82-50cb35da7efe",
    iconSet: "MaterialCommunityIcons",
    iconName: "format-superscript"
  },
  {
    uuid: "2be7b3d1-f2da-4233-aa28-e969b82cd717",
    iconSet: "MaterialCommunityIcons",
    iconName: "format-text"
  },
  {
    uuid: "aa8cd69c-9248-4e69-a19f-ce7cb56f7376",
    iconSet: "MaterialCommunityIcons",
    iconName: "format-text-rotation-angle-down"
  },
  {
    uuid: "e2c3be87-d6d7-45ff-bfdc-219dbae03853",
    iconSet: "MaterialCommunityIcons",
    iconName: "format-text-rotation-angle-up"
  },
  {
    uuid: "565ace81-7b91-4e15-b0b6-a1ac71b572e7",
    iconSet: "MaterialCommunityIcons",
    iconName: "format-text-rotation-down"
  },
  {
    uuid: "e30e00a6-e4f5-41e6-adf2-109123af7175",
    iconSet: "MaterialCommunityIcons",
    iconName: "format-text-rotation-down-vertical"
  },
  {
    uuid: "3a15e94d-4aad-4457-8a48-4cf63a4d30a0",
    iconSet: "MaterialCommunityIcons",
    iconName: "format-text-rotation-none"
  },
  {
    uuid: "34b11961-f8da-4131-8978-114aa2c40e46",
    iconSet: "MaterialCommunityIcons",
    iconName: "format-text-rotation-up"
  },
  {
    uuid: "707c9859-cac1-46c5-b6e9-63396ef1b234",
    iconSet: "MaterialCommunityIcons",
    iconName: "format-text-rotation-vertical"
  },
  {
    uuid: "7b28c124-aa1f-4c82-a89a-4c40803dd581",
    iconSet: "MaterialCommunityIcons",
    iconName: "format-text-variant"
  },
  {
    uuid: "c20e2d18-3cdb-432c-870d-b29b743bc0ee",
    iconSet: "MaterialCommunityIcons",
    iconName: "format-text-wrapping-clip"
  },
  {
    uuid: "5100770d-e46e-4edd-8395-7a88267702c7",
    iconSet: "MaterialCommunityIcons",
    iconName: "format-text-wrapping-overflow"
  },
  {
    uuid: "3f18eb5f-831a-44f1-931d-8e5cace06d91",
    iconSet: "MaterialCommunityIcons",
    iconName: "format-text-wrapping-wrap"
  },
  {
    uuid: "ecf65869-a707-4df1-b27d-a07dfaed7b95",
    iconSet: "MaterialCommunityIcons",
    iconName: "format-textbox"
  },
  {
    uuid: "e3f8ac19-23b1-4205-81da-84a78c3ce751",
    iconSet: "MaterialCommunityIcons",
    iconName: "format-textdirection-l-to-r"
  },
  {
    uuid: "9830c0fe-6d00-4c9c-a9ed-c755e39b616d",
    iconSet: "MaterialCommunityIcons",
    iconName: "format-textdirection-r-to-l"
  },
  {
    uuid: "8a913929-ae0d-4a19-8134-618672e26ac0",
    iconSet: "MaterialCommunityIcons",
    iconName: "format-title"
  },
  {
    uuid: "17a5ff09-282d-438f-bb31-55044a94d7d5",
    iconSet: "MaterialCommunityIcons",
    iconName: "format-underline"
  },
  {
    uuid: "aa0f02e2-18b6-40b9-9958-5a7909b635f0",
    iconSet: "MaterialCommunityIcons",
    iconName: "format-vertical-align-bottom"
  },
  {
    uuid: "7e3664d6-2abd-48a3-a70e-d3878cdd12a3",
    iconSet: "MaterialCommunityIcons",
    iconName: "format-vertical-align-center"
  },
  {
    uuid: "a7f47e60-c663-4d6f-8ce7-2cdeadee0a22",
    iconSet: "MaterialCommunityIcons",
    iconName: "format-vertical-align-top"
  },
  {
    uuid: "b25a9151-7cbb-474e-ab01-4c2e6f549079",
    iconSet: "MaterialCommunityIcons",
    iconName: "format-wrap-inline"
  },
  {
    uuid: "b4f9b561-0694-4a8f-b2c4-6b6fc440738b",
    iconSet: "MaterialCommunityIcons",
    iconName: "format-wrap-square"
  },
  {
    uuid: "bf9b63de-816a-46ee-8cd0-3394d810c4a7",
    iconSet: "MaterialCommunityIcons",
    iconName: "format-wrap-tight"
  },
  {
    uuid: "395ef8f6-a6b7-4ed2-957c-03cd01b75d8d",
    iconSet: "MaterialCommunityIcons",
    iconName: "format-wrap-top-bottom"
  },
  {
    uuid: "18d085a9-5d6c-417a-8c17-6e566d8e6561",
    iconSet: "MaterialCommunityIcons",
    iconName: "forum"
  },
  {
    uuid: "1f695799-ce93-4d14-bf15-15cf95927fb6",
    iconSet: "MaterialCommunityIcons",
    iconName: "forum-outline"
  },
  {
    uuid: "5740e8b4-78ef-46eb-b4d7-ed43f52bf117",
    iconSet: "MaterialCommunityIcons",
    iconName: "forward"
  },
  {
    uuid: "0030ab03-4106-4d0d-b8a9-6715fa9622dc",
    iconSet: "MaterialCommunityIcons",
    iconName: "forwardburger"
  },
  {
    uuid: "696e1b7a-7931-429e-bd1c-340b01aee735",
    iconSet: "MaterialCommunityIcons",
    iconName: "fountain"
  },
  {
    uuid: "081bc4bf-21b8-4a5a-af3c-9c348d034ffe",
    iconSet: "MaterialCommunityIcons",
    iconName: "fountain-pen"
  },
  {
    uuid: "3d07f18d-2589-4a6a-b130-a11f6f192a17",
    iconSet: "MaterialCommunityIcons",
    iconName: "fountain-pen-tip"
  },
  {
    uuid: "bf95dda9-2b01-4bd7-b560-bfa4a74406cb",
    iconSet: "MaterialCommunityIcons",
    iconName: "freebsd"
  },
  {
    uuid: "c4b0470a-a14e-40e9-b2aa-ef8de1a5714e",
    iconSet: "MaterialCommunityIcons",
    iconName: "frequently-asked-questions"
  },
  {
    uuid: "eb270199-0905-452a-8661-c36d8b69437a",
    iconSet: "MaterialCommunityIcons",
    iconName: "fridge"
  },
  {
    uuid: "7e879392-fdba-4df1-858d-d5f99c05360a",
    iconSet: "MaterialCommunityIcons",
    iconName: "fridge-alert"
  },
  {
    uuid: "de0d9814-c680-4ef3-bdda-cb12208e0c2a",
    iconSet: "MaterialCommunityIcons",
    iconName: "fridge-alert-outline"
  },
  {
    uuid: "7568f6da-37e1-49a5-8614-18bfff2e72d0",
    iconSet: "MaterialCommunityIcons",
    iconName: "fridge-bottom"
  },
  {
    uuid: "cb479b2b-e980-4b5b-b6ef-e958519f12cc",
    iconSet: "MaterialCommunityIcons",
    iconName: "fridge-off"
  },
  {
    uuid: "6359ee6d-e3bf-4baf-b6a0-ab8aa2e9a6e2",
    iconSet: "MaterialCommunityIcons",
    iconName: "fridge-off-outline"
  },
  {
    uuid: "64fb2dfe-7d0f-4a21-a6cf-5104c75716b0",
    iconSet: "MaterialCommunityIcons",
    iconName: "fridge-outline"
  },
  {
    uuid: "95103ff8-5058-4fa3-b50c-e41e8b463d40",
    iconSet: "MaterialCommunityIcons",
    iconName: "fridge-top"
  },
  {
    uuid: "5992f9ab-fa2d-49e5-8b7c-57d18d7d1f6f",
    iconSet: "MaterialCommunityIcons",
    iconName: "fruit-cherries"
  },
  {
    uuid: "7e4d225a-5d4b-47cf-ad7a-e8c421a07863",
    iconSet: "MaterialCommunityIcons",
    iconName: "fruit-cherries-off"
  },
  {
    uuid: "7203ddd0-15c8-4fbf-891f-e05ea319ec0f",
    iconSet: "MaterialCommunityIcons",
    iconName: "fruit-citrus"
  },
  {
    uuid: "9b88010f-8a1c-4b99-8dcc-77199548ffbc",
    iconSet: "MaterialCommunityIcons",
    iconName: "fruit-citrus-off"
  },
  {
    uuid: "6fafaaea-b6d8-4c27-aa17-7afaf1f498d9",
    iconSet: "MaterialCommunityIcons",
    iconName: "fruit-grapes"
  },
  {
    uuid: "ec0e34c6-809d-48b8-ac72-62dd48d75d4b",
    iconSet: "MaterialCommunityIcons",
    iconName: "fruit-grapes-outline"
  },
  {
    uuid: "5bc9fce6-ee61-4086-bc9b-97cff74537d4",
    iconSet: "MaterialCommunityIcons",
    iconName: "fruit-pineapple"
  },
  {
    uuid: "f0dae44b-2b9f-402c-aff1-36e29d986c62",
    iconSet: "MaterialCommunityIcons",
    iconName: "fruit-watermelon"
  },
  {
    uuid: "5490c73b-d656-43c9-941b-5f0a836a6539",
    iconSet: "MaterialCommunityIcons",
    iconName: "fuel"
  },
  {
    uuid: "15c306b3-39a6-4413-85c3-172ddd5012d6",
    iconSet: "MaterialCommunityIcons",
    iconName: "fullscreen"
  },
  {
    uuid: "5617eee8-d943-471b-a8bb-eff71de6453d",
    iconSet: "MaterialCommunityIcons",
    iconName: "fullscreen-exit"
  },
  {
    uuid: "20de48ac-cdc7-4f99-b96d-b21190c07341",
    iconSet: "MaterialCommunityIcons",
    iconName: "function"
  },
  {
    uuid: "65aee57f-0250-4ad7-bce7-8a0c4d11da89",
    iconSet: "MaterialCommunityIcons",
    iconName: "function-variant"
  },
  {
    uuid: "26020c9b-3cfa-42c0-9c8a-c5666048613e",
    iconSet: "MaterialCommunityIcons",
    iconName: "furigana-horizontal"
  },
  {
    uuid: "5a5efd23-5f43-43b8-aff9-51e49c137909",
    iconSet: "MaterialCommunityIcons",
    iconName: "furigana-vertical"
  },
  {
    uuid: "c69b12a3-9c95-4ce7-945a-925030f4eb85",
    iconSet: "MaterialCommunityIcons",
    iconName: "fuse"
  },
  {
    uuid: "7a421a0e-d4a0-4d1b-8e17-90c065c11274",
    iconSet: "MaterialCommunityIcons",
    iconName: "fuse-alert"
  },
  {
    uuid: "ae566127-f306-4fb3-807c-50522f9f1efe",
    iconSet: "MaterialCommunityIcons",
    iconName: "fuse-blade"
  },
  {
    uuid: "aba40336-0db3-409a-a719-367da0faa761",
    iconSet: "MaterialCommunityIcons",
    iconName: "fuse-off"
  },
  {
    uuid: "014d2846-2648-49d4-8981-85a6ef7744ce",
    iconSet: "MaterialCommunityIcons",
    iconName: "gamepad"
  },
  {
    uuid: "4c382c2c-8a39-4897-b834-bfb838e76f71",
    iconSet: "MaterialCommunityIcons",
    iconName: "gamepad-circle"
  },
  {
    uuid: "aded55ca-2288-4b5a-8b10-7ead0211f16d",
    iconSet: "MaterialCommunityIcons",
    iconName: "gamepad-circle-down"
  },
  {
    uuid: "ed226d29-f259-4356-aee9-6a89a6271cf1",
    iconSet: "MaterialCommunityIcons",
    iconName: "gamepad-circle-left"
  },
  {
    uuid: "483b129b-d463-4b04-9e77-2a5ce9f85702",
    iconSet: "MaterialCommunityIcons",
    iconName: "gamepad-circle-outline"
  },
  {
    uuid: "0fe65869-7210-49ce-86b3-84ac0274b15f",
    iconSet: "MaterialCommunityIcons",
    iconName: "gamepad-circle-right"
  },
  {
    uuid: "a747d630-43b0-4b83-8902-6d1cffede653",
    iconSet: "MaterialCommunityIcons",
    iconName: "gamepad-circle-up"
  },
  {
    uuid: "003dd4af-972f-4494-ac2b-d4c7dea87736",
    iconSet: "MaterialCommunityIcons",
    iconName: "gamepad-down"
  },
  {
    uuid: "9d7fce36-edbf-411d-ac69-a5da4b1e6824",
    iconSet: "MaterialCommunityIcons",
    iconName: "gamepad-left"
  },
  {
    uuid: "c67cd320-e0a2-4dd9-a69b-3a4ba17f6d3e",
    iconSet: "MaterialCommunityIcons",
    iconName: "gamepad-right"
  },
  {
    uuid: "1165c3b7-a8b1-482e-a15d-84104fb05d3a",
    iconSet: "MaterialCommunityIcons",
    iconName: "gamepad-round"
  },
  {
    uuid: "08228484-ceb4-462e-9ad4-fb6b93632a10",
    iconSet: "MaterialCommunityIcons",
    iconName: "gamepad-round-down"
  },
  {
    uuid: "e461ea07-99e4-44e1-a3c9-a964924d43a8",
    iconSet: "MaterialCommunityIcons",
    iconName: "gamepad-round-left"
  },
  {
    uuid: "fa67baa4-c885-4654-8a9e-3239493511ce",
    iconSet: "MaterialCommunityIcons",
    iconName: "gamepad-round-outline"
  },
  {
    uuid: "d9744fc9-4141-4347-9d88-1899bb32aabf",
    iconSet: "MaterialCommunityIcons",
    iconName: "gamepad-round-right"
  },
  {
    uuid: "5b343558-03df-4c0e-98bc-dc2f3b55490b",
    iconSet: "MaterialCommunityIcons",
    iconName: "gamepad-round-up"
  },
  {
    uuid: "dbcce4f4-e3ed-4e1f-ad62-2e62d3cf1728",
    iconSet: "MaterialCommunityIcons",
    iconName: "gamepad-square"
  },
  {
    uuid: "8bcbb1f7-f4b1-448e-916b-1bd3ab2a3397",
    iconSet: "MaterialCommunityIcons",
    iconName: "gamepad-square-outline"
  },
  {
    uuid: "73a75976-4154-4611-92b0-19474a247a25",
    iconSet: "MaterialCommunityIcons",
    iconName: "gamepad-up"
  },
  {
    uuid: "0c0babf0-0bbc-4699-8136-7664258e05bf",
    iconSet: "MaterialCommunityIcons",
    iconName: "gamepad-variant"
  },
  {
    uuid: "f750f9df-0592-4970-837e-f9777b876edf",
    iconSet: "MaterialCommunityIcons",
    iconName: "gamepad-variant-outline"
  },
  {
    uuid: "62128759-64eb-4b63-aa4a-982e5c7639da",
    iconSet: "MaterialCommunityIcons",
    iconName: "gamma"
  },
  {
    uuid: "989b99b1-c25f-4e04-b75d-73088603af50",
    iconSet: "MaterialCommunityIcons",
    iconName: "gantry-crane"
  },
  {
    uuid: "eb455e53-d681-40cc-a8a9-fc0481cda745",
    iconSet: "MaterialCommunityIcons",
    iconName: "garage"
  },
  {
    uuid: "2561fcfc-404a-42b5-866e-c140d825c13d",
    iconSet: "MaterialCommunityIcons",
    iconName: "garage-alert"
  },
  {
    uuid: "7e88d306-f57a-40b8-8d12-10572bcaf2a1",
    iconSet: "MaterialCommunityIcons",
    iconName: "garage-alert-variant"
  },
  {
    uuid: "9764e6ba-aecf-40ee-a65f-cecee5d87403",
    iconSet: "MaterialCommunityIcons",
    iconName: "garage-open"
  },
  {
    uuid: "68329f2c-c49e-4f5b-8f6d-c708991eac7f",
    iconSet: "MaterialCommunityIcons",
    iconName: "garage-open-variant"
  },
  {
    uuid: "ce817a2c-5d60-4f32-b6fc-fd0182f083aa",
    iconSet: "MaterialCommunityIcons",
    iconName: "garage-variant"
  },
  {
    uuid: "00b28f68-2453-44a4-83a3-41f3c34112e2",
    iconSet: "MaterialCommunityIcons",
    iconName: "gas-cylinder"
  },
  {
    uuid: "adc7b75b-7502-4371-81e7-bda23f1cc08d",
    iconSet: "MaterialCommunityIcons",
    iconName: "gas-station"
  },
  {
    uuid: "03ad3bda-c8d3-4f82-86a2-74a7cfc678b9",
    iconSet: "MaterialCommunityIcons",
    iconName: "gas-station-off"
  },
  {
    uuid: "b415fa81-c1e3-45d7-a131-c0c56b019609",
    iconSet: "MaterialCommunityIcons",
    iconName: "gas-station-off-outline"
  },
  {
    uuid: "9d5729a9-7a0d-41cc-b515-0bb41c5d45df",
    iconSet: "MaterialCommunityIcons",
    iconName: "gas-station-outline"
  },
  {
    uuid: "0437b54f-7993-4f45-af07-8cb19afedb19",
    iconSet: "MaterialCommunityIcons",
    iconName: "gate"
  },
  {
    uuid: "1ee7d54f-2f6c-40e7-b76e-fa78c3e31ee3",
    iconSet: "MaterialCommunityIcons",
    iconName: "gate-and"
  },
  {
    uuid: "88438cd6-5b96-4bbb-9fcb-c3b80db9e851",
    iconSet: "MaterialCommunityIcons",
    iconName: "gate-arrow-right"
  },
  {
    uuid: "97dd9591-598b-4b63-8953-335f5decefe7",
    iconSet: "MaterialCommunityIcons",
    iconName: "gate-nand"
  },
  {
    uuid: "7eeb430f-bca3-495f-839f-854f6464da65",
    iconSet: "MaterialCommunityIcons",
    iconName: "gate-nor"
  },
  {
    uuid: "4b3540f0-ba66-4129-b4bd-2f08d594fa8b",
    iconSet: "MaterialCommunityIcons",
    iconName: "gate-not"
  },
  {
    uuid: "5bef4da0-d849-48e6-898c-1afb98b23fc2",
    iconSet: "MaterialCommunityIcons",
    iconName: "gate-open"
  },
  {
    uuid: "dc2ca1bd-01f5-4e0b-8516-c51f677729b4",
    iconSet: "MaterialCommunityIcons",
    iconName: "gate-or"
  },
  {
    uuid: "5afcd00e-5f06-4f2f-bb41-7a03bf05a70b",
    iconSet: "MaterialCommunityIcons",
    iconName: "gate-xnor"
  },
  {
    uuid: "62d55dfc-d304-4933-b5f9-bdac141fb9c5",
    iconSet: "MaterialCommunityIcons",
    iconName: "gate-xor"
  },
  {
    uuid: "2843472d-da22-4e3f-820e-9e720116ac38",
    iconSet: "MaterialCommunityIcons",
    iconName: "gatsby"
  },
  {
    uuid: "a57cbb90-7690-4ede-a064-00dddc312029",
    iconSet: "MaterialCommunityIcons",
    iconName: "gauge"
  },
  {
    uuid: "30b6627d-d8ba-4e7d-8aa6-c3142b7274c1",
    iconSet: "MaterialCommunityIcons",
    iconName: "gauge-empty"
  },
  {
    uuid: "42190f9d-e0c3-461a-9e16-097db3daae4e",
    iconSet: "MaterialCommunityIcons",
    iconName: "gauge-full"
  },
  {
    uuid: "75e4db8b-93c2-4b30-8280-db3435a188f8",
    iconSet: "MaterialCommunityIcons",
    iconName: "gauge-low"
  },
  {
    uuid: "e26b68fb-a875-474e-925e-1bdbb5edd3c3",
    iconSet: "MaterialCommunityIcons",
    iconName: "gavel"
  },
  {
    uuid: "6084bd7a-b722-4153-a34d-1c4cc3e3c631",
    iconSet: "MaterialCommunityIcons",
    iconName: "gender-female"
  },
  {
    uuid: "0d55f237-4034-4630-82c1-1a6b7ce99f3f",
    iconSet: "MaterialCommunityIcons",
    iconName: "gender-male"
  },
  {
    uuid: "deeae4f5-b28c-4992-a37a-ef205665157a",
    iconSet: "MaterialCommunityIcons",
    iconName: "gender-male-female"
  },
  {
    uuid: "7f8ee7fc-914b-4fc3-b02f-2dfc2da8d18f",
    iconSet: "MaterialCommunityIcons",
    iconName: "gender-male-female-variant"
  },
  {
    uuid: "b34fdca3-fb02-4798-a2e5-990d3646df53",
    iconSet: "MaterialCommunityIcons",
    iconName: "gender-non-binary"
  },
  {
    uuid: "618b9624-bb4c-4139-aae3-2a596bd474c5",
    iconSet: "MaterialCommunityIcons",
    iconName: "gender-transgender"
  },
  {
    uuid: "c3a97213-1512-4dca-88c6-93f3da375b77",
    iconSet: "MaterialCommunityIcons",
    iconName: "gentoo"
  },
  {
    uuid: "b5b7730e-633a-42c1-97fd-4a5cd16cde5d",
    iconSet: "MaterialCommunityIcons",
    iconName: "gesture"
  },
  {
    uuid: "c15460fa-77f0-4b62-aa84-27823e4f9a0d",
    iconSet: "MaterialCommunityIcons",
    iconName: "gesture-double-tap"
  },
  {
    uuid: "af1328b8-bf64-435d-ab46-d91f3c024de8",
    iconSet: "MaterialCommunityIcons",
    iconName: "gesture-pinch"
  },
  {
    uuid: "e03bdf60-10e7-4d6b-9c91-95a4db36f374",
    iconSet: "MaterialCommunityIcons",
    iconName: "gesture-spread"
  },
  {
    uuid: "f39d97de-ab12-4fd5-bb62-8c791cea0990",
    iconSet: "MaterialCommunityIcons",
    iconName: "gesture-swipe"
  },
  {
    uuid: "ed6a65a6-5c5d-4119-b45a-24db364bc42b",
    iconSet: "MaterialCommunityIcons",
    iconName: "gesture-swipe-down"
  },
  {
    uuid: "f426c5bf-e7d3-4b11-9608-13b2e74b9963",
    iconSet: "MaterialCommunityIcons",
    iconName: "gesture-swipe-horizontal"
  },
  {
    uuid: "de806b0a-4ef0-407e-8780-23b2817ee8ef",
    iconSet: "MaterialCommunityIcons",
    iconName: "gesture-swipe-left"
  },
  {
    uuid: "59be13e4-dcc7-46c7-9e5c-b6c5af13ed1e",
    iconSet: "MaterialCommunityIcons",
    iconName: "gesture-swipe-right"
  },
  {
    uuid: "f456dd75-cc44-4afd-8e23-00c1e15c9b51",
    iconSet: "MaterialCommunityIcons",
    iconName: "gesture-swipe-up"
  },
  {
    uuid: "3e9154f8-1eb9-4e77-bf9a-06a18987abe3",
    iconSet: "MaterialCommunityIcons",
    iconName: "gesture-swipe-vertical"
  },
  {
    uuid: "2a812df4-8131-40ee-bdca-026ce7768712",
    iconSet: "MaterialCommunityIcons",
    iconName: "gesture-tap"
  },
  {
    uuid: "81a18de2-04a4-4c56-8a60-117b4e67d541",
    iconSet: "MaterialCommunityIcons",
    iconName: "gesture-tap-box"
  },
  {
    uuid: "9bcc33c0-0a1e-414c-9d0d-f423d7819208",
    iconSet: "MaterialCommunityIcons",
    iconName: "gesture-tap-button"
  },
  {
    uuid: "dd1bab58-e444-433d-994c-94bf058c6d3e",
    iconSet: "MaterialCommunityIcons",
    iconName: "gesture-tap-hold"
  },
  {
    uuid: "01b91589-29fe-420f-afc4-cd9f1ca82689",
    iconSet: "MaterialCommunityIcons",
    iconName: "gesture-two-double-tap"
  },
  {
    uuid: "1d565317-80be-4ddd-ba33-e9976e54fd88",
    iconSet: "MaterialCommunityIcons",
    iconName: "gesture-two-tap"
  },
  {
    uuid: "769de24e-cccf-4de2-936f-18e0882f55e9",
    iconSet: "MaterialCommunityIcons",
    iconName: "ghost"
  },
  {
    uuid: "6f86f4af-e8a4-49e5-ae1e-34f1ea98ff98",
    iconSet: "MaterialCommunityIcons",
    iconName: "ghost-off"
  },
  {
    uuid: "980bc8d5-457b-417b-a071-3e60b2ecc940",
    iconSet: "MaterialCommunityIcons",
    iconName: "gif"
  },
  {
    uuid: "64773f4a-397c-48e3-8337-f4e88bdbe988",
    iconSet: "MaterialCommunityIcons",
    iconName: "gift"
  },
  {
    uuid: "fa302c33-8c80-4416-9147-d2008426d41e",
    iconSet: "MaterialCommunityIcons",
    iconName: "gift-outline"
  },
  {
    uuid: "b7c8d809-567b-4405-8c8d-d7c12cb00481",
    iconSet: "MaterialCommunityIcons",
    iconName: "git"
  },
  {
    uuid: "8f264e35-8446-4840-9f37-e4ef162a414a",
    iconSet: "MaterialCommunityIcons",
    iconName: "github"
  },
  {
    uuid: "435bf809-0b5a-4157-9dc2-858260e3561a",
    iconSet: "MaterialCommunityIcons",
    iconName: "gitlab"
  },
  {
    uuid: "75bfacd7-4d58-47be-aef6-79620f1d9480",
    iconSet: "MaterialCommunityIcons",
    iconName: "glass-cocktail"
  },
  {
    uuid: "b5b869f3-5d08-4212-8d30-46e1c71fd01b",
    iconSet: "MaterialCommunityIcons",
    iconName: "glass-flute"
  },
  {
    uuid: "795c8ca9-7d0e-4ad1-a4ce-12271ea0dd74",
    iconSet: "MaterialCommunityIcons",
    iconName: "glass-mug"
  },
  {
    uuid: "8c40e2f9-bc01-49ed-97f3-9c43140c6ac2",
    iconSet: "MaterialCommunityIcons",
    iconName: "glass-mug-variant"
  },
  {
    uuid: "00977001-ed9d-47ae-928e-9d38c1bd18be",
    iconSet: "MaterialCommunityIcons",
    iconName: "glass-pint-outline"
  },
  {
    uuid: "7ed38a87-f68d-4909-ad94-627597398fec",
    iconSet: "MaterialCommunityIcons",
    iconName: "glass-stange"
  },
  {
    uuid: "a83346af-2f02-438d-8cc2-930bde9555ee",
    iconSet: "MaterialCommunityIcons",
    iconName: "glass-tulip"
  },
  {
    uuid: "60d2c08d-9f53-4af1-9189-5f6ed306d032",
    iconSet: "MaterialCommunityIcons",
    iconName: "glass-wine"
  },
  {
    uuid: "07d97214-4379-4406-ace2-035e2c736011",
    iconSet: "MaterialCommunityIcons",
    iconName: "glasses"
  },
  {
    uuid: "e23616e7-13bf-43c7-beb2-c3d0d56df3c1",
    iconSet: "MaterialCommunityIcons",
    iconName: "globe-light"
  },
  {
    uuid: "a666573f-fa96-4572-a93c-b2acb1dc73aa",
    iconSet: "MaterialCommunityIcons",
    iconName: "globe-model"
  },
  {
    uuid: "6e18d905-d05f-4e0c-a529-22037253a514",
    iconSet: "MaterialCommunityIcons",
    iconName: "gmail"
  },
  {
    uuid: "e4805770-2d0f-4e47-9daa-fef02d2ab50a",
    iconSet: "MaterialCommunityIcons",
    iconName: "gnome"
  },
  {
    uuid: "d91526e8-3513-4419-a2cc-2bc81bb8ee3a",
    iconSet: "MaterialCommunityIcons",
    iconName: "go-kart"
  },
  {
    uuid: "51ee3712-fd9c-4833-9cad-9d3d0631b29f",
    iconSet: "MaterialCommunityIcons",
    iconName: "go-kart-track"
  },
  {
    uuid: "b1579b91-e696-4b9a-bb40-d64e851e16c2",
    iconSet: "MaterialCommunityIcons",
    iconName: "gog"
  },
  {
    uuid: "0fd00579-4116-4262-9c7d-274d0edfeda4",
    iconSet: "MaterialCommunityIcons",
    iconName: "gold"
  },
  {
    uuid: "86ed6c89-bb39-42c8-86f9-bb423b369da9",
    iconSet: "MaterialCommunityIcons",
    iconName: "golf"
  },
  {
    uuid: "33e1553d-1b16-45c3-902a-664944c3f09c",
    iconSet: "MaterialCommunityIcons",
    iconName: "golf-cart"
  },
  {
    uuid: "6b6b9fc6-771b-4b74-922a-fdf37063be94",
    iconSet: "MaterialCommunityIcons",
    iconName: "golf-tee"
  },
  {
    uuid: "8f9cc898-ac40-42c0-860c-fb400c7f95ed",
    iconSet: "MaterialCommunityIcons",
    iconName: "gondola"
  },
  {
    uuid: "93d42bf8-d36c-4989-94bc-0646c3e54c79",
    iconSet: "MaterialCommunityIcons",
    iconName: "goodreads"
  },
  {
    uuid: "f010efa1-d3a7-427c-9855-27da3cddff5b",
    iconSet: "MaterialCommunityIcons",
    iconName: "google"
  },
  {
    uuid: "de92cb65-6207-4732-af95-3e85a4254d49",
    iconSet: "MaterialCommunityIcons",
    iconName: "google-ads"
  },
  {
    uuid: "07e3454a-78f0-4ac5-aedb-207234e84acf",
    iconSet: "MaterialCommunityIcons",
    iconName: "google-analytics"
  },
  {
    uuid: "8dabc2da-b6b8-4837-9bb1-6fdf8b61772d",
    iconSet: "MaterialCommunityIcons",
    iconName: "google-assistant"
  },
  {
    uuid: "2e2cfab5-c2f7-46f7-9fb3-c2cb83f2fae2",
    iconSet: "MaterialCommunityIcons",
    iconName: "google-cardboard"
  },
  {
    uuid: "7fd9a9c6-ea23-472c-a3f1-b856d842f10a",
    iconSet: "MaterialCommunityIcons",
    iconName: "google-chrome"
  },
  {
    uuid: "ce1f6eaa-6616-4981-994a-e30bb1aad066",
    iconSet: "MaterialCommunityIcons",
    iconName: "google-circles"
  },
  {
    uuid: "75379b82-b718-470f-ac7b-137a4fc6e976",
    iconSet: "MaterialCommunityIcons",
    iconName: "google-circles-communities"
  },
  {
    uuid: "e3a0298e-e945-46c8-9e95-d420b85c6263",
    iconSet: "MaterialCommunityIcons",
    iconName: "google-circles-extended"
  },
  {
    uuid: "15a9e43b-ed34-4438-8b5e-a9e05e482dd6",
    iconSet: "MaterialCommunityIcons",
    iconName: "google-circles-group"
  },
  {
    uuid: "8db1efcb-afdf-4d58-bf23-f1344c2ec736",
    iconSet: "MaterialCommunityIcons",
    iconName: "google-classroom"
  },
  {
    uuid: "990df874-c39a-4b34-940f-9b9a06a70277",
    iconSet: "MaterialCommunityIcons",
    iconName: "google-cloud"
  },
  {
    uuid: "f944fa05-9570-4e21-b8c7-28b8782d182f",
    iconSet: "MaterialCommunityIcons",
    iconName: "google-controller"
  },
  {
    uuid: "5a8d7605-5c17-48e0-b024-2171d950eb29",
    iconSet: "MaterialCommunityIcons",
    iconName: "google-controller-off"
  },
  {
    uuid: "c60a8dd1-73b5-4062-ad5b-2dee7305afb7",
    iconSet: "MaterialCommunityIcons",
    iconName: "google-downasaur"
  },
  {
    uuid: "0c559f60-8728-41fd-807e-2bf62f6256df",
    iconSet: "MaterialCommunityIcons",
    iconName: "google-drive"
  },
  {
    uuid: "18bb022c-70e0-4140-b960-4b6b2b82a721",
    iconSet: "MaterialCommunityIcons",
    iconName: "google-earth"
  },
  {
    uuid: "36b7812c-30c9-44bf-bcdf-f14be45471ea",
    iconSet: "MaterialCommunityIcons",
    iconName: "google-fit"
  },
  {
    uuid: "647feadb-2764-46d9-b952-448c3f89f913",
    iconSet: "MaterialCommunityIcons",
    iconName: "google-glass"
  },
  {
    uuid: "27f6b24d-74da-44ae-9b4e-0e6c6041ab6b",
    iconSet: "MaterialCommunityIcons",
    iconName: "google-hangouts"
  },
  {
    uuid: "9b297d7a-0473-42f3-88a4-55033959691b",
    iconSet: "MaterialCommunityIcons",
    iconName: "google-home"
  },
  {
    uuid: "ce83b107-3c97-473e-8a71-5082307a50dd",
    iconSet: "MaterialCommunityIcons",
    iconName: "google-keep"
  },
  {
    uuid: "cd2c67ab-7ef1-4eb1-a932-2f4d825d9a0c",
    iconSet: "MaterialCommunityIcons",
    iconName: "google-lens"
  },
  {
    uuid: "9720d954-e131-4b7e-96b6-bb6506dca5db",
    iconSet: "MaterialCommunityIcons",
    iconName: "google-maps"
  },
  {
    uuid: "8e76e6ad-43ff-4fa4-9246-302dd298aa33",
    iconSet: "MaterialCommunityIcons",
    iconName: "google-my-business"
  },
  {
    uuid: "9c9950fe-f4c5-404c-b730-75178374d484",
    iconSet: "MaterialCommunityIcons",
    iconName: "google-nearby"
  },
  {
    uuid: "b72fdc47-3cab-48ec-906c-82841eacc96c",
    iconSet: "MaterialCommunityIcons",
    iconName: "google-photos"
  },
  {
    uuid: "56d88b18-4e0e-4967-9c0b-f18e58f11e69",
    iconSet: "MaterialCommunityIcons",
    iconName: "google-play"
  },
  {
    uuid: "862cfec2-1af1-4e32-9058-0c5163af7d63",
    iconSet: "MaterialCommunityIcons",
    iconName: "google-plus"
  },
  {
    uuid: "987da773-959c-488f-a26d-61d0de910344",
    iconSet: "MaterialCommunityIcons",
    iconName: "google-podcast"
  },
  {
    uuid: "62e2ac5a-0517-4975-90a8-c8d9d9bdfb73",
    iconSet: "MaterialCommunityIcons",
    iconName: "google-spreadsheet"
  },
  {
    uuid: "475cb559-d399-458b-9e1f-793c4765cf78",
    iconSet: "MaterialCommunityIcons",
    iconName: "google-street-view"
  },
  {
    uuid: "9d8f831c-8648-4329-b22f-73aee8cb9ac4",
    iconSet: "MaterialCommunityIcons",
    iconName: "google-translate"
  },
  {
    uuid: "2878b1da-fc18-4fdf-bb10-5b174c8447d5",
    iconSet: "MaterialCommunityIcons",
    iconName: "gradient"
  },
  {
    uuid: "1ef41f9d-7c03-4079-9836-970540b7aa3d",
    iconSet: "MaterialCommunityIcons",
    iconName: "grain"
  },
  {
    uuid: "60c2e412-92c1-437f-8c7b-de786eb6eb05",
    iconSet: "MaterialCommunityIcons",
    iconName: "graph"
  },
  {
    uuid: "d31f79b3-b3ae-40b7-93d7-15ebaec4c0f0",
    iconSet: "MaterialCommunityIcons",
    iconName: "graph-outline"
  },
  {
    uuid: "7b330883-6909-4600-b282-2fbb35f9f48c",
    iconSet: "MaterialCommunityIcons",
    iconName: "graphql"
  },
  {
    uuid: "026eb279-df90-481f-b54c-8ef37297e83a",
    iconSet: "MaterialCommunityIcons",
    iconName: "grave-stone"
  },
  {
    uuid: "31f7411b-8142-427e-bd64-c506e5b7012f",
    iconSet: "MaterialCommunityIcons",
    iconName: "grease-pencil"
  },
  {
    uuid: "d2b20d49-63de-4b75-9b66-7bd9618c4d62",
    iconSet: "MaterialCommunityIcons",
    iconName: "greater-than"
  },
  {
    uuid: "eefe832c-7ac0-4ebd-8d5d-4d1260988216",
    iconSet: "MaterialCommunityIcons",
    iconName: "greater-than-or-equal"
  },
  {
    uuid: "0ba22673-de9d-4d10-ba67-41de3546baab",
    iconSet: "MaterialCommunityIcons",
    iconName: "grid"
  },
  {
    uuid: "bb995f56-d557-430a-9aef-40c3ae6afd3f",
    iconSet: "MaterialCommunityIcons",
    iconName: "grid-large"
  },
  {
    uuid: "43a9f4c4-b24e-43bc-a4ec-baa2621a5cde",
    iconSet: "MaterialCommunityIcons",
    iconName: "grid-off"
  },
  {
    uuid: "7b42175a-6ff0-4156-9756-bb96e5c225f1",
    iconSet: "MaterialCommunityIcons",
    iconName: "grill"
  },
  {
    uuid: "41bf3e60-422c-4752-a712-aee063e2510c",
    iconSet: "MaterialCommunityIcons",
    iconName: "grill-outline"
  },
  {
    uuid: "84001960-7c34-4beb-a0fc-3256c580352d",
    iconSet: "MaterialCommunityIcons",
    iconName: "group"
  },
  {
    uuid: "6bdfbb69-e382-4327-baa0-1538bf2f11af",
    iconSet: "MaterialCommunityIcons",
    iconName: "guitar-acoustic"
  },
  {
    uuid: "0dd15e0f-f9f5-4e91-b2dd-2fae3c91b0ce",
    iconSet: "MaterialCommunityIcons",
    iconName: "guitar-electric"
  },
  {
    uuid: "242fb95c-e673-420e-9e1d-16b2902c91d1",
    iconSet: "MaterialCommunityIcons",
    iconName: "guitar-pick"
  },
  {
    uuid: "7a87b65b-e695-45f4-9136-d44887769ed1",
    iconSet: "MaterialCommunityIcons",
    iconName: "guitar-pick-outline"
  },
  {
    uuid: "d6e5f9bf-1300-4b24-bcce-922dff50640e",
    iconSet: "MaterialCommunityIcons",
    iconName: "guy-fawkes-mask"
  },
  {
    uuid: "0494b0aa-a091-482f-aea4-b3b80dc369d7",
    iconSet: "MaterialCommunityIcons",
    iconName: "hail"
  },
  {
    uuid: "5dca9ca1-2d91-4a88-b5e3-7dd813fe3679",
    iconSet: "MaterialCommunityIcons",
    iconName: "hair-dryer"
  },
  {
    uuid: "7eb04378-a007-4563-9920-6adcd2837e81",
    iconSet: "MaterialCommunityIcons",
    iconName: "hair-dryer-outline"
  },
  {
    uuid: "228c6965-6748-4656-9e5b-40f2d8690a8a",
    iconSet: "MaterialCommunityIcons",
    iconName: "halloween"
  },
  {
    uuid: "059da741-5c54-4317-a477-f79e1a50bf07",
    iconSet: "MaterialCommunityIcons",
    iconName: "hamburger"
  },
  {
    uuid: "8df6b511-8497-4c3e-908f-39a2d0c59707",
    iconSet: "MaterialCommunityIcons",
    iconName: "hammer"
  },
  {
    uuid: "0888aae4-c063-4ec6-8c23-35f00b0df8b7",
    iconSet: "MaterialCommunityIcons",
    iconName: "hammer-screwdriver"
  },
  {
    uuid: "e796afdf-d50f-4786-b5fc-dec50e0f55fd",
    iconSet: "MaterialCommunityIcons",
    iconName: "hammer-wrench"
  },
  {
    uuid: "8192cacc-ff21-4fd2-b8b2-2ba8695abdeb",
    iconSet: "MaterialCommunityIcons",
    iconName: "hand"
  },
  {
    uuid: "f4e0628e-5ffc-415c-b1c4-3d11372617ab",
    iconSet: "MaterialCommunityIcons",
    iconName: "hand-heart"
  },
  {
    uuid: "fcee9438-6f3b-48a0-9f08-4cb60ccba742",
    iconSet: "MaterialCommunityIcons",
    iconName: "hand-left"
  },
  {
    uuid: "92a55587-e999-4e8b-a28e-a946f68d24b7",
    iconSet: "MaterialCommunityIcons",
    iconName: "hand-okay"
  },
  {
    uuid: "7383b078-44b9-47f5-9e01-4ab4ec2888a6",
    iconSet: "MaterialCommunityIcons",
    iconName: "hand-peace"
  },
  {
    uuid: "8b8a4001-b6d2-41e1-ac69-9b673b12814a",
    iconSet: "MaterialCommunityIcons",
    iconName: "hand-peace-variant"
  },
  {
    uuid: "37831c09-61e9-494a-906a-a414bb496249",
    iconSet: "MaterialCommunityIcons",
    iconName: "hand-pointing-down"
  },
  {
    uuid: "f4f54a84-b31d-4aa7-966c-c077ce3b4c09",
    iconSet: "MaterialCommunityIcons",
    iconName: "hand-pointing-left"
  },
  {
    uuid: "69f14557-c18d-43d0-bb0a-ab92d6881f64",
    iconSet: "MaterialCommunityIcons",
    iconName: "hand-pointing-right"
  },
  {
    uuid: "6df0e5ec-46f1-44b3-989a-20bce2c5703a",
    iconSet: "MaterialCommunityIcons",
    iconName: "hand-pointing-up"
  },
  {
    uuid: "72def360-0a90-4f68-95bb-f835195594ec",
    iconSet: "MaterialCommunityIcons",
    iconName: "hand-right"
  },
  {
    uuid: "43623d31-7f07-4f5d-84c2-e3444f7748f6",
    iconSet: "MaterialCommunityIcons",
    iconName: "hand-saw"
  },
  {
    uuid: "2d648bfe-cf05-4147-aa99-611be055d0b8",
    iconSet: "MaterialCommunityIcons",
    iconName: "hand-water"
  },
  {
    uuid: "99fa878e-62a7-4da3-bb63-eb15ab600d0a",
    iconSet: "MaterialCommunityIcons",
    iconName: "handball"
  },
  {
    uuid: "8645a9ff-f216-46d4-a172-8111732c96d4",
    iconSet: "MaterialCommunityIcons",
    iconName: "handcuffs"
  },
  {
    uuid: "a6bbf97c-394d-40f0-8d6c-85a52ee4e710",
    iconSet: "MaterialCommunityIcons",
    iconName: "handshake"
  },
  {
    uuid: "7b482ec0-56fb-47b8-ae7d-f86a05fa7562",
    iconSet: "MaterialCommunityIcons",
    iconName: "hanger"
  },
  {
    uuid: "90c6f9c7-ec15-4f06-8188-fbca974fef68",
    iconSet: "MaterialCommunityIcons",
    iconName: "hard-hat"
  },
  {
    uuid: "00a7c141-8730-42f0-b994-1ca8a35f6920",
    iconSet: "MaterialCommunityIcons",
    iconName: "harddisk"
  },
  {
    uuid: "024ab23b-5183-4353-9b56-6c76ebb4182b",
    iconSet: "MaterialCommunityIcons",
    iconName: "harddisk-plus"
  },
  {
    uuid: "ca5898db-935f-4e3c-bc50-765277b1c829",
    iconSet: "MaterialCommunityIcons",
    iconName: "harddisk-remove"
  },
  {
    uuid: "8474e9a4-d70b-4034-96b5-4d58a5e3016a",
    iconSet: "MaterialCommunityIcons",
    iconName: "hat-fedora"
  },
  {
    uuid: "8e643696-ab2e-4b85-82ec-a2fb34a669c0",
    iconSet: "MaterialCommunityIcons",
    iconName: "hazard-lights"
  },
  {
    uuid: "ee71b3c8-dcb3-44d8-b653-9005f3f944b7",
    iconSet: "MaterialCommunityIcons",
    iconName: "hdr"
  },
  {
    uuid: "e6bc2e4d-cfb1-4b02-b0f2-f69e69a599aa",
    iconSet: "MaterialCommunityIcons",
    iconName: "hdr-off"
  },
  {
    uuid: "e5402a7b-46a5-46a6-b745-112079e474ad",
    iconSet: "MaterialCommunityIcons",
    iconName: "head"
  },
  {
    uuid: "0d5c1f3f-164c-40a5-98eb-8b2d8a7db4d5",
    iconSet: "MaterialCommunityIcons",
    iconName: "head-alert"
  },
  {
    uuid: "b3e5c706-3378-4963-8b2c-c6809bf72f3f",
    iconSet: "MaterialCommunityIcons",
    iconName: "head-alert-outline"
  },
  {
    uuid: "d3bcb754-19c5-4e81-b2e5-6d0271ed04d7",
    iconSet: "MaterialCommunityIcons",
    iconName: "head-check"
  },
  {
    uuid: "a1c8e637-a318-41f0-a7db-6c627c763471",
    iconSet: "MaterialCommunityIcons",
    iconName: "head-check-outline"
  },
  {
    uuid: "194e22ae-f3e8-497b-8f0c-91db540220d5",
    iconSet: "MaterialCommunityIcons",
    iconName: "head-cog"
  },
  {
    uuid: "fc2f4546-7708-401a-a59a-1b5905f124d0",
    iconSet: "MaterialCommunityIcons",
    iconName: "head-cog-outline"
  },
  {
    uuid: "79c969f1-5191-46ba-aba1-31d4652e5b5c",
    iconSet: "MaterialCommunityIcons",
    iconName: "head-dots-horizontal"
  },
  {
    uuid: "e325e955-9308-4776-96c1-af44eb1885f0",
    iconSet: "MaterialCommunityIcons",
    iconName: "head-dots-horizontal-outline"
  },
  {
    uuid: "c453b650-f08f-4e70-9ef8-a33a498fa7a5",
    iconSet: "MaterialCommunityIcons",
    iconName: "head-flash"
  },
  {
    uuid: "8d1a589b-af52-40e9-9fdc-2b25d6a2b140",
    iconSet: "MaterialCommunityIcons",
    iconName: "head-flash-outline"
  },
  {
    uuid: "bb6f6193-c636-4845-aa7e-b3606e81bb00",
    iconSet: "MaterialCommunityIcons",
    iconName: "head-heart"
  },
  {
    uuid: "a0be751c-c61c-4fe1-bb55-dadb3bde3511",
    iconSet: "MaterialCommunityIcons",
    iconName: "head-heart-outline"
  },
  {
    uuid: "12889a76-909f-4769-b193-9fe1a20a6d74",
    iconSet: "MaterialCommunityIcons",
    iconName: "head-lightbulb"
  },
  {
    uuid: "70cba811-57b2-4ce4-b1b5-9e7c38cc5b62",
    iconSet: "MaterialCommunityIcons",
    iconName: "head-lightbulb-outline"
  },
  {
    uuid: "7959eba0-1da8-4acf-aa2b-a96e020d7457",
    iconSet: "MaterialCommunityIcons",
    iconName: "head-minus"
  },
  {
    uuid: "7c5fec9d-c485-4b6a-a628-ade8e3691753",
    iconSet: "MaterialCommunityIcons",
    iconName: "head-minus-outline"
  },
  {
    uuid: "ccb5e157-0e89-47f3-8c6f-0c8ced7c3cc8",
    iconSet: "MaterialCommunityIcons",
    iconName: "head-outline"
  },
  {
    uuid: "d5d5935c-db7f-412c-aab8-9ec98db94e1c",
    iconSet: "MaterialCommunityIcons",
    iconName: "head-plus"
  },
  {
    uuid: "b08be54a-560c-4d4c-b402-171f2b701eef",
    iconSet: "MaterialCommunityIcons",
    iconName: "head-plus-outline"
  },
  {
    uuid: "eaf964d7-323a-4984-92da-ef5069b59837",
    iconSet: "MaterialCommunityIcons",
    iconName: "head-question"
  },
  {
    uuid: "79910fe5-7c87-4734-a4c5-859c271b3b0b",
    iconSet: "MaterialCommunityIcons",
    iconName: "head-question-outline"
  },
  {
    uuid: "0fd21232-ba45-471e-b364-f1cfa4ed6f75",
    iconSet: "MaterialCommunityIcons",
    iconName: "head-remove"
  },
  {
    uuid: "828bc9ad-9bbc-4be1-bb84-41823c5a9a54",
    iconSet: "MaterialCommunityIcons",
    iconName: "head-remove-outline"
  },
  {
    uuid: "443f8c53-c3e8-477d-8608-c078829c404e",
    iconSet: "MaterialCommunityIcons",
    iconName: "head-snowflake"
  },
  {
    uuid: "a479b4cc-5220-4e5e-8575-2caba4d6ef9b",
    iconSet: "MaterialCommunityIcons",
    iconName: "head-snowflake-outline"
  },
  {
    uuid: "6b8cd759-efc7-45c2-88ed-7d4286c05283",
    iconSet: "MaterialCommunityIcons",
    iconName: "head-sync"
  },
  {
    uuid: "051b1d47-61f1-4c8c-8c15-839240f1f3a7",
    iconSet: "MaterialCommunityIcons",
    iconName: "head-sync-outline"
  },
  {
    uuid: "70c030ce-b923-41c8-aff5-d603613a78d0",
    iconSet: "MaterialCommunityIcons",
    iconName: "headphones"
  },
  {
    uuid: "44cac73b-4231-414c-b474-a3df5c2e6456",
    iconSet: "MaterialCommunityIcons",
    iconName: "headphones-bluetooth"
  },
  {
    uuid: "2d63b35b-a2ad-41e1-825a-629503882155",
    iconSet: "MaterialCommunityIcons",
    iconName: "headphones-box"
  },
  {
    uuid: "51477495-4e88-4455-b5d5-3ca7b65bfd17",
    iconSet: "MaterialCommunityIcons",
    iconName: "headphones-off"
  },
  {
    uuid: "0b94d578-9844-434b-a32d-5c73f22e5c9b",
    iconSet: "MaterialCommunityIcons",
    iconName: "headphones-settings"
  },
  {
    uuid: "864c7d1b-b8e9-4c8b-8216-02ca26bbc5bb",
    iconSet: "MaterialCommunityIcons",
    iconName: "headset"
  },
  {
    uuid: "c81a42e7-aa75-4b7c-b05a-4317266c1f0d",
    iconSet: "MaterialCommunityIcons",
    iconName: "headset-dock"
  },
  {
    uuid: "092cba21-c2d5-4716-9b86-4e3a30058b3d",
    iconSet: "MaterialCommunityIcons",
    iconName: "headset-off"
  },
  {
    uuid: "5cf37a02-a49d-4acc-962e-6cde59327230",
    iconSet: "MaterialCommunityIcons",
    iconName: "heart"
  },
  {
    uuid: "cf14a516-a06e-4e96-aff5-9ac61586ca5b",
    iconSet: "MaterialCommunityIcons",
    iconName: "heart-box"
  },
  {
    uuid: "e4464942-d4cf-48f0-b510-e2f897bc1be1",
    iconSet: "MaterialCommunityIcons",
    iconName: "heart-box-outline"
  },
  {
    uuid: "7dc97fb9-6036-4f87-a32b-3c4b4fa1a7a4",
    iconSet: "MaterialCommunityIcons",
    iconName: "heart-broken"
  },
  {
    uuid: "b6e41550-90cf-4a34-bdce-2bd80e8731b3",
    iconSet: "MaterialCommunityIcons",
    iconName: "heart-broken-outline"
  },
  {
    uuid: "d67a3d4a-9bf5-4051-8876-20d509b42604",
    iconSet: "MaterialCommunityIcons",
    iconName: "heart-circle"
  },
  {
    uuid: "81b0617b-4aeb-485d-8da3-0b6aa4d41c61",
    iconSet: "MaterialCommunityIcons",
    iconName: "heart-circle-outline"
  },
  {
    uuid: "9625255c-a038-45c1-9340-eeb9ad6e80aa",
    iconSet: "MaterialCommunityIcons",
    iconName: "heart-flash"
  },
  {
    uuid: "6e75829e-2aba-4ac5-8003-014ead8bf474",
    iconSet: "MaterialCommunityIcons",
    iconName: "heart-half"
  },
  {
    uuid: "b781c176-461e-4a62-956d-041d09d40970",
    iconSet: "MaterialCommunityIcons",
    iconName: "heart-half-full"
  },
  {
    uuid: "036c6cce-5e1a-4e95-843f-b24715d86d7f",
    iconSet: "MaterialCommunityIcons",
    iconName: "heart-half-outline"
  },
  {
    uuid: "09d56934-d8c4-4569-b637-ce2f1f9a1fde",
    iconSet: "MaterialCommunityIcons",
    iconName: "heart-minus"
  },
  {
    uuid: "4971ddd7-ce19-426f-a2a7-891169d09564",
    iconSet: "MaterialCommunityIcons",
    iconName: "heart-minus-outline"
  },
  {
    uuid: "08602978-4480-436f-81cd-df8dd5e2d523",
    iconSet: "MaterialCommunityIcons",
    iconName: "heart-multiple"
  },
  {
    uuid: "cd5c2f2f-f45d-40ef-b31f-d63da721ea95",
    iconSet: "MaterialCommunityIcons",
    iconName: "heart-multiple-outline"
  },
  {
    uuid: "529d3e69-44ea-4c06-8997-baa606cbcbb6",
    iconSet: "MaterialCommunityIcons",
    iconName: "heart-off"
  },
  {
    uuid: "504089ea-e6dd-4256-9ca6-7c2abc9c0b7e",
    iconSet: "MaterialCommunityIcons",
    iconName: "heart-off-outline"
  },
  {
    uuid: "304d9d4c-53a4-4c52-a3d0-3710f8b14374",
    iconSet: "MaterialCommunityIcons",
    iconName: "heart-outline"
  },
  {
    uuid: "2fc744d2-52d8-4fdb-86d7-54041fc3e7cc",
    iconSet: "MaterialCommunityIcons",
    iconName: "heart-plus"
  },
  {
    uuid: "7e8c5fa5-2b29-45aa-8c6a-91b727ca3231",
    iconSet: "MaterialCommunityIcons",
    iconName: "heart-plus-outline"
  },
  {
    uuid: "1de35536-ab80-4eb2-aa17-06c55fbf6696",
    iconSet: "MaterialCommunityIcons",
    iconName: "heart-pulse"
  },
  {
    uuid: "9815f201-8d11-439e-914a-1b80d824a95c",
    iconSet: "MaterialCommunityIcons",
    iconName: "heart-remove"
  },
  {
    uuid: "717ed97e-f6f4-449b-8315-e651cf2835f6",
    iconSet: "MaterialCommunityIcons",
    iconName: "heart-remove-outline"
  },
  {
    uuid: "6fb91818-5a49-40fe-91ca-9326b28c31e0",
    iconSet: "MaterialCommunityIcons",
    iconName: "helicopter"
  },
  {
    uuid: "ec90e8aa-236e-4bfc-ad73-b0ec8291ced9",
    iconSet: "MaterialCommunityIcons",
    iconName: "help"
  },
  {
    uuid: "5909e7c3-c01e-4013-bf3f-930ef4b5ebef",
    iconSet: "MaterialCommunityIcons",
    iconName: "help-box"
  },
  {
    uuid: "8df32791-80f7-4391-bdf9-db77e154631f",
    iconSet: "MaterialCommunityIcons",
    iconName: "help-circle"
  },
  {
    uuid: "71bff743-16e4-4b56-93dc-b17dc121f1ba",
    iconSet: "MaterialCommunityIcons",
    iconName: "help-circle-outline"
  },
  {
    uuid: "0431f7ec-7458-40de-90f2-d95059d1ae82",
    iconSet: "MaterialCommunityIcons",
    iconName: "help-network"
  },
  {
    uuid: "52e001ba-86d7-40f9-97c9-bdf9aaa9ce5f",
    iconSet: "MaterialCommunityIcons",
    iconName: "help-network-outline"
  },
  {
    uuid: "d94927ec-0a69-4563-af92-d82fb2277fd5",
    iconSet: "MaterialCommunityIcons",
    iconName: "help-rhombus"
  },
  {
    uuid: "6893710d-a2b8-46ea-b23d-a8ffc447f051",
    iconSet: "MaterialCommunityIcons",
    iconName: "help-rhombus-outline"
  },
  {
    uuid: "a067c2b1-be3b-4400-8a54-709f36f1f82e",
    iconSet: "MaterialCommunityIcons",
    iconName: "hexadecimal"
  },
  {
    uuid: "567b8b75-46bb-4bef-af78-7fab1f04b681",
    iconSet: "MaterialCommunityIcons",
    iconName: "hexagon"
  },
  {
    uuid: "f977cac1-11a1-43b8-98bd-f068b6b9677d",
    iconSet: "MaterialCommunityIcons",
    iconName: "hexagon-multiple"
  },
  {
    uuid: "28dd5a2c-7feb-4962-a6a3-f9609b615cc5",
    iconSet: "MaterialCommunityIcons",
    iconName: "hexagon-multiple-outline"
  },
  {
    uuid: "3d9a4770-d89c-47c0-9163-cb3e8a2be73c",
    iconSet: "MaterialCommunityIcons",
    iconName: "hexagon-outline"
  },
  {
    uuid: "35565dd4-67ad-484e-b80f-976d31aea657",
    iconSet: "MaterialCommunityIcons",
    iconName: "hexagon-slice-1"
  },
  {
    uuid: "81694371-b12d-4cf1-8b21-7cd3b6e1cb53",
    iconSet: "MaterialCommunityIcons",
    iconName: "hexagon-slice-2"
  },
  {
    uuid: "f72262fe-823a-449e-9ed3-ccb5a8da87ac",
    iconSet: "MaterialCommunityIcons",
    iconName: "hexagon-slice-3"
  },
  {
    uuid: "1e365c6e-59d3-421f-8a96-f051b32313d0",
    iconSet: "MaterialCommunityIcons",
    iconName: "hexagon-slice-4"
  },
  {
    uuid: "9fb57112-100c-473f-8ac9-be45f5dc7f32",
    iconSet: "MaterialCommunityIcons",
    iconName: "hexagon-slice-5"
  },
  {
    uuid: "2b9707e6-974d-4085-998a-1ce5c312e4ec",
    iconSet: "MaterialCommunityIcons",
    iconName: "hexagon-slice-6"
  },
  {
    uuid: "6af0ce94-761c-4b78-80de-e6620c9e9863",
    iconSet: "MaterialCommunityIcons",
    iconName: "hexagram"
  },
  {
    uuid: "8d668f45-0d54-4c7b-b1c7-7997766e7ea6",
    iconSet: "MaterialCommunityIcons",
    iconName: "hexagram-outline"
  },
  {
    uuid: "54d9b3e9-1e47-4636-8dc7-f35a722214b5",
    iconSet: "MaterialCommunityIcons",
    iconName: "high-definition"
  },
  {
    uuid: "99e6e8d2-6064-46f0-9740-b4793295b91b",
    iconSet: "MaterialCommunityIcons",
    iconName: "high-definition-box"
  },
  {
    uuid: "41923fe6-27bc-433a-b251-14a74d423812",
    iconSet: "MaterialCommunityIcons",
    iconName: "highway"
  },
  {
    uuid: "e7260159-764d-44bf-aa35-8c9c4cb164ed",
    iconSet: "MaterialCommunityIcons",
    iconName: "hiking"
  },
  {
    uuid: "5211e5df-5f3f-432f-ada5-5488034ef08a",
    iconSet: "MaterialCommunityIcons",
    iconName: "hinduism"
  },
  {
    uuid: "7b897e4a-72d5-4133-bf29-9d9ffba3b3eb",
    iconSet: "MaterialCommunityIcons",
    iconName: "history"
  },
  {
    uuid: "24f50e96-695e-4344-9429-f512ad26752f",
    iconSet: "MaterialCommunityIcons",
    iconName: "hockey-puck"
  },
  {
    uuid: "606091f5-6883-456d-ad57-5a221e51ff23",
    iconSet: "MaterialCommunityIcons",
    iconName: "hockey-sticks"
  },
  {
    uuid: "afa1d779-8d18-419a-8718-c045a83c5bf7",
    iconSet: "MaterialCommunityIcons",
    iconName: "hololens"
  },
  {
    uuid: "307a3847-382d-481e-9eb8-89ea8921aef8",
    iconSet: "MaterialCommunityIcons",
    iconName: "home"
  },
  {
    uuid: "3fec276f-7dd7-4838-8d19-05c41f2b85e8",
    iconSet: "MaterialCommunityIcons",
    iconName: "home-account"
  },
  {
    uuid: "a44a1bc4-b952-4d64-a1d2-a99563639d35",
    iconSet: "MaterialCommunityIcons",
    iconName: "home-alert"
  },
  {
    uuid: "ac8fb995-ddce-4598-9160-5c52397cd52b",
    iconSet: "MaterialCommunityIcons",
    iconName: "home-analytics"
  },
  {
    uuid: "9f7d81f8-f7d0-4d5e-8865-0238d85a2899",
    iconSet: "MaterialCommunityIcons",
    iconName: "home-assistant"
  },
  {
    uuid: "5af7a13a-4af0-4b35-8f13-9275d288fb2d",
    iconSet: "MaterialCommunityIcons",
    iconName: "home-automation"
  },
  {
    uuid: "8285c52a-f587-4b93-8846-3bbc48fa3750",
    iconSet: "MaterialCommunityIcons",
    iconName: "home-circle"
  },
  {
    uuid: "b2e0da61-4a98-4622-a08d-8c247fbbe4aa",
    iconSet: "MaterialCommunityIcons",
    iconName: "home-circle-outline"
  },
  {
    uuid: "b7a3a9d5-cbee-44a8-995d-e264d8b9856a",
    iconSet: "MaterialCommunityIcons",
    iconName: "home-city"
  },
  {
    uuid: "ac3525b6-6e92-4c9c-a77b-9b648a4d362d",
    iconSet: "MaterialCommunityIcons",
    iconName: "home-city-outline"
  },
  {
    uuid: "fc03ee32-531b-4d9b-aa89-379ed8780cdb",
    iconSet: "MaterialCommunityIcons",
    iconName: "home-currency-usd"
  },
  {
    uuid: "411e67d8-9703-4f3f-b03c-8cde1ef052b7",
    iconSet: "MaterialCommunityIcons",
    iconName: "home-edit"
  },
  {
    uuid: "6f163967-2de5-4725-b264-e3156a241f37",
    iconSet: "MaterialCommunityIcons",
    iconName: "home-edit-outline"
  },
  {
    uuid: "f85f447d-068a-483a-9b0b-92f97c5a0da7",
    iconSet: "MaterialCommunityIcons",
    iconName: "home-export-outline"
  },
  {
    uuid: "d4180c90-e4b2-4698-b058-600198fbb2a4",
    iconSet: "MaterialCommunityIcons",
    iconName: "home-flood"
  },
  {
    uuid: "0b5ee31a-20cb-4a24-a7aa-18d600d7b661",
    iconSet: "MaterialCommunityIcons",
    iconName: "home-floor-0"
  },
  {
    uuid: "ec14ac6f-4e22-4271-b664-35758426aa49",
    iconSet: "MaterialCommunityIcons",
    iconName: "home-floor-1"
  },
  {
    uuid: "c55e8525-53a8-4004-a10a-6d158c454e96",
    iconSet: "MaterialCommunityIcons",
    iconName: "home-floor-2"
  },
  {
    uuid: "07b395a0-611c-40c2-b7f0-05f5224651d0",
    iconSet: "MaterialCommunityIcons",
    iconName: "home-floor-3"
  },
  {
    uuid: "c5967f48-fa35-4951-9089-d72eb07ea043",
    iconSet: "MaterialCommunityIcons",
    iconName: "home-floor-a"
  },
  {
    uuid: "52d03bca-cc32-4749-968d-1f44d7cfe632",
    iconSet: "MaterialCommunityIcons",
    iconName: "home-floor-b"
  },
  {
    uuid: "c35654ae-4da9-4c42-8718-07d61cb22c04",
    iconSet: "MaterialCommunityIcons",
    iconName: "home-floor-g"
  },
  {
    uuid: "7864dd07-5471-4afe-a74e-d2ce00403596",
    iconSet: "MaterialCommunityIcons",
    iconName: "home-floor-l"
  },
  {
    uuid: "25508b26-97b6-4c6d-9376-da447677db5c",
    iconSet: "MaterialCommunityIcons",
    iconName: "home-floor-negative-1"
  },
  {
    uuid: "b142565d-8d6f-4572-9e7f-8d993d4eee4f",
    iconSet: "MaterialCommunityIcons",
    iconName: "home-group"
  },
  {
    uuid: "dab3f778-7000-42ae-a250-3afefc902b7e",
    iconSet: "MaterialCommunityIcons",
    iconName: "home-heart"
  },
  {
    uuid: "74385a33-6bda-42df-bd27-7ed8e7ecf379",
    iconSet: "MaterialCommunityIcons",
    iconName: "home-import-outline"
  },
  {
    uuid: "6321e657-2cf9-47f0-8698-f10b5a68a7e2",
    iconSet: "MaterialCommunityIcons",
    iconName: "home-lightbulb"
  },
  {
    uuid: "bffe21c8-86cf-4635-8219-12ca67e9e3de",
    iconSet: "MaterialCommunityIcons",
    iconName: "home-lightbulb-outline"
  },
  {
    uuid: "1817af6c-0de7-4095-8577-2643d04ac1ea",
    iconSet: "MaterialCommunityIcons",
    iconName: "home-lock"
  },
  {
    uuid: "be375bcf-40d0-44b5-8598-cd7b7fce0677",
    iconSet: "MaterialCommunityIcons",
    iconName: "home-lock-open"
  },
  {
    uuid: "cd8be41f-0c8d-4090-a76e-c42a1b8af0f9",
    iconSet: "MaterialCommunityIcons",
    iconName: "home-map-marker"
  },
  {
    uuid: "ec1c7a84-3e17-4683-9e55-673d6d8e4971",
    iconSet: "MaterialCommunityIcons",
    iconName: "home-minus"
  },
  {
    uuid: "bac97b62-8d2f-4d72-a8d8-7f9d883fa458",
    iconSet: "MaterialCommunityIcons",
    iconName: "home-minus-outline"
  },
  {
    uuid: "2ec434a0-8a4f-477a-b263-d795d0ae1a36",
    iconSet: "MaterialCommunityIcons",
    iconName: "home-modern"
  },
  {
    uuid: "4bad537c-9740-4097-85f9-a721dbbd7781",
    iconSet: "MaterialCommunityIcons",
    iconName: "home-outline"
  },
  {
    uuid: "3011a5db-feb2-4bc4-893f-54bdc0173b9d",
    iconSet: "MaterialCommunityIcons",
    iconName: "home-plus"
  },
  {
    uuid: "77773323-83a0-4ece-8639-2176961900df",
    iconSet: "MaterialCommunityIcons",
    iconName: "home-plus-outline"
  },
  {
    uuid: "95d56ceb-d5c2-4b06-b44a-32583c144bd6",
    iconSet: "MaterialCommunityIcons",
    iconName: "home-remove"
  },
  {
    uuid: "d9afb1e2-e011-48c9-aa6b-853668932bf1",
    iconSet: "MaterialCommunityIcons",
    iconName: "home-remove-outline"
  },
  {
    uuid: "96b7eafc-6ec3-4069-8d93-3dc403239277",
    iconSet: "MaterialCommunityIcons",
    iconName: "home-roof"
  },
  {
    uuid: "51f1555a-530d-41dc-a593-1321ca8cfe0b",
    iconSet: "MaterialCommunityIcons",
    iconName: "home-search"
  },
  {
    uuid: "a5511bd3-cb9f-4351-b505-2b97f8578b99",
    iconSet: "MaterialCommunityIcons",
    iconName: "home-search-outline"
  },
  {
    uuid: "2cfdfcb8-6932-43a6-808a-d23f9553276f",
    iconSet: "MaterialCommunityIcons",
    iconName: "home-thermometer"
  },
  {
    uuid: "47ca1248-fcea-451a-88f4-26132003fc52",
    iconSet: "MaterialCommunityIcons",
    iconName: "home-thermometer-outline"
  },
  {
    uuid: "4b4149bb-590d-4562-9902-f077179926f6",
    iconSet: "MaterialCommunityIcons",
    iconName: "home-variant"
  },
  {
    uuid: "812df9ea-4ab7-4021-8c8d-65b8075709bd",
    iconSet: "MaterialCommunityIcons",
    iconName: "home-variant-outline"
  },
  {
    uuid: "4e6f6144-0d16-4b5f-8d07-6c2c56b84ce5",
    iconSet: "MaterialCommunityIcons",
    iconName: "hook"
  },
  {
    uuid: "1853bbcb-b97b-4061-8b15-493a2c897d1a",
    iconSet: "MaterialCommunityIcons",
    iconName: "hook-off"
  },
  {
    uuid: "028e83fb-f3d2-497f-906f-97d3d031a48b",
    iconSet: "MaterialCommunityIcons",
    iconName: "hops"
  },
  {
    uuid: "eef9b8c9-4a7d-410d-8481-4a9304ebb4e8",
    iconSet: "MaterialCommunityIcons",
    iconName: "horizontal-rotate-clockwise"
  },
  {
    uuid: "d4b157fa-da1e-436d-be04-bc5f140b4255",
    iconSet: "MaterialCommunityIcons",
    iconName: "horizontal-rotate-counterclockwise"
  },
  {
    uuid: "bd7b192d-f984-4b3b-a684-84b0b5257918",
    iconSet: "MaterialCommunityIcons",
    iconName: "horseshoe"
  },
  {
    uuid: "0c8cdabb-5935-49df-85bb-f7a22fe760f9",
    iconSet: "MaterialCommunityIcons",
    iconName: "hospital"
  },
  {
    uuid: "6694e050-93b6-4b5f-b04b-47c3ebf9ebd6",
    iconSet: "MaterialCommunityIcons",
    iconName: "hospital-box"
  },
  {
    uuid: "05d41b9e-c505-41f7-9dac-3b99d352ff9a",
    iconSet: "MaterialCommunityIcons",
    iconName: "hospital-box-outline"
  },
  {
    uuid: "dcaa88f7-ead1-4a91-84d4-584bcee42f35",
    iconSet: "MaterialCommunityIcons",
    iconName: "hospital-building"
  },
  {
    uuid: "bd4c7521-33a5-465b-ac24-69d8e9ee112c",
    iconSet: "MaterialCommunityIcons",
    iconName: "hospital-marker"
  },
  {
    uuid: "510d12ef-5975-4caf-9a85-ef61f4d12f79",
    iconSet: "MaterialCommunityIcons",
    iconName: "hot-tub"
  },
  {
    uuid: "3a26044f-5c1c-4298-9c25-9c1d7cb2aa55",
    iconSet: "MaterialCommunityIcons",
    iconName: "hours-24"
  },
  {
    uuid: "cb077076-0ec6-4018-82a9-d4a69ce50948",
    iconSet: "MaterialCommunityIcons",
    iconName: "hubspot"
  },
  {
    uuid: "ccf30a3e-9be2-45aa-a4c7-67d4cb6ebf6c",
    iconSet: "MaterialCommunityIcons",
    iconName: "hulu"
  },
  {
    uuid: "9423cac3-8f50-4a6a-a5ce-dcd20e54df11",
    iconSet: "MaterialCommunityIcons",
    iconName: "human"
  },
  {
    uuid: "029b6f8a-0cc6-4ffd-868f-91f3274b1e46",
    iconSet: "MaterialCommunityIcons",
    iconName: "human-baby-changing-table"
  },
  {
    uuid: "70a19a86-982d-4076-8f6d-8b0c827a2d5c",
    iconSet: "MaterialCommunityIcons",
    iconName: "human-child"
  },
  {
    uuid: "5fce0bcb-c75c-416e-95f0-5f08027c6f08",
    iconSet: "MaterialCommunityIcons",
    iconName: "human-female"
  },
  {
    uuid: "2fc0c7c4-69f7-4b40-b7e4-2c0fa392ecd2",
    iconSet: "MaterialCommunityIcons",
    iconName: "human-female-boy"
  },
  {
    uuid: "f82b22cd-1f6b-410a-a56e-279e15006d07",
    iconSet: "MaterialCommunityIcons",
    iconName: "human-female-female"
  },
  {
    uuid: "a184e64f-8970-478a-af91-4091c70e274b",
    iconSet: "MaterialCommunityIcons",
    iconName: "human-female-girl"
  },
  {
    uuid: "b7c8058a-e694-4ac5-ab9a-daa6b990a91c",
    iconSet: "MaterialCommunityIcons",
    iconName: "human-greeting"
  },
  {
    uuid: "7687f4fa-7f56-40ce-b179-95aaa3d40550",
    iconSet: "MaterialCommunityIcons",
    iconName: "human-handsdown"
  },
  {
    uuid: "98571ef6-23d4-4b21-9716-000c91ecf213",
    iconSet: "MaterialCommunityIcons",
    iconName: "human-handsup"
  },
  {
    uuid: "b9a74e33-81d0-436e-bd5a-96dd16906b78",
    iconSet: "MaterialCommunityIcons",
    iconName: "human-male"
  },
  {
    uuid: "b8b91841-afc1-40b7-bdcf-966ce2ba0576",
    iconSet: "MaterialCommunityIcons",
    iconName: "human-male-boy"
  },
  {
    uuid: "b26b86c4-fa3e-4e30-9188-cf874a2edfb6",
    iconSet: "MaterialCommunityIcons",
    iconName: "human-male-child"
  },
  {
    uuid: "023a06a3-9afe-4a00-adf2-24e921edbe87",
    iconSet: "MaterialCommunityIcons",
    iconName: "human-male-female"
  },
  {
    uuid: "16d6cbff-bffc-4284-9b86-54a13d4baab8",
    iconSet: "MaterialCommunityIcons",
    iconName: "human-male-girl"
  },
  {
    uuid: "e48eeca4-9de9-43b0-af48-852cb0060ddd",
    iconSet: "MaterialCommunityIcons",
    iconName: "human-male-height"
  },
  {
    uuid: "a97e0ec4-e7fd-43c1-913b-2bc81b06331f",
    iconSet: "MaterialCommunityIcons",
    iconName: "human-male-height-variant"
  },
  {
    uuid: "59d5931b-0d47-4f8b-b3c4-d35f9f11ffe8",
    iconSet: "MaterialCommunityIcons",
    iconName: "human-male-male"
  },
  {
    uuid: "3c232256-daea-41cc-8943-a6b03825bd8a",
    iconSet: "MaterialCommunityIcons",
    iconName: "human-pregnant"
  },
  {
    uuid: "70803d57-9f97-4c22-9bcb-3297bc4a0bbd",
    iconSet: "MaterialCommunityIcons",
    iconName: "human-wheelchair"
  },
  {
    uuid: "4cd812dd-c5c8-4871-9f65-fbbc8d95b9f0",
    iconSet: "MaterialCommunityIcons",
    iconName: "humble-bundle"
  },
  {
    uuid: "b7d5d845-48c1-4503-b58b-4e48d8ec415a",
    iconSet: "MaterialCommunityIcons",
    iconName: "hvac"
  },
  {
    uuid: "a3d5cedd-6340-4118-9a9c-6bd1face4083",
    iconSet: "MaterialCommunityIcons",
    iconName: "hydraulic-oil-level"
  },
  {
    uuid: "b7daf567-0985-40a3-beff-200752b45316",
    iconSet: "MaterialCommunityIcons",
    iconName: "hydraulic-oil-temperature"
  },
  {
    uuid: "5e22ef03-cc69-4f62-a3ae-626eefd24b3c",
    iconSet: "MaterialCommunityIcons",
    iconName: "hydro-power"
  },
  {
    uuid: "f22aead6-7b31-476a-8c74-bd630b066567",
    iconSet: "MaterialCommunityIcons",
    iconName: "ice-cream"
  },
  {
    uuid: "b3518d2a-8acf-4e0e-b7ac-9376ec90e88c",
    iconSet: "MaterialCommunityIcons",
    iconName: "ice-cream-off"
  },
  {
    uuid: "520eb83c-4935-4d3d-b510-5490e55af3ff",
    iconSet: "MaterialCommunityIcons",
    iconName: "ice-pop"
  },
  {
    uuid: "1bbe4f65-1d5d-48e8-91f6-0793a50f8af7",
    iconSet: "MaterialCommunityIcons",
    iconName: "id-card"
  },
  {
    uuid: "4f8746b8-e4ee-4f49-b2d6-7531c2fbf338",
    iconSet: "MaterialCommunityIcons",
    iconName: "identifier"
  },
  {
    uuid: "064db4a0-b20d-4e99-8a97-264cb15ba313",
    iconSet: "MaterialCommunityIcons",
    iconName: "ideogram-cjk"
  },
  {
    uuid: "14121a63-36a6-44f5-b2bb-a504f144de8b",
    iconSet: "MaterialCommunityIcons",
    iconName: "ideogram-cjk-variant"
  },
  {
    uuid: "4bf3134c-7bea-4fd7-b586-5b1857caf632",
    iconSet: "MaterialCommunityIcons",
    iconName: "iframe"
  },
  {
    uuid: "f8a5e9b0-551f-4e39-ab8e-6f54a9c2d118",
    iconSet: "MaterialCommunityIcons",
    iconName: "iframe-array"
  },
  {
    uuid: "a3a6ba2e-de70-4af9-9127-51d6973e5620",
    iconSet: "MaterialCommunityIcons",
    iconName: "iframe-array-outline"
  },
  {
    uuid: "1e2c0d87-5d57-4e07-9c98-e679c971ec31",
    iconSet: "MaterialCommunityIcons",
    iconName: "iframe-braces"
  },
  {
    uuid: "c93b58d1-d465-4ed3-b4ca-199aa515f07a",
    iconSet: "MaterialCommunityIcons",
    iconName: "iframe-braces-outline"
  },
  {
    uuid: "d0a32ae0-4f4d-4705-96cc-a7bb4cec8381",
    iconSet: "MaterialCommunityIcons",
    iconName: "iframe-outline"
  },
  {
    uuid: "a660929e-6a42-4c7f-b7c9-750912d6f213",
    iconSet: "MaterialCommunityIcons",
    iconName: "iframe-parentheses"
  },
  {
    uuid: "ea2bf9d5-5299-41a3-a02b-6c19f759bc0c",
    iconSet: "MaterialCommunityIcons",
    iconName: "iframe-parentheses-outline"
  },
  {
    uuid: "0e050f11-3c0b-4d37-8942-af4b5cacb0f0",
    iconSet: "MaterialCommunityIcons",
    iconName: "iframe-variable"
  },
  {
    uuid: "e394f7cb-a96c-464d-9e1c-df40f86163ef",
    iconSet: "MaterialCommunityIcons",
    iconName: "iframe-variable-outline"
  },
  {
    uuid: "861c6733-a010-4162-a54d-71ec5bc5f262",
    iconSet: "MaterialCommunityIcons",
    iconName: "image"
  },
  {
    uuid: "8905083a-b5f2-4a2c-afe9-5464ecbaf5de",
    iconSet: "MaterialCommunityIcons",
    iconName: "image-album"
  },
  {
    uuid: "b21e2a98-bc0f-46c0-a62a-ab09fe9a14dc",
    iconSet: "MaterialCommunityIcons",
    iconName: "image-area"
  },
  {
    uuid: "96a94722-aef4-4176-91c1-884ed3bde12a",
    iconSet: "MaterialCommunityIcons",
    iconName: "image-area-close"
  },
  {
    uuid: "d456a494-5951-4307-8aa8-8cc454c125f0",
    iconSet: "MaterialCommunityIcons",
    iconName: "image-auto-adjust"
  },
  {
    uuid: "cfae17d3-07e7-46be-bbba-08eff7a28edb",
    iconSet: "MaterialCommunityIcons",
    iconName: "image-broken"
  },
  {
    uuid: "b7eccc9c-2ed8-454e-9866-8537f6594696",
    iconSet: "MaterialCommunityIcons",
    iconName: "image-broken-variant"
  },
  {
    uuid: "86bdddb0-b427-46f0-9b05-e0a6ff398aaa",
    iconSet: "MaterialCommunityIcons",
    iconName: "image-edit"
  },
  {
    uuid: "d1da64dc-fad3-4180-aa8b-99b167274798",
    iconSet: "MaterialCommunityIcons",
    iconName: "image-edit-outline"
  },
  {
    uuid: "a7938309-a8cb-42d1-af12-b175edb60c35",
    iconSet: "MaterialCommunityIcons",
    iconName: "image-filter-black-white"
  },
  {
    uuid: "76eff414-d471-4b50-974e-f3d428de998e",
    iconSet: "MaterialCommunityIcons",
    iconName: "image-filter-center-focus"
  },
  {
    uuid: "6ae5277b-60ac-4edd-9540-7a5cc262d819",
    iconSet: "MaterialCommunityIcons",
    iconName: "image-filter-center-focus-strong"
  },
  {
    uuid: "d11f40e7-4ff6-4ae8-bc80-e473b8c1543b",
    iconSet: "MaterialCommunityIcons",
    iconName: "image-filter-center-focus-strong-outline"
  },
  {
    uuid: "d6b5b0a8-0d88-41e2-b363-638f12549fc6",
    iconSet: "MaterialCommunityIcons",
    iconName: "image-filter-center-focus-weak"
  },
  {
    uuid: "c572f924-d33b-4782-b2c6-b23a50d99b60",
    iconSet: "MaterialCommunityIcons",
    iconName: "image-filter-drama"
  },
  {
    uuid: "1f157c23-0406-4461-b764-dea36582717d",
    iconSet: "MaterialCommunityIcons",
    iconName: "image-filter-frames"
  },
  {
    uuid: "7453166b-c473-4d33-80c5-fda077549a24",
    iconSet: "MaterialCommunityIcons",
    iconName: "image-filter-hdr"
  },
  {
    uuid: "b197d3bd-ea7c-4504-8a3b-6be09a86a605",
    iconSet: "MaterialCommunityIcons",
    iconName: "image-filter-none"
  },
  {
    uuid: "b9255f59-bef0-4b8a-9176-73b00e3ae6d3",
    iconSet: "MaterialCommunityIcons",
    iconName: "image-filter-tilt-shift"
  },
  {
    uuid: "354af1f5-133b-49be-ab3f-1431e462ea46",
    iconSet: "MaterialCommunityIcons",
    iconName: "image-filter-vintage"
  },
  {
    uuid: "6b7289e0-2009-4090-92ef-0f14c299d6af",
    iconSet: "MaterialCommunityIcons",
    iconName: "image-frame"
  },
  {
    uuid: "2a221e41-5916-4925-9546-118598960353",
    iconSet: "MaterialCommunityIcons",
    iconName: "image-minus"
  },
  {
    uuid: "66504d97-68eb-45cf-bbdf-24bdae44e7cb",
    iconSet: "MaterialCommunityIcons",
    iconName: "image-move"
  },
  {
    uuid: "8517bb88-7312-41ed-9839-eed96123c102",
    iconSet: "MaterialCommunityIcons",
    iconName: "image-multiple"
  },
  {
    uuid: "777b0e77-4609-450f-ba11-de98dacacde0",
    iconSet: "MaterialCommunityIcons",
    iconName: "image-multiple-outline"
  },
  {
    uuid: "70633e10-a2a7-448e-bab2-baf20aa987de",
    iconSet: "MaterialCommunityIcons",
    iconName: "image-off"
  },
  {
    uuid: "b798cfea-e7ae-422b-842d-4e8071d9c442",
    iconSet: "MaterialCommunityIcons",
    iconName: "image-off-outline"
  },
  {
    uuid: "64b9e1f6-14d0-43d3-a0a7-f27ac3f7e850",
    iconSet: "MaterialCommunityIcons",
    iconName: "image-outline"
  },
  {
    uuid: "34456ddf-0632-4309-8c2d-c0af0ff1396a",
    iconSet: "MaterialCommunityIcons",
    iconName: "image-plus"
  },
  {
    uuid: "a5f9de09-b498-42e7-83d4-bdd3f9599f5f",
    iconSet: "MaterialCommunityIcons",
    iconName: "image-remove"
  },
  {
    uuid: "600ccf84-01d0-4d6b-bbff-776697902524",
    iconSet: "MaterialCommunityIcons",
    iconName: "image-search"
  },
  {
    uuid: "b246c9b1-7fec-4c8f-ac35-837b947400a4",
    iconSet: "MaterialCommunityIcons",
    iconName: "image-search-outline"
  },
  {
    uuid: "e99f3fa0-6871-4908-b78a-8f6c92e391a1",
    iconSet: "MaterialCommunityIcons",
    iconName: "image-size-select-actual"
  },
  {
    uuid: "fad61939-2f86-4dca-bb33-ae196ed317d1",
    iconSet: "MaterialCommunityIcons",
    iconName: "image-size-select-large"
  },
  {
    uuid: "7668eda0-87c5-4002-9015-bd60aae3af53",
    iconSet: "MaterialCommunityIcons",
    iconName: "image-size-select-small"
  },
  {
    uuid: "69954a9d-3d21-44ad-a1ce-57fa2f2004ce",
    iconSet: "MaterialCommunityIcons",
    iconName: "import"
  },
  {
    uuid: "d1ff2ce8-9a27-4692-a85e-72708d930959",
    iconSet: "MaterialCommunityIcons",
    iconName: "inbox"
  },
  {
    uuid: "a68aa434-4a3f-4490-a2bf-0a0a2766fdf4",
    iconSet: "MaterialCommunityIcons",
    iconName: "inbox-arrow-down"
  },
  {
    uuid: "9a4c4bf3-bdf0-4ed9-9059-97d88112a258",
    iconSet: "MaterialCommunityIcons",
    iconName: "inbox-arrow-down-outline"
  },
  {
    uuid: "29bb6b61-f5b3-4e42-8369-bb1389c69188",
    iconSet: "MaterialCommunityIcons",
    iconName: "inbox-arrow-up"
  },
  {
    uuid: "076a9231-b8f7-4080-bf8d-9d66a48a0718",
    iconSet: "MaterialCommunityIcons",
    iconName: "inbox-arrow-up-outline"
  },
  {
    uuid: "73af8f01-a843-48f0-961e-ec73fbbe43fa",
    iconSet: "MaterialCommunityIcons",
    iconName: "inbox-full"
  },
  {
    uuid: "50cbd9b2-4f5a-4956-9e4c-6c2f7b2a6b22",
    iconSet: "MaterialCommunityIcons",
    iconName: "inbox-full-outline"
  },
  {
    uuid: "83388a11-0abc-424f-a478-9f1202dc7b68",
    iconSet: "MaterialCommunityIcons",
    iconName: "inbox-multiple"
  },
  {
    uuid: "79204d01-0cd8-43b0-83f9-5a3eb8d3411b",
    iconSet: "MaterialCommunityIcons",
    iconName: "inbox-multiple-outline"
  },
  {
    uuid: "dc43ebb5-d374-439b-9343-a066ff58cf8b",
    iconSet: "MaterialCommunityIcons",
    iconName: "inbox-outline"
  },
  {
    uuid: "0966c21a-b2e8-4210-9f5b-f1d59fccfa9a",
    iconSet: "MaterialCommunityIcons",
    iconName: "incognito"
  },
  {
    uuid: "6ed3a029-2cb8-4cf6-8177-36b06883fa5a",
    iconSet: "MaterialCommunityIcons",
    iconName: "incognito-circle"
  },
  {
    uuid: "ff248a24-7e24-4426-9ac6-3d24a394ad3a",
    iconSet: "MaterialCommunityIcons",
    iconName: "incognito-circle-off"
  },
  {
    uuid: "dd15a2fb-c4a7-49da-99e3-fca49092ed15",
    iconSet: "MaterialCommunityIcons",
    iconName: "incognito-off"
  },
  {
    uuid: "dc8ec64a-2796-45c9-995e-85604d68134e",
    iconSet: "MaterialCommunityIcons",
    iconName: "infinity"
  },
  {
    uuid: "51cae140-28ff-47f8-b340-04c197e4f536",
    iconSet: "MaterialCommunityIcons",
    iconName: "information"
  },
  {
    uuid: "0392eb62-f787-4e15-ae89-c394982eea5d",
    iconSet: "MaterialCommunityIcons",
    iconName: "information-outline"
  },
  {
    uuid: "c2ceb19d-53be-4518-9508-da81846fd548",
    iconSet: "MaterialCommunityIcons",
    iconName: "information-variant"
  },
  {
    uuid: "5c97f9c7-78ab-4b59-8772-05963c96db89",
    iconSet: "MaterialCommunityIcons",
    iconName: "instagram"
  },
  {
    uuid: "0bc54171-3e0b-439d-a72d-fbf8fe9e42ec",
    iconSet: "MaterialCommunityIcons",
    iconName: "instrument-triangle"
  },
  {
    uuid: "c393ab51-9736-46e0-ad04-f3f2d004af3d",
    iconSet: "MaterialCommunityIcons",
    iconName: "invert-colors"
  },
  {
    uuid: "b1e584b2-7bac-4031-b501-542b0fdbefdf",
    iconSet: "MaterialCommunityIcons",
    iconName: "invert-colors-off"
  },
  {
    uuid: "bf695aa4-78c8-4f4d-9291-997d4add7951",
    iconSet: "MaterialCommunityIcons",
    iconName: "iobroker"
  },
  {
    uuid: "d95df26e-b4bb-4afa-9492-bdac0ebd55a3",
    iconSet: "MaterialCommunityIcons",
    iconName: "ip"
  },
  {
    uuid: "8b4694bc-90bc-4bca-9c1f-44f84c4d0b51",
    iconSet: "MaterialCommunityIcons",
    iconName: "ip-network"
  },
  {
    uuid: "7dd80fcc-e6bf-4b1c-a299-f948e6316ba3",
    iconSet: "MaterialCommunityIcons",
    iconName: "ip-network-outline"
  },
  {
    uuid: "a67ddbdf-b7e5-4544-ac50-f19cf82a13cb",
    iconSet: "MaterialCommunityIcons",
    iconName: "ipod"
  },
  {
    uuid: "7c565285-9f88-4ef7-8dfc-dda0a2c6096b",
    iconSet: "MaterialCommunityIcons",
    iconName: "islam"
  },
  {
    uuid: "67a1c228-a8f6-47c9-81e3-488d8a342d4f",
    iconSet: "MaterialCommunityIcons",
    iconName: "island"
  },
  {
    uuid: "3878c0bf-4cb7-4404-8a75-c32bb689dc88",
    iconSet: "MaterialCommunityIcons",
    iconName: "iv-bag"
  },
  {
    uuid: "5e5626c0-9b5b-4842-b176-45018396fd38",
    iconSet: "MaterialCommunityIcons",
    iconName: "jabber"
  },
  {
    uuid: "d544dbea-d1f9-416a-be1b-a2067c4d3faa",
    iconSet: "MaterialCommunityIcons",
    iconName: "jeepney"
  },
  {
    uuid: "fb9bd336-ab4a-487e-8691-25d0b2d1ca75",
    iconSet: "MaterialCommunityIcons",
    iconName: "jellyfish"
  },
  {
    uuid: "3a1bfd1f-b43d-4452-985c-0192684b54a2",
    iconSet: "MaterialCommunityIcons",
    iconName: "jellyfish-outline"
  },
  {
    uuid: "d026ceab-e3e0-415c-aeaf-9205d0d4a597",
    iconSet: "MaterialCommunityIcons",
    iconName: "jira"
  },
  {
    uuid: "80a0076c-4c01-423c-a791-6d67e9669e3e",
    iconSet: "MaterialCommunityIcons",
    iconName: "jquery"
  },
  {
    uuid: "e6fefacc-3c2b-43b2-9f40-5bf2343e9c86",
    iconSet: "MaterialCommunityIcons",
    iconName: "jsfiddle"
  },
  {
    uuid: "803ad757-f1d9-4f66-8b77-744b4d6dd17b",
    iconSet: "MaterialCommunityIcons",
    iconName: "judaism"
  },
  {
    uuid: "a4563bd0-cbaa-40bf-b87e-c1119469df29",
    iconSet: "MaterialCommunityIcons",
    iconName: "jump-rope"
  },
  {
    uuid: "bc331c0e-e7b7-4a86-8ee6-cf7dc98b4a24",
    iconSet: "MaterialCommunityIcons",
    iconName: "kabaddi"
  },
  {
    uuid: "b2e73e15-7f46-434b-9350-e769e7e7887c",
    iconSet: "MaterialCommunityIcons",
    iconName: "karate"
  },
  {
    uuid: "a841e1b5-faff-44b5-9c19-d29c55d73b93",
    iconSet: "MaterialCommunityIcons",
    iconName: "keg"
  },
  {
    uuid: "369e9404-1496-451f-a259-2ce8f90d579f",
    iconSet: "MaterialCommunityIcons",
    iconName: "kettle"
  },
  {
    uuid: "c8f67c3a-e98f-4bf4-a7e9-85b5a8a56edd",
    iconSet: "MaterialCommunityIcons",
    iconName: "kettle-alert"
  },
  {
    uuid: "89f7d7cd-0e17-4be9-81d2-9f0dcc2cb77d",
    iconSet: "MaterialCommunityIcons",
    iconName: "kettle-alert-outline"
  },
  {
    uuid: "2bd10705-2e17-4176-b689-fcc8a8df7342",
    iconSet: "MaterialCommunityIcons",
    iconName: "kettle-off"
  },
  {
    uuid: "871830f2-e370-4e3e-888c-80734adb17bf",
    iconSet: "MaterialCommunityIcons",
    iconName: "kettle-off-outline"
  },
  {
    uuid: "e9b7bdc4-1dd3-4ef9-bea4-0468266109c0",
    iconSet: "MaterialCommunityIcons",
    iconName: "kettle-outline"
  },
  {
    uuid: "e083c47c-3963-4aa7-a428-10e29db469d0",
    iconSet: "MaterialCommunityIcons",
    iconName: "kettle-steam"
  },
  {
    uuid: "e6a9009d-21b3-429f-bf47-3a983c5ae30c",
    iconSet: "MaterialCommunityIcons",
    iconName: "kettle-steam-outline"
  },
  {
    uuid: "bc363618-10ff-437b-ad16-fa2b8961ecfd",
    iconSet: "MaterialCommunityIcons",
    iconName: "kettlebell"
  },
  {
    uuid: "9243972b-334a-4660-bf2e-02b4aa53c5e6",
    iconSet: "MaterialCommunityIcons",
    iconName: "key"
  },
  {
    uuid: "65c67647-e154-4830-8a03-6f18fbec3e10",
    iconSet: "MaterialCommunityIcons",
    iconName: "key-arrow-right"
  },
  {
    uuid: "4353fe1c-2f29-4378-8d24-b37650c2a2c7",
    iconSet: "MaterialCommunityIcons",
    iconName: "key-change"
  },
  {
    uuid: "dbe8a5a5-c829-4f04-889c-a6128b888f3c",
    iconSet: "MaterialCommunityIcons",
    iconName: "key-link"
  },
  {
    uuid: "16ee36dd-af47-4fab-9ad3-f8d5a12d0e5c",
    iconSet: "MaterialCommunityIcons",
    iconName: "key-minus"
  },
  {
    uuid: "f114e553-81ed-46e9-9698-f7642fc200e5",
    iconSet: "MaterialCommunityIcons",
    iconName: "key-outline"
  },
  {
    uuid: "f3015f79-aad6-4af0-af95-7d171b177ef4",
    iconSet: "MaterialCommunityIcons",
    iconName: "key-plus"
  },
  {
    uuid: "884fd295-92fc-42e5-8787-00758b653783",
    iconSet: "MaterialCommunityIcons",
    iconName: "key-remove"
  },
  {
    uuid: "b8df86dc-9615-4942-85ee-8c81412f1fc4",
    iconSet: "MaterialCommunityIcons",
    iconName: "key-star"
  },
  {
    uuid: "a95fa698-d5f3-4310-b5ef-49a3419a614c",
    iconSet: "MaterialCommunityIcons",
    iconName: "key-variant"
  },
  {
    uuid: "fd3fb0c5-826d-4e6e-ab8d-c30a0c4abc3a",
    iconSet: "MaterialCommunityIcons",
    iconName: "key-wireless"
  },
  {
    uuid: "4b05fd01-4649-4d88-bb84-f9ebd5dd5c49",
    iconSet: "MaterialCommunityIcons",
    iconName: "keyboard"
  },
  {
    uuid: "66a82309-65be-4ef6-8ebc-2766605120bb",
    iconSet: "MaterialCommunityIcons",
    iconName: "keyboard-backspace"
  },
  {
    uuid: "d734077f-7467-4992-9c9a-c1e3aa5e1df7",
    iconSet: "MaterialCommunityIcons",
    iconName: "keyboard-caps"
  },
  {
    uuid: "f3cda6ef-4366-4a5e-b545-c2a3ca1b04fb",
    iconSet: "MaterialCommunityIcons",
    iconName: "keyboard-close"
  },
  {
    uuid: "294dc23a-af45-4ca2-b2ad-fd40e38a1b4b",
    iconSet: "MaterialCommunityIcons",
    iconName: "keyboard-esc"
  },
  {
    uuid: "93b38394-7132-49cf-b6cc-4d88df9f3785",
    iconSet: "MaterialCommunityIcons",
    iconName: "keyboard-f1"
  },
  {
    uuid: "13621459-e181-4c9f-a7d5-f44fef30f437",
    iconSet: "MaterialCommunityIcons",
    iconName: "keyboard-f10"
  },
  {
    uuid: "2fbbeb88-7315-4da2-b858-1cee78c9209e",
    iconSet: "MaterialCommunityIcons",
    iconName: "keyboard-f11"
  },
  {
    uuid: "31d14800-6703-45cc-bef8-f10317b22823",
    iconSet: "MaterialCommunityIcons",
    iconName: "keyboard-f12"
  },
  {
    uuid: "36ce892e-f21b-4ed0-aa92-26d0646c7575",
    iconSet: "MaterialCommunityIcons",
    iconName: "keyboard-f2"
  },
  {
    uuid: "c261eca7-03e1-4efb-8c94-5cd8fafe6677",
    iconSet: "MaterialCommunityIcons",
    iconName: "keyboard-f3"
  },
  {
    uuid: "a2fd8405-cffa-47a0-a8c2-42e2c01f0bfb",
    iconSet: "MaterialCommunityIcons",
    iconName: "keyboard-f4"
  },
  {
    uuid: "8bc1eb3e-c0a1-4737-9e49-43aaee80fea9",
    iconSet: "MaterialCommunityIcons",
    iconName: "keyboard-f5"
  },
  {
    uuid: "c0a9b6ae-2f43-4286-b978-93138819f486",
    iconSet: "MaterialCommunityIcons",
    iconName: "keyboard-f6"
  },
  {
    uuid: "d2691947-19b6-41ce-a648-66efa51b6df0",
    iconSet: "MaterialCommunityIcons",
    iconName: "keyboard-f7"
  },
  {
    uuid: "faad7b21-9079-47a2-8caf-71f8d2dde02d",
    iconSet: "MaterialCommunityIcons",
    iconName: "keyboard-f8"
  },
  {
    uuid: "3a3e2159-1042-49c5-9b21-42fae8cb5fb6",
    iconSet: "MaterialCommunityIcons",
    iconName: "keyboard-f9"
  },
  {
    uuid: "094d6635-729e-46fe-80f0-370c5dcad07e",
    iconSet: "MaterialCommunityIcons",
    iconName: "keyboard-off"
  },
  {
    uuid: "0ea00dbc-4df6-4817-9b05-b6d8f8f79214",
    iconSet: "MaterialCommunityIcons",
    iconName: "keyboard-off-outline"
  },
  {
    uuid: "3863f691-6622-475c-8d11-7c1ce4ae792b",
    iconSet: "MaterialCommunityIcons",
    iconName: "keyboard-outline"
  },
  {
    uuid: "69a5c603-04ed-4fcb-821e-cdddc51662d2",
    iconSet: "MaterialCommunityIcons",
    iconName: "keyboard-return"
  },
  {
    uuid: "82415204-8e7c-4d91-a137-1fa3b46a04a5",
    iconSet: "MaterialCommunityIcons",
    iconName: "keyboard-settings"
  },
  {
    uuid: "4fe89bf3-6bdf-46a1-9009-87c1dca091f5",
    iconSet: "MaterialCommunityIcons",
    iconName: "keyboard-settings-outline"
  },
  {
    uuid: "c53669e3-55f0-45c5-94f9-3513bedc1ca1",
    iconSet: "MaterialCommunityIcons",
    iconName: "keyboard-space"
  },
  {
    uuid: "921f3351-4e1d-4129-bd35-6829aa31b0b9",
    iconSet: "MaterialCommunityIcons",
    iconName: "keyboard-tab"
  },
  {
    uuid: "eb20446f-3f29-44ba-be6d-c9658923a6e6",
    iconSet: "MaterialCommunityIcons",
    iconName: "keyboard-variant"
  },
  {
    uuid: "29229627-ac9f-450c-9731-b656f82b184e",
    iconSet: "MaterialCommunityIcons",
    iconName: "khanda"
  },
  {
    uuid: "1ab246e3-1b61-4db7-9767-d034ec867877",
    iconSet: "MaterialCommunityIcons",
    iconName: "kickstarter"
  },
  {
    uuid: "a1877b16-1f0e-4483-9180-8fd5df3dabb9",
    iconSet: "MaterialCommunityIcons",
    iconName: "klingon"
  },
  {
    uuid: "cc2a2b95-089c-4649-a546-c24b4a15a185",
    iconSet: "MaterialCommunityIcons",
    iconName: "knife"
  },
  {
    uuid: "28c4fbf6-37f3-4143-934b-43fc3d95661d",
    iconSet: "MaterialCommunityIcons",
    iconName: "knife-military"
  },
  {
    uuid: "277b40f0-23f2-43b0-9f99-cd0bec686dc7",
    iconSet: "MaterialCommunityIcons",
    iconName: "kodi"
  },
  {
    uuid: "94d2c6ea-d928-4a57-981d-3c595bebd106",
    iconSet: "MaterialCommunityIcons",
    iconName: "kubernetes"
  },
  {
    uuid: "5bd61e14-6bc4-4ab5-a96f-a940ad513912",
    iconSet: "MaterialCommunityIcons",
    iconName: "label"
  },
  {
    uuid: "bbf1fd58-90de-4153-b431-5fa9e4a2a768",
    iconSet: "MaterialCommunityIcons",
    iconName: "label-multiple"
  },
  {
    uuid: "42019dfb-b85f-4455-a4f1-237bc65e7342",
    iconSet: "MaterialCommunityIcons",
    iconName: "label-multiple-outline"
  },
  {
    uuid: "b8619e6f-1598-4217-9e4c-70d47c89acc4",
    iconSet: "MaterialCommunityIcons",
    iconName: "label-off"
  },
  {
    uuid: "a1d2c4d5-4178-41cb-ae1b-e9c00c5c24cb",
    iconSet: "MaterialCommunityIcons",
    iconName: "label-off-outline"
  },
  {
    uuid: "369cd5c1-cc93-42ae-9f9c-051e851b8365",
    iconSet: "MaterialCommunityIcons",
    iconName: "label-outline"
  },
  {
    uuid: "4ffcc9ae-d21e-43a9-adcc-32cdaf8a15cd",
    iconSet: "MaterialCommunityIcons",
    iconName: "label-percent"
  },
  {
    uuid: "f85c8763-3f4f-4504-a6d0-a4fe4ffa3a88",
    iconSet: "MaterialCommunityIcons",
    iconName: "label-percent-outline"
  },
  {
    uuid: "86d59b1d-5a97-4e79-a431-218fb52e169a",
    iconSet: "MaterialCommunityIcons",
    iconName: "label-variant"
  },
  {
    uuid: "b76094c8-0e5a-4535-9311-563eea3635c9",
    iconSet: "MaterialCommunityIcons",
    iconName: "label-variant-outline"
  },
  {
    uuid: "75920805-925e-445d-83a9-cde7cd001853",
    iconSet: "MaterialCommunityIcons",
    iconName: "ladybug"
  },
  {
    uuid: "10652a83-9ef4-41e6-89a2-1d77e4c3c89d",
    iconSet: "MaterialCommunityIcons",
    iconName: "lambda"
  },
  {
    uuid: "d20f81d9-5af7-46ef-a568-def300a737d0",
    iconSet: "MaterialCommunityIcons",
    iconName: "lamp"
  },
  {
    uuid: "635e915b-87df-4e90-ae46-821c97ef8f7d",
    iconSet: "MaterialCommunityIcons",
    iconName: "lan"
  },
  {
    uuid: "62b7efa2-8095-493b-893b-47507aaebe3e",
    iconSet: "MaterialCommunityIcons",
    iconName: "lan-check"
  },
  {
    uuid: "419561db-2522-4082-9006-93f099ec68be",
    iconSet: "MaterialCommunityIcons",
    iconName: "lan-connect"
  },
  {
    uuid: "dcf3a357-4934-4fbd-8104-c276d8d67648",
    iconSet: "MaterialCommunityIcons",
    iconName: "lan-disconnect"
  },
  {
    uuid: "5a5b80fc-cefa-4997-9057-ba55e1a9f536",
    iconSet: "MaterialCommunityIcons",
    iconName: "lan-pending"
  },
  {
    uuid: "a9951f31-a77f-467a-94fc-ef39b10c7f3b",
    iconSet: "MaterialCommunityIcons",
    iconName: "language-c"
  },
  {
    uuid: "6d98d2bc-9a50-4994-bf92-82f1ff73caf2",
    iconSet: "MaterialCommunityIcons",
    iconName: "language-cpp"
  },
  {
    uuid: "0912a4ef-5132-4e1d-8270-b5ebd0717d1e",
    iconSet: "MaterialCommunityIcons",
    iconName: "language-csharp"
  },
  {
    uuid: "d90d5d76-8460-4ddb-8776-58257dc2211c",
    iconSet: "MaterialCommunityIcons",
    iconName: "language-css3"
  },
  {
    uuid: "01e68d06-199c-4b0f-873e-2f3a3ab50516",
    iconSet: "MaterialCommunityIcons",
    iconName: "language-fortran"
  },
  {
    uuid: "f367538d-a9a0-4e1f-9789-510a396bf44e",
    iconSet: "MaterialCommunityIcons",
    iconName: "language-go"
  },
  {
    uuid: "9038bdd9-85d9-4f6f-991a-f55bec26be77",
    iconSet: "MaterialCommunityIcons",
    iconName: "language-haskell"
  },
  {
    uuid: "61c7c03f-22b7-4a68-8d74-10b9caa50178",
    iconSet: "MaterialCommunityIcons",
    iconName: "language-html5"
  },
  {
    uuid: "dc0279fa-bfed-4b05-8712-3fe694ac7436",
    iconSet: "MaterialCommunityIcons",
    iconName: "language-java"
  },
  {
    uuid: "7f075e13-7ca5-45f3-808b-3cce636d631f",
    iconSet: "MaterialCommunityIcons",
    iconName: "language-javascript"
  },
  {
    uuid: "9f4dca65-83d0-47ae-914e-d9c9634929c2",
    iconSet: "MaterialCommunityIcons",
    iconName: "language-kotlin"
  },
  {
    uuid: "de36fbe6-d66a-449b-9154-5f2bab852987",
    iconSet: "MaterialCommunityIcons",
    iconName: "language-lua"
  },
  {
    uuid: "23dbc7da-0bb4-4512-b743-f9f79c3b34e3",
    iconSet: "MaterialCommunityIcons",
    iconName: "language-markdown"
  },
  {
    uuid: "f76379f9-a55b-40b9-ac05-2fc17db1a2ae",
    iconSet: "MaterialCommunityIcons",
    iconName: "language-markdown-outline"
  },
  {
    uuid: "e604e985-ba88-464f-875e-6d2b6acb87dc",
    iconSet: "MaterialCommunityIcons",
    iconName: "language-php"
  },
  {
    uuid: "b91f1135-da92-4a26-a811-a243111deda7",
    iconSet: "MaterialCommunityIcons",
    iconName: "language-python"
  },
  {
    uuid: "79c50c09-b5fc-4136-a0eb-a7e6dbe4185f",
    iconSet: "MaterialCommunityIcons",
    iconName: "language-r"
  },
  {
    uuid: "cd20cd9c-8aed-4599-93fb-523cecfaef39",
    iconSet: "MaterialCommunityIcons",
    iconName: "language-ruby"
  },
  {
    uuid: "71dc0e4d-0503-4dd6-bef5-29f6eae62ba1",
    iconSet: "MaterialCommunityIcons",
    iconName: "language-ruby-on-rails"
  },
  {
    uuid: "365a0944-4410-4966-a5e6-1b2bfbd8bcdc",
    iconSet: "MaterialCommunityIcons",
    iconName: "language-swift"
  },
  {
    uuid: "0c429790-ed90-4d38-8990-207a13470dd0",
    iconSet: "MaterialCommunityIcons",
    iconName: "language-typescript"
  },
  {
    uuid: "eeeeb266-6bfd-4160-80cf-ce3008a20871",
    iconSet: "MaterialCommunityIcons",
    iconName: "language-xaml"
  },
  {
    uuid: "75c3cb91-2aa4-47b6-b7d2-90732142eae5",
    iconSet: "MaterialCommunityIcons",
    iconName: "laptop"
  },
  {
    uuid: "e9784324-9bab-4dc4-8e29-23f7b1aae1c2",
    iconSet: "MaterialCommunityIcons",
    iconName: "laptop-chromebook"
  },
  {
    uuid: "cbd160de-7d79-4a63-a64c-b251f9896370",
    iconSet: "MaterialCommunityIcons",
    iconName: "laptop-mac"
  },
  {
    uuid: "d93409a1-d0c5-4db9-80cc-205857ae2e32",
    iconSet: "MaterialCommunityIcons",
    iconName: "laptop-off"
  },
  {
    uuid: "c3cdc07d-7d4d-47e2-80b4-97fe4e1c1354",
    iconSet: "MaterialCommunityIcons",
    iconName: "laptop-windows"
  },
  {
    uuid: "b93a1006-f1a6-4dbe-a321-113f24479ad1",
    iconSet: "MaterialCommunityIcons",
    iconName: "laravel"
  },
  {
    uuid: "a76017a8-7d05-48ae-a058-2da5891c7ba0",
    iconSet: "MaterialCommunityIcons",
    iconName: "laser-pointer"
  },
  {
    uuid: "b1d09c0e-c99c-4ca2-996c-f8cf49ccae12",
    iconSet: "MaterialCommunityIcons",
    iconName: "lasso"
  },
  {
    uuid: "9067f5a8-4eb9-4140-bdef-0354f8bdc9b5",
    iconSet: "MaterialCommunityIcons",
    iconName: "lastpass"
  },
  {
    uuid: "2fccc7ec-e38a-4b2d-8d4d-42aa45593b08",
    iconSet: "MaterialCommunityIcons",
    iconName: "latitude"
  },
  {
    uuid: "e63ced1b-5d0c-4984-9af7-5ec1ad21f6b9",
    iconSet: "MaterialCommunityIcons",
    iconName: "launch"
  },
  {
    uuid: "57bdff8e-4b2f-45fb-a245-e279c4c679f8",
    iconSet: "MaterialCommunityIcons",
    iconName: "lava-lamp"
  },
  {
    uuid: "d5f1f7dc-6780-45de-a869-d03d88d4f1b1",
    iconSet: "MaterialCommunityIcons",
    iconName: "layers"
  },
  {
    uuid: "e1c9f710-a26a-4cc1-86a9-ae88e86b342a",
    iconSet: "MaterialCommunityIcons",
    iconName: "layers-minus"
  },
  {
    uuid: "36a272d3-deb2-4006-89df-b1c240fdc884",
    iconSet: "MaterialCommunityIcons",
    iconName: "layers-off"
  },
  {
    uuid: "e9d18905-995d-4b27-bc23-a59f4725cb23",
    iconSet: "MaterialCommunityIcons",
    iconName: "layers-off-outline"
  },
  {
    uuid: "cf83777a-fd8a-45b4-a2f4-17a6e3e32d1a",
    iconSet: "MaterialCommunityIcons",
    iconName: "layers-outline"
  },
  {
    uuid: "3d81b3ac-8fd4-4c6f-b9c0-82b0d9cedbd8",
    iconSet: "MaterialCommunityIcons",
    iconName: "layers-plus"
  },
  {
    uuid: "043762b0-371c-447e-a62f-8902f722f48b",
    iconSet: "MaterialCommunityIcons",
    iconName: "layers-remove"
  },
  {
    uuid: "d32a9eac-ffda-4586-83f4-589860432003",
    iconSet: "MaterialCommunityIcons",
    iconName: "layers-search"
  },
  {
    uuid: "4dd5c9ef-e21c-4880-bcd9-cfbae4b2869b",
    iconSet: "MaterialCommunityIcons",
    iconName: "layers-search-outline"
  },
  {
    uuid: "490ac5f6-44ae-425d-ae48-bb185a40853f",
    iconSet: "MaterialCommunityIcons",
    iconName: "layers-triple"
  },
  {
    uuid: "445ae2b5-bcc5-4629-a25d-7540f760acf1",
    iconSet: "MaterialCommunityIcons",
    iconName: "layers-triple-outline"
  },
  {
    uuid: "51e2d1ae-c46f-48b4-a7a0-2cfe27519a7a",
    iconSet: "MaterialCommunityIcons",
    iconName: "lead-pencil"
  },
  {
    uuid: "5c852ea7-a639-44a6-9f99-1ea2487a477e",
    iconSet: "MaterialCommunityIcons",
    iconName: "leaf"
  },
  {
    uuid: "0fec3cb1-5545-4784-a724-662d1ae2c0f8",
    iconSet: "MaterialCommunityIcons",
    iconName: "leaf-maple"
  },
  {
    uuid: "499f7e22-0b18-4db0-bc0c-cad14454bcad",
    iconSet: "MaterialCommunityIcons",
    iconName: "leaf-maple-off"
  },
  {
    uuid: "52f92efc-cbe0-470f-ac39-5758bc2c2260",
    iconSet: "MaterialCommunityIcons",
    iconName: "leaf-off"
  },
  {
    uuid: "3013f3cc-26e2-4172-b50f-85d39649acd4",
    iconSet: "MaterialCommunityIcons",
    iconName: "leak"
  },
  {
    uuid: "1dd873ad-9467-4a33-a97e-9aec6f6ff311",
    iconSet: "MaterialCommunityIcons",
    iconName: "leak-off"
  },
  {
    uuid: "681e0767-e0ec-4b27-bc67-3e11f082e1de",
    iconSet: "MaterialCommunityIcons",
    iconName: "led-off"
  },
  {
    uuid: "2ad1742e-1573-45f4-98ec-16a43b79e03e",
    iconSet: "MaterialCommunityIcons",
    iconName: "led-on"
  },
  {
    uuid: "4910d13f-8af6-4cd9-ba7b-bcf8be91ee97",
    iconSet: "MaterialCommunityIcons",
    iconName: "led-outline"
  },
  {
    uuid: "5af12308-f5ab-42de-b50e-1839c01c64d0",
    iconSet: "MaterialCommunityIcons",
    iconName: "led-strip"
  },
  {
    uuid: "2d1ede83-93ac-47fa-b1d8-891b692db4c7",
    iconSet: "MaterialCommunityIcons",
    iconName: "led-strip-variant"
  },
  {
    uuid: "498471d4-0889-468b-893d-6a4c5b1b48c7",
    iconSet: "MaterialCommunityIcons",
    iconName: "led-variant-off"
  },
  {
    uuid: "0435924a-00d7-4aac-9014-1e320566adef",
    iconSet: "MaterialCommunityIcons",
    iconName: "led-variant-on"
  },
  {
    uuid: "ce494c44-17fb-47b0-8b89-2965df6c1701",
    iconSet: "MaterialCommunityIcons",
    iconName: "led-variant-outline"
  },
  {
    uuid: "90ebf9d9-c9ec-449f-91de-7a6eb9b573bc",
    iconSet: "MaterialCommunityIcons",
    iconName: "leek"
  },
  {
    uuid: "47794411-f263-4757-ad5f-51c8e7f1b810",
    iconSet: "MaterialCommunityIcons",
    iconName: "less-than"
  },
  {
    uuid: "fc16ac78-6454-4f0f-9c87-ffad43c8becb",
    iconSet: "MaterialCommunityIcons",
    iconName: "less-than-or-equal"
  },
  {
    uuid: "4f1dc9d0-1fe6-471c-9f4c-ccd1dff9d09a",
    iconSet: "MaterialCommunityIcons",
    iconName: "library"
  },
  {
    uuid: "9c774039-85d3-4c55-8c59-a033bce263d3",
    iconSet: "MaterialCommunityIcons",
    iconName: "library-shelves"
  },
  {
    uuid: "ac2bd571-f39f-42f1-af18-ef72d00a8ce8",
    iconSet: "MaterialCommunityIcons",
    iconName: "license"
  },
  {
    uuid: "6a7da7f8-98c2-4f56-9d24-8769f4eb28eb",
    iconSet: "MaterialCommunityIcons",
    iconName: "lifebuoy"
  },
  {
    uuid: "a4e7c8af-672b-4c4a-bb6e-5207984de647",
    iconSet: "MaterialCommunityIcons",
    iconName: "light-switch"
  },
  {
    uuid: "8ad6325d-e879-44c4-b19c-73a7750ab600",
    iconSet: "MaterialCommunityIcons",
    iconName: "lightbulb"
  },
  {
    uuid: "cc3b397d-5126-4088-87d0-01dbf17a439e",
    iconSet: "MaterialCommunityIcons",
    iconName: "lightbulb-cfl"
  },
  {
    uuid: "ddefac21-5aa6-4517-ae38-b0d15179dc48",
    iconSet: "MaterialCommunityIcons",
    iconName: "lightbulb-cfl-off"
  },
  {
    uuid: "9120b6da-405c-4024-b733-dc7009b90aef",
    iconSet: "MaterialCommunityIcons",
    iconName: "lightbulb-cfl-spiral"
  },
  {
    uuid: "d834b1b6-81ec-46e6-88d1-19f4e460cbed",
    iconSet: "MaterialCommunityIcons",
    iconName: "lightbulb-cfl-spiral-off"
  },
  {
    uuid: "3f391464-1167-43fd-a080-0ae5e0ff4354",
    iconSet: "MaterialCommunityIcons",
    iconName: "lightbulb-group"
  },
  {
    uuid: "5f63f8dd-7897-4b85-9be6-8ce56da4be2a",
    iconSet: "MaterialCommunityIcons",
    iconName: "lightbulb-group-off"
  },
  {
    uuid: "98bf7fd7-1283-4c6e-ab29-d97dfd53c009",
    iconSet: "MaterialCommunityIcons",
    iconName: "lightbulb-group-off-outline"
  },
  {
    uuid: "04a3b776-2deb-4381-9197-54c44b333733",
    iconSet: "MaterialCommunityIcons",
    iconName: "lightbulb-group-outline"
  },
  {
    uuid: "eff9a879-b95e-46c2-8f75-681b60e99fdc",
    iconSet: "MaterialCommunityIcons",
    iconName: "lightbulb-multiple"
  },
  {
    uuid: "3f0c3467-4cc7-492c-8864-79ce2e3846dc",
    iconSet: "MaterialCommunityIcons",
    iconName: "lightbulb-multiple-off"
  },
  {
    uuid: "e4b42d42-1074-43f9-bd09-983a72a804f5",
    iconSet: "MaterialCommunityIcons",
    iconName: "lightbulb-multiple-off-outline"
  },
  {
    uuid: "ff07bb1f-c730-4754-a29e-3dcd4f6d986c",
    iconSet: "MaterialCommunityIcons",
    iconName: "lightbulb-multiple-outline"
  },
  {
    uuid: "00566153-1bf9-4278-b3b0-be3ca3e2d88e",
    iconSet: "MaterialCommunityIcons",
    iconName: "lightbulb-off"
  },
  {
    uuid: "f582d4cd-f31a-407c-8bf3-ab1114997075",
    iconSet: "MaterialCommunityIcons",
    iconName: "lightbulb-off-outline"
  },
  {
    uuid: "1a8e83ac-8ef7-4646-abb6-bc8e3dc59aae",
    iconSet: "MaterialCommunityIcons",
    iconName: "lightbulb-on"
  },
  {
    uuid: "3a1c6da5-3e54-4e2f-9ff4-35d8001f12e9",
    iconSet: "MaterialCommunityIcons",
    iconName: "lightbulb-on-outline"
  },
  {
    uuid: "533d4de9-a2b5-47f0-bc08-8a90f7dd1085",
    iconSet: "MaterialCommunityIcons",
    iconName: "lightbulb-outline"
  },
  {
    uuid: "99027db2-227b-4f0c-a441-2f656ac668a8",
    iconSet: "MaterialCommunityIcons",
    iconName: "lighthouse"
  },
  {
    uuid: "fd88678f-2f2e-4d8a-b213-a593c2a05770",
    iconSet: "MaterialCommunityIcons",
    iconName: "lighthouse-on"
  },
  {
    uuid: "6a1e3271-0e12-4204-8b83-3bf4e745083f",
    iconSet: "MaterialCommunityIcons",
    iconName: "lightning-bolt"
  },
  {
    uuid: "651eef53-9c11-49dc-b171-a6f4afab1305",
    iconSet: "MaterialCommunityIcons",
    iconName: "lightning-bolt-outline"
  },
  {
    uuid: "8bbc253f-a2bb-4cab-9373-f2c1e79f218a",
    iconSet: "MaterialCommunityIcons",
    iconName: "lingerie"
  },
  {
    uuid: "06d39ea4-2f11-44fe-a17d-4dcc37b9dc42",
    iconSet: "MaterialCommunityIcons",
    iconName: "link"
  },
  {
    uuid: "698ae67b-002c-492d-943a-f030e1ae5fff",
    iconSet: "MaterialCommunityIcons",
    iconName: "link-box"
  },
  {
    uuid: "c7f0876a-f23f-49ee-a005-6e7793b6b4fe",
    iconSet: "MaterialCommunityIcons",
    iconName: "link-box-outline"
  },
  {
    uuid: "b31b70f7-8cc2-41a1-bd77-75b9c0651f6d",
    iconSet: "MaterialCommunityIcons",
    iconName: "link-box-variant"
  },
  {
    uuid: "54b6aaf9-09f8-4323-8030-f9656b70384f",
    iconSet: "MaterialCommunityIcons",
    iconName: "link-box-variant-outline"
  },
  {
    uuid: "b91d79db-2a4e-42fe-9186-ae9ed207f0c7",
    iconSet: "MaterialCommunityIcons",
    iconName: "link-lock"
  },
  {
    uuid: "2b2df2c6-7c0a-4aba-99c5-0daba3e6ab4c",
    iconSet: "MaterialCommunityIcons",
    iconName: "link-off"
  },
  {
    uuid: "06f1778d-4b53-491f-8e6a-c00f9ac99bf0",
    iconSet: "MaterialCommunityIcons",
    iconName: "link-plus"
  },
  {
    uuid: "b162910e-068a-407b-ab35-52cab92d99e9",
    iconSet: "MaterialCommunityIcons",
    iconName: "link-variant"
  },
  {
    uuid: "2ce02b80-0864-454a-a2f7-e06eae9fee9d",
    iconSet: "MaterialCommunityIcons",
    iconName: "link-variant-minus"
  },
  {
    uuid: "059742dc-6716-4508-8611-8eecf961c09b",
    iconSet: "MaterialCommunityIcons",
    iconName: "link-variant-off"
  },
  {
    uuid: "a415e4d9-6d96-47cd-ab96-843c63493363",
    iconSet: "MaterialCommunityIcons",
    iconName: "link-variant-plus"
  },
  {
    uuid: "dfcbaf64-2530-420b-9cfe-b948d90bf490",
    iconSet: "MaterialCommunityIcons",
    iconName: "link-variant-remove"
  },
  {
    uuid: "f6a19956-0d4f-4b9c-a50a-5342f1ad8171",
    iconSet: "MaterialCommunityIcons",
    iconName: "linkedin"
  },
  {
    uuid: "09ad9d56-f7e9-4aab-8919-4eaffac85cff",
    iconSet: "MaterialCommunityIcons",
    iconName: "linux"
  },
  {
    uuid: "417a9766-3f60-4635-9ae4-4cb71fc773a8",
    iconSet: "MaterialCommunityIcons",
    iconName: "linux-mint"
  },
  {
    uuid: "b4359077-71fe-49dd-9189-ad936ce13456",
    iconSet: "MaterialCommunityIcons",
    iconName: "lipstick"
  },
  {
    uuid: "2f85a779-f1a1-4cf6-bc40-c5da90442fa5",
    iconSet: "MaterialCommunityIcons",
    iconName: "litecoin"
  },
  {
    uuid: "28312fa5-d7af-4a3f-b316-e4c2dc156570",
    iconSet: "MaterialCommunityIcons",
    iconName: "loading"
  },
  {
    uuid: "b316b965-3a54-4da2-9609-b49d1a495d6b",
    iconSet: "MaterialCommunityIcons",
    iconName: "location-enter"
  },
  {
    uuid: "2d33d62e-1249-49f6-b129-02d425cffa3a",
    iconSet: "MaterialCommunityIcons",
    iconName: "location-exit"
  },
  {
    uuid: "8ab2869e-ff1e-4f0c-9449-8c8e28383da5",
    iconSet: "MaterialCommunityIcons",
    iconName: "lock"
  },
  {
    uuid: "d4076619-41ef-4662-89d4-c3dae74ff654",
    iconSet: "MaterialCommunityIcons",
    iconName: "lock-alert"
  },
  {
    uuid: "951f5690-29b5-42d3-90eb-e41f0e19dc06",
    iconSet: "MaterialCommunityIcons",
    iconName: "lock-check"
  },
  {
    uuid: "8da8f0ff-e6e7-4b73-9b4e-7987b69c2160",
    iconSet: "MaterialCommunityIcons",
    iconName: "lock-clock"
  },
  {
    uuid: "7687b36e-59e2-421e-a280-30d04f450738",
    iconSet: "MaterialCommunityIcons",
    iconName: "lock-open"
  },
  {
    uuid: "5c2203f7-b31f-4512-ac41-71a82e17e8d1",
    iconSet: "MaterialCommunityIcons",
    iconName: "lock-open-alert"
  },
  {
    uuid: "7951a51f-ab85-4a39-89e8-805e487bcca6",
    iconSet: "MaterialCommunityIcons",
    iconName: "lock-open-check"
  },
  {
    uuid: "2437bcb0-c717-457d-84ca-e2c4f8f1775d",
    iconSet: "MaterialCommunityIcons",
    iconName: "lock-open-outline"
  },
  {
    uuid: "f1ace8d4-f62a-4803-9c06-22d41d02896b",
    iconSet: "MaterialCommunityIcons",
    iconName: "lock-open-variant"
  },
  {
    uuid: "a3538871-2c6d-41f5-9469-d8cedda28cee",
    iconSet: "MaterialCommunityIcons",
    iconName: "lock-open-variant-outline"
  },
  {
    uuid: "40fd2ea3-aa94-424e-87b3-5db13d162664",
    iconSet: "MaterialCommunityIcons",
    iconName: "lock-outline"
  },
  {
    uuid: "4a30abc2-ad75-4c2f-a648-67252ca1b475",
    iconSet: "MaterialCommunityIcons",
    iconName: "lock-pattern"
  },
  {
    uuid: "08414912-464c-452a-9cc7-6dda1c5a7528",
    iconSet: "MaterialCommunityIcons",
    iconName: "lock-plus"
  },
  {
    uuid: "6f41618d-c168-41bf-95db-4f661589ffa0",
    iconSet: "MaterialCommunityIcons",
    iconName: "lock-question"
  },
  {
    uuid: "75974828-ff95-48d7-bc98-d5c4202baad3",
    iconSet: "MaterialCommunityIcons",
    iconName: "lock-reset"
  },
  {
    uuid: "9b89af48-ac9b-4b81-935b-bfb08e35767f",
    iconSet: "MaterialCommunityIcons",
    iconName: "lock-smart"
  },
  {
    uuid: "a698324c-498b-4f02-8c81-b1d49b11967b",
    iconSet: "MaterialCommunityIcons",
    iconName: "locker"
  },
  {
    uuid: "29c1eed1-cc70-4c60-904b-05572e746b66",
    iconSet: "MaterialCommunityIcons",
    iconName: "locker-multiple"
  },
  {
    uuid: "5a06e771-2589-4a3b-8128-5d1d666efd5a",
    iconSet: "MaterialCommunityIcons",
    iconName: "login"
  },
  {
    uuid: "47dd3435-d8d7-4a9b-8dae-232c5400db0d",
    iconSet: "MaterialCommunityIcons",
    iconName: "login-variant"
  },
  {
    uuid: "86c48f3b-1016-46b1-a92d-1eec7f4f99fb",
    iconSet: "MaterialCommunityIcons",
    iconName: "logout"
  },
  {
    uuid: "9f6c781f-12eb-4de2-91cc-6849f694ced4",
    iconSet: "MaterialCommunityIcons",
    iconName: "logout-variant"
  },
  {
    uuid: "776991e8-49e8-4ccc-b86a-6ff0abcfe3a7",
    iconSet: "MaterialCommunityIcons",
    iconName: "longitude"
  },
  {
    uuid: "c867cadf-3532-47d4-99bf-51235e5c786b",
    iconSet: "MaterialCommunityIcons",
    iconName: "looks"
  },
  {
    uuid: "73be5065-3115-4b5a-8dbf-f1496ab17a2b",
    iconSet: "MaterialCommunityIcons",
    iconName: "loupe"
  },
  {
    uuid: "9b339659-9381-4bd5-9f03-a22ef878184d",
    iconSet: "MaterialCommunityIcons",
    iconName: "lumx"
  },
  {
    uuid: "8c8107d2-dba7-475d-99be-bca315c99ce8",
    iconSet: "MaterialCommunityIcons",
    iconName: "lungs"
  },
  {
    uuid: "2e85ab3b-e875-468f-95f5-ee8657800d80",
    iconSet: "MaterialCommunityIcons",
    iconName: "magnet"
  },
  {
    uuid: "f17e082d-340e-4f9b-b294-7a1b0d05099a",
    iconSet: "MaterialCommunityIcons",
    iconName: "magnet-on"
  },
  {
    uuid: "530acb4c-a709-454d-9e80-cd00d9e38c29",
    iconSet: "MaterialCommunityIcons",
    iconName: "magnify"
  },
  {
    uuid: "4b49a574-33be-42e7-b4ae-f7476ef76465",
    iconSet: "MaterialCommunityIcons",
    iconName: "magnify-close"
  },
  {
    uuid: "2b5b03bc-a88a-4f00-9e84-8d3d39acc6d0",
    iconSet: "MaterialCommunityIcons",
    iconName: "magnify-minus"
  },
  {
    uuid: "b6ac0bec-173e-4870-a5d1-825c2cc07c2c",
    iconSet: "MaterialCommunityIcons",
    iconName: "magnify-minus-cursor"
  },
  {
    uuid: "fc3f57a5-837e-48a2-bc11-44a177adb7d0",
    iconSet: "MaterialCommunityIcons",
    iconName: "magnify-minus-outline"
  },
  {
    uuid: "565f5a17-d8bd-4aa9-ba95-80e1cad5bd6d",
    iconSet: "MaterialCommunityIcons",
    iconName: "magnify-plus"
  },
  {
    uuid: "97d14329-7823-40ea-a936-331308142d6f",
    iconSet: "MaterialCommunityIcons",
    iconName: "magnify-plus-cursor"
  },
  {
    uuid: "7e1e5627-068d-4d90-aee4-2fe270da9331",
    iconSet: "MaterialCommunityIcons",
    iconName: "magnify-plus-outline"
  },
  {
    uuid: "688fa0f3-b5f0-472c-bc4a-f56db3d10359",
    iconSet: "MaterialCommunityIcons",
    iconName: "magnify-remove-cursor"
  },
  {
    uuid: "41f38c65-3a06-437b-a1a3-13d82783134c",
    iconSet: "MaterialCommunityIcons",
    iconName: "magnify-remove-outline"
  },
  {
    uuid: "daaebe42-7aa8-4c2c-ab65-d103301dce65",
    iconSet: "MaterialCommunityIcons",
    iconName: "magnify-scan"
  },
  {
    uuid: "f390180e-e16c-4e05-a2be-b6ecc6f65f79",
    iconSet: "MaterialCommunityIcons",
    iconName: "mail"
  },
  {
    uuid: "dc4a0d4f-c1db-4162-8e33-01f2e82bde24",
    iconSet: "MaterialCommunityIcons",
    iconName: "mailbox"
  },
  {
    uuid: "ea8e8721-804a-43d6-83fd-4038a585074b",
    iconSet: "MaterialCommunityIcons",
    iconName: "mailbox-open"
  },
  {
    uuid: "a1e8bbb2-72d0-436d-ad43-9a008d7d310e",
    iconSet: "MaterialCommunityIcons",
    iconName: "mailbox-open-outline"
  },
  {
    uuid: "1f81e12d-6f45-424e-9e17-60c07877ae18",
    iconSet: "MaterialCommunityIcons",
    iconName: "mailbox-open-up"
  },
  {
    uuid: "1369316a-c42e-44c6-b94d-c3c5054e0549",
    iconSet: "MaterialCommunityIcons",
    iconName: "mailbox-open-up-outline"
  },
  {
    uuid: "4962dd2c-8608-43b9-a7c8-69099b9522c5",
    iconSet: "MaterialCommunityIcons",
    iconName: "mailbox-outline"
  },
  {
    uuid: "136c7c2b-f347-4295-8206-ad6605696cbb",
    iconSet: "MaterialCommunityIcons",
    iconName: "mailbox-up"
  },
  {
    uuid: "c25fc3f0-5969-4c8c-9cb7-64ce78bbf702",
    iconSet: "MaterialCommunityIcons",
    iconName: "mailbox-up-outline"
  },
  {
    uuid: "fdad4d37-e80d-4004-81bd-518646ff43ed",
    iconSet: "MaterialCommunityIcons",
    iconName: "map"
  },
  {
    uuid: "e17168c0-d1af-426a-9fe3-c143aaf8bc6f",
    iconSet: "MaterialCommunityIcons",
    iconName: "map-check"
  },
  {
    uuid: "592ee35a-340c-4dc5-a947-7a7606d30585",
    iconSet: "MaterialCommunityIcons",
    iconName: "map-check-outline"
  },
  {
    uuid: "975e0c7b-a912-4edb-9349-0915ea32c7f3",
    iconSet: "MaterialCommunityIcons",
    iconName: "map-clock"
  },
  {
    uuid: "e92b479f-c1c8-48e7-93e5-4f95b0dac3ce",
    iconSet: "MaterialCommunityIcons",
    iconName: "map-clock-outline"
  },
  {
    uuid: "d13080a4-fb6d-4abd-afa3-43f152ef02fa",
    iconSet: "MaterialCommunityIcons",
    iconName: "map-legend"
  },
  {
    uuid: "5ba1fe63-a323-4928-b01d-2f03e953ea65",
    iconSet: "MaterialCommunityIcons",
    iconName: "map-marker"
  },
  {
    uuid: "3730340b-0d91-46f0-85bd-fcae8424cfae",
    iconSet: "MaterialCommunityIcons",
    iconName: "map-marker-alert"
  },
  {
    uuid: "3a416dbe-2e65-471d-add6-2e127d6077ee",
    iconSet: "MaterialCommunityIcons",
    iconName: "map-marker-alert-outline"
  },
  {
    uuid: "ebf98e65-0204-49a8-a4f6-9d75f3348dfb",
    iconSet: "MaterialCommunityIcons",
    iconName: "map-marker-check"
  },
  {
    uuid: "1b916e9f-43e0-4b7e-adb7-ab4970351352",
    iconSet: "MaterialCommunityIcons",
    iconName: "map-marker-check-outline"
  },
  {
    uuid: "3dc033ae-24ba-4422-ab62-9ec8a139b8be",
    iconSet: "MaterialCommunityIcons",
    iconName: "map-marker-circle"
  },
  {
    uuid: "c57c7967-998b-42e7-8c35-e72aa3972505",
    iconSet: "MaterialCommunityIcons",
    iconName: "map-marker-distance"
  },
  {
    uuid: "38bbe055-7a34-4f88-ab0e-020420ed1271",
    iconSet: "MaterialCommunityIcons",
    iconName: "map-marker-down"
  },
  {
    uuid: "5958bee7-f78b-41f3-8d5f-df242ec55dd6",
    iconSet: "MaterialCommunityIcons",
    iconName: "map-marker-left"
  },
  {
    uuid: "7b67dbc3-6a55-4d07-9309-006831983e3e",
    iconSet: "MaterialCommunityIcons",
    iconName: "map-marker-left-outline"
  },
  {
    uuid: "8fdaa752-2e5c-461d-a403-26a3a98d87a8",
    iconSet: "MaterialCommunityIcons",
    iconName: "map-marker-minus"
  },
  {
    uuid: "a70db5b9-6fc2-4455-83bc-31cf1961173d",
    iconSet: "MaterialCommunityIcons",
    iconName: "map-marker-minus-outline"
  },
  {
    uuid: "e9250ec3-084e-4617-92b4-b3307fe6d72f",
    iconSet: "MaterialCommunityIcons",
    iconName: "map-marker-multiple"
  },
  {
    uuid: "4a0a5e58-6571-4e58-af0c-7297925eea15",
    iconSet: "MaterialCommunityIcons",
    iconName: "map-marker-multiple-outline"
  },
  {
    uuid: "9316a07f-5ccf-4423-9d82-e66043c5f511",
    iconSet: "MaterialCommunityIcons",
    iconName: "map-marker-off"
  },
  {
    uuid: "e22121cf-f4c1-4822-9e6b-8045b39f0160",
    iconSet: "MaterialCommunityIcons",
    iconName: "map-marker-off-outline"
  },
  {
    uuid: "f07afb62-1d1d-4633-abb7-831fabeb814d",
    iconSet: "MaterialCommunityIcons",
    iconName: "map-marker-outline"
  },
  {
    uuid: "5e9f38b4-93b0-49ac-a124-eefacdc97581",
    iconSet: "MaterialCommunityIcons",
    iconName: "map-marker-path"
  },
  {
    uuid: "a7476d5e-17f1-4b70-934b-8baf65a55687",
    iconSet: "MaterialCommunityIcons",
    iconName: "map-marker-plus"
  },
  {
    uuid: "0fa16f6d-bac0-4f09-99d5-16df16ee4320",
    iconSet: "MaterialCommunityIcons",
    iconName: "map-marker-plus-outline"
  },
  {
    uuid: "19c5ea50-3ea1-46a6-a3d2-4741e7c6734e",
    iconSet: "MaterialCommunityIcons",
    iconName: "map-marker-question"
  },
  {
    uuid: "d3c9f4c7-7743-4c36-9643-6560e5eeba89",
    iconSet: "MaterialCommunityIcons",
    iconName: "map-marker-question-outline"
  },
  {
    uuid: "8952914e-b36d-45c6-b191-33bf68e67fba",
    iconSet: "MaterialCommunityIcons",
    iconName: "map-marker-radius"
  },
  {
    uuid: "6679339e-8df7-4b0e-b9fa-9bbeb68d9a5e",
    iconSet: "MaterialCommunityIcons",
    iconName: "map-marker-radius-outline"
  },
  {
    uuid: "1b24d0c1-f760-4b0b-99da-f1d3fecab60c",
    iconSet: "MaterialCommunityIcons",
    iconName: "map-marker-remove"
  },
  {
    uuid: "63e594bb-3e0c-4e30-9bbf-df57b6857dae",
    iconSet: "MaterialCommunityIcons",
    iconName: "map-marker-remove-outline"
  },
  {
    uuid: "829a6d60-2814-452a-9def-7c376051709f",
    iconSet: "MaterialCommunityIcons",
    iconName: "map-marker-remove-variant"
  },
  {
    uuid: "62b67552-b278-485d-a506-140f0e2ee10a",
    iconSet: "MaterialCommunityIcons",
    iconName: "map-marker-right"
  },
  {
    uuid: "ed087e55-3491-4375-9127-942f23a418c6",
    iconSet: "MaterialCommunityIcons",
    iconName: "map-marker-right-outline"
  },
  {
    uuid: "db2f10c5-6b84-425f-8223-212be28633dd",
    iconSet: "MaterialCommunityIcons",
    iconName: "map-marker-up"
  },
  {
    uuid: "d0cb610b-8dde-487d-95fc-ef6241ee6fa3",
    iconSet: "MaterialCommunityIcons",
    iconName: "map-minus"
  },
  {
    uuid: "faa73540-6568-4a3c-839f-2bb4d506cb78",
    iconSet: "MaterialCommunityIcons",
    iconName: "map-outline"
  },
  {
    uuid: "e648c525-bbba-4588-967f-0fa01ccfd6ee",
    iconSet: "MaterialCommunityIcons",
    iconName: "map-plus"
  },
  {
    uuid: "b67f3ac9-30a4-4f7f-bd07-e51c155e36da",
    iconSet: "MaterialCommunityIcons",
    iconName: "map-search"
  },
  {
    uuid: "7d082bf7-2e97-46e1-8694-da801ca806ea",
    iconSet: "MaterialCommunityIcons",
    iconName: "map-search-outline"
  },
  {
    uuid: "a215aae1-a1e8-4744-9d32-f1d7e738f60f",
    iconSet: "MaterialCommunityIcons",
    iconName: "mapbox"
  },
  {
    uuid: "0a6d770b-172b-4e41-adcb-6bd6c51071a9",
    iconSet: "MaterialCommunityIcons",
    iconName: "margin"
  },
  {
    uuid: "a6dd667a-53b3-49a6-aaca-7564bbdf149b",
    iconSet: "MaterialCommunityIcons",
    iconName: "marker"
  },
  {
    uuid: "1178225b-d3ab-427b-9917-3f7bbd166148",
    iconSet: "MaterialCommunityIcons",
    iconName: "marker-cancel"
  },
  {
    uuid: "033151e9-1ba0-4e25-a6e0-517c2cf5b8b4",
    iconSet: "MaterialCommunityIcons",
    iconName: "marker-check"
  },
  {
    uuid: "5c216473-505e-47b3-9139-901fb209ba5d",
    iconSet: "MaterialCommunityIcons",
    iconName: "mastodon"
  },
  {
    uuid: "c91bf7b3-ef3f-4856-930b-5d8429584e2f",
    iconSet: "MaterialCommunityIcons",
    iconName: "material-design"
  },
  {
    uuid: "5214753c-a4da-42e2-8b2b-ec35b3ac1701",
    iconSet: "MaterialCommunityIcons",
    iconName: "material-ui"
  },
  {
    uuid: "7273eaaf-e4bc-438d-baae-89f6a72256ef",
    iconSet: "MaterialCommunityIcons",
    iconName: "math-compass"
  },
  {
    uuid: "c68e6595-762c-4d8a-ad80-551fdcecd6dc",
    iconSet: "MaterialCommunityIcons",
    iconName: "math-cos"
  },
  {
    uuid: "523b0b45-6067-4f2e-9f9b-aa572f69ae85",
    iconSet: "MaterialCommunityIcons",
    iconName: "math-integral"
  },
  {
    uuid: "87bcbb70-6136-4013-b64c-ea786e2fe5d0",
    iconSet: "MaterialCommunityIcons",
    iconName: "math-integral-box"
  },
  {
    uuid: "30ecc8a6-79e5-41e7-bfd6-5e79ec156dde",
    iconSet: "MaterialCommunityIcons",
    iconName: "math-log"
  },
  {
    uuid: "a71f0366-bb3c-4e35-af50-b35729698ed8",
    iconSet: "MaterialCommunityIcons",
    iconName: "math-norm"
  },
  {
    uuid: "3d4e6ef3-c952-4457-8542-5bb2fee88a54",
    iconSet: "MaterialCommunityIcons",
    iconName: "math-norm-box"
  },
  {
    uuid: "52705dc4-9fc8-449c-bc32-37836d0f81d3",
    iconSet: "MaterialCommunityIcons",
    iconName: "math-sin"
  },
  {
    uuid: "bbc39fa9-de54-4a5f-9aec-d21cf0aece1d",
    iconSet: "MaterialCommunityIcons",
    iconName: "math-tan"
  },
  {
    uuid: "d75ea4d1-967b-4d28-b513-33e038e0cbd0",
    iconSet: "MaterialCommunityIcons",
    iconName: "matrix"
  },
  {
    uuid: "2b00e516-8a59-46ee-b5e5-fef2a205c1c1",
    iconSet: "MaterialCommunityIcons",
    iconName: "medal"
  },
  {
    uuid: "a1c0cb9b-177f-468a-b5b3-2d89a972c96a",
    iconSet: "MaterialCommunityIcons",
    iconName: "medal-outline"
  },
  {
    uuid: "3062413c-513a-4f02-ab06-80b6aaa5be2d",
    iconSet: "MaterialCommunityIcons",
    iconName: "medical-bag"
  },
  {
    uuid: "f7748f69-98c0-461a-bc3c-8e71861a98bf",
    iconSet: "MaterialCommunityIcons",
    iconName: "meditation"
  },
  {
    uuid: "4598ad97-d633-4f30-a441-ebd0b367d1e6",
    iconSet: "MaterialCommunityIcons",
    iconName: "memory"
  },
  {
    uuid: "527d3adf-691b-4024-a829-3345a7dfa559",
    iconSet: "MaterialCommunityIcons",
    iconName: "menu"
  },
  {
    uuid: "3918b872-71be-4ecb-aba6-29d5e78b5f64",
    iconSet: "MaterialCommunityIcons",
    iconName: "menu-down"
  },
  {
    uuid: "2ce52faa-def5-4073-aa85-c8cbab9984ab",
    iconSet: "MaterialCommunityIcons",
    iconName: "menu-down-outline"
  },
  {
    uuid: "7e561dc0-8195-4c2c-a66f-69dd985902f5",
    iconSet: "MaterialCommunityIcons",
    iconName: "menu-left"
  },
  {
    uuid: "7b58bf5d-bebe-451a-8b9f-6e22c65ec588",
    iconSet: "MaterialCommunityIcons",
    iconName: "menu-left-outline"
  },
  {
    uuid: "fe61c858-a04a-497b-8d3f-31d1bb07becc",
    iconSet: "MaterialCommunityIcons",
    iconName: "menu-open"
  },
  {
    uuid: "80fa493a-6a1e-485f-bfab-0760ce5612e7",
    iconSet: "MaterialCommunityIcons",
    iconName: "menu-right"
  },
  {
    uuid: "7213d613-c988-4ca8-96fa-5ec3e670b636",
    iconSet: "MaterialCommunityIcons",
    iconName: "menu-right-outline"
  },
  {
    uuid: "fcd30229-c83f-4783-831b-637bfb18177c",
    iconSet: "MaterialCommunityIcons",
    iconName: "menu-swap"
  },
  {
    uuid: "9a15e1ce-3861-42fa-a3cf-daed40b33ae3",
    iconSet: "MaterialCommunityIcons",
    iconName: "menu-swap-outline"
  },
  {
    uuid: "98ab5028-2ab2-4166-b80b-d2b620c9e915",
    iconSet: "MaterialCommunityIcons",
    iconName: "menu-up"
  },
  {
    uuid: "8e825691-a43c-4504-8ff2-f2d868796150",
    iconSet: "MaterialCommunityIcons",
    iconName: "menu-up-outline"
  },
  {
    uuid: "97c1c736-d819-413f-ac6f-0425f0a01e56",
    iconSet: "MaterialCommunityIcons",
    iconName: "merge"
  },
  {
    uuid: "7cdf63ce-0a2e-42ef-b616-4704fc437bb9",
    iconSet: "MaterialCommunityIcons",
    iconName: "message"
  },
  {
    uuid: "01005f99-8294-433e-93ae-e7b6f93e94c7",
    iconSet: "MaterialCommunityIcons",
    iconName: "message-alert"
  },
  {
    uuid: "85ef646f-06b4-4197-92d4-daaaae4a4d55",
    iconSet: "MaterialCommunityIcons",
    iconName: "message-alert-outline"
  },
  {
    uuid: "2e9cb17e-ff4c-4656-815f-412ff2cb26dc",
    iconSet: "MaterialCommunityIcons",
    iconName: "message-arrow-left"
  },
  {
    uuid: "41d26d17-866c-4fc1-aaec-986aca56b788",
    iconSet: "MaterialCommunityIcons",
    iconName: "message-arrow-left-outline"
  },
  {
    uuid: "cb801dea-054d-4aab-9bd8-5defe9430f63",
    iconSet: "MaterialCommunityIcons",
    iconName: "message-arrow-right"
  },
  {
    uuid: "6ca3a657-6d2b-4d69-9dba-b31bab08fe62",
    iconSet: "MaterialCommunityIcons",
    iconName: "message-arrow-right-outline"
  },
  {
    uuid: "930d5f1a-c8e6-4352-9b1a-225cf49c922d",
    iconSet: "MaterialCommunityIcons",
    iconName: "message-bulleted"
  },
  {
    uuid: "e492dfe8-963a-4d28-bc6d-73bc2835991a",
    iconSet: "MaterialCommunityIcons",
    iconName: "message-bulleted-off"
  },
  {
    uuid: "2e5b2ca3-e92a-427d-bf49-36b928d7070d",
    iconSet: "MaterialCommunityIcons",
    iconName: "message-cog"
  },
  {
    uuid: "51d9fbef-7e53-4f52-94f5-8df49a03567e",
    iconSet: "MaterialCommunityIcons",
    iconName: "message-cog-outline"
  },
  {
    uuid: "dc89fb57-dd30-44d2-9595-bad67ee0bf62",
    iconSet: "MaterialCommunityIcons",
    iconName: "message-draw"
  },
  {
    uuid: "b89a69c2-3072-4268-88c1-e4b81752c44a",
    iconSet: "MaterialCommunityIcons",
    iconName: "message-image"
  },
  {
    uuid: "cad16e6d-49e2-45c8-a4d1-e8ab9a219dca",
    iconSet: "MaterialCommunityIcons",
    iconName: "message-image-outline"
  },
  {
    uuid: "18ca7152-2d2f-4278-bbcb-daf5fcb6efff",
    iconSet: "MaterialCommunityIcons",
    iconName: "message-lock"
  },
  {
    uuid: "7e3d2bed-cb67-4282-bbcc-6cb2a830642d",
    iconSet: "MaterialCommunityIcons",
    iconName: "message-lock-outline"
  },
  {
    uuid: "2be7f245-11fe-426c-9176-53be801c898e",
    iconSet: "MaterialCommunityIcons",
    iconName: "message-minus"
  },
  {
    uuid: "57fb680d-b5a4-42ca-80c4-1030d6fcce38",
    iconSet: "MaterialCommunityIcons",
    iconName: "message-minus-outline"
  },
  {
    uuid: "bb7dab04-0160-4f78-aced-9a38675e6e40",
    iconSet: "MaterialCommunityIcons",
    iconName: "message-outline"
  },
  {
    uuid: "5ff128d8-c23d-4e1d-977e-067b0a27bcaa",
    iconSet: "MaterialCommunityIcons",
    iconName: "message-plus"
  },
  {
    uuid: "116c3211-a018-4117-9bf7-ec10679c876f",
    iconSet: "MaterialCommunityIcons",
    iconName: "message-plus-outline"
  },
  {
    uuid: "ee8ef539-9851-4bd5-b86e-6be959adc1de",
    iconSet: "MaterialCommunityIcons",
    iconName: "message-processing"
  },
  {
    uuid: "74c92fdd-4a05-40da-b526-4c51c571b6d4",
    iconSet: "MaterialCommunityIcons",
    iconName: "message-processing-outline"
  },
  {
    uuid: "46745319-0565-4f6c-8123-19bbb724db6f",
    iconSet: "MaterialCommunityIcons",
    iconName: "message-reply"
  },
  {
    uuid: "47b51be9-83ff-4499-ba12-71ba820c33b1",
    iconSet: "MaterialCommunityIcons",
    iconName: "message-reply-text"
  },
  {
    uuid: "c9d31716-a75d-412c-91a2-d9b2b22d27f5",
    iconSet: "MaterialCommunityIcons",
    iconName: "message-settings"
  },
  {
    uuid: "c138a961-7039-4b3d-853e-0474dbed2267",
    iconSet: "MaterialCommunityIcons",
    iconName: "message-settings-outline"
  },
  {
    uuid: "23fa5a4e-29fb-41a3-a158-a39c7c0b6da0",
    iconSet: "MaterialCommunityIcons",
    iconName: "message-text"
  },
  {
    uuid: "5fe3579b-4879-4e1c-a962-bcf06644a5c6",
    iconSet: "MaterialCommunityIcons",
    iconName: "message-text-clock"
  },
  {
    uuid: "c73165b7-ff72-43a6-82f3-a78a286ec05c",
    iconSet: "MaterialCommunityIcons",
    iconName: "message-text-clock-outline"
  },
  {
    uuid: "4e892991-2824-4c2c-a9c4-5f3486a81844",
    iconSet: "MaterialCommunityIcons",
    iconName: "message-text-lock"
  },
  {
    uuid: "967a4fd3-4bc6-4477-a6d7-0da22b5a633c",
    iconSet: "MaterialCommunityIcons",
    iconName: "message-text-lock-outline"
  },
  {
    uuid: "61682433-58d6-4ae0-9159-9ad36613d10e",
    iconSet: "MaterialCommunityIcons",
    iconName: "message-text-outline"
  },
  {
    uuid: "4483ad52-e857-4937-a40a-74151050cc81",
    iconSet: "MaterialCommunityIcons",
    iconName: "message-video"
  },
  {
    uuid: "6703ba67-f062-4c20-8073-4b296f7df7a2",
    iconSet: "MaterialCommunityIcons",
    iconName: "meteor"
  },
  {
    uuid: "57ad513a-0c95-426b-b8de-0a588b25d6b4",
    iconSet: "MaterialCommunityIcons",
    iconName: "metronome"
  },
  {
    uuid: "04629545-dc58-4acb-803f-8554a78636ba",
    iconSet: "MaterialCommunityIcons",
    iconName: "metronome-tick"
  },
  {
    uuid: "b17e0c4f-8ba9-47f3-a895-dc62ce1c88d2",
    iconSet: "MaterialCommunityIcons",
    iconName: "micro-sd"
  },
  {
    uuid: "b9260725-0444-4133-8c2d-358642c7817a",
    iconSet: "MaterialCommunityIcons",
    iconName: "microphone"
  },
  {
    uuid: "d568ceec-bbe0-4931-ab1b-a8a48e44b420",
    iconSet: "MaterialCommunityIcons",
    iconName: "microphone-minus"
  },
  {
    uuid: "27797187-ae1a-4ae1-9020-0b206c84c680",
    iconSet: "MaterialCommunityIcons",
    iconName: "microphone-off"
  },
  {
    uuid: "8ddb7393-78c5-4389-860e-97ee62eca04b",
    iconSet: "MaterialCommunityIcons",
    iconName: "microphone-outline"
  },
  {
    uuid: "ea0260b5-0c1e-4bbc-a7c6-965fc5be49f2",
    iconSet: "MaterialCommunityIcons",
    iconName: "microphone-plus"
  },
  {
    uuid: "4954d95d-3a68-4bf8-a6d8-1d3fc5bc357b",
    iconSet: "MaterialCommunityIcons",
    iconName: "microphone-settings"
  },
  {
    uuid: "8b72b7df-8ade-4cdf-acb9-d14226679791",
    iconSet: "MaterialCommunityIcons",
    iconName: "microphone-variant"
  },
  {
    uuid: "648227bd-c10e-46e1-9158-51a28d508e1f",
    iconSet: "MaterialCommunityIcons",
    iconName: "microphone-variant-off"
  },
  {
    uuid: "58fa4e1b-d473-4442-b496-aa53c5eadce7",
    iconSet: "MaterialCommunityIcons",
    iconName: "microscope"
  },
  {
    uuid: "a80a7980-9dba-4c66-8021-65c23e8e4a99",
    iconSet: "MaterialCommunityIcons",
    iconName: "microsoft"
  },
  {
    uuid: "e0d33a1f-c87b-41cf-918f-7517db657663",
    iconSet: "MaterialCommunityIcons",
    iconName: "microsoft-access"
  },
  {
    uuid: "02d9405c-2739-4922-9de9-870cf608d6ee",
    iconSet: "MaterialCommunityIcons",
    iconName: "microsoft-azure"
  },
  {
    uuid: "2c313245-049a-4a28-958e-bee0d2f44bda",
    iconSet: "MaterialCommunityIcons",
    iconName: "microsoft-azure-devops"
  },
  {
    uuid: "3999941c-7f6e-44fa-8dee-e57e5cd35bca",
    iconSet: "MaterialCommunityIcons",
    iconName: "microsoft-bing"
  },
  {
    uuid: "4d73a317-d5c1-4ac1-9dd1-8a730e39a5a4",
    iconSet: "MaterialCommunityIcons",
    iconName: "microsoft-dynamics-365"
  },
  {
    uuid: "de026799-777d-4440-abfc-d2dc5ed7a05a",
    iconSet: "MaterialCommunityIcons",
    iconName: "microsoft-edge"
  },
  {
    uuid: "e995f907-ba3e-4328-a5d0-50012c827362",
    iconSet: "MaterialCommunityIcons",
    iconName: "microsoft-edge-legacy"
  },
  {
    uuid: "1bdfc5ff-ebbc-4b06-9ad7-8b43a96a04dd",
    iconSet: "MaterialCommunityIcons",
    iconName: "microsoft-excel"
  },
  {
    uuid: "8c9cfc9a-d6e5-4ba4-931b-3a00987a37d1",
    iconSet: "MaterialCommunityIcons",
    iconName: "microsoft-internet-explorer"
  },
  {
    uuid: "11c6b467-9f06-44e8-a075-d7e784a69445",
    iconSet: "MaterialCommunityIcons",
    iconName: "microsoft-office"
  },
  {
    uuid: "8ffe8764-eb8d-4fcd-bd88-088f725681b8",
    iconSet: "MaterialCommunityIcons",
    iconName: "microsoft-onedrive"
  },
  {
    uuid: "e7b4d822-3f57-402e-919b-edecf5e5d88b",
    iconSet: "MaterialCommunityIcons",
    iconName: "microsoft-onenote"
  },
  {
    uuid: "b87f3e06-f449-420e-bfbf-b261fa59c870",
    iconSet: "MaterialCommunityIcons",
    iconName: "microsoft-outlook"
  },
  {
    uuid: "c6e668f9-f524-4e3f-8fce-ebbd9e4d13b0",
    iconSet: "MaterialCommunityIcons",
    iconName: "microsoft-powerpoint"
  },
  {
    uuid: "864e4525-1b52-4e6a-8bb4-3eb59159c8a2",
    iconSet: "MaterialCommunityIcons",
    iconName: "microsoft-sharepoint"
  },
  {
    uuid: "707f15db-6bdd-4735-a723-125be643316b",
    iconSet: "MaterialCommunityIcons",
    iconName: "microsoft-teams"
  },
  {
    uuid: "fe4d5e81-843a-45a1-88b7-701e8f519553",
    iconSet: "MaterialCommunityIcons",
    iconName: "microsoft-visual-studio"
  },
  {
    uuid: "b7b105d3-c87b-4c8b-9de5-974e48bd8672",
    iconSet: "MaterialCommunityIcons",
    iconName: "microsoft-visual-studio-code"
  },
  {
    uuid: "3899167f-0d2d-49ac-99c7-0907a026212d",
    iconSet: "MaterialCommunityIcons",
    iconName: "microsoft-windows"
  },
  {
    uuid: "d394bd37-3968-49d2-bed1-619cb9d1f40b",
    iconSet: "MaterialCommunityIcons",
    iconName: "microsoft-windows-classic"
  },
  {
    uuid: "2ba32832-0e1c-46b3-8c48-546c85c75a54",
    iconSet: "MaterialCommunityIcons",
    iconName: "microsoft-word"
  },
  {
    uuid: "3549cb95-5339-4767-8775-233b1460fb28",
    iconSet: "MaterialCommunityIcons",
    iconName: "microsoft-xbox"
  },
  {
    uuid: "9de4bdef-3131-47d3-ac2e-c793e29d68ac",
    iconSet: "MaterialCommunityIcons",
    iconName: "microsoft-xbox-controller"
  },
  {
    uuid: "edb56989-a654-4217-a77c-0b535441e5b2",
    iconSet: "MaterialCommunityIcons",
    iconName: "microsoft-xbox-controller-battery-alert"
  },
  {
    uuid: "5ce116dc-5a0c-4272-a446-589d27aa0ebb",
    iconSet: "MaterialCommunityIcons",
    iconName: "microsoft-xbox-controller-battery-charging"
  },
  {
    uuid: "ee60a9d6-acd3-4977-8fc4-ab8bde0fa967",
    iconSet: "MaterialCommunityIcons",
    iconName: "microsoft-xbox-controller-battery-empty"
  },
  {
    uuid: "2fdcaf00-2cee-4ee7-8f13-3637d438de21",
    iconSet: "MaterialCommunityIcons",
    iconName: "microsoft-xbox-controller-battery-full"
  },
  {
    uuid: "e000593f-eaeb-40c0-b33c-d7fab2736a29",
    iconSet: "MaterialCommunityIcons",
    iconName: "microsoft-xbox-controller-battery-low"
  },
  {
    uuid: "67edc1ff-942c-4a8d-8231-7025de1ac6aa",
    iconSet: "MaterialCommunityIcons",
    iconName: "microsoft-xbox-controller-battery-medium"
  },
  {
    uuid: "6f453ccb-4836-4be8-90c4-e2e43c429492",
    iconSet: "MaterialCommunityIcons",
    iconName: "microsoft-xbox-controller-battery-unknown"
  },
  {
    uuid: "62251011-bc09-4558-80cf-72bee2a39828",
    iconSet: "MaterialCommunityIcons",
    iconName: "microsoft-xbox-controller-menu"
  },
  {
    uuid: "6f7e05a1-c75f-4f74-aaba-6d66ffc7d688",
    iconSet: "MaterialCommunityIcons",
    iconName: "microsoft-xbox-controller-off"
  },
  {
    uuid: "67513c45-2b04-4f71-8ea4-ab7793cddac0",
    iconSet: "MaterialCommunityIcons",
    iconName: "microsoft-xbox-controller-view"
  },
  {
    uuid: "4e47809c-8a0d-4c1f-9de8-95da3fd8e266",
    iconSet: "MaterialCommunityIcons",
    iconName: "microsoft-yammer"
  },
  {
    uuid: "3f311ad5-5060-4589-a940-5b705d36489e",
    iconSet: "MaterialCommunityIcons",
    iconName: "microwave"
  },
  {
    uuid: "b7882eca-cee2-4503-807f-fdc53160bda1",
    iconSet: "MaterialCommunityIcons",
    iconName: "microwave-off"
  },
  {
    uuid: "20f4171c-c0f2-4915-8061-35d3435b8fab",
    iconSet: "MaterialCommunityIcons",
    iconName: "middleware"
  },
  {
    uuid: "8a1b6dd6-03ef-477f-890d-9f5e63e8a7f5",
    iconSet: "MaterialCommunityIcons",
    iconName: "middleware-outline"
  },
  {
    uuid: "74d30e54-f36e-441e-a372-3ffac4b6fcd3",
    iconSet: "MaterialCommunityIcons",
    iconName: "midi"
  },
  {
    uuid: "6c7f5756-3074-4058-a663-c5745858d95a",
    iconSet: "MaterialCommunityIcons",
    iconName: "midi-port"
  },
  {
    uuid: "1da9f108-02e3-4e7a-8aa1-8333635e1879",
    iconSet: "MaterialCommunityIcons",
    iconName: "mine"
  },
  {
    uuid: "7d8b6223-1bec-44ac-8d79-06c216806c46",
    iconSet: "MaterialCommunityIcons",
    iconName: "minecraft"
  },
  {
    uuid: "abab24ab-4852-46b2-aa61-e3dd40440f44",
    iconSet: "MaterialCommunityIcons",
    iconName: "mini-sd"
  },
  {
    uuid: "fdc90b7c-2638-4b99-b54f-76d5f779b55f",
    iconSet: "MaterialCommunityIcons",
    iconName: "minidisc"
  },
  {
    uuid: "4340e6e2-b40d-4e4f-8bbc-d3520ca8fa68",
    iconSet: "MaterialCommunityIcons",
    iconName: "minus"
  },
  {
    uuid: "1ef38627-46ee-4602-964d-e91c38682bd0",
    iconSet: "MaterialCommunityIcons",
    iconName: "minus-box"
  },
  {
    uuid: "81f22a42-ba6d-4f22-83b4-122dce8e3021",
    iconSet: "MaterialCommunityIcons",
    iconName: "minus-box-multiple"
  },
  {
    uuid: "4335353d-1fc5-4df5-af6a-dc9f3dfbef90",
    iconSet: "MaterialCommunityIcons",
    iconName: "minus-box-multiple-outline"
  },
  {
    uuid: "3410ecb3-c9a7-4034-b047-9d13c649de44",
    iconSet: "MaterialCommunityIcons",
    iconName: "minus-box-outline"
  },
  {
    uuid: "21c629ad-01dd-4dc9-b480-ba1af6723784",
    iconSet: "MaterialCommunityIcons",
    iconName: "minus-circle"
  },
  {
    uuid: "3920e181-1896-422a-91cb-8281b23d1869",
    iconSet: "MaterialCommunityIcons",
    iconName: "minus-circle-multiple"
  },
  {
    uuid: "0e437e4f-e5b6-4726-9c52-4aa5448f620f",
    iconSet: "MaterialCommunityIcons",
    iconName: "minus-circle-multiple-outline"
  },
  {
    uuid: "8bb08510-2c60-4a6a-8346-364b62f43968",
    iconSet: "MaterialCommunityIcons",
    iconName: "minus-circle-off"
  },
  {
    uuid: "3049344a-3b3e-42f4-96f9-3a150fe50794",
    iconSet: "MaterialCommunityIcons",
    iconName: "minus-circle-off-outline"
  },
  {
    uuid: "8fb54840-7754-4a6d-bc60-98a1d5749f2c",
    iconSet: "MaterialCommunityIcons",
    iconName: "minus-circle-outline"
  },
  {
    uuid: "998a520f-f1ef-4b2a-aeaa-68817de43330",
    iconSet: "MaterialCommunityIcons",
    iconName: "minus-network"
  },
  {
    uuid: "202eae39-ccec-43fb-bed8-5f0b86bafb8f",
    iconSet: "MaterialCommunityIcons",
    iconName: "minus-network-outline"
  },
  {
    uuid: "825ae1b4-1f56-4e9f-bcda-8c1d88bf4f72",
    iconSet: "MaterialCommunityIcons",
    iconName: "mirror"
  },
  {
    uuid: "a45cc5bc-a882-425e-99b1-5f73279e8776",
    iconSet: "MaterialCommunityIcons",
    iconName: "mixed-martial-arts"
  },
  {
    uuid: "60640054-4339-4fd3-8d71-6c0f41cf4e3b",
    iconSet: "MaterialCommunityIcons",
    iconName: "mixed-reality"
  },
  {
    uuid: "95e913fb-5f63-491a-93a7-2aacbacc2592",
    iconSet: "MaterialCommunityIcons",
    iconName: "mixer"
  },
  {
    uuid: "9b493310-c3fe-4c42-8919-d04200c871a9",
    iconSet: "MaterialCommunityIcons",
    iconName: "molecule"
  },
  {
    uuid: "cb78d309-3e0e-4df9-8de1-53757293f238",
    iconSet: "MaterialCommunityIcons",
    iconName: "molecule-co"
  },
  {
    uuid: "5dc8eadc-158a-4bba-8c9a-3e4045d0327f",
    iconSet: "MaterialCommunityIcons",
    iconName: "molecule-co2"
  },
  {
    uuid: "8d263793-4e64-4363-9484-6e4724431b11",
    iconSet: "MaterialCommunityIcons",
    iconName: "monitor"
  },
  {
    uuid: "6b35f9cc-592e-4aba-b2dc-15e850784c4c",
    iconSet: "MaterialCommunityIcons",
    iconName: "monitor-cellphone"
  },
  {
    uuid: "afd5704f-4ec8-4f6f-80a3-f1be05b5d4a5",
    iconSet: "MaterialCommunityIcons",
    iconName: "monitor-cellphone-star"
  },
  {
    uuid: "1e239f76-da41-4c24-9448-276623e87e5b",
    iconSet: "MaterialCommunityIcons",
    iconName: "monitor-clean"
  },
  {
    uuid: "01ec6230-43c2-4ea1-aec9-28061eb0ebab",
    iconSet: "MaterialCommunityIcons",
    iconName: "monitor-dashboard"
  },
  {
    uuid: "6f66832d-966a-4568-a922-e9093e3125b4",
    iconSet: "MaterialCommunityIcons",
    iconName: "monitor-edit"
  },
  {
    uuid: "35f9c928-91a4-4807-a9b7-3f92958bb590",
    iconSet: "MaterialCommunityIcons",
    iconName: "monitor-eye"
  },
  {
    uuid: "536c170c-2106-428c-8889-77610d148c1a",
    iconSet: "MaterialCommunityIcons",
    iconName: "monitor-lock"
  },
  {
    uuid: "7a68c12c-d876-4af6-b807-9d05b9d96ed8",
    iconSet: "MaterialCommunityIcons",
    iconName: "monitor-multiple"
  },
  {
    uuid: "f5457791-55da-4f76-b401-29812973722b",
    iconSet: "MaterialCommunityIcons",
    iconName: "monitor-off"
  },
  {
    uuid: "1bae2a0c-4884-4d18-b0b3-dea406a42ae3",
    iconSet: "MaterialCommunityIcons",
    iconName: "monitor-screenshot"
  },
  {
    uuid: "49ec8577-0879-4227-ac37-604f08486d90",
    iconSet: "MaterialCommunityIcons",
    iconName: "monitor-share"
  },
  {
    uuid: "54af8a1a-a6ba-4d6e-b366-e032e4ee9b16",
    iconSet: "MaterialCommunityIcons",
    iconName: "monitor-speaker"
  },
  {
    uuid: "de7d45ec-6f87-4373-b2cb-51fb101b8cad",
    iconSet: "MaterialCommunityIcons",
    iconName: "monitor-speaker-off"
  },
  {
    uuid: "5c05c895-d694-42aa-b6b3-4d207c65fe24",
    iconSet: "MaterialCommunityIcons",
    iconName: "monitor-star"
  },
  {
    uuid: "ea3147d0-b510-4c48-9415-48b7f5fb9850",
    iconSet: "MaterialCommunityIcons",
    iconName: "moon-first-quarter"
  },
  {
    uuid: "c256420f-cf3c-4fd1-9c65-851fd06dfdb2",
    iconSet: "MaterialCommunityIcons",
    iconName: "moon-full"
  },
  {
    uuid: "8b9c6c22-ac54-487b-b5b1-3e2d0472c644",
    iconSet: "MaterialCommunityIcons",
    iconName: "moon-last-quarter"
  },
  {
    uuid: "45ef8674-65ad-46f7-a334-2dcd3e0667d2",
    iconSet: "MaterialCommunityIcons",
    iconName: "moon-new"
  },
  {
    uuid: "ffdd8269-a788-4ed4-a24f-bb45ebb50caf",
    iconSet: "MaterialCommunityIcons",
    iconName: "moon-waning-crescent"
  },
  {
    uuid: "9c9e6114-6f28-4a3c-a67d-783b237133c0",
    iconSet: "MaterialCommunityIcons",
    iconName: "moon-waning-gibbous"
  },
  {
    uuid: "555ea21a-bd2a-4ccd-9cf4-a13c5c721f3d",
    iconSet: "MaterialCommunityIcons",
    iconName: "moon-waxing-crescent"
  },
  {
    uuid: "1638b6c9-84c5-450f-a202-37eff4232dfb",
    iconSet: "MaterialCommunityIcons",
    iconName: "moon-waxing-gibbous"
  },
  {
    uuid: "444dbe01-3e37-4ba6-b13c-92d701293f5a",
    iconSet: "MaterialCommunityIcons",
    iconName: "moped"
  },
  {
    uuid: "0b1d9fc2-8fe4-4feb-bb18-e4ae6889355d",
    iconSet: "MaterialCommunityIcons",
    iconName: "more"
  },
  {
    uuid: "0a658d84-d033-473d-bd23-85aa7e9310c1",
    iconSet: "MaterialCommunityIcons",
    iconName: "mother-heart"
  },
  {
    uuid: "4082f931-c600-4c3a-993d-92f0854957fb",
    iconSet: "MaterialCommunityIcons",
    iconName: "mother-nurse"
  },
  {
    uuid: "79ab71ed-ea13-4b4a-8d16-27e402f77fc3",
    iconSet: "MaterialCommunityIcons",
    iconName: "motion-sensor"
  },
  {
    uuid: "525623fd-5b2c-45b3-8695-c20f3aa28bcc",
    iconSet: "MaterialCommunityIcons",
    iconName: "motion-sensor-off"
  },
  {
    uuid: "972131b6-3754-486b-a58e-8c0ce9621646",
    iconSet: "MaterialCommunityIcons",
    iconName: "motorbike"
  },
  {
    uuid: "cc084d29-c967-4f55-8577-add24eb96a57",
    iconSet: "MaterialCommunityIcons",
    iconName: "mouse"
  },
  {
    uuid: "22e978e4-4d30-4740-b9ba-1df14525c033",
    iconSet: "MaterialCommunityIcons",
    iconName: "mouse-bluetooth"
  },
  {
    uuid: "7da2908f-185e-4aaf-a661-23a280d43c44",
    iconSet: "MaterialCommunityIcons",
    iconName: "mouse-off"
  },
  {
    uuid: "bbaeb656-b569-4f2d-85dc-38130fc0c9bf",
    iconSet: "MaterialCommunityIcons",
    iconName: "mouse-variant"
  },
  {
    uuid: "f278923d-1392-4b5a-9396-c7261358f4a7",
    iconSet: "MaterialCommunityIcons",
    iconName: "mouse-variant-off"
  },
  {
    uuid: "ce9e88bd-d92f-4c86-9f1d-567032e7b512",
    iconSet: "MaterialCommunityIcons",
    iconName: "move-resize"
  },
  {
    uuid: "4bd3276b-e561-436d-83e3-c41a70a6e235",
    iconSet: "MaterialCommunityIcons",
    iconName: "move-resize-variant"
  },
  {
    uuid: "e8b5dae4-7a40-470c-83a1-766422a81792",
    iconSet: "MaterialCommunityIcons",
    iconName: "movie"
  },
  {
    uuid: "5c0817d9-8461-4168-9764-983b77538c1d",
    iconSet: "MaterialCommunityIcons",
    iconName: "movie-edit"
  },
  {
    uuid: "bba9e812-8f96-4705-82d7-32c715ed3b9b",
    iconSet: "MaterialCommunityIcons",
    iconName: "movie-edit-outline"
  },
  {
    uuid: "ed5fa7fc-0dd8-40d2-92cc-a40c2e5b5ac9",
    iconSet: "MaterialCommunityIcons",
    iconName: "movie-filter"
  },
  {
    uuid: "aac370ec-b70d-4c20-90b6-64ee5d1d685a",
    iconSet: "MaterialCommunityIcons",
    iconName: "movie-filter-outline"
  },
  {
    uuid: "880e0b3a-a3f8-4123-83c6-3f8a412a92c9",
    iconSet: "MaterialCommunityIcons",
    iconName: "movie-open"
  },
  {
    uuid: "8448330d-def4-438d-b11c-8303e8004f72",
    iconSet: "MaterialCommunityIcons",
    iconName: "movie-open-outline"
  },
  {
    uuid: "81954a26-3fa5-4a8a-93f3-3743aab35433",
    iconSet: "MaterialCommunityIcons",
    iconName: "movie-outline"
  },
  {
    uuid: "b1aed482-17c1-41d8-9420-a6e704ce69c0",
    iconSet: "MaterialCommunityIcons",
    iconName: "movie-roll"
  },
  {
    uuid: "1329570c-5997-421f-8758-1ceee6fdb485",
    iconSet: "MaterialCommunityIcons",
    iconName: "movie-search"
  },
  {
    uuid: "f211ab68-86ea-411d-b773-8c40b590f814",
    iconSet: "MaterialCommunityIcons",
    iconName: "movie-search-outline"
  },
  {
    uuid: "3171cd60-37e3-40a2-b089-f89683583735",
    iconSet: "MaterialCommunityIcons",
    iconName: "muffin"
  },
  {
    uuid: "f9dc03b6-9a46-4747-adb0-0a725a0f413a",
    iconSet: "MaterialCommunityIcons",
    iconName: "multiplication"
  },
  {
    uuid: "ac0baf73-125b-4c85-8f15-89bef5974c2a",
    iconSet: "MaterialCommunityIcons",
    iconName: "multiplication-box"
  },
  {
    uuid: "7e8b3f4e-2c14-4987-bd01-f02cd0d3faf7",
    iconSet: "MaterialCommunityIcons",
    iconName: "mushroom"
  },
  {
    uuid: "2d437233-1f4b-469b-a3cd-f55d734d146a",
    iconSet: "MaterialCommunityIcons",
    iconName: "mushroom-off"
  },
  {
    uuid: "a404ef08-0689-4311-af98-2653406189af",
    iconSet: "MaterialCommunityIcons",
    iconName: "mushroom-off-outline"
  },
  {
    uuid: "d28e2386-8f4c-47a9-898d-279cd4d24c12",
    iconSet: "MaterialCommunityIcons",
    iconName: "mushroom-outline"
  },
  {
    uuid: "955ba6f2-68c0-499c-a725-0c0cc640728b",
    iconSet: "MaterialCommunityIcons",
    iconName: "music"
  },
  {
    uuid: "a90380c3-2849-477b-ab6e-14af974171e3",
    iconSet: "MaterialCommunityIcons",
    iconName: "music-accidental-double-flat"
  },
  {
    uuid: "793242ff-7d13-429c-b708-034241a8be83",
    iconSet: "MaterialCommunityIcons",
    iconName: "music-accidental-double-sharp"
  },
  {
    uuid: "102b6dce-256b-4407-94ed-8dcd1c3677f3",
    iconSet: "MaterialCommunityIcons",
    iconName: "music-accidental-flat"
  },
  {
    uuid: "18425937-c747-48ab-87c5-57fc9d02808b",
    iconSet: "MaterialCommunityIcons",
    iconName: "music-accidental-natural"
  },
  {
    uuid: "3a0bfbbd-8492-4b44-8d80-3d28ff4df924",
    iconSet: "MaterialCommunityIcons",
    iconName: "music-accidental-sharp"
  },
  {
    uuid: "061ca438-c780-495d-b9b0-cf7edb4d255c",
    iconSet: "MaterialCommunityIcons",
    iconName: "music-box"
  },
  {
    uuid: "29561234-885e-4f11-b72b-1f3fee95222f",
    iconSet: "MaterialCommunityIcons",
    iconName: "music-box-multiple"
  },
  {
    uuid: "bed13114-2dbc-4204-acfa-8f795fe78020",
    iconSet: "MaterialCommunityIcons",
    iconName: "music-box-multiple-outline"
  },
  {
    uuid: "84b01663-f7cd-4250-90c8-0eb353bff291",
    iconSet: "MaterialCommunityIcons",
    iconName: "music-box-outline"
  },
  {
    uuid: "3e5dcff8-1e41-46e3-8ee7-12d5f2a22e47",
    iconSet: "MaterialCommunityIcons",
    iconName: "music-circle"
  },
  {
    uuid: "bd613770-cfc8-43a4-a40b-90e32c5bbcd2",
    iconSet: "MaterialCommunityIcons",
    iconName: "music-circle-outline"
  },
  {
    uuid: "13c146a5-d3fd-4ad6-9481-ffcf2f0a86db",
    iconSet: "MaterialCommunityIcons",
    iconName: "music-clef-alto"
  },
  {
    uuid: "8b075c3b-63f5-4b5f-89a3-8772c4f065de",
    iconSet: "MaterialCommunityIcons",
    iconName: "music-clef-bass"
  },
  {
    uuid: "c3db4039-c745-45eb-a9d5-94649354f271",
    iconSet: "MaterialCommunityIcons",
    iconName: "music-clef-treble"
  },
  {
    uuid: "68edb10a-a45d-486d-b126-2742e3eef9c7",
    iconSet: "MaterialCommunityIcons",
    iconName: "music-note"
  },
  {
    uuid: "d2ff112c-3c50-461a-8040-a56827223b88",
    iconSet: "MaterialCommunityIcons",
    iconName: "music-note-bluetooth"
  },
  {
    uuid: "bcfcecc8-481e-4813-91bb-7c5620123e53",
    iconSet: "MaterialCommunityIcons",
    iconName: "music-note-bluetooth-off"
  },
  {
    uuid: "61e2eacc-29f1-4f91-adbd-9c0347c50f01",
    iconSet: "MaterialCommunityIcons",
    iconName: "music-note-eighth"
  },
  {
    uuid: "f8ff5c5b-e71b-4c49-a6cf-43ea7f0062fd",
    iconSet: "MaterialCommunityIcons",
    iconName: "music-note-eighth-dotted"
  },
  {
    uuid: "10d0f194-1f8a-4f6a-bbee-051a5290f062",
    iconSet: "MaterialCommunityIcons",
    iconName: "music-note-half"
  },
  {
    uuid: "6838d8b0-6de2-4eec-a47e-444e205ef940",
    iconSet: "MaterialCommunityIcons",
    iconName: "music-note-half-dotted"
  },
  {
    uuid: "855783de-8d55-404b-bf6c-f909c975da69",
    iconSet: "MaterialCommunityIcons",
    iconName: "music-note-off"
  },
  {
    uuid: "d3bb79bd-9870-429d-92e6-80f34b1b4855",
    iconSet: "MaterialCommunityIcons",
    iconName: "music-note-off-outline"
  },
  {
    uuid: "ad4c92c1-1250-45ab-98eb-7986d12666f5",
    iconSet: "MaterialCommunityIcons",
    iconName: "music-note-outline"
  },
  {
    uuid: "335e29d4-1b7f-4789-b7f5-c3bd11bce578",
    iconSet: "MaterialCommunityIcons",
    iconName: "music-note-plus"
  },
  {
    uuid: "db21779b-6086-43f0-bd28-6d160cab3f50",
    iconSet: "MaterialCommunityIcons",
    iconName: "music-note-quarter"
  },
  {
    uuid: "e0581cc8-b193-42a2-a18b-ffa561f351d2",
    iconSet: "MaterialCommunityIcons",
    iconName: "music-note-quarter-dotted"
  },
  {
    uuid: "c4516a3b-69ac-4ba4-aa79-25b5594d3036",
    iconSet: "MaterialCommunityIcons",
    iconName: "music-note-sixteenth"
  },
  {
    uuid: "1db72042-550b-494b-97b8-eb1637c0d0cc",
    iconSet: "MaterialCommunityIcons",
    iconName: "music-note-sixteenth-dotted"
  },
  {
    uuid: "781e766a-b98e-464a-9e31-1d9df208d78b",
    iconSet: "MaterialCommunityIcons",
    iconName: "music-note-whole"
  },
  {
    uuid: "ab9c639d-bf16-4492-95ac-408002bbd25d",
    iconSet: "MaterialCommunityIcons",
    iconName: "music-note-whole-dotted"
  },
  {
    uuid: "d2bf8ed1-69cf-4c09-98cb-10fa1a4d5316",
    iconSet: "MaterialCommunityIcons",
    iconName: "music-off"
  },
  {
    uuid: "ba29c2b9-2fc4-42b2-bcff-ca0b8c8ed84f",
    iconSet: "MaterialCommunityIcons",
    iconName: "music-rest-eighth"
  },
  {
    uuid: "fde3e162-b080-4eae-ab5d-28f1e9bee7d3",
    iconSet: "MaterialCommunityIcons",
    iconName: "music-rest-half"
  },
  {
    uuid: "b4bc1e91-ad90-41be-881a-e05d3253d51e",
    iconSet: "MaterialCommunityIcons",
    iconName: "music-rest-quarter"
  },
  {
    uuid: "00fd2d50-ecb6-442d-844b-3b7940e640ac",
    iconSet: "MaterialCommunityIcons",
    iconName: "music-rest-sixteenth"
  },
  {
    uuid: "e6801810-c13f-4d44-9547-1bccb89a442a",
    iconSet: "MaterialCommunityIcons",
    iconName: "music-rest-whole"
  },
  {
    uuid: "0fd21dc6-f55c-4973-b751-630578033501",
    iconSet: "MaterialCommunityIcons",
    iconName: "nail"
  },
  {
    uuid: "04bcd8aa-cbef-4f8a-ae67-833a81c64d3f",
    iconSet: "MaterialCommunityIcons",
    iconName: "nas"
  },
  {
    uuid: "aafb64bb-4e80-4871-8212-878ba7321b6d",
    iconSet: "MaterialCommunityIcons",
    iconName: "nativescript"
  },
  {
    uuid: "05320544-54cd-4fb8-93b0-4c775e274d63",
    iconSet: "MaterialCommunityIcons",
    iconName: "nature"
  },
  {
    uuid: "66cddc68-34b2-497e-8b8b-badd2793ff34",
    iconSet: "MaterialCommunityIcons",
    iconName: "nature-people"
  },
  {
    uuid: "e4175531-1ca0-438c-aa24-5cf40adece29",
    iconSet: "MaterialCommunityIcons",
    iconName: "navigation"
  },
  {
    uuid: "45e9ef34-e63e-48f1-a880-0def0c297558",
    iconSet: "MaterialCommunityIcons",
    iconName: "near-me"
  },
  {
    uuid: "e202b40e-23a7-4295-a814-493956c45f16",
    iconSet: "MaterialCommunityIcons",
    iconName: "necklace"
  },
  {
    uuid: "88918859-bdd3-4d57-87db-ce12287f9f00",
    iconSet: "MaterialCommunityIcons",
    iconName: "needle"
  },
  {
    uuid: "f63fabaa-5b9d-4c83-81b5-2e11c71c6660",
    iconSet: "MaterialCommunityIcons",
    iconName: "netflix"
  },
  {
    uuid: "ec40bb4d-e490-4c49-85ef-eb676fe0eb96",
    iconSet: "MaterialCommunityIcons",
    iconName: "network"
  },
  {
    uuid: "04af5e72-10cd-401e-8df4-880c4abf030d",
    iconSet: "MaterialCommunityIcons",
    iconName: "network-off"
  },
  {
    uuid: "41f4e048-3477-4d3b-a864-61610278a8a2",
    iconSet: "MaterialCommunityIcons",
    iconName: "network-off-outline"
  },
  {
    uuid: "d0fa0d41-e142-4fc3-978c-8dd2abef08ea",
    iconSet: "MaterialCommunityIcons",
    iconName: "network-outline"
  },
  {
    uuid: "fe60832d-dbbc-4cba-94c5-eb3569c88646",
    iconSet: "MaterialCommunityIcons",
    iconName: "network-strength-1"
  },
  {
    uuid: "4e6c1318-aba1-4248-92e3-efd6395d6119",
    iconSet: "MaterialCommunityIcons",
    iconName: "network-strength-1-alert"
  },
  {
    uuid: "4e4490e6-eb35-443a-9879-2b7968ca3292",
    iconSet: "MaterialCommunityIcons",
    iconName: "network-strength-2"
  },
  {
    uuid: "afe13481-11f3-4436-8567-c2d01d91dd16",
    iconSet: "MaterialCommunityIcons",
    iconName: "network-strength-2-alert"
  },
  {
    uuid: "b9dcbbeb-6167-4486-9983-8c746fe749c0",
    iconSet: "MaterialCommunityIcons",
    iconName: "network-strength-3"
  },
  {
    uuid: "06edaadd-cfcd-4e92-abca-b6e326ac18a9",
    iconSet: "MaterialCommunityIcons",
    iconName: "network-strength-3-alert"
  },
  {
    uuid: "b49e438b-23bd-4554-a7b1-788545ee616e",
    iconSet: "MaterialCommunityIcons",
    iconName: "network-strength-4"
  },
  {
    uuid: "0e712101-8527-460d-a2d3-66332bb76d99",
    iconSet: "MaterialCommunityIcons",
    iconName: "network-strength-4-alert"
  },
  {
    uuid: "f3a37141-c81a-4703-aba0-6ccce12a8ba0",
    iconSet: "MaterialCommunityIcons",
    iconName: "network-strength-off"
  },
  {
    uuid: "50e770d0-cb8f-46a8-9fef-62c482c1a265",
    iconSet: "MaterialCommunityIcons",
    iconName: "network-strength-off-outline"
  },
  {
    uuid: "fe1fd87a-c14a-4ac7-83e0-42973305d9cf",
    iconSet: "MaterialCommunityIcons",
    iconName: "network-strength-outline"
  },
  {
    uuid: "021a9c15-9d3b-4765-97cc-598adc81b874",
    iconSet: "MaterialCommunityIcons",
    iconName: "new-box"
  },
  {
    uuid: "cf6c6326-820e-497f-afe4-55c82838c9ce",
    iconSet: "MaterialCommunityIcons",
    iconName: "newspaper"
  },
  {
    uuid: "00686b3c-ec39-47f1-a22b-9cba42100cd8",
    iconSet: "MaterialCommunityIcons",
    iconName: "newspaper-minus"
  },
  {
    uuid: "6659909e-8de8-4460-891e-62a3d39d7871",
    iconSet: "MaterialCommunityIcons",
    iconName: "newspaper-plus"
  },
  {
    uuid: "6bf39eed-9bc0-40f9-b601-958a77a00ff9",
    iconSet: "MaterialCommunityIcons",
    iconName: "newspaper-variant"
  },
  {
    uuid: "b43c9735-4aef-4557-934d-f22f2e83bae4",
    iconSet: "MaterialCommunityIcons",
    iconName: "newspaper-variant-multiple"
  },
  {
    uuid: "cc3686be-f40b-478e-94d5-943caa05df4c",
    iconSet: "MaterialCommunityIcons",
    iconName: "newspaper-variant-multiple-outline"
  },
  {
    uuid: "a48e002d-c709-4f22-adc0-17c83f7ff2d5",
    iconSet: "MaterialCommunityIcons",
    iconName: "newspaper-variant-outline"
  },
  {
    uuid: "60b8b220-eae5-42d7-81d4-2ef081a6d54e",
    iconSet: "MaterialCommunityIcons",
    iconName: "nfc"
  },
  {
    uuid: "cc530a81-1939-46f0-bdbf-c812719995da",
    iconSet: "MaterialCommunityIcons",
    iconName: "nfc-search-variant"
  },
  {
    uuid: "7a0177fd-0445-443a-a527-4d1ae38e5793",
    iconSet: "MaterialCommunityIcons",
    iconName: "nfc-tap"
  },
  {
    uuid: "230c20e4-cdcd-4838-9ab3-91f78ce9701e",
    iconSet: "MaterialCommunityIcons",
    iconName: "nfc-variant"
  },
  {
    uuid: "5b64743b-a661-4d65-a0a1-9b69cc812f1c",
    iconSet: "MaterialCommunityIcons",
    iconName: "nfc-variant-off"
  },
  {
    uuid: "698093a7-e1c5-4eef-a550-7c47c0851d28",
    iconSet: "MaterialCommunityIcons",
    iconName: "ninja"
  },
  {
    uuid: "df9e496a-e40c-4ea6-a1ad-63320a68bd9d",
    iconSet: "MaterialCommunityIcons",
    iconName: "nintendo-game-boy"
  },
  {
    uuid: "ecc8eea7-9254-47ba-98eb-090721dd9dd6",
    iconSet: "MaterialCommunityIcons",
    iconName: "nintendo-switch"
  },
  {
    uuid: "fd7292f7-0611-420a-91fa-8cb323a58177",
    iconSet: "MaterialCommunityIcons",
    iconName: "nintendo-wii"
  },
  {
    uuid: "2d477c60-4f25-44bb-9105-d43234551398",
    iconSet: "MaterialCommunityIcons",
    iconName: "nintendo-wiiu"
  },
  {
    uuid: "e65098f0-c89c-47b9-b98e-b4d60bf45464",
    iconSet: "MaterialCommunityIcons",
    iconName: "nix"
  },
  {
    uuid: "107d6e31-a207-439a-ae00-67cc0b9204d8",
    iconSet: "MaterialCommunityIcons",
    iconName: "nodejs"
  },
  {
    uuid: "75947399-6a35-41e0-a90f-c6fc0c885df5",
    iconSet: "MaterialCommunityIcons",
    iconName: "noodles"
  },
  {
    uuid: "52c24956-f280-4ed4-b7b3-d513bc4a9c67",
    iconSet: "MaterialCommunityIcons",
    iconName: "not-equal"
  },
  {
    uuid: "ed0c2b40-ef7c-4633-aea9-1363a21289f0",
    iconSet: "MaterialCommunityIcons",
    iconName: "not-equal-variant"
  },
  {
    uuid: "f2784121-b638-4f9a-ace0-ec073e585a46",
    iconSet: "MaterialCommunityIcons",
    iconName: "note"
  },
  {
    uuid: "db217617-673f-46a0-a914-a39470a68c14",
    iconSet: "MaterialCommunityIcons",
    iconName: "note-multiple"
  },
  {
    uuid: "fcde6bfd-3647-41ad-a134-5fefdd64e0a5",
    iconSet: "MaterialCommunityIcons",
    iconName: "note-multiple-outline"
  },
  {
    uuid: "4133f70b-bd32-4d62-a8ee-6a5d5beae31c",
    iconSet: "MaterialCommunityIcons",
    iconName: "note-outline"
  },
  {
    uuid: "51788550-fd6b-4951-a8e6-ef0575e4cadb",
    iconSet: "MaterialCommunityIcons",
    iconName: "note-plus"
  },
  {
    uuid: "0d137ba2-c212-4fd9-8ba7-d5674dd3082e",
    iconSet: "MaterialCommunityIcons",
    iconName: "note-plus-outline"
  },
  {
    uuid: "f7d8ae9e-0c6b-4589-8f83-2a87af5772ab",
    iconSet: "MaterialCommunityIcons",
    iconName: "note-text"
  },
  {
    uuid: "fabca2ce-9b81-4e10-8a75-2edc36a52196",
    iconSet: "MaterialCommunityIcons",
    iconName: "note-text-outline"
  },
  {
    uuid: "32bdb06f-d666-4f23-897a-bba5401f881b",
    iconSet: "MaterialCommunityIcons",
    iconName: "notebook"
  },
  {
    uuid: "4ad31e31-7ae5-48fd-891c-975be2c4dd36",
    iconSet: "MaterialCommunityIcons",
    iconName: "notebook-multiple"
  },
  {
    uuid: "6e7f072a-78a6-458f-881b-f282d0f6ae4b",
    iconSet: "MaterialCommunityIcons",
    iconName: "notebook-outline"
  },
  {
    uuid: "76609773-d0ef-4b4d-b366-40792283ed4b",
    iconSet: "MaterialCommunityIcons",
    iconName: "notification-clear-all"
  },
  {
    uuid: "2951077f-8ab1-427f-81a5-450f431fcda0",
    iconSet: "MaterialCommunityIcons",
    iconName: "npm"
  },
  {
    uuid: "5348b307-56fc-4312-8642-98fe75b4bd05",
    iconSet: "MaterialCommunityIcons",
    iconName: "nuke"
  },
  {
    uuid: "07d85f51-f80f-4804-a2f7-45b4773e5032",
    iconSet: "MaterialCommunityIcons",
    iconName: "null"
  },
  {
    uuid: "5bfb1d90-ad32-473a-a8f4-7febe4206ade",
    iconSet: "MaterialCommunityIcons",
    iconName: "numeric"
  },
  {
    uuid: "5d05317d-8860-4066-8447-7f510feb80d4",
    iconSet: "MaterialCommunityIcons",
    iconName: "numeric-0"
  },
  {
    uuid: "84f3d22a-1555-4b40-bb75-7a49cd784038",
    iconSet: "MaterialCommunityIcons",
    iconName: "numeric-0-box"
  },
  {
    uuid: "b0b6dabe-92f2-453b-ae91-49aca7190e74",
    iconSet: "MaterialCommunityIcons",
    iconName: "numeric-0-box-multiple"
  },
  {
    uuid: "cfba82bb-ef9a-43e2-9624-82d55a45b07e",
    iconSet: "MaterialCommunityIcons",
    iconName: "numeric-0-box-multiple-outline"
  },
  {
    uuid: "a2ccd0c7-d84a-414f-b522-c3a1ed2b1a4c",
    iconSet: "MaterialCommunityIcons",
    iconName: "numeric-0-box-outline"
  },
  {
    uuid: "25b0aaa2-38af-4f60-bee4-4b884441c1c5",
    iconSet: "MaterialCommunityIcons",
    iconName: "numeric-0-circle"
  },
  {
    uuid: "061119c2-51a7-4bea-8b9f-1c64dc75088e",
    iconSet: "MaterialCommunityIcons",
    iconName: "numeric-0-circle-outline"
  },
  {
    uuid: "b669eebc-9683-4308-a17a-e10533fbf097",
    iconSet: "MaterialCommunityIcons",
    iconName: "numeric-1"
  },
  {
    uuid: "75905855-ac36-4141-878d-f89206b80ec2",
    iconSet: "MaterialCommunityIcons",
    iconName: "numeric-1-box"
  },
  {
    uuid: "9ced795b-c98c-4790-b928-11d90ba530c6",
    iconSet: "MaterialCommunityIcons",
    iconName: "numeric-1-box-multiple"
  },
  {
    uuid: "22b5bfa6-48e7-4845-8b15-7568e45c9e98",
    iconSet: "MaterialCommunityIcons",
    iconName: "numeric-1-box-multiple-outline"
  },
  {
    uuid: "6974ebf1-0c68-49d1-b99a-c975bc34a4e7",
    iconSet: "MaterialCommunityIcons",
    iconName: "numeric-1-box-outline"
  },
  {
    uuid: "8069dd4e-f7d2-4c0b-801f-6be84f401aec",
    iconSet: "MaterialCommunityIcons",
    iconName: "numeric-1-circle"
  },
  {
    uuid: "5bd5727b-b558-4070-98af-ac31c8e64d49",
    iconSet: "MaterialCommunityIcons",
    iconName: "numeric-1-circle-outline"
  },
  {
    uuid: "dc994568-8b67-4a1a-a7d9-4ffc86480adb",
    iconSet: "MaterialCommunityIcons",
    iconName: "numeric-10"
  },
  {
    uuid: "7f835f03-61c5-4aef-b3d6-e4532e1d2a85",
    iconSet: "MaterialCommunityIcons",
    iconName: "numeric-10-box"
  },
  {
    uuid: "37498e49-9d96-4d14-ada1-115a057619c8",
    iconSet: "MaterialCommunityIcons",
    iconName: "numeric-10-box-multiple"
  },
  {
    uuid: "32930436-e4f4-4ed4-9f98-805c179401b3",
    iconSet: "MaterialCommunityIcons",
    iconName: "numeric-10-box-multiple-outline"
  },
  {
    uuid: "2240d3b0-0183-42d0-a497-fa35ec6ec4c0",
    iconSet: "MaterialCommunityIcons",
    iconName: "numeric-10-box-outline"
  },
  {
    uuid: "ea681018-4340-4bce-a8e6-b587affc2b1e",
    iconSet: "MaterialCommunityIcons",
    iconName: "numeric-10-circle"
  },
  {
    uuid: "8bb910d6-2325-4960-bb40-dc8db3fbafe2",
    iconSet: "MaterialCommunityIcons",
    iconName: "numeric-10-circle-outline"
  },
  {
    uuid: "b47a402c-6cb0-4d75-962d-34ac52c84bed",
    iconSet: "MaterialCommunityIcons",
    iconName: "numeric-2"
  },
  {
    uuid: "84d4ce5b-3949-4806-9fbc-d720a40c0a49",
    iconSet: "MaterialCommunityIcons",
    iconName: "numeric-2-box"
  },
  {
    uuid: "6fc250b0-862a-4dcf-9a23-a08bf6fe86c8",
    iconSet: "MaterialCommunityIcons",
    iconName: "numeric-2-box-multiple"
  },
  {
    uuid: "5cfde4f0-26d1-4296-9651-f8957bf571f8",
    iconSet: "MaterialCommunityIcons",
    iconName: "numeric-2-box-multiple-outline"
  },
  {
    uuid: "88a930ad-1763-4f1b-a1cd-ab59f6d5cb07",
    iconSet: "MaterialCommunityIcons",
    iconName: "numeric-2-box-outline"
  },
  {
    uuid: "93e91108-379b-42cb-9aec-21507619b593",
    iconSet: "MaterialCommunityIcons",
    iconName: "numeric-2-circle"
  },
  {
    uuid: "477947ec-608a-4c4f-9be8-1bd9f4f5d557",
    iconSet: "MaterialCommunityIcons",
    iconName: "numeric-2-circle-outline"
  },
  {
    uuid: "a9dd64c2-634e-4634-908d-635576e4fd2c",
    iconSet: "MaterialCommunityIcons",
    iconName: "numeric-3"
  },
  {
    uuid: "0a690a37-14d9-4873-97f7-064b263de52b",
    iconSet: "MaterialCommunityIcons",
    iconName: "numeric-3-box"
  },
  {
    uuid: "d61cedf6-934d-4008-8811-ace7aa2ba8aa",
    iconSet: "MaterialCommunityIcons",
    iconName: "numeric-3-box-multiple"
  },
  {
    uuid: "e949e96c-ddff-4e91-a9be-94eddfe87f6b",
    iconSet: "MaterialCommunityIcons",
    iconName: "numeric-3-box-multiple-outline"
  },
  {
    uuid: "7baca861-b116-46ad-b417-f573249c41bd",
    iconSet: "MaterialCommunityIcons",
    iconName: "numeric-3-box-outline"
  },
  {
    uuid: "b7f9a796-d5db-4ee9-9fd5-e4a35519e789",
    iconSet: "MaterialCommunityIcons",
    iconName: "numeric-3-circle"
  },
  {
    uuid: "1860d676-e163-4274-b38b-0e9b360e2f67",
    iconSet: "MaterialCommunityIcons",
    iconName: "numeric-3-circle-outline"
  },
  {
    uuid: "b3340f22-3530-4624-b688-c0c75ab60f0e",
    iconSet: "MaterialCommunityIcons",
    iconName: "numeric-4"
  },
  {
    uuid: "fdc4880e-0ca9-4c32-b759-052154c4394a",
    iconSet: "MaterialCommunityIcons",
    iconName: "numeric-4-box"
  },
  {
    uuid: "d90c0b9c-1354-4178-8308-b8df5ea85446",
    iconSet: "MaterialCommunityIcons",
    iconName: "numeric-4-box-multiple"
  },
  {
    uuid: "246d5eac-c33e-46f0-802d-d5d439297c31",
    iconSet: "MaterialCommunityIcons",
    iconName: "numeric-4-box-multiple-outline"
  },
  {
    uuid: "105fd8b6-ed3d-4dc8-aec3-a1bf3dc6e248",
    iconSet: "MaterialCommunityIcons",
    iconName: "numeric-4-box-outline"
  },
  {
    uuid: "ea2bc36e-f7bf-4f40-8431-57ad2e8cc7c4",
    iconSet: "MaterialCommunityIcons",
    iconName: "numeric-4-circle"
  },
  {
    uuid: "dc599820-8c74-49fd-8e08-ef85083eb906",
    iconSet: "MaterialCommunityIcons",
    iconName: "numeric-4-circle-outline"
  },
  {
    uuid: "41892078-4277-4614-86e4-4a15310c9a66",
    iconSet: "MaterialCommunityIcons",
    iconName: "numeric-5"
  },
  {
    uuid: "de582221-74d2-498a-989b-adfb22338560",
    iconSet: "MaterialCommunityIcons",
    iconName: "numeric-5-box"
  },
  {
    uuid: "88430349-2e7f-47a4-b98a-8c92aaa72d05",
    iconSet: "MaterialCommunityIcons",
    iconName: "numeric-5-box-multiple"
  },
  {
    uuid: "222372e4-b213-455b-8ae8-37a1e4e22e8f",
    iconSet: "MaterialCommunityIcons",
    iconName: "numeric-5-box-multiple-outline"
  },
  {
    uuid: "54547210-92ad-426a-b4a3-b228bc9afbdb",
    iconSet: "MaterialCommunityIcons",
    iconName: "numeric-5-box-outline"
  },
  {
    uuid: "a0e9a168-7482-4597-a7d5-f9842218d767",
    iconSet: "MaterialCommunityIcons",
    iconName: "numeric-5-circle"
  },
  {
    uuid: "1648b4d8-024e-464e-9cb7-71dbd92f9a1b",
    iconSet: "MaterialCommunityIcons",
    iconName: "numeric-5-circle-outline"
  },
  {
    uuid: "e1a921ce-60d9-4d34-92d1-ef35caaed4f6",
    iconSet: "MaterialCommunityIcons",
    iconName: "numeric-6"
  },
  {
    uuid: "a4da2d79-5af1-4c4e-9920-134cf637fed3",
    iconSet: "MaterialCommunityIcons",
    iconName: "numeric-6-box"
  },
  {
    uuid: "ad7a5c63-6254-44b0-b8c2-2849a9211f92",
    iconSet: "MaterialCommunityIcons",
    iconName: "numeric-6-box-multiple"
  },
  {
    uuid: "2b4c3b2a-b7c8-4298-9318-e1b10ae882d4",
    iconSet: "MaterialCommunityIcons",
    iconName: "numeric-6-box-multiple-outline"
  },
  {
    uuid: "7e00645d-da94-4730-83a7-228e483936cc",
    iconSet: "MaterialCommunityIcons",
    iconName: "numeric-6-box-outline"
  },
  {
    uuid: "9cbbbf60-225f-4269-9e30-7ace514d3082",
    iconSet: "MaterialCommunityIcons",
    iconName: "numeric-6-circle"
  },
  {
    uuid: "9a7f560d-4606-44e7-940a-81a7681cf765",
    iconSet: "MaterialCommunityIcons",
    iconName: "numeric-6-circle-outline"
  },
  {
    uuid: "65c20523-77d6-43bd-a31d-d4636bfa4f64",
    iconSet: "MaterialCommunityIcons",
    iconName: "numeric-7"
  },
  {
    uuid: "0450492d-34d1-48ae-a0c5-e10b7fe06d59",
    iconSet: "MaterialCommunityIcons",
    iconName: "numeric-7-box"
  },
  {
    uuid: "730c83b5-57e1-4c46-a369-79c473e9c30d",
    iconSet: "MaterialCommunityIcons",
    iconName: "numeric-7-box-multiple"
  },
  {
    uuid: "cea4b4a8-a478-4e1b-8c70-2e8dac7d0cd7",
    iconSet: "MaterialCommunityIcons",
    iconName: "numeric-7-box-multiple-outline"
  },
  {
    uuid: "d67055e9-d846-49e8-ab2d-d91d4f5dd70a",
    iconSet: "MaterialCommunityIcons",
    iconName: "numeric-7-box-outline"
  },
  {
    uuid: "8e324a54-e93c-4437-a683-ad8afcd234ff",
    iconSet: "MaterialCommunityIcons",
    iconName: "numeric-7-circle"
  },
  {
    uuid: "26ba1b44-87ee-4089-b03c-a83c6b9f014c",
    iconSet: "MaterialCommunityIcons",
    iconName: "numeric-7-circle-outline"
  },
  {
    uuid: "8e090254-2b24-4bf9-b9ee-42795d20e88a",
    iconSet: "MaterialCommunityIcons",
    iconName: "numeric-8"
  },
  {
    uuid: "c56939f4-f760-4ffa-8164-b15b750b9b74",
    iconSet: "MaterialCommunityIcons",
    iconName: "numeric-8-box"
  },
  {
    uuid: "c82f5363-971c-48d7-b2d3-2721ad6203a6",
    iconSet: "MaterialCommunityIcons",
    iconName: "numeric-8-box-multiple"
  },
  {
    uuid: "9c953a39-5752-4a6b-abb0-ec3bde39cbea",
    iconSet: "MaterialCommunityIcons",
    iconName: "numeric-8-box-multiple-outline"
  },
  {
    uuid: "8408fc59-ecc2-405d-963c-5a0a49b86cfd",
    iconSet: "MaterialCommunityIcons",
    iconName: "numeric-8-box-outline"
  },
  {
    uuid: "24d3b1ef-91b8-43da-b809-22c636de2e38",
    iconSet: "MaterialCommunityIcons",
    iconName: "numeric-8-circle"
  },
  {
    uuid: "f79c4d35-6b8b-4b6e-8e4a-225767e0c938",
    iconSet: "MaterialCommunityIcons",
    iconName: "numeric-8-circle-outline"
  },
  {
    uuid: "8c0b12e9-a12c-4f5a-a424-b865d4c67a3e",
    iconSet: "MaterialCommunityIcons",
    iconName: "numeric-9"
  },
  {
    uuid: "96d26e05-3013-44a9-b1ad-2e286e9eb92f",
    iconSet: "MaterialCommunityIcons",
    iconName: "numeric-9-box"
  },
  {
    uuid: "0a41fb47-7cb1-4e1a-a8f1-6740b7acbe45",
    iconSet: "MaterialCommunityIcons",
    iconName: "numeric-9-box-multiple"
  },
  {
    uuid: "ba2c2bf4-4bdf-42b6-a7bf-187fdfc6116d",
    iconSet: "MaterialCommunityIcons",
    iconName: "numeric-9-box-multiple-outline"
  },
  {
    uuid: "66798fa7-ba7a-4aff-a88b-167bac34efa9",
    iconSet: "MaterialCommunityIcons",
    iconName: "numeric-9-box-outline"
  },
  {
    uuid: "af1a6019-89d5-4546-bf66-63f9f21b414c",
    iconSet: "MaterialCommunityIcons",
    iconName: "numeric-9-circle"
  },
  {
    uuid: "3f1af073-1dc8-4d04-916d-fd673da03dcb",
    iconSet: "MaterialCommunityIcons",
    iconName: "numeric-9-circle-outline"
  },
  {
    uuid: "71ea8fa0-4882-4cea-be22-a9370b34e041",
    iconSet: "MaterialCommunityIcons",
    iconName: "numeric-9-plus"
  },
  {
    uuid: "0c562b5f-7d47-4cd8-96ec-aa5d01a31d58",
    iconSet: "MaterialCommunityIcons",
    iconName: "numeric-9-plus-box"
  },
  {
    uuid: "6385b8b9-3e23-43e7-be0a-f02aad8cf023",
    iconSet: "MaterialCommunityIcons",
    iconName: "numeric-9-plus-box-multiple"
  },
  {
    uuid: "63f3620a-555b-40ad-87aa-5c5a01b27222",
    iconSet: "MaterialCommunityIcons",
    iconName: "numeric-9-plus-box-multiple-outline"
  },
  {
    uuid: "604cc45e-4270-4a7e-b8c7-a197729ff4d1",
    iconSet: "MaterialCommunityIcons",
    iconName: "numeric-9-plus-box-outline"
  },
  {
    uuid: "341c5f30-50de-4b2b-a480-119cfb09b06c",
    iconSet: "MaterialCommunityIcons",
    iconName: "numeric-9-plus-circle"
  },
  {
    uuid: "e4320e12-90f2-4c87-b49d-b304ff5898e1",
    iconSet: "MaterialCommunityIcons",
    iconName: "numeric-9-plus-circle-outline"
  },
  {
    uuid: "690eeb96-71b9-4224-8118-45952511997d",
    iconSet: "MaterialCommunityIcons",
    iconName: "numeric-negative-1"
  },
  {
    uuid: "b1fa680c-ff41-4a4b-b5ee-9ace9b7704a5",
    iconSet: "MaterialCommunityIcons",
    iconName: "nut"
  },
  {
    uuid: "eccc052b-39b7-4a13-995b-802fd6c8c068",
    iconSet: "MaterialCommunityIcons",
    iconName: "nutrition"
  },
  {
    uuid: "070d360f-5ccf-40ea-80aa-a336b3186d73",
    iconSet: "MaterialCommunityIcons",
    iconName: "nuxt"
  },
  {
    uuid: "42d1ed96-52ca-475c-b2b3-29ae063634e6",
    iconSet: "MaterialCommunityIcons",
    iconName: "oar"
  },
  {
    uuid: "84b8f283-0588-47ce-ae82-d38e0e22d7d7",
    iconSet: "MaterialCommunityIcons",
    iconName: "ocarina"
  },
  {
    uuid: "db321c17-9121-4780-8cc1-fe9678b9976e",
    iconSet: "MaterialCommunityIcons",
    iconName: "oci"
  },
  {
    uuid: "e9ed8155-7999-433f-a543-f9ac16108e7a",
    iconSet: "MaterialCommunityIcons",
    iconName: "ocr"
  },
  {
    uuid: "245d9bc0-ffc1-4310-85a3-b63a1eb778db",
    iconSet: "MaterialCommunityIcons",
    iconName: "octagon"
  },
  {
    uuid: "49da0b0a-1665-4294-b146-f861abb65cb1",
    iconSet: "MaterialCommunityIcons",
    iconName: "octagon-outline"
  },
  {
    uuid: "0b2d0cb1-a187-418a-84a4-6902c49351ee",
    iconSet: "MaterialCommunityIcons",
    iconName: "octagram"
  },
  {
    uuid: "3df7f465-1c3f-49a8-ae8a-800ae1798498",
    iconSet: "MaterialCommunityIcons",
    iconName: "octagram-outline"
  },
  {
    uuid: "dd168922-9f80-434e-ad24-00cf42b7470b",
    iconSet: "MaterialCommunityIcons",
    iconName: "odnoklassniki"
  },
  {
    uuid: "1506923a-6066-449b-b00d-08cff8c77e8b",
    iconSet: "MaterialCommunityIcons",
    iconName: "offer"
  },
  {
    uuid: "0581d003-8aea-420d-96d4-f73e3835219d",
    iconSet: "MaterialCommunityIcons",
    iconName: "office-building"
  },
  {
    uuid: "d7120e0e-971e-4cf4-ac42-fb64f3098ceb",
    iconSet: "MaterialCommunityIcons",
    iconName: "oil"
  },
  {
    uuid: "76a8fca5-1976-433a-b8f7-1bff47e8a81d",
    iconSet: "MaterialCommunityIcons",
    iconName: "oil-lamp"
  },
  {
    uuid: "c4165102-1e5d-4333-baca-752f45090946",
    iconSet: "MaterialCommunityIcons",
    iconName: "oil-level"
  },
  {
    uuid: "03d2d1f4-fc06-4ea4-8576-d4799b6a3885",
    iconSet: "MaterialCommunityIcons",
    iconName: "oil-temperature"
  },
  {
    uuid: "9c327e5d-7e42-415a-8d12-6ef83cb6b198",
    iconSet: "MaterialCommunityIcons",
    iconName: "omega"
  },
  {
    uuid: "fe08f692-45e7-436a-8d0b-499bafbf6253",
    iconSet: "MaterialCommunityIcons",
    iconName: "one-up"
  },
  {
    uuid: "728bfd96-56a2-4817-882c-38f423dded53",
    iconSet: "MaterialCommunityIcons",
    iconName: "onepassword"
  },
  {
    uuid: "4564990f-613d-4388-82a3-b639d40831b2",
    iconSet: "MaterialCommunityIcons",
    iconName: "opacity"
  },
  {
    uuid: "b7a50235-436d-4f2f-8e83-210e1da241a2",
    iconSet: "MaterialCommunityIcons",
    iconName: "open-in-app"
  },
  {
    uuid: "37c7b76a-5fb0-4e76-8c3a-0a7f82a1edcb",
    iconSet: "MaterialCommunityIcons",
    iconName: "open-in-new"
  },
  {
    uuid: "6af900eb-63d1-461e-9c04-534a99e117aa",
    iconSet: "MaterialCommunityIcons",
    iconName: "open-source-initiative"
  },
  {
    uuid: "20629b1e-a8df-4876-95c1-6817db9345a9",
    iconSet: "MaterialCommunityIcons",
    iconName: "openid"
  },
  {
    uuid: "12eda746-0963-4dff-8ceb-fc2d48c0e803",
    iconSet: "MaterialCommunityIcons",
    iconName: "opera"
  },
  {
    uuid: "dc9b87f7-26dc-49d4-b462-b58f8c8bb474",
    iconSet: "MaterialCommunityIcons",
    iconName: "orbit"
  },
  {
    uuid: "82f08114-cfd3-4e0b-8395-81d9869667ee",
    iconSet: "MaterialCommunityIcons",
    iconName: "order-alphabetical-ascending"
  },
  {
    uuid: "d3599117-e168-4d68-ad7e-b72f5bbc6d55",
    iconSet: "MaterialCommunityIcons",
    iconName: "order-alphabetical-descending"
  },
  {
    uuid: "163f1b70-eed3-48e4-9070-f27980e4b3a2",
    iconSet: "MaterialCommunityIcons",
    iconName: "order-bool-ascending"
  },
  {
    uuid: "d48f16cf-a784-418d-a31d-6afecd6c7747",
    iconSet: "MaterialCommunityIcons",
    iconName: "order-bool-ascending-variant"
  },
  {
    uuid: "1a1bf552-f402-4667-8c4a-192fa13b4c3c",
    iconSet: "MaterialCommunityIcons",
    iconName: "order-bool-descending"
  },
  {
    uuid: "279dfe91-152b-4970-821f-6f8918f97389",
    iconSet: "MaterialCommunityIcons",
    iconName: "order-bool-descending-variant"
  },
  {
    uuid: "6b4ff8b8-9f85-400d-8c25-afb54ee64b67",
    iconSet: "MaterialCommunityIcons",
    iconName: "order-numeric-ascending"
  },
  {
    uuid: "546e0d7e-e0d2-4abc-8898-0c6fd77c378c",
    iconSet: "MaterialCommunityIcons",
    iconName: "order-numeric-descending"
  },
  {
    uuid: "8d599978-3e30-4daa-bae0-77ff60d6bc6a",
    iconSet: "MaterialCommunityIcons",
    iconName: "origin"
  },
  {
    uuid: "ee8b66ad-e861-436a-bdca-f1d64eefed94",
    iconSet: "MaterialCommunityIcons",
    iconName: "ornament"
  },
  {
    uuid: "a08ce6a3-3a89-4088-8078-4f970fcbc87d",
    iconSet: "MaterialCommunityIcons",
    iconName: "ornament-variant"
  },
  {
    uuid: "a2414194-c739-4327-8934-ae446ce91a43",
    iconSet: "MaterialCommunityIcons",
    iconName: "outdoor-lamp"
  },
  {
    uuid: "61c5742c-d69e-42b5-ac7f-dca614ffa223",
    iconSet: "MaterialCommunityIcons",
    iconName: "overscan"
  },
  {
    uuid: "be6a571e-0b0e-47c7-8343-ed7b49b1625f",
    iconSet: "MaterialCommunityIcons",
    iconName: "owl"
  },
  {
    uuid: "05ee1847-0765-4cbf-82a0-292837d920e4",
    iconSet: "MaterialCommunityIcons",
    iconName: "pac-man"
  },
  {
    uuid: "2011a275-0c09-4a66-bd8b-03945b6a3a90",
    iconSet: "MaterialCommunityIcons",
    iconName: "package"
  },
  {
    uuid: "72350e5c-2ccd-4546-98b7-31644a7aeb5c",
    iconSet: "MaterialCommunityIcons",
    iconName: "package-down"
  },
  {
    uuid: "7e97708b-2f46-4414-ad09-3286187cef93",
    iconSet: "MaterialCommunityIcons",
    iconName: "package-up"
  },
  {
    uuid: "7cf7f1c6-e25d-49e5-848d-e72f89cc8371",
    iconSet: "MaterialCommunityIcons",
    iconName: "package-variant"
  },
  {
    uuid: "f897a969-fac9-4706-a79d-09474266c2c6",
    iconSet: "MaterialCommunityIcons",
    iconName: "package-variant-closed"
  },
  {
    uuid: "c7b47f1c-c956-4c01-844b-85d0df10ff5b",
    iconSet: "MaterialCommunityIcons",
    iconName: "page-first"
  },
  {
    uuid: "8cb6731a-d111-43d3-8a32-e607e4c934aa",
    iconSet: "MaterialCommunityIcons",
    iconName: "page-last"
  },
  {
    uuid: "3b341050-48c0-4612-9d13-280f1c3551ba",
    iconSet: "MaterialCommunityIcons",
    iconName: "page-layout-body"
  },
  {
    uuid: "afe71ebf-2143-4e45-8f2e-989001f126ab",
    iconSet: "MaterialCommunityIcons",
    iconName: "page-layout-footer"
  },
  {
    uuid: "2d53f68c-34b2-4259-99f8-b008ca687aa7",
    iconSet: "MaterialCommunityIcons",
    iconName: "page-layout-header"
  },
  {
    uuid: "21dbf0c8-6d4f-4f75-86aa-165dbab1273b",
    iconSet: "MaterialCommunityIcons",
    iconName: "page-layout-header-footer"
  },
  {
    uuid: "0befb8e1-1983-4329-b3ed-ebb63103fe0f",
    iconSet: "MaterialCommunityIcons",
    iconName: "page-layout-sidebar-left"
  },
  {
    uuid: "19ab21a8-94c0-4384-b7e4-30e73f7b7972",
    iconSet: "MaterialCommunityIcons",
    iconName: "page-layout-sidebar-right"
  },
  {
    uuid: "e1ecc41f-e791-4177-962a-2c4ec7a51cfb",
    iconSet: "MaterialCommunityIcons",
    iconName: "page-next"
  },
  {
    uuid: "8b354d90-8a5a-48f1-a25a-3bb18ba6238c",
    iconSet: "MaterialCommunityIcons",
    iconName: "page-next-outline"
  },
  {
    uuid: "a160dc01-573f-428f-8286-fde78ee2d3d0",
    iconSet: "MaterialCommunityIcons",
    iconName: "page-previous"
  },
  {
    uuid: "06fc19ac-3fc6-4eb1-b16e-c6ac58810ff7",
    iconSet: "MaterialCommunityIcons",
    iconName: "page-previous-outline"
  },
  {
    uuid: "b8e4e3b3-1e3b-4ba0-b7a5-0c925fba49f8",
    iconSet: "MaterialCommunityIcons",
    iconName: "pail"
  },
  {
    uuid: "ef9e8b87-fca3-4398-afdc-7d2113255306",
    iconSet: "MaterialCommunityIcons",
    iconName: "pail-minus"
  },
  {
    uuid: "016e84ff-a8c7-4ca1-a122-f5fee0b508d0",
    iconSet: "MaterialCommunityIcons",
    iconName: "pail-minus-outline"
  },
  {
    uuid: "3f8f6f31-a0f9-45f0-aab8-29121280db8d",
    iconSet: "MaterialCommunityIcons",
    iconName: "pail-off"
  },
  {
    uuid: "528613fe-0dfa-4efa-8b3f-7a008be416bc",
    iconSet: "MaterialCommunityIcons",
    iconName: "pail-off-outline"
  },
  {
    uuid: "bd0256dd-0dee-42d2-8772-8c913cb2ef54",
    iconSet: "MaterialCommunityIcons",
    iconName: "pail-outline"
  },
  {
    uuid: "3b2edb6f-068a-4529-a3bc-65079b61059d",
    iconSet: "MaterialCommunityIcons",
    iconName: "pail-plus"
  },
  {
    uuid: "4cac4805-ad41-4c81-9646-026ad1949589",
    iconSet: "MaterialCommunityIcons",
    iconName: "pail-plus-outline"
  },
  {
    uuid: "99ae88e9-5076-4db4-8666-3e910fde1169",
    iconSet: "MaterialCommunityIcons",
    iconName: "pail-remove"
  },
  {
    uuid: "3f978dcc-2bfe-487a-8e99-f3de16d9a561",
    iconSet: "MaterialCommunityIcons",
    iconName: "pail-remove-outline"
  },
  {
    uuid: "5b03149f-e145-4aeb-9570-97d46de2b884",
    iconSet: "MaterialCommunityIcons",
    iconName: "palette"
  },
  {
    uuid: "2e1f2105-abd5-47e7-ba8a-0894c5c105b9",
    iconSet: "MaterialCommunityIcons",
    iconName: "palette-advanced"
  },
  {
    uuid: "7a048ab6-a7a1-49af-82cd-f1d0e3c39192",
    iconSet: "MaterialCommunityIcons",
    iconName: "palette-outline"
  },
  {
    uuid: "7c5f4a75-6ecc-45bb-9523-569068afb434",
    iconSet: "MaterialCommunityIcons",
    iconName: "palette-swatch"
  },
  {
    uuid: "0514c46f-e209-412d-a3ec-484256f16a30",
    iconSet: "MaterialCommunityIcons",
    iconName: "palette-swatch-outline"
  },
  {
    uuid: "5fa3cba4-cc69-4968-a147-672c2d03cfb6",
    iconSet: "MaterialCommunityIcons",
    iconName: "palm-tree"
  },
  {
    uuid: "ec80adc4-6b4c-4675-b85b-25d26b179ce7",
    iconSet: "MaterialCommunityIcons",
    iconName: "pan"
  },
  {
    uuid: "dfd690ea-c0a2-42c1-b9c1-92a558771ca3",
    iconSet: "MaterialCommunityIcons",
    iconName: "pan-bottom-left"
  },
  {
    uuid: "a34993dc-7872-4f1e-a23e-91734cff29f2",
    iconSet: "MaterialCommunityIcons",
    iconName: "pan-bottom-right"
  },
  {
    uuid: "6c9a6d0f-5144-4cb8-9915-85bd3280e5a1",
    iconSet: "MaterialCommunityIcons",
    iconName: "pan-down"
  },
  {
    uuid: "4abb5216-79f6-42c3-a5cc-74045524ca56",
    iconSet: "MaterialCommunityIcons",
    iconName: "pan-horizontal"
  },
  {
    uuid: "2a9f039a-7cf2-49aa-8f88-84257a53ab6c",
    iconSet: "MaterialCommunityIcons",
    iconName: "pan-left"
  },
  {
    uuid: "ed90b608-c031-4d4d-9046-00126792b529",
    iconSet: "MaterialCommunityIcons",
    iconName: "pan-right"
  },
  {
    uuid: "35ac908f-c987-4402-b0c9-5335c67aa8e1",
    iconSet: "MaterialCommunityIcons",
    iconName: "pan-top-left"
  },
  {
    uuid: "6e06e98f-d213-4b36-aa54-dac1ee7439a5",
    iconSet: "MaterialCommunityIcons",
    iconName: "pan-top-right"
  },
  {
    uuid: "65e36df1-48f9-40ce-84ae-e851af3341f4",
    iconSet: "MaterialCommunityIcons",
    iconName: "pan-up"
  },
  {
    uuid: "8670854f-c581-44a3-9dff-303247a308e2",
    iconSet: "MaterialCommunityIcons",
    iconName: "pan-vertical"
  },
  {
    uuid: "5f594465-8689-41b6-8e93-7b2d200de75a",
    iconSet: "MaterialCommunityIcons",
    iconName: "panda"
  },
  {
    uuid: "06c9ad4b-fe12-4fc4-9c9a-ea4c180ef4be",
    iconSet: "MaterialCommunityIcons",
    iconName: "pandora"
  },
  {
    uuid: "91e47f4f-17fa-4a3e-b295-8fe2de80f192",
    iconSet: "MaterialCommunityIcons",
    iconName: "panorama"
  },
  {
    uuid: "eeb93b47-0209-41e8-a028-0f858d8a6bdf",
    iconSet: "MaterialCommunityIcons",
    iconName: "panorama-fisheye"
  },
  {
    uuid: "2cabbc63-0439-4b64-af75-b211812e3876",
    iconSet: "MaterialCommunityIcons",
    iconName: "panorama-horizontal"
  },
  {
    uuid: "e4216a60-a4c3-4acc-bbda-fac1adcf93c5",
    iconSet: "MaterialCommunityIcons",
    iconName: "panorama-vertical"
  },
  {
    uuid: "13f619fb-5078-4c4e-b2a2-913837e85d64",
    iconSet: "MaterialCommunityIcons",
    iconName: "panorama-wide-angle"
  },
  {
    uuid: "c5a03e24-1c20-4cae-8df3-aada361e856d",
    iconSet: "MaterialCommunityIcons",
    iconName: "paper-cut-vertical"
  },
  {
    uuid: "bb66a250-b303-4a94-86c8-662d7aea277e",
    iconSet: "MaterialCommunityIcons",
    iconName: "paper-roll"
  },
  {
    uuid: "ef438166-0c08-4408-91e9-f2278267cc01",
    iconSet: "MaterialCommunityIcons",
    iconName: "paper-roll-outline"
  },
  {
    uuid: "80ca32a0-af64-4e4c-9eaa-aa9568046556",
    iconSet: "MaterialCommunityIcons",
    iconName: "paperclip"
  },
  {
    uuid: "76f35fd3-dc7c-4627-82fb-6d8dcd8ba3b7",
    iconSet: "MaterialCommunityIcons",
    iconName: "parachute"
  },
  {
    uuid: "add9029f-faa8-4f38-b546-f46889f5ed95",
    iconSet: "MaterialCommunityIcons",
    iconName: "parachute-outline"
  },
  {
    uuid: "5afd1da9-07d3-4022-98a0-e575dd58df97",
    iconSet: "MaterialCommunityIcons",
    iconName: "parking"
  },
  {
    uuid: "9a527dd6-88b0-41ed-a03e-6fa44025fa38",
    iconSet: "MaterialCommunityIcons",
    iconName: "party-popper"
  },
  {
    uuid: "4963a79b-e43d-4f0f-8eed-be649ab78e87",
    iconSet: "MaterialCommunityIcons",
    iconName: "passport"
  },
  {
    uuid: "384fc964-3ffa-4e19-87eb-978b85e2477e",
    iconSet: "MaterialCommunityIcons",
    iconName: "passport-biometric"
  },
  {
    uuid: "9b4c61bf-ab08-484e-b114-4f0121c910c6",
    iconSet: "MaterialCommunityIcons",
    iconName: "pasta"
  },
  {
    uuid: "abcdf0a5-f1dd-4009-bbfa-4c43cec7d3cc",
    iconSet: "MaterialCommunityIcons",
    iconName: "patio-heater"
  },
  {
    uuid: "8aa88b36-f729-46a4-8ade-5000e5cbdd1f",
    iconSet: "MaterialCommunityIcons",
    iconName: "patreon"
  },
  {
    uuid: "69b017c8-8590-44f6-8319-b0ec3f03a16b",
    iconSet: "MaterialCommunityIcons",
    iconName: "pause"
  },
  {
    uuid: "fcb176d1-987b-4d3e-b210-358cb6953a89",
    iconSet: "MaterialCommunityIcons",
    iconName: "pause-circle"
  },
  {
    uuid: "5ac4d39a-eaa2-4e86-8e04-08ba880d536c",
    iconSet: "MaterialCommunityIcons",
    iconName: "pause-circle-outline"
  },
  {
    uuid: "d4874832-1473-42b1-9fd4-6fa9e6a82b74",
    iconSet: "MaterialCommunityIcons",
    iconName: "pause-octagon"
  },
  {
    uuid: "59b1df8c-0cfb-4056-b58e-b609f280cf35",
    iconSet: "MaterialCommunityIcons",
    iconName: "pause-octagon-outline"
  },
  {
    uuid: "f53529b0-07fa-4fed-92df-32e7c8ec3a4b",
    iconSet: "MaterialCommunityIcons",
    iconName: "paw"
  },
  {
    uuid: "21acd491-7953-4c40-b2ad-69c5994c2f23",
    iconSet: "MaterialCommunityIcons",
    iconName: "paw-off"
  },
  {
    uuid: "0ec98896-6154-4d9b-ab99-2b77f2ba3f56",
    iconSet: "MaterialCommunityIcons",
    iconName: "pdf-box"
  },
  {
    uuid: "3cbd9bf1-3ae1-495f-b20a-7e8458326029",
    iconSet: "MaterialCommunityIcons",
    iconName: "peace"
  },
  {
    uuid: "677e9927-d07f-44f9-b696-ea2010fd215e",
    iconSet: "MaterialCommunityIcons",
    iconName: "peanut"
  },
  {
    uuid: "0a56c96b-5684-452f-a4bc-5ce064bf1b95",
    iconSet: "MaterialCommunityIcons",
    iconName: "peanut-off"
  },
  {
    uuid: "87dab79d-d7ea-45b3-8f47-c0635e9f00a5",
    iconSet: "MaterialCommunityIcons",
    iconName: "peanut-off-outline"
  },
  {
    uuid: "5f43f2bd-c556-408b-a4dc-5cf1dfd8d971",
    iconSet: "MaterialCommunityIcons",
    iconName: "peanut-outline"
  },
  {
    uuid: "0a7f5b83-0e41-421c-8a62-db776dc39781",
    iconSet: "MaterialCommunityIcons",
    iconName: "pen"
  },
  {
    uuid: "3056284a-1c2c-4a88-ba8e-74ee58a87188",
    iconSet: "MaterialCommunityIcons",
    iconName: "pen-lock"
  },
  {
    uuid: "0112e9dc-7575-4171-9e7f-6129e30b9284",
    iconSet: "MaterialCommunityIcons",
    iconName: "pen-minus"
  },
  {
    uuid: "10b517c5-85fe-4aac-85aa-e471159b04bd",
    iconSet: "MaterialCommunityIcons",
    iconName: "pen-off"
  },
  {
    uuid: "988726b9-e59b-41c7-9aeb-2a6b4149b77d",
    iconSet: "MaterialCommunityIcons",
    iconName: "pen-plus"
  },
  {
    uuid: "bce9bfa6-4f59-442f-94d5-46c80717662b",
    iconSet: "MaterialCommunityIcons",
    iconName: "pen-remove"
  },
  {
    uuid: "3a5f47a0-3774-4f38-b9ca-a432eaed354f",
    iconSet: "MaterialCommunityIcons",
    iconName: "pencil"
  },
  {
    uuid: "6fbdb8bb-247f-4945-9fcc-72d3b3168167",
    iconSet: "MaterialCommunityIcons",
    iconName: "pencil-box"
  },
  {
    uuid: "0afbb553-9cf6-43d1-9567-52124b6de586",
    iconSet: "MaterialCommunityIcons",
    iconName: "pencil-box-multiple"
  },
  {
    uuid: "6783d186-28a0-4e9f-87aa-c3f122e4cca3",
    iconSet: "MaterialCommunityIcons",
    iconName: "pencil-box-multiple-outline"
  },
  {
    uuid: "b010d79f-68db-4c7a-b7ef-a8bf64e1c226",
    iconSet: "MaterialCommunityIcons",
    iconName: "pencil-box-outline"
  },
  {
    uuid: "be135fa9-62f0-4794-a3b9-20c93f54a940",
    iconSet: "MaterialCommunityIcons",
    iconName: "pencil-circle"
  },
  {
    uuid: "3cdb8a7c-99f7-4f99-91ee-5632e8799404",
    iconSet: "MaterialCommunityIcons",
    iconName: "pencil-circle-outline"
  },
  {
    uuid: "32207200-1189-4af5-84c5-ba6f646e9ca5",
    iconSet: "MaterialCommunityIcons",
    iconName: "pencil-lock"
  },
  {
    uuid: "0c972486-cd35-443c-ad1c-1e1f34272738",
    iconSet: "MaterialCommunityIcons",
    iconName: "pencil-lock-outline"
  },
  {
    uuid: "f89192c2-cbf3-4792-9d51-ab038d4e484e",
    iconSet: "MaterialCommunityIcons",
    iconName: "pencil-minus"
  },
  {
    uuid: "f7692de1-719f-42a1-9839-e4cc601afb37",
    iconSet: "MaterialCommunityIcons",
    iconName: "pencil-minus-outline"
  },
  {
    uuid: "f88c0954-bf43-45c8-b56d-a0c65cc72e77",
    iconSet: "MaterialCommunityIcons",
    iconName: "pencil-off"
  },
  {
    uuid: "e5f1b193-4cdd-43fc-852d-9c0c4d76113a",
    iconSet: "MaterialCommunityIcons",
    iconName: "pencil-off-outline"
  },
  {
    uuid: "ac78007a-06c8-4b0d-8a04-8c6ae42846e3",
    iconSet: "MaterialCommunityIcons",
    iconName: "pencil-outline"
  },
  {
    uuid: "daafcc89-f0ea-479c-aee4-6aee96225ef1",
    iconSet: "MaterialCommunityIcons",
    iconName: "pencil-plus"
  },
  {
    uuid: "0cb68e0c-57ca-4568-8118-d217329be65b",
    iconSet: "MaterialCommunityIcons",
    iconName: "pencil-plus-outline"
  },
  {
    uuid: "1d1b3757-a7f7-42bc-994f-e94c8b76808b",
    iconSet: "MaterialCommunityIcons",
    iconName: "pencil-remove"
  },
  {
    uuid: "57d1d324-c491-4161-8386-c4ced3bcd7b5",
    iconSet: "MaterialCommunityIcons",
    iconName: "pencil-remove-outline"
  },
  {
    uuid: "9e4a4634-fb44-4c94-9508-2c5a085dce50",
    iconSet: "MaterialCommunityIcons",
    iconName: "pencil-ruler"
  },
  {
    uuid: "b32bc90b-0afc-412f-bc02-68eac35d0dfb",
    iconSet: "MaterialCommunityIcons",
    iconName: "penguin"
  },
  {
    uuid: "35efc189-7a78-4460-82a6-d8b28c3d818f",
    iconSet: "MaterialCommunityIcons",
    iconName: "pentagon"
  },
  {
    uuid: "bec06661-b012-4564-b287-e2de91d24b59",
    iconSet: "MaterialCommunityIcons",
    iconName: "pentagon-outline"
  },
  {
    uuid: "a2a1ac41-67b7-456f-a9ce-5a912bb3e9fa",
    iconSet: "MaterialCommunityIcons",
    iconName: "percent"
  },
  {
    uuid: "fa698ea8-0679-49bc-bd8c-176a4e9cf4ec",
    iconSet: "MaterialCommunityIcons",
    iconName: "percent-outline"
  },
  {
    uuid: "b4873b2b-664e-4b21-996f-b205b39a932e",
    iconSet: "MaterialCommunityIcons",
    iconName: "periodic-table"
  },
  {
    uuid: "c36e6db3-8f67-4345-803d-2b93ccae87bd",
    iconSet: "MaterialCommunityIcons",
    iconName: "perspective-less"
  },
  {
    uuid: "5745f7e0-43bc-4ffa-a330-d18b5d81e24c",
    iconSet: "MaterialCommunityIcons",
    iconName: "perspective-more"
  },
  {
    uuid: "5f0afabc-037d-425b-9616-9914ceac07af",
    iconSet: "MaterialCommunityIcons",
    iconName: "pharmacy"
  },
  {
    uuid: "19cae8ce-c76c-4422-83e9-5baf38340d4b",
    iconSet: "MaterialCommunityIcons",
    iconName: "phone"
  },
  {
    uuid: "6daf9283-f0ab-4d23-85e8-431eb0607c76",
    iconSet: "MaterialCommunityIcons",
    iconName: "phone-alert"
  },
  {
    uuid: "9c2029ae-812a-4d8a-b669-5438b84aa00e",
    iconSet: "MaterialCommunityIcons",
    iconName: "phone-alert-outline"
  },
  {
    uuid: "6387cc45-8b70-476c-af90-acad6e780dcc",
    iconSet: "MaterialCommunityIcons",
    iconName: "phone-bluetooth"
  },
  {
    uuid: "fa5eb632-ad2a-4b07-bbe2-e619fae12e29",
    iconSet: "MaterialCommunityIcons",
    iconName: "phone-bluetooth-outline"
  },
  {
    uuid: "e51c0f0c-84a3-4f3c-8fd4-bf88e6028aff",
    iconSet: "MaterialCommunityIcons",
    iconName: "phone-cancel"
  },
  {
    uuid: "72bb61e4-ae8c-4bbd-bbfd-961e93c43006",
    iconSet: "MaterialCommunityIcons",
    iconName: "phone-cancel-outline"
  },
  {
    uuid: "fc225e4a-58ac-4c9e-b242-b46fe3953466",
    iconSet: "MaterialCommunityIcons",
    iconName: "phone-check"
  },
  {
    uuid: "9f5a3cd2-90dd-40ad-b63d-9a0a0a4d23f6",
    iconSet: "MaterialCommunityIcons",
    iconName: "phone-check-outline"
  },
  {
    uuid: "320a388d-c48b-4084-a8b6-f93b2455e57b",
    iconSet: "MaterialCommunityIcons",
    iconName: "phone-classic"
  },
  {
    uuid: "2a14e59e-4cdf-4086-88b7-8130f7344281",
    iconSet: "MaterialCommunityIcons",
    iconName: "phone-classic-off"
  },
  {
    uuid: "67659fe1-2391-4bec-af58-c222b02a97c4",
    iconSet: "MaterialCommunityIcons",
    iconName: "phone-forward"
  },
  {
    uuid: "486ff4fb-4d7e-4a99-bf3e-c4353fa5ddc1",
    iconSet: "MaterialCommunityIcons",
    iconName: "phone-forward-outline"
  },
  {
    uuid: "f29ba43b-b299-4125-afd3-541df6001dc9",
    iconSet: "MaterialCommunityIcons",
    iconName: "phone-hangup"
  },
  {
    uuid: "659ba81b-ae34-4d00-9c8e-b54f1a89b867",
    iconSet: "MaterialCommunityIcons",
    iconName: "phone-hangup-outline"
  },
  {
    uuid: "5962bff7-a561-4236-95f0-c2555e25f93e",
    iconSet: "MaterialCommunityIcons",
    iconName: "phone-in-talk"
  },
  {
    uuid: "ab3c1cb2-fba2-47a9-a753-e6056400f0dd",
    iconSet: "MaterialCommunityIcons",
    iconName: "phone-in-talk-outline"
  },
  {
    uuid: "f5701fe3-fd63-4c71-b2aa-b8df0de5c876",
    iconSet: "MaterialCommunityIcons",
    iconName: "phone-incoming"
  },
  {
    uuid: "c61e7937-6f27-4c99-880b-9123546430fc",
    iconSet: "MaterialCommunityIcons",
    iconName: "phone-incoming-outline"
  },
  {
    uuid: "32b13f95-b29c-4ead-800b-4dc7308b4fa6",
    iconSet: "MaterialCommunityIcons",
    iconName: "phone-lock"
  },
  {
    uuid: "eea0b148-56d1-4d8f-b08c-032461c89b04",
    iconSet: "MaterialCommunityIcons",
    iconName: "phone-lock-outline"
  },
  {
    uuid: "25fe67c7-24db-4f23-8ff9-005dca45e4c9",
    iconSet: "MaterialCommunityIcons",
    iconName: "phone-log"
  },
  {
    uuid: "5c2a7cfd-fa45-4879-92d6-e534a287cc8d",
    iconSet: "MaterialCommunityIcons",
    iconName: "phone-log-outline"
  },
  {
    uuid: "28af2e03-605a-48a3-9dac-97cad036df67",
    iconSet: "MaterialCommunityIcons",
    iconName: "phone-message"
  },
  {
    uuid: "4883d5af-d70e-4023-9286-94f4ea69545d",
    iconSet: "MaterialCommunityIcons",
    iconName: "phone-message-outline"
  },
  {
    uuid: "3bac8f26-e4a0-4553-94ae-a2890c097285",
    iconSet: "MaterialCommunityIcons",
    iconName: "phone-minus"
  },
  {
    uuid: "80c7d8cb-3f89-4394-b29a-00230e2c94d3",
    iconSet: "MaterialCommunityIcons",
    iconName: "phone-minus-outline"
  },
  {
    uuid: "86440e5a-514a-41e1-99d8-e19f1f0e111f",
    iconSet: "MaterialCommunityIcons",
    iconName: "phone-missed"
  },
  {
    uuid: "0176ba7e-52cf-45dd-be65-64760b078ded",
    iconSet: "MaterialCommunityIcons",
    iconName: "phone-missed-outline"
  },
  {
    uuid: "116eb637-f470-43a1-a1e4-3515c1c08da6",
    iconSet: "MaterialCommunityIcons",
    iconName: "phone-off"
  },
  {
    uuid: "bc0cab75-700e-4af2-857a-021575a890be",
    iconSet: "MaterialCommunityIcons",
    iconName: "phone-off-outline"
  },
  {
    uuid: "982d7140-b1a2-418e-9eeb-e7de9f4aa4b7",
    iconSet: "MaterialCommunityIcons",
    iconName: "phone-outgoing"
  },
  {
    uuid: "9c303b81-df48-4b81-a46f-c5a9561bf7d4",
    iconSet: "MaterialCommunityIcons",
    iconName: "phone-outgoing-outline"
  },
  {
    uuid: "ea06eb7f-8259-41d3-8814-b19e8071f42f",
    iconSet: "MaterialCommunityIcons",
    iconName: "phone-outline"
  },
  {
    uuid: "fa285055-dbb9-44d6-84b6-3c5e157d068b",
    iconSet: "MaterialCommunityIcons",
    iconName: "phone-paused"
  },
  {
    uuid: "55161224-0146-42dc-b73c-7a12abd9c264",
    iconSet: "MaterialCommunityIcons",
    iconName: "phone-paused-outline"
  },
  {
    uuid: "9f8f112e-6850-4414-8f89-a5e13338acdd",
    iconSet: "MaterialCommunityIcons",
    iconName: "phone-plus"
  },
  {
    uuid: "30cdeeaf-faef-4818-81de-fcb2cf5b509e",
    iconSet: "MaterialCommunityIcons",
    iconName: "phone-plus-outline"
  },
  {
    uuid: "12a89f06-af2e-4ad9-9519-171c61d04d15",
    iconSet: "MaterialCommunityIcons",
    iconName: "phone-return"
  },
  {
    uuid: "d2fb34e7-ce2f-4c69-8e67-0023666eca8c",
    iconSet: "MaterialCommunityIcons",
    iconName: "phone-return-outline"
  },
  {
    uuid: "22dbb07b-f213-413e-b687-0f2ff60eda5b",
    iconSet: "MaterialCommunityIcons",
    iconName: "phone-ring"
  },
  {
    uuid: "f623451d-396e-4f22-9b8e-aeb09850bdd6",
    iconSet: "MaterialCommunityIcons",
    iconName: "phone-ring-outline"
  },
  {
    uuid: "9900e589-e0e9-4f2f-9a95-f5a265aa1839",
    iconSet: "MaterialCommunityIcons",
    iconName: "phone-rotate-landscape"
  },
  {
    uuid: "2d4c008f-b67f-4038-9be2-41af449cbd6c",
    iconSet: "MaterialCommunityIcons",
    iconName: "phone-rotate-portrait"
  },
  {
    uuid: "441ec3d5-72c4-4fcb-ac0a-12192518acef",
    iconSet: "MaterialCommunityIcons",
    iconName: "phone-settings"
  },
  {
    uuid: "de5ac67d-c1ca-4070-9862-d66ea4a9be65",
    iconSet: "MaterialCommunityIcons",
    iconName: "phone-settings-outline"
  },
  {
    uuid: "6a46982e-6088-4bcd-9807-a4941774badf",
    iconSet: "MaterialCommunityIcons",
    iconName: "phone-voip"
  },
  {
    uuid: "f7a98ecb-b2a1-4f5e-8ada-2a31b61f9ec6",
    iconSet: "MaterialCommunityIcons",
    iconName: "pi"
  },
  {
    uuid: "628dd1e6-9b57-4baa-a937-c4b513742fd6",
    iconSet: "MaterialCommunityIcons",
    iconName: "pi-box"
  },
  {
    uuid: "cf998180-3299-4947-9dba-47d43d2825af",
    iconSet: "MaterialCommunityIcons",
    iconName: "pi-hole"
  },
  {
    uuid: "8de561a2-5ee9-4f5e-b542-7448573dfb21",
    iconSet: "MaterialCommunityIcons",
    iconName: "piano"
  },
  {
    uuid: "14e06b24-8f55-4082-85e4-9431251840ea",
    iconSet: "MaterialCommunityIcons",
    iconName: "pickaxe"
  },
  {
    uuid: "c7c00a20-615d-4167-9db6-0f61ca4cd7ca",
    iconSet: "MaterialCommunityIcons",
    iconName: "picture-in-picture-bottom-right"
  },
  {
    uuid: "84e8e5d7-614e-4a56-8e52-f6157a446ab0",
    iconSet: "MaterialCommunityIcons",
    iconName: "picture-in-picture-bottom-right-outline"
  },
  {
    uuid: "0e9f60e2-19a0-4d30-ad2e-d7fff2516493",
    iconSet: "MaterialCommunityIcons",
    iconName: "picture-in-picture-top-right"
  },
  {
    uuid: "0ed3647d-8ed0-4517-b5db-c550a2253061",
    iconSet: "MaterialCommunityIcons",
    iconName: "picture-in-picture-top-right-outline"
  },
  {
    uuid: "9d4cca6c-1e4b-4114-b177-a113033492c7",
    iconSet: "MaterialCommunityIcons",
    iconName: "pier"
  },
  {
    uuid: "83f374d1-d42f-40ef-8364-3ca02372fe59",
    iconSet: "MaterialCommunityIcons",
    iconName: "pier-crane"
  },
  {
    uuid: "b6bac3e1-bc34-4dec-9650-662474a0e39b",
    iconSet: "MaterialCommunityIcons",
    iconName: "pig"
  },
  {
    uuid: "fc34bcf5-3a28-4d18-aef1-766934070b31",
    iconSet: "MaterialCommunityIcons",
    iconName: "pig-variant"
  },
  {
    uuid: "d4a87af8-515c-4ad5-871f-2101bfae4535",
    iconSet: "MaterialCommunityIcons",
    iconName: "piggy-bank"
  },
  {
    uuid: "569f24fa-d989-41a5-96cd-2a765acbbecd",
    iconSet: "MaterialCommunityIcons",
    iconName: "pill"
  },
  {
    uuid: "03967f13-8a6b-47c1-9bfe-3d5a18594d49",
    iconSet: "MaterialCommunityIcons",
    iconName: "pillar"
  },
  {
    uuid: "ae539ae0-a40e-4027-a15f-c83468ba9477",
    iconSet: "MaterialCommunityIcons",
    iconName: "pin"
  },
  {
    uuid: "52d59dd9-182f-4270-b152-05b4684894c8",
    iconSet: "MaterialCommunityIcons",
    iconName: "pin-off"
  },
  {
    uuid: "b3b63f00-a815-462f-9468-d26601527d65",
    iconSet: "MaterialCommunityIcons",
    iconName: "pin-off-outline"
  },
  {
    uuid: "a37d8557-598e-44d9-8a2e-9b160128d5b5",
    iconSet: "MaterialCommunityIcons",
    iconName: "pin-outline"
  },
  {
    uuid: "a1deb505-e3df-4c57-a326-ab5b772e7329",
    iconSet: "MaterialCommunityIcons",
    iconName: "pine-tree"
  },
  {
    uuid: "66e59a2b-2b1e-481f-8f9f-4001fe8304cf",
    iconSet: "MaterialCommunityIcons",
    iconName: "pine-tree-box"
  },
  {
    uuid: "18d6932d-26f2-4ac6-8da6-daa3d198e7ec",
    iconSet: "MaterialCommunityIcons",
    iconName: "pine-tree-fire"
  },
  {
    uuid: "5af19fe8-e4b7-4c21-82b1-4b19e110ad2b",
    iconSet: "MaterialCommunityIcons",
    iconName: "pinterest"
  },
  {
    uuid: "2ab71fba-fe1d-499c-9666-5faa30d81988",
    iconSet: "MaterialCommunityIcons",
    iconName: "pinwheel"
  },
  {
    uuid: "348172e2-a9af-4a5a-b79c-21f85b41db01",
    iconSet: "MaterialCommunityIcons",
    iconName: "pinwheel-outline"
  },
  {
    uuid: "4672a8ff-9215-4110-b0b0-b2b3d2a7df6d",
    iconSet: "MaterialCommunityIcons",
    iconName: "pipe"
  },
  {
    uuid: "13bdb399-632e-48a4-9643-caf4773634c9",
    iconSet: "MaterialCommunityIcons",
    iconName: "pipe-disconnected"
  },
  {
    uuid: "c7b0431f-15fc-4cc7-90fe-8809afe5ebeb",
    iconSet: "MaterialCommunityIcons",
    iconName: "pipe-leak"
  },
  {
    uuid: "f70f8b11-82ec-45c4-b3f5-216294f8b907",
    iconSet: "MaterialCommunityIcons",
    iconName: "pipe-wrench"
  },
  {
    uuid: "7c73ac97-82a6-4965-a7bd-5dbb1f945eb4",
    iconSet: "MaterialCommunityIcons",
    iconName: "pirate"
  },
  {
    uuid: "03129a7b-53d8-4b20-9b40-3c243c659ac7",
    iconSet: "MaterialCommunityIcons",
    iconName: "pistol"
  },
  {
    uuid: "df8319ac-0f01-4406-938e-c411cdc97621",
    iconSet: "MaterialCommunityIcons",
    iconName: "piston"
  },
  {
    uuid: "74653598-fd52-4db2-95b2-5c64a0f8f088",
    iconSet: "MaterialCommunityIcons",
    iconName: "pizza"
  },
  {
    uuid: "900689f0-f3ee-47d0-879e-b344759acfca",
    iconSet: "MaterialCommunityIcons",
    iconName: "play"
  },
  {
    uuid: "837343df-98b2-4336-b5ba-8618e1bd7a38",
    iconSet: "MaterialCommunityIcons",
    iconName: "play-box"
  },
  {
    uuid: "2d5684c4-9f07-4b51-a9d0-9863f6f52785",
    iconSet: "MaterialCommunityIcons",
    iconName: "play-box-multiple"
  },
  {
    uuid: "c6346db9-e777-4e41-8936-3cc862f5a440",
    iconSet: "MaterialCommunityIcons",
    iconName: "play-box-multiple-outline"
  },
  {
    uuid: "ab2ca13e-35cd-4991-a664-edc489b4e65d",
    iconSet: "MaterialCommunityIcons",
    iconName: "play-box-outline"
  },
  {
    uuid: "4748ff7a-37e0-4931-93c9-5ad4c5b64a83",
    iconSet: "MaterialCommunityIcons",
    iconName: "play-circle"
  },
  {
    uuid: "a5c5258c-cabe-45a1-8c43-6fa9c2d9d6cf",
    iconSet: "MaterialCommunityIcons",
    iconName: "play-circle-outline"
  },
  {
    uuid: "4bcb740d-1d0c-4832-b5f6-a887d3940820",
    iconSet: "MaterialCommunityIcons",
    iconName: "play-network"
  },
  {
    uuid: "96ae7bd2-f046-4ce4-b8c1-c094e9af55e4",
    iconSet: "MaterialCommunityIcons",
    iconName: "play-network-outline"
  },
  {
    uuid: "000bf94d-2b30-46e2-a867-99dbc8d2fae0",
    iconSet: "MaterialCommunityIcons",
    iconName: "play-outline"
  },
  {
    uuid: "125eb732-dfba-4707-9bdd-248897a36b10",
    iconSet: "MaterialCommunityIcons",
    iconName: "play-pause"
  },
  {
    uuid: "f04ab3e1-d7ad-4cf9-83d3-bf4736cb289b",
    iconSet: "MaterialCommunityIcons",
    iconName: "play-protected-content"
  },
  {
    uuid: "4694bbaf-96e9-459e-9d02-27bb398caa75",
    iconSet: "MaterialCommunityIcons",
    iconName: "play-speed"
  },
  {
    uuid: "165bc6ab-73f9-410f-a1c8-3bbd1a3c2ac5",
    iconSet: "MaterialCommunityIcons",
    iconName: "playlist-check"
  },
  {
    uuid: "ebf95109-30e5-4084-9d77-b9833618d215",
    iconSet: "MaterialCommunityIcons",
    iconName: "playlist-edit"
  },
  {
    uuid: "88b03d3d-b0f6-474f-89c4-15019f39e463",
    iconSet: "MaterialCommunityIcons",
    iconName: "playlist-minus"
  },
  {
    uuid: "9b6d830f-6e4d-46c6-844b-00421e49fa98",
    iconSet: "MaterialCommunityIcons",
    iconName: "playlist-music"
  },
  {
    uuid: "4389cdee-6817-4016-a0b7-127bdddbba32",
    iconSet: "MaterialCommunityIcons",
    iconName: "playlist-music-outline"
  },
  {
    uuid: "61f139ac-0d53-4d68-aacd-642f90ded482",
    iconSet: "MaterialCommunityIcons",
    iconName: "playlist-play"
  },
  {
    uuid: "93092f6c-823c-4271-bbad-a31dd9b5e11c",
    iconSet: "MaterialCommunityIcons",
    iconName: "playlist-plus"
  },
  {
    uuid: "5225b905-adf9-4298-a46b-bcee58b0afcc",
    iconSet: "MaterialCommunityIcons",
    iconName: "playlist-remove"
  },
  {
    uuid: "612e2b38-c533-49ef-9d93-10808d0bf083",
    iconSet: "MaterialCommunityIcons",
    iconName: "playlist-star"
  },
  {
    uuid: "1bb5b180-914f-436a-a44e-c48b08a11699",
    iconSet: "MaterialCommunityIcons",
    iconName: "plex"
  },
  {
    uuid: "cde42390-8051-4509-9bfb-462ff507af63",
    iconSet: "MaterialCommunityIcons",
    iconName: "plus"
  },
  {
    uuid: "b911a85c-c52f-4d61-bd7c-3f5ce47a8414",
    iconSet: "MaterialCommunityIcons",
    iconName: "plus-box"
  },
  {
    uuid: "00a71d28-df18-4369-9e24-cbadf473ba7c",
    iconSet: "MaterialCommunityIcons",
    iconName: "plus-box-multiple"
  },
  {
    uuid: "8084ed97-2d6d-4517-bda0-a9a8064477d7",
    iconSet: "MaterialCommunityIcons",
    iconName: "plus-box-multiple-outline"
  },
  {
    uuid: "c53072b9-0f03-4803-9a8a-9571c924d712",
    iconSet: "MaterialCommunityIcons",
    iconName: "plus-box-outline"
  },
  {
    uuid: "45014746-473a-4dad-b42d-8d66aae45252",
    iconSet: "MaterialCommunityIcons",
    iconName: "plus-circle"
  },
  {
    uuid: "cebff3ff-75be-4c50-8aa0-56e3bbb62808",
    iconSet: "MaterialCommunityIcons",
    iconName: "plus-circle-multiple"
  },
  {
    uuid: "31044bf0-4093-4724-ace3-7e65fe13e952",
    iconSet: "MaterialCommunityIcons",
    iconName: "plus-circle-multiple-outline"
  },
  {
    uuid: "394831f6-4d12-40c6-afb2-1e836d35c9c6",
    iconSet: "MaterialCommunityIcons",
    iconName: "plus-circle-outline"
  },
  {
    uuid: "de4bf22a-e907-49e7-8fd3-471d7f4bf72d",
    iconSet: "MaterialCommunityIcons",
    iconName: "plus-minus"
  },
  {
    uuid: "a0217808-6866-433d-ab1c-c256917419a5",
    iconSet: "MaterialCommunityIcons",
    iconName: "plus-minus-box"
  },
  {
    uuid: "2fee55fa-355b-4841-97d7-e097a5429037",
    iconSet: "MaterialCommunityIcons",
    iconName: "plus-minus-variant"
  },
  {
    uuid: "ab5257e9-3498-48cb-b8ee-dc3accb1d8bb",
    iconSet: "MaterialCommunityIcons",
    iconName: "plus-network"
  },
  {
    uuid: "58ee37af-b4e5-4a05-b0ca-e6e7453491c4",
    iconSet: "MaterialCommunityIcons",
    iconName: "plus-network-outline"
  },
  {
    uuid: "80529a30-54c9-45fe-952b-5952ae80aa12",
    iconSet: "MaterialCommunityIcons",
    iconName: "plus-one"
  },
  {
    uuid: "163c0cc5-5305-4152-a605-911038628269",
    iconSet: "MaterialCommunityIcons",
    iconName: "plus-outline"
  },
  {
    uuid: "41ed71be-38c9-42d4-9fea-3d1a895ab41f",
    iconSet: "MaterialCommunityIcons",
    iconName: "plus-thick"
  },
  {
    uuid: "fc518175-fc04-44c5-8383-80a7aed3896e",
    iconSet: "MaterialCommunityIcons",
    iconName: "podcast"
  },
  {
    uuid: "c37bef6c-77ad-431a-9168-82e1a212373c",
    iconSet: "MaterialCommunityIcons",
    iconName: "podium"
  },
  {
    uuid: "52f7dc42-9b1c-4328-bd13-27ad55c8c328",
    iconSet: "MaterialCommunityIcons",
    iconName: "podium-bronze"
  },
  {
    uuid: "275df9d2-3f27-47d7-96fa-32a12c74f547",
    iconSet: "MaterialCommunityIcons",
    iconName: "podium-gold"
  },
  {
    uuid: "9755058f-5f4e-4ae9-91a5-fe45f5c5efcd",
    iconSet: "MaterialCommunityIcons",
    iconName: "podium-silver"
  },
  {
    uuid: "00da0a03-00fd-430a-b7ad-75461214aa90",
    iconSet: "MaterialCommunityIcons",
    iconName: "point-of-sale"
  },
  {
    uuid: "7548718d-cbf1-46cb-a58d-db0abadbd03f",
    iconSet: "MaterialCommunityIcons",
    iconName: "pokeball"
  },
  {
    uuid: "44671fa3-5868-4736-b380-51d57c84d7ca",
    iconSet: "MaterialCommunityIcons",
    iconName: "pokemon-go"
  },
  {
    uuid: "4eb732b5-3616-418f-95ed-38fdaf9270ce",
    iconSet: "MaterialCommunityIcons",
    iconName: "poker-chip"
  },
  {
    uuid: "753f68cd-1182-4a5a-b400-4c40940b9952",
    iconSet: "MaterialCommunityIcons",
    iconName: "polaroid"
  },
  {
    uuid: "9b93da81-2eef-43f9-b27c-221094e7fb07",
    iconSet: "MaterialCommunityIcons",
    iconName: "police-badge"
  },
  {
    uuid: "844f2f4d-3006-4ff2-9fd4-d59a8777da97",
    iconSet: "MaterialCommunityIcons",
    iconName: "police-badge-outline"
  },
  {
    uuid: "57915166-a0bd-4e10-a7b7-fa7f5549229f",
    iconSet: "MaterialCommunityIcons",
    iconName: "poll"
  },
  {
    uuid: "2efff53a-bc79-4d99-ae20-828d37f1070e",
    iconSet: "MaterialCommunityIcons",
    iconName: "poll-box"
  },
  {
    uuid: "059650e7-0d40-4a96-986d-3e22ea02b609",
    iconSet: "MaterialCommunityIcons",
    iconName: "poll-box-outline"
  },
  {
    uuid: "5de9d0dd-a6df-4a42-a4f0-bef9c3bc8edc",
    iconSet: "MaterialCommunityIcons",
    iconName: "polo"
  },
  {
    uuid: "7b7786e3-3a36-4152-bcac-3b858b5dfa7e",
    iconSet: "MaterialCommunityIcons",
    iconName: "polymer"
  },
  {
    uuid: "35541eb5-fbef-44cc-b455-47741082ef96",
    iconSet: "MaterialCommunityIcons",
    iconName: "pool"
  },
  {
    uuid: "32665336-7fd5-42a5-a589-58c443e12d0d",
    iconSet: "MaterialCommunityIcons",
    iconName: "popcorn"
  },
  {
    uuid: "5b94c2a6-c806-4624-941c-b7b7910ae1d0",
    iconSet: "MaterialCommunityIcons",
    iconName: "post"
  },
  {
    uuid: "59352530-7a3e-48d9-86c6-0fc9b309c587",
    iconSet: "MaterialCommunityIcons",
    iconName: "post-outline"
  },
  {
    uuid: "694b2bc1-a5ba-4a44-a213-fea20dee1796",
    iconSet: "MaterialCommunityIcons",
    iconName: "postage-stamp"
  },
  {
    uuid: "f47e3cd6-d7c2-430c-9f9c-649ca218f57f",
    iconSet: "MaterialCommunityIcons",
    iconName: "pot"
  },
  {
    uuid: "126f45f2-f96b-4feb-830e-d806f7739122",
    iconSet: "MaterialCommunityIcons",
    iconName: "pot-mix"
  },
  {
    uuid: "dfe06a5e-ac60-4670-86f2-a79db4264c3b",
    iconSet: "MaterialCommunityIcons",
    iconName: "pot-mix-outline"
  },
  {
    uuid: "a830dc54-d8a5-4986-96fd-a959c485cf62",
    iconSet: "MaterialCommunityIcons",
    iconName: "pot-outline"
  },
  {
    uuid: "c81f8404-39a8-49cd-ae97-2e59fc394145",
    iconSet: "MaterialCommunityIcons",
    iconName: "pot-steam"
  },
  {
    uuid: "350f3315-8b7e-46a9-ac0b-91d0cdc2f614",
    iconSet: "MaterialCommunityIcons",
    iconName: "pot-steam-outline"
  },
  {
    uuid: "30d2d727-1ce1-499d-bedc-b5aea12d1c33",
    iconSet: "MaterialCommunityIcons",
    iconName: "pound"
  },
  {
    uuid: "c593b52c-21a7-450d-b960-323d4a6aaca3",
    iconSet: "MaterialCommunityIcons",
    iconName: "pound-box"
  },
  {
    uuid: "52cb0a89-9048-462d-951d-ced497a247da",
    iconSet: "MaterialCommunityIcons",
    iconName: "pound-box-outline"
  },
  {
    uuid: "dc4926a1-9f5f-49e6-8a1e-9c5446e338d1",
    iconSet: "MaterialCommunityIcons",
    iconName: "power"
  },
  {
    uuid: "2e21e8ec-04c7-499e-81dd-d0da418bb3d6",
    iconSet: "MaterialCommunityIcons",
    iconName: "power-cycle"
  },
  {
    uuid: "50a54b9b-81b8-4d2e-b3bd-d1808d2e3741",
    iconSet: "MaterialCommunityIcons",
    iconName: "power-off"
  },
  {
    uuid: "99fce1eb-9caa-4d59-b958-68511398b52c",
    iconSet: "MaterialCommunityIcons",
    iconName: "power-on"
  },
  {
    uuid: "5619c07a-aa2c-4b06-b15a-c9518707ac5e",
    iconSet: "MaterialCommunityIcons",
    iconName: "power-plug"
  },
  {
    uuid: "78d0f6b8-1379-44d5-88cc-4b4830044af3",
    iconSet: "MaterialCommunityIcons",
    iconName: "power-plug-off"
  },
  {
    uuid: "f6cbdc48-4176-4240-a9c8-fbee2b96f79c",
    iconSet: "MaterialCommunityIcons",
    iconName: "power-plug-off-outline"
  },
  {
    uuid: "82cbf986-c942-4be5-b666-591e558d1d9f",
    iconSet: "MaterialCommunityIcons",
    iconName: "power-plug-outline"
  },
  {
    uuid: "1ee52efc-0fc9-4816-af86-57d65633bf8f",
    iconSet: "MaterialCommunityIcons",
    iconName: "power-settings"
  },
  {
    uuid: "9a7beac5-baba-4868-9b4f-7a1514387a4b",
    iconSet: "MaterialCommunityIcons",
    iconName: "power-sleep"
  },
  {
    uuid: "51732dce-d21c-450c-abed-529939301660",
    iconSet: "MaterialCommunityIcons",
    iconName: "power-socket"
  },
  {
    uuid: "98cdc35f-e7d9-4fcf-992f-de0fb6e81bb1",
    iconSet: "MaterialCommunityIcons",
    iconName: "power-socket-au"
  },
  {
    uuid: "05c3cc0f-27cc-403d-a4fd-544f33067edc",
    iconSet: "MaterialCommunityIcons",
    iconName: "power-socket-de"
  },
  {
    uuid: "f57cdff9-30cc-4946-b195-400795b2a6c2",
    iconSet: "MaterialCommunityIcons",
    iconName: "power-socket-eu"
  },
  {
    uuid: "9a073c71-8959-4df1-ae07-ff20031933da",
    iconSet: "MaterialCommunityIcons",
    iconName: "power-socket-fr"
  },
  {
    uuid: "60a0134c-01c8-4eb4-883e-3948ad3e3ac4",
    iconSet: "MaterialCommunityIcons",
    iconName: "power-socket-jp"
  },
  {
    uuid: "8fc36ced-a3bf-49d4-98a1-48788eceafd4",
    iconSet: "MaterialCommunityIcons",
    iconName: "power-socket-uk"
  },
  {
    uuid: "18e65414-b9f1-4872-859d-522682c63654",
    iconSet: "MaterialCommunityIcons",
    iconName: "power-socket-us"
  },
  {
    uuid: "ecbfc76e-215f-4461-be3a-36ba377c5968",
    iconSet: "MaterialCommunityIcons",
    iconName: "power-standby"
  },
  {
    uuid: "7799f9a6-75bd-4d1a-bf8a-2832074da221",
    iconSet: "MaterialCommunityIcons",
    iconName: "powershell"
  },
  {
    uuid: "be240a1a-cdde-465e-a01e-d73abcbcf6e8",
    iconSet: "MaterialCommunityIcons",
    iconName: "prescription"
  },
  {
    uuid: "de0a5e91-d8a4-4458-9152-6c6e4a7a47f8",
    iconSet: "MaterialCommunityIcons",
    iconName: "presentation"
  },
  {
    uuid: "abffc87e-7328-47a1-9993-651c37deac99",
    iconSet: "MaterialCommunityIcons",
    iconName: "presentation-play"
  },
  {
    uuid: "9ae28397-b973-4008-9fb6-58b9e76951d3",
    iconSet: "MaterialCommunityIcons",
    iconName: "printer"
  },
  {
    uuid: "fa2c9e67-3204-477d-b13e-1e719a8b9ab1",
    iconSet: "MaterialCommunityIcons",
    iconName: "printer-3d"
  },
  {
    uuid: "e1cb3784-d05e-441e-abf9-59136dd4833d",
    iconSet: "MaterialCommunityIcons",
    iconName: "printer-3d-nozzle"
  },
  {
    uuid: "2110b57d-7ded-4191-8be8-95e09c9096e3",
    iconSet: "MaterialCommunityIcons",
    iconName: "printer-3d-nozzle-alert"
  },
  {
    uuid: "d05ed34e-2b71-4098-9819-9acb519b493f",
    iconSet: "MaterialCommunityIcons",
    iconName: "printer-3d-nozzle-alert-outline"
  },
  {
    uuid: "93896c77-8335-46eb-912a-5c113b1acc3c",
    iconSet: "MaterialCommunityIcons",
    iconName: "printer-3d-nozzle-outline"
  },
  {
    uuid: "42a4f776-8a42-4628-8366-9e578a161178",
    iconSet: "MaterialCommunityIcons",
    iconName: "printer-alert"
  },
  {
    uuid: "1fb4a9ec-0728-4b11-a150-228fac370361",
    iconSet: "MaterialCommunityIcons",
    iconName: "printer-check"
  },
  {
    uuid: "7bbeff2e-b1f7-4694-a29d-167d52f0aa67",
    iconSet: "MaterialCommunityIcons",
    iconName: "printer-eye"
  },
  {
    uuid: "f9b941f2-1387-4de8-ad4d-b6b83c8fa1f2",
    iconSet: "MaterialCommunityIcons",
    iconName: "printer-off"
  },
  {
    uuid: "1c8efe26-fa01-4541-976e-a42f42bd423b",
    iconSet: "MaterialCommunityIcons",
    iconName: "printer-pos"
  },
  {
    uuid: "cd74bbbe-2139-4121-a767-a3add062449e",
    iconSet: "MaterialCommunityIcons",
    iconName: "printer-search"
  },
  {
    uuid: "c6bd3e15-323c-4619-a6c5-126b2427a714",
    iconSet: "MaterialCommunityIcons",
    iconName: "printer-settings"
  },
  {
    uuid: "d7d19496-7eaf-4554-a535-6d47a7d1f223",
    iconSet: "MaterialCommunityIcons",
    iconName: "printer-wireless"
  },
  {
    uuid: "96e889d3-0837-415c-966e-0d5bab4f2f13",
    iconSet: "MaterialCommunityIcons",
    iconName: "priority-high"
  },
  {
    uuid: "7417c2d1-9c51-4fb1-9332-17a4f6843385",
    iconSet: "MaterialCommunityIcons",
    iconName: "priority-low"
  },
  {
    uuid: "7ffe1045-ff8b-4210-8f4b-a26b29005123",
    iconSet: "MaterialCommunityIcons",
    iconName: "professional-hexagon"
  },
  {
    uuid: "58a0c628-3bc6-4743-a6b7-a6ef19a3dea8",
    iconSet: "MaterialCommunityIcons",
    iconName: "progress-alert"
  },
  {
    uuid: "18c48aa5-ad95-456b-9bc5-62d308c93417",
    iconSet: "MaterialCommunityIcons",
    iconName: "progress-check"
  },
  {
    uuid: "98cae3dc-1f45-47d0-9440-7ddd1003b523",
    iconSet: "MaterialCommunityIcons",
    iconName: "progress-clock"
  },
  {
    uuid: "5f002036-ae76-42f6-bdbf-2ba57179aab8",
    iconSet: "MaterialCommunityIcons",
    iconName: "progress-close"
  },
  {
    uuid: "d683f8be-709b-4200-b087-915154cdd565",
    iconSet: "MaterialCommunityIcons",
    iconName: "progress-download"
  },
  {
    uuid: "546daa7d-3bef-40a5-a388-e072a5f6ad4c",
    iconSet: "MaterialCommunityIcons",
    iconName: "progress-upload"
  },
  {
    uuid: "15e0dff4-94a1-4e29-a55c-1454b39707d8",
    iconSet: "MaterialCommunityIcons",
    iconName: "progress-wrench"
  },
  {
    uuid: "dee107ef-21ef-43f4-b405-c779e12f4c1d",
    iconSet: "MaterialCommunityIcons",
    iconName: "projector"
  },
  {
    uuid: "4e7a332e-7ba4-4fd4-bf42-18689399c509",
    iconSet: "MaterialCommunityIcons",
    iconName: "projector-screen"
  },
  {
    uuid: "935c1803-a21b-48ba-ad8e-0c1d9fc06fc6",
    iconSet: "MaterialCommunityIcons",
    iconName: "propane-tank"
  },
  {
    uuid: "dfb704e5-bc09-40de-82a1-982587cca388",
    iconSet: "MaterialCommunityIcons",
    iconName: "propane-tank-outline"
  },
  {
    uuid: "0ea60269-308f-466d-b6ca-636139913af4",
    iconSet: "MaterialCommunityIcons",
    iconName: "protocol"
  },
  {
    uuid: "ae2538bb-d619-48ae-a6c6-6a40ee20fbe0",
    iconSet: "MaterialCommunityIcons",
    iconName: "publish"
  },
  {
    uuid: "c5bbf614-e0ac-48bd-80ca-5ce0128ef07f",
    iconSet: "MaterialCommunityIcons",
    iconName: "pulse"
  },
  {
    uuid: "8ce44218-bdf9-416f-b5b9-0022d13aaf95",
    iconSet: "MaterialCommunityIcons",
    iconName: "pump"
  },
  {
    uuid: "87e58d6d-b624-4ffe-8c00-d905edf86c4f",
    iconSet: "MaterialCommunityIcons",
    iconName: "pumpkin"
  },
  {
    uuid: "55d316ab-fbe2-4ef7-a934-34734e7a6f29",
    iconSet: "MaterialCommunityIcons",
    iconName: "purse"
  },
  {
    uuid: "da030620-1598-465f-8662-36c0107e3030",
    iconSet: "MaterialCommunityIcons",
    iconName: "purse-outline"
  },
  {
    uuid: "606d2fde-26f5-4c19-82f9-c8f6d14b0649",
    iconSet: "MaterialCommunityIcons",
    iconName: "puzzle"
  },
  {
    uuid: "bb9795ee-59b9-4468-ad2f-8a10d9e73bb2",
    iconSet: "MaterialCommunityIcons",
    iconName: "puzzle-check"
  },
  {
    uuid: "8aeea1e0-1983-4fa4-bf3a-d6990de2e770",
    iconSet: "MaterialCommunityIcons",
    iconName: "puzzle-check-outline"
  },
  {
    uuid: "47c3a5a2-5d81-4e5e-9814-4e572d25a57b",
    iconSet: "MaterialCommunityIcons",
    iconName: "puzzle-edit"
  },
  {
    uuid: "60a16d09-7853-4332-ae1e-59e275333b21",
    iconSet: "MaterialCommunityIcons",
    iconName: "puzzle-edit-outline"
  },
  {
    uuid: "9eb479b8-84c4-4725-8495-0a547705e5d8",
    iconSet: "MaterialCommunityIcons",
    iconName: "puzzle-heart"
  },
  {
    uuid: "13352412-0b68-4349-9ebd-a7de43091c70",
    iconSet: "MaterialCommunityIcons",
    iconName: "puzzle-heart-outline"
  },
  {
    uuid: "b40b84e8-a293-4692-81fa-938bf90a396a",
    iconSet: "MaterialCommunityIcons",
    iconName: "puzzle-minus"
  },
  {
    uuid: "94eec13e-12ba-4a69-92ea-ab2a303c47fc",
    iconSet: "MaterialCommunityIcons",
    iconName: "puzzle-minus-outline"
  },
  {
    uuid: "fd388477-a093-44c0-b307-544048f4ea08",
    iconSet: "MaterialCommunityIcons",
    iconName: "puzzle-outline"
  },
  {
    uuid: "d68dc269-bc06-4ca4-9310-56c6e2fba7fe",
    iconSet: "MaterialCommunityIcons",
    iconName: "puzzle-plus"
  },
  {
    uuid: "77839310-c9bd-4551-94f6-f305ea2e6d60",
    iconSet: "MaterialCommunityIcons",
    iconName: "puzzle-plus-outline"
  },
  {
    uuid: "a442bbe8-ba91-4649-9025-fd457809615c",
    iconSet: "MaterialCommunityIcons",
    iconName: "puzzle-remove"
  },
  {
    uuid: "f743f6cc-3f28-44eb-8b1a-1ac6ab486763",
    iconSet: "MaterialCommunityIcons",
    iconName: "puzzle-remove-outline"
  },
  {
    uuid: "a007c6d4-4b2d-42e2-8a94-f4f041051da6",
    iconSet: "MaterialCommunityIcons",
    iconName: "puzzle-star"
  },
  {
    uuid: "0fb61d80-c064-49f8-8ead-220afcd439ee",
    iconSet: "MaterialCommunityIcons",
    iconName: "puzzle-star-outline"
  },
  {
    uuid: "dbc75128-fd82-4617-8ae2-9aa7927eaaa7",
    iconSet: "MaterialCommunityIcons",
    iconName: "qi"
  },
  {
    uuid: "8df719fe-3c9b-48de-9f00-21d0cac96cd0",
    iconSet: "MaterialCommunityIcons",
    iconName: "qqchat"
  },
  {
    uuid: "c3e9dbaf-3c10-49a2-9b51-d4f5f8471f40",
    iconSet: "MaterialCommunityIcons",
    iconName: "qrcode"
  },
  {
    uuid: "1fe5dd01-fb80-4ca9-9b5f-f28d0d76f544",
    iconSet: "MaterialCommunityIcons",
    iconName: "qrcode-edit"
  },
  {
    uuid: "f8307b97-a17f-4a3b-9b4f-c28112fa373d",
    iconSet: "MaterialCommunityIcons",
    iconName: "qrcode-minus"
  },
  {
    uuid: "b55df50b-17a7-4087-ab77-eb3c5c220fa7",
    iconSet: "MaterialCommunityIcons",
    iconName: "qrcode-plus"
  },
  {
    uuid: "8654c245-e542-4dcb-aa10-cc79df0ce23a",
    iconSet: "MaterialCommunityIcons",
    iconName: "qrcode-remove"
  },
  {
    uuid: "61e3c3c2-073b-4395-924d-62000d27081c",
    iconSet: "MaterialCommunityIcons",
    iconName: "qrcode-scan"
  },
  {
    uuid: "312e4376-4870-4931-a264-5f6087bb9cad",
    iconSet: "MaterialCommunityIcons",
    iconName: "quadcopter"
  },
  {
    uuid: "52be5270-c28c-4485-86dd-adc48be49bb2",
    iconSet: "MaterialCommunityIcons",
    iconName: "quality-high"
  },
  {
    uuid: "318eb2a0-9535-497f-9844-a626997ae31f",
    iconSet: "MaterialCommunityIcons",
    iconName: "quality-low"
  },
  {
    uuid: "7e1584ee-43d1-43d7-95ac-b50731fffce6",
    iconSet: "MaterialCommunityIcons",
    iconName: "quality-medium"
  },
  {
    uuid: "7f56c766-0306-4194-a91d-0cc8cb0f3ddc",
    iconSet: "MaterialCommunityIcons",
    iconName: "quora"
  },
  {
    uuid: "a6727fae-9fca-4c11-b5b6-007ef4c8fa36",
    iconSet: "MaterialCommunityIcons",
    iconName: "rabbit"
  },
  {
    uuid: "fa5646b3-a68c-4a92-9c7b-a552e0aa53d1",
    iconSet: "MaterialCommunityIcons",
    iconName: "racing-helmet"
  },
  {
    uuid: "e2116eba-e161-42c2-966a-dbb7554039c0",
    iconSet: "MaterialCommunityIcons",
    iconName: "racquetball"
  },
  {
    uuid: "c0e5a0bf-8384-4afd-b740-c8380702c1cf",
    iconSet: "MaterialCommunityIcons",
    iconName: "radar"
  },
  {
    uuid: "1958cdfb-2cb2-4c04-93eb-e229fdb39f50",
    iconSet: "MaterialCommunityIcons",
    iconName: "radiator"
  },
  {
    uuid: "b9465d63-aa13-4f67-866b-257719dd5b44",
    iconSet: "MaterialCommunityIcons",
    iconName: "radiator-disabled"
  },
  {
    uuid: "776753e0-520a-48ba-98f1-82538b80cd12",
    iconSet: "MaterialCommunityIcons",
    iconName: "radiator-off"
  },
  {
    uuid: "096fa8b4-5aba-4f1c-a272-e27c500b3073",
    iconSet: "MaterialCommunityIcons",
    iconName: "radio"
  },
  {
    uuid: "793c792e-170f-4c8a-a0ee-e864021f9654",
    iconSet: "MaterialCommunityIcons",
    iconName: "radio-am"
  },
  {
    uuid: "1939bfd9-e93d-401b-8bb5-aa5bd639f225",
    iconSet: "MaterialCommunityIcons",
    iconName: "radio-fm"
  },
  {
    uuid: "f6f7a792-3373-41a4-9d9e-e04866cb15bf",
    iconSet: "MaterialCommunityIcons",
    iconName: "radio-handheld"
  },
  {
    uuid: "170bc631-d2ce-4128-8c6c-740f224b2401",
    iconSet: "MaterialCommunityIcons",
    iconName: "radio-off"
  },
  {
    uuid: "80c82ff1-a0eb-4c69-ac34-92d75c66737d",
    iconSet: "MaterialCommunityIcons",
    iconName: "radio-tower"
  },
  {
    uuid: "99fde925-b207-4a69-baad-82af8a3c0375",
    iconSet: "MaterialCommunityIcons",
    iconName: "radioactive"
  },
  {
    uuid: "b320bb05-928a-49bb-9123-0dce98966c85",
    iconSet: "MaterialCommunityIcons",
    iconName: "radioactive-off"
  },
  {
    uuid: "3c0851fa-3bac-4ffe-84dc-20426dde3d0d",
    iconSet: "MaterialCommunityIcons",
    iconName: "radiobox-blank"
  },
  {
    uuid: "be4c5dd1-2573-42e7-946f-d2f60d058499",
    iconSet: "MaterialCommunityIcons",
    iconName: "radiobox-marked"
  },
  {
    uuid: "fddf80e6-7f95-4915-8598-6fc903249491",
    iconSet: "MaterialCommunityIcons",
    iconName: "radiology-box"
  },
  {
    uuid: "336b1b18-bded-4ab0-adca-9a982f03771e",
    iconSet: "MaterialCommunityIcons",
    iconName: "radiology-box-outline"
  },
  {
    uuid: "515566df-b8ad-4255-965b-a0bc24ffad12",
    iconSet: "MaterialCommunityIcons",
    iconName: "radius"
  },
  {
    uuid: "62f363c4-9d4d-4654-add5-d5a9dbc04ab0",
    iconSet: "MaterialCommunityIcons",
    iconName: "radius-outline"
  },
  {
    uuid: "91d373ed-5660-4445-b793-9f9f282b2060",
    iconSet: "MaterialCommunityIcons",
    iconName: "railroad-light"
  },
  {
    uuid: "49874409-b4bd-496b-a78f-2c2156ed1c25",
    iconSet: "MaterialCommunityIcons",
    iconName: "raspberry-pi"
  },
  {
    uuid: "f91b5638-3e8c-4ffc-bedf-12365821a4aa",
    iconSet: "MaterialCommunityIcons",
    iconName: "ray-end"
  },
  {
    uuid: "56710166-cd6b-4dd3-b641-142d8d92e70a",
    iconSet: "MaterialCommunityIcons",
    iconName: "ray-end-arrow"
  },
  {
    uuid: "0a17d762-ffc3-4600-a950-13b1ab40431e",
    iconSet: "MaterialCommunityIcons",
    iconName: "ray-start"
  },
  {
    uuid: "bef2ff1c-df35-4556-aa7e-449757d1ac56",
    iconSet: "MaterialCommunityIcons",
    iconName: "ray-start-arrow"
  },
  {
    uuid: "90e4ab8d-1289-42cc-9c7c-b61edd818d2a",
    iconSet: "MaterialCommunityIcons",
    iconName: "ray-start-end"
  },
  {
    uuid: "5329e3d4-9763-4fcd-b7ae-b47ec83233b4",
    iconSet: "MaterialCommunityIcons",
    iconName: "ray-vertex"
  },
  {
    uuid: "74d431c2-84f8-4009-8d48-2ce63c619fcb",
    iconSet: "MaterialCommunityIcons",
    iconName: "react"
  },
  {
    uuid: "e96dab1d-4684-4f77-a899-a83ac303cb62",
    iconSet: "MaterialCommunityIcons",
    iconName: "read"
  },
  {
    uuid: "dfac3e25-6521-4108-95f2-553d97ebffd3",
    iconSet: "MaterialCommunityIcons",
    iconName: "receipt"
  },
  {
    uuid: "5d28be21-f8a3-41eb-b53b-f1b172364efa",
    iconSet: "MaterialCommunityIcons",
    iconName: "record"
  },
  {
    uuid: "4df77c23-ad5e-4239-b10d-ffa3a21f1de6",
    iconSet: "MaterialCommunityIcons",
    iconName: "record-circle"
  },
  {
    uuid: "0403ced7-6faf-4e6a-90a7-fdbf31241c8d",
    iconSet: "MaterialCommunityIcons",
    iconName: "record-circle-outline"
  },
  {
    uuid: "ef5ea254-c5a4-448a-b452-25968106bbbe",
    iconSet: "MaterialCommunityIcons",
    iconName: "record-player"
  },
  {
    uuid: "4f980685-2ab6-4154-9c76-63034cd6b43b",
    iconSet: "MaterialCommunityIcons",
    iconName: "record-rec"
  },
  {
    uuid: "cb384923-ed3c-426c-8535-f1065ac457f3",
    iconSet: "MaterialCommunityIcons",
    iconName: "rectangle"
  },
  {
    uuid: "1ace5e92-1437-4061-b951-82eabfb4d252",
    iconSet: "MaterialCommunityIcons",
    iconName: "rectangle-outline"
  },
  {
    uuid: "7aaa0154-9f08-46f2-856d-29c2e3d3abdb",
    iconSet: "MaterialCommunityIcons",
    iconName: "recycle"
  },
  {
    uuid: "1072e248-24e5-443f-9f8c-84bf692e171a",
    iconSet: "MaterialCommunityIcons",
    iconName: "recycle-variant"
  },
  {
    uuid: "570c9640-b61f-4bfc-a9e2-503bda37e568",
    iconSet: "MaterialCommunityIcons",
    iconName: "reddit"
  },
  {
    uuid: "1f366731-1fce-40ac-ab04-6e2523d8bd05",
    iconSet: "MaterialCommunityIcons",
    iconName: "redhat"
  },
  {
    uuid: "707e6389-32eb-4692-b65e-04e3bced06ea",
    iconSet: "MaterialCommunityIcons",
    iconName: "redo"
  },
  {
    uuid: "a1af8441-f352-4451-a3f3-44e1f27ac53c",
    iconSet: "MaterialCommunityIcons",
    iconName: "redo-variant"
  },
  {
    uuid: "6b7e9cac-58c6-406d-8055-9bbd9d45ed55",
    iconSet: "MaterialCommunityIcons",
    iconName: "reflect-horizontal"
  },
  {
    uuid: "e1ea6565-f86a-452a-9270-ec671fda18e6",
    iconSet: "MaterialCommunityIcons",
    iconName: "reflect-vertical"
  },
  {
    uuid: "57647949-c487-420b-8773-3a75990a1ae2",
    iconSet: "MaterialCommunityIcons",
    iconName: "refresh"
  },
  {
    uuid: "4bbf0dbd-02fe-4701-aa60-95c3a5ee4ca3",
    iconSet: "MaterialCommunityIcons",
    iconName: "refresh-circle"
  },
  {
    uuid: "b2ec856a-21f6-4fe7-8bf7-21d8c4cd5f35",
    iconSet: "MaterialCommunityIcons",
    iconName: "regex"
  },
  {
    uuid: "da63a731-3c97-4f50-a7d1-4233a81a2aab",
    iconSet: "MaterialCommunityIcons",
    iconName: "registered-trademark"
  },
  {
    uuid: "3bf4f7bd-1fb5-4ebe-9916-3611839354f5",
    iconSet: "MaterialCommunityIcons",
    iconName: "relation-many-to-many"
  },
  {
    uuid: "3d3d6e7a-af22-4cff-9445-b9e8d74ebbf8",
    iconSet: "MaterialCommunityIcons",
    iconName: "relation-many-to-one"
  },
  {
    uuid: "35ddf9ea-13d0-4bf5-85cd-cd0ba3597286",
    iconSet: "MaterialCommunityIcons",
    iconName: "relation-many-to-one-or-many"
  },
  {
    uuid: "2cf18df7-83ba-43f5-98bd-70a51121434e",
    iconSet: "MaterialCommunityIcons",
    iconName: "relation-many-to-only-one"
  },
  {
    uuid: "b5bfa5b3-2646-44c0-a3d9-6ef85d31e361",
    iconSet: "MaterialCommunityIcons",
    iconName: "relation-many-to-zero-or-many"
  },
  {
    uuid: "54f696d9-5e26-4baf-add5-582256fd05cc",
    iconSet: "MaterialCommunityIcons",
    iconName: "relation-many-to-zero-or-one"
  },
  {
    uuid: "5a228cc3-9ffd-4819-ba4b-5159e610acb4",
    iconSet: "MaterialCommunityIcons",
    iconName: "relation-one-or-many-to-many"
  },
  {
    uuid: "773137e5-0c29-42c0-9b1a-e944f9084253",
    iconSet: "MaterialCommunityIcons",
    iconName: "relation-one-or-many-to-one"
  },
  {
    uuid: "3f011881-e0d8-4e79-b5e8-bdc94c29ec3b",
    iconSet: "MaterialCommunityIcons",
    iconName: "relation-one-or-many-to-one-or-many"
  },
  {
    uuid: "1e0c9ae9-6780-4af8-ae34-b4d69815bd59",
    iconSet: "MaterialCommunityIcons",
    iconName: "relation-one-or-many-to-only-one"
  },
  {
    uuid: "89f8b120-09a1-44d3-9683-0e23f910d104",
    iconSet: "MaterialCommunityIcons",
    iconName: "relation-one-or-many-to-zero-or-many"
  },
  {
    uuid: "c5853243-7d73-423c-992f-0e2ed85dcf5e",
    iconSet: "MaterialCommunityIcons",
    iconName: "relation-one-or-many-to-zero-or-one"
  },
  {
    uuid: "89db7dda-70a0-423e-955c-2c89bf8bfa2a",
    iconSet: "MaterialCommunityIcons",
    iconName: "relation-one-to-many"
  },
  {
    uuid: "9b94bff1-796c-49c3-860d-1771083fee17",
    iconSet: "MaterialCommunityIcons",
    iconName: "relation-one-to-one"
  },
  {
    uuid: "42d0f188-c03f-42a4-9cae-f38778e5a186",
    iconSet: "MaterialCommunityIcons",
    iconName: "relation-one-to-one-or-many"
  },
  {
    uuid: "05e10b88-6d2b-4d05-8308-13fe77805d30",
    iconSet: "MaterialCommunityIcons",
    iconName: "relation-one-to-only-one"
  },
  {
    uuid: "7e6d234a-ca29-46b3-a4fc-0d6fe1a05814",
    iconSet: "MaterialCommunityIcons",
    iconName: "relation-one-to-zero-or-many"
  },
  {
    uuid: "d752d7f3-ba93-436e-8912-1eae54db6f94",
    iconSet: "MaterialCommunityIcons",
    iconName: "relation-one-to-zero-or-one"
  },
  {
    uuid: "d1a36f72-a783-401e-9f29-2f2fd6fa6d58",
    iconSet: "MaterialCommunityIcons",
    iconName: "relation-only-one-to-many"
  },
  {
    uuid: "04976c9f-0b73-4243-9200-5dd084dadcab",
    iconSet: "MaterialCommunityIcons",
    iconName: "relation-only-one-to-one"
  },
  {
    uuid: "d8d9a414-2337-4c1b-8fc8-1e8c784872df",
    iconSet: "MaterialCommunityIcons",
    iconName: "relation-only-one-to-one-or-many"
  },
  {
    uuid: "59bda915-d719-4730-bea0-c28da48ef577",
    iconSet: "MaterialCommunityIcons",
    iconName: "relation-only-one-to-only-one"
  },
  {
    uuid: "54b3a930-8c0c-4db3-9370-4c45dc384cae",
    iconSet: "MaterialCommunityIcons",
    iconName: "relation-only-one-to-zero-or-many"
  },
  {
    uuid: "bf3924ec-afb6-4c01-bbdf-5ddca3f6372c",
    iconSet: "MaterialCommunityIcons",
    iconName: "relation-only-one-to-zero-or-one"
  },
  {
    uuid: "d0e1be54-5170-4d33-b2fa-0c7c84912cf2",
    iconSet: "MaterialCommunityIcons",
    iconName: "relation-zero-or-many-to-many"
  },
  {
    uuid: "acebffd2-55ff-4da0-87ff-0a9273ce38a2",
    iconSet: "MaterialCommunityIcons",
    iconName: "relation-zero-or-many-to-one"
  },
  {
    uuid: "fb1e761a-0a8d-4e3f-9f08-aac0cf26ca9a",
    iconSet: "MaterialCommunityIcons",
    iconName: "relation-zero-or-many-to-one-or-many"
  },
  {
    uuid: "e91c0a7b-d5c7-4b3e-ac68-ff4d69bbcfe5",
    iconSet: "MaterialCommunityIcons",
    iconName: "relation-zero-or-many-to-only-one"
  },
  {
    uuid: "58796ed1-4bd3-42d5-a891-0a3710e23aad",
    iconSet: "MaterialCommunityIcons",
    iconName: "relation-zero-or-many-to-zero-or-many"
  },
  {
    uuid: "fc80e022-cbc5-4c4d-9e24-f1718baddec2",
    iconSet: "MaterialCommunityIcons",
    iconName: "relation-zero-or-many-to-zero-or-one"
  },
  {
    uuid: "c441a95f-74ed-4fc7-ae4c-228aa4f1c9ba",
    iconSet: "MaterialCommunityIcons",
    iconName: "relation-zero-or-one-to-many"
  },
  {
    uuid: "53dde508-e7fd-4bd9-be7c-6ce59eef2c91",
    iconSet: "MaterialCommunityIcons",
    iconName: "relation-zero-or-one-to-one"
  },
  {
    uuid: "0cb2642f-bce5-404e-9889-aaee85737a07",
    iconSet: "MaterialCommunityIcons",
    iconName: "relation-zero-or-one-to-one-or-many"
  },
  {
    uuid: "f04dacb7-15c1-4509-a320-a708ae76f2fb",
    iconSet: "MaterialCommunityIcons",
    iconName: "relation-zero-or-one-to-only-one"
  },
  {
    uuid: "5b093440-7728-4cc2-bfea-6671cb01d092",
    iconSet: "MaterialCommunityIcons",
    iconName: "relation-zero-or-one-to-zero-or-many"
  },
  {
    uuid: "ee9f9fef-02a0-42a1-9d1c-6df971336820",
    iconSet: "MaterialCommunityIcons",
    iconName: "relation-zero-or-one-to-zero-or-one"
  },
  {
    uuid: "22295d10-fe00-4c53-ac4a-2e15c399df48",
    iconSet: "MaterialCommunityIcons",
    iconName: "relative-scale"
  },
  {
    uuid: "ce04170c-6d0d-413b-89c7-3aab6d2bb4c6",
    iconSet: "MaterialCommunityIcons",
    iconName: "reload"
  },
  {
    uuid: "a8e2c9b1-b978-4f9c-82ac-d8be32c15392",
    iconSet: "MaterialCommunityIcons",
    iconName: "reload-alert"
  },
  {
    uuid: "440913ef-75da-4d31-8e87-99fd303c8e6b",
    iconSet: "MaterialCommunityIcons",
    iconName: "reminder"
  },
  {
    uuid: "4a4b10ff-644f-4930-982f-21a5c300101b",
    iconSet: "MaterialCommunityIcons",
    iconName: "remote"
  },
  {
    uuid: "09f0eae8-788a-4065-a376-e16e5392ccd0",
    iconSet: "MaterialCommunityIcons",
    iconName: "remote-desktop"
  },
  {
    uuid: "54525634-6be3-497e-a4cc-3b3dc429833e",
    iconSet: "MaterialCommunityIcons",
    iconName: "remote-off"
  },
  {
    uuid: "4ff64534-3796-48ab-8c5b-f392a3690a07",
    iconSet: "MaterialCommunityIcons",
    iconName: "remote-tv"
  },
  {
    uuid: "3a8cd78f-8815-468c-b113-4a40a8fbd509",
    iconSet: "MaterialCommunityIcons",
    iconName: "remote-tv-off"
  },
  {
    uuid: "d8becd86-189d-41cc-8739-22156811db1f",
    iconSet: "MaterialCommunityIcons",
    iconName: "rename-box"
  },
  {
    uuid: "00bc27ca-bde0-4412-b27f-2363355864a1",
    iconSet: "MaterialCommunityIcons",
    iconName: "reorder-horizontal"
  },
  {
    uuid: "03ed05a3-022b-48b9-ae72-0ae549e112a2",
    iconSet: "MaterialCommunityIcons",
    iconName: "reorder-vertical"
  },
  {
    uuid: "6a836660-5104-4a2f-9bcf-5227663e82e2",
    iconSet: "MaterialCommunityIcons",
    iconName: "repeat"
  },
  {
    uuid: "ca34233d-eb11-4855-805f-bb7b4f8e1fcb",
    iconSet: "MaterialCommunityIcons",
    iconName: "repeat-off"
  },
  {
    uuid: "418664e1-8c60-4c33-afcb-55d1ab07ed7d",
    iconSet: "MaterialCommunityIcons",
    iconName: "repeat-once"
  },
  {
    uuid: "3ef61a6f-0083-4e41-af50-8ab240308bc1",
    iconSet: "MaterialCommunityIcons",
    iconName: "replay"
  },
  {
    uuid: "673320fc-c40a-45e3-b4bd-3a919d3728a7",
    iconSet: "MaterialCommunityIcons",
    iconName: "reply"
  },
  {
    uuid: "e07a03e1-dcb9-4a80-a34a-fa6d7bb4f183",
    iconSet: "MaterialCommunityIcons",
    iconName: "reply-all"
  },
  {
    uuid: "b4a10ba5-8b04-4d80-8edb-a9cb349380d5",
    iconSet: "MaterialCommunityIcons",
    iconName: "reply-all-outline"
  },
  {
    uuid: "2bb66b5f-2a3e-4941-a60b-54696b1d9352",
    iconSet: "MaterialCommunityIcons",
    iconName: "reply-circle"
  },
  {
    uuid: "ad96e9c2-6361-4ff6-b634-308d44e0a6cc",
    iconSet: "MaterialCommunityIcons",
    iconName: "reply-outline"
  },
  {
    uuid: "6328e6c3-fbfb-4097-a250-107c1e91c547",
    iconSet: "MaterialCommunityIcons",
    iconName: "reproduction"
  },
  {
    uuid: "e989004c-7b52-4632-86ee-31147222d841",
    iconSet: "MaterialCommunityIcons",
    iconName: "resistor"
  },
  {
    uuid: "2ee07bed-9d23-41fc-b5e9-62ab6c05d6c4",
    iconSet: "MaterialCommunityIcons",
    iconName: "resistor-nodes"
  },
  {
    uuid: "e5990593-a861-4c3d-bc32-13a4bbcc617d",
    iconSet: "MaterialCommunityIcons",
    iconName: "resize"
  },
  {
    uuid: "bee7418a-6c25-41c6-ac9e-659f5e0697a0",
    iconSet: "MaterialCommunityIcons",
    iconName: "resize-bottom-right"
  },
  {
    uuid: "9773ef98-8c7f-4920-8cb0-d9def0ab7f33",
    iconSet: "MaterialCommunityIcons",
    iconName: "responsive"
  },
  {
    uuid: "360835c3-1da9-4aec-bc10-bb7c0857096c",
    iconSet: "MaterialCommunityIcons",
    iconName: "restart"
  },
  {
    uuid: "ec667fb1-772d-4024-83e0-e0f430543494",
    iconSet: "MaterialCommunityIcons",
    iconName: "restart-alert"
  },
  {
    uuid: "ef1f65bc-d27d-434f-bdc1-ec5757db045f",
    iconSet: "MaterialCommunityIcons",
    iconName: "restart-off"
  },
  {
    uuid: "cf0943af-436e-4496-bfab-9b56f6b9473f",
    iconSet: "MaterialCommunityIcons",
    iconName: "restore"
  },
  {
    uuid: "bc45fada-ed93-4f14-a817-f3ae88fdbeae",
    iconSet: "MaterialCommunityIcons",
    iconName: "restore-alert"
  },
  {
    uuid: "f637f872-05d4-456d-b9c3-ef4ad4f18562",
    iconSet: "MaterialCommunityIcons",
    iconName: "rewind"
  },
  {
    uuid: "0b2fda3d-c6c7-42c3-93cb-4241d59145e4",
    iconSet: "MaterialCommunityIcons",
    iconName: "rewind-10"
  },
  {
    uuid: "7f3d20c9-a8b5-4d95-8b04-4b846c31ce0f",
    iconSet: "MaterialCommunityIcons",
    iconName: "rewind-30"
  },
  {
    uuid: "10cdf922-3312-493d-9474-1277a4d74103",
    iconSet: "MaterialCommunityIcons",
    iconName: "rewind-5"
  },
  {
    uuid: "9b0329f4-8072-42b9-9af3-fb65a31ca5fe",
    iconSet: "MaterialCommunityIcons",
    iconName: "rewind-outline"
  },
  {
    uuid: "099b2c04-4bab-48a6-a1d7-96c0d9c3efd7",
    iconSet: "MaterialCommunityIcons",
    iconName: "rhombus"
  },
  {
    uuid: "2b9406bf-092a-48a0-9a9f-550562f4e221",
    iconSet: "MaterialCommunityIcons",
    iconName: "rhombus-medium"
  },
  {
    uuid: "6cc0bc99-e579-4454-aeb7-2117a958c340",
    iconSet: "MaterialCommunityIcons",
    iconName: "rhombus-medium-outline"
  },
  {
    uuid: "7d5a7f21-82b4-4bf2-9029-a0c84e60b319",
    iconSet: "MaterialCommunityIcons",
    iconName: "rhombus-outline"
  },
  {
    uuid: "dadca9d5-0860-4011-a489-e14f956db61a",
    iconSet: "MaterialCommunityIcons",
    iconName: "rhombus-split"
  },
  {
    uuid: "a22bcf3d-c78a-4874-ac72-289fdafd3bc8",
    iconSet: "MaterialCommunityIcons",
    iconName: "rhombus-split-outline"
  },
  {
    uuid: "919a057f-0bfc-4516-89df-7d2c091a3ff3",
    iconSet: "MaterialCommunityIcons",
    iconName: "ribbon"
  },
  {
    uuid: "67e6bec6-f5cb-44b5-8939-0786d8fc5cc5",
    iconSet: "MaterialCommunityIcons",
    iconName: "rice"
  },
  {
    uuid: "3a49834f-1974-4006-b81f-df81c0c34707",
    iconSet: "MaterialCommunityIcons",
    iconName: "ring"
  },
  {
    uuid: "8b206b92-0aec-4033-a597-d69551ddbae1",
    iconSet: "MaterialCommunityIcons",
    iconName: "rivet"
  },
  {
    uuid: "075d0c69-8021-4c5e-afbe-fb7552bdb0fa",
    iconSet: "MaterialCommunityIcons",
    iconName: "road"
  },
  {
    uuid: "41866502-23f9-4722-94b3-2c831d9a3203",
    iconSet: "MaterialCommunityIcons",
    iconName: "road-variant"
  },
  {
    uuid: "b815e419-e2e0-4d6d-ae8c-30f354d50430",
    iconSet: "MaterialCommunityIcons",
    iconName: "robber"
  },
  {
    uuid: "45760326-ce1a-429f-a185-f74555ada5b5",
    iconSet: "MaterialCommunityIcons",
    iconName: "robot"
  },
  {
    uuid: "ae72e797-6cf5-4d02-a591-d4f4b2ae9390",
    iconSet: "MaterialCommunityIcons",
    iconName: "robot-industrial"
  },
  {
    uuid: "f6d3ab9f-d360-4b01-90c8-626cffa0838e",
    iconSet: "MaterialCommunityIcons",
    iconName: "robot-mower"
  },
  {
    uuid: "ab111120-6154-4f11-a173-3223aab7bc2d",
    iconSet: "MaterialCommunityIcons",
    iconName: "robot-mower-outline"
  },
  {
    uuid: "858f7e5b-2fb2-4baf-be10-4a90983b8484",
    iconSet: "MaterialCommunityIcons",
    iconName: "robot-vacuum"
  },
  {
    uuid: "81b6ef77-d144-451c-bf85-6b5a5b255d71",
    iconSet: "MaterialCommunityIcons",
    iconName: "robot-vacuum-variant"
  },
  {
    uuid: "80c0cd1f-deb4-4a1b-a1c6-c96a1dc66878",
    iconSet: "MaterialCommunityIcons",
    iconName: "rocket"
  },
  {
    uuid: "320bed17-9bcc-41ab-ab11-9566280095cf",
    iconSet: "MaterialCommunityIcons",
    iconName: "rocket-launch"
  },
  {
    uuid: "07e679f6-6fa8-4dbc-a21c-b96dc2a79486",
    iconSet: "MaterialCommunityIcons",
    iconName: "rocket-launch-outline"
  },
  {
    uuid: "fd0e115c-3d5e-4966-939c-6f91a28c670a",
    iconSet: "MaterialCommunityIcons",
    iconName: "rocket-outline"
  },
  {
    uuid: "5c9fa048-6277-4e0f-b209-9002c85f042b",
    iconSet: "MaterialCommunityIcons",
    iconName: "rodent"
  },
  {
    uuid: "f370abc7-1631-4878-aee9-35a54947cea0",
    iconSet: "MaterialCommunityIcons",
    iconName: "roller-skate"
  },
  {
    uuid: "8c54c4f4-99ce-4a3e-bd49-30ed83923a5d",
    iconSet: "MaterialCommunityIcons",
    iconName: "roller-skate-off"
  },
  {
    uuid: "29582f1d-c126-41e6-bb46-ae961e14eabe",
    iconSet: "MaterialCommunityIcons",
    iconName: "rollerblade"
  },
  {
    uuid: "de67db4e-febc-4d1f-9a66-8626fadaace6",
    iconSet: "MaterialCommunityIcons",
    iconName: "rollerblade-off"
  },
  {
    uuid: "10d870c3-ef6c-4400-a794-1695e21d0fd6",
    iconSet: "MaterialCommunityIcons",
    iconName: "rollupjs"
  },
  {
    uuid: "8f929ea3-ccdf-4ed8-bac5-b215443ffd34",
    iconSet: "MaterialCommunityIcons",
    iconName: "roman-numeral-1"
  },
  {
    uuid: "72497d2d-865b-4e16-b17a-45eb415ad0ae",
    iconSet: "MaterialCommunityIcons",
    iconName: "roman-numeral-10"
  },
  {
    uuid: "b9829781-1cbf-41f2-abca-1651cf7f9770",
    iconSet: "MaterialCommunityIcons",
    iconName: "roman-numeral-2"
  },
  {
    uuid: "577bc4ae-e291-4cbc-b1c6-c33a2e031334",
    iconSet: "MaterialCommunityIcons",
    iconName: "roman-numeral-3"
  },
  {
    uuid: "4d69067d-1dd3-44e0-96b4-ab0f0d7c516d",
    iconSet: "MaterialCommunityIcons",
    iconName: "roman-numeral-4"
  },
  {
    uuid: "8aeb471a-0a03-463d-9b90-90985abfc605",
    iconSet: "MaterialCommunityIcons",
    iconName: "roman-numeral-5"
  },
  {
    uuid: "9b927c72-2483-43cf-ad5d-cfea11b22928",
    iconSet: "MaterialCommunityIcons",
    iconName: "roman-numeral-6"
  },
  {
    uuid: "05c8911a-7e84-4f76-ba3c-20bafe45b501",
    iconSet: "MaterialCommunityIcons",
    iconName: "roman-numeral-7"
  },
  {
    uuid: "3c6ad5d8-7469-49b7-b6ec-6f1c5166034b",
    iconSet: "MaterialCommunityIcons",
    iconName: "roman-numeral-8"
  },
  {
    uuid: "9e9f94f8-a675-4cfa-a26b-24dd7acd7153",
    iconSet: "MaterialCommunityIcons",
    iconName: "roman-numeral-9"
  },
  {
    uuid: "b8cb5f8c-1569-4698-a46a-b8b005832180",
    iconSet: "MaterialCommunityIcons",
    iconName: "room-service"
  },
  {
    uuid: "b83184aa-6934-482c-9c23-2d08adde8943",
    iconSet: "MaterialCommunityIcons",
    iconName: "room-service-outline"
  },
  {
    uuid: "911287b4-6491-4a6d-b0ab-5a2700653dfe",
    iconSet: "MaterialCommunityIcons",
    iconName: "rotate-3d"
  },
  {
    uuid: "67fe941f-0fa1-4a37-bcd1-944cf1f71641",
    iconSet: "MaterialCommunityIcons",
    iconName: "rotate-3d-variant"
  },
  {
    uuid: "d04a7ba2-7e84-4b97-8b7b-a9dff3ba7f26",
    iconSet: "MaterialCommunityIcons",
    iconName: "rotate-left"
  },
  {
    uuid: "0f914e94-c037-4029-be01-e025e27b1e48",
    iconSet: "MaterialCommunityIcons",
    iconName: "rotate-left-variant"
  },
  {
    uuid: "780c0731-3112-4b83-850e-322e071d42ea",
    iconSet: "MaterialCommunityIcons",
    iconName: "rotate-orbit"
  },
  {
    uuid: "7afc2f4c-23f4-4621-b8cc-73fb34f9e0d1",
    iconSet: "MaterialCommunityIcons",
    iconName: "rotate-right"
  },
  {
    uuid: "d9b9666d-c56b-4de8-a53f-7f80709ea0ee",
    iconSet: "MaterialCommunityIcons",
    iconName: "rotate-right-variant"
  },
  {
    uuid: "b91fc91b-fae4-4fe3-9b5d-aeb924a7e877",
    iconSet: "MaterialCommunityIcons",
    iconName: "rounded-corner"
  },
  {
    uuid: "0c5d6715-0ef8-423a-b9b1-29c229fcc2b0",
    iconSet: "MaterialCommunityIcons",
    iconName: "router"
  },
  {
    uuid: "4b184ad3-6fe4-41b4-a77d-4aaeb0fcda25",
    iconSet: "MaterialCommunityIcons",
    iconName: "router-network"
  },
  {
    uuid: "b086dcdf-7a1f-4f70-b806-31b60201460f",
    iconSet: "MaterialCommunityIcons",
    iconName: "router-wireless"
  },
  {
    uuid: "c22257dc-ccb4-4736-aa45-3a9b2baf2d70",
    iconSet: "MaterialCommunityIcons",
    iconName: "router-wireless-settings"
  },
  {
    uuid: "cb956b89-33de-4868-a6a2-21ea0c5112d8",
    iconSet: "MaterialCommunityIcons",
    iconName: "routes"
  },
  {
    uuid: "115281bb-baab-4eb6-ac03-9d8f6c71de2c",
    iconSet: "MaterialCommunityIcons",
    iconName: "routes-clock"
  },
  {
    uuid: "b684d6c0-8df9-404c-907b-3027cb105d56",
    iconSet: "MaterialCommunityIcons",
    iconName: "rowing"
  },
  {
    uuid: "fe12450c-4176-4479-b579-4492d8998ff6",
    iconSet: "MaterialCommunityIcons",
    iconName: "rss"
  },
  {
    uuid: "34de1197-4a64-4892-8d1d-b49d8a8619d5",
    iconSet: "MaterialCommunityIcons",
    iconName: "rss-box"
  },
  {
    uuid: "f33308f4-23c1-4e2f-8041-04fb80b67a42",
    iconSet: "MaterialCommunityIcons",
    iconName: "rss-off"
  },
  {
    uuid: "39c8b8f4-38a4-44e8-8cc9-a6ca3126543b",
    iconSet: "MaterialCommunityIcons",
    iconName: "rug"
  },
  {
    uuid: "f906380f-9554-4d02-b9c0-c928dc9633bb",
    iconSet: "MaterialCommunityIcons",
    iconName: "rugby"
  },
  {
    uuid: "b014c470-8abf-420f-914c-67c27bad2091",
    iconSet: "MaterialCommunityIcons",
    iconName: "ruler"
  },
  {
    uuid: "3103c60c-98b9-4bb0-8988-fb5aee771c1a",
    iconSet: "MaterialCommunityIcons",
    iconName: "ruler-square"
  },
  {
    uuid: "57f81113-a5b0-45c4-b5da-c1b723b58e7d",
    iconSet: "MaterialCommunityIcons",
    iconName: "ruler-square-compass"
  },
  {
    uuid: "ae202dde-bf41-4176-9fe0-b8c4175c2ff0",
    iconSet: "MaterialCommunityIcons",
    iconName: "run"
  },
  {
    uuid: "3132ffbd-5d65-471e-9793-30d68b95e744",
    iconSet: "MaterialCommunityIcons",
    iconName: "run-fast"
  },
  {
    uuid: "2255f5d6-b845-4674-b875-cbdbc36cbccf",
    iconSet: "MaterialCommunityIcons",
    iconName: "rv-truck"
  },
  {
    uuid: "26590d94-9a86-4fc6-b902-cb027620dbc9",
    iconSet: "MaterialCommunityIcons",
    iconName: "sack"
  },
  {
    uuid: "03b0e275-1cee-4c75-b73f-d3c5eb5d7845",
    iconSet: "MaterialCommunityIcons",
    iconName: "sack-percent"
  },
  {
    uuid: "ea75cca6-f369-49f3-9794-2b62bc169562",
    iconSet: "MaterialCommunityIcons",
    iconName: "safe"
  },
  {
    uuid: "a02be260-6a5f-48be-a012-55aac159b779",
    iconSet: "MaterialCommunityIcons",
    iconName: "safe-square"
  },
  {
    uuid: "c0d3ccf2-30fb-44be-9258-6422ec1fbe2d",
    iconSet: "MaterialCommunityIcons",
    iconName: "safe-square-outline"
  },
  {
    uuid: "8433ea7b-7227-4ca0-8f16-9d92f18a7fc3",
    iconSet: "MaterialCommunityIcons",
    iconName: "safety-goggles"
  },
  {
    uuid: "98a54478-fcb5-4409-9888-a95588eff045",
    iconSet: "MaterialCommunityIcons",
    iconName: "sail-boat"
  },
  {
    uuid: "b1b4d355-d4d4-480a-b155-23e4df5b7a0d",
    iconSet: "MaterialCommunityIcons",
    iconName: "sale"
  },
  {
    uuid: "b03dd783-bc61-4b71-980c-f3ebc7a08807",
    iconSet: "MaterialCommunityIcons",
    iconName: "salesforce"
  },
  {
    uuid: "cb45e4c2-8675-4df1-8436-5dabc122a734",
    iconSet: "MaterialCommunityIcons",
    iconName: "sass"
  },
  {
    uuid: "4439c099-9edb-4b4f-9861-5e6663db75aa",
    iconSet: "MaterialCommunityIcons",
    iconName: "satellite"
  },
  {
    uuid: "4abae45c-186d-408d-bcf6-8f168ce81159",
    iconSet: "MaterialCommunityIcons",
    iconName: "satellite-uplink"
  },
  {
    uuid: "aaaf44ca-942b-43e6-a4f2-d8bb54841936",
    iconSet: "MaterialCommunityIcons",
    iconName: "satellite-variant"
  },
  {
    uuid: "3c988864-1a72-461b-9f09-2ab04cf20d00",
    iconSet: "MaterialCommunityIcons",
    iconName: "sausage"
  },
  {
    uuid: "f15af9b1-5eea-455c-9a96-d29a2aaacd5e",
    iconSet: "MaterialCommunityIcons",
    iconName: "saw-blade"
  },
  {
    uuid: "751f05bb-366c-4bd3-80b4-1473eb9811f7",
    iconSet: "MaterialCommunityIcons",
    iconName: "sawtooth-wave"
  },
  {
    uuid: "0b6e60bd-ecf0-454c-b092-9d6c318fe246",
    iconSet: "MaterialCommunityIcons",
    iconName: "saxophone"
  },
  {
    uuid: "6bc8c701-0145-4ee8-96c1-e94e803c3968",
    iconSet: "MaterialCommunityIcons",
    iconName: "scale"
  },
  {
    uuid: "79bd55b8-ca93-4a6f-997f-50c1fb435be5",
    iconSet: "MaterialCommunityIcons",
    iconName: "scale-balance"
  },
  {
    uuid: "cb1ebdf0-59f8-4414-8e41-7f4929b38d94",
    iconSet: "MaterialCommunityIcons",
    iconName: "scale-bathroom"
  },
  {
    uuid: "10c24205-cb84-4a0d-a4f3-17426a65d211",
    iconSet: "MaterialCommunityIcons",
    iconName: "scale-off"
  },
  {
    uuid: "b71d699c-4293-4fe3-9e0c-a764b50be592",
    iconSet: "MaterialCommunityIcons",
    iconName: "scan-helper"
  },
  {
    uuid: "4c31cb32-b658-4886-a914-ec0782de7ca2",
    iconSet: "MaterialCommunityIcons",
    iconName: "scanner"
  },
  {
    uuid: "621ee2dd-737b-4272-b078-b938e2d7109e",
    iconSet: "MaterialCommunityIcons",
    iconName: "scanner-off"
  },
  {
    uuid: "cbbf9d94-c1a6-4d0f-ac75-44750bc7a459",
    iconSet: "MaterialCommunityIcons",
    iconName: "scatter-plot"
  },
  {
    uuid: "b131a427-8450-460b-9f29-06441c9def94",
    iconSet: "MaterialCommunityIcons",
    iconName: "scatter-plot-outline"
  },
  {
    uuid: "3b06e594-3b67-480c-8f29-b669a194ccd3",
    iconSet: "MaterialCommunityIcons",
    iconName: "school"
  },
  {
    uuid: "b9d58f2d-955b-4c86-bd3a-40dc1a0b2e5c",
    iconSet: "MaterialCommunityIcons",
    iconName: "school-outline"
  },
  {
    uuid: "dfca324f-82be-429d-b0b2-df0e1f2c1e3d",
    iconSet: "MaterialCommunityIcons",
    iconName: "scissors-cutting"
  },
  {
    uuid: "5338d071-c702-4a5a-9ddf-d265c943b497",
    iconSet: "MaterialCommunityIcons",
    iconName: "scooter"
  },
  {
    uuid: "662b6cf0-0d12-4e19-ac0c-9b173a2c231b",
    iconSet: "MaterialCommunityIcons",
    iconName: "scoreboard"
  },
  {
    uuid: "e1402425-ab7b-486d-a3a4-6b6a9d3f1dcc",
    iconSet: "MaterialCommunityIcons",
    iconName: "scoreboard-outline"
  },
  {
    uuid: "709020c1-a8ba-499f-9cf8-e79773d8c55e",
    iconSet: "MaterialCommunityIcons",
    iconName: "screen-rotation"
  },
  {
    uuid: "cb492b87-65ee-48e0-b410-948df969d65b",
    iconSet: "MaterialCommunityIcons",
    iconName: "screen-rotation-lock"
  },
  {
    uuid: "5f8cb337-7602-4658-966c-b82b69dbfeb2",
    iconSet: "MaterialCommunityIcons",
    iconName: "screw-flat-top"
  },
  {
    uuid: "f568449b-00b5-4846-8688-c8571c12fd8e",
    iconSet: "MaterialCommunityIcons",
    iconName: "screw-lag"
  },
  {
    uuid: "a4f18516-7221-4b1e-a6d8-64966918f641",
    iconSet: "MaterialCommunityIcons",
    iconName: "screw-machine-flat-top"
  },
  {
    uuid: "8769744f-4146-4d93-8405-232730ecbfe5",
    iconSet: "MaterialCommunityIcons",
    iconName: "screw-machine-round-top"
  },
  {
    uuid: "3969f147-cee5-4453-b1f6-d9aafddcde90",
    iconSet: "MaterialCommunityIcons",
    iconName: "screw-round-top"
  },
  {
    uuid: "3aa91039-a314-4769-bb8e-03a0726cbfad",
    iconSet: "MaterialCommunityIcons",
    iconName: "screwdriver"
  },
  {
    uuid: "dbdb977d-f476-4ee2-b1cd-54eaf505b1ac",
    iconSet: "MaterialCommunityIcons",
    iconName: "script"
  },
  {
    uuid: "0a7be107-99e6-4b69-942e-ed6528c90e59",
    iconSet: "MaterialCommunityIcons",
    iconName: "script-outline"
  },
  {
    uuid: "c036bd53-c7b5-4903-843d-8e5ed36f368f",
    iconSet: "MaterialCommunityIcons",
    iconName: "script-text"
  },
  {
    uuid: "aa5b97d3-5891-4768-8d19-4f02ed6e5ecd",
    iconSet: "MaterialCommunityIcons",
    iconName: "script-text-outline"
  },
  {
    uuid: "f5eade88-2ef7-48a1-9097-f6407a605f28",
    iconSet: "MaterialCommunityIcons",
    iconName: "sd"
  },
  {
    uuid: "651df76c-f222-448c-90c9-0507f5ced438",
    iconSet: "MaterialCommunityIcons",
    iconName: "seal"
  },
  {
    uuid: "e544d953-1322-4554-bbbf-7511bdba4310",
    iconSet: "MaterialCommunityIcons",
    iconName: "seal-variant"
  },
  {
    uuid: "030f065b-238a-427a-8950-af428d7e852f",
    iconSet: "MaterialCommunityIcons",
    iconName: "search-web"
  },
  {
    uuid: "c25a73a1-91cc-488f-b056-2054252f07f6",
    iconSet: "MaterialCommunityIcons",
    iconName: "seat"
  },
  {
    uuid: "b7eee96d-6743-433b-897b-24e37f823881",
    iconSet: "MaterialCommunityIcons",
    iconName: "seat-flat"
  },
  {
    uuid: "f59c7c98-3895-4a9d-93c9-6f2158986cd3",
    iconSet: "MaterialCommunityIcons",
    iconName: "seat-flat-angled"
  },
  {
    uuid: "c2d6e332-fb0a-4654-829d-d3f6102d6a11",
    iconSet: "MaterialCommunityIcons",
    iconName: "seat-individual-suite"
  },
  {
    uuid: "033f06f7-1f3c-4965-9368-6341c28ecade",
    iconSet: "MaterialCommunityIcons",
    iconName: "seat-legroom-extra"
  },
  {
    uuid: "6ba553fa-582b-4536-b04b-ce687a1645e7",
    iconSet: "MaterialCommunityIcons",
    iconName: "seat-legroom-normal"
  },
  {
    uuid: "cc8664bf-075a-48ed-9513-2cd7bff05a19",
    iconSet: "MaterialCommunityIcons",
    iconName: "seat-legroom-reduced"
  },
  {
    uuid: "8ac7bf71-106d-45bc-8ae2-04fd8a39815a",
    iconSet: "MaterialCommunityIcons",
    iconName: "seat-outline"
  },
  {
    uuid: "714d35db-1636-4b00-9a65-195e048bfcc9",
    iconSet: "MaterialCommunityIcons",
    iconName: "seat-passenger"
  },
  {
    uuid: "b9d56945-dbaa-42e6-a4ee-e9cbdf820f79",
    iconSet: "MaterialCommunityIcons",
    iconName: "seat-recline-extra"
  },
  {
    uuid: "130eefe0-784c-4a8a-94d0-c865d930064e",
    iconSet: "MaterialCommunityIcons",
    iconName: "seat-recline-normal"
  },
  {
    uuid: "6b522c12-922d-45d0-abdc-596ea33259b3",
    iconSet: "MaterialCommunityIcons",
    iconName: "seatbelt"
  },
  {
    uuid: "5a9f7e7a-ecb7-4a11-8fd4-3097db2f3af2",
    iconSet: "MaterialCommunityIcons",
    iconName: "security"
  },
  {
    uuid: "ae799936-2580-444d-b039-38449c6c9e07",
    iconSet: "MaterialCommunityIcons",
    iconName: "security-network"
  },
  {
    uuid: "e2dab59d-163f-4c45-b68a-bed365b6943e",
    iconSet: "MaterialCommunityIcons",
    iconName: "seed"
  },
  {
    uuid: "b6206945-ae8f-4463-b9e0-0d7e9699cf3c",
    iconSet: "MaterialCommunityIcons",
    iconName: "seed-off"
  },
  {
    uuid: "f483bce8-2e8e-40ab-9bae-2a2480980442",
    iconSet: "MaterialCommunityIcons",
    iconName: "seed-off-outline"
  },
  {
    uuid: "615aa38d-4796-4e31-b924-d53d821f408b",
    iconSet: "MaterialCommunityIcons",
    iconName: "seed-outline"
  },
  {
    uuid: "6a07b891-ce1d-42d4-9c6e-f00079d95801",
    iconSet: "MaterialCommunityIcons",
    iconName: "segment"
  },
  {
    uuid: "dcbf79be-acba-4dc4-aaf0-919a8b551a5c",
    iconSet: "MaterialCommunityIcons",
    iconName: "select"
  },
  {
    uuid: "c8de5973-1137-4fb0-8c4b-c989e77a2098",
    iconSet: "MaterialCommunityIcons",
    iconName: "select-all"
  },
  {
    uuid: "20bf2122-a479-43d2-8c26-233b2e26c506",
    iconSet: "MaterialCommunityIcons",
    iconName: "select-color"
  },
  {
    uuid: "ff4edaeb-72da-42cc-81f9-4439e2abe674",
    iconSet: "MaterialCommunityIcons",
    iconName: "select-compare"
  },
  {
    uuid: "f55fb3e9-ce0f-4a29-9db1-1a608637dab1",
    iconSet: "MaterialCommunityIcons",
    iconName: "select-drag"
  },
  {
    uuid: "42b7fe87-0d3d-4bd2-9655-c53af3fc4c6b",
    iconSet: "MaterialCommunityIcons",
    iconName: "select-group"
  },
  {
    uuid: "2ec02121-f66a-4bfa-bf9e-76f8bedac59c",
    iconSet: "MaterialCommunityIcons",
    iconName: "select-inverse"
  },
  {
    uuid: "02563ac8-0382-41f2-82d0-b22ea63bf4b3",
    iconSet: "MaterialCommunityIcons",
    iconName: "select-marker"
  },
  {
    uuid: "26f741a5-b209-4711-82d9-1ae8444e3c5c",
    iconSet: "MaterialCommunityIcons",
    iconName: "select-multiple"
  },
  {
    uuid: "458dba08-16e8-4458-9444-27802f9999b2",
    iconSet: "MaterialCommunityIcons",
    iconName: "select-multiple-marker"
  },
  {
    uuid: "3430f474-1d03-4bb3-84d1-8c262ab40f9a",
    iconSet: "MaterialCommunityIcons",
    iconName: "select-off"
  },
  {
    uuid: "d0d9e254-3f18-4679-bf4c-18d611785e44",
    iconSet: "MaterialCommunityIcons",
    iconName: "select-place"
  },
  {
    uuid: "6769dc95-b786-4b77-ba81-d61cc07d9c22",
    iconSet: "MaterialCommunityIcons",
    iconName: "select-search"
  },
  {
    uuid: "3d37ac72-b1b8-4806-8752-474e61a56b41",
    iconSet: "MaterialCommunityIcons",
    iconName: "selection"
  },
  {
    uuid: "1ac06308-802a-459e-94a5-21cdaf1bbc5a",
    iconSet: "MaterialCommunityIcons",
    iconName: "selection-drag"
  },
  {
    uuid: "694a3368-1003-4e45-98d8-5e281fc1f42f",
    iconSet: "MaterialCommunityIcons",
    iconName: "selection-ellipse"
  },
  {
    uuid: "ed71f954-1c8e-454e-b006-263f8f35b118",
    iconSet: "MaterialCommunityIcons",
    iconName: "selection-ellipse-arrow-inside"
  },
  {
    uuid: "34ec04df-dbd6-4941-8081-db9d75edd775",
    iconSet: "MaterialCommunityIcons",
    iconName: "selection-marker"
  },
  {
    uuid: "ed352226-cb0a-4eb3-be97-b5c3bfc637b7",
    iconSet: "MaterialCommunityIcons",
    iconName: "selection-multiple"
  },
  {
    uuid: "a63c1eb1-cf53-4487-a664-6f45cdad5f7b",
    iconSet: "MaterialCommunityIcons",
    iconName: "selection-multiple-marker"
  },
  {
    uuid: "9e8e867c-fceb-43b4-85a3-9178a1294e1e",
    iconSet: "MaterialCommunityIcons",
    iconName: "selection-off"
  },
  {
    uuid: "bd3dec21-8683-4e41-9727-ebf4dd189519",
    iconSet: "MaterialCommunityIcons",
    iconName: "selection-search"
  },
  {
    uuid: "548ce8ec-3696-4bd4-a57e-7412161d69e2",
    iconSet: "MaterialCommunityIcons",
    iconName: "semantic-web"
  },
  {
    uuid: "38f634c2-e3cb-4c53-8586-c9670b4b2737",
    iconSet: "MaterialCommunityIcons",
    iconName: "send"
  },
  {
    uuid: "50c3175d-8bc7-4da0-8102-2d7bbcaa02cd",
    iconSet: "MaterialCommunityIcons",
    iconName: "send-check"
  },
  {
    uuid: "594e5bd9-725f-45d0-a133-7435dc8c67c6",
    iconSet: "MaterialCommunityIcons",
    iconName: "send-check-outline"
  },
  {
    uuid: "6225c0df-1707-4d42-a27d-a6ef3d592893",
    iconSet: "MaterialCommunityIcons",
    iconName: "send-circle"
  },
  {
    uuid: "950208b7-14c9-4fe6-b944-92bc76c8ded0",
    iconSet: "MaterialCommunityIcons",
    iconName: "send-circle-outline"
  },
  {
    uuid: "ef938743-0b0e-484e-9a08-93b0a8443600",
    iconSet: "MaterialCommunityIcons",
    iconName: "send-clock"
  },
  {
    uuid: "2afaba6c-c639-4b75-8144-06778549a2c4",
    iconSet: "MaterialCommunityIcons",
    iconName: "send-clock-outline"
  },
  {
    uuid: "2bf66ac2-afee-4694-9043-125d382da64b",
    iconSet: "MaterialCommunityIcons",
    iconName: "send-lock"
  },
  {
    uuid: "3a8973fe-06d8-44f2-b8fd-14af0802fe69",
    iconSet: "MaterialCommunityIcons",
    iconName: "send-lock-outline"
  },
  {
    uuid: "b7489f30-5caf-4635-b9c2-7d129ad6af14",
    iconSet: "MaterialCommunityIcons",
    iconName: "send-outline"
  },
  {
    uuid: "4f6dc594-7cbc-4d4d-be3e-3778056072be",
    iconSet: "MaterialCommunityIcons",
    iconName: "serial-port"
  },
  {
    uuid: "bd92c817-35e9-4c70-acb6-25e675ebcbd6",
    iconSet: "MaterialCommunityIcons",
    iconName: "server"
  },
  {
    uuid: "03c1d28b-29f5-4913-9e3d-59dc0f5ceda6",
    iconSet: "MaterialCommunityIcons",
    iconName: "server-minus"
  },
  {
    uuid: "d4038783-2272-4b0d-b207-fcfbc0bfe296",
    iconSet: "MaterialCommunityIcons",
    iconName: "server-network"
  },
  {
    uuid: "fc4c85b6-2aef-4229-9f86-3a0a06876e34",
    iconSet: "MaterialCommunityIcons",
    iconName: "server-network-off"
  },
  {
    uuid: "3f044608-e80d-4b44-ad90-3199aac30eaf",
    iconSet: "MaterialCommunityIcons",
    iconName: "server-off"
  },
  {
    uuid: "1aee11fb-c0db-4fff-8833-dfe7a2056304",
    iconSet: "MaterialCommunityIcons",
    iconName: "server-plus"
  },
  {
    uuid: "55206f60-3d78-4389-89a6-8da76f928c1a",
    iconSet: "MaterialCommunityIcons",
    iconName: "server-remove"
  },
  {
    uuid: "59235348-d35a-453c-90a9-1d8f96a33de8",
    iconSet: "MaterialCommunityIcons",
    iconName: "server-security"
  },
  {
    uuid: "aa21855a-d94f-4221-bb37-553eec6b99cf",
    iconSet: "MaterialCommunityIcons",
    iconName: "set-all"
  },
  {
    uuid: "8c52a556-7f0d-4bed-95a4-a604a31bbe25",
    iconSet: "MaterialCommunityIcons",
    iconName: "set-center"
  },
  {
    uuid: "909e2369-88a5-4e4e-b608-7c1da281d5c8",
    iconSet: "MaterialCommunityIcons",
    iconName: "set-center-right"
  },
  {
    uuid: "4318b00f-85fd-40b0-b4f7-9d0a123dfc99",
    iconSet: "MaterialCommunityIcons",
    iconName: "set-left"
  },
  {
    uuid: "e9749288-d188-4c82-8e2a-553bba012962",
    iconSet: "MaterialCommunityIcons",
    iconName: "set-left-center"
  },
  {
    uuid: "533b6734-40c1-41af-b6c8-36945d8be7de",
    iconSet: "MaterialCommunityIcons",
    iconName: "set-left-right"
  },
  {
    uuid: "b087f6c3-e10c-4795-b1e5-840fb25de442",
    iconSet: "MaterialCommunityIcons",
    iconName: "set-merge"
  },
  {
    uuid: "d1145d71-5619-41f6-83a2-8954c50b43a1",
    iconSet: "MaterialCommunityIcons",
    iconName: "set-none"
  },
  {
    uuid: "e601a8a2-a122-458e-9ef4-80151310d57c",
    iconSet: "MaterialCommunityIcons",
    iconName: "set-right"
  },
  {
    uuid: "a291bc87-71e6-474c-88d4-390ace082104",
    iconSet: "MaterialCommunityIcons",
    iconName: "set-split"
  },
  {
    uuid: "ba9ffff3-f114-4a5b-af94-bf4a6818a439",
    iconSet: "MaterialCommunityIcons",
    iconName: "set-square"
  },
  {
    uuid: "3b7e9e00-0ff7-49ff-a310-f607d4068dc6",
    iconSet: "MaterialCommunityIcons",
    iconName: "set-top-box"
  },
  {
    uuid: "7b4233ae-11f8-4606-a013-0692dba7a129",
    iconSet: "MaterialCommunityIcons",
    iconName: "settings-helper"
  },
  {
    uuid: "0de7e6b4-8eeb-4964-af48-c737feac54b7",
    iconSet: "MaterialCommunityIcons",
    iconName: "shaker"
  },
  {
    uuid: "9f939d96-e31c-482d-9ac0-45f17725a38b",
    iconSet: "MaterialCommunityIcons",
    iconName: "shaker-outline"
  },
  {
    uuid: "70a99b15-c353-4af9-96c7-850da35c8235",
    iconSet: "MaterialCommunityIcons",
    iconName: "shape"
  },
  {
    uuid: "50a82e82-46b7-45e6-9b9f-186f136b3462",
    iconSet: "MaterialCommunityIcons",
    iconName: "shape-circle-plus"
  },
  {
    uuid: "5639e87e-4e92-4fdc-8b83-04be982040ab",
    iconSet: "MaterialCommunityIcons",
    iconName: "shape-outline"
  },
  {
    uuid: "66fa20db-f5e1-401e-903a-ce3d0320084d",
    iconSet: "MaterialCommunityIcons",
    iconName: "shape-oval-plus"
  },
  {
    uuid: "5e8674c8-a491-4355-8a50-a7760476d3f4",
    iconSet: "MaterialCommunityIcons",
    iconName: "shape-plus"
  },
  {
    uuid: "f884b620-b785-4d8c-bd86-ee9ab306891b",
    iconSet: "MaterialCommunityIcons",
    iconName: "shape-polygon-plus"
  },
  {
    uuid: "01239196-90b9-4a5d-bd90-0fd5a7ff0cc9",
    iconSet: "MaterialCommunityIcons",
    iconName: "shape-rectangle-plus"
  },
  {
    uuid: "cba10685-7eda-452c-b289-624bf46de346",
    iconSet: "MaterialCommunityIcons",
    iconName: "shape-square-plus"
  },
  {
    uuid: "ed4a497b-8bb4-4ba0-a8ed-cf749c5071b3",
    iconSet: "MaterialCommunityIcons",
    iconName: "share"
  },
  {
    uuid: "515853f2-5dfb-45b7-9155-2bf3f6880f8a",
    iconSet: "MaterialCommunityIcons",
    iconName: "share-all"
  },
  {
    uuid: "6b8abace-c247-4e00-91d5-995a6fe14370",
    iconSet: "MaterialCommunityIcons",
    iconName: "share-all-outline"
  },
  {
    uuid: "efa05150-809b-48d5-8258-ae989ac8eb4e",
    iconSet: "MaterialCommunityIcons",
    iconName: "share-circle"
  },
  {
    uuid: "d74338f6-ac21-48f4-bed8-892e1bee81e3",
    iconSet: "MaterialCommunityIcons",
    iconName: "share-off"
  },
  {
    uuid: "4529a734-55c5-452b-87f7-5622ed1e1cee",
    iconSet: "MaterialCommunityIcons",
    iconName: "share-off-outline"
  },
  {
    uuid: "bfeee96b-d513-4358-a48f-d8fe979c954c",
    iconSet: "MaterialCommunityIcons",
    iconName: "share-outline"
  },
  {
    uuid: "31087707-8192-44ce-943f-1b205bc10cef",
    iconSet: "MaterialCommunityIcons",
    iconName: "share-variant"
  },
  {
    uuid: "6daaa924-fbac-4bba-ab98-3174b320dad5",
    iconSet: "MaterialCommunityIcons",
    iconName: "sheep"
  },
  {
    uuid: "2281ce10-08a8-4919-819f-fca961b5ef7c",
    iconSet: "MaterialCommunityIcons",
    iconName: "shield"
  },
  {
    uuid: "4cbd8e8d-a72b-4ce9-8f4b-838d6e89d5dc",
    iconSet: "MaterialCommunityIcons",
    iconName: "shield-account"
  },
  {
    uuid: "3bad03fc-e558-431f-bec7-92e6076c8c58",
    iconSet: "MaterialCommunityIcons",
    iconName: "shield-account-outline"
  },
  {
    uuid: "97d29bf0-dbc3-4b82-b9a1-a48a7b58dcf5",
    iconSet: "MaterialCommunityIcons",
    iconName: "shield-airplane"
  },
  {
    uuid: "484316a2-3c73-4e74-8f1c-6a796dd8fbcc",
    iconSet: "MaterialCommunityIcons",
    iconName: "shield-airplane-outline"
  },
  {
    uuid: "cd0343b1-35d9-473a-a4b1-66e544a26cc4",
    iconSet: "MaterialCommunityIcons",
    iconName: "shield-alert"
  },
  {
    uuid: "e5d5466f-72b5-4dc8-b8f7-9e349aaa9f9f",
    iconSet: "MaterialCommunityIcons",
    iconName: "shield-alert-outline"
  },
  {
    uuid: "90e6a917-d0a9-4427-8f21-20fc67f38f10",
    iconSet: "MaterialCommunityIcons",
    iconName: "shield-bug"
  },
  {
    uuid: "79030e66-2bbb-4b37-8de9-27cc154955e8",
    iconSet: "MaterialCommunityIcons",
    iconName: "shield-bug-outline"
  },
  {
    uuid: "e7d8830a-9178-4f1f-bb4a-806b6e251ed2",
    iconSet: "MaterialCommunityIcons",
    iconName: "shield-car"
  },
  {
    uuid: "26242397-4b0b-4ea3-882c-949a39ee8c82",
    iconSet: "MaterialCommunityIcons",
    iconName: "shield-check"
  },
  {
    uuid: "6ce97b03-f790-4e52-a223-a6680a3fc3a4",
    iconSet: "MaterialCommunityIcons",
    iconName: "shield-check-outline"
  },
  {
    uuid: "1e5c3864-476b-49be-95f5-a2fdf9ba42f2",
    iconSet: "MaterialCommunityIcons",
    iconName: "shield-cross"
  },
  {
    uuid: "9fea896c-b4f2-44dc-ab14-fdad4c7dc801",
    iconSet: "MaterialCommunityIcons",
    iconName: "shield-cross-outline"
  },
  {
    uuid: "7dd4c7b3-dff4-445f-bef5-4a9b07c44ce6",
    iconSet: "MaterialCommunityIcons",
    iconName: "shield-edit"
  },
  {
    uuid: "d98d84d0-5589-4329-bb19-c10dbd1943b3",
    iconSet: "MaterialCommunityIcons",
    iconName: "shield-edit-outline"
  },
  {
    uuid: "e920fe89-2e71-44fb-9165-a2adfcf2e354",
    iconSet: "MaterialCommunityIcons",
    iconName: "shield-half"
  },
  {
    uuid: "7acb6598-e0f7-4bd2-a535-880513fcad3a",
    iconSet: "MaterialCommunityIcons",
    iconName: "shield-half-full"
  },
  {
    uuid: "aaf77acd-07b8-46f6-a1e3-fea628cf29db",
    iconSet: "MaterialCommunityIcons",
    iconName: "shield-home"
  },
  {
    uuid: "a34aaa64-3d1d-49b1-befd-113231d3d685",
    iconSet: "MaterialCommunityIcons",
    iconName: "shield-home-outline"
  },
  {
    uuid: "634f0d7a-c3c1-483b-8335-b5228d7c51c3",
    iconSet: "MaterialCommunityIcons",
    iconName: "shield-key"
  },
  {
    uuid: "40b1f1da-4c10-4abf-bf9c-a963181a14c9",
    iconSet: "MaterialCommunityIcons",
    iconName: "shield-key-outline"
  },
  {
    uuid: "407ddcf6-aa83-4559-adfb-8e5bae6c4a9c",
    iconSet: "MaterialCommunityIcons",
    iconName: "shield-link-variant"
  },
  {
    uuid: "95b34848-65cf-4f77-a940-26432c2ccbe5",
    iconSet: "MaterialCommunityIcons",
    iconName: "shield-link-variant-outline"
  },
  {
    uuid: "a94ea598-6217-4d37-b18b-51735e3fc3cb",
    iconSet: "MaterialCommunityIcons",
    iconName: "shield-lock"
  },
  {
    uuid: "60ec5ef1-f394-44ad-887e-88d4937c8ceb",
    iconSet: "MaterialCommunityIcons",
    iconName: "shield-lock-outline"
  },
  {
    uuid: "827d30db-d12d-49ba-a1dd-0c838dafe413",
    iconSet: "MaterialCommunityIcons",
    iconName: "shield-off"
  },
  {
    uuid: "ff3a3d50-bf2f-4de7-b303-7d5f877cdc7c",
    iconSet: "MaterialCommunityIcons",
    iconName: "shield-off-outline"
  },
  {
    uuid: "ea580789-225b-44d3-9bd3-1c7232f9b869",
    iconSet: "MaterialCommunityIcons",
    iconName: "shield-outline"
  },
  {
    uuid: "06becccf-3576-447a-935e-d793faf782eb",
    iconSet: "MaterialCommunityIcons",
    iconName: "shield-plus"
  },
  {
    uuid: "2c5dc8d2-94d5-42fd-b4ac-c6533001934f",
    iconSet: "MaterialCommunityIcons",
    iconName: "shield-plus-outline"
  },
  {
    uuid: "6ae30361-fc9e-46fc-8965-9e69117cba7e",
    iconSet: "MaterialCommunityIcons",
    iconName: "shield-refresh"
  },
  {
    uuid: "e992ad32-5942-4068-aba5-fe3c3fb7f883",
    iconSet: "MaterialCommunityIcons",
    iconName: "shield-refresh-outline"
  },
  {
    uuid: "3fd5668d-2776-414d-a9a2-09912f21302a",
    iconSet: "MaterialCommunityIcons",
    iconName: "shield-remove"
  },
  {
    uuid: "3932f12c-5ce1-46e2-891b-4144adaeaec9",
    iconSet: "MaterialCommunityIcons",
    iconName: "shield-remove-outline"
  },
  {
    uuid: "fb54d4ca-b203-4e04-a7d5-75a4437e5a74",
    iconSet: "MaterialCommunityIcons",
    iconName: "shield-search"
  },
  {
    uuid: "68689ad3-c275-4085-a933-9a3c385cde7e",
    iconSet: "MaterialCommunityIcons",
    iconName: "shield-star"
  },
  {
    uuid: "53f417af-03e3-4481-936c-3abe792cb5ba",
    iconSet: "MaterialCommunityIcons",
    iconName: "shield-star-outline"
  },
  {
    uuid: "d45b4395-4ee1-4e7d-9327-ed4fea9e36e6",
    iconSet: "MaterialCommunityIcons",
    iconName: "shield-sun"
  },
  {
    uuid: "57521247-28f7-4f8e-b23d-dab64192bfac",
    iconSet: "MaterialCommunityIcons",
    iconName: "shield-sun-outline"
  },
  {
    uuid: "2a89c53a-add9-4980-95ea-ece2a244d3a5",
    iconSet: "MaterialCommunityIcons",
    iconName: "shield-sync"
  },
  {
    uuid: "ea35cd82-b628-4dee-8226-acd88962e8bd",
    iconSet: "MaterialCommunityIcons",
    iconName: "shield-sync-outline"
  },
  {
    uuid: "dca704c7-f125-418c-955b-fc7d2f10c47b",
    iconSet: "MaterialCommunityIcons",
    iconName: "ship-wheel"
  },
  {
    uuid: "369135bf-73f9-48c8-8721-24953418ff81",
    iconSet: "MaterialCommunityIcons",
    iconName: "shoe-formal"
  },
  {
    uuid: "ee31855a-bbb7-424a-92d2-dd4f62bf2c86",
    iconSet: "MaterialCommunityIcons",
    iconName: "shoe-heel"
  },
  {
    uuid: "350bcbad-091b-416a-86d6-ebb5facd7344",
    iconSet: "MaterialCommunityIcons",
    iconName: "shoe-print"
  },
  {
    uuid: "67c0bd5b-0061-4938-b6ee-13f9b389f96c",
    iconSet: "MaterialCommunityIcons",
    iconName: "shopping"
  },
  {
    uuid: "6d6e1570-ee01-4e21-a3c1-b392cc0d0243",
    iconSet: "MaterialCommunityIcons",
    iconName: "shopping-music"
  },
  {
    uuid: "6cd33085-26c4-4ae8-818b-cd425f56ef56",
    iconSet: "MaterialCommunityIcons",
    iconName: "shopping-outline"
  },
  {
    uuid: "237d30c2-61ac-4f24-b463-54dfc5d5f9f9",
    iconSet: "MaterialCommunityIcons",
    iconName: "shopping-search"
  },
  {
    uuid: "6c877536-bfec-42a7-b2ec-0edde2c89b04",
    iconSet: "MaterialCommunityIcons",
    iconName: "shovel"
  },
  {
    uuid: "43825dae-c1db-44a3-90bc-3314a1f99945",
    iconSet: "MaterialCommunityIcons",
    iconName: "shovel-off"
  },
  {
    uuid: "382946cc-7269-484c-a727-2a2c36d0d8b1",
    iconSet: "MaterialCommunityIcons",
    iconName: "shower"
  },
  {
    uuid: "a3343eba-61e2-4e84-8c50-a62b760a53b9",
    iconSet: "MaterialCommunityIcons",
    iconName: "shower-head"
  },
  {
    uuid: "95d0885f-d9cd-4668-9d86-634bc60286d8",
    iconSet: "MaterialCommunityIcons",
    iconName: "shredder"
  },
  {
    uuid: "4f10712b-c487-4fcb-8715-4ca196f133b6",
    iconSet: "MaterialCommunityIcons",
    iconName: "shuffle"
  },
  {
    uuid: "c7eaf323-b127-4323-aff8-adb2db2d75a2",
    iconSet: "MaterialCommunityIcons",
    iconName: "shuffle-disabled"
  },
  {
    uuid: "ff53db01-91eb-4138-bc62-269f3da38d9f",
    iconSet: "MaterialCommunityIcons",
    iconName: "shuffle-variant"
  },
  {
    uuid: "8ce86d92-6a6b-4c31-a4cd-b96fae0bdc1f",
    iconSet: "MaterialCommunityIcons",
    iconName: "shuriken"
  },
  {
    uuid: "11d664b3-4e02-46fd-836e-31e84c8301bd",
    iconSet: "MaterialCommunityIcons",
    iconName: "sigma"
  },
  {
    uuid: "f12e4ef9-814e-4674-84c8-f16f929fb189",
    iconSet: "MaterialCommunityIcons",
    iconName: "sigma-lower"
  },
  {
    uuid: "244a0924-4db2-4e69-a09a-5dec21606a60",
    iconSet: "MaterialCommunityIcons",
    iconName: "sign-caution"
  },
  {
    uuid: "d1185dfb-c6da-4a84-8174-453b1e2924a9",
    iconSet: "MaterialCommunityIcons",
    iconName: "sign-direction"
  },
  {
    uuid: "28a43cbb-fe85-407b-9514-4623e0226a09",
    iconSet: "MaterialCommunityIcons",
    iconName: "sign-direction-minus"
  },
  {
    uuid: "5c789080-a4a1-4ff4-be6c-ec13f2a729f0",
    iconSet: "MaterialCommunityIcons",
    iconName: "sign-direction-plus"
  },
  {
    uuid: "b6c143a0-8c2b-4767-a2f1-73fdcfc9998f",
    iconSet: "MaterialCommunityIcons",
    iconName: "sign-direction-remove"
  },
  {
    uuid: "491cb445-68b9-4eb3-82d7-a3b81fcd92cc",
    iconSet: "MaterialCommunityIcons",
    iconName: "sign-real-estate"
  },
  {
    uuid: "cb35c570-0198-4479-a6f0-df8c2e8cede5",
    iconSet: "MaterialCommunityIcons",
    iconName: "sign-text"
  },
  {
    uuid: "77b59ca4-525d-4f0a-b8b7-8437d996ef62",
    iconSet: "MaterialCommunityIcons",
    iconName: "signal"
  },
  {
    uuid: "bb082b71-3268-4033-b7a0-468549778006",
    iconSet: "MaterialCommunityIcons",
    iconName: "signal-2g"
  },
  {
    uuid: "97c5cca9-a9b2-4f2f-b100-d60af84b7ecf",
    iconSet: "MaterialCommunityIcons",
    iconName: "signal-3g"
  },
  {
    uuid: "d5b667ec-4727-424e-bbdf-62d2c47e7617",
    iconSet: "MaterialCommunityIcons",
    iconName: "signal-4g"
  },
  {
    uuid: "db62a96d-a7d4-4f20-8eaf-a8f7419fff54",
    iconSet: "MaterialCommunityIcons",
    iconName: "signal-5g"
  },
  {
    uuid: "70ed64cd-17a3-476f-8445-baf0374a8180",
    iconSet: "MaterialCommunityIcons",
    iconName: "signal-cellular-1"
  },
  {
    uuid: "f392b383-a77a-4b6b-b535-cdb46f4c7d1c",
    iconSet: "MaterialCommunityIcons",
    iconName: "signal-cellular-2"
  },
  {
    uuid: "f8291847-f006-4a12-92e8-7a5012b4e127",
    iconSet: "MaterialCommunityIcons",
    iconName: "signal-cellular-3"
  },
  {
    uuid: "89eaf83b-6624-45e6-8aa2-dcf696f722f9",
    iconSet: "MaterialCommunityIcons",
    iconName: "signal-cellular-outline"
  },
  {
    uuid: "9f7ea132-294c-4e4f-a54f-ef483ffe06f4",
    iconSet: "MaterialCommunityIcons",
    iconName: "signal-distance-variant"
  },
  {
    uuid: "01a71497-7da3-4bed-b635-7c90a65f17c1",
    iconSet: "MaterialCommunityIcons",
    iconName: "signal-hspa"
  },
  {
    uuid: "a852f666-5ecf-49a5-aac1-ffdaec624777",
    iconSet: "MaterialCommunityIcons",
    iconName: "signal-hspa-plus"
  },
  {
    uuid: "859ef718-d646-43cc-827b-29c8cb3de436",
    iconSet: "MaterialCommunityIcons",
    iconName: "signal-off"
  },
  {
    uuid: "29526f10-678d-4ea9-853d-e5c256467360",
    iconSet: "MaterialCommunityIcons",
    iconName: "signal-variant"
  },
  {
    uuid: "c24af4b6-175d-4615-b2bf-7b6bd26871b0",
    iconSet: "MaterialCommunityIcons",
    iconName: "signature"
  },
  {
    uuid: "80919439-e242-4c29-b2c2-d3593cb04a9f",
    iconSet: "MaterialCommunityIcons",
    iconName: "signature-freehand"
  },
  {
    uuid: "47377df0-f909-4e48-935e-562ec2d249fc",
    iconSet: "MaterialCommunityIcons",
    iconName: "signature-image"
  },
  {
    uuid: "0d7eab44-a661-4e5f-9165-acfd5bb5d040",
    iconSet: "MaterialCommunityIcons",
    iconName: "signature-text"
  },
  {
    uuid: "de54cd54-f85d-41cf-a193-54c6899892db",
    iconSet: "MaterialCommunityIcons",
    iconName: "silo"
  },
  {
    uuid: "719f4c07-092b-4e05-bb8b-4a5464e64498",
    iconSet: "MaterialCommunityIcons",
    iconName: "silverware"
  },
  {
    uuid: "440f66bc-e1ae-4e35-9847-0d8b6f546724",
    iconSet: "MaterialCommunityIcons",
    iconName: "silverware-clean"
  },
  {
    uuid: "56846c76-461e-49b5-b10d-5a9a6c225609",
    iconSet: "MaterialCommunityIcons",
    iconName: "silverware-fork"
  },
  {
    uuid: "ab0a4dac-d612-4aac-8600-909b0cdead4b",
    iconSet: "MaterialCommunityIcons",
    iconName: "silverware-fork-knife"
  },
  {
    uuid: "8bca28a9-68d3-4ef0-8c93-cbe1252a99eb",
    iconSet: "MaterialCommunityIcons",
    iconName: "silverware-spoon"
  },
  {
    uuid: "8c9c74ba-b629-4d7b-9dc0-b1d5fb1f978d",
    iconSet: "MaterialCommunityIcons",
    iconName: "silverware-variant"
  },
  {
    uuid: "1bfe681f-b4bc-4d2e-953f-76ecc38b9624",
    iconSet: "MaterialCommunityIcons",
    iconName: "sim"
  },
  {
    uuid: "327753ce-4629-466d-a064-d843d7065b19",
    iconSet: "MaterialCommunityIcons",
    iconName: "sim-alert"
  },
  {
    uuid: "83b0c43f-adb3-4f00-8586-356b0ac90784",
    iconSet: "MaterialCommunityIcons",
    iconName: "sim-off"
  },
  {
    uuid: "1d542116-539c-421d-8d43-cad76a8868ef",
    iconSet: "MaterialCommunityIcons",
    iconName: "simple-icons"
  },
  {
    uuid: "9053bdb9-ae1b-4db7-8c1e-6c48ed664a5c",
    iconSet: "MaterialCommunityIcons",
    iconName: "sina-weibo"
  },
  {
    uuid: "ec38f4d3-ffe5-470a-aec2-8191c6933550",
    iconSet: "MaterialCommunityIcons",
    iconName: "sine-wave"
  },
  {
    uuid: "cc6bc2c2-7041-4c64-ba90-955dbc416bc6",
    iconSet: "MaterialCommunityIcons",
    iconName: "sitemap"
  },
  {
    uuid: "e5795faa-3f31-4bdf-b230-d3da8b0b2805",
    iconSet: "MaterialCommunityIcons",
    iconName: "size-l"
  },
  {
    uuid: "68c20950-7e9e-459e-9d6f-83a8028fed21",
    iconSet: "MaterialCommunityIcons",
    iconName: "size-m"
  },
  {
    uuid: "e938c0f1-88bc-425b-a4e7-2287fdd7f7a0",
    iconSet: "MaterialCommunityIcons",
    iconName: "size-s"
  },
  {
    uuid: "5f3d054a-8605-44ca-bc99-a7b89015a8a1",
    iconSet: "MaterialCommunityIcons",
    iconName: "size-xl"
  },
  {
    uuid: "6baf28cc-c1a4-4cc0-9616-0a9f54a347f3",
    iconSet: "MaterialCommunityIcons",
    iconName: "size-xs"
  },
  {
    uuid: "996b7c8b-b547-49c0-9946-d49edfa66bcc",
    iconSet: "MaterialCommunityIcons",
    iconName: "size-xxl"
  },
  {
    uuid: "f30d4a1b-e3c7-43f5-8de6-bf112f755d4e",
    iconSet: "MaterialCommunityIcons",
    iconName: "size-xxs"
  },
  {
    uuid: "a23e6096-d112-41e9-9f28-b590a8c50758",
    iconSet: "MaterialCommunityIcons",
    iconName: "size-xxxl"
  },
  {
    uuid: "6b1541c9-dbaf-4d6e-a337-c3b56647b652",
    iconSet: "MaterialCommunityIcons",
    iconName: "skate"
  },
  {
    uuid: "a98afcf9-6208-4e75-b25f-3a6c2ad26cd8",
    iconSet: "MaterialCommunityIcons",
    iconName: "skateboard"
  },
  {
    uuid: "ced3e017-137c-4151-90ce-8b3f2ee9b4b3",
    iconSet: "MaterialCommunityIcons",
    iconName: "skew-less"
  },
  {
    uuid: "a84fabac-8df8-4348-931f-dadb4239732a",
    iconSet: "MaterialCommunityIcons",
    iconName: "skew-more"
  },
  {
    uuid: "3ceb8213-9157-4eb6-b62f-928406192640",
    iconSet: "MaterialCommunityIcons",
    iconName: "ski"
  },
  {
    uuid: "2f3cc140-a63d-407f-8095-d5fa6ab1571c",
    iconSet: "MaterialCommunityIcons",
    iconName: "ski-cross-country"
  },
  {
    uuid: "6eca5023-b727-450b-97ef-cf27a1233833",
    iconSet: "MaterialCommunityIcons",
    iconName: "ski-water"
  },
  {
    uuid: "d2f0984a-9886-424b-8470-f2115b0c2696",
    iconSet: "MaterialCommunityIcons",
    iconName: "skip-backward"
  },
  {
    uuid: "78fb75b8-bf38-46bc-bf38-02c15ed5470e",
    iconSet: "MaterialCommunityIcons",
    iconName: "skip-backward-outline"
  },
  {
    uuid: "dc6e4902-416b-4f32-b480-70dae52a3817",
    iconSet: "MaterialCommunityIcons",
    iconName: "skip-forward"
  },
  {
    uuid: "ca55a20f-4b6e-43cd-ac31-753c44500498",
    iconSet: "MaterialCommunityIcons",
    iconName: "skip-forward-outline"
  },
  {
    uuid: "612c64f4-8613-43d4-9e3c-9630d6c1f056",
    iconSet: "MaterialCommunityIcons",
    iconName: "skip-next"
  },
  {
    uuid: "8ab0ff0a-d881-4977-a18f-b2edef6b54cc",
    iconSet: "MaterialCommunityIcons",
    iconName: "skip-next-circle"
  },
  {
    uuid: "e70c86b6-866c-4b57-a09a-809f791ea5d9",
    iconSet: "MaterialCommunityIcons",
    iconName: "skip-next-circle-outline"
  },
  {
    uuid: "62ca89c4-dc84-4963-b8f5-cdb882fabf24",
    iconSet: "MaterialCommunityIcons",
    iconName: "skip-next-outline"
  },
  {
    uuid: "f077a9d9-4e9f-448b-a0c5-b59adaaee23b",
    iconSet: "MaterialCommunityIcons",
    iconName: "skip-previous"
  },
  {
    uuid: "5142d774-34da-41f5-8b91-1edaf97da387",
    iconSet: "MaterialCommunityIcons",
    iconName: "skip-previous-circle"
  },
  {
    uuid: "dc87be8b-88cd-4acf-8cbc-ba53052007e9",
    iconSet: "MaterialCommunityIcons",
    iconName: "skip-previous-circle-outline"
  },
  {
    uuid: "4fc42009-549e-4650-8f64-a5c2e57ff89a",
    iconSet: "MaterialCommunityIcons",
    iconName: "skip-previous-outline"
  },
  {
    uuid: "fefb3f22-fbe1-40b6-8114-6fc9c77931c6",
    iconSet: "MaterialCommunityIcons",
    iconName: "skull"
  },
  {
    uuid: "b4cf14ce-42b6-4f56-a0f2-6c039114ae78",
    iconSet: "MaterialCommunityIcons",
    iconName: "skull-crossbones"
  },
  {
    uuid: "f8125f22-f85a-4915-9022-5f104588b4fa",
    iconSet: "MaterialCommunityIcons",
    iconName: "skull-crossbones-outline"
  },
  {
    uuid: "c08c7ef7-d1a4-466e-abc3-82456b024159",
    iconSet: "MaterialCommunityIcons",
    iconName: "skull-outline"
  },
  {
    uuid: "2d4d9a48-1c00-47b9-851d-6d06ad36151a",
    iconSet: "MaterialCommunityIcons",
    iconName: "skull-scan"
  },
  {
    uuid: "efa48ac7-e0e7-455a-ad34-4bb2e56a5e8d",
    iconSet: "MaterialCommunityIcons",
    iconName: "skull-scan-outline"
  },
  {
    uuid: "f4c52922-6b21-46fa-83a5-878fa84f1b2f",
    iconSet: "MaterialCommunityIcons",
    iconName: "skype"
  },
  {
    uuid: "6538c74c-e3d9-4f9d-814b-c6d71c223f8c",
    iconSet: "MaterialCommunityIcons",
    iconName: "skype-business"
  },
  {
    uuid: "462cf1c8-62dd-4d7d-8bc0-5bc546db9d65",
    iconSet: "MaterialCommunityIcons",
    iconName: "slack"
  },
  {
    uuid: "817089b0-f431-4c8a-99aa-d0714f646d26",
    iconSet: "MaterialCommunityIcons",
    iconName: "slash-forward"
  },
  {
    uuid: "bbb3967d-4e17-4caf-aa00-fdc0fc9b5cc1",
    iconSet: "MaterialCommunityIcons",
    iconName: "slash-forward-box"
  },
  {
    uuid: "9e38dc28-ab81-4626-bf76-7a77e853a7da",
    iconSet: "MaterialCommunityIcons",
    iconName: "sleep"
  },
  {
    uuid: "56fc363b-0b47-4f31-b885-ba5a5948f697",
    iconSet: "MaterialCommunityIcons",
    iconName: "sleep-off"
  },
  {
    uuid: "352bba30-9db0-4b43-89ad-9af9708e2f98",
    iconSet: "MaterialCommunityIcons",
    iconName: "slope-downhill"
  },
  {
    uuid: "ca856974-7f25-49a4-9df0-6121a19af812",
    iconSet: "MaterialCommunityIcons",
    iconName: "slope-uphill"
  },
  {
    uuid: "da064035-bfc1-4f5c-a475-748263c4ce7e",
    iconSet: "MaterialCommunityIcons",
    iconName: "slot-machine"
  },
  {
    uuid: "3c56d042-d6f2-4994-91ad-3fd760d8b95f",
    iconSet: "MaterialCommunityIcons",
    iconName: "slot-machine-outline"
  },
  {
    uuid: "434d3d23-4be4-4ddb-bdfe-ad80e1832a2d",
    iconSet: "MaterialCommunityIcons",
    iconName: "smart-card"
  },
  {
    uuid: "8d1b850a-4c6e-4b0f-8759-068df91b3902",
    iconSet: "MaterialCommunityIcons",
    iconName: "smart-card-outline"
  },
  {
    uuid: "e5b28d2e-e250-4581-bcb5-7cf4c6a75cd8",
    iconSet: "MaterialCommunityIcons",
    iconName: "smart-card-reader"
  },
  {
    uuid: "7053d870-9b6d-4809-9a9a-82014f7176eb",
    iconSet: "MaterialCommunityIcons",
    iconName: "smart-card-reader-outline"
  },
  {
    uuid: "a6732f32-4990-433b-8ece-1c464389a382",
    iconSet: "MaterialCommunityIcons",
    iconName: "smog"
  },
  {
    uuid: "50c0d7e3-bcc7-47e3-9f0a-910a84f6cc29",
    iconSet: "MaterialCommunityIcons",
    iconName: "smoke-detector"
  },
  {
    uuid: "863ce101-eb85-4f8b-afd3-fb8d75be884b",
    iconSet: "MaterialCommunityIcons",
    iconName: "smoking"
  },
  {
    uuid: "d301e7ed-5746-4445-b28a-a7120bb2611a",
    iconSet: "MaterialCommunityIcons",
    iconName: "smoking-off"
  },
  {
    uuid: "64e73a8f-dac0-4f37-bd3c-3aaf8c5c3d6c",
    iconSet: "MaterialCommunityIcons",
    iconName: "smoking-pipe"
  },
  {
    uuid: "b5190944-0043-4789-9ab1-e0ebe78717bc",
    iconSet: "MaterialCommunityIcons",
    iconName: "smoking-pipe-off"
  },
  {
    uuid: "525b6d2f-249c-49f1-a126-c1c5a7aebd0b",
    iconSet: "MaterialCommunityIcons",
    iconName: "snapchat"
  },
  {
    uuid: "c91cc771-0c00-425d-a9d5-d0ef84ba8777",
    iconSet: "MaterialCommunityIcons",
    iconName: "snowboard"
  },
  {
    uuid: "c52d761e-17c9-446e-aba5-f8b65f0e829e",
    iconSet: "MaterialCommunityIcons",
    iconName: "snowflake"
  },
  {
    uuid: "a69b98df-154e-49e9-a8b3-e165fb716282",
    iconSet: "MaterialCommunityIcons",
    iconName: "snowflake-alert"
  },
  {
    uuid: "7cb8e926-29b3-4e32-87cb-581ae3324872",
    iconSet: "MaterialCommunityIcons",
    iconName: "snowflake-melt"
  },
  {
    uuid: "2c6692ce-2035-4bad-a698-fbfc5fa39fb6",
    iconSet: "MaterialCommunityIcons",
    iconName: "snowflake-variant"
  },
  {
    uuid: "e11b37bb-c011-4121-98e3-31835554ba65",
    iconSet: "MaterialCommunityIcons",
    iconName: "snowman"
  },
  {
    uuid: "d5781c1c-c3ea-456a-94ce-97bacf0a99da",
    iconSet: "MaterialCommunityIcons",
    iconName: "soccer"
  },
  {
    uuid: "6fe49d00-27af-4cc7-9214-8c45f8fe3e2c",
    iconSet: "MaterialCommunityIcons",
    iconName: "soccer-field"
  },
  {
    uuid: "2e200752-2f73-44e6-b86a-e2b4c47384d4",
    iconSet: "MaterialCommunityIcons",
    iconName: "sofa"
  },
  {
    uuid: "ba96c102-210d-41c6-878c-cc8d048943cb",
    iconSet: "MaterialCommunityIcons",
    iconName: "solar-panel"
  },
  {
    uuid: "4b69766f-75a9-454d-ad1d-20572faf38a9",
    iconSet: "MaterialCommunityIcons",
    iconName: "solar-panel-large"
  },
  {
    uuid: "914d61a6-1b88-4b57-ac1c-a16654b7e905",
    iconSet: "MaterialCommunityIcons",
    iconName: "solar-power"
  },
  {
    uuid: "19bfbdd8-811d-4cc1-b072-33cdcb1ad880",
    iconSet: "MaterialCommunityIcons",
    iconName: "soldering-iron"
  },
  {
    uuid: "e103b31b-e1fb-4681-8955-a4f6b16d2a22",
    iconSet: "MaterialCommunityIcons",
    iconName: "solid"
  },
  {
    uuid: "868de407-2148-4503-bb30-7034b53eec7f",
    iconSet: "MaterialCommunityIcons",
    iconName: "sony-playstation"
  },
  {
    uuid: "9d1926fb-a676-48f5-a334-766f536d62f7",
    iconSet: "MaterialCommunityIcons",
    iconName: "sort"
  },
  {
    uuid: "8b8b9743-064d-41ba-bd90-2078e0144ebd",
    iconSet: "MaterialCommunityIcons",
    iconName: "sort-alphabetical-ascending"
  },
  {
    uuid: "99cda6ae-5ca2-4d4f-8c79-972ab98295b6",
    iconSet: "MaterialCommunityIcons",
    iconName: "sort-alphabetical-ascending-variant"
  },
  {
    uuid: "c593aa02-111b-4ab3-beed-a240a9c4f402",
    iconSet: "MaterialCommunityIcons",
    iconName: "sort-alphabetical-descending"
  },
  {
    uuid: "9104733a-9853-4dc8-86ee-678b370fc014",
    iconSet: "MaterialCommunityIcons",
    iconName: "sort-alphabetical-descending-variant"
  },
  {
    uuid: "12ceeb16-0e1b-4e35-9287-7b3605fb8bd7",
    iconSet: "MaterialCommunityIcons",
    iconName: "sort-alphabetical-variant"
  },
  {
    uuid: "80934a4a-a75e-438e-88d5-043a8d5aed0e",
    iconSet: "MaterialCommunityIcons",
    iconName: "sort-ascending"
  },
  {
    uuid: "f82c56b8-7174-41d9-9588-0c6cefa48777",
    iconSet: "MaterialCommunityIcons",
    iconName: "sort-bool-ascending"
  },
  {
    uuid: "47d15117-439d-4289-8270-0e4ae613e4c5",
    iconSet: "MaterialCommunityIcons",
    iconName: "sort-bool-ascending-variant"
  },
  {
    uuid: "38b8b5e8-010a-485e-b222-c67ce0c66741",
    iconSet: "MaterialCommunityIcons",
    iconName: "sort-bool-descending"
  },
  {
    uuid: "39fdd82f-f244-44e5-83f9-6f80c972cf59",
    iconSet: "MaterialCommunityIcons",
    iconName: "sort-bool-descending-variant"
  },
  {
    uuid: "0063551f-ec12-451b-8440-bf0c118853ef",
    iconSet: "MaterialCommunityIcons",
    iconName: "sort-descending"
  },
  {
    uuid: "c3a652d8-723c-409f-8543-5a6b3c1d66e0",
    iconSet: "MaterialCommunityIcons",
    iconName: "sort-numeric-ascending"
  },
  {
    uuid: "0794e41e-c9d5-401c-bde6-dc7668bd0590",
    iconSet: "MaterialCommunityIcons",
    iconName: "sort-numeric-ascending-variant"
  },
  {
    uuid: "6235cc0e-4c39-4ed7-ba7e-01f430df124c",
    iconSet: "MaterialCommunityIcons",
    iconName: "sort-numeric-descending"
  },
  {
    uuid: "d41c1790-43b5-492e-8d29-a90069cfe841",
    iconSet: "MaterialCommunityIcons",
    iconName: "sort-numeric-descending-variant"
  },
  {
    uuid: "fc9db108-2a01-4594-8295-8e20573d7be9",
    iconSet: "MaterialCommunityIcons",
    iconName: "sort-numeric-variant"
  },
  {
    uuid: "ad5c8eb5-7e89-4b60-b838-c1d52a9a98cf",
    iconSet: "MaterialCommunityIcons",
    iconName: "sort-reverse-variant"
  },
  {
    uuid: "df91db9e-25cd-4ca6-b006-b012f227beda",
    iconSet: "MaterialCommunityIcons",
    iconName: "sort-variant"
  },
  {
    uuid: "17412fac-66e4-45e6-86a1-6a8f578ea36d",
    iconSet: "MaterialCommunityIcons",
    iconName: "sort-variant-lock"
  },
  {
    uuid: "1d2cbb38-1597-4434-90de-100154b4999d",
    iconSet: "MaterialCommunityIcons",
    iconName: "sort-variant-lock-open"
  },
  {
    uuid: "da88a666-1fb3-4541-9ca8-550800d3015b",
    iconSet: "MaterialCommunityIcons",
    iconName: "sort-variant-remove"
  },
  {
    uuid: "b24730ee-5220-401f-b568-b288df6ec00e",
    iconSet: "MaterialCommunityIcons",
    iconName: "soundcloud"
  },
  {
    uuid: "7c4759d3-d07d-4590-b3ef-3696ba4a9cfd",
    iconSet: "MaterialCommunityIcons",
    iconName: "source-branch"
  },
  {
    uuid: "22b80269-5e21-4cec-a290-a125e71c92d5",
    iconSet: "MaterialCommunityIcons",
    iconName: "source-branch-check"
  },
  {
    uuid: "a5ada3aa-86cd-4ed5-ae08-f3be67c407f1",
    iconSet: "MaterialCommunityIcons",
    iconName: "source-branch-minus"
  },
  {
    uuid: "1602a9e2-7d43-4f94-b762-12bf04b62161",
    iconSet: "MaterialCommunityIcons",
    iconName: "source-branch-plus"
  },
  {
    uuid: "158174b4-c3c4-4736-a788-6a47b77008e8",
    iconSet: "MaterialCommunityIcons",
    iconName: "source-branch-refresh"
  },
  {
    uuid: "7ff473a8-c0ab-4657-98e6-a3acc233df59",
    iconSet: "MaterialCommunityIcons",
    iconName: "source-branch-remove"
  },
  {
    uuid: "b53f6c55-c1c0-4c2f-86ab-9bf2fbdc2bae",
    iconSet: "MaterialCommunityIcons",
    iconName: "source-branch-sync"
  },
  {
    uuid: "404f6e65-2613-457a-a01f-a1d497fc121e",
    iconSet: "MaterialCommunityIcons",
    iconName: "source-commit"
  },
  {
    uuid: "db335c8b-1ff9-4a25-8f78-ae35a7aab6c5",
    iconSet: "MaterialCommunityIcons",
    iconName: "source-commit-end"
  },
  {
    uuid: "4d397d80-9f17-4fb7-ad5a-918ff93d1f62",
    iconSet: "MaterialCommunityIcons",
    iconName: "source-commit-end-local"
  },
  {
    uuid: "2fc85484-3b9a-4611-b018-c1a801705b6e",
    iconSet: "MaterialCommunityIcons",
    iconName: "source-commit-local"
  },
  {
    uuid: "2b01456b-3459-4fc3-8925-bc030b4ff677",
    iconSet: "MaterialCommunityIcons",
    iconName: "source-commit-next-local"
  },
  {
    uuid: "edbd88c0-8227-4d27-893b-dc77e34ff052",
    iconSet: "MaterialCommunityIcons",
    iconName: "source-commit-start"
  },
  {
    uuid: "8b063c77-4500-424f-ae66-444fea0be86c",
    iconSet: "MaterialCommunityIcons",
    iconName: "source-commit-start-next-local"
  },
  {
    uuid: "73b6eddc-915c-41ca-a801-1b0ac29f7007",
    iconSet: "MaterialCommunityIcons",
    iconName: "source-fork"
  },
  {
    uuid: "5a2f8e5d-96cf-4617-b829-38f4b56fa80d",
    iconSet: "MaterialCommunityIcons",
    iconName: "source-merge"
  },
  {
    uuid: "ab42d1b7-9504-44a4-b80d-f71026fa8cdd",
    iconSet: "MaterialCommunityIcons",
    iconName: "source-pull"
  },
  {
    uuid: "c57d0d68-16ec-4abe-93bd-dff644eebd1c",
    iconSet: "MaterialCommunityIcons",
    iconName: "source-repository"
  },
  {
    uuid: "8e328f9f-f907-4171-82d0-1bd7a46f679e",
    iconSet: "MaterialCommunityIcons",
    iconName: "source-repository-multiple"
  },
  {
    uuid: "3018f1ef-39c3-4491-8dd4-88bc1f723575",
    iconSet: "MaterialCommunityIcons",
    iconName: "soy-sauce"
  },
  {
    uuid: "87bfdc83-9dc1-4e65-8b5f-803011daf957",
    iconSet: "MaterialCommunityIcons",
    iconName: "soy-sauce-off"
  },
  {
    uuid: "b89d1c15-676c-42d3-b833-f9be0a73f05a",
    iconSet: "MaterialCommunityIcons",
    iconName: "spa"
  },
  {
    uuid: "6214a81d-3f69-4726-9938-65ca0e473162",
    iconSet: "MaterialCommunityIcons",
    iconName: "spa-outline"
  },
  {
    uuid: "deb01822-79cc-4a09-9c0a-2e7735d8905c",
    iconSet: "MaterialCommunityIcons",
    iconName: "space-invaders"
  },
  {
    uuid: "b499ca32-69a2-447f-adef-b99ce0ec8941",
    iconSet: "MaterialCommunityIcons",
    iconName: "space-station"
  },
  {
    uuid: "3e77ef99-81c3-4f77-b7a7-b2682dc3db9a",
    iconSet: "MaterialCommunityIcons",
    iconName: "spade"
  },
  {
    uuid: "41fe6cdb-2b1e-4aa7-b750-ec94ef063099",
    iconSet: "MaterialCommunityIcons",
    iconName: "speaker"
  },
  {
    uuid: "b34b4c09-99d0-4e40-ad16-6cff127c4955",
    iconSet: "MaterialCommunityIcons",
    iconName: "speaker-bluetooth"
  },
  {
    uuid: "da2e120d-7199-471e-905e-5d2c0bffca1e",
    iconSet: "MaterialCommunityIcons",
    iconName: "speaker-multiple"
  },
  {
    uuid: "01dede6e-bb86-4e4b-a4ee-adc30f3c24b0",
    iconSet: "MaterialCommunityIcons",
    iconName: "speaker-off"
  },
  {
    uuid: "ed158645-1455-43f5-a1b3-dc92624118df",
    iconSet: "MaterialCommunityIcons",
    iconName: "speaker-wireless"
  },
  {
    uuid: "9eb54cb3-57c8-413b-8876-39247332a505",
    iconSet: "MaterialCommunityIcons",
    iconName: "speedometer"
  },
  {
    uuid: "8f705f35-4e69-4d00-a3ee-a88be33b98e9",
    iconSet: "MaterialCommunityIcons",
    iconName: "speedometer-medium"
  },
  {
    uuid: "67b32874-c7bb-42e1-9bda-5d063f7c3fed",
    iconSet: "MaterialCommunityIcons",
    iconName: "speedometer-slow"
  },
  {
    uuid: "1dc771f7-70a3-4761-bfac-6a0d8c023c69",
    iconSet: "MaterialCommunityIcons",
    iconName: "spellcheck"
  },
  {
    uuid: "1d31e260-c8af-4817-afd7-5b7b0c93c1f5",
    iconSet: "MaterialCommunityIcons",
    iconName: "spider"
  },
  {
    uuid: "76781c80-2b13-4f25-a96d-e0e7c1e8ec3c",
    iconSet: "MaterialCommunityIcons",
    iconName: "spider-thread"
  },
  {
    uuid: "d12b7bb4-21f9-4567-bb0b-2fe0434d20b3",
    iconSet: "MaterialCommunityIcons",
    iconName: "spider-web"
  },
  {
    uuid: "952a8034-5172-46cf-a2e4-187eb3db79b8",
    iconSet: "MaterialCommunityIcons",
    iconName: "spoon-sugar"
  },
  {
    uuid: "264a9409-28ce-4ec2-905c-439b07f9478d",
    iconSet: "MaterialCommunityIcons",
    iconName: "spotify"
  },
  {
    uuid: "5bb21666-0147-489b-9ace-168b9d07b86f",
    iconSet: "MaterialCommunityIcons",
    iconName: "spotlight"
  },
  {
    uuid: "6558cc07-5218-4030-99cb-ef9110228916",
    iconSet: "MaterialCommunityIcons",
    iconName: "spotlight-beam"
  },
  {
    uuid: "ed427b67-4819-4ab1-878e-7fae7f415718",
    iconSet: "MaterialCommunityIcons",
    iconName: "spray"
  },
  {
    uuid: "acca2f0e-b39d-41d2-8d8c-826e01267cc4",
    iconSet: "MaterialCommunityIcons",
    iconName: "spray-bottle"
  },
  {
    uuid: "c4ab2ce3-c22f-465c-9f3e-d2947a7145c3",
    iconSet: "MaterialCommunityIcons",
    iconName: "sprinkler"
  },
  {
    uuid: "98ad94e9-1f63-4ca7-a8dd-7ab1300b30e6",
    iconSet: "MaterialCommunityIcons",
    iconName: "sprinkler-variant"
  },
  {
    uuid: "b06adbe9-5ec4-4e6a-a843-c48b11b68003",
    iconSet: "MaterialCommunityIcons",
    iconName: "sprout"
  },
  {
    uuid: "5967333e-ffaa-4d09-8c5f-ea450f3c7830",
    iconSet: "MaterialCommunityIcons",
    iconName: "sprout-outline"
  },
  {
    uuid: "8e04f3fa-b07d-40da-b045-4917f7b8f2b9",
    iconSet: "MaterialCommunityIcons",
    iconName: "square"
  },
  {
    uuid: "4cf310a2-b059-42b3-a150-921dc29a20b0",
    iconSet: "MaterialCommunityIcons",
    iconName: "square-edit-outline"
  },
  {
    uuid: "e27b1018-a844-4e45-9059-1e8796131abc",
    iconSet: "MaterialCommunityIcons",
    iconName: "square-medium"
  },
  {
    uuid: "49f2f5d9-32cc-4571-82cc-297646a75a93",
    iconSet: "MaterialCommunityIcons",
    iconName: "square-medium-outline"
  },
  {
    uuid: "b7ae4320-55d2-49d0-a4b1-7ec84435bc42",
    iconSet: "MaterialCommunityIcons",
    iconName: "square-off"
  },
  {
    uuid: "531cbce4-bd2d-45c2-9dd7-5cb498bd2a64",
    iconSet: "MaterialCommunityIcons",
    iconName: "square-off-outline"
  },
  {
    uuid: "09558b34-ee01-4966-ac07-bf71a38f828a",
    iconSet: "MaterialCommunityIcons",
    iconName: "square-outline"
  },
  {
    uuid: "1a9c1dbd-b7e5-43bd-9bfb-880f81855df2",
    iconSet: "MaterialCommunityIcons",
    iconName: "square-root"
  },
  {
    uuid: "7f079851-b3a2-44e2-9684-a390704cb1da",
    iconSet: "MaterialCommunityIcons",
    iconName: "square-root-box"
  },
  {
    uuid: "210cf01e-bf6d-4fcf-b6f2-15fd060598ac",
    iconSet: "MaterialCommunityIcons",
    iconName: "square-small"
  },
  {
    uuid: "658afa9d-f642-4f11-98bb-af5c51cbe323",
    iconSet: "MaterialCommunityIcons",
    iconName: "square-wave"
  },
  {
    uuid: "743a6e8d-dd05-4234-99d2-3f1993c0fce4",
    iconSet: "MaterialCommunityIcons",
    iconName: "squeegee"
  },
  {
    uuid: "aa2369ac-a451-4f0e-ae7d-bdad910c096b",
    iconSet: "MaterialCommunityIcons",
    iconName: "ssh"
  },
  {
    uuid: "03b85267-930b-40c4-8140-af695a7a7160",
    iconSet: "MaterialCommunityIcons",
    iconName: "stack-exchange"
  },
  {
    uuid: "68b54f26-76f3-40b1-9f15-9f54a6fe5452",
    iconSet: "MaterialCommunityIcons",
    iconName: "stack-overflow"
  },
  {
    uuid: "026ed9e8-acd4-454a-af3f-0ee5644bb889",
    iconSet: "MaterialCommunityIcons",
    iconName: "stackpath"
  },
  {
    uuid: "8fda5034-491d-43bf-8a47-f663d06c9448",
    iconSet: "MaterialCommunityIcons",
    iconName: "stadium"
  },
  {
    uuid: "9906bfdc-06ad-4b3e-8160-2b2d4a62ebdf",
    iconSet: "MaterialCommunityIcons",
    iconName: "stadium-variant"
  },
  {
    uuid: "66d26a2a-2dc1-4301-967c-07ed326503fa",
    iconSet: "MaterialCommunityIcons",
    iconName: "stairs"
  },
  {
    uuid: "9ab0d9b0-0ace-42ef-b5ec-979367f1ddfd",
    iconSet: "MaterialCommunityIcons",
    iconName: "stairs-box"
  },
  {
    uuid: "15a833ba-e686-4d53-a61e-cc905bc80954",
    iconSet: "MaterialCommunityIcons",
    iconName: "stairs-down"
  },
  {
    uuid: "b9b1888c-3486-434b-bdfa-da4a2165e055",
    iconSet: "MaterialCommunityIcons",
    iconName: "stairs-up"
  },
  {
    uuid: "44295dc2-ee81-4816-96e0-65e02aa135fb",
    iconSet: "MaterialCommunityIcons",
    iconName: "stamper"
  },
  {
    uuid: "090add8c-2fce-429a-9916-c60af6848be5",
    iconSet: "MaterialCommunityIcons",
    iconName: "standard-definition"
  },
  {
    uuid: "57880eb1-00b6-42ef-86ca-1c7fd62d641b",
    iconSet: "MaterialCommunityIcons",
    iconName: "star"
  },
  {
    uuid: "913ddad9-d3b2-4238-bf22-191610d66b96",
    iconSet: "MaterialCommunityIcons",
    iconName: "star-box"
  },
  {
    uuid: "98df1976-eadb-4726-af7d-afad67989d1f",
    iconSet: "MaterialCommunityIcons",
    iconName: "star-box-multiple"
  },
  {
    uuid: "7c63735a-0a70-4d35-a15c-f66e51063ebe",
    iconSet: "MaterialCommunityIcons",
    iconName: "star-box-multiple-outline"
  },
  {
    uuid: "988135f8-b8c3-4b37-8de3-e81caf1ad4f6",
    iconSet: "MaterialCommunityIcons",
    iconName: "star-box-outline"
  },
  {
    uuid: "7aaa3c89-ecf3-4402-9481-0ff5b5c4b937",
    iconSet: "MaterialCommunityIcons",
    iconName: "star-circle"
  },
  {
    uuid: "e376ee76-fb25-4b00-a5ec-3956b500e899",
    iconSet: "MaterialCommunityIcons",
    iconName: "star-circle-outline"
  },
  {
    uuid: "02ed90b1-7b59-4686-945d-e5138ddad2e0",
    iconSet: "MaterialCommunityIcons",
    iconName: "star-face"
  },
  {
    uuid: "a9551740-6760-4852-b0b2-a0f47be140bf",
    iconSet: "MaterialCommunityIcons",
    iconName: "star-four-points"
  },
  {
    uuid: "194283d9-dd15-4dd3-ae35-62029dc08f61",
    iconSet: "MaterialCommunityIcons",
    iconName: "star-four-points-outline"
  },
  {
    uuid: "3c470a0c-3968-45d0-8ad4-99cb4cac1ea8",
    iconSet: "MaterialCommunityIcons",
    iconName: "star-half"
  },
  {
    uuid: "4e843355-9ac8-459d-ab58-26855ea34968",
    iconSet: "MaterialCommunityIcons",
    iconName: "star-half-full"
  },
  {
    uuid: "26e42a79-dc06-45fe-ac03-51aaf2cf0e51",
    iconSet: "MaterialCommunityIcons",
    iconName: "star-off"
  },
  {
    uuid: "a000b621-3af6-4906-bb51-1352fd9a6c27",
    iconSet: "MaterialCommunityIcons",
    iconName: "star-outline"
  },
  {
    uuid: "2a6c5064-d7a0-4206-bd44-abefd8a63493",
    iconSet: "MaterialCommunityIcons",
    iconName: "star-three-points"
  },
  {
    uuid: "79682b72-c73e-47e5-96f9-9fefa9bfdf83",
    iconSet: "MaterialCommunityIcons",
    iconName: "star-three-points-outline"
  },
  {
    uuid: "694d0d12-a594-4848-b6ef-fe2f403af014",
    iconSet: "MaterialCommunityIcons",
    iconName: "state-machine"
  },
  {
    uuid: "47bb1fb7-44d9-4b04-8f84-79af562eb643",
    iconSet: "MaterialCommunityIcons",
    iconName: "steam"
  },
  {
    uuid: "ca96bf39-4d57-4025-ab11-ffcf6debd63d",
    iconSet: "MaterialCommunityIcons",
    iconName: "steering"
  },
  {
    uuid: "3af68270-10db-49c3-94ca-56c9a666ba76",
    iconSet: "MaterialCommunityIcons",
    iconName: "steering-off"
  },
  {
    uuid: "dca72139-a99d-4bfd-89c2-de74ec8bd672",
    iconSet: "MaterialCommunityIcons",
    iconName: "step-backward"
  },
  {
    uuid: "2a339d58-9f76-4c65-a04c-40aa6a8bc056",
    iconSet: "MaterialCommunityIcons",
    iconName: "step-backward-2"
  },
  {
    uuid: "b8b47053-5ce7-47ce-b865-7c0c1fb4ddcf",
    iconSet: "MaterialCommunityIcons",
    iconName: "step-forward"
  },
  {
    uuid: "dd43c2d9-2d14-4a69-a3ac-f957df7cec15",
    iconSet: "MaterialCommunityIcons",
    iconName: "step-forward-2"
  },
  {
    uuid: "f3bf2c7a-ddb3-40ac-8641-264c86c49607",
    iconSet: "MaterialCommunityIcons",
    iconName: "stethoscope"
  },
  {
    uuid: "b816e0dc-129a-45a6-bbad-ae028ab04973",
    iconSet: "MaterialCommunityIcons",
    iconName: "sticker"
  },
  {
    uuid: "ba1f2d25-99ff-475f-a159-89b77055fb8a",
    iconSet: "MaterialCommunityIcons",
    iconName: "sticker-alert"
  },
  {
    uuid: "c14b6812-e64b-436a-b7e5-02689674eea3",
    iconSet: "MaterialCommunityIcons",
    iconName: "sticker-alert-outline"
  },
  {
    uuid: "b09281a9-7f66-41ac-8d24-ccd6b2fd3e65",
    iconSet: "MaterialCommunityIcons",
    iconName: "sticker-check"
  },
  {
    uuid: "f0846fad-7ab5-4c0e-b949-84b9b604fc42",
    iconSet: "MaterialCommunityIcons",
    iconName: "sticker-check-outline"
  },
  {
    uuid: "56f5ace4-3228-466d-a6d4-9e316c1138da",
    iconSet: "MaterialCommunityIcons",
    iconName: "sticker-circle-outline"
  },
  {
    uuid: "c484aa32-513e-4a5a-8697-ec3d63d87575",
    iconSet: "MaterialCommunityIcons",
    iconName: "sticker-emoji"
  },
  {
    uuid: "b842891b-e34b-425b-842d-d4efe137149c",
    iconSet: "MaterialCommunityIcons",
    iconName: "sticker-minus"
  },
  {
    uuid: "977d5157-6aad-462c-bf9c-24c14d5e5a60",
    iconSet: "MaterialCommunityIcons",
    iconName: "sticker-minus-outline"
  },
  {
    uuid: "664b6c1d-8b0b-4d20-91ac-df759296d20f",
    iconSet: "MaterialCommunityIcons",
    iconName: "sticker-outline"
  },
  {
    uuid: "47303437-8588-4ac6-8545-121f4d531c3f",
    iconSet: "MaterialCommunityIcons",
    iconName: "sticker-plus"
  },
  {
    uuid: "b16dea46-2616-40d0-b215-3985aeebc6b3",
    iconSet: "MaterialCommunityIcons",
    iconName: "sticker-plus-outline"
  },
  {
    uuid: "1aa6f2e9-faca-4657-95e4-352f50dc3bd0",
    iconSet: "MaterialCommunityIcons",
    iconName: "sticker-remove"
  },
  {
    uuid: "0b58a76b-d062-4012-ad81-4211ded0790b",
    iconSet: "MaterialCommunityIcons",
    iconName: "sticker-remove-outline"
  },
  {
    uuid: "87967263-7e3f-4e53-b8e2-71e653fdf94f",
    iconSet: "MaterialCommunityIcons",
    iconName: "stocking"
  },
  {
    uuid: "b6995ac4-79ee-4537-8438-1df3ae58e86d",
    iconSet: "MaterialCommunityIcons",
    iconName: "stomach"
  },
  {
    uuid: "41495cbe-7456-468a-b229-59575b91b6d1",
    iconSet: "MaterialCommunityIcons",
    iconName: "stop"
  },
  {
    uuid: "7d8ed9c2-4102-4999-a922-d8a11925ef50",
    iconSet: "MaterialCommunityIcons",
    iconName: "stop-circle"
  },
  {
    uuid: "be83f2b8-af04-4b01-a00a-eb4597986228",
    iconSet: "MaterialCommunityIcons",
    iconName: "stop-circle-outline"
  },
  {
    uuid: "70c2018e-db46-487b-aa4b-ea11616887d8",
    iconSet: "MaterialCommunityIcons",
    iconName: "store"
  },
  {
    uuid: "72b3d4b0-c9ca-4425-8cb3-4326687cf4fd",
    iconSet: "MaterialCommunityIcons",
    iconName: "store-24-hour"
  },
  {
    uuid: "d67ec0f1-b6b5-4f0d-bc16-62db0f7812b7",
    iconSet: "MaterialCommunityIcons",
    iconName: "store-outline"
  },
  {
    uuid: "11713858-b8ee-41d3-8e46-b7f4301ec690",
    iconSet: "MaterialCommunityIcons",
    iconName: "storefront"
  },
  {
    uuid: "202510ac-c444-4a06-b44c-c3582a0ea3d5",
    iconSet: "MaterialCommunityIcons",
    iconName: "storefront-outline"
  },
  {
    uuid: "0db0e584-9226-48bf-be53-e7344a3386e8",
    iconSet: "MaterialCommunityIcons",
    iconName: "stove"
  },
  {
    uuid: "7a390943-27e0-4c87-98da-39c06c14d171",
    iconSet: "MaterialCommunityIcons",
    iconName: "strategy"
  },
  {
    uuid: "d9087b86-77e1-4205-9626-af7c9c6637f2",
    iconSet: "MaterialCommunityIcons",
    iconName: "stretch-to-page"
  },
  {
    uuid: "7f5edf49-ee10-4de1-a78f-5c52f25b2a38",
    iconSet: "MaterialCommunityIcons",
    iconName: "stretch-to-page-outline"
  },
  {
    uuid: "3bd86b97-0718-4992-8b46-c88266a60731",
    iconSet: "MaterialCommunityIcons",
    iconName: "string-lights"
  },
  {
    uuid: "e53c83fd-9034-475a-a5f6-e87ade90ddcd",
    iconSet: "MaterialCommunityIcons",
    iconName: "string-lights-off"
  },
  {
    uuid: "b03b424b-5754-49c5-a936-c97420fcfd00",
    iconSet: "MaterialCommunityIcons",
    iconName: "subdirectory-arrow-left"
  },
  {
    uuid: "71f91cd2-7a4c-4db6-b5fa-f9649b091d72",
    iconSet: "MaterialCommunityIcons",
    iconName: "subdirectory-arrow-right"
  },
  {
    uuid: "f0c68b1d-637b-46ef-a61e-c4861c094de4",
    iconSet: "MaterialCommunityIcons",
    iconName: "subtitles"
  },
  {
    uuid: "a659f8db-a3e6-4e1b-841b-385be32b308c",
    iconSet: "MaterialCommunityIcons",
    iconName: "subtitles-outline"
  },
  {
    uuid: "bbe62d48-f580-4ad3-9649-dc31e8079f71",
    iconSet: "MaterialCommunityIcons",
    iconName: "subway"
  },
  {
    uuid: "d05ea764-d58b-4fa3-9522-337c8a1a0ba9",
    iconSet: "MaterialCommunityIcons",
    iconName: "subway-alert-variant"
  },
  {
    uuid: "2b9144a8-aec5-4380-9635-b79edf94a393",
    iconSet: "MaterialCommunityIcons",
    iconName: "subway-variant"
  },
  {
    uuid: "861f732c-2f17-4bfc-8ce0-480625c433e8",
    iconSet: "MaterialCommunityIcons",
    iconName: "summit"
  },
  {
    uuid: "51b15caf-8577-483d-ac61-93fd241356aa",
    iconSet: "MaterialCommunityIcons",
    iconName: "sunglasses"
  },
  {
    uuid: "0ac2fd06-aae9-42f6-81d2-6867b9246ad7",
    iconSet: "MaterialCommunityIcons",
    iconName: "surround-sound"
  },
  {
    uuid: "53bc81e4-4b8f-4a5a-9d60-e31970b84e69",
    iconSet: "MaterialCommunityIcons",
    iconName: "surround-sound-2-0"
  },
  {
    uuid: "5f917444-6bbc-49eb-a473-cb42a347da06",
    iconSet: "MaterialCommunityIcons",
    iconName: "surround-sound-3-1"
  },
  {
    uuid: "6af3aac7-d139-4788-bb49-71dfa8eb5c58",
    iconSet: "MaterialCommunityIcons",
    iconName: "surround-sound-5-1"
  },
  {
    uuid: "737f29b6-7143-46e7-9fe9-98cf958da878",
    iconSet: "MaterialCommunityIcons",
    iconName: "surround-sound-7-1"
  },
  {
    uuid: "591312b8-ecfd-4489-9829-fa941249edfb",
    iconSet: "MaterialCommunityIcons",
    iconName: "svg"
  },
  {
    uuid: "bee1810d-3f26-4f9e-b471-97d22162686a",
    iconSet: "MaterialCommunityIcons",
    iconName: "swap-horizontal"
  },
  {
    uuid: "139bbdca-780a-429f-b063-d70771b82762",
    iconSet: "MaterialCommunityIcons",
    iconName: "swap-horizontal-bold"
  },
  {
    uuid: "14d587f0-9b3a-4f9c-9c0f-b4e38a4009ac",
    iconSet: "MaterialCommunityIcons",
    iconName: "swap-horizontal-circle"
  },
  {
    uuid: "7cf38d3a-0077-4c87-a40a-1585b2c12d1d",
    iconSet: "MaterialCommunityIcons",
    iconName: "swap-horizontal-circle-outline"
  },
  {
    uuid: "bc9ba957-d232-4a5d-b7c3-1d578924d14a",
    iconSet: "MaterialCommunityIcons",
    iconName: "swap-horizontal-variant"
  },
  {
    uuid: "d06b15b2-5a04-44a0-99e2-184abfd8b9ce",
    iconSet: "MaterialCommunityIcons",
    iconName: "swap-vertical"
  },
  {
    uuid: "9abf3aff-8351-4c89-ae33-305c0e8e783e",
    iconSet: "MaterialCommunityIcons",
    iconName: "swap-vertical-bold"
  },
  {
    uuid: "7b8a50aa-26fd-4fdf-83f1-715e1b7dd158",
    iconSet: "MaterialCommunityIcons",
    iconName: "swap-vertical-circle"
  },
  {
    uuid: "ea102dd9-f25b-4480-940b-df271b4efb94",
    iconSet: "MaterialCommunityIcons",
    iconName: "swap-vertical-circle-outline"
  },
  {
    uuid: "018b0d91-c134-4422-8175-690ef02a03cd",
    iconSet: "MaterialCommunityIcons",
    iconName: "swap-vertical-variant"
  },
  {
    uuid: "2fee2d8a-8c85-4bba-9af9-1b19c96be521",
    iconSet: "MaterialCommunityIcons",
    iconName: "swim"
  },
  {
    uuid: "8858b77b-3431-4ce2-bd82-269eefdf3e6e",
    iconSet: "MaterialCommunityIcons",
    iconName: "switch"
  },
  {
    uuid: "c115a355-a377-433a-acf7-355016a54cf3",
    iconSet: "MaterialCommunityIcons",
    iconName: "sword"
  },
  {
    uuid: "95762854-b455-4bf1-b442-117f7f707222",
    iconSet: "MaterialCommunityIcons",
    iconName: "sword-cross"
  },
  {
    uuid: "fff91c63-e3af-489b-834c-8bc52d5d806c",
    iconSet: "MaterialCommunityIcons",
    iconName: "syllabary-hangul"
  },
  {
    uuid: "f51d3e63-bdab-4f34-8ef4-866941817d12",
    iconSet: "MaterialCommunityIcons",
    iconName: "syllabary-hiragana"
  },
  {
    uuid: "25fd5b6c-52c6-4956-9d6a-732b3daf5ea4",
    iconSet: "MaterialCommunityIcons",
    iconName: "syllabary-katakana"
  },
  {
    uuid: "573946cc-fad8-426f-83a4-64b172fc7566",
    iconSet: "MaterialCommunityIcons",
    iconName: "syllabary-katakana-halfwidth"
  },
  {
    uuid: "82af37e8-6dc6-480d-92ed-d69c02903ace",
    iconSet: "MaterialCommunityIcons",
    iconName: "symfony"
  },
  {
    uuid: "b4a93b28-532a-4420-b021-3269627ba2a1",
    iconSet: "MaterialCommunityIcons",
    iconName: "sync"
  },
  {
    uuid: "c88fae41-72f9-4afa-bd0a-73c1c7829e38",
    iconSet: "MaterialCommunityIcons",
    iconName: "sync-alert"
  },
  {
    uuid: "adae70c9-c7d2-4e8e-87d8-92e8cc1ffabb",
    iconSet: "MaterialCommunityIcons",
    iconName: "sync-circle"
  },
  {
    uuid: "301c7a9d-9510-41f1-836f-1b036c73058f",
    iconSet: "MaterialCommunityIcons",
    iconName: "sync-off"
  },
  {
    uuid: "63a75a2e-a60e-45ff-92d9-773c89818bfa",
    iconSet: "MaterialCommunityIcons",
    iconName: "tab"
  },
  {
    uuid: "dc2cdb50-d71c-4ded-a53a-401b774472ed",
    iconSet: "MaterialCommunityIcons",
    iconName: "tab-minus"
  },
  {
    uuid: "e7331e41-e80b-4b6b-b58d-ede5be42e971",
    iconSet: "MaterialCommunityIcons",
    iconName: "tab-plus"
  },
  {
    uuid: "6926c65c-9073-4d45-bbdb-d453c50e2511",
    iconSet: "MaterialCommunityIcons",
    iconName: "tab-remove"
  },
  {
    uuid: "f92fdb1d-e9a9-47c6-be21-b9965a02a1ab",
    iconSet: "MaterialCommunityIcons",
    iconName: "tab-unselected"
  },
  {
    uuid: "9abaa60a-bcbb-4f26-afeb-6cc707c6a509",
    iconSet: "MaterialCommunityIcons",
    iconName: "table"
  },
  {
    uuid: "cdd56205-9403-429d-ae32-9c39d14e94c7",
    iconSet: "MaterialCommunityIcons",
    iconName: "table-account"
  },
  {
    uuid: "52cf4f5c-ca04-4378-a1e0-7f6530527cab",
    iconSet: "MaterialCommunityIcons",
    iconName: "table-alert"
  },
  {
    uuid: "d8c199f7-3d74-4198-a877-bc2d2448a5c7",
    iconSet: "MaterialCommunityIcons",
    iconName: "table-arrow-down"
  },
  {
    uuid: "a4f4e607-1138-4892-bfc3-d4a0a10d8dbd",
    iconSet: "MaterialCommunityIcons",
    iconName: "table-arrow-left"
  },
  {
    uuid: "04bbd50e-2518-40e8-8e7d-5367a267f7d9",
    iconSet: "MaterialCommunityIcons",
    iconName: "table-arrow-right"
  },
  {
    uuid: "b498b13e-ed70-41d0-9ec2-038b02e309fa",
    iconSet: "MaterialCommunityIcons",
    iconName: "table-arrow-up"
  },
  {
    uuid: "245dfae1-76ed-4458-bd0b-63af26152c71",
    iconSet: "MaterialCommunityIcons",
    iconName: "table-border"
  },
  {
    uuid: "40e3ef8e-779e-457d-9a62-f255e9f2fcbb",
    iconSet: "MaterialCommunityIcons",
    iconName: "table-cancel"
  },
  {
    uuid: "6917e1a5-da78-487e-a606-77746882540a",
    iconSet: "MaterialCommunityIcons",
    iconName: "table-chair"
  },
  {
    uuid: "603b4e30-f187-41f1-b698-2dc1956ab8a2",
    iconSet: "MaterialCommunityIcons",
    iconName: "table-check"
  },
  {
    uuid: "45eafeb9-bc02-41ab-bf6a-7e53119e9fef",
    iconSet: "MaterialCommunityIcons",
    iconName: "table-clock"
  },
  {
    uuid: "ab1ee50c-ba4a-4c25-8759-ec10901b56fe",
    iconSet: "MaterialCommunityIcons",
    iconName: "table-cog"
  },
  {
    uuid: "0a4339e1-892a-49f2-9d92-66096ba504c2",
    iconSet: "MaterialCommunityIcons",
    iconName: "table-column"
  },
  {
    uuid: "419e4670-4dc0-4bd8-a7f8-5e90c9f53412",
    iconSet: "MaterialCommunityIcons",
    iconName: "table-column-plus-after"
  },
  {
    uuid: "c9bb1cd2-46fb-4674-8598-a215794dcad7",
    iconSet: "MaterialCommunityIcons",
    iconName: "table-column-plus-before"
  },
  {
    uuid: "b4d1e62c-517c-4121-82ff-fd12257aeccc",
    iconSet: "MaterialCommunityIcons",
    iconName: "table-column-remove"
  },
  {
    uuid: "74a7af6e-34b4-4385-93fe-422423688d52",
    iconSet: "MaterialCommunityIcons",
    iconName: "table-column-width"
  },
  {
    uuid: "e9b9e0fc-10b7-493c-943a-e4d7098ccece",
    iconSet: "MaterialCommunityIcons",
    iconName: "table-edit"
  },
  {
    uuid: "c9cb16fe-a302-441a-a346-cc2afb7eaf10",
    iconSet: "MaterialCommunityIcons",
    iconName: "table-eye"
  },
  {
    uuid: "c18732ac-d07d-475e-892b-7838a8b8b0a0",
    iconSet: "MaterialCommunityIcons",
    iconName: "table-eye-off"
  },
  {
    uuid: "d04353bd-03d4-4589-9a44-4ce129fcb2eb",
    iconSet: "MaterialCommunityIcons",
    iconName: "table-furniture"
  },
  {
    uuid: "770a53e4-98c1-4375-b357-9e8cc211b240",
    iconSet: "MaterialCommunityIcons",
    iconName: "table-headers-eye"
  },
  {
    uuid: "192f9bc2-29b0-4b08-b0d5-47e65347f007",
    iconSet: "MaterialCommunityIcons",
    iconName: "table-headers-eye-off"
  },
  {
    uuid: "fa84c1c0-d1d0-471a-958d-16282cc0dee5",
    iconSet: "MaterialCommunityIcons",
    iconName: "table-heart"
  },
  {
    uuid: "9fd39bde-e61a-476c-a1bc-281afa2310bf",
    iconSet: "MaterialCommunityIcons",
    iconName: "table-key"
  },
  {
    uuid: "eea89210-3bc1-4493-a0c8-11ba926060f9",
    iconSet: "MaterialCommunityIcons",
    iconName: "table-large"
  },
  {
    uuid: "cb099111-3d15-48f1-82ec-11883da2f2e5",
    iconSet: "MaterialCommunityIcons",
    iconName: "table-large-plus"
  },
  {
    uuid: "c5d2eb0a-e49d-4069-aa7e-2df4f1b9a746",
    iconSet: "MaterialCommunityIcons",
    iconName: "table-large-remove"
  },
  {
    uuid: "b31d83c1-a13f-4ff9-90d4-66afb540ad45",
    iconSet: "MaterialCommunityIcons",
    iconName: "table-lock"
  },
  {
    uuid: "e89edfb7-c4e9-490b-8c8b-adad7747c849",
    iconSet: "MaterialCommunityIcons",
    iconName: "table-merge-cells"
  },
  {
    uuid: "1f17324c-3b40-472f-bd30-a81f11d1794a",
    iconSet: "MaterialCommunityIcons",
    iconName: "table-minus"
  },
  {
    uuid: "9ca2fb02-bad5-43c3-a8db-dd64ee2db26d",
    iconSet: "MaterialCommunityIcons",
    iconName: "table-multiple"
  },
  {
    uuid: "3fc7b9cd-905f-4900-8091-175d4ecc7df1",
    iconSet: "MaterialCommunityIcons",
    iconName: "table-network"
  },
  {
    uuid: "c21028dc-779a-465c-a622-7d76dca818be",
    iconSet: "MaterialCommunityIcons",
    iconName: "table-of-contents"
  },
  {
    uuid: "6a25dc12-5840-4b10-86a7-da97a3e9ad01",
    iconSet: "MaterialCommunityIcons",
    iconName: "table-off"
  },
  {
    uuid: "72f70736-43a5-426c-8c66-9210545d8dbd",
    iconSet: "MaterialCommunityIcons",
    iconName: "table-plus"
  },
  {
    uuid: "23c6477c-4b85-4633-86b9-7b0a7a4cd456",
    iconSet: "MaterialCommunityIcons",
    iconName: "table-refresh"
  },
  {
    uuid: "c8c31d03-a4cb-4064-b05a-531f10c8a460",
    iconSet: "MaterialCommunityIcons",
    iconName: "table-remove"
  },
  {
    uuid: "facc3b62-5e37-4206-83c9-ba426b7ed069",
    iconSet: "MaterialCommunityIcons",
    iconName: "table-row"
  },
  {
    uuid: "248bb09a-e1f2-4cdd-91e2-e3f5a44b69af",
    iconSet: "MaterialCommunityIcons",
    iconName: "table-row-height"
  },
  {
    uuid: "d577558e-1345-4c45-a6f3-7cef6cf8fd2b",
    iconSet: "MaterialCommunityIcons",
    iconName: "table-row-plus-after"
  },
  {
    uuid: "38caefed-dc91-4731-b7c1-a0d971edf258",
    iconSet: "MaterialCommunityIcons",
    iconName: "table-row-plus-before"
  },
  {
    uuid: "47b4a151-3001-4f73-891a-0d3bf00232d5",
    iconSet: "MaterialCommunityIcons",
    iconName: "table-row-remove"
  },
  {
    uuid: "df85a3d8-f574-4ef1-92b5-4e0ef7d11521",
    iconSet: "MaterialCommunityIcons",
    iconName: "table-search"
  },
  {
    uuid: "ff17ea7b-abcc-4b62-86b5-d92fb6f15b2c",
    iconSet: "MaterialCommunityIcons",
    iconName: "table-settings"
  },
  {
    uuid: "672dee67-5eff-4b19-9a44-efa749a4395c",
    iconSet: "MaterialCommunityIcons",
    iconName: "table-split-cell"
  },
  {
    uuid: "a2be463a-af0d-48e9-9f18-2c65d37f5eb8",
    iconSet: "MaterialCommunityIcons",
    iconName: "table-star"
  },
  {
    uuid: "139e931d-0e55-495c-a9cb-bfeb3d04a0cf",
    iconSet: "MaterialCommunityIcons",
    iconName: "table-sync"
  },
  {
    uuid: "2a4e923f-0820-4ddf-a979-0630548b2ef2",
    iconSet: "MaterialCommunityIcons",
    iconName: "table-tennis"
  },
  {
    uuid: "b3ff607f-9c6d-4ec1-8e75-f9b4d6a7eb53",
    iconSet: "MaterialCommunityIcons",
    iconName: "tablet"
  },
  {
    uuid: "c44c072c-4793-4015-abb9-1f5da2485f32",
    iconSet: "MaterialCommunityIcons",
    iconName: "tablet-android"
  },
  {
    uuid: "aef00361-ce75-4411-bbce-74dc535fe5e8",
    iconSet: "MaterialCommunityIcons",
    iconName: "tablet-cellphone"
  },
  {
    uuid: "aa52668f-b772-40a2-8a52-7dad1171ea30",
    iconSet: "MaterialCommunityIcons",
    iconName: "tablet-dashboard"
  },
  {
    uuid: "0b0a092d-2152-4ca0-967d-bcce8f8c2066",
    iconSet: "MaterialCommunityIcons",
    iconName: "tablet-ipad"
  },
  {
    uuid: "328a494b-d5c6-4ba3-862f-d09592a93f5d",
    iconSet: "MaterialCommunityIcons",
    iconName: "taco"
  },
  {
    uuid: "8257b91d-0b27-4528-bcdb-95ddc65b6a6c",
    iconSet: "MaterialCommunityIcons",
    iconName: "tag"
  },
  {
    uuid: "d9511413-f468-4d6d-8442-dee264c03fe8",
    iconSet: "MaterialCommunityIcons",
    iconName: "tag-faces"
  },
  {
    uuid: "1ebb769e-4e13-487d-9fb0-13ec0078f57a",
    iconSet: "MaterialCommunityIcons",
    iconName: "tag-heart"
  },
  {
    uuid: "0714ec52-6f68-4980-b199-614673cb6710",
    iconSet: "MaterialCommunityIcons",
    iconName: "tag-heart-outline"
  },
  {
    uuid: "904be8ad-265b-4aca-901a-cbcb805b8042",
    iconSet: "MaterialCommunityIcons",
    iconName: "tag-minus"
  },
  {
    uuid: "35e33617-0c6d-4ab3-a869-0f6b393cffc3",
    iconSet: "MaterialCommunityIcons",
    iconName: "tag-minus-outline"
  },
  {
    uuid: "751406ce-bce7-4bce-abff-0a408df4b669",
    iconSet: "MaterialCommunityIcons",
    iconName: "tag-multiple"
  },
  {
    uuid: "85d0f3bb-4a7a-4891-9696-d15a33aae854",
    iconSet: "MaterialCommunityIcons",
    iconName: "tag-multiple-outline"
  },
  {
    uuid: "5b2c6388-ae35-4e7b-8396-3b4ffab3080c",
    iconSet: "MaterialCommunityIcons",
    iconName: "tag-off"
  },
  {
    uuid: "bcd18142-6c0f-46c6-993b-8691e25f9c08",
    iconSet: "MaterialCommunityIcons",
    iconName: "tag-off-outline"
  },
  {
    uuid: "5b2cde02-5386-45fe-a244-c84f07fb238c",
    iconSet: "MaterialCommunityIcons",
    iconName: "tag-outline"
  },
  {
    uuid: "b5799fb4-21d7-4326-89eb-162285187fd7",
    iconSet: "MaterialCommunityIcons",
    iconName: "tag-plus"
  },
  {
    uuid: "c561687e-511f-4e59-add3-0ed4c76f16a4",
    iconSet: "MaterialCommunityIcons",
    iconName: "tag-plus-outline"
  },
  {
    uuid: "a383911a-e4ea-44de-9138-0ae506b2dd72",
    iconSet: "MaterialCommunityIcons",
    iconName: "tag-remove"
  },
  {
    uuid: "50952c09-8780-4d37-a553-7d1a41aebb59",
    iconSet: "MaterialCommunityIcons",
    iconName: "tag-remove-outline"
  },
  {
    uuid: "aa3875a9-b2e8-4de1-8e86-8031e3be8786",
    iconSet: "MaterialCommunityIcons",
    iconName: "tag-text"
  },
  {
    uuid: "9123eaa0-3643-47e8-aa12-325114791944",
    iconSet: "MaterialCommunityIcons",
    iconName: "tag-text-outline"
  },
  {
    uuid: "2f4d25af-7c51-4c29-ad88-033a2f8f9e98",
    iconSet: "MaterialCommunityIcons",
    iconName: "tailwind"
  },
  {
    uuid: "c1c607df-ed94-47fb-afc0-068ce14a2824",
    iconSet: "MaterialCommunityIcons",
    iconName: "tank"
  },
  {
    uuid: "be382a3a-1dfe-4a1f-b67a-854fddda370f",
    iconSet: "MaterialCommunityIcons",
    iconName: "tanker-truck"
  },
  {
    uuid: "e1df442d-090a-483f-857e-e942b2251ff0",
    iconSet: "MaterialCommunityIcons",
    iconName: "tape-measure"
  },
  {
    uuid: "23419e1c-fdc0-48de-9481-c0c997917fc4",
    iconSet: "MaterialCommunityIcons",
    iconName: "target"
  },
  {
    uuid: "9561ecba-0bb9-4f9a-9cde-6667e66e8353",
    iconSet: "MaterialCommunityIcons",
    iconName: "target-account"
  },
  {
    uuid: "5aabf254-04cc-4408-a1b2-34a5cd9dbc6b",
    iconSet: "MaterialCommunityIcons",
    iconName: "target-variant"
  },
  {
    uuid: "cbf239af-a46f-4987-ba77-4ca1432b067b",
    iconSet: "MaterialCommunityIcons",
    iconName: "taxi"
  },
  {
    uuid: "e190eb0d-7220-428c-9025-fb380c31268d",
    iconSet: "MaterialCommunityIcons",
    iconName: "tea"
  },
  {
    uuid: "ca394379-72b4-455e-af50-27eddb6060e4",
    iconSet: "MaterialCommunityIcons",
    iconName: "tea-outline"
  },
  {
    uuid: "3a316cef-d08e-461b-87b9-cd589ec6c83f",
    iconSet: "MaterialCommunityIcons",
    iconName: "teach"
  },
  {
    uuid: "7c7cacad-e9e5-4860-a565-c4613667d317",
    iconSet: "MaterialCommunityIcons",
    iconName: "teamviewer"
  },
  {
    uuid: "84314e1e-b099-4f06-934c-d6e24df1070b",
    iconSet: "MaterialCommunityIcons",
    iconName: "telegram"
  },
  {
    uuid: "7ad6fdec-d267-4627-8e3c-f3be1f64c73d",
    iconSet: "MaterialCommunityIcons",
    iconName: "telescope"
  },
  {
    uuid: "97c99183-9340-4cf9-a753-b52cbe656976",
    iconSet: "MaterialCommunityIcons",
    iconName: "television"
  },
  {
    uuid: "1988c059-6e10-42cf-ad77-c23a40c0bb81",
    iconSet: "MaterialCommunityIcons",
    iconName: "television-ambient-light"
  },
  {
    uuid: "b1a75c73-9a91-4c14-9cdb-8dc8087cc802",
    iconSet: "MaterialCommunityIcons",
    iconName: "television-box"
  },
  {
    uuid: "1495792b-4305-4772-98f9-2e2de3d2ed68",
    iconSet: "MaterialCommunityIcons",
    iconName: "television-classic"
  },
  {
    uuid: "b8c4f08c-b914-44e1-980d-65fbb256f6ec",
    iconSet: "MaterialCommunityIcons",
    iconName: "television-classic-off"
  },
  {
    uuid: "e3d7afc1-69d6-47a5-a794-40a2196c2726",
    iconSet: "MaterialCommunityIcons",
    iconName: "television-clean"
  },
  {
    uuid: "61905016-7495-4131-b9f3-29e8b88a6950",
    iconSet: "MaterialCommunityIcons",
    iconName: "television-guide"
  },
  {
    uuid: "de606681-ac82-46a8-bcb5-d150615f0fdb",
    iconSet: "MaterialCommunityIcons",
    iconName: "television-off"
  },
  {
    uuid: "5da7139b-dc1f-4766-a2b3-2de1401d7ef5",
    iconSet: "MaterialCommunityIcons",
    iconName: "television-pause"
  },
  {
    uuid: "269caaa5-8035-46f9-8bba-ad589d7d1f8d",
    iconSet: "MaterialCommunityIcons",
    iconName: "television-play"
  },
  {
    uuid: "adf3761d-bcef-40bb-8708-67c5727bc369",
    iconSet: "MaterialCommunityIcons",
    iconName: "television-stop"
  },
  {
    uuid: "ba0bcd32-9cfa-45ea-9890-1578ba93754b",
    iconSet: "MaterialCommunityIcons",
    iconName: "temperature-celsius"
  },
  {
    uuid: "63b6783b-084b-4d8b-9bee-96d236d0c071",
    iconSet: "MaterialCommunityIcons",
    iconName: "temperature-fahrenheit"
  },
  {
    uuid: "5565ebf1-a41a-4c2a-9955-9e0c6af1054f",
    iconSet: "MaterialCommunityIcons",
    iconName: "temperature-kelvin"
  },
  {
    uuid: "8a193ddf-08ec-4b9b-804a-c4395625c72a",
    iconSet: "MaterialCommunityIcons",
    iconName: "tennis"
  },
  {
    uuid: "1105dd19-a4fe-40f9-8c0c-c66b3d642987",
    iconSet: "MaterialCommunityIcons",
    iconName: "tennis-ball"
  },
  {
    uuid: "609695fd-4401-4235-b42a-7e6f354fefbc",
    iconSet: "MaterialCommunityIcons",
    iconName: "tent"
  },
  {
    uuid: "b02f4b90-2601-4644-b0ec-3c2cbe1322f3",
    iconSet: "MaterialCommunityIcons",
    iconName: "terraform"
  },
  {
    uuid: "61be6c75-6e01-4797-b9e5-39488f7c5d7c",
    iconSet: "MaterialCommunityIcons",
    iconName: "terrain"
  },
  {
    uuid: "4a3232a2-a61c-424d-b01b-13949ae7014e",
    iconSet: "MaterialCommunityIcons",
    iconName: "test-tube"
  },
  {
    uuid: "011a1a75-1e93-4c3f-a2a1-117160c397f0",
    iconSet: "MaterialCommunityIcons",
    iconName: "test-tube-empty"
  },
  {
    uuid: "9e66f014-28ef-4c77-9028-87512daa5d07",
    iconSet: "MaterialCommunityIcons",
    iconName: "test-tube-off"
  },
  {
    uuid: "05501a5a-1907-44a9-afba-c3e7615cb91a",
    iconSet: "MaterialCommunityIcons",
    iconName: "text"
  },
  {
    uuid: "08742513-1935-4962-b492-ac7f6aafb6ad",
    iconSet: "MaterialCommunityIcons",
    iconName: "text-box"
  },
  {
    uuid: "03cbd9d4-fa0d-4e31-bd29-8716603b284b",
    iconSet: "MaterialCommunityIcons",
    iconName: "text-box-check"
  },
  {
    uuid: "f3daaec1-356d-4fb2-a44d-4f185ed45a9c",
    iconSet: "MaterialCommunityIcons",
    iconName: "text-box-check-outline"
  },
  {
    uuid: "97bd9a86-fe75-49c3-8d88-beb2e1f236aa",
    iconSet: "MaterialCommunityIcons",
    iconName: "text-box-minus"
  },
  {
    uuid: "86569a32-e737-47c3-a971-ed10e2f6f972",
    iconSet: "MaterialCommunityIcons",
    iconName: "text-box-minus-outline"
  },
  {
    uuid: "c67b2232-7d12-41c4-847d-6a20a60f0533",
    iconSet: "MaterialCommunityIcons",
    iconName: "text-box-multiple"
  },
  {
    uuid: "52af14c0-0430-497f-944b-3b2fc98697bb",
    iconSet: "MaterialCommunityIcons",
    iconName: "text-box-multiple-outline"
  },
  {
    uuid: "08469135-143c-48a5-88ea-77f7fd93cce5",
    iconSet: "MaterialCommunityIcons",
    iconName: "text-box-outline"
  },
  {
    uuid: "2c617668-73af-4334-aaa8-8603062b6a6e",
    iconSet: "MaterialCommunityIcons",
    iconName: "text-box-plus"
  },
  {
    uuid: "a5be5e37-5c28-43c4-8088-f31b0175b640",
    iconSet: "MaterialCommunityIcons",
    iconName: "text-box-plus-outline"
  },
  {
    uuid: "3bbd5931-6799-477a-9ed0-03541e32d197",
    iconSet: "MaterialCommunityIcons",
    iconName: "text-box-remove"
  },
  {
    uuid: "fcf7efc2-f39b-4127-af5a-1a8ef0243a01",
    iconSet: "MaterialCommunityIcons",
    iconName: "text-box-remove-outline"
  },
  {
    uuid: "ee9eadfa-7a4a-49af-abeb-add400c26d50",
    iconSet: "MaterialCommunityIcons",
    iconName: "text-box-search"
  },
  {
    uuid: "9b7fb189-72f4-48a2-9cee-e992ecb7f4dd",
    iconSet: "MaterialCommunityIcons",
    iconName: "text-box-search-outline"
  },
  {
    uuid: "52aa1bfd-9089-4e51-94ef-c448c333325c",
    iconSet: "MaterialCommunityIcons",
    iconName: "text-recognition"
  },
  {
    uuid: "3bf14b3c-7564-4a3a-a695-ccaae93537a9",
    iconSet: "MaterialCommunityIcons",
    iconName: "text-search"
  },
  {
    uuid: "63fe231a-1009-4e16-b74a-4b7e8757cc7a",
    iconSet: "MaterialCommunityIcons",
    iconName: "text-shadow"
  },
  {
    uuid: "20a23d8d-bfbb-4c9c-9bb3-c919609d74f9",
    iconSet: "MaterialCommunityIcons",
    iconName: "text-short"
  },
  {
    uuid: "e1ad2fd9-ca98-41d6-adf6-2e9163a6a6c9",
    iconSet: "MaterialCommunityIcons",
    iconName: "text-subject"
  },
  {
    uuid: "f84dbaa3-f35d-4e29-855b-036b9e13a9c0",
    iconSet: "MaterialCommunityIcons",
    iconName: "text-to-speech"
  },
  {
    uuid: "9efa1868-c327-4a4c-af32-2f36fc5ecad8",
    iconSet: "MaterialCommunityIcons",
    iconName: "text-to-speech-off"
  },
  {
    uuid: "202ebd3c-4eae-4a3c-b145-569a6f48c05b",
    iconSet: "MaterialCommunityIcons",
    iconName: "texture"
  },
  {
    uuid: "8ace5ce8-ee82-4ab1-ba00-16daecd83fa4",
    iconSet: "MaterialCommunityIcons",
    iconName: "texture-box"
  },
  {
    uuid: "6bfb95ac-92f3-4b49-b5df-92b8af3d3f65",
    iconSet: "MaterialCommunityIcons",
    iconName: "theater"
  },
  {
    uuid: "6325ace1-2d66-442c-b5dd-cea3d30358e6",
    iconSet: "MaterialCommunityIcons",
    iconName: "theme-light-dark"
  },
  {
    uuid: "374442bb-f25e-4e80-be37-4df7d66e5d51",
    iconSet: "MaterialCommunityIcons",
    iconName: "thermometer"
  },
  {
    uuid: "1c3c0d80-81b7-42f5-9262-5dc07bfd1b7b",
    iconSet: "MaterialCommunityIcons",
    iconName: "thermometer-alert"
  },
  {
    uuid: "f34806ee-39a0-4d28-af5d-66164d3bb1a4",
    iconSet: "MaterialCommunityIcons",
    iconName: "thermometer-chevron-down"
  },
  {
    uuid: "561f3f52-7fc2-44b5-91d0-b298ce206f9e",
    iconSet: "MaterialCommunityIcons",
    iconName: "thermometer-chevron-up"
  },
  {
    uuid: "4a67f92e-deab-47a8-91de-9e37292694fe",
    iconSet: "MaterialCommunityIcons",
    iconName: "thermometer-high"
  },
  {
    uuid: "f3d45bf3-942a-427b-8377-a9b682397277",
    iconSet: "MaterialCommunityIcons",
    iconName: "thermometer-lines"
  },
  {
    uuid: "b818485b-d841-4135-af3d-1f966c10958a",
    iconSet: "MaterialCommunityIcons",
    iconName: "thermometer-low"
  },
  {
    uuid: "c85d1576-556d-45ad-901c-209a39daed7b",
    iconSet: "MaterialCommunityIcons",
    iconName: "thermometer-minus"
  },
  {
    uuid: "ba1c6335-890f-4121-9c10-ce396a70d8f4",
    iconSet: "MaterialCommunityIcons",
    iconName: "thermometer-plus"
  },
  {
    uuid: "a8766d2f-3fec-4275-b3b5-f7b8de467273",
    iconSet: "MaterialCommunityIcons",
    iconName: "thermostat"
  },
  {
    uuid: "e8630878-41c1-440b-b90c-7931d3b434ef",
    iconSet: "MaterialCommunityIcons",
    iconName: "thermostat-box"
  },
  {
    uuid: "90428047-7cd1-44f1-9aa7-0fe258aad204",
    iconSet: "MaterialCommunityIcons",
    iconName: "thought-bubble"
  },
  {
    uuid: "ae3fe50b-f56d-47a2-946b-a7b6b9012ddf",
    iconSet: "MaterialCommunityIcons",
    iconName: "thought-bubble-outline"
  },
  {
    uuid: "5266d832-fff6-439f-86f3-b6463d2beef0",
    iconSet: "MaterialCommunityIcons",
    iconName: "thumb-down"
  },
  {
    uuid: "d4262996-3384-40ee-9c4a-1a8693c0d2a0",
    iconSet: "MaterialCommunityIcons",
    iconName: "thumb-down-outline"
  },
  {
    uuid: "8a43febd-64a8-422a-89aa-be2f9de26ae3",
    iconSet: "MaterialCommunityIcons",
    iconName: "thumb-up"
  },
  {
    uuid: "a5392f29-50aa-45bd-8b3e-57f3e963c228",
    iconSet: "MaterialCommunityIcons",
    iconName: "thumb-up-outline"
  },
  {
    uuid: "3e6be8e5-dc78-41a1-b946-81a0e3c2b83d",
    iconSet: "MaterialCommunityIcons",
    iconName: "thumbs-up-down"
  },
  {
    uuid: "2f35bfe0-eeab-46bf-a569-1375e25579d1",
    iconSet: "MaterialCommunityIcons",
    iconName: "ticket"
  },
  {
    uuid: "51800427-1647-4a0e-bb40-709c61744078",
    iconSet: "MaterialCommunityIcons",
    iconName: "ticket-account"
  },
  {
    uuid: "0e2a8f2b-57ad-4a7e-9da6-2e6b907e42a5",
    iconSet: "MaterialCommunityIcons",
    iconName: "ticket-confirmation"
  },
  {
    uuid: "e8c9c40b-c874-4b95-86ea-decfff13cbfc",
    iconSet: "MaterialCommunityIcons",
    iconName: "ticket-confirmation-outline"
  },
  {
    uuid: "674dd4e9-a491-4a19-8567-c43456149711",
    iconSet: "MaterialCommunityIcons",
    iconName: "ticket-outline"
  },
  {
    uuid: "c618bb4b-b221-4b61-be24-1a347d01d642",
    iconSet: "MaterialCommunityIcons",
    iconName: "ticket-percent"
  },
  {
    uuid: "7f64f7ef-2eb8-4980-8245-51d79b05e984",
    iconSet: "MaterialCommunityIcons",
    iconName: "ticket-percent-outline"
  },
  {
    uuid: "3a31102a-a2be-4f09-aa43-8e3a48348390",
    iconSet: "MaterialCommunityIcons",
    iconName: "tie"
  },
  {
    uuid: "90dbb9f7-1b1b-4966-88af-ae2449725ec0",
    iconSet: "MaterialCommunityIcons",
    iconName: "tilde"
  },
  {
    uuid: "b93ace2a-8c8a-448b-b1e8-4dbf0830df6d",
    iconSet: "MaterialCommunityIcons",
    iconName: "timelapse"
  },
  {
    uuid: "2aec4b4a-c9aa-457d-959b-f4ce5e9896e6",
    iconSet: "MaterialCommunityIcons",
    iconName: "timeline"
  },
  {
    uuid: "bc7c4f95-0eaa-4185-9eb1-fb869d3f1c80",
    iconSet: "MaterialCommunityIcons",
    iconName: "timeline-alert"
  },
  {
    uuid: "4730b29f-5e66-4602-a12b-f8a16d7080bb",
    iconSet: "MaterialCommunityIcons",
    iconName: "timeline-alert-outline"
  },
  {
    uuid: "3212e614-5952-404f-a13a-a5b59bdb7062",
    iconSet: "MaterialCommunityIcons",
    iconName: "timeline-clock"
  },
  {
    uuid: "e3aad1c5-5a44-4d82-94c1-808f6f4994e1",
    iconSet: "MaterialCommunityIcons",
    iconName: "timeline-clock-outline"
  },
  {
    uuid: "fb3bd135-8296-4af3-95fe-8bf85bf7bff9",
    iconSet: "MaterialCommunityIcons",
    iconName: "timeline-help"
  },
  {
    uuid: "b9da75a5-2495-4756-933f-3b48a29aa7a3",
    iconSet: "MaterialCommunityIcons",
    iconName: "timeline-help-outline"
  },
  {
    uuid: "c89f0bc2-95a5-4e87-a9d6-5856e70faf9a",
    iconSet: "MaterialCommunityIcons",
    iconName: "timeline-outline"
  },
  {
    uuid: "a534a85c-f121-4b53-879d-7d02c4120190",
    iconSet: "MaterialCommunityIcons",
    iconName: "timeline-plus"
  },
  {
    uuid: "5df2ba81-fd5f-4fcf-8821-7cbb8ac2325e",
    iconSet: "MaterialCommunityIcons",
    iconName: "timeline-plus-outline"
  },
  {
    uuid: "1c793cba-0479-43e9-8577-1e08a2c193c0",
    iconSet: "MaterialCommunityIcons",
    iconName: "timeline-text"
  },
  {
    uuid: "9e9d61d4-fd54-4750-92e4-ee7530bfa6ee",
    iconSet: "MaterialCommunityIcons",
    iconName: "timeline-text-outline"
  },
  {
    uuid: "7e966fb0-6ec2-4395-86a2-76b066ee970e",
    iconSet: "MaterialCommunityIcons",
    iconName: "timer"
  },
  {
    uuid: "e277be4e-b4fd-478b-a6c4-f7e738758db3",
    iconSet: "MaterialCommunityIcons",
    iconName: "timer-10"
  },
  {
    uuid: "3e104b8a-c83f-4478-9cb7-d609fa6a689e",
    iconSet: "MaterialCommunityIcons",
    iconName: "timer-3"
  },
  {
    uuid: "f619df5c-12a3-4fdf-94dd-3c1149c16c79",
    iconSet: "MaterialCommunityIcons",
    iconName: "timer-off"
  },
  {
    uuid: "30e5e619-e688-4f3c-8d1a-09c333831e79",
    iconSet: "MaterialCommunityIcons",
    iconName: "timer-off-outline"
  },
  {
    uuid: "ff5815ad-bdba-4320-8cfc-7b23b98eb4b5",
    iconSet: "MaterialCommunityIcons",
    iconName: "timer-outline"
  },
  {
    uuid: "6a0925dd-aca6-4d71-86fd-5150a67df618",
    iconSet: "MaterialCommunityIcons",
    iconName: "timer-sand"
  },
  {
    uuid: "bd9951ad-69e6-448f-a302-c43b078ed788",
    iconSet: "MaterialCommunityIcons",
    iconName: "timer-sand-empty"
  },
  {
    uuid: "1dbb82a8-9f83-4a84-9cf4-9318e01ac23e",
    iconSet: "MaterialCommunityIcons",
    iconName: "timer-sand-full"
  },
  {
    uuid: "8c86e284-dbc0-4ffe-9031-9734109889dd",
    iconSet: "MaterialCommunityIcons",
    iconName: "timetable"
  },
  {
    uuid: "decb1bf6-0854-4588-9eba-7503b38ad1ee",
    iconSet: "MaterialCommunityIcons",
    iconName: "toaster"
  },
  {
    uuid: "3b5abe98-f340-4da0-984a-9435d67fccd1",
    iconSet: "MaterialCommunityIcons",
    iconName: "toaster-off"
  },
  {
    uuid: "d5a76e60-d748-46a0-8129-84cf3d246dc5",
    iconSet: "MaterialCommunityIcons",
    iconName: "toaster-oven"
  },
  {
    uuid: "7921054f-4699-45a8-88fd-90adcd17e83a",
    iconSet: "MaterialCommunityIcons",
    iconName: "toggle-switch"
  },
  {
    uuid: "f69ba2c7-b25d-4a2f-8364-0db742a09991",
    iconSet: "MaterialCommunityIcons",
    iconName: "toggle-switch-off"
  },
  {
    uuid: "ffb2753c-032d-4d12-8c1b-5142e282526b",
    iconSet: "MaterialCommunityIcons",
    iconName: "toggle-switch-off-outline"
  },
  {
    uuid: "18e09bd6-d673-41fb-a6a3-2e8d5297ab59",
    iconSet: "MaterialCommunityIcons",
    iconName: "toggle-switch-outline"
  },
  {
    uuid: "8ebc3e33-8662-4c68-b20f-be8cadef298e",
    iconSet: "MaterialCommunityIcons",
    iconName: "toilet"
  },
  {
    uuid: "5e256664-f70f-4a99-8d2f-ea7e02cbc42c",
    iconSet: "MaterialCommunityIcons",
    iconName: "toolbox"
  },
  {
    uuid: "6c720599-3945-47ec-a1a4-4b475597c424",
    iconSet: "MaterialCommunityIcons",
    iconName: "toolbox-outline"
  },
  {
    uuid: "4e89326a-0f37-4208-8e90-561d6f43635e",
    iconSet: "MaterialCommunityIcons",
    iconName: "tools"
  },
  {
    uuid: "9ffe6578-f990-4ee8-9475-5ec2a8c12d02",
    iconSet: "MaterialCommunityIcons",
    iconName: "tooltip"
  },
  {
    uuid: "efdfc642-314a-48f0-8e9c-1dfbfa07c29d",
    iconSet: "MaterialCommunityIcons",
    iconName: "tooltip-account"
  },
  {
    uuid: "5824a261-6b9b-409a-aaf5-59521d13e0e9",
    iconSet: "MaterialCommunityIcons",
    iconName: "tooltip-edit"
  },
  {
    uuid: "96cbb0ca-8158-45e8-b96c-6bdd43e35c00",
    iconSet: "MaterialCommunityIcons",
    iconName: "tooltip-edit-outline"
  },
  {
    uuid: "25e57c2e-05c1-49fe-8a08-22effffca4e4",
    iconSet: "MaterialCommunityIcons",
    iconName: "tooltip-image"
  },
  {
    uuid: "ffbe546e-c54c-4770-99cb-ce077bf2fd9c",
    iconSet: "MaterialCommunityIcons",
    iconName: "tooltip-image-outline"
  },
  {
    uuid: "f6b4b4db-e0c5-48b5-aba9-0830eb9ae7b8",
    iconSet: "MaterialCommunityIcons",
    iconName: "tooltip-outline"
  },
  {
    uuid: "b94e01f5-30a0-4f62-ab97-56f5d602ce79",
    iconSet: "MaterialCommunityIcons",
    iconName: "tooltip-plus"
  },
  {
    uuid: "5a340aa6-bf5c-4ccf-9134-cd40c85b5a9b",
    iconSet: "MaterialCommunityIcons",
    iconName: "tooltip-plus-outline"
  },
  {
    uuid: "c542d6e3-e002-400a-aa13-bb8cd0f96407",
    iconSet: "MaterialCommunityIcons",
    iconName: "tooltip-text"
  },
  {
    uuid: "324e16a8-8477-42fb-aca5-ce9105ca6ae7",
    iconSet: "MaterialCommunityIcons",
    iconName: "tooltip-text-outline"
  },
  {
    uuid: "940ce5f7-c857-4346-8127-03b208010a8f",
    iconSet: "MaterialCommunityIcons",
    iconName: "tooth"
  },
  {
    uuid: "b90b55d0-054d-478d-b3a5-8940dae03fd0",
    iconSet: "MaterialCommunityIcons",
    iconName: "tooth-outline"
  },
  {
    uuid: "e389d21b-bd52-4661-acbe-59c2cea81af9",
    iconSet: "MaterialCommunityIcons",
    iconName: "toothbrush"
  },
  {
    uuid: "1dd7134c-fea3-451a-9254-3b9cbe67ba72",
    iconSet: "MaterialCommunityIcons",
    iconName: "toothbrush-electric"
  },
  {
    uuid: "fcd682ad-2a69-4001-9eef-75a188b7fa13",
    iconSet: "MaterialCommunityIcons",
    iconName: "toothbrush-paste"
  },
  {
    uuid: "6039759a-5a8f-4f25-87f4-2300858e082c",
    iconSet: "MaterialCommunityIcons",
    iconName: "tortoise"
  },
  {
    uuid: "40ca4a09-c76d-46ca-bbfd-f10060ce7c91",
    iconSet: "MaterialCommunityIcons",
    iconName: "toslink"
  },
  {
    uuid: "f3d3d42f-9464-432d-a00a-5723cc9c3d7a",
    iconSet: "MaterialCommunityIcons",
    iconName: "tournament"
  },
  {
    uuid: "f4cbf328-d230-4e57-b2c5-53a84ae0329b",
    iconSet: "MaterialCommunityIcons",
    iconName: "tow-truck"
  },
  {
    uuid: "dd0b2113-ef7a-4dfa-b5f2-8c6a9ef00cbe",
    iconSet: "MaterialCommunityIcons",
    iconName: "tower-beach"
  },
  {
    uuid: "6a06880d-205d-4f0e-bb8e-b2edb6ebf37c",
    iconSet: "MaterialCommunityIcons",
    iconName: "tower-fire"
  },
  {
    uuid: "1695ac36-d509-412c-8a43-2c47bc98f857",
    iconSet: "MaterialCommunityIcons",
    iconName: "toy-brick"
  },
  {
    uuid: "5c78b9fb-4272-4c1d-a726-35d867a9ee74",
    iconSet: "MaterialCommunityIcons",
    iconName: "toy-brick-marker"
  },
  {
    uuid: "fe4e3d65-baf6-4ace-aa90-cafee2e4330f",
    iconSet: "MaterialCommunityIcons",
    iconName: "toy-brick-marker-outline"
  },
  {
    uuid: "b8a3bc76-9f97-4810-82ca-69eff8ced43d",
    iconSet: "MaterialCommunityIcons",
    iconName: "toy-brick-minus"
  },
  {
    uuid: "1786c636-121a-422c-8409-fd8cb3b82453",
    iconSet: "MaterialCommunityIcons",
    iconName: "toy-brick-minus-outline"
  },
  {
    uuid: "2c9d5115-8705-461d-89f7-7eb406fbb17b",
    iconSet: "MaterialCommunityIcons",
    iconName: "toy-brick-outline"
  },
  {
    uuid: "4caab80b-dd51-45c2-be33-67c489dcd2ba",
    iconSet: "MaterialCommunityIcons",
    iconName: "toy-brick-plus"
  },
  {
    uuid: "8c526d5c-83ec-49e0-8425-79175510284e",
    iconSet: "MaterialCommunityIcons",
    iconName: "toy-brick-plus-outline"
  },
  {
    uuid: "db189e1d-a873-40fd-ae6e-17783642842c",
    iconSet: "MaterialCommunityIcons",
    iconName: "toy-brick-remove"
  },
  {
    uuid: "8c2b97e1-5ada-4d85-a1ed-c09db8cb63aa",
    iconSet: "MaterialCommunityIcons",
    iconName: "toy-brick-remove-outline"
  },
  {
    uuid: "c35537fa-4ee9-4638-8bd4-87a0b1f03a5e",
    iconSet: "MaterialCommunityIcons",
    iconName: "toy-brick-search"
  },
  {
    uuid: "d4f0dc79-7abc-49a3-83a2-48d7e4c2e3b8",
    iconSet: "MaterialCommunityIcons",
    iconName: "toy-brick-search-outline"
  },
  {
    uuid: "e790d55b-ef3e-49ae-acf1-4292fc0c294e",
    iconSet: "MaterialCommunityIcons",
    iconName: "track-light"
  },
  {
    uuid: "d70eb393-1cd2-4eea-a307-d0d1e7da0a8b",
    iconSet: "MaterialCommunityIcons",
    iconName: "trackpad"
  },
  {
    uuid: "30ea6191-6b73-4013-88d2-f50e13b673db",
    iconSet: "MaterialCommunityIcons",
    iconName: "trackpad-lock"
  },
  {
    uuid: "666f634c-a42b-41cc-a419-6318a16165d3",
    iconSet: "MaterialCommunityIcons",
    iconName: "tractor"
  },
  {
    uuid: "e9d7459d-cd7a-439e-b46a-114cd421cfcc",
    iconSet: "MaterialCommunityIcons",
    iconName: "tractor-variant"
  },
  {
    uuid: "ab32ffed-1a47-4ce7-88fe-943b84c93f6f",
    iconSet: "MaterialCommunityIcons",
    iconName: "trademark"
  },
  {
    uuid: "7163ee21-48df-402b-b830-1f110c63c7b1",
    iconSet: "MaterialCommunityIcons",
    iconName: "traffic-cone"
  },
  {
    uuid: "603ea765-6a1f-483d-8929-d6f3d854607a",
    iconSet: "MaterialCommunityIcons",
    iconName: "traffic-light"
  },
  {
    uuid: "61e9a83b-fff1-430e-87ee-b939747fe266",
    iconSet: "MaterialCommunityIcons",
    iconName: "train"
  },
  {
    uuid: "cb5a1fb4-2c93-4a95-91a0-449a1b9cd682",
    iconSet: "MaterialCommunityIcons",
    iconName: "train-car"
  },
  {
    uuid: "86fa2761-e6a4-49cc-bb03-2cb67ae0e63e",
    iconSet: "MaterialCommunityIcons",
    iconName: "train-variant"
  },
  {
    uuid: "bda573ce-dae9-4131-b562-5382f5d541b9",
    iconSet: "MaterialCommunityIcons",
    iconName: "tram"
  },
  {
    uuid: "9c68caf7-4859-4bdf-8786-fcc3c46c5c9f",
    iconSet: "MaterialCommunityIcons",
    iconName: "tram-side"
  },
  {
    uuid: "063683b7-4a60-49af-802c-f0225ea43585",
    iconSet: "MaterialCommunityIcons",
    iconName: "transcribe"
  },
  {
    uuid: "35892ca0-7257-415c-8358-0c1801ff8d53",
    iconSet: "MaterialCommunityIcons",
    iconName: "transcribe-close"
  },
  {
    uuid: "1406926c-4539-4441-91ea-b94e79fa6927",
    iconSet: "MaterialCommunityIcons",
    iconName: "transfer"
  },
  {
    uuid: "cccf94b9-1624-4c65-91c3-2a94681405cf",
    iconSet: "MaterialCommunityIcons",
    iconName: "transfer-down"
  },
  {
    uuid: "7dd7ae25-c8e0-4db4-b475-b74b791da2a7",
    iconSet: "MaterialCommunityIcons",
    iconName: "transfer-left"
  },
  {
    uuid: "7671cfdc-e2d0-4df8-a230-d0b08d7d72d2",
    iconSet: "MaterialCommunityIcons",
    iconName: "transfer-right"
  },
  {
    uuid: "a26fcd8e-b69a-4dc9-96ec-bc8cda002bc3",
    iconSet: "MaterialCommunityIcons",
    iconName: "transfer-up"
  },
  {
    uuid: "6234da9a-66a4-4b05-98fa-d1c82cb569b5",
    iconSet: "MaterialCommunityIcons",
    iconName: "transit-connection"
  },
  {
    uuid: "d739dd63-becc-4c8b-924a-b789b36850d7",
    iconSet: "MaterialCommunityIcons",
    iconName: "transit-connection-variant"
  },
  {
    uuid: "1a934abc-bc3c-4d70-a2ef-3d3aaa350bbe",
    iconSet: "MaterialCommunityIcons",
    iconName: "transit-detour"
  },
  {
    uuid: "bd8baaff-e211-42d9-b86b-c8c8a1fc156d",
    iconSet: "MaterialCommunityIcons",
    iconName: "transit-transfer"
  },
  {
    uuid: "a972bae1-4ab8-4b85-87b0-20cb18501a49",
    iconSet: "MaterialCommunityIcons",
    iconName: "transition"
  },
  {
    uuid: "b17797d7-3e3a-4027-ae29-06b50483283f",
    iconSet: "MaterialCommunityIcons",
    iconName: "transition-masked"
  },
  {
    uuid: "1299b430-3ce6-4e67-9058-57abab17680d",
    iconSet: "MaterialCommunityIcons",
    iconName: "translate"
  },
  {
    uuid: "79dc98e0-75a3-4e7d-bcf5-5725522a9677",
    iconSet: "MaterialCommunityIcons",
    iconName: "translate-off"
  },
  {
    uuid: "7688f8b5-8e8c-4c14-984b-48c189d8bb43",
    iconSet: "MaterialCommunityIcons",
    iconName: "transmission-tower"
  },
  {
    uuid: "c19978ca-9537-4aec-a445-598932156c07",
    iconSet: "MaterialCommunityIcons",
    iconName: "trash-can"
  },
  {
    uuid: "dda1b8b9-1d8b-4f06-ba15-1ea9df0d34d5",
    iconSet: "MaterialCommunityIcons",
    iconName: "trash-can-outline"
  },
  {
    uuid: "41f5af1e-0b03-410e-825a-16b8290ace16",
    iconSet: "MaterialCommunityIcons",
    iconName: "tray"
  },
  {
    uuid: "1f0055c6-056d-4cc7-9884-0d5f1ed7ed7b",
    iconSet: "MaterialCommunityIcons",
    iconName: "tray-alert"
  },
  {
    uuid: "4ba68ca7-ecbc-46dc-bac3-4427b29b5619",
    iconSet: "MaterialCommunityIcons",
    iconName: "tray-full"
  },
  {
    uuid: "31e55312-158b-47a0-9b68-98c63c8ff3b1",
    iconSet: "MaterialCommunityIcons",
    iconName: "tray-minus"
  },
  {
    uuid: "34d18424-0281-4ed6-b3dd-1f9e81177edc",
    iconSet: "MaterialCommunityIcons",
    iconName: "tray-plus"
  },
  {
    uuid: "d72e8101-beaa-41fa-ae7e-98b95ae814e5",
    iconSet: "MaterialCommunityIcons",
    iconName: "tray-remove"
  },
  {
    uuid: "239c429f-4b91-4b8c-8a2e-80f73592669a",
    iconSet: "MaterialCommunityIcons",
    iconName: "treasure-chest"
  },
  {
    uuid: "9301b7ae-15d1-4cf1-a93d-7b669f383927",
    iconSet: "MaterialCommunityIcons",
    iconName: "tree"
  },
  {
    uuid: "6527b79a-b658-4ab5-81ba-bfdcc2641aa8",
    iconSet: "MaterialCommunityIcons",
    iconName: "tree-outline"
  },
  {
    uuid: "a5751b00-6322-4eb0-819d-e2c74d02785f",
    iconSet: "MaterialCommunityIcons",
    iconName: "trello"
  },
  {
    uuid: "f68166f6-cc7a-4608-8cae-88a9fbb6deaf",
    iconSet: "MaterialCommunityIcons",
    iconName: "trending-down"
  },
  {
    uuid: "9da387a2-18c0-4d5f-8869-ba217cfc1d66",
    iconSet: "MaterialCommunityIcons",
    iconName: "trending-neutral"
  },
  {
    uuid: "544842e6-e091-4b7a-b170-7aaa53d4e8d9",
    iconSet: "MaterialCommunityIcons",
    iconName: "trending-up"
  },
  {
    uuid: "46689e7e-9601-44a8-bc15-c4134b242422",
    iconSet: "MaterialCommunityIcons",
    iconName: "triangle"
  },
  {
    uuid: "0c30139d-585a-4636-b5af-f7476ed19959",
    iconSet: "MaterialCommunityIcons",
    iconName: "triangle-outline"
  },
  {
    uuid: "0da53b20-e9c6-4016-ae0c-daec8dd6e410",
    iconSet: "MaterialCommunityIcons",
    iconName: "triangle-wave"
  },
  {
    uuid: "4a2893d3-35a3-4a8a-9882-8eb7a7959bbd",
    iconSet: "MaterialCommunityIcons",
    iconName: "triforce"
  },
  {
    uuid: "25910c2f-9f77-4d2f-aaec-c41a8644ebf4",
    iconSet: "MaterialCommunityIcons",
    iconName: "trophy"
  },
  {
    uuid: "282334c2-8d1c-487c-a4e3-913c5c057d00",
    iconSet: "MaterialCommunityIcons",
    iconName: "trophy-award"
  },
  {
    uuid: "2dd6ddca-6846-4c33-8c5b-346787b7aade",
    iconSet: "MaterialCommunityIcons",
    iconName: "trophy-broken"
  },
  {
    uuid: "a1d846b9-b1f2-495b-a585-7c7f9138a566",
    iconSet: "MaterialCommunityIcons",
    iconName: "trophy-outline"
  },
  {
    uuid: "a1309226-3ba8-495a-a63d-9696348e4d84",
    iconSet: "MaterialCommunityIcons",
    iconName: "trophy-variant"
  },
  {
    uuid: "3010acd3-62ea-4b89-affc-e6cc524f9bf9",
    iconSet: "MaterialCommunityIcons",
    iconName: "trophy-variant-outline"
  },
  {
    uuid: "0929d1ab-e276-426b-8ad3-da38c2130e11",
    iconSet: "MaterialCommunityIcons",
    iconName: "truck"
  },
  {
    uuid: "c1475963-5bee-4abd-8e88-7d600cb5c83c",
    iconSet: "MaterialCommunityIcons",
    iconName: "truck-check"
  },
  {
    uuid: "accbffd7-3d98-4f38-8f6f-09296c1646a7",
    iconSet: "MaterialCommunityIcons",
    iconName: "truck-check-outline"
  },
  {
    uuid: "57da6f85-79ef-4577-ab0e-c519f384f037",
    iconSet: "MaterialCommunityIcons",
    iconName: "truck-delivery"
  },
  {
    uuid: "68d13358-3da8-4a87-883f-857191de3d78",
    iconSet: "MaterialCommunityIcons",
    iconName: "truck-delivery-outline"
  },
  {
    uuid: "5c5b5034-b739-4fbf-b5df-428d3727e8af",
    iconSet: "MaterialCommunityIcons",
    iconName: "truck-fast"
  },
  {
    uuid: "4ce8e19d-7e03-4d57-80fc-0e4719aedadf",
    iconSet: "MaterialCommunityIcons",
    iconName: "truck-fast-outline"
  },
  {
    uuid: "975dc7d1-61ff-4860-86b3-bfd28ec46400",
    iconSet: "MaterialCommunityIcons",
    iconName: "truck-outline"
  },
  {
    uuid: "3b35b095-4cef-4691-8df2-499b029c6e6e",
    iconSet: "MaterialCommunityIcons",
    iconName: "truck-trailer"
  },
  {
    uuid: "f290c416-aae9-4d44-b6f2-d0ad01261e18",
    iconSet: "MaterialCommunityIcons",
    iconName: "trumpet"
  },
  {
    uuid: "d495477e-5383-48a8-8438-d2caafb9a75e",
    iconSet: "MaterialCommunityIcons",
    iconName: "tshirt-crew"
  },
  {
    uuid: "9a6d8a01-b05f-45db-b736-1ad8f23195ec",
    iconSet: "MaterialCommunityIcons",
    iconName: "tshirt-crew-outline"
  },
  {
    uuid: "2be86d30-ee29-4d1c-b343-2e1b58c4d035",
    iconSet: "MaterialCommunityIcons",
    iconName: "tshirt-v"
  },
  {
    uuid: "87b712c0-ce29-4362-b6db-a33d17807772",
    iconSet: "MaterialCommunityIcons",
    iconName: "tshirt-v-outline"
  },
  {
    uuid: "54640f94-c542-43cc-9d72-805ec68280e4",
    iconSet: "MaterialCommunityIcons",
    iconName: "tumble-dryer"
  },
  {
    uuid: "86ae9163-415e-4bca-b2e3-497e06def524",
    iconSet: "MaterialCommunityIcons",
    iconName: "tumble-dryer-alert"
  },
  {
    uuid: "f31e2784-a127-4fec-a0f5-fcb3309f979c",
    iconSet: "MaterialCommunityIcons",
    iconName: "tumble-dryer-off"
  },
  {
    uuid: "4f634ebb-1089-4b1c-8980-a6d937f5f1c3",
    iconSet: "MaterialCommunityIcons",
    iconName: "tune"
  },
  {
    uuid: "383910b1-355a-4029-aaf3-dba6135537a1",
    iconSet: "MaterialCommunityIcons",
    iconName: "tune-vertical"
  },
  {
    uuid: "830cbef7-9e50-4458-8f18-dfa4653cc6f8",
    iconSet: "MaterialCommunityIcons",
    iconName: "turnstile"
  },
  {
    uuid: "0cd1e183-522a-4598-9d8a-a4112bc1ade1",
    iconSet: "MaterialCommunityIcons",
    iconName: "turnstile-outline"
  },
  {
    uuid: "330dfff7-3573-4b70-9636-92b59a4ebea2",
    iconSet: "MaterialCommunityIcons",
    iconName: "turtle"
  },
  {
    uuid: "54e205a7-df81-42b1-a11e-e3b6efb04024",
    iconSet: "MaterialCommunityIcons",
    iconName: "twitch"
  },
  {
    uuid: "4c683bd4-b518-4f45-b004-df77b792b17d",
    iconSet: "MaterialCommunityIcons",
    iconName: "twitter"
  },
  {
    uuid: "e4a37800-b61d-4a8f-a5bc-998feabc1734",
    iconSet: "MaterialCommunityIcons",
    iconName: "twitter-retweet"
  },
  {
    uuid: "0cfa4888-5ed0-44f1-ac94-44ce049db0d3",
    iconSet: "MaterialCommunityIcons",
    iconName: "two-factor-authentication"
  },
  {
    uuid: "2bc75b24-fd23-4644-b6d0-1c9805a0de3e",
    iconSet: "MaterialCommunityIcons",
    iconName: "typewriter"
  },
  {
    uuid: "bb0a0746-93a5-4545-ace8-5dbd0c2ec8b6",
    iconSet: "MaterialCommunityIcons",
    iconName: "ubisoft"
  },
  {
    uuid: "cd952d1d-3f7f-4a51-8153-248535936bd5",
    iconSet: "MaterialCommunityIcons",
    iconName: "ubuntu"
  },
  {
    uuid: "4a04149b-6d1f-435c-a9a1-2f5cdba3739c",
    iconSet: "MaterialCommunityIcons",
    iconName: "ufo"
  },
  {
    uuid: "41185223-9a11-468b-ad46-b26187fb759f",
    iconSet: "MaterialCommunityIcons",
    iconName: "ufo-outline"
  },
  {
    uuid: "e5eba6b1-52cb-45af-9333-d668f8e475ad",
    iconSet: "MaterialCommunityIcons",
    iconName: "ultra-high-definition"
  },
  {
    uuid: "844d0dfa-7e62-44c9-ade1-6e3cfd77147c",
    iconSet: "MaterialCommunityIcons",
    iconName: "umbraco"
  },
  {
    uuid: "8d444ec1-0b99-4868-9043-8939357353c4",
    iconSet: "MaterialCommunityIcons",
    iconName: "umbrella"
  },
  {
    uuid: "a6f4bff3-a290-4528-8f99-881fc362610a",
    iconSet: "MaterialCommunityIcons",
    iconName: "umbrella-closed"
  },
  {
    uuid: "a267b847-e16d-4c67-a157-0c7b4e79c878",
    iconSet: "MaterialCommunityIcons",
    iconName: "umbrella-closed-outline"
  },
  {
    uuid: "00c8d109-7454-4808-84af-a6ebc7e1501f",
    iconSet: "MaterialCommunityIcons",
    iconName: "umbrella-closed-variant"
  },
  {
    uuid: "6e3416aa-efc5-41a3-ba6a-fcbf22bb8649",
    iconSet: "MaterialCommunityIcons",
    iconName: "umbrella-outline"
  },
  {
    uuid: "a7f6c3df-71a6-46f3-9a4c-86b8f8ac2a95",
    iconSet: "MaterialCommunityIcons",
    iconName: "undo"
  },
  {
    uuid: "73d0d263-b27a-45f0-9847-7395c1d6682b",
    iconSet: "MaterialCommunityIcons",
    iconName: "undo-variant"
  },
  {
    uuid: "b1b40efe-cad5-492f-a3f3-3306138b5714",
    iconSet: "MaterialCommunityIcons",
    iconName: "unfold-less-horizontal"
  },
  {
    uuid: "8b74528c-45e7-45aa-babd-0d1071c5a8f3",
    iconSet: "MaterialCommunityIcons",
    iconName: "unfold-less-vertical"
  },
  {
    uuid: "a3ec6779-e73d-4f78-a173-ba096fa58bc2",
    iconSet: "MaterialCommunityIcons",
    iconName: "unfold-more-horizontal"
  },
  {
    uuid: "be74926b-590e-4afc-9cde-6b2c957d35f4",
    iconSet: "MaterialCommunityIcons",
    iconName: "unfold-more-vertical"
  },
  {
    uuid: "e655a917-9eb4-4721-82d7-4671f635fa51",
    iconSet: "MaterialCommunityIcons",
    iconName: "ungroup"
  },
  {
    uuid: "dfe2a7b9-788e-46b2-8d42-66e882c3e80f",
    iconSet: "MaterialCommunityIcons",
    iconName: "unicode"
  },
  {
    uuid: "f68e0d3f-48bb-4f03-9e2d-1f3bc46f1282",
    iconSet: "MaterialCommunityIcons",
    iconName: "unity"
  },
  {
    uuid: "3c36e68b-5803-4b2c-8fb3-a245ea5ef3b4",
    iconSet: "MaterialCommunityIcons",
    iconName: "unreal"
  },
  {
    uuid: "cf5002fa-b94a-43db-b1ea-20cdc82585a7",
    iconSet: "MaterialCommunityIcons",
    iconName: "untappd"
  },
  {
    uuid: "3515d903-ff8c-4922-99be-ff9ef9444a60",
    iconSet: "MaterialCommunityIcons",
    iconName: "update"
  },
  {
    uuid: "4f25d49b-ba86-40da-aecf-12c67c42cfc8",
    iconSet: "MaterialCommunityIcons",
    iconName: "upload"
  },
  {
    uuid: "4904faf3-d5c9-4bfc-bacf-bd8b52407e50",
    iconSet: "MaterialCommunityIcons",
    iconName: "upload-lock"
  },
  {
    uuid: "a74cf898-d250-4686-9aa1-65a0dd9ecbf5",
    iconSet: "MaterialCommunityIcons",
    iconName: "upload-lock-outline"
  },
  {
    uuid: "b7ee87a9-3b3b-416d-94f3-87aafe845101",
    iconSet: "MaterialCommunityIcons",
    iconName: "upload-multiple"
  },
  {
    uuid: "1292912b-6205-4229-be1b-9f6464ecd5c5",
    iconSet: "MaterialCommunityIcons",
    iconName: "upload-network"
  },
  {
    uuid: "10a846bc-4db2-4313-9144-ceb3fc2435fc",
    iconSet: "MaterialCommunityIcons",
    iconName: "upload-network-outline"
  },
  {
    uuid: "69c29c5e-031e-4ad8-a5ed-d06647c91b6f",
    iconSet: "MaterialCommunityIcons",
    iconName: "upload-off"
  },
  {
    uuid: "f22154a3-ebae-4580-b862-08d06cc390d2",
    iconSet: "MaterialCommunityIcons",
    iconName: "upload-off-outline"
  },
  {
    uuid: "b7b8010c-ba8a-4b7e-af14-01195e6d8f5d",
    iconSet: "MaterialCommunityIcons",
    iconName: "upload-outline"
  },
  {
    uuid: "0feb1876-f47b-4cb9-8bb7-a2a3d04d3aa6",
    iconSet: "MaterialCommunityIcons",
    iconName: "usb"
  },
  {
    uuid: "fb76d484-b243-4561-b95a-0267a63c9d03",
    iconSet: "MaterialCommunityIcons",
    iconName: "usb-flash-drive"
  },
  {
    uuid: "30e4ed6c-a201-4858-a89b-c77705a5c36e",
    iconSet: "MaterialCommunityIcons",
    iconName: "usb-flash-drive-outline"
  },
  {
    uuid: "0b6e5fd7-fdd7-453d-bb15-7ab6a7e3f660",
    iconSet: "MaterialCommunityIcons",
    iconName: "usb-port"
  },
  {
    uuid: "8e0f4e29-639d-4082-8097-698cd1e748e3",
    iconSet: "MaterialCommunityIcons",
    iconName: "valve"
  },
  {
    uuid: "bec2fa51-0a57-4b7d-bc42-b8acc8d201c7",
    iconSet: "MaterialCommunityIcons",
    iconName: "valve-closed"
  },
  {
    uuid: "a55e8f97-e489-48af-b433-9ab50dfd6656",
    iconSet: "MaterialCommunityIcons",
    iconName: "valve-open"
  },
  {
    uuid: "14c03aa6-e68e-4f89-ae8d-cc1953fc19d5",
    iconSet: "MaterialCommunityIcons",
    iconName: "van-passenger"
  },
  {
    uuid: "8b099dd8-78a5-4999-81ba-76898d441622",
    iconSet: "MaterialCommunityIcons",
    iconName: "van-utility"
  },
  {
    uuid: "89478b85-e3c2-49aa-8654-a56f073f8607",
    iconSet: "MaterialCommunityIcons",
    iconName: "vanish"
  },
  {
    uuid: "0298e129-7bc4-4032-ac01-371bc896789b",
    iconSet: "MaterialCommunityIcons",
    iconName: "vanity-light"
  },
  {
    uuid: "76b1aad8-579d-4d26-8c63-ef1d873fd4e8",
    iconSet: "MaterialCommunityIcons",
    iconName: "variable"
  },
  {
    uuid: "77adf9ac-da44-41b6-b39a-9c2a6f068a09",
    iconSet: "MaterialCommunityIcons",
    iconName: "variable-box"
  },
  {
    uuid: "dfc666e8-105e-4221-b0d1-091d8a2717d3",
    iconSet: "MaterialCommunityIcons",
    iconName: "vector-arrange-above"
  },
  {
    uuid: "927cea26-ffac-48a3-9674-b5507e4f88b6",
    iconSet: "MaterialCommunityIcons",
    iconName: "vector-arrange-below"
  },
  {
    uuid: "181fd487-565c-4495-91a3-a180e11a06a8",
    iconSet: "MaterialCommunityIcons",
    iconName: "vector-bezier"
  },
  {
    uuid: "40a4a931-2143-4cc2-ab63-495861f7d833",
    iconSet: "MaterialCommunityIcons",
    iconName: "vector-circle"
  },
  {
    uuid: "2eda7665-2caf-4c6e-942c-ba6315fdcd69",
    iconSet: "MaterialCommunityIcons",
    iconName: "vector-circle-variant"
  },
  {
    uuid: "4def0c9c-6deb-424b-8fa6-6e5faa07d854",
    iconSet: "MaterialCommunityIcons",
    iconName: "vector-combine"
  },
  {
    uuid: "16d65e55-5bba-416a-b30f-39a99803c890",
    iconSet: "MaterialCommunityIcons",
    iconName: "vector-curve"
  },
  {
    uuid: "f7ec6308-326e-4fb8-ac80-add329f1f548",
    iconSet: "MaterialCommunityIcons",
    iconName: "vector-difference"
  },
  {
    uuid: "c857f6dd-13ee-486e-99e5-bed2ea26d523",
    iconSet: "MaterialCommunityIcons",
    iconName: "vector-difference-ab"
  },
  {
    uuid: "718ab215-6458-40ca-b486-633c5490f83e",
    iconSet: "MaterialCommunityIcons",
    iconName: "vector-difference-ba"
  },
  {
    uuid: "9a227650-b6b1-4a33-84a2-05300d706d90",
    iconSet: "MaterialCommunityIcons",
    iconName: "vector-ellipse"
  },
  {
    uuid: "7c18b046-340b-47ea-aa3a-ed0893e11d8b",
    iconSet: "MaterialCommunityIcons",
    iconName: "vector-intersection"
  },
  {
    uuid: "bb4725b6-045c-4a63-87e2-df469a0759a5",
    iconSet: "MaterialCommunityIcons",
    iconName: "vector-line"
  },
  {
    uuid: "3e695c0c-54a9-4f4b-8e7e-2a0e2419c1a7",
    iconSet: "MaterialCommunityIcons",
    iconName: "vector-link"
  },
  {
    uuid: "fb73337c-d66b-4070-883b-46b9737e089c",
    iconSet: "MaterialCommunityIcons",
    iconName: "vector-point"
  },
  {
    uuid: "a208a201-bc1e-4981-8ea5-a33aef26e7b0",
    iconSet: "MaterialCommunityIcons",
    iconName: "vector-polygon"
  },
  {
    uuid: "9ee5ae84-8425-42f3-965d-f09388dc7704",
    iconSet: "MaterialCommunityIcons",
    iconName: "vector-polyline"
  },
  {
    uuid: "69c611e6-bfa5-4884-a994-d09c0cc30846",
    iconSet: "MaterialCommunityIcons",
    iconName: "vector-polyline-edit"
  },
  {
    uuid: "a05181c1-df58-4bb1-8f9a-85020ca51d18",
    iconSet: "MaterialCommunityIcons",
    iconName: "vector-polyline-minus"
  },
  {
    uuid: "9e87d0e6-0512-43ed-89ff-1c2719608b56",
    iconSet: "MaterialCommunityIcons",
    iconName: "vector-polyline-plus"
  },
  {
    uuid: "992d2512-2e1b-40c7-9a56-be010edde6a5",
    iconSet: "MaterialCommunityIcons",
    iconName: "vector-polyline-remove"
  },
  {
    uuid: "a119e816-566a-4195-9e9b-661641b46faa",
    iconSet: "MaterialCommunityIcons",
    iconName: "vector-radius"
  },
  {
    uuid: "d4df6070-b21f-47c3-b641-ad9e7c457183",
    iconSet: "MaterialCommunityIcons",
    iconName: "vector-rectangle"
  },
  {
    uuid: "86f7d242-f61b-486c-9a8b-7f0ab74a4755",
    iconSet: "MaterialCommunityIcons",
    iconName: "vector-selection"
  },
  {
    uuid: "e6bb6c5f-da38-4e82-8ad8-1e9351624ea9",
    iconSet: "MaterialCommunityIcons",
    iconName: "vector-square"
  },
  {
    uuid: "db6e6914-3088-42aa-90b7-03177155d77d",
    iconSet: "MaterialCommunityIcons",
    iconName: "vector-triangle"
  },
  {
    uuid: "42cc9703-87e9-4262-a733-beb1dbb70acb",
    iconSet: "MaterialCommunityIcons",
    iconName: "vector-union"
  },
  {
    uuid: "b5707851-a414-44c1-8e7b-1ca2887d7268",
    iconSet: "MaterialCommunityIcons",
    iconName: "vhs"
  },
  {
    uuid: "bd826f5f-6f53-440b-afeb-b8e9142d8b66",
    iconSet: "MaterialCommunityIcons",
    iconName: "vibrate"
  },
  {
    uuid: "aca510ca-b795-4026-89f0-55bdb00be81b",
    iconSet: "MaterialCommunityIcons",
    iconName: "vibrate-off"
  },
  {
    uuid: "45ad104c-3c15-4549-8e3d-d4950df113da",
    iconSet: "MaterialCommunityIcons",
    iconName: "video"
  },
  {
    uuid: "36f1f433-94b2-4c31-ae4d-9927623bc47a",
    iconSet: "MaterialCommunityIcons",
    iconName: "video-3d"
  },
  {
    uuid: "854f1fc6-5e3e-4d83-8edb-b847bc5148a3",
    iconSet: "MaterialCommunityIcons",
    iconName: "video-3d-off"
  },
  {
    uuid: "d414fb14-6223-4734-a267-3277dce9eeb9",
    iconSet: "MaterialCommunityIcons",
    iconName: "video-3d-variant"
  },
  {
    uuid: "a14c2d56-1c57-430c-8ec8-3feffd302d01",
    iconSet: "MaterialCommunityIcons",
    iconName: "video-4k-box"
  },
  {
    uuid: "e0b82a22-c165-41fa-b134-f2eb1ee044f4",
    iconSet: "MaterialCommunityIcons",
    iconName: "video-account"
  },
  {
    uuid: "bfda9989-03eb-4abe-8b50-8ce2aaf38b90",
    iconSet: "MaterialCommunityIcons",
    iconName: "video-box"
  },
  {
    uuid: "1febb35a-fa37-4a28-a76b-4f02a9ecbc95",
    iconSet: "MaterialCommunityIcons",
    iconName: "video-box-off"
  },
  {
    uuid: "7c1fd879-ef94-4e5b-b245-88866075fa2f",
    iconSet: "MaterialCommunityIcons",
    iconName: "video-check"
  },
  {
    uuid: "8e1a192f-b7c3-4f8e-ba74-2566c20acf17",
    iconSet: "MaterialCommunityIcons",
    iconName: "video-check-outline"
  },
  {
    uuid: "d2e39570-39fd-4a53-8890-7a23d81d3838",
    iconSet: "MaterialCommunityIcons",
    iconName: "video-image"
  },
  {
    uuid: "d9a41c7d-c31e-4fb7-affc-4a83582e77fe",
    iconSet: "MaterialCommunityIcons",
    iconName: "video-input-antenna"
  },
  {
    uuid: "762000ef-6e55-49fb-a25f-063591c34370",
    iconSet: "MaterialCommunityIcons",
    iconName: "video-input-component"
  },
  {
    uuid: "46d1d229-6044-4e3f-9218-86ba2e848596",
    iconSet: "MaterialCommunityIcons",
    iconName: "video-input-hdmi"
  },
  {
    uuid: "d9dc70ae-8267-4d1f-a1a5-9bcba54c50d0",
    iconSet: "MaterialCommunityIcons",
    iconName: "video-input-scart"
  },
  {
    uuid: "cb732997-5e26-4de3-adc5-b10af0832027",
    iconSet: "MaterialCommunityIcons",
    iconName: "video-input-svideo"
  },
  {
    uuid: "a256b05d-cbf3-4251-9996-d9bb2db348ca",
    iconSet: "MaterialCommunityIcons",
    iconName: "video-minus"
  },
  {
    uuid: "2cd3f74b-a625-4b5c-8d16-8a27b02b2e5b",
    iconSet: "MaterialCommunityIcons",
    iconName: "video-minus-outline"
  },
  {
    uuid: "5feb54d8-361c-42d6-b848-37622c630797",
    iconSet: "MaterialCommunityIcons",
    iconName: "video-off"
  },
  {
    uuid: "6627a293-3bac-42e2-b85e-b769765c4d20",
    iconSet: "MaterialCommunityIcons",
    iconName: "video-off-outline"
  },
  {
    uuid: "68bb0229-1d02-4723-8eec-79d803d49769",
    iconSet: "MaterialCommunityIcons",
    iconName: "video-outline"
  },
  {
    uuid: "07e61e45-1cea-4f28-8fcb-6af3c3f9d5d6",
    iconSet: "MaterialCommunityIcons",
    iconName: "video-plus"
  },
  {
    uuid: "36fb1f3e-f6be-48d3-995c-14c9a2b4f286",
    iconSet: "MaterialCommunityIcons",
    iconName: "video-plus-outline"
  },
  {
    uuid: "911d5e2f-b708-44a2-a544-f32dfc0648b3",
    iconSet: "MaterialCommunityIcons",
    iconName: "video-stabilization"
  },
  {
    uuid: "6b813b5d-6f40-44e1-b92f-71c587372cf3",
    iconSet: "MaterialCommunityIcons",
    iconName: "video-switch"
  },
  {
    uuid: "c5ab36e8-05d6-491e-b71d-ce23d92a5959",
    iconSet: "MaterialCommunityIcons",
    iconName: "video-switch-outline"
  },
  {
    uuid: "1d646c62-ad72-4a18-9905-5a55c2191382",
    iconSet: "MaterialCommunityIcons",
    iconName: "video-vintage"
  },
  {
    uuid: "81e0b1b1-b9d6-48d2-a1e5-67119aa1fc08",
    iconSet: "MaterialCommunityIcons",
    iconName: "video-wireless"
  },
  {
    uuid: "7e80ba70-22d6-4cfc-a8fd-3f460cd3d828",
    iconSet: "MaterialCommunityIcons",
    iconName: "video-wireless-outline"
  },
  {
    uuid: "affabaf3-a15d-43c7-ad6a-62563302cf1a",
    iconSet: "MaterialCommunityIcons",
    iconName: "view-agenda"
  },
  {
    uuid: "09f7cac1-7594-43e1-825e-975c9f9545fb",
    iconSet: "MaterialCommunityIcons",
    iconName: "view-agenda-outline"
  },
  {
    uuid: "2221d596-5979-4e76-983b-9fc5032c4cbe",
    iconSet: "MaterialCommunityIcons",
    iconName: "view-array"
  },
  {
    uuid: "4b837e17-fe55-4962-89f7-ffe2698bb9bd",
    iconSet: "MaterialCommunityIcons",
    iconName: "view-array-outline"
  },
  {
    uuid: "98c2afd5-dc26-47f8-b4c7-4027650edc2a",
    iconSet: "MaterialCommunityIcons",
    iconName: "view-carousel"
  },
  {
    uuid: "2420782f-656a-4969-b40e-769a1646b639",
    iconSet: "MaterialCommunityIcons",
    iconName: "view-carousel-outline"
  },
  {
    uuid: "caee5b97-a459-4964-a803-f7a0de6d9445",
    iconSet: "MaterialCommunityIcons",
    iconName: "view-column"
  },
  {
    uuid: "0c3514ba-ffe3-4c1a-8251-c9a77531ee94",
    iconSet: "MaterialCommunityIcons",
    iconName: "view-column-outline"
  },
  {
    uuid: "566e2050-aa3f-4fdb-b34c-b44444a5ee40",
    iconSet: "MaterialCommunityIcons",
    iconName: "view-comfy"
  },
  {
    uuid: "c00443de-68df-4882-9422-6bb97b3d7b4f",
    iconSet: "MaterialCommunityIcons",
    iconName: "view-comfy-outline"
  },
  {
    uuid: "7c9d8801-a5df-4b91-8f28-df6a84f305c0",
    iconSet: "MaterialCommunityIcons",
    iconName: "view-compact"
  },
  {
    uuid: "f7b8bf72-71cd-4597-8371-442fd97bd6d9",
    iconSet: "MaterialCommunityIcons",
    iconName: "view-compact-outline"
  },
  {
    uuid: "83d6ab19-3b30-466c-8c6c-d5d97f38578d",
    iconSet: "MaterialCommunityIcons",
    iconName: "view-dashboard"
  },
  {
    uuid: "35803549-12bc-4220-8b20-5e207e6554c0",
    iconSet: "MaterialCommunityIcons",
    iconName: "view-dashboard-outline"
  },
  {
    uuid: "3fa3a4c2-64b6-41f3-946b-7844ca38bb81",
    iconSet: "MaterialCommunityIcons",
    iconName: "view-dashboard-variant"
  },
  {
    uuid: "a551be34-545f-4384-86bd-dafb3fe54198",
    iconSet: "MaterialCommunityIcons",
    iconName: "view-dashboard-variant-outline"
  },
  {
    uuid: "5dc76713-42ad-4c88-a4e1-c2a8ea5501b0",
    iconSet: "MaterialCommunityIcons",
    iconName: "view-day"
  },
  {
    uuid: "054f6830-8d07-4dde-920c-dfeeedbfc803",
    iconSet: "MaterialCommunityIcons",
    iconName: "view-day-outline"
  },
  {
    uuid: "7814f19a-8f5a-45ce-bc48-4b802c09ab87",
    iconSet: "MaterialCommunityIcons",
    iconName: "view-grid"
  },
  {
    uuid: "386d34d0-ee35-4962-b44d-3b7046582e8c",
    iconSet: "MaterialCommunityIcons",
    iconName: "view-grid-outline"
  },
  {
    uuid: "0b7daf91-97cc-4d63-b684-cb0121ea48c2",
    iconSet: "MaterialCommunityIcons",
    iconName: "view-grid-plus"
  },
  {
    uuid: "bcac83e4-8590-4feb-905e-1a294bf9145e",
    iconSet: "MaterialCommunityIcons",
    iconName: "view-grid-plus-outline"
  },
  {
    uuid: "56e6d3dd-b894-49c0-94f5-4a4137884cfd",
    iconSet: "MaterialCommunityIcons",
    iconName: "view-headline"
  },
  {
    uuid: "e4ed9d2d-ea5f-42d4-b22f-26a2d05f2f6c",
    iconSet: "MaterialCommunityIcons",
    iconName: "view-list"
  },
  {
    uuid: "20bd59fd-ab49-43e9-b48f-4a74f9db5fd6",
    iconSet: "MaterialCommunityIcons",
    iconName: "view-list-outline"
  },
  {
    uuid: "77f0333f-3598-4741-8e75-dfb5b491f694",
    iconSet: "MaterialCommunityIcons",
    iconName: "view-module"
  },
  {
    uuid: "95bbf2a0-5375-4290-a385-fe8c1555585a",
    iconSet: "MaterialCommunityIcons",
    iconName: "view-module-outline"
  },
  {
    uuid: "e362be55-b814-4cb2-8d53-7e1c409be9c9",
    iconSet: "MaterialCommunityIcons",
    iconName: "view-parallel"
  },
  {
    uuid: "173cbef5-cef6-4ba8-9bcd-a6016228454a",
    iconSet: "MaterialCommunityIcons",
    iconName: "view-parallel-outline"
  },
  {
    uuid: "1156aaf4-3d97-4d4d-a426-ac5df396e0e0",
    iconSet: "MaterialCommunityIcons",
    iconName: "view-quilt"
  },
  {
    uuid: "b947d3cb-660b-4401-a3f5-1ae3efa8a692",
    iconSet: "MaterialCommunityIcons",
    iconName: "view-quilt-outline"
  },
  {
    uuid: "c1a7a12e-52bd-4bfb-b10d-97f160c4ba0b",
    iconSet: "MaterialCommunityIcons",
    iconName: "view-sequential"
  },
  {
    uuid: "3853b458-2823-4bf0-a863-a17474d6e1c5",
    iconSet: "MaterialCommunityIcons",
    iconName: "view-sequential-outline"
  },
  {
    uuid: "3b0b503c-343e-4f4e-8476-ca0c766c5366",
    iconSet: "MaterialCommunityIcons",
    iconName: "view-split-horizontal"
  },
  {
    uuid: "363487a2-6594-40a3-ac02-f48c0a091966",
    iconSet: "MaterialCommunityIcons",
    iconName: "view-split-vertical"
  },
  {
    uuid: "8c6085c4-edb8-4770-a055-d50487d67159",
    iconSet: "MaterialCommunityIcons",
    iconName: "view-stream"
  },
  {
    uuid: "171eecee-f73c-4233-b039-1b34cf15e0da",
    iconSet: "MaterialCommunityIcons",
    iconName: "view-stream-outline"
  },
  {
    uuid: "59518bed-696f-4825-8506-1acb9869e90d",
    iconSet: "MaterialCommunityIcons",
    iconName: "view-week"
  },
  {
    uuid: "be9a7d8a-1417-4907-a8a5-5a361db76af8",
    iconSet: "MaterialCommunityIcons",
    iconName: "view-week-outline"
  },
  {
    uuid: "83b8e7db-281f-43f7-a01d-375ddb93f8fe",
    iconSet: "MaterialCommunityIcons",
    iconName: "vimeo"
  },
  {
    uuid: "ee947c42-8d70-42ad-af33-2ff92aef47c6",
    iconSet: "MaterialCommunityIcons",
    iconName: "violin"
  },
  {
    uuid: "9441d6a0-11ad-40aa-881e-ffce41c0ee4c",
    iconSet: "MaterialCommunityIcons",
    iconName: "virtual-reality"
  },
  {
    uuid: "dbd2d6dd-a701-4f73-8531-8f3c1b19b4d3",
    iconSet: "MaterialCommunityIcons",
    iconName: "virus"
  },
  {
    uuid: "553e3f37-7ee4-4bab-9d2d-07a7088ab462",
    iconSet: "MaterialCommunityIcons",
    iconName: "virus-outline"
  },
  {
    uuid: "07ca8fae-5414-413d-b17f-ce114cec8a65",
    iconSet: "MaterialCommunityIcons",
    iconName: "vk"
  },
  {
    uuid: "0ef8dc32-a727-4cc7-aa11-4d11b29fd7b7",
    iconSet: "MaterialCommunityIcons",
    iconName: "vlc"
  },
  {
    uuid: "fa59ba0a-4c57-4c34-be8a-30cdde6029ca",
    iconSet: "MaterialCommunityIcons",
    iconName: "voice-off"
  },
  {
    uuid: "663c9ef0-a747-44cc-a60e-5517ad63f11b",
    iconSet: "MaterialCommunityIcons",
    iconName: "voicemail"
  },
  {
    uuid: "d86856b3-f5e7-4010-926a-a2d5a92bd6b8",
    iconSet: "MaterialCommunityIcons",
    iconName: "volleyball"
  },
  {
    uuid: "3360eeb9-589c-420a-8131-8b54334bc997",
    iconSet: "MaterialCommunityIcons",
    iconName: "volume-high"
  },
  {
    uuid: "5c4a0a40-05f1-4b4d-a96c-a0f5e67f85ae",
    iconSet: "MaterialCommunityIcons",
    iconName: "volume-low"
  },
  {
    uuid: "ed43a0e4-565c-40e7-9f92-1035638f3e65",
    iconSet: "MaterialCommunityIcons",
    iconName: "volume-medium"
  },
  {
    uuid: "72f3cc11-5782-4dd4-b460-b891c1c2ddbb",
    iconSet: "MaterialCommunityIcons",
    iconName: "volume-minus"
  },
  {
    uuid: "69567f2f-468b-4c6b-b30c-050dee7a9538",
    iconSet: "MaterialCommunityIcons",
    iconName: "volume-mute"
  },
  {
    uuid: "7695d986-d555-48b1-9ae9-0cc6238fe504",
    iconSet: "MaterialCommunityIcons",
    iconName: "volume-off"
  },
  {
    uuid: "d6aa63e6-759c-4e05-ac21-e2256463e4ae",
    iconSet: "MaterialCommunityIcons",
    iconName: "volume-plus"
  },
  {
    uuid: "e776ebea-7c2a-47b8-a46c-505f2fad0d51",
    iconSet: "MaterialCommunityIcons",
    iconName: "volume-source"
  },
  {
    uuid: "1e8239b9-796e-4611-bbcc-c4af79f4fe5a",
    iconSet: "MaterialCommunityIcons",
    iconName: "volume-variant-off"
  },
  {
    uuid: "ab34c8f5-bd77-48ef-b012-6ba3101e6950",
    iconSet: "MaterialCommunityIcons",
    iconName: "volume-vibrate"
  },
  {
    uuid: "2835e288-ef6d-4ca3-aaa3-e24b0e9be821",
    iconSet: "MaterialCommunityIcons",
    iconName: "vote"
  },
  {
    uuid: "19580656-0c7d-4272-918b-3ff782215345",
    iconSet: "MaterialCommunityIcons",
    iconName: "vote-outline"
  },
  {
    uuid: "9ed4c413-1d87-47f4-917e-7457de04a8f0",
    iconSet: "MaterialCommunityIcons",
    iconName: "vpn"
  },
  {
    uuid: "7a45f925-715c-409b-a9d0-580093e3df9b",
    iconSet: "MaterialCommunityIcons",
    iconName: "vuejs"
  },
  {
    uuid: "269568d1-d411-4a58-8c90-4435df918f83",
    iconSet: "MaterialCommunityIcons",
    iconName: "vuetify"
  },
  {
    uuid: "bb5a84f4-7583-4ba7-92c6-280244c3aa69",
    iconSet: "MaterialCommunityIcons",
    iconName: "walk"
  },
  {
    uuid: "a1e3c921-a7dd-4329-9b28-53913c3060fc",
    iconSet: "MaterialCommunityIcons",
    iconName: "wall"
  },
  {
    uuid: "cbd454b9-c48d-4e26-bf1b-42f62709d73d",
    iconSet: "MaterialCommunityIcons",
    iconName: "wall-sconce"
  },
  {
    uuid: "5ef1d9de-92a6-4af6-aacc-d8dd01daaf38",
    iconSet: "MaterialCommunityIcons",
    iconName: "wall-sconce-flat"
  },
  {
    uuid: "a90ddce0-7204-428b-a3fb-010f4e29eafa",
    iconSet: "MaterialCommunityIcons",
    iconName: "wall-sconce-flat-variant"
  },
  {
    uuid: "0e48da66-2837-4c2c-9453-f10df511b6ce",
    iconSet: "MaterialCommunityIcons",
    iconName: "wall-sconce-round"
  },
  {
    uuid: "e197380d-57ba-4551-a480-175cb116e83e",
    iconSet: "MaterialCommunityIcons",
    iconName: "wall-sconce-round-variant"
  },
  {
    uuid: "561be1d4-e027-4ff6-bac8-e9b6c0e75254",
    iconSet: "MaterialCommunityIcons",
    iconName: "wallet"
  },
  {
    uuid: "aea6a4ee-41ac-490b-8f99-f55b9080c692",
    iconSet: "MaterialCommunityIcons",
    iconName: "wallet-giftcard"
  },
  {
    uuid: "d53e0814-d302-47ad-aced-31fa34fb71b2",
    iconSet: "MaterialCommunityIcons",
    iconName: "wallet-membership"
  },
  {
    uuid: "284fc0b4-40f9-4b08-9e10-42dc8c70fd0c",
    iconSet: "MaterialCommunityIcons",
    iconName: "wallet-outline"
  },
  {
    uuid: "d1b417f3-d329-4f6d-a8a4-3186079cae26",
    iconSet: "MaterialCommunityIcons",
    iconName: "wallet-plus"
  },
  {
    uuid: "eba30c4b-f95c-48f0-91bc-2468b85a386a",
    iconSet: "MaterialCommunityIcons",
    iconName: "wallet-plus-outline"
  },
  {
    uuid: "e838dc2d-99c3-471d-8ce5-41470eccfbbe",
    iconSet: "MaterialCommunityIcons",
    iconName: "wallet-travel"
  },
  {
    uuid: "db4fc82b-ea6a-4c49-ba4a-e2ffbd04f900",
    iconSet: "MaterialCommunityIcons",
    iconName: "wallpaper"
  },
  {
    uuid: "9061065e-deda-46c4-8ecf-f126c714a3a2",
    iconSet: "MaterialCommunityIcons",
    iconName: "wan"
  },
  {
    uuid: "9b16cc41-0e30-4f6b-b779-897e71811234",
    iconSet: "MaterialCommunityIcons",
    iconName: "wardrobe"
  },
  {
    uuid: "91c3bbac-84c9-4222-a343-bc2c241a724d",
    iconSet: "MaterialCommunityIcons",
    iconName: "wardrobe-outline"
  },
  {
    uuid: "957f5987-c565-40f3-a962-14b1e59c44ed",
    iconSet: "MaterialCommunityIcons",
    iconName: "warehouse"
  },
  {
    uuid: "efc3888d-a253-415c-a869-c91fe5d5de48",
    iconSet: "MaterialCommunityIcons",
    iconName: "washing-machine"
  },
  {
    uuid: "45b58a56-4d20-4957-b877-be2c4171abd5",
    iconSet: "MaterialCommunityIcons",
    iconName: "washing-machine-alert"
  },
  {
    uuid: "6fd36a74-3446-4339-8751-7e2d29cfff16",
    iconSet: "MaterialCommunityIcons",
    iconName: "washing-machine-off"
  },
  {
    uuid: "c3ac1c65-5f58-4e2f-a68b-3426863514eb",
    iconSet: "MaterialCommunityIcons",
    iconName: "watch"
  },
  {
    uuid: "60c59528-fc8e-4e14-8941-66ad746950bb",
    iconSet: "MaterialCommunityIcons",
    iconName: "watch-export"
  },
  {
    uuid: "625ec862-745c-4313-948a-f50ded6b96d2",
    iconSet: "MaterialCommunityIcons",
    iconName: "watch-export-variant"
  },
  {
    uuid: "fe06af7f-680b-41cd-ae8c-a800bbbb9d27",
    iconSet: "MaterialCommunityIcons",
    iconName: "watch-import"
  },
  {
    uuid: "60c8e65f-9aca-4f1d-afd1-1a3dde2186a0",
    iconSet: "MaterialCommunityIcons",
    iconName: "watch-import-variant"
  },
  {
    uuid: "4219cfb7-d1ff-46c5-b394-f76d68855ab3",
    iconSet: "MaterialCommunityIcons",
    iconName: "watch-variant"
  },
  {
    uuid: "084b3dd2-aee8-4369-ba92-0e72118ad7dd",
    iconSet: "MaterialCommunityIcons",
    iconName: "watch-vibrate"
  },
  {
    uuid: "f7bc27be-5b00-474d-b7a4-da51bd2d8745",
    iconSet: "MaterialCommunityIcons",
    iconName: "watch-vibrate-off"
  },
  {
    uuid: "7ecb1055-0361-4950-8207-74461e9f5fe8",
    iconSet: "MaterialCommunityIcons",
    iconName: "water"
  },
  {
    uuid: "8b12d3dd-6a93-41b4-826c-bc37f9807f89",
    iconSet: "MaterialCommunityIcons",
    iconName: "water-boiler"
  },
  {
    uuid: "8adee26d-4755-4d63-b4d6-2901d2de5713",
    iconSet: "MaterialCommunityIcons",
    iconName: "water-boiler-alert"
  },
  {
    uuid: "47080bf0-fafc-426a-8f75-119ac545ff60",
    iconSet: "MaterialCommunityIcons",
    iconName: "water-boiler-off"
  },
  {
    uuid: "920b7c99-4151-4656-b398-71f7896375c9",
    iconSet: "MaterialCommunityIcons",
    iconName: "water-off"
  },
  {
    uuid: "35e53464-1692-4a85-bb4a-1ac7d4e2bca9",
    iconSet: "MaterialCommunityIcons",
    iconName: "water-outline"
  },
  {
    uuid: "0bfb8843-6c74-4377-bcd0-7825bd65542f",
    iconSet: "MaterialCommunityIcons",
    iconName: "water-percent"
  },
  {
    uuid: "48ba8eff-4734-4769-bad7-a0ec33e3af70",
    iconSet: "MaterialCommunityIcons",
    iconName: "water-polo"
  },
  {
    uuid: "bbfdd7eb-f35d-45b1-a555-a8bab4b42fd1",
    iconSet: "MaterialCommunityIcons",
    iconName: "water-pump"
  },
  {
    uuid: "290f8801-51e7-410b-aeb7-182c9dcd368c",
    iconSet: "MaterialCommunityIcons",
    iconName: "water-pump-off"
  },
  {
    uuid: "a591e439-76af-4ada-b3c2-c238a66c3bb2",
    iconSet: "MaterialCommunityIcons",
    iconName: "water-well"
  },
  {
    uuid: "16c49fa8-0665-41f0-a11a-95711445994c",
    iconSet: "MaterialCommunityIcons",
    iconName: "water-well-outline"
  },
  {
    uuid: "60c9716f-e78f-467a-8fa1-9f9262910d23",
    iconSet: "MaterialCommunityIcons",
    iconName: "watering-can"
  },
  {
    uuid: "7f0d9255-3149-4d28-81b1-9b33d580fbbd",
    iconSet: "MaterialCommunityIcons",
    iconName: "watering-can-outline"
  },
  {
    uuid: "2167c15c-fb6b-47af-a90a-a90ce422fbd3",
    iconSet: "MaterialCommunityIcons",
    iconName: "watermark"
  },
  {
    uuid: "43d18d4b-f6c3-428c-9cfc-f76a85befa4e",
    iconSet: "MaterialCommunityIcons",
    iconName: "wave"
  },
  {
    uuid: "3acd40c0-4347-419a-99c6-9cc738006b61",
    iconSet: "MaterialCommunityIcons",
    iconName: "waveform"
  },
  {
    uuid: "7afb1870-ba93-4dda-83c8-001feeca11cd",
    iconSet: "MaterialCommunityIcons",
    iconName: "waves"
  },
  {
    uuid: "2c2f9aad-7337-409e-81a5-436f6102c90f",
    iconSet: "MaterialCommunityIcons",
    iconName: "waze"
  },
  {
    uuid: "5bc3b97a-8752-4e68-9ea0-629678a104d9",
    iconSet: "MaterialCommunityIcons",
    iconName: "weather-cloudy"
  },
  {
    uuid: "3f40e436-4d56-42d1-9f0a-c0699029d9d8",
    iconSet: "MaterialCommunityIcons",
    iconName: "weather-cloudy-alert"
  },
  {
    uuid: "251b142a-1da8-4608-9f5f-39ec2905b14b",
    iconSet: "MaterialCommunityIcons",
    iconName: "weather-cloudy-arrow-right"
  },
  {
    uuid: "ead7603b-8c8c-4514-ac39-72814e005944",
    iconSet: "MaterialCommunityIcons",
    iconName: "weather-fog"
  },
  {
    uuid: "c9a5fb12-878e-46cd-a8a2-50f32918d98c",
    iconSet: "MaterialCommunityIcons",
    iconName: "weather-hail"
  },
  {
    uuid: "300ab253-9b9a-4fb0-a343-d5207a3bd6ac",
    iconSet: "MaterialCommunityIcons",
    iconName: "weather-hazy"
  },
  {
    uuid: "0a509767-5c45-4a9e-96dd-5c9b0a8a2f4a",
    iconSet: "MaterialCommunityIcons",
    iconName: "weather-hurricane"
  },
  {
    uuid: "bb30bff6-fb73-406b-9550-79331145c12d",
    iconSet: "MaterialCommunityIcons",
    iconName: "weather-lightning"
  },
  {
    uuid: "c75e1560-c272-499e-ac23-077ee83598ea",
    iconSet: "MaterialCommunityIcons",
    iconName: "weather-lightning-rainy"
  },
  {
    uuid: "ce766fe2-d61b-4910-af3f-d88f6c6e4b69",
    iconSet: "MaterialCommunityIcons",
    iconName: "weather-night"
  },
  {
    uuid: "cf1545ae-6285-425d-a792-2cefd707454a",
    iconSet: "MaterialCommunityIcons",
    iconName: "weather-night-partly-cloudy"
  },
  {
    uuid: "cdd03ca5-d958-45e6-866b-2024c52fa620",
    iconSet: "MaterialCommunityIcons",
    iconName: "weather-partly-cloudy"
  },
  {
    uuid: "4775e99e-b7b2-424f-a0f3-f427d64255b9",
    iconSet: "MaterialCommunityIcons",
    iconName: "weather-partly-lightning"
  },
  {
    uuid: "0655356f-ad31-4eca-981b-8bcba9fbbd5e",
    iconSet: "MaterialCommunityIcons",
    iconName: "weather-partly-rainy"
  },
  {
    uuid: "3cacf774-0dde-468b-b98c-b0a8b66ec79a",
    iconSet: "MaterialCommunityIcons",
    iconName: "weather-partly-snowy"
  },
  {
    uuid: "1ad024ff-a1c6-4716-8607-af23d4fed0cd",
    iconSet: "MaterialCommunityIcons",
    iconName: "weather-partly-snowy-rainy"
  },
  {
    uuid: "ad103d8f-9417-4ba8-afc4-731acc294b82",
    iconSet: "MaterialCommunityIcons",
    iconName: "weather-pouring"
  },
  {
    uuid: "b57ed974-086e-4306-8cb5-876469d5b579",
    iconSet: "MaterialCommunityIcons",
    iconName: "weather-rainy"
  },
  {
    uuid: "47c7d2c6-32d7-4419-b3a1-db1a5b6aff29",
    iconSet: "MaterialCommunityIcons",
    iconName: "weather-snowy"
  },
  {
    uuid: "e01966bb-83fb-4971-b51e-dcfe2309f513",
    iconSet: "MaterialCommunityIcons",
    iconName: "weather-snowy-heavy"
  },
  {
    uuid: "54314df0-dda6-48ae-9472-aee4a285a42a",
    iconSet: "MaterialCommunityIcons",
    iconName: "weather-snowy-rainy"
  },
  {
    uuid: "2d2788d4-1214-45f3-a5c8-21ee9c6e497a",
    iconSet: "MaterialCommunityIcons",
    iconName: "weather-sunny"
  },
  {
    uuid: "e240eb7d-969f-4a51-9228-4753c2d0bf75",
    iconSet: "MaterialCommunityIcons",
    iconName: "weather-sunny-alert"
  },
  {
    uuid: "a998b8e0-239c-4b36-8715-77409df44339",
    iconSet: "MaterialCommunityIcons",
    iconName: "weather-sunset"
  },
  {
    uuid: "143a5255-7d89-43a9-9f99-8ff4800d5c2b",
    iconSet: "MaterialCommunityIcons",
    iconName: "weather-sunset-down"
  },
  {
    uuid: "131ac649-061f-4038-97f0-9d5081c44d94",
    iconSet: "MaterialCommunityIcons",
    iconName: "weather-sunset-up"
  },
  {
    uuid: "0079b736-8d64-4685-bb4a-33df384beff9",
    iconSet: "MaterialCommunityIcons",
    iconName: "weather-tornado"
  },
  {
    uuid: "19b4e43e-1061-480d-8c39-8826cffa5252",
    iconSet: "MaterialCommunityIcons",
    iconName: "weather-windy"
  },
  {
    uuid: "801aa095-c421-43e9-a38c-98f86f2a47f4",
    iconSet: "MaterialCommunityIcons",
    iconName: "weather-windy-variant"
  },
  {
    uuid: "9bbb9563-c7c3-4fa3-98e7-d5e0e3d98e2c",
    iconSet: "MaterialCommunityIcons",
    iconName: "web"
  },
  {
    uuid: "7deb021a-dc9b-4bab-bf37-7014d983159a",
    iconSet: "MaterialCommunityIcons",
    iconName: "web-box"
  },
  {
    uuid: "3de0d680-5a20-44fa-aa7f-0f57ca4588c6",
    iconSet: "MaterialCommunityIcons",
    iconName: "web-clock"
  },
  {
    uuid: "691cc60f-23b7-48e4-97f6-db127b7fa7a2",
    iconSet: "MaterialCommunityIcons",
    iconName: "webcam"
  },
  {
    uuid: "0ad8a8ee-3d8a-45ca-bb40-426e139b82ff",
    iconSet: "MaterialCommunityIcons",
    iconName: "webhook"
  },
  {
    uuid: "79e0a7ab-9e06-4c66-a41c-b5ee034e372b",
    iconSet: "MaterialCommunityIcons",
    iconName: "webpack"
  },
  {
    uuid: "48ee10e9-7411-46e9-937e-5070f581fe8a",
    iconSet: "MaterialCommunityIcons",
    iconName: "webrtc"
  },
  {
    uuid: "e8bf936a-50d8-4dca-99be-2bc0b8f3ce4b",
    iconSet: "MaterialCommunityIcons",
    iconName: "wechat"
  },
  {
    uuid: "4f1a77dc-36f3-439d-aeaa-4a70a9c5b8ab",
    iconSet: "MaterialCommunityIcons",
    iconName: "weight"
  },
  {
    uuid: "a054d11e-4542-41bf-838f-a37a4cf34c02",
    iconSet: "MaterialCommunityIcons",
    iconName: "weight-gram"
  },
  {
    uuid: "a0aa9829-d620-4281-8aaa-757d28921e3c",
    iconSet: "MaterialCommunityIcons",
    iconName: "weight-kilogram"
  },
  {
    uuid: "e101874c-f23a-441d-8576-86cfbe425212",
    iconSet: "MaterialCommunityIcons",
    iconName: "weight-lifter"
  },
  {
    uuid: "f958d734-88e9-41f8-88db-0ff4607e1a3a",
    iconSet: "MaterialCommunityIcons",
    iconName: "weight-pound"
  },
  {
    uuid: "d7737f58-98ae-4e70-9a90-b0e08fa71910",
    iconSet: "MaterialCommunityIcons",
    iconName: "whatsapp"
  },
  {
    uuid: "3efd35ce-2ec3-4057-a3e1-c3ddb68774cf",
    iconSet: "MaterialCommunityIcons",
    iconName: "wheelchair-accessibility"
  },
  {
    uuid: "c5fa9df6-d28c-45db-b546-ad75bf139023",
    iconSet: "MaterialCommunityIcons",
    iconName: "whistle"
  },
  {
    uuid: "b7839f04-3775-4981-b6b2-90397364179c",
    iconSet: "MaterialCommunityIcons",
    iconName: "whistle-outline"
  },
  {
    uuid: "14d067b9-0463-4195-b344-4a66f7c9868f",
    iconSet: "MaterialCommunityIcons",
    iconName: "white-balance-auto"
  },
  {
    uuid: "db3abbe0-eb4a-4066-86c3-9b7a05360467",
    iconSet: "MaterialCommunityIcons",
    iconName: "white-balance-incandescent"
  },
  {
    uuid: "cd92efc2-084c-41b9-92eb-56ab87ccb13b",
    iconSet: "MaterialCommunityIcons",
    iconName: "white-balance-iridescent"
  },
  {
    uuid: "123dded8-c7d4-4d19-8dde-6ea74c982bfd",
    iconSet: "MaterialCommunityIcons",
    iconName: "white-balance-sunny"
  },
  {
    uuid: "f4432988-f777-411f-81c7-7050f3d54c23",
    iconSet: "MaterialCommunityIcons",
    iconName: "widgets"
  },
  {
    uuid: "31578a1b-1c9e-4dc3-b9fb-53c8e7a43ff3",
    iconSet: "MaterialCommunityIcons",
    iconName: "widgets-outline"
  },
  {
    uuid: "0df9a5ce-e78f-40d8-ac0e-5a79585d5b83",
    iconSet: "MaterialCommunityIcons",
    iconName: "wifi"
  },
  {
    uuid: "e2cd3cdd-7a10-4404-8d36-a829b6311b11",
    iconSet: "MaterialCommunityIcons",
    iconName: "wifi-off"
  },
  {
    uuid: "c5034363-9dcf-4e3c-b8b5-742db0fd6260",
    iconSet: "MaterialCommunityIcons",
    iconName: "wifi-star"
  },
  {
    uuid: "0931a3aa-1b18-4a17-8847-4673f66d1174",
    iconSet: "MaterialCommunityIcons",
    iconName: "wifi-strength-1"
  },
  {
    uuid: "df242544-841d-41cf-8a24-607d8a955bfb",
    iconSet: "MaterialCommunityIcons",
    iconName: "wifi-strength-1-alert"
  },
  {
    uuid: "40f4a62f-eb04-4bee-8ce6-c1a0beddc1ee",
    iconSet: "MaterialCommunityIcons",
    iconName: "wifi-strength-1-lock"
  },
  {
    uuid: "522feb0d-0d00-44df-b9e5-78ff0a897fd4",
    iconSet: "MaterialCommunityIcons",
    iconName: "wifi-strength-2"
  },
  {
    uuid: "55accea7-c88f-45cb-b734-e75eace22c63",
    iconSet: "MaterialCommunityIcons",
    iconName: "wifi-strength-2-alert"
  },
  {
    uuid: "337bd2eb-8562-4f06-b6cd-054311a67207",
    iconSet: "MaterialCommunityIcons",
    iconName: "wifi-strength-2-lock"
  },
  {
    uuid: "8b242596-cc9f-47f3-bfdf-63efe1733448",
    iconSet: "MaterialCommunityIcons",
    iconName: "wifi-strength-3"
  },
  {
    uuid: "fb64c0f5-67dc-4d5a-9f48-8fcbd33af403",
    iconSet: "MaterialCommunityIcons",
    iconName: "wifi-strength-3-alert"
  },
  {
    uuid: "d13e5e8b-a4b2-49c7-9da8-942134b84c3e",
    iconSet: "MaterialCommunityIcons",
    iconName: "wifi-strength-3-lock"
  },
  {
    uuid: "58301d26-fd67-453e-8035-41cc82b676c4",
    iconSet: "MaterialCommunityIcons",
    iconName: "wifi-strength-4"
  },
  {
    uuid: "cd39a0c8-1c47-43ed-84a1-238d2486970f",
    iconSet: "MaterialCommunityIcons",
    iconName: "wifi-strength-4-alert"
  },
  {
    uuid: "4ebf6862-b3f7-4654-8901-ddd224ab4f96",
    iconSet: "MaterialCommunityIcons",
    iconName: "wifi-strength-4-lock"
  },
  {
    uuid: "7e999547-9f29-4b5b-a1fe-bf730b1d9b18",
    iconSet: "MaterialCommunityIcons",
    iconName: "wifi-strength-alert-outline"
  },
  {
    uuid: "cad44a9f-103f-498a-b3fe-15d333089cf8",
    iconSet: "MaterialCommunityIcons",
    iconName: "wifi-strength-lock-outline"
  },
  {
    uuid: "ad9d492e-8702-4298-96f1-b81b2f438d52",
    iconSet: "MaterialCommunityIcons",
    iconName: "wifi-strength-off"
  },
  {
    uuid: "a3c55912-30ea-4161-9093-16e28836c206",
    iconSet: "MaterialCommunityIcons",
    iconName: "wifi-strength-off-outline"
  },
  {
    uuid: "650f58ba-9f64-4627-98f8-6fcad3807445",
    iconSet: "MaterialCommunityIcons",
    iconName: "wifi-strength-outline"
  },
  {
    uuid: "50e44bf9-5d67-46c4-a208-fca29a405ae7",
    iconSet: "MaterialCommunityIcons",
    iconName: "wikipedia"
  },
  {
    uuid: "d5373c97-a457-4167-b608-6a8e9b5d379e",
    iconSet: "MaterialCommunityIcons",
    iconName: "wind-turbine"
  },
  {
    uuid: "7ff8d724-c875-4f68-ab2b-a1655cbaed0b",
    iconSet: "MaterialCommunityIcons",
    iconName: "window-close"
  },
  {
    uuid: "2d542475-0a26-4f27-9fb4-3b833d11605c",
    iconSet: "MaterialCommunityIcons",
    iconName: "window-closed"
  },
  {
    uuid: "99be6d5e-06d8-4c62-9cbb-1a2dfe3c4b5f",
    iconSet: "MaterialCommunityIcons",
    iconName: "window-closed-variant"
  },
  {
    uuid: "7764f3a5-0e95-4e17-ba30-1f71d9f29a9d",
    iconSet: "MaterialCommunityIcons",
    iconName: "window-maximize"
  },
  {
    uuid: "e0fe7702-7547-444e-92bf-d0a05e43c3c3",
    iconSet: "MaterialCommunityIcons",
    iconName: "window-minimize"
  },
  {
    uuid: "a1b419a6-5d16-4f24-9d71-1d3e2c9c9bd4",
    iconSet: "MaterialCommunityIcons",
    iconName: "window-open"
  },
  {
    uuid: "e6f9acb8-6258-4847-816e-86000365bd5c",
    iconSet: "MaterialCommunityIcons",
    iconName: "window-open-variant"
  },
  {
    uuid: "6deeb633-594e-4474-9d3e-b22e40c2c80e",
    iconSet: "MaterialCommunityIcons",
    iconName: "window-restore"
  },
  {
    uuid: "bbd84b8c-69a8-45dc-a89f-628440cff966",
    iconSet: "MaterialCommunityIcons",
    iconName: "window-shutter"
  },
  {
    uuid: "4a5edeed-38b3-4e55-a96e-93ed794a9f8a",
    iconSet: "MaterialCommunityIcons",
    iconName: "window-shutter-alert"
  },
  {
    uuid: "0ecfdb0d-fb22-45af-91e7-4ac5468589d1",
    iconSet: "MaterialCommunityIcons",
    iconName: "window-shutter-open"
  },
  {
    uuid: "942cee8b-9de5-4595-befb-f5fd29e7b2b4",
    iconSet: "MaterialCommunityIcons",
    iconName: "wiper"
  },
  {
    uuid: "4b546296-366a-4c81-a06f-81efef4235f7",
    iconSet: "MaterialCommunityIcons",
    iconName: "wiper-wash"
  },
  {
    uuid: "6d650ba2-92b9-490e-9219-5b9a1129df23",
    iconSet: "MaterialCommunityIcons",
    iconName: "wizard-hat"
  },
  {
    uuid: "91bb406a-7c4a-47af-9b7f-9ecf61f541fb",
    iconSet: "MaterialCommunityIcons",
    iconName: "wordpress"
  },
  {
    uuid: "8246e1c7-489d-4e0f-b40a-f747ccf76bdf",
    iconSet: "MaterialCommunityIcons",
    iconName: "wrap"
  },
  {
    uuid: "d519b06f-4bde-417f-98b5-89c72ace61a7",
    iconSet: "MaterialCommunityIcons",
    iconName: "wrap-disabled"
  },
  {
    uuid: "261ca9ba-702b-46b4-a1f5-8ef0d666304a",
    iconSet: "MaterialCommunityIcons",
    iconName: "wrench"
  },
  {
    uuid: "8cd58244-5425-46b0-9b24-af7c3400cc21",
    iconSet: "MaterialCommunityIcons",
    iconName: "wrench-outline"
  },
  {
    uuid: "e641e6f5-1bd9-401f-87a3-489c9ad09265",
    iconSet: "MaterialCommunityIcons",
    iconName: "xamarin"
  },
  {
    uuid: "35cbcf76-1814-433e-946c-cf7fa11e3d5c",
    iconSet: "MaterialCommunityIcons",
    iconName: "xamarin-outline"
  },
  {
    uuid: "6cb13577-ac8d-4826-8be6-7d75ec71b038",
    iconSet: "MaterialCommunityIcons",
    iconName: "xing"
  },
  {
    uuid: "b64f15f1-7e67-4147-b087-564db48d5c70",
    iconSet: "MaterialCommunityIcons",
    iconName: "xml"
  },
  {
    uuid: "2d470d89-37e7-4ec1-ae0d-0ac2e550a96a",
    iconSet: "MaterialCommunityIcons",
    iconName: "xmpp"
  },
  {
    uuid: "87c0e571-b0e2-4917-a271-648b81ddf2b3",
    iconSet: "MaterialCommunityIcons",
    iconName: "y-combinator"
  },
  {
    uuid: "9bd97282-0299-421e-b392-b6137b61bd93",
    iconSet: "MaterialCommunityIcons",
    iconName: "yahoo"
  },
  {
    uuid: "cfbd4ff2-f82a-4df4-b599-ced162fa8544",
    iconSet: "MaterialCommunityIcons",
    iconName: "yeast"
  },
  {
    uuid: "194beb6b-08d4-4306-8331-966222e9ba4c",
    iconSet: "MaterialCommunityIcons",
    iconName: "yin-yang"
  },
  {
    uuid: "7f85667c-7a31-40b9-86c2-e0afe8e82f7b",
    iconSet: "MaterialCommunityIcons",
    iconName: "yoga"
  },
  {
    uuid: "226dd5bc-f829-415d-ab86-c105fab724bf",
    iconSet: "MaterialCommunityIcons",
    iconName: "youtube"
  },
  {
    uuid: "34a5581a-9b08-447e-bbc4-62a15a2b5722",
    iconSet: "MaterialCommunityIcons",
    iconName: "youtube-gaming"
  },
  {
    uuid: "9c1dd110-ea8a-4e76-8c05-1623fae65d8a",
    iconSet: "MaterialCommunityIcons",
    iconName: "youtube-studio"
  },
  {
    uuid: "4d4c99e3-d3e5-4e19-b9fa-f5efac243b3e",
    iconSet: "MaterialCommunityIcons",
    iconName: "youtube-subscription"
  },
  {
    uuid: "7e37f9ff-759b-4c65-a9a1-67e45a6c90a1",
    iconSet: "MaterialCommunityIcons",
    iconName: "youtube-tv"
  },
  {
    uuid: "b7a6a347-a32d-4b36-b6e3-e190427cbba5",
    iconSet: "MaterialCommunityIcons",
    iconName: "z-wave"
  },
  {
    uuid: "73e901e2-68d7-4535-bc9c-2315fdc9782f",
    iconSet: "MaterialCommunityIcons",
    iconName: "zend"
  },
  {
    uuid: "efb64c83-b7df-46f8-992c-503e1b794057",
    iconSet: "MaterialCommunityIcons",
    iconName: "zigbee"
  },
  {
    uuid: "4a03fef9-6a7a-4cba-833f-425c1035a1e6",
    iconSet: "MaterialCommunityIcons",
    iconName: "zip-box"
  },
  {
    uuid: "d8c90249-bf05-4524-8714-862b8d887023",
    iconSet: "MaterialCommunityIcons",
    iconName: "zip-box-outline"
  },
  {
    uuid: "87ff679e-05cc-403b-9644-78a250cf4819",
    iconSet: "MaterialCommunityIcons",
    iconName: "zip-disk"
  },
  {
    uuid: "080d4998-f505-4ef3-9425-681a3147c825",
    iconSet: "MaterialCommunityIcons",
    iconName: "zodiac-aquarius"
  },
  {
    uuid: "d60c20fe-4657-45d6-a9f3-a1b80a1c3a63",
    iconSet: "MaterialCommunityIcons",
    iconName: "zodiac-aries"
  },
  {
    uuid: "8676ff01-f309-4a64-9daa-ae21d48632a6",
    iconSet: "MaterialCommunityIcons",
    iconName: "zodiac-cancer"
  },
  {
    uuid: "53ea9a71-02b5-49ae-b0e3-6ba0ecf9a13b",
    iconSet: "MaterialCommunityIcons",
    iconName: "zodiac-capricorn"
  },
  {
    uuid: "4284d11d-2b00-4719-b968-dbe54cbaa845",
    iconSet: "MaterialCommunityIcons",
    iconName: "zodiac-gemini"
  },
  {
    uuid: "7d2b253e-4a1f-406e-aff4-066edc6343b6",
    iconSet: "MaterialCommunityIcons",
    iconName: "zodiac-leo"
  },
  {
    uuid: "f0e1e4ab-904e-400f-afec-63880155368d",
    iconSet: "MaterialCommunityIcons",
    iconName: "zodiac-libra"
  },
  {
    uuid: "2edda44c-222f-47d1-ae7c-4e2b3fe4d8af",
    iconSet: "MaterialCommunityIcons",
    iconName: "zodiac-pisces"
  },
  {
    uuid: "6ae8ed33-e9a1-4cd6-8087-812ab63ebf17",
    iconSet: "MaterialCommunityIcons",
    iconName: "zodiac-sagittarius"
  },
  {
    uuid: "78b39253-68cc-4f21-839d-3963ee65f694",
    iconSet: "MaterialCommunityIcons",
    iconName: "zodiac-scorpio"
  },
  {
    uuid: "6e464fb4-0176-4d7b-bfe5-9f2420692a31",
    iconSet: "MaterialCommunityIcons",
    iconName: "zodiac-taurus"
  },
  {
    uuid: "81108fe7-5161-43a1-9f64-afdce1683218",
    iconSet: "MaterialCommunityIcons",
    iconName: "zodiac-virgo"
  },
  {
    uuid: "565027b5-b1f2-4040-aa45-fad3675f8d1c",
    iconSet: "MaterialCommunityIcons",
    iconName: "blank"
  },
  {
    uuid: "7791a5e1-201a-4cb9-bfef-555ba2357576",
    iconSet: "MaterialIcons",
    iconName: "360"
  },
  {
    uuid: "f7cd9999-4386-4fc6-b9a9-86b325f57549",
    iconSet: "MaterialIcons",
    iconName: "10k"
  },
  {
    uuid: "98fa84f0-8986-427b-8632-15e4d039b2dc",
    iconSet: "MaterialIcons",
    iconName: "10mp"
  },
  {
    uuid: "d682a1ef-6b51-45ca-936e-1127d6bfefc0",
    iconSet: "MaterialIcons",
    iconName: "11mp"
  },
  {
    uuid: "c5e09013-38e6-45a9-9edc-485cf60c44e6",
    iconSet: "MaterialIcons",
    iconName: "12mp"
  },
  {
    uuid: "30e5a51b-27a3-44df-b98f-0ca4b37f82e9",
    iconSet: "MaterialIcons",
    iconName: "13mp"
  },
  {
    uuid: "6478e701-2b1e-4cde-9916-7980ed51e718",
    iconSet: "MaterialIcons",
    iconName: "14mp"
  },
  {
    uuid: "404e938e-be5d-4aa4-92cb-63f6b4746ec7",
    iconSet: "MaterialIcons",
    iconName: "15mp"
  },
  {
    uuid: "9f135e5c-0d26-4a0c-94d6-b5fa0cb17172",
    iconSet: "MaterialIcons",
    iconName: "16mp"
  },
  {
    uuid: "68e3f0d1-f664-42df-b159-538dcfed9611",
    iconSet: "MaterialIcons",
    iconName: "17mp"
  },
  {
    uuid: "07a738bc-033c-4cd1-aec1-8624dccc608a",
    iconSet: "MaterialIcons",
    iconName: "18mp"
  },
  {
    uuid: "f8be211e-04da-44f1-8c1b-f6cebde9adb6",
    iconSet: "MaterialIcons",
    iconName: "19mp"
  },
  {
    uuid: "535d9347-a3d2-4f22-a959-24fccd011d2c",
    iconSet: "MaterialIcons",
    iconName: "1k"
  },
  {
    uuid: "240ca835-b67e-415c-b104-48f10d14b244",
    iconSet: "MaterialIcons",
    iconName: "1k-plus"
  },
  {
    uuid: "a424502a-1d35-48f3-826e-0b6139cb72af",
    iconSet: "MaterialIcons",
    iconName: "20mp"
  },
  {
    uuid: "52e5d8a2-2f15-4c44-bc09-4dfadc5a1c98",
    iconSet: "MaterialIcons",
    iconName: "21mp"
  },
  {
    uuid: "c4e38fbf-7f55-4a2b-a87e-2ff20aaa1aca",
    iconSet: "MaterialIcons",
    iconName: "22mp"
  },
  {
    uuid: "3f21fafb-8610-4219-a799-c52323713a13",
    iconSet: "MaterialIcons",
    iconName: "23mp"
  },
  {
    uuid: "71eb50ef-c9a3-459a-84d2-f24eaea9c2f4",
    iconSet: "MaterialIcons",
    iconName: "24mp"
  },
  {
    uuid: "b3453cd4-6900-4902-bdf8-24cdcddd0896",
    iconSet: "MaterialIcons",
    iconName: "2k"
  },
  {
    uuid: "9f84d0f6-e98f-472f-9420-1b8e60fef85b",
    iconSet: "MaterialIcons",
    iconName: "2k-plus"
  },
  {
    uuid: "4640915c-471a-4c93-92ef-574a953206cb",
    iconSet: "MaterialIcons",
    iconName: "2mp"
  },
  {
    uuid: "d6d950d8-5b90-4d21-b8c7-6a8079759b63",
    iconSet: "MaterialIcons",
    iconName: "3d-rotation"
  },
  {
    uuid: "df51c96d-a2fd-42bf-a3cd-5f84e9ef3f6b",
    iconSet: "MaterialIcons",
    iconName: "3k"
  },
  {
    uuid: "ca339dee-b69b-43dc-a107-641a104553ba",
    iconSet: "MaterialIcons",
    iconName: "3k-plus"
  },
  {
    uuid: "78dde2c7-5416-44f1-a9d5-a792ddf6c07d",
    iconSet: "MaterialIcons",
    iconName: "3mp"
  },
  {
    uuid: "b0a18099-f4ef-48c9-bcb0-3366c517f739",
    iconSet: "MaterialIcons",
    iconName: "4k"
  },
  {
    uuid: "fea184af-5709-4d49-be58-9501da448a2c",
    iconSet: "MaterialIcons",
    iconName: "4k-plus"
  },
  {
    uuid: "6383f21c-316c-4f8f-8001-c773a606ae57",
    iconSet: "MaterialIcons",
    iconName: "4mp"
  },
  {
    uuid: "50b510e3-9208-48e2-a653-0add7b4f27f0",
    iconSet: "MaterialIcons",
    iconName: "5g"
  },
  {
    uuid: "31c98f45-7169-4823-82f3-2758f1df2125",
    iconSet: "MaterialIcons",
    iconName: "5k"
  },
  {
    uuid: "152cb2fa-b98c-4893-9552-2de42249ba56",
    iconSet: "MaterialIcons",
    iconName: "5k-plus"
  },
  {
    uuid: "3389b61a-4d24-443a-8507-daba8c9ad8ce",
    iconSet: "MaterialIcons",
    iconName: "5mp"
  },
  {
    uuid: "ec0c20af-7755-413d-8c37-1b1bbcffc5fb",
    iconSet: "MaterialIcons",
    iconName: "6-ft-apart"
  },
  {
    uuid: "95ece3aa-4d37-44ad-98c1-6830d8dd8234",
    iconSet: "MaterialIcons",
    iconName: "6k"
  },
  {
    uuid: "ccc7a01c-264f-4568-9753-9cc03fd01746",
    iconSet: "MaterialIcons",
    iconName: "6k-plus"
  },
  {
    uuid: "f071be8a-fbc4-44aa-9a6c-728724349cce",
    iconSet: "MaterialIcons",
    iconName: "6mp"
  },
  {
    uuid: "b4a7afbe-e0d7-42db-8af6-25bf62e8a291",
    iconSet: "MaterialIcons",
    iconName: "7k"
  },
  {
    uuid: "6c04cbbc-806d-43ff-968c-67e897aa3ec5",
    iconSet: "MaterialIcons",
    iconName: "7k-plus"
  },
  {
    uuid: "7cc092e0-3c73-427e-86ee-79ebeb293f2b",
    iconSet: "MaterialIcons",
    iconName: "7mp"
  },
  {
    uuid: "0c8a5ca3-008a-4693-a291-4e2891195f02",
    iconSet: "MaterialIcons",
    iconName: "8k"
  },
  {
    uuid: "0155060a-5544-4c46-b1de-de967105e5ae",
    iconSet: "MaterialIcons",
    iconName: "8k-plus"
  },
  {
    uuid: "e93538e7-3141-4e8b-a74d-af13c563de7f",
    iconSet: "MaterialIcons",
    iconName: "8mp"
  },
  {
    uuid: "38762974-96ac-43f7-9505-42cc2febd5d3",
    iconSet: "MaterialIcons",
    iconName: "9k"
  },
  {
    uuid: "1c6635d5-ce92-4dc2-8dd3-94d81e529976",
    iconSet: "MaterialIcons",
    iconName: "9k-plus"
  },
  {
    uuid: "85e74eee-0bd9-4749-a843-7130ae25ac2f",
    iconSet: "MaterialIcons",
    iconName: "9mp"
  },
  {
    uuid: "1cdf0020-f7de-4184-88b7-8c7e546808e2",
    iconSet: "MaterialIcons",
    iconName: "ac-unit"
  },
  {
    uuid: "80c078a7-e45b-44ea-919c-4cd73aa3cd7c",
    iconSet: "MaterialIcons",
    iconName: "access-alarm"
  },
  {
    uuid: "53887271-3975-420c-8168-0a099e511673",
    iconSet: "MaterialIcons",
    iconName: "access-alarms"
  },
  {
    uuid: "55f72510-d130-4290-b369-e0c3e002126d",
    iconSet: "MaterialIcons",
    iconName: "access-time"
  },
  {
    uuid: "ac71b919-e59e-4cb6-b910-84df9502c0e9",
    iconSet: "MaterialIcons",
    iconName: "accessibility"
  },
  {
    uuid: "85228b05-421c-4f58-8492-5906c4b48a28",
    iconSet: "MaterialIcons",
    iconName: "accessibility-new"
  },
  {
    uuid: "4e547cc1-c0a4-40d2-aabc-e7c1754a7209",
    iconSet: "MaterialIcons",
    iconName: "accessible"
  },
  {
    uuid: "496a7a6e-4c19-4253-9eb8-49ac82ef9e4a",
    iconSet: "MaterialIcons",
    iconName: "accessible-forward"
  },
  {
    uuid: "29200695-dca1-4316-8192-8d3113473732",
    iconSet: "MaterialIcons",
    iconName: "account-balance"
  },
  {
    uuid: "2a6664b2-518c-4429-9b43-3ecb04d10b0d",
    iconSet: "MaterialIcons",
    iconName: "account-balance-wallet"
  },
  {
    uuid: "ab55e794-2157-43a9-ada4-a7e5228d7c3b",
    iconSet: "MaterialIcons",
    iconName: "account-box"
  },
  {
    uuid: "73975c30-8fa9-437f-a9cd-0340dbd85edd",
    iconSet: "MaterialIcons",
    iconName: "account-circle"
  },
  {
    uuid: "41b96558-6b9f-45ee-b31b-d03d72db5933",
    iconSet: "MaterialIcons",
    iconName: "account-tree"
  },
  {
    uuid: "ca3c6f55-6ad1-4f6e-99af-48baa520e1fd",
    iconSet: "MaterialIcons",
    iconName: "ad-units"
  },
  {
    uuid: "64d40ed5-7a72-4c2d-b022-545de698f1e2",
    iconSet: "MaterialIcons",
    iconName: "adb"
  },
  {
    uuid: "0e54512e-d377-4ad9-8710-8c372220c12f",
    iconSet: "MaterialIcons",
    iconName: "add"
  },
  {
    uuid: "a27036ff-254a-4f45-bbc9-7163ff639b6d",
    iconSet: "MaterialIcons",
    iconName: "add-a-photo"
  },
  {
    uuid: "3050d9e9-e9cc-4813-83a6-7ac8304e828b",
    iconSet: "MaterialIcons",
    iconName: "add-alarm"
  },
  {
    uuid: "b6fb6a7f-27ad-4e40-b829-0c1c63d7adec",
    iconSet: "MaterialIcons",
    iconName: "add-alert"
  },
  {
    uuid: "1c809843-e0c4-403b-a7cc-1c1c7ee82f37",
    iconSet: "MaterialIcons",
    iconName: "add-box"
  },
  {
    uuid: "d94d7202-a21e-4483-961e-4fdff745b983",
    iconSet: "MaterialIcons",
    iconName: "add-business"
  },
  {
    uuid: "efa06669-edf3-41a8-9446-600f9d84583c",
    iconSet: "MaterialIcons",
    iconName: "add-call"
  },
  {
    uuid: "ac726e74-2987-4193-a5f7-f725a82d4ec3",
    iconSet: "MaterialIcons",
    iconName: "add-chart"
  },
  {
    uuid: "1be3a461-da5c-4596-9902-31a554c3b287",
    iconSet: "MaterialIcons",
    iconName: "add-circle"
  },
  {
    uuid: "42d33a58-d298-4ad3-9c55-51e8dfa1927d",
    iconSet: "MaterialIcons",
    iconName: "add-circle-outline"
  },
  {
    uuid: "58f6787e-fd4b-43ec-ab4f-a6a56e1b9b2f",
    iconSet: "MaterialIcons",
    iconName: "add-comment"
  },
  {
    uuid: "1affbc29-3acc-426a-8ebd-5e32f2ed65d3",
    iconSet: "MaterialIcons",
    iconName: "add-ic-call"
  },
  {
    uuid: "ebd1404d-0776-4577-aa66-118147a5fac9",
    iconSet: "MaterialIcons",
    iconName: "add-link"
  },
  {
    uuid: "17a5c7fe-6714-4709-bed3-922fbd8fa62a",
    iconSet: "MaterialIcons",
    iconName: "add-location"
  },
  {
    uuid: "8d942883-346e-485d-9c3e-649f85dd4195",
    iconSet: "MaterialIcons",
    iconName: "add-location-alt"
  },
  {
    uuid: "27a2f549-e358-4586-a753-393bb3b2b07b",
    iconSet: "MaterialIcons",
    iconName: "add-moderator"
  },
  {
    uuid: "7c5f852c-5099-4b17-a6ca-0634eb4f9b44",
    iconSet: "MaterialIcons",
    iconName: "add-photo-alternate"
  },
  {
    uuid: "740033f0-2e53-4e0e-8038-8e519cfd5045",
    iconSet: "MaterialIcons",
    iconName: "add-road"
  },
  {
    uuid: "c6967783-5a69-4524-be8f-3910e68241c9",
    iconSet: "MaterialIcons",
    iconName: "add-shopping-cart"
  },
  {
    uuid: "1896e5e8-e7d8-443c-aeea-b6482c908442",
    iconSet: "MaterialIcons",
    iconName: "add-task"
  },
  {
    uuid: "03418c8c-7fb1-4156-a03e-31a2f6c72ba1",
    iconSet: "MaterialIcons",
    iconName: "add-to-drive"
  },
  {
    uuid: "4ae0f1d4-62e0-44a3-99d0-1bc69517c8f6",
    iconSet: "MaterialIcons",
    iconName: "add-to-home-screen"
  },
  {
    uuid: "5166f388-1fb3-4e24-b0e8-9c14f5682655",
    iconSet: "MaterialIcons",
    iconName: "add-to-photos"
  },
  {
    uuid: "83b7becf-336d-4e2a-be9d-b7f47bda051f",
    iconSet: "MaterialIcons",
    iconName: "add-to-queue"
  },
  {
    uuid: "db2ab594-8a3f-4dd3-9cd3-c3cbb6ac581b",
    iconSet: "MaterialIcons",
    iconName: "addchart"
  },
  {
    uuid: "3af28295-bdf8-40b5-81c9-40a1d069cc94",
    iconSet: "MaterialIcons",
    iconName: "adjust"
  },
  {
    uuid: "e82c6e3f-8126-4151-99ba-31c2b2fdf34a",
    iconSet: "MaterialIcons",
    iconName: "admin-panel-settings"
  },
  {
    uuid: "27c0089d-4a50-4282-95f3-1e69ba00e2e6",
    iconSet: "MaterialIcons",
    iconName: "agriculture"
  },
  {
    uuid: "6d4fb102-2229-4eac-9baf-3c482e0c6a9b",
    iconSet: "MaterialIcons",
    iconName: "airline-seat-flat"
  },
  {
    uuid: "845e70c9-3e72-4c06-a935-9dc17f57a849",
    iconSet: "MaterialIcons",
    iconName: "airline-seat-flat-angled"
  },
  {
    uuid: "008e078f-15bf-4aed-8d92-687d10cc21da",
    iconSet: "MaterialIcons",
    iconName: "airline-seat-individual-suite"
  },
  {
    uuid: "3141d785-4f65-4466-ab33-d96241c9b385",
    iconSet: "MaterialIcons",
    iconName: "airline-seat-legroom-extra"
  },
  {
    uuid: "2f15132e-7a2f-4a5c-92cd-b0e678bbb69c",
    iconSet: "MaterialIcons",
    iconName: "airline-seat-legroom-normal"
  },
  {
    uuid: "299b5260-6b91-4c5b-ab52-1a79b94f1b33",
    iconSet: "MaterialIcons",
    iconName: "airline-seat-legroom-reduced"
  },
  {
    uuid: "d093afcc-a745-48f8-9e66-7ce0627eff00",
    iconSet: "MaterialIcons",
    iconName: "airline-seat-recline-extra"
  },
  {
    uuid: "ad73026d-7b38-4c4d-b347-cc0927d011be",
    iconSet: "MaterialIcons",
    iconName: "airline-seat-recline-normal"
  },
  {
    uuid: "6650b6b9-54d9-49d2-98b7-b375fb81901d",
    iconSet: "MaterialIcons",
    iconName: "airplanemode-active"
  },
  {
    uuid: "f1fb411c-efdf-4750-ae69-562315889020",
    iconSet: "MaterialIcons",
    iconName: "airplanemode-inactive"
  },
  {
    uuid: "0dc23262-167e-484a-9f14-e0b40a11e90a",
    iconSet: "MaterialIcons",
    iconName: "airplanemode-off"
  },
  {
    uuid: "767d047b-5ceb-4a2a-a334-678b2413fb1d",
    iconSet: "MaterialIcons",
    iconName: "airplanemode-on"
  },
  {
    uuid: "9be3e954-9663-4220-a250-1b3ca09b84da",
    iconSet: "MaterialIcons",
    iconName: "airplay"
  },
  {
    uuid: "7d094079-9293-4010-8930-eee690740ca1",
    iconSet: "MaterialIcons",
    iconName: "airport-shuttle"
  },
  {
    uuid: "7b718bc8-aff6-4721-97aa-3824c43c06d1",
    iconSet: "MaterialIcons",
    iconName: "alarm"
  },
  {
    uuid: "122f6886-2881-4cf0-abbd-4a7b485cf5b7",
    iconSet: "MaterialIcons",
    iconName: "alarm-add"
  },
  {
    uuid: "2cbbce88-ded6-495d-b7fe-1c72b1ad777a",
    iconSet: "MaterialIcons",
    iconName: "alarm-off"
  },
  {
    uuid: "572de428-f94e-4f18-af5e-a51e6d3bdab8",
    iconSet: "MaterialIcons",
    iconName: "alarm-on"
  },
  {
    uuid: "30860d0e-dda9-4b70-a31f-dfa46f38538a",
    iconSet: "MaterialIcons",
    iconName: "album"
  },
  {
    uuid: "17903320-dbe7-4b89-bf66-c00bebc5f068",
    iconSet: "MaterialIcons",
    iconName: "align-horizontal-center"
  },
  {
    uuid: "615ff905-3f58-4747-9682-8b418fd8d218",
    iconSet: "MaterialIcons",
    iconName: "align-horizontal-left"
  },
  {
    uuid: "90ec6f84-1390-4520-a19e-ea57b3bcd730",
    iconSet: "MaterialIcons",
    iconName: "align-horizontal-right"
  },
  {
    uuid: "a8b24b8f-8860-4d37-a26e-78fc466b79b1",
    iconSet: "MaterialIcons",
    iconName: "align-vertical-bottom"
  },
  {
    uuid: "f9be0524-f28e-466d-af1e-04cf8c62173d",
    iconSet: "MaterialIcons",
    iconName: "align-vertical-center"
  },
  {
    uuid: "022beee6-48dc-4ec2-a0e1-3d793c0b1617",
    iconSet: "MaterialIcons",
    iconName: "align-vertical-top"
  },
  {
    uuid: "64311eda-ad20-486a-a463-2313cee8c182",
    iconSet: "MaterialIcons",
    iconName: "all-inbox"
  },
  {
    uuid: "031d9314-1ba5-4bd3-b80e-11a83b33e65c",
    iconSet: "MaterialIcons",
    iconName: "all-inclusive"
  },
  {
    uuid: "3ec8a0a8-4a54-4c16-8e86-8d22b974bd5b",
    iconSet: "MaterialIcons",
    iconName: "all-out"
  },
  {
    uuid: "5105d42f-3b57-448f-ba44-7c266e4347cb",
    iconSet: "MaterialIcons",
    iconName: "alt-route"
  },
  {
    uuid: "48222e0d-5b02-4aed-99eb-dc0cf07187a0",
    iconSet: "MaterialIcons",
    iconName: "alternate-email"
  },
  {
    uuid: "c68b9ced-2779-4d29-b413-1d0cdccaf4b7",
    iconSet: "MaterialIcons",
    iconName: "amp-stories"
  },
  {
    uuid: "ce6ec445-4abc-480e-8daf-5ab4741ed1eb",
    iconSet: "MaterialIcons",
    iconName: "analytics"
  },
  {
    uuid: "7c68c578-e21c-4449-a153-49c1fa1cae12",
    iconSet: "MaterialIcons",
    iconName: "anchor"
  },
  {
    uuid: "a439536b-97fa-44f3-8821-3a6c5ce1b82e",
    iconSet: "MaterialIcons",
    iconName: "android"
  },
  {
    uuid: "c62725b1-6451-46ac-b238-7dfa8aedec48",
    iconSet: "MaterialIcons",
    iconName: "animation"
  },
  {
    uuid: "fd7a3534-d63a-4c9e-8a9e-9d56ce4ca39e",
    iconSet: "MaterialIcons",
    iconName: "announcement"
  },
  {
    uuid: "b6a52ee0-2fb0-4239-8230-cdab9a385681",
    iconSet: "MaterialIcons",
    iconName: "apartment"
  },
  {
    uuid: "03cbcbdd-416b-4734-b325-132414e22595",
    iconSet: "MaterialIcons",
    iconName: "api"
  },
  {
    uuid: "e761f836-bdc4-4d17-87ce-a990a8d1cc7b",
    iconSet: "MaterialIcons",
    iconName: "app-blocking"
  },
  {
    uuid: "2602b4b0-9f9e-4fb0-81c4-6a7d7b0046fa",
    iconSet: "MaterialIcons",
    iconName: "app-registration"
  },
  {
    uuid: "ed953404-3e63-4af0-a8d2-f392a1aafda3",
    iconSet: "MaterialIcons",
    iconName: "app-settings-alt"
  },
  {
    uuid: "3c91b122-08f2-4490-a6cb-6d41b2fa7402",
    iconSet: "MaterialIcons",
    iconName: "approval"
  },
  {
    uuid: "cf4b5c36-15f0-4721-a789-7889c1a97615",
    iconSet: "MaterialIcons",
    iconName: "apps"
  },
  {
    uuid: "301f383b-133e-4ed5-8cf2-4295fd26f7e5",
    iconSet: "MaterialIcons",
    iconName: "architecture"
  },
  {
    uuid: "f525ed9d-c328-42eb-9e8f-623e96ed88c5",
    iconSet: "MaterialIcons",
    iconName: "archive"
  },
  {
    uuid: "41df324c-c43d-4ebb-a1f7-0def59cc240a",
    iconSet: "MaterialIcons",
    iconName: "arrow-back"
  },
  {
    uuid: "db84832b-bcd2-4a9c-92cc-7429f1ad3acd",
    iconSet: "MaterialIcons",
    iconName: "arrow-back-ios"
  },
  {
    uuid: "79087765-35b2-40d5-aeec-7af8116d818d",
    iconSet: "MaterialIcons",
    iconName: "arrow-circle-down"
  },
  {
    uuid: "de639d51-acb9-4267-8050-8d9771509cc7",
    iconSet: "MaterialIcons",
    iconName: "arrow-circle-up"
  },
  {
    uuid: "c77f8a18-285b-40c0-95c4-149628ab73a2",
    iconSet: "MaterialIcons",
    iconName: "arrow-downward"
  },
  {
    uuid: "efb2c78e-245e-4cf8-87a1-0f31af198a38",
    iconSet: "MaterialIcons",
    iconName: "arrow-drop-down"
  },
  {
    uuid: "1198c7d8-02ac-416a-9034-ea3c231aca1b",
    iconSet: "MaterialIcons",
    iconName: "arrow-drop-down-circle"
  },
  {
    uuid: "80f9846b-b40e-4538-a37a-04fcb51464a3",
    iconSet: "MaterialIcons",
    iconName: "arrow-drop-up"
  },
  {
    uuid: "81d08c16-3df4-49f1-b866-907e56ce6d61",
    iconSet: "MaterialIcons",
    iconName: "arrow-forward"
  },
  {
    uuid: "20984100-83e3-47f9-b8bb-afb81fbecced",
    iconSet: "MaterialIcons",
    iconName: "arrow-forward-ios"
  },
  {
    uuid: "30f8a9da-fbe0-4d71-9fb1-b8dfd721453c",
    iconSet: "MaterialIcons",
    iconName: "arrow-left"
  },
  {
    uuid: "d00bcd6d-0744-4ec5-8c5c-87f7c6339cf5",
    iconSet: "MaterialIcons",
    iconName: "arrow-right"
  },
  {
    uuid: "544a328d-a267-4ee5-bd7a-8674b399e8e8",
    iconSet: "MaterialIcons",
    iconName: "arrow-right-alt"
  },
  {
    uuid: "e20c4f97-5e25-4980-adfe-e96cdb1fa078",
    iconSet: "MaterialIcons",
    iconName: "arrow-upward"
  },
  {
    uuid: "850b3466-e507-4a3d-aa02-142aab42382e",
    iconSet: "MaterialIcons",
    iconName: "art-track"
  },
  {
    uuid: "531c76f6-81df-4d7d-a652-383817e71b1a",
    iconSet: "MaterialIcons",
    iconName: "article"
  },
  {
    uuid: "b555f98f-898a-49e6-9f28-97b98768206c",
    iconSet: "MaterialIcons",
    iconName: "aspect-ratio"
  },
  {
    uuid: "f220c541-3abf-4125-b9c8-466385bf1c59",
    iconSet: "MaterialIcons",
    iconName: "assessment"
  },
  {
    uuid: "194b8765-c65a-40bd-8c5d-ec0799c7d211",
    iconSet: "MaterialIcons",
    iconName: "assignment"
  },
  {
    uuid: "d956adb6-65dd-4430-83e3-a757b80b3bf4",
    iconSet: "MaterialIcons",
    iconName: "assignment-ind"
  },
  {
    uuid: "5b433540-b565-4743-903d-4b851198a3b1",
    iconSet: "MaterialIcons",
    iconName: "assignment-late"
  },
  {
    uuid: "f310ecbe-c5d3-488f-b13c-d774ad3dc5e6",
    iconSet: "MaterialIcons",
    iconName: "assignment-return"
  },
  {
    uuid: "ff96b05c-a2c0-47f1-8d44-67bedeaf4303",
    iconSet: "MaterialIcons",
    iconName: "assignment-returned"
  },
  {
    uuid: "919dce95-95e9-4de6-84b1-89f65929ea5a",
    iconSet: "MaterialIcons",
    iconName: "assignment-turned-in"
  },
  {
    uuid: "69815ae3-4a7a-4f81-b6f6-b0e97a0b2061",
    iconSet: "MaterialIcons",
    iconName: "assistant"
  },
  {
    uuid: "4794347a-ec0f-4d38-b884-14cb61cf14d7",
    iconSet: "MaterialIcons",
    iconName: "assistant-direction"
  },
  {
    uuid: "eeb3b37e-b264-4654-ac87-6691eb31ba7b",
    iconSet: "MaterialIcons",
    iconName: "assistant-navigation"
  },
  {
    uuid: "cfd587d4-3a1d-437e-8e53-d3f014d00e78",
    iconSet: "MaterialIcons",
    iconName: "assistant-photo"
  },
  {
    uuid: "b0baafc3-e9c2-4fa9-ac27-531f09c35cb0",
    iconSet: "MaterialIcons",
    iconName: "atm"
  },
  {
    uuid: "2a290e1b-d5bb-4692-863d-e194cac73db5",
    iconSet: "MaterialIcons",
    iconName: "attach-email"
  },
  {
    uuid: "8114d415-92d8-4aff-9d99-f488ff69bd29",
    iconSet: "MaterialIcons",
    iconName: "attach-file"
  },
  {
    uuid: "b134512c-96a5-4e54-830c-44dcbb0b2d3d",
    iconSet: "MaterialIcons",
    iconName: "attach-money"
  },
  {
    uuid: "e379bbd1-bda3-418e-89ae-0f29eb691dca",
    iconSet: "MaterialIcons",
    iconName: "attachment"
  },
  {
    uuid: "6d4379c3-0a96-41cf-964b-ae65a072d593",
    iconSet: "MaterialIcons",
    iconName: "attractions"
  },
  {
    uuid: "23cb27a9-9e72-4494-977c-1bb470517b60",
    iconSet: "MaterialIcons",
    iconName: "audiotrack"
  },
  {
    uuid: "40dd7895-44cb-48f1-b7ea-08e290ac0e15",
    iconSet: "MaterialIcons",
    iconName: "auto-awesome"
  },
  {
    uuid: "bc25ad52-ed7a-4029-b073-a753e9e0b830",
    iconSet: "MaterialIcons",
    iconName: "auto-awesome-mosaic"
  },
  {
    uuid: "cb723eeb-4caa-4054-8443-2df0968e832a",
    iconSet: "MaterialIcons",
    iconName: "auto-awesome-motion"
  },
  {
    uuid: "43a887a2-cf41-4191-b8b0-187915f7ccf3",
    iconSet: "MaterialIcons",
    iconName: "auto-delete"
  },
  {
    uuid: "db7ce935-4274-4556-b6a5-2d4f8b6daf5b",
    iconSet: "MaterialIcons",
    iconName: "auto-fix-high"
  },
  {
    uuid: "bf369a72-5962-48de-a6cb-b1ef8b288f8d",
    iconSet: "MaterialIcons",
    iconName: "auto-fix-normal"
  },
  {
    uuid: "0e21d1bc-2b43-4566-82c9-6cb162914876",
    iconSet: "MaterialIcons",
    iconName: "auto-fix-off"
  },
  {
    uuid: "08c0a752-3d0e-425c-814e-c31c08f376a9",
    iconSet: "MaterialIcons",
    iconName: "auto-stories"
  },
  {
    uuid: "509c849f-a432-4717-a8d7-d513ba5b4a8a",
    iconSet: "MaterialIcons",
    iconName: "autorenew"
  },
  {
    uuid: "5a7994db-4853-4821-8c65-48b538a22788",
    iconSet: "MaterialIcons",
    iconName: "av-timer"
  },
  {
    uuid: "81b59a48-9ae9-445c-961e-b65bc0b69019",
    iconSet: "MaterialIcons",
    iconName: "baby-changing-station"
  },
  {
    uuid: "e4bdb7c2-7b64-4680-a42b-adf64bd08f1d",
    iconSet: "MaterialIcons",
    iconName: "backpack"
  },
  {
    uuid: "0701c577-ce82-431a-9112-341cd549040d",
    iconSet: "MaterialIcons",
    iconName: "backspace"
  },
  {
    uuid: "2cf128b9-93d0-479e-9161-9322ec969a92",
    iconSet: "MaterialIcons",
    iconName: "backup"
  },
  {
    uuid: "ac5a92c8-a4ba-4544-b25f-ecce87305159",
    iconSet: "MaterialIcons",
    iconName: "backup-table"
  },
  {
    uuid: "de31b935-fd1a-410a-8524-0cde4cae3c9e",
    iconSet: "MaterialIcons",
    iconName: "badge"
  },
  {
    uuid: "5ef0b7c8-206b-4c70-9168-ba6bc2eb7d6d",
    iconSet: "MaterialIcons",
    iconName: "bakery-dining"
  },
  {
    uuid: "7847ca2e-e380-4b22-844e-277105702a87",
    iconSet: "MaterialIcons",
    iconName: "ballot"
  },
  {
    uuid: "e3d32d0f-5fb6-474d-844b-82f64ee033b1",
    iconSet: "MaterialIcons",
    iconName: "bar-chart"
  },
  {
    uuid: "825967cc-aec4-4b89-bf4a-3fa315f2a8cc",
    iconSet: "MaterialIcons",
    iconName: "batch-prediction"
  },
  {
    uuid: "f3ef8a4b-3cc1-47b3-adaa-211b0ef52908",
    iconSet: "MaterialIcons",
    iconName: "bathtub"
  },
  {
    uuid: "f8ee7052-75a7-45c2-9695-0697f9e4f271",
    iconSet: "MaterialIcons",
    iconName: "battery-alert"
  },
  {
    uuid: "9fe73a70-b4b0-487b-9c5f-c2acb1e7953b",
    iconSet: "MaterialIcons",
    iconName: "battery-charging-full"
  },
  {
    uuid: "7d4bca4c-6cef-4f99-82b1-c8c254739231",
    iconSet: "MaterialIcons",
    iconName: "battery-full"
  },
  {
    uuid: "a5746787-9b65-4075-9454-97ad2aa0b69f",
    iconSet: "MaterialIcons",
    iconName: "battery-std"
  },
  {
    uuid: "9d163f1f-223b-4ab8-86a3-b48d1cab1849",
    iconSet: "MaterialIcons",
    iconName: "battery-unknown"
  },
  {
    uuid: "7ab91ddf-179d-4ca8-88f5-6b07d1e17288",
    iconSet: "MaterialIcons",
    iconName: "beach-access"
  },
  {
    uuid: "76e18254-4fca-4f48-903c-ae10433aab73",
    iconSet: "MaterialIcons",
    iconName: "bedtime"
  },
  {
    uuid: "b794ad2e-a71e-4082-84c9-dd45971fdb93",
    iconSet: "MaterialIcons",
    iconName: "beenhere"
  },
  {
    uuid: "0a9d5ad0-5bd7-4753-a0fd-e66a4fa3a679",
    iconSet: "MaterialIcons",
    iconName: "bento"
  },
  {
    uuid: "3e009fdd-d30e-4852-980b-473e35b7fa82",
    iconSet: "MaterialIcons",
    iconName: "bike-scooter"
  },
  {
    uuid: "82a7f4f3-58fb-4258-be54-902d132a6e73",
    iconSet: "MaterialIcons",
    iconName: "biotech"
  },
  {
    uuid: "c0e57686-1d68-4fc1-aca6-9cbb9051132f",
    iconSet: "MaterialIcons",
    iconName: "block"
  },
  {
    uuid: "ad29cbae-d679-472b-84ff-abb4e06e194f",
    iconSet: "MaterialIcons",
    iconName: "block-flipped"
  },
  {
    uuid: "dce89da4-05e1-483f-b98f-7223a83b5b6e",
    iconSet: "MaterialIcons",
    iconName: "bluetooth"
  },
  {
    uuid: "0fd3c021-de58-4750-938d-c696f603a24e",
    iconSet: "MaterialIcons",
    iconName: "bluetooth-audio"
  },
  {
    uuid: "dfcfff97-46bf-4681-8d6b-5078433ca5f0",
    iconSet: "MaterialIcons",
    iconName: "bluetooth-connected"
  },
  {
    uuid: "2a3370c4-a3f3-4df3-b655-3daa7f19471a",
    iconSet: "MaterialIcons",
    iconName: "bluetooth-disabled"
  },
  {
    uuid: "cb4a21d6-16f6-47d7-92f7-c330c871e70f",
    iconSet: "MaterialIcons",
    iconName: "bluetooth-searching"
  },
  {
    uuid: "058ddf81-ba0c-4e70-961b-8f49c98d9b52",
    iconSet: "MaterialIcons",
    iconName: "blur-circular"
  },
  {
    uuid: "3423f92c-c00e-4f15-bb2c-81744145d258",
    iconSet: "MaterialIcons",
    iconName: "blur-linear"
  },
  {
    uuid: "6f335d93-d4af-4c84-a16b-0fd590eb4e43",
    iconSet: "MaterialIcons",
    iconName: "blur-off"
  },
  {
    uuid: "9062ddff-a4a4-4b92-a7fe-7ee2ba8c2e60",
    iconSet: "MaterialIcons",
    iconName: "blur-on"
  },
  {
    uuid: "8ed19006-b0a3-4610-88b6-7645742cc4d8",
    iconSet: "MaterialIcons",
    iconName: "bolt"
  },
  {
    uuid: "1ad25dbd-31f0-4717-a628-3915bd3ce19d",
    iconSet: "MaterialIcons",
    iconName: "book"
  },
  {
    uuid: "dd54aea5-d36a-4343-b9fe-e3320da42a82",
    iconSet: "MaterialIcons",
    iconName: "book-online"
  },
  {
    uuid: "dcddcf65-6f52-4a7c-ae72-d73509be23ef",
    iconSet: "MaterialIcons",
    iconName: "bookmark"
  },
  {
    uuid: "d812f0fe-b337-4bed-8fe2-9a6633b919dc",
    iconSet: "MaterialIcons",
    iconName: "bookmark-border"
  },
  {
    uuid: "1d76af52-34ea-4b72-806d-a00c341d2ae4",
    iconSet: "MaterialIcons",
    iconName: "bookmark-outline"
  },
  {
    uuid: "0a5b271a-dade-4b1b-ac04-f95d8abbd2cd",
    iconSet: "MaterialIcons",
    iconName: "bookmarks"
  },
  {
    uuid: "60b95a9d-063b-4bec-b5ce-76ff07d47021",
    iconSet: "MaterialIcons",
    iconName: "border-all"
  },
  {
    uuid: "75918622-c56f-41b6-bbd6-6f03d97f81f3",
    iconSet: "MaterialIcons",
    iconName: "border-bottom"
  },
  {
    uuid: "45545d09-87c7-4fe1-9287-5c6e09bc2fdb",
    iconSet: "MaterialIcons",
    iconName: "border-clear"
  },
  {
    uuid: "f084bf49-9997-4419-b0b7-4e7c9dde283f",
    iconSet: "MaterialIcons",
    iconName: "border-color"
  },
  {
    uuid: "3eb5f534-8df7-42b6-b4cd-0843dc896482",
    iconSet: "MaterialIcons",
    iconName: "border-horizontal"
  },
  {
    uuid: "964ea2cf-9050-4e06-99c1-acded9f6064e",
    iconSet: "MaterialIcons",
    iconName: "border-inner"
  },
  {
    uuid: "f7980f28-4697-4408-bc81-250ca3374981",
    iconSet: "MaterialIcons",
    iconName: "border-left"
  },
  {
    uuid: "82b4f29a-440a-4926-9bac-44f887672106",
    iconSet: "MaterialIcons",
    iconName: "border-outer"
  },
  {
    uuid: "f545f378-90a9-4d7b-a69c-ce002694d084",
    iconSet: "MaterialIcons",
    iconName: "border-right"
  },
  {
    uuid: "0eb951aa-5c4e-42b8-9f1b-3abeab86196c",
    iconSet: "MaterialIcons",
    iconName: "border-style"
  },
  {
    uuid: "c22747cb-c37f-46e4-81b8-58552cf9b417",
    iconSet: "MaterialIcons",
    iconName: "border-top"
  },
  {
    uuid: "b7eb99fb-8b7e-4c6b-9a6a-69f5d47c7904",
    iconSet: "MaterialIcons",
    iconName: "border-vertical"
  },
  {
    uuid: "2e06a3b3-3544-4211-85ed-3ff697b8bec8",
    iconSet: "MaterialIcons",
    iconName: "branding-watermark"
  },
  {
    uuid: "61265cfc-6566-46ee-ac23-7bcebd063ca6",
    iconSet: "MaterialIcons",
    iconName: "breakfast-dining"
  },
  {
    uuid: "a00f8510-9d3c-4bc8-a480-e01d3d33e5f1",
    iconSet: "MaterialIcons",
    iconName: "brightness-1"
  },
  {
    uuid: "fadd42c8-dd8e-46de-a284-72830579f980",
    iconSet: "MaterialIcons",
    iconName: "brightness-2"
  },
  {
    uuid: "fcd643f1-2cee-48c3-8944-bf3c31ff9c0d",
    iconSet: "MaterialIcons",
    iconName: "brightness-3"
  },
  {
    uuid: "fc421ac0-4a16-4c7b-8509-8e3f9c4b0482",
    iconSet: "MaterialIcons",
    iconName: "brightness-4"
  },
  {
    uuid: "d591e9d1-b1d3-4682-aca1-e5c0740135fc",
    iconSet: "MaterialIcons",
    iconName: "brightness-5"
  },
  {
    uuid: "88a1e662-5f20-4ec4-b66d-3472b7dfd359",
    iconSet: "MaterialIcons",
    iconName: "brightness-6"
  },
  {
    uuid: "bb2624d9-e3b0-49cc-90dd-447fe31d8d61",
    iconSet: "MaterialIcons",
    iconName: "brightness-7"
  },
  {
    uuid: "c42fb2be-4b81-4943-99ae-8f880ef3bf3a",
    iconSet: "MaterialIcons",
    iconName: "brightness-auto"
  },
  {
    uuid: "6b537fa8-50ec-4748-8f5d-912146199edf",
    iconSet: "MaterialIcons",
    iconName: "brightness-high"
  },
  {
    uuid: "4c8c89b5-97b2-4517-835a-a7845b81c5ce",
    iconSet: "MaterialIcons",
    iconName: "brightness-low"
  },
  {
    uuid: "3e0751d3-8785-4917-8214-aeb2f3018413",
    iconSet: "MaterialIcons",
    iconName: "brightness-medium"
  },
  {
    uuid: "70f1abdc-3c01-4821-ab7d-c87840a7d14e",
    iconSet: "MaterialIcons",
    iconName: "broken-image"
  },
  {
    uuid: "dc5fb69a-382e-4a2d-89fd-d3e0984b5673",
    iconSet: "MaterialIcons",
    iconName: "browser-not-supported"
  },
  {
    uuid: "9d97108d-3101-4722-b906-536c8fac4902",
    iconSet: "MaterialIcons",
    iconName: "brunch-dining"
  },
  {
    uuid: "27983f25-99bd-4574-9ed3-89cc61e88bcf",
    iconSet: "MaterialIcons",
    iconName: "brush"
  },
  {
    uuid: "964c80fe-d9a6-4492-9c46-f64e85f14cbf",
    iconSet: "MaterialIcons",
    iconName: "bubble-chart"
  },
  {
    uuid: "07cb24ac-25de-4c72-a550-d18955611f18",
    iconSet: "MaterialIcons",
    iconName: "bug-report"
  },
  {
    uuid: "edc78ab1-a16c-4394-927d-d30df6c6991c",
    iconSet: "MaterialIcons",
    iconName: "build"
  },
  {
    uuid: "2a5c608a-7f2b-4bc8-bfde-f6efde584e51",
    iconSet: "MaterialIcons",
    iconName: "build-circle"
  },
  {
    uuid: "80929bc1-32ce-4db0-8f5c-2dc08e6d8af1",
    iconSet: "MaterialIcons",
    iconName: "burst-mode"
  },
  {
    uuid: "61994914-b872-4ac3-b56d-3a5a419fa449",
    iconSet: "MaterialIcons",
    iconName: "bus-alert"
  },
  {
    uuid: "5ed73419-b082-44b4-8001-6aed5cf7e11f",
    iconSet: "MaterialIcons",
    iconName: "business"
  },
  {
    uuid: "6807b99f-1611-4b56-a2f9-f18d69e97ad6",
    iconSet: "MaterialIcons",
    iconName: "business-center"
  },
  {
    uuid: "ebad5500-4b52-4f99-b62f-686ab4b1bd67",
    iconSet: "MaterialIcons",
    iconName: "cached"
  },
  {
    uuid: "615bbdd2-a49d-4a43-a22c-a14f03137e05",
    iconSet: "MaterialIcons",
    iconName: "cake"
  },
  {
    uuid: "2bdcd3d5-fa03-42d5-b001-5bca9e45a271",
    iconSet: "MaterialIcons",
    iconName: "calculate"
  },
  {
    uuid: "8dd9dd63-803e-47d5-a5bb-17bc68e94da2",
    iconSet: "MaterialIcons",
    iconName: "calendar-today"
  },
  {
    uuid: "442253b8-dc04-4ec8-8754-c73ba8516d50",
    iconSet: "MaterialIcons",
    iconName: "calendar-view-day"
  },
  {
    uuid: "3b7f1e12-3b17-4b7a-819c-eabd28ae987a",
    iconSet: "MaterialIcons",
    iconName: "call"
  },
  {
    uuid: "17c5b8e4-24bb-4e71-ac9a-b09657c6c2a5",
    iconSet: "MaterialIcons",
    iconName: "call-end"
  },
  {
    uuid: "e51a6ad3-d39e-4d13-919b-2949fb0e46ea",
    iconSet: "MaterialIcons",
    iconName: "call-made"
  },
  {
    uuid: "9a467f93-5368-4683-beef-69328d89648a",
    iconSet: "MaterialIcons",
    iconName: "call-merge"
  },
  {
    uuid: "1489d17c-7b9d-460d-905c-2b56036ea55c",
    iconSet: "MaterialIcons",
    iconName: "call-missed"
  },
  {
    uuid: "e57182e2-6fd9-4c96-ac55-fd5cb4d6ea44",
    iconSet: "MaterialIcons",
    iconName: "call-missed-outgoing"
  },
  {
    uuid: "b9172b5b-fd04-4dc8-ab7a-44fb3e40b137",
    iconSet: "MaterialIcons",
    iconName: "call-received"
  },
  {
    uuid: "4d3a3465-42db-4a2f-a33b-2d4c3992aeaa",
    iconSet: "MaterialIcons",
    iconName: "call-split"
  },
  {
    uuid: "aa1cb768-df3c-4ca6-a110-87c07457ff80",
    iconSet: "MaterialIcons",
    iconName: "call-to-action"
  },
  {
    uuid: "758dfe39-5adf-4af6-b55b-168919cd5d27",
    iconSet: "MaterialIcons",
    iconName: "camera"
  },
  {
    uuid: "ecbb9d77-993b-404f-9c4b-340ffa9bea79",
    iconSet: "MaterialIcons",
    iconName: "camera-alt"
  },
  {
    uuid: "594ef52d-de1d-4272-adb1-0039a77f1644",
    iconSet: "MaterialIcons",
    iconName: "camera-enhance"
  },
  {
    uuid: "b3dbe837-c76e-47bc-8c1e-8bfc32f261bb",
    iconSet: "MaterialIcons",
    iconName: "camera-front"
  },
  {
    uuid: "c9019e7b-5a06-4a69-8203-0e1a29fecbf9",
    iconSet: "MaterialIcons",
    iconName: "camera-rear"
  },
  {
    uuid: "fb4cc6ca-c9b8-4607-bbe4-2613b082114e",
    iconSet: "MaterialIcons",
    iconName: "camera-roll"
  },
  {
    uuid: "d921adf8-5a54-445d-9ab4-3d4ec21c494f",
    iconSet: "MaterialIcons",
    iconName: "campaign"
  },
  {
    uuid: "7923be4d-4371-4854-8690-06fcca95ba45",
    iconSet: "MaterialIcons",
    iconName: "cancel"
  },
  {
    uuid: "44ee2dd2-38bf-4319-bc51-6be3ebcdb974",
    iconSet: "MaterialIcons",
    iconName: "cancel-presentation"
  },
  {
    uuid: "8d568934-4e09-43f6-98eb-d1b98f29306a",
    iconSet: "MaterialIcons",
    iconName: "cancel-schedule-send"
  },
  {
    uuid: "2e284b07-00f5-40c5-8e8c-ae774961060d",
    iconSet: "MaterialIcons",
    iconName: "car-rental"
  },
  {
    uuid: "2212ec54-3c79-45db-8e79-fe3f3958be8e",
    iconSet: "MaterialIcons",
    iconName: "car-repair"
  },
  {
    uuid: "794accbb-d74a-4ec3-9c23-186ca0776e5b",
    iconSet: "MaterialIcons",
    iconName: "card-giftcard"
  },
  {
    uuid: "628cb57d-7938-4bd3-ae38-c4e0f18bfecc",
    iconSet: "MaterialIcons",
    iconName: "card-membership"
  },
  {
    uuid: "ec03eca4-d47d-4d0a-933a-9eb37e943359",
    iconSet: "MaterialIcons",
    iconName: "card-travel"
  },
  {
    uuid: "6173445b-c5eb-4cdd-93c3-98b363be7839",
    iconSet: "MaterialIcons",
    iconName: "carpenter"
  },
  {
    uuid: "e74969ae-98e3-4a4f-99f1-5ce5c9a5f046",
    iconSet: "MaterialIcons",
    iconName: "cases"
  },
  {
    uuid: "52a3f431-9b67-4e6b-b5dc-b7c5dcfb338f",
    iconSet: "MaterialIcons",
    iconName: "casino"
  },
  {
    uuid: "cd89302d-708c-4851-8b75-9f3e23c19931",
    iconSet: "MaterialIcons",
    iconName: "cast"
  },
  {
    uuid: "99629fb3-77f7-4adf-b64a-8cf48ff95b12",
    iconSet: "MaterialIcons",
    iconName: "cast-connected"
  },
  {
    uuid: "33e54c12-6a37-482f-a5ec-4a01c4e7c925",
    iconSet: "MaterialIcons",
    iconName: "cast-for-education"
  },
  {
    uuid: "4a018855-f28d-4f20-a0c5-a8e5b828d090",
    iconSet: "MaterialIcons",
    iconName: "category"
  },
  {
    uuid: "bac7c78a-42ce-4561-9d0c-51ade07e5d62",
    iconSet: "MaterialIcons",
    iconName: "celebration"
  },
  {
    uuid: "45fb577f-66ae-4cc4-bcbe-64555117d04a",
    iconSet: "MaterialIcons",
    iconName: "cell-wifi"
  },
  {
    uuid: "172ba299-8aec-44e0-bfba-bbe33c2f8560",
    iconSet: "MaterialIcons",
    iconName: "center-focus-strong"
  },
  {
    uuid: "d3e73694-0d6d-4c45-9d94-20c871da7f0f",
    iconSet: "MaterialIcons",
    iconName: "center-focus-weak"
  },
  {
    uuid: "9bde8caf-27dd-43ac-98a0-1c06a7c50cb3",
    iconSet: "MaterialIcons",
    iconName: "change-history"
  },
  {
    uuid: "c903d058-7a4b-45b1-9d61-18f180a1e128",
    iconSet: "MaterialIcons",
    iconName: "charging-station"
  },
  {
    uuid: "2f01b0f4-4007-4f9b-ba0d-949ab7ad8c36",
    iconSet: "MaterialIcons",
    iconName: "chat"
  },
  {
    uuid: "758bac69-2135-4d7a-a73c-c040c55e1592",
    iconSet: "MaterialIcons",
    iconName: "chat-bubble"
  },
  {
    uuid: "c1281f08-1deb-4838-bdb6-2250629b83d4",
    iconSet: "MaterialIcons",
    iconName: "chat-bubble-outline"
  },
  {
    uuid: "e041a9de-8e26-4fa0-bcd4-9b190302c91a",
    iconSet: "MaterialIcons",
    iconName: "check"
  },
  {
    uuid: "f856260e-b385-4c65-91ae-ec179497ef33",
    iconSet: "MaterialIcons",
    iconName: "check-box"
  },
  {
    uuid: "4bbb9233-59e6-4b22-90f9-8e6a2f2a585f",
    iconSet: "MaterialIcons",
    iconName: "check-box-outline-blank"
  },
  {
    uuid: "2d80bc4d-9382-478d-99cb-7b9b23d05ebf",
    iconSet: "MaterialIcons",
    iconName: "check-circle"
  },
  {
    uuid: "76d6208f-8d06-4d3b-a6ee-ade529c3b49d",
    iconSet: "MaterialIcons",
    iconName: "check-circle-outline"
  },
  {
    uuid: "5c537f6a-1c41-42f9-8999-83a2de8ff277",
    iconSet: "MaterialIcons",
    iconName: "checkroom"
  },
  {
    uuid: "beecbcd6-9386-4f98-a0d6-782ebcb6090f",
    iconSet: "MaterialIcons",
    iconName: "chevron-left"
  },
  {
    uuid: "9caf8519-fa91-465d-abd1-9b15be7167e3",
    iconSet: "MaterialIcons",
    iconName: "chevron-right"
  },
  {
    uuid: "da5587d6-af28-4bc7-be61-0cc1854d00e7",
    iconSet: "MaterialIcons",
    iconName: "child-care"
  },
  {
    uuid: "4872b655-4577-44cb-9dc3-69ed1e63cdac",
    iconSet: "MaterialIcons",
    iconName: "child-friendly"
  },
  {
    uuid: "1c4d2404-5ec4-4bd0-8c1d-f948f908eee1",
    iconSet: "MaterialIcons",
    iconName: "chrome-reader-mode"
  },
  {
    uuid: "3c39bebc-a50a-472a-9715-eff2a8bc2e1c",
    iconSet: "MaterialIcons",
    iconName: "circle"
  },
  {
    uuid: "dac3bee6-a516-4426-b5d6-bc7814a5bcf9",
    iconSet: "MaterialIcons",
    iconName: "circle-notifications"
  },
  {
    uuid: "bbdfb07f-da68-41e3-8d4a-544418788264",
    iconSet: "MaterialIcons",
    iconName: "class"
  },
  {
    uuid: "5592ed54-ac09-40d1-9196-4f5b20cc8c63",
    iconSet: "MaterialIcons",
    iconName: "clean-hands"
  },
  {
    uuid: "8923abba-de63-4743-9b71-d063277a1667",
    iconSet: "MaterialIcons",
    iconName: "cleaning-services"
  },
  {
    uuid: "10a55bf5-6c98-4442-9ee8-248ec8ef59ac",
    iconSet: "MaterialIcons",
    iconName: "clear"
  },
  {
    uuid: "24542b9c-089c-4dad-9046-043ebb6f2c84",
    iconSet: "MaterialIcons",
    iconName: "clear-all"
  },
  {
    uuid: "4620a00f-956b-4897-a9ba-1078b311c69f",
    iconSet: "MaterialIcons",
    iconName: "close"
  },
  {
    uuid: "a4459c24-efa1-4267-8485-6ef2e9791bdc",
    iconSet: "MaterialIcons",
    iconName: "close-fullscreen"
  },
  {
    uuid: "33fbc1dc-4b49-4f06-b3b2-34a46c52273a",
    iconSet: "MaterialIcons",
    iconName: "closed-caption"
  },
  {
    uuid: "f53408a1-e90b-42d0-8eb5-a17a7243bcd8",
    iconSet: "MaterialIcons",
    iconName: "closed-caption-disabled"
  },
  {
    uuid: "edc9c145-94bb-4afe-84b6-77f6082d5e2d",
    iconSet: "MaterialIcons",
    iconName: "closed-caption-off"
  },
  {
    uuid: "5c206a23-bc6a-40e1-a849-eb6e7f9a50f2",
    iconSet: "MaterialIcons",
    iconName: "cloud"
  },
  {
    uuid: "84d59eac-71be-4b07-bb18-25d3db155b43",
    iconSet: "MaterialIcons",
    iconName: "cloud-circle"
  },
  {
    uuid: "8fa00468-4679-439a-a8f4-be33aa697431",
    iconSet: "MaterialIcons",
    iconName: "cloud-done"
  },
  {
    uuid: "99499a4a-105e-4710-b592-670480981639",
    iconSet: "MaterialIcons",
    iconName: "cloud-download"
  },
  {
    uuid: "9ba9162e-55f6-4d02-895f-40990acaf07e",
    iconSet: "MaterialIcons",
    iconName: "cloud-off"
  },
  {
    uuid: "d12904f5-9909-41f1-a215-28f49b02e0d0",
    iconSet: "MaterialIcons",
    iconName: "cloud-queue"
  },
  {
    uuid: "6168cd8d-f426-4d0a-85a7-39437d0c3bcf",
    iconSet: "MaterialIcons",
    iconName: "cloud-upload"
  },
  {
    uuid: "cef98a38-1e6d-4d36-a8f2-ccd47f9eb300",
    iconSet: "MaterialIcons",
    iconName: "code"
  },
  {
    uuid: "5e0d5681-4123-4fc7-a105-e8fe9490cb8e",
    iconSet: "MaterialIcons",
    iconName: "collections"
  },
  {
    uuid: "bed6b4b7-35e2-4a0e-bf8c-acc076e0d725",
    iconSet: "MaterialIcons",
    iconName: "collections-bookmark"
  },
  {
    uuid: "8e418824-b981-486e-8c81-66b21d327b70",
    iconSet: "MaterialIcons",
    iconName: "color-lens"
  },
  {
    uuid: "c5717cd8-8178-4735-8f4f-205ce03e429b",
    iconSet: "MaterialIcons",
    iconName: "colorize"
  },
  {
    uuid: "f0febfa4-df2e-4623-aeda-61774d827cbd",
    iconSet: "MaterialIcons",
    iconName: "comment"
  },
  {
    uuid: "23f0e9b0-eb8e-49c6-b7f9-b461c6e26671",
    iconSet: "MaterialIcons",
    iconName: "comment-bank"
  },
  {
    uuid: "67b7e43b-d097-48bf-9535-c59590d1f3f9",
    iconSet: "MaterialIcons",
    iconName: "commute"
  },
  {
    uuid: "eafa4f49-7a26-4bb6-846d-94d4fbf04664",
    iconSet: "MaterialIcons",
    iconName: "compare"
  },
  {
    uuid: "95fbc220-6240-40ad-909b-bc23fa893c2a",
    iconSet: "MaterialIcons",
    iconName: "compare-arrows"
  },
  {
    uuid: "dce966da-2738-4960-a69a-0a514bf4e8b3",
    iconSet: "MaterialIcons",
    iconName: "compass-calibration"
  },
  {
    uuid: "c4c12d94-7046-4ac0-b083-8d9183037af5",
    iconSet: "MaterialIcons",
    iconName: "compress"
  },
  {
    uuid: "3f65aaa0-9297-4b25-9b23-e8772ae70d24",
    iconSet: "MaterialIcons",
    iconName: "computer"
  },
  {
    uuid: "fdbcf583-80ee-4e14-927b-c1a04d29fa90",
    iconSet: "MaterialIcons",
    iconName: "confirmation-num"
  },
  {
    uuid: "88ec2a26-89c0-428c-bffd-d2a42fae642f",
    iconSet: "MaterialIcons",
    iconName: "confirmation-number"
  },
  {
    uuid: "01618010-343c-47f8-9f3d-2299246af9b2",
    iconSet: "MaterialIcons",
    iconName: "connect-without-contact"
  },
  {
    uuid: "97092c33-8b23-4c67-b788-c11ec5fac9f9",
    iconSet: "MaterialIcons",
    iconName: "connected-tv"
  },
  {
    uuid: "1c667ee1-66ef-44f0-b570-dfa944201bf3",
    iconSet: "MaterialIcons",
    iconName: "construction"
  },
  {
    uuid: "17d93aa8-18c5-44d8-8ed3-ca6dbef10c07",
    iconSet: "MaterialIcons",
    iconName: "contact-mail"
  },
  {
    uuid: "ddf25c2e-f374-472b-bd0f-570c8cfa70b1",
    iconSet: "MaterialIcons",
    iconName: "contact-page"
  },
  {
    uuid: "f9258254-9789-4b03-b8a3-d97314c30e37",
    iconSet: "MaterialIcons",
    iconName: "contact-phone"
  },
  {
    uuid: "bb2a179d-9d6f-4867-af2e-3e94d5dc2353",
    iconSet: "MaterialIcons",
    iconName: "contact-support"
  },
  {
    uuid: "785f5872-cff5-4a61-b14f-9998ffc4f49a",
    iconSet: "MaterialIcons",
    iconName: "contactless"
  },
  {
    uuid: "781f6a10-6403-4913-b702-8252aa8a7f12",
    iconSet: "MaterialIcons",
    iconName: "contacts"
  },
  {
    uuid: "5708eed3-cc7b-4e2d-bf5d-15d60e007272",
    iconSet: "MaterialIcons",
    iconName: "content-copy"
  },
  {
    uuid: "3e011617-7e62-497e-ac9d-2274abd89e34",
    iconSet: "MaterialIcons",
    iconName: "content-cut"
  },
  {
    uuid: "04735316-c6f4-4355-be31-9b1d5493b619",
    iconSet: "MaterialIcons",
    iconName: "content-paste"
  },
  {
    uuid: "7f76f55d-7333-4303-b32d-457fc6c21497",
    iconSet: "MaterialIcons",
    iconName: "control-camera"
  },
  {
    uuid: "1b810cf2-2088-4142-95a8-9492d7403481",
    iconSet: "MaterialIcons",
    iconName: "control-point"
  },
  {
    uuid: "ceac65bc-fb8c-45a7-933f-fd57f6fa5d3e",
    iconSet: "MaterialIcons",
    iconName: "control-point-duplicate"
  },
  {
    uuid: "a4eb48fd-8cba-4201-b65e-5dd5e195e78d",
    iconSet: "MaterialIcons",
    iconName: "copyright"
  },
  {
    uuid: "82e1f699-62dc-4f01-865f-4bab3c125e29",
    iconSet: "MaterialIcons",
    iconName: "coronavirus"
  },
  {
    uuid: "69824aff-64c4-47ad-b8ab-42539231eca5",
    iconSet: "MaterialIcons",
    iconName: "corporate-fare"
  },
  {
    uuid: "ac035084-4509-4775-9756-cb2f4a93ae7f",
    iconSet: "MaterialIcons",
    iconName: "countertops"
  },
  {
    uuid: "87728d0a-3dd9-4c7f-8274-f82fb3ce4873",
    iconSet: "MaterialIcons",
    iconName: "create"
  },
  {
    uuid: "6fa63530-22cb-4a8c-82e3-5efa5825e06c",
    iconSet: "MaterialIcons",
    iconName: "create-new-folder"
  },
  {
    uuid: "672e0317-b5b6-40ba-8891-91fe70073ef5",
    iconSet: "MaterialIcons",
    iconName: "credit-card"
  },
  {
    uuid: "758f2147-a5b2-4503-b6ae-339a1995c971",
    iconSet: "MaterialIcons",
    iconName: "crop"
  },
  {
    uuid: "43a294c4-f7db-45d9-987c-e72fbb42dab7",
    iconSet: "MaterialIcons",
    iconName: "crop-16-9"
  },
  {
    uuid: "7e670ec1-9c58-45d7-b5e1-fe317141ad95",
    iconSet: "MaterialIcons",
    iconName: "crop-3-2"
  },
  {
    uuid: "79de893a-41d0-4403-879e-c0ff31de727d",
    iconSet: "MaterialIcons",
    iconName: "crop-5-4"
  },
  {
    uuid: "929c1a71-bc5b-41dc-a175-4af0207bd910",
    iconSet: "MaterialIcons",
    iconName: "crop-7-5"
  },
  {
    uuid: "61b3243d-6519-4d50-8e53-a9cde18f1344",
    iconSet: "MaterialIcons",
    iconName: "crop-din"
  },
  {
    uuid: "575a69a6-f774-4ca3-8b75-f6bf6b33aafd",
    iconSet: "MaterialIcons",
    iconName: "crop-free"
  },
  {
    uuid: "266686a1-0473-4be9-864e-3b6caebb00c9",
    iconSet: "MaterialIcons",
    iconName: "crop-landscape"
  },
  {
    uuid: "01286081-d206-4cf8-a71f-bf4296daed44",
    iconSet: "MaterialIcons",
    iconName: "crop-original"
  },
  {
    uuid: "eadecfbe-e252-4c9c-bbcb-046b6d085cec",
    iconSet: "MaterialIcons",
    iconName: "crop-portrait"
  },
  {
    uuid: "a4f6cbd3-b398-409a-83f8-7117ac6df502",
    iconSet: "MaterialIcons",
    iconName: "crop-rotate"
  },
  {
    uuid: "fc8fa3d6-2dc8-4ba0-b382-b30a04d2ab52",
    iconSet: "MaterialIcons",
    iconName: "crop-square"
  },
  {
    uuid: "115f6772-073e-4a99-9adf-4203fa021833",
    iconSet: "MaterialIcons",
    iconName: "dangerous"
  },
  {
    uuid: "321ed68a-ee33-4340-8c95-4ccaa14ad353",
    iconSet: "MaterialIcons",
    iconName: "dashboard"
  },
  {
    uuid: "02aac85f-cc93-4978-a1fe-5e1068f50d6c",
    iconSet: "MaterialIcons",
    iconName: "dashboard-customize"
  },
  {
    uuid: "c440666b-cba7-4334-889f-e03e994acad7",
    iconSet: "MaterialIcons",
    iconName: "data-usage"
  },
  {
    uuid: "0eb7dce3-92bf-4f90-83ae-23e297f31f85",
    iconSet: "MaterialIcons",
    iconName: "date-range"
  },
  {
    uuid: "bb7316ed-0846-4c87-b063-6de4d78f3388",
    iconSet: "MaterialIcons",
    iconName: "deck"
  },
  {
    uuid: "0d8eaba5-a91b-42a6-a460-4d3363ca8ba5",
    iconSet: "MaterialIcons",
    iconName: "dehaze"
  },
  {
    uuid: "f8872fbc-3d7c-4a61-856d-1e16498a175a",
    iconSet: "MaterialIcons",
    iconName: "delete"
  },
  {
    uuid: "fb4793fa-cdd2-4577-b9a5-35a47c9b4760",
    iconSet: "MaterialIcons",
    iconName: "delete-forever"
  },
  {
    uuid: "c7cd82aa-4853-416b-b4b0-eb8b77f18660",
    iconSet: "MaterialIcons",
    iconName: "delete-outline"
  },
  {
    uuid: "362cf727-3289-432b-ac54-8010c4b83c5f",
    iconSet: "MaterialIcons",
    iconName: "delete-sweep"
  },
  {
    uuid: "06a1c675-a756-4877-ba3c-f384e065157f",
    iconSet: "MaterialIcons",
    iconName: "delivery-dining"
  },
  {
    uuid: "45c54318-847c-41ee-9143-f8c66f16bfbc",
    iconSet: "MaterialIcons",
    iconName: "departure-board"
  },
  {
    uuid: "1a7683dc-5464-4717-b7c4-acf20c078f4c",
    iconSet: "MaterialIcons",
    iconName: "description"
  },
  {
    uuid: "3adfa90f-a816-49d7-928a-64e6a1eed78a",
    iconSet: "MaterialIcons",
    iconName: "design-services"
  },
  {
    uuid: "a1ada69c-3a65-47f2-8208-5b121d5c7c22",
    iconSet: "MaterialIcons",
    iconName: "desktop-access-disabled"
  },
  {
    uuid: "ffd75bd3-96cc-4fae-999d-83bb57fcf8f6",
    iconSet: "MaterialIcons",
    iconName: "desktop-mac"
  },
  {
    uuid: "b8ce994f-2caa-49fa-a799-ebb506b3224d",
    iconSet: "MaterialIcons",
    iconName: "desktop-windows"
  },
  {
    uuid: "93b6bc21-1063-4282-a55a-4e43a83db678",
    iconSet: "MaterialIcons",
    iconName: "details"
  },
  {
    uuid: "1fc0718e-db6b-47b3-ba32-f5bb5f8189e2",
    iconSet: "MaterialIcons",
    iconName: "developer-board"
  },
  {
    uuid: "390beaea-430b-4d71-80ff-029661b419f2",
    iconSet: "MaterialIcons",
    iconName: "developer-mode"
  },
  {
    uuid: "1fc4bf5e-a075-40a5-bfb6-c1c416b705ce",
    iconSet: "MaterialIcons",
    iconName: "device-hub"
  },
  {
    uuid: "4b365e8e-b74c-4ce9-8406-9e41cf8f613d",
    iconSet: "MaterialIcons",
    iconName: "device-thermostat"
  },
  {
    uuid: "34984814-b6ae-4463-a61c-275884022bba",
    iconSet: "MaterialIcons",
    iconName: "device-unknown"
  },
  {
    uuid: "87a6d3d2-8fbf-450b-aa50-7bdc2300cd1c",
    iconSet: "MaterialIcons",
    iconName: "devices"
  },
  {
    uuid: "a10b93b3-e2a4-4b7f-93fb-94edff95819f",
    iconSet: "MaterialIcons",
    iconName: "devices-other"
  },
  {
    uuid: "9239dc7e-c51f-4728-8505-9417f34915c9",
    iconSet: "MaterialIcons",
    iconName: "dialer-sip"
  },
  {
    uuid: "63446484-6182-41d6-8642-25f730c1c5ec",
    iconSet: "MaterialIcons",
    iconName: "dialpad"
  },
  {
    uuid: "851ebe28-9acc-4eb0-b031-73e739ae221d",
    iconSet: "MaterialIcons",
    iconName: "dinner-dining"
  },
  {
    uuid: "396ac3c8-d0cf-44aa-97f9-8f2e27302cae",
    iconSet: "MaterialIcons",
    iconName: "directions"
  },
  {
    uuid: "532c0664-08c1-4930-85d0-dae16007a3eb",
    iconSet: "MaterialIcons",
    iconName: "directions-bike"
  },
  {
    uuid: "f7d0a8cf-1e4e-4400-b6c0-e79b358231db",
    iconSet: "MaterialIcons",
    iconName: "directions-boat"
  },
  {
    uuid: "b51b7360-facd-4220-af9c-fd458f9b17f5",
    iconSet: "MaterialIcons",
    iconName: "directions-bus"
  },
  {
    uuid: "a9a5c0b0-42fd-48fb-bebf-89cf51871250",
    iconSet: "MaterialIcons",
    iconName: "directions-car"
  },
  {
    uuid: "16288123-ca95-4020-8f7e-a734485293f7",
    iconSet: "MaterialIcons",
    iconName: "directions-ferry"
  },
  {
    uuid: "c449d8e6-d62e-4b66-a0bf-a89adbd42c08",
    iconSet: "MaterialIcons",
    iconName: "directions-off"
  },
  {
    uuid: "e4392c9f-a376-428a-949c-5b62b0601eed",
    iconSet: "MaterialIcons",
    iconName: "directions-railway"
  },
  {
    uuid: "403ec0ee-8b45-4a2f-b0e8-4a3dc94ce949",
    iconSet: "MaterialIcons",
    iconName: "directions-run"
  },
  {
    uuid: "dccb82ed-dbf1-4ac7-b901-7c5de819588b",
    iconSet: "MaterialIcons",
    iconName: "directions-subway"
  },
  {
    uuid: "08ffa115-748a-49ee-90cb-574cdf4e3ed0",
    iconSet: "MaterialIcons",
    iconName: "directions-train"
  },
  {
    uuid: "3783b663-69c9-42e6-9ff3-a0ce2a8e84c5",
    iconSet: "MaterialIcons",
    iconName: "directions-transit"
  },
  {
    uuid: "1789897a-602a-47f0-9faa-dba4c8b1b4fa",
    iconSet: "MaterialIcons",
    iconName: "directions-walk"
  },
  {
    uuid: "710f139d-ebf0-4ea4-bbcc-1a3a6e487013",
    iconSet: "MaterialIcons",
    iconName: "dirty-lens"
  },
  {
    uuid: "faf3b29e-a1e9-4597-bdf3-3eae00a96fce",
    iconSet: "MaterialIcons",
    iconName: "disabled-by-default"
  },
  {
    uuid: "7763a315-2b16-441e-955f-86af225c595b",
    iconSet: "MaterialIcons",
    iconName: "disc-full"
  },
  {
    uuid: "34f78660-5772-4758-8859-6195096f020b",
    iconSet: "MaterialIcons",
    iconName: "dnd-forwardslash"
  },
  {
    uuid: "0ae49974-f323-4362-831d-af102e010b65",
    iconSet: "MaterialIcons",
    iconName: "dns"
  },
  {
    uuid: "2d853ebb-ee4e-4139-a3cd-cff381c7f6a1",
    iconSet: "MaterialIcons",
    iconName: "do-not-disturb"
  },
  {
    uuid: "240985ed-db34-411b-a5e9-f66915c7370e",
    iconSet: "MaterialIcons",
    iconName: "do-not-disturb-alt"
  },
  {
    uuid: "bfb2a3b1-255a-4218-babd-4371610ea887",
    iconSet: "MaterialIcons",
    iconName: "do-not-disturb-off"
  },
  {
    uuid: "3fe008a3-edea-498c-9505-e47b8a802b65",
    iconSet: "MaterialIcons",
    iconName: "do-not-disturb-on"
  },
  {
    uuid: "3ea415b4-de42-4b5c-83b2-e654c1ec342e",
    iconSet: "MaterialIcons",
    iconName: "do-not-step"
  },
  {
    uuid: "2c7ab938-f473-4a28-ad1c-c862056fdad3",
    iconSet: "MaterialIcons",
    iconName: "do-not-touch"
  },
  {
    uuid: "68aea829-6e4c-4d3c-b517-6e6ff85cb08f",
    iconSet: "MaterialIcons",
    iconName: "dock"
  },
  {
    uuid: "60b752f9-5bc4-45a3-b912-a5e3662ab505",
    iconSet: "MaterialIcons",
    iconName: "domain"
  },
  {
    uuid: "3215b100-79c6-41bf-baa3-fb87b4bcf875",
    iconSet: "MaterialIcons",
    iconName: "domain-disabled"
  },
  {
    uuid: "bbb94fff-b452-49c0-bc39-82de76242943",
    iconSet: "MaterialIcons",
    iconName: "domain-verification"
  },
  {
    uuid: "2dff5cfd-7f96-410a-8781-cdc447f93739",
    iconSet: "MaterialIcons",
    iconName: "done"
  },
  {
    uuid: "274f3ce1-afe4-478f-a1c8-e8425896d848",
    iconSet: "MaterialIcons",
    iconName: "done-all"
  },
  {
    uuid: "9562ac04-0897-4674-a603-50ecf6017983",
    iconSet: "MaterialIcons",
    iconName: "done-outline"
  },
  {
    uuid: "e1961ca9-bf28-46a0-87b8-955285a80769",
    iconSet: "MaterialIcons",
    iconName: "donut-large"
  },
  {
    uuid: "0e45571a-bd26-433a-9bb3-f10cbea81d7e",
    iconSet: "MaterialIcons",
    iconName: "donut-small"
  },
  {
    uuid: "c92843c0-bf7c-4e71-8226-b8d611ff2d37",
    iconSet: "MaterialIcons",
    iconName: "double-arrow"
  },
  {
    uuid: "df85d9f5-20e9-48c2-b023-e038402e0b36",
    iconSet: "MaterialIcons",
    iconName: "drafts"
  },
  {
    uuid: "d2eb7e0c-dd16-40a5-a60b-79537bc195ad",
    iconSet: "MaterialIcons",
    iconName: "drag-handle"
  },
  {
    uuid: "61e41de6-0ac9-452b-aedd-2ece8b93c4d4",
    iconSet: "MaterialIcons",
    iconName: "drag-indicator"
  },
  {
    uuid: "e1767647-2d6b-4e87-9c9b-f2e8f3a0bafd",
    iconSet: "MaterialIcons",
    iconName: "drive-eta"
  },
  {
    uuid: "7eb4363d-70a4-4d45-a576-c4e157e12773",
    iconSet: "MaterialIcons",
    iconName: "drive-file-move"
  },
  {
    uuid: "b1f7019a-c6e3-4a86-91a7-430734bb5f52",
    iconSet: "MaterialIcons",
    iconName: "drive-file-move-outline"
  },
  {
    uuid: "6682f508-e72d-47d0-b696-0e641a623ac7",
    iconSet: "MaterialIcons",
    iconName: "drive-file-rename-outline"
  },
  {
    uuid: "9f561e89-bbb2-4175-bd2b-509f52e6243c",
    iconSet: "MaterialIcons",
    iconName: "drive-folder-upload"
  },
  {
    uuid: "66253365-2b6a-49f3-8add-6ecebd64f365",
    iconSet: "MaterialIcons",
    iconName: "dry"
  },
  {
    uuid: "0de2051b-38d3-4548-a1ef-ce305a6484f7",
    iconSet: "MaterialIcons",
    iconName: "dry-cleaning"
  },
  {
    uuid: "96dc7234-c76f-4f6e-92bf-6aae4c3982cf",
    iconSet: "MaterialIcons",
    iconName: "duo"
  },
  {
    uuid: "04a1c420-e64e-493e-8c6c-d47169e36211",
    iconSet: "MaterialIcons",
    iconName: "dvr"
  },
  {
    uuid: "4e7c4f92-ba44-4d3f-ad11-085b8156b87c",
    iconSet: "MaterialIcons",
    iconName: "dynamic-feed"
  },
  {
    uuid: "ee99a19a-1618-46a6-a7a1-0b8af69f2aca",
    iconSet: "MaterialIcons",
    iconName: "dynamic-form"
  },
  {
    uuid: "30ca8714-7848-48f1-91c9-b35e4fe4bb6f",
    iconSet: "MaterialIcons",
    iconName: "east"
  },
  {
    uuid: "d3056aca-44b9-47e3-adc2-0dbcb4bab760",
    iconSet: "MaterialIcons",
    iconName: "eco"
  },
  {
    uuid: "d2b21926-3f36-4f31-a8d4-20e23da39357",
    iconSet: "MaterialIcons",
    iconName: "edit"
  },
  {
    uuid: "a6615a4c-b3a0-4c4e-bd8c-c08aedaa5611",
    iconSet: "MaterialIcons",
    iconName: "edit-attributes"
  },
  {
    uuid: "b71a098e-17f0-4a42-a1a1-37244ea42a68",
    iconSet: "MaterialIcons",
    iconName: "edit-location"
  },
  {
    uuid: "e28df5eb-f8e7-4270-9da5-145ca7f102b2",
    iconSet: "MaterialIcons",
    iconName: "edit-off"
  },
  {
    uuid: "63b7eb56-db2b-4e7d-9843-3536f667d2e0",
    iconSet: "MaterialIcons",
    iconName: "edit-road"
  },
  {
    uuid: "cb67cde4-4cd2-46a9-8f5f-753e3548a03d",
    iconSet: "MaterialIcons",
    iconName: "eject"
  },
  {
    uuid: "a8219753-4ea7-4aa1-8376-fb807daca9a2",
    iconSet: "MaterialIcons",
    iconName: "elderly"
  },
  {
    uuid: "fac5e843-d736-4889-b46a-c7627622422d",
    iconSet: "MaterialIcons",
    iconName: "electric-bike"
  },
  {
    uuid: "eb55f65d-5000-4c6f-8a8b-b899f670c284",
    iconSet: "MaterialIcons",
    iconName: "electric-car"
  },
  {
    uuid: "c48cd0b3-cf24-4c92-91d6-425014238030",
    iconSet: "MaterialIcons",
    iconName: "electric-moped"
  },
  {
    uuid: "ef45b5f4-e50f-4bd7-b1fe-05cc16feecc7",
    iconSet: "MaterialIcons",
    iconName: "electric-rickshaw"
  },
  {
    uuid: "92aec458-9947-48c8-9db2-e7ed72de3640",
    iconSet: "MaterialIcons",
    iconName: "electric-scooter"
  },
  {
    uuid: "c40dea9c-6976-4dcf-bd69-ac5a831298a8",
    iconSet: "MaterialIcons",
    iconName: "electrical-services"
  },
  {
    uuid: "9d3f8701-91a2-4e7d-9afa-39a61ca53d1a",
    iconSet: "MaterialIcons",
    iconName: "elevator"
  },
  {
    uuid: "e602a450-9457-4207-8d7f-5df58a3746ee",
    iconSet: "MaterialIcons",
    iconName: "email"
  },
  {
    uuid: "744268ed-a990-466f-b9c9-8e1ed3877df6",
    iconSet: "MaterialIcons",
    iconName: "emoji-emotions"
  },
  {
    uuid: "7861de37-3fd1-4685-925b-e8a7ea53ee9f",
    iconSet: "MaterialIcons",
    iconName: "emoji-events"
  },
  {
    uuid: "6ee51fb4-2063-4fd4-a1e7-eb5ef9165209",
    iconSet: "MaterialIcons",
    iconName: "emoji-flags"
  },
  {
    uuid: "954370c1-9cc1-45fa-916f-a101694809c8",
    iconSet: "MaterialIcons",
    iconName: "emoji-food-beverage"
  },
  {
    uuid: "9c05c19b-7aaa-4164-b881-fed81cd7b4e4",
    iconSet: "MaterialIcons",
    iconName: "emoji-nature"
  },
  {
    uuid: "ab664f20-1105-444d-bcc1-91d2c193d07a",
    iconSet: "MaterialIcons",
    iconName: "emoji-objects"
  },
  {
    uuid: "21f931df-1fbc-4dd9-9142-0984563de814",
    iconSet: "MaterialIcons",
    iconName: "emoji-people"
  },
  {
    uuid: "2cd19d3a-fff4-4e39-abfd-ed9438ef8812",
    iconSet: "MaterialIcons",
    iconName: "emoji-symbols"
  },
  {
    uuid: "0cc21774-8e11-4801-a6ec-fcea95a5b0dc",
    iconSet: "MaterialIcons",
    iconName: "emoji-transportation"
  },
  {
    uuid: "caa6399d-41d8-458f-8607-e60814f86c5d",
    iconSet: "MaterialIcons",
    iconName: "engineering"
  },
  {
    uuid: "4bbd5e3d-12e5-436c-86fe-c923c9133185",
    iconSet: "MaterialIcons",
    iconName: "enhance-photo-translate"
  },
  {
    uuid: "d25b5bb1-a4a7-49a0-b984-078e5496c46a",
    iconSet: "MaterialIcons",
    iconName: "enhanced-encryption"
  },
  {
    uuid: "1d670d99-bf71-436b-9e03-57969987e99e",
    iconSet: "MaterialIcons",
    iconName: "equalizer"
  },
  {
    uuid: "01778317-19c0-4416-b93a-2c2a88e8773d",
    iconSet: "MaterialIcons",
    iconName: "error"
  },
  {
    uuid: "4f1e386e-3a76-4890-80ab-d0602b79d399",
    iconSet: "MaterialIcons",
    iconName: "error-outline"
  },
  {
    uuid: "a6cecc80-488b-46ea-9a40-b7f60b9b7cdf",
    iconSet: "MaterialIcons",
    iconName: "escalator"
  },
  {
    uuid: "36ad0838-d400-4787-8b9b-bff7f1b9efb8",
    iconSet: "MaterialIcons",
    iconName: "escalator-warning"
  },
  {
    uuid: "c6cf75b3-6e0b-4741-94af-d76fdfc0ef28",
    iconSet: "MaterialIcons",
    iconName: "euro"
  },
  {
    uuid: "3065f987-3ff7-4c41-9eb3-8240fbf198ed",
    iconSet: "MaterialIcons",
    iconName: "euro-symbol"
  },
  {
    uuid: "256cb929-881b-4901-b076-b1c757617724",
    iconSet: "MaterialIcons",
    iconName: "ev-station"
  },
  {
    uuid: "ea43bf40-4167-48bc-ae58-ca9b3cd5d12f",
    iconSet: "MaterialIcons",
    iconName: "event"
  },
  {
    uuid: "d5db2b1d-fb87-47cd-932b-a2f4219d114f",
    iconSet: "MaterialIcons",
    iconName: "event-available"
  },
  {
    uuid: "16ea01a9-4c92-4191-888b-2ca6e0696fb7",
    iconSet: "MaterialIcons",
    iconName: "event-busy"
  },
  {
    uuid: "45086402-0a70-4258-b511-bcf8cfea0f6f",
    iconSet: "MaterialIcons",
    iconName: "event-note"
  },
  {
    uuid: "07efa267-b486-4567-ace4-d0ef9b9638aa",
    iconSet: "MaterialIcons",
    iconName: "event-seat"
  },
  {
    uuid: "0d155313-0ffe-45f7-86ca-5571b832c8ad",
    iconSet: "MaterialIcons",
    iconName: "exit-to-app"
  },
  {
    uuid: "b6fa6b98-f216-4827-99fa-acfa595c9c2a",
    iconSet: "MaterialIcons",
    iconName: "expand"
  },
  {
    uuid: "06f38637-f344-4044-82ea-ce71a35d48f0",
    iconSet: "MaterialIcons",
    iconName: "expand-less"
  },
  {
    uuid: "6ef8e9a0-54dc-4398-bc21-be624874656c",
    iconSet: "MaterialIcons",
    iconName: "expand-more"
  },
  {
    uuid: "54c50a84-b097-4af0-9940-7b0cc1a24c25",
    iconSet: "MaterialIcons",
    iconName: "explicit"
  },
  {
    uuid: "50735d4b-d790-40f0-bc25-2cf99a29cc19",
    iconSet: "MaterialIcons",
    iconName: "explore"
  },
  {
    uuid: "b08d0073-5031-48a1-99b2-2313b687c852",
    iconSet: "MaterialIcons",
    iconName: "explore-off"
  },
  {
    uuid: "d676509a-5033-490b-b354-cc16a75d1240",
    iconSet: "MaterialIcons",
    iconName: "exposure"
  },
  {
    uuid: "577dc107-230f-4a52-a5fe-2660115069c2",
    iconSet: "MaterialIcons",
    iconName: "exposure-minus-1"
  },
  {
    uuid: "f6d2940a-97ca-440e-893b-19bbc76a7b42",
    iconSet: "MaterialIcons",
    iconName: "exposure-minus-2"
  },
  {
    uuid: "ee2a1480-0436-4c6a-afd8-f8d529b0762f",
    iconSet: "MaterialIcons",
    iconName: "exposure-neg-1"
  },
  {
    uuid: "0fbe138c-8a1b-4e75-bc0b-5db1c71d4cc3",
    iconSet: "MaterialIcons",
    iconName: "exposure-neg-2"
  },
  {
    uuid: "06d77a44-688f-4d63-af0e-43e2fcb96e64",
    iconSet: "MaterialIcons",
    iconName: "exposure-plus-1"
  },
  {
    uuid: "995052d9-ee24-472d-9de0-59f5b6d54486",
    iconSet: "MaterialIcons",
    iconName: "exposure-plus-2"
  },
  {
    uuid: "e947443e-6155-4f24-a258-38cc133a60ee",
    iconSet: "MaterialIcons",
    iconName: "exposure-zero"
  },
  {
    uuid: "f9f3480c-50d7-418f-997f-50457e928fe3",
    iconSet: "MaterialIcons",
    iconName: "extension"
  },
  {
    uuid: "ab73b836-6178-4b21-845d-83d41b1592e5",
    iconSet: "MaterialIcons",
    iconName: "face"
  },
  {
    uuid: "2866ac0c-26e9-41ae-866b-871ab64c8239",
    iconSet: "MaterialIcons",
    iconName: "face-retouching-natural"
  },
  {
    uuid: "ecbdfcd6-e6a9-45a4-9ce2-d5573ea70d72",
    iconSet: "MaterialIcons",
    iconName: "facebook"
  },
  {
    uuid: "9439c63d-b126-4cef-92d7-b6dbf2dcba32",
    iconSet: "MaterialIcons",
    iconName: "fact-check"
  },
  {
    uuid: "d49432c7-ebae-4792-8340-884e5e8228a5",
    iconSet: "MaterialIcons",
    iconName: "family-restroom"
  },
  {
    uuid: "58a99050-7b55-413b-a5a2-7385576b9407",
    iconSet: "MaterialIcons",
    iconName: "fast-forward"
  },
  {
    uuid: "776030a8-c4a6-489e-8147-2484967a7b3e",
    iconSet: "MaterialIcons",
    iconName: "fast-rewind"
  },
  {
    uuid: "d92a3dc4-b07d-421a-892c-18c48181537a",
    iconSet: "MaterialIcons",
    iconName: "fastfood"
  },
  {
    uuid: "0b7add48-7dc1-400f-b336-7723d3559543",
    iconSet: "MaterialIcons",
    iconName: "favorite"
  },
  {
    uuid: "e8ad9bc1-1a5a-48d1-8646-fd6dd2f354f6",
    iconSet: "MaterialIcons",
    iconName: "favorite-border"
  },
  {
    uuid: "c2f418a6-95bb-4589-8b6c-3acb4b36b4bf",
    iconSet: "MaterialIcons",
    iconName: "favorite-outline"
  },
  {
    uuid: "b0a7e8b0-0196-4c7c-8d8a-e688a5cf7a4a",
    iconSet: "MaterialIcons",
    iconName: "featured-play-list"
  },
  {
    uuid: "a275a73f-06f6-4e55-ae97-779d40d757fb",
    iconSet: "MaterialIcons",
    iconName: "featured-video"
  },
  {
    uuid: "b96d0971-7802-47db-bf7d-8b9a3bb113b3",
    iconSet: "MaterialIcons",
    iconName: "feedback"
  },
  {
    uuid: "7b74ae81-7ff5-413b-948f-9c113e2a37e4",
    iconSet: "MaterialIcons",
    iconName: "fence"
  },
  {
    uuid: "3a2d4cb3-65b2-4fca-b2ac-b218f6ec13a3",
    iconSet: "MaterialIcons",
    iconName: "festival"
  },
  {
    uuid: "f27a2e37-6abc-4d6e-b0a3-a8d46390bbcd",
    iconSet: "MaterialIcons",
    iconName: "fiber-dvr"
  },
  {
    uuid: "6705e651-fca6-4e51-a6a6-b0bda30820a9",
    iconSet: "MaterialIcons",
    iconName: "fiber-manual-record"
  },
  {
    uuid: "1dcb4e65-e026-4f38-9c44-c62f0b4bd141",
    iconSet: "MaterialIcons",
    iconName: "fiber-new"
  },
  {
    uuid: "e7a7686e-d7ce-4330-bdb5-c30c09958e2f",
    iconSet: "MaterialIcons",
    iconName: "fiber-pin"
  },
  {
    uuid: "85db626b-6d25-4286-9143-9d1540dc6f64",
    iconSet: "MaterialIcons",
    iconName: "fiber-smart-record"
  },
  {
    uuid: "7e66add8-8c60-436b-b711-9d61285f6519",
    iconSet: "MaterialIcons",
    iconName: "file-copy"
  },
  {
    uuid: "f6ed862e-7ba5-4e49-978f-3369c59e1512",
    iconSet: "MaterialIcons",
    iconName: "file-download"
  },
  {
    uuid: "0e1475bc-7a54-41f9-b893-bd5137edcbe4",
    iconSet: "MaterialIcons",
    iconName: "file-download-done"
  },
  {
    uuid: "a5f74127-2f65-4977-806c-c8541dc35d8a",
    iconSet: "MaterialIcons",
    iconName: "file-present"
  },
  {
    uuid: "65db547d-4519-4082-9a7d-70d111532b46",
    iconSet: "MaterialIcons",
    iconName: "file-upload"
  },
  {
    uuid: "e78b0249-67f8-4f73-a80e-c0ac3a163165",
    iconSet: "MaterialIcons",
    iconName: "filter"
  },
  {
    uuid: "45e03b0e-cbf9-49fc-b0a1-05f4210a74ee",
    iconSet: "MaterialIcons",
    iconName: "filter-1"
  },
  {
    uuid: "7256886a-28c3-40e4-8667-74b9a3e468ae",
    iconSet: "MaterialIcons",
    iconName: "filter-2"
  },
  {
    uuid: "4a11a244-09c8-4782-9f9e-22c5682704bb",
    iconSet: "MaterialIcons",
    iconName: "filter-3"
  },
  {
    uuid: "910a4c68-77d1-49d4-95c8-cf4b95465f4d",
    iconSet: "MaterialIcons",
    iconName: "filter-4"
  },
  {
    uuid: "0a467fd2-c59b-458e-a306-a026cfd94218",
    iconSet: "MaterialIcons",
    iconName: "filter-5"
  },
  {
    uuid: "3a680825-9706-45d0-ab48-56a53e526e70",
    iconSet: "MaterialIcons",
    iconName: "filter-6"
  },
  {
    uuid: "b56c2507-6885-49c5-94e1-49ba2f3c5660",
    iconSet: "MaterialIcons",
    iconName: "filter-7"
  },
  {
    uuid: "ec05c9e3-1e3d-40dc-82b7-d514f380b21d",
    iconSet: "MaterialIcons",
    iconName: "filter-8"
  },
  {
    uuid: "e8b8a22c-b7be-47c9-a2a4-b52367249bf9",
    iconSet: "MaterialIcons",
    iconName: "filter-9"
  },
  {
    uuid: "304b8274-c553-4269-8d8c-a1644ddab8b4",
    iconSet: "MaterialIcons",
    iconName: "filter-9-plus"
  },
  {
    uuid: "29869aa0-2921-4154-934a-718c2105bb41",
    iconSet: "MaterialIcons",
    iconName: "filter-alt"
  },
  {
    uuid: "523ecc1a-22e7-4962-9535-7d0cfcf983cc",
    iconSet: "MaterialIcons",
    iconName: "filter-b-and-w"
  },
  {
    uuid: "0325b3ef-0eee-4549-acb4-867e5b700ff8",
    iconSet: "MaterialIcons",
    iconName: "filter-center-focus"
  },
  {
    uuid: "6cd897f1-c5c5-417b-adbf-5c4dd087cb5b",
    iconSet: "MaterialIcons",
    iconName: "filter-drama"
  },
  {
    uuid: "9b141781-5d55-4783-9361-d9bc1d2265d3",
    iconSet: "MaterialIcons",
    iconName: "filter-frames"
  },
  {
    uuid: "f3129ffa-4ff7-43e9-8d28-6f11dfbaa5d3",
    iconSet: "MaterialIcons",
    iconName: "filter-hdr"
  },
  {
    uuid: "5512e4ae-7d17-4188-b834-18b63e9b455d",
    iconSet: "MaterialIcons",
    iconName: "filter-list"
  },
  {
    uuid: "d3f0d8c6-e896-4646-8fc9-eeaa460a3e7a",
    iconSet: "MaterialIcons",
    iconName: "filter-list-alt"
  },
  {
    uuid: "fc2d79c5-9954-4081-8841-d7af8f0abebb",
    iconSet: "MaterialIcons",
    iconName: "filter-none"
  },
  {
    uuid: "21b5db99-189e-4d0b-9923-390670e8e383",
    iconSet: "MaterialIcons",
    iconName: "filter-tilt-shift"
  },
  {
    uuid: "818ae041-730a-4a7a-8f9e-013555f48f39",
    iconSet: "MaterialIcons",
    iconName: "filter-vintage"
  },
  {
    uuid: "dc88292c-1b80-4183-85b2-132b57e33464",
    iconSet: "MaterialIcons",
    iconName: "find-in-page"
  },
  {
    uuid: "fe36f70d-6b63-4b97-83a4-7c2cb841c6f6",
    iconSet: "MaterialIcons",
    iconName: "find-replace"
  },
  {
    uuid: "64edc3a4-ec21-46e9-9974-b667737e5617",
    iconSet: "MaterialIcons",
    iconName: "fingerprint"
  },
  {
    uuid: "1e76d7ac-1a99-43ee-992e-d516efcb06e5",
    iconSet: "MaterialIcons",
    iconName: "fire-extinguisher"
  },
  {
    uuid: "19c15a69-a342-46e6-94a0-42c1d6f43142",
    iconSet: "MaterialIcons",
    iconName: "fire-hydrant"
  },
  {
    uuid: "468419fd-96e9-495d-9342-676f9315c18c",
    iconSet: "MaterialIcons",
    iconName: "fireplace"
  },
  {
    uuid: "7b298903-0d62-4802-87c2-2797345a04cc",
    iconSet: "MaterialIcons",
    iconName: "first-page"
  },
  {
    uuid: "cd8e9c19-4f5c-489c-a5aa-684013d63d78",
    iconSet: "MaterialIcons",
    iconName: "fit-screen"
  },
  {
    uuid: "47c8c8b7-e261-4898-bf88-841723a3d6da",
    iconSet: "MaterialIcons",
    iconName: "fitness-center"
  },
  {
    uuid: "1787ec34-55f7-4ed8-a77d-2dcec318d4ee",
    iconSet: "MaterialIcons",
    iconName: "flag"
  },
  {
    uuid: "f1f30ab2-8949-48f0-bb1c-97e8fc8fe194",
    iconSet: "MaterialIcons",
    iconName: "flaky"
  },
  {
    uuid: "7e6d2041-6626-4ebf-971a-afeee375e98c",
    iconSet: "MaterialIcons",
    iconName: "flare"
  },
  {
    uuid: "820306f2-3355-4574-9d4d-9927b368dc59",
    iconSet: "MaterialIcons",
    iconName: "flash-auto"
  },
  {
    uuid: "90347100-5161-47a0-868f-a004f7ffd693",
    iconSet: "MaterialIcons",
    iconName: "flash-off"
  },
  {
    uuid: "785c0bae-67bd-4ab5-9d65-194932a77eeb",
    iconSet: "MaterialIcons",
    iconName: "flash-on"
  },
  {
    uuid: "6c677ce3-b0bb-48da-a146-86ecc6c589d3",
    iconSet: "MaterialIcons",
    iconName: "flight"
  },
  {
    uuid: "ca5bb0f3-9e43-4510-a27c-53a0b082588f",
    iconSet: "MaterialIcons",
    iconName: "flight-land"
  },
  {
    uuid: "178a8e71-f1f5-4a82-8b36-ec98a90d579c",
    iconSet: "MaterialIcons",
    iconName: "flight-takeoff"
  },
  {
    uuid: "8d7c9161-a380-4e05-b0d4-aaf2b445de82",
    iconSet: "MaterialIcons",
    iconName: "flip"
  },
  {
    uuid: "df674ef1-c726-4d9d-b711-53fa488c1ec0",
    iconSet: "MaterialIcons",
    iconName: "flip-camera-android"
  },
  {
    uuid: "88f7b39d-f944-4d04-80a0-9d2da13427f5",
    iconSet: "MaterialIcons",
    iconName: "flip-camera-ios"
  },
  {
    uuid: "1d614a69-3416-4842-a0f5-ca0e629578e4",
    iconSet: "MaterialIcons",
    iconName: "flip-to-back"
  },
  {
    uuid: "5be1ac30-fea2-4e0a-8a22-ce5e9c977f88",
    iconSet: "MaterialIcons",
    iconName: "flip-to-front"
  },
  {
    uuid: "3249fb0c-45c8-44e6-b9a6-074cc699474e",
    iconSet: "MaterialIcons",
    iconName: "folder"
  },
  {
    uuid: "788869f6-19fb-4531-85c5-fe225f22e92d",
    iconSet: "MaterialIcons",
    iconName: "folder-open"
  },
  {
    uuid: "c8b4da4b-0969-49af-989b-51c5a1675bc4",
    iconSet: "MaterialIcons",
    iconName: "folder-shared"
  },
  {
    uuid: "8e9c71c0-12ce-4ce7-a336-c94bc95c1dbc",
    iconSet: "MaterialIcons",
    iconName: "folder-special"
  },
  {
    uuid: "0523dc6c-19dc-45ff-a803-844ce31de098",
    iconSet: "MaterialIcons",
    iconName: "follow-the-signs"
  },
  {
    uuid: "71e685d5-fe4a-4f41-96fa-0c2a80035b10",
    iconSet: "MaterialIcons",
    iconName: "font-download"
  },
  {
    uuid: "7aaf5ab5-01a2-4585-a065-989c3aac43b8",
    iconSet: "MaterialIcons",
    iconName: "food-bank"
  },
  {
    uuid: "f56d88ab-66f2-4a97-bd8c-665e428305a0",
    iconSet: "MaterialIcons",
    iconName: "format-align-center"
  },
  {
    uuid: "d9c7b99b-f530-4158-b568-55395ca366e0",
    iconSet: "MaterialIcons",
    iconName: "format-align-justify"
  },
  {
    uuid: "ffce1659-a2a3-487d-ba42-6095c509f511",
    iconSet: "MaterialIcons",
    iconName: "format-align-left"
  },
  {
    uuid: "2c3277d5-9d3a-4749-a7d7-8a01f3e2bdd7",
    iconSet: "MaterialIcons",
    iconName: "format-align-right"
  },
  {
    uuid: "704b0c25-9b99-4621-9e6b-200f7c99fa2a",
    iconSet: "MaterialIcons",
    iconName: "format-bold"
  },
  {
    uuid: "36c91718-4ad9-4e92-8a8a-d79d0e98f8ed",
    iconSet: "MaterialIcons",
    iconName: "format-clear"
  },
  {
    uuid: "069a5207-aa12-4412-a6d8-56f9e85ccb46",
    iconSet: "MaterialIcons",
    iconName: "format-color-fill"
  },
  {
    uuid: "97998986-2a70-4f5c-b0c2-6a2a372d6229",
    iconSet: "MaterialIcons",
    iconName: "format-color-reset"
  },
  {
    uuid: "25e410cd-4e26-4a5c-94d5-e93be26b12c6",
    iconSet: "MaterialIcons",
    iconName: "format-color-text"
  },
  {
    uuid: "ee6fc27b-0f51-4eba-bf39-7f32b893b31d",
    iconSet: "MaterialIcons",
    iconName: "format-indent-decrease"
  },
  {
    uuid: "c01691fe-237e-4b19-98d5-f26356766a44",
    iconSet: "MaterialIcons",
    iconName: "format-indent-increase"
  },
  {
    uuid: "c2922720-54ec-40b3-a6cd-e22380713dfd",
    iconSet: "MaterialIcons",
    iconName: "format-italic"
  },
  {
    uuid: "aa55c49c-6574-4ad6-8cf8-74a38b7defa2",
    iconSet: "MaterialIcons",
    iconName: "format-line-spacing"
  },
  {
    uuid: "09321867-3430-4e2a-af45-4e72b9265bf5",
    iconSet: "MaterialIcons",
    iconName: "format-list-bulleted"
  },
  {
    uuid: "9b807c04-d5d5-4d9e-a81f-53d61099b3bf",
    iconSet: "MaterialIcons",
    iconName: "format-list-numbered"
  },
  {
    uuid: "02733a6f-aed4-4c9f-8fa7-fdf060827546",
    iconSet: "MaterialIcons",
    iconName: "format-list-numbered-rtl"
  },
  {
    uuid: "ccc306ef-0a16-436a-98cb-8be5ba9a487b",
    iconSet: "MaterialIcons",
    iconName: "format-paint"
  },
  {
    uuid: "2d0fcea1-1ef5-4c65-9500-284d9521e85f",
    iconSet: "MaterialIcons",
    iconName: "format-quote"
  },
  {
    uuid: "2e666bfd-8084-4002-a9f7-5390450cab19",
    iconSet: "MaterialIcons",
    iconName: "format-shapes"
  },
  {
    uuid: "328b9f6e-f8ae-47e2-80b0-4e9e07ecda19",
    iconSet: "MaterialIcons",
    iconName: "format-size"
  },
  {
    uuid: "f5739faf-d08e-4302-af99-f6afe983101f",
    iconSet: "MaterialIcons",
    iconName: "format-strikethrough"
  },
  {
    uuid: "159886da-62c8-42f4-a210-dca237bcff57",
    iconSet: "MaterialIcons",
    iconName: "format-textdirection-l-to-r"
  },
  {
    uuid: "cfc712c8-d62f-482b-8f16-bdbd6d229b1e",
    iconSet: "MaterialIcons",
    iconName: "format-textdirection-r-to-l"
  },
  {
    uuid: "d87d11cb-0940-4910-a752-75b831108b82",
    iconSet: "MaterialIcons",
    iconName: "format-underline"
  },
  {
    uuid: "2486ef23-271a-4aae-bbba-84c835be179e",
    iconSet: "MaterialIcons",
    iconName: "format-underlined"
  },
  {
    uuid: "8e241c3e-2a84-465c-b032-e517db8dc0cb",
    iconSet: "MaterialIcons",
    iconName: "forum"
  },
  {
    uuid: "849d8600-6a96-420c-a84c-5fca7f2e01fc",
    iconSet: "MaterialIcons",
    iconName: "forward"
  },
  {
    uuid: "ea48fc0b-1fe8-4053-85b2-37c7d6d40160",
    iconSet: "MaterialIcons",
    iconName: "forward-10"
  },
  {
    uuid: "d9f883a8-cf53-45e1-aac8-4528b5bc98eb",
    iconSet: "MaterialIcons",
    iconName: "forward-30"
  },
  {
    uuid: "20ceadc3-f092-40f2-986d-723b868e65fd",
    iconSet: "MaterialIcons",
    iconName: "forward-5"
  },
  {
    uuid: "11d01d25-0bb7-4be4-ba71-2973a8dbf2e2",
    iconSet: "MaterialIcons",
    iconName: "forward-to-inbox"
  },
  {
    uuid: "95934589-74c3-453d-a0c9-4940e5dbd235",
    iconSet: "MaterialIcons",
    iconName: "foundation"
  },
  {
    uuid: "396fa445-3b5f-4285-bb6d-b3d9d9a5ea6d",
    iconSet: "MaterialIcons",
    iconName: "free-breakfast"
  },
  {
    uuid: "959da32d-f2a0-4829-87a6-9d1f375f9b55",
    iconSet: "MaterialIcons",
    iconName: "fullscreen"
  },
  {
    uuid: "02fec2c8-bce9-4dd5-a061-be21cbdb2b9e",
    iconSet: "MaterialIcons",
    iconName: "fullscreen-exit"
  },
  {
    uuid: "a9713948-4c07-47ba-8d0f-63e1b36248bc",
    iconSet: "MaterialIcons",
    iconName: "functions"
  },
  {
    uuid: "6cea4d7d-5e71-4338-b424-fa965a43d05b",
    iconSet: "MaterialIcons",
    iconName: "g-translate"
  },
  {
    uuid: "b07938b4-16d8-4dd5-b027-488eb4dc3396",
    iconSet: "MaterialIcons",
    iconName: "gamepad"
  },
  {
    uuid: "1369a9c2-19fd-462c-a93f-ae182a4b5b89",
    iconSet: "MaterialIcons",
    iconName: "games"
  },
  {
    uuid: "4e338742-5d9d-44ad-be4e-9815fec97b75",
    iconSet: "MaterialIcons",
    iconName: "gavel"
  },
  {
    uuid: "81f83d2b-0f1c-47f6-a62c-ecd62d6bebbf",
    iconSet: "MaterialIcons",
    iconName: "gesture"
  },
  {
    uuid: "bd6336d7-996d-48ca-91b2-10c409c887a2",
    iconSet: "MaterialIcons",
    iconName: "get-app"
  },
  {
    uuid: "abac0859-00f8-4b06-94cb-5ce7f5df2799",
    iconSet: "MaterialIcons",
    iconName: "gif"
  },
  {
    uuid: "498737d7-b0f1-4615-bfe5-927201dc2a7d",
    iconSet: "MaterialIcons",
    iconName: "goat"
  },
  {
    uuid: "e9da7a56-568e-48e6-b3b6-d72ff1b00221",
    iconSet: "MaterialIcons",
    iconName: "golf-course"
  },
  {
    uuid: "2cf4eee4-3ee3-4ca6-9896-92dbd8abfcec",
    iconSet: "MaterialIcons",
    iconName: "gps-fixed"
  },
  {
    uuid: "4422555e-951f-4a52-8c31-adcf1465b43f",
    iconSet: "MaterialIcons",
    iconName: "gps-not-fixed"
  },
  {
    uuid: "8c0027e8-f9c6-45ba-a519-5257228c86d9",
    iconSet: "MaterialIcons",
    iconName: "gps-off"
  },
  {
    uuid: "5de2ea14-02a2-4941-8711-28e6b9f7e0f6",
    iconSet: "MaterialIcons",
    iconName: "grade"
  },
  {
    uuid: "e8a71a4c-860b-4753-b256-73384157f015",
    iconSet: "MaterialIcons",
    iconName: "gradient"
  },
  {
    uuid: "d6d06244-7205-43b3-849f-91137999e55f",
    iconSet: "MaterialIcons",
    iconName: "grading"
  },
  {
    uuid: "7b81b145-cdd7-441e-9b48-3f92dcd5700b",
    iconSet: "MaterialIcons",
    iconName: "grain"
  },
  {
    uuid: "5638cda6-5918-4927-b6db-a7690e68e8d5",
    iconSet: "MaterialIcons",
    iconName: "graphic-eq"
  },
  {
    uuid: "02c37edd-32a8-415a-9d17-6c8b431ddce9",
    iconSet: "MaterialIcons",
    iconName: "grass"
  },
  {
    uuid: "31d6fc85-a064-465a-a160-e4e8a0069d0d",
    iconSet: "MaterialIcons",
    iconName: "grid-off"
  },
  {
    uuid: "9a8e9f97-6e9a-43d1-adf9-e31fc57d626a",
    iconSet: "MaterialIcons",
    iconName: "grid-on"
  },
  {
    uuid: "ff656274-eb95-4553-a4dc-a32ebca6d075",
    iconSet: "MaterialIcons",
    iconName: "grid-view"
  },
  {
    uuid: "c971d417-6bd9-4828-b2aa-182c18b3d259",
    iconSet: "MaterialIcons",
    iconName: "group"
  },
  {
    uuid: "01c8f643-b5ec-4b59-8081-904d4a79d772",
    iconSet: "MaterialIcons",
    iconName: "group-add"
  },
  {
    uuid: "a537bcd5-c872-4ca7-984b-fb8fe1acf7c6",
    iconSet: "MaterialIcons",
    iconName: "group-work"
  },
  {
    uuid: "52cd1177-5bd0-41ef-a524-4cd6296c348b",
    iconSet: "MaterialIcons",
    iconName: "groups"
  },
  {
    uuid: "f5cba110-06b3-4b74-8726-59d246e4a2c8",
    iconSet: "MaterialIcons",
    iconName: "hail"
  },
  {
    uuid: "6019fdb8-d2a8-411b-b476-772e4168502d",
    iconSet: "MaterialIcons",
    iconName: "handyman"
  },
  {
    uuid: "63691c6a-90c8-4890-a26a-b5a555f0f4db",
    iconSet: "MaterialIcons",
    iconName: "hardware"
  },
  {
    uuid: "fc827918-aaf0-46f3-a955-89db97b75d07",
    iconSet: "MaterialIcons",
    iconName: "hd"
  },
  {
    uuid: "f0779b01-9f45-4217-8eba-27833e6cabcc",
    iconSet: "MaterialIcons",
    iconName: "hdr-enhanced-select"
  },
  {
    uuid: "ae8fd6cd-7e3b-435b-9ca7-7f4e1476dfd1",
    iconSet: "MaterialIcons",
    iconName: "hdr-off"
  },
  {
    uuid: "c5ae82e0-16de-4d86-b1f5-060edbcf89e1",
    iconSet: "MaterialIcons",
    iconName: "hdr-on"
  },
  {
    uuid: "56b7d823-8eb1-4a22-93b8-aebd2fffdd67",
    iconSet: "MaterialIcons",
    iconName: "hdr-strong"
  },
  {
    uuid: "91f406cb-c675-4589-8f86-1f7d8ae61b04",
    iconSet: "MaterialIcons",
    iconName: "hdr-weak"
  },
  {
    uuid: "258c30bf-35cb-4508-aa9e-c9d9487c2fbf",
    iconSet: "MaterialIcons",
    iconName: "headset"
  },
  {
    uuid: "1d0ebbf5-3a50-4476-8e67-e9e781c8f8c5",
    iconSet: "MaterialIcons",
    iconName: "headset-mic"
  },
  {
    uuid: "16e5f176-1a35-4369-967d-ac37f32207ed",
    iconSet: "MaterialIcons",
    iconName: "headset-off"
  },
  {
    uuid: "14910a5c-3c92-4c67-b1c6-e3940e860825",
    iconSet: "MaterialIcons",
    iconName: "healing"
  },
  {
    uuid: "1458ff28-263e-4da8-a988-68d1a657b332",
    iconSet: "MaterialIcons",
    iconName: "hearing"
  },
  {
    uuid: "df0dd1cf-b585-4a1b-8330-f6068a5aa227",
    iconSet: "MaterialIcons",
    iconName: "hearing-disabled"
  },
  {
    uuid: "490fc6cd-6eaa-41c6-afb3-338ea256eea6",
    iconSet: "MaterialIcons",
    iconName: "height"
  },
  {
    uuid: "8b1c017c-e248-47a8-b28e-b2e121ba1217",
    iconSet: "MaterialIcons",
    iconName: "help"
  },
  {
    uuid: "443bcaef-ced0-4eb3-ae90-06ad66a42c33",
    iconSet: "MaterialIcons",
    iconName: "help-center"
  },
  {
    uuid: "3f8b4d74-a50f-470a-9dd7-a4807ee040e9",
    iconSet: "MaterialIcons",
    iconName: "help-outline"
  },
  {
    uuid: "eb087722-8350-45ef-a6ce-e44fbb920785",
    iconSet: "MaterialIcons",
    iconName: "high-quality"
  },
  {
    uuid: "dcee3538-68a3-493a-ab20-6e9c293d2341",
    iconSet: "MaterialIcons",
    iconName: "highlight"
  },
  {
    uuid: "dc18ebd1-8f2f-4d09-abba-b782990c365b",
    iconSet: "MaterialIcons",
    iconName: "highlight-alt"
  },
  {
    uuid: "407bbe6e-282d-40fb-9289-d217edb1cc99",
    iconSet: "MaterialIcons",
    iconName: "highlight-off"
  },
  {
    uuid: "273b8bfd-65c9-4c9b-b388-2823d096d1be",
    iconSet: "MaterialIcons",
    iconName: "highlight-remove"
  },
  {
    uuid: "5880280b-37d0-4947-ab4b-9747a7268413",
    iconSet: "MaterialIcons",
    iconName: "history"
  },
  {
    uuid: "34f8515a-6b2b-4331-b661-2fa02e861b1c",
    iconSet: "MaterialIcons",
    iconName: "history-edu"
  },
  {
    uuid: "dbf735bc-edd4-4e10-ae76-9614cd1c4deb",
    iconSet: "MaterialIcons",
    iconName: "history-toggle-off"
  },
  {
    uuid: "d8ea587a-0c6f-4a56-a385-5ca7206f2951",
    iconSet: "MaterialIcons",
    iconName: "home"
  },
  {
    uuid: "1ae00370-eb0e-4c7d-9075-d282c8ce2ec8",
    iconSet: "MaterialIcons",
    iconName: "home-filled"
  },
  {
    uuid: "cf63e218-9ce3-4385-b92a-a2b079fe28ce",
    iconSet: "MaterialIcons",
    iconName: "home-repair-service"
  },
  {
    uuid: "aa0fcdaf-b75b-4ac6-8855-42da0b0991b2",
    iconSet: "MaterialIcons",
    iconName: "home-work"
  },
  {
    uuid: "501b4633-53ad-4e76-8b98-e65487e2d000",
    iconSet: "MaterialIcons",
    iconName: "horizontal-distribute"
  },
  {
    uuid: "96701d78-437a-40ff-ba5e-edfa4674618b",
    iconSet: "MaterialIcons",
    iconName: "horizontal-rule"
  },
  {
    uuid: "17ee73bf-f27b-4e23-876d-5e7853802d79",
    iconSet: "MaterialIcons",
    iconName: "horizontal-split"
  },
  {
    uuid: "6b50a26e-7202-4fe8-9d6c-d37eb94c025c",
    iconSet: "MaterialIcons",
    iconName: "hot-tub"
  },
  {
    uuid: "e458e485-0b8e-4a3d-8765-dbf9c35ea1b8",
    iconSet: "MaterialIcons",
    iconName: "hotel"
  },
  {
    uuid: "915ccf96-9b7b-4a91-8c60-763c6f9870fc",
    iconSet: "MaterialIcons",
    iconName: "hourglass-bottom"
  },
  {
    uuid: "1fd7e81d-5331-4ac5-baa5-462b3229a3f1",
    iconSet: "MaterialIcons",
    iconName: "hourglass-disabled"
  },
  {
    uuid: "63b66c7b-cc87-4549-9a0c-ae4e6c5cf867",
    iconSet: "MaterialIcons",
    iconName: "hourglass-empty"
  },
  {
    uuid: "5473cfbe-9d27-497d-a653-b6815b6805b0",
    iconSet: "MaterialIcons",
    iconName: "hourglass-full"
  },
  {
    uuid: "4d7e5d94-4a66-4968-868b-3d3a004d1283",
    iconSet: "MaterialIcons",
    iconName: "hourglass-top"
  },
  {
    uuid: "e51866f4-3599-4b31-9e77-a1a90483cf2c",
    iconSet: "MaterialIcons",
    iconName: "house"
  },
  {
    uuid: "a334f0b0-fb68-44ee-85c8-a6e461a21a66",
    iconSet: "MaterialIcons",
    iconName: "house-siding"
  },
  {
    uuid: "344cf145-094b-4d36-8a71-0f50f2c48524",
    iconSet: "MaterialIcons",
    iconName: "how-to-reg"
  },
  {
    uuid: "d1b0e11f-681b-4bf4-a54b-d734f53391da",
    iconSet: "MaterialIcons",
    iconName: "how-to-vote"
  },
  {
    uuid: "817ca84e-3485-4a54-9bcc-f7912cc473d6",
    iconSet: "MaterialIcons",
    iconName: "http"
  },
  {
    uuid: "7dd58f33-28a4-456a-a8a1-a67581f23d0e",
    iconSet: "MaterialIcons",
    iconName: "https"
  },
  {
    uuid: "54a3cbe0-c75b-4741-8ff0-f00efbe50e56",
    iconSet: "MaterialIcons",
    iconName: "hvac"
  },
  {
    uuid: "522e943f-2faa-49ab-b4ca-7be3114b747f",
    iconSet: "MaterialIcons",
    iconName: "icecream"
  },
  {
    uuid: "bb1a61b6-a2f2-40ab-9e86-02378660f2d7",
    iconSet: "MaterialIcons",
    iconName: "image"
  },
  {
    uuid: "8d9b83f5-d04c-4071-938a-7b7a0314acbf",
    iconSet: "MaterialIcons",
    iconName: "image-aspect-ratio"
  },
  {
    uuid: "4f05196e-f9e4-4cec-9d7a-d5f70b2e436c",
    iconSet: "MaterialIcons",
    iconName: "image-not-supported"
  },
  {
    uuid: "2c08e609-8286-4e89-b6c9-4c8cd7bc420d",
    iconSet: "MaterialIcons",
    iconName: "image-search"
  },
  {
    uuid: "8651ab7e-2ca1-416d-b1ba-33d96325a0d3",
    iconSet: "MaterialIcons",
    iconName: "imagesearch-roller"
  },
  {
    uuid: "55db367a-f4ae-4e13-867f-6e3094c441c8",
    iconSet: "MaterialIcons",
    iconName: "import-contacts"
  },
  {
    uuid: "5b521704-c858-44b7-b59d-c37b339f28de",
    iconSet: "MaterialIcons",
    iconName: "import-export"
  },
  {
    uuid: "f270b994-2f2e-49e1-9312-8bc906aa74c1",
    iconSet: "MaterialIcons",
    iconName: "important-devices"
  },
  {
    uuid: "a8fa60c8-fcf1-45f4-a6dc-5ad9aa42007c",
    iconSet: "MaterialIcons",
    iconName: "inbox"
  },
  {
    uuid: "10cc43ee-df13-44ee-a75d-112bf2762510",
    iconSet: "MaterialIcons",
    iconName: "indeterminate-check-box"
  },
  {
    uuid: "10fc7c02-ce4e-4d45-92da-f5c614e8c3a8",
    iconSet: "MaterialIcons",
    iconName: "info"
  },
  {
    uuid: "60f0661f-e31c-437a-b4c8-e91098040be1",
    iconSet: "MaterialIcons",
    iconName: "info-outline"
  },
  {
    uuid: "9273215b-79cc-40f8-adc3-630a72a2463a",
    iconSet: "MaterialIcons",
    iconName: "input"
  },
  {
    uuid: "1d64e8f9-5db2-4db6-84b6-f2ec35c4fa1a",
    iconSet: "MaterialIcons",
    iconName: "insert-chart"
  },
  {
    uuid: "a19c6464-774e-41f7-bf82-a962615e1780",
    iconSet: "MaterialIcons",
    iconName: "insert-chart-outlined"
  },
  {
    uuid: "4d640a04-4b6c-4327-827b-646aaddc86fd",
    iconSet: "MaterialIcons",
    iconName: "insert-comment"
  },
  {
    uuid: "bafb9267-25dd-4935-8f11-2e132340fde2",
    iconSet: "MaterialIcons",
    iconName: "insert-drive-file"
  },
  {
    uuid: "3c85b535-9cb9-4d8e-9271-3cb65c1382e7",
    iconSet: "MaterialIcons",
    iconName: "insert-emoticon"
  },
  {
    uuid: "13cdc115-0d86-4f38-bdd7-6cbd59c8e4e3",
    iconSet: "MaterialIcons",
    iconName: "insert-invitation"
  },
  {
    uuid: "d0acd8c7-b789-4415-a8cc-1225d4db6154",
    iconSet: "MaterialIcons",
    iconName: "insert-link"
  },
  {
    uuid: "40aaf71f-a040-4491-b2c6-7c2a54ce28b8",
    iconSet: "MaterialIcons",
    iconName: "insert-photo"
  },
  {
    uuid: "81b5228b-0fed-4d8a-a06c-a7b2bfef7afa",
    iconSet: "MaterialIcons",
    iconName: "insights"
  },
  {
    uuid: "8e2083e0-2376-4213-a093-ba9102d8d0c8",
    iconSet: "MaterialIcons",
    iconName: "integration-instructions"
  },
  {
    uuid: "b7e6d187-c88d-4999-a71e-655df53e376a",
    iconSet: "MaterialIcons",
    iconName: "inventory"
  },
  {
    uuid: "db41956f-ddf5-4f99-9267-91c3ffd996ff",
    iconSet: "MaterialIcons",
    iconName: "invert-colors"
  },
  {
    uuid: "e76aa012-4306-4e51-a732-0a12eb6ba6c3",
    iconSet: "MaterialIcons",
    iconName: "invert-colors-off"
  },
  {
    uuid: "aae21ffc-3a2e-454f-b546-a6fa41648ef0",
    iconSet: "MaterialIcons",
    iconName: "invert-colors-on"
  },
  {
    uuid: "74875180-8388-4706-b31c-bb1c62064550",
    iconSet: "MaterialIcons",
    iconName: "ios-share"
  },
  {
    uuid: "232323c4-bb6f-447e-bf0f-a99d3a715fa2",
    iconSet: "MaterialIcons",
    iconName: "iso"
  },
  {
    uuid: "569a7199-a951-4b57-811a-fcbb20a1a3e4",
    iconSet: "MaterialIcons",
    iconName: "keyboard"
  },
  {
    uuid: "f016b819-a2df-4fff-922d-7b51df22a53f",
    iconSet: "MaterialIcons",
    iconName: "keyboard-arrow-down"
  },
  {
    uuid: "60d0b053-8d99-4fc5-8c9d-80b51c3dc9f4",
    iconSet: "MaterialIcons",
    iconName: "keyboard-arrow-left"
  },
  {
    uuid: "a1a113ee-d09c-4a63-b0a2-78d10db9d994",
    iconSet: "MaterialIcons",
    iconName: "keyboard-arrow-right"
  },
  {
    uuid: "f60d5a41-3efc-406b-8dc3-24a391c59c43",
    iconSet: "MaterialIcons",
    iconName: "keyboard-arrow-up"
  },
  {
    uuid: "a156e217-3ec6-4d46-a734-ff1db02f4bed",
    iconSet: "MaterialIcons",
    iconName: "keyboard-backspace"
  },
  {
    uuid: "da5f457c-a306-4e73-81a1-6abebb925aee",
    iconSet: "MaterialIcons",
    iconName: "keyboard-capslock"
  },
  {
    uuid: "ed597096-37a3-4c79-92ea-bde89080e99a",
    iconSet: "MaterialIcons",
    iconName: "keyboard-control"
  },
  {
    uuid: "ab033ddf-6cea-4286-bbf0-124e39b726a2",
    iconSet: "MaterialIcons",
    iconName: "keyboard-hide"
  },
  {
    uuid: "483ec8d9-dc94-4654-b0d0-7d85af086167",
    iconSet: "MaterialIcons",
    iconName: "keyboard-return"
  },
  {
    uuid: "29ee6da6-58c4-474a-a6c7-787626df5560",
    iconSet: "MaterialIcons",
    iconName: "keyboard-tab"
  },
  {
    uuid: "038ff607-3800-4a86-9cc6-c671166c1da1",
    iconSet: "MaterialIcons",
    iconName: "keyboard-voice"
  },
  {
    uuid: "cbf63c3e-3441-4e45-9fd9-68abb84d87b6",
    iconSet: "MaterialIcons",
    iconName: "king-bed"
  },
  {
    uuid: "0ca0b75a-0ffa-414f-af53-ee8b6bc46f44",
    iconSet: "MaterialIcons",
    iconName: "kitchen"
  },
  {
    uuid: "8c6a1e2a-c7db-4e54-8ba3-c5486889d196",
    iconSet: "MaterialIcons",
    iconName: "label"
  },
  {
    uuid: "09be7d80-29da-43fd-baaa-e781e93b7552",
    iconSet: "MaterialIcons",
    iconName: "label-important"
  },
  {
    uuid: "9c9c3cd9-3f83-466b-b225-4189cc1b25b0",
    iconSet: "MaterialIcons",
    iconName: "label-important-outline"
  },
  {
    uuid: "e41a6472-ddad-45b9-ae8b-9221469cc368",
    iconSet: "MaterialIcons",
    iconName: "label-off"
  },
  {
    uuid: "34f4d6db-a347-4d55-9f79-7a9e27f15f2d",
    iconSet: "MaterialIcons",
    iconName: "label-outline"
  },
  {
    uuid: "55586cfb-f455-48a4-b279-c4c7141be71f",
    iconSet: "MaterialIcons",
    iconName: "landscape"
  },
  {
    uuid: "ee1125dd-358a-48f7-ad44-5b60729e04ba",
    iconSet: "MaterialIcons",
    iconName: "language"
  },
  {
    uuid: "27d1dd6f-d545-424b-9bd4-347306a6022c",
    iconSet: "MaterialIcons",
    iconName: "laptop"
  },
  {
    uuid: "ed9ced94-1d4b-4394-9e0a-b9db4d70fee1",
    iconSet: "MaterialIcons",
    iconName: "laptop-chromebook"
  },
  {
    uuid: "d69d1006-9440-40de-ae1c-d3f8e4388589",
    iconSet: "MaterialIcons",
    iconName: "laptop-mac"
  },
  {
    uuid: "ff355901-afc9-4a5e-9126-9147970435e4",
    iconSet: "MaterialIcons",
    iconName: "laptop-windows"
  },
  {
    uuid: "47c875c1-d22a-42b7-8cc4-fd4ecdfc26cb",
    iconSet: "MaterialIcons",
    iconName: "last-page"
  },
  {
    uuid: "ea6cde23-3fa6-49f5-a0ef-ac3134d22e77",
    iconSet: "MaterialIcons",
    iconName: "launch"
  },
  {
    uuid: "1ed72bf1-b51f-44e5-88ac-87094ada1e6b",
    iconSet: "MaterialIcons",
    iconName: "layers"
  },
  {
    uuid: "9d2c8d94-436e-4d9e-95ee-c3dc0766b72b",
    iconSet: "MaterialIcons",
    iconName: "layers-clear"
  },
  {
    uuid: "0e46d0e1-4fd8-4ae3-99a2-cf80eaf0f8fe",
    iconSet: "MaterialIcons",
    iconName: "leaderboard"
  },
  {
    uuid: "2a942636-86ac-4522-9d9f-2322c5d61845",
    iconSet: "MaterialIcons",
    iconName: "leak-add"
  },
  {
    uuid: "6b0f4b7a-a7c7-4256-801c-c88dd4cca158",
    iconSet: "MaterialIcons",
    iconName: "leak-remove"
  },
  {
    uuid: "98aceb87-ed5e-4bb3-b690-7601c90b0bd1",
    iconSet: "MaterialIcons",
    iconName: "leave-bags-at-home"
  },
  {
    uuid: "7c600226-e4af-4b76-8473-47bd09e2ed16",
    iconSet: "MaterialIcons",
    iconName: "legend-toggle"
  },
  {
    uuid: "d1f83085-c095-441e-83cf-a1807de9e99e",
    iconSet: "MaterialIcons",
    iconName: "lens"
  },
  {
    uuid: "443cb32e-db1b-472c-9e97-c4e5aca6b6b3",
    iconSet: "MaterialIcons",
    iconName: "library-add"
  },
  {
    uuid: "ea7197f0-861f-4ea3-8cd1-233988f449bd",
    iconSet: "MaterialIcons",
    iconName: "library-add-check"
  },
  {
    uuid: "147cd72c-44d3-4e33-8889-6a426297ed84",
    iconSet: "MaterialIcons",
    iconName: "library-books"
  },
  {
    uuid: "2ebca37c-ff2a-4827-a393-4858dbd84793",
    iconSet: "MaterialIcons",
    iconName: "library-music"
  },
  {
    uuid: "499b6a77-a9e2-4da7-aeb6-e959da13d828",
    iconSet: "MaterialIcons",
    iconName: "lightbulb"
  },
  {
    uuid: "8c9bdb61-51c9-40a8-bc4a-80c7d24a68b8",
    iconSet: "MaterialIcons",
    iconName: "lightbulb-outline"
  },
  {
    uuid: "18ca3ae3-8ad0-4aba-8bdf-d206b7349cd2",
    iconSet: "MaterialIcons",
    iconName: "line-style"
  },
  {
    uuid: "ca077088-8563-4f30-a2b6-3460231a0cc1",
    iconSet: "MaterialIcons",
    iconName: "line-weight"
  },
  {
    uuid: "501b7889-735c-4697-bd44-0357015736e9",
    iconSet: "MaterialIcons",
    iconName: "linear-scale"
  },
  {
    uuid: "b7fdd914-1b73-4255-bbcc-b0993cef8a4c",
    iconSet: "MaterialIcons",
    iconName: "link"
  },
  {
    uuid: "b7dbe9b0-98cd-4b7e-8b7c-a79459928b93",
    iconSet: "MaterialIcons",
    iconName: "link-off"
  },
  {
    uuid: "0368ec68-f601-48e4-847f-5218dfbafda6",
    iconSet: "MaterialIcons",
    iconName: "linked-camera"
  },
  {
    uuid: "00dd898f-ed0d-4bf1-999a-287cc0860225",
    iconSet: "MaterialIcons",
    iconName: "liquor"
  },
  {
    uuid: "98295036-f260-4f23-a1d6-0601d48ee670",
    iconSet: "MaterialIcons",
    iconName: "list"
  },
  {
    uuid: "4b719f2b-b91d-4eaa-9a1f-23ad4df10e57",
    iconSet: "MaterialIcons",
    iconName: "list-alt"
  },
  {
    uuid: "4061e6c6-9fff-4bb6-acaa-ecb2f3b1a92e",
    iconSet: "MaterialIcons",
    iconName: "live-help"
  },
  {
    uuid: "d9ec86d1-daed-4aa8-a12f-b8fc71722b11",
    iconSet: "MaterialIcons",
    iconName: "live-tv"
  },
  {
    uuid: "cb863881-3d41-4b09-a894-06de7662ba5c",
    iconSet: "MaterialIcons",
    iconName: "local-activity"
  },
  {
    uuid: "3aee722a-8470-4afc-915a-5efb881ce027",
    iconSet: "MaterialIcons",
    iconName: "local-airport"
  },
  {
    uuid: "9e6ab5f3-d1de-4fad-a41e-132f8659b281",
    iconSet: "MaterialIcons",
    iconName: "local-atm"
  },
  {
    uuid: "26f33f88-3797-45e0-9310-876e1997f865",
    iconSet: "MaterialIcons",
    iconName: "local-attraction"
  },
  {
    uuid: "e38a95de-fd3e-4598-9964-dfaab0084545",
    iconSet: "MaterialIcons",
    iconName: "local-bar"
  },
  {
    uuid: "1bef0a99-9615-4b3c-bde9-362a3e488754",
    iconSet: "MaterialIcons",
    iconName: "local-cafe"
  },
  {
    uuid: "c5bf7efb-d3ac-402d-b968-a6f8def83531",
    iconSet: "MaterialIcons",
    iconName: "local-car-wash"
  },
  {
    uuid: "b19535b5-0340-451c-8458-40f9afc5d00f",
    iconSet: "MaterialIcons",
    iconName: "local-convenience-store"
  },
  {
    uuid: "9b4a70b4-c23d-4eec-8511-cea2305eaa5d",
    iconSet: "MaterialIcons",
    iconName: "local-dining"
  },
  {
    uuid: "5cb56243-d6fe-42ca-8556-64c502a8b210",
    iconSet: "MaterialIcons",
    iconName: "local-drink"
  },
  {
    uuid: "53a54c5a-f6c6-423e-9144-785daa5729c2",
    iconSet: "MaterialIcons",
    iconName: "local-fire-department"
  },
  {
    uuid: "9c952c36-af8e-471e-a5a2-983778519e65",
    iconSet: "MaterialIcons",
    iconName: "local-florist"
  },
  {
    uuid: "518f808f-587e-4024-9e11-0093ee943a3b",
    iconSet: "MaterialIcons",
    iconName: "local-gas-station"
  },
  {
    uuid: "04c19c3d-f28d-4857-b90d-415fd8de88e2",
    iconSet: "MaterialIcons",
    iconName: "local-grocery-store"
  },
  {
    uuid: "173f20d2-a5ee-4744-82fa-a089fad19d46",
    iconSet: "MaterialIcons",
    iconName: "local-hospital"
  },
  {
    uuid: "24bbf747-11d2-413e-a442-6f1a517d93de",
    iconSet: "MaterialIcons",
    iconName: "local-hotel"
  },
  {
    uuid: "ebd9f784-9d0e-46ed-9ebb-d74bd1ef00ca",
    iconSet: "MaterialIcons",
    iconName: "local-laundry-service"
  },
  {
    uuid: "990c86a7-43c9-4e15-a8a2-ef4080145079",
    iconSet: "MaterialIcons",
    iconName: "local-library"
  },
  {
    uuid: "15ea1e82-4402-496b-bb05-e647084cb490",
    iconSet: "MaterialIcons",
    iconName: "local-mall"
  },
  {
    uuid: "fa25f01c-91b8-4ba3-8790-853abc428802",
    iconSet: "MaterialIcons",
    iconName: "local-movies"
  },
  {
    uuid: "6fcc2a6e-4b6c-4ca9-8200-bc0caccd882c",
    iconSet: "MaterialIcons",
    iconName: "local-offer"
  },
  {
    uuid: "a8830771-3435-49a7-871e-086832c736b5",
    iconSet: "MaterialIcons",
    iconName: "local-parking"
  },
  {
    uuid: "98c44994-cdf5-4fa0-9f7f-ec841efbc794",
    iconSet: "MaterialIcons",
    iconName: "local-pharmacy"
  },
  {
    uuid: "e02dd74f-078b-4ec3-b834-82138d74ffba",
    iconSet: "MaterialIcons",
    iconName: "local-phone"
  },
  {
    uuid: "d12b3349-acb4-4108-9359-9b283f57c6fe",
    iconSet: "MaterialIcons",
    iconName: "local-pizza"
  },
  {
    uuid: "6fe94fea-1a76-4262-98db-23c92afed78b",
    iconSet: "MaterialIcons",
    iconName: "local-play"
  },
  {
    uuid: "561a518d-e688-48fb-b90b-d56cde732643",
    iconSet: "MaterialIcons",
    iconName: "local-police"
  },
  {
    uuid: "9a6d534d-e9fe-4747-b98b-8b118820b2b1",
    iconSet: "MaterialIcons",
    iconName: "local-post-office"
  },
  {
    uuid: "63ed0dcb-d5a6-4ed0-826c-5af48ea51956",
    iconSet: "MaterialIcons",
    iconName: "local-print-shop"
  },
  {
    uuid: "73cb4ee3-a393-4521-80ec-c0d946f496f5",
    iconSet: "MaterialIcons",
    iconName: "local-printshop"
  },
  {
    uuid: "f8d44e1f-aa8f-4900-8d72-89976653ca5e",
    iconSet: "MaterialIcons",
    iconName: "local-restaurant"
  },
  {
    uuid: "71f94496-5540-4610-a1f1-e0eb00dd9e3b",
    iconSet: "MaterialIcons",
    iconName: "local-see"
  },
  {
    uuid: "1762149f-6686-40bb-941f-0d08f310d756",
    iconSet: "MaterialIcons",
    iconName: "local-shipping"
  },
  {
    uuid: "def0443f-0329-4479-8cdb-655a707bf259",
    iconSet: "MaterialIcons",
    iconName: "local-taxi"
  },
  {
    uuid: "2d7feb7d-35eb-4545-8c91-7b624f2b7ab2",
    iconSet: "MaterialIcons",
    iconName: "location-city"
  },
  {
    uuid: "4aba9d2c-bfa8-4010-8b64-140fe3a7ca02",
    iconSet: "MaterialIcons",
    iconName: "location-disabled"
  },
  {
    uuid: "a34d04d3-bb71-49c6-a43a-e34daa5f9826",
    iconSet: "MaterialIcons",
    iconName: "location-history"
  },
  {
    uuid: "e3ade657-de8f-49f4-9b93-dd9b98a83a78",
    iconSet: "MaterialIcons",
    iconName: "location-off"
  },
  {
    uuid: "ce66eb21-651d-41cd-8d53-91bc18c88219",
    iconSet: "MaterialIcons",
    iconName: "location-on"
  },
  {
    uuid: "57b22a0c-ef03-4d8e-902a-5eef7ac728c8",
    iconSet: "MaterialIcons",
    iconName: "location-pin"
  },
  {
    uuid: "c33b9fc7-4459-4203-ac7c-cc7ab181b62a",
    iconSet: "MaterialIcons",
    iconName: "location-searching"
  },
  {
    uuid: "ba8fdfe4-37c3-4055-bbd4-0b535a6cfa29",
    iconSet: "MaterialIcons",
    iconName: "lock"
  },
  {
    uuid: "93836dc8-01b2-47c9-9877-a0e9cb0bf7b0",
    iconSet: "MaterialIcons",
    iconName: "lock-clock"
  },
  {
    uuid: "eb8c2cbc-0f19-4bc9-ab89-91a039c498e1",
    iconSet: "MaterialIcons",
    iconName: "lock-open"
  },
  {
    uuid: "72af86c3-9cdc-4f93-9f3f-b05729cf7905",
    iconSet: "MaterialIcons",
    iconName: "lock-outline"
  },
  {
    uuid: "b2baac39-e47a-49ff-b042-4232b8ef1b72",
    iconSet: "MaterialIcons",
    iconName: "login"
  },
  {
    uuid: "c9e81c4c-273b-4f26-b179-031419766168",
    iconSet: "MaterialIcons",
    iconName: "logout"
  },
  {
    uuid: "1c5f3a35-8bae-44af-a70e-ea8d6a99c635",
    iconSet: "MaterialIcons",
    iconName: "looks"
  },
  {
    uuid: "f9cd0ce9-f4f7-40da-b030-82274477a6ed",
    iconSet: "MaterialIcons",
    iconName: "looks-3"
  },
  {
    uuid: "ec37cb14-8514-4589-9431-e84a1fe10270",
    iconSet: "MaterialIcons",
    iconName: "looks-4"
  },
  {
    uuid: "f8dfc1b9-47bd-431e-b54f-5b0730546399",
    iconSet: "MaterialIcons",
    iconName: "looks-5"
  },
  {
    uuid: "119b4cc4-d359-4fc2-a106-e33c41890d61",
    iconSet: "MaterialIcons",
    iconName: "looks-6"
  },
  {
    uuid: "7cbb568c-42f5-403e-a1ed-0dc76e912868",
    iconSet: "MaterialIcons",
    iconName: "looks-one"
  },
  {
    uuid: "4a77ec3a-5992-46b0-86a3-418a33050dba",
    iconSet: "MaterialIcons",
    iconName: "looks-two"
  },
  {
    uuid: "e094cf0b-56c5-4f8a-b444-ba98717ac1d2",
    iconSet: "MaterialIcons",
    iconName: "loop"
  },
  {
    uuid: "10e29211-5439-4c6b-a665-db82b47eed45",
    iconSet: "MaterialIcons",
    iconName: "loupe"
  },
  {
    uuid: "1762775c-56be-4331-8ee9-19b948d5e1f7",
    iconSet: "MaterialIcons",
    iconName: "low-priority"
  },
  {
    uuid: "34140ebd-b26c-4406-8842-feea782b0e5d",
    iconSet: "MaterialIcons",
    iconName: "loyalty"
  },
  {
    uuid: "5f6633a7-cead-49b7-ad12-c781e2b794aa",
    iconSet: "MaterialIcons",
    iconName: "luggage"
  },
  {
    uuid: "55c8156a-1089-4f53-9b5a-a1a4744e1067",
    iconSet: "MaterialIcons",
    iconName: "lunch-dining"
  },
  {
    uuid: "67facdfc-758b-4e29-8b74-2e827b1b8e0d",
    iconSet: "MaterialIcons",
    iconName: "mail"
  },
  {
    uuid: "0134ea91-4391-4c0b-842b-b08066752967",
    iconSet: "MaterialIcons",
    iconName: "mail-outline"
  },
  {
    uuid: "d5d0bba9-93f6-4b5d-99c7-e3706ad93d09",
    iconSet: "MaterialIcons",
    iconName: "map"
  },
  {
    uuid: "54c1182d-582e-4d0c-834a-2d0384a711ac",
    iconSet: "MaterialIcons",
    iconName: "maps-ugc"
  },
  {
    uuid: "54079cb6-6e58-4914-bced-6e6927f40a61",
    iconSet: "MaterialIcons",
    iconName: "margin"
  },
  {
    uuid: "96e2f114-e76a-4e24-b961-562761bc2e1d",
    iconSet: "MaterialIcons",
    iconName: "mark-as-unread"
  },
  {
    uuid: "6f64dd7d-8565-4967-a4e5-2fb8d2e841da",
    iconSet: "MaterialIcons",
    iconName: "mark-chat-read"
  },
  {
    uuid: "acca0866-3461-4a05-aa07-7cdaf946b21e",
    iconSet: "MaterialIcons",
    iconName: "mark-chat-unread"
  },
  {
    uuid: "7409c8c0-661c-48cf-bdd6-b913adfa7f2b",
    iconSet: "MaterialIcons",
    iconName: "mark-email-read"
  },
  {
    uuid: "96233c66-6cc7-4eba-9c6f-b6a0efdbdf5c",
    iconSet: "MaterialIcons",
    iconName: "mark-email-unread"
  },
  {
    uuid: "f2a956c0-2f7f-4734-8bbe-8f770bb48128",
    iconSet: "MaterialIcons",
    iconName: "markunread"
  },
  {
    uuid: "997c3417-998f-4cf6-bbec-07e76c555b14",
    iconSet: "MaterialIcons",
    iconName: "markunread-mailbox"
  },
  {
    uuid: "259b0c00-2cfd-49ad-8a36-f28c0cd0f612",
    iconSet: "MaterialIcons",
    iconName: "masks"
  },
  {
    uuid: "f58bd358-f479-43ce-a4f4-3be752b1661e",
    iconSet: "MaterialIcons",
    iconName: "maximize"
  },
  {
    uuid: "8c9cea3d-7296-4cec-9ca3-3c355f1b3312",
    iconSet: "MaterialIcons",
    iconName: "mediation"
  },
  {
    uuid: "1efa71b3-652f-441b-acb8-7b271e92c917",
    iconSet: "MaterialIcons",
    iconName: "medical-services"
  },
  {
    uuid: "f8f8dd53-0991-4b9a-8b38-362f8c387676",
    iconSet: "MaterialIcons",
    iconName: "meeting-room"
  },
  {
    uuid: "a3c25acd-84c2-4e9c-a263-cfeae6a557d0",
    iconSet: "MaterialIcons",
    iconName: "memory"
  },
  {
    uuid: "86d31aa1-aa99-4c89-a452-a7dacc703909",
    iconSet: "MaterialIcons",
    iconName: "menu"
  },
  {
    uuid: "d5d293aa-8e20-4ab8-bc7d-54c56adc99d2",
    iconSet: "MaterialIcons",
    iconName: "menu-book"
  },
  {
    uuid: "f8103a48-acfc-4b83-adc6-831737a72dac",
    iconSet: "MaterialIcons",
    iconName: "menu-open"
  },
  {
    uuid: "db4386e8-e1e9-4fe8-bcc1-6bd6dcb20dfd",
    iconSet: "MaterialIcons",
    iconName: "merge-type"
  },
  {
    uuid: "5e901870-aba7-43c7-b502-b65e65b93ea3",
    iconSet: "MaterialIcons",
    iconName: "message"
  },
  {
    uuid: "658f4c4c-f181-460c-bae9-5d9517599648",
    iconSet: "MaterialIcons",
    iconName: "messenger"
  },
  {
    uuid: "f8493baa-51ea-4d9d-948b-d7beb7222f59",
    iconSet: "MaterialIcons",
    iconName: "messenger-outline"
  },
  {
    uuid: "48903e65-64aa-43a8-b269-c9a879cb55b5",
    iconSet: "MaterialIcons",
    iconName: "mic"
  },
  {
    uuid: "3b191573-27a2-41f9-a581-4ea6111c457e",
    iconSet: "MaterialIcons",
    iconName: "mic-external-off"
  },
  {
    uuid: "cd3ccd5e-98fc-43aa-a45a-6cdb6c0b5f74",
    iconSet: "MaterialIcons",
    iconName: "mic-external-on"
  },
  {
    uuid: "efdea89c-c33a-4de5-86e3-8df2c7ef0e2a",
    iconSet: "MaterialIcons",
    iconName: "mic-none"
  },
  {
    uuid: "1ba422c2-d599-43d4-88c4-8914482f07f8",
    iconSet: "MaterialIcons",
    iconName: "mic-off"
  },
  {
    uuid: "e6a06d67-b951-4936-89d8-d893c8d53c33",
    iconSet: "MaterialIcons",
    iconName: "microwave"
  },
  {
    uuid: "6db1026d-0129-499d-a8ac-7f81fecbae2e",
    iconSet: "MaterialIcons",
    iconName: "military-tech"
  },
  {
    uuid: "5b17d141-a804-4392-b8b7-0cd8334ff709",
    iconSet: "MaterialIcons",
    iconName: "minimize"
  },
  {
    uuid: "c8ac169d-f6a8-4e65-b6c0-a781bf42d956",
    iconSet: "MaterialIcons",
    iconName: "miscellaneous-services"
  },
  {
    uuid: "0c1fbae8-a605-4f7d-ac61-6750af578859",
    iconSet: "MaterialIcons",
    iconName: "missed-video-call"
  },
  {
    uuid: "f78a2888-152a-4c86-b944-0043c2baaea0",
    iconSet: "MaterialIcons",
    iconName: "mms"
  },
  {
    uuid: "b090e1f6-0c56-45e6-9dc8-93b48457f150",
    iconSet: "MaterialIcons",
    iconName: "mobile-friendly"
  },
  {
    uuid: "e9e2818c-bce2-4267-b32c-acd90e4031ef",
    iconSet: "MaterialIcons",
    iconName: "mobile-off"
  },
  {
    uuid: "6cd5bf3e-75d4-4c8c-bca6-c151b3a77119",
    iconSet: "MaterialIcons",
    iconName: "mobile-screen-share"
  },
  {
    uuid: "86c5f198-e490-4ca2-bba6-17f820c47406",
    iconSet: "MaterialIcons",
    iconName: "mode-comment"
  },
  {
    uuid: "a2788a14-6c34-49d3-8c69-45dc0b479100",
    iconSet: "MaterialIcons",
    iconName: "mode-edit"
  },
  {
    uuid: "5c972077-fd93-4865-bd4f-85ed833b5668",
    iconSet: "MaterialIcons",
    iconName: "model-training"
  },
  {
    uuid: "2920ebe8-05ab-4843-8f9c-6208f0803d88",
    iconSet: "MaterialIcons",
    iconName: "monetization-on"
  },
  {
    uuid: "f8dea3fd-5a55-4594-9217-2e3fd94f6bc2",
    iconSet: "MaterialIcons",
    iconName: "money"
  },
  {
    uuid: "3f53d854-5ae2-41a5-8a8a-e506fd20295e",
    iconSet: "MaterialIcons",
    iconName: "money-off"
  },
  {
    uuid: "eb0338b6-a52a-4428-93c1-48edded7388e",
    iconSet: "MaterialIcons",
    iconName: "monitor"
  },
  {
    uuid: "b9661275-7031-47b7-bb09-443cc4b78024",
    iconSet: "MaterialIcons",
    iconName: "monochrome-photos"
  },
  {
    uuid: "b07399dd-bf44-45df-a2f2-b4598778ba41",
    iconSet: "MaterialIcons",
    iconName: "mood"
  },
  {
    uuid: "dccd0d99-421d-4398-9c37-f2f146221acc",
    iconSet: "MaterialIcons",
    iconName: "mood-bad"
  },
  {
    uuid: "0e6a9164-c7d2-416f-9597-790be7b7d6a1",
    iconSet: "MaterialIcons",
    iconName: "moped"
  },
  {
    uuid: "47be91d0-008d-4111-9886-d3ce4ebcbd7f",
    iconSet: "MaterialIcons",
    iconName: "more"
  },
  {
    uuid: "f433c580-40b9-4aa6-98c5-e1d7f26a7fe8",
    iconSet: "MaterialIcons",
    iconName: "more-horiz"
  },
  {
    uuid: "1f8836b2-ff6c-462c-8a6e-0dcccccc5557",
    iconSet: "MaterialIcons",
    iconName: "more-time"
  },
  {
    uuid: "94bbcefe-f4c7-4d00-a851-b396d2f67252",
    iconSet: "MaterialIcons",
    iconName: "more-vert"
  },
  {
    uuid: "08eb695b-f7ce-4e4c-95a2-ef4aa33962d8",
    iconSet: "MaterialIcons",
    iconName: "motion-photos-off"
  },
  {
    uuid: "37918162-c0f0-4ec2-8046-f2ede4fcbf50",
    iconSet: "MaterialIcons",
    iconName: "motion-photos-on"
  },
  {
    uuid: "a6aa8858-60dd-432a-976e-d783dddc96d6",
    iconSet: "MaterialIcons",
    iconName: "motion-photos-pause"
  },
  {
    uuid: "944138d0-7106-461f-9b0d-87e8e41521c6",
    iconSet: "MaterialIcons",
    iconName: "motion-photos-paused"
  },
  {
    uuid: "5c3b2f55-2d5f-4cc7-8721-05778c9f83c1",
    iconSet: "MaterialIcons",
    iconName: "motorcycle"
  },
  {
    uuid: "c3c36a8d-759b-45da-a601-603f17f60d23",
    iconSet: "MaterialIcons",
    iconName: "mouse"
  },
  {
    uuid: "079f1f4e-9922-45b7-a877-4bb07ca45daa",
    iconSet: "MaterialIcons",
    iconName: "move-to-inbox"
  },
  {
    uuid: "d45934a6-b93a-47b9-9372-a3af7f7c632c",
    iconSet: "MaterialIcons",
    iconName: "movie"
  },
  {
    uuid: "739e9587-2e8c-4b94-be63-beb44713093b",
    iconSet: "MaterialIcons",
    iconName: "movie-creation"
  },
  {
    uuid: "0a3f4901-f360-4370-a94a-053bff349b4c",
    iconSet: "MaterialIcons",
    iconName: "movie-filter"
  },
  {
    uuid: "3d3b3ff2-7e70-444b-b3f1-1751675ab465",
    iconSet: "MaterialIcons",
    iconName: "mp"
  },
  {
    uuid: "2e8dba05-8165-431b-9ffc-aaa5af1a34cf",
    iconSet: "MaterialIcons",
    iconName: "multiline-chart"
  },
  {
    uuid: "6ada36c6-f49a-4d73-86e6-f4134b6aa204",
    iconSet: "MaterialIcons",
    iconName: "multiple-stop"
  },
  {
    uuid: "d71fd9d8-74e4-478c-ab32-92f07a727ce5",
    iconSet: "MaterialIcons",
    iconName: "multitrack-audio"
  },
  {
    uuid: "2f1b88b7-77cf-4240-b8e5-4cb7358f2a34",
    iconSet: "MaterialIcons",
    iconName: "museum"
  },
  {
    uuid: "ad079fdd-d362-43a4-85e1-453c45580663",
    iconSet: "MaterialIcons",
    iconName: "music-note"
  },
  {
    uuid: "0c608908-1187-4659-b538-59a924c72a91",
    iconSet: "MaterialIcons",
    iconName: "music-off"
  },
  {
    uuid: "a8c85bca-66bc-4b47-938c-991eef983d08",
    iconSet: "MaterialIcons",
    iconName: "music-video"
  },
  {
    uuid: "ab221648-b49f-4607-ab04-8a1bc504a748",
    iconSet: "MaterialIcons",
    iconName: "my-library-add"
  },
  {
    uuid: "cb1149c6-50b1-410f-8554-0cf7a1f512f3",
    iconSet: "MaterialIcons",
    iconName: "my-library-books"
  },
  {
    uuid: "5ac1a448-8e14-415b-b97a-1f6f9bc78273",
    iconSet: "MaterialIcons",
    iconName: "my-library-music"
  },
  {
    uuid: "3d989603-d470-46d4-8b2f-f05f28b1ecc6",
    iconSet: "MaterialIcons",
    iconName: "my-location"
  },
  {
    uuid: "f50ca8fe-8cff-4a8d-96d0-99af27f13367",
    iconSet: "MaterialIcons",
    iconName: "nat"
  },
  {
    uuid: "bdc5e93c-df43-4a24-ad2c-97f20fcb8d19",
    iconSet: "MaterialIcons",
    iconName: "nature"
  },
  {
    uuid: "fa70adc2-6ff5-4420-9918-bbb036307bca",
    iconSet: "MaterialIcons",
    iconName: "nature-people"
  },
  {
    uuid: "dc77bd88-0a4d-4ff0-9df5-d80fcff4bbd2",
    iconSet: "MaterialIcons",
    iconName: "navigate-before"
  },
  {
    uuid: "e13d479f-189d-4231-8557-9e694ed180ba",
    iconSet: "MaterialIcons",
    iconName: "navigate-next"
  },
  {
    uuid: "f71ab609-026d-42dd-8101-141c2bf33ca4",
    iconSet: "MaterialIcons",
    iconName: "navigation"
  },
  {
    uuid: "6831fbc5-a5c9-4cc9-8ed6-11be90bc4240",
    iconSet: "MaterialIcons",
    iconName: "near-me"
  },
  {
    uuid: "e784abc8-3b93-42c0-b42b-b0cdd703dd52",
    iconSet: "MaterialIcons",
    iconName: "near-me-disabled"
  },
  {
    uuid: "8db4eeae-a55b-4684-97f2-df3494ae4eee",
    iconSet: "MaterialIcons",
    iconName: "network-cell"
  },
  {
    uuid: "8ead630b-89ee-49ac-bbf7-0c2a7064c91d",
    iconSet: "MaterialIcons",
    iconName: "network-check"
  },
  {
    uuid: "84cb87ef-be40-4a89-998f-f5591167b32e",
    iconSet: "MaterialIcons",
    iconName: "network-locked"
  },
  {
    uuid: "f622ce33-0d19-40c9-9d6b-69ba7475a8a4",
    iconSet: "MaterialIcons",
    iconName: "network-wifi"
  },
  {
    uuid: "e4140eb3-b706-4533-9dfe-7862b2e735ee",
    iconSet: "MaterialIcons",
    iconName: "new-releases"
  },
  {
    uuid: "db143882-2e13-457e-903c-ee4fcf822174",
    iconSet: "MaterialIcons",
    iconName: "next-plan"
  },
  {
    uuid: "3aa91bf1-013e-420c-b26e-834bc8281ecc",
    iconSet: "MaterialIcons",
    iconName: "next-week"
  },
  {
    uuid: "1b365bd8-7838-4a2d-91fc-a28164f8e097",
    iconSet: "MaterialIcons",
    iconName: "nfc"
  },
  {
    uuid: "723f6952-7a6a-49db-ae21-8dfea42e8539",
    iconSet: "MaterialIcons",
    iconName: "night-shelter"
  },
  {
    uuid: "47dd2f32-45fd-4f20-b475-3a6543d1c71f",
    iconSet: "MaterialIcons",
    iconName: "nightlife"
  },
  {
    uuid: "2136876f-d8be-4b0e-81bc-b6f9093cca4c",
    iconSet: "MaterialIcons",
    iconName: "nightlight-round"
  },
  {
    uuid: "9f1ed1ce-3ac2-4445-8972-fb3a45b797ca",
    iconSet: "MaterialIcons",
    iconName: "nights-stay"
  },
  {
    uuid: "537863ac-1f44-46ed-9136-c88fac1678b1",
    iconSet: "MaterialIcons",
    iconName: "no-backpack"
  },
  {
    uuid: "82daa778-69e9-4a04-b02a-b194a0e8cfc6",
    iconSet: "MaterialIcons",
    iconName: "no-cell"
  },
  {
    uuid: "1f0ec667-9fcd-45cb-ac96-38fd7bdd4223",
    iconSet: "MaterialIcons",
    iconName: "no-drinks"
  },
  {
    uuid: "440b8c1b-b4ed-4b86-9953-c9ecac3b9e27",
    iconSet: "MaterialIcons",
    iconName: "no-encryption"
  },
  {
    uuid: "390ece7e-3785-4061-9335-587a16881a89",
    iconSet: "MaterialIcons",
    iconName: "no-flash"
  },
  {
    uuid: "6f0c06fe-3858-4562-9e4a-c175d700321b",
    iconSet: "MaterialIcons",
    iconName: "no-food"
  },
  {
    uuid: "b4a7bd2c-5649-4507-8c41-68107b195c9a",
    iconSet: "MaterialIcons",
    iconName: "no-luggage"
  },
  {
    uuid: "30512afa-2ba9-45af-a7b5-8c01ad7d658b",
    iconSet: "MaterialIcons",
    iconName: "no-meals"
  },
  {
    uuid: "fab63fb4-2560-454f-bcc3-66c57aad8690",
    iconSet: "MaterialIcons",
    iconName: "no-meals-ouline"
  },
  {
    uuid: "11a7da79-0b2b-48e0-9116-6a9cd63ffbb0",
    iconSet: "MaterialIcons",
    iconName: "no-meeting-room"
  },
  {
    uuid: "ea71296d-3c52-4202-af96-2e18f01ec4c1",
    iconSet: "MaterialIcons",
    iconName: "no-photography"
  },
  {
    uuid: "88d7445f-b20e-4932-8e6c-8d49fbc678ec",
    iconSet: "MaterialIcons",
    iconName: "no-sim"
  },
  {
    uuid: "7ab08628-ee9d-469a-88b9-d74d715a732a",
    iconSet: "MaterialIcons",
    iconName: "no-stroller"
  },
  {
    uuid: "89c0a89b-3c4b-4654-89eb-927983f8fc9c",
    iconSet: "MaterialIcons",
    iconName: "no-transfer"
  },
  {
    uuid: "b2224dc4-5935-4f21-a55b-fd318250d718",
    iconSet: "MaterialIcons",
    iconName: "north"
  },
  {
    uuid: "a4d5abd8-b4bd-47bc-abaf-779acdae9343",
    iconSet: "MaterialIcons",
    iconName: "north-east"
  },
  {
    uuid: "fb7ff7d9-7098-4c84-9402-6d38c738d889",
    iconSet: "MaterialIcons",
    iconName: "north-west"
  },
  {
    uuid: "5af95184-20ce-443e-b66b-c9dfb6ac6757",
    iconSet: "MaterialIcons",
    iconName: "not-accessible"
  },
  {
    uuid: "5c1fa60a-2188-4379-ac68-acd449b67fd8",
    iconSet: "MaterialIcons",
    iconName: "not-interested"
  },
  {
    uuid: "6c54212c-746d-4b6f-b945-c49ced4f6bd4",
    iconSet: "MaterialIcons",
    iconName: "not-listed-location"
  },
  {
    uuid: "6b745d14-83ce-43f3-87f0-b68257636d65",
    iconSet: "MaterialIcons",
    iconName: "not-started"
  },
  {
    uuid: "e4cbf89e-6747-4afe-9469-55dc816fd981",
    iconSet: "MaterialIcons",
    iconName: "note"
  },
  {
    uuid: "850f04a2-9b38-48d1-aeae-92f0f24dba41",
    iconSet: "MaterialIcons",
    iconName: "note-add"
  },
  {
    uuid: "b29adf08-6a6d-4962-9f19-85bd962e240d",
    iconSet: "MaterialIcons",
    iconName: "notes"
  },
  {
    uuid: "1ad67031-d383-4962-83c9-98df8093b364",
    iconSet: "MaterialIcons",
    iconName: "notification-important"
  },
  {
    uuid: "2947e785-30dd-4e72-9208-9e04fa4860ab",
    iconSet: "MaterialIcons",
    iconName: "notifications"
  },
  {
    uuid: "5b1730a6-2f48-49fd-b3ef-e35f907747e9",
    iconSet: "MaterialIcons",
    iconName: "notifications-active"
  },
  {
    uuid: "d9cce8f1-9542-4631-9cdc-b561a146257d",
    iconSet: "MaterialIcons",
    iconName: "notifications-none"
  },
  {
    uuid: "ee4169e4-07a8-4c82-be50-b16a588de47d",
    iconSet: "MaterialIcons",
    iconName: "notifications-off"
  },
  {
    uuid: "4b5e9a1c-57fd-4330-a84a-22f8742c4464",
    iconSet: "MaterialIcons",
    iconName: "notifications-on"
  },
  {
    uuid: "9844dc8c-cc20-46d5-ab8b-82caf9481437",
    iconSet: "MaterialIcons",
    iconName: "notifications-paused"
  },
  {
    uuid: "5c306e61-24d8-4b75-9477-90e166ead49a",
    iconSet: "MaterialIcons",
    iconName: "now-wallpaper"
  },
  {
    uuid: "b39be7b7-2d10-4de0-89ba-504523dd48d2",
    iconSet: "MaterialIcons",
    iconName: "now-widgets"
  },
  {
    uuid: "aac77d50-6b5f-4a97-a2a8-3dfa3c46818b",
    iconSet: "MaterialIcons",
    iconName: "offline-bolt"
  },
  {
    uuid: "a5b00ab7-6507-4313-86e1-655c04015e87",
    iconSet: "MaterialIcons",
    iconName: "offline-pin"
  },
  {
    uuid: "68bf3bd1-411a-4705-8268-fde5cf7f66a6",
    iconSet: "MaterialIcons",
    iconName: "offline-share"
  },
  {
    uuid: "40fca433-8897-45b8-bd45-cff3bab826f9",
    iconSet: "MaterialIcons",
    iconName: "ondemand-video"
  },
  {
    uuid: "0bd3f55e-2a1c-49ac-98da-66b0f1aa1641",
    iconSet: "MaterialIcons",
    iconName: "online-prediction"
  },
  {
    uuid: "7871d900-9b16-4a43-82b4-14968632e95c",
    iconSet: "MaterialIcons",
    iconName: "opacity"
  },
  {
    uuid: "c3b48d76-2820-4308-9875-a25645da7605",
    iconSet: "MaterialIcons",
    iconName: "open-in-browser"
  },
  {
    uuid: "d22d0241-87c2-45fe-a7f0-26e123aea701",
    iconSet: "MaterialIcons",
    iconName: "open-in-full"
  },
  {
    uuid: "590e56a3-6c8a-461d-a4eb-a6838b79adfa",
    iconSet: "MaterialIcons",
    iconName: "open-in-new"
  },
  {
    uuid: "b9321a3f-d0a4-4f21-ba36-fedcf24f1b3f",
    iconSet: "MaterialIcons",
    iconName: "open-with"
  },
  {
    uuid: "845e721c-2e67-483f-a42b-d8e0c99287b1",
    iconSet: "MaterialIcons",
    iconName: "outbond"
  },
  {
    uuid: "feb893b1-b91b-4b07-b4c4-cf7c4ccf3ac8",
    iconSet: "MaterialIcons",
    iconName: "outbox"
  },
  {
    uuid: "ed1b8e39-1203-4d1a-9d4f-7a83facdd702",
    iconSet: "MaterialIcons",
    iconName: "outdoor-grill"
  },
  {
    uuid: "5fd6f48f-892f-4d45-94ad-18f34e151903",
    iconSet: "MaterialIcons",
    iconName: "outgoing-mail"
  },
  {
    uuid: "d0d3f964-ddc3-477d-a128-cd3bfaf210f8",
    iconSet: "MaterialIcons",
    iconName: "outlet"
  },
  {
    uuid: "c3548798-65d4-4d8d-9467-f0da03e53a2e",
    iconSet: "MaterialIcons",
    iconName: "outlined-flag"
  },
  {
    uuid: "49686e8e-7a6e-48ad-bb1e-2584fbfbe847",
    iconSet: "MaterialIcons",
    iconName: "padding"
  },
  {
    uuid: "2143bf03-be4e-4a75-8364-a0a27f8d0d23",
    iconSet: "MaterialIcons",
    iconName: "pages"
  },
  {
    uuid: "3280857e-0881-46d1-8f6b-d7d98994c656",
    iconSet: "MaterialIcons",
    iconName: "pageview"
  },
  {
    uuid: "3389847f-2a07-4d20-b18c-d2c217919484",
    iconSet: "MaterialIcons",
    iconName: "palette"
  },
  {
    uuid: "9041f10e-5de1-4583-a2e8-affe0153e96d",
    iconSet: "MaterialIcons",
    iconName: "pan-tool"
  },
  {
    uuid: "05b38a68-e60a-4b94-811d-e1062913b156",
    iconSet: "MaterialIcons",
    iconName: "panorama"
  },
  {
    uuid: "3ee46b5d-77d4-4ced-b4da-3c6f56ea52f6",
    iconSet: "MaterialIcons",
    iconName: "panorama-fish-eye"
  },
  {
    uuid: "04438653-4040-47ae-b244-4d66f0a215e7",
    iconSet: "MaterialIcons",
    iconName: "panorama-fisheye"
  },
  {
    uuid: "964fcccf-97eb-4cb7-a665-f51d9b33d2d0",
    iconSet: "MaterialIcons",
    iconName: "panorama-horizontal"
  },
  {
    uuid: "1c0c3853-8263-4845-a7fe-b1720055c504",
    iconSet: "MaterialIcons",
    iconName: "panorama-horizontal-select"
  },
  {
    uuid: "892c5f9c-c180-4ecb-af63-dde64e860748",
    iconSet: "MaterialIcons",
    iconName: "panorama-photosphere"
  },
  {
    uuid: "02535939-a1a0-41ec-bf72-4ea647debff4",
    iconSet: "MaterialIcons",
    iconName: "panorama-photosphere-select"
  },
  {
    uuid: "976f7331-0b06-47cc-b2e1-803bc52324b5",
    iconSet: "MaterialIcons",
    iconName: "panorama-vertical"
  },
  {
    uuid: "3c6170a3-cc90-4677-be01-a4c058877ccc",
    iconSet: "MaterialIcons",
    iconName: "panorama-vertical-select"
  },
  {
    uuid: "097daa78-bbf9-4a2a-8449-9bcb21f1281e",
    iconSet: "MaterialIcons",
    iconName: "panorama-wide-angle"
  },
  {
    uuid: "e33055cf-52b9-4245-a276-bf1668cfd5d1",
    iconSet: "MaterialIcons",
    iconName: "panorama-wide-angle-select"
  },
  {
    uuid: "c51fc8f2-423e-4825-bc5b-42367c6bca3b",
    iconSet: "MaterialIcons",
    iconName: "park"
  },
  {
    uuid: "17072eee-e108-4567-8151-fd8fb6b61e49",
    iconSet: "MaterialIcons",
    iconName: "party-mode"
  },
  {
    uuid: "85b9d776-c793-461d-8ada-0a3bc7b3531b",
    iconSet: "MaterialIcons",
    iconName: "pause"
  },
  {
    uuid: "cc766710-54d7-4f50-9b2a-63e2c2400727",
    iconSet: "MaterialIcons",
    iconName: "pause-circle-filled"
  },
  {
    uuid: "9321b761-2c26-4967-8271-13a261537e15",
    iconSet: "MaterialIcons",
    iconName: "pause-circle-outline"
  },
  {
    uuid: "6289abe6-8f0e-40bb-8e18-fc93c2fd9544",
    iconSet: "MaterialIcons",
    iconName: "pause-presentation"
  },
  {
    uuid: "062fe515-105d-4ff5-aeba-2c4d44a8c914",
    iconSet: "MaterialIcons",
    iconName: "payment"
  },
  {
    uuid: "fda71346-9bc2-43ac-98bd-4324a6de42b4",
    iconSet: "MaterialIcons",
    iconName: "payments"
  },
  {
    uuid: "6afa5c13-d459-4a47-8d2f-29bc35d12638",
    iconSet: "MaterialIcons",
    iconName: "pedal-bike"
  },
  {
    uuid: "c8e61bf6-d500-4203-829b-7e9130ce7c4b",
    iconSet: "MaterialIcons",
    iconName: "pending"
  },
  {
    uuid: "f06f2c99-8d5c-4bc7-b7b1-7129b2f3e3e8",
    iconSet: "MaterialIcons",
    iconName: "pending-actions"
  },
  {
    uuid: "68f3b3be-5644-4df4-b65d-f2cb88c5b433",
    iconSet: "MaterialIcons",
    iconName: "people"
  },
  {
    uuid: "ca6c4769-3030-45ef-a4d3-418ffa7e2e92",
    iconSet: "MaterialIcons",
    iconName: "people-alt"
  },
  {
    uuid: "d8377611-4488-4eac-9c44-87886d42e333",
    iconSet: "MaterialIcons",
    iconName: "people-outline"
  },
  {
    uuid: "bb287686-2210-402e-802d-eaed82ec3eec",
    iconSet: "MaterialIcons",
    iconName: "perm-camera-mic"
  },
  {
    uuid: "84256098-bac1-4c74-b0e6-487d2885982c",
    iconSet: "MaterialIcons",
    iconName: "perm-contact-cal"
  },
  {
    uuid: "e52643d2-f4e7-47f6-bcd1-a5d666f04b2e",
    iconSet: "MaterialIcons",
    iconName: "perm-contact-calendar"
  },
  {
    uuid: "4352dcbb-cfcf-41de-9b39-af29c0b5c910",
    iconSet: "MaterialIcons",
    iconName: "perm-data-setting"
  },
  {
    uuid: "07dc6432-a058-4cd1-9c96-3c5c5aece7de",
    iconSet: "MaterialIcons",
    iconName: "perm-device-info"
  },
  {
    uuid: "0642a3cb-860a-45c5-9d57-c25f0862a5c2",
    iconSet: "MaterialIcons",
    iconName: "perm-device-information"
  },
  {
    uuid: "04c8d64d-5790-4355-ad58-bed4ff48fcb8",
    iconSet: "MaterialIcons",
    iconName: "perm-identity"
  },
  {
    uuid: "ab386688-d3ff-49f8-8633-d34330ad3819",
    iconSet: "MaterialIcons",
    iconName: "perm-media"
  },
  {
    uuid: "fb882471-f348-4aa7-94b1-3a9ce39c1687",
    iconSet: "MaterialIcons",
    iconName: "perm-phone-msg"
  },
  {
    uuid: "d7257bb4-ab84-4b74-8236-e01dc7122759",
    iconSet: "MaterialIcons",
    iconName: "perm-scan-wifi"
  },
  {
    uuid: "c46f0353-dbf6-412d-9865-7b838aa838f6",
    iconSet: "MaterialIcons",
    iconName: "person"
  },
  {
    uuid: "d826a509-0f96-4cdc-9041-807e76463690",
    iconSet: "MaterialIcons",
    iconName: "person-add"
  },
  {
    uuid: "b41087ba-d9b9-4b30-baf1-8f54badbce8b",
    iconSet: "MaterialIcons",
    iconName: "person-add-alt"
  },
  {
    uuid: "09ea0cfd-1012-468d-a8da-91f6c2f729d4",
    iconSet: "MaterialIcons",
    iconName: "person-add-alt-1"
  },
  {
    uuid: "d0f2d9a9-526f-48f0-bc2e-96dd22b633f7",
    iconSet: "MaterialIcons",
    iconName: "person-add-disabled"
  },
  {
    uuid: "00c6f970-9208-4161-a417-0fc3c344e8d0",
    iconSet: "MaterialIcons",
    iconName: "person-outline"
  },
  {
    uuid: "50e75556-6119-48e4-9519-270528addf6c",
    iconSet: "MaterialIcons",
    iconName: "person-pin"
  },
  {
    uuid: "aca5d655-c959-4381-a657-b379e7be1653",
    iconSet: "MaterialIcons",
    iconName: "person-pin-circle"
  },
  {
    uuid: "3b499e1d-2387-433d-9faa-7955ca04e2f2",
    iconSet: "MaterialIcons",
    iconName: "person-remove"
  },
  {
    uuid: "cacd50ac-66df-4e8b-b88d-b1e8d1a1a04f",
    iconSet: "MaterialIcons",
    iconName: "person-remove-alt-1"
  },
  {
    uuid: "241aaeed-38e9-41ae-ae21-e72e5f80aba6",
    iconSet: "MaterialIcons",
    iconName: "person-search"
  },
  {
    uuid: "66b24abd-9985-4e48-9ba8-18e194e4c134",
    iconSet: "MaterialIcons",
    iconName: "personal-video"
  },
  {
    uuid: "19aecda5-1403-4503-b3a2-4fcc1fa14da1",
    iconSet: "MaterialIcons",
    iconName: "pest-control"
  },
  {
    uuid: "370638c0-d209-444a-ad66-9a3a71ff9d42",
    iconSet: "MaterialIcons",
    iconName: "pest-control-rodent"
  },
  {
    uuid: "7ce0e749-2b82-46bd-bf76-12651b9f8884",
    iconSet: "MaterialIcons",
    iconName: "pets"
  },
  {
    uuid: "f512def9-92a8-4901-977a-36f7ece13c98",
    iconSet: "MaterialIcons",
    iconName: "phone"
  },
  {
    uuid: "0ff2713d-31ec-4a56-b465-3ac4b24a5170",
    iconSet: "MaterialIcons",
    iconName: "phone-android"
  },
  {
    uuid: "9e16e493-6e67-4f8b-b1ca-57011a8f8b4b",
    iconSet: "MaterialIcons",
    iconName: "phone-bluetooth-speaker"
  },
  {
    uuid: "05f8aba9-301a-42ff-bea6-3c81a54150d1",
    iconSet: "MaterialIcons",
    iconName: "phone-callback"
  },
  {
    uuid: "8d2d76e2-ae83-4dfc-95df-7661938e886a",
    iconSet: "MaterialIcons",
    iconName: "phone-disabled"
  },
  {
    uuid: "4f256c9e-688e-4c26-b768-97aef5aae9e0",
    iconSet: "MaterialIcons",
    iconName: "phone-enabled"
  },
  {
    uuid: "5d67de50-026d-4b23-b66d-699354d10cf0",
    iconSet: "MaterialIcons",
    iconName: "phone-forwarded"
  },
  {
    uuid: "191f40f5-7d09-4dcc-82ad-aeb3f6f1feda",
    iconSet: "MaterialIcons",
    iconName: "phone-in-talk"
  },
  {
    uuid: "ec6b955b-93e7-4ea3-aa37-441e29db6050",
    iconSet: "MaterialIcons",
    iconName: "phone-iphone"
  },
  {
    uuid: "b816fe2e-2591-4322-a931-e888d282ee66",
    iconSet: "MaterialIcons",
    iconName: "phone-locked"
  },
  {
    uuid: "28a75719-ff29-4826-a1bd-27013e286aed",
    iconSet: "MaterialIcons",
    iconName: "phone-missed"
  },
  {
    uuid: "caa9fd4c-ad8b-4908-873a-dfbfd0ab4bf4",
    iconSet: "MaterialIcons",
    iconName: "phone-paused"
  },
  {
    uuid: "55cb578e-e328-47e7-b341-66e51918d77b",
    iconSet: "MaterialIcons",
    iconName: "phonelink"
  },
  {
    uuid: "db70db60-ec56-45cf-8fd9-ad44cc1c4481",
    iconSet: "MaterialIcons",
    iconName: "phonelink-erase"
  },
  {
    uuid: "957306f6-88ab-4c7f-9369-c9d83571c1ea",
    iconSet: "MaterialIcons",
    iconName: "phonelink-lock"
  },
  {
    uuid: "b6020d19-3976-4df9-b904-dac2361d8e64",
    iconSet: "MaterialIcons",
    iconName: "phonelink-off"
  },
  {
    uuid: "9702427a-7052-4ba5-baba-67e5f9b31a95",
    iconSet: "MaterialIcons",
    iconName: "phonelink-ring"
  },
  {
    uuid: "1bd80270-d98e-4ece-aba5-765d98fca37e",
    iconSet: "MaterialIcons",
    iconName: "phonelink-setup"
  },
  {
    uuid: "4381b1f6-11ff-49c4-aeed-040be4efb5bf",
    iconSet: "MaterialIcons",
    iconName: "photo"
  },
  {
    uuid: "7877c768-7950-4b49-95c4-60c677245b6f",
    iconSet: "MaterialIcons",
    iconName: "photo-album"
  },
  {
    uuid: "794b38f4-2075-40b8-b884-c7dbfdbf9e38",
    iconSet: "MaterialIcons",
    iconName: "photo-camera"
  },
  {
    uuid: "c50eda8d-a5db-4c9f-ab66-4929ee276d8b",
    iconSet: "MaterialIcons",
    iconName: "photo-camera-back"
  },
  {
    uuid: "caea4363-4126-484b-ae50-4eea1905d7d7",
    iconSet: "MaterialIcons",
    iconName: "photo-camera-front"
  },
  {
    uuid: "c502528e-3c0a-4629-b63b-ba605043589b",
    iconSet: "MaterialIcons",
    iconName: "photo-filter"
  },
  {
    uuid: "3cc7caad-d355-4db5-bcb9-e46b0427bd86",
    iconSet: "MaterialIcons",
    iconName: "photo-library"
  },
  {
    uuid: "d7f4a3e6-771a-4010-86f4-8b1d008aa1ab",
    iconSet: "MaterialIcons",
    iconName: "photo-size-select-actual"
  },
  {
    uuid: "737f6e0a-4a7b-4f6c-8be1-a74f16cdc7fc",
    iconSet: "MaterialIcons",
    iconName: "photo-size-select-large"
  },
  {
    uuid: "9a8ce252-1a4b-486a-92b4-9ba313d23a9d",
    iconSet: "MaterialIcons",
    iconName: "photo-size-select-small"
  },
  {
    uuid: "8e9372bb-20aa-4f6d-af6e-bff6c4dfe50f",
    iconSet: "MaterialIcons",
    iconName: "picture-as-pdf"
  },
  {
    uuid: "e4b0c694-62de-478c-b984-10af25797e49",
    iconSet: "MaterialIcons",
    iconName: "picture-in-picture"
  },
  {
    uuid: "95a5198a-3696-47c4-a322-4583d24e1891",
    iconSet: "MaterialIcons",
    iconName: "picture-in-picture-alt"
  },
  {
    uuid: "5b458f7c-5f1f-444a-847a-907bcd7e65dc",
    iconSet: "MaterialIcons",
    iconName: "pie-chart"
  },
  {
    uuid: "c6ead2cc-3073-4010-8a7f-dbdceba7fdbe",
    iconSet: "MaterialIcons",
    iconName: "pie-chart-outlined"
  },
  {
    uuid: "6cc564c7-4b62-4ae5-80e7-0d41f4b35cf6",
    iconSet: "MaterialIcons",
    iconName: "pin-drop"
  },
  {
    uuid: "62f8cb16-69e2-4fe5-9740-6a7e06955b6d",
    iconSet: "MaterialIcons",
    iconName: "pivot-table-chart"
  },
  {
    uuid: "af3178b6-8b77-48c4-9d15-eed763a86637",
    iconSet: "MaterialIcons",
    iconName: "place"
  },
  {
    uuid: "fd09a30c-a8c8-4da6-9048-99a0cf8a8fdf",
    iconSet: "MaterialIcons",
    iconName: "plagiarism"
  },
  {
    uuid: "4106f3c5-0d0c-41ae-86cf-d77021ee3a6f",
    iconSet: "MaterialIcons",
    iconName: "play-arrow"
  },
  {
    uuid: "51339cb3-f152-43ab-8511-294c9d168c0e",
    iconSet: "MaterialIcons",
    iconName: "play-circle-fill"
  },
  {
    uuid: "d7a408ee-24f5-46cc-8d44-a603eb622a72",
    iconSet: "MaterialIcons",
    iconName: "play-circle-filled"
  },
  {
    uuid: "e3e93b8e-8e97-46cf-b7c8-e5cacb656a38",
    iconSet: "MaterialIcons",
    iconName: "play-circle-outline"
  },
  {
    uuid: "daebbb33-0451-419a-9982-79eec7d8e3f3",
    iconSet: "MaterialIcons",
    iconName: "play-disabled"
  },
  {
    uuid: "b7c6f90a-9a66-41a5-9f9a-b221896d1426",
    iconSet: "MaterialIcons",
    iconName: "play-for-work"
  },
  {
    uuid: "0b4c9405-5807-41e3-94e0-c917845d1309",
    iconSet: "MaterialIcons",
    iconName: "playlist-add"
  },
  {
    uuid: "396e665f-86fe-4718-9742-35909aed507a",
    iconSet: "MaterialIcons",
    iconName: "playlist-add-check"
  },
  {
    uuid: "4b81fd04-3271-4374-8a8b-b31a92e0a81c",
    iconSet: "MaterialIcons",
    iconName: "playlist-play"
  },
  {
    uuid: "48f0d403-0381-43e5-b865-bf5121880158",
    iconSet: "MaterialIcons",
    iconName: "plumbing"
  },
  {
    uuid: "671bad91-38f6-4154-80a9-6ed229e2f351",
    iconSet: "MaterialIcons",
    iconName: "plus-one"
  },
  {
    uuid: "3cf84670-5d3c-427c-ae17-4671b3ec556f",
    iconSet: "MaterialIcons",
    iconName: "point-of-sale"
  },
  {
    uuid: "690ffaa7-8bb0-466a-9420-5bd84f60916b",
    iconSet: "MaterialIcons",
    iconName: "policy"
  },
  {
    uuid: "5aa910da-23da-4382-b109-6cfa02d374ff",
    iconSet: "MaterialIcons",
    iconName: "poll"
  },
  {
    uuid: "30f4e4f6-2eb5-427f-ac6e-7ebc16b5fd74",
    iconSet: "MaterialIcons",
    iconName: "polymer"
  },
  {
    uuid: "20433ae1-ec49-4612-a0fe-cffcc9e8ff0c",
    iconSet: "MaterialIcons",
    iconName: "pool"
  },
  {
    uuid: "0cfc5f46-d504-47f9-91af-4b42ea4257aa",
    iconSet: "MaterialIcons",
    iconName: "portable-wifi-off"
  },
  {
    uuid: "36a667c2-7bbc-4eac-9709-ffd740a828f6",
    iconSet: "MaterialIcons",
    iconName: "portrait"
  },
  {
    uuid: "c7fcdb43-4c6a-4046-9f60-a3fa31d1a5f6",
    iconSet: "MaterialIcons",
    iconName: "post-add"
  },
  {
    uuid: "9e688d44-4287-433a-9c6b-d095e7e01474",
    iconSet: "MaterialIcons",
    iconName: "power"
  },
  {
    uuid: "65bc2fc7-d487-4a1d-99e3-54141e8c6500",
    iconSet: "MaterialIcons",
    iconName: "power-input"
  },
  {
    uuid: "2f872459-9581-47bc-8fd2-8cc9577b4eee",
    iconSet: "MaterialIcons",
    iconName: "power-off"
  },
  {
    uuid: "3a034679-de20-42ff-96a2-a42a64017974",
    iconSet: "MaterialIcons",
    iconName: "power-settings-new"
  },
  {
    uuid: "1b3930bd-8a0d-40d6-a368-6a0df2a98801",
    iconSet: "MaterialIcons",
    iconName: "pregnant-woman"
  },
  {
    uuid: "e2386b8c-d293-4665-84f8-82cfbeb669cc",
    iconSet: "MaterialIcons",
    iconName: "present-to-all"
  },
  {
    uuid: "75052aef-24e1-47f3-a1ff-db3ceaacce67",
    iconSet: "MaterialIcons",
    iconName: "preview"
  },
  {
    uuid: "90ddb60f-f9dd-4aaa-87a8-fd420eedb976",
    iconSet: "MaterialIcons",
    iconName: "print"
  },
  {
    uuid: "3938281b-6fc7-48d1-83ab-d0bc678d4d20",
    iconSet: "MaterialIcons",
    iconName: "print-disabled"
  },
  {
    uuid: "32ef27f5-7f0c-49ee-a6b1-9405ff0be965",
    iconSet: "MaterialIcons",
    iconName: "priority-high"
  },
  {
    uuid: "c3561f02-5f72-4b57-a3f3-65b882acfc6c",
    iconSet: "MaterialIcons",
    iconName: "privacy-tip"
  },
  {
    uuid: "d7f6c450-bf96-44f4-9d60-b4c7da5ffe63",
    iconSet: "MaterialIcons",
    iconName: "psychology"
  },
  {
    uuid: "a66829e9-0120-4e2e-8eae-e75b03c73657",
    iconSet: "MaterialIcons",
    iconName: "public"
  },
  {
    uuid: "804a6f4e-444a-4b70-af39-6d8cd47fbacf",
    iconSet: "MaterialIcons",
    iconName: "public-off"
  },
  {
    uuid: "95103e4e-1a50-4f23-b3d2-facb0153dd23",
    iconSet: "MaterialIcons",
    iconName: "publish"
  },
  {
    uuid: "bec47667-5ac9-4fd4-819d-e4d3da3973a5",
    iconSet: "MaterialIcons",
    iconName: "published-with-changes"
  },
  {
    uuid: "798ea46f-b522-477d-b03f-e7bf65a0d7a5",
    iconSet: "MaterialIcons",
    iconName: "push-pin"
  },
  {
    uuid: "527b3e47-73a4-4e34-bb95-d3bff846265f",
    iconSet: "MaterialIcons",
    iconName: "qr-code"
  },
  {
    uuid: "5be306b2-bde3-45c3-974e-e1516ab8167f",
    iconSet: "MaterialIcons",
    iconName: "qr-code-2"
  },
  {
    uuid: "184aa334-8187-49a5-9713-f3b02e9277be",
    iconSet: "MaterialIcons",
    iconName: "qr-code-scanner"
  },
  {
    uuid: "1a91c40e-a92b-41cb-854a-63cfcb6174da",
    iconSet: "MaterialIcons",
    iconName: "query-builder"
  },
  {
    uuid: "47724a2e-6a67-49d5-a929-b5d44323bbdd",
    iconSet: "MaterialIcons",
    iconName: "question-answer"
  },
  {
    uuid: "fd69137e-7e50-4736-b264-5a845946e55f",
    iconSet: "MaterialIcons",
    iconName: "queue"
  },
  {
    uuid: "386dbb37-3c72-42b0-82cf-47d4490d0334",
    iconSet: "MaterialIcons",
    iconName: "queue-music"
  },
  {
    uuid: "d4dacf50-810d-47c9-a36e-9c7e4617bfb2",
    iconSet: "MaterialIcons",
    iconName: "queue-play-next"
  },
  {
    uuid: "3d585f88-67a4-44d5-89b5-9902766bf1b0",
    iconSet: "MaterialIcons",
    iconName: "quick-contacts-dialer"
  },
  {
    uuid: "8028f911-946a-4f15-994a-131c87baa1be",
    iconSet: "MaterialIcons",
    iconName: "quick-contacts-mail"
  },
  {
    uuid: "f737b24b-69d7-466b-be3b-723ca88535c8",
    iconSet: "MaterialIcons",
    iconName: "quickreply"
  },
  {
    uuid: "459bb578-d212-436a-a0a0-e31aad2fa2b5",
    iconSet: "MaterialIcons",
    iconName: "radio"
  },
  {
    uuid: "21b7e657-d345-4fdf-956b-375eb896220f",
    iconSet: "MaterialIcons",
    iconName: "radio-button-checked"
  },
  {
    uuid: "b3c46ca5-8078-4a75-8279-5d3f5d92817d",
    iconSet: "MaterialIcons",
    iconName: "radio-button-off"
  },
  {
    uuid: "eaa832e8-5e08-4da0-adcc-638149b0164b",
    iconSet: "MaterialIcons",
    iconName: "radio-button-on"
  },
  {
    uuid: "c931de5b-d74b-47c5-911d-238361ffc2a6",
    iconSet: "MaterialIcons",
    iconName: "radio-button-unchecked"
  },
  {
    uuid: "b719bf9b-5d4a-4971-83a1-37059dbc0609",
    iconSet: "MaterialIcons",
    iconName: "railway-alert"
  },
  {
    uuid: "debd73fb-6987-486b-97f1-d2d562648928",
    iconSet: "MaterialIcons",
    iconName: "ramen-dining"
  },
  {
    uuid: "1f8f7758-ad98-47a1-8890-648456252c3c",
    iconSet: "MaterialIcons",
    iconName: "rate-review"
  },
  {
    uuid: "2347d8a4-1551-4e71-a1f9-c8009dd03b5a",
    iconSet: "MaterialIcons",
    iconName: "read-more"
  },
  {
    uuid: "ddac0585-20a4-4db9-b9f7-d564b6244f81",
    iconSet: "MaterialIcons",
    iconName: "receipt"
  },
  {
    uuid: "e4a3d78a-5abe-41ae-a6e4-8cbee9bff69e",
    iconSet: "MaterialIcons",
    iconName: "receipt-long"
  },
  {
    uuid: "55e8b468-2943-4325-aee7-8268e31c3fa0",
    iconSet: "MaterialIcons",
    iconName: "recent-actors"
  },
  {
    uuid: "8b080de1-cea0-4aac-b5ed-205a586cb82f",
    iconSet: "MaterialIcons",
    iconName: "recommend"
  },
  {
    uuid: "67d4b939-46dd-427d-8f02-704fb42d5858",
    iconSet: "MaterialIcons",
    iconName: "record-voice-over"
  },
  {
    uuid: "4f03d08b-5ccc-47e2-9142-db0b281bdc37",
    iconSet: "MaterialIcons",
    iconName: "redeem"
  },
  {
    uuid: "67b9fa2c-3f8c-4e04-aae6-8a987d01417b",
    iconSet: "MaterialIcons",
    iconName: "redo"
  },
  {
    uuid: "75d862a0-ccf7-4ba7-924e-85a7428cd16b",
    iconSet: "MaterialIcons",
    iconName: "reduce-capacity"
  },
  {
    uuid: "9ee6b058-9506-4480-8e5f-0ececfe8d876",
    iconSet: "MaterialIcons",
    iconName: "refresh"
  },
  {
    uuid: "9e39d4c6-56b9-4a9c-b743-99ffad1b89cf",
    iconSet: "MaterialIcons",
    iconName: "remove"
  },
  {
    uuid: "b498dac0-cddd-4005-947d-2a4250185133",
    iconSet: "MaterialIcons",
    iconName: "remove-circle"
  },
  {
    uuid: "568c60e7-1186-48d9-936a-39a68dba70e6",
    iconSet: "MaterialIcons",
    iconName: "remove-circle-outline"
  },
  {
    uuid: "254fd1fe-23e7-44e1-bece-dac042087e32",
    iconSet: "MaterialIcons",
    iconName: "remove-done"
  },
  {
    uuid: "4b3721c5-d7ce-4def-945a-4065fb957e32",
    iconSet: "MaterialIcons",
    iconName: "remove-from-queue"
  },
  {
    uuid: "149bdeb6-8398-4ba3-8460-f67a579d208e",
    iconSet: "MaterialIcons",
    iconName: "remove-moderator"
  },
  {
    uuid: "83e3d6b2-4383-4ce1-9f3c-eaeec263410f",
    iconSet: "MaterialIcons",
    iconName: "remove-red-eye"
  },
  {
    uuid: "f8e3998e-bd53-4198-be38-6dd34affe8ae",
    iconSet: "MaterialIcons",
    iconName: "remove-shopping-cart"
  },
  {
    uuid: "848ca8d8-b8c9-4ae4-a365-049218098dc0",
    iconSet: "MaterialIcons",
    iconName: "reorder"
  },
  {
    uuid: "2400e220-73bf-458c-8a1b-7e534bd61916",
    iconSet: "MaterialIcons",
    iconName: "repeat"
  },
  {
    uuid: "c7acd30e-0ad9-4470-b777-293898edabc7",
    iconSet: "MaterialIcons",
    iconName: "repeat-on"
  },
  {
    uuid: "907bf4ac-b723-443f-be36-13506c2fe286",
    iconSet: "MaterialIcons",
    iconName: "repeat-one"
  },
  {
    uuid: "51a22eaa-c8c8-45e8-afb1-ad6c90b56f70",
    iconSet: "MaterialIcons",
    iconName: "repeat-one-on"
  },
  {
    uuid: "01d7f58e-1265-492d-8acb-0b76d64ee73b",
    iconSet: "MaterialIcons",
    iconName: "replay"
  },
  {
    uuid: "9d7aedfa-754c-4532-8a69-d70ac3b692db",
    iconSet: "MaterialIcons",
    iconName: "replay-10"
  },
  {
    uuid: "c8228380-88f4-4ec5-b8aa-94602d8a842b",
    iconSet: "MaterialIcons",
    iconName: "replay-30"
  },
  {
    uuid: "8082cdf9-f610-46a6-bc9f-7d0a1c3d6dcf",
    iconSet: "MaterialIcons",
    iconName: "replay-5"
  },
  {
    uuid: "e35b22c3-8b0f-4cd8-a033-d5d36303ebec",
    iconSet: "MaterialIcons",
    iconName: "replay-circle-filled"
  },
  {
    uuid: "caff3463-af84-4cb5-b7b9-b951b3c26ca6",
    iconSet: "MaterialIcons",
    iconName: "reply"
  },
  {
    uuid: "1a254282-1733-4c08-a2ad-dc1565ffe522",
    iconSet: "MaterialIcons",
    iconName: "reply-all"
  },
  {
    uuid: "2a12c89d-a8b0-41cf-ba28-d545aa4ecd4a",
    iconSet: "MaterialIcons",
    iconName: "report"
  },
  {
    uuid: "376e436a-5a75-4430-9b60-8a0599df46c0",
    iconSet: "MaterialIcons",
    iconName: "report-off"
  },
  {
    uuid: "2eeb0029-9a4c-448b-8da4-23a34cbe28cb",
    iconSet: "MaterialIcons",
    iconName: "report-problem"
  },
  {
    uuid: "74bf7908-d193-4445-a8fb-b0bba99548b6",
    iconSet: "MaterialIcons",
    iconName: "request-page"
  },
  {
    uuid: "6499a2cc-e4b6-4fa5-8431-722073aed8ab",
    iconSet: "MaterialIcons",
    iconName: "request-quote"
  },
  {
    uuid: "fc3b2545-5261-4f45-afe1-98220e8a1395",
    iconSet: "MaterialIcons",
    iconName: "reset-tv"
  },
  {
    uuid: "16d1a7db-dbe1-4658-8995-8cff1aa050a1",
    iconSet: "MaterialIcons",
    iconName: "restaurant"
  },
  {
    uuid: "9bedc6b4-f6df-483a-94f8-33cd40c030b3",
    iconSet: "MaterialIcons",
    iconName: "restaurant-menu"
  },
  {
    uuid: "c42fc669-6dfc-4ca3-8714-378c16653024",
    iconSet: "MaterialIcons",
    iconName: "restore"
  },
  {
    uuid: "10ae1fc5-5c35-4580-a7d0-9951ed3e5ce0",
    iconSet: "MaterialIcons",
    iconName: "restore-from-trash"
  },
  {
    uuid: "da1adc1b-43eb-4cce-996b-a783b75ba8fe",
    iconSet: "MaterialIcons",
    iconName: "restore-page"
  },
  {
    uuid: "2b8efbfc-ad2c-42ab-be24-3ca64ca2f55e",
    iconSet: "MaterialIcons",
    iconName: "rice-bowl"
  },
  {
    uuid: "1e667da5-c483-4c53-a0a6-0dcc0272c79b",
    iconSet: "MaterialIcons",
    iconName: "ring-volume"
  },
  {
    uuid: "ce65f3b5-220c-4464-a373-ecc56d659f1a",
    iconSet: "MaterialIcons",
    iconName: "roofing"
  },
  {
    uuid: "a601b186-43e2-49df-84ed-38cf2c834a17",
    iconSet: "MaterialIcons",
    iconName: "room"
  },
  {
    uuid: "e0acd779-e133-40fa-91ff-a278e5c57cdf",
    iconSet: "MaterialIcons",
    iconName: "room-preferences"
  },
  {
    uuid: "870463e1-ceb7-4a92-94bc-9584addc7c20",
    iconSet: "MaterialIcons",
    iconName: "room-service"
  },
  {
    uuid: "9d8c900e-e1db-448c-b904-bacf60e1468f",
    iconSet: "MaterialIcons",
    iconName: "rotate-90-degrees-ccw"
  },
  {
    uuid: "6c71051d-2f0e-4541-8b3e-1d0f0d243622",
    iconSet: "MaterialIcons",
    iconName: "rotate-left"
  },
  {
    uuid: "0be8ccc0-6e39-4884-ac5b-7ec00a6e66c3",
    iconSet: "MaterialIcons",
    iconName: "rotate-right"
  },
  {
    uuid: "4f838e04-e028-4258-a0d4-d2e9fef913ee",
    iconSet: "MaterialIcons",
    iconName: "rounded-corner"
  },
  {
    uuid: "16b703fc-a99d-44e4-9f06-92d71b592d2f",
    iconSet: "MaterialIcons",
    iconName: "router"
  },
  {
    uuid: "39ad1299-b5db-459e-b342-ceb486d9a6cd",
    iconSet: "MaterialIcons",
    iconName: "rowing"
  },
  {
    uuid: "637f9c64-c891-4047-b274-a1cb02de11d0",
    iconSet: "MaterialIcons",
    iconName: "rss-feed"
  },
  {
    uuid: "adc4fd01-1e02-4511-8bf9-b34429d40657",
    iconSet: "MaterialIcons",
    iconName: "rtt"
  },
  {
    uuid: "61ad1b53-5e19-4017-aa64-a83d0a1456ca",
    iconSet: "MaterialIcons",
    iconName: "rule"
  },
  {
    uuid: "826e434a-062b-4e33-85d9-2a34970a32ba",
    iconSet: "MaterialIcons",
    iconName: "rule-folder"
  },
  {
    uuid: "87006164-f1eb-4d7c-83d4-beab61c8e804",
    iconSet: "MaterialIcons",
    iconName: "run-circle"
  },
  {
    uuid: "6aed5a8c-9e0b-4d04-a9e3-58b616d77604",
    iconSet: "MaterialIcons",
    iconName: "rv-hookup"
  },
  {
    uuid: "3c5eb5e8-d77b-450e-aea0-94aebe3f4ea7",
    iconSet: "MaterialIcons",
    iconName: "sanitizer"
  },
  {
    uuid: "58600ad3-ba80-410d-8832-171ff8795eff",
    iconSet: "MaterialIcons",
    iconName: "satellite"
  },
  {
    uuid: "bcdbb8e9-15c3-49ed-81bd-f6ba385d0b60",
    iconSet: "MaterialIcons",
    iconName: "save"
  },
  {
    uuid: "55ed5951-13fb-401a-9034-288603c9062c",
    iconSet: "MaterialIcons",
    iconName: "save-alt"
  },
  {
    uuid: "81c61120-41ad-40e0-a6ec-a9b514930f11",
    iconSet: "MaterialIcons",
    iconName: "saved-search"
  },
  {
    uuid: "b7a9f8d3-5f78-42b8-a8fa-e31fd89b4a37",
    iconSet: "MaterialIcons",
    iconName: "scanner"
  },
  {
    uuid: "2d9c3f02-a4d1-4372-aadc-53b415d917a4",
    iconSet: "MaterialIcons",
    iconName: "scatter-plot"
  },
  {
    uuid: "90dc5838-0324-499d-8e70-b0c5f2d8a863",
    iconSet: "MaterialIcons",
    iconName: "schedule"
  },
  {
    uuid: "ba5fd7f5-325f-42cf-bf04-5f03c7e55121",
    iconSet: "MaterialIcons",
    iconName: "schedule-send"
  },
  {
    uuid: "86c456cf-82e4-4cef-8a4f-7708644f7c6c",
    iconSet: "MaterialIcons",
    iconName: "school"
  },
  {
    uuid: "87d8a97a-5ac3-4ff1-b280-16acd18a1b30",
    iconSet: "MaterialIcons",
    iconName: "science"
  },
  {
    uuid: "553253e0-eb5b-4806-be1d-c32f93cd56a7",
    iconSet: "MaterialIcons",
    iconName: "score"
  },
  {
    uuid: "4a613409-c0f9-49dc-a947-8519c4bea1f7",
    iconSet: "MaterialIcons",
    iconName: "screen-lock-landscape"
  },
  {
    uuid: "66a5e830-4360-424a-9aa8-0560179415bf",
    iconSet: "MaterialIcons",
    iconName: "screen-lock-portrait"
  },
  {
    uuid: "9de01acc-18d8-4f8a-a881-337c5f9946df",
    iconSet: "MaterialIcons",
    iconName: "screen-lock-rotation"
  },
  {
    uuid: "e4af0e2b-f1af-4239-ba7e-80243a875495",
    iconSet: "MaterialIcons",
    iconName: "screen-rotation"
  },
  {
    uuid: "8fb292ae-1839-4d1d-956f-e4aac00bd427",
    iconSet: "MaterialIcons",
    iconName: "screen-search-desktop"
  },
  {
    uuid: "66c2eea7-e76a-4a89-b609-ccfde49130a8",
    iconSet: "MaterialIcons",
    iconName: "screen-share"
  },
  {
    uuid: "5b1e31df-0aaa-4ced-b932-fedabaca71bd",
    iconSet: "MaterialIcons",
    iconName: "sd"
  },
  {
    uuid: "d7d8c927-878b-4c88-9261-134b41b11fde",
    iconSet: "MaterialIcons",
    iconName: "sd-card"
  },
  {
    uuid: "462b8cc2-8cf4-4948-9c64-4ce928d18c48",
    iconSet: "MaterialIcons",
    iconName: "sd-storage"
  },
  {
    uuid: "f12294fe-fd8e-47bc-af4b-310f485b9523",
    iconSet: "MaterialIcons",
    iconName: "search"
  },
  {
    uuid: "af5d1af8-c052-46cb-939e-d10f43ddf6c6",
    iconSet: "MaterialIcons",
    iconName: "search-off"
  },
  {
    uuid: "c925cc11-e38f-4bfa-9dd1-7f5cd899ffca",
    iconSet: "MaterialIcons",
    iconName: "security"
  },
  {
    uuid: "ee5be66f-2b95-4c95-a8a5-364fdc4aeaa9",
    iconSet: "MaterialIcons",
    iconName: "segment"
  },
  {
    uuid: "040d4a77-9a0b-4308-a094-a3df42c8a373",
    iconSet: "MaterialIcons",
    iconName: "select-all"
  },
  {
    uuid: "55f52bf0-1865-44dc-b4ad-dc42e6527904",
    iconSet: "MaterialIcons",
    iconName: "self-improvement"
  },
  {
    uuid: "762a6221-15de-4eec-a3bf-5c88d354626b",
    iconSet: "MaterialIcons",
    iconName: "send"
  },
  {
    uuid: "250a05a5-badf-4c55-9e0c-0771735b2177",
    iconSet: "MaterialIcons",
    iconName: "send-and-archive"
  },
  {
    uuid: "ff0bfce0-ab5f-4568-b9ab-4464b662572c",
    iconSet: "MaterialIcons",
    iconName: "send-to-mobile"
  },
  {
    uuid: "d6cea5e9-24b3-4c59-afd6-5d80b64085c5",
    iconSet: "MaterialIcons",
    iconName: "sensor-door"
  },
  {
    uuid: "7ab135c8-8d35-4d3b-823f-4e8daf078807",
    iconSet: "MaterialIcons",
    iconName: "sensor-window"
  },
  {
    uuid: "a4267c6f-e121-46df-bca7-67fc47f5af2a",
    iconSet: "MaterialIcons",
    iconName: "sentiment-dissatisfied"
  },
  {
    uuid: "2a7d0391-fa4e-427d-b222-fd3e7d6fcdd6",
    iconSet: "MaterialIcons",
    iconName: "sentiment-neutral"
  },
  {
    uuid: "3f7ecede-89f6-4a23-980b-cd6cc50ebec0",
    iconSet: "MaterialIcons",
    iconName: "sentiment-satisfied"
  },
  {
    uuid: "b9d59ede-9e3f-4117-9d00-29f3682c7da9",
    iconSet: "MaterialIcons",
    iconName: "sentiment-satisfied-alt"
  },
  {
    uuid: "018ba9d2-89cb-4eb7-ba55-203fd67e1cee",
    iconSet: "MaterialIcons",
    iconName: "sentiment-very-dissatisfied"
  },
  {
    uuid: "a7b9365c-54a0-4269-89ea-7404f6e4eb48",
    iconSet: "MaterialIcons",
    iconName: "sentiment-very-satisfied"
  },
  {
    uuid: "a505dbad-bb5e-4d83-8468-698b8434281e",
    iconSet: "MaterialIcons",
    iconName: "set-meal"
  },
  {
    uuid: "2b70eede-b4f1-4822-968b-6d8355854e65",
    iconSet: "MaterialIcons",
    iconName: "settings"
  },
  {
    uuid: "3ef56fd9-296c-4922-b192-07cedc608240",
    iconSet: "MaterialIcons",
    iconName: "settings-applications"
  },
  {
    uuid: "1dd20654-5f6d-48f8-8526-9f48972b921f",
    iconSet: "MaterialIcons",
    iconName: "settings-backup-restore"
  },
  {
    uuid: "74a1ce7d-137b-4ef8-9612-8ecf2b5f58f3",
    iconSet: "MaterialIcons",
    iconName: "settings-bluetooth"
  },
  {
    uuid: "1b3d62b2-d436-45d4-bd90-f4b63a3b1b4d",
    iconSet: "MaterialIcons",
    iconName: "settings-brightness"
  },
  {
    uuid: "1520a60d-39d4-405e-b1fb-b41ef1187b22",
    iconSet: "MaterialIcons",
    iconName: "settings-cell"
  },
  {
    uuid: "5d9e6c9d-e2c5-40f1-81cd-66a76fc3b14c",
    iconSet: "MaterialIcons",
    iconName: "settings-display"
  },
  {
    uuid: "be33843c-ffab-495d-9d7c-cdce4e8abbdb",
    iconSet: "MaterialIcons",
    iconName: "settings-ethernet"
  },
  {
    uuid: "11d08c94-caf2-4b0a-b51d-1e56fdd8fb17",
    iconSet: "MaterialIcons",
    iconName: "settings-input-antenna"
  },
  {
    uuid: "5070e977-a696-4604-9114-a61f624f332d",
    iconSet: "MaterialIcons",
    iconName: "settings-input-component"
  },
  {
    uuid: "f1de3d68-3bb1-45d3-9e6c-bf08269bfb1e",
    iconSet: "MaterialIcons",
    iconName: "settings-input-composite"
  },
  {
    uuid: "ab615bf3-77db-41a3-ab7c-4b4e08aa89bf",
    iconSet: "MaterialIcons",
    iconName: "settings-input-hdmi"
  },
  {
    uuid: "f4322082-6e7e-46d7-9beb-c7a6ed8bcd8b",
    iconSet: "MaterialIcons",
    iconName: "settings-input-svideo"
  },
  {
    uuid: "c1f24ddc-f2f0-413c-a22c-194b3468ef55",
    iconSet: "MaterialIcons",
    iconName: "settings-overscan"
  },
  {
    uuid: "45b3e518-f0e1-4b43-b809-56394969ee72",
    iconSet: "MaterialIcons",
    iconName: "settings-phone"
  },
  {
    uuid: "8a3b1c2f-5390-42da-848b-7dc6d4e74d9e",
    iconSet: "MaterialIcons",
    iconName: "settings-power"
  },
  {
    uuid: "e115bdf3-04e6-4f17-b709-0bad908ca808",
    iconSet: "MaterialIcons",
    iconName: "settings-remote"
  },
  {
    uuid: "11e94a87-ad31-45f7-b36b-208f278323bf",
    iconSet: "MaterialIcons",
    iconName: "settings-system-daydream"
  },
  {
    uuid: "59cbe366-36d4-4d58-bc31-6e130bf00aaf",
    iconSet: "MaterialIcons",
    iconName: "settings-voice"
  },
  {
    uuid: "1fe939a8-a325-47d4-9270-55d75537ce01",
    iconSet: "MaterialIcons",
    iconName: "share"
  },
  {
    uuid: "b47208af-ef6b-46a0-acbe-5fec146afd06",
    iconSet: "MaterialIcons",
    iconName: "shield"
  },
  {
    uuid: "bba8b2f4-7989-4f1e-b9b2-2142e610660b",
    iconSet: "MaterialIcons",
    iconName: "shop"
  },
  {
    uuid: "91ce1f2c-9e99-4482-ab80-b96b6d77232b",
    iconSet: "MaterialIcons",
    iconName: "shop-two"
  },
  {
    uuid: "e08eecab-f702-4e53-b850-cce81a9b3320",
    iconSet: "MaterialIcons",
    iconName: "shopping-bag"
  },
  {
    uuid: "d3735eea-c42a-4953-a684-5a2f136acdee",
    iconSet: "MaterialIcons",
    iconName: "shopping-basket"
  },
  {
    uuid: "ddb366d6-b3f8-46b8-b1c7-c1327c373c1d",
    iconSet: "MaterialIcons",
    iconName: "shopping-cart"
  },
  {
    uuid: "33afedab-b6e7-4b2a-aacc-7e66d6d15a5f",
    iconSet: "MaterialIcons",
    iconName: "short-text"
  },
  {
    uuid: "512dad5f-042f-43e0-943e-401e80feebee",
    iconSet: "MaterialIcons",
    iconName: "show-chart"
  },
  {
    uuid: "33be2f21-2a75-42b5-a255-c3d81f22c6eb",
    iconSet: "MaterialIcons",
    iconName: "shuffle"
  },
  {
    uuid: "176f3758-31a8-4211-b604-7fe40816c657",
    iconSet: "MaterialIcons",
    iconName: "shuffle-on"
  },
  {
    uuid: "eceaa948-d24c-4d4e-a618-ed313a44034f",
    iconSet: "MaterialIcons",
    iconName: "shutter-speed"
  },
  {
    uuid: "45e2e25c-e80a-42b3-8ab9-2f2ddfd97635",
    iconSet: "MaterialIcons",
    iconName: "sick"
  },
  {
    uuid: "9fc4a2f8-387d-4d76-978d-bd40e8a71086",
    iconSet: "MaterialIcons",
    iconName: "signal-cellular-0-bar"
  },
  {
    uuid: "402c4c96-6928-4b20-bbba-b937b182ef5d",
    iconSet: "MaterialIcons",
    iconName: "signal-cellular-4-bar"
  },
  {
    uuid: "f1476ad0-5358-4bf5-a8be-3fa7f415eb23",
    iconSet: "MaterialIcons",
    iconName: "signal-cellular-alt"
  },
  {
    uuid: "e57439b8-9a12-4012-b348-f59b104924d1",
    iconSet: "MaterialIcons",
    iconName: "signal-cellular-connected-no-internet-4-bar"
  },
  {
    uuid: "a9514da4-aba9-48c6-9efd-6aac78d640e3",
    iconSet: "MaterialIcons",
    iconName: "signal-cellular-no-sim"
  },
  {
    uuid: "1c557e5a-da5f-4958-9d8a-8dd72758eff1",
    iconSet: "MaterialIcons",
    iconName: "signal-cellular-null"
  },
  {
    uuid: "2bc8449e-3f96-4fff-a936-2976223ed7ef",
    iconSet: "MaterialIcons",
    iconName: "signal-cellular-off"
  },
  {
    uuid: "e7e19a5f-278b-4d55-9fc1-1f3fd121aaa0",
    iconSet: "MaterialIcons",
    iconName: "signal-wifi-0-bar"
  },
  {
    uuid: "c5eeeaaa-a851-4157-90c7-8cac7582fc26",
    iconSet: "MaterialIcons",
    iconName: "signal-wifi-4-bar"
  },
  {
    uuid: "2169737c-b109-4629-b8ac-8180dd5a61df",
    iconSet: "MaterialIcons",
    iconName: "signal-wifi-4-bar-lock"
  },
  {
    uuid: "626fda7f-e616-426f-8e2c-6ef9947468e3",
    iconSet: "MaterialIcons",
    iconName: "signal-wifi-off"
  },
  {
    uuid: "32dbcf34-6c8b-4035-9b90-5c939b4bff1a",
    iconSet: "MaterialIcons",
    iconName: "sim-card"
  },
  {
    uuid: "27e7af61-ea70-4c8f-8300-43dadb2e7b2c",
    iconSet: "MaterialIcons",
    iconName: "sim-card-alert"
  },
  {
    uuid: "626fe71b-ac8c-4481-9ce9-71f723b9143f",
    iconSet: "MaterialIcons",
    iconName: "single-bed"
  },
  {
    uuid: "bbb3a38a-73ab-4231-8242-edb9d3c1d110",
    iconSet: "MaterialIcons",
    iconName: "skip-next"
  },
  {
    uuid: "21d42b06-25f3-439d-addb-23d635f17445",
    iconSet: "MaterialIcons",
    iconName: "skip-previous"
  },
  {
    uuid: "46ab3c9a-6fd1-4ea9-a2ea-0cda32733578",
    iconSet: "MaterialIcons",
    iconName: "slideshow"
  },
  {
    uuid: "51da33aa-06f2-4941-9df7-a551042ccd1c",
    iconSet: "MaterialIcons",
    iconName: "slow-motion-video"
  },
  {
    uuid: "7a770d66-feda-4902-b6c3-8a0cba8f4410",
    iconSet: "MaterialIcons",
    iconName: "smart-button"
  },
  {
    uuid: "50216031-0345-4b54-a26a-c909394457c2",
    iconSet: "MaterialIcons",
    iconName: "smartphone"
  },
  {
    uuid: "03e13f45-e533-4055-b313-b71c63bc4368",
    iconSet: "MaterialIcons",
    iconName: "smoke-free"
  },
  {
    uuid: "51965f1d-79ce-4357-afd9-af91358c4b0f",
    iconSet: "MaterialIcons",
    iconName: "smoking-rooms"
  },
  {
    uuid: "805cf3fe-3acd-4d9e-b1a1-f1a57b67341e",
    iconSet: "MaterialIcons",
    iconName: "sms"
  },
  {
    uuid: "e59b58e9-3ddb-4a71-9991-1b0e7e51cb3b",
    iconSet: "MaterialIcons",
    iconName: "sms-failed"
  },
  {
    uuid: "691db95a-1c52-4bc2-a4aa-2bab48116317",
    iconSet: "MaterialIcons",
    iconName: "snippet-folder"
  },
  {
    uuid: "5ecf2407-b04b-482b-a2df-b3944ea8e58f",
    iconSet: "MaterialIcons",
    iconName: "snooze"
  },
  {
    uuid: "cf93f450-c95c-4772-8d0e-868461d4fb0d",
    iconSet: "MaterialIcons",
    iconName: "soap"
  },
  {
    uuid: "2902b697-63d7-46f6-ab1d-ee671083442d",
    iconSet: "MaterialIcons",
    iconName: "sort"
  },
  {
    uuid: "9f665ef1-056d-41b5-86c8-f7d57d884b65",
    iconSet: "MaterialIcons",
    iconName: "sort-by-alpha"
  },
  {
    uuid: "04696014-bccf-4f47-a5f4-39af34008e83",
    iconSet: "MaterialIcons",
    iconName: "source"
  },
  {
    uuid: "a62d3433-de6e-4b61-951c-b61ca330698a",
    iconSet: "MaterialIcons",
    iconName: "south"
  },
  {
    uuid: "e5375fee-f6aa-4cef-9371-8a79ff9dcb15",
    iconSet: "MaterialIcons",
    iconName: "south-east"
  },
  {
    uuid: "24b21809-3143-4a14-a2a4-d51bb090cc8a",
    iconSet: "MaterialIcons",
    iconName: "south-west"
  },
  {
    uuid: "e9606c05-3f7d-4fee-9e47-da25ea9873bb",
    iconSet: "MaterialIcons",
    iconName: "spa"
  },
  {
    uuid: "6340e20b-a2c2-4a03-a04c-0a7a3e516732",
    iconSet: "MaterialIcons",
    iconName: "space-bar"
  },
  {
    uuid: "a55fe6c5-4633-45a7-8410-7e8e4dc709a2",
    iconSet: "MaterialIcons",
    iconName: "speaker"
  },
  {
    uuid: "42e7a677-e7ef-4801-bd66-8262cac53909",
    iconSet: "MaterialIcons",
    iconName: "speaker-group"
  },
  {
    uuid: "c672a411-ad0f-4159-af68-ddda40a74911",
    iconSet: "MaterialIcons",
    iconName: "speaker-notes"
  },
  {
    uuid: "c92525b4-858d-480d-9249-431e9f852cea",
    iconSet: "MaterialIcons",
    iconName: "speaker-notes-off"
  },
  {
    uuid: "6ffe88df-d009-44b7-95c5-b5c7ddb8d09b",
    iconSet: "MaterialIcons",
    iconName: "speaker-phone"
  },
  {
    uuid: "da3a60d1-338a-4f72-96fa-383af32f4e15",
    iconSet: "MaterialIcons",
    iconName: "speed"
  },
  {
    uuid: "b75f4b7a-6a6f-4ac9-a60d-1347099188b2",
    iconSet: "MaterialIcons",
    iconName: "spellcheck"
  },
  {
    uuid: "8526bbd1-9722-499d-89ae-08e3fc58b4c1",
    iconSet: "MaterialIcons",
    iconName: "sports"
  },
  {
    uuid: "ed9dc2df-df28-40b3-b648-990e3550614d",
    iconSet: "MaterialIcons",
    iconName: "sports-bar"
  },
  {
    uuid: "e6d9e02f-a27d-4e3a-a65d-faa47f6fea8f",
    iconSet: "MaterialIcons",
    iconName: "sports-baseball"
  },
  {
    uuid: "9db87d2c-994f-46e7-93b0-161b4c09a229",
    iconSet: "MaterialIcons",
    iconName: "sports-basketball"
  },
  {
    uuid: "4273335c-4b4f-4242-bfb6-1f30d16f72f0",
    iconSet: "MaterialIcons",
    iconName: "sports-cricket"
  },
  {
    uuid: "f8764bf2-c762-4055-8721-67ff34ef8722",
    iconSet: "MaterialIcons",
    iconName: "sports-esports"
  },
  {
    uuid: "0e4c5e1b-f5ba-457d-9bc9-051614e33e53",
    iconSet: "MaterialIcons",
    iconName: "sports-football"
  },
  {
    uuid: "66ec05dd-a09d-4e98-8f82-78c02c7fc64b",
    iconSet: "MaterialIcons",
    iconName: "sports-golf"
  },
  {
    uuid: "be03e966-0124-463e-b1d4-954cd5edacc7",
    iconSet: "MaterialIcons",
    iconName: "sports-handball"
  },
  {
    uuid: "81fb09bd-4c2c-46c9-96f5-bb07c943a562",
    iconSet: "MaterialIcons",
    iconName: "sports-hockey"
  },
  {
    uuid: "cd776a03-d677-4198-8d68-4e17a2cbbd63",
    iconSet: "MaterialIcons",
    iconName: "sports-kabaddi"
  },
  {
    uuid: "5b196f98-a45a-46bb-ab79-23af30fc5451",
    iconSet: "MaterialIcons",
    iconName: "sports-mma"
  },
  {
    uuid: "6602dae0-7d70-426b-9759-57e8d1d89adb",
    iconSet: "MaterialIcons",
    iconName: "sports-motorsports"
  },
  {
    uuid: "32e900ec-b84d-453f-a6f1-f37e9934a175",
    iconSet: "MaterialIcons",
    iconName: "sports-rugby"
  },
  {
    uuid: "66534bc7-23e4-45d4-84ba-e898c2024399",
    iconSet: "MaterialIcons",
    iconName: "sports-soccer"
  },
  {
    uuid: "253eba5b-c8b4-41a5-82a5-aaa2268f615a",
    iconSet: "MaterialIcons",
    iconName: "sports-tennis"
  },
  {
    uuid: "03e08fad-3f38-46bd-bb22-b7ed9a84580b",
    iconSet: "MaterialIcons",
    iconName: "sports-volleyball"
  },
  {
    uuid: "35b30acf-c1e2-4126-a668-a5a93829c897",
    iconSet: "MaterialIcons",
    iconName: "square-foot"
  },
  {
    uuid: "3ed2827f-2b2b-4426-9c60-6b81a0f1e22e",
    iconSet: "MaterialIcons",
    iconName: "stacked-bar-chart"
  },
  {
    uuid: "5a7c9968-eebb-4835-b3e8-75224774d2e5",
    iconSet: "MaterialIcons",
    iconName: "stacked-line-chart"
  },
  {
    uuid: "86e5aebd-94c5-4112-b8b4-0ab7136032a7",
    iconSet: "MaterialIcons",
    iconName: "stairs"
  },
  {
    uuid: "3e4e0a75-2255-4322-849f-f1a257301881",
    iconSet: "MaterialIcons",
    iconName: "star"
  },
  {
    uuid: "0cc23f03-4b74-411e-b38e-5917ece7eedc",
    iconSet: "MaterialIcons",
    iconName: "star-border"
  },
  {
    uuid: "28e2d7ce-24d6-4129-afa2-7c190593681b",
    iconSet: "MaterialIcons",
    iconName: "star-half"
  },
  {
    uuid: "93c00c7d-249a-4098-a376-5486a830e206",
    iconSet: "MaterialIcons",
    iconName: "star-outline"
  },
  {
    uuid: "a169c12c-d86d-4d5f-9bdc-56af979f4399",
    iconSet: "MaterialIcons",
    iconName: "star-rate"
  },
  {
    uuid: "d4e038d5-4a6c-4bc1-a383-bbe953c748c5",
    iconSet: "MaterialIcons",
    iconName: "stars"
  },
  {
    uuid: "c0961193-8751-46cd-b1de-2b06b1d9df0f",
    iconSet: "MaterialIcons",
    iconName: "stay-current-landscape"
  },
  {
    uuid: "f3ad3e8b-e1b6-46a8-9f8f-6d1de70cb56e",
    iconSet: "MaterialIcons",
    iconName: "stay-current-portrait"
  },
  {
    uuid: "cc4a9c3e-3410-4db3-b097-2dc34f836a78",
    iconSet: "MaterialIcons",
    iconName: "stay-primary-landscape"
  },
  {
    uuid: "9220e52c-a79f-45fe-acde-a3e96e4ef1d5",
    iconSet: "MaterialIcons",
    iconName: "stay-primary-portrait"
  },
  {
    uuid: "f08b571e-86bc-4c60-8ecd-5c7c8fac3509",
    iconSet: "MaterialIcons",
    iconName: "sticky-note-2"
  },
  {
    uuid: "f7ce6b1a-6c35-497a-9fff-879058b0c32b",
    iconSet: "MaterialIcons",
    iconName: "stop"
  },
  {
    uuid: "ac02a207-8f71-4899-8bcd-5a47145e0abf",
    iconSet: "MaterialIcons",
    iconName: "stop-circle"
  },
  {
    uuid: "cf8c6c65-5290-4632-b32b-fa91c19b383b",
    iconSet: "MaterialIcons",
    iconName: "stop-screen-share"
  },
  {
    uuid: "03d96d7d-cadc-483d-b426-8306d18f66fa",
    iconSet: "MaterialIcons",
    iconName: "storage"
  },
  {
    uuid: "03ecdde4-fc83-4842-a832-98666592a2ee",
    iconSet: "MaterialIcons",
    iconName: "store"
  },
  {
    uuid: "3ac0f5b5-cafe-4217-93a3-d8741d6184ac",
    iconSet: "MaterialIcons",
    iconName: "store-mall-directory"
  },
  {
    uuid: "f6ff9982-0ceb-4701-be49-0c5f27268ecd",
    iconSet: "MaterialIcons",
    iconName: "storefront"
  },
  {
    uuid: "fc58bc1a-1f6b-47cd-8f93-6fe0248f0312",
    iconSet: "MaterialIcons",
    iconName: "straighten"
  },
  {
    uuid: "53e736e3-3509-4bad-9fac-a5dc385c56e4",
    iconSet: "MaterialIcons",
    iconName: "stream"
  },
  {
    uuid: "5dddb338-dd31-4e4a-8f79-661d477fc5b1",
    iconSet: "MaterialIcons",
    iconName: "streetview"
  },
  {
    uuid: "22bf4041-6999-4f67-b3d0-ce3a2c995db2",
    iconSet: "MaterialIcons",
    iconName: "strikethrough-s"
  },
  {
    uuid: "33866ab7-c762-4b11-9e90-1e4bb7404daf",
    iconSet: "MaterialIcons",
    iconName: "stroller"
  },
  {
    uuid: "247ca8bf-dc33-482c-9b17-b89de1756606",
    iconSet: "MaterialIcons",
    iconName: "style"
  },
  {
    uuid: "efab870f-8e02-49c4-bcd8-f4800c2ef28e",
    iconSet: "MaterialIcons",
    iconName: "subdirectory-arrow-left"
  },
  {
    uuid: "1e51a584-b378-499b-a4ba-e2140f61811e",
    iconSet: "MaterialIcons",
    iconName: "subdirectory-arrow-right"
  },
  {
    uuid: "51a5f738-9588-4406-8324-e646bee8cdd8",
    iconSet: "MaterialIcons",
    iconName: "subject"
  },
  {
    uuid: "5f6aca1e-1ad4-475f-83f0-148178fc6504",
    iconSet: "MaterialIcons",
    iconName: "subscript"
  },
  {
    uuid: "eb3a0ac5-0f2c-4de5-b35c-0461be7b1633",
    iconSet: "MaterialIcons",
    iconName: "subscriptions"
  },
  {
    uuid: "fc0161c7-6bfe-4953-bc2f-f145a54a73d5",
    iconSet: "MaterialIcons",
    iconName: "subtitles"
  },
  {
    uuid: "67812de9-eeaa-43da-8082-8adfea0eb782",
    iconSet: "MaterialIcons",
    iconName: "subtitles-off"
  },
  {
    uuid: "8a2f2110-f4b3-430e-ba32-21c1162ffb2e",
    iconSet: "MaterialIcons",
    iconName: "subway"
  },
  {
    uuid: "1ccca3c2-1dad-448b-aafd-87b8b5525d5b",
    iconSet: "MaterialIcons",
    iconName: "superscript"
  },
  {
    uuid: "9f56140d-5988-4522-9e93-ecfaa4609a80",
    iconSet: "MaterialIcons",
    iconName: "supervised-user-circle"
  },
  {
    uuid: "ab5ab929-464a-4651-a02b-fa90ee6aa862",
    iconSet: "MaterialIcons",
    iconName: "supervisor-account"
  },
  {
    uuid: "4b3d1c31-c31c-4de4-9209-210e7288b521",
    iconSet: "MaterialIcons",
    iconName: "support"
  },
  {
    uuid: "6756102b-78fe-4c1a-b924-149f2e21848f",
    iconSet: "MaterialIcons",
    iconName: "support-agent"
  },
  {
    uuid: "81c12188-1a7e-4803-a179-29f6fbc47cad",
    iconSet: "MaterialIcons",
    iconName: "surround-sound"
  },
  {
    uuid: "b47d00ed-ce7d-49a0-bea8-9702e00d4a71",
    iconSet: "MaterialIcons",
    iconName: "swap-calls"
  },
  {
    uuid: "c1c36246-4312-44b2-a24e-e4510511c902",
    iconSet: "MaterialIcons",
    iconName: "swap-horiz"
  },
  {
    uuid: "3cf10aed-9f18-4514-a14d-8203ddd4b221",
    iconSet: "MaterialIcons",
    iconName: "swap-horizontal-circle"
  },
  {
    uuid: "506fd9b6-7475-4727-a086-5d69db141c64",
    iconSet: "MaterialIcons",
    iconName: "swap-vert"
  },
  {
    uuid: "e49201ac-0748-466f-afe5-9f64ad0461e7",
    iconSet: "MaterialIcons",
    iconName: "swap-vert-circle"
  },
  {
    uuid: "97ee8a1b-d180-45c8-ba69-142b7c4c6169",
    iconSet: "MaterialIcons",
    iconName: "swap-vertical-circle"
  },
  {
    uuid: "24a9ce4f-2d25-4ff2-87c4-7abe76c799a6",
    iconSet: "MaterialIcons",
    iconName: "swipe"
  },
  {
    uuid: "4636ca69-1258-48c0-a350-1e43fa467224",
    iconSet: "MaterialIcons",
    iconName: "switch-account"
  },
  {
    uuid: "22c33647-1b08-4815-a49a-eb3f426e21c7",
    iconSet: "MaterialIcons",
    iconName: "switch-camera"
  },
  {
    uuid: "5630166c-9490-48f2-b650-ae523a62db77",
    iconSet: "MaterialIcons",
    iconName: "switch-left"
  },
  {
    uuid: "8c3a0133-addf-4a65-9112-ecbd964ad0a5",
    iconSet: "MaterialIcons",
    iconName: "switch-right"
  },
  {
    uuid: "c9e00f60-7b83-4819-aae6-9cfba87021ef",
    iconSet: "MaterialIcons",
    iconName: "switch-video"
  },
  {
    uuid: "a2540db0-2ee2-4ff7-bfbf-098feb4d19d1",
    iconSet: "MaterialIcons",
    iconName: "sync"
  },
  {
    uuid: "dffa6430-207b-499e-a7d3-90ae10d3ed41",
    iconSet: "MaterialIcons",
    iconName: "sync-alt"
  },
  {
    uuid: "0b38bb09-151a-46a2-8cbd-f8c0a53ff59c",
    iconSet: "MaterialIcons",
    iconName: "sync-disabled"
  },
  {
    uuid: "cf4925d9-7acf-41b8-8d9a-5327c6d9589a",
    iconSet: "MaterialIcons",
    iconName: "sync-problem"
  },
  {
    uuid: "303047fa-2fe2-4eba-9de9-97651f6ffd0a",
    iconSet: "MaterialIcons",
    iconName: "system-update"
  },
  {
    uuid: "669e43e8-cc3c-4b08-942a-5b5483d59742",
    iconSet: "MaterialIcons",
    iconName: "system-update-alt"
  },
  {
    uuid: "f81df868-1907-48d3-8f36-b4b0ad893fc1",
    iconSet: "MaterialIcons",
    iconName: "system-update-tv"
  },
  {
    uuid: "1c02db64-ea32-4044-8cbe-88552ce09d18",
    iconSet: "MaterialIcons",
    iconName: "tab"
  },
  {
    uuid: "4d288d22-41cd-4dbd-945e-5beeb6781f27",
    iconSet: "MaterialIcons",
    iconName: "tab-unselected"
  },
  {
    uuid: "58c8b40b-443d-4b2f-ad7b-aa3cf24d0c75",
    iconSet: "MaterialIcons",
    iconName: "table-chart"
  },
  {
    uuid: "f90c2b16-696d-45e0-9c16-a84647175202",
    iconSet: "MaterialIcons",
    iconName: "table-rows"
  },
  {
    uuid: "09d02249-b756-43c3-95d7-fe9d444e7d65",
    iconSet: "MaterialIcons",
    iconName: "table-view"
  },
  {
    uuid: "35c02c60-cd48-4a2d-be1a-f967565a390b",
    iconSet: "MaterialIcons",
    iconName: "tablet"
  },
  {
    uuid: "91707820-e1de-486a-b2d1-3f62e5d56873",
    iconSet: "MaterialIcons",
    iconName: "tablet-android"
  },
  {
    uuid: "0debeed4-73b8-4598-a373-434e538ab66a",
    iconSet: "MaterialIcons",
    iconName: "tablet-mac"
  },
  {
    uuid: "91b3ccd6-b227-4188-8ccf-f5ea32d3db53",
    iconSet: "MaterialIcons",
    iconName: "tag"
  },
  {
    uuid: "16422cbc-c011-473a-aa20-6efb188e915e",
    iconSet: "MaterialIcons",
    iconName: "tag-faces"
  },
  {
    uuid: "c87e62ce-fb91-4dc0-a687-cc18d9935047",
    iconSet: "MaterialIcons",
    iconName: "takeout-dining"
  },
  {
    uuid: "74133d35-d449-4417-b5fb-ad2b8c7176ca",
    iconSet: "MaterialIcons",
    iconName: "tap-and-play"
  },
  {
    uuid: "24265824-d50c-4ffd-b1b4-274f1f67945b",
    iconSet: "MaterialIcons",
    iconName: "tapas"
  },
  {
    uuid: "462fe1e7-aa35-4ea3-996e-46825ca4e014",
    iconSet: "MaterialIcons",
    iconName: "taxi-alert"
  },
  {
    uuid: "f0208486-93e6-41f2-bdfd-344eed1e2fb2",
    iconSet: "MaterialIcons",
    iconName: "terrain"
  },
  {
    uuid: "b0d858e5-d6f8-443d-9c0b-851d504da2da",
    iconSet: "MaterialIcons",
    iconName: "text-fields"
  },
  {
    uuid: "5acea0fc-7869-47f8-ad48-e5dcf21864a8",
    iconSet: "MaterialIcons",
    iconName: "text-format"
  },
  {
    uuid: "9232068e-4669-47eb-bae8-f7b9f1a475a5",
    iconSet: "MaterialIcons",
    iconName: "text-rotate-up"
  },
  {
    uuid: "46e4f59e-92f0-4b1f-a7e9-a7d8be47f6f4",
    iconSet: "MaterialIcons",
    iconName: "text-rotate-vertical"
  },
  {
    uuid: "e2168520-87e8-4a58-b72f-ad3725a5bf7d",
    iconSet: "MaterialIcons",
    iconName: "text-rotation-angledown"
  },
  {
    uuid: "50d946a8-48aa-468a-9900-ac66bb28dab5",
    iconSet: "MaterialIcons",
    iconName: "text-rotation-angleup"
  },
  {
    uuid: "5c436d8e-f3f4-4fcf-a39f-c866938cc6ae",
    iconSet: "MaterialIcons",
    iconName: "text-rotation-down"
  },
  {
    uuid: "b37b6211-6da2-467a-8d12-85c79b05a0d2",
    iconSet: "MaterialIcons",
    iconName: "text-rotation-none"
  },
  {
    uuid: "31b29117-1cc7-4cab-b10a-684d26963f3d",
    iconSet: "MaterialIcons",
    iconName: "text-snippet"
  },
  {
    uuid: "f78b2abc-1c68-420e-893b-d95e18e5a2f0",
    iconSet: "MaterialIcons",
    iconName: "textsms"
  },
  {
    uuid: "a0dd965c-da27-4e42-b092-d8d659a560f1",
    iconSet: "MaterialIcons",
    iconName: "texture"
  },
  {
    uuid: "d37116b2-86a9-46d3-b81a-01fa862c3fc0",
    iconSet: "MaterialIcons",
    iconName: "theater-comedy"
  },
  {
    uuid: "04fd8bed-5e2e-42a5-ac48-0d7aa9b0f9f8",
    iconSet: "MaterialIcons",
    iconName: "theaters"
  },
  {
    uuid: "73d8d426-4a56-4d52-aec0-c7367d42233e",
    iconSet: "MaterialIcons",
    iconName: "thumb-down"
  },
  {
    uuid: "032dea35-5a16-408a-86af-078e777774d4",
    iconSet: "MaterialIcons",
    iconName: "thumb-down-alt"
  },
  {
    uuid: "1370304e-fb3b-4bc6-be60-a2baafe58fd2",
    iconSet: "MaterialIcons",
    iconName: "thumb-down-off-alt"
  },
  {
    uuid: "02f7bcd5-4294-4414-9073-38e0b4e88b15",
    iconSet: "MaterialIcons",
    iconName: "thumb-up"
  },
  {
    uuid: "f789ddb4-2021-42c3-85d2-5d0f7e1cc520",
    iconSet: "MaterialIcons",
    iconName: "thumb-up-alt"
  },
  {
    uuid: "6f24675e-13dc-42e6-baa0-fbb80a920f40",
    iconSet: "MaterialIcons",
    iconName: "thumb-up-off-alt"
  },
  {
    uuid: "e820650f-7c87-415c-bac6-4025dcce2fc6",
    iconSet: "MaterialIcons",
    iconName: "thumbs-up-down"
  },
  {
    uuid: "eb6b3bdb-2394-408b-aa7f-d7eaea72a8c8",
    iconSet: "MaterialIcons",
    iconName: "time-to-leave"
  },
  {
    uuid: "431d04a5-f21a-48a4-9590-0481508fc86c",
    iconSet: "MaterialIcons",
    iconName: "timelapse"
  },
  {
    uuid: "952b26e3-7a73-40a4-840b-7170f34bc2bc",
    iconSet: "MaterialIcons",
    iconName: "timeline"
  },
  {
    uuid: "565ef6c5-a3ee-46f2-925e-408aafd82dda",
    iconSet: "MaterialIcons",
    iconName: "timer"
  },
  {
    uuid: "db8b9dd0-71b6-4fa9-95aa-90d0d285cf93",
    iconSet: "MaterialIcons",
    iconName: "timer-10"
  },
  {
    uuid: "ee5e19ce-9c43-4b98-b5ba-1ac2a7bbf11c",
    iconSet: "MaterialIcons",
    iconName: "timer-3"
  },
  {
    uuid: "27e97f12-8308-480a-925c-fc93f3d930aa",
    iconSet: "MaterialIcons",
    iconName: "timer-off"
  },
  {
    uuid: "53f25940-0397-4581-9c68-3f55b8dd59aa",
    iconSet: "MaterialIcons",
    iconName: "title"
  },
  {
    uuid: "8e87ca8d-8bd5-4f8e-b669-36e94dea65bd",
    iconSet: "MaterialIcons",
    iconName: "toc"
  },
  {
    uuid: "32ffe43f-547d-4b0a-a881-2c04946aa3fe",
    iconSet: "MaterialIcons",
    iconName: "today"
  },
  {
    uuid: "b056a16b-f638-4561-89e0-a582e33c6a3d",
    iconSet: "MaterialIcons",
    iconName: "toggle-off"
  },
  {
    uuid: "4942dca7-e5f1-4190-897d-9f682c265bd8",
    iconSet: "MaterialIcons",
    iconName: "toggle-on"
  },
  {
    uuid: "6353b50a-cdf4-49e6-85e9-5904e9c8ab3e",
    iconSet: "MaterialIcons",
    iconName: "toll"
  },
  {
    uuid: "71afbf8c-79ad-4025-80df-82669a31f609",
    iconSet: "MaterialIcons",
    iconName: "tonality"
  },
  {
    uuid: "7e26330b-aa36-4bb4-855a-cb47bb0b5979",
    iconSet: "MaterialIcons",
    iconName: "topic"
  },
  {
    uuid: "643b3511-0168-401d-9165-4eff3a32fced",
    iconSet: "MaterialIcons",
    iconName: "touch-app"
  },
  {
    uuid: "9cf6d7c9-4de2-4feb-a532-093784464f0d",
    iconSet: "MaterialIcons",
    iconName: "tour"
  },
  {
    uuid: "6780a637-93e7-4bb0-9fbc-a47b7d3fa91a",
    iconSet: "MaterialIcons",
    iconName: "toys"
  },
  {
    uuid: "ef29213b-4208-4b9e-ba5a-0386bc7dab7a",
    iconSet: "MaterialIcons",
    iconName: "track-changes"
  },
  {
    uuid: "e4d05fab-8e77-4050-9e72-2823d4410d36",
    iconSet: "MaterialIcons",
    iconName: "traffic"
  },
  {
    uuid: "22733333-964c-4d02-a07b-1adfd2c03d06",
    iconSet: "MaterialIcons",
    iconName: "train"
  },
  {
    uuid: "45bf20d0-bb03-48ff-b5cd-3acd2e808b1e",
    iconSet: "MaterialIcons",
    iconName: "tram"
  },
  {
    uuid: "f486a437-6f51-4f16-93f7-a02c751463dc",
    iconSet: "MaterialIcons",
    iconName: "transfer-within-a-station"
  },
  {
    uuid: "39570ead-72b1-43d4-88b0-ef9640e381a8",
    iconSet: "MaterialIcons",
    iconName: "transform"
  },
  {
    uuid: "52f8f4fd-a609-478d-bb2e-958db50dc1e1",
    iconSet: "MaterialIcons",
    iconName: "transit-enterexit"
  },
  {
    uuid: "d3c3323d-9b9a-4068-8742-a2906bd3160a",
    iconSet: "MaterialIcons",
    iconName: "translate"
  },
  {
    uuid: "08c69e0b-45c0-4e3f-a32e-c553e3c64d00",
    iconSet: "MaterialIcons",
    iconName: "trending-down"
  },
  {
    uuid: "6f732fcc-3101-4d8b-8195-c0e83b21ed78",
    iconSet: "MaterialIcons",
    iconName: "trending-flat"
  },
  {
    uuid: "b7d2a8ef-1adf-4c82-a6de-94071b66ea5a",
    iconSet: "MaterialIcons",
    iconName: "trending-neutral"
  },
  {
    uuid: "1e240363-332b-4f51-ae53-3f802e9bd3d6",
    iconSet: "MaterialIcons",
    iconName: "trending-up"
  },
  {
    uuid: "6a8e63b1-cc3f-43b6-b9bc-d7dd90bf4aab",
    iconSet: "MaterialIcons",
    iconName: "trip-origin"
  },
  {
    uuid: "427b2db1-1253-476a-b3dc-0b84753b0ca9",
    iconSet: "MaterialIcons",
    iconName: "tty"
  },
  {
    uuid: "408ab854-7307-44d0-9798-50a03d0aa502",
    iconSet: "MaterialIcons",
    iconName: "tune"
  },
  {
    uuid: "9bb6d1fe-6239-4b3e-bc73-2eeed76e721c",
    iconSet: "MaterialIcons",
    iconName: "turned-in"
  },
  {
    uuid: "7d100eaf-1b2b-407c-8119-f43eb8bb1218",
    iconSet: "MaterialIcons",
    iconName: "turned-in-not"
  },
  {
    uuid: "ec6b3912-c26e-40ab-bc0d-3c9d9458a1e2",
    iconSet: "MaterialIcons",
    iconName: "tv"
  },
  {
    uuid: "e7201051-35da-4d57-86ea-a70151e23eee",
    iconSet: "MaterialIcons",
    iconName: "tv-off"
  },
  {
    uuid: "e9a194b0-e42d-4add-9be2-40de91a2fdba",
    iconSet: "MaterialIcons",
    iconName: "two-wheeler"
  },
  {
    uuid: "3bc9fe5d-cba9-4c73-aeed-2f7fb6a5f809",
    iconSet: "MaterialIcons",
    iconName: "umbrella"
  },
  {
    uuid: "40565ec3-351a-445a-95aa-0a77af8223d6",
    iconSet: "MaterialIcons",
    iconName: "unarchive"
  },
  {
    uuid: "3041b49e-2eb1-4192-a019-0a7ddd508e58",
    iconSet: "MaterialIcons",
    iconName: "undo"
  },
  {
    uuid: "17fb8342-8cee-4313-8ca8-4be314956e8a",
    iconSet: "MaterialIcons",
    iconName: "unfold-less"
  },
  {
    uuid: "2be784f0-4c02-478c-bbde-905c9f6f3594",
    iconSet: "MaterialIcons",
    iconName: "unfold-more"
  },
  {
    uuid: "97871c8d-5cf9-4808-9085-d3334a0aa12c",
    iconSet: "MaterialIcons",
    iconName: "unpublished"
  },
  {
    uuid: "46ced365-0bf7-46bf-9ed6-6371bfcde113",
    iconSet: "MaterialIcons",
    iconName: "unsubscribe"
  },
  {
    uuid: "317a2a08-93f1-44d2-af1d-493c4c738743",
    iconSet: "MaterialIcons",
    iconName: "update"
  },
  {
    uuid: "e6da2408-d39b-4c06-a6b9-98c1ecfddca7",
    iconSet: "MaterialIcons",
    iconName: "update-disabled"
  },
  {
    uuid: "9dd21350-1a61-445b-a81d-35360a9a9226",
    iconSet: "MaterialIcons",
    iconName: "upgrade"
  },
  {
    uuid: "088c2b63-a351-4339-ab60-daede144e9c3",
    iconSet: "MaterialIcons",
    iconName: "upload-file"
  },
  {
    uuid: "4d66095e-5b36-41a3-8f8e-c597719ba269",
    iconSet: "MaterialIcons",
    iconName: "usb"
  },
  {
    uuid: "bacb232a-9c16-4ec3-b446-f9ef275bcfff",
    iconSet: "MaterialIcons",
    iconName: "verified"
  },
  {
    uuid: "d00ab54a-bcfd-4cf8-aef0-8b0cdaf9fccc",
    iconSet: "MaterialIcons",
    iconName: "verified-user"
  },
  {
    uuid: "e8c66988-0a9f-4774-8abc-ce32b5f6b843",
    iconSet: "MaterialIcons",
    iconName: "vertical-align-bottom"
  },
  {
    uuid: "28a1731d-098d-4baf-b04f-b19f9ee37a0f",
    iconSet: "MaterialIcons",
    iconName: "vertical-align-center"
  },
  {
    uuid: "67b6fab9-c775-476e-9dd9-3fa78816a199",
    iconSet: "MaterialIcons",
    iconName: "vertical-align-top"
  },
  {
    uuid: "8db8aba9-576f-49e2-8bb1-40a8223b65c1",
    iconSet: "MaterialIcons",
    iconName: "vertical-distribute"
  },
  {
    uuid: "fbf362cd-1e5d-4e95-93ae-9e9d09dd1330",
    iconSet: "MaterialIcons",
    iconName: "vertical-split"
  },
  {
    uuid: "79d21710-fa48-44ff-b251-8814edf9e0cf",
    iconSet: "MaterialIcons",
    iconName: "vibration"
  },
  {
    uuid: "b23259dc-6224-4566-a789-250f195e1de3",
    iconSet: "MaterialIcons",
    iconName: "video-call"
  },
  {
    uuid: "6ff8ad68-50ac-4d1a-9f93-065ba4d56099",
    iconSet: "MaterialIcons",
    iconName: "video-collection"
  },
  {
    uuid: "fde832b2-091e-4148-8184-bd504d5ff3c7",
    iconSet: "MaterialIcons",
    iconName: "video-label"
  },
  {
    uuid: "1cbc386f-bbb2-4369-b875-c2152cbd745c",
    iconSet: "MaterialIcons",
    iconName: "video-library"
  },
  {
    uuid: "9245015f-d676-4c57-ab4d-51e9afc531a7",
    iconSet: "MaterialIcons",
    iconName: "video-settings"
  },
  {
    uuid: "2943e15d-044e-4eaf-a98e-b027da983c4a",
    iconSet: "MaterialIcons",
    iconName: "videocam"
  },
  {
    uuid: "fd603e85-e345-4100-905e-207b4992d74e",
    iconSet: "MaterialIcons",
    iconName: "videocam-off"
  },
  {
    uuid: "75193f18-6c7e-4a93-849c-d115e8c7ea93",
    iconSet: "MaterialIcons",
    iconName: "videogame-asset"
  },
  {
    uuid: "31085ac1-e9bd-40b7-bad9-f3ff5799be29",
    iconSet: "MaterialIcons",
    iconName: "view-agenda"
  },
  {
    uuid: "ada35d47-ba1f-4b52-b082-83f2cada61de",
    iconSet: "MaterialIcons",
    iconName: "view-array"
  },
  {
    uuid: "660cd8f8-989e-40fd-9daf-cf28faaad9af",
    iconSet: "MaterialIcons",
    iconName: "view-carousel"
  },
  {
    uuid: "b22cdb98-1d99-4d31-b217-fca8fed6cda3",
    iconSet: "MaterialIcons",
    iconName: "view-column"
  },
  {
    uuid: "ca76cdb8-1498-473c-85cf-8a07f6f31116",
    iconSet: "MaterialIcons",
    iconName: "view-comfortable"
  },
  {
    uuid: "74cdf8cb-d543-42bc-96ec-01d902e4083e",
    iconSet: "MaterialIcons",
    iconName: "view-comfy"
  },
  {
    uuid: "38ae1192-d051-49da-9e79-d5ef88a77aa9",
    iconSet: "MaterialIcons",
    iconName: "view-compact"
  },
  {
    uuid: "18b388ae-06cb-40d4-85f2-c0972258e34b",
    iconSet: "MaterialIcons",
    iconName: "view-day"
  },
  {
    uuid: "d6dc5339-43ef-4118-aa37-8e5c835a1e49",
    iconSet: "MaterialIcons",
    iconName: "view-headline"
  },
  {
    uuid: "e538245e-a25f-4fe3-8602-d21f6fc04feb",
    iconSet: "MaterialIcons",
    iconName: "view-in-ar"
  },
  {
    uuid: "33ee55bc-5cd1-41ed-a815-087b4ccfafa5",
    iconSet: "MaterialIcons",
    iconName: "view-list"
  },
  {
    uuid: "a81d4052-ae9b-4e22-b1a9-e4ed0c94d76c",
    iconSet: "MaterialIcons",
    iconName: "view-module"
  },
  {
    uuid: "dd174946-591b-4dd6-a034-e344ed37caed",
    iconSet: "MaterialIcons",
    iconName: "view-quilt"
  },
  {
    uuid: "58bc0591-33ff-4a28-9b98-2e5fe1686932",
    iconSet: "MaterialIcons",
    iconName: "view-sidebar"
  },
  {
    uuid: "4495a191-98ce-416c-98be-903f56b6adb8",
    iconSet: "MaterialIcons",
    iconName: "view-stream"
  },
  {
    uuid: "cce7ae6c-0e0f-4771-a47b-423e215f6f70",
    iconSet: "MaterialIcons",
    iconName: "view-week"
  },
  {
    uuid: "0fc176fa-42d2-453d-b352-7c7edfb7d3ad",
    iconSet: "MaterialIcons",
    iconName: "vignette"
  },
  {
    uuid: "15bf6d61-b2e8-4bfb-92c1-3cabc8baff7a",
    iconSet: "MaterialIcons",
    iconName: "visibility"
  },
  {
    uuid: "22d19655-d378-48e8-90c7-ead41f0efbaf",
    iconSet: "MaterialIcons",
    iconName: "visibility-off"
  },
  {
    uuid: "aba00d15-657b-428e-a819-ab4b0fefc97b",
    iconSet: "MaterialIcons",
    iconName: "voice-chat"
  },
  {
    uuid: "aa3a7c84-951d-47b4-94e9-c6797d846026",
    iconSet: "MaterialIcons",
    iconName: "voice-over-off"
  },
  {
    uuid: "00069637-aa2b-44fd-8fcc-a815b2232139",
    iconSet: "MaterialIcons",
    iconName: "voicemail"
  },
  {
    uuid: "819f805d-9ec2-40ac-8187-9c9553e59bdd",
    iconSet: "MaterialIcons",
    iconName: "volume-down"
  },
  {
    uuid: "033b1990-26ef-4c0d-b59f-cccda042d93e",
    iconSet: "MaterialIcons",
    iconName: "volume-mute"
  },
  {
    uuid: "8a2b14ff-b105-4705-b554-43bebc02aeef",
    iconSet: "MaterialIcons",
    iconName: "volume-off"
  },
  {
    uuid: "60dd51e7-164f-4721-9345-41bfe77a7f46",
    iconSet: "MaterialIcons",
    iconName: "volume-up"
  },
  {
    uuid: "f0653e69-696f-47bd-a238-75e5d6b67a15",
    iconSet: "MaterialIcons",
    iconName: "volunteer-activism"
  },
  {
    uuid: "b7e79fc7-3481-4a2b-af74-a2ddb9c6f904",
    iconSet: "MaterialIcons",
    iconName: "vpn-key"
  },
  {
    uuid: "1ea84027-2e40-4585-a2ca-612150544893",
    iconSet: "MaterialIcons",
    iconName: "vpn-lock"
  },
  {
    uuid: "c581537b-1e61-40f9-955d-9aa7a367f76c",
    iconSet: "MaterialIcons",
    iconName: "wallet-giftcard"
  },
  {
    uuid: "34c6549f-ff0c-4a8e-b62d-0c7aeca15f30",
    iconSet: "MaterialIcons",
    iconName: "wallet-membership"
  },
  {
    uuid: "e0904ec1-756e-4559-b966-86c3d747af1b",
    iconSet: "MaterialIcons",
    iconName: "wallet-travel"
  },
  {
    uuid: "90496949-8f39-411f-bd26-2dbf2dfd3d8d",
    iconSet: "MaterialIcons",
    iconName: "wallpaper"
  },
  {
    uuid: "5e254069-8cce-4558-881b-788c3308492e",
    iconSet: "MaterialIcons",
    iconName: "warning"
  },
  {
    uuid: "98e51318-aaf3-43b6-91c2-6170ba579e0c",
    iconSet: "MaterialIcons",
    iconName: "wash"
  },
  {
    uuid: "3c32dd03-90ae-4b68-b86b-760bf7905fea",
    iconSet: "MaterialIcons",
    iconName: "watch"
  },
  {
    uuid: "970287b8-2dca-433f-b2ab-d870abd176bd",
    iconSet: "MaterialIcons",
    iconName: "watch-later"
  },
  {
    uuid: "09d8ef63-d42d-40d3-91ea-ef85893ff6d1",
    iconSet: "MaterialIcons",
    iconName: "water-damage"
  },
  {
    uuid: "8220e05e-a6ea-426b-8bf0-9d76515a2018",
    iconSet: "MaterialIcons",
    iconName: "waterfall-chart"
  },
  {
    uuid: "99f8b188-a815-446a-8a48-11de6ada1431",
    iconSet: "MaterialIcons",
    iconName: "waves"
  },
  {
    uuid: "fb847559-75d1-438a-bf2d-b1e8e37020bd",
    iconSet: "MaterialIcons",
    iconName: "wb-auto"
  },
  {
    uuid: "deb0c089-6145-4bc9-9b3a-7c5506eb1bec",
    iconSet: "MaterialIcons",
    iconName: "wb-cloudy"
  },
  {
    uuid: "316125c9-23a2-44ee-bdba-8bb176471b41",
    iconSet: "MaterialIcons",
    iconName: "wb-incandescent"
  },
  {
    uuid: "a865856c-218e-4d44-8312-81a34a75da37",
    iconSet: "MaterialIcons",
    iconName: "wb-iridescent"
  },
  {
    uuid: "c0e413c1-288b-4d55-9b4b-4bfa97bcd363",
    iconSet: "MaterialIcons",
    iconName: "wb-shade"
  },
  {
    uuid: "bd59097d-cb51-4a11-8482-dd365136a3e6",
    iconSet: "MaterialIcons",
    iconName: "wb-sunny"
  },
  {
    uuid: "0844bdab-51d7-43b7-b405-b3062e1f1936",
    iconSet: "MaterialIcons",
    iconName: "wb-twighlight"
  },
  {
    uuid: "1d3975ad-7785-4ce7-845a-336267076c2d",
    iconSet: "MaterialIcons",
    iconName: "wc"
  },
  {
    uuid: "1c73e560-e7c5-43c0-b244-371f89d73a88",
    iconSet: "MaterialIcons",
    iconName: "web"
  },
  {
    uuid: "1294f826-a187-4715-94b5-647a67feb8d5",
    iconSet: "MaterialIcons",
    iconName: "web-asset"
  },
  {
    uuid: "3f1c9c2e-1b74-4907-a1d0-d0b95e15b1b1",
    iconSet: "MaterialIcons",
    iconName: "weekend"
  },
  {
    uuid: "c79c9dc6-4565-40ce-86d8-5da6004dd253",
    iconSet: "MaterialIcons",
    iconName: "west"
  },
  {
    uuid: "975fdeb7-a2f7-4c72-9992-0a9015c09aae",
    iconSet: "MaterialIcons",
    iconName: "whatshot"
  },
  {
    uuid: "0d52429e-2f20-4c42-b21e-4bab45849eb4",
    iconSet: "MaterialIcons",
    iconName: "wheelchair-pickup"
  },
  {
    uuid: "ad0b0018-d6cb-434f-8b60-70fddcd8e668",
    iconSet: "MaterialIcons",
    iconName: "where-to-vote"
  },
  {
    uuid: "9f1b9b9d-7e5b-4da4-af3c-c87908770b96",
    iconSet: "MaterialIcons",
    iconName: "widgets"
  },
  {
    uuid: "e4acd656-5c4c-4fd8-bcfb-5f0dcfb31065",
    iconSet: "MaterialIcons",
    iconName: "wifi"
  },
  {
    uuid: "89c77c8a-00e5-4326-b9fc-81ddf89e1a08",
    iconSet: "MaterialIcons",
    iconName: "wifi-calling"
  },
  {
    uuid: "1a97fa8d-598e-4196-9a46-a25e3a02f55b",
    iconSet: "MaterialIcons",
    iconName: "wifi-lock"
  },
  {
    uuid: "929a8c45-4c92-4f47-8dfc-ae17324c7e69",
    iconSet: "MaterialIcons",
    iconName: "wifi-off"
  },
  {
    uuid: "4811d7aa-dc5f-464e-9c5c-7e5fdab18842",
    iconSet: "MaterialIcons",
    iconName: "wifi-protected-setup"
  },
  {
    uuid: "edc475ea-cecb-4e9a-afe7-17ca217b1086",
    iconSet: "MaterialIcons",
    iconName: "wifi-tethering"
  },
  {
    uuid: "45da530f-148d-4a7d-8c75-f5202bcc5ac9",
    iconSet: "MaterialIcons",
    iconName: "wine-bar"
  },
  {
    uuid: "80dfc536-4562-49c8-803f-2b53601a863e",
    iconSet: "MaterialIcons",
    iconName: "work"
  },
  {
    uuid: "137254f2-0e28-4455-8935-d13a9c64e510",
    iconSet: "MaterialIcons",
    iconName: "work-off"
  },
  {
    uuid: "6c2b4512-7b39-4135-ace6-08357ebd8221",
    iconSet: "MaterialIcons",
    iconName: "work-outline"
  },
  {
    uuid: "02593965-889d-46b3-b5bb-a882be4c0bce",
    iconSet: "MaterialIcons",
    iconName: "workspaces-filled"
  },
  {
    uuid: "c00c5878-ac91-4e72-b579-9cec87354a4e",
    iconSet: "MaterialIcons",
    iconName: "workspaces-outline"
  },
  {
    uuid: "0f5496b4-90a5-479a-8fac-c0a1dc382cde",
    iconSet: "MaterialIcons",
    iconName: "wrap-text"
  },
  {
    uuid: "c5a3a7cb-4aed-450f-a5e2-ddc7b8c5a4aa",
    iconSet: "MaterialIcons",
    iconName: "wrong-location"
  },
  {
    uuid: "1e1343d1-7a3b-41d9-a2d3-6b6eaefa6231",
    iconSet: "MaterialIcons",
    iconName: "wysiwyg"
  },
  {
    uuid: "029f6ed0-1321-4abf-a604-81938b2377df",
    iconSet: "MaterialIcons",
    iconName: "youtube-searched-for"
  },
  {
    uuid: "f9298236-2a97-4d48-9cee-efe110e8ca41",
    iconSet: "MaterialIcons",
    iconName: "zoom-in"
  },
  {
    uuid: "ba181b63-7b25-439b-a216-07c54d311260",
    iconSet: "MaterialIcons",
    iconName: "zoom-out"
  },
  {
    uuid: "41bdbbf5-2d15-463b-ac93-ba09e5d6d772",
    iconSet: "MaterialIcons",
    iconName: "zoom-out-map"
  },
  {
    uuid: "177487bb-c7cf-4fbe-85cc-857ee8837b16",
    iconSet: "Octicons",
    iconName: "alert"
  },
  {
    uuid: "a320c436-1ade-40d4-b42b-19d06d0c8752",
    iconSet: "Octicons",
    iconName: "archive"
  },
  {
    uuid: "6e58ed79-00a2-49a7-9b54-2db67a9add65",
    iconSet: "Octicons",
    iconName: "arrow-both"
  },
  {
    uuid: "a45fb0f6-3bff-4017-89bd-3e0ad4df900b",
    iconSet: "Octicons",
    iconName: "arrow-down"
  },
  {
    uuid: "b9bb9bda-0c44-4e3a-86ce-828745c58aa0",
    iconSet: "Octicons",
    iconName: "arrow-left"
  },
  {
    uuid: "88b3d8b2-0097-4823-a344-c1983a4649a4",
    iconSet: "Octicons",
    iconName: "arrow-right"
  },
  {
    uuid: "f4cbb4f4-48db-4792-9767-84f7a30dec54",
    iconSet: "Octicons",
    iconName: "arrow-small-down"
  },
  {
    uuid: "e911afe2-0ac2-4f2c-b1f4-6392cd69f789",
    iconSet: "Octicons",
    iconName: "arrow-small-left"
  },
  {
    uuid: "e2ffc0ca-ea9b-4770-8d78-245516531dc4",
    iconSet: "Octicons",
    iconName: "arrow-small-right"
  },
  {
    uuid: "1faa3d91-ac64-4c61-883d-658c06848ff6",
    iconSet: "Octicons",
    iconName: "arrow-small-up"
  },
  {
    uuid: "e841dccd-b2c0-461d-84a8-30f75977d302",
    iconSet: "Octicons",
    iconName: "arrow-up"
  },
  {
    uuid: "24e75a27-5598-42c0-b4bc-a01c190c1291",
    iconSet: "Octicons",
    iconName: "beaker"
  },
  {
    uuid: "1bff79b8-7f3e-4906-ba8f-564fc26e8a3c",
    iconSet: "Octicons",
    iconName: "bell"
  },
  {
    uuid: "ee49ec13-87ed-438d-9833-d3b49cf63d93",
    iconSet: "Octicons",
    iconName: "bold"
  },
  {
    uuid: "2cd6a3da-62ae-4506-8336-8946602716ab",
    iconSet: "Octicons",
    iconName: "book"
  },
  {
    uuid: "80f46237-121e-44cc-80bd-a7c66a865bc0",
    iconSet: "Octicons",
    iconName: "bookmark"
  },
  {
    uuid: "ff8e1ab4-e4f9-4906-9168-a71349e76092",
    iconSet: "Octicons",
    iconName: "briefcase"
  },
  {
    uuid: "faabc88b-0266-4888-948d-14b2498021ac",
    iconSet: "Octicons",
    iconName: "broadcast"
  },
  {
    uuid: "d1509ea9-d8e8-4d41-af1d-c787644efc58",
    iconSet: "Octicons",
    iconName: "browser"
  },
  {
    uuid: "bd5f9735-76c3-4b16-9b54-4a5d39fb6085",
    iconSet: "Octicons",
    iconName: "bug"
  },
  {
    uuid: "4eea13bc-633a-467f-a8f2-76539cff476d",
    iconSet: "Octicons",
    iconName: "calendar"
  },
  {
    uuid: "ab9f035a-e5b1-4217-8044-d3353c0b04b9",
    iconSet: "Octicons",
    iconName: "check"
  },
  {
    uuid: "1df8e239-b6a9-41b2-8800-f2cb147a06dc",
    iconSet: "Octicons",
    iconName: "checklist"
  },
  {
    uuid: "8f381f60-319d-4dfe-ab42-88214a5095bd",
    iconSet: "Octicons",
    iconName: "chevron-down"
  },
  {
    uuid: "7712cfca-b022-42e5-b434-84ae25a5a3bb",
    iconSet: "Octicons",
    iconName: "chevron-left"
  },
  {
    uuid: "9de24334-8894-40e1-b738-f20db5c46548",
    iconSet: "Octicons",
    iconName: "chevron-right"
  },
  {
    uuid: "d74d2794-b1c5-4003-8d57-2d4935d7fa8f",
    iconSet: "Octicons",
    iconName: "chevron-up"
  },
  {
    uuid: "28ac9811-2cc2-4ec1-b933-29ac77798ead",
    iconSet: "Octicons",
    iconName: "circle-slash"
  },
  {
    uuid: "a8d8d962-a867-4341-bd3f-7412d3b84107",
    iconSet: "Octicons",
    iconName: "circuit-board"
  },
  {
    uuid: "1a0d075e-97f6-4b07-86f9-0f7d316adb32",
    iconSet: "Octicons",
    iconName: "clippy"
  },
  {
    uuid: "cc7fb955-8fc3-4d03-b15a-bf58b3956075",
    iconSet: "Octicons",
    iconName: "clock"
  },
  {
    uuid: "330dbc61-fa11-4323-924a-e9520456346c",
    iconSet: "Octicons",
    iconName: "cloud-download"
  },
  {
    uuid: "d537f27d-9a3a-4049-98ce-93759a880d7f",
    iconSet: "Octicons",
    iconName: "cloud-upload"
  },
  {
    uuid: "0dfd3afb-156e-4168-9b74-a3caf161319e",
    iconSet: "Octicons",
    iconName: "code"
  },
  {
    uuid: "8f0be961-717f-4944-b882-a90f56a42bf6",
    iconSet: "Octicons",
    iconName: "comment"
  },
  {
    uuid: "5261186e-b966-4441-83fe-038feb4f7065",
    iconSet: "Octicons",
    iconName: "comment-discussion"
  },
  {
    uuid: "8016e0c2-a46c-4168-8bec-a284cb6fa269",
    iconSet: "Octicons",
    iconName: "credit-card"
  },
  {
    uuid: "8bb9dbb0-c0b6-477b-8142-6fd19763ba48",
    iconSet: "Octicons",
    iconName: "dash"
  },
  {
    uuid: "cb246ce4-a46d-4830-bc48-527357392824",
    iconSet: "Octicons",
    iconName: "dashboard"
  },
  {
    uuid: "1e3ddf9b-f01e-4232-b02c-d20449028f0a",
    iconSet: "Octicons",
    iconName: "database"
  },
  {
    uuid: "ffd1ce03-6b7d-4846-8d97-736715e6e328",
    iconSet: "Octicons",
    iconName: "desktop-download"
  },
  {
    uuid: "94e9d2ea-554f-4385-87c7-dd100a91ed94",
    iconSet: "Octicons",
    iconName: "device-camera"
  },
  {
    uuid: "6ca32791-eb72-4e59-a3d1-1648f674486d",
    iconSet: "Octicons",
    iconName: "device-camera-video"
  },
  {
    uuid: "88b78c09-51ba-4850-b15a-1ec4109019dc",
    iconSet: "Octicons",
    iconName: "device-desktop"
  },
  {
    uuid: "3476776c-b149-42ee-81a1-736c4fdea5ed",
    iconSet: "Octicons",
    iconName: "device-mobile"
  },
  {
    uuid: "a3e65078-849f-4a20-a90b-4d2e8b2279c2",
    iconSet: "Octicons",
    iconName: "diff"
  },
  {
    uuid: "c0e66185-791c-4a5e-b107-95e9e5fa89e9",
    iconSet: "Octicons",
    iconName: "diff-added"
  },
  {
    uuid: "60fb01f9-7892-4b55-908e-f30a0a2480f9",
    iconSet: "Octicons",
    iconName: "diff-ignored"
  },
  {
    uuid: "1cd484da-749e-43ed-adf7-967c9d84b8e9",
    iconSet: "Octicons",
    iconName: "diff-modified"
  },
  {
    uuid: "3de9e2e6-b063-4876-9747-a17fbc238968",
    iconSet: "Octicons",
    iconName: "diff-removed"
  },
  {
    uuid: "055eb621-9294-42e1-b03a-e814af69640a",
    iconSet: "Octicons",
    iconName: "diff-renamed"
  },
  {
    uuid: "d43642d6-b271-440f-86d7-794b8d671988",
    iconSet: "Octicons",
    iconName: "ellipsis"
  },
  {
    uuid: "f8f37c49-ec21-4d5c-b8be-48951e527d86",
    iconSet: "Octicons",
    iconName: "eye"
  },
  {
    uuid: "a9456e05-e3bb-4359-be16-03c7bf2840cb",
    iconSet: "Octicons",
    iconName: "eye-closed"
  },
  {
    uuid: "10fe5051-c651-4c05-a8e8-cf76b83c3687",
    iconSet: "Octicons",
    iconName: "file"
  },
  {
    uuid: "6cfa042a-630b-4662-8387-1d3247390714",
    iconSet: "Octicons",
    iconName: "file-binary"
  },
  {
    uuid: "541e3357-5251-41ec-af74-c818e4aa09d9",
    iconSet: "Octicons",
    iconName: "file-code"
  },
  {
    uuid: "a13678cf-9746-4390-b7ce-61e7deaa5a38",
    iconSet: "Octicons",
    iconName: "file-directory"
  },
  {
    uuid: "acb12483-c29c-4e9c-b63d-f00d23526cde",
    iconSet: "Octicons",
    iconName: "file-media"
  },
  {
    uuid: "ff41a010-a1bd-4787-9b40-31b8dd10fbbf",
    iconSet: "Octicons",
    iconName: "file-pdf"
  },
  {
    uuid: "47900586-93c4-43f5-8a63-91409551cd42",
    iconSet: "Octicons",
    iconName: "file-submodule"
  },
  {
    uuid: "df775939-6f29-4f93-b80f-59b4bde69e04",
    iconSet: "Octicons",
    iconName: "file-symlink-directory"
  },
  {
    uuid: "35eda201-d151-4f44-8fd7-df428541d928",
    iconSet: "Octicons",
    iconName: "file-symlink-file"
  },
  {
    uuid: "a19660d4-3914-4cc6-a35a-b26fb12dd2c0",
    iconSet: "Octicons",
    iconName: "file-zip"
  },
  {
    uuid: "fc4be97c-e166-4a32-bc28-5747d6ecde14",
    iconSet: "Octicons",
    iconName: "flame"
  },
  {
    uuid: "b3e878db-16a2-49c8-9fe0-62fb18f117c4",
    iconSet: "Octicons",
    iconName: "fold"
  },
  {
    uuid: "79ccefb9-e2b0-4aff-9379-d5d4c7a9bc45",
    iconSet: "Octicons",
    iconName: "fold-down"
  },
  {
    uuid: "b4092c47-7b2e-47cf-9513-78ba739e07bd",
    iconSet: "Octicons",
    iconName: "fold-up"
  },
  {
    uuid: "6ec8d3da-7fdf-4bcb-9426-189892ce4b7f",
    iconSet: "Octicons",
    iconName: "gear"
  },
  {
    uuid: "ac33f152-a317-4fba-904f-7eafdb5583a1",
    iconSet: "Octicons",
    iconName: "gift"
  },
  {
    uuid: "814a662b-2770-4d9e-a1b6-ca0fa61e9c2a",
    iconSet: "Octicons",
    iconName: "gist"
  },
  {
    uuid: "af55dfbb-4384-400d-9298-17bfba7c2013",
    iconSet: "Octicons",
    iconName: "gist-secret"
  },
  {
    uuid: "0bbc1bdd-aa5c-4519-b506-ce83c7b37700",
    iconSet: "Octicons",
    iconName: "git-branch"
  },
  {
    uuid: "df952e57-16ac-419f-b673-c4502d7fd480",
    iconSet: "Octicons",
    iconName: "git-commit"
  },
  {
    uuid: "83e0d006-8b71-4656-8024-2736b0e488ab",
    iconSet: "Octicons",
    iconName: "git-compare"
  },
  {
    uuid: "740ec44b-ee63-4cba-b12b-608c933f5012",
    iconSet: "Octicons",
    iconName: "git-merge"
  },
  {
    uuid: "5ace8787-6311-4930-8d30-2ad7050556f9",
    iconSet: "Octicons",
    iconName: "git-pull-request"
  },
  {
    uuid: "a615b08a-9329-42dc-a3a6-a98c9ad5329f",
    iconSet: "Octicons",
    iconName: "github-action"
  },
  {
    uuid: "4f430f9f-68b3-4769-8293-04d19510aff0",
    iconSet: "Octicons",
    iconName: "globe"
  },
  {
    uuid: "3ade3185-c3fa-494c-88b3-fa81ece80025",
    iconSet: "Octicons",
    iconName: "grabber"
  },
  {
    uuid: "32a031e6-4543-4a86-a4de-840f507d0b1c",
    iconSet: "Octicons",
    iconName: "graph"
  },
  {
    uuid: "ccab4602-c0d3-4a72-a752-0e0963e4df73",
    iconSet: "Octicons",
    iconName: "heart"
  },
  {
    uuid: "a0d4a8c3-8fff-44d1-87b6-f6538da49f82",
    iconSet: "Octicons",
    iconName: "history"
  },
  {
    uuid: "c362af4e-5ed9-4d3a-82f9-d29725dfec33",
    iconSet: "Octicons",
    iconName: "home"
  },
  {
    uuid: "c54fabe9-442b-48ac-91ce-66b295360dae",
    iconSet: "Octicons",
    iconName: "horizontal-rule"
  },
  {
    uuid: "441c1b61-a839-4741-9a10-d7f61cbebf49",
    iconSet: "Octicons",
    iconName: "hubot"
  },
  {
    uuid: "7e0a9c3c-5d31-407d-86ef-c90b0960c5b9",
    iconSet: "Octicons",
    iconName: "inbox"
  },
  {
    uuid: "8d85a0ce-7c72-4072-8bb8-f071f17d333e",
    iconSet: "Octicons",
    iconName: "info"
  },
  {
    uuid: "69dd3f01-97d6-4e01-8194-bf8890acebee",
    iconSet: "Octicons",
    iconName: "issue-closed"
  },
  {
    uuid: "5cb5ee5e-fce6-4261-96c7-3930c395d150",
    iconSet: "Octicons",
    iconName: "issue-opened"
  },
  {
    uuid: "5421793b-e6e3-49ee-a0dc-7d988d7c53a6",
    iconSet: "Octicons",
    iconName: "issue-reopened"
  },
  {
    uuid: "2c54389e-ccb3-4e9b-947d-f76eb32617ec",
    iconSet: "Octicons",
    iconName: "italic"
  },
  {
    uuid: "9ee30325-6020-4b2a-8c88-a89c51804813",
    iconSet: "Octicons",
    iconName: "jersey"
  },
  {
    uuid: "cb465614-5f8b-460b-b26d-6ab71e09363c",
    iconSet: "Octicons",
    iconName: "kebab-horizontal"
  },
  {
    uuid: "1217908f-eefd-4eb2-a548-fd9cc544760b",
    iconSet: "Octicons",
    iconName: "kebab-vertical"
  },
  {
    uuid: "1fe0faea-c31b-4e10-a4c5-fc36206bfc5a",
    iconSet: "Octicons",
    iconName: "key"
  },
  {
    uuid: "71e33ae9-6564-4efe-9d22-6b40b13195a3",
    iconSet: "Octicons",
    iconName: "keyboard"
  },
  {
    uuid: "86a68cf6-0b38-464b-a200-3ce4cac0a85a",
    iconSet: "Octicons",
    iconName: "law"
  },
  {
    uuid: "dc6eb9f4-3017-411c-888b-b1514c5995e3",
    iconSet: "Octicons",
    iconName: "light-bulb"
  },
  {
    uuid: "e851e9a5-a9dc-4bd6-a63d-5a2eba974f4e",
    iconSet: "Octicons",
    iconName: "link"
  },
  {
    uuid: "8ead4c8c-0cf4-4df0-aae6-df3649e7a9e5",
    iconSet: "Octicons",
    iconName: "link-external"
  },
  {
    uuid: "99a2ad62-bed6-4f30-926c-1afcda6570fe",
    iconSet: "Octicons",
    iconName: "list-ordered"
  },
  {
    uuid: "55e55f8d-b6ab-418d-9a09-af49f16be744",
    iconSet: "Octicons",
    iconName: "list-unordered"
  },
  {
    uuid: "2a0efce8-a793-473d-afb1-8d47929ddb23",
    iconSet: "Octicons",
    iconName: "location"
  },
  {
    uuid: "dab96368-be4b-49b5-8450-4c700176c3f1",
    iconSet: "Octicons",
    iconName: "lock"
  },
  {
    uuid: "d4408266-2e06-4590-b79e-13a4ac41a9e0",
    iconSet: "Octicons",
    iconName: "logo-gist"
  },
  {
    uuid: "80a0d6aa-a3eb-40fe-a2b6-a7ac4a653125",
    iconSet: "Octicons",
    iconName: "logo-github"
  },
  {
    uuid: "66031e3e-4c90-40eb-8211-7c8bc7f72145",
    iconSet: "Octicons",
    iconName: "mail"
  },
  {
    uuid: "673b902c-bac9-4b75-b451-7f3a6385f107",
    iconSet: "Octicons",
    iconName: "mail-read"
  },
  {
    uuid: "70d25718-c6df-4b7d-9776-e7831c23a625",
    iconSet: "Octicons",
    iconName: "mark-github"
  },
  {
    uuid: "67021b5d-da37-4202-802f-1c3970ea9c5d",
    iconSet: "Octicons",
    iconName: "markdown"
  },
  {
    uuid: "5259cad8-577c-4428-9962-1fb8e67b3ff0",
    iconSet: "Octicons",
    iconName: "megaphone"
  },
  {
    uuid: "cccfce16-53d7-4ef4-b27b-679d117d6614",
    iconSet: "Octicons",
    iconName: "mention"
  },
  {
    uuid: "99fc0e32-dccb-4efc-979d-e248d9e64d6e",
    iconSet: "Octicons",
    iconName: "milestone"
  },
  {
    uuid: "186ebe37-7161-4730-9b47-188c7f2c7ae4",
    iconSet: "Octicons",
    iconName: "mirror"
  },
  {
    uuid: "da759810-7ade-495e-b621-ab19ec4458db",
    iconSet: "Octicons",
    iconName: "mortar-board"
  },
  {
    uuid: "0342162b-9938-41b1-83d3-4fa80a0324ae",
    iconSet: "Octicons",
    iconName: "mute"
  },
  {
    uuid: "19324117-ef78-4965-b78a-0805ffc0f92d",
    iconSet: "Octicons",
    iconName: "no-newline"
  },
  {
    uuid: "11ec6ceb-ddf5-45f5-bcfb-9863552dccfa",
    iconSet: "Octicons",
    iconName: "note"
  },
  {
    uuid: "bcae8f6b-b098-44a8-8e88-bf4ab745bf97",
    iconSet: "Octicons",
    iconName: "octoface"
  },
  {
    uuid: "dda8e7da-36ef-4175-8446-8690a1ff4db5",
    iconSet: "Octicons",
    iconName: "organization"
  },
  {
    uuid: "8ffa9e7f-a725-47fb-ab45-2ba650568064",
    iconSet: "Octicons",
    iconName: "package"
  },
  {
    uuid: "c32aabd5-70a4-49b8-84d8-1efa377f942c",
    iconSet: "Octicons",
    iconName: "paintcan"
  },
  {
    uuid: "0aa60790-aa52-42b6-a1e8-fb7a09cf9fe7",
    iconSet: "Octicons",
    iconName: "pencil"
  },
  {
    uuid: "b2208ef6-bf02-40f1-8e2e-b406c82ad4e3",
    iconSet: "Octicons",
    iconName: "person"
  },
  {
    uuid: "af093cee-8f50-4bbb-8051-deb1dd7dfd1e",
    iconSet: "Octicons",
    iconName: "pin"
  },
  {
    uuid: "c8fbfd54-e3df-4ae9-8ef4-0cc546ca2c37",
    iconSet: "Octicons",
    iconName: "play"
  },
  {
    uuid: "31d39f17-e398-4c66-8350-7b2292b5042f",
    iconSet: "Octicons",
    iconName: "plug"
  },
  {
    uuid: "6e2873e3-cbaa-4c39-bab9-ae4a7c8936c8",
    iconSet: "Octicons",
    iconName: "plus"
  },
  {
    uuid: "5c0e346b-86ec-4f37-8019-7672b7c1f86e",
    iconSet: "Octicons",
    iconName: "plus-small"
  },
  {
    uuid: "aad73584-b82c-46bf-b706-d6886f95f68e",
    iconSet: "Octicons",
    iconName: "primitive-dot"
  },
  {
    uuid: "c9270102-3940-44f9-957b-8dd5f7e8c444",
    iconSet: "Octicons",
    iconName: "primitive-square"
  },
  {
    uuid: "2c6d7c16-7f95-439f-8899-af7c81259f2d",
    iconSet: "Octicons",
    iconName: "project"
  },
  {
    uuid: "83275855-53f5-410a-82e1-96f64b1104ce",
    iconSet: "Octicons",
    iconName: "pulse"
  },
  {
    uuid: "40952e52-4958-4513-be95-688bd865de76",
    iconSet: "Octicons",
    iconName: "question"
  },
  {
    uuid: "550bd70e-ad36-4eb9-bdc0-1d46b5914d0f",
    iconSet: "Octicons",
    iconName: "quote"
  },
  {
    uuid: "eb1eca52-026c-4fd8-ad26-819a0f81b0db",
    iconSet: "Octicons",
    iconName: "radio-tower"
  },
  {
    uuid: "da57b446-df1b-4089-a15d-5593efba9a0f",
    iconSet: "Octicons",
    iconName: "reply"
  },
  {
    uuid: "cd7765bb-3ba7-42b5-8421-0f8e393902d8",
    iconSet: "Octicons",
    iconName: "repo"
  },
  {
    uuid: "8561b36b-3b12-4ffc-8b99-b71b47f90676",
    iconSet: "Octicons",
    iconName: "repo-clone"
  },
  {
    uuid: "47431f49-5363-49a1-ba7f-d3331400afce",
    iconSet: "Octicons",
    iconName: "repo-force-push"
  },
  {
    uuid: "22eeceab-a35f-4204-a6d1-6149f70823a5",
    iconSet: "Octicons",
    iconName: "repo-forked"
  },
  {
    uuid: "e6385163-a7bc-4561-85a0-b9bb558e9a85",
    iconSet: "Octicons",
    iconName: "repo-pull"
  },
  {
    uuid: "ab87537b-4fa3-44ef-9884-d9ef4ecca7b2",
    iconSet: "Octicons",
    iconName: "repo-push"
  },
  {
    uuid: "2e1e145c-5e53-4ebd-8964-6047febee753",
    iconSet: "Octicons",
    iconName: "report"
  },
  {
    uuid: "ce84dff9-2f61-4a9a-afd9-6258a1bc4ba4",
    iconSet: "Octicons",
    iconName: "request-changes"
  },
  {
    uuid: "3a88e5b0-cf44-4b37-9304-d549f2c1639e",
    iconSet: "Octicons",
    iconName: "rocket"
  },
  {
    uuid: "b9858a30-a93d-44a4-8774-7739c96847a8",
    iconSet: "Octicons",
    iconName: "rss"
  },
  {
    uuid: "d331b55c-3df3-4c7b-a5f0-3b68706004db",
    iconSet: "Octicons",
    iconName: "ruby"
  },
  {
    uuid: "58b825c5-720e-4bef-8173-dd3d501733da",
    iconSet: "Octicons",
    iconName: "screen-full"
  },
  {
    uuid: "15bfeda6-b281-4e32-8d1b-8b67a8172cdf",
    iconSet: "Octicons",
    iconName: "screen-normal"
  },
  {
    uuid: "958f10e9-f8da-4e7a-8804-b5a2380f582b",
    iconSet: "Octicons",
    iconName: "search"
  },
  {
    uuid: "da81ecfc-be41-455e-88b2-14a3461cc766",
    iconSet: "Octicons",
    iconName: "server"
  },
  {
    uuid: "c9f633f9-4428-4d8e-b8ba-3b75faa66007",
    iconSet: "Octicons",
    iconName: "settings"
  },
  {
    uuid: "9c08a435-7baf-42dc-ba0c-ba7953e900f8",
    iconSet: "Octicons",
    iconName: "shield"
  },
  {
    uuid: "b67bdef6-ee67-4505-9f1b-61bd8da5fc79",
    iconSet: "Octicons",
    iconName: "sign-in"
  },
  {
    uuid: "e33a6338-56bd-4024-ae61-9306a73e1e1b",
    iconSet: "Octicons",
    iconName: "sign-out"
  },
  {
    uuid: "7be38ec1-f9b4-4592-acb2-c89b09359655",
    iconSet: "Octicons",
    iconName: "smiley"
  },
  {
    uuid: "cdb1f455-e28f-46f3-b37b-3b7d86d4fd19",
    iconSet: "Octicons",
    iconName: "squirrel"
  },
  {
    uuid: "bc61b694-1e92-418d-a65b-828d4645aab0",
    iconSet: "Octicons",
    iconName: "star"
  },
  {
    uuid: "36db2e50-2331-450e-a42c-c559d0ba9a6f",
    iconSet: "Octicons",
    iconName: "stop"
  },
  {
    uuid: "eef39cea-f7cc-4d11-b636-4acc8c5f191a",
    iconSet: "Octicons",
    iconName: "sync"
  },
  {
    uuid: "5fe55803-9646-4846-9fee-350c4dfdd5f8",
    iconSet: "Octicons",
    iconName: "tag"
  },
  {
    uuid: "f93eac88-7dc1-49de-9cc7-556f10459ba2",
    iconSet: "Octicons",
    iconName: "tasklist"
  },
  {
    uuid: "5a6bbc32-a70a-4d36-a2ad-add1d18e90c8",
    iconSet: "Octicons",
    iconName: "telescope"
  },
  {
    uuid: "df8f61b6-5c38-4063-b076-0589632c98cd",
    iconSet: "Octicons",
    iconName: "terminal"
  },
  {
    uuid: "75e1c27e-2f8d-4e53-8212-ecb1d8d10540",
    iconSet: "Octicons",
    iconName: "text-size"
  },
  {
    uuid: "d1fb3089-76e5-4ba8-8879-c326805a2c4e",
    iconSet: "Octicons",
    iconName: "three-bars"
  },
  {
    uuid: "5eeed3e8-579b-4796-80f2-af86e87fd82b",
    iconSet: "Octicons",
    iconName: "thumbsdown"
  },
  {
    uuid: "62de7c11-2bca-43b9-afce-b7fbf51fbf9f",
    iconSet: "Octicons",
    iconName: "thumbsup"
  },
  {
    uuid: "ff6cd2ae-9827-4549-aa24-ea4d167f67e3",
    iconSet: "Octicons",
    iconName: "tools"
  },
  {
    uuid: "e98d03ac-eedc-4ab1-8811-035668507f83",
    iconSet: "Octicons",
    iconName: "trashcan"
  },
  {
    uuid: "41ec3b72-c043-47d6-8d54-6cd42701cc6a",
    iconSet: "Octicons",
    iconName: "triangle-down"
  },
  {
    uuid: "02b882ea-c193-46b2-8690-36d75ecc0555",
    iconSet: "Octicons",
    iconName: "triangle-left"
  },
  {
    uuid: "24d5fe62-30c1-4d71-ab4f-0729d35d84a8",
    iconSet: "Octicons",
    iconName: "triangle-right"
  },
  {
    uuid: "783bf450-7437-4c51-81c9-786185ac3bfc",
    iconSet: "Octicons",
    iconName: "triangle-up"
  },
  {
    uuid: "3a74d7b2-1648-4a93-b631-ccab7e779109",
    iconSet: "Octicons",
    iconName: "unfold"
  },
  {
    uuid: "7183adc7-4b84-4f41-aaeb-bb2e5fdbc8c1",
    iconSet: "Octicons",
    iconName: "unmute"
  },
  {
    uuid: "4570c411-a140-4e0a-90d6-adbe61186e68",
    iconSet: "Octicons",
    iconName: "unverified"
  },
  {
    uuid: "af1cc9e8-3795-47bd-b538-d3245880d782",
    iconSet: "Octicons",
    iconName: "verified"
  },
  {
    uuid: "e1a2b478-0c59-48e3-b00b-e29947d6eefb",
    iconSet: "Octicons",
    iconName: "versions"
  },
  {
    uuid: "33b386c5-6c9e-4d32-8fad-c8a81e7a0895",
    iconSet: "Octicons",
    iconName: "watch"
  },
  {
    uuid: "aa149ca4-4c0d-4a50-b442-5541da551067",
    iconSet: "Octicons",
    iconName: "x"
  },
  {
    uuid: "92e96487-225f-435a-8055-56bdb28e333b",
    iconSet: "Octicons",
    iconName: "zap"
  },
  {
    uuid: "68590fb0-0047-4ab2-a0db-8f013ed5f203",
    iconSet: "SimpleLineIcons",
    iconName: "user"
  },
  {
    uuid: "932f0ca7-8054-411d-82f3-0611a20e9529",
    iconSet: "SimpleLineIcons",
    iconName: "people"
  },
  {
    uuid: "374f745a-80ee-4823-b539-d4a6774e6ddc",
    iconSet: "SimpleLineIcons",
    iconName: "user-female"
  },
  {
    uuid: "ef9c4be0-3849-4df5-9576-d2add5741af5",
    iconSet: "SimpleLineIcons",
    iconName: "user-follow"
  },
  {
    uuid: "b834682b-25a5-4e68-b9b6-93733280212d",
    iconSet: "SimpleLineIcons",
    iconName: "user-following"
  },
  {
    uuid: "020adc3b-8b71-4020-9eac-19315805dd15",
    iconSet: "SimpleLineIcons",
    iconName: "user-unfollow"
  },
  {
    uuid: "cb8b6636-682a-4ec4-8272-2f9ff57b5480",
    iconSet: "SimpleLineIcons",
    iconName: "login"
  },
  {
    uuid: "6377ec3a-ad9a-4681-8453-2589bfdcbc3b",
    iconSet: "SimpleLineIcons",
    iconName: "logout"
  },
  {
    uuid: "1779e63e-1bd4-4e3b-b205-1a54eb9ec309",
    iconSet: "SimpleLineIcons",
    iconName: "emotsmile"
  },
  {
    uuid: "3e38e67c-51ab-481b-a8a7-87f7779b79c0",
    iconSet: "SimpleLineIcons",
    iconName: "phone"
  },
  {
    uuid: "1873d520-ad4d-4caa-964f-76a742e301b4",
    iconSet: "SimpleLineIcons",
    iconName: "call-end"
  },
  {
    uuid: "8b8cca80-1363-413a-b769-1ff06c116dba",
    iconSet: "SimpleLineIcons",
    iconName: "call-in"
  },
  {
    uuid: "999c5d3e-eabc-49a3-9d8b-3a154f09de26",
    iconSet: "SimpleLineIcons",
    iconName: "call-out"
  },
  {
    uuid: "202b9e88-344d-4a82-b33c-4ecb9330a686",
    iconSet: "SimpleLineIcons",
    iconName: "map"
  },
  {
    uuid: "2162e04e-7d96-4db1-8bf4-9bf9e5209205",
    iconSet: "SimpleLineIcons",
    iconName: "location-pin"
  },
  {
    uuid: "55897899-26be-42af-a927-de067ce512a0",
    iconSet: "SimpleLineIcons",
    iconName: "direction"
  },
  {
    uuid: "68ae5c88-d669-4a82-80c4-c8a0a9a197c5",
    iconSet: "SimpleLineIcons",
    iconName: "directions"
  },
  {
    uuid: "64f0bf4f-d835-4bea-8930-bb0772e6db3d",
    iconSet: "SimpleLineIcons",
    iconName: "compass"
  },
  {
    uuid: "a7030fd5-82f8-46ba-b0c9-25d54c757f9e",
    iconSet: "SimpleLineIcons",
    iconName: "layers"
  },
  {
    uuid: "52e20e2f-4f6d-41fe-a2c5-2d807486ab6e",
    iconSet: "SimpleLineIcons",
    iconName: "menu"
  },
  {
    uuid: "42bb391b-14a0-46a8-a7cc-4e6aa65a9fda",
    iconSet: "SimpleLineIcons",
    iconName: "list"
  },
  {
    uuid: "ea21b6bf-3a23-4ab2-a72c-a7fa56ad4d35",
    iconSet: "SimpleLineIcons",
    iconName: "options-vertical"
  },
  {
    uuid: "ee02ca2b-0d6a-4eec-b909-d34a737c598e",
    iconSet: "SimpleLineIcons",
    iconName: "options"
  },
  {
    uuid: "c429c3b2-8a0d-4242-a7ae-200e679d5efa",
    iconSet: "SimpleLineIcons",
    iconName: "arrow-down"
  },
  {
    uuid: "9698daea-950a-4737-b2b4-a13e57ab1075",
    iconSet: "SimpleLineIcons",
    iconName: "arrow-left"
  },
  {
    uuid: "84a8b9a4-58c7-4604-a89e-172bed9b0a33",
    iconSet: "SimpleLineIcons",
    iconName: "arrow-right"
  },
  {
    uuid: "595ebcab-052b-4661-9430-9a5e890f3939",
    iconSet: "SimpleLineIcons",
    iconName: "arrow-up"
  },
  {
    uuid: "07a588d9-59a6-4700-a76b-519d42fb7992",
    iconSet: "SimpleLineIcons",
    iconName: "arrow-up-circle"
  },
  {
    uuid: "a73c69e9-a610-48ec-ad38-f19162140bd2",
    iconSet: "SimpleLineIcons",
    iconName: "arrow-left-circle"
  },
  {
    uuid: "85d927e9-b52a-4390-9508-460991561f5f",
    iconSet: "SimpleLineIcons",
    iconName: "arrow-right-circle"
  },
  {
    uuid: "3e5f4d98-229a-4264-8f30-da7c03443336",
    iconSet: "SimpleLineIcons",
    iconName: "arrow-down-circle"
  },
  {
    uuid: "afad2a99-f62b-402c-beec-d4784abce6c5",
    iconSet: "SimpleLineIcons",
    iconName: "check"
  },
  {
    uuid: "0064a062-5e7b-46e7-914d-2dbea7c70f3d",
    iconSet: "SimpleLineIcons",
    iconName: "clock"
  },
  {
    uuid: "4283a662-94ae-42e9-92e6-a003a1e2913a",
    iconSet: "SimpleLineIcons",
    iconName: "plus"
  },
  {
    uuid: "21a3eff9-7159-4ed2-a85e-536a1ce2f6ed",
    iconSet: "SimpleLineIcons",
    iconName: "minus"
  },
  {
    uuid: "623257b6-12cd-4f86-b96f-451a39670390",
    iconSet: "SimpleLineIcons",
    iconName: "close"
  },
  {
    uuid: "e9d99768-bb2b-409c-9984-a5fcab1a7873",
    iconSet: "SimpleLineIcons",
    iconName: "event"
  },
  {
    uuid: "88713c9e-0aca-44f8-8d29-39b93a231cc1",
    iconSet: "SimpleLineIcons",
    iconName: "exclamation"
  },
  {
    uuid: "5908a7bb-197f-4165-9bf9-ea8a8a121108",
    iconSet: "SimpleLineIcons",
    iconName: "organization"
  },
  {
    uuid: "433657f5-57fc-421c-a392-b8f3a9c07db4",
    iconSet: "SimpleLineIcons",
    iconName: "trophy"
  },
  {
    uuid: "74d58205-f281-4563-a2ae-9314cfdf4abf",
    iconSet: "SimpleLineIcons",
    iconName: "screen-smartphone"
  },
  {
    uuid: "5d849db2-1d12-4cbe-b780-465c46d5aa95",
    iconSet: "SimpleLineIcons",
    iconName: "screen-desktop"
  },
  {
    uuid: "5211f196-899b-449d-8eaa-786633c66dc6",
    iconSet: "SimpleLineIcons",
    iconName: "plane"
  },
  {
    uuid: "eaea00a8-dc9f-4e94-a3fb-cb0c81685e0d",
    iconSet: "SimpleLineIcons",
    iconName: "notebook"
  },
  {
    uuid: "aac1da38-e154-4654-90fa-a220658bf5cc",
    iconSet: "SimpleLineIcons",
    iconName: "mustache"
  },
  {
    uuid: "36f98d02-3cdc-451e-bc0f-01116de98808",
    iconSet: "SimpleLineIcons",
    iconName: "mouse"
  },
  {
    uuid: "33cb5ed2-8420-4ac3-bba8-ad581fa599fb",
    iconSet: "SimpleLineIcons",
    iconName: "magnet"
  },
  {
    uuid: "6b45cc53-2b10-49b8-b737-fc742e9b8fcc",
    iconSet: "SimpleLineIcons",
    iconName: "energy"
  },
  {
    uuid: "149ebddb-15f5-4480-835d-a2124c63214a",
    iconSet: "SimpleLineIcons",
    iconName: "disc"
  },
  {
    uuid: "72d709bc-88d6-4196-855e-121a322e41d5",
    iconSet: "SimpleLineIcons",
    iconName: "cursor"
  },
  {
    uuid: "8e0a4f9c-8ba4-4711-9aaa-d75cef492e7d",
    iconSet: "SimpleLineIcons",
    iconName: "cursor-move"
  },
  {
    uuid: "91d202c3-e077-4aec-ab24-af105d4741bd",
    iconSet: "SimpleLineIcons",
    iconName: "crop"
  },
  {
    uuid: "82c5af22-c6b1-44a7-a68e-938ee4a7c65e",
    iconSet: "SimpleLineIcons",
    iconName: "chemistry"
  },
  {
    uuid: "133af33b-9a75-46aa-bf59-02a606a9cc2f",
    iconSet: "SimpleLineIcons",
    iconName: "speedometer"
  },
  {
    uuid: "8375885f-e50f-4898-a7b6-a3d1abbb9257",
    iconSet: "SimpleLineIcons",
    iconName: "shield"
  },
  {
    uuid: "fa177faa-c080-409e-bed2-4636d5ad5648",
    iconSet: "SimpleLineIcons",
    iconName: "screen-tablet"
  },
  {
    uuid: "b1753275-cf39-405b-836e-12101c7ca1e6",
    iconSet: "SimpleLineIcons",
    iconName: "magic-wand"
  },
  {
    uuid: "8d5ffb08-596a-4915-8a11-ecbf2c63c50c",
    iconSet: "SimpleLineIcons",
    iconName: "hourglass"
  },
  {
    uuid: "0971fedc-84aa-42df-8687-71f23805841c",
    iconSet: "SimpleLineIcons",
    iconName: "graduation"
  },
  {
    uuid: "47fae106-e37c-4eea-86ea-fd170780aa3d",
    iconSet: "SimpleLineIcons",
    iconName: "ghost"
  },
  {
    uuid: "7bf3370c-b2b9-4af7-bd1c-624f5ea9f151",
    iconSet: "SimpleLineIcons",
    iconName: "game-controller"
  },
  {
    uuid: "ef869a31-7bbf-4c3b-ba6a-e2b5c7d6d02d",
    iconSet: "SimpleLineIcons",
    iconName: "fire"
  },
  {
    uuid: "ec9266ad-ecac-48e5-be1c-06e2121d8d07",
    iconSet: "SimpleLineIcons",
    iconName: "eyeglass"
  },
  {
    uuid: "b5a6165a-2a56-4d01-a3cd-8ab0e0563092",
    iconSet: "SimpleLineIcons",
    iconName: "envelope-open"
  },
  {
    uuid: "b60e3253-ba4c-4f6e-bf06-cf583240ec49",
    iconSet: "SimpleLineIcons",
    iconName: "envelope-letter"
  },
  {
    uuid: "514b4774-0c70-4db0-b9b4-c2982cf3a328",
    iconSet: "SimpleLineIcons",
    iconName: "bell"
  },
  {
    uuid: "acef10cc-235c-4671-8864-95b6ca727ad9",
    iconSet: "SimpleLineIcons",
    iconName: "badge"
  },
  {
    uuid: "3150e2c3-8366-4cac-a4b6-7a63ed1f3af4",
    iconSet: "SimpleLineIcons",
    iconName: "anchor"
  },
  {
    uuid: "cb4136dd-b32f-4038-a936-fbf926382a8f",
    iconSet: "SimpleLineIcons",
    iconName: "wallet"
  },
  {
    uuid: "fd7aee61-46ba-435b-a201-45297e14bf15",
    iconSet: "SimpleLineIcons",
    iconName: "vector"
  },
  {
    uuid: "d9be8979-b6ea-42ce-8c41-ca5013eb6f3f",
    iconSet: "SimpleLineIcons",
    iconName: "speech"
  },
  {
    uuid: "16e69a9b-21f0-4902-8af8-5bb23b2d8ed4",
    iconSet: "SimpleLineIcons",
    iconName: "puzzle"
  },
  {
    uuid: "690e7426-0105-4c02-bc22-2f727630fd06",
    iconSet: "SimpleLineIcons",
    iconName: "printer"
  },
  {
    uuid: "5d8389a0-3207-4b77-b84d-f82074e1d14e",
    iconSet: "SimpleLineIcons",
    iconName: "present"
  },
  {
    uuid: "700ac5db-aa99-460a-886c-8cf47c625dba",
    iconSet: "SimpleLineIcons",
    iconName: "playlist"
  },
  {
    uuid: "7f6b7e17-e352-4ed0-a199-2325d1aa64ca",
    iconSet: "SimpleLineIcons",
    iconName: "pin"
  },
  {
    uuid: "e8cc1c99-4776-4dcc-a709-ae9b17a3a8c3",
    iconSet: "SimpleLineIcons",
    iconName: "picture"
  },
  {
    uuid: "58fb17c5-f060-467f-ba29-31bc1498f600",
    iconSet: "SimpleLineIcons",
    iconName: "handbag"
  },
  {
    uuid: "5d6b20dc-6ddd-4306-a95a-52e22a3e6425",
    iconSet: "SimpleLineIcons",
    iconName: "globe-alt"
  },
  {
    uuid: "64b74860-6064-4a25-858b-6df39a95915b",
    iconSet: "SimpleLineIcons",
    iconName: "globe"
  },
  {
    uuid: "03968484-ae1f-4511-937f-cf4902a818d8",
    iconSet: "SimpleLineIcons",
    iconName: "folder-alt"
  },
  {
    uuid: "a3c9b247-8e67-471c-85e2-a247e381193a",
    iconSet: "SimpleLineIcons",
    iconName: "folder"
  },
  {
    uuid: "c9f6ab06-5533-4b65-8e25-c4cadfa87498",
    iconSet: "SimpleLineIcons",
    iconName: "film"
  },
  {
    uuid: "82c94d26-60a6-4ba4-bd99-afe726694a79",
    iconSet: "SimpleLineIcons",
    iconName: "feed"
  },
  {
    uuid: "2f22db33-91af-473b-ab40-a04c37a40827",
    iconSet: "SimpleLineIcons",
    iconName: "drop"
  },
  {
    uuid: "4dfc53ff-a056-4545-981c-548c46c80ce9",
    iconSet: "SimpleLineIcons",
    iconName: "drawer"
  },
  {
    uuid: "8782c10c-06b7-4782-94eb-6431415d823c",
    iconSet: "SimpleLineIcons",
    iconName: "docs"
  },
  {
    uuid: "ad57183b-d429-4c76-96df-1beb263d7508",
    iconSet: "SimpleLineIcons",
    iconName: "doc"
  },
  {
    uuid: "dc915374-b9d6-4884-b57e-62b8beb124b7",
    iconSet: "SimpleLineIcons",
    iconName: "diamond"
  },
  {
    uuid: "98bb85cd-c495-4400-afe5-1d04e7109b67",
    iconSet: "SimpleLineIcons",
    iconName: "cup"
  },
  {
    uuid: "90b2913c-0156-404e-809c-bd77e0c50228",
    iconSet: "SimpleLineIcons",
    iconName: "calculator"
  },
  {
    uuid: "7d541f3e-dbb1-4a0f-b686-1a75fa0ce813",
    iconSet: "SimpleLineIcons",
    iconName: "bubbles"
  },
  {
    uuid: "a68499ae-51b1-4aa5-9be5-1d5febaaff81",
    iconSet: "SimpleLineIcons",
    iconName: "briefcase"
  },
  {
    uuid: "b43cacef-f6f7-4afe-87a3-e20ba5b18a0b",
    iconSet: "SimpleLineIcons",
    iconName: "book-open"
  },
  {
    uuid: "067c9130-b609-4bc9-a7d1-c94e5b70e384",
    iconSet: "SimpleLineIcons",
    iconName: "basket-loaded"
  },
  {
    uuid: "ce36f5af-e899-4b9c-8ccf-30d56729ac7b",
    iconSet: "SimpleLineIcons",
    iconName: "basket"
  },
  {
    uuid: "b72f2a0b-e7d2-495f-bff5-eb056cbbaef6",
    iconSet: "SimpleLineIcons",
    iconName: "bag"
  },
  {
    uuid: "b9bdf867-9462-46e9-bf81-c284dd8a0300",
    iconSet: "SimpleLineIcons",
    iconName: "action-undo"
  },
  {
    uuid: "fca937fe-b243-4197-b313-051d58b06258",
    iconSet: "SimpleLineIcons",
    iconName: "action-redo"
  },
  {
    uuid: "512734b9-b753-45fd-a0c1-727c546b55b2",
    iconSet: "SimpleLineIcons",
    iconName: "wrench"
  },
  {
    uuid: "260e8e8e-8b6a-4547-a792-97f7fc0a3f62",
    iconSet: "SimpleLineIcons",
    iconName: "umbrella"
  },
  {
    uuid: "08c09a46-8d9d-44ca-9918-aaa689186a3c",
    iconSet: "SimpleLineIcons",
    iconName: "trash"
  },
  {
    uuid: "e9d83c1d-f458-4fa4-b0ab-f9e563b11a2b",
    iconSet: "SimpleLineIcons",
    iconName: "tag"
  },
  {
    uuid: "4a216606-08e3-4beb-b4f2-da0795c27b6b",
    iconSet: "SimpleLineIcons",
    iconName: "support"
  },
  {
    uuid: "225a7316-34e0-4894-a900-b94f7a713956",
    iconSet: "SimpleLineIcons",
    iconName: "frame"
  },
  {
    uuid: "e48d2284-b5ce-4008-8e2a-5f032e3f1887",
    iconSet: "SimpleLineIcons",
    iconName: "size-fullscreen"
  },
  {
    uuid: "0c6647d0-3913-4f49-89ff-f011130e63ab",
    iconSet: "SimpleLineIcons",
    iconName: "size-actual"
  },
  {
    uuid: "c880063a-c658-4254-8261-9ef28f61f841",
    iconSet: "SimpleLineIcons",
    iconName: "shuffle"
  },
  {
    uuid: "098ded10-de8f-4d4e-8bda-f982cb865c3a",
    iconSet: "SimpleLineIcons",
    iconName: "share-alt"
  },
  {
    uuid: "f867a545-1f44-4199-b840-70068cfa0bf2",
    iconSet: "SimpleLineIcons",
    iconName: "share"
  },
  {
    uuid: "727d59e5-eb22-4a02-8d3b-84214423f2f8",
    iconSet: "SimpleLineIcons",
    iconName: "rocket"
  },
  {
    uuid: "7967b43f-353d-472c-8297-5624d4f67175",
    iconSet: "SimpleLineIcons",
    iconName: "question"
  },
  {
    uuid: "983f19fb-9a46-48cf-ae1a-8ee0bd1bf79f",
    iconSet: "SimpleLineIcons",
    iconName: "pie-chart"
  },
  {
    uuid: "6350c40f-3fcf-420a-8c56-e2a3404d37ec",
    iconSet: "SimpleLineIcons",
    iconName: "pencil"
  },
  {
    uuid: "e6be2d4f-41b0-4ba0-9e64-64276f16e55f",
    iconSet: "SimpleLineIcons",
    iconName: "note"
  },
  {
    uuid: "2a9300e2-5dc8-489a-ae6c-5c3d281d2992",
    iconSet: "SimpleLineIcons",
    iconName: "loop"
  },
  {
    uuid: "fe2cf53b-4e11-4919-b638-07d72766f55f",
    iconSet: "SimpleLineIcons",
    iconName: "home"
  },
  {
    uuid: "aea5d8d1-e786-47d7-8203-fa196410f823",
    iconSet: "SimpleLineIcons",
    iconName: "grid"
  },
  {
    uuid: "10ef82ac-3c10-4b4b-a069-8939418a8289",
    iconSet: "SimpleLineIcons",
    iconName: "graph"
  },
  {
    uuid: "0aace1d0-89a9-4861-8dde-fdb4e5a12542",
    iconSet: "SimpleLineIcons",
    iconName: "microphone"
  },
  {
    uuid: "9fb62546-0e32-4ba3-9a12-afbc990f0a20",
    iconSet: "SimpleLineIcons",
    iconName: "music-tone-alt"
  },
  {
    uuid: "37a304d3-76b7-471c-81d6-1e450bb19bb2",
    iconSet: "SimpleLineIcons",
    iconName: "music-tone"
  },
  {
    uuid: "8385a39f-dd7a-4903-a018-9627399c98f2",
    iconSet: "SimpleLineIcons",
    iconName: "earphones-alt"
  },
  {
    uuid: "e93f5fd7-02cd-495b-a553-110d7e0f0306",
    iconSet: "SimpleLineIcons",
    iconName: "earphones"
  },
  {
    uuid: "58e0cd13-6e60-4eea-9bfa-63fbca0eeb9f",
    iconSet: "SimpleLineIcons",
    iconName: "equalizer"
  },
  {
    uuid: "bc7fbe16-63dd-4cf5-b2d7-496727e0392f",
    iconSet: "SimpleLineIcons",
    iconName: "like"
  },
  {
    uuid: "90f78e5f-00c1-4f62-bb95-cda4efc6deca",
    iconSet: "SimpleLineIcons",
    iconName: "dislike"
  },
  {
    uuid: "ad3fd395-8183-4cac-99de-a6c0fe89538f",
    iconSet: "SimpleLineIcons",
    iconName: "control-start"
  },
  {
    uuid: "738ff8f2-d162-40d5-953f-49949d956737",
    iconSet: "SimpleLineIcons",
    iconName: "control-rewind"
  },
  {
    uuid: "bf308844-7ef9-4f10-ad39-2c0c3353a563",
    iconSet: "SimpleLineIcons",
    iconName: "control-play"
  },
  {
    uuid: "bfbfdc60-991f-47b5-9f51-cba4492690d4",
    iconSet: "SimpleLineIcons",
    iconName: "control-pause"
  },
  {
    uuid: "381f5b14-1d1d-4dd0-95c9-411c638c1260",
    iconSet: "SimpleLineIcons",
    iconName: "control-forward"
  },
  {
    uuid: "401473b4-8b7a-40f2-9daa-7e4082fd1be4",
    iconSet: "SimpleLineIcons",
    iconName: "control-end"
  },
  {
    uuid: "c59de70e-ba9c-4bb4-9fbc-49ada683d98d",
    iconSet: "SimpleLineIcons",
    iconName: "volume-1"
  },
  {
    uuid: "595e49e7-f92d-42fc-b6e7-2d42fd7553fc",
    iconSet: "SimpleLineIcons",
    iconName: "volume-2"
  },
  {
    uuid: "d52ff13b-02f9-421a-a6f1-11234c880a23",
    iconSet: "SimpleLineIcons",
    iconName: "volume-off"
  },
  {
    uuid: "047464c3-031c-4d75-944e-c3b201558656",
    iconSet: "SimpleLineIcons",
    iconName: "calendar"
  },
  {
    uuid: "7bf941e3-5429-4668-9a83-42ef8d6e4918",
    iconSet: "SimpleLineIcons",
    iconName: "bulb"
  },
  {
    uuid: "6eb2d924-eb88-45f7-86d0-a8725a5303b4",
    iconSet: "SimpleLineIcons",
    iconName: "chart"
  },
  {
    uuid: "1068eed8-f4c9-46bf-bf03-de40fdb76a48",
    iconSet: "SimpleLineIcons",
    iconName: "ban"
  },
  {
    uuid: "2f6ea822-cd37-4def-a73a-a631da12829d",
    iconSet: "SimpleLineIcons",
    iconName: "bubble"
  },
  {
    uuid: "b8e6c78d-ea95-4517-bead-45ae8517f0d6",
    iconSet: "SimpleLineIcons",
    iconName: "camrecorder"
  },
  {
    uuid: "9880eb52-29a0-4773-952b-3a4376e03e4e",
    iconSet: "SimpleLineIcons",
    iconName: "camera"
  },
  {
    uuid: "29eb2b0e-02ec-4b00-b548-cdc27576247c",
    iconSet: "SimpleLineIcons",
    iconName: "cloud-download"
  },
  {
    uuid: "8efd4de8-a204-42f7-a49a-9583582c9333",
    iconSet: "SimpleLineIcons",
    iconName: "cloud-upload"
  },
  {
    uuid: "43855ddd-e0af-4474-8515-327e1727f77d",
    iconSet: "SimpleLineIcons",
    iconName: "envelope"
  },
  {
    uuid: "454548c2-f6f2-4e8d-96a9-12f727a2e1b3",
    iconSet: "SimpleLineIcons",
    iconName: "eye"
  },
  {
    uuid: "9963ee98-5507-498f-aa39-96c210c55dba",
    iconSet: "SimpleLineIcons",
    iconName: "flag"
  },
  {
    uuid: "30230e60-d1a8-4758-b01d-08c38acfb37c",
    iconSet: "SimpleLineIcons",
    iconName: "heart"
  },
  {
    uuid: "991af1e3-302b-4bde-9ddd-0e6099e969a7",
    iconSet: "SimpleLineIcons",
    iconName: "info"
  },
  {
    uuid: "f70f1e49-c7cd-4177-a76c-df930577501d",
    iconSet: "SimpleLineIcons",
    iconName: "key"
  },
  {
    uuid: "e55bc1eb-bfb0-4064-9141-be399c282432",
    iconSet: "SimpleLineIcons",
    iconName: "link"
  },
  {
    uuid: "a0a9a368-bcd5-40c8-a9f5-0be19d932720",
    iconSet: "SimpleLineIcons",
    iconName: "lock"
  },
  {
    uuid: "ecfd059b-f6f6-4881-a854-81a3152bfb96",
    iconSet: "SimpleLineIcons",
    iconName: "lock-open"
  },
  {
    uuid: "6810570f-8530-4248-8131-c53fa1a81e43",
    iconSet: "SimpleLineIcons",
    iconName: "magnifier"
  },
  {
    uuid: "4759ed26-d13a-4913-ae80-4a7b5a08272b",
    iconSet: "SimpleLineIcons",
    iconName: "magnifier-add"
  },
  {
    uuid: "0904eae8-b994-4385-8d09-a794628a8f19",
    iconSet: "SimpleLineIcons",
    iconName: "magnifier-remove"
  },
  {
    uuid: "458d4fa0-082a-4a74-b7b2-2030626c536f",
    iconSet: "SimpleLineIcons",
    iconName: "paper-clip"
  },
  {
    uuid: "8bb24270-659e-4af4-b839-474a29d05f88",
    iconSet: "SimpleLineIcons",
    iconName: "paper-plane"
  },
  {
    uuid: "ef5a766f-a98f-454f-845d-4b227f6ae6fe",
    iconSet: "SimpleLineIcons",
    iconName: "power"
  },
  {
    uuid: "1c5264cf-c445-4e55-a423-0e28cae81074",
    iconSet: "SimpleLineIcons",
    iconName: "refresh"
  },
  {
    uuid: "567c7c44-51a1-490c-99ee-fa50831be0fb",
    iconSet: "SimpleLineIcons",
    iconName: "reload"
  },
  {
    uuid: "0b383384-ef26-46b7-8dc0-2e97f95cb210",
    iconSet: "SimpleLineIcons",
    iconName: "settings"
  },
  {
    uuid: "74d62a7c-1862-42aa-8963-05915dc887ed",
    iconSet: "SimpleLineIcons",
    iconName: "star"
  },
  {
    uuid: "af0ccdf4-3e85-4e27-adaf-6559ae815d9c",
    iconSet: "SimpleLineIcons",
    iconName: "symbol-female"
  },
  {
    uuid: "f5956310-2027-4cdd-9af0-210dad548f67",
    iconSet: "SimpleLineIcons",
    iconName: "symbol-male"
  },
  {
    uuid: "155fe0a4-8183-4342-9a21-11122ba16cdb",
    iconSet: "SimpleLineIcons",
    iconName: "target"
  },
  {
    uuid: "5464fda8-cc33-4740-a750-b1a4b8e159f2",
    iconSet: "SimpleLineIcons",
    iconName: "credit-card"
  },
  {
    uuid: "6bfd3680-0af0-41c2-abd4-9e41683ddd44",
    iconSet: "SimpleLineIcons",
    iconName: "paypal"
  },
  {
    uuid: "825909e6-7ea2-43d2-a6a1-0f542f76ed54",
    iconSet: "SimpleLineIcons",
    iconName: "social-tumblr"
  },
  {
    uuid: "1102e649-f872-45ab-8b9a-b85aca587f42",
    iconSet: "SimpleLineIcons",
    iconName: "social-twitter"
  },
  {
    uuid: "627e3f9d-a491-4d71-9f08-ec000346a59e",
    iconSet: "SimpleLineIcons",
    iconName: "social-facebook"
  },
  {
    uuid: "c27b20a7-9c72-4869-ac6e-c7d7e55aa5fa",
    iconSet: "SimpleLineIcons",
    iconName: "social-instagram"
  },
  {
    uuid: "5f848d09-4b56-49e9-8ed7-11893876a6ac",
    iconSet: "SimpleLineIcons",
    iconName: "social-linkedin"
  },
  {
    uuid: "4e5e0f0a-c554-49f1-85d7-3242084e4615",
    iconSet: "SimpleLineIcons",
    iconName: "social-pinterest"
  },
  {
    uuid: "1617fdf0-ea06-4c8c-9777-44d4e99b2868",
    iconSet: "SimpleLineIcons",
    iconName: "social-github"
  },
  {
    uuid: "d200a4dc-97f7-4468-b096-29eb55bbebee",
    iconSet: "SimpleLineIcons",
    iconName: "social-google"
  },
  {
    uuid: "9a2be409-ee3c-46b8-98fc-7c1644fccb55",
    iconSet: "SimpleLineIcons",
    iconName: "social-reddit"
  },
  {
    uuid: "f621e949-f953-4b61-901a-50402b79f91b",
    iconSet: "SimpleLineIcons",
    iconName: "social-skype"
  },
  {
    uuid: "696738b3-614d-4d2e-8289-f1422e5b72f3",
    iconSet: "SimpleLineIcons",
    iconName: "social-dribbble"
  },
  {
    uuid: "baebadf1-0d87-465d-9509-db413d836397",
    iconSet: "SimpleLineIcons",
    iconName: "social-behance"
  },
  {
    uuid: "fb7d4a36-2c48-4787-b333-a827481e4901",
    iconSet: "SimpleLineIcons",
    iconName: "social-foursqare"
  },
  {
    uuid: "fcc052b3-5920-44d0-9e27-14ea63d9f38f",
    iconSet: "SimpleLineIcons",
    iconName: "social-soundcloud"
  },
  {
    uuid: "1f61ff84-9330-4f87-bfcb-9462161637f1",
    iconSet: "SimpleLineIcons",
    iconName: "social-spotify"
  },
  {
    uuid: "9a1103b7-53dd-42c3-8c46-adf885fcdfd9",
    iconSet: "SimpleLineIcons",
    iconName: "social-stumbleupon"
  },
  {
    uuid: "b7bd799e-ecf6-48d6-9a06-9783f74a488c",
    iconSet: "SimpleLineIcons",
    iconName: "social-youtube"
  },
  {
    uuid: "d457813a-7109-48aa-a45b-9f2fbccd9885",
    iconSet: "SimpleLineIcons",
    iconName: "social-dropbox"
  },
  {
    uuid: "0cec7ac9-cf93-4b58-89b1-c683060a4ebe",
    iconSet: "SimpleLineIcons",
    iconName: "social-vkontakte"
  },
  {
    uuid: "13b1c34d-3f8b-4e7b-b472-23fe63e51813",
    iconSet: "SimpleLineIcons",
    iconName: "social-steam"
  },
  {
    uuid: "c3034b65-1342-4997-bc2f-3ab0eb270cf8",
    iconSet: "Zocial",
    iconName: "acrobat"
  },
  {
    uuid: "aa237bfd-62aa-43a3-98fd-6210f52be415",
    iconSet: "Zocial",
    iconName: "amazon"
  },
  {
    uuid: "abd05a5c-4eb8-4a51-b14f-1888c8703c25",
    iconSet: "Zocial",
    iconName: "android"
  },
  {
    uuid: "e05603aa-d47a-48f2-a6f5-49d2ea11779f",
    iconSet: "Zocial",
    iconName: "angellist"
  },
  {
    uuid: "a752264f-a438-40de-a23e-0c837107c113",
    iconSet: "Zocial",
    iconName: "aol"
  },
  {
    uuid: "85e4e2c4-1b8f-4383-b8ff-ba2eecae5c3c",
    iconSet: "Zocial",
    iconName: "appnet"
  },
  {
    uuid: "82c2d719-6639-4d2c-a510-0fa02f71a7dd",
    iconSet: "Zocial",
    iconName: "appstore"
  },
  {
    uuid: "6ea29d1e-f476-4c7e-9daf-349f1db2e2e5",
    iconSet: "Zocial",
    iconName: "bitbucket"
  },
  {
    uuid: "d79a8953-e9b5-41cd-9b2a-957719b3b60b",
    iconSet: "Zocial",
    iconName: "bitcoin"
  },
  {
    uuid: "6af5eaf6-a093-4bd8-a53e-bc1f5bf1a217",
    iconSet: "Zocial",
    iconName: "blogger"
  },
  {
    uuid: "cb497726-df9c-4dce-844f-7f6ae0dda49d",
    iconSet: "Zocial",
    iconName: "buffer"
  },
  {
    uuid: "8a60e6a6-dc65-480d-9ffd-71ca21c5d713",
    iconSet: "Zocial",
    iconName: "cal"
  },
  {
    uuid: "d71c5eb3-6e42-4b9b-bacc-70ac34ee10d1",
    iconSet: "Zocial",
    iconName: "call"
  },
  {
    uuid: "de9f2dc9-ad2c-42e4-9f51-25b5c252488d",
    iconSet: "Zocial",
    iconName: "cart"
  },
  {
    uuid: "d8678ddb-7deb-4d17-b77b-9341d6667338",
    iconSet: "Zocial",
    iconName: "chrome"
  },
  {
    uuid: "38ec23c5-2dd6-4665-a797-8a21fc89cc78",
    iconSet: "Zocial",
    iconName: "cloudapp"
  },
  {
    uuid: "5e33911e-cf99-4f5a-87b2-f4bedafbf9ff",
    iconSet: "Zocial",
    iconName: "creativecommons"
  },
  {
    uuid: "0748de15-89ea-45dd-ab69-c65ce8e357e3",
    iconSet: "Zocial",
    iconName: "delicious"
  },
  {
    uuid: "f5cf1ee6-5cc2-47a7-8e35-e92e237f76a1",
    iconSet: "Zocial",
    iconName: "digg"
  },
  {
    uuid: "af354047-4431-44df-806e-e483022f0358",
    iconSet: "Zocial",
    iconName: "disqus"
  },
  {
    uuid: "678f0aba-6599-4e5f-8023-b280bbaa4d97",
    iconSet: "Zocial",
    iconName: "dribbble"
  },
  {
    uuid: "6ace0149-327d-49e5-b905-b654b08c0500",
    iconSet: "Zocial",
    iconName: "dropbox"
  },
  {
    uuid: "c119c073-cc4c-4602-b6ba-5d293882523c",
    iconSet: "Zocial",
    iconName: "drupal"
  },
  {
    uuid: "11cd58e4-92df-492a-abc6-8e534c154731",
    iconSet: "Zocial",
    iconName: "dwolla"
  },
  {
    uuid: "33235524-f286-4505-a135-d71aaa593de9",
    iconSet: "Zocial",
    iconName: "email"
  },
  {
    uuid: "7739a25f-8bbe-4d7f-b49b-1c88e3bc4d8a",
    iconSet: "Zocial",
    iconName: "eventasaurus"
  },
  {
    uuid: "395dc041-20a9-4e42-bbb7-1569ea28fcbf",
    iconSet: "Zocial",
    iconName: "eventbrite"
  },
  {
    uuid: "15041eb6-bd29-4c7d-b2e2-d89e54f65cbb",
    iconSet: "Zocial",
    iconName: "eventful"
  },
  {
    uuid: "42db2e10-24d7-47bb-9405-83bced8aac4a",
    iconSet: "Zocial",
    iconName: "evernote"
  },
  {
    uuid: "bb5bfbe8-468e-44bb-b8af-ffe032b5a147",
    iconSet: "Zocial",
    iconName: "facebook"
  },
  {
    uuid: "5fea677e-58fe-48a9-b619-a9f8923d591e",
    iconSet: "Zocial",
    iconName: "fivehundredpx"
  },
  {
    uuid: "d6570b89-3739-4fef-94c7-605b556dcb15",
    iconSet: "Zocial",
    iconName: "flattr"
  },
  {
    uuid: "8f4b9bb6-1858-43fa-acc9-ad0f3faead14",
    iconSet: "Zocial",
    iconName: "flickr"
  },
  {
    uuid: "8fc30bc0-11e7-48b1-b5b7-4745a4598ed9",
    iconSet: "Zocial",
    iconName: "forrst"
  },
  {
    uuid: "06e12eb8-10f1-444b-9bcb-2779ff0bdc35",
    iconSet: "Zocial",
    iconName: "foursquare"
  },
  {
    uuid: "f4f00e59-0fb9-4c63-99e4-deef2c89176c",
    iconSet: "Zocial",
    iconName: "github"
  },
  {
    uuid: "bee7b0a7-295e-4916-81a5-0cbaf824887e",
    iconSet: "Zocial",
    iconName: "gmail"
  },
  {
    uuid: "ca96a4ee-dd51-4de2-8bf3-765654c6375b",
    iconSet: "Zocial",
    iconName: "google"
  },
  {
    uuid: "3ce90ccb-174b-4fa8-9128-ea39e8c4052e",
    iconSet: "Zocial",
    iconName: "googleplay"
  },
  {
    uuid: "bcb75eef-ff50-4550-b908-ea0d2b7e1f7a",
    iconSet: "Zocial",
    iconName: "googleplus"
  },
  {
    uuid: "c397e743-ddc3-40c0-a681-8181388ebfa5",
    iconSet: "Zocial",
    iconName: "gowalla"
  },
  {
    uuid: "736046ef-907a-4262-869a-52c369ee096d",
    iconSet: "Zocial",
    iconName: "grooveshark"
  },
  {
    uuid: "ae237d20-2c59-4c52-b101-45649d178ca5",
    iconSet: "Zocial",
    iconName: "guest"
  },
  {
    uuid: "9853aff9-f3f1-44e8-997b-953997e535fd",
    iconSet: "Zocial",
    iconName: "html5"
  },
  {
    uuid: "41085acc-3df3-457f-8d9d-d46b11c0111a",
    iconSet: "Zocial",
    iconName: "ie"
  },
  {
    uuid: "5b5f6688-f8e0-4047-bd8d-4df0cd21a832",
    iconSet: "Zocial",
    iconName: "instagram"
  },
  {
    uuid: "fedcb4d1-3fbc-40de-a08c-1605932533c3",
    iconSet: "Zocial",
    iconName: "instapaper"
  },
  {
    uuid: "189888db-f108-452d-bf6b-2623e004ef04",
    iconSet: "Zocial",
    iconName: "intensedebate"
  },
  {
    uuid: "713ab8f4-6f62-4c42-950e-b628237c2f0d",
    iconSet: "Zocial",
    iconName: "itunes"
  },
  {
    uuid: "625b4523-c4d3-45f0-80f8-6d1fcc6833ae",
    iconSet: "Zocial",
    iconName: "klout"
  },
  {
    uuid: "2b803112-6f24-4d93-904a-f08382290b14",
    iconSet: "Zocial",
    iconName: "lanyrd"
  },
  {
    uuid: "9a63fe5d-0557-43b6-ae04-097722691cbc",
    iconSet: "Zocial",
    iconName: "lastfm"
  },
  {
    uuid: "2176f899-9eed-4a26-b66c-83fa7c5041d7",
    iconSet: "Zocial",
    iconName: "lego"
  },
  {
    uuid: "a69c73bb-41d9-426c-bcb5-fe15cc8d1029",
    iconSet: "Zocial",
    iconName: "linkedin"
  },
  {
    uuid: "9e0521a4-f368-4401-aae4-af69030c6d3f",
    iconSet: "Zocial",
    iconName: "lkdto"
  },
  {
    uuid: "07d211c0-b9d7-4bf3-b09c-72edce5198b1",
    iconSet: "Zocial",
    iconName: "logmein"
  },
  {
    uuid: "5724af36-717c-442a-9fd9-3e684f341a5e",
    iconSet: "Zocial",
    iconName: "macstore"
  },
  {
    uuid: "1967b983-c4e0-47b0-b70e-fea11b3a220b",
    iconSet: "Zocial",
    iconName: "meetup"
  },
  {
    uuid: "3f23bebd-7a2c-4f02-892f-3edf5738a05a",
    iconSet: "Zocial",
    iconName: "myspace"
  },
  {
    uuid: "f34670dd-3039-4f57-ab23-4953a1e624a1",
    iconSet: "Zocial",
    iconName: "ninetyninedesigns"
  },
  {
    uuid: "7e55cdcf-475f-4782-b349-6569ce77fd4f",
    iconSet: "Zocial",
    iconName: "openid"
  },
  {
    uuid: "ac4fff83-d3c6-4804-adb9-5de2c79744c0",
    iconSet: "Zocial",
    iconName: "opentable"
  },
  {
    uuid: "e8c2b95c-881e-4efe-867c-222994348398",
    iconSet: "Zocial",
    iconName: "paypal"
  },
  {
    uuid: "6fa4aa64-b8ff-42ac-9738-1140572e2210",
    iconSet: "Zocial",
    iconName: "persona"
  },
  {
    uuid: "2085eac2-ad03-44b0-9496-8cce0de82b39",
    iconSet: "Zocial",
    iconName: "pinboard"
  },
  {
    uuid: "e0d7ac6b-20ad-4599-83b5-86f7459385fe",
    iconSet: "Zocial",
    iconName: "pinterest"
  },
  {
    uuid: "342a3bd5-eb32-4091-b634-38a1e6528b5b",
    iconSet: "Zocial",
    iconName: "plancast"
  },
  {
    uuid: "a6f2f72d-32f0-4a59-a4e8-add8308d8759",
    iconSet: "Zocial",
    iconName: "plurk"
  },
  {
    uuid: "87f1c150-e0a1-4a36-9b4f-404d6baac154",
    iconSet: "Zocial",
    iconName: "pocket"
  },
  {
    uuid: "c94096ae-a6e7-4c63-9fbf-28a026b729e8",
    iconSet: "Zocial",
    iconName: "podcast"
  },
  {
    uuid: "d0844567-9cf4-4862-a610-ccaeef76073f",
    iconSet: "Zocial",
    iconName: "posterous"
  },
  {
    uuid: "2e80c2e0-28e7-4dac-a8f9-77cdba845872",
    iconSet: "Zocial",
    iconName: "print"
  },
  {
    uuid: "c7b602ed-5ab0-4b71-85d8-33473bd59e86",
    iconSet: "Zocial",
    iconName: "quora"
  },
  {
    uuid: "9fb5e42a-4111-4725-9bb8-81d109779674",
    iconSet: "Zocial",
    iconName: "reddit"
  },
  {
    uuid: "54e4eaa8-086a-4070-8c56-729a69094a78",
    iconSet: "Zocial",
    iconName: "rss"
  },
  {
    uuid: "226fc8bd-c0f3-43af-b78e-66148d910b95",
    iconSet: "Zocial",
    iconName: "scribd"
  },
  {
    uuid: "35610f4b-8836-4188-8e7f-031ab8dc02b2",
    iconSet: "Zocial",
    iconName: "skype"
  },
  {
    uuid: "5d755cca-df5e-4cda-bc79-0bb623bc5016",
    iconSet: "Zocial",
    iconName: "smashing"
  },
  {
    uuid: "8a4824a0-06a2-42ed-be1a-cd3c01c82684",
    iconSet: "Zocial",
    iconName: "songkick"
  },
  {
    uuid: "532016e6-3b01-4561-89fd-a558c1c4f08a",
    iconSet: "Zocial",
    iconName: "soundcloud"
  },
  {
    uuid: "19edbdc8-8879-4f98-ac40-f8c38de7a571",
    iconSet: "Zocial",
    iconName: "spotify"
  },
  {
    uuid: "31c2795c-a095-4aec-b580-6c80ebb87435",
    iconSet: "Zocial",
    iconName: "stackoverflow"
  },
  {
    uuid: "53990484-a919-4593-9cdf-1bb4ef88d4e5",
    iconSet: "Zocial",
    iconName: "statusnet"
  },
  {
    uuid: "7a19d305-730b-4e7a-986d-9f9a8cc3d314",
    iconSet: "Zocial",
    iconName: "steam"
  },
  {
    uuid: "8c88f6ac-83f3-4e6a-ae94-067712e56901",
    iconSet: "Zocial",
    iconName: "stripe"
  },
  {
    uuid: "29bfaf97-8bbd-4ab9-888d-c6fc5f33deab",
    iconSet: "Zocial",
    iconName: "stumbleupon"
  },
  {
    uuid: "eabfa2cf-eacd-4daa-ba53-2ab79686d65f",
    iconSet: "Zocial",
    iconName: "tumblr"
  },
  {
    uuid: "30145b3f-56e6-473d-a7fa-958f8ef3dd11",
    iconSet: "Zocial",
    iconName: "twitter"
  },
  {
    uuid: "2141cfbd-ff04-4669-b7d0-ccb8641b1779",
    iconSet: "Zocial",
    iconName: "viadeo"
  },
  {
    uuid: "73a0a16a-b411-4e1e-93d4-c0757841eaa4",
    iconSet: "Zocial",
    iconName: "vimeo"
  },
  {
    uuid: "e64f930d-260b-4cd1-bcce-ea6e8a7bb283",
    iconSet: "Zocial",
    iconName: "vk"
  },
  {
    uuid: "8965cfc2-ccd8-44f5-9acc-55048cce0268",
    iconSet: "Zocial",
    iconName: "weibo"
  },
  {
    uuid: "3ab42e93-47b8-4585-b9e4-d9ccc8d1f446",
    iconSet: "Zocial",
    iconName: "wikipedia"
  },
  {
    uuid: "b24354ea-bd72-467c-8190-6483a10df68e",
    iconSet: "Zocial",
    iconName: "windows"
  },
  {
    uuid: "af91ed52-e8c4-4b3d-b9bc-a59db0180c53",
    iconSet: "Zocial",
    iconName: "wordpress"
  },
  {
    uuid: "1f84f581-d5bd-47db-82e3-f4e804746f7f",
    iconSet: "Zocial",
    iconName: "xing"
  },
  {
    uuid: "2130e9c9-d283-4141-9e8b-9250b7e0a63c",
    iconSet: "Zocial",
    iconName: "yahoo"
  },
  {
    uuid: "a902a1da-8ec8-4f4a-843f-98289194865b",
    iconSet: "Zocial",
    iconName: "ycombinator"
  },
  {
    uuid: "b70b3bf0-5dee-49f2-ba39-12342ccd8a58",
    iconSet: "Zocial",
    iconName: "yelp"
  },
  {
    uuid: "791ee241-6eae-4d48-9af5-f019bf9ff933",
    iconSet: "Zocial",
    iconName: "youtube"
  }
];
export default IconCollection;
// # sourceMappingURL=Icons.js.map
