import React, { useEffect, useState } from "react";
import {
  StyleSheet,
  Text,
  View,
  ScrollView,
  Image,
  ActivityIndicator,
  TouchableOpacity,
  Platform
} from "react-native";
import Glob from "src/globalConstants";
import Database from "src/backend/database";
import Analytics from "src/backend/analytics";
import NavBar from "src/components/navBar";
import HelpText from "src/components/HelpText";
import Moment from "moment";
import Util from "src/utility";
import ExportOrdersButton from "src/components/ExportOrdersButton";
import Rex from "src/globalState";
import SearchBar from "src/components/searchBar";

const { height, width } = Glob.get("dimensions");

const ProductItem = ({ product }) => (
  <View style={styles.productItem}>
    <Text style={styles.productName}>
      {product.name} {product.quantity > 1 && `(Quantity: ${product.quantity})`}{" "}
      •{" "}
      <Text style={styles.productDetails}>
        ${(product.amount / 100).toFixed(2)}
      </Text>
    </Text>
    {product.description && (
      <Text style={styles.productDescription}>{product.description}</Text>
    )}
  </View>
);

const OrderCard = ({ order, navigation, user }) => {
  const questionResponses = JSON.parse(
    order.metadata?.questionResponses || "{}"
  );
  const handleUserPress = async () => {
    if (user) {
      navigation.push("manageUser", {
        user,
        disableUserEditing: true
      });
    } else {
      Util.alert(
        "User Not Found",
        "Unable to retrieve user details (double-check that this user hasn't been deleted)."
      );
    }
  };

  return (
    <View style={styles.orderCard}>
      <View style={styles.orderHeader}>
        <Text style={styles.orderDate}>
          {Moment(order.timestamp).format("MMM D, YYYY h:mm A")}
        </Text>
        <Text style={styles.orderPrice}>${(order.price / 100).toFixed(2)}</Text>
      </View>
      <View style={styles.orderContentWrapper}>
        <TouchableOpacity
          onPress={handleUserPress}
          style={styles.customerInfoWrapper}
        >
          <View style={styles.orderDetails}>
            <Text
              style={[
                styles.customerName,
                { color: Rex.getConfig()?.colors?.text || "#2D3748" }
              ]}
            >
              {user
                ? `${user.firstName} ${user.lastName}`
                : "(No customer name found)"}
            </Text>
            <Text style={styles.customerEmail}>
              {user ? user.email : "(No email found)"}
            </Text>
          </View>
        </TouchableOpacity>
        <View style={styles.productListWrapper}>
          <View style={styles.productList}>
            {order.products.map((product, index) => (
              <ProductItem key={`${order.id}-${index}`} product={product} />
            ))}
          </View>
        </View>
        {Object.keys(questionResponses).length > 0 && (
          <View style={styles.questionResponses}>
            {Object.entries(questionResponses).map(
              ([question, response], index) => (
                <View key={index} style={styles.questionResponseItem}>
                  <Text style={styles.question}>{question}</Text>
                  <Text style={styles.response}>{response}</Text>
                </View>
              )
            )}
          </View>
        )}
      </View>
    </View>
  );
};

export default function CommerceOrderHistory({ navigation }) {
  const [loadedOrders, setLoadedOrders] = useState(false);
  const [orders, setOrders] = useState(null);
  const [filteredOrders, setFilteredOrders] = useState(null);
  const [users, setUsers] = useState({});

  useEffect(() => {
    Analytics.logEvent("view_commerceOrderHistory");
    fetchStripeOrders();
    Database.fetchAllUsers().then((allUsers) => {
      const userObj = {};
      allUsers.forEach((user) => {
        userObj[user.uid] = user;
      });
      setUsers(userObj);
    });
  }, []);

  useEffect(() => {
    if (orders) {
      setFilteredOrders(orders);
    }
  }, [orders]);

  const fetchStripeOrders = async () => {
    const stripeOrders = await Database.fetchStripeConnectedAccountOrders();
    if (stripeOrders && Array.isArray(stripeOrders)) {
      const ordersWithSearchableText = stripeOrders.map((order) => ({
        ...order,
        searchableText: createSearchableText(order)
      }));
      setOrders(ordersWithSearchableText);
    }
    setLoadedOrders(true);
  };

  const createSearchableText = (order) => {
    const user = users[order.metadata?.userID];
    const customerName = user ? `${user.firstName} ${user.lastName}` : "";
    const customerEmail = user?.email || "";
    const orderDate = Moment(order.timestamp).format("MMM D, YYYY h:mm A");
    const orderPrice = (order.price / 100).toFixed(2);
    const productNames = order.products
      .map((product) => product.name)
      .join(" ");
    const productDescriptions = order.products
      .map((product) => product.description)
      .join(" ");
    const questionResponses = JSON.parse(
      order.metadata?.questionResponses || "{}"
    );
    const questionResponsesText = Object.entries(questionResponses)
      .map(([question, response]) => `${question}: ${response}`)
      .join(" ");
    return `${customerName} ${customerEmail} ${orderDate} ${orderPrice} ${productNames} ${productDescriptions} ${questionResponsesText}`.toLowerCase();
  };

  const handleSearch = (query) => {
    if (query.trim() === "") {
      setFilteredOrders(orders);
    } else {
      const searchResults = Util.searchItems(orders, query, "searchableText");
      setFilteredOrders(searchResults);
    }
  };

  if (!loadedOrders)
    return (
      <View style={styles.pageContent}>
        <NavBar navigation={navigation} text="Order History" />
        <ScrollView style={{ paddingHorizontal: 20, width }}>
          <ActivityIndicator size="large" style={{ marginTop: 80 }} />
        </ScrollView>
      </View>
    );

  if (!orders || orders.length < 1)
    return (
      <View style={[styles.pageContent, { backgroundColor: "white" }]}>
        <NavBar navigation={navigation} text="Order History" />
        <ScrollView
          style={{ paddingHorizontal: 20, width }}
          scrollIndicatorInsets={{ right: 1 }}
        >
          <Image
            source={Glob.get("cardboardBoxOpen")}
            style={{
              height: height * 0.3,
              width: height * 0.3,
              resizeMode: "contain",
              alignSelf: "center"
            }}
          />
          <Text
            style={{
              fontWeight: "bold",
              fontSize: 18,
              textAlign: "center"
            }}
          >
            You haven't received any orders yet.
          </Text>
        </ScrollView>
      </View>
    );

  return (
    <View style={styles.pageContent}>
      <NavBar
        navigation={navigation}
        text="Order History"
        RightButton={
          Platform.OS === "web" ? (
            <ExportOrdersButton
              title="Order History"
              orders={filteredOrders || []}
            />
          ) : null
        }
      />
      <ScrollView
        style={styles.scrollView}
        contentContainerStyle={styles.scrollViewContent}
      >
        <HelpText
          text={`You've received ${orders.length} order${
            orders.length === 1 ? "" : "s"
          }, totaling $${orders
            .reduce((sum, order) => sum + order.price / 100, 0)
            .toFixed(2)}. ${
            Platform.OS === "web"
              ? "You can export all orders to a CSV file by clicking the button in the top-right corner."
              : `You can export all orders to a CSV file if you open this "Order History" screen in the web version of your app.`
          }`}
          noteStyle
          style={styles.helpText}
        />
        <View style={{ width: "100%", alignItems: "center", marginBottom: 12 }}>
          <SearchBar
            placeholder="Search orders..."
            onChangeText={handleSearch}
          />
        </View>
        {(filteredOrders || []).map((order) => (
          <OrderCard
            key={order.id}
            order={order}
            navigation={navigation}
            user={users[order.metadata?.userID] || null}
          />
        ))}
      </ScrollView>
    </View>
  );
}

const styles = StyleSheet.create({
  pageContent: {
    flex: 1,
    alignItems: "center",
    backgroundColor: "#F5F7FA"
  },
  scrollView: {
    width
  },
  scrollViewContent: {
    paddingHorizontal: 20,
    paddingBottom: 50
  },
  helpText: {
    marginTop: 20,
    marginBottom: 10
  },
  orderCard: {
    backgroundColor: "white",
    borderRadius: 12,
    padding: 16,
    marginBottom: 16,
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.1,
    shadowRadius: 4,
    elevation: 3,
    overflow: "hidden"
  },
  orderHeader: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 6
  },
  orderDate: {
    fontSize: 14,
    color: "#A0AEC0"
  },
  orderPrice: {
    fontSize: 18,
    fontWeight: "bold",
    color: "#2DD881"
  },
  customerName: {
    fontSize: 16,
    fontWeight: "bold",
    color: "#2D3748",
    marginBottom: 2
  },
  customerEmail: {
    fontSize: 12,
    color: "#718096"
  },
  productListHeader: {
    fontSize: 16,
    fontWeight: "600",
    color: "#2D3748",
    marginBottom: 8
  },
  productItem: {
    marginBottom: 8
  },
  productName: {
    fontSize: 14,
    fontWeight: "500",
    color: "#2D3748"
  },
  productDetails: {
    fontSize: 12,
    color: "#718096"
  },
  productDescription: {
    fontSize: 12,
    color: "#718096",
    marginTop: 2
  },
  orderContentWrapper: {
    flexDirection: Glob.deviceHasiPhoneXDimensions() ? "column" : "row"
  },
  customerInfoWrapper: {
    flex: 1,
    minWidth: 200,
    minHeight: "auto",
    paddingRight: 12,
    ...(Glob.deviceHasiPhoneXDimensions()
      ? {
          marginBottom: 12,
          borderBottomWidth: 1,
          borderBottomColor: "#E2E8F0",
          paddingBottom: 12
        }
      : {
          borderRightWidth: 1,
          borderRightColor: "#E2E8F0"
        })
  },
  productListWrapper: {
    flex: 2,
    minWidth: 300,
    ...(Glob.deviceHasiPhoneXDimensions()
      ? { paddingTop: 0 }
      : { paddingLeft: 12 })
  },
  orderDetails: {
    paddingTop: 12
  },
  productList: {
    paddingTop: Glob.deviceHasiPhoneXDimensions() ? 0 : 12
  },
  questionResponses: {
    marginTop: 12
  },
  questionResponseItem: {
    marginBottom: 8
  },
  question: {
    fontSize: 14,
    fontWeight: "500"
  },
  response: {
    fontSize: 14,
    color: "#718096",
    marginLeft: 10
  }
});
