/* global window */

import React from "react";
import {
  StyleSheet,
  Text,
  View,
  Animated,
  Dimensions,
  ImageBackground,
  TouchableHighlight,
  TouchableOpacity,
  LayoutAnimation,
  ScrollView,
  Platform,
  ActivityIndicator
} from "react-native";
import Modal from "react-native-modal";
import Constants from "expo-constants";
import * as Notifications from "expo-notifications";
import { LinearGradient } from "expo-linear-gradient";
import tinycolor from "tinycolor2";
import {
  MaterialIcons,
  MaterialCommunityIcons,
  Entypo
} from "@expo/vector-icons";
import { isIOS, isAndroid } from "react-device-detect";
import ConfettiCannon from "react-native-confetti-cannon";
import * as Linking from "expo-linking";
import * as Font from "expo-font";
import * as Network from "expo-network";
import * as Device from "expo-device";
import School from "school/school";
import Style from "src/globalStyles";
import Glob from "src/globalConstants";
import Rex from "src/globalState";
import Database from "src/backend/database";
import Analytics from "src/backend/analytics";
import Firebase from "src/backend/firebase";
import Util from "src/utility";
import CircularProgress from "src/components/CircularProgress";
import NavBar from "src/components/navBar";
import PageList from "src/components/pageList";
import SearchBar from "src/components/searchBar";
import Button from "src/components/Button";
import RootMenu from "src/screens/rootMenu";
import AlertModal from "src/components/AlertModal";
import BannerMessage from "src/components/BannerMessage";
import WebPageMetaTitle from "src/components/WebPageMetaTitle";
import HomePaginationDots from "src/components/HomePagitationDots";

const { height, width } = Dimensions.get("window");
const { width: widthWithMargins } = Glob.get("dimensions");

const hardcodedBackgroundImage = School.get("background image") || null;

const randomCelebratoryEmoji = Util.randomCelebratoryEmoji();

const APP_VERSION_SUPPORTS_IAP = Database.compareVersions(
  Glob.get("appVersion"),
  Glob.get("revenueCatMinimumAppVersion")
);

const APP_IS_DEVELOPMENT_MODE =
  Constants.expoConfig.web.config.firebase.projectId !== "seabirdmain";
const EAS_PROJECT_ID = Constants.expoConfig.extra?.eas?.projectId;

let DEVICE = {};
if (Device.isDevice) {
  const {
    brand,
    manufacturer,
    modelName,
    deviceYearClass,
    osName,
    osVersion,
    deviceName
  } = Device;
  DEVICE = {
    brand,
    deviceName,
    deviceYearClass,
    manufacturer,
    modelName,
    osName,
    osVersion,
    platformOS: Platform.OS,
    standaloneAppSlug: Constants.expoConfig.slug
  };
}

// Spring
const CustomLayoutSpring = {
  duration: 400,
  create: {
    type: LayoutAnimation.Types.spring,
    property: LayoutAnimation.Properties.scaleXY,
    springDamping: 0.7
  },
  update: {
    type: LayoutAnimation.Types.spring,
    springDamping: 0.7
  }
};

export default class Root extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fontLoaded: false, // TODO: Unused
      internetIsConnected: true,
      allUserHomePortals: [], // all the portals that the user has active, independent of any search
      filteredUserHomePortals: [],
      fadeAnim: new Animated.Value(0),
      homescreenBanner: null,
      hideHomescreenBanner: false,
      privileges: [],
      userIsAdmin: false,
      userIsDemoAccount: false,
      appsWithUnopenedNotifications: new Set(),
      appsJoined: [],
      loadedPortals: false,
      isEditing: false, // whether the user is actively customizing their home screen,
      userCustomizedHomeScreenPreviously: false,
      currentPage: 0,
      appStoreLink: null,
      appVersionRequired: null,
      backgroundImage: null,
      showTutorialBanner: false,
      showUnopenedTutorialTask: false,
      tutorialTasksRemaining: [],
      showInstallAppBanner: Platform.OS === "web",
      randomInstallAppMessage: "",
      showMenu: false,
      alert: null,
      bannerMessageKey: "bannerMessage",
      hiddenActivityFeedPortals: []
    };
    // Firebase.getUser();

    // // TODO: THIS IS FOR THE OLD VERSION
    // // read user's homeorder from database and set it in Rex to be read globally
    // Database.listenUserHomeOrder((value) => {
    //   try {
    //     if (value && value != '[]' && (typeof value === 'string' || value instanceof String)) {
    //       var portals = JSON.parse(value);
    //       this.setState({
    //           home_portals: portals,
    //           allUserHomePortals: portals,
    //       });
    //       this.setViews(portals);
    //       Rex.updateHome(portals);
    //   } else {
    //       var portals = Rex.getHomePortals();
    //       Rex.updateHome(portals);
    //       Database.setUserHomeOrder(JSON.stringify(portals));
    //       this.setState({
    //           home_portals: portals,
    //           allUserHomePortals: portals,
    //       });
    //       this.setViews(portals);
    //     }
    //   } catch(e) {
    //     console.log(e)
    //   }
    // });

    Database.getAllPortalMetadata().then((metadata) => {
      const portals = Object.entries(metadata || []).map(([key, value]) => ({
        ...value,
        navName: key
      }));
      Rex.setAllPortals(portals);
    });

    // // read allPortals homeorder and set it in Rex to be read globally (never set)
    // Database.getPortalContent('aa_HomeOrders', ( value ) => {
    //   let portals = JSON.parse(value.all);
    //   console.log('portals!');
    //   console.log(portals);
    //   Rex.setAllPortals(portals);
    // });

    // // read allPortals homeorder and set it in Rex to be read globally (never set)
    // Database.getPortalContent('aa_HomeOrders', ( value ) => {
    //   let portals = JSON.parse(value.all);
    //   Rex.setAllPortals(portals);
    // });
  }

  componentDidMount() {
    const { navigation, isFromMiniScreen, productPurchased } = this.props;
    Font.loadAsync({
      Lato: require("resources/fonts/Lato-Regular.ttf")
    }).then(() => this.setState({ fontLoaded: true }));

    if (!isFromMiniScreen) {
      Analytics.logEvent("view_root");

      Notifications.setBadgeCountAsync(0);

      // Block backwards navigation (important for Android hardware back button)
      navigation.addListener("beforeRemove", (e) => e.preventDefault());

      if (productPurchased) {
        if (productPurchased === "phoneCredits") {
          this.setState({
            alert: {
              title: "Phone credits purchased! 🎉",
              message:
                "You can check your total phone credits in Administrative Superpowers."
            }
          });
        } else {
          this.setState({
            alert: {
              title: "Your app is live! 🎉",
              message:
                "You can manage your billing in Administrative Superpowers. If there are any follow-up steps required, we'll be in touch with you within a few days."
            }
          });
        }
      }

      // If on web, update the URL to point to this app
      if (Platform.OS === "web") {
        Util.setURLQueryString(`?app=${School.getDatabaseAppID()}`);
      }

      Database.setLastViewedRootAtTimestamp();
      this.registerUserForPushNotifications().then(
        (pushToken) => {
          Analytics.logEvent("action_root_savingDeviceWithPushToken", {
            ...DEVICE,
            pushToken
          });
          return Database.setDeviceData({ ...DEVICE, pushToken });
        },
        () => {
          Analytics.logEvent(
            "action_root_savingDeviceWithoutPushToken",
            DEVICE
          );
          return Database.setDeviceData(DEVICE);
        }
      );
      Database.fetchUserPrivileges().then((privileges) => {
        this.setState({
          userIsAdmin: privileges.length > 0,
          privileges: privileges.sort()
        });
        Rex.setSessionMemory("adminPrivileges", privileges);
      });
      // Handle notifications that are received or selected while the app
      // is open. If the app was closed and then opened by tapping the
      // notification (rather than just tapping the app icon to open it),
      // this function will fire on the next tick after the app starts
      // with the notification data.
      this._notificationSubscription = Notifications.addNotificationResponseReceivedListener(
        this._handleNotification
      );

      Database.fetchAppCommunityType().then((appType) => {
        // todo: elsewhere in the code, use Rex.getConfig()?.appType instead of Database.fetchAppCommunityType
        Rex.setConfig({ ...Rex.getConfig(), appType });
      });

      const databaseAppID = School.getDatabaseAppID();
      Util.localStorageSetItemAsync("databaseAppID", databaseAppID);
      Database.fetchUserAccountFields().then(({ fieldsArray }) => {
        Database.fetchAllUserData().then((data) => {
          // If the user is somehow logged in without a /users node or without an email, log them out
          if (!data?.email) {
            Database.logoutUser()
              .then(() => Util.reloadApp())
              .catch((error) => Util.alert(`Error: ${error}`));
            return;
          }
          // Else if the user is somehow logged in without a user type, randomly pick one for them
          if (!data?.type) {
            Analytics.logEvent("error_root_userHasNoAccountType", {
              email: data?.email
            });
            Database.getAllDefaultPortals((portals) => {
              const allUserTypes = Object.keys(portals);
              if (allUserTypes && allUserTypes.length > 0)
                Database.setUserType(allUserTypes[0]);
            });
          }
          this.setState({
            userIsDemoAccount:
              Firebase.getUserID() === Glob.get("demoAccountUserID")
          });
          Database.subscribeToGlobalUser((globalUser) => {
            if (globalUser?.appsJoined) {
              let appsJoined = Object.keys(globalUser.appsJoined);
              const appsInThisMetaApp = Rex.getMetaApp()?.apps;
              if (appsInThisMetaApp) {
                appsJoined = appsJoined.filter(
                  (app) => app in appsInThisMetaApp
                );
              }
              Analytics.setUserProperties({
                appsJoined,
                appsJoinedCount: appsJoined.length
              });
              this.setState({ appsJoined });
            }
          });
          // Update the fields based on who they currently are
          Database.safelySetGlobalUser({
            email: data?.email,
            firstName: data?.firstName,
            lastName: data?.lastName
          }).then((globalUser) => {
            if (!Util.appIsStandalone() && globalUser?.appsJoined) {
              let appsJoined = Object.keys(globalUser.appsJoined);
              const appsInThisMetaApp = Rex.getMetaApp()?.apps;
              if (appsInThisMetaApp) {
                appsJoined = appsJoined.filter(
                  (app) => app in appsInThisMetaApp
                );
              }
              this.listenForUnopenedNotifications(appsJoined);
            } else {
              this.listenForUnopenedNotifications([School.getDatabaseAppID()]);
            }
          });
          const customFieldValues = {};
          fieldsArray.forEach((field) => {
            if (field.key)
              customFieldValues[field.key] = data[field.key] || null;
          });
          Analytics.setUserID();
          Analytics.setUserProperties({
            first_name: data?.firstName,
            last_name: data?.lastName,
            email: data?.email,
            type: data?.type,
            databaseAppID,
            standaloneAppSlug: Constants.expoConfig.slug,
            appVersion: Glob.get("appVersionFull"),
            organizationName: Rex.getConfig()?.names?.full,
            ...customFieldValues,
            isAdmin: Object.keys(data?.privileges || {}).length > 0
          });

          // Check if the user has tasks to complete
          Database.fetchTasks().then((tasks) => {
            // If user has customized their home screen during a previous session, we assume they understand the app enough to review it
            if (tasks?.done?.customizeHomeScreen) {
              Util.requestAppStoreReviewIfNeverRequested();
              this.setState({ userCustomizedHomeScreenPreviously: true });
            }
            if (tasks?.todo?.registerForGroups) {
              Database.removeTask("registerForGroups");
              navigation.push("editUserGroups", {
                isEntryScreen: true
              });
            }
            Database.fetchAppMetadata().then((appMetadata) => {
              // If the user is the app creator
              if (appMetadata?.creator === Firebase.getUserID()) {
                if (
                  Glob.get("appIsOnespot") &&
                  !APP_IS_DEVELOPMENT_MODE &&
                  !Glob.get("isExpoGo")
                ) {
                  try {
                    Analytics.initializeSmartlook(
                      data?.email,
                      `${data?.firstName} ${data?.lastName}`
                    );
                  } catch {
                    // Failed to initialize Smartlook (e.g. if it's a development build). Continue onward
                  }
                }

                const tasksRemaining = this.calculateTutorialTasksRemaining(
                  tasks?.done
                );
                const percentDone = this.calculatePercentTutorialDone(
                  tasksRemaining
                );
                Analytics.setUserProperties({
                  appCreationPercentDone: percentDone
                });
                // If the only remaining tasks were added to the code more recently (Nov 7, 2023), don't show the banner
                const remainingTaskIsNewer =
                  tasksRemaining.length === 1 &&
                  tasksRemaining[0] === "viewAllMembers";
                if (percentDone < 100 && !remainingTaskIsNewer) {
                  Analytics.logEvent("action_root_showTutorialBanner", {
                    percentDone,
                    ...(tasks?.done
                      ? { tasksDone: Object.keys(tasks?.done) }
                      : {})
                  });
                  this.setState({
                    showTutorialBanner: true
                  });
                  // Show in-app subscription paywall
                  const { appType } = Rex.getConfig() || {};
                  if (
                    Glob.get("appIsOnespot") &&
                    Platform.OS !== "web" &&
                    APP_VERSION_SUPPORTS_IAP &&
                    appType?.key !== "school" &&
                    !appMetadata?.public
                  ) {
                    Database.fetchBillingInfo().then((billingInfo) => {
                      if (!billingInfo) {
                        Analytics.logEvent("action_root_showCreatorOnboarding");
                        Rex.setConfig({
                          ...Rex.getConfig(),
                          showSubscriptionPaywall: true
                        });
                        navigation.push("onespotCreatorOnboarding", {
                          shouldPublish: false
                        });
                      }
                    });
                  }
                  // Show "Ready to publish?" modal
                  else if (percentDone > 49) {
                    const tasksRequiredBeforePublishing = [
                      "createNewPortal",
                      "editPortal",
                      "viewAdmin"
                    ];
                    const allRequiredTasksDone = tasksRequiredBeforePublishing.every(
                      (task) => tasks.done[task]
                    );
                    if (allRequiredTasksDone && !appMetadata?.public) {
                      let message = `Your app is ${percentDone}% done! 🙌`;
                      if (Platform.OS === "web") {
                        message += `\n\nTry publishing ${
                          Rex.getConfig()?.names?.full
                        } to make it discoverable and to start sharing it with people.`;
                      } else {
                        message += `\n\nTo make ${
                          Rex.getConfig()?.names?.full
                        } discoverable and shareable, log into Onespot's web platform and publish it (www.1spot.app)`;
                      }
                      this.setState({
                        alert: {
                          title: "Ready to publish?",
                          message,
                          confirm: {
                            text: "Publish 🚀",
                            onPress: () => {
                              Analytics.logEvent(
                                "touch_root_alertModalPublish_publish"
                              );
                              if (Platform.OS === "web")
                                navigation.push("publish");
                              else
                                Util.openURL(
                                  `https://www.1spot.app?app=${databaseAppID}`
                                );
                            }
                          },
                          cancel: {
                            text: "Not Yet",
                            onPress: () => {
                              Analytics.logEvent(
                                "touch_root_alertModalPublish_cancel"
                              );
                            }
                          },
                          disableOutsideTouch: true
                        }
                      });
                    }
                  }
                }
              }
            });
          });
        });
      });

      Database.subscribeToTasks((tasks) => {
        const newTutorialTasksRemaining = this.calculateTutorialTasksRemaining(
          tasks?.done
        );
        this.setState({ tutorialTasksRemaining: newTutorialTasksRemaining });
      });
    }

    Database.listenUserType((accountType) => {
      Rex.setUserType(accountType);
      this.refreshHomeScreen();
    });

    Firebase.getMediaURLAsync("background.png").then((url) =>
      this.setState({ backgroundImage: url })
    );

    Database.fetchAppStoreInfo().then((info) => {
      if (info?.version) {
        this.setState({ appVersionRequired: info?.version?.required || "" });
      } else {
        // backup to ensure backwards compatibility
        Database.listenAppVersion((_, requiredVersion) => {
          this.setState({ appVersionRequired: requiredVersion });
        });
      }

      if (info?.appLinks) {
        this.setState({
          appStoreLink:
            Platform.OS === "ios" || (Platform.OS === "web" && isIOS)
              ? info.appLinks.ios
              : info.appLinks.android
        });
      } else {
        // for backwards compatibility
        this.setState({
          appStoreLink:
            Platform.OS === "ios" || (Platform.OS === "web" && isIOS)
              ? School.get("app link ios")
              : School.get("app link android")
        });
      }
    });

    Network.getNetworkStateAsync().then((connection) =>
      this.setState({ internetIsConnected: connection.isConnected })
    );

    // sets a timer to regularly refresh home screen // TODO: WHY???
    // this just re-renders the homescreen if homeorder has been changed in Customize
    // TODO: do this more efficiently
    // setInterval(this.checkRefresh, 300);
    // this.refreshHomeScreen();

    const INSTALL_APP_MESSAGES = [
      `Download the ${
        Rex.getConfig()?.names?.nickname
      } app now to get notifications and lots more.`,
      `Get the app now to stay engaged with everything related to ${
        Rex.getConfig()?.names?.nickname
      }.`,
      `Instant messaging, notifications & alerts, and complete customization. Get the ${
        Rex.getConfig()?.names?.nickname
      } app now!`,
      "Go mobile. All the cool kids are doing it 😎"
    ];
    this.setState({
      randomInstallAppMessage:
        INSTALL_APP_MESSAGES[Util.randomInt(INSTALL_APP_MESSAGES.length)]
    });

    Animated.timing(
      // Animate over time
      this.state.fadeAnim, // The animated value to drive
      {
        toValue: 1, // Animate to opacity: 1 (opaque)
        duration: 1500, // Make it take a while
        useNativeDriver: true
      }
    ).start(); // Starts the animation

    // TODO: From push notification, always deep link straight to that notification (in Notifications screen)
    // More info: https://docs.expo.dev/versions/latest/sdk/notifications/#handling-push-notifications-with-react-navigation

    // Deep link to a specific portal
    Linking.parseInitialURLAsync().then((deepLink) => {
      if (deepLink && deepLink.path === "screen") {
        const { type: portalType, navName } = deepLink.queryParams;
        if (portalType && navName) {
          if (portalType === "native") {
            navigation.push(navName, {
              portalType,
              navName,
              txtName: `${portalType}: ${navName}`
            }); // e.g. 'food'
          } else {
            navigation.push(portalType, {
              portalType,
              navName,
              txtName: `${portalType}: ${navName}`
            }); // e.g. 'webNav'
          }
        }
      }
    });
  }

  componentWillUnmount() {
    // cleanup called when component is unmounting
    Database.unsubscribeFromTasks();
  }

  listenForUnopenedNotifications = (apps) => {
    // Add an unopened-notifications listener for every app the user is in
    apps.forEach((appID) => {
      Database.listenIsNotificationsUnopenedInApp(appID, (includesUnopened) => {
        const { appsWithUnopenedNotifications } = this.state;
        const newAppsWithUnopenedNotifications = new Set(
          appsWithUnopenedNotifications
        );
        if (includesUnopened) newAppsWithUnopenedNotifications.add(appID);
        else newAppsWithUnopenedNotifications.delete(appID);
        this.setState({
          appsWithUnopenedNotifications: newAppsWithUnopenedNotifications
        });
      });
    });
  };

  calculateTutorialTasksRemaining = (tasksDone = {}) => {
    return Glob.get("tutorialTasks").filter((task) => !(task in tasksDone));
  };

  calculatePercentTutorialDone = (tasksRemaining) => {
    const numberDone =
      Glob.get("tutorialTasks").length - tasksRemaining.length + 1;
    return Math.round(
      (100 * numberDone) / (Glob.get("tutorialTasks").length + 1)
    );
  };

  // todo: this might fetch a lot of data
  refreshHomeScreen = () => {
    this.setState({ allUserHomePortals: Rex.getHomePortals() });
    Database.getHomescreenBanner((homescreenBanner) => {
      const hideHomescreenBanner =
        !homescreenBanner || homescreenBanner.active === "no";
      this.setState({
        homescreenBanner,
        hideHomescreenBanner,
        bannerMessageKey: `${Math.random()}`
      });
    });
    const { privileges } = this.state;
    const canEditPortals = privileges.includes("EditAllPortals");
    Database.fetchAllActivityFeeds({
      showRestrictedFeeds: canEditPortals
    }).then(({ hiddenActivityFeedPortals = [] }) => {
      Database.getUserPortals().then((value) => {
        const userPortals = Util.filterPortals(value).filter(
          (portal) => !hiddenActivityFeedPortals.includes(portal.navName)
        );
        this.setState({
          allUserHomePortals: userPortals,
          filteredUserHomePortals: userPortals,
          loadedPortals: true,
          hiddenActivityFeedPortals
        });
        Rex.updateHome(userPortals);
      });
    });
  };

  // Temporarily turn off the admin view
  turnOffAdminPrivileges = () => {
    Rex.setSessionMemory("adminPrivileges", []);
    this.setState({
      userIsAdmin: false,
      privileges: []
    });
  };

  // Re-render the screen if the homeorder has changed
  // Also check the homescreen banner message again
  // TODO: Make those things just listen to changes in the db
  checkRefresh = () => {
    this.refreshHomeScreen();
  };

  // This is passed to places where we admins can edit specific portals
  onUpdatePortal = (callbackFunc = () => {}, refreshLocalData = true) => {
    Database.getAllPortalMetadata().then((metadata) => {
      const portals = Object.entries(metadata || []).map(([key, value]) => ({
        ...value,
        navName: key
      }));
      portals.sort((p1, p2) => {
        // sort alphabetically
        if (p1.txtName < p2.txtName) return -1;
        if (p1.txtName > p2.txtName) return 1;
        return 0;
      });
      Rex.setAllPortals(portals);
      callbackFunc(portals);
      if (refreshLocalData) this.refreshHomeScreen();
    });
  };

  _handleNotification = (notification) => {
    Analytics.logEvent("push_notification", { notification });
  };

  getInactivePortals = () => {
    const { allUserHomePortals } = this.state;
    const activePortalKeys = allUserHomePortals.map((p) => p.navName);
    const { hiddenActivityFeedPortals = [] } = this.state;
    return Util.filterPortals(
      Rex.getAllPortals().filter(
        (p) =>
          !hiddenActivityFeedPortals.includes(p.navName) &&
          !activePortalKeys.includes(p.navName)
      )
    );
  };

  searchModules = (text) => {
    const { allUserHomePortals } = this.state;
    Analytics.logEvent("touch_root_search", { search_phrase: text });
    const searchKey = text;
    if (searchKey.length > 0) {
      const inactivePortals = this.getInactivePortals();
      const activeHomePortalsFound = Util.searchItems(
        allUserHomePortals,
        searchKey,
        "txtName"
      );
      const inactiveHomePortalsFound = Util.searchItems(
        inactivePortals,
        searchKey,
        "txtName"
      ).map((p) => ({ ...p, isInactivePortal: true }));
      const newHomeOrder = [
        ...activeHomePortalsFound,
        ...inactiveHomePortalsFound
      ];
      this.setState({ filteredUserHomePortals: newHomeOrder });
      LayoutAnimation.configureNext(CustomLayoutSpring);
    } else if (!searchKey) {
      this.setState({ filteredUserHomePortals: allUserHomePortals });
      LayoutAnimation.configureNext(CustomLayoutSpring);
    }
  };

  askToRegisterUserForPushNotifications = async () => {
    return new Promise((resolve) => {
      Util.alert(
        "Can we send you notifications?",
        `This will let ${Rex.getConfig()?.names?.nickname ||
          "this app"} give you relevant alerts & messages. We recommend you allow notifications; you can always turn them off later.`,
        [
          { text: "Not Now", onPress: () => resolve(false) },
          { text: "Yes 👍", onPress: () => resolve(true) }
        ]
      );
    });
  };

  registerUserForPushNotifications = async () => {
    return new Promise(async (resolve, reject) => {
      if (Platform.OS === "web") return reject();
      const {
        status: existingStatus,
        canAskAgain
      } = await Notifications.getPermissionsAsync();
      Analytics.logEvent("action_root_checkingPushNotificationsStatus", {
        status: existingStatus,
        canAskAgain
      });
      let finalStatus = existingStatus;
      if (existingStatus !== "granted" && canAskAgain) {
        const shouldAsk = await this.askToRegisterUserForPushNotifications();
        if (!shouldAsk) {
          Analytics.logEvent("action_root_userDeclinedPushNotificationsAsk");
          return reject();
        }
        Analytics.logEvent("action_root_requestingPushNotifications");
        const { status } = await Notifications.requestPermissionsAsync();
        finalStatus = status;
        if (status !== "granted")
          Analytics.logEvent("action_root_userRejectedPushNotifications");
      }
      if (finalStatus === "granted") {
        Analytics.logEvent("action_root_userGrantedPushNotifications");
        try {
          // Implements this bug-fix: https://github.com/expo/expo/issues/23225#issuecomment-1624028839
          const token = await Notifications.getExpoPushTokenAsync({
            projectId: EAS_PROJECT_ID
          });
          Analytics.logEvent("action_root_FetchedPushToken", {
            token
          });
          if (token?.data) return resolve(token.data);
        } catch (error) {
          Util.alert(
            "Oops!",
            `We were unable to register this device for push notifications | ${error}`
          );
        }
      }
      Analytics.logEvent("action_root_userHasNotGrantedPushNotifications");
      return reject();
    });
  };

  fetchAllAppsSuperAdmin = () => {
    const { userIsAdmin, appsJoined } = this.state;
    if (userIsAdmin && Database.userIsSuperAdmin()) {
      const appsInThisMetaApp = Rex.getMetaApp()?.apps;
      if (appsInThisMetaApp) {
        this.setState({ appsJoined: Object.keys(appsInThisMetaApp) });
      } else {
        Database.onespotFetchAllAppsMetadata().then((all) => {
          const appsNotJoined = Object.keys(all).filter(
            (appID) => !appsJoined.includes(appID)
          );
          this.listenForUnopenedNotifications(appsNotJoined);
          this.setState({ appsJoined: Object.keys(all) });
        });
      }
    }
  };

  toggleCustomizingHomeScreen = (isFromLongPressingTile = false) => {
    const { isEditing, allUserHomePortals } = this.state;
    if (!isEditing) {
      Analytics.logEvent("touch_root_customize", {
        isFromLongPressingTile: !!isFromLongPressingTile
      });
    } else Analytics.logEvent("touch_root_customizeDone");
    this.setState({
      isEditing: !isEditing,
      filteredUserHomePortals: allUserHomePortals // clear any searches
    });
  };

  renderForceUpdate = () => {
    const { appStoreLink } = this.state;

    return (
      <View style={styles.forceUpdateView}>
        <Text style={styles.forceUpdateText}>
          Your version of this app is no longer supported.
        </Text>

        <TouchableHighlight
          style={styles.button}
          onPress={() => {
            if (appStoreLink) Util.openURL(appStoreLink);
          }}
        >
          <Text style={styles.buttonText}>UPDATE APP</Text>
        </TouchableHighlight>

        <Text style={styles.forceUpdateSubtext}>
          We know forcing an update like this can be annoying, and we really are
          sorry we have to do it now. But, to fix bugs and keep improving the
          app, we very occasionally have to make core changes to our database.
          When the database is updated, old versions of the app will stop
          working, like what happened here. We promise not to do this
          frequently! Thanks for using this app :)
        </Text>
      </View>
    );
  };

  renderCloseBannerButton = (onPress, color = "white") => {
    return (
      <TouchableOpacity
        style={{
          position: "absolute",
          top: 0,
          right: 0,
          padding: 7,
          justifyContent: "center",
          alignItems: "center"
        }}
        activeOpacity={0.6}
        onPress={onPress}
      >
        <MaterialCommunityIcons name="window-close" size={24} color={color} />
      </TouchableOpacity>
    );
  };

  renderSearchBar = () => {
    const { navigation, globalConfigOverride } = this.props;
    const {
      homescreenBanner,
      hideHomescreenBanner,
      isEditing,
      showTutorialBanner,
      tutorialTasksRemaining,
      hiddenActivityFeedPortals = []
    } = this.state;
    if (isEditing)
      return (
        <>
          <Button
            key="addNewPortalButton"
            text="Add to Your Home Screen"
            icon="plus"
            style={{ backgroundColor: "rgba(255, 255, 255, 0.2)" }}
            textStyle={{ color: "rgba(255, 255, 255, 1)" }}
            onPress={() =>
              navigation.push("addPortals", {
                checkRefresh: this.checkRefresh,
                portalsToHide: hiddenActivityFeedPortals
              })
            }
          />
          <Text
            style={[
              Style.get("headerText"),
              { color: "white", marginVertical: 3 }
            ]}
          >
            Hold & drag to reorder
          </Text>
        </>
      );
    if (showTutorialBanner) {
      const percentDone = this.calculatePercentTutorialDone(
        tutorialTasksRemaining
      );
      let buttonText = "✨ Start Building!";
      if (percentDone >= 100) buttonText = "🙌 You Did It!";
      else if (percentDone > 80) buttonText = "🚀 Finish Up!";
      else if (percentDone > 50) buttonText = "🛠 Keep Building!";
      else if (percentDone > 10) buttonText = "👉 Next Steps!";
      const GREEN = "#2DD881";
      return (
        <View
          style={{
            width: "100%",
            alignItems: "center",
            backgroundColor: "rgba(0,0,0,0.5)"
          }}
        >
          <View
            style={{
              flexDirection: "row",
              width: widthWithMargins,
              justifyContent: "center",
              paddingVertical: 10
            }}
          >
            <CircularProgress small value={percentDone} color={GREEN} />
            <Button
              text={buttonText}
              small
              color={GREEN}
              style={{ marginLeft: 20 }}
              onPress={() =>
                navigation.push("tutorial", {
                  showUnopenedTutorialTask: () =>
                    this.setState({ showUnopenedTutorialTask: true })
                })
              }
            />
          </View>
          {this.renderCloseBannerButton(() =>
            this.setState({ showTutorialBanner: false })
          )}
        </View>
      );
    }
    const globalConfig = globalConfigOverride || Rex.getConfig();
    const searchBarEnabled = globalConfig?.searchBarEnabled;
    if (searchBarEnabled && (!homescreenBanner || hideHomescreenBanner)) {
      return (
        <SearchBar
          placeholder="Search"
          searchSectionStyle={styles.searchSection}
          searchInputStyle={styles.searchSectionInput}
          onTextChangeAction={this.searchModule}
          onChangeText={(text) => {
            this.searchModules(text);
          }}
        />
      );
    }
    return null;
  };

  render() {
    const { navigation, globalConfigOverride, productPurchased } = this.props;
    const {
      appVersionRequired,
      homescreenBanner,
      hideHomescreenBanner,
      isEditing,
      allUserHomePortals,
      filteredUserHomePortals,
      loadedPortals,
      userIsAdmin,
      userIsDemoAccount,
      privileges,
      backgroundImage,
      appStoreLink,
      showInstallAppBanner,
      randomInstallAppMessage,
      showMenu,
      appsJoined,
      appsWithUnopenedNotifications,
      alert,
      bannerMessageKey,
      showTutorialBanner,
      showUnopenedTutorialTask,
      userCustomizedHomeScreenPreviously,
      currentPage,
      hiddenActivityFeedPortals = []
    } = this.state;

    // FORCE APP UPDATE: If Rex has a minimum required version, and the user's version is lower than that, force app update
    if (
      appVersionRequired &&
      !Database.compareVersions(Glob.get("appVersion"), appVersionRequired)
    ) {
      return (
        <ImageBackground
          source={
            backgroundImage
              ? { uri: backgroundImage }
              : hardcodedBackgroundImage
          }
          style={[
            styles.background,
            { backgroundColor: Rex.getConfig()?.colors?.background }
          ]}
        >
          <View style={styles.mainHeader}>
            <NavBar text={Rex.getConfig()?.names?.full} cannotNavigateBack />
          </View>

          {this.renderForceUpdate()}
        </ImageBackground>
      );
    }

    const userIsAllowedToCreatePortals =
      !!privileges && privileges.includes("EditAllPortals");
    const userIsAllowedToEditDefaultPortals =
      !!privileges && privileges.includes("EditDefaultPortals");
    const userIsAllowedToCustomizeHomeScreen =
      Rex.getConfig()?.portalsConfigCustomizationDisabled !== true ||
      userIsAllowedToEditDefaultPortals;

    const noPortalsExist = (Rex.getAllPortals() || []).length < 1;
    const rightButtonIconSize = Math.min(
      0.07 * width,
      Glob.deviceHasTabletDimensions() ? 42 : 26
    );
    const RightButton = userIsAllowedToCustomizeHomeScreen ? (
      <TouchableOpacity
        style={{
          width: "100%",
          height: "100%",
          alignItems: "flex-end",
          justifyContent: "center",
          paddingRight: 10,
          opacity: noPortalsExist ? 0.15 : 1
        }}
        activeOpacity={0.6}
        disabled={noPortalsExist}
        onPress={() => this.toggleCustomizingHomeScreen()}
      >
        {isEditing ? (
          <Entypo name="check" size={rightButtonIconSize} color="white" />
        ) : (
          <MaterialIcons
            name="dashboard-customize"
            size={rightButtonIconSize}
            color="white"
          />
        )}
      </TouchableOpacity>
    ) : null;

    // todo: add analytics to this
    const InstallAppBanner = () => {
      return (
        <View
          style={{
            width: "100%",
            alignItems: "center",
            backgroundColor: "rgba(255,255,255,0.5)"
          }}
        >
          <View
            style={{ height: "100%", width: widthWithMargins, padding: 20 }}
          >
            <Text style={[Style.get("headerText"), { textAlign: "center" }]}>
              {Rex.getConfig()?.names?.full} is even better in the app!
            </Text>
            <Text
              style={[
                Style.get("subheaderText"),
                { color: "white", textAlign: "center" }
              ]}
            >
              {randomInstallAppMessage}
            </Text>
            {isIOS || isAndroid ? (
              <Button
                text={`${randomCelebratoryEmoji} Install ${
                  isIOS ? "iOS" : "Android"
                } App`}
                onPress={() => window.open(appStoreLink, "_blank")}
              />
            ) : (
              <Button
                text={`${randomCelebratoryEmoji} Get the App`}
                onPress={() =>
                  navigation.push("shareApp", {
                    userWantsToInstallMobileApp: true
                  })
                }
              />
            )}
          </View>
          {this.renderCloseBannerButton(() =>
            this.setState({ showInstallAppBanner: false })
          )}
        </View>
      );
    };

    const backgroundImageSource = backgroundImage
      ? { uri: backgroundImage }
      : hardcodedBackgroundImage;

    const databaseAppID = School.getDatabaseAppID();
    const notificationsIncludeUnopened = appsWithUnopenedNotifications.has(
      databaseAppID
    );
    const otherAppsWithUnopenedNotifications = new Set(
      appsWithUnopenedNotifications
    );
    otherAppsWithUnopenedNotifications.delete(databaseAppID);
    const notificationsIncludeUnopenedInAnotherApp =
      otherAppsWithUnopenedNotifications.size > 0;

    const numberOfPages = Math.ceil(filteredUserHomePortals.length / 6);

    return (
      <ImageBackground
        source={backgroundImageSource}
        style={[
          styles.background,
          { backgroundColor: Rex.getConfig()?.colors?.background },
          Platform.OS === "web" ? { position: "fixed" } : {}
        ]}
      >
        {/* {!backgroundImageSource && Platform.OS !== "android" && (
          ERROR: THIS DOES NOT WORK ON IOS OR ANDROID PROD APPS (only in expo + web)
          <LinearGradient
            colors={[
              tinycolor(Rex.getConfig()?.colors?.background)
                .lighten(5)
                .toString(),
              Rex.getConfig()?.colors?.background
            ]}
            style={{
              position: "absolute",
              left: 0,
              right: 0,
              top: 0,
              height
            }}
          />
        )} */}
        <View style={styles.mainHeader}>
          <WebPageMetaTitle title={Rex.getConfig()?.names?.full} />
          <NavBar
            navigation={navigation}
            text={isEditing ? "Customize" : Rex.getConfig()?.names?.full}
            screenType={isEditing ? "rootCustomize" : "root"}
            RightButton={RightButton}
            checkRefresh={this.checkRefresh}
            onUpdatePortal={this.onUpdatePortal}
            adminPrivileges={userIsAdmin && privileges}
            unopenedNotification={notificationsIncludeUnopened}
            unopenedNotificationInAnotherJoinedApp={
              notificationsIncludeUnopenedInAnotherApp
            }
            unopenedTutorialTask={showUnopenedTutorialTask}
            hideUnopenedTutorialTask={() =>
              this.setState({ showUnopenedTutorialTask: false })
            }
            turnOffAdminPrivileges={this.turnOffAdminPrivileges}
            onPressBackButton={() => this.setState({ showMenu: true })}
            backgroundColor={globalConfigOverride?.colors?.navbar}
            userIsDemoAccount={userIsDemoAccount}
          />
        </View>
        {homescreenBanner && !hideHomescreenBanner && !isEditing && (
          <BannerMessage
            {...homescreenBanner}
            key={bannerMessageKey}
            navigation={navigation}
            onPressClose={() => this.setState({ hideHomescreenBanner: true })}
          />
        )}
        {this.renderSearchBar()}
        <ScrollView
          scrollEnabled={Platform.OS === "web"}
          contentContainerStyle={{ width, alignItems: "center" }}
          scrollIndicatorInsets={{ right: 1 }}
        >
          {loadedPortals ? (
            <>
              {filteredUserHomePortals?.length < 1 && (
                <View style={{ alignItems: "center", marginHorizontal: 30 }}>
                  {allUserHomePortals?.length < 1 ? (
                    <>
                      <Text style={styles.noPortalsFoundText}>
                        {noPortalsExist && userIsAllowedToCreatePortals
                          ? "Welcome! Your app is empty."
                          : `Your ${
                              userIsAllowedToCreatePortals ? "personal " : ""
                            }home screen is empty.`}
                      </Text>
                      <Text style={styles.noPortalsFoundTextSecondary}>
                        {noPortalsExist && userIsAllowedToCreatePortals
                          ? "Press the button below to make your first screen."
                          : `Press the plus button to add some portals to your home screen!${
                              userIsAllowedToCreatePortals
                                ? " Or press the New Screen button to create a new one for everyone."
                                : ""
                            }`}
                      </Text>
                    </>
                  ) : (
                    <>
                      <Text style={styles.noPortalsFoundText}>
                        No portals found 😕
                      </Text>
                      <Text style={styles.noPortalsFoundTextSecondary}>
                        Maybe the portal you're looking for has a different
                        name?
                      </Text>
                    </>
                  )}
                </View>
              )}
              <PageList
                isEditing={isEditing}
                userHasInactivePortals={this.getInactivePortals().length > 0}
                portals={filteredUserHomePortals}
                navigation={navigation}
                onUpdatePortal={this.onUpdatePortal}
                checkRefresh={this.checkRefresh}
                userIsAllowedToCreatePortals={privileges.includes(
                  "EditAllPortals"
                )}
                userIsAllowedToCustomizeHomeScreen={
                  userIsAllowedToCustomizeHomeScreen
                }
                globalConfigOverride={globalConfigOverride}
                condense={
                  !!homescreenBanner &&
                  !hideHomescreenBanner &&
                  !!showTutorialBanner
                }
                toggleCustomizingHomeScreen={
                  userIsAllowedToCustomizeHomeScreen
                    ? this.toggleCustomizingHomeScreen
                    : null
                }
                setCurrentPage={(newCurrentPage) =>
                  this.setState({ currentPage: newCurrentPage })
                }
                numberOfPages={numberOfPages}
                portalsToHide={hiddenActivityFeedPortals}
                userIsDemoAccount={userIsDemoAccount}
              />
            </>
          ) : (
            <ActivityIndicator
              size="large"
              color="white"
              style={{ marginTop: 50 }}
            />
          )}
        </ScrollView>
        {numberOfPages > 1 &&
          !isEditing &&
          (Platform.OS !== "web" || userIsDemoAccount) && (
            <View
              style={{
                width: "100%",
                position: "absolute",
                bottom: 0,
                justifyContent: "flex-start",
                alignItems: "center"
              }}
            >
              <HomePaginationDots
                currentPage={currentPage}
                numberOfPages={numberOfPages}
                showCustomizeTip={
                  !userCustomizedHomeScreenPreviously &&
                  userIsAllowedToCustomizeHomeScreen
                }
              />
            </View>
          )}
        {showInstallAppBanner && !userIsDemoAccount && <InstallAppBanner />}
        <Modal
          isVisible={showMenu}
          animationIn="slideInLeft"
          animationOut="slideOutLeft"
          onBackdropPress={() => this.setState({ showMenu: false })}
          style={{ margin: 0 }}
        >
          <RootMenu
            navigation={navigation}
            closeMenu={() => this.setState({ showMenu: false })}
            appsJoined={appsJoined}
            appsWithUnopenedNotifications={appsWithUnopenedNotifications}
            checkRefresh={this.checkRefresh}
            fetchAllAppsSuperAdmin={this.fetchAllAppsSuperAdmin}
            adminPrivileges={userIsAdmin && privileges}
            userIsDemoAccount={userIsDemoAccount}
          />
        </Modal>
        <AlertModal
          alert={alert}
          setAlert={(newAlert) => this.setState({ alert: newAlert })}
        />
        {!!productPurchased && (
          <ConfettiCannon
            count={200}
            origin={{ x: width / 2, y: height }}
            autoStart
            fadeOut
          />
        )}
      </ImageBackground>
    );
  }
}

const styles = StyleSheet.create({
  /* Style for main background image. */
  background: {
    height: "100%",
    width: "100%",
    flexDirection: "column",
    alignItems: "center"
  },

  /* Style for the header section that holds the school name and crest */
  mainHeader: {
    width: "100%",
    height: Glob.get("navBarHeight"),
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "space-around"
  },

  /* Style for the page containing text + button for a forced app update */
  forceUpdateView: {
    alignItems: "center"
  },

  /* Style for the text saying that the app must be updated */
  forceUpdateText: {
    color: "rgba(75, 75, 75, 1)",
    backgroundColor: "transparent",
    fontSize: 0.04 * height,
    textAlign: "center",
    marginTop: 0.05 * height,
    marginBottom: 0.05 * height,
    paddingLeft: 0.08 * width,
    paddingRight: 0.08 * width
  },

  forceUpdateSubtext: {
    color: "rgba(75, 75, 75, 1)",
    backgroundColor: "transparent",
    fontSize: 0.02 * height,
    textAlign: "center",
    marginTop: 0.2 * height,
    paddingLeft: 0.12 * width,
    paddingRight: 0.12 * width
  },

  /* Style for the "update" button */
  button: {
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(200, 200, 200, 1)",
    height: 0.063 * height,
    width: 0.719 * width,
    borderRadius: 0.075 * height,
    borderWidth: 1,
    borderColor: "rgba(75, 75, 75, 1)"
  },

  buttonText: {
    color: "rgba(75, 75, 75, 1)",
    fontSize: 0.03 * height,
    textAlign: "center"
  },

  noPortalsFoundText: {
    color: "white",
    fontWeight: "bold",
    marginTop: 50,
    fontSize: 18
  },

  noPortalsFoundTextSecondary: {
    color: "white",
    fontSize: 12
  }
});
